import { Model, attr } from "redux-orm";
import set from "lodash/set";
import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";

import {
  SYSTEM_MESSAGE_MODEL_NAME,
  SYSTEM_MESSAGE_MODEL_ID
} from "./systemMessageConstants";
import {
  MANDATORY_RULE,
  MANDATORY_MESSAGE,
  NUMBER_OF_CHARACTERS_RULE,
  NUMBER_OF_CHARACTERS_MESSAGE_100,
  NUMBER_OF_CHARACTERS_MESSAGE_1000,
  DATE_RULE,
  DATE_MESSAGE
} from "../../constants/validationConstants";

export const defaultAttributes = { isActive: true };

class SystemMessage extends Model {
  static get fields() {
    return {
      id: attr(),
      systemMessageId: attr(),
      user: attr(),
      title: attr(),
      message: attr(),
      startDate: attr(),
      endDate: attr(),
      modified: attr(),
      isActive: attr(),
      file: attr(),
      hasAttachment: attr()
    };
  }

  static parse(systemMessageData) {
    if (systemMessageData[SYSTEM_MESSAGE_MODEL_ID]) {
      set(systemMessageData, "id", systemMessageData[SYSTEM_MESSAGE_MODEL_ID]);
    }
    return this.upsert(systemMessageData);
  }

  static updateModel(systemMessageData) {
    return this.update(systemMessageData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherSystemMessage) {
    this.update(otherSystemMessage.ref);
  }

  static get validation() {
    return {
      title: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 1,
            max: 100
          }
        }
      ],
      message: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
          options: {
            min: 0,
            max: 1000
          }
        }
      ],
      startDate: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DATE_RULE,
          message: DATE_MESSAGE
        }
      ],
      endDate: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DATE_RULE,
          message: DATE_MESSAGE
        }
      ]
    };
  }

  validate() {
    return validateModel(this.validation, this.toJSON());
  }

  validateByProperty(name) {
    return validateProperty(SystemMessage.validation[name], this.ref[name]);
  }

  hasErrors() {
    return doesModelHaveErrors(SystemMessage.validation, this.toJSON());
  }
}

SystemMessage.modelName = SYSTEM_MESSAGE_MODEL_NAME;

export default SystemMessage;
