import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { classes, Grid } from "./styles/ConfigurationActions";
import {
  selectIsLoading,
  selectCanEditAgencyRule
} from "../../../../business/selectors/applicationSelectors";

const ConfigurationActions = () => {
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);
  const handleCancel = event => {
    event.preventDefault();
    navigate("/");
  };

  return (
    // <Grid container justifyContent="space-between" className={classes.root}>//TODO(AM)
    <Grid container justifyContent="space-between">
      <Grid item>
        <Button
          id="ca_cancel"
          onClick={handleCancel}
          name="ca_cancel"
          form="AAConfigForm"
          type="reset"
          disabled={isLoading}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="ca_save"
          name="ca_save"
          form="AAConfigForm"
          type="submit"
          disabled={isLoading}
          className={clsx(classes.saveButton, classes.approveButton)}
        >
          SAVE
        </Button>
      </Grid>
    </Grid>
  );
};
export default ConfigurationActions;
