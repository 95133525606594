import { styled, Dialog as MuiDialog } from "@mui/material";

const PREFIX = "MultipleCurrencySupplierDialogStyle";
export const classes = {
  tableCell: `${PREFIX}-tableCell`,
  dialogContent: `${PREFIX}-dialogContent`
};

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${classes.tableCell}`]: {
    borderRight: `${theme.typography.pxToRem(1)} solid rgba(224, 224, 224, 1)`
  },
  [`& .${classes.dialogContent}`]: {
    overflow: "hidden"
  }
}));
