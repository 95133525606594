import clsx from "clsx";
import NumberFormat from "react-number-format";

import { find } from "lodash";

import { Chip, Typography, IconButton, Hidden, Tooltip } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { EditIcon, InsertionRowIcon } from "../../../assets/styles/icons";

import { classes, Grid } from "../styles/insertionPanelStyles";

import { parseDate } from "../../../business/models/common/utils/clientUtils";
import {
  isOrderPendingForApprovalByID,
  doesOrderHaveStatusToBeCancelledOrCancelledById,
  getPropertyTextFromApiData
} from "../../../business/models/Order/orderSelectors";
import { canEditCampaign } from "../../../business/selectors/userProfileSelectors";
import { checkIsOrderValidAndShowError } from "../../../business/models/Order/orderActions";
import { editInsertions } from "../../../business/models/Insertion/insertionActions";
import { getInsertion } from "../../../business/models/Insertion/insertionSelectors";
import { selectInsertionsWithChange } from "../../../business/selectors/campaignDashboardSelector";
import { currencySymbols } from "../../../business/constants/businessConstants";
import { PLACEHOLDER } from "../../../configurations/appConstants";

const InsertionViewItem = ({ id, isInCopyProcess }) => {
  const dispatch = useDispatch();

  const insertion = useSelector(state => getInsertion(state, { entityId: id }));

  const {
    insertionIsInvoiced,
    orderId,
    insertionInvoiceDate,
    insertionNetAmount,
    insertionNumOfUnits,
    currencySupplierSymbol,
    insertionSurcharge1Amount,
    insertionSurcharge2Amount
  } = insertion;

  const title = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "titleId", orderId })
  );

  const hasStatusPendingForApproval = useSelector(state =>
    isOrderPendingForApprovalByID(state, orderId)
  );

  const hasStatusToBeCancelledOrCancelled = useSelector(state =>
    doesOrderHaveStatusToBeCancelledOrCancelledById(state, orderId)
  );

  const disableAction = !useSelector(canEditCampaign);

  const insertionsWithChage = useSelector(selectInsertionsWithChange);

  const handleEdit = () => {
    const isValid = dispatch(checkIsOrderValidAndShowError(orderId));
    if (isValid) {
      dispatch(editInsertions());
    }
  };

  const typographyClass = insertionIsInvoiced
    ? clsx(
        classes.typographyInsertionHeader,
        classes.typographyInvoicedInsertionHeader
      )
    : clsx(classes.typographyInsertionHeader);

  const chipClass = insertionIsInvoiced
    ? clsx(classes.chip, classes.whiteChip, classes.redLabelChip)
    : clsx(classes.chip, classes.whiteChip);

  const insertionChange = find(insertionsWithChage, ["item1", id]);
  // eslint-disable-next-line no-extra-boolean-cast
  const insertionAmountChange = !!insertionChange ? insertionChange.item2 : "";
  // eslint-disable-next-line no-extra-boolean-cast
  const insertionSurcharge1AmountChange = !!insertionChange
    ? insertionChange.item3
    : "";
  // eslint-disable-next-line no-extra-boolean-cast
  const insertionSurcharge2AmountChange = !!insertionChange
    ? insertionChange.item4
    : "";

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <InsertionRowIcon />
          </Grid>
          <Grid item>
            <Typography
              id={`title_${id}`}
              name={`title_${id}`}
              className={typographyClass}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              id={`insertionInvoiceDate_${id}`}
              name={`insertionInvoiceDate_${id}`}
              label={parseDate(insertionInvoiceDate)}
              className={chipClass}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Tooltip
              title={
                // eslint-disable-next-line no-extra-boolean-cast
                !!insertionAmountChange ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.ctcTooltip}>
                      <Typography
                        className={classes.ctcProperty}
                        variant="body1"
                      >
                        {`Previous amount (${currencySupplierSymbol}):`}
                        &nbsp;&nbsp;
                      </Typography>
                      {(
                        <NumberFormat
                          id="insertionAmountChange"
                          name="insertionAmountChange"
                          value={insertionNetAmount - insertionAmountChange}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      ) || PLACEHOLDER}
                    </Grid>
                    <Grid item xs={12} className={classes.ctcTooltip}>
                      <Typography
                        className={classes.ctcProperty}
                        variant="body1"
                      >
                        {`Amount difference (${currencySupplierSymbol}):`}
                        &nbsp;&nbsp;
                      </Typography>
                      {(
                        <NumberFormat
                          id="insertionAmountChange"
                          name="insertionAmountChange"
                          value={insertionAmountChange}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      ) || PLACEHOLDER}
                    </Grid>
                  </Grid>
                ) : (
                  false
                )
              }
            >
              <div>
                <Chip
                  id={`insertionNetAmount_${id}`}
                  name={`insertionNetAmount_${id}`}
                  component={NumberFormat}
                  value={insertionNetAmount}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={`${currencySupplierSymbol} `}
                  className={clsx(chipClass, {
                    [classes.orangeChip]: !!insertionAmountChange
                  })}
                  variant="outlined"
                  fixedDecimalScale
                  decimalScale={2}
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item>
            <Chip
              id={`insertionNumOfUnits_${id}`}
              name={`insertionNumOfUnits_${id}`}
              label={insertionNumOfUnits}
              className={chipClass}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Tooltip
              title={
                // eslint-disable-next-line no-extra-boolean-cast
                !!insertionSurcharge1AmountChange ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.ctcTooltip}>
                      <Typography
                        className={classes.ctcProperty}
                        variant="body1"
                      >
                        {`Previous amount (${currencySupplierSymbol}):`}
                        &nbsp;&nbsp;
                      </Typography>
                      {(
                        <NumberFormat
                          id="insertionSurcharge1AmountChange"
                          name="insertionSurcharge1AmountChange"
                          value={
                            insertionSurcharge1Amount -
                            insertionSurcharge1AmountChange
                          }
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      ) || PLACEHOLDER}
                    </Grid>
                    <Grid item xs={12} className={classes.ctcTooltip}>
                      <Typography
                        className={classes.ctcProperty}
                        variant="body1"
                      >
                        {`Amount difference (${currencySupplierSymbol}):`}
                        &nbsp;&nbsp;
                      </Typography>
                      {(
                        <NumberFormat
                          id="insertionSurcharge1AmountChange"
                          name="insertionSurcharge1AmountChange"
                          value={insertionSurcharge1AmountChange}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      ) || PLACEHOLDER}
                    </Grid>
                  </Grid>
                ) : (
                  false
                )
              }
            >
              <div>
                <Chip
                  id={`insertionSurcharge1Amount_${id}`}
                  name={`insertionSurcharge1Amount_${id}`}
                  component={NumberFormat}
                  value={insertionSurcharge1Amount}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={`${currencySupplierSymbol} `}
                  className={clsx(chipClass, {
                    [classes.orangeChip]: !!insertionSurcharge1AmountChange
                  })}
                  variant="outlined"
                  fixedDecimalScale
                  decimalScale={2}
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                // eslint-disable-next-line no-extra-boolean-cast
                !!insertionSurcharge2AmountChange ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.ctcTooltip}>
                      <Typography
                        className={classes.ctcProperty}
                        variant="body1"
                      >
                        {`Previous amount (${currencySupplierSymbol}):`}
                        &nbsp;&nbsp;
                      </Typography>
                      {(
                        <NumberFormat
                          id="insertionSurcharge2AmountChange"
                          name="insertionSurcharge2AmountChange"
                          value={
                            insertionSurcharge2Amount -
                            insertionSurcharge2AmountChange
                          }
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      ) || PLACEHOLDER}
                    </Grid>
                    <Grid item xs={12} className={classes.ctcTooltip}>
                      <Typography
                        className={classes.ctcProperty}
                        variant="body1"
                      >
                        {`Amount difference (${currencySupplierSymbol}):`}
                        &nbsp;&nbsp;
                      </Typography>
                      {(
                        <NumberFormat
                          id="insertionSurcharge2AmountChange"
                          name="insertionSurcharge2AmountChange"
                          value={insertionSurcharge2AmountChange}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      ) || PLACEHOLDER}
                    </Grid>
                  </Grid>
                ) : (
                  false
                )
              }
            >
              <div>
                <Chip
                  id={`insertionSurcharge2Amount_${id}`}
                  name={`insertionSurcharge2Amount_${id}`}
                  component={NumberFormat}
                  value={insertionSurcharge2Amount}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={`${currencySupplierSymbol} `}
                  className={clsx(chipClass, {
                    [classes.orangeChip]: !!insertionSurcharge2AmountChange
                  })}
                  variant="outlined"
                  fixedDecimalScale
                  decimalScale={2}
                />
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Hidden xsUp={isInCopyProcess}>
              <IconButton
                id={`i_edit_${id}`}
                name={`i_edit_${id}`}
                disabled={
                  disableAction ||
                  hasStatusPendingForApproval ||
                  hasStatusToBeCancelledOrCancelled
                }
                onClick={handleEdit}
                className={classes.iconButton}
              >
                <EditIcon
                  disabled={
                    disableAction ||
                    hasStatusPendingForApproval ||
                    hasStatusToBeCancelledOrCancelled
                  }
                />
              </IconButton>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InsertionViewItem;
