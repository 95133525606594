import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import { useParams, useNavigate } from "react-router-dom";

import { AccordionSummary, AccordionDetails, Divider } from "@mui/material";

import InsertionPanel from "../InsertionPanel";
import ActionOrderElement from "./ActionOrderElement";

import { classes, Accordion } from "../styles/actionOrderItem";
import { parseIntOrDefault } from "../../../../../../business/models/common/utils/clientUtils";
import {
  CAMPAIGN,
  PLAN,
  ORDER,
  ACTION_PAGE,
  EMPTY_STRING
} from "../../../../../../configurations/appConstants";

const ActionOrderItem = ({ order }) => {
  const navigate = useNavigate();
  const { agency, campaignId: cId, planId: pId, orderId: oId } = useParams();
  const { id } = order;

  const urlPlanId = parseIntOrDefault(pId);
  const urlCampaignId = parseIntOrDefault(cId);
  const urlOrderId = parseIntOrDefault(oId);

  const changeUrl = () =>
    navigate(
      `/${agency}/${ACTION_PAGE}/${CAMPAIGN}/${urlCampaignId}/${PLAN}/${urlPlanId}${
        urlOrderId === id ? EMPTY_STRING : `/${ORDER}/${id}`
      }`
    );

  const handleChange = () => {
    changeUrl();
  };

  const isActive = urlOrderId === id;

  return (
    <Accordion
      square
      elevation={0}
      className={clsx(classes.expansionPanel, {
        [classes.selected]: isActive
      })}
      expanded={isActive}
      onChange={handleChange}
    >
      <AccordionSummary className={classes.summaryOrderPanel}>
        <ActionOrderElement order={order} key={id} />
      </AccordionSummary>
      <Divider variant="middle" />
      <AccordionDetails className={classes.expensionDetailsTablePanel}>
        <InsertionPanel />
      </AccordionDetails>
    </Accordion>
  );
};

ActionOrderItem.propTypes = {
  order: PropTypes.object.isRequired
};

export default ActionOrderItem;
