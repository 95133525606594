import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import CtcButtonPlan from "./CtcButtonPlan";
import { GET_CTC_INFO_PLAN } from "../../../configurations/apiUrls";
import { CAMPAIGN_MODEL_ID } from "../../../business/models/Campaign/campaignConstants";
import { PLAN_MODEL_ID } from "../../../business/models/Plan/planConstants";
import generateUrl from "../../../business/models/common/utils/urlUtils";
import { selectAgency } from "../../../business/selectors/applicationSelectors";
import { TYPE_ICON, TYPE_BUTTON } from "../../../configurations/appConstants";
import api from "../../../functions/api";

const CtcButtonWrapperPlan = ({ campaignId, planId, type }) => {
  const [planData, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const agencyId = useSelector(selectAgency);

  const handleFetchData = async () => {
    setLoading(true);
    const url = generateUrl(GET_CTC_INFO_PLAN, {
      agency: agencyId,
      [CAMPAIGN_MODEL_ID]: campaignId,
      [PLAN_MODEL_ID]: planId
    });
    const response = await api.get(url);
    if (response) {
      setData(response);
      setLoading(false);
    }
  };
  const { clientTotalCostEur, clientTotalCost, currencySupplierId } = planData;

  return (
    <CtcButtonPlan
      id={`p_view_ctc_multiple_${planId}`}
      name={`p_view_ctc_multiple_${planId}`}
      type={type}
      clientTotalCostEur={clientTotalCostEur}
      clientTotalCost={clientTotalCost}
      fetchData={handleFetchData}
      currencySupplier={currencySupplierId}
      showCtc
      loading={loading}
    />
  );
};

CtcButtonWrapperPlan.propTypes = {
  campaignId: PropTypes.number,
  planId: PropTypes.number,
  type: PropTypes.oneOf([TYPE_BUTTON, TYPE_ICON])
};

CtcButtonWrapperPlan.defaultProps = {
  type: TYPE_BUTTON
};

export default CtcButtonWrapperPlan;
