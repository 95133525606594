import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Controls from "./controls/index";
import { setPropertyValue } from "../../business/models/TrafficPlanRemark/trafficPlanRemarkActions";
import { getPropertyValue } from "../../business/models/TrafficPlanRemark/trafficPlanRemarkSelectors";

class TrafficPlanRemarkField extends Component {
  handleChange = value => {
    const { name, onChange } = this.props;
    onChange({ [name]: value });
  };

  render() {
    const { component, ...componentProps } = this.props;
    const Control = Controls[component];
    return (
      <Control
        component={component}
        {...componentProps}
        onChange={this.handleChange}
      />
    );
  }
}

TrafficPlanRemarkField.propTypes = {
  component: PropTypes.oneOf(["text"]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getPropertyValue(state, ownProps)
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: data => dispatch(setPropertyValue(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrafficPlanRemarkField);
