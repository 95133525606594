import { styled, Grid as MuiGrid } from "@mui/material";
import {
  supernova,
  white,
  azureRadiance,
  baliHai,
  linkWater,
  black
} from "../../../../assets/styles/colors";

const PREFIX = "CreateActionsStyles";
export const classes = {
  redirectButton: `${PREFIX}-redirectButton`,
  saveButton: `${PREFIX}-saveButton`,
  markedButton: `${PREFIX}-markedButton`,
  fixedSize: `${PREFIX}-fixedSize`,
  addPlanButton: `${PREFIX}-addPlanButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.redirectButton}`]: {
    color: black,
    "&:disabled": {
      color: linkWater
    }
  },
  [`& .${classes.saveButton}`]: {
    color: white
  },
  [`& .${classes.markedButton}`]: {
    backgroundColor: supernova
  },
  [`& .${classes.addOrderButton}`]: {
    backgroundColor: azureRadiance
  },
  [`& .${classes.fixedSize}`]: {
    width: theme.typography.pxToRem(200)
  },
  [`& .${classes.addPlanButton}`]: {
    backgroundColor: baliHai
  }
}));
