import { styled, Grid as MuiGrid } from "@mui/material";
import {
  baliHai,
  solitude,
  matisse,
  crusta,
  supernova,
  white
} from "../../../assets/styles/colors";
import { getThemeSpacing } from "../../../functions/util";

const PREFIX = "OrderCompactStyles";
export const classes = {
  checkbox: `${PREFIX}-checkbox`,
  statusButton: `${PREFIX}-statusButton`,
  typographyOrderHeader: `${PREFIX}-typographyOrderHeader`,
  chip: `${PREFIX}-chip`,
  chipDark: `${PREFIX}-chip-dark`,
  chipNotify: `${PREFIX}-chip-notify`,
  widerChip: `${PREFIX}-widerChip`,
  badge: `${PREFIX}-badge`,
  iconButton: `${PREFIX}-iconButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.checkbox}`]: {
    height: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(24)
  },
  [`& .${classes.statusButton}`]: {
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyOrderHeader}`]: {
    color: matisse,
    width: theme.typography.pxToRem(195),
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`& .${classes.chip}`]: {
    color: baliHai,
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    minWidth: theme.typography.pxToRem(80),
    backgroundColor: solitude
  },
  [`& .${classes.chipDark}`]: {
    color: white,
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    minWidth: theme.typography.pxToRem(80),
    backgroundColor: baliHai
  },
  [`& .${classes.chipNotify}`]: {
    color: baliHai,
    fontWeight: "bold",
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    minWidth: theme.typography.pxToRem(80),
    backgroundColor: supernova
  },
  [`& .${classes.widerChip}`]: {
    minWidth: theme.typography.pxToRem(120)
  },
  [`& .${classes.iconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.badge}`]: {
    top: "75%",
    color: crusta
  }
}));
