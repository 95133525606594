import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogContentText
} from "@mui/material";

const paperProps = { square: true };

const CurrencySupplierDialog = props => {
  const { open, onOk } = props;

  return (
    <Dialog open={open} PaperProps={paperProps} maxWidth="md">
      <DialogContent>
        <DialogContentText noWrap={false}>
          When currency supplier is other than EUR the exchange rate supplier
          should contain the rate to convert that foreign currency to EUR,
          issued by GroupM Finance. When currency supplier is EUR the exchange
          rate supplier is always 1. Only titles can be selected which are
          available in the currency supplier. The values entered in the order
          should be in the currency that is selected in currency supplier.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Button onClick={onOk} color="secondary">
            Ok
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CurrencySupplierDialog;
