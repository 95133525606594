import {
  CLEAR_CREATE_PAGE,
  OPEN_CAMPAIGN_FORM_PAGE,
  CLOSE_CAMPAIGN_FORM_PAGE,
  OPEN_PLAN_FORM_PAGE,
  CLOSE_PLAN_FORM_PAGE,
  OPEN_ORDER_FORM_PAGE,
  CLOSE_ORDER_FORM_PAGE,
  SET_ORDER_BUYING_STATUSES,
  OPEN_CLIENT_STATUS_LOG_DIALOG,
  CLOSE_CLIENT_STATUS_LOG_DIALOG,
  OPEN_PROJECT_DIALOG,
  CLOSE_PROJECT_DIALOG,
  OPEN_ORDER_BUYING_STATUSES_DIALOG,
  CLOSE_ORDER_BUYING_STATUSES_DIALOG,
  OPEN_SET_ACTUAL_DIALOG,
  CLOSE_SET_ACTUAL_DIALOG,
  OPEN_PLAN_EXTENSION_FORM_PAGE,
  CLOSE_PLAN_EXTENSION_FORM_PAGE,
  CLOSE_SET_CAMPAIGN_ACTUAL_DIALOG,
  OPEN_SET_CAMPAIGN_ACTUAL_DIALOG,
  OPEN_PLAN_EXPORT_DIALOG,
  CLOSE_PLAN_EXPORT_DIALOG
} from "../constants/campaignDashboardConstants";
import { selectCurrentPlan } from "../models/Plan/planSelectors";
import generateUrl from "../models/common/utils/urlUtils";
import { LOAD_ORDER_BUYING_STATUSES } from "../../configurations/apiUrls";
import { GET } from "../constants/applicationConstants";
import { enqueueApiErrorMessage, apiCall } from "./applicationActions";
import {
  selectOrderBuyingStatusesPlanId,
  selectIsSetActualDialogOpen,
  selectIsSetCampaignActualDialogOpen
} from "../selectors/campaignDashboardSelector";
import { selectAgency } from "../selectors/applicationSelectors";

export function clearCreatePage() {
  return {
    type: CLEAR_CREATE_PAGE
  };
}

export function openCampaignFormPage() {
  return {
    type: OPEN_CAMPAIGN_FORM_PAGE
  };
}

export function closeCampaignFormPage() {
  return {
    type: CLOSE_CAMPAIGN_FORM_PAGE
  };
}

export function openPlanFormPage() {
  return {
    type: OPEN_PLAN_FORM_PAGE
  };
}

export function closePlanFormPage() {
  return {
    type: CLOSE_PLAN_FORM_PAGE
  };
}

export function openPlanExportDialog() {
  return {
    type: OPEN_PLAN_EXPORT_DIALOG
  };
}

export function closePlanExportDialog() {
  return {
    type: CLOSE_PLAN_EXPORT_DIALOG
  };
}

export function openOrderFormPage() {
  return {
    type: OPEN_ORDER_FORM_PAGE
  };
}

export function closeOrderFormPage() {
  return {
    type: CLOSE_ORDER_FORM_PAGE
  };
}

export function openPlanExtensionFormPage() {
  return {
    type: OPEN_PLAN_EXTENSION_FORM_PAGE
  };
}

export function closePlanExtensionFormPage() {
  return {
    type: CLOSE_PLAN_EXTENSION_FORM_PAGE
  };
}

export function loadOrderBuyingStatuses() {
  return (dispatch, getState) => {
    const state = getState();
    const currentPlan = selectCurrentPlan(state);
    const agency = selectAgency(state);
    const currentOrderBuyingStatusesPlanId = selectOrderBuyingStatusesPlanId(
      state
    );

    if (currentOrderBuyingStatusesPlanId === currentPlan) {
      return Promise.resolve();
    }

    const url = generateUrl(LOAD_ORDER_BUYING_STATUSES, {
      planId: currentPlan,
      agency
    });

    return dispatch(apiCall(GET, url)).then(
      response => {
        dispatch({ type: SET_ORDER_BUYING_STATUSES, payload: response });
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export const openClientStatusLogDialog = () => ({
  type: OPEN_CLIENT_STATUS_LOG_DIALOG
});

export const closeClientStatusLogDialog = () => ({
  type: CLOSE_CLIENT_STATUS_LOG_DIALOG
});

export const openProjectDialog = () => ({ type: OPEN_PROJECT_DIALOG });

export const closeProjectDialog = () => ({ type: CLOSE_PROJECT_DIALOG });

export const openOrderBuyingStatusesDialog = () => ({
  type: OPEN_ORDER_BUYING_STATUSES_DIALOG
});

export const closeOrderBuyingStatusesDialog = () => ({
  type: CLOSE_ORDER_BUYING_STATUSES_DIALOG
});

export const openSetActualDialog = () => ({
  type: OPEN_SET_ACTUAL_DIALOG
});

export const closeSetActualDialog = () => ({
  type: CLOSE_SET_ACTUAL_DIALOG
});

export const openSetCampaignActualDialog = () => ({
  type: OPEN_SET_CAMPAIGN_ACTUAL_DIALOG
});

export const closeSetCampaignActualDialog = () => ({
  type: CLOSE_SET_CAMPAIGN_ACTUAL_DIALOG
});

export const toggleSetCampaignActualDialog = () => (dispatch, getState) => {
  const state = getState();
  const isSetCampaignActualDialogOpen = selectIsSetCampaignActualDialogOpen(
    state
  );

  // eslint-disable-next-line no-unused-expressions
  isSetCampaignActualDialogOpen
    ? dispatch(closeSetCampaignActualDialog())
    : dispatch(openSetCampaignActualDialog());
};

export const toggleSetActualDialog = () => (dispatch, getState) => {
  const state = getState();
  const isSetActualDialogOpen = selectIsSetActualDialogOpen(state);

  // eslint-disable-next-line no-unused-expressions
  isSetActualDialogOpen
    ? dispatch(closeSetActualDialog())
    : dispatch(openSetActualDialog());
};
