import get from "lodash/get";
import set from "lodash/set";
import isBoolean from "lodash/isBoolean";
import isFinite from "lodash/isFinite";
import isArray from "lodash/isArray";
import reduce from "lodash/reduce";
import round from "lodash/round";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import { currencies } from "../../../constants/businessConstants";

const BOOL = "bool";
const NUMBER = "number";
const NUMBER_DIVIDER = "number_divider";
const NUMBER_MULTIPLIER = "number_multiplier";

const GROSS_TOTAL = "grosstotal";
const INSERTION_GROSS_TOTAL = "insertiongrosstotal";
const GROSS_PER_UNIT = "grossperunit";
const NET_PER_UNIT = "netperunit";
const INSERTION_NET_PER_UNIT = "insertionnetperunit";
const NET_NET_TOTAL = "netnettotal";
const INSERTION_NET_NET_TOTAL = "insertionnetnettotal";
const NET_NET_PER_UNIT = "netnetperunit";
const FEE = "fee";
const TOTAL_COST_CLIENT = "totalcostclient";

const SUM_INSERTION_NUM_OF_UNITS = "suminsertionnumofunits";
const SUM_INSERTION_DISCOUNT = "suminsertiondiscount";
const SUM_INSERTION_NET_TOTAL = "suminsertionnettotal";
const SUM_INSERTION_AGENCY_DISCOUNT = "suminsertionagencydiscount";
const SUM_INSERTION_SURCHARGE_1 = "suminsertionsurcharge1";
const SUM_INSERTION_SURCHARGE_2 = "suminsertionsurcharge2";
const SUM_INSERTION_NET_PER_UNIT = "suminsertionnetperunit";
const SUM_INSERTION_GROSS_TOTAL = "suminsertiongrosstotal";
const SUM_INSERTION_NET_NET_TOTAL = "suminsertionnetnettotal";
const SUM_ORDER_NET_TOTAL = "sumOrderNetTotal";
const ORDER_AMOUNT_PERCENTAGE_ON_PLAN = "orderAmountPercentageOnPlan";
const ORDER_AMOUNT_PERCENTAGE_ON_CAMPAIGN = "orderAmountPercentageOnCampaign";
const SUM_TOTAL_COST_CLIENT = "sumtotalcostclient";
const SUM_TOTAL_COST_CLIENT_EUR = "sumtotalcostclienteur";

// #region Core Calculator
export const sum = values =>
  round(
    reduce(values, (result, value) => result + value, 0),
    4
  );
const divide = (a, b) => {
  const retVal = round(a / b, 4);
  return isFinite(retVal) ? retVal : 0;
};
const percentageOf = (a, b) => {
  const retVal = round(a - a * (b / 100), 4);
  return isFinite(retVal) ? retVal : 0;
};
const percentage = (a, b) => {
  const retVal = round(a * (b / 100), 4);
  return isFinite(retVal) ? retVal : 0;
};
const percentageOfTwoNumbers = (a, b) => {
  let retVal = 0;
  if (b > 0 && a > 0) {
    retVal = round((100 * a) / b, 4);
  }
  return retVal;
};
const multiply = (a, b) => {
  const retVal = round(a * b, 4);
  return retVal;
};

const subtract = (result, param) => round(result - param, 4);

export const roundOnTwoDec = decimalNmbr =>
  ((decimalNmbr >= 0 || -1) * Math.round(Math.abs(decimalNmbr) * 100)) / 100;
// #endregion Core Calculator

// #region Helpers
const getValueOrDefault = (param, type) => {
  switch (type) {
    case BOOL:
      return isBoolean(param) ? param : false;
    case NUMBER:
      return isFinite(param) ? param : 0;
    case NUMBER_DIVIDER:
      return isFinite(param) ? param : 1;
    case NUMBER_MULTIPLIER:
      return isFinite(param) ? param : 1;
    default:
      return param;
  }
};

const multiplyObjectNumericProperties = (obj, multiplier) => {
  const multiplyedObj = {};
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === "number") {
      multiplyedObj[key] = multiply(obj[key], multiplier);
    }
  });

  return multiplyedObj;
};
// #endregion Helpers

const getSurchargeAmount = (value, discountApply, discount) => {
  let retVal = discountApply ? percentageOf(value, discount) : value;
  retVal = Math.round(retVal * 100) / 100;

  return isFinite(retVal) ? retVal : 0;
};

const getOrderCalculationBase = (type, netTotal, netNetTotal) => {
  switch (type) {
    case 2:
      return netTotal;
    case 4:
      return netNetTotal;
    default:
      return 0;
  }
};

const getSurchargeCalculationBase = (type, value, discountApply, discount) => {
  switch (type) {
    case 2:
      return value;
    case 4:
      return getSurchargeAmount(value, discountApply, discount);
    default:
      return 0;
  }
};

const getSurchargeFee = (
  type,
  feeApply,
  commissionApply,
  value,
  discount,
  feePercentage
) => {
  let retVal = 0;
  if (feeApply) {
    const calculationBase = getSurchargeCalculationBase(
      type,
      value,
      commissionApply,
      discount
    );
    retVal = percentage(calculationBase, feePercentage);
  }
  return retVal;
};

const getNestedObject = (nestedObj, pathArr) => {
  return reduce(
    pathArr,
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    nestedObj
  );
};

// Calculations
const calcGrossTotal = params => {
  const netTotal = getValueOrDefault(params.netTotal, NUMBER);
  const discount = getValueOrDefault(params.discountAmount, NUMBER);
  return sum([netTotal, discount]);
};

const calcInsertionGrossTotal = params => {
  const netTotal = getValueOrDefault(params.insertionNetAmount, NUMBER);
  const discount = getValueOrDefault(params.insertionDiscountAmount, NUMBER);
  return sum([netTotal, discount]);
};

const calcGrossPerUnit = params => {
  const grossTotal = calcGrossTotal(params);
  const numOfUnits = getValueOrDefault(params.numOfUnits, NUMBER_DIVIDER);
  const costTypeMultiplier = getValueOrDefault(
    params.costTypeMultiplier,
    NUMBER_MULTIPLIER
  );
  return divide(grossTotal, numOfUnits) * costTypeMultiplier;
};

const calcNetPerUnit = params => {
  const netTotal = getValueOrDefault(params.netTotal, NUMBER);
  const numOfUnits = getValueOrDefault(params.numOfUnits, NUMBER_DIVIDER);
  const costTypeMultiplier = getValueOrDefault(
    params.costTypeMultiplier,
    NUMBER_MULTIPLIER
  );
  return divide(netTotal, numOfUnits) * costTypeMultiplier;
};

const calcInsertionNetPerUnit = params => {
  const netTotal = getValueOrDefault(params.insertionNetAmount, NUMBER);
  const numOfUnits = getValueOrDefault(
    params.insertionNumOfUnits,
    NUMBER_DIVIDER
  );
  return divide(netTotal, numOfUnits);
};

const calcNetNetTotal = params => {
  const netTotal = getValueOrDefault(params.netTotal, NUMBER);
  const agencyDiscountAmount = getValueOrDefault(
    params.agencyDiscountAmount,
    NUMBER
  );
  return percentageOf(netTotal, agencyDiscountAmount);
};

const calcInsertionNetNetTotal = params => {
  const netTotal = getValueOrDefault(params.insertionNetAmount, NUMBER);
  const agencyDiscountAmount = getValueOrDefault(
    params.insertionAgencyDiscountAmount,
    NUMBER
  );
  return percentageOf(netTotal, agencyDiscountAmount);
};

const calcNetNetPerUnit = params => {
  const netNetTotal = calcNetNetTotal(params);
  const numOfUnits = getValueOrDefault(params.numOfUnits, NUMBER_DIVIDER);
  const costTypeMultiplier = getValueOrDefault(
    params.costTypeMultiplier,
    NUMBER_MULTIPLIER
  );
  return divide(netNetTotal, numOfUnits) * costTypeMultiplier;
};

const calcFee = params => {
  const feePercentage = getValueOrDefault(params.feePercentage, NUMBER);
  const agencyDiscountAmount = getValueOrDefault(
    params.agencyDiscountAmount,
    NUMBER
  );
  const circulationBaseType = params.circulationBase;
  const netTotal = getValueOrDefault(params.netTotal, NUMBER);
  const netNetTotal = calcNetNetPerUnit(params);
  const surcharge1Costs = getValueOrDefault(params.surcharge1Amount, NUMBER);
  const surcharge1CommisionApply = getValueOrDefault(
    params.surcharge1Commission,
    BOOL
  );
  const surcharge1FeeApply = getValueOrDefault(params.surcharge1Fee, BOOL);
  const surcharge2Costs = getValueOrDefault(params.surcharge2Amount, NUMBER);
  const surcharge2CommisionApply = getValueOrDefault(
    params.surcharge2Commission,
    BOOL
  );
  const surcharge2FeeApply = getValueOrDefault(params.surcharge2Fee, BOOL);

  const orderCalculationBase = getOrderCalculationBase(
    circulationBaseType,
    netTotal,
    netNetTotal
  );
  const orderFee = percentage(orderCalculationBase, feePercentage);

  const surcharge1Fee = getSurchargeFee(
    circulationBaseType,
    surcharge1FeeApply,
    surcharge1CommisionApply,
    surcharge1Costs,
    agencyDiscountAmount,
    feePercentage
  );

  const surcharge2Fee = getSurchargeFee(
    circulationBaseType,
    surcharge2FeeApply,
    surcharge2CommisionApply,
    surcharge2Costs,
    agencyDiscountAmount,
    feePercentage
  );
  const result = sum([orderFee, surcharge1Fee, surcharge2Fee]);
  return result;
};

// #region CTC
const calcTotalCostClientProperties = params => {
  const agencyDiscountAmount = getValueOrDefault(
    params.agencyDiscountAmount,
    NUMBER
  );
  const surcharge1Costs = getValueOrDefault(params.surcharge1Amount, NUMBER);
  const surcharge1CommisionApply = getValueOrDefault(
    params.surcharge1Commission,
    BOOL
  );
  const surcharge2Costs = getValueOrDefault(params.surcharge2Amount, NUMBER);
  const surcharge2CommisionApply = getValueOrDefault(
    params.surcharge2Commission,
    BOOL
  );
  const netNetTotal = calcNetNetPerUnit(params);
  const fee = calcFee(params);
  const surcharge1Amount = getSurchargeAmount(
    surcharge1Costs,
    surcharge1CommisionApply,
    agencyDiscountAmount
  );
  const surcharge2Amount = getSurchargeAmount(
    surcharge2Costs,
    surcharge2CommisionApply,
    agencyDiscountAmount
  );
  return { netNetTotal, fee, surcharge1Amount, surcharge2Amount };
};

const sumByCtcProperties = params => {
  let result;
  result = params.reduce((prevParam, param) => {
    return {
      netNetTotal: sum([prevParam.netNetTotal, param.netNetTotal]),
      fee: sum([prevParam.fee, param.fee]),
      surcharge1Amount: sum([
        prevParam.surcharge1Amount,
        param.surcharge1Amount
      ]),
      surcharge2Amount: sum([
        prevParam.surcharge2Amount,
        param.surcharge2Amount
      ])
    };
  });
  result = {
    ...result,
    totalCostClient: sum([
      result.netNetTotal,
      result.surcharge1Amount,
      result.surcharge2Amount,
      result.fee
    ])
  };
  return result;
};

const calcSumTotalCostClient = params => {
  let result;
  const { currencySupplierId: currency = currencies.EUR } =
    (!isEmpty(params) && params[0]) || {};

  if (!isEmpty(params) && currency !== currencies.EUR) {
    const results = params.map(param => {
      const ctc = calcTotalCostClientProperties(param);

      const { fee, netNetTotal } = ctc;
      ctc.fee = roundOnTwoDec(fee);
      ctc.netNetTotal = roundOnTwoDec(netNetTotal);

      return ctc;
    });
    result = sumByCtcProperties(results);
  }
  return result;
};

const calcSumTotalCostClientEur = params => {
  let result;
  if (!isEmpty(params)) {
    const results = params.map(param => {
      const exchangeRate = getValueOrDefault(
        param.exchangeRate,
        NUMBER_MULTIPLIER
      );
      const { currencySupplierId: currency = currencies.EUR } = param || {};
      const ctc = calcTotalCostClientProperties(param);

      return currency !== currencies.EUR
        ? multiplyObjectNumericProperties(ctc, exchangeRate)
        : ctc;
    });
    result = sumByCtcProperties(results);
  }
  return result;
};

const calcTotalCostClient = params => {
  const ctcProperties = calcTotalCostClientProperties(params);
  const arrayOfCtcPropValues = Object.keys(ctcProperties).map(
    key => ctcProperties[key]
  );
  return sum(arrayOfCtcPropValues);
};
// #endregion CTC

const calcSumInsertionNumOfUnits = paramsArray =>
  reduce(
    paramsArray,
    (result, params) =>
      subtract(result, getValueOrDefault(params.insertionNumOfUnits, NUMBER)),
    getValueOrDefault(getNestedObject(paramsArray, [0, "numOfUnits"]), NUMBER)
  );

const calcSumInsertionDiscount = paramsArray =>
  reduce(
    paramsArray,
    (result, params) =>
      subtract(
        result,
        getValueOrDefault(params.insertionDiscountAmount, NUMBER)
      ),
    getValueOrDefault(
      getNestedObject(paramsArray, [0, "discountAmount"]),
      NUMBER
    )
  );

const calcSumInsertionNetTotal = paramsArray =>
  reduce(
    paramsArray,
    (result, params) =>
      subtract(result, getValueOrDefault(params.insertionNetAmount, NUMBER)),
    getValueOrDefault(getNestedObject(paramsArray, [0, "netTotal"]), NUMBER)
  );

const calcSumInsertionAgencyDiscount = paramsArray =>
  reduce(
    paramsArray,
    (result, params) =>
      subtract(
        result,
        getValueOrDefault(params.insertionAgencyDiscountAmount, NUMBER)
      ),
    getValueOrDefault(
      getNestedObject(paramsArray, [0, "agencyDiscountAmount"]),
      NUMBER
    )
  );

const calcSumInsertionSurcharge1 = paramsArray =>
  reduce(
    paramsArray,
    (result, params) =>
      subtract(
        result,
        getValueOrDefault(params.insertionSurcharge1Amount, NUMBER)
      ),
    getValueOrDefault(
      getNestedObject(paramsArray, [0, "surcharge1Amount"]),
      NUMBER
    )
  );

const calcSumInsertionSurcharge2 = paramsArray =>
  reduce(
    paramsArray,
    (result, params) =>
      subtract(
        result,
        getValueOrDefault(params.insertionSurcharge2Amount, NUMBER)
      ),
    getValueOrDefault(
      getNestedObject(paramsArray, [0, "surcharge2Amount"]),
      NUMBER
    )
  );

// - workaround for bug fix regarding insertion header
const calcSumInsertionNetPerUnit = () => 0;

const calcSumInsertionGrossTotal = paramsArray =>
  reduce(
    paramsArray,
    (result, params) => subtract(result, calcInsertionGrossTotal(params)),
    getValueOrDefault(calcGrossTotal(paramsArray[0] || {}), NUMBER)
  );

const calcSumInsertionNetNetTotal = paramsArray =>
  reduce(
    paramsArray,
    (result, params) => subtract(result, calcInsertionNetNetTotal(params)),
    getValueOrDefault(calcNetNetTotal(paramsArray[0] || {}), NUMBER)
  );

const calcSumOrderNetTotal = paramsArray => {
  const values = paramsArray.map(el => getValueOrDefault(el.netTotal, NUMBER));
  return sum(values);
};

const calcOrderAmountPercentageOnPlan = paramsArray => {
  const { totalBudget, currencySupplierId = currencies.EUR } =
    last(paramsArray) || {};

  let ctc;
  if (currencySupplierId !== currencies.EUR) {
    ctc = calcSumTotalCostClient(paramsArray);
  } else {
    ctc = calcSumTotalCostClientEur(paramsArray);
  }

  const { totalCostClient } = ctc || {};
  const result = percentageOfTwoNumbers(totalCostClient, totalBudget);
  return result;
};

const calcOrderAmountPercentageOnCampaign = paramsArray => {
  const { poBudget, currencySupplierId = currencies.EUR } =
    last(paramsArray) || {};

  let ctc;
  if (currencySupplierId !== currencies.EUR) {
    ctc = calcSumTotalCostClient(paramsArray);
  } else {
    ctc = calcSumTotalCostClientEur(paramsArray);
  }

  const { totalCostClient } = ctc || {};
  const result = percentageOfTwoNumbers(totalCostClient, poBudget);
  return result;
};

// Executor
const execute = (method, params) => {
  switch (method) {
    case GROSS_TOTAL:
      return calcGrossTotal(params);
    case INSERTION_GROSS_TOTAL:
      return calcInsertionGrossTotal(params);
    case GROSS_PER_UNIT:
      return calcGrossPerUnit(params);
    case NET_PER_UNIT:
      return calcNetPerUnit(params);
    case INSERTION_NET_PER_UNIT:
      return calcInsertionNetPerUnit(params);
    case NET_NET_TOTAL:
      return calcNetNetTotal(params);
    case INSERTION_NET_NET_TOTAL:
      return calcInsertionNetNetTotal(params);
    case NET_NET_PER_UNIT:
      return calcNetNetPerUnit(params);
    case FEE:
      return calcFee(params);
    case TOTAL_COST_CLIENT:
      return calcTotalCostClient(params);
    case SUM_INSERTION_NUM_OF_UNITS:
      return calcSumInsertionNumOfUnits(params);
    case SUM_INSERTION_DISCOUNT:
      return calcSumInsertionDiscount(params);
    case SUM_INSERTION_NET_TOTAL:
      return calcSumInsertionNetTotal(params);
    case SUM_INSERTION_AGENCY_DISCOUNT:
      return calcSumInsertionAgencyDiscount(params);
    case SUM_INSERTION_SURCHARGE_1:
      return calcSumInsertionSurcharge1(params);
    case SUM_INSERTION_SURCHARGE_2:
      return calcSumInsertionSurcharge2(params);
    case SUM_INSERTION_NET_PER_UNIT:
      return calcSumInsertionNetPerUnit(params);
    case SUM_INSERTION_GROSS_TOTAL:
      return calcSumInsertionGrossTotal(params);
    case SUM_INSERTION_NET_NET_TOTAL:
      return calcSumInsertionNetNetTotal(params);
    case SUM_ORDER_NET_TOTAL:
      return calcSumOrderNetTotal(params);
    case ORDER_AMOUNT_PERCENTAGE_ON_PLAN:
      return calcOrderAmountPercentageOnPlan(params);
    case ORDER_AMOUNT_PERCENTAGE_ON_CAMPAIGN:
      return calcOrderAmountPercentageOnCampaign(params);
    case SUM_TOTAL_COST_CLIENT:
      return calcSumTotalCostClient(params);
    case SUM_TOTAL_COST_CLIENT_EUR:
      return calcSumTotalCostClientEur(params);
    default:
      return undefined;
  }
};

const extractData = (params, data) =>
  reduce(
    params,
    (result, propertyName) =>
      set(result, propertyName, get(data, propertyName)),
    {}
  );

const extractArrayData = (params, data) =>
  reduce(data, (result, row) => [...result, extractData(params, row)], []);

export const calculateProperty = (propertyCalculation, data) => {
  const extracted = isArray(data)
    ? extractArrayData(propertyCalculation.calcParams, data)
    : extractData(propertyCalculation.calcParams, data);
  return execute(propertyCalculation.calcMethod, extracted);
};

const calculate = (config, data) => {
  return reduce(
    config,
    (calculation, propertyCalculation, propertyName) =>
      set(
        calculation,
        propertyName,
        calculateProperty(propertyCalculation, data)
      ),
    {}
  );
};

export default calculate;
