import {
  styled,
  DialogContentText as MuiDialogContentText
} from "@mui/material";

const PREFIX = "DeletePublicPermissionDialogStyles";
export const classes = {
  root: `${PREFIX}-root`
};

export const DialogContentText = styled(MuiDialogContentText)(({ theme }) => ({
  [`&.${classes.root}`]: {
    paddingTop: "15px"
  }
}));
