import { styled } from "@mui/material";

const PREFIX = "CampaignFormStyles";
export const classes = {
  root: `${PREFIX}-root`
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: "0 auto",
    width: theme.typography.pxToRem(600)
  }
}));
