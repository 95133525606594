import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import Select from "react-select";

import {
  DialogContent,
  Button,
  DialogActions,
  DialogContentText
} from "@mui/material";

import { selectOpenTrafficExportVersionDialog } from "../../../../business/selectors/applicationSelectors";
import {
  resetExportVersions,
  downloadExportVersion,
  setTrafficExportVersion,
  clearTrafficExportVersion
} from "../../../../business/models/TrafficPlan/trafficPlanActions";
import {
  selectExportVersions,
  selectTrafficExportSelectedVersion
} from "../../../../business/models/TrafficPlan/trafficPlanSelectors";
import { closeTrafficExportVersionDialog } from "../../../../business/actions/applicationActions";

import { classes, Dialog } from "../styles/dialog";

const TrafficExportVersionDialog = ({
  open,
  versions,
  value,
  clearVersions,
  downloadVersion,
  closeDialog,
  setSelectedVersion,
  clearSelectedVersion
}) => {
  const handleClose = () => {
    closeDialog();
    clearVersions();
    clearSelectedVersion();
  };

  const handleChange = version => {
    setSelectedVersion(version);
  };

  const handleSubmit = () => {
    downloadVersion();
    closeDialog();
    clearVersions();
    clearSelectedVersion();
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialog }}
      maxWidth="md"
      fullWidth
    >
      <DialogContent classes={{ root: classes.root }}>
        <DialogContentText className={classes.title}>
          Select traffic export version:
        </DialogContentText>
        <Select
          id="select_export-version"
          name="select_export-version"
          options={versions}
          onChange={handleChange}
          className={classes.option}
        />
      </DialogContent>
      <DialogActions>
        <Button
          id="close_export-version"
          name="close_export-version"
          onClick={handleClose}
          color="primary"
        >
          Close
        </Button>
        <Button
          id="download_export-version"
          name="download_export-version"
          onClick={handleSubmit}
          disabled={!value || Object.keys(value).length === 0}
          color="secondary"
        >
          Download version
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TrafficExportVersionDialog.propTypes = {
  open: PropTypes.bool,
  options: PropTypes.array,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  value: PropTypes.object
};

const mapStateToProps = state => {
  return {
    versions: selectExportVersions(state),
    open: selectOpenTrafficExportVersionDialog(state),
    value: selectTrafficExportSelectedVersion(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearVersions: () => dispatch(resetExportVersions()),
    downloadVersion: () => dispatch(downloadExportVersion()),
    closeDialog: () => dispatch(closeTrafficExportVersionDialog()),
    setSelectedVersion: version => dispatch(setTrafficExportVersion(version)),
    clearSelectedVersion: () => dispatch(clearTrafficExportVersion())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrafficExportVersionDialog);
