export const CAMPAIGN_SELECT = "CAMPAIGN_SELECT";
export const CAMPAIGN_EDIT_START = "CAMPAIGN_EDIT_START";
export const CAMPAIGN_EDIT_STOP = "CAMPAIGN_EDIT_STOP";
export const CAMPAIGN_CLEAR_CURRENT = "CAMPAIGN_CLEAR_CURRENT";
export const CAMPAIGN_DISPLAY_ERRORS = "CAMPAIGN_DISPLAY_ERRORS";
export const CAMPAIGN_HIDE_ERRORS = "CAMPAIGN_HIDE_ERRORS";
export const CAMPAIGN_SET_IS_NEW = "CAMPAIGN_SET_IS_NEW";
export const CAMPAIGN_RESET_IS_NEW = "CAMPAIGN_RESET_IS_NEW";
export const SET_CLIENT_CREDIT_MESSAGE = "SET_CLIENT_CREDIT_MESSAGE";
export const RESET_CLIENT_CREDIT_MESSAGE = "RESET_CLIENT_CREDIT_MESSAGE";

export const CAMPAIGN_SUCCESS_MESSAGE = "Campaign saved successfully";
export const CAMPAIGN_SET_ACTUAL_MESSAGE = "Actual was saved successfully";

export const CAMPAIGN_MODEL_NAME = "Campaign";
export const CAMPAIGN_MODEL_ID = "campaignId";
export const CAMPAIGN_SEARCH_ID = "campaignSearchId";

export const CAMPAIGN_PROCESS_MANAGER_ROLE_ID = 3;
