import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";

import { useLocation } from "react-router-dom";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import {
  campaignSaveSearch,
  editCampaignSaveSearch
} from "../../../../business/actions/overviewPageActions";
import { EMPTY_STRING } from "../../../../configurations/appConstants";
import {
  SAVE_SEARCH_NAME_VALIDATION_ERROR_MESSAGE,
  defaultOptionForSaveSearch,
  SAVE_SEARCH_DIALOG,
  SAVE_AS_SEARCH_DIALOG
} from "../../../../business/constants/overviewPageConstants";
import { classes, Grid } from "../styles/saveSearchDialogStyle";

const SaveSearchDialog = ({
  searchN,
  searchId,
  onRefetch,
  onOpenDialog,
  open,
  isSaveAs,
  setSaveAs,
  setQueryParams
}) => {
  const savedSearchName = searchN === "None" ? EMPTY_STRING : searchN;
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchName, setSearchName] = useState(savedSearchName);
  const [hasError, setHasError] = useState(false);

  const isEdit = searchId !== defaultOptionForSaveSearch.value;
  const saveAs = !isEdit || (isEdit && isSaveAs);

  const handleClose = () => {
    onOpenDialog(false);
    setSearchName(EMPTY_STRING);
    setSaveAs(false);
  };

  const handleSave = () => {
    const trimSearhName = searchName.trim();
    if (
      trimSearhName.length === 0 ||
      trimSearhName === EMPTY_STRING ||
      trimSearhName === defaultOptionForSaveSearch.label ||
      (trimSearhName === searchN && isEdit && isSaveAs) ||
      trimSearhName.length > 100
    ) {
      setHasError(true);
      return;
    }
    const searchParams = new URLSearchParams(location.search);

    searchParams.delete("currentPage");
    searchParams.delete("pageSize");
    searchParams.delete("sort");

    const value = `?${searchParams.toString()}`;
    if (saveAs) {
      dispatch(campaignSaveSearch(value, searchName)).then(
        response =>
          response?.isSuccessful &&
          ((onRefetch(true),
          onOpenDialog(false),
          setSearchName(EMPTY_STRING),
          setSaveAs(false)),
          setQueryParams(EMPTY_STRING))
      );
    } else {
      dispatch(editCampaignSaveSearch(value, searchName, searchId)).then(
        response =>
          response?.isSuccessful &&
          ((onRefetch(true),
          onOpenDialog(false),
          setSearchName(EMPTY_STRING),
          setSaveAs(false)),
          setQueryParams(EMPTY_STRING))
      );
    }
  };

  const handleChange = e => {
    setSearchName(e.target.value);
  };

  return (
    <Dialog
      aria-labelledby="search-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="save_search-dialog-title">
        {!saveAs ? SAVE_SEARCH_DIALOG : SAVE_AS_SEARCH_DIALOG}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} className={classes.root}>
            <TextField
              id="add_SearchName"
              name="add_SearchName"
              placeholder="Please enter search name"
              value={searchName}
              onChange={handleChange}
              error={hasError}
              helperText={hasError && SAVE_SEARCH_NAME_VALIDATION_ERROR_MESSAGE}
              fullWidth
              size="medium"
              variant="outlined"
              disabled={isEdit && !isSaveAs}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveSearchDialog;
