import { styled } from "@mui/material";
import {
  azureRadiance,
  baliHai,
  bittersweet,
  black,
  crusta,
  deYork,
  dodgerBlue,
  dodgerBlue20,
  jordyBlue,
  lilac,
  linkWater,
  matisse,
  sapphire,
  softRed,
  solitude,
  supernova,
  white
} from "./colors";

const PREFIX = "IconStyles";
export const classes = {
  size: `${PREFIX}-size`,
  font12: `${PREFIX}-font12`,
  size24: `${PREFIX}-size24`,
  size20: `${PREFIX}-size20`,
  size32: `${PREFIX}-size32`,
  size48: `${PREFIX}-size48`,
  circle: `${PREFIX}-circle`,
  agencyLogoIcon: `${PREFIX}-agencyLogoIcon`,
  typographyIcon: `${PREFIX}-typographyIcon`,
  activeTypographyIcon: `${PREFIX}-activeTypographyIcon`,
  blueIcon: `${PREFIX}-blueIcon`,
  yellowIcon: `${PREFIX}-yellowIcon`,
  lightBlueIcon: `${PREFIX}-lightBlueIcon`,
  greenIcon: `${PREFIX}-greenIcon`,
  grayIcon: `${PREFIX}-grayIcon`,
  redIcon: `${PREFIX}-redIcon`,
  orangeIcon: `${PREFIX}-orangeIcon`,
  purpleIcon: `${PREFIX}-purpleIcon`,
  progressIcon: `${PREFIX}-progressIcon`,
  changeIcon: `${PREFIX}-changeIcon`,
  changeNotApprovedIcon: `${PREFIX}-changeNotApprovedIcon`,
  font18: `${PREFIX}-font18`,
  font14: `${PREFIX}-font14`,
  disabled: `${PREFIX}-disabled`,
  colorLilac: `${PREFIX}-colorLilac`,
  colorDeYork: `${PREFIX}-colorDeYork`,
  colorJordyBlue: `${PREFIX}-colorJordyBlue`,
  colorCrusta: `${PREFIX}-colorCrusta`,
  colorSupernova: `${PREFIX}-colorSupernova`,
  colorDodgerBlue: `${PREFIX}-colorDodgerBlue`,
  colorDodgerBlue20: `${PREFIX}-colorDodgerBlue20`,
  colorSapphire: `${PREFIX}-colorSapphire`,
  colorMatisse: `${PREFIX}-colorMatisse`,
  colorBaliHai: `${PREFIX}-colorBaliHai`,
  colorLinkWater: `${PREFIX}-colorLinkWater`,
  colorSolitude: `${PREFIX}-colorSolitude`,
  colorWhite: `${PREFIX}-colorWhite`,
  colorAzureRadiance: `${PREFIX}-colorAzureRadiance`,
  colorSoftRed: `${PREFIX}-colorSoftRed`,
  colorBittersweet: `${PREFIX}-colorBittersweet`,
  colorBlack: `${PREFIX}-colorBlack`
};

export const getStyledIcon = icon =>
  styled(icon)(({ theme }) => ({
    [`&.${classes.font12}`]: {
      width: theme.typography.pxToRem(18),
      height: theme.typography.pxToRem(18)
    },
    [`&.${classes.size24}`]: {
      width: theme.typography.pxToRem(18),
      height: theme.typography.pxToRem(18)
    },
    [`&.${classes.size20}`]: {
      width: theme.typography.pxToRem(18),
      height: theme.typography.pxToRem(18)
    },
    [`&.${classes.size32}`]: {
      width: theme.typography.pxToRem(32),
      height: theme.typography.pxToRem(32)
    },
    [`&.${classes.size48}`]: {
      width: theme.typography.pxToRem(48),
      height: theme.typography.pxToRem(48)
    },
    [`&.${classes.circle}`]: {
      borderRadius: "50%"
    },
    [`&.${classes.agencyLogoIcon}`]: {
      height: theme.typography.pxToRem(48),
      display: "block",
      marginLeft: "auto",
      marginRight: "auto"
    },
    [`&.${classes.typographyIcon}`]: {
      color: baliHai
    },
    [`&.${classes.activeTypographyIcon}`]: {
      color: matisse
    },
    [`&.${classes.blueIcon}`]: {
      color: azureRadiance
    },
    [`&.${classes.yellowIcon}`]: {
      color: supernova
    },
    [`&.${classes.lightBlueIcon}`]: {
      color: jordyBlue
    },
    [`&.${classes.greenIcon}`]: {
      color: deYork
    },
    [`&.${classes.grayIcon}`]: {
      color: linkWater
    },
    [`&.${classes.redIcon}`]: {
      color: crusta
    },
    [`&.${classes.orangeIcon}`]: {
      color: supernova
    },
    [`&.${classes.purpleIcon}`]: {
      color: lilac
    },
    [`&.${classes.progressIcon}`]: {
      backgroundColor: jordyBlue,
      color: white
    },
    [`&.${classes.changeIcon}`]: {
      backgroundColor: lilac,
      color: white
    },
    [`&.${classes.changeNotApprovedIcon}`]: {
      width: theme.typography.pxToRem(18),
      height: theme.typography.pxToRem(18),
      backgroundColor: crusta,
      color: white
    },
    [`&.${classes.font18}`]: {
      fontSize: theme.typography.pxToRem(18)
    },
    [`&.${classes.font14}`]: {
      fontSize: theme.typography.pxToRem(14)
    },
    [`&.${classes.disabled}`]: {
      color: linkWater
    },
    [`&.${classes.colorLilac}`]: {
      color: lilac
    },
    [`&.${classes.colorDeYork}`]: {
      color: deYork
    },
    [`&.${classes.colorJordyBlue}`]: {
      color: jordyBlue
    },
    [`&.${classes.colorCrusta}`]: {
      color: crusta
    },
    [`&.${classes.colorSupernova}`]: {
      color: supernova
    },
    [`&.${classes.colorDodgerBlue}`]: {
      color: dodgerBlue
    },
    [`&.${classes.colorDodgerBlue20}`]: {
      color: dodgerBlue20
    },
    [`&.${classes.colorSapphire}`]: {
      color: sapphire
    },
    [`&.${classes.colorMatisse}`]: {
      color: matisse
    },
    [`&.${classes.colorBaliHai}`]: {
      color: baliHai
    },
    [`&.${classes.colorLinkWater}`]: {
      color: linkWater
    },
    [`&.${classes.colorSolitude}`]: {
      color: solitude
    },
    [`&.${classes.colorWhite}`]: {
      color: white
    },
    [`&.${classes.colorAzureRadiance}`]: {
      color: azureRadiance
    },
    [`&.${classes.colorSoftRed}`]: {
      color: softRed
    },
    [`&.${classes.colorBittersweet}`]: {
      color: bittersweet
    },
    [`&.${classes.colorBlack}`]: {
      color: black
    }
  }));

export const getStyledSidebarIcon = icon =>
  styled(icon)(({ theme }) => ({
    [`&.${classes.size}`]: {
      width: theme.typography.pxToRem(18),
      height: theme.typography.pxToRem(18)
    }
  }));
