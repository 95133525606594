export const PROJECT_SELECT = "PROJECT_SELECT";
export const PROJECT_EDIT_START = "PROJECT_EDIT_START";
export const PROJECT_EDIT_STOP = "PROJECT_EDIT_STOP";
export const PROJECT_CLEAR_CURRENT = "PROJECT_CLEAR_CURRENT";
export const PROJECT_DISPLAY_ERRORS = "PROJECT_DISPLAY_ERRORS";
export const PROJECT_HIDE_ERRORS = "PROJECT_HIDE_ERRORS";
export const PROJECT_SET_IS_NEW = "PROJECT_SET_IS_NEW";
export const PROJECT_RESET_IS_NEW = "PROJECT_RESET_IS_NEW";
export const PROJECT_RESET_CURRENT = "PROJECT_RESET_CURRENT";

export const PROJECT_SUCCESS_MESSAGE = "Project saved successfully";

export const PROJECT_MODEL_NAME = "Project";
export const PROJECT_MODEL_ID = "projectId";
export const PROJECT_PROPERTY = "projects";
