import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Typography, Tooltip } from "@mui/material";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import { EMPTY_STRING } from "../../../../configurations/appConstants";
import SystemMessageField from "../../../form/SystemMessageField";
import {
  selectHasAttachment,
  selectFileName
} from "../../../../business/models/SystemMessage/systemMessageSelectors";
import { classes, Grid } from "../styles/uploadDialog";

const SystemMessageUpload = () => {
  const isLoading = useSelector(selectIsLoading);
  const hasAttachment = useSelector(selectHasAttachment);
  const fileName = useSelector(selectFileName);

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={0}
      alignItems="center"
    >
      <Grid item>
        <Tooltip title={fileName || ""}>
          <Typography
            color={hasAttachment ? "default" : "error"}
            className={classes.typographyFileName}
          >
            Attachment: {fileName}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item>
        <SystemMessageField
          component="uploadButton"
          name="file"
          id="file"
          label={hasAttachment ? "replace" : "upload"}
          isLoading={isLoading}
          mime="application/pdf"
        />
      </Grid>
    </Grid>
  );
};

SystemMessageUpload.propTypes = {
  isLoading: PropTypes.bool,
  fileName: PropTypes.string,
  hasAttachment: PropTypes.bool
};

SystemMessageUpload.defaultProps = {
  fileName: EMPTY_STRING,
  hasAttachment: false,
  isLoading: false
};

export default SystemMessageUpload;
