import {
  styled,
  Paper as MuiPaper,
  Grid as MuiGrid,
  Toolbar as MuiToolbar
} from "@mui/material";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "UserProfilePageStyles";
export const classes = {
  paper: `${PREFIX}-paper`,
  typographyHeaderCaption: `${PREFIX}-typographyHeaderCaption`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  typographySettingsHeaderCaption: `${PREFIX}-typographySettingsHeaderCaption`,
  switchAll: `${PREFIX}-switchAll`,
  overviewPanel: `${PREFIX}-overviewPanel`,
  list: `${PREFIX}-list`
};

export const Paper = styled(MuiPaper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    padding: theme.typography.pxToRem(getThemeSpacing(theme, 3))
  },
  [`& .${classes.typographyHeaderCaption}`]: {
    fontSize: theme.typography.pxToRem(30),
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.typographyCaption}`]: {
    fontSize: theme.typography.pxToRem(20),
    paddingTop: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.typographySettingsHeaderCaption}`]: {
    fontSize: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.switchAll}`]: {
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 3))
  }
}));
