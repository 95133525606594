import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { Button } from "@mui/material";
import { generateUrlPath } from "../../../../business/models/common/utils/clientUtils";
import { clearPlan } from "../../../../business/models/Plan/planActions";
import { clearOrder } from "../../../../business/models/Order/orderActions";
import {
  openPlanFormPage,
  openOrderFormPage
} from "../../../../business/actions/campaignDashboardActions";
import {
  canViewCampaignAction,
  canEditCampaign
} from "../../../../business/selectors/userProfileSelectors";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import {
  isSelected,
  selectCurrentPlan,
  selectCopyProcessFromPlanData
} from "../../../../business/models/Plan/planSelectors";
import {
  selectCurrentCampaign,
  getCampaignData
} from "../../../../business/models/Campaign/campaignSelectors";
import { hasOrdersWithStatus2or6or32 } from "../../../../business/models/Order/orderSelectors";
import { selectInsertionIsEditing } from "../../../../business/models/Insertion/insertionSelectors";
import {
  CAMPAIGN,
  CREATE_PAGE,
  ACTION_PAGE
} from "../../../../configurations/appConstants";
import { classes, Grid } from "../styles/createActions";

const PageActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agency } = useParams();

  const campaignId = useSelector(selectCurrentCampaign);
  const planId = useSelector(selectCurrentPlan);
  const canViewCampaignDetailsAction = useSelector(canViewCampaignAction);
  const hasOrdersWithStatus32or6or2 = useSelector(hasOrdersWithStatus2or6or32);
  const isLoading = useSelector(selectIsLoading);
  const isPlanSelected = useSelector(isSelected);
  const isEditing = useSelector(selectInsertionIsEditing);
  const disableAction = !useSelector(canEditCampaign);
  const isPlanInCopyProcess = useSelector(selectCopyProcessFromPlanData);
  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const handleAddPlan = () => {
    dispatch(clearPlan());
    navigate(`/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${campaignId}`);
    dispatch(openPlanFormPage());
  };

  const handleAddOrder = () => {
    dispatch(clearOrder());
    navigate(`/${agency}/${CREATE_PAGE}${generateUrlPath(campaignId, planId)}`);
    dispatch(openOrderFormPage());
  };

  const goToActionDetailsPage = () => {
    if (canViewCampaignDetailsAction && hasOrdersWithStatus32or6or2) {
      navigate(`/${agency}/${ACTION_PAGE}/${CAMPAIGN}/${campaignId}`);
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        {canViewCampaignDetailsAction && isDebtorActive && (
          <Grid item>
            <Button
              id="redirect_action-dashboard"
              name="redirect_action-dashboard"
              className={classes.redirectButton}
              disabled={
                isLoading ||
                isEditing ||
                !hasOrdersWithStatus32or6or2 ||
                disableAction
              }
              onClick={goToActionDetailsPage}
            >
              GO TO ACTIONS PAGE
            </Button>
          </Grid>
        )}
        {isDebtorActive && (
          <Grid item>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  id="add_plan"
                  name="add_plan"
                  variant="contained"
                  className={clsx(
                    classes.saveButton,
                    classes.addPlanButton,
                    classes.buttonSpace
                  )}
                  disabled={isLoading || isEditing || disableAction}
                  onClick={handleAddPlan}
                >
                  ADD PLAN
                </Button>
              </Grid>
              <Grid item>
                <Button
                  id="add_order"
                  name="add_order"
                  variant="contained"
                  className={clsx(classes.saveButton, classes.addOrderButton)}
                  disabled={
                    isLoading ||
                    !isPlanSelected ||
                    isEditing ||
                    disableAction ||
                    isPlanInCopyProcess
                  }
                  onClick={handleAddOrder}
                >
                  ADD ORDER
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PageActions;
