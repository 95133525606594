import { styled } from "@mui/material";

const PREFIX = "ActionTableClearAllStyle";
export const classes = {
  button: `${PREFIX}-button`
};

export const Div = styled("div")(() => ({
  [`& .${classes.button}`]: {
    color: "#000000de"
  }
}));
