import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import { selectActiveStep } from "../../../../business/selectors/copyPlanSelectors";
import { createCampaignCopyPlan } from "../../../../business/models/Campaign/campaignActions";
import { stepsEnum } from "../../../../business/constants/copyPlanConstants";
import {
  isStepOptional,
  isLastStep,
  isCancelButtonVisiable,
  isPauseButtonVisiable
} from "../../../../business/helpers/copyPlanHelpers";
import {
  createPlanCopyPlan,
  cancelCopyPlan
} from "../../../../business/models/Plan/planActions";
import { isCampaignInExistingCampaignCopyPlanProcess } from "../../../../business/models/Campaign/campaignSelectors";
import {
  createOrderCopyPlan,
  skipOrderCopyPlan
} from "../../../../business/models/Order/orderActions";
import { createPlanExtensionCopyPlan } from "../../../../business/models/PlanExtension/planExtensionActions";
import { selectAgencyId } from "../../../../business/models/common/utils/appUtils";
import CopyPlanStepper from "../copyPlan/CopyPlanStepper";
import { classes, Grid } from "../styles/stepperActions";

const StepperActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agency } = useParams();
  const agencyId = selectAgencyId(agency);

  const activeStep = useSelector(selectActiveStep);
  const loading = useSelector(selectIsLoading);
  const existingCampaign = useSelector(
    isCampaignInExistingCampaignCopyPlanProcess
  );

  // TODO: move this outside of StepperActions for reusability
  const isCampaignStep = activeStep === stepsEnum.CAMPAIGN;
  const isPlanStep = activeStep === stepsEnum.PLAN;
  const isOrderStep = activeStep === stepsEnum.ORDER;
  const isPlanExtensionStep = activeStep === stepsEnum.PLAN_EXTENSION;

  const handleNext = e => {
    const { id } = e.currentTarget;

    if (id === "pause_process") {
      navigate(`/${agency}/copy-plan-wizard`);
      return;
    }

    if (isCampaignStep) {
      dispatch(createCampaignCopyPlan());
    }

    if (isPlanStep) {
      dispatch(createPlanCopyPlan());
    }

    if (isPlanExtensionStep) {
      dispatch(createPlanExtensionCopyPlan());
    }

    if (isOrderStep) {
      dispatch(createOrderCopyPlan());
    }
  };

  const handleSkip = () => {
    dispatch(skipOrderCopyPlan());
  };

  const handleCancel = () => {
    dispatch(cancelCopyPlan(agencyId));
    navigate(`/${agency}/copy-plan-wizard`);
  };

  const handleFinish = () => {
    navigate(`/${agency}/copy-plan-wizard`);
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      {isCancelButtonVisiable(activeStep) ? (
        <Button
          className={classes.buttonCancel}
          disabled={loading}
          onClick={handleCancel}
          variant="contained"
          name="cancel_copy-plan-wizard"
          id="cancel_copy-plan-wizard"
        >
          Cancel
        </Button>
      ) : (
        <div />
      )}
      <Grid item xs={9}>
        <CopyPlanStepper />
      </Grid>
      <div>
        {isStepOptional(activeStep) && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSkip}
            disabled={loading}
            className={classes.button}
            name="skip_step"
            id="skip_step"
          >
            Skip
          </Button>
        )}
        {isPauseButtonVisiable(activeStep) && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
            disabled={loading}
            name="pause_process"
            id="pause_process"
          >
            Pause
          </Button>
        )}
        {isLastStep(activeStep) ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleFinish}
            disabled={loading}
            name="finish_step"
            id="finish_step"
          >
            Finish
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled={loading || existingCampaign}
            color="primary"
            onClick={handleNext}
            name="next_step"
            id="next_step"
          >
            {isPlanStep ? "Proceed to Additional Plan Data" : "Next"}
          </Button>
        )}
      </div>
    </Grid>
  );
};

export default StepperActions;
