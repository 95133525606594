import { styled, Button as MuiButton, Box } from "@mui/material";

import { crusta } from "../../../../assets/styles/colors";
import ChangeOrderStatusDialogItem from "../popup/order-status/ChangeOrderStatusDialogItem";

const PREFIX = "ChangeOrderStatusDialogStyles";
export const classes = {
  closeButton: `${PREFIX}-closeButton`
};

export const Button = styled(MuiButton)(() => ({
  [`&.${classes.closeButton}`]: {
    color: crusta
  }
}));

export const ChangeOrderStatusDialogItemStyled = props => {
  const { id, key, order } = props;

  return (
    <Box sx={{ pt: 1 }}>
      <ChangeOrderStatusDialogItem id={id} key={key} order={order}>
        Save
      </ChangeOrderStatusDialogItem>
    </Box>
  );
};
