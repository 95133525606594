import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes, { number } from "prop-types";

import isFunction from "lodash/isFunction";

import CtcButtonBase from "./CtcButtonBase";
import { TYPE_BUTTON, TYPE_ICON } from "../../../configurations/appConstants";
import { currencies } from "../../../business/constants/businessConstants";
import { getPlanById } from "../../../business/models/Plan/planSelectors";

const CtcButtonPlan = ({
  type,
  clientTotalCostEur,
  clientTotalCost,
  fetchData,
  currencySupplier,
  loading,
  showCtc,
  id,
  name
}) => {
  const planId = id.substr(id.lastIndexOf("_") + 1) ?? 0;
  const planData = useSelector(state => getPlanById(state, Number(planId)));
  const currencyClient = planData?.currencyClient ?? "";
  const exchangeRateClient = planData?.exchangeRateClient ?? 1;
  const exchangeRateSupplier = planData?.exchangeRate ?? 1;
  const [openTooltip, setTooltip] = useState(false);

  const handleTooltipClose = e => {
    e.stopPropagation();
    setTooltip(false);
  };

  const handleTooltipOpenAndFetchData = e => {
    e.stopPropagation();
    if (isFunction(fetchData)) {
      fetchData().then(() => {
        setTooltip(true);
      });
    } else {
      setTooltip(true);
    }
  };
  return (
    <CtcButtonBase
      id={id}
      name={name}
      clientTotalCostEur={clientTotalCostEur}
      clientTotalCost={clientTotalCost}
      currencyClient={currencyClient}
      currencySupplier={currencySupplier}
      onOpen={handleTooltipOpenAndFetchData}
      onClose={handleTooltipClose}
      open={openTooltip}
      type={type}
      showCtc={showCtc}
      loading={loading}
      exchangeRateClient={exchangeRateClient}
      exchangeRateSupplier={exchangeRateSupplier}
    />
  );
};

CtcButtonPlan.propTypes = {
  clientTotalCost: PropTypes.object,
  clientTotalCostEur: PropTypes.object,
  fetchData: PropTypes.func,
  type: PropTypes.oneOf([TYPE_BUTTON, TYPE_ICON]),
  showCtc: PropTypes.bool,
  currencySupplier: PropTypes.oneOf([...Object.keys(currencies)]),
  id: PropTypes.string,
  name: PropTypes.string
};

CtcButtonPlan.defaultProps = {
  type: TYPE_BUTTON,
  hasMultipleCurrencies: false,
  showCtc: true
};

export default CtcButtonPlan;
