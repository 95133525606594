import React, { memo } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import { useSelector } from "react-redux";

import { styled, Toolbar } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

import { classes, AppBar, Offset } from "../styles/appBarWrapper";
import {
  selectOpenDrawer,
  selectIsLoading
} from "../../../../business/selectors/applicationSelectors";

const AppBarWrapper = ({ bottom, children }) => {
  const open = useSelector(selectOpenDrawer);
  const isLoading = useSelector(selectIsLoading);
  return (
    <>
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
          [classes.appBarBottom]: bottom
        })}
        elevation={0}
        position="fixed"
      >
        {isLoading && bottom && (
          <div>
            <LinearProgress />
          </div>
        )}
        <Toolbar className={classes.appToolbar} disableGutters>
          {children}
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  );
};

AppBarWrapper.propTypes = {
  bottom: PropTypes.bool,
  children: PropTypes.node
};

AppBarWrapper.defaultProps = {
  bottom: false
};

export default memo(AppBarWrapper);
