/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import {
  AccordionSummary,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Link,
  Badge
} from "@mui/material";

import ClipboardTooltip from "../../../shared/ClipboardTooltip";
import {
  AgencyLogoIcon,
  MediaTypeSearchIcon,
  TrafficPlanAssignedIcon
} from "../../../../assets/styles/icons";
import { parseDate } from "../../../../business/models/common/utils/clientUtils";
import { classes, Accordion } from "../styles/trafficOverviewTrafficPlanPanel";
import { PLACEHOLDER } from "../../../../configurations/appConstants";
import { isNullOrUndefined } from "../../../../functions/util";
import withRouter from "../../configuration/form/withRouter";

class TrafficOverviewTrafficPlanPanel extends Component {
  onOpenClick = event => {
    event.stopPropagation();
    const { onOpenClick, trafficPlan } = this.props;
    const { planId } = trafficPlan;

    onOpenClick(planId);
  };

  handleCopy = event => {
    event.stopPropagation();
  };

  render() {
    const {
      trafficPlan,
      handleAssignToMe,
      username,
      areActionsDisabled,
      handleEditTraffickerDialogOpen
    } = this.props;

    const {
      planName,
      trafficPlanId,
      trafficPlanStatus,
      debtorInternalName: debtor,
      attention,
      sum,
      agency,
      hasUnreadRemarks,
      trafficker,
      mediaType,
      firstOrderStartDate,
      processManager,
      hasTrafficItemInTrafficBeforeBuyingStatus,
      hasTrafficItemInChangedStatus,
      planner,
      marathonPlanId
    } = trafficPlan;

    const attentionClassName = clsx(classes.fontSize18, {
      [classes.redColor]: hasTrafficItemInTrafficBeforeBuyingStatus,
      [classes.orangeColor]:
        hasTrafficItemInChangedStatus &&
        !hasTrafficItemInTrafficBeforeBuyingStatus
    });

    const { agency: agencyFromUrl } = this.props.params;

    const preventDefault = event => {
      event.preventDefault();
    };
    const tiId = trafficPlan.trafficPlanId;
    const tpId = trafficPlan.planId;
    return (
      <>
        <Accordion square elevation={0} expanded={false}>
          <AccordionSummary>
            <Grid container justifyContent="space-between">
              <Grid item xs={2} sm={2} md={2} lg={2} xl={3}>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Grid item className={classes.trafficOverviewAgencyIconField}>
                    <AgencyLogoIcon agency={agency} />
                  </Grid>
                  <Grid
                    item
                    zeroMinWidth
                    className={classes.trafficOverviewPlanNameField}
                    id={`debtor_${tpId}`}
                    name={`debtor_${tpId}`}
                  >
                    <Typography
                      noWrap
                      className={classes.typographyHeaderCaption}
                    >
                      {debtor}
                    </Typography>
                    <Tooltip
                      title={
                        <ClipboardTooltip
                          onButtonClick={this.handleCopy}
                          text={planName}
                          propertyId="planName"
                          entityId={tpId}
                        />
                      }
                      placement="bottom-start"
                    >
                      <Typography
                        onClick={this.onOpenClick}
                        className={clsx(
                          classes.typographyHeaderValue,
                          classes.typographyLink
                        )}
                        noWrap
                      >
                        <Link
                          href={`/${agencyFromUrl}/traffic/${tpId}/${tiId}`}
                          onClick={preventDefault}
                          id={`planName_redirect_${tpId}`}
                          name={`planeName_redirect_${tpId}`}
                          className={classes.link}
                          underline="none"
                          color="inherit"
                        >
                          {planName}
                        </Link>
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={9}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item className={classes.trafficOverviewMediaTypeField}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <MediaTypeSearchIcon type={mediaType} />
                      </Grid>
                      <Grid item>
                        <Typography
                          id={`mediaType_${tpId}`}
                          name={`mediaType_${tpId}`}
                        >
                          {mediaType.toUpperCase()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    className={classes.trafficOverviewAssignedIconField}
                  >
                    <IconButton
                      id={`assigntoMe_${tpId}`}
                      name={`assignToMe_${tpId}`}
                      onClick={() =>
                        handleAssignToMe(trafficPlanId, username === trafficker)
                      }
                      className={classes.iconButton}
                      disabled={areActionsDisabled}
                    >
                      <TrafficPlanAssignedIcon
                        assigned={username === trafficker}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item className={classes.trafficOverviewEmailField}>
                    <Tooltip
                      title={trafficker || "Unassigned"}
                      id={`show_trafficer_${tpId}`}
                      name={`show_trafficer_${tpId}`}
                    >
                      <Typography
                        align="right"
                        className={classes.cssTraffic}
                        noWrap
                        id={`trafficer_${tpId}`}
                        name={`trafficer_${tpId}`}
                        onClick={() =>
                          handleEditTraffickerDialogOpen(
                            trafficPlanId,
                            trafficker
                          )
                        }
                      >
                        {trafficker || "Unassigned"}
                      </Typography>
                    </Tooltip>
                    <Typography
                      align="right"
                      className={classes.typographyCaption}
                    >
                      Trafficker
                    </Typography>
                  </Grid>
                  <Grid item className={classes.trafficOverviewStatusField}>
                    <Typography
                      align="right"
                      className={classes.cssTraffic}
                      id={`trafficPlanStatus_${tpId}`}
                      name={`trafficPlanStatus_${tpId}`}
                    >
                      {trafficPlanStatus}
                    </Typography>
                    <Typography
                      align="right"
                      className={classes.typographyCaption}
                    >
                      Status
                    </Typography>
                  </Grid>
                  <Grid item className={classes.trafficOverviewDateField}>
                    <Typography
                      align="right"
                      className={classes.cssTraffic}
                      id={`orderStartDate_${tpId}`}
                      name={`orderStartDate_${tpId}`}
                    >
                      {!isNullOrUndefined(firstOrderStartDate)
                        ? parseDate(firstOrderStartDate)
                        : PLACEHOLDER}
                    </Typography>
                    <Typography
                      align="right"
                      className={classes.typographyCaption}
                    >
                      1st Order start date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    align="right"
                    className={classes.trafficOverviewCounterField}
                  >
                    <Badge
                      variant="dot"
                      invisible={!hasUnreadRemarks}
                      color="secondary"
                    >
                      <Typography
                        id={`attention_${tpId}`}
                        name={`attention_${tpId}`}
                        className={attentionClassName}
                      >
                        {attention}
                      </Typography>
                      <Typography
                        id={`sum_${tpId}`}
                        name={`sum_${tpId}`}
                        className={classes.cssTraffic}
                      >
                        /{sum}
                      </Typography>
                    </Badge>
                    <Typography
                      align="right"
                      className={classes.typographyCaption}
                      id={`mediaType_count_${tpId}`}
                      name={`mediaType_count_${tpId}`}
                    >
                      {mediaType}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.trafficOverviewCounterField}>
                    <Typography
                      align="right"
                      id={`marathonPlanId_${tpId}`}
                      name={`marathonPlanId_${tpId}`}
                      className={classes.cssTraffic}
                    >
                      {marathonPlanId}
                    </Typography>
                    <Typography
                      align="right"
                      className={classes.typographyCaption}
                    >
                      Plan Id
                    </Typography>
                  </Grid>
                  <Grid item className={classes.trafficOverviewEmailField}>
                    <Tooltip title={processManager}>
                      <Typography
                        align="right"
                        className={classes.cssTraffic}
                        noWrap
                        id={`processManager_${tpId}`}
                        name={`processManager_${tpId}`}
                      >
                        {processManager}
                      </Typography>
                    </Tooltip>
                    <Typography
                      align="right"
                      className={classes.typographyCaption}
                    >
                      Process Manager
                    </Typography>
                  </Grid>
                  <Grid item className={classes.trafficOverviewEmailField}>
                    <Tooltip title={planner}>
                      <Typography
                        align="right"
                        className={classes.cssTraffic}
                        id={`planner_${tpId}`}
                        name={`planner_${tpId}`}
                        noWrap
                      >
                        {planner}
                      </Typography>
                    </Tooltip>
                    <Typography
                      align="right"
                      className={classes.typographyCaption}
                    >
                      Planner
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      </>
    );
  }
}

TrafficOverviewTrafficPlanPanel.propTypes = {
  trafficPlan: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  onOpenClick: PropTypes.func
};

export default withRouter(TrafficOverviewTrafficPlanPanel);
