import React from "react";

import clsx from "clsx";
import { connect } from "react-redux";

import { Button } from "@mui/material";

import {
  openDialog,
  approvedOrdersListEmptyError,
  clearMultipleEditSelections
} from "../../../../business/models/TrafficPlan/trafficPlanActions";
import {
  selectIsLoading,
  selectTrafficItemActionSwitch
} from "../../../../business/selectors/applicationSelectors";
import {
  isSelected,
  selectAnyTrafficItemSelected,
  selectAreAnyTrafficItemsForMultipleEditSelected,
  doesTrafficPlanExist,
  isLoadedOrdersListEmpty,
  selectPlanIdByTrafficPlanId,
  isTrafficInEditMode,
  selectCanGoToDashboardPage,
  getSelectedTraffic
} from "../../../../business/models/TrafficPlan/trafficPlanSelectors";
import {
  openAddTrafficDialog,
  openAddAllTrafficItemsDialog,
  toggleTrafficItemAction,
  openTrafficItemMultipleEditDialog
} from "../../../../business/actions/applicationActions";
import {
  canViewCampaignOverview,
  selectCreateTrafficItemsForTrafficPlan,
  canViewTrafficManagementPage
} from "../../../../business/selectors/userProfileSelectors";
import {
  CREATE_PAGE,
  CAMPAIGN,
  PLAN,
  ORDER,
  EMPTY_STRING
} from "../../../../configurations/appConstants";
import {
  selectOrderIdByTrafficItemId,
  isTrafficItemInEditMode
} from "../../../../business/models/TrafficItem/trafficItemSelectors";
import { editTrafficItemMultiple } from "../../../../business/models/TrafficItem/trafficItemActions";
import { selectCurrentCampaign } from "../../../../business/models/Campaign/campaignSelectors";
import { isNullOrUndefinedOrEmpty } from "../../../../functions/util";
import { classes, Grid, Switch } from "../styles/trafficDashboardActions";
import withRouter from "../../configuration/form/withRouter";

const TrafficPageActions = ({
  openStatusDialog,
  openAddTraffic,
  openAddAllTrafficItems,
  toggleActionSwitch,
  canViewCampaignOverviewDetails,
  canViewAddAllTrafficItemsButton,
  params,
  navigate,
  isLoading,
  isTrafficPlanSelected,
  isTrafficItemSelected,
  switchChecked,
  loadedOrdersEmpty,
  trafficPlanCreated,
  isTrafficItemEdit,
  isTrafficPlanEdit,
  showErrorMessageForEmptyApprovedOrdersList,
  openMultipleEditDialog,
  areAnyTrafficItemsForMultipleEditSelected,
  edit,
  campaignId,
  planId,
  orderId,
  clearMultipleSelections,
  canGoToDashboard,
  isDebtorActive
}) => {
  // const canViewManageDigitalTrafficTemplates = false;

  const handleChangeTrafficItemStatus = () => {
    openStatusDialog();
  };

  const handleAddTrafficItem = () => {
    if (loadedOrdersEmpty) {
      showErrorMessageForEmptyApprovedOrdersList();
      return;
    }

    openAddTraffic();
  };

  const handleAddAllTrafficItems = () => {
    openAddAllTrafficItems();
  };

  const goToOverviewDetailsPage = () => {
    const agencyValue = params.agency;
    // TODO: check this if it's valid
    if (canViewCampaignOverviewDetails) {
      const plan = isNullOrUndefinedOrEmpty(planId)
        ? EMPTY_STRING
        : `/${PLAN}/${planId}`;
      const order = isNullOrUndefinedOrEmpty(orderId)
        ? EMPTY_STRING
        : `/${ORDER}/${orderId}`;
      const urlParams = `${CAMPAIGN}/${campaignId}${plan}${order}`;
      navigate(`/${agencyValue}/${CREATE_PAGE}/${urlParams}`);
    }
  };

  const handleMultipleEdit = () => {
    edit();
    openMultipleEditDialog();
  };

  const handleSwitchChange = () => {
    clearMultipleSelections();
    toggleActionSwitch();
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        {canViewCampaignOverviewDetails && (
          <Button
            id="redirect_campaign_dashboard"
            name="redirect_campaign_dashboard"
            className={classes.redirectButton}
            disabled={isLoading || !canGoToDashboard}
            onClick={goToOverviewDetailsPage}
          >
            GO TO DASHBOARD PAGE
          </Button>
        )}
        {/* {canViewManageDigitalTrafficTemplates && (
          <Button
            id="redirect_manage_digital_traffic_template"
            name="redirect_manage_digital_traffic_template"
            className={classes.redirectButton}
            disabled={isLoading}
            onClick={goToManageDigitalTrafficTemplatePage}
          >
            MANAGE DIGITAL TRAFFIC TEMPLATES
          </Button>
        )} */}
      </Grid>
      {isDebtorActive && (
        <Grid item>
          <div className={classes.changeSection}>
            <Switch
              id="toggle_actions"
              name="toggle_actions"
              disabled={isLoading || isTrafficItemEdit || isTrafficPlanEdit}
              color={switchChecked ? "primary" : "default"}
              checked={switchChecked}
              onChange={handleSwitchChange}
            />
            {!switchChecked && (
              <Button
                variant="contained"
                id="change_status"
                name="change_status"
                className={clsx(
                  classes.buttonSave,
                  classes.colorSuperNova,
                  classes.fixedSize
                )}
                disabled={
                  isLoading ||
                  !isTrafficPlanSelected ||
                  !isTrafficItemSelected ||
                  !trafficPlanCreated ||
                  isTrafficItemEdit ||
                  isTrafficPlanEdit
                }
                onClick={handleChangeTrafficItemStatus}
              >
                CHANGE STATUS
              </Button>
            )}
            {switchChecked && (
              <Button
                variant="contained"
                id="edit_multiple"
                name="edit_multiple"
                color="primary"
                className={clsx(classes.buttonSave, classes.fixedSize)}
                disabled={
                  isLoading ||
                  !isTrafficPlanSelected ||
                  !areAnyTrafficItemsForMultipleEditSelected ||
                  isTrafficItemEdit ||
                  isTrafficPlanEdit
                }
                onClick={handleMultipleEdit}
              >
                EDIT MULTIPLE
              </Button>
            )}
          </div>
          {canViewAddAllTrafficItemsButton && (
            <Button
              variant="contained"
              id="add_items"
              name="add_items"
              className={clsx(
                classes.buttonSave,
                classes.buttonSpace,
                classes.buttonApply
              )}
              disabled={
                isLoading ||
                !isTrafficPlanSelected ||
                isTrafficItemEdit ||
                isTrafficPlanEdit
              }
              onClick={handleAddAllTrafficItems}
            >
              ADD ALL ITEMS
            </Button>
          )}
          <Button
            variant="contained"
            id="add_item"
            name="add_item"
            className={clsx(
              classes.buttonSave,
              classes.buttonSpace,
              classes.colorSuperNova
            )}
            disabled={
              isLoading ||
              !isTrafficPlanSelected ||
              isTrafficItemEdit ||
              isTrafficPlanEdit
            }
            onClick={handleAddTrafficItem}
          >
            ADD ITEM
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  const { isDebtorActive } = getSelectedTraffic(state) || false;
  return {
    isLoading: selectIsLoading(state),
    isTrafficPlanSelected: isSelected(state),
    isTrafficItemSelected: selectAnyTrafficItemSelected(state),
    canViewCampaignOverviewDetails: canViewCampaignOverview(state),
    loadedOrdersEmpty: isLoadedOrdersListEmpty(state),
    isTrafficItemEdit: isTrafficItemInEditMode(state),
    isTrafficPlanEdit: isTrafficInEditMode(state),
    switchChecked: selectTrafficItemActionSwitch(state),
    trafficPlanCreated: doesTrafficPlanExist(state),
    areAnyTrafficItemsForMultipleEditSelected: selectAreAnyTrafficItemsForMultipleEditSelected(
      state
    ),
    campaignId: selectCurrentCampaign(state),
    planId: selectPlanIdByTrafficPlanId(state),
    orderId: selectOrderIdByTrafficItemId(state),
    canViewAddAllTrafficItemsButton: selectCreateTrafficItemsForTrafficPlan(
      state
    ),
    canViewManageDigitalTrafficTemplate: canViewTrafficManagementPage(state),
    canGoToDashboard: selectCanGoToDashboardPage(state),
    isDebtorActive
  };
};

const mapDispatchToProps = dispatch => ({
  showErrorMessageForEmptyApprovedOrdersList: () =>
    dispatch(approvedOrdersListEmptyError()),
  openStatusDialog: () => dispatch(openDialog()),
  openAddTraffic: () => dispatch(openAddTrafficDialog()),
  openAddAllTrafficItems: () => dispatch(openAddAllTrafficItemsDialog()),
  toggleActionSwitch: () => dispatch(toggleTrafficItemAction()),
  openMultipleEditDialog: () => dispatch(openTrafficItemMultipleEditDialog()),
  edit: () => dispatch(editTrafficItemMultiple()),
  clearMultipleSelections: () => dispatch(clearMultipleEditSelections())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrafficPageActions)
);
