import React, { useState, memo } from "react";
import { Info } from "@mui/icons-material";

import { classes, IconButton } from "../styles/infoButton";
import InfoDialog from "./InfoDialog";

const InfoButton = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <IconButton
        id="info"
        name="info"
        alt="InfoButton"
        onClick={handleToggle}
        aria-owns={open ? "legend" : undefined}
        aria-haspopup="true"
        className={classes.button}
      >
        <Info />
      </IconButton>
      <InfoDialog open={open} onBackdropClick={handleToggle} />
    </>
  );
};

export default memo(InfoButton);
