import React from "react";

import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { agencyRoutes, routes } from "../../../configurations/routes";
import { selectAgency } from "../../../business/selectors/applicationSelectors";

const Main = () => {
  const agencyID = useSelector(selectAgency);
  return (
    <>
      <Routes>
        {agencyID &&
          agencyRoutes.map(({ path, element }, key) => (
            <Route key={key} path={path} element={element} />
          ))}
        {routes.map(({ path, element }, key) => (
          <Route key={key} path={path} element={element} />
        ))}
      </Routes>
    </>
  );
};

export default Main;
