import {
  createSelector,
  createSelectorCreator,
  defaultMemoize
} from "reselect";
import createCachedSelector from "re-reselect";

import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

import { getEntitiesSession } from "../common/entities/entitySelectors";
import {
  selectCurrentTrafficPlanId,
  selectSelectedTrafficItems,
  selectSelectedMultipleEditTrafficItems,
  selectTrafficPlanId,
  selectSelectedTrafficPlanMediaType
} from "../TrafficPlan/trafficPlanSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";
import { selectAgency } from "../../selectors/applicationSelectors";
import TrafficItemModel from "./TrafficItem";
import { getOptions, getText } from "../common/utils/modelUtils";
import {
  EMPTY_STRING,
  MEDIATYPE_DIGITAL,
  MEDIATYPE_INVENTORY
} from "../../../configurations/appConstants";
import { TRAFFIC_ITEM_MODEL_ID } from "./trafficItemConstants";

export const selectTrafficItem = state => state.trafficItem;
export const selectPropertyName = (_, props) => props.name;
export const selectPropertyId = (_, props) => props.id;
export const selectTrafficItemId = (_, { trafficItemId }) => trafficItemId;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getTrafficItems = createDeepEqualSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entities, currentTrafficPlanId) => {
    const { TrafficItem } = entities;
    return TrafficItem.filter(
      ti => ti.trafficPlan === currentTrafficPlanId
    ).toRefArray();
  }
);

export const getTrafficItemModel = createCachedSelector(
  [getEntitiesSession, selectTrafficItemId],
  (entities, trafficItemId) => {
    const { TrafficItem } = entities;
    const trafficItemModel = TrafficItem.withId(trafficItemId);
    return trafficItemModel || undefined;
  }
)((_, { trafficItemId }) => trafficItemId);

export const getDimensionsText = createSelector(
  [getTrafficItemModel, getApiData, selectAgency],
  (trafficItemModelInstance, apiData, agencyID) => {
    const { apiConfiguration: configuration } = TrafficItemModel;
    const trafficItem = trafficItemModelInstance
      ? trafficItemModelInstance.ref
      : {};
    return getText(
      trafficItem.dimensionsId,
      configuration.dimensionsId,
      trafficItem,
      apiData,
      agencyID
    );
  }
);

export const getDigitalFileText = createSelector(
  [getTrafficItemModel, getApiData, selectAgency],
  (trafficItemModelInstance, apiData, agencyID) => {
    const { apiConfiguration: configuration } = TrafficItemModel;
    const trafficItem = trafficItemModelInstance
      ? trafficItemModelInstance.ref
      : undefined;
    return getText(
      trafficItem.digitalFileId,
      configuration.digitalFileId,
      trafficItem,
      apiData,
      agencyID
    );
  }
);

export const getMaterialHostedByText = createSelector(
  [getTrafficItemModel, getApiData, selectAgency],
  (trafficItemModelInstance, apiData, agencyID) => {
    const { apiConfiguration: configuration } = TrafficItemModel;
    const trafficItem = trafficItemModelInstance
      ? trafficItemModelInstance.ref
      : undefined;
    return getText(
      trafficItem.materialHostedById,
      configuration.materialHostedById,
      trafficItem,
      apiData,
      agencyID
    );
  }
);

export const getAdServerText = createSelector(
  [getTrafficItemModel, getApiData, selectAgency],
  (trafficItemModelInstance, apiData, agencyID) => {
    const { apiConfiguration: configuration } = TrafficItemModel;
    const trafficItem = trafficItemModelInstance
      ? trafficItemModelInstance.ref
      : undefined;
    return getText(
      trafficItem.adServerId,
      configuration.adServerId,
      trafficItem,
      apiData,
      agencyID
    );
  }
);

export const getTrafficItemsForChangeStatus = createSelector(
  [getEntitiesSession, selectSelectedTrafficItems, getApiData, selectAgency],
  (entitiesSession, selectedTrafficItemsForChangeStatus, apiData, agencyID) => {
    const { TrafficItem } = entitiesSession;
    const configuration = TrafficItemModel.apiConfiguration;
    return TrafficItem.filter(trafficItem =>
      selectedTrafficItemsForChangeStatus.includes(trafficItem.trafficItemId)
    )
      .toModelArray()
      .map(trafficItem => {
        const entity = {
          ...trafficItem.trafficPlan.ref,
          ...trafficItem.ref
        };
        return {
          ...entity,
          adServer: getText(
            trafficItem.ref.adServerId,
            configuration.adServerId,
            entity,
            apiData,
            agencyID
          ),
          digitalFile: getText(
            trafficItem.ref.digitalFileId,
            configuration.digitalFileId,
            entity,
            apiData,
            agencyID
          ),
          dimensions: getText(
            trafficItem.ref.dimensionsId,
            configuration.dimensionsId,
            entity,
            apiData,
            agencyID
          ),
          materialHostedBy: getText(
            trafficItem.ref.materialHostedById,
            configuration.materialHostedById,
            entity,
            apiData,
            agencyID
          )
        };
      });
  }
);

export const selectCurrentTrafficItem = createSelector(
  selectTrafficItem,
  trafficItem => trafficItem.currentTrafficItem
);

export const selectIsCurrentTrafficItemVirtual = createSelector(
  [getEntitiesSession, selectCurrentTrafficItem],
  (entities, trafficItemId) => {
    const { TrafficItem } = entities;
    return trafficItemId ? !TrafficItem.idExists(trafficItemId) : false;
  }
);

export const selectIsEditingTrafficItem = createSelector(
  selectTrafficItem,
  trafficItem => trafficItem.isEditing
);
export const selectIsCopyingTrafficItem = createSelector(
  selectTrafficItem,
  trafficItem => trafficItem.isCopying
);
export const isTrafficItemInEditMode = createSelector(
  [selectTrafficItem],
  trafficItem => {
    return trafficItem.isNew || trafficItem.isEditing;
  }
);

export const isTrafficItemDigital = createSelector(
  [selectTrafficItem],
  trafficItem => {
    return trafficItem
      ? trafficItem.mediaTypeId === MEDIATYPE_DIGITAL ||
          trafficItem.mediaTypeId === MEDIATYPE_INVENTORY
      : false;
  }
);

export const selectTrafficItemDigitalTemplateId = createSelector(
  [selectTrafficItem],
  trafficItem => trafficItem.trafficDigitalTemplateId
);

export const selectTrafficItemDisplayErrors = createSelector(
  selectTrafficItem,
  trafficItem => trafficItem.displayErrors
);

export const getEditingTrafficItem = createSelector(
  [
    getEditingEntitiesSession,
    selectCurrentTrafficItem,
    selectCurrentTrafficPlanId
  ],
  (editingSession, trafficItemId, trafficPlanId) => {
    const { TrafficItem } = editingSession;
    const trafficItemModel = TrafficItem.get(c => c.id === trafficItemId);
    return trafficItemModel
      ? {
          ...trafficItemModel.ref,
          trafficPlanId
        }
      : undefined;
  }
);

export const getEditingTrafficItemMultiple = createSelector(
  [getEditingEntitiesSession, selectCurrentTrafficItem],
  (editingSession, trafficItemId) => {
    const { TrafficItem } = editingSession;
    const trafficItemModel = TrafficItem.get(ti => ti.id === trafficItemId);
    return trafficItemModel
      ? {
          ...trafficItemModel.ref
        }
      : undefined;
  }
);

// TODO: check this selector if is valid
export const getTrafficItem = createSelector(
  [getEntitiesSession, selectCurrentTrafficItem],
  (entitiesSession, trafficItemId) => {
    const { TrafficItem } = entitiesSession;
    const trafficItemModel = TrafficItem.get(c => c.id === trafficItemId);

    return trafficItemModel ? trafficItemModel.ref : undefined;
  }
);

export const doesModelHaveErrors = createSelector(
  [getEditingEntitiesSession, selectCurrentTrafficItem],
  (editingSession, trafficItemId) => {
    const { TrafficItem } = editingSession;
    const trafficItemModel = TrafficItem.get(c => c.id === trafficItemId);
    const { mediaTypeId } = trafficItemModel || EMPTY_STRING;
    return trafficItemModel ? trafficItemModel.hasErrors(mediaTypeId) : false;
  }
);

export const doesModelForAgentMultipleEditHaveErrors = createSelector(
  [getEditingEntitiesSession, selectCurrentTrafficItem],
  (editingSession, trafficItemId) => {
    const { TrafficItem } = editingSession;
    const trafficItemModel = TrafficItem.get(c => c.id === trafficItemId);
    const { mediaTypeId } = trafficItemModel || EMPTY_STRING;
    return trafficItemModel
      ? trafficItemModel.hasErrorsForAgentMultipleEdit(mediaTypeId)
      : false;
  }
);

export const getTrafficItemPropertyOptions = createCachedSelector(
  [getApiData, getEditingTrafficItem, selectPropertyName, selectAgency],
  (apiData, trafficItem, name, agencyID) => {
    const configuration = TrafficItemModel.apiConfiguration[name];

    return configuration
      ? getOptions(configuration, apiData, trafficItem, agencyID)
      : undefined;
  }
)((_, props) => props.name);

export const getTrafficItemViewPropertyValue = createCachedSelector(
  [getEntitiesSession, selectPropertyName, selectCurrentTrafficItem],
  (entitiesSession, name, trafficItemId) => {
    const { TrafficItem } = entitiesSession;
    const trafficItem = TrafficItem.get(p => p.id === trafficItemId);
    return trafficItem ? trafficItem.ref[name] : undefined;
  }
)((_, props) => props.name);

export const getTrafficItemPropertyValue = createCachedSelector(
  [getEditingTrafficItem, selectPropertyName],
  (trafficItem, name) => (trafficItem ? trafficItem[name] : undefined)
)((_, props) => props.name);

export const getTrafficItemPropertyPreviousValue = createCachedSelector(
  [getTrafficItem, selectPropertyName],
  (trafficItem, name) => (trafficItem ? trafficItem[name] : undefined)
)((_, props) => props.name);

export const getTrafficItemPropertyErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectCurrentTrafficItem, selectPropertyName],
  (editingSession, trafficItemId, name) => {
    const { TrafficItem } = editingSession;
    const trafficItemModel = TrafficItem.get(c => c.id === trafficItemId);
    const { mediaTypeId } = trafficItemModel || EMPTY_STRING;
    return trafficItemModel
      ? trafficItemModel.validateByProperty(mediaTypeId, name)
      : undefined;
  }
)((_, props) => props.name);

export const getEntitiesTrafficItem = createSelector(
  [getEntitiesSession, selectPropertyId],
  (entitySession, id) => {
    const { TrafficItem } = entitySession;
    const trafficItemModel = TrafficItem.get(c => c.id === id);

    return trafficItemModel;
  }
);

export const getTrafficItemPropertySelectValue = createCachedSelector(
  [getEntitiesTrafficItem, selectPropertyName],
  (trafficItem, name) => (trafficItem ? trafficItem[name] : undefined)
)((_, props) => props.name);

export const getTrafficItemStatusText = createSelector(
  [getEntitiesSession, selectPropertyId],
  (entitySession, id) => {
    const { TrafficItem } = entitySession;
    const trafficItemModel = TrafficItem.get(c => c.id === id);
    if (trafficItemModel) {
      const { trafficItemStatusText } = trafficItemModel;
      return trafficItemStatusText;
    }
  }
);

export const getTrafficItemById = createSelector(
  [getEntitiesSession, selectCurrentTrafficItem],
  (entitiesSession, trafficItemId) => {
    const { TrafficItem } = entitiesSession;
    const trafficItemModel = TrafficItem.get(c => c.id === trafficItemId);

    return trafficItemModel ? trafficItemModel.ref : undefined;
  }
);

export const doesTrafficItemExistById = createSelector(
  [getEntitiesSession, selectTrafficPlanId, selectTrafficItemId],
  (entitiesSession, trafficPlanId, trafficItemId) => {
    const { TrafficItem } = entitiesSession;
    return TrafficItem.exists(
      i => i.id === trafficItemId && i.trafficPlan === trafficPlanId
    );
  }
);

export const isTrafficItemOrderApproved = createSelector(
  [getEntitiesSession, selectPropertyId],
  (entitySession, id) => {
    const { TrafficItem } = entitySession;
    const trafficItemModel = TrafficItem.get(c => c.id === id);

    return trafficItemModel
      ? trafficItemModel.ref.orderClientStatusId === 3
      : false;
  }
);

export const trafficItemsHasNonApprovedOrders = createSelector(
  [selectSelectedTrafficItems, getTrafficItems],
  (selectedTrafficItemList, fullTrafficItems) => {
    const trafficItems = fullTrafficItems.filter(
      e =>
        selectedTrafficItemList.includes(e.trafficItemId) &&
        e.orderClientStatusId !== 3
    );

    return trafficItems ? trafficItems.length > 0 : false;
  }
);

export const getMultipleEditData = createSelector(
  [getEditingTrafficItemMultiple, selectSelectedMultipleEditTrafficItems],
  (editingTrafficItemMultiple, trafficItemIds) => {
    const returnObj = omit(
      editingTrafficItemMultiple,
      "id",
      TRAFFIC_ITEM_MODEL_ID
    );
    return { ...returnObj, trafficItemIds };
  }
);

export const selectIsTrafficItemSelected = createSelector(
  [selectSelectedTrafficItems, selectTrafficItemId],
  (selectedTrafficItemList, trafficItemId) => {
    return selectedTrafficItemList.indexOf(trafficItemId) >= 0;
  }
);

export const selectIsTrafficItemSelectableForStatusChange = createSelector(
  [getEntitiesSession, selectSelectedTrafficPlanMediaType, selectTrafficItemId],
  (entitiesSession, selectedTrafficPlanMediaType, trafficItemId) => {
    const { TrafficItem } = entitiesSession;
    const trafficItem = TrafficItem.get(ti => ti.id === trafficItemId);
    const { isPreliminary, mediaTypeId } = trafficItem || {};

    return (
      !isPreliminary &&
      (!selectedTrafficPlanMediaType ||
        mediaTypeId === selectedTrafficPlanMediaType)
    );
  }
);

export const selectIsTrafficItemSelectable = createSelector(
  [getEntitiesSession, selectSelectedTrafficPlanMediaType, selectTrafficItemId],
  (entitiesSession, selectedMediaTypeId, trafficItemId) => {
    const { TrafficItem } = entitiesSession;
    const trafficItem = TrafficItem.get(ti => ti.id === trafficItemId);
    const { mediaTypeId } = trafficItem || {};

    return !selectedMediaTypeId || mediaTypeId === selectedMediaTypeId;
  }
);

export const selectIsMultipleEditTrafficItemSelected = createCachedSelector(
  [selectSelectedMultipleEditTrafficItems, selectTrafficItemId],
  (selectedMultipleEditTrafficItems, trafficItemId) => {
    return selectedMultipleEditTrafficItems.indexOf(trafficItemId) >= 0;
  }
)((_, { trafficItemId }) => trafficItemId);

export const selectOrderIdByTrafficItemId = createSelector(
  [getEntitiesSession, selectCurrentTrafficItem],
  (entitiesSession, trafficItemId) => {
    const { TrafficItem } = entitiesSession;
    const trafficItem = TrafficItem.get(ti => ti.id === trafficItemId);
    return trafficItem ? trafficItem.orderId : undefined;
  }
);

export const multipleSelectTrafficItemsHasPreliminaryItem = createSelector(
  [getEntitiesSession, selectSelectedMultipleEditTrafficItems],
  (entitiesSession, selectedMultipleEditTrafficItems) => {
    const { TrafficItem } = entitiesSession;
    const preliminaryTrafficItems = TrafficItem.filter(
      e =>
        selectedMultipleEditTrafficItems.includes(e.trafficItemId) &&
        e.isPreliminary
    );

    return preliminaryTrafficItems
      ? preliminaryTrafficItems.count() > 0
      : false;
  }
);
