import React from "react";
import PropTypes from "prop-types";

import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid
} from "@mui/material";

import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import Scrollbar from "../../../../../../shared/Scrollbar";

import {
  classes,
  DialogContent
} from "../../../../styles/trafficExportPreviewTable";
import {
  PLACEHOLDER,
  EMPTY_TABLE
} from "../../../../../../../configurations/appConstants";

const PreviewDigital = ({ data }) => {
  const {
    paperRoot,
    tableCellUnderline,
    scrollTableCell,
    tableCellHeader,
    tableCellHeaderInner
  } = classes;
  const {
    debtorName,
    plannerAgency,
    productName,
    creationDate,
    remarks,
    creativeAgency,
    contact,
    email,
    cc,
    contactGroupm,
    phoneNumberGroupm,
    version,
    ordersDigital,
    planName
  } = data;
  return (
    <>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className={paperRoot} elevation={0}>
              <Table>
                <TableBody key={"campaignId"}>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CLIENT NAME
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{debtorName}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        VERSION
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{version}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        PRODUCT NAME
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{productName}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        DATE
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{creationDate}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        PLAN NAME
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{planName}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        REPLACES DATE
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{PLACEHOLDER}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={2} className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        REMARKS
                      </Typography>
                    </TableCell>
                    <TableCell rowSpan={2} colSpan={4}>
                      <Typography align="left">{remarks}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={paperRoot} elevation={0}>
              <Table>
                <TableBody key={"campaignId"}>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CREATIVE AGENCY
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{creativeAgency}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        CONTACT GROUPM
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{contactGroupm}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CONTACT
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{contact}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        EMAIL ADDRESS
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">TrafficNL@groupm.com</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        E-MAIL
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{email}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        PHONE NUMBER
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{phoneNumberGroupm}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CC
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{cc}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        PLANNER AGENCY
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{plannerAgency}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={paperRoot} elevation={0}>
              <Scrollbar
                autoHeightMax={600}
                autoHeightMin={95}
                autoHide={false}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          GROUPM ORDER NUMBER
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          PLAN NUMBER
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          TITLE+SALESHOUSE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          DESCRIPTION
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          START DATE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          END DATE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          DELIVERY DATE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          MAXIMUM SECONDS
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          DIMENSIONS
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          WEIGHT
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          CREATIVE TYPE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          ANIMATION/LOOPING
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          FRAMERATE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          REMARKS
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          ADSERVER
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          MATERIAL HOSTED BY
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          CONTACT
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {!isEmpty(ordersDigital) ? (
                      ordersDigital.map((order, index) => {
                        const {
                          marathonOrderId,
                          marathonPlanId,
                          title,
                          trafficItemDescription,
                          trafficItemStartDate,
                          trafficItemEndDate,
                          deliveryDate,
                          maximumSeconds,
                          dimension,
                          weight,
                          creativeType,
                          animationLooping,
                          framerate,
                          remarks: orderRemarks,
                          adServer,
                          materialHostedBy,
                          contact: orderContact
                        } = order;
                        return (
                          <TableRow
                            key={`${marathonOrderId +
                              marathonPlanId} - ${index}`}
                          >
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {marathonOrderId}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {marathonPlanId}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {title}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {trafficItemDescription}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {trafficItemStartDate}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {trafficItemEndDate}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {deliveryDate}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {maximumSeconds}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {dimension}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {weight}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {creativeType}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {animationLooping}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {framerate}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {orderRemarks}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {adServer}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {materialHostedBy}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {orderContact}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={17} align="center">
                          <Typography align="center" noWrap>
                            {EMPTY_TABLE}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

PreviewDigital.propTypes = {
  data: PropTypes.object
};

export default PreviewDigital;
