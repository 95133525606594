import { createSelector } from "reselect";
import orderBy from "lodash/orderBy";
import {
  generateNotificationUrlPath,
  parseUtcDate
} from "../models/common/utils/clientUtils";
import {
  NOTIFICATION_STATUS_UNREAD,
  NOTIFICATION_TYPE_SYSTEM
} from "../../configurations/appConstants";

export const notificationList = state => state.notifications;

export const selectNotifications = createSelector(
  notificationList,
  notifications => {
    return orderBy(
      notifications.notificationList,
      ["typeId", "modified"],
      ["asc", "desc"]
    ).map(n => ({
      id: n.notificationId,
      title: n.title,
      message: n.message,
      modified: parseUtcDate(n.modified),
      urlPath: generateNotificationUrlPath(
        n.typeId,
        n.payload?.agencyId,
        n.payload?.campaignId,
        n.payload?.planId,
        n.payload?.trafficPlanId,
        n.payload?.marathonOrderIds,
        n.payload?.marathonPlanId
      ),
      typeId: n.typeId,
      hasAttachment: n.payload?.hasAttachment,
      systemMessageId: n.payload?.systemMessageId,
      fileName: n.payload?.fileName
    }));
  }
);

export const selectSysNotifications = createSelector(
  notificationList,
  notifications => {
    return orderBy(
      notifications.notificationList.filter(
        n =>
          n.typeId === NOTIFICATION_TYPE_SYSTEM &&
          n.statusId === NOTIFICATION_STATUS_UNREAD
      ),
      ["modified"],
      ["desc"]
    ).map(n => ({
      id: n.notificationId,
      title: n.title,
      message: n.message,
      modified: parseUtcDate(n.modified),
      hasAttachment: n.payload.hasAttachment,
      systemMessageId: n.payload.systemMessageId,
      fileName: n.payload.fileName
    }));
  }
);

export const selectNotificationCount = createSelector(
  notificationList,
  notifications => {
    return notifications.notificationList.filter(
      n =>
        n.typeId !== NOTIFICATION_TYPE_SYSTEM &&
        n.statusId === NOTIFICATION_STATUS_UNREAD
    ).length;
  }
);
