import React, { memo, useCallback, useState, useRef } from "react";

import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import clsx from "clsx";

import {
  AccordionSummary,
  AccordionDetails,
  Grid,
  Divider
} from "@mui/material";

import TrafficLineFold from "./extensions/TrafficLineFold";
import TrafficLineActions from "./extensions/TrafficLineActions";

import {
  selectCurrentTrafficPlanId,
  getSelectedTraffic
} from "../../business/models/TrafficPlan/trafficPlanSelectors";

import {
  toggleMultipleEditTrafficItems,
  trafficItemStatusChangeToggleSelected
} from "../../business/models/TrafficPlan/trafficPlanActions";

import {
  TRAFFIC_PAGE,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_NOT_APPROVED,
  MEDIATYPE_DIGITAL,
  MEDIATYPE_INVENTORY
} from "../../configurations/appConstants";

import {
  canEditTrafficAgentFields,
  selectUserIsAdmin,
  canViewTrafficManagementPage
} from "../../business/selectors/userProfileSelectors";
import {
  selectCurrentTrafficItem,
  selectIsEditingTrafficItem,
  selectTrafficItemDigitalTemplateId
} from "../../business/models/TrafficItem/trafficItemSelectors";
import { changeTrafficItemStatus } from "../../business/models/TrafficItem/trafficItemActions";
import { selectCurrentPlan } from "../../business/models/Plan/planSelectors";
import TrafficItemItemActions from "./extensions/TrafficItemItemActions";
import TrafficItemItemSelection from "./extensions/TrafficItemItemSelection";
import TrafficLineCompact from "./extensions/TrafficLineCompact";
import { parseIntOrDefault } from "../../business/models/common/utils/clientUtils";
import TrafficItemRemarkPopper from "./extensions/TrafficItemRemarkPopper";
import useTemplateData from "../form/hooks/useTemplateData";
import { classes, Accordion } from "./styles/item";

const TrafficItemItem = ({
  id,
  trafficItem = {},
  isActive,
  isEditing,
  areTrafficAgentFieldsDisabled,
  currentPlan,
  currentTrafficPlan,
  canViewManageDigitalTrafficTemplate,
  isAdmin,
  isDebtorActive
}) => {
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { agency, trafficItemId: urlTrafficItemId } = useParams();
  useTemplateData(true, isActive);

  const changeUrl = () =>
    navigate(
      `/${agency}/${TRAFFIC_PAGE}/${currentPlan}/${currentTrafficPlan}${
        parseIntOrDefault(urlTrafficItemId) === id ? "" : `/${id}`
      }`
    );

  const handleChange = e => {
    e.stopPropagation();
    changeUrl(id);
  };

  const handleClipboardCopy = useCallback(
    () => e => {
      e.stopPropagation();
    },
    []
  );

  const {
    mediaTypeId,
    isPreliminary,
    trafficItemRemarks,
    trafficItemStatusText,
    orderClientStatusId,
    marathonOrderId,
    orderNumOfUnits,
    costType,
    netTotal,
    netPerUnit
  } = trafficItem;

  const isDisabledByStatusCopy =
    trafficItemStatusText === "Cancelled" ||
    orderClientStatusId === ORDER_STATUS_NOT_APPROVED ||
    orderClientStatusId === ORDER_STATUS_CANCELLED;

  const isDisabledByStatus =
    trafficItemStatusText === "Cancelled" ||
    (trafficItemStatusText === "Finalize" &&
      orderClientStatusId === ORDER_STATUS_CANCELLED);

  const isTemplateSelectVisible =
    isEditing &&
    isActive &&
    (mediaTypeId === MEDIATYPE_DIGITAL ||
      mediaTypeId === MEDIATYPE_INVENTORY) &&
    canViewManageDigitalTrafficTemplate;

  const isTemplateSelectDisabled = !isAdmin && isPreliminary;

  return (
    <>
      <Accordion
        square
        elevation={0}
        className={clsx(classes.expensionPanel, {
          [classes.selected]: isActive
        })}
        expanded={isActive}
        onChange={handleChange}
      >
        <AccordionSummary>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <TrafficItemItemSelection
                isPreliminary={isPreliminary}
                isDisabledByStatus={isDisabledByStatus}
                id={id}
                isDebtorActive={isDebtorActive}
              />
            </Grid>
            <Grid item>
              <TrafficLineCompact
                onClipboardCopy={handleClipboardCopy}
                item={trafficItem}
                showTemplateSelect={isTemplateSelectVisible}
                templateSelectDisabled={isTemplateSelectDisabled}
              />
            </Grid>
            <Grid item>
              <TrafficItemItemActions
                isDisabledByStatusCopy={isDisabledByStatusCopy}
                isDisabledByStatus={isDisabledByStatus}
                isPreliminary={isPreliminary}
                id={id}
                setOpen={setOpen}
                buttonRef={buttonRef}
                trafficItemRemarks={trafficItemRemarks}
                marathonOrderId={marathonOrderId}
                orderNumOfUnits={orderNumOfUnits}
                costType={costType}
                netTotal={netTotal}
                netPerUnit={netPerUnit}
                isDebtorActive={isDebtorActive}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails
          className={classes.expansionDetailsTablePanelTrafficItem}
        >
          {isActive && ( //  This is needed due to performance issue
            <TrafficLineFold
              mediaTypeId={mediaTypeId}
              editMode={isEditing}
              areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
              isDebtorActive={isDebtorActive}
            />
          )}
        </AccordionDetails>
        {isEditing && (
          <AccordionDetails className={classes.ExpansionDetailsAction}>
            <TrafficLineActions isDebtorActive={isDebtorActive} />
          </AccordionDetails>
        )}
      </Accordion>
      <TrafficItemRemarkPopper
        open={open}
        setOpen={setOpen}
        buttonRef={buttonRef}
        remarks={trafficItemRemarks}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const isEditing = selectIsEditingTrafficItem(state);
  const currentTrafficItem = selectCurrentTrafficItem(state);
  const { isDebtorActive } = getSelectedTraffic(state) || false;

  return {
    areTrafficAgentFieldsDisabled: !canEditTrafficAgentFields(state),
    currentPlan: selectCurrentPlan(state),
    currentTrafficPlan: selectCurrentTrafficPlanId(state),
    trafficDigitalTemplateId: selectTrafficItemDigitalTemplateId(state),
    isEditing,
    isActive: currentTrafficItem === ownProps.id,
    canViewManageDigitalTrafficTemplate: canViewTrafficManagementPage(state),
    isAdmin: selectUserIsAdmin(state),
    isDebtorActive
  };
};

const mapDispatchToProps = dispatch => ({
  setSelectedForStatusChange: (trafficItemId, selected) =>
    dispatch(trafficItemStatusChangeToggleSelected(trafficItemId, selected)),
  setSelectedForMultipleEdit: (id, selected) =>
    dispatch(toggleMultipleEditTrafficItems(id, selected)),
  changeStatus: (id, value) => dispatch(changeTrafficItemStatus(id, value))
});

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(TrafficItemItem)
);
