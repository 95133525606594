import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import toUpper from "lodash/toUpper";
import set from "lodash/set";
import { selectAgency } from "../../selectors/applicationSelectors";
import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";
import {
  getQueryObj,
  generateParamObj,
  getHash,
  getText,
  getID,
  getPathValue
} from "../common/utils/modelUtils";
import generateUrl from "../common/utils/urlUtils";
import {
  selectCurrentCampaign,
  getCampaignData
} from "../Campaign/campaignSelectors";
import CampaignModel from "../Campaign/Campaign";
import PlanModel from "./Plan";
import OrderModel from "../Order/Order";
import calculate from "../common/utils/calculationUtils";
import { currencySymbols } from "../../constants/businessConstants";
import { isNullOrUndefined } from "../../../functions/util";
import { selectIsInCopyPlanProcess } from "../../selectors/copyPlanSelectors";

export const selectPlan = state => state.plan;
export const selectPropertyName = (_, props) => props.name;
export const selectOrderId = (_, orderId) => orderId;
export const selectPlanId = (_, planId) => planId;
export const selectPropsPlanId = (_, props) => props.planId;
export const selectInventoryRole = (_, props) => props.isInventoryUser;

export const selectedOrderStatus = createSelector(
  selectPlan,
  plan => plan.selectedOrderStatus
);

export const selectedNextOrderStatus = createSelector(
  selectPlan,
  plan => plan.selectedOrderNextStatus
);

export const selectedOrders = createSelector(
  selectPlan,
  plan => plan.selectedOrders
);

export const selectCurrentPlan = createSelector(
  selectPlan,
  plan => plan.currentPlan
);

export const selectIsEditingPlan = createSelector(
  selectPlan,
  plan => plan.isEditing
);

export const selectPlanDisplayErrors = createSelector(
  selectPlan,
  plan => plan.displayErrors
);

export const selectPlanIsNew = createSelector(selectPlan, plan => plan.isNew);

export const selectSelectedOrdersForDivide = createSelector(
  selectPlan,
  plan => plan.selectedOrdersForDivide
);

export const selectSelectedInsertionDates = createSelector(
  selectPlan,
  plan => plan.selectedInsertionDates
);

export const selectIsDivideInsertionEvenly = createSelector(
  selectPlan,
  plan => plan.isDivideInsertionsEvenly
);

export const isSelected = createSelector(
  selectPlan,
  plan => !isNullOrUndefined(plan.currentPlan)
);

export const getPlan = createSelector(
  [getEntitiesSession, selectPlan],
  (entitiesSession, plan) => {
    const { Plan } = entitiesSession;
    const planModel = Plan.get(c => c.id === plan.currentPlan);

    return planModel ? planModel.ref : undefined;
  }
);

export const getPlanActual = createSelector(
  [getEntitiesSession, selectPlan],
  (entitiesSession, plan) => {
    const { Plan } = entitiesSession;
    const planModel = Plan.get(c => c.id === plan.currentPlan);

    return planModel ? planModel.ref.isActual : undefined;
  }
);

export const getPlanFull = createSelector(
  [getEntitiesSession, selectPlan],
  (entitiesSession, plan) => {
    const { Plan } = entitiesSession;
    const planModel = Plan.get(c => c.id === plan.currentPlan);

    return planModel
      ? { ...planModel.campaign.ref, ...planModel.ref }
      : undefined;
  }
);

export const getPlanData = createSelector(
  [getEntitiesSession, selectCurrentPlan, getApiData, selectAgency],
  (entitiesSession, planId, apiData, agencyID) => {
    const { Plan } = entitiesSession;
    const planModel = Plan.get(c => c.id === planId);
    if (!planModel) {
      return undefined;
    }

    const fullEntity = { ...planModel.campaign.ref, ...planModel.ref };
    const campaignConfiguration = CampaignModel.apiConfiguration;
    const planConfiguration = PlanModel.apiConfiguration;

    return {
      ...fullEntity,
      mediaType: getText(
        fullEntity.mediaTypeId,
        planConfiguration.mediaTypeId,
        fullEntity,
        apiData,
        agencyID
      ),
      planAgreement: getText(
        fullEntity.planAgreementId,
        planConfiguration.planAgreementId,
        fullEntity,
        apiData,
        agencyID
      ),
      currencySupplier: getText(
        fullEntity.currencySupplierId,
        planConfiguration.currencySupplierId,
        fullEntity,
        apiData,
        agencyID
      ),
      currencySupplierSymbol: currencySymbols[fullEntity.currencySupplierId],
      debtor: getText(
        fullEntity.debtorId,
        campaignConfiguration.debtorId,
        fullEntity,
        apiData,
        agencyID
      ),
      marathonDebtorId: getID(
        fullEntity.debtorId,
        campaignConfiguration.debtorId,
        fullEntity,
        apiData,
        agencyID
      ),
      planner: getText(
        fullEntity.plannerId,
        planConfiguration.plannerId,
        fullEntity,
        apiData,
        agencyID
      ),
      marathonProjectId: getText(
        fullEntity.marathonProjectId,
        planConfiguration.marathonProjectId,
        fullEntity,
        apiData,
        agencyID
      )
    };
  }
);

export const getNumberOfOrders = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  (entitiesSession, planId) => {
    const { Order } = entitiesSession;
    return Order.filter(
      o => o.plan === planId && o.statusId !== 4 && o.statusId !== 32
    ).count();
  }
);

export const getPlans = createSelector(
  [getEntitiesSession, selectCurrentCampaign, getNumberOfOrders],
  (entitiesSession, campaignId, numberOfOrders) => {
    const { Plan } = entitiesSession;

    return Plan.filter(plan => plan.campaign === campaignId)
      .toModelArray()
      .map(plan => {
        return {
          ...plan.ref,
          numberOfOrders,
          planner: plan.ref.planner?.username,
          currencySupplierSymbol: currencySymbols[plan.ref.currencySupplierId]
        };
      });
  }
);

export const getSinglePlan = createSelector(
  [getEntitiesSession, selectPropsPlanId],
  (entitiesSession, planId) => {
    const { Plan } = entitiesSession;
    const planModel = Plan.get(c => c.id === planId);

    return planModel
      ? { ...planModel.campaign.ref, ...planModel.ref }
      : undefined;
  }
);

export const getPropertyTextFromApiData = createSelector(
  [getSinglePlan, getApiData, selectAgency, selectPropertyName],
  (plan, apiData, agency, name) => {
    const configuration = PlanModel.apiConfiguration;
    return plan
      ? getText(plan[name], configuration[name], plan, apiData, agency)
      : null;
  }
);

export const getPlanIds = createSelector([getPlans], plans => {
  return plans.map(plan => plan.id);
});

export const getCalculationDataForEveryPlan = createSelector(
  [getEntitiesSession, getApiData, selectAgency, getPlanIds],
  (entitiesSession, apiData, agencyID, planIds) => {
    const { Order } = entitiesSession;
    const calculationConfig = PlanModel.calculations;
    const orderConfiguration = OrderModel.apiConfiguration;

    return planIds.map(id => {
      const orders = Order.filter(
        order =>
          order.plan === id && order.statusId !== 4 && order.statusId !== 32
      )
        .toModelArray()
        .map(order => {
          const entity = {
            ...order.plan.campaign.ref,
            ...order.plan.ref,
            ...order.ref
          };
          return {
            ...entity,
            surcharge1Commission: getPathValue(
              orderConfiguration.surcharge1Commission,
              entity,
              apiData,
              agencyID
            ),
            surcharge1Fee: getPathValue(
              orderConfiguration.surcharge1Fee,
              entity,
              apiData,
              agencyID
            ),
            surcharge2Commission: getPathValue(
              orderConfiguration.surcharge2Commission,
              entity,
              apiData,
              agencyID
            ),
            surcharge2Fee: getPathValue(
              orderConfiguration.surcharge2Fee,
              entity,
              apiData,
              agencyID
            )
          };
        });
      const calculationObject = calculate(calculationConfig, orders);
      return {
        planId: id,
        ...calculationObject
      };
    });
  }
);

export const getPlanCalculationData = createSelector(
  [getEntitiesSession, getApiData, selectAgency, selectPlanId],
  (entitiesSession, apiData, agencyID, planId) => {
    const { Order } = entitiesSession;
    const calculationConfig = PlanModel.calculations;
    const orderConfiguration = OrderModel.apiConfiguration;
    let calculations = null;
    const orders = Order.filter(
      order =>
        order.plan === planId && order.statusId !== 4 && order.statusId !== 32
    )
      .toModelArray()
      .map(order => {
        const entity = {
          ...order.plan.campaign.ref,
          ...order.plan.ref,
          ...order.ref
        };
        return {
          ...entity,
          surcharge1Commission: getPathValue(
            orderConfiguration.surcharge1Commission,
            entity,
            apiData,
            agencyID
          ),
          surcharge1Fee: getPathValue(
            orderConfiguration.surcharge1Fee,
            entity,
            apiData,
            agencyID
          ),
          surcharge2Commission: getPathValue(
            orderConfiguration.surcharge2Commission,
            entity,
            apiData,
            agencyID
          ),
          surcharge2Fee: getPathValue(
            orderConfiguration.surcharge2Fee,
            entity,
            apiData,
            agencyID
          )
        };
      });
    if (orders) {
      calculations = calculate(calculationConfig, orders);
    }
    return calculations;
  }
);

export const getPlansWithCalculations = createSelector(
  [getPlans, getCalculationDataForEveryPlan],
  (plans, calculations) => {
    return plans.map(plan => {
      const calculation = calculations.find(c => c.planId === plan.id);
      return { ...plan, ...calculation };
    });
  }
);

export const getCurrencyDataForEveryPlan = createSelector(
  [getPlansWithCalculations],
  plans => {
    return plans.map(plan => {
      return {
        clientTotalCost: plan.clientTotalCost,
        clientTotalCostEur: plan.clientTotalCostEur,
        marathonPlanId: plan.marathonPlanId,
        planId: plan.planId,
        currencySupplierId: plan.currencySupplierId
      };
    });
  }
);

export const getEditingPlan = createSelector(
  [getEditingEntitiesSession, selectPlan],
  (editingSession, plan) => {
    const { Plan } = editingSession;
    const planModel = Plan.get(c => c.id === plan.currentPlan);
    return planModel
      ? { ...planModel.campaign?.ref, ...planModel.ref }
      : undefined;
  }
);

export const getPlanForSave = createSelector(
  [getEditingEntitiesSession, selectPlan],
  (editingSession, plan) => {
    const { Plan } = editingSession;
    const planModel = Plan.get(c => c.id === plan.currentPlan);
    return planModel ? planModel.ref : undefined;
  }
);

export const getPlanById = createSelector(
  [getEntitiesSession, selectPlanId],
  (entitiesSession, planId) => {
    const { Plan } = entitiesSession;
    const planModel = Plan.get(p => p.id === planId);

    return planModel ? planModel.ref : undefined;
  }
);

export const doesPlanHaveErrors = createSelector(
  [getEditingEntitiesSession, selectPlan],
  (editingSession, plan) => {
    const { Plan } = editingSession;
    const planModel = Plan.get(c => c.id === plan.currentPlan);
    return planModel ? planModel.hasErrors() : false;
  }
);

export const getPlanPropertyErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectPlan, selectPropertyName],
  (editingSession, plan, name) => {
    const { Plan } = editingSession;
    const planModel = Plan.get(c => c.id === plan.currentPlan);
    return planModel ? planModel.validateByProperty(name) : undefined;
  }
)((state, props) => props.name);

export const getPlanPropertyValue = createCachedSelector(
  [getEditingPlan, selectPropertyName],
  (plan, name) => (plan ? plan[name] : undefined)
)((state, props) => props.name);

export const getPlanPropertyPreviousValue = createCachedSelector(
  [getPlan, selectPropertyName],
  (plan, name) => (plan ? plan[name] : undefined)
)((state, props) => props.name);

export const getPlanRatePropertyValue = createCachedSelector(
  [getEditingPlan, selectPropertyName],
  (plan, name) => {
    const defaultValue = PlanModel.defaultAttributes[name];
    const dependentFieldName = PlanModel.dependentFields[name];
    const isEuro = plan ? toUpper(plan[dependentFieldName]) === "EUR" : false;

    if (isEuro) {
      set(plan, name, defaultValue);
    }

    return plan ? plan[name] : undefined;
  }
)((state, props) => props.name);

export const getPlanPropertyOptions = createCachedSelector(
  [
    getApiData,
    getEditingPlan,
    selectPropertyName,
    selectAgency,
    selectInventoryRole
  ],
  (apiData, plan, name, agencyID, isInventoryUser) => {
    const configuration = PlanModel.apiConfiguration[name];
    const planObject = plan ? plan.planId : undefined;
    if (configuration) {
      const queryObj = getQueryObj(
        configuration.urlParams,
        configuration.urlRequiredParams,
        plan
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(configuration.url, paramObj, queryObj);
        let retVal = apiData[getHash(url)];
        if (
          name === "mediaTypeId" &&
          isNullOrUndefined(planObject) &&
          !isInventoryUser
        ) {
          retVal = retVal?.filter(o => {
            return o.text !== "Inventory";
          });
        }
        return retVal;
      }
    }
    return undefined;
  }
)((state, props) => props.name);

export const doesPlanHaveInvoicedInsertions = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  (entitiesSession, planId) => {
    const { Order, Insertion } = entitiesSession;
    const ordersIDs = Order.filter(o => o.plan === planId)
      .toModelArray()
      .map(o => o.id);
    const insertions = Insertion.filter(i => ordersIDs.includes(i.order))
      .toModelArray()
      .map(i => i.insertionIsInvoiced);
    return !isEmpty(insertions) ? some(insertions) : false;
  }
);

export const doesPlanHaveOrders = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  (entitiesSession, planId) => {
    const { Order } = entitiesSession;
    return Order.filter(o => o.plan === planId).count() > 0;
  }
);

export const doesPlanHaveCancelledOrToBeCancelledOrders = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  (entitiesSession, planId) => {
    const { Order } = entitiesSession;
    return (
      Order.filter(
        o => o.plan === planId && (o.statusId === 4 || o.statusId === 32)
      ).count() > 0
    );
  }
);

export const getMarathonPlanId = createSelector(
  [getEntitiesSession, selectPlan],
  (entitiesSession, plan) => {
    const { Plan } = entitiesSession;
    const planModel = Plan.get(p => p.id === plan.currentPlan);

    return planModel ? planModel.ref.marathonPlanId : 0;
  }
);

export const isCurrencySupplierEuro = createSelector(
  [getEditingEntitiesSession, selectCurrentPlan],
  (editingSession, planId) => {
    const { Plan } = editingSession;
    const planModel = Plan.get(p => p.id === planId);

    return planModel
      ? toUpper(planModel.ref.currencySupplierId) === "EUR"
      : false;
  }
);

export const isCurrencyClientEuro = createSelector(
  [getEditingEntitiesSession, selectCurrentPlan],
  (editingSession, planId) => {
    const { Plan } = editingSession;
    const planModel = Plan.get(p => p.id === planId);

    return planModel ? toUpper(planModel.ref.currencyClient) === "EUR" : false;
  }
);

export const disabledByDefault = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  () => true
);

export const disabledByInactiveDebtor = createSelector(
  [getCampaignData],
  campaign => {
    return campaign?.isDebtorActive === false;
  }
);

export const selectRules = createSelector(
  [
    doesPlanHaveInvoicedInsertions,
    doesPlanHaveOrders,
    doesPlanHaveCancelledOrToBeCancelledOrders,
    selectIsInCopyPlanProcess,
    isCurrencySupplierEuro,
    isCurrencyClientEuro,
    disabledByDefault,
    disabledByInactiveDebtor
  ],
  (
    hasInvoiced,
    hasOrders,
    hasCancelledOrToBeCancelledOrders,
    isProcessPlanCopy,
    isEurCurrencySupplier,
    isEurCurrencyClient,
    defaultDisabled,
    disabled
  ) => {
    return {
      doesPlanHaveInvoicedInsertions: hasInvoiced,
      doesPlanHaveOrders: hasOrders,
      doesPlanHaveCancelledOrToBeCancelledOrders: hasCancelledOrToBeCancelledOrders,
      isInProcessPlanCopy: isProcessPlanCopy,
      isCurrencySupplierEuro: isEurCurrencySupplier,
      isCurrencyClientEuro: isEurCurrencyClient,
      disabledByDefault: defaultDisabled,
      disabledByInactiveDebtor: disabled
    };
  }
);

export const isDisabled = createCachedSelector(
  [selectRules, selectPropertyName],
  (rules, name) => {
    const configuration = PlanModel.disabledFields;
    return some(configuration[name], c => rules[c]);
  }
)((state, props) => props.name);

export const doesPlanExist = createSelector(
  [getEntitiesSession, selectPlanId],
  (entitiesSession, planId) => {
    const { Plan } = entitiesSession;
    return Plan.idExists(planId);
  }
);

export const selectCascadingSaveFlag = createSelector(
  [getPlan, getEditingPlan, selectPlanIsNew, getNumberOfOrders],
  (savedPlan, editingPlan, isNew, numberOfOrders) => {
    const {
      currencyClient: savedCurrencyClient,
      currencySupplierId: savedCurrencySupplierId,
      exchangeRate: savedExchangeRate,
      exchangeRateClient: savedExchangeRateClient,
      planPoNumber: savedPlanPoNumber,
      planAgreementId: savedPlanAgreementId
    } = savedPlan || {};
    const {
      currencyClient: editedCurrencyClient,
      currencySupplierId: editedCurrencySupplierId,
      exchangeRate: editedExchangeRate,
      exchangeRateClient: editedExchangeRateClient,
      planPoNumber: editedPlanPoNumber,
      planAgreementId: editedPlanAgreementId
    } = editingPlan || {};

    if (
      (savedCurrencyClient !== editedCurrencyClient ||
        savedCurrencySupplierId !== editedCurrencySupplierId ||
        savedExchangeRate !== editedExchangeRate ||
        savedPlanPoNumber !== editedPlanPoNumber ||
        savedPlanAgreementId !== editedPlanAgreementId ||
        savedExchangeRateClient !== editedExchangeRateClient) &&
      !isNew &&
      numberOfOrders > 0
    ) {
      return true;
    }
    return false;
  }
);

export const selectInvoicedOrderAgreementChangeFlag = createSelector(
  [getPlan, getEditingPlan, doesPlanHaveInvoicedInsertions],
  (savedPlan, editingPlan, hasInvoiced) => {
    const { planAgreementId: savedAgreement } = savedPlan || {};
    const { planAgreementId: editedAgreemnt } = editingPlan || {};
    if (savedAgreement !== editedAgreemnt && hasInvoiced) {
      return true;
    }
    return false;
  }
);

export const selectIsOrderSelected = createSelector(
  [selectedOrders, selectOrderId],
  (selectedOrderList, orderId) => {
    return selectedOrderList.indexOf(orderId) >= 0;
  }
);

export const selectAnyOrderSelected = createSelector(
  [selectedOrders],
  selectedOrderList => {
    return selectedOrderList.length > 0;
  }
);

export const selectOrderIsSelectable = createSelector(
  [getEntitiesSession, selectedOrderStatus, selectOrderId],
  (entitiesSession, selectedStatus, orderId) => {
    const { Order } = entitiesSession;
    const order = Order.get(o => o.id === orderId);

    return selectedStatus
      ? selectedStatus === order.statusId
      : [1, 2, 3].indexOf(order.statusId) >= 0;
  }
);

export const selectOrdersWithStatus = createSelector(
  [getEntitiesSession, selectCurrentPlan, selectedOrderStatus],
  (entitiesSession, planId, selectedStatusId) => {
    const { Order } = entitiesSession;

    return Order.filter(
      o => o.plan === planId && o.statusId === selectedStatusId
    )
      .toModelArray()
      .map(o => o.id);
  }
);

export const selectAreAnyOrdersForDivideChecked = createSelector(
  [selectSelectedOrdersForDivide],
  selectedOrdersForDivide => {
    return selectedOrdersForDivide.length > 0;
  }
);

export const selectIsOrderForDivideChecked = createSelector(
  [selectSelectedOrdersForDivide, selectOrderId],
  (selectedOrdersForDivide, orderId) => {
    return selectedOrdersForDivide.indexOf(orderId) >= 0;
  }
);

export const doesPlanHaveOrdersReadyForTraffic = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  (entitiesSession, planId) => {
    const { Order } = entitiesSession;
    return Order.exists(o => o.plan === planId && o.isReadyForTraffic);
  }
);

export const selectFlightForMbs = createSelector(
  selectPlan,
  plan => plan.flightToSendToMbs
);

export const selectPlanForMbs = createSelector(
  selectPlan,
  plan => plan.planToSendToMbs
);

export const doesPlanHaveExtensionData = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  (entitiesSession, planId) => {
    const { PlanExtension } = entitiesSession;
    return PlanExtension.exists(pe => pe.plan === planId);
  }
);

export const selectCurrentPlanMediaType = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  (entitiesSession, planId) => {
    const { Plan } = entitiesSession;
    const plan = Plan.get(p => p.id === planId);

    return plan ? plan.mediaTypeId : undefined;
  }
);

export const doesPlanHaveTargetMetricsKpis = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  (entitiesSession, planId) => {
    const { PlanExtension, PlanTargetMetricsKpi } = entitiesSession;
    const planExtensionIds = PlanExtension.filter(pe => pe.plan === planId)
      .toModelArray()
      .map(pe => pe.id);
    const planExtensionId = !isEmpty(planExtensionIds)
      ? planExtensionIds[0]
      : null;

    let targetMetricsKpiCount = 0;
    if (!isNullOrUndefined(planExtensionId)) {
      targetMetricsKpiCount = PlanTargetMetricsKpi.filter(
        ptmkpi => ptmkpi.planExtension === planExtensionId
      ).count();
    }

    return targetMetricsKpiCount > 0;
  }
);

export const selectDeletePlansTargetMetricsKpiData = createSelector(
  [
    getPlan,
    getEditingPlan,
    selectPlanIsNew,
    getNumberOfOrders,
    doesPlanHaveTargetMetricsKpis
  ],
  (savedPlan, editingPlan, isNew, numberOfOrders, hasTargetMetricsKpis) => {
    const { mediaTypeId: savedMediaTypeId } = savedPlan || {};
    const { mediaTypeId: editedMediaTypeId } = editingPlan || {};

    return (
      !isNew &&
      numberOfOrders === 0 &&
      savedMediaTypeId !== editedMediaTypeId &&
      hasTargetMetricsKpis
    );
  }
);

export const selectCopyProcessFromPlanData = createSelector(
  [getPlanData],
  plan => {
    return plan?.isInCopyProcess;
  }
);

export const selectCanUserDoActions = createSelector(
  [getEntitiesSession, selectPropsPlanId],
  (entitiesSession, planId) => {
    const { Order } = entitiesSession;
    return Order.exists(o => o.plan === planId && o.canUserDoActions);
  }
);
