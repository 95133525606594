import React from "react";
import clsx from "clsx";
import Field from "../../../../../form/TrafficItemField";
import { classes, Grid } from "../../../../styles/fold";

const FoldPrint = ({
  editMode,
  areTrafficAgentFieldsDisabled,
  displayErrors,
  isDebtorActive
}) => {
  return (
    <Grid container justifyContent="flex-start">
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="trafficItemId"
          name="trafficItemId"
          helperText="Traffic Item ID"
          label="Traffic Item ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="marathonOrderId"
          name="marathonOrderId"
          helperText="Order ID"
          label="Order ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="marathonPlanId"
          name="marathonPlanId"
          helperText="Plan ID"
          label="Plan ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="orderStartDate"
          name="orderStartDate"
          helperText="Start Date"
          label="Start Date"
          variant="standard"
          hideIcon
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="orderEndDate"
          name="orderEndDate"
          helperText="End Date"
          label="End Date"
          variant="standard"
          hideIcon
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="orderFormat"
          name="orderFormat"
          helperText="Format"
          label="Format"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="orderDescription"
          name="orderDescription"
          helperText="Description"
          label="Description"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="trimSize"
          name="trimSize"
          helperText="Trim Size"
          label="Trim Size"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="typeSize"
          name="typeSize"
          helperText="Type Size"
          label="Type Size"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="colour"
          name="colour"
          helperText="Colour"
          label="Colour"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="technicalSpecifications"
          name="technicalSpecifications"
          helperText="Technical Specifications"
          label="Technical Specifications"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="editionNumber"
          name="editionNumber"
          helperText="Edition Number"
          label="Edition Number"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="materialDeadline"
          name="materialDeadline"
          helperText="Material Deadline"
          label="Material Deadline"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          hideIcon={!editMode}
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
        xs={12}
      >
        <Field
          component="text"
          id="trafficItemRemarks"
          name="trafficItemRemarks"
          helperText="Remarks"
          label="Remarks"
          variant="standard"
          disabled={!editMode || !isDebtorActive}
          rowsMax="6"
          multiline
          isLabelHighlighted
        />
      </Grid>
    </Grid>
  );
};

export default FoldPrint;
