import React from "react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import { TableHead, TableRow, TableCell, TableBody, Grid } from "@mui/material";

import { EMPTY_TABLE } from "../../../../../configurations/appConstants";

import SecondTableRow from "./extensions/TargetMetricsKpiSecondTableRow";

import { classes, Table, Button } from "../../styles/targetMetricsKpiTable";

const SecondPlanTargetMetricsKpiTable = ({
  data,
  onHandleAdd,
  onHandleEdit,
  onHandleDelete
}) => {
  const isDisabled = data.length === 5;
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} align="left">
              Weekly Distribution
            </TableCell>
            <TableCell className={classes.header} align="left">
              Impressions
            </TableCell>
            <TableCell className={classes.header} align="left">
              Position in Break
            </TableCell>
            <TableCell className={classes.header} align="left">
              Views (Viewable + Indemo)
            </TableCell>
            <TableCell className={classes.header} align="left">
              Preferred Position
            </TableCell>
            <TableCell className={classes.header} align="left">
              Primetime Share
            </TableCell>
            <TableCell className={classes.header} align="left">
              Remarks / Restrictions
            </TableCell>
            <TableCell className={classes.header} align="center" />
            <TableCell className={classes.header} align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(data) ? (
            data.map(row => (
              <SecondTableRow
                key={row.id}
                data={row}
                onHandleEdit={onHandleEdit}
                onHandleDelete={onHandleDelete}
              />
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={12}>
                {EMPTY_TABLE}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            name="t_update"
            id="t_update"
            variant="contained"
            className={classes.saveButton}
            onClick={onHandleAdd}
            disabled={isDisabled}
          >
            ADD
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

SecondPlanTargetMetricsKpiTable.propTypes = {
  onHandleAdd: PropTypes.func.isRequired,
  onHandleEdit: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired
};

export default SecondPlanTargetMetricsKpiTable;
