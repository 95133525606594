import React from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";
import clsx from "clsx";

import {
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Button
} from "@mui/material";

import { isEmpty } from "lodash";

import { classes, Table } from "../../styles/projectTable";

const ProjectTableHeader = ({ onAdd, projects = [], isDebtorActive }) => {
  const isDisabled = isEmpty(projects);

  return (
    <Table className={classes.tableHeader}>
      <TableHead className={classes.tableHead}>
        <TableRow>
          <TableCell className={classes.cellHeader}>
            <Typography
              className={classes.typography}
              noWrap
              variant="subtitle1"
            >
              Project name
            </Typography>
          </TableCell>
          <TableCell className={classes.cellHeader}>
            <Typography
              className={classes.typography}
              noWrap
              variant="subtitle1"
            >
              Project Manager
            </Typography>
          </TableCell>
          <TableCell className={clsx(classes.cellHeader, classes.cellCustom)}>
            <Typography
              className={classes.typography}
              noWrap
              align="left"
              variant="subtitle1"
            >
              Marathon ID
            </Typography>
          </TableCell>
          <TableCell align="right" className={classes.cellAction}>
            {isDebtorActive && (
              <Button
                onClick={onAdd}
                className={classes.buttonAdd}
                disabled={isDisabled}
              >
                Add
              </Button>
            )}
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};

ProjectTableHeader.propTypes = {
  onAdd: PropTypes.func.isRequired
};

export default ProjectTableHeader;
