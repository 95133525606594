import React from "react";
import PropTypes from "prop-types";

import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";

import { DASH_PLACEHOLDER } from "../../../../../../configurations/appConstants";
import { EditIcon, DeleteIcon } from "../../../../../../assets/styles/icons";

import {
  trimString,
  parseDate
} from "../../../../../../business/models/common/utils/clientUtils";
import { isNullOrUndefinedOrEmpty } from "../../../../../../functions/util";

const PlanChangeOverviewRow = ({ data, onHandleEdit, onHandleDelete }) => {
  const { id, version, change, consequence, changedBy, date } = data;
  const stringLength = 30;
  return (
    <TableRow>
      <TableCell align="left">
        <Tooltip title={version || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(version)
              ? trimString(version, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={change || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(change)
              ? trimString(change, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={consequence || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(consequence)
              ? trimString(consequence, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={changedBy || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(changedBy)
              ? trimString(changedBy, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={parseDate(date) || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(date)
              ? parseDate(date)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <IconButton
          name={`tco_edit_${id}`}
          id={`tco_edit_${id}`}
          onClick={() => onHandleEdit(id)}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell align="left">
        <IconButton
          name={`tco_delete_${id}`}
          id={`tco_delete_${id}`}
          onClick={() => onHandleDelete(id)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

PlanChangeOverviewRow.propTypes = {
  onHandleEdit: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired
};

export default PlanChangeOverviewRow;
