import React, { memo } from "react";

import { Help } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { classes, IconButton } from "../styles/supportButton";

const SupportButton = () => {
  return (
    <Tooltip title="Create support ticket">
      <IconButton
        id="support"
        name="support"
        alt="SupportButton"
        aria-owns="suppor"
        target="_blank"
        href="https://groupmnld.atlassian.net/servicedesk/customer/portal/1/group/15"
        className={classes.button}
      >
        <Help />
      </IconButton>
    </Tooltip>
  );
};

export default memo(SupportButton);
