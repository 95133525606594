export const START_UNINTERRUPTIBLE_PROCESS = "START_UNINTERRUPTIBLE_PROCESS"; // TODO: Related to copy plan => Need to move this to copy plan actions
export const RESET_UNINTERRUPTIBLE_PROCESS = "RESET_UNINTERRUPTIBLE_PROCESS"; // TODO: Related to copy plan => Need to move this to copy plan actions

export const API_CALL_START = "API_CALL_START";
export const API_CALL_SUCCESS = "API_CALL_SUCCESS";
export const API_CALL_SUCCESS_NOTIFY = "API_CALL_SUCCESS_NOTIFY";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const API_CALL_ERROR_MSG = "API_CALL_ERROR_MSG";
export const APPLICATION_ERROR = "APPLICATION_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const TOGGLE_INSERTION_POPUP = "TOGGLE_INSERTION_POPUP";
export const TOGGLE_CAMPAIGN_CONFIRM_POPUP = "TOGGLE_CAMPAIGN_CONFIRM_POPUP";
export const TOGGLE_PLAN_CONFIRM_POPUP = "TOGGLE_PLAN_CONFIRM_POPUP";
export const TOGGLE_ORDER_CONFIRM_POPUP = "TOGGLE_ORDER_CONFIRM_POPUP";
export const TOGGLE_MBS_CONFIRM_POPUP = "TOGGLE_MBS_CONFIRM_POPUP";
export const SET_ACTION = "SET_ACTION";
export const CLEAR_ACTION = "CLEAR_ACTION";
export const SET_AGENCY = "SET_AGENCY";
export const CLEAR_AGENCY = "CLEAR_AGENCY";
export const TOGGLE_AGREEMENT_CONFIRM_POPUP = "TOGGLE_AGREEMENT_CONFIRM_POPUP";
export const OPEN_ORDER_STATUS_CHANGE_DIALOG =
  "OPEN_ORDER_STATUS_CHANGE_DIALOG";
export const CLOSE_ORDER_STATUS_CHANGE_DIALOG =
  "CLOSE_ORDER_STATUS_CHANGE_DIALOG";
export const SET_ORDER_STATUS_CHANGE_DIALOG_TITLE =
  "SET_ORDER_STATUS_CHANGE_DIALOG_TITLE";
export const RESET_TRAFFIC_ITEM_ACTION = "RESET_TRAFFIC_ITEM_ACTION";

export const ORDER_FORM_UPDATE = "orderUpdate";
export const ORDER_FORM_INSERTION_UPDATE = "insertionUpdate";

export const TOGGLE_ORDERS_ACTION = "TOGGLE_ORDERS_ACTION";
export const SET_CHECK_ALL_ORDERS = "SET_CHECK_ALL_ORDERS";
export const OPEN_DIVIDE_ORDERS_DIALOG = "OPEN_DIVIDE_ORDERS_DIALOG";
export const CLOSE_DIVIDE_ORDERS_DIALOG = "CLOSE_DIVIDE_ORDERS_DIALOG";
export const OPEN_SYSTEM_MESSAGE_DIALOG = "OPEN_SYSTEM_MESSAGE_DIALOG";
export const CLOSE_SYSTEM_MESSAGE_DIALOG = "CLOSE_SYSTEM_MESSAGE_DIALOG";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const TOGGLE_MEDIA_TYPE_CONFIRM_POPUP =
  "TOGGLE_MEDIA_TYPE_CONFIRM_POPUP";
export const EDIT_AGENCY_RULE = "EDIT_AGENCY_RULE";

// api methods
export const GET = "get";
export const POST = "post";
export const PUT = "put";
export const DELETE = "delete";

// events
export const ACTION_CREATE = "create";
export const ACTION_EDIT = "edit";

// traffic
export const TOGGLE_TRAFFIC_ITEM_ACTION = "TOGGLE_TRAFFIC_ITEM_ACTION";
export const OPEN_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG =
  "OPEN_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG";
export const CLOSE_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG =
  "CLOSE_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG";
export const OPEN_TRAFFIC_ITEM_DELETE_DIALOG =
  "OPEN_TRAFFIC_ITEM_DELETE_DIALOG";
export const CLOSE_TRAFFIC_ITEM_DELETE_DIALOG =
  "CLOSE_TRAFFIC_ITEM_DELETE_DIALOG";
export const OPEN_ADD_TRAFFIC_DIALOG = "OPEN_ADD_TRAFFIC_DIALOG";
export const OPEN_ADD_ALL_TRAFFIC_ITEMS_DIALOG =
  "OPEN_ADD_ALL_TRAFFIC_ITEMS_DIALOG";
export const CLOSE_ADD_TRAFFIC_DIALOG = "CLOSE_ADD_TRAFFIC_DIALOG";
export const CLOSE_ADD_ALL_TRAFFIC_ITEMS_DIALOG =
  "CLOSE_ADD_ALL_TRAFFIC_ITEMS_DIALOG";
export const OPEN_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG =
  "OPEN_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG";
export const CLOSE_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG =
  "CLOSE_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG";

// application
export const OPEN_SYSTEM_NOTIFICATION_DIALOG =
  "OPEN_SYSTEM_NOTIFICATION_DIALOG";
export const OPEN_EDIT_TRAFFICKER_DIALOG = "OPEN_EDIT_TRAFFICKER_DIALOG";
export const CLOSE_SYSTEM_NOTIFICATION_DIALOG =
  "CLOSE_SYSTEM_NOTIFICATION_DIALOG";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const CLOSE_EDIT_TRAFFICKER_DIALOG = "CLOSE_EDIT_TRAFFICKER_DIALOG";

export const OPEN_TRAFFIC_EXPORT_VERSION_DIALOG =
  "OPEN_TRAFFIC_EXPORT_VERSION_DIALOG";
export const CLOSE_TRAFFIC_EXPORT_VERSION_DIALOG =
  "CLOSE_TRAFFIC_EXPORT_VERSION_DIALOG";

export const OPEN_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG =
  "OPEN_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG";
export const CLOSE_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG =
  "CLOSE_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG";

export const API_CALL_TRAFFIC_DASHBOARD_START =
  "API_CALL_TRAFFIC_DASHBOARD_START";

export const API_CALL_TRAFFIC_DASHBOARD_SUCCESS =
  "API_CALL_TRAFFIC_DASHBOARD_SUCCESS";

export const API_CALL_TRAFFIC_DASHBOARD_ERROR =
  "API_CALL_TRAFFIC_DASHBOARD_ERROR";

export const SET_REFETCH = "SET_REFETCH";
export const RESET_REFETCH = "RESET_REFETCH";

export const MAX_FILTER_NUMBER = 3;
export const MAX_FILTER_COPYPLAN = 1;
