import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { MenuItem, OutlinedInput } from "@mui/material";

import { classes, Select } from "./styles/trafficDigitalTemplateDropdown";

import { selectTrafficItemDigitalTemplateId } from "../../business/models/TrafficItem/trafficItemSelectors";

import { isNullOrUndefined } from "../../functions/util";
import api from "../../functions/api";
import generateUrl from "../../business/models/common/utils/urlUtils";
import { GET_TRAFFIC_DIGITAL_TEMPLATES } from "../../configurations/apiUrls";
import { enqueueApiErrorMessage } from "../../business/actions/applicationActions";
import { setTrafficItemDigitalTemplate } from "../../business/models/TrafficItem/trafficItemActions";
import { EMPTY_STRING } from "../../configurations/appConstants";
import { selectAgencyId } from "../../business/models/common/utils/appUtils";

const TrafficDigitalTemplateSelect = ({ disabled }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const value = useSelector(selectTrafficItemDigitalTemplateId) || EMPTY_STRING;
  const { agency } = useParams();

  const onChange = val => {
    dispatch(setTrafficItemDigitalTemplate(val));
  };

  useEffect(() => {
    const agencyId = selectAgencyId(agency);
    const url = generateUrl(GET_TRAFFIC_DIGITAL_TEMPLATES, {
      agency: agencyId
    });

    api
      .get(url)
      .then(response => {
        setOptions(response.items);
      })
      .catch(error => {
        dispatch(enqueueApiErrorMessage(error));
      });
    return () => {
      dispatch(setTrafficItemDigitalTemplate(EMPTY_STRING));
    };
  }, [agency, dispatch]);

  const handleChange = event => {
    event.stopPropagation();
    onChange(event.target.value);
  };

  const handleClick = event => {
    event.stopPropagation();
  };

  const valueOption = options.find(option => option.key === value);
  const isValueInOption = !isNullOrUndefined(valueOption);

  return (
    <Select
      value={value}
      classes={{
        icon: classes.arrowDropDownIcon,
        select: classes.outlinedInput
      }}
      className={classes.select}
      disabled={disabled}
      onChange={handleChange}
      onClick={handleClick}
      input={
        <OutlinedInput
          name={`select_traffic_template`}
          id={`select_traffic_template`}
        />
      }
    >
      {!isValueInOption && (
        <MenuItem
          value={value}
          key={`ti-template-${value}`}
          name={`ti-template-${value}`}
          disabled={true}
        >
          No options
        </MenuItem>
      )}
      {options.map(({ key, text, disabledItem }) => (
        <MenuItem
          key={key}
          id={`ti-template-${key}`}
          name={`ti-template-${key}`}
          value={key}
          disabled={disabledItem}
        >
          {text}
        </MenuItem>
      ))}
    </Select>
  );
};

TrafficDigitalTemplateSelect.propTypes = {
  disabled: PropTypes.bool
};

TrafficDigitalTemplateSelect.defaultProps = {
  disabled: false
};

export default TrafficDigitalTemplateSelect;
