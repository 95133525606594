import {
  styled,
  Typography as MuiTypography,
  Chip as MuiChip
} from "@mui/material";
import {
  matisse,
  crusta,
  baliHai,
  solitude,
  white
} from "../../../../../../assets/styles/colors";

const PREFIX = "ActionInsertionItemStyle";
export const classes = {
  typographyInsertionHeader: `${PREFIX}-typographyInsertionHeader`,
  typographyInvoicedInsertionHeader: `${PREFIX}-typographyInvoicedInsertionHeader`,
  chip: `${PREFIX}-chip`,
  whiteChip: `${PREFIX}-whiteChip`,
  redLabelChip: `${PREFIX}-redLabelChip`,
  insertionIconButton: `${PREFIX}-insertionIconButton`
};

export const Typography = styled(MuiTypography)(({ theme }) => ({
  [`&.${classes.typographyInsertionHeader}`]: {
    color: matisse,
    width: theme.typography.pxToRem(195),
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(theme.spacing(1)),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`&.${classes.typographyInvoicedInsertionHeader}`]: {
    color: crusta
  }
}));

export const Chip = styled(MuiChip)(({ theme }) => ({
  [`&.${classes.chip}`]: {
    color: baliHai,
    marginLeft: theme.typography.pxToRem(theme.spacing(1)),
    minWidth: theme.typography.pxToRem(80),
    backgroundColor: solitude,
    padding: theme.typography.pxToRem(10)
  },
  [`&.${classes.whiteChip}`]: {
    backgroundColor: white
  },
  [`&.${classes.redLabelChip}`]: {
    color: crusta
  },
  [`&.${classes.insertionIconButton}`]: {
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(32)
  }
}));
