import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, ListItem } from "@mui/material";

import InsertionForm from "../../pages/create/forms/InsertionForm";
import CalculationForm from "../../pages/create/forms/CalculationForm";

import { getEditingInsertionsIDs } from "../../../business/models/Insertion/insertionSelectors";
import {
  deleteInsertion,
  cancelInsertions
} from "../../../business/models/Insertion/insertionActions";
import { updateOrder } from "../../../business/models/Order/orderActions";
import {
  isOrderCancelledOrToBeCancelled,
  isOrderPendingForApproval,
  selectCurrentOrder
} from "../../../business/models/Order/orderSelectors";
import { selectIsLoading } from "../../../business/selectors/applicationSelectors";
import { classes, List } from "../styles/insertion";

const InsertionsEdit = () => {
  const dispatch = useDispatch();

  const orderId = useSelector(selectCurrentOrder);
  const isCancelledOrToBeCancelled = useSelector(
    isOrderCancelledOrToBeCancelled
  );
  const isPendingForApproval = useSelector(isOrderPendingForApproval);
  const editingInsertionsIDs = useSelector(getEditingInsertionsIDs);
  const isLoading = useSelector(selectIsLoading);
  const disableButton = isCancelledOrToBeCancelled || isPendingForApproval;

  const handleDelete = id => {
    dispatch(deleteInsertion(id));
  };

  const handleSave = () => {
    dispatch(updateOrder());
  };

  const handleCancel = () => {
    dispatch(cancelInsertions());
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <List className={classes.table}>
          {editingInsertionsIDs.map((insertionId, index) => (
            <ListItem key={insertionId} className={classes.tableRow}>
              <InsertionForm
                entityId={insertionId}
                onDelete={handleDelete}
                disableDelete={disableButton || index === 0}
                orderId={orderId}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <CalculationForm
          isLoading={isLoading}
          onSave={handleSave}
          disableSave={disableButton}
          onCancel={handleCancel}
          showSave
          orderId={orderId}
        />
      </Grid>
    </Grid>
  );
};

export default InsertionsEdit;
