import React, { useState } from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import ChipEdit from "./chips/ChipEdit";
import ChipView from "./chips/ChipView";
import { EMPTY_STRING } from "../../../configurations/appConstants";

const Filter = ({
  value,
  property,
  addFilter,
  removeFilter,
  options,
  optionText,
  id
}) => {
  const [edit, setEdit] = useState(false);

  const cancelEdit = () => setEdit(false);

  const startEdit = () => setEdit(true);

  return (
    <>
      {edit ? (
        <Grid item>
          <ChipEdit
            id={id}
            filterProperty={property}
            filterValue={value}
            commit={addFilter}
            options={options}
            cancelEdit={cancelEdit}
          />
        </Grid>
      ) : (
        <Grid item>
          <ChipView
            id={id}
            onClick={startEdit}
            filterPropertyText={optionText}
            filterProperty={property}
            filterValue={value}
            onDelete={removeFilter}
          />
        </Grid>
      )}
    </>
  );
};

Filter.propTypes = {
  value: PropTypes.string,
  property: PropTypes.string,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  optionText: PropTypes.string.isRequired
};

Filter.defaultProps = {
  value: EMPTY_STRING,
  options: [],
  optionText: EMPTY_STRING
};

export default Filter;
