import { createSelector } from "reselect";
import { isNullOrUndefined } from "../../functions/util";

const actionPage = state => state.actionPage;
const selectTable = state => state.actionPage.table;
const selectOrderId = (_, props) => props.id;
const selectMarathonOrderId = (_, props) => props.marathonOrderId;

export const selectItems = createSelector(selectTable, table => table.items);

export const selectCount = createSelector(selectTable, table => table.count);

export const selectSendStatuses = createSelector(
  actionPage,
  action => action.sendStatuess
);

export const selectUnsendStatuses = createSelector(
  actionPage,
  action => action.unsendStatuses
);

export const getSelected = createSelector(
  actionPage,
  action => action.selected
);

export const selectIsSelected = createSelector(
  [actionPage, selectOrderId],
  (action, orderId) => {
    return action.selected.includes(orderId);
  }
);

export const selectSelectedNumber = createSelector(
  actionPage,
  action => action.selectedNumber
);

export const selectOpenDialog = createSelector(
  actionPage,
  action => action.openDialog
);

export const selectAction = createSelector(actionPage, action => action.action);

export const SelectIsStatusSuccessful = createSelector(
  [actionPage, selectMarathonOrderId],
  (action, id) => {
    const { sendStatuses } = action;
    return sendStatuses ? sendStatuses.includes(id) : false;
  }
);

export const selectOpenCompareDialog = createSelector(
  actionPage,
  action => action.openCompareDialog
);

export const selectCompareOrderId = createSelector(
  actionPage,
  action => action.compareOrderId
);

export const selectIsCompareOrderSelected = createSelector(
  [getSelected, selectCompareOrderId],
  (selected, orderId) => {
    return isNullOrUndefined(orderId) ? false : selected.includes(orderId);
  }
);
