import cuid from "cuid";
import { get, set, forEach } from "lodash";
import { selectAgency } from "../../selectors/applicationSelectors";
import generateUrl from "../common/utils/urlUtils";
import TrafficTemplateModel from "./TrafficTemplate";
import {
  apiCall,
  enqueueApiErrorMessage,
  enqueueNotificationMessage
} from "../../actions/applicationActions";
import { POST, DELETE, PUT, GET } from "../../constants/applicationConstants";
import {
  SAVE_TRAFFIC_TEMPLATE,
  DELETE_TRAFFIC_TEMPLATE,
  UPDATE_TRAFFIC_TEMPLATE,
  GET_TRAFFIC_DIGITAL_TEMPLATE
} from "../../../configurations/apiUrls";
import {
  TRAFFIC_TEMPLATE_MODEL_NAME,
  TRAFFIC_TEMPLATE_SELECT,
  TRAFFIC_TEMPLATE_EDIT_START,
  TRAFFIC_TEMPLATE_SET_IS_NEW,
  TRAFFIC_TEMPLATE_CLEAR_CURRENT,
  TRAFFIC_TEMPLATE_EDIT_STOP,
  TRAFFIC_TEMPLATE_RESET_IS_NEW,
  TRAFFIC_TEMPLATE_HIDE_ERRORS,
  TRAFFIC_TEMPLATE_MODEL_ID,
  TRAFFIC__TEMPLATE_SUCCESS_MESSAGE,
  TRAFFIC_TEMPLATE_DELETE_SUCCESS_MESSAGE,
  TRAFFIC_TEMPLATE_DISPLAY_ERRORS
} from "./trafficTemplateConstants";
import {
  editItemAttributes,
  editNewItem,
  stopEditingItem,
  commitData,
  editExistingItem
} from "../common/editing/editingActions";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import {
  selectIsEditingTrafficTemplate,
  selectCurrentTrafficTemplate,
  getEditingTrafficTemplate,
  doesTrafficTemplateHaveErrors,
  doesTrafficTemplateExistsById
} from "./trafficTemplateSelectors";
import { deleteEntity } from "../common/entities/entityActions";
import { getQueryObj, generateParamObj } from "../common/utils/modelUtils";
import fetchApiDataIfNeeded from "../../actions/apiDataActions";
import {
  selectTrafficItemDigitalTemplateId,
  selectCurrentTrafficItem
} from "../TrafficItem/trafficItemSelectors";
import { setTrafficItemValue } from "../TrafficItem/trafficItemActions";

// TABLE

export function loadTrafficTableMediaData(data) {
  return (dispatch, getState) => {
    const configuration = TrafficTemplateModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url));
      }
    });
  };
}

export function loadEditingTrafficTemplateMediaData(data) {
  return (dispatch, getState) => {
    const configuration = TrafficTemplateModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url));
      }
    });
  };
}

// DIALOG

// helpers
export function clearCurrentTrafficTemplate() {
  return dispatch => {
    dispatch({ type: TRAFFIC_TEMPLATE_CLEAR_CURRENT });
  };
}
export function stopEditingTrafficTemplate() {
  return (dispatch, getState) => {
    const currentTrafficTemplate = selectCurrentTrafficTemplate(getState());

    dispatch(
      stopEditingItem(TRAFFIC_TEMPLATE_MODEL_NAME, currentTrafficTemplate)
    );
    dispatch({ type: TRAFFIC_TEMPLATE_EDIT_STOP });
    dispatch({ type: TRAFFIC_TEMPLATE_HIDE_ERRORS });
    dispatch({ type: TRAFFIC_TEMPLATE_RESET_IS_NEW });
  };
}

export function startEditingTrafficTemplate() {
  return (dispatch, getState) => {
    const currentTrafficTemplate = selectCurrentTrafficTemplate(getState());

    dispatch(
      editExistingItem(TRAFFIC_TEMPLATE_MODEL_NAME, currentTrafficTemplate)
    );

    dispatch({ type: TRAFFIC_TEMPLATE_EDIT_START });

    const editingData = getEditingTrafficTemplate(getState());
    dispatch(loadEditingTrafficTemplateMediaData(editingData));
  };
}

export function displayTrafficTemplateErrors() {
  return { type: TRAFFIC_TEMPLATE_DISPLAY_ERRORS };
}

// actions

export const setTrafficTemplateValue = data => {
  return (dispatch, getState) => {
    const currentTrafficTemplate = selectCurrentTrafficTemplate(getState());

    dispatch(
      editItemAttributes(
        TRAFFIC_TEMPLATE_MODEL_NAME,
        currentTrafficTemplate,
        data
      )
    );
  };
};

export function selectTrafficTemplate(templateID) {
  return (dispatch, getState) => {
    const state = getState();
    const id = templateID;
    const isEditing = selectIsEditingTrafficTemplate(state);

    if (isEditing) {
      dispatch(stopEditingTrafficTemplate());
    }

    dispatch({ type: TRAFFIC_TEMPLATE_CLEAR_CURRENT });
    dispatch({
      type: TRAFFIC_TEMPLATE_SELECT,
      payload: id
    });
  };
}

export function addNewTrafficTemplate() {
  return (dispatch, getState) => {
    const session = getUnsharedEntitiesSession(getState());
    const { TrafficTemplate } = session;

    const id = cuid();
    const newTrafficTemplate = TrafficTemplate.generate({ id });
    const TrafficTemplateContents = newTrafficTemplate.toJSON();

    dispatch(
      editNewItem(TRAFFIC_TEMPLATE_MODEL_NAME, id, TrafficTemplateContents)
    );
    dispatch(selectTrafficTemplate(id));
    dispatch({ type: TRAFFIC_TEMPLATE_EDIT_START });
    dispatch({ type: TRAFFIC_TEMPLATE_SET_IS_NEW });
  };
}

export const commitDataIfMeetsQuery = (data, query) => {
  return dispatch => {
    const { templateName, remarks } = query;
    const tempName = get(data, "templateName");
    const tempRemarks = get(data, "remarks");

    if (
      tempName?.includes(templateName) ||
      tempRemarks?.includes(remarks) ||
      (!templateName && !remarks)
    ) {
      dispatch(commitData(TRAFFIC_TEMPLATE_MODEL_NAME, data.id, data));
    }
  };
};

export function createTrafficTemplate(query) {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesTrafficTemplateHaveErrors(state);
    if (hasErrors) {
      dispatch(displayTrafficTemplateErrors());
      return Promise.reject();
    }
    const agencyID = selectAgency(getState());
    const url = generateUrl(SAVE_TRAFFIC_TEMPLATE, {
      agency: agencyID
    });
    const body = getEditingTrafficTemplate(state);

    return dispatch(apiCall(POST, url, body)).then(
      response => {
        const id = get(response, TRAFFIC_TEMPLATE_MODEL_ID);
        const responseData = set(response, "id", id);
        dispatch(commitDataIfMeetsQuery(responseData, query));
        dispatch(selectTrafficTemplate(id));
        dispatch(stopEditingTrafficTemplate());
        dispatch({ type: TRAFFIC_TEMPLATE_RESET_IS_NEW });
        dispatch(enqueueNotificationMessage(TRAFFIC__TEMPLATE_SUCCESS_MESSAGE));
        return !!id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export const deleteTrafficTemplate = id => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const url = generateUrl(DELETE_TRAFFIC_TEMPLATE, {
    agency: agencyID,
    [TRAFFIC_TEMPLATE_MODEL_ID]: id
  });

  return dispatch(apiCall(DELETE, url)).then(
    () => {
      dispatch(
        enqueueNotificationMessage(TRAFFIC_TEMPLATE_DELETE_SUCCESS_MESSAGE)
      );

      dispatch(deleteEntity(TRAFFIC_TEMPLATE_MODEL_NAME, id));
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export function updateTrafficTemplate() {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesTrafficTemplateHaveErrors(state);
    if (hasErrors) {
      dispatch(displayTrafficTemplateErrors());
      return Promise.reject();
    }

    const agencyID = selectAgency(getState());
    const url = generateUrl(UPDATE_TRAFFIC_TEMPLATE, {
      agency: agencyID
    });
    const body = getEditingTrafficTemplate(state);

    return dispatch(apiCall(PUT, url, body)).then(
      response => {
        const id = get(response, TRAFFIC_TEMPLATE_MODEL_ID);
        const responseData = set(response, "id", id);
        dispatch(commitData(TRAFFIC_TEMPLATE_MODEL_NAME, id, responseData));
        dispatch(stopEditingTrafficTemplate());
        dispatch(clearCurrentTrafficTemplate());
        dispatch(enqueueNotificationMessage(TRAFFIC__TEMPLATE_SUCCESS_MESSAGE));
        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export const fetchTemplateDataIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  const templateId = selectTrafficItemDigitalTemplateId(state);
  const templateExists = doesTrafficTemplateExistsById(state, {
    id: templateId
  });
  const agencyId = selectAgency(state);

  const url = generateUrl(GET_TRAFFIC_DIGITAL_TEMPLATE, {
    agency: agencyId,
    [TRAFFIC_TEMPLATE_MODEL_ID]: templateId
  });

  if (templateExists) return Promise.resolve(templateId);

  if (templateId) {
    return dispatch(apiCall(GET, url)).then(
      response => {
        const { template } = response;
        const id = get(template, TRAFFIC_TEMPLATE_MODEL_ID);
        const templateData = set(template, "id", id);
        dispatch(commitData(TRAFFIC_TEMPLATE_MODEL_NAME, id, templateData));
        return Promise.resolve(id);
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  }
  return Promise.reject();
};

export const MapTemplateToTrafficItem = () => (dispatch, getState) => {
  const state = getState();

  const session = getUnsharedEntitiesSession(state);
  const { TrafficTemplate, TrafficItem } = session;

  const trafficTemplateId = selectTrafficItemDigitalTemplateId(state);
  const trafficItemId = selectCurrentTrafficItem(state);

  const trafficItem = TrafficItem.get(ti => ti.id === trafficItemId)?.ref;
  const trafficTemplate = TrafficTemplate.get(
    template => template.id === trafficTemplateId
  )?.ref;

  const newRemark =
    trafficItem?.trafficItemRemarks && trafficTemplate.remarks
      ? trafficItem.trafficItemRemarks.concat(`\n${trafficTemplate.remarks}`)
      : trafficItem?.trafficItemRemarks || trafficTemplate.remarks;

  const newTrafficItem = {
    ...trafficTemplate,
    trafficItemContact: trafficTemplate.contact,
    trafficItemRemarks: newRemark,
    trafficItemDescription: trafficTemplate.description,
    trafficItemId,
    id: trafficItemId
  };

  dispatch(setTrafficItemValue(newTrafficItem));
};
