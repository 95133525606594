import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { DialogContent, Grid, Button, DialogActions } from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import Scrollbar from "../../../shared/Scrollbar";
import ChangeTrafficItemStatusDialogItem from "./extensions/ChangeTrafficItemDialogItem";
import TrafficStatusDialogSelect from "../../../form/TrafficStatusDialogSelect";

import { getTrafficItemsForChangeStatus } from "../../../../business/models/TrafficItem/trafficItemSelectors";
import { selectOpenTrafficItemStatusChangeDialog } from "../../../../business/selectors/applicationSelectors";
import {
  closeDialog,
  changeStatusForTrafficItems
} from "../../../../business/models/TrafficPlan/trafficPlanActions";

import { classes, Dialog } from "../styles/changeTrafficStatusDialog";
import withRouter from "../../configuration/form/withRouter";

class ChangeTrafficStatusDialog extends Component {
  state = {
    selectedStatus: 0
  };

  handleSelectChange = value => {
    this.setState({
      selectedStatus: value
    });
  };

  handleApply = () => {
    const { selectedStatus } = this.state;
    this.props.changeStatusForTrafficItems(selectedStatus);

    this.setState({
      selectedStatus: 0
    });
  };

  handleCancel = () => {
    this.setState({
      selectedStatus: 0
    });
    this.props.onCancel();
  };

  render() {
    const { open, trafficItems } = this.props;
    const statusSelected = this.state.selectedStatus !== 0;

    return (
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onExited={this.handleOnExited}
        onExit={this.handleOnExit}
      >
        <DialogTitle id="action-dialog-title">Change status</DialogTitle>
        <DialogContent id="action-dialog-content" sx={{ mt: 1.5 }}>
          <Scrollbar>
            {trafficItems.map(trafficItem => (
              <ChangeTrafficItemStatusDialogItem
                key={trafficItem.trafficItemId}
                id={trafficItem.trafficItemId}
                trafficItem={trafficItem}
              />
            ))}
          </Scrollbar>
        </DialogContent>
        <DialogActions id="action-dialog-actions">
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                id="cancel_traffic_status_dialog"
                name="cancel_traffic_status_dialog"
                onClick={this.handleCancel}
                className={classes.buttonCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <TrafficStatusDialogSelect
                    value={this.state.selectedStatus}
                    name="trafficItemStatusId"
                    onChange={this.handleSelectChange}
                  />
                </Grid>
                <Grid item>
                  <Button
                    id="apply_traffic_status_dialog"
                    name="apply_traffic_status_dialog"
                    onClick={this.handleApply}
                    variant="contained"
                    disabled={!statusSelected}
                    className={clsx(
                      classes.buttonSave,
                      classes.buttonApply,
                      classes.buttonSpace
                    )}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

ChangeTrafficStatusDialog.propTypes = {
  open: PropTypes.bool
};

const mapStateToProps = (state, params) => {
  const trafficItems = getTrafficItemsForChangeStatus(state);

  const selectedNumber = trafficItems.length;
  const disabled = selectedNumber === 0;

  return {
    open: selectOpenTrafficItemStatusChangeDialog(state),
    trafficItems,
    disabled,
    agency: params.agency
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCancel: () => dispatch(closeDialog()),
    changeStatusForTrafficItems: statusId =>
      dispatch(changeStatusForTrafficItems(statusId))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangeTrafficStatusDialog)
);
