import { styled, Grid as MuiGrid } from "@mui/material";
import { white, dodgerBlue } from "../../../assets/styles/colors";

const PREFIX = "HdtImagePageStyles";
export const classes = {
  root: `${PREFIX}-root`,
  gridList: `${PREFIX}-gridList`,
  gridTile: `${PREFIX}-gridTile`,
  hdtButton: `${PREFIX}-hdtButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`&.${classes.gridList}`]: {
    height: "auto",
    transform: "translateZ(0)"
  },
  [`&.${classes.gridTile}`]: {
    width: "45% !important"
  },
  [`&.${classes.hdtButton}`]: {
    color: white,
    backgroundColor: dodgerBlue
  }
}));
