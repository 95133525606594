import React, { memo } from "react";
import PropTypes from "prop-types";

import { TableHead, TableCell, Grid } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import TablePaginationActionsWrapped from "./TablePaginationActions";
import Sort from "./Sort";
import { EMPTY_STRING } from "../../../../configurations/appConstants";
import { classes, TableRow } from "../styles/table";

const TableOverviewHeader = ({
  count,
  pageSize,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPageOptions,
  sortValue,
  onSortChange,
  selectProps,
  contentLoading,
  backIconButtonProps,
  nextIconButtonProps,
  showTableControls
}) => {
  const disableAction = { disabled: contentLoading };

  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        <TableCell>
          {showTableControls && (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Sort sortValue={sortValue} onSortChange={onSortChange} />
              </Grid>
              <Grid item>
                <TablePagination
                  component="div"
                  colSpan={3}
                  count={count}
                  rowsPerPage={pageSize}
                  page={count ? currentPage : 0}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                  SelectProps={{ ...selectProps, ...disableAction }}
                  backIconButtonProps={{
                    ...backIconButtonProps,
                    ...disableAction
                  }}
                  nextIconButtonProps={{
                    ...nextIconButtonProps,
                    ...disableAction
                  }}
                />
              </Grid>
            </Grid>
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

TableOverviewHeader.propTypes = {
  count: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.array,
  onSortChange: PropTypes.func.isRequired,
  sortValue: PropTypes.string,
  selectProps: PropTypes.object,
  backIconButtonProps: PropTypes.object,
  nextIconButtonProps: PropTypes.object,
  contentLoading: PropTypes.bool
};

TableOverviewHeader.defaultProps = {
  count: 0,
  pageSize: 10,
  currentPage: 0,
  rowsPerPageOptions: [5, 10, 25],
  sortValue: EMPTY_STRING,
  selectProps: {
    native: true
  },
  contentLoading: false
};

export default memo(TableOverviewHeader);
