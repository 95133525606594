import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const config: Configuration = {
  auth: {
    authority:
      "https://login.microsoftonline.com/86cbe1bb-213f-4271-b174-bd59d03c87a3",
    clientId: "ec0254d9-977e-4d97-93d7-243563e8a89a",
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
};

// eslint-disable-next-line import/prefer-default-export
export const authProvider = new PublicClientApplication(config);
