import {
  styled,
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell
} from "@mui/material";
import { SIDE_BAR_OPEN_WIDTH } from "../../../../configurations/appConstants";
import { solitude } from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "TableStyles";
export const classes = {
  table: `${PREFIX}-table`,
  tableRow: `${PREFIX}-tableRow`,
  tableCell: `${PREFIX}-tableCell`
};

export const Table = styled(MuiTable)(({ theme }) => ({
  [`&.${classes.table}`]: {
    minWidth: theme.typography.pxToRem(window.innerWidth - SIDE_BAR_OPEN_WIDTH),
    overflowX: "auto",
    border: `1px solid ${solitude}`,
    borderCollapse: "separate",
    marginTop: theme.typography.pxToRem(6)
  }
}));

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
  [`&.${classes.tableRow}`]: {
    height: theme.typography.pxToRem(70)
  }
}));

export const TableCell = styled(MuiTableCell)(() => ({
  [`&.${classes.tableCell}`]: {
    border: "none",
    padding: "0.1875rem 1.125rem 0.1175rem 1.125rem"
  }
}));
