import { styled, Dialog as MuiDialog } from "@mui/material";
import { crusta, azureRadiance } from "../../../../assets/styles/colors";

const PREFIX = "AdmanagementDialogStyles";
export const classes = {
  buttonClose: `${PREFIX}-buttonClose`,
  buttonContinue: `${PREFIX}-buttonContinue`
};

export const Dialog = styled(MuiDialog)(() => ({
  [`& .${classes.buttonClose}`]: {
    color: crusta
  },
  [`& .${classes.buttonContinue}`]: {
    color: azureRadiance
  }
}));
