import React from "react";
import PropTypes from "prop-types";

import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";

import { DASH_PLACEHOLDER } from "../../../../../../configurations/appConstants";
import { EditIcon, DeleteIcon } from "../../../../../../assets/styles/icons";

import { trimString } from "../../../../../../business/models/common/utils/clientUtils";
import { isNullOrUndefinedOrEmpty } from "../../../../../../functions/util";

const SecondRow = ({ data, onHandleEdit, onHandleDelete }) => {
  const {
    id,
    weeklyDistribution,
    impressions,
    positionInBreak,
    views,
    preferredPosition,
    primetimeShare,
    remarksRestrictions
  } = data;
  const stringLength = 12;
  return (
    <TableRow>
      <TableCell align="left">
        <Tooltip title={weeklyDistribution || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(weeklyDistribution)
              ? trimString(weeklyDistribution, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={impressions || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(impressions)
              ? trimString(impressions, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={positionInBreak || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(positionInBreak)
              ? trimString(positionInBreak, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={views || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(views)
              ? trimString(views, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={preferredPosition || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(preferredPosition)
              ? trimString(preferredPosition, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={primetimeShare || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(primetimeShare)
              ? trimString(primetimeShare, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={remarksRestrictions || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(remarksRestrictions)
              ? trimString(remarksRestrictions, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <IconButton
          name={`tmkpi_edit_${id}`}
          id={`tmkpi_edit_${id}`}
          onClick={() => onHandleEdit(id)}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell align="left">
        <IconButton
          name={`tmkpi_delete_${id}`}
          id={`tmkpi_delete_${id}`}
          onClick={() => onHandleDelete(id)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

SecondRow.propTypes = {
  onHandleEdit: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired
};

export default SecondRow;
