import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";

import { Grow, ClickAwayListener, Grid, Typography } from "@mui/material";

import { PLACEHOLDER } from "../../../../../../configurations/appConstants";
import { isNullOrUndefined } from "../../../../../../functions/util";

import { classes, Paper, Popper } from "../styles/actionOrderElementPopper";
import {
  getOrderCalculationData,
  getPropertyTextFromApiData
} from "../../../../../../business/models/Order/orderSelectors";

const ActionOrderElementPopper = ({
  orderId,
  open,
  onClose,
  buttonRef,
  description,
  orderPoNumber,
  targetAudience,
  position,
  frequencyCap,
  accountId,
  numOfUnits,
  unit,
  agencyDiscountAmount,
  netTotal,
  discountAmount,
  remarks,
  surcharge1Amount,
  surcharge2Amount
}) => {
  // #region ApiData values
  const statusText = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "statusId", orderId })
  );

  const surcharge2Type = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "surcharge2TypeId", orderId })
  );

  const surcharge1Type = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "surcharge1TypeId", orderId })
  );

  const discountCode = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "discountCodeId", orderId })
  );

  const costType = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "costTypeId", orderId })
  );

  const format = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "formatId", orderId })
  );

  const digitalChannel = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "digitalChannelId", orderId })
  );

  const agreement = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "orderAgreementId", orderId })
  );

  const channel = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "channelId", orderId })
  );
  // #endregion

  const calculation = useSelector(state =>
    getOrderCalculationData(state, { orderId })
  );

  const {
    fee,
    grossPerUnit,
    grossTotal,
    netNetPerUnit,
    netNetTotal,
    netPerUnit,
    totalCostClient
  } = calculation;
  return (
    <Popper
      open={open}
      anchorEl={buttonRef.current}
      placement="bottom-start"
      transition
      className={classes.orderPopper}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          id="ao-view-grow"
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.detailsPanel}>
            <ClickAwayListener onClickAway={onClose}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Typography
                    id="channel"
                    name="channel"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {channel || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Channel
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="description"
                    name="description"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {description || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="agreement"
                    name="agreement"
                    align="left"
                    className={classes.typographyDetailsValue}
                    noWrap
                  >
                    {agreement || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Agreement
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="orderPoNumber"
                    name="orderPoNumber"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {orderPoNumber || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    PO Number
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="targetAudience"
                    name="targetAudience"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {targetAudience || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Target Audience
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="digitalChannel"
                    name="digitalChannel"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {digitalChannel || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Digital Channel
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="format"
                    name="format"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {format || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Format
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="position"
                    name="position"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {position || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Position
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="frequencyCap"
                    name="frequencyCap"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {frequencyCap || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Frequency Cap
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="accountId"
                    name="accountId"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {accountId || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    AccountID
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="costType"
                    name="costType"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {costType || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Cost Type
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(numOfUnits) ? (
                      <NumberFormat
                        id="numOfUnits"
                        name="numOfUnits"
                        decimalScale={2}
                        value={numOfUnits}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    # of Units
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="unit"
                    name="unit"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {unit || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Unit
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(grossTotal) ? (
                      <NumberFormat
                        id="grossTotal"
                        name="grossTotal"
                        decimalScale={2}
                        value={grossTotal}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Gross Total
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(grossPerUnit) ? (
                      <NumberFormat
                        id="grossPerUnit"
                        name="grossPerUnit"
                        decimalScale={2}
                        value={grossPerUnit}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Gross per Unit
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="discountCode"
                    name="discountCode"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {discountCode || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Discount Type
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(discountAmount) ? (
                      <NumberFormat
                        id="discountAmount"
                        name="discountAmount"
                        decimalScale={2}
                        value={discountAmount}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Discount Amount
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(netTotal) ? (
                      <NumberFormat
                        id="netTotal"
                        name="netTotal"
                        decimalScale={2}
                        value={netTotal}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Net Total
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(netPerUnit) ? (
                      <NumberFormat
                        id="netPerUnit"
                        name="netPerUnit"
                        decimalScale={2}
                        value={netPerUnit}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Net per Unit
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(agencyDiscountAmount) ? (
                      <NumberFormat
                        id="agencyDiscountAmount"
                        name="agencyDiscountAmount"
                        decimalScale={2}
                        value={agencyDiscountAmount}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Agency Discount
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(netNetTotal) ? (
                      <NumberFormat
                        id="netNetTotal"
                        name="netNetTotal"
                        decimalScale={2}
                        value={netNetTotal}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Net Net Total
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(netNetPerUnit) ? (
                      <NumberFormat
                        id="netNetPerUnit"
                        name="netNetPerUnit"
                        decimalScale={2}
                        value={netNetPerUnit}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Net Net per Unit
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(fee) ? (
                      <NumberFormat
                        id="fee"
                        name="fee"
                        decimalScale={2}
                        value={fee}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Fee Percentage
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="surcharge1Type"
                    name="surcharge1Type"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {surcharge1Type || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Surcharge Type 1
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(surcharge1Amount) ? (
                      <NumberFormat
                        id="surcharge1Amount"
                        name="surcharge1Amount"
                        decimalScale={2}
                        value={surcharge1Amount}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Surcharge Amount 1
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="surcharge2Type"
                    name="surcharge2Type"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {surcharge2Type || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Surcharge Type 2
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(surcharge2Amount) ? (
                      <NumberFormat
                        id="surcharge2Amount"
                        name="surcharge2Amount"
                        decimalScale={2}
                        value={surcharge2Amount}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Surcharge Amount 2
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(totalCostClient) ? (
                      <NumberFormat
                        id="totalCostClient"
                        name="totalCostClient"
                        decimalScale={2}
                        value={totalCostClient}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Total Cost Client
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="remarks"
                    name="remarks"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {remarks || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Order Remarks
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="statusText"
                    name="statusText"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {statusText || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Client Status
                  </Typography>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

ActionOrderElementPopper.propTypes = {
  orderId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  butttonRef: PropTypes.object,
  description: PropTypes.string,
  orderPoNumber: PropTypes.string,
  targetAudience: PropTypes.string,
  position: PropTypes.string,
  frequencyCap: PropTypes.string,
  accountId: PropTypes.string,
  numOfUnits: PropTypes.number,
  unit: PropTypes.string,
  agencyDiscountAmount: PropTypes.number,
  netTotal: PropTypes.number,
  discountAmount: PropTypes.number,
  remarks: PropTypes.string,
  surcharge1Amount: PropTypes.number,
  surcharge2Amount: PropTypes.number
};

export default ActionOrderElementPopper;
