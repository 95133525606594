import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";

export const selectSystemMessage = state => state.systemMessage;
export const selectPropertyName = (_, props) => props.name;
export const selectDefaultDisable = (_, props) => props.disabled;

export const selectCurrentSystemMessage = createSelector(
  selectSystemMessage,
  systemMessages => systemMessages.currentSystemMessage
);

export const selectIsEditingSystemMessage = createSelector(
  selectSystemMessage,
  systemMessages => systemMessages.isEditing
);

export const selectSystemMessageDisplayErrors = createSelector(
  selectSystemMessage,
  systemMessages => systemMessages.displayErrors
);

export const selectSystemMessageIsNew = createSelector(
  selectSystemMessage,
  systemMessage => systemMessage.isNew
);

export const getSystemMessages = createSelector(
  getEntitiesSession,
  entitiesSession => {
    const { SystemMessage } = entitiesSession;
    const systemMessages = SystemMessage.all().toRefArray();
    return systemMessages;
  }
);

export const hasLoadedSystemMessages = createSelector(
  getEntitiesSession,
  entitiesSession => {
    const { SystemMessage } = entitiesSession;

    return SystemMessage.all().count() > 0;
  }
);

export const getEditingSystemMessage = createSelector(
  [getEditingEntitiesSession, selectSystemMessage],
  (editingSession, systemMessage) => {
    const { SystemMessage } = editingSession;
    const editingSystemMessageModel = SystemMessage.filter(
      editingSystemMessage =>
        editingSystemMessage.id === systemMessage.currentSystemMessage
    ).first();

    return editingSystemMessageModel
      ? editingSystemMessageModel.ref
      : undefined;
  }
);

export const getSystemMessage = createSelector(
  [getEntitiesSession, selectSystemMessage],
  (entitiesSession, systemMessage) => {
    const { SystemMessage } = entitiesSession;
    const systemMessageModel = SystemMessage.get(
      c => c.id === systemMessage.currentSystemMessage
    );

    return systemMessageModel ? systemMessageModel.ref : undefined;
  }
);

export const getEditingSystemMessageErrors = createSelector(
  [getEditingEntitiesSession, selectSystemMessage],
  (editingSession, systemMessage) => {
    const { SystemMessage } = editingSession;
    const systemMessageModel = SystemMessage.filter(
      c => c.id === systemMessage.currentSystemMessage
    ).first();
    return systemMessageModel ? systemMessageModel.validate() : undefined;
  }
);

export const doesSystemMessageHaveErrors = createSelector(
  [getEditingEntitiesSession, selectSystemMessage],
  (editingSession, systemMessage) => {
    const { SystemMessage } = editingSession;
    const systemMessageModel = SystemMessage.get(
      c => c.id === systemMessage.currentSystemMessage
    );
    return systemMessageModel ? systemMessageModel.hasErrors() : false;
  }
);

export const getSystemMessagePropertyErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectSystemMessage, selectPropertyName],
  (editingSession, systemMessage, name) => {
    const { SystemMessage } = editingSession;
    const systemMessageModel = SystemMessage.get(
      c => c.id === systemMessage.currentSystemMessage
    );
    return systemMessageModel
      ? systemMessageModel.validateByProperty(name)
      : undefined;
  }
)((_, props) => props.name);

export const getSystemMessagePropertyValue = createCachedSelector(
  [getEditingSystemMessage, selectPropertyName],
  (systemMessage, name) => (systemMessage ? systemMessage[name] : undefined)
)((_, props) => props.name);

export const getSystemMessagePropertyPreviousValue = createCachedSelector(
  [getSystemMessage, selectPropertyName],
  (systemMessage, name) => (systemMessage ? systemMessage[name] : undefined)
)((_, props) => props.name);

export const isSystemMessageActive = createSelector(
  [getEditingEntitiesSession, selectCurrentSystemMessage],
  (editingSession, systemMessageId) => {
    const { SystemMessage } = editingSession;
    return SystemMessage.idExists(systemMessageId)
      ? SystemMessage.withId(systemMessageId).ref.isActive
      : false;
  }
);

export const selectRules = createSelector([isSystemMessageActive], isActive => {
  return {
    isSystemMessageNotActive: !isActive
  };
});

export const selectHasAttachment = createSelector(
  [getSystemMessage],
  systemMessage => (systemMessage ? systemMessage.hasAttachment : undefined)
);

export const selectFileName = createSelector(
  [getEditingSystemMessage],
  systemMessage => systemMessage?.file?.fileName
);
