import { styled, Paper as MuiPaper } from "@mui/material";
import {
  solitude,
  matisse,
  baliHai,
  crusta
} from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "SidePanelStyles";
export const classes = {
  sidePanel: `${PREFIX}-sidePanel`,
  sidePanelWrapper: `${PREFIX}-sidePanelWrapper`,
  divider: `${PREFIX}-divider`,
  typographyDetailsValue: `${PREFIX}-typographyDetailsValue`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  typographyHeaderValue: `${PREFIX}-typographyHeaderValue`,
  typographyHeaderCaption: `${PREFIX}-typographyHeaderCaption`,
  sideBox: `${PREFIX}-sideBox`,
  mark: `${PREFIX}-mark`,
  startSection: `${PREFIX}-startSection`
};

export const Paper = styled(MuiPaper)(({ theme }) => ({
  [`&.${classes.sidePanel}`]: {
    height: "100%",
    backgroundColor: solitude
  },
  [`& .${classes.sidePanelWrapper}`]: {
    padding: theme.typography.pxToRem(getThemeSpacing(theme, 2))
  },
  [`& .${classes.divider}`]: {},
  [`& .${classes.typographyDetailsValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(16)
  },
  [`& .${classes.typographyCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyHeaderValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(20)
  },
  [`& .${classes.typographyHeaderCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${classes.sideBox}`]: {
    marginTop: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    marginBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.mark}`]: {
    color: crusta
  },
  [`& .${classes.startSection}`]: {
    marginTop: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  }
}));
