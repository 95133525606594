import { useSelector } from "react-redux";

import { ListItem } from "@mui/material";

import InsertionViewItem from "./InsertionViewItem";
import { getInsertionsIDs } from "../../../business/models/Insertion/insertionSelectors";
import { classes, List } from "../styles/insertion";

const InsertionsView = ({ isInCopyProcess }) => {
  const insertions = useSelector(getInsertionsIDs);

  return (
    <List className={classes.table}>
      {insertions.map((id, key, { length }) => (
        <ListItem
          key={id}
          divider={length !== key + 1}
          className={classes.tableRow}
        >
          <InsertionViewItem id={id} isInCopyProcess={isInCopyProcess} />
        </ListItem>
      ))}
    </List>
  );
};

export default InsertionsView;
