import React, { useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import includes from "lodash/includes";
import filter from "lodash/filter";
import concat from "lodash/concat";

import {
  useQueryParams,
  StringParam,
  BooleanParam,
  DelimitedArrayParam
} from "use-query-params";

import ActionTable from "./table/ActionTable";

import { ACTION_PAGE, CAMPAIGN } from "../../../../configurations/appConstants";

import {
  loadItems,
  resetPage
} from "../../../../business/actions/actionPageActions";

import {
  selectItems,
  selectCount
} from "../../../../business/selectors/actionPageSelectors";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import { isNullOrUndefined } from "../../../../functions/util";

const filterQuerys = {
  campaignid: StringParam,
  campaignname: StringParam,
  planid: StringParam,
  planname: StringParam,
  orderid: StringParam,
  debtorname: StringParam,
  processmanager: StringParam
};

const ActionOverviewPage = () => {
  const dispatch = useDispatch();
  const { orderStatus: status, agency } = useParams();
  const navigate = useNavigate();

  const rows = useSelector(selectItems);
  const count = useSelector(selectCount);
  const isLoading = useSelector(selectIsLoading);

  const [query, setQuery] = useQueryParams({
    ...filterQuerys,
    searchMyCampaigns: BooleanParam,
    searchMediaTypes: DelimitedArrayParam,
    sort: StringParam,
    currentPage: StringParam,
    pageSize: StringParam
  });

  const {
    searchMyCampaigns,
    searchMediaTypes,
    sort,
    campaignid,
    campaignname,
    planid,
    planname,
    orderid,
    debtorname,
    processmanager,
    currentPage = 0,
    pageSize = 10
  } = query;

  const handleDynamicFilter = useCallback(
    filterQuery => {
      setQuery({ ...filterQuery, currentPage: 0 }, "pushIn");
    },
    [setQuery]
  );

  const handleSortChange = event => {
    setQuery({ sort: event.target.value, currentPage: 0 }, "pushIn");
  };

  const handleSearchMediaTypes = mediaType => {
    setQuery(
      {
        searchMediaTypes: includes(searchMediaTypes, mediaType)
          ? filter(searchMediaTypes, val => val !== mediaType)
          : concat(searchMediaTypes, mediaType),
        currentPage: 0
      },
      "pushIn"
    );
  };

  const handleSearchMyCampaigns = () => {
    setQuery(
      { searchMyCampaigns: !searchMyCampaigns, currentPage: 0 },
      "pushIn"
    );
  };

  const handleChangePage = (_, page) => {
    setQuery({ currentPage: page }, "pushIn");
  };

  const handleChangeRowsPerPage = event => {
    setQuery({ pageSize: event.target.value, currentPage: 0 }, "pushIn");
  };

  const handleClear = () => {
    setQuery({}, "push");
  };

  // Actions
  const handleOpenClick = (id, searchParam) => {
    if (!isNullOrUndefined(searchParam)) {
      navigate(`/${agency}/${ACTION_PAGE}/${CAMPAIGN}/${searchParam}`);
    } else {
      navigate(`/${agency}/${ACTION_PAGE}/${CAMPAIGN}/${id}`);
    }
  };

  useEffect(() => {
    dispatch(
      loadItems({
        currentPage,
        pageSize,
        searchMyCampaigns,
        searchMediaTypes,
        sort,
        status,
        campaignid,
        campaignname,
        planid,
        planname,
        orderid,
        debtorname,
        processmanager
      })
    );
  }, [
    currentPage,
    pageSize,
    searchMediaTypes,
    searchMyCampaigns,
    sort,
    status,
    campaignid,
    campaignname,
    planid,
    planname,
    orderid,
    debtorname,
    processmanager,
    dispatch
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetPage());
    };
  }, [dispatch]);

  return (
    <ActionTable
      rows={rows}
      count={count}
      pageSize={Number(pageSize)}
      currentPage={Number(currentPage)}
      searchMyCampaigns={searchMyCampaigns}
      searchMediaTypes={searchMediaTypes}
      sort={sort}
      status={status}
      contentLoading={isLoading}
      allowAction={true}
      onClear={handleClear}
      onSortChange={handleSortChange}
      onSearchMyCampaigns={handleSearchMyCampaigns}
      onSearchMediaTypes={handleSearchMediaTypes}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onOpenClick={handleOpenClick}
      onDynamicFilter={handleDynamicFilter}
      queryFilters={{
        campaignid,
        campaignname,
        planid,
        planname,
        orderid,
        debtorname,
        processmanager
      }}
    />
  );
};

export default ActionOverviewPage;
