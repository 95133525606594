import { styled, darken, alpha, Paper as MuiPaper } from "@mui/material";

const PREFIX = "ApprovalMatrixStyles";
export const classes = {
  paperRoot: `${PREFIX}-paperRoot`,
  tableCellUnderline: `${PREFIX}-tableCellUnderline`,
  scrollTableCell: `${PREFIX}-scrollTableCell`
};

export const Paper = styled(MuiPaper)(({ theme }) => ({
  [`&.${classes.paperRoot}`]: {
    border: `1px solid ${darken(alpha(theme.palette.divider, 1), 0.68)}`
  },
  [`& .${classes.tableCellUnderline}`]: {
    borderBottom: `1px solid ${darken(alpha(theme.palette.divider, 1), 0.68)}`
  },
  [`& .${classes.typographyInvoicedInsertionHeader}`]: {
    borderRight: `1px solid ${darken(alpha(theme.palette.divider, 1), 0.68)}`
  }
}));
