import React from "react";
import { useSelector } from "react-redux";

import { IconButton } from "@mui/material";

import InsertionField from "../../../form/InsertionField";
import InsertionCalculationField from "../../../form/InsertionCalculationField";

import { DeleteIcon } from "../../../../assets/styles/icons";

import { classes, Grid } from "../styles/insertionForm";

import { getCampaignData } from "../../../../business/models/Campaign/campaignSelectors";

const InsertionEditItem = ({ entityId, disableDelete, onDelete }) => {
  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const handleDelete = () => {
    onDelete(entityId);
  };

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={1} className={classes.insertionDate}>
        <InsertionField
          component="date"
          id="insertionInvoiceDate"
          name="insertionInvoiceDate"
          label="Invoice date *"
          entityId={entityId}
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionField
          component="decimal"
          id="insertionNumOfUnits"
          name="insertionNumOfUnits"
          label="# of units *"
          entityId={entityId}
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionField
          component="decimal"
          id="insertionDiscountAmount"
          name="insertionDiscountAmount"
          label="Disc. amount *"
          entityId={entityId}
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionCalculationField
          id="insertionNetPerUnit"
          name="insertionNetPerUnit"
          label="Net per unit"
          entityId={entityId}
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionField
          component="decimal"
          id="insertionNetAmount"
          name="insertionNetAmount"
          label="Net total *"
          entityId={entityId}
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionCalculationField
          id="insertionGrossTotal"
          name="insertionGrossTotal"
          label="Gross total"
          entityId={entityId}
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionField
          component="decimal"
          id="insertionAgencyDiscountAmount"
          name="insertionAgencyDiscountAmount"
          label="Agency disc. *"
          entityId={entityId}
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionCalculationField
          id="insertionNetNetTotal"
          name="insertionNetNetTotal"
          label="Net net total"
          entityId={entityId}
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionField
          component="decimal"
          id="insertionSurcharge1Amount"
          name="insertionSurcharge1Amount"
          label="Surchar. 1"
          entityId={entityId}
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionField
          component="decimal"
          id="insertionSurcharge2Amount"
          name="insertionSurcharge2Amount"
          label="Surchar. 2"
          entityId={entityId}
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionField
          component="text"
          id="insertionPoNumber"
          name="insertionPoNumber"
          label="PO number"
          entityId={entityId}
          maxLength={50}
        />
      </Grid>
      {isDebtorActive && (
        <Grid item xs={1}>
          <IconButton
            name={`i_delete_${entityId}`}
            id={`i_delete_${entityId}`}
            onClick={handleDelete}
            className={classes.deleteButton}
            disabled={disableDelete}
          >
            <DeleteIcon disabled={disableDelete} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default InsertionEditItem;
