import { createTheme } from "@mui/material";
import outherTheme from "../../theme";

const theme = createTheme(
  {
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: "0.6625rem"
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            fontSize: "0.75rem"
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          outlined: {
            "&$shrink": {
              transform: "translate(10px, -6px) scale(0.75)"
            }
          }
        }
      }
    }
  },
  outherTheme
);

export default theme;
