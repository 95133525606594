import get from "lodash/get";
import set from "lodash/set";
import {
  SET_ACTIVE_STEP,
  RESET_ACTIVE_STEP,
  SET_STEPS,
  RESET_STEPS,
  SET_PROCESS,
  CLEAR_STEPPER,
  SET_CAMPAIGN_STATUS,
  RESET_CAMPAIGN_STATUS,
  SET_CURRENT_ORDER_STEP,
  SET_ORDER_STEPS,
  RESET_ORDER_STEPS,
  RESET_CURRENT_ORDER_STEP,
  stepsEnum
} from "../constants/copyPlanConstants";
import { selectAgency } from "../selectors/applicationSelectors";
import { RESUME_COPY_PLAN } from "../../configurations/apiUrls";
import { apiCall, enqueueApiErrorMessage } from "./applicationActions";
import { GET } from "../constants/applicationConstants";
import generateUrl from "../models/common/utils/urlUtils";
import {
  ORDER_MODEL_ID,
  ORDER_MODEL_NAME
} from "../models/Order/orderConstants";
import { commitData } from "../models/common/editing/editingActions";
import { PLAN_MODEL_ID, PLAN_MODEL_NAME } from "../models/Plan/planConstants";
import {
  PLAN_EXTENSION_MODEL_ID,
  PLAN_EXTENSION_MODEL_NAME
} from "../models/PlanExtension/planExtensionConstants";
import {
  CAMPAIGN_MODEL_ID,
  CAMPAIGN_MODEL_NAME
} from "../models/Campaign/campaignConstants";
/* eslint-disable import/no-cycle */
import {
  startEditingCampaign,
  selectCampaign,
  addNewCampaign
} from "../models/Campaign/campaignActions";
import { selectPlan, startEditingPlan } from "../models/Plan/planActions";
import { selectOrder } from "../models/Order/orderActions";

export const setActiveStep = payload => ({
  type: SET_ACTIVE_STEP,
  payload
});

// TODO: remove this - not used
export const clearActiveStep = () => ({
  type: RESET_ACTIVE_STEP
});

// TODO: remove this - not used
export const setSteps = payload => ({
  type: SET_STEPS,
  payload
});

// TODO: remove this - not used
export const resetSteps = () => ({
  type: RESET_STEPS
});

export const setProcess = payload => ({
  type: SET_PROCESS,
  payload
});

export const setCampaignStatus = payload => ({
  type: SET_CAMPAIGN_STATUS,
  payload
});

// TODO: remove this - not used
export const resetCampaignStatus = () => ({
  type: RESET_CAMPAIGN_STATUS
});

export function clearStepper() {
  return {
    type: CLEAR_STEPPER
  };
}

export const setOrderSteps = payload => ({
  type: SET_ORDER_STEPS,
  payload
});

// TODO: remove this - not used
export const resetOrderSteps = () => ({
  type: RESET_ORDER_STEPS
});

export const setCurrentOrderStep = payload => ({
  type: SET_CURRENT_ORDER_STEP,
  payload
});

// TODO: remove this - not used
export const resetCurrentOrderStep = () => ({
  type: RESET_CURRENT_ORDER_STEP
});

/* eslint-disable no-extra-boolean-cast */
export const resumeCopyPlan = copyProcessId => {
  return (dispatch, getState) => {
    const state = getState();
    const agencyID = selectAgency(state);

    const url = generateUrl(RESUME_COPY_PLAN, {
      agency: agencyID,
      processId: copyProcessId
    });

    return dispatch(apiCall(GET, url)).then(
      response => {
        if (response) {
          const {
            step,
            campaign,
            plan,
            planExtension,
            order,
            orderStepsCount,
            currentOrderStep
          } = response;

          if (step === stepsEnum.CAMPAIGN) {
            dispatch(addNewCampaign());
          }

          if (step === stepsEnum.PLAN_EXTENSION) {
            const campaignId = get(campaign, CAMPAIGN_MODEL_ID);
            const campaignData = set(campaign, "id", campaignId);
            dispatch(commitData(CAMPAIGN_MODEL_NAME, campaignId, campaignData));
            dispatch(selectCampaign(campaignId));

            const planId = get(plan, PLAN_MODEL_ID);
            const planData = set(plan, "id", planId);
            dispatch(commitData(PLAN_MODEL_NAME, planId, planData));
            dispatch(selectPlan(planId));

            if (!!planExtension) {
              const planExtensionid = get(
                planExtension,
                PLAN_EXTENSION_MODEL_ID
              );
              const planExtensionData = set(
                planExtension,
                "id",
                planExtensionid
              );
              dispatch(
                commitData(
                  PLAN_EXTENSION_MODEL_NAME,
                  planExtensionid,
                  planExtensionData
                )
              );
            }
          }

          if (step === stepsEnum.ORDER) {
            const campaignId = get(campaign, CAMPAIGN_MODEL_ID);
            const campaignData = set(campaign, "id", campaignId);
            dispatch(commitData(CAMPAIGN_MODEL_NAME, campaignId, campaignData));
            dispatch(selectCampaign(campaignId));
            dispatch(startEditingCampaign());

            const planId = get(plan, PLAN_MODEL_ID);
            const planData = set(plan, "id", planId);
            dispatch(commitData(PLAN_MODEL_NAME, planId, planData));
            dispatch(selectPlan(planId));
            dispatch(startEditingPlan());

            const orderId = get(order, ORDER_MODEL_ID);
            if (!!order) {
              const orderData = set(order, "id", orderId);
              set(orderData, "isCopyTemp", true);
              dispatch(commitData(ORDER_MODEL_NAME, orderId, orderData));
              dispatch(selectOrder(orderId));
            }

            dispatch(setOrderSteps(orderStepsCount));
            dispatch(setCurrentOrderStep(currentOrderStep));
          }

          dispatch(setActiveStep(step));

          return null;
        }

        return null;
      },
      error => {
        // TODO: Change this later to regex. Ugly solution, but needed.
        if (!!error[0] && error[0].indexOf("doesn't exist") < 0) {
          dispatch(enqueueApiErrorMessage(error));
        } else {
          return "return_to_overview";
        }

        return null;
      }
    );
  };
};
