import React, { memo } from "react";
import PropTypes from "prop-types";

import { Toolbar, Grid } from "@mui/material";

import TableOverviewBody from "../../overview/form/TableOverviewBody";
import TableOverviewFooter from "../../overview/form/TableOverviewFooter";
import DynamicFilter from "../../../shared/dynamicFilter/DynamicFilter";
import { defaultOptionsForCoppyPlan } from "../../../../business/constants/filterOptions";
import { MAX_FILTER_COPYPLAN } from "../../../../business/constants/applicationConstants";

import { classes, Table } from "../styles/copyPlanTable";
import { EMPTY_STRING } from "../../../../configurations/appConstants";

const CopyPlanTable = ({
  rows,
  pageSize,
  currentPage,
  searchText,
  searchProperty,
  count,
  contentLoading,
  onInputSearch,
  onSearchBy,
  onChangePage,
  onChangeRowsPerPage,
  onEditClick,
  handleTrafficClick,
  canViewTraffic,
  disableAction,
  onDynamicFilter,
  queryFilters,
  showTableControls
}) => {
  return (
    <>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <DynamicFilter
                limit={MAX_FILTER_COPYPLAN}
                searchProperty={searchProperty}
                onInputSearch={onInputSearch}
                onSearchBy={onSearchBy}
                searchText={searchText}
                updateUrl={onDynamicFilter}
                queryFilters={queryFilters}
                filterOptions={defaultOptionsForCoppyPlan}
              />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Table className={classes.table}>
        <TableOverviewBody
          isCopyPlan
          rows={rows}
          onEditClick={onEditClick}
          handleTrafficClick={handleTrafficClick}
          canViewTraffic={canViewTraffic}
          disableAction={disableAction}
          contentLoading={contentLoading}
        />
        <TableOverviewFooter
          count={count}
          onChangePage={onChangePage}
          pageSize={pageSize}
          currentPage={currentPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          showTableControls={showTableControls}
        />
      </Table>
    </>
  );
};

CopyPlanTable.propTypes = {
  rows: PropTypes.array,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  contentLoading: PropTypes.bool,
  showTableControls: PropTypes.bool,
  isCopyPlan: PropTypes.bool,
  searchText: PropTypes.string,
  searchProperty: PropTypes.string,
  count: PropTypes.number,
  canViewTraffic: PropTypes.bool,
  disableAction: PropTypes.bool,
  queryFilters: PropTypes.object,
  onInputSearch: PropTypes.func,
  onSearchBy: PropTypes.func,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDynamicFilter: PropTypes.func.isRequired,
  handleTrafficClick: PropTypes.func.isRequired
};

CopyPlanTable.defaultProps = {
  rows: [],
  count: 0,
  pageSize: 10,
  currentPage: 0,
  contentLoading: false,
  canViewTraffic: false,
  disableAction: false,
  showTableControls: true,
  searchText: EMPTY_STRING,
  searchProperty: EMPTY_STRING,
  queryFilters: {}
};

export default memo(CopyPlanTable);
