export const PLAN_CHANGE_OVERVIEW_MODEL_ID = "planChangeOverviewId";
export const PLAN_CHANGE_OVERVIEW_MODEL_NAME = "PlanChangeOverview";
export const PLAN_CHANGE_OVERVIEW_PROPERTY_NAME = "planChangeOverviews";

export const PLAN_CHANGE_OVERVIEW_CLEAR_CURRENT =
  "PLAN_CHANGE_OVERVIEW_CLEAR_CURRENT";
export const PLAN_CHANGE_OVERVIEW_SELECT = "PLAN_CHANGE_OVERVIEW_SELECT";
export const PLAN_CHANGE_OVERVIEW_EDIT_START =
  "PLAN_CHANGE_OVERVIEW_EDIT_START";
export const PLAN_CHANGE_OVERVIEW_EDIT_STOP = "PLAN_CHANGE_OVERVIEW_EDIT_STOP";
export const PLAN_CHANGE_OVERVIEW_HIDE_ERRORS =
  "PLAN_CHANGE_OVERVIEW_HIDE_ERRORS";
export const PLAN_CHANGE_OVERVIEW_DISPLAY_ERRORS =
  "PLAN_CHANGE_OVERVIEW_DISPLAY_ERRORS";
export const PLAN_CHANGE_OVERVIEW_SET_EDITING_ORIGINAL_ITEM =
  "PLAN_CHANGE_OVERVIEW_SET_EDITING_ORIGINAL_ITEM";
export const PLAN_CHANGE_OVERVIEW_CLEAR_EDITING_ORIGINAL_ITEM =
  "PLAN_CHANGE_OVERVIEW_CLEAR_EDITING_ORIGINAL_ITEM";

export const ADD_TEXT = "ADD";
export const EDIT_TEXT = "EDIT";
