import { createReducer } from "../common/utils/reducerUtils";
import {
  TRAFFIC_ITEM_SELECT,
  TRAFFIC_ITEM_COPY_START,
  TRAFFIC_ITEM_EDIT_START,
  TRAFFIC_ITEM_EDIT_STOP,
  TRAFFIC_ITEM_COPY_STOP,
  TRAFFIC_ITEM_DISPLAY_ERRORS,
  TRAFFIC_ITEM_HIDE_ERRORS,
  TRAFFIC_ITEM_CLEAR_CURRENT,
  TRAFFIC_ITEM_SET_DIGITAL_TEMPLATE,
  TRAFFIC_ITEM_RESET_DIGITAL_TEMPLATE
} from "./trafficItemConstants";

const initialState = {
  currentTrafficItem: null,
  isEditing: false,
  isCopying: false,
  displayErrors: false,
  trafficDigitalTemplateId: null
};
function selectTrafficItem(state, payload) {
  return {
    ...state,
    currentTrafficItem: payload.trafficItemId
  };
}

const setTrafficDigitalTemplate = (state, payload) => {
  return {
    ...state,
    trafficDigitalTemplateId: payload.template
  };
};

const resetTrafficDigitalTemplate = state => {
  return {
    ...state,
    trafficDigitalTemplateId: null
  };
};

function startEditingTrafficItem(state) {
  return {
    ...state,
    isEditing: true
  };
}

function stopEditingTrafficItem(state) {
  return {
    ...state,
    isEditing: false
  };
}

function startCopyingTrafficItem(state) {
  return {
    ...state,
    isCopying: true
  };
}

function stopCopyingTrafficItem(state) {
  return {
    ...state,
    isCopying: false
  };
}

function displayErrors(state) {
  return {
    ...state,
    displayErrors: true
  };
}

function hideErrors(state) {
  return {
    ...state,
    displayErrors: false
  };
}

function clearCurrent() {
  return initialState;
}

export default createReducer(initialState, {
  [TRAFFIC_ITEM_SELECT]: selectTrafficItem,
  [TRAFFIC_ITEM_EDIT_START]: startEditingTrafficItem,
  [TRAFFIC_ITEM_COPY_START]: startCopyingTrafficItem,
  [TRAFFIC_ITEM_EDIT_STOP]: stopEditingTrafficItem,
  [TRAFFIC_ITEM_COPY_STOP]: stopCopyingTrafficItem,
  [TRAFFIC_ITEM_DISPLAY_ERRORS]: displayErrors,
  [TRAFFIC_ITEM_HIDE_ERRORS]: hideErrors,
  [TRAFFIC_ITEM_CLEAR_CURRENT]: clearCurrent,
  [TRAFFIC_ITEM_SET_DIGITAL_TEMPLATE]: setTrafficDigitalTemplate,
  [TRAFFIC_ITEM_RESET_DIGITAL_TEMPLATE]: resetTrafficDigitalTemplate
});
