import React, { memo } from "react";
import clsx from "clsx";
import { classes, Button } from "../styles/trafficOnlyMyTrafficPlansSearch";

const TrafficOnlyMyTrafficPlansSearch = ({
  searchMyTrafficPlans,
  onSearchMyTrafficPlans
}) => {
  const btnClass = clsx(classes.button, {
    [classes.active]: searchMyTrafficPlans
  });

  return (
    <Button
      name="search_myTrafficPlans"
      id="search_myTrafficPlans"
      className={btnClass}
      onClick={onSearchMyTrafficPlans}
    >
      My Traffic Plans
    </Button>
  );
};

export default memo(TrafficOnlyMyTrafficPlansSearch);
