import React from "react";
import { TableBody, TableCell, TableHead, TableRow, Grid } from "@mui/material";

import isEmpty from "lodash/isEmpty";

import { classes, Table } from "../styles/orderBuyingStatusesTable";
import { EMPTY_TABLE } from "../../../../configurations/appConstants";
import {
  StatusIcon,
  NoTrafficStatusIcon
} from "../../../../assets/styles/icons";

const OrderBuyingStatusesTable = ({ rows }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Order ID</TableCell>
          <TableCell>Client Status</TableCell>
          <TableCell>Buying Status</TableCell>
          <TableCell>Traffic Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!isEmpty(rows) ? (
          rows.map(row => (
            <TableRow key={row.marathonOrderId}>
              <TableCell className={classes.tableCell}>
                <Grid>{row.marathonOrderId}</Grid>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <StatusIcon status={row.marathonStatusId} />
                  </Grid>
                  <Grid item>{row.marathonStatus}</Grid>
                </Grid>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Grid item>
                  {!isEmpty(row.buyingStatus) ? (
                    row.buyingstatus
                  ) : (
                    <NoTrafficStatusIcon />
                  )}
                </Grid>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Grid item>
                  {!isEmpty(row.trafficStatus) ? (
                    row.trafficStatus
                  ) : (
                    <NoTrafficStatusIcon />
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell align="center">{EMPTY_TABLE}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default OrderBuyingStatusesTable;
