import {
  styled,
  Button as MuiButton,
  DialogContent as MuiDialogContent
} from "@mui/material";
import { crusta } from "../../../../assets/styles/colors";

const PREFIX = "ApprovalDialogStyles";
export const classes = {
  closeButton: `${PREFIX}-closeButton`,
  dialog: `${PREFIX}-dialog`
};

export const Button = styled(MuiButton)(() => ({
  [`&.${classes.closeButton}`]: {
    color: crusta
  }
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    paddingTop: "15px"
  }
}));
