/* eslint-disable no-nested-ternary */
import isObject from "lodash/isObject";
import upperFirst from "lodash/upperFirst";
import moment from "moment";
import { selectAgencyName, getNotificationRedirectUrl } from "./appUtils";
import {
  CAMPAIGN,
  PLAN,
  ORDER,
  PLACEHOLDER
} from "../../../../configurations/appConstants";
import { isNullOrUndefined } from "../../../../functions/util";

export function getValueFromEvent(e) {
  const { target } = e;

  let newValues;

  if (target) {
    const value = target.type === "checkbox" ? target.checked : target.value;
    newValues = {
      [target.name]: value
    };
  } else if (isObject(e)) {
    newValues = e;
  }

  return newValues;
}

export function parseDate(value) {
  return isNullOrUndefined(value)
    ? PLACEHOLDER
    : moment(value).format("D MMM 'YY");
}

export function parseDate2(value) {
  return isNullOrUndefined(value)
    ? PLACEHOLDER
    : moment(value).format("DD-MM-YYYY");
}

export function parseUtcDate(value) {
  return isNullOrUndefined(value)
    ? PLACEHOLDER
    : moment
        .utc(value)
        .local()
        .format("DD-MM-YYYY HH:mm");
}

export function generateUrlPath(campaignId, planId, orderId) {
  return isNullOrUndefined(campaignId)
    ? ""
    : isNullOrUndefined(planId)
    ? `/${CAMPAIGN}/${campaignId}`
    : isNullOrUndefined(orderId)
    ? `/${CAMPAIGN}/${campaignId}/${PLAN}/${planId}`
    : `/${CAMPAIGN}/${campaignId}/${PLAN}/${planId}/${ORDER}/${orderId}`;
}

export function parseIntOrDefault(value) {
  const parsed = Number(value);
  if (Number.isNaN(parsed)) {
    return null;
  }
  return parsed;
}

// TrafficPlanID can be int or string
export function parseIntOrString(value) {
  const parsed = Number(value);
  if (Number.isNaN(parsed)) {
    return value;
  }
  return parsed;
}

export function trimString(value, size) {
  const suffix = " ...";
  let newValue = value;
  if (value?.length > size) {
    newValue = value.substring(0, size - suffix.length) + suffix;
  }
  return newValue;
}

export function generateNotificationUrlPath(
  typeId,
  agencyId,
  campaignId,
  planId,
  trafficPlanId,
  marathonOrderIds,
  marathonPlanId
) {
  return `/${upperFirst(
    selectAgencyName(agencyId)
  )}/${getNotificationRedirectUrl(
    typeId,
    campaignId,
    planId,
    trafficPlanId,
    marathonOrderIds,
    marathonPlanId
  )}`;
}
