import React from "react";
import { useSelector } from "react-redux";

import { Button } from "@mui/material";

import InsertionCalculationSumField from "../../../form/InsertionCalculationSumField";
import { classes, Grid } from "../styles/calculationForm";

import { getCampaignData } from "../../../../business/models/Campaign/campaignSelectors";

const CalculationForm = ({
  showSave,
  isLoading,
  disableSave,
  disableAction,
  onSave,
  onCancel,
  orderId
}) => {
  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const handleSave = () => {
    if (typeof onSave === "function") {
      onSave();
    }
  };

  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
  };

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={1} />
      <Grid item xs={1}>
        <InsertionCalculationSumField
          id="sumInsertionNumOfUnits"
          name="sumInsertionNumOfUnits"
          label="# of units"
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionCalculationSumField
          id="sumInsertionDiscountAmount"
          name="sumInsertionDiscountAmount"
          label="Disc. amount"
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionCalculationSumField
          id="sumInsertionNetPerUnit"
          name="sumInsertionNetPerUnit"
          label="Net per unit"
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionCalculationSumField
          id="sumInsertionNetAmount"
          name="sumInsertionNetAmount"
          label="Net total"
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionCalculationSumField
          id="sumInsertionGrossTotal"
          name="sumInsertionGrossTotal"
          label="Gross total"
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionCalculationSumField
          id="sumInsertionAgencyDiscountAmount"
          name="sumInsertionAgencyDiscountAmount"
          label="Agency disc."
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionCalculationSumField
          id="sumInsertionNetNetTotal"
          name="sumInsertionNetNetTotal"
          label="Net net total"
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionCalculationSumField
          id="sumInsertionSurcharge1Amount"
          name="sumInsertionSurcharge1Amount"
          label="Surchar. 1"
        />
      </Grid>
      <Grid item xs={1}>
        <InsertionCalculationSumField
          id="sumInsertionSurcharge2Amount"
          name="sumInsertionSurcharge2Amount"
          label="Surchar. 2"
        />
      </Grid>
      <Grid item xs={1}>
        {showSave && (
          <Button
            id={`i_cancel_${orderId}`}
            name={`i_cancel_${orderId}`}
            className={classes.cancelButton}
            onClick={handleCancel}
            disabled={isLoading || disableAction}
          >
            CANCEL
          </Button>
        )}
      </Grid>
      <Grid item xs={1}>
        {showSave && isDebtorActive && (
          <Button
            id={`i_save_${orderId}`}
            name={`i_save_${orderId}`}
            variant="contained"
            className={classes.saveButton}
            disabled={isLoading || disableSave}
            onClick={handleSave}
          >
            SAVE
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default CalculationForm;
