import {
  styled,
  Tooltip as MuiTooltip,
  IconButton as MuiIconButton
} from "@mui/material";
import { dodgerBlue20 } from "../../../../assets/styles/colors";

const PREFIX = "AdditionalTableRuleSearchStyle";
export const classes = {
  active: `${PREFIX}-active`
};

export const Tooltip = styled(MuiTooltip)(() => ({
  [`& .${classes.active}`]: {
    backgroundColor: dodgerBlue20
  }
}));

export const IconButton = styled(MuiIconButton)(() => ({
  [`&.${classes.active}`]: {
    backgroundColor: dodgerBlue20
  }
}));
