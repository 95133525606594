import React, { memo, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import {
  DialogContent,
  Button,
  DialogActions,
  DialogContentText
} from "@mui/material";

import AutoComplete from "../../../form/controls/AutoComplete";
import AutoCompleteCreatable from "../../../form/controls/AutoCompleteCreatable";
import { classes, Dialog } from "../styles/trafficDialogStyle";
import {
  selectOpenTrafficPlanEmailDialog,
  selectSelectedUsers,
  selectCurrentTrafficPlanId,
  isSelectedTrafficDigital
} from "../../../../business/models/TrafficPlan/trafficPlanSelectors";
import { getUsers } from "../../../../business/models/User/userSelectors";
import {
  setSelectedUsers,
  closeTrafficPlanEmailDialog,
  clearSelectedUsers,
  emailTraffic
} from "../../../../business/models/TrafficPlan/trafficPlanActions";
import TrafficPlanUpload from "../../traffic-dashboard/actions/TrafficPlanUpload";
import { validation } from "../../../../business/models/common/utils/validationUtils";
import { EMAIL_MESSAGE } from "../../../../business/constants/validationConstants";
import { isNullOrUndefined } from "../../../../functions/util";

export const digitalTrafficTemplateOptions = [
  { text: "Adform ", key: 1 },
  { text: "Sizmek", key: 2 },
  { text: "DCM", key: 3 },
  { text: "Publisher", key: 4 }
];

const TrafficEmailDialog = ({
  open,
  userOptions,
  value,
  onChange,
  clearUsers,
  currentTrafficPlan,
  closeDialog,
  emailTrafficPlan,
  isSelectedTrafficDigitalValue
}) => {
  const [templateId, setTemplateId] = useState(null);
  const [file, setFile] = useState({});
  const [errors, setErrors] = useState([]);

  const handleClose = () => {
    closeDialog();
    clearSelectedUsers();
    clearUsers();
    setTemplateId(null);
    setFile({});
  };

  const handleChange = userIds => {
    setErrors([]);
    const selectedUsers = userIds.map(userId => {
      let selectedUser = userOptions.find(user => user.key === userId);
      if (isNullOrUndefined(selectedUser)) {
        selectedUser = { key: userId, text: userId, enabled: false };
        if (!validation.email(userId)) {
          setErrors([...errors, EMAIL_MESSAGE]);
        }
      }
      return selectedUser;
    });

    onChange(selectedUsers);
  };

  const handleChangeTemplate = temp => {
    setTemplateId(temp);
  };

  const handleSubmit = () => {
    emailTrafficPlan(
      currentTrafficPlan,
      value.map(user => user.text),
      templateId,
      file
    );
    closeDialog();
    clearUsers();
    setTemplateId(null);
    setFile({});
  };

  const isEmptyEmail = !value || value.length === 0;
  const isEmailValid = errors.length === 0;
  const isDisabled = isSelectedTrafficDigitalValue
    ? isEmptyEmail || !isEmailValid || templateId === null
    : isEmptyEmail || !isEmailValid;

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialog }}
      maxWidth="md"
      fullWidth
    >
      <DialogContent classes={{ root: classes.root }}>
        <DialogContentText>Select email addresses:</DialogContentText>

        <AutoCompleteCreatable
          id="select_emails"
          name="select_emails"
          isMulti
          options={userOptions}
          value={value}
          onChange={handleChange}
          errors={errors}
          displayErrors={!isEmailValid}
          isValid={isEmailValid}
        />

        {isSelectedTrafficDigitalValue && (
          <>
            <DialogContentText>
              Select digital traffic template:
            </DialogContentText>
            <AutoComplete
              id="select_version"
              name="select_version"
              options={digitalTrafficTemplateOptions}
              value={templateId}
              onChange={handleChangeTemplate}
            />
          </>
        )}
        <TrafficPlanUpload fileName={file.fileName} onChange={setFile} />
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel_email"
          name="cancel_email"
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          id="send_email"
          name="send_email"
          onClick={handleSubmit}
          disabled={isDisabled}
          color="secondary"
        >
          Send Email
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TrafficEmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  userOptions: PropTypes.array,
  closeDialog: PropTypes.func.isRequired,
  emailTrafficPlan: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  clearUsers: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  open: selectOpenTrafficPlanEmailDialog(state),
  value: selectSelectedUsers(state),
  userOptions: getUsers(state),
  currentTrafficPlan: selectCurrentTrafficPlanId(state),
  isSelectedTrafficDigitalValue: isSelectedTrafficDigital(state)
});

const mapDispatchToProps = dispatch => {
  return {
    onChange: value => dispatch(setSelectedUsers(value)),
    closeDialog: () => dispatch(closeTrafficPlanEmailDialog()),
    emailTrafficPlan: (
      trafficPlanId,
      emailAddresses,
      trafficDigitalType,
      attachment
    ) =>
      dispatch(
        emailTraffic(
          trafficPlanId,
          emailAddresses,
          trafficDigitalType,
          attachment
        )
      ),
    clearUsers: () => dispatch(clearSelectedUsers())
  };
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(TrafficEmailDialog)
);
