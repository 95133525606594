import { styled, Select as MuiSelect } from "@mui/material";
import { linkWater, sapphire, solitude } from "../../../assets/styles/colors";

const PREFIX = "TrafficStatusSelectStyles";
export const classes = {
  arrowDropDownIcon: `${PREFIX}-arrowDropDownIcon`,
  outlinedInput: `${PREFIX}-outlinedInput`,
  select: `${PREFIX}-select`
};

export const Select = styled(MuiSelect)(({ theme }) => ({
  [`&.${classes.arrowDropDownIcon}`]: {
    color: sapphire,
    right: theme.typography.pxToRem(5)
  },
  [`&.${classes.outlinedInput}`]: {
    "&:focus": { backgroundColor: "transparent" }
  },
  [`&.${classes.select}`]: {
    borderRadius: theme.typography.pxToRem(4),
    height: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(230),
    "&.MuiOutlinedInput-root.Mui-disabled": {
      background: solitude
    }
  }
}));
