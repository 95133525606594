import React from "react";
import { Alert, Grid } from "@mui/material";
import { Field } from "formik";
import AutoCompleteFormik from "../../../../form/controls/AutoCompleteFormik";
import { ConfigOptionsContextConsumer } from "../../configurationContext";

const SpecificSurchargeFields = ({
  type,
  isHighRiskSurchargeRuleActive,
  canEditAgencyRules
}) => (
  <ConfigOptionsContextConsumer>
    {context => (
      <>
        <Grid item xs={6}>
          <Grid container alignItems="center" spacing={5}>
            {!canEditAgencyRules && (
              <Grid item justifyContent="center" xs={6}>
                <Alert elevation={0} variant="outlined" severity="info">
                  You can only add values
                </Alert>
              </Grid>
            )}
            <Grid container item alignItems="center" spacing={2}>
              <Grid item xs={5}>
                <Field
                  component={AutoCompleteFormik}
                  id={`${type}Surcharge1TypeId`}
                  name={`${type}Surcharge1TypeId`}
                  disabled={!isHighRiskSurchargeRuleActive}
                  textFieldProps={{
                    label: "Surcharge 1 Type ID"
                  }}
                  multiple
                  canEditAgencyRules={canEditAgencyRules}
                  options={context.surchargeOptions}
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={AutoCompleteFormik}
                  id={`${type}Surcharge2TypeId`}
                  name={`${type}Surcharge2TypeId`}
                  label="Surcharge 2 Type ID"
                  disabled={!isHighRiskSurchargeRuleActive}
                  textFieldProps={{
                    label: "Surcharge 2 Type ID"
                  }}
                  multiple
                  canEditAgencyRules={canEditAgencyRules}
                  options={context.surchargeOptions}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )}
  </ConfigOptionsContextConsumer>
);

export default SpecificSurchargeFields;
