import cuid from "cuid";
import get from "lodash/get";
import set from "lodash/set";
import keys from "lodash/keys";
import forEach from "lodash/forEach";
import assign from "lodash/assign";

import ProjectModel from "./Project";
import {
  PROJECT_SELECT,
  PROJECT_EDIT_START,
  PROJECT_EDIT_STOP,
  PROJECT_CLEAR_CURRENT,
  PROJECT_DISPLAY_ERRORS,
  PROJECT_HIDE_ERRORS,
  PROJECT_SUCCESS_MESSAGE,
  PROJECT_MODEL_ID,
  PROJECT_MODEL_NAME,
  PROJECT_SET_IS_NEW,
  PROJECT_RESET_IS_NEW,
  PROJECT_RESET_CURRENT
} from "./projectConstants";
import {
  selectCurrentProject,
  selectIsEditingProject,
  getEditingProject,
  doesProjectHaveErrors,
  selectProjectIsNew,
  selectFirstIdFromProjectList
} from "./projectSelectors";

import { CAMPAIGN_MODEL_ID } from "../Campaign/campaignConstants";
import {
  startEditingCampaign,
  stopEditingCampaign,
  clearCurrentCampaign
} from "../Campaign/campaignActions";
import {
  getCampaign,
  selectCurrentCampaign
} from "../Campaign/campaignSelectors";

import {
  CREATE_PROJECT,
  UPDATE_PROJECT
} from "../../../configurations/apiUrls";

import {
  getQueryObj,
  generateParamObj,
  getMappedObject,
  getPathValue,
  getMatchingProperties
} from "../common/utils/modelUtils";
import {
  editItemAttributes,
  editExistingItem,
  editNewItem,
  stopEditingItem,
  commitData
} from "../common/editing/editingActions";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import generateUrl from "../common/utils/urlUtils";

import {
  enqueueApiErrorMessage,
  enqueueNotificationMessage,
  apiCall
} from "../../actions/applicationActions";
import fetchApiDataIfNeeded from "../../actions/apiDataActions";

import { selectUserName } from "../../selectors/userProfileSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";
import { selectAgency } from "../../selectors/applicationSelectors";
import { POST, PUT } from "../../constants/applicationConstants";
import { isNullOrUndefined } from "../../../functions/util";
import { selectAgencyName } from "../common/utils/appUtils";

export function setProjectMediaData() {
  return (dispatch, getState) => {
    const state = getState();
    const configuration = ProjectModel.apiConfiguration;
    const apiData = getApiData(state);
    const editingData = getEditingProject(state);
    const currentProject = selectCurrentProject(state);
    const agencyID = selectAgency(state);
    forEach(configuration, (prop, key) => {
      if (prop.path) {
        const prevValue = get(editingData, key);
        const value = getPathValue(prop, editingData, apiData, agencyID);
        if (!isNullOrUndefined(value) && value !== "" && prevValue !== value) {
          dispatch(
            editItemAttributes(PROJECT_MODEL_NAME, currentProject, {
              [key]: value
            })
          );
        }
      }
    });
  };
}

export function loadProjectMediaData(data) {
  return (dispatch, getState) => {
    const configuration = ProjectModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url, setProjectMediaData));
      }
    });
  };
}

export function startEditingProject() {
  return (dispatch, getState) => {
    const state = getState();
    const currentProject = selectCurrentProject(state);
    dispatch(editExistingItem(PROJECT_MODEL_NAME, currentProject));
    const editingProject = getEditingProject(state);
    dispatch(loadProjectMediaData(editingProject));
    dispatch({ type: PROJECT_EDIT_START });
  };
}

export function stopEditingProject() {
  return (dispatch, getState) => {
    const state = getState();
    const currentProject = selectCurrentProject(state);
    dispatch(stopEditingItem(PROJECT_MODEL_NAME, currentProject));
    dispatch({ type: PROJECT_EDIT_STOP });
    dispatch({ type: PROJECT_HIDE_ERRORS });
  };
}

export function selectProject(projectID) {
  return (dispatch, getState) => {
    const state = getState();
    const id = projectID || selectFirstIdFromProjectList(state);
    const isEditing = selectIsEditingProject(state);

    if (isEditing) {
      dispatch(stopEditingProject());
    }

    dispatch({ type: PROJECT_CLEAR_CURRENT });
    dispatch({
      type: PROJECT_SELECT,
      payload: {
        currentProject: id
      }
    });
  };
}

export const editProject = id => dispatch => {
  dispatch(selectProject(id));
  dispatch(startEditingCampaign());
  dispatch(startEditingProject());
};

export const setCurrentProjectToFirstFromList = () => (dispatch, getState) => {
  const state = getState();
  const isNew = selectProjectIsNew(state);
  const projectId = selectFirstIdFromProjectList(state);
  if (isNew && projectId) {
    dispatch({ type: PROJECT_CLEAR_CURRENT });
    dispatch({
      type: PROJECT_SELECT,
      payload: {
        currentProject: projectId
      }
    });
  }
};

export const clearCurrentProject = () => dispatch =>
  dispatch({ type: PROJECT_CLEAR_CURRENT });

export const resetCurrentProject = () => dispatch =>
  dispatch({ type: PROJECT_RESET_CURRENT });

export function clearProject() {
  return dispatch => {
    dispatch(clearCurrentProject());
    dispatch(clearCurrentCampaign());
  };
}

export function cancelProject() {
  return (dispatch, getState) => {
    dispatch(stopEditingProject());
    dispatch(stopEditingCampaign());
    const isNew = selectProjectIsNew(getState());
    if (isNew) {
      dispatch(clearCurrentProject());
    } else {
      dispatch(resetCurrentProject());
    }
  };
}

export function addNewProject() {
  return (dispatch, getState) => {
    const state = getState();
    const session = getUnsharedEntitiesSession(state);
    const campaignId = selectCurrentCampaign(state);
    const agencyId = selectAgency(state);
    const agency = selectAgencyName(agencyId);
    const campaign = getCampaign(state);
    const inheritObject = getMappedObject(ProjectModel.inheritConfiguration, {
      ...campaign,
      agency
    });

    const { Project } = session;

    const id = cuid();
    const projectLeaderId = selectUserName(getState());
    const newProject = Project.generate({
      id,
      projectLeaderId,
      ...inheritObject,
      campaign: campaignId
    });

    const projectContents = newProject.toJSON();

    dispatch(startEditingCampaign());
    dispatch(editNewItem(PROJECT_MODEL_NAME, id, projectContents));
    dispatch(selectProject(id));
    dispatch({ type: PROJECT_SET_IS_NEW });
    dispatch({ type: PROJECT_EDIT_START });
    dispatch(loadProjectMediaData(projectContents));
  };
}

export function displayProjectErrors() {
  return { type: PROJECT_DISPLAY_ERRORS };
}

export function createProject() {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesProjectHaveErrors(state);
    if (hasErrors) {
      dispatch(displayProjectErrors());
      return Promise.reject();
    }

    const agencyID = selectAgency(getState());
    const currentCampaign = selectCurrentCampaign(state);
    const url = generateUrl(CREATE_PROJECT, {
      agency: agencyID,
      [CAMPAIGN_MODEL_ID]: currentCampaign
    });
    const body = getEditingProject(state);

    return dispatch(apiCall(POST, url, body)).then(
      response => {
        const id = get(response, PROJECT_MODEL_ID);
        const responseData = set(response, "id", id);
        dispatch(commitData(PROJECT_MODEL_NAME, id, responseData));
        dispatch(selectProject(id));
        dispatch(stopEditingCampaign());
        dispatch({ type: PROJECT_RESET_IS_NEW });
        dispatch(enqueueNotificationMessage(PROJECT_SUCCESS_MESSAGE));
        return !!id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function updateProject() {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesProjectHaveErrors(state);
    if (hasErrors) {
      dispatch(displayProjectErrors());
      return Promise.reject();
    }

    const agencyID = selectAgency(getState());
    const currentCampaign = selectCurrentCampaign(state);
    const currentProject = selectCurrentProject(state);
    const url = generateUrl(UPDATE_PROJECT, {
      agency: agencyID,
      [CAMPAIGN_MODEL_ID]: currentCampaign,
      [PROJECT_MODEL_ID]: currentProject
    });
    const body = getEditingProject(state);

    return dispatch(apiCall(PUT, url, body)).then(
      response => {
        const id = get(response, PROJECT_MODEL_ID);
        const responseData = set(response, "id", id);
        dispatch(commitData(PROJECT_MODEL_NAME, id, responseData));
        dispatch(selectProject(id));
        dispatch(stopEditingCampaign());
        dispatch({ type: PROJECT_RESET_IS_NEW });
        dispatch(enqueueNotificationMessage(PROJECT_SUCCESS_MESSAGE));
        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function resetProjectData(currentProject, data) {
  return dispatch => {
    const configuration = ProjectModel.apiConfiguration;
    const name = keys(data)[0];
    const properties = getMatchingProperties(configuration, name);
    forEach(properties, propertyName => {
      dispatch(
        editItemAttributes(PROJECT_MODEL_NAME, currentProject, {
          [propertyName]: null
        })
      );
    });
  };
}

export function setProjectValue(data) {
  return (dispatch, getState) => {
    const currentProject = selectCurrentProject(getState());
    const editingData = getEditingProject(getState());
    const projectData = assign({}, editingData, data);
    dispatch(loadProjectMediaData(projectData));
    dispatch(editItemAttributes(PROJECT_MODEL_NAME, currentProject, data));
    dispatch(resetProjectData(currentProject, data));
  };
}
