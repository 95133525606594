import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

import { Button } from "@mui/material";

import ConfirmAgreementDialog from "../popup/ConfirmAgreementDialog";

import {
  ORDER_FORM_UPDATE,
  ORDER_FORM_INSERTION_UPDATE
} from "../../../../business/constants/applicationConstants";

import {
  toggleInsertionPopup,
  setAction,
  toggleAgreementConfirmPopup
} from "../../../../business/actions/applicationActions";
import {
  cancelOrder,
  updateOrder,
  checkIsOrderValidAndShowErrors
} from "../../../../business/models/Order/orderActions";
import { closeOrderFormPage } from "../../../../business/actions/campaignDashboardActions";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import {
  selectInvoicedOrderAgreementChangeFlag,
  isOrderCancelledOrToBeCancelled,
  isOrderPendingForApproval
} from "../../../../business/models/Order/orderSelectors";
import { getCampaignData } from "../../../../business/models/Campaign/campaignSelectors";
import {
  canEditCampaign,
  selectUserIsAdmin
} from "../../../../business/selectors/userProfileSelectors";
import { classes, Grid } from "../styles/orderEditActions";

const OrderEditActions = () => {
  const dispatch = useDispatch();

  const isCancelledOrToBeCancelled = useSelector(
    isOrderCancelledOrToBeCancelled
  );
  const isPendingForApproval = useSelector(isOrderPendingForApproval);

  const isLoading = useSelector(selectIsLoading);
  const disableAction = !useSelector(canEditCampaign);
  const agreementChangeForInvoicedOrder = useSelector(
    selectInvoicedOrderAgreementChangeFlag
  );
  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const disableSave = isCancelledOrToBeCancelled || isPendingForApproval;
  const isUserAdmin = useSelector(selectUserIsAdmin);

  const commitSave = () => {
    dispatch(setAction(ORDER_FORM_UPDATE));

    dispatch(updateOrder()).then(id => {
      if (id) {
        dispatch(closeOrderFormPage());
      }
    });
  };

  const handleCancel = () => {
    dispatch(cancelOrder());
    dispatch(closeOrderFormPage());
  };

  const handleSave = () => {
    dispatch(setAction(ORDER_FORM_UPDATE));

    if (agreementChangeForInvoicedOrder) {
      dispatch(toggleAgreementConfirmPopup());
    } else {
      commitSave();
    }
  };

  const handleEditInsertions = () => {
    const isValid = dispatch(checkIsOrderValidAndShowErrors());
    if (isValid) {
      dispatch(setAction(ORDER_FORM_INSERTION_UPDATE));
      dispatch(toggleInsertionPopup());
    }
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button
            className={classes.cancelButton}
            onClick={handleCancel}
            disabled={isLoading || disableAction}
          >
            CANCEL
          </Button>
        </Grid>
        {isDebtorActive && (
          <Grid item>
            <Grid container spacing={2} justifyContent="space-around">
              <Grid item>
                <Button
                  variant="contained"
                  className={clsx(classes.saveButton, classes.markedButton)}
                  disabled={isLoading || disableSave || disableAction}
                  onClick={handleSave}
                >
                  SAVE
                </Button>
              </Grid>
              {isCancelledOrToBeCancelled && isUserAdmin && (
                <Grid item>
                  <Button
                    variant="contained"
                    className={clsx(classes.saveButton, classes.markedButton)}
                    disabled={isLoading}
                    onClick={handleSave}
                  >
                    SAVE CANCELLED ORDER
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.saveButton}
                  onClick={handleEditInsertions}
                  disabled={disableAction}
                >
                  EDIT INSERTIONS
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ConfirmAgreementDialog onOk={commitSave} />
    </>
  );
};

export default OrderEditActions;
