import React from "react";
import { Button } from "@mui/material";

import TrafficPlanField from "../../form/TrafficPlanField";
import { classes, Grid } from "../styles/trafficLine";

export default function TrafficPlanEdit({
  isDigital,
  handleOnSave,
  handleOnCancel,
  isCreativeAgencyVisible,
  isContactVisible,
  isEmailVisible,
  isCCVisible,
  isContactGroupMVisible,
  isPhoneNumberGroupMVisible,
  isLoading,
  areTrafficAgencyFieldsDisabled,
  areTrafficAgentFieldsDisabled
}) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item>
            <TrafficPlanField
              component="text"
              id="planner"
              name="planner"
              label="Planner"
              variant="standard"
              className={classes.trafficField}
              isLabelHighlighted
              disabled
            />
          </Grid>
          {isCreativeAgencyVisible && (
            <Grid item>
              <TrafficPlanField
                component="text"
                id="creativeAgency"
                name="creativeAgency"
                label="Creative Agency *"
                variant="standard"
                className={classes.trafficField}
                isLabelHighlighted
                disabled={areTrafficAgencyFieldsDisabled}
              />
            </Grid>
          )}
          {isContactVisible && (
            <Grid item>
              <TrafficPlanField
                component="text"
                id="contact"
                name="contact"
                label="Contact *"
                variant="standard"
                className={classes.trafficField}
                isLabelHighlighted
                disabled={areTrafficAgencyFieldsDisabled}
              />
            </Grid>
          )}
          {isEmailVisible && (
            <Grid item>
              <TrafficPlanField
                component="text"
                id="email"
                name="email"
                label="Email *"
                variant="standard"
                className={classes.trafficField}
                isLabelHighlighted
                disabled={areTrafficAgencyFieldsDisabled}
              />
            </Grid>
          )}
          {isCCVisible && (
            <Grid item>
              <TrafficPlanField
                component="text"
                id="cc"
                name="cc"
                label="CC"
                variant="standard"
                className={classes.trafficField}
                isLabelHighlighted
                disabled={areTrafficAgencyFieldsDisabled}
              />
            </Grid>
          )}
          {isContactGroupMVisible && (
            <Grid item>
              <TrafficPlanField
                component="text"
                id="contactGroupM"
                name="contactGroupM"
                label="Contact GroupM"
                variant="standard"
                className={classes.trafficField}
                disabled={areTrafficAgentFieldsDisabled}
              />
            </Grid>
          )}
          {isPhoneNumberGroupMVisible && (
            <Grid item>
              <TrafficPlanField
                component="text"
                id="phoneNumberGroupM"
                name="phoneNumberGroupM"
                label="Phone Number"
                variant="standard"
                className={classes.trafficField}
                disabled={areTrafficAgentFieldsDisabled}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {isDigital && (
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item xs={2}>
              <TrafficPlanField
                component="autocomplete"
                id="trafficRequest"
                name="trafficRequest"
                label="Traffic Request *"
                variant="standard"
                className={classes.trafficField}
                textFieldClassName={classes.trafficField}
                disabled={areTrafficAgencyFieldsDisabled}
                isLabelHighlighted
              />
            </Grid>
            <Grid item xs={2}>
              <TrafficPlanField
                component="autocomplete"
                id="thirdPartyMeasurement"
                name="thirdPartyMeasurement"
                label="Third Party Measurement *"
                variant="standard"
                className={classes.trafficField}
                textFieldClassName={classes.trafficField}
                disabled={areTrafficAgencyFieldsDisabled}
                isLabelHighlighted
              />
            </Grid>
            <Grid item>
              <TrafficPlanField
                component="text"
                id="adManagementOrderID"
                name="adManagementOrderID"
                label="AdManagement OrderID"
                variant="standard"
                className={classes.trafficField}
                disabled={areTrafficAgencyFieldsDisabled}
                isLabelHighlighted
              />
            </Grid>
            <Grid item>
              <TrafficPlanField
                component="text"
                id="adServerSeat"
                name="adServerSeat"
                label="AdServer Seat"
                variant="standard"
                className={classes.trafficField}
                disabled={areTrafficAgencyFieldsDisabled}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              className={classes.cancelButton}
              disabled={isLoading}
              onClick={handleOnCancel}
              id="tp_cancel"
              name="tp_cancel"
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={isLoading}
              onClick={handleOnSave}
              id="tp_save"
              name="tp_save"
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
