import { styled, Card as MuiCard } from "@mui/material";
import { darkIndigo } from "../../../../assets/styles/colors";

const PREFIX = "ActionInsertionItemStyles";
export const classes = {
  card: `${PREFIX}-card`,
  colorSystemMessageAvatar: `${PREFIX}-colorSystemMessageAvatar`
};

export const Card = styled(MuiCard)(({ theme }) => ({
  [`&.${classes.card}`]: {
    minWidth: theme.typography.pxToRem(275),
    maxWidth: theme.typography.pxToRem(700),
    margin: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(10)}`
  },
  [`& .${classes.colorSystemMessageAvatar}`]: {
    backgroundColor: darkIndigo
  }
}));
