import React from "react";

import { connect } from "react-redux";

import Scrollbar from "../shared/Scrollbar";
import TrafficItemItem from "./TrafficItemItem";

import { getTrafficItems } from "../../business/models/TrafficItem/trafficItemSelectors";

const TrafficItemPanel = ({ trafficItems }) => (
  <Scrollbar>
    {trafficItems.map(ti => (
      <TrafficItemItem key={ti.id} id={ti.id} trafficItem={ti} />
    ))}
  </Scrollbar>
);

const mapStateToProps = state => {
  return {
    trafficItems: getTrafficItems(state)
  };
};

export default connect(mapStateToProps, null)(TrafficItemPanel);
