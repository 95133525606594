const currencies = {
  AED: "AED",
  ALL: "ALL",
  AUD: "AUD",
  BAM: "BAM",
  BDT: "BDT",
  CAD: "CAD",
  CHF: "CHF",
  CLP: "CLP",
  CNY: "CNY",
  CZK: "CZK",
  DKK: "DKK",
  EUR: "EUR",
  GBP: "GBP",
  HKD: "HKD",
  HUF: "HUF",
  IDR: "IDR",
  INR: "INR",
  ISK: "ISK",
  JPY: "JPY",
  KHR: "KHR",
  KRW: "KRW",
  KSH: "KSH",
  KWD: "KWD",
  MAD: "MAD",
  MXN: "MXN",
  MYR: "MYR",
  NGN: "NGN",
  NIS: "NIS",
  NOK: "NOK",
  NZD: "NZD",
  PEN: "PEN",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  QAR: "QAR",
  RON: "RON",
  RUB: "RUB",
  SAR: "SAR",
  SEK: "SEK",
  SGD: "SGD",
  THB: "THB",
  TND: "TND",
  TRY: "TRY",
  TWD: "TWD",
  TZS: "TZS",
  USD: "USD",
  VND: "VND",
  ZAR: "ZAR"
};

const currencySymbols = {
  [currencies.AED]: "DH",
  [currencies.ALL]: "Lek",
  [currencies.AUD]: "$",
  [currencies.BAM]: "KM",
  [currencies.BDT]: "৳",
  [currencies.CAD]: "$",
  [currencies.CHF]: "CHF",
  [currencies.CLP]: "$",
  [currencies.CNY]: "¥",
  [currencies.CZK]: "Kč",
  [currencies.DKK]: "kr",
  [currencies.EUR]: "€",
  [currencies.GBP]: "£",
  [currencies.HKD]: "$",
  [currencies.HUF]: "Ft",
  [currencies.IDR]: "Rp",
  [currencies.INR]: "₹",
  [currencies.ISK]: "kr",
  [currencies.JPY]: "¥",
  [currencies.KHR]: "៛",
  [currencies.KRW]: "₩",
  [currencies.KSH]: "Sh",
  [currencies.KWD]: "KD",
  [currencies.MAD]: "DH",
  [currencies.MXN]: "Mex$",
  [currencies.MYR]: "RM",
  [currencies.NGN]: "₦",
  [currencies.NIS]: "₪",
  [currencies.NOK]: "kr",
  [currencies.NZD]: "$",
  [currencies.PEN]: "S/",
  [currencies.PHP]: "₱",
  [currencies.PKR]: "Re",
  [currencies.PLN]: "zł",
  [currencies.QAR]: "QR",
  [currencies.RON]: "leu",
  [currencies.RUB]: "₽",
  [currencies.SAR]: "SAR",
  [currencies.SEK]: "kr",
  [currencies.SGD]: "$",
  [currencies.THB]: "฿",
  [currencies.TND]: "DT",
  [currencies.TRY]: "₺",
  [currencies.TWD]: "NT$",
  [currencies.TZS]: "Sh",
  [currencies.USD]: "$",
  [currencies.VND]: "₫",
  [currencies.ZAR]: "R"
};

Object.freeze(currencySymbols);
Object.freeze(currencies);

export { currencies, currencySymbols };
