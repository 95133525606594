import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import {
  Clear,
  FlightTakeoff,
  FlightLand,
  Visibility,
  Cached as Compare,
  CallSplit,
  FileCopy,
  Cached,
  HourglassEmpty as Hourglass,
  MoreHoriz,
  CheckCircle,
  Cancel,
  Airplay,
  DeleteForever,
  CheckBox,
  CheckBoxOutlineBlank,
  QuestionAnswer,
  Help,
  AccountCircle as UserAccount,
  Email,
  ReportProblem,
  Replay,
  Sort,
  Search,
  Save,
  DoneAll,
  PermIdentity,
  Lock,
  Fingerprint,
  Comment,
  Publish,
  ImportContacts,
  Radio,
  Tv,
  Public,
  LocalMovies,
  NaturePeople,
  DevicesOther,
  PersonAdd,
  PersonAddDisabled as PersonDisable,
  History,
  FormatIndentIncrease,
  EuroSymbol,
  StarBorder,
  Star,
  FindInPage,
  GetApp,
  TrendingUp,
  AttachMoney,
  CreditCard,
  Title,
  RemoveCircleOutline,
  DeleteSharp,
  MonetizationOn,
  Description,
  DoneOutline,
  Edit,
  GroupWork,
  Home,
  HourglassFull,
  ImageSearch,
  Traffic,
  ViewHeadline,
  LooksOneOutlined as LooksOne,
  Remove,
  InventorySharp,
  NextPlanOutlined
} from "@mui/icons-material";
import colors from "./colors";

import { classes, getStyledSidebarIcon, getStyledIcon } from "./iconStyles";

// MoreHoriz za traffic status

import arrivedImg from "../img/campaignStatusIcons/arrived.png";
import inAirImg from "../img/campaignStatusIcons/inAir.png";
import scheduledImg from "../img/campaignStatusIcons/scheduled.png";
import takeOffImg from "../img/campaignStatusIcons/takeOff.png";
import landingImg from "../img/campaignStatusIcons/landing.png";

import groupMLogoIcon from "../img/agencyLogoIcons/GroupMLogo.png";
import kineticLogoIcon from "../img/agencyLogoIcons/KineticLogo.png";
import mindshareLogoIcon from "../img/agencyLogoIcons/MindshareLogo.png";
import wavemakerLogoIcon from "../img/agencyLogoIcons/WavemakerLogo.png";
import essencemediacomLogoIcon from "../img/agencyLogoIcons/EssenceMediacomLogo.png";
import blossomLogoIcon from "../img/agencyLogoIcons/BlossomLogo.png";
import greenhouseoneLogoIcon from "../img/agencyLogoIcons/GreenhouseoneLogo.png";
import greenhousetechLogoIcon from "../img/agencyLogoIcons/GreenhousetechLogo.png";
import choreographcreateLogoIcon from "../img/agencyLogoIcons/ChoreographCreateLogo.png";

import GroupMNameIcon from "../img/groupmlogo.png";
import KineticNameIcon from "../img/kineticlogo.png";
import EssenceMediacomNameIcon from "../img/essencemediacomlogo.png";
import MindshareNameIcon from "../img/mindsharelogo.png";
import WavemakerNameIcon from "../img/wavemakerlogo.png";
import BlossomNameIcon from "../img/blossomlogo.png";
import GreenhouseoneNameIcon from "../img/greenhouseonelogo.png";
import GreenhousetechNameIcon from "../img/greenhousetechlogo.png";
import ChoreographCreateNameIcon from "../img/choreographcreatelogo.png";

import "../css/colors.css"; // Remove
import {
  AGENCY_GROUPM,
  AGENCY_KINETIC,
  AGENCY_ESSENCEMEDIACOM,
  AGENCY_WAVEMAKER,
  AGENCY_MINDSHARE,
  CAMPAIGN_SCHEDULED_STATUS,
  CAMPAIGN_TAKE_OFF_STATUS,
  CAMPAIGN_IN_AIR_STATUS,
  CAMPAIGN_LANDING_STATUS,
  CAMPAIGN_ARRIVED_STATUS,
  ruleTypesEnum,
  AGENCY_BLOSSOM,
  AGENCY_GREENHOUSEONE,
  AGENCY_GREENHOUSETECH,
  AGENCY_CHOREOGRAPHCREATE
} from "../../configurations/appConstants";

import { capitalize } from "../../functions/util";

const {
  EXCEED_THRESHOLD_NAME,
  FOREIGN_CURRENCY_NAME,
  SPECIFIC_SURCHARGE_NAME,
  SPECIFIC_TITLE_NAME,
  NEGATIVE_AMOUNT_NAME
} = ruleTypesEnum;

const CampaignStatusIcon = ({ status }) => {
  const Img = getStyledIcon("img");
  switch (status) {
    case CAMPAIGN_SCHEDULED_STATUS:
      return (
        <Img src={scheduledImg} alt="Scheduled" className={classes.size48} />
      );
    case CAMPAIGN_TAKE_OFF_STATUS:
      return (
        <Img src={takeOffImg} alt="takeOffImg" className={classes.size48} />
      );
    case CAMPAIGN_IN_AIR_STATUS:
      return <Img src={inAirImg} alt="InAir" className={classes.size48} />;
    case CAMPAIGN_LANDING_STATUS:
      return <Img src={landingImg} alt="Landing" className={classes.size48} />;
    case CAMPAIGN_ARRIVED_STATUS:
      return <Img src={arrivedImg} alt="Landing" className={classes.size48} />;
    default:
      return <div />;
  }
};

export const MoneyIcon = ({ disabled }) => {
  const MonetizationOnIcon = getStyledIcon(MonetizationOn);

  return (
    <MonetizationOnIcon
      className={clsx(classes.font12, classes.typographyIcon, {
        [classes.disabled]: disabled
      })}
    />
  );
};

const TakeOffIcon = ({ fontSize }) => {
  const FlightTakeoffIcon = getStyledIcon(FlightTakeoff);

  return <FlightTakeoffIcon className="blueLight" fontSize={fontSize} />;
};

const LandingIcon = ({ fontSize }) => {
  const FlightLandIcon = getStyledIcon(FlightLand);

  return <FlightLandIcon className="purple" fontSize={fontSize} />;
};

const VisibilityIcon = ({ fontSize }) => {
  const StyledVisibilityIcon = getStyledIcon(Visibility);

  return <StyledVisibilityIcon className="blueLight" fontSize={fontSize} />;
};

const SearchIcon = ({ fontSize, color = "initial" }) => {
  const StyledSearchIcon = getStyledIcon(Search);

  return (
    <StyledSearchIcon
      className={clsx({
        [classes[`color${capitalize(color)}`]]: color !== "initial"
      })}
      fontSize={fontSize}
    />
  );
};

const SortIcon = ({ fontSize, color = "initial" }) => {
  const StyledSortIcon = getStyledIcon(Sort);

  return (
    <StyledSortIcon
      className={clsx({
        [classes[`color${capitalize(color)}`]]: color !== "initial"
      })}
      fontSize={fontSize}
    />
  );
};

const DownloadIcon = ({ isActive, disabled, fontSize, color = "initial" }) => {
  const GetAppIcon = getStyledIcon(GetApp);

  return (
    <GetAppIcon
      className={clsx(color, fontSize, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive,
        [classes.disabled]: disabled,
        [classes[`color${capitalize(color)}`]]: color !== "initial",
        [classes.font14]: fontSize === 14
      })}
    />
  );
};

DownloadIcon.propTypes = {
  color: PropTypes.oneOf(Object.keys(colors))
};

const ResumeIcon = ({ fontSize, color = "initial" }) => {
  const ReplayIcon = getStyledIcon(Replay);

  return (
    <ReplayIcon
      className={clsx({
        [classes[`color${capitalize(color)}`]]: color !== "initial"
      })}
      fontSize={fontSize}
    />
  );
};

ResumeIcon.propTypes = {
  color: PropTypes.oneOf(Object.keys(colors))
};

const TrafficIcon = ({ fontSize, color = "initial" }) => {
  const StyledTrafficIcon = getStyledIcon(Traffic);

  return (
    <StyledTrafficIcon
      className={clsx({
        [classes[`color${capitalize(color)}`]]: color !== "initial"
      })}
      fontSize={fontSize}
    />
  );
};

const FavouriteEmptyIcon = ({ fontSize, color = "initial" }) => {
  const StarBorderIcon = getStyledIcon(StarBorder);

  return (
    <StarBorderIcon
      className={clsx({
        [classes[`color${capitalize(color)}`]]: color !== "initial"
      })}
      fontSize={fontSize}
    />
  );
};

const FavouriteIcon = ({ fontSize, color = "initial" }) => {
  const StarIcon = getStyledIcon(Star);

  return (
    <StarIcon
      className={clsx({
        [classes[`color${capitalize(color)}`]]: color !== "initial"
      })}
      fontSize={fontSize}
    />
  );
};

TrafficIcon.propTypes = {
  color: PropTypes.oneOf(Object.keys(colors))
};

const SaveIcon = ({ fontSize }) => {
  const StyledSaveIcon = getStyledIcon(Save);

  return <StyledSaveIcon className="blue" fontSize={fontSize} />;
};

const ClearIcon = ({ fontSize }) => {
  const StyledClearIcon = getStyledIcon(Clear);

  return <StyledClearIcon className="red" fontSize={fontSize} />;
};
const CreateIcon = ({ color }) => {
  const EditIcon = getStyledSidebarIcon(Edit);

  return <EditIcon className={clsx(color, classes.size)} />;
};
const ActionIcon = ({ color }) => {
  const DoneOutlineIcon = getStyledSidebarIcon(DoneOutline);

  return <DoneOutlineIcon className={clsx(color, classes.size)} />;
};
const AdditionalApprovalIcon = ({ color }) => {
  const HourglassFullIcon = getStyledSidebarIcon(HourglassFull);

  return <HourglassFullIcon className={clsx(color, classes.size)} />;
};
const HomeIcon = ({ color }) => {
  const StyledHomeIcon = getStyledSidebarIcon(Home);

  return <StyledHomeIcon className={clsx(color, classes.size)} />;
};

const TemplateIcon = ({ color }) => {
  const DescriptionIcon = getStyledSidebarIcon(Description);

  return <DescriptionIcon className={clsx(color, classes.size)} />;
};

const OverviewIcon = ({ color }) => {
  const ViewHeadlineIcon = getStyledSidebarIcon(ViewHeadline);

  return <ViewHeadlineIcon className={clsx(color, classes.size)} />;
};

const TrafficOverviewIcon = ({ color }) => {
  const StyledTrafficIcon = getStyledSidebarIcon(Traffic);

  return <StyledTrafficIcon className={clsx(color, classes.size)} />;
};

const HtdImageIcon = ({ color }) => {
  const ImageSearchIcon = getStyledSidebarIcon(ImageSearch);

  return <ImageSearchIcon className={clsx(color, classes.size)} />;
};

export {
  VisibilityIcon,
  SearchIcon,
  SortIcon,
  SaveIcon,
  ClearIcon,
  ActionIcon,
  AdditionalApprovalIcon,
  OverviewIcon,
  DownloadIcon,
  TrafficIcon,
  TakeOffIcon,
  LandingIcon,
  CreateIcon,
  HomeIcon,
  TemplateIcon,
  TrafficOverviewIcon,
  FavouriteEmptyIcon,
  FavouriteIcon,
  CampaignStatusIcon,
  HtdImageIcon,
  ResumeIcon
};

export const FingerprintIcon = ({ isActive, disabled }) => {
  const StyledFingerprintIcon = getStyledIcon(Fingerprint);

  return (
    <StyledFingerprintIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive,
        [classes.disabled]: disabled
      })}
    />
  );
};

export const AssignIcon = ({ isActive, disabled }) => {
  const StyledPersonAddIcon = getStyledIcon(PersonAdd);

  return (
    <StyledPersonAddIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive,
        [classes.disabled]: disabled
      })}
    />
  );
};

export const UnassignIcon = ({ isActive, disabled }) => {
  const PersonDisableIcon = getStyledIcon(PersonDisable);

  return (
    <PersonDisableIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive,
        [classes.disabled]: disabled
      })}
    />
  );
};

export const PreviewIcon = ({ isActive, disabled }) => {
  const FindInPageIcon = getStyledIcon(FindInPage);

  return (
    <FindInPageIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive,
        [classes.disabled]: disabled
      })}
    />
  );
};

export const ExportVersionIcon = ({ isActive, disabled }) => {
  const HistoryIcon = getStyledIcon(History);

  return (
    <HistoryIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive,
        [classes.disabled]: disabled
      })}
    />
  );
};

export const RemarkIcon = ({ disabled }) => {
  const CommentIcon = getStyledIcon(Comment);

  return (
    <CommentIcon
      className={clsx(classes.font24, classes.blueIcon, {
        [classes.disabled]: disabled
      })}
    />
  );
};

export const PermIdentityIcon = ({ disabled }) => {
  const StyledPermIdentityIcon = getStyledIcon(PermIdentity);

  return (
    <StyledPermIdentityIcon
      className={clsx(classes.font24, classes.blueIcon, {
        [classes.disabled]: disabled
      })}
    />
  );
};

export const LockIcon = ({ disabled }) => {
  const StyledLockIcon = getStyledIcon(Lock);

  return (
    <StyledLockIcon
      className={clsx(classes.font24, classes.blueIcon, {
        [classes.disabled]: disabled
      })}
    />
  );
};

export const PublishIcon = ({ disabled }) => {
  const StyledPublishIcon = getStyledIcon(Publish);

  return (
    <StyledPublishIcon
      className={clsx(classes.font24, classes.blueIcon, {
        [classes.disabled]: disabled
      })}
    />
  );
};

export const ChatIcon = ({ isActive, disabled }) => {
  const CommentIcon = getStyledIcon(Comment);

  return (
    <CommentIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive,
        [classes.disabled]: disabled
      })}
    />
  );
};

export const InternalRemarkIcon = ({ isActive, disabled }) => {
  const QuestionAnswerIcon = getStyledIcon(QuestionAnswer);

  return (
    <QuestionAnswerIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive,
        [classes.disabled]: disabled
      })}
    />
  );
};

export const EditIcon = ({ disabled }) => {
  const StyledEditIcon = getStyledIcon(Edit);

  return (
    <StyledEditIcon
      className={clsx(classes.orangeIcon, { [classes.disabled]: disabled })}
    />
  );
};

export const ViewIcon = ({ color = "initial" }) => {
  const StyledVisibilityIcon = getStyledIcon(Visibility);

  return (
    <StyledVisibilityIcon
      className={clsx({
        [classes[`color${capitalize(color)}`]]: color !== "initial"
      })}
    />
  );
};

export const CompareIcon = () => {
  const StyledCompareIcon = getStyledIcon(Compare);

  return <StyledCompareIcon className={classes.lightBlueIcon} />;
};

export const OrderBuyingStatusesIcon = () => {
  const FormatIndentIncreaseIcon = getStyledIcon(FormatIndentIncrease);

  return <FormatIndentIncreaseIcon className={classes.blackIcon} />;
};

export const OrderFinancialsDigitalIcon = () => {
  const EuroSymbolIcon = getStyledIcon(EuroSymbol);

  return <EuroSymbolIcon className={classes.blueIcon} />;
};

export const NoTrafficStatusIcon = () => {
  const RemoveCircleOutlineIcon = getStyledIcon(RemoveCircleOutline);

  return <RemoveCircleOutlineIcon className={classes.redIcon} />;
};

export const DeleteNotificationIcon = () => {
  const DeleteSharpIcon = getStyledIcon(DeleteSharp);

  return <DeleteSharpIcon className={classes.blueIcon} />;
};

export const InsertionIcon = ({ disabled }) => {
  const CallSplitIcon = getStyledIcon(CallSplit);

  return (
    <CallSplitIcon
      className={clsx(classes.blueIcon, { [classes.disabled]: disabled })}
    />
  );
};

export const InsertionRowIcon = ({ disabled }) => {
  const CallSplitIcon = getStyledIcon(CallSplit);

  return (
    <CallSplitIcon
      className={clsx(classes.grayIcon, { [classes.disabled]: disabled })}
    />
  );
};

export const InvoicedInsertionIcon = ({ disabled }) => {
  const CallSplitIcon = getStyledIcon(CallSplit);

  return (
    <CallSplitIcon
      className={clsx(classes.redIcon, { [classes.disabled]: disabled })}
    />
  );
};

export const CopyIcon = ({ color = "azureRadiance", disabled }) => {
  const FileCopyIcon = getStyledIcon(FileCopy);

  return (
    <FileCopyIcon
      className={clsx(
        { [classes[`color${capitalize(color)}`]]: !disabled },
        { [classes.disabled]: disabled }
      )}
    />
  );
};

export const CopyTextIcon = () => {
  const FileCopyIcon = getStyledIcon(FileCopy);

  return <FileCopyIcon className={classes.font18} />;
};

export const DeleteIcon = ({ disabled }) => {
  const StyledClearIcon = getStyledIcon(Clear);

  return (
    <StyledClearIcon
      className={clsx(classes.redIcon, { [classes.disabled]: disabled })}
    />
  );
};

export const DeleteSingleNotificationIcon = ({ disabled }) => {
  const StyledClearIcon = getStyledIcon(Clear);

  return (
    <StyledClearIcon
      className={clsx(classes.yellowIcon, { [classes.disabled]: disabled })}
    />
  );
};

export const DeleteOrderIcon = ({ disabled }) => {
  const DeleteForeverIcon = getStyledIcon(DeleteForever);

  return (
    <DeleteForeverIcon
      className={clsx(classes.blueIcon, { [classes.disabled]: disabled })}
    />
  );
};

export const DeleteItemIcon = ({ disabled }) => {
  const DeleteForeverIcon = getStyledIcon(DeleteForever);

  return (
    <DeleteForeverIcon
      className={clsx(classes.blueIcon, { [classes.disabled]: disabled })}
    />
  );
};

export const MbsIcon = ({ disabled }) => {
  const AirplayIcon = getStyledIcon(Airplay);

  return (
    <AirplayIcon
      className={clsx(classes.blackIcon, { [classes.disabled]: disabled })}
    />
  );
};

export const CheckBoxIcon = () => {
  const StyledCheckBoxIcon = getStyledIcon(CheckBox);

  return <StyledCheckBoxIcon className={classes.blueIcon} />;
};

export const UncheckBoxIcon = () => {
  const CheckBoxOutlineBlankIcon = getStyledIcon(CheckBoxOutlineBlank);

  return <CheckBoxOutlineBlankIcon className={classes.typographyIcon} />;
};

export const StatusChangeIcon = ({ disabled }) => {
  const NextPlanOutlinedIcon = getStyledIcon(NextPlanOutlined);

  return (
    <NextPlanOutlinedIcon
      className={clsx(classes.blueIcon, { [classes.disabled]: disabled })}
    />
  );
};

const SpecificTitleIcon = ({ isActive }) => {
  const TitleIcon = getStyledIcon(Title);

  return (
    <TitleIcon
      className={clsx(classes.font24, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const SpecificSurchargeIcon = ({ isActive }) => {
  const CreditCardIcon = getStyledIcon(CreditCard);

  return (
    <CreditCardIcon
      className={clsx(classes.font24, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const ForeignCurrencyIcon = ({ isActive }) => {
  const AttachMoneyIcon = getStyledIcon(AttachMoney);

  return (
    <AttachMoneyIcon
      className={clsx(classes.font24, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const ExceedThresholdIcon = ({ isActive }) => {
  const TrendingUpIcon = getStyledIcon(TrendingUp);

  return (
    <TrendingUpIcon
      className={clsx(classes.font24, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const NegativeAmountIcon = ({ isActive }) => {
  const MinusIcon = getStyledIcon(Remove);
  const OneIcon = getStyledIcon(LooksOne);

  return (
    <>
      <MinusIcon
        className={clsx(classes.font24, {
          [classes.typographyIcon]: !isActive,
          [classes.activeTypographyIcon]: isActive
        })}
      />
      <OneIcon
        className={clsx(classes.font24, {
          [classes.typographyIcon]: !isActive,
          [classes.activeTypographyIcon]: isActive
        })}
      />
    </>
  );
};

const PrintIcon = ({ isActive }) => {
  const ImportContactsIcon = getStyledIcon(ImportContacts);

  return (
    <ImportContactsIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const RadioIcon = ({ isActive }) => {
  const StyledRadioIcon = getStyledIcon(Radio);

  return (
    <StyledRadioIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const TvIcon = ({ isActive }) => {
  const StyledTvIcon = getStyledIcon(Tv);

  return (
    <StyledTvIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const DigitalIcon = ({ isActive }) => {
  const PublicIcon = getStyledIcon(Public);

  return (
    <PublicIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const CinemaIcon = ({ isActive }) => {
  const LocalMoviesIcon = getStyledIcon(LocalMovies);

  return (
    <LocalMoviesIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const OohIcon = ({ isActive }) => {
  const NaturePeopleIcon = getStyledIcon(NaturePeople);

  return (
    <NaturePeopleIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const OtherIcon = ({ isActive }) => {
  const DevicesOtherIcon = getStyledIcon(DevicesOther);

  return (
    <DevicesOtherIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const InventoryIcon = ({ isActive }) => {
  const InventorySharpIcon = getStyledIcon(InventorySharp);

  return (
    <InventorySharpIcon
      className={clsx(classes.font14, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive
      })}
    />
  );
};

const PrintSearchIcon = () => {
  const ImportContactsIcon = getStyledIcon(ImportContacts);

  return <ImportContactsIcon className={classes.typographyIcon} />;
};

const RadioSearchIcon = () => {
  const StyledRadioIcon = getStyledIcon(Radio);

  return <StyledRadioIcon className={classes.typographyIcon} />;
};

const TvSearchIcon = () => {
  const StyledTvIcon = getStyledIcon(Tv);

  return <StyledTvIcon className={classes.typographyIcon} />;
};

const DigitalSearchIcon = () => {
  const PublicIcon = getStyledIcon(Public);

  return <PublicIcon className={classes.typographyIcon} />;
};

const CinemaSearchIcon = () => {
  const LocalMoviesIcon = getStyledIcon(LocalMovies);

  return <LocalMoviesIcon className={classes.typographyIcon} />;
};

const OohSearchIcon = () => {
  const NaturePeopleIcon = getStyledIcon(NaturePeople);

  return <NaturePeopleIcon className={classes.typographyIcon} />;
};

const OtherSearchIcon = () => {
  const DevicesOtherIcon = getStyledIcon(DevicesOther);

  return <DevicesOtherIcon className={classes.typographyIcon} />;
};

const InventorySearchIcon = () => {
  const InventorySharpIcon = getStyledIcon(InventorySharp);

  return <InventorySharpIcon className={classes.typographyIcon} />;
};

export const ApprovedSelectionIcon = () => {
  const DoneAllIcon = getStyledIcon(DoneAll);

  return <DoneAllIcon className={clsx(classes.size24, classes.greenIcon)} />;
};

export const DeclineSelectionIcon = () => {
  const StyledClearIcon = getStyledIcon(Clear);

  return <StyledClearIcon className={clsx(classes.size24, classes.redIcon)} />;
};

const WorkInProgressIcon = () => {
  const MoreHorizIcon = getStyledIcon(MoreHoriz);

  return (
    <MoreHorizIcon
      className={clsx(classes.size20, classes.circle, classes.progressIcon)}
    />
  );
};

const ReadyForApprovalIcon = () => {
  const CheckCircleIcon = getStyledIcon(CheckCircle);

  return <CheckCircleIcon className={classes.grayIcon} />;
};

const ApprovedIcon = () => {
  const CheckCircleIcon = getStyledIcon(CheckCircle);

  return <CheckCircleIcon className={classes.greenIcon} />;
};

const NotApprovedIcon = () => {
  const CheckCircleIcon = getStyledIcon(CheckCircle);

  return <CheckCircleIcon className={classes.redIcon} />;
};

const OrderChangeIcon = () => {
  const CachedIcon = getStyledIcon(Cached);

  return (
    <CachedIcon
      className={clsx(classes.size20, classes.circle, classes.changeIcon)}
    />
  );
};

const ChangeNotApprovedIcon = () => {
  const CachedIcon = getStyledIcon(Cached);

  return (
    <CachedIcon
      className={clsx(
        classes.size20,
        classes.circle,
        classes.changeNotApprovedIcon
      )}
    />
  );
};
const MatrixApprovedIcon = () => {
  const CheckCircleIcon = getStyledIcon(CheckCircle);

  return <CheckCircleIcon className={classes.greenIcon} />;
};

const MatrixAwaitingIcon = () => {
  const HelpIcon = getStyledIcon(Help);

  return <HelpIcon className={classes.grayIcon} />;
};

export const CancelledIcon = ({ size, disabled }) => {
  const CancelIcon = getStyledIcon(Cancel);

  return (
    <CancelIcon
      className={clsx(classes.redIcon, {
        [classes.size20]: size === 20,
        [classes.size24]: size === 24,
        [classes.size32]: size === 32,
        [classes.size48]: size === 48,
        [classes.disabled]: disabled
      })}
    />
  );
};

const ToBeCancelledIcon = () => {
  const CancelIcon = getStyledIcon(Cancel);

  return <CancelIcon className={classes.orangeIcon} />;
};

const PendingForApprovalRfaIcon = () => {
  const HourglassIcon = getStyledIcon(Hourglass);

  return (
    <HourglassIcon
      className={clsx(classes.size20, classes.circle, classes.changeIcon)}
    />
  );
};

const PendingForApprovalOcIcon = () => {
  const HourglassIcon = getStyledIcon(Hourglass);

  return (
    <HourglassIcon
      className={clsx(classes.size20, classes.circle, classes.changeIcon)}
    />
  );
};

export const StatusCannotBeChangedIcon = () => {
  const ReportProblemIcon = getStyledIcon(ReportProblem);

  return <ReportProblemIcon className={classes.orangeIcon} />;
};

export const ProfileIcon = () => {
  const StyledPermIdentityIcon = getStyledIcon(PermIdentity);

  return <StyledPermIdentityIcon className={classes.lightBlueIcon} />;
};

export const UserAccountIcon = () => {
  const StyledUserAccountIcon = getStyledIcon(UserAccount);

  return (
    <StyledUserAccountIcon className={clsx(classes.size48, classes.blueIcon)} />
  );
};

export const EmailIcon = ({
  fontSize,
  color = "initial",
  isActive,
  disabled
}) => {
  const StyledEmailIcon = getStyledIcon(Email);

  return (
    <StyledEmailIcon
      className={clsx(color, fontSize, {
        [classes.typographyIcon]: !isActive,
        [classes.activeTypographyIcon]: isActive,
        [classes.disabled]: disabled,
        [classes[`color${capitalize(color)}`]]: color !== "initial",
        [classes.font14]: fontSize === 14
      })}
    />
  );
};

export const MediaTypeIcon = ({ type, isActive }) => {
  switch (type) {
    case "print":
      return <PrintIcon isActive={isActive} />;
    case "radio":
      return <RadioIcon isActive={isActive} />;
    case "tv":
      return <TvIcon isActive={isActive} />;
    case "digital":
      return <DigitalIcon isActive={isActive} />;
    case "cinema":
      return <CinemaIcon isActive={isActive} />;
    case "ooh":
      return <OohIcon isActive={isActive} />;
    case "other":
      return <OtherIcon isActive={isActive} />;
    case "inventory":
      return <InventoryIcon isActive={isActive} />;
    default:
      return <div />;
  }
};

export const MediaTypeSearchIcon = ({ type }) => {
  switch (type) {
    case "Print":
      return <PrintSearchIcon />;
    case "Radio":
      return <RadioSearchIcon />;
    case "Tv":
      return <TvSearchIcon />;
    case "TV":
      return <TvSearchIcon />;
    case "Digital":
      return <DigitalSearchIcon />;
    case "Cinema":
      return <CinemaSearchIcon />;
    case "Ooh":
      return <OohSearchIcon />;
    case "OOH":
      return <OohSearchIcon />;
    case "Other":
      return <OtherSearchIcon />;
    case "Inventory":
      return <InventorySearchIcon />;
    default:
      return <div />;
  }
};

export const RuleTypeIcon = ({ type, isActive }) => {
  switch (type) {
    case "foreignCurrency":
      return <ForeignCurrencyIcon isActive={isActive} />;
    case "negativeAmount":
      return <NegativeAmountIcon isActive={isActive} />;
    case "specificTitle":
      return <SpecificTitleIcon isActive={isActive} />;
    case "specificSurcharge":
      return <SpecificSurchargeIcon isActive={isActive} />;
    case "exceedThreshold":
      return <ExceedThresholdIcon isActive={isActive} />;
    default:
      return <div />;
  }
};

export const RuleTypeSearchIcon = ({ type }) => {
  switch (type) {
    case FOREIGN_CURRENCY_NAME:
      return <ForeignCurrencyIcon />;
    case NEGATIVE_AMOUNT_NAME:
      return <NegativeAmountIcon />;
    case SPECIFIC_TITLE_NAME:
      return <SpecificTitleIcon />;
    case SPECIFIC_SURCHARGE_NAME:
      return <SpecificSurchargeIcon />;
    case EXCEED_THRESHOLD_NAME:
      return <ExceedThresholdIcon />;
    default:
      return <div />;
  }
};
export const StatusIcon = ({ status }) => {
  switch (status) {
    case 1:
      return <WorkInProgressIcon />;
    case 2:
      return <ReadyForApprovalIcon />;
    case 3:
    case 34:
      return <ApprovedIcon />;
    case 4:
      return <CancelledIcon />;
    case 5:
      return <NotApprovedIcon />;
    case 6:
      return <OrderChangeIcon />;
    case 32:
      return <ToBeCancelledIcon />;
    case 33:
      return <ChangeNotApprovedIcon />;
    case 35:
      return <PendingForApprovalRfaIcon />;
    case 36:
      return <PendingForApprovalOcIcon />;
    default:
      return <div />;
  }
};

export const ApprovalStatusIcon = ({ status }) => {
  switch (status) {
    case true:
      return <MatrixApprovedIcon />;
    case false:
      return <MatrixAwaitingIcon />;
    case null:
      return <div>N/A</div>;
    default:
      return <div />;
  }
};

export const AgencyLogoIcon = ({ agency }) => {
  const Img = getStyledIcon("img");

  switch (agency) {
    case AGENCY_GROUPM:
      return (
        <Img
          id="agency_groupM"
          src={groupMLogoIcon}
          alt="GroupM"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_KINETIC:
      return (
        <Img
          id="agency_kinetic"
          src={kineticLogoIcon}
          alt="Kinetic"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_ESSENCEMEDIACOM:
      return (
        <Img
          id="agency_essencemediacom"
          src={essencemediacomLogoIcon}
          alt="EssenceMediacom"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_WAVEMAKER:
      return (
        <Img
          id="agency_wavemaker"
          src={wavemakerLogoIcon}
          alt="Wavemaker"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_MINDSHARE:
      return (
        <Img
          id="agency_mindshare"
          src={mindshareLogoIcon}
          alt="Mindshare"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_BLOSSOM:
      return (
        <Img
          id="agency_blossom"
          src={blossomLogoIcon}
          alt="Blossom"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_GREENHOUSEONE:
      return (
        <Img
          id="agency_greenhouseone"
          src={greenhouseoneLogoIcon}
          alt="Greenhouseone"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_GREENHOUSETECH:
      return (
        <Img
          id="agency_greenhousetech"
          src={greenhousetechLogoIcon}
          alt="Greenhousetech"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_CHOREOGRAPHCREATE:
      return (
        <Img
          id="agency_choreographcreate"
          src={choreographcreateLogoIcon}
          alt="ChoreographCreate"
          className={classes.agencyLogoIcon}
        />
      );
    default:
      return <div />;
  }
};

export const AgencyLogoNameIcon = ({ agency }) => {
  const Img = getStyledIcon("img");

  switch (agency) {
    case AGENCY_GROUPM:
      return (
        <Img
          src={GroupMNameIcon}
          alt="GroupM"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_KINETIC:
      return (
        <Img
          src={KineticNameIcon}
          alt="Kinetic"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_ESSENCEMEDIACOM:
      return (
        <Img
          src={EssenceMediacomNameIcon}
          alt="EssenceMediacom"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_WAVEMAKER:
      return (
        <Img
          src={WavemakerNameIcon}
          alt="Wavemaker"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_MINDSHARE:
      return (
        <Img
          src={MindshareNameIcon}
          alt="Mindshare"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_BLOSSOM:
      return (
        <Img
          src={BlossomNameIcon}
          alt="Blossom"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_GREENHOUSEONE:
      return (
        <Img
          src={GreenhouseoneNameIcon}
          alt="Greenhouseone"
          className={classes.agencyLogoIcon}
        />
      );
    case AGENCY_CHOREOGRAPHCREATE:
      return (
        <Img
          src={ChoreographCreateNameIcon}
          alt="Choreographcreate"
          className={classes.agencyLogoIcon}
        />
      );
    default:
      return <div />;
  }
};

export const TrafficPlanAssignedIcon = ({ assigned }) => {
  const PersonDisableIcon = getStyledIcon(PersonDisable);
  const PersonAddIcon = getStyledIcon(PersonAdd);

  return assigned ? (
    <PersonDisableIcon className={classes.trafficPlanAssignedIcon} />
  ) : (
    <PersonAddIcon className={classes.trafficPlanAssignedIcon} />
  );
};
