import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";

import { AccordionSummary, AccordionDetails, Divider } from "@mui/material";

import {
  CAMPAIGN,
  PLAN,
  ORDER,
  PLACEHOLDER,
  ACTION_PAGE,
  EMPTY_STRING
} from "../../../../../../configurations/appConstants";

import { classes, Accordion } from "../styles/actionPlanItem";

import { getPropertyTextFromApiData } from "../../../../../../business/models/Plan/planSelectors";
import ActionPlanItemPopper from "./ActionPlanItemPopper";
import ActionPlanItemFold from "./ActionPlanItemFold";
import ActionPlanItemCompact from "./ActionPlanItemCompact";
import { parseIntOrDefault } from "../../../../../../business/models/common/utils/clientUtils";

const ActionPlanItem = ({ plan }) => {
  const navigate = useNavigate();
  const { agency, campaignId: cId, planId: pId } = useParams();
  const [open, setOpen] = useState(false);

  const urlPlanId = parseIntOrDefault(pId);
  const urlCampaignId = parseIntOrDefault(cId);

  const {
    planName = PLACEHOLDER,
    totalBudget,
    marathonPlanId,
    invoiceDate,
    currencyClient,
    exchangeRateClient,
    exchangeRate,
    planPoNumber,
    planner,
    currencySupplierSymbol,
    planId,
    planRemarks
  } = plan || {};

  const currencySupplier = useSelector(state =>
    getPropertyTextFromApiData(state, {
      name: "currencySupplierId",
      planId
    })
  );

  const buttonRef = useRef(null);

  const handleToggle = () => {
    buttonRef.current.focus();
    setOpen(!open);
  };

  const handleClose = event => {
    if (buttonRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const changeUrl = () =>
    navigate(
      `/${agency}/${ACTION_PAGE}/${CAMPAIGN}/${urlCampaignId}${
        urlPlanId === planId ? EMPTY_STRING : `/${PLAN}/${planId}`
      }`
    );

  const handleChange = () => {
    changeUrl();
  };

  const isActive = urlPlanId === planId;

  return (
    <>
      <Accordion
        square
        elevation={0}
        className={clsx(classes.expensionPlanPanel, {
          [classes.selected]: isActive
        })}
        expanded={isActive}
        onChange={handleChange}
      >
        <AccordionSummary
          classes={{ content: classes.expansionPanelSummeryContent }}
        >
          <ActionPlanItemCompact
            currencySupplierSymbol={currencySupplierSymbol}
            marathonPlanId={marathonPlanId}
            totalBudget={totalBudget}
            planName={planName}
            planId={planId}
            campaignId={urlCampaignId}
          />
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <ActionPlanItemFold
            planId={planId}
            buttonRef={buttonRef}
            onToggle={handleToggle}
          />
        </AccordionDetails>
      </Accordion>
      <ActionPlanItemPopper
        open={open}
        buttonRef={buttonRef}
        onClose={handleClose}
        marathonPlanId={marathonPlanId}
        invoiceDate={invoiceDate}
        currencyClient={currencyClient}
        exchangeRateClient={exchangeRateClient}
        currencySupplier={currencySupplier}
        exchangeRate={exchangeRate}
        planPoNumber={planPoNumber}
        planner={planner}
        planRemarks={planRemarks}
      />
    </>
  );
};

export default ActionPlanItem;
