import { styled, Accordion as MuiAccordion } from "@mui/material";
import { dodgerBlue } from "../../../../../../assets/styles/colors";

const PREFIX = "ActionPlanItemStyle";
export const classes = {
  expensionPlanPanel: `${PREFIX}-expensionPlanPanel`,
  expansionPanelSummeryContent: `${PREFIX}-expansionPanelSummeryContent`,
  selected: `${PREFIX}-selected`
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`&.${classes.expensionPlanPanel}`]: {
    marginBottom: theme.typography.pxToRem(8)
  },
  [`&.${classes.selected}`]: {
    borderColor: dodgerBlue
  },
  [`& .${classes.expansionPanelSummeryContent}`]: {
    width: "-webkit-fill-available"
  }
}));
