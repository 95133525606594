import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { isAuthorized } from "../../business/selectors/userProfileSelectors";
import ErrorMessage from "./ErrorMessage";
import { ERROR_MSG_NOT_AUTHORIZED } from "../../configurations/appConstants";

class PrivateRoute extends React.Component {
  render() {
    const { element: Component, path, authorized } = this.props;
    return (
      <Route
        path={path}
        element={
          authorized ? (
            <Component />
          ) : (
            <ErrorMessage message={ERROR_MSG_NOT_AUTHORIZED} />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authorized: isAuthorized(state, ownProps.claim)
  };
};

export default connect(mapStateToProps)(PrivateRoute);
