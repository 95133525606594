import React from "react";
import clsx from "clsx";
import Field from "../../../../../form/TrafficItemField";
import { classes, Grid } from "../../../../styles/fold";

const FoldCinema = ({
  editMode,
  areTrafficAgentFieldsDisabled,
  displayErrors,
  isDebtorActive
}) => {
  return (
    <Grid container justifyContent="flex-start">
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="trafficItemId"
          name="trafficItemId"
          helperText="Traffic Item ID"
          label="Traffic Item ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="marathonOrderId"
          name="marathonOrderId"
          helperText="Order ID"
          label="Order ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="marathonPlanId"
          name="marathonPlanId"
          helperText="Plan ID"
          label="Plan ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="orderStartDate"
          name="orderStartDate"
          helperText="Start Date"
          label="Start Date"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="orderEndDate"
          name="orderEndDate"
          helperText="End Date"
          label="End Date"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="orderDescription"
          name="orderDescription"
          helperText="Description"
          label="Description"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="networkLocations"
          name="networkLocations"
          label="Network Locations"
          helperText="Network Locations"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="autocomplete"
          id="digitalFileId"
          name="digitalFileId"
          label="Digital File"
          helperText="Digital File"
          variant="standard"
          textFieldClassName={classes.field}
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="spotLength"
          name="spotLength"
          label="Spotlength"
          helperText="Spotlength"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="deliveryDate"
          name="deliveryDate"
          label="Delivery Date"
          helperText="Delivery Date"
          variant="standard"
          hideIcon={!editMode}
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="technicalSpecifications"
          name="technicalSpecifications"
          label="Technical Specifications"
          helperText="Technical Specifications"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="deliveryAddress"
          name="deliveryAddress"
          label="Delivery Address"
          helperText="Delivery Address"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="vendorReference"
          name="vendorReference"
          label="Vendor Reference"
          helperText="Vendor Reference"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
        xs={12}
      >
        <Field
          component="text"
          id="trafficItemRemarks"
          name="trafficItemRemarks"
          helperText="Remarks"
          label="Remarks"
          variant="standard"
          disabled={!editMode || !isDebtorActive}
          rowsMax="6"
          multiline
          isLabelHighlighted
        />
      </Grid>
    </Grid>
  );
};

export default FoldCinema;
