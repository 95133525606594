import { styled, Grid as MuiGrid } from "@mui/material";

const PREFIX = "AddPublicPermissionDialogStyles";
export const classes = {
  root: `${PREFIX}-root`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    paddingTop: "35px"
  }
}));
