import { styled, Accordion as MuiAccordion } from "@mui/material";

import {
  linkWater,
  azureRadiance,
  baliHai,
  matisse,
  solitude,
  lightGrey
} from "../../../../assets/styles/colors";

const PREFIX = "CampaignOverviewPanelStyles";
export const classes = {
  invalidAgency: `${PREFIX}-invalidAgency`,
  copyProcess: `${PREFIX}-copyProcess`,
  calcInfoGridItem: `${PREFIX}-calcInfoGridItem`,
  ctcInfoGridItemAlign: `${PREFIX}-ctcInfoGridItemAlign`,
  typographyValue: `${PREFIX}-typographyValue`,
  typographyDetailsValue: `${PREFIX}-typographyDetailsValue`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  typographyMarked: `${PREFIX}-typographyMarked`,
  typographyHeaderValue: `${PREFIX}-typographyHeaderValue`,
  typographyHeaderCaption: `${PREFIX}-typographyHeaderCaption`,
  typographyLink: `${PREFIX}-typographyLink`,
  budgetWidth: `${PREFIX}-budgetWidth`,
  plansWidth: `${PREFIX}-plansWidth`,
  ordersWidth: `${PREFIX}-ordersWidth`,
  dateWidth: `${PREFIX}-dateWidth`,
  verticalDivider: `${PREFIX}-verticalDivider`,
  link: `${PREFIX}-link`
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`&.${classes.invalidAgency}`]: {
    backgroundColor: lightGrey
  },
  [`&.${classes.copyProcess}`]: {
    backgroundColor: "lavender"
  },
  [`& .${classes.calcInfoGridItem}`]: {
    width: theme.typography.pxToRem(183)
  },
  [`& .${classes.ctcInfoGridItemAlign}`]: {
    textAlign: "end"
  },
  [`& .${classes.typographyValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(22)
  },
  [`& .${classes.typographyDetailsValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(16)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyMarked}`]: {
    color: azureRadiance
  },
  [`& .${classes.typographyHeaderValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyHeaderCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyLink}`]: {
    "&:hover": {
      color: theme.palette.primary.light
    }
  },
  [`& .${classes.link}`]: {
    display: "block"
  },
  [`& .${classes.budgetWidth}`]: {
    width: theme.typography.pxToRem(280)
  },
  [`& .${classes.plansWidth}`]: {
    width: theme.typography.pxToRem(72)
  },
  [`& .${classes.ordersWidth}`]: {
    width: theme.typography.pxToRem(78)
  },
  [`& .${classes.dateWidth}`]: {
    width: theme.typography.pxToRem(200)
  },
  [`& .${classes.verticalDivider}`]: {
    borderLeft: `0.1em solid ${solitude}`,
    padding: "0.5em"
  }
}));
