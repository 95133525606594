export const MANDATORY_RULE = "mandatory";
export const EMAIL_RULE = "email";
export const DATE_RULE = "date";
export const DECIMAL_RULE = "decimal";
export const ISO8859_RULE = "iso_8859_15";
export const NUMBER_OF_CHARACTERS_RULE = "length";
export const EQUALS_TO_ZERO_RULE = "equals";
export const VALIDATE_EXCHANGE_RATE = "validate_exchange_rate";

export const MANDATORY_MESSAGE = "This is a mandatory field";
export const EMAIL_MESSAGE = "Please enter a valid email address";
export const DATE_MESSAGE = "Please enter a valid date value";
export const DECIMAL_MESSAGE = "Please, insert a number";
export const ISO8859_MESSAGE =
  "Some characters are not supported by ISO 8859-15";
export const NUMBER_OF_CHARACTERS_MESSAGE_4 =
  "The maximum amount of characters for this field is 4";
export const NUMBER_OF_CHARACTERS_MESSAGE_50 =
  "The maximum amount of characters for this field is 50";
export const NUMBER_OF_CHARACTERS_MESSAGE_100 =
  "The maximum amount of characters for this field is 100";
export const NUMBER_OF_CHARACTERS_MESSAGE_150 =
  "The maximum amount of characters for this field is 150";
export const NUMBER_OF_CHARACTERS_MESSAGE_200 =
  "The maximum amount of characters for this field is 200";
export const NUMBER_OF_CHARACTERS_MESSAGE_255 =
  "The maximum amount of characters for this field is 255";
export const NUMBER_OF_CHARACTERS_MESSAGE_365 =
  "The maximum amount of characters for this field is 365";
export const NUMBER_OF_CHARACTERS_MESSAGE_500 =
  "The maximum amount of characters for this field is 500";
export const NUMBER_OF_CHARACTERS_MESSAGE_550 =
  "The maximum amount of characters for this field is 550";
export const NUMBER_OF_CHARACTERS_MESSAGE_1000 =
  "The maximum amount of characters for this field is 1000";
export const EQUALS_TO_ZERO_MESSAGE = "Value must be equal to 0";
export const VALIDATE_EXCHANGE_RATE_MESSAGE =
  "Exchange rate cannot be 1.00 when Currency is non-EUR. Please contact GroupM Finance";
