import { GET, PUT } from "../constants/applicationConstants";
import generateUrl from "../models/common/utils/urlUtils";
import {
  ADDITIONAL_APPROVAL_SETTINGS,
  GET_RULE_PROPERTIES,
  GET_APPROVERS,
  GET_SURCHARGE_CODES,
  GET_MEDIAS
} from "../../configurations/apiUrls";
import {
  apiCall,
  enqueueApplicationErrorMessage,
  enqueueApiErrorMessage,
  apiCallDirect,
  apiCallAll,
  enqueueNotificationMessage
} from "./applicationActions";
import { CONFIGURATION_SAVE_SUCCESS } from "../constants/configurationPageConstants";

const getRequestBody = agencyName => {
  let agencyId = 0;
  if (agencyName === "groupm") {
    agencyId = 1;
  } else if (agencyName === "kinetic") {
    agencyId = 5;
  } else if (agencyName === "essencemediacom") {
    agencyId = 6;
  } else if (agencyName === "mindshare") {
    agencyId = 2;
  } else if (agencyName === "wavemaker") {
    agencyId = 7;
  } else if (agencyName === "blossom") {
    agencyId = 8;
  } else if (agencyName === "greenhouseone") {
    agencyId = 9;
  } else if (agencyName === "greenhousetech") {
    agencyId = 10;
  } else if (agencyName === "choreographcreate") {
    agencyId = 11;
  }

  return {
    settingsForAgency: agencyId
  };
};

export function saveAditionalApprovalSettings(
  agencyName,
  additionalApprovalSettings
) {
  return dispatch => {
    let requestBody = getRequestBody(agencyName);
    requestBody = { ...requestBody, ...additionalApprovalSettings };

    const paramObj = { agency: 0 };
    const url = generateUrl(ADDITIONAL_APPROVAL_SETTINGS, paramObj, {});

    return dispatch(apiCall(PUT, url, requestBody)).then(
      response => {
        const { isSuccessful } = response || false;

        if (isSuccessful) {
          dispatch(enqueueNotificationMessage(CONFIGURATION_SAVE_SUCCESS));
        }

        return isSuccessful;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export const getPageData = () => dispatch => {
  const urlGetApprovers = generateUrl(GET_APPROVERS, {
    agency: 0
  });
  const promiseGetApprovers = dispatch(apiCallDirect(GET, urlGetApprovers));

  const urlGetRuleProperties = generateUrl(GET_RULE_PROPERTIES, {
    agency: 0
  });
  const promiseGetRuleProperties = dispatch(
    apiCallDirect(GET, urlGetRuleProperties)
  );

  const urlGetTitles = generateUrl(GET_MEDIAS, {
    agency: 0
  });
  const promiseGetTitles = dispatch(apiCallDirect(GET, urlGetTitles));

  const urlGetSurchargeTypes = generateUrl(GET_SURCHARGE_CODES, {
    agency: 0
  });
  const promiseGetSurchargeTypes = dispatch(
    apiCallDirect(GET, urlGetSurchargeTypes)
  );

  return dispatch(
    apiCallAll([
      promiseGetApprovers,
      promiseGetRuleProperties,
      promiseGetTitles,
      promiseGetSurchargeTypes
    ])
  ).then(
    response => {
      const options = {
        approverOptions: response[0].items,
        rulePropertyOptions: response[1].ruleRuleProperties,
        titleOptions: response[2].titleItems,
        surchargeOptions: response[3].items
      };
      return options;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const loadAditionalApprovalSettings = agencyName => dispatch => {
  const paramObj = { agency: 0 };
  const queryObj = getRequestBody(agencyName);
  const url = generateUrl(ADDITIONAL_APPROVAL_SETTINGS, paramObj, queryObj);

  return dispatch(apiCall(GET, url)).then(
    response => {
      if (response) {
        return response;
      }
      return null;
    },
    error => {
      dispatch(enqueueApplicationErrorMessage(error));
    }
  );
};
