import TextFieldBase from "./TextField";
import DateField from "./DateField";
import DecimalField from "./DecimalField";
import AutoComplete from "./AutoComplete";
import Checkbox from "./Checkbox";
import UploadButton from "./UploadButton";
import SwitchToggle from "./SwitchToggle";
import { CardLink } from "./CardLink";

const Controls = {
  text: TextFieldBase,
  date: DateField,
  decimal: DecimalField,
  autocomplete: AutoComplete,
  checkbox: Checkbox,
  uploadButton: UploadButton,
  switch: SwitchToggle,
  cardLink: CardLink
};

export default Controls;
