import { styled } from "@mui/material";
import Select from "react-select";
import { getThemeSpacing } from "../../../functions/util";

const PREFIX = "autocompleteStyles";
export const classes = {
  input: `${PREFIX}-input`,
  valueContainer: `${PREFIX}-valueContainer`,
  paper: `${PREFIX}-paper`
};

export const MuiSelect = styled(Select)(({ theme }) => ({
  [`& .${classes.input}`]: {
    display: "flex"
  },
  [`& .${classes.valueContainer}`]: {
    display: "flex",
    flex: 1,
    flexWrap: "nowrap",
    alignItems: "center",
    wordBreak: "break-word"
  },
  [`&.${classes.paper}`]: {
    marginTop: getThemeSpacing(theme, 1)
  }
}));

export const autocompleteCustomStyles = {
  input: styles => {
    return {
      ...styles,
      margin: 0,
      padding: 0
    };
  },
  dropdownIndicator: styles => {
    return {
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 2
    };
  },
  formControl: styles => {
    return {
      ...styles,
      marginTop: 0
    };
  },
  clearIndicator: styles => {
    return {
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 2,
      paddingLeft: 0
    };
  },
  indicatorsContainer: styles => {
    return {
      ...styles,
      " svg": {
        width: 13,
        height: 13
      }
    };
  },
  indicatorSeparator: styles => {
    return {
      ...styles,
      margin: 0
    };
  },
  menuPortal: base => ({
    ...base,
    zIndex: 9999
  })
};
