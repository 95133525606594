import { EMPTY_STRING } from "../../configurations/appConstants";

export const SET_OVERVIEW_TAB_COUNTERS = "SET_OVERVIEW_TAB_COUNTERS";
export const RESET_OVERVIEW_PAGE = "RESET_OVERVIEW_PAGE";
export const SET_OVERVIEW_ITEMS = "SET_OVERVIEW_ITEMS";
export const SET_OVERVIEW_COUNT = "SET_OVERVIEW_COUNT";
export const SET_SELECTED_SAVE_SEARCH = "SET_SELECTED_SAVE_SEARCH";

export const SAVE_SEARCH_NAME_VALIDATION_ERROR_MESSAGE =
  "Please, enter a valid search name. Search name must not be empty or 'None' or the same as an existing search name. Search name can not be longer than 100 characters";

export const SAVE_SEARCH_DIALOG = "Save Search dialog";
export const SAVE_AS_SEARCH_DIALOG = "Save As Search dialog";

export const defaultOptionForSaveSearch = {
  label: "None",
  value: 0,
  path: EMPTY_STRING
};
