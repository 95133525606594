import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { Badge, IconButton, Hidden } from "@mui/material";

import {
  EditIcon,
  ViewIcon,
  InsertionIcon,
  InvoicedInsertionIcon,
  CopyIcon,
  DeleteOrderIcon
} from "../../../assets/styles/icons";
import {
  selectOrder,
  checkIsOrderValidAndShowError,
  copyOrder,
  setOrderToDelete,
  editOrder
} from "../../../business/models/Order/orderActions";
import { openOrderFormPage } from "../../../business/actions/campaignDashboardActions";
import {
  editInsertions,
  addNewInsertion
} from "../../../business/models/Insertion/insertionActions";
import { toggleOrderConfirmPopup } from "../../../business/actions/applicationActions";
import { selectInsertionIsEditing } from "../../../business/models/Insertion/insertionSelectors";
import {
  isOrderCancelledOrToBeCancelledByID,
  isOrderPendingForApprovalByID,
  selectCurrentOrder
} from "../../../business/models/Order/orderSelectors";
import { canEditCampaign } from "../../../business/selectors/userProfileSelectors";
import { getCampaignData } from "../../../business/models/Campaign/campaignSelectors";
import {
  CAMPAIGN,
  PLAN,
  ORDER,
  CREATE_PAGE,
  COLOR_JORDYBLUE
} from "../../../configurations/appConstants";
import { classes, Grid } from "../styles/orderCompact";

const OrderCompactActions = ({
  buttonRef,
  setOpen,
  open,
  id,
  numberOfInsertions,
  haveInvoicedInsertions,
  allowAddInsertion,
  isInCopyProcess
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agency, campaignId, planId, orderId: urlOrderId } = useParams();
  const currentOrder = useSelector(selectCurrentOrder);
  const disableAction = !useSelector(canEditCampaign);
  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const isInsertionEditing = useSelector(selectInsertionIsEditing);
  const isCancelledOrToBeCancelled = useSelector(state =>
    isOrderCancelledOrToBeCancelledByID(state, id)
  );
  const isPendingForApproval = useSelector(state =>
    isOrderPendingForApprovalByID(state, id)
  );

  const insertionBadge =
    numberOfInsertions && numberOfInsertions > 1 ? numberOfInsertions : 0;

  const disableAddInsertion =
    isCancelledOrToBeCancelled || isPendingForApproval || !isDebtorActive;
  const allowEdit = !isInsertionEditing;
  const allowCopy = !isInsertionEditing;
  const allowDelete = !isInsertionEditing;

  const changeUrl = () => {
    const url = `/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${campaignId}/${PLAN}/${planId}/${ORDER}/${id}`;
    if (urlOrderId !== id) navigate(url);
  };

  const handleToggle = e => {
    e.stopPropagation();
    buttonRef.current.focus();
    setOpen(!open);
  };

  const handleEdit = e => {
    e.stopPropagation();
    changeUrl();
    dispatch(selectOrder(id));
    dispatch(editOrder());
    dispatch(openOrderFormPage());
  };

  const handleAddInsertion = e => {
    e.stopPropagation();
    const isValid = dispatch(checkIsOrderValidAndShowError(id));

    if (!isValid) return;

    if (!isInsertionEditing || id !== currentOrder) {
      changeUrl();
      dispatch(selectOrder(id));
      dispatch(editInsertions());
    }

    dispatch(addNewInsertion());
  };

  const handleCopy = e => {
    e.stopPropagation();
    dispatch(copyOrder(id)).then(oId => {
      if (oId) {
        dispatch(openOrderFormPage());
      }
    });
  };

  const handleDelete = e => {
    e.stopPropagation();
    dispatch(setOrderToDelete(id));
    dispatch(toggleOrderConfirmPopup());
  };
  return (
    <Grid container alignItems="center">
      <Grid item>
        <IconButton
          name={`o_view_${id}`}
          id={`o_view_${id}`}
          onClick={handleToggle}
          ref={buttonRef}
          className={classes.iconButton}
        >
          <ViewIcon color={COLOR_JORDYBLUE} />
        </IconButton>
      </Grid>
      <Grid item>
        <Hidden xsUp={isInCopyProcess}>
          <IconButton
            name={`o_edit_${id}`}
            id={`o_edit_${id}`}
            disabled={!allowEdit || disableAction}
            onClick={handleEdit}
            className={classes.iconButton}
          >
            <EditIcon disabled={disableAction} />
          </IconButton>
        </Hidden>
      </Grid>
      <Grid item>
        <Hidden xsUp={isInCopyProcess}>
          <IconButton
            name={`o_insertion_${id}`}
            id={`o_insertion_${id}`}
            disabled={
              !allowAddInsertion || disableAddInsertion || disableAction
            }
            onClick={handleAddInsertion}
            className={classes.iconButton}
          >
            <Badge
              badgeContent={insertionBadge}
              classes={{ badge: classes.badge }}
            >
              {haveInvoicedInsertions ? (
                <InvoicedInsertionIcon
                  disabled={disableAction || disableAddInsertion}
                />
              ) : (
                <InsertionIcon
                  disabled={disableAction || disableAddInsertion}
                />
              )}
            </Badge>
          </IconButton>
        </Hidden>
      </Grid>
      {isDebtorActive && (
        <Grid item>
          <Hidden xsUp={isInCopyProcess}>
            <IconButton
              name={`o_copy_${id}`}
              id={`o_copy_${id}`}
              disabled={!allowCopy || disableAction}
              onClick={handleCopy}
              className={classes.iconButton}
            >
              <CopyIcon disabled={disableAction} />
            </IconButton>
          </Hidden>
        </Grid>
      )}
      {isDebtorActive && (
        <Grid item>
          <Hidden xsUp={isInCopyProcess}>
            <IconButton
              name={`o_delete_${id}`}
              id={`o_delete_${id}`}
              disabled={!allowDelete || disableAction}
              onClick={handleDelete}
              className={classes.iconButton}
            >
              <DeleteOrderIcon disabled={disableAction} />
            </IconButton>
          </Hidden>
        </Grid>
      )}
    </Grid>
  );
};

OrderCompactActions.propTypes = {
  buttonRef: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  id: PropTypes.number,

  numberOfInsertions: PropTypes.number,
  haveInvoicedInsertions: PropTypes.bool,
  allowAddInsertion: PropTypes.bool
};

export default OrderCompactActions;
