import React from "react";
import { Field, useFormikContext } from "formik";
import {
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Divider
} from "@mui/material";
import { useSelector } from "react-redux";

import AutoCompleteFormik from "../../../form/controls/AutoCompleteFormik";
import RuleTypeFields from "./RuleTypeFields";
import { RuleTypeSearchIcon } from "../../../../assets/styles/icons";
import { ruleTypeDict } from "../../../../configurations/appConstants";
import { ConfigOptionsContextConsumer } from "../configurationContext";
import { selectCanEditAgencyRule } from "../../../../business/selectors/applicationSelectors";
import { classes, Card } from "./styles/rulePanel";
import { FormikSwitch } from "../../../../assets/styles/switchToggle";

const RulePanel = ({ type }) => {
  const { values } = useFormikContext();
  const canEditAgencyRules = useSelector(selectCanEditAgencyRule);

  return (
    <ConfigOptionsContextConsumer>
      {context => (
        <Card elevation={values[`${type}Rule`] ? 7 : 3}>
          <CardHeader
            avatar={
              <RuleTypeSearchIcon
                className={
                  values[`${type}Rule`]
                    ? classes.activeTypography
                    : classes.typography
                }
                type={type}
              />
            }
            title={
              <Typography
                className={
                  values[`${type}Rule`]
                    ? classes.activeTypography
                    : classes.typography
                }
                variant="h6"
              >
                {ruleTypeDict[type]}
              </Typography>
            }
          />
          <Divider className={classes.divider} light />
          <CardContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Grid item />
              <Grid item xs={8}>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <RuleTypeFields
                    type={type}
                    isForeignCurrencyRuleActive={
                      values.foreignCurrencyRule && canEditAgencyRules
                    }
                    isNegativeAmountRuleActive={
                      values.negativeAmountRule && canEditAgencyRules
                    }
                    isHighRiskTitleRuleActive={values.highRiskTitleRule}
                    isHighRiskSurchargeRuleActive={values.highRiskSurchargeRule}
                    isExceedesThresholdRuleActive={
                      values.exceedsThresholdRule && canEditAgencyRules
                    }
                    canEditAgencyRules={canEditAgencyRules}
                  />
                </Grid>
              </Grid>
              <Grid item xs={2} className={classes.autoComplete}>
                <Field
                  disabled={!values[`${type}Rule`] || !canEditAgencyRules}
                  component={AutoCompleteFormik}
                  id={`${type}Approvers`}
                  name={`${type}Approvers`}
                  textFieldProps={{
                    label: "Approvers"
                  }}
                  multiple
                  options={context.approverOptions}
                  canEditAgencyRules={canEditAgencyRules}
                />
              </Grid>
            </Grid>
            <Field
              id={`${type}Rule`}
              name={`${type}Rule`}
              color="primary"
              type="checkbox"
              component={FormikSwitch}
              disabled={!canEditAgencyRules}
            ></Field>
          </CardContent>
        </Card>
      )}
    </ConfigOptionsContextConsumer>
  );
};

export default RulePanel;
