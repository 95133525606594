import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { StringParam, useQueryParams } from "use-query-params";
import ManageTrafficTemplateDialog from "./dialogs/ManageTrafficTemplateDialog";
import TrafficTemplateTable from "./table/TrafficTemplateTable";

import { selectIsTrafficTemplateDialogOpen } from "../../../business/selectors/trafficTemplateManagerSelector";
import {
  closeManageTrafficTemplateDialog,
  openManageTrafficTemplateDialog
} from "../../../business/actions/trafficTemplateManagerActions";
import { loadTrafficTemplates } from "../../../business/models/common/data/dataActions";
import {
  getTrafficTemplateData,
  selectIsNewTrafficTemplate
} from "../../../business/models/TrafficTemplate/trafficTemplateSelectors";
import {
  createTrafficTemplate,
  deleteTrafficTemplate,
  startEditingTrafficTemplate,
  selectTrafficTemplate,
  updateTrafficTemplate
} from "../../../business/models/TrafficTemplate/trafficTemplateActions";

const filterQuerys = {
  templateName: StringParam,
  remarks: StringParam
};

const TrafficTemplateManagerPage = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useQueryParams({
    ...filterQuerys
  });

  const { templateName, remarks } = query;

  const handleDynamicFilter = useCallback(
    filterQuery => {
      setQuery({ ...filterQuery }, "pushIn");
    },
    [setQuery]
  );

  const isTemplateDialogOpen = useSelector(selectIsTrafficTemplateDialogOpen);
  const templates = useSelector(getTrafficTemplateData);
  const isNewTemplate = useSelector(selectIsNewTrafficTemplate);

  const handleApplyTemplateDialog = () => {
    const result = isNewTemplate
      ? dispatch(createTrafficTemplate(query))
      : dispatch(updateTrafficTemplate());
    result.then(
      isSuccessful =>
        isSuccessful && dispatch(closeManageTrafficTemplateDialog())
    );
  };

  const handleCancelTemplateDialog = () => {
    dispatch(closeManageTrafficTemplateDialog());
  };

  const handleEditTrafficTemplate = templateId => {
    dispatch(openManageTrafficTemplateDialog());
    dispatch(selectTrafficTemplate(templateId));
    dispatch(startEditingTrafficTemplate(templateId));
  };

  const handleDeleteTrafficTemplate = id => {
    dispatch(deleteTrafficTemplate(id));
  };

  useEffect(() => {
    dispatch(loadTrafficTemplates({ templateName, remarks }));
  }, [dispatch, templateName, remarks]);

  return (
    <>
      <TrafficTemplateTable
        data={templates}
        onEdit={handleEditTrafficTemplate}
        onDelete={handleDeleteTrafficTemplate}
        onDynamicFilter={handleDynamicFilter}
        queryFilters={{
          templateName,
          remarks
        }}
      />
      <ManageTrafficTemplateDialog
        open={isTemplateDialogOpen}
        onApply={handleApplyTemplateDialog}
        onCancel={handleCancelTemplateDialog}
        isNewTemplate={isNewTemplate}
      />
    </>
  );
};

export default TrafficTemplateManagerPage;
