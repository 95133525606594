import {
  SET_ACTIVE_STEP,
  RESET_ACTIVE_STEP,
  SET_STEPS,
  RESET_STEPS,
  SET_PROCESS,
  getSteps,
  CLEAR_STEPPER,
  SET_CAMPAIGN_STATUS,
  RESET_CAMPAIGN_STATUS,
  SET_CURRENT_ORDER_STEP,
  SET_ORDER_STEPS,
  RESET_ORDER_STEPS,
  RESET_CURRENT_ORDER_STEP
} from "../constants/copyPlanConstants";

const initialState = {
  processId: null,
  campaignStatus: "new",
  steps: getSteps(),
  activeStep: 1,
  orderSteps: null, // Total number of copy Order steps
  currentOrderStep: 1
};

const campaignDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_STEPPER:
      return { ...initialState };
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload
      };
    case RESET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: 1
      };
    case SET_STEPS:
      return {
        ...state,
        steps: action.payload
      };
    case RESET_STEPS:
      return {
        ...state,
        steps: getSteps()
      };
    case SET_PROCESS:
      return {
        ...state,
        processId: action.payload
      };
    case SET_CAMPAIGN_STATUS:
      return {
        ...state,
        campaignStatus: action.payload
      };
    case RESET_CAMPAIGN_STATUS:
      return {
        ...state,
        campaignStatus: "new"
      };
    case SET_CURRENT_ORDER_STEP:
      return {
        ...state,
        currentOrderStep: action.payload
      };
    case RESET_CURRENT_ORDER_STEP:
      return {
        ...state,
        currentOrderStep: 1
      };
    case SET_ORDER_STEPS:
      return {
        ...state,
        orderSteps: action.payload
      };
    case RESET_ORDER_STEPS:
      return {
        ...state,
        orderSteps: null
      };
    default:
      return state;
  }
};

export default campaignDashboardReducer;
