import React from "react";
import PropTypes from "prop-types";

import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid
} from "@mui/material";

import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import Scrollbar from "../../../../../../shared/Scrollbar";

import {
  classes,
  DialogContent
} from "../../../../styles/trafficExportPreviewTable";
import {
  PLACEHOLDER,
  EMPTY_TABLE
} from "../../../../../../../configurations/appConstants";

const PreviewCinema = ({ data }) => {
  const {
    paperRoot,
    tableCellUnderline,
    scrollTableCell,
    tableCellHeader,
    tableCellHeaderInner
  } = classes;

  const {
    debtorName,
    plannerAgency,
    productName,
    creationDate,
    remarks,
    creativeAgency,
    contact,
    email,
    cc,
    contactGroupm,
    phoneNumberGroupm,
    version,
    ordersCinema,
    planName
  } = data;

  return (
    <DialogContent>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper className={paperRoot} elevation={0}>
            <Table>
              <TableBody key={"campaignId"}>
                <TableRow>
                  <TableCell className={tableCellHeader}>
                    <Typography align="left">DEBTOR NAME</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{debtorName}</Typography>
                  </TableCell>
                  <TableCell
                    className={clsx(tableCellHeader, tableCellHeaderInner)}
                  >
                    <Typography align="left">VERSION</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{version}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={tableCellHeader}>
                    <Typography align="left">PRODUCT NAME</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{productName}</Typography>
                  </TableCell>
                  <TableCell
                    className={clsx(tableCellHeader, tableCellHeaderInner)}
                  >
                    <Typography align="left">DATE</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{creationDate}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={tableCellHeader}>
                    <Typography align="left">PLAN NAME</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{planName}</Typography>
                  </TableCell>
                  <TableCell
                    className={clsx(tableCellHeader, tableCellHeaderInner)}
                  >
                    <Typography align="left">REPLACES</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{PLACEHOLDER}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2} className={tableCellHeader}>
                    <Typography align="left">REMARKS</Typography>
                  </TableCell>
                  <TableCell rowSpan={2} colSpan={4}>
                    <Typography align="left">{remarks}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow />
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={paperRoot} elevation={0}>
            <Table>
              <TableBody key={"campaignId"}>
                <TableRow>
                  <TableCell className={tableCellHeader}>
                    <Typography align="left">CREATIVE AGENCY</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{creativeAgency}</Typography>
                  </TableCell>
                  <TableCell
                    className={clsx(tableCellHeader, tableCellHeaderInner)}
                  >
                    <Typography align="left">CONTACT GROUPM</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{contactGroupm}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={tableCellHeader}>
                    <Typography align="left">CONTACT</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{contact}</Typography>
                  </TableCell>
                  <TableCell
                    className={clsx(tableCellHeader, tableCellHeaderInner)}
                  >
                    <Typography align="left">EMAIL ADDRESS</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">
                      AMS.KIN-team-OOH@groupm.com
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={tableCellHeader}>
                    <Typography align="left">E-MAIL</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{email}</Typography>
                  </TableCell>
                  <TableCell
                    className={clsx(tableCellHeader, tableCellHeaderInner)}
                  >
                    <Typography align="left">PHONE NUMBER</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{phoneNumberGroupm}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={tableCellHeader}>
                    <Typography align="left">CC</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{cc}</Typography>
                  </TableCell>
                  <TableCell
                    className={clsx(tableCellHeader, tableCellHeaderInner)}
                  >
                    <Typography align="left">PLANNER AGENCY</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left">{plannerAgency}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={paperRoot} elevation={0}>
            <Scrollbar autoHeightMax={600} autoHeightMin={95} autoHide={false}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        ORDER NUMBER
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        PLAN NUMBER
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        VENDOR
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        FORMAT
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        NETWORK / LOCATIONS
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        DESCRIPTION
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        DIGITAL FILE (YES / NO)
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        SPOTLENGTH
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        # OF UNITS
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        START DATE
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        END DATE
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        DELIVERY DATE
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        TECHNICAL SPECIFICATIONS
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        DELIVERY ADDRESS
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        VENDOR REFERENCE
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(scrollTableCell, tableCellUnderline)}
                    >
                      <Typography align="left" noWrap>
                        REMARKS
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {!isEmpty(ordersCinema) ? (
                    ordersCinema.map((order, index) => {
                      const {
                        marathonOrderId,
                        marathonPlanId,
                        title,
                        deliveryDate,
                        remarks: orderRemarks,
                        deliveryAddress,
                        description,
                        digitalFile,
                        format,
                        networkLocations,
                        numOfUnits,
                        orderEndDate,
                        orderStartDate,
                        spotLength,
                        technicalSpecifications,
                        vendorReference
                      } = order;
                      return (
                        <TableRow
                          key={`${marathonOrderId + marathonPlanId} - ${index}`}
                        >
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {marathonOrderId}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {marathonPlanId}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {title}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {format}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {networkLocations}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {description}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {digitalFile}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {spotLength}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {numOfUnits}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {orderStartDate}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {orderEndDate}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {deliveryDate}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {technicalSpecifications}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {deliveryAddress}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {vendorReference}
                            </Typography>
                          </TableCell>
                          <TableCell className={scrollTableCell}>
                            <Typography align="left" noWrap>
                              {orderRemarks}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={17} align="center">
                        <Typography align="center" noWrap>
                          {EMPTY_TABLE}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </Paper>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

PreviewCinema.propTypes = {
  data: PropTypes.object
};

export default PreviewCinema;
