import React from "react";

import { connect } from "react-redux";
import clsx from "clsx";

import { Chip, Typography, Tooltip } from "@mui/material";

import ClipboardTooltip from "../../../../../shared/ClipboardTooltip";
import { parseDate } from "../../../../../../business/models/common/utils/clientUtils";
import { PLACEHOLDER } from "../../../../../../configurations/appConstants";
import { getDimensionsText } from "../../../../../../business/models/TrafficItem/trafficItemSelectors";
import TrafficDigitalTemplateSelect from "../../../../../form/TrafficDigitalTemplateSelect";
import { classes, Grid } from "../../../../styles/compact";

const CompactDigital = ({
  item,
  onClipboardCopy,
  dimensions,
  showTemplateSelect,
  templateSelectDisabled
}) => {
  const {
    trafficItemDescription,
    maximumSeconds,
    trafficItemStartDate,
    trafficItemEndDate,
    title,
    id
  } = item || {};
  return (
    <Grid container spacing={2}>
      {showTemplateSelect && (
        <Grid item>
          <TrafficDigitalTemplateSelect disabled={templateSelectDisabled} />
        </Grid>
      )}
      <Grid item>
        <Tooltip
          title={
            <ClipboardTooltip onButtonClick={onClipboardCopy} text={title} />
          }
          placement="bottom-start"
        >
          <Chip
            classes={{ label: classes.chipHeaderLarge }}
            label={
              <Typography
                id={`title_${id}`}
                name={`title_${id}`}
                className={clsx(classes.typography, classes.inheritWidth)}
                align="center"
                noWrap
              >
                {title || PLACEHOLDER}
              </Typography>
            }
            className={classes.chipTraffic}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <ClipboardTooltip
              onButtonClick={onClipboardCopy}
              text={trafficItemDescription}
            />
          }
          placement="bottom-start"
        >
          <Chip
            classes={{ label: classes.chipHeader }}
            label={
              <Typography
                id={`trafficItemDescription_${id}`}
                name={`trafficItemDescription_${id}`}
                className={clsx(classes.typography, classes.inheritWidth)}
                align="center"
                noWrap
              >
                {trafficItemDescription || PLACEHOLDER}
              </Typography>
            }
            className={classes.chipTraffic}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Chip
          classes={{ label: classes.chipHeader }}
          label={
            <Typography
              id={`trafficItemStartDate_${id}`}
              name={`trafficItemStartDate_${id}`}
              className={clsx(classes.typography, classes.inheritWidth)}
              align="center"
              noWrap
            >
              {parseDate(trafficItemStartDate)}
            </Typography>
          }
          className={classes.chipTraffic}
        />
      </Grid>
      <Grid item>
        <Chip
          classes={{ label: classes.chipHeader }}
          label={
            <Typography
              id={`trafficItemEndDate_${id}`}
              name={`trafficItemEndDate_${id}`}
              className={clsx(classes.typography, classes.inheritWidth)}
              align="center"
              noWrap
            >
              {parseDate(trafficItemEndDate)}
            </Typography>
          }
          className={classes.chipTraffic}
        />
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <ClipboardTooltip
              onButtonClick={onClipboardCopy}
              text={maximumSeconds}
            />
          }
          placement="bottom-start"
        >
          <Chip
            classes={{ label: classes.chipHeader }}
            label={
              <Typography
                id={`maximumSeconds_${id}`}
                name={`maximumSeconds_${id}`}
                className={clsx(classes.typography, classes.inheritWidth)}
                align="center"
                noWrap
              >
                {maximumSeconds || PLACEHOLDER}
              </Typography>
            }
            className={classes.chipTraffic}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <ClipboardTooltip
              onButtonClick={onClipboardCopy}
              text={dimensions}
            />
          }
          placement="bottom-start"
        >
          <Chip
            classes={{ label: classes.chipHeader }}
            label={
              <Typography
                id={`dimensions_${id}`}
                name={`dimensions_${id}`}
                className={clsx(classes.typography, classes.inheritWidth)}
                align="center"
                noWrap
              >
                {dimensions || PLACEHOLDER}
              </Typography>
            }
            className={classes.chipTraffic}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, { item }) => {
  return {
    dimensions: getDimensionsText(state, item)
  };
};

export default connect(mapStateToProps, null)(CompactDigital);
