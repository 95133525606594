import React from "react";

import { ThemeProvider } from "@mui/material/styles";

import { ConfigOptionsContextProvider } from "./configurationContext";
import AAConfigForm from "./form/AAConfigForm";
import theme from "../../../assets/styles/pages/configuration/theme";

const AditionalApprovalConfigurationPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <ConfigOptionsContextProvider>
        <div>
          <AAConfigForm />
        </div>
      </ConfigOptionsContextProvider>
    </ThemeProvider>
  );
};

export default AditionalApprovalConfigurationPage;
