import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

import { EMPTY_STRING } from "../../../configurations/appConstants";
import { solitude } from "../../../assets/styles/colors";

const TextFieldBase = ({
  id,
  name,
  type,
  variant,
  label,
  disabled,
  value,
  displayErrors,
  errors,
  maxLength,
  className,
  multiline,
  margin,
  rowsMax,
  helperText,
  InputProps,
  inputProps,
  formHelperTextProps,
  inputLabelProps,
  placeholder,
  onChange
}) => {
  const trimStart = v =>
    typeof v === "string" || v instanceof String ? v.trimLeft() : v;

  const trimEnd = v =>
    typeof v === "string" || v instanceof String ? v.trimRight() : v;

  const handleChange = (...args) => {
    const { value: val } = args[0].target;
    if (typeof onChange === "function") {
      const retVal = trimStart(val);
      onChange(retVal);
    }
  };

  const handleBlur = e => {
    const { value: val } = e.target;
    if (typeof onChange === "function") {
      const retVal = trimEnd(val);
      onChange(retVal);
    }
  };

  const nativeInputProps = { ...inputProps, autoComplete: "off", maxLength };
  const isValid = errors.length === 0;
  const errorText = errors[0];

  return (
    <TextField
      placeholder={placeholder}
      multiline={multiline}
      onBlur={handleBlur}
      margin={margin}
      id={id}
      name={name}
      type={type}
      variant={variant}
      label={label}
      disabled={disabled}
      value={value || EMPTY_STRING}
      inputProps={nativeInputProps}
      error={displayErrors && !isValid}
      helperText={(displayErrors && errorText) || helperText}
      fullWidth
      onChange={handleChange}
      className={className}
      maxRows={rowsMax}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...InputProps,
        sx: { background: disabled && solitude }
      }}
      FormHelperTextProps={formHelperTextProps}
      InputLabelProps={inputLabelProps}
    />
  );
};

TextFieldBase.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "number"]),
  variant: PropTypes.oneOf(["standard", "outlined"]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayErrors: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  inputProps: PropTypes.object
};

TextFieldBase.defaultProps = {
  type: "text",
  value: "",
  variant: "outlined",
  errors: [],
  disabled: false,
  displayErrors: false,
  margin: "normal",
  helperText: null
};

export default TextFieldBase;
