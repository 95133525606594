import { styled } from "@mui/material";

const PREFIX = "UserManagmentPageStyles";
export const classes = {
  root: `${PREFIX}-root`
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%"
  }
}));
