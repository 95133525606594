import React, { memo } from "react";

import Cinema from "./byMediaType/Traffic/Item/CompactCinema";
import Digital from "./byMediaType/Traffic/Item/CompactDigital";
import Ooh from "./byMediaType/Traffic/Item/CompactOoh";
import Print from "./byMediaType/Traffic/Item/CompactPrint";
import Radio from "./byMediaType/Traffic/Item/CompactRadio";
import Tv from "./byMediaType/Traffic/Item/CompactTv";
import {
  MEDIATYPE_DIGITAL,
  MEDIATYPE_PRINT,
  MEDIATYPE_OOH,
  MEDIATYPE_TV,
  MEDIATYPE_RADIO,
  MEDIATYPE_CINEMA,
  MEDIATYPE_INVENTORY
} from "../../../configurations/appConstants";

const TrafficLineCompact = ({
  item,
  onClipboardCopy,
  showTemplateSelect,
  templateSelectDisabled
}) => {
  switch (item.mediaTypeId) {
    case MEDIATYPE_PRINT:
      return <Print item={item} onClipboardCopy={onClipboardCopy} />;
    case MEDIATYPE_DIGITAL:
    case MEDIATYPE_INVENTORY:
      return (
        <Digital
          item={item}
          onClipboardCopy={onClipboardCopy}
          showTemplateSelect={showTemplateSelect}
          templateSelectDisabled={templateSelectDisabled}
        />
      );
    case MEDIATYPE_OOH:
      return <Ooh item={item} onClipboardCopy={onClipboardCopy} />;
    case MEDIATYPE_RADIO:
      return <Radio item={item} onClipboardCopy={onClipboardCopy} />;
    case MEDIATYPE_TV:
      return <Tv item={item} onClipboardCopy={onClipboardCopy} />;
    case MEDIATYPE_CINEMA:
      return <Cinema item={item} onClipboardCopy={onClipboardCopy} />;
    default:
      return null;
  }
};

export default memo(TrafficLineCompact);
