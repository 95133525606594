import { createSelector } from "reselect";

const application = state => state.application;

// TODO: Related to copy plan => Need to move this to copy plan actions
export const selectIsUninterruptibleProcess = createSelector(
  application,
  app => app.uninterruptibleProcess
);

export const selectIsLoading = createSelector(
  application,
  app => app.isLoading
);

export const selectErrors = createSelector(application, app => app.errors);

export const selectNotifications = createSelector(
  application,
  app => app.notifications
);

export const selectOpenInsertionPopup = createSelector(
  application,
  app => app.openInsertionPopup
);

export const selectOpenCampaignConfirmPopup = createSelector(
  application,
  app => app.openCampaignConfirmPopup
);

export const selectOpenPlanConfirmPopup = createSelector(
  application,
  app => app.openPlanConfirmPopup
);

export const selectOpenOrderConfirmPopup = createSelector(
  application,
  app => app.openOrderConfirmPopup
);

export const selectOpenMbsConfirmPopup = createSelector(
  application,
  app => app.openMbsConfirmPopup
);

export const selectOpenAgreementConfirmPopup = createSelector(
  application,
  app => app.openAgreementConfirmPopup
);

export const selectAction = createSelector(application, app => app.action);

export const selectAgency = createSelector(application, app => app.agency);

export const selectOpenOrderStatusChangeDialog = createSelector(
  application,
  app => app.openOrderStatusChangeDialog
);

export const selectOpenOrderStatusChangeDialogTitle = createSelector(
  application,
  app => app.orderStatusChangeDialogTitle
);

export const selectOpenTrafficItemDeleteDialogTitle = createSelector(
  application,
  app => app.openTrafficItemDeleteDialog
);

export const selectOpenTrafficItemStatusChangeDialog = createSelector(
  application,
  app => app.openTrafficItemStatusChangeDialog
);

export const selectOpenDivideOrdersDialog = createSelector(
  application,
  app => app.openDivideOrdersDialog
);

export const selectOrdersActionSwitch = createSelector(
  application,
  app => app.ordersActionSwitch
);

export const selectCheckAllOrders = createSelector(
  application,
  app => app.checkAllOrders
);

export const selectOpenAddTrafficDialog = createSelector(
  application,
  app => app.openAddTrafficDialog
);

export const hasEditTraffickerDialogOpened = createSelector(
  application,
  app => app.hasEditTraffickerDialogOpened
);

export const selectOpenAddAllTrafficItemsDialog = createSelector(
  application,
  app => app.openAddAllTrafficItemsDialog
);

export const selectOpenSystemMessageDialog = createSelector(
  application,
  app => app.openSystemMessageDialog
);

export const selectOpenTrafficItemMultipleEditDialog = createSelector(
  application,
  app => app.openTrafficItemMultipleEditDialog
);

export const selectTrafficItemActionSwitch = createSelector(
  application,
  app => app.trafficItemActionSwitch
);

export const selectOpenSysNotificationDialog = createSelector(
  application,
  app => app.openSysNotificationDialog
);

export const selectOpenDrawer = createSelector(
  application,
  app => app.openDrawer
);

export const selectOpenTrafficExportVersionDialog = createSelector(
  application,
  app => app.openTrafficExportVersionDialog
);

export const selectOpenTrafficExportDigitalVersionDialog = createSelector(
  application,
  app => app.openTrafficExportDigitalVersionDialog
);

export const selectIsTrafficDashboardLoading = createSelector(
  application,
  app => app.isTrafficDashboardLoading
);

export const selectRefetch = createSelector(application, app => app.refetch);

export const selectOpenMediaTypeConfirmPopup = createSelector(
  application,
  app => app.openMediaTypeConfirmPopup
);

export const selectCanEditAgencyRule = createSelector(
  application,
  app => app.canEditAgencyRules
);
