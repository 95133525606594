import forEach from "lodash/forEach";
// import assign from "lodash/assign";
import keys from "lodash/keys";
import get from "lodash/get";
import set from "lodash/set";
import omit from "lodash/omit";

import cuid from "cuid";
import TrafficItemModel from "./TrafficItem";

import {
  EDIT_TRAFFIC_ITEM,
  CREATE_TRAFFIC_ITEM,
  CREATE_ALL_TRAFFIC_ITEMS,
  COPY_TRAFFIC_ITEM,
  EDIT_TRAFFIC_ITEM_STATUS,
  DELETE_TRAFFIC_ITEM
} from "../../../configurations/apiUrls";

import { TRAFFIC_PLAN_MODEL_ID } from "../TrafficPlan/trafficPlanConstants";

import {
  TRAFFIC_ITEM_MODEL_ID,
  TRAFFIC_ITEM_MODEL_NAME,
  TRAFFIC_ITEM_EDIT_STOP,
  TRAFFIC_ITEM_COPY_STOP,
  TRAFFIC_ITEM_DISPLAY_ERRORS,
  TRAFFIC_ITEM_HIDE_ERRORS,
  TRAFFIC_ITEM_CLEAR_CURRENT,
  TRAFFIC_ITEM_SELECT,
  TRAFFIC_ITEM_EDIT_START,
  TRAFFIC_ITEM_COPY_START,
  TRAFFIC_ITEM_SUCCESS_MESSAGE,
  TRAFFIC_ITEM_SET_IS_NEW,
  TRAFFIC_ITEM_STATUS_ID,
  TRAFFIC_ITEM_STATUS_SUCCESS_MESSAGE,
  TRAFFIC_ITEMS_ALL_SUCCESS_MESSAGE,
  TRAFFIC_ITEMS_ALREADY_CREATED_MESSAGE,
  TRAFFIC_ITEMS_INVALID_TRANSITIONS,
  TRAFFIC_ITEM_SET_DIGITAL_TEMPLATE,
  TRAFFIC_ITEM_RESET_DIGITAL_TEMPLATE
} from "./trafficItemConstants";
import { ORDER_MODEL_ID } from "../Order/orderConstants";

import {
  enqueueApiErrorMessage,
  enqueueNotificationMessage,
  enqueueApplicationErrorMessage,
  apiCall
} from "../../actions/applicationActions";
import fetchApiDataIfNeeded from "../../actions/apiDataActions";
import {
  stopEditingItem,
  editExistingItem,
  editItemAttributes,
  commitData,
  editNewItem
} from "../common/editing/editingActions";

import { getApiData } from "../../selectors/apiDataSelectors";
import { selectAgency } from "../../selectors/applicationSelectors";
import {
  selectIsEditingTrafficItem,
  selectCurrentTrafficItem,
  getEditingTrafficItem,
  doesModelHaveErrors,
  getTrafficItemById,
  selectIsCopyingTrafficItem
} from "./trafficItemSelectors";

import {
  getQueryObj,
  generateParamObj,
  getMatchingProperties,
  getPathValue
} from "../common/utils/modelUtils";
import generateUrl from "../common/utils/urlUtils";
import { TRAFFIC_PAGE } from "../../../configurations/appConstants";
import { deleteEntity } from "../common/entities/entityActions";
import { PUT, POST, DELETE } from "../../constants/applicationConstants";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import {
  selectSelectedTrafficPlanMediaType,
  selectCurrentTrafficPlanId,
  selectOrderForTraffic
} from "../TrafficPlan/trafficPlanSelectors";
import { selectCurrentPlan } from "../Plan/planSelectors";
import { selectAgencyName } from "../common/utils/appUtils";
import { isNullOrUndefined } from "../../../functions/util";

// #region Helpers
const changeUrl = (trafficItemId, navigate) => (_, getState) => {
  const state = getState();
  const agencyId = selectAgency(state);
  const agency = selectAgencyName(agencyId);
  const currentPlan = selectCurrentPlan(state);
  const currentTrafficPlan = selectCurrentTrafficPlanId(state);
  navigate(
    `/${agency}/${TRAFFIC_PAGE}/${currentPlan}/${currentTrafficPlan}/${trafficItemId}`
  );
};
// #endregion Helpers

function setTrafficItemMediaData() {
  return (dispatch, getState) => {
    const state = getState();
    const configuration = TrafficItemModel.apiConfiguration;
    const apiData = getApiData(state);
    const editingData = getEditingTrafficItem(state);
    const currentOrder = selectCurrentTrafficItem(state);
    const agencyID = selectAgency(state);
    forEach(configuration, (prop, key) => {
      if (prop.path) {
        const prevValue = get(editingData, key);
        const value = getPathValue(prop, editingData, apiData, agencyID);
        if (!isNullOrUndefined(value) && value !== "" && prevValue !== value) {
          dispatch(
            editItemAttributes(TRAFFIC_ITEM_MODEL_NAME, currentOrder, {
              [key]: value
            })
          );
        }
      }
    });
  };
}

export function loadTrafficItemMediaData(data) {
  return (dispatch, getState) => {
    const configuration = TrafficItemModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url));
      }
    });
  };
}

export function loadEditingTrafficItemMediaData(data) {
  return (dispatch, getState) => {
    const configuration = TrafficItemModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url, setTrafficItemMediaData));
      }
    });
  };
}

export function startEditingTrafficItem() {
  return (dispatch, getState) => {
    const currentTrafficItem = selectCurrentTrafficItem(getState());

    dispatch(editExistingItem(TRAFFIC_ITEM_MODEL_NAME, currentTrafficItem));
    dispatch({ type: TRAFFIC_ITEM_EDIT_START });

    // Load media - improve - dropdowns
    const editingData = getEditingTrafficItem(getState());
    dispatch(loadEditingTrafficItemMediaData(editingData));
  };
}

export function stopEditingTrafficItem() {
  return (dispatch, getState) => {
    const currentTrafficItem = selectCurrentTrafficItem(getState());

    dispatch(stopEditingItem(TRAFFIC_ITEM_MODEL_NAME, currentTrafficItem));
    dispatch({ type: TRAFFIC_ITEM_EDIT_STOP });
    dispatch({ type: TRAFFIC_ITEM_HIDE_ERRORS });
  };
}

export const clearCurrentTrafficItem = () => dispatch =>
  dispatch({ type: TRAFFIC_ITEM_CLEAR_CURRENT });

export function cancelTrafficItem() {
  return dispatch => {
    dispatch(stopEditingTrafficItem());
    dispatch({ type: TRAFFIC_ITEM_HIDE_ERRORS });
  };
}

export function cancelMultipleTrafficItems() {
  return dispatch => {
    dispatch(stopEditingTrafficItem());
    dispatch(clearCurrentTrafficItem());
    dispatch({ type: TRAFFIC_ITEM_HIDE_ERRORS });
  };
}

export function selectTrafficItem(trafficItemId) {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectIsEditingTrafficItem(state);
    const isCopying = selectIsCopyingTrafficItem(state);

    if (isEditing && !isCopying) dispatch(stopEditingTrafficItem());

    if (isCopying) {
      dispatch({ type: TRAFFIC_ITEM_COPY_STOP });
    }

    dispatch({
      type: TRAFFIC_ITEM_SELECT,
      payload: { trafficItemId }
    });
  };
}

export function editTrafficItem() {
  return dispatch => {
    dispatch(startEditingTrafficItem());
  };
}

export const startEditingTrafficItemMultiple = () => (dispatch, getState) => {
  const state = getState();
  const session = getUnsharedEntitiesSession(state);
  const mediaTypeId = selectSelectedTrafficPlanMediaType(state);

  const { TrafficItem } = session;

  const id = cuid();

  const newTrafficItem = TrafficItem.generate({
    trafficItemId: id,
    id,
    mediaTypeId
  });

  const trafficItemContents = newTrafficItem.toJSON();

  dispatch(editNewItem(TRAFFIC_ITEM_MODEL_NAME, id, trafficItemContents));
  dispatch(selectTrafficItem(id));
  dispatch({ type: TRAFFIC_ITEM_EDIT_START });
};

export function editTrafficItemMultiple() {
  return dispatch => {
    dispatch(startEditingTrafficItemMultiple());
  };
}

export function resetTrafficItemData(currentTrafficItem, data) {
  return dispatch => {
    const configuration = TrafficItemModel.apiConfiguration;
    const defaults = TrafficItemModel.defaultAttributes;
    const name = keys(data)[0];
    const properties = getMatchingProperties(configuration, name);
    forEach(properties, propertyName => {
      dispatch(
        editItemAttributes(TRAFFIC_ITEM_MODEL_NAME, currentTrafficItem, {
          [propertyName]: defaults[propertyName]
        })
      );
    });
  };
}

export function setTrafficItemDefaultValueIfNeeded(currentTrafficItem, data) {
  return dispatch => {
    const defaults = TrafficItemModel.defaultAttributes;
    const name = keys(data)[0];

    if (isNullOrUndefined(data[name]) && !isNullOrUndefined(defaults[name])) {
      dispatch(
        editItemAttributes(TRAFFIC_ITEM_MODEL_NAME, currentTrafficItem, {
          [name]: defaults[name]
        })
      );
    }
  };
}

export function setTrafficItemValue(data) {
  return (dispatch, getState) => {
    const state = getState();
    const currentTrafficItem = selectCurrentTrafficItem(state);

    dispatch(
      editItemAttributes(TRAFFIC_ITEM_MODEL_NAME, currentTrafficItem, data)
    );
    dispatch(setTrafficItemDefaultValueIfNeeded(currentTrafficItem, data));
    dispatch(resetTrafficItemData(currentTrafficItem, data));
    dispatch(setTrafficItemMediaData());
  };
}

export function updateTrafficItem() {
  return (dispatch, getState) => {
    const state = getState();

    const hasErrors = doesModelHaveErrors(state);
    if (hasErrors) {
      dispatch({ type: TRAFFIC_ITEM_DISPLAY_ERRORS });
      return Promise.reject();
    }

    const agencyID = selectAgency(state);
    const data = getEditingTrafficItem(state);

    const url = generateUrl(EDIT_TRAFFIC_ITEM, {
      agency: agencyID,
      [ORDER_MODEL_ID]: data.orderId,
      [TRAFFIC_ITEM_MODEL_ID]: data[TRAFFIC_ITEM_MODEL_ID]
    });

    return dispatch(apiCall(PUT, url, data)).then(
      response => {
        const responseData = set(
          response,
          "id",
          response[TRAFFIC_ITEM_MODEL_ID]
        );

        dispatch(
          commitData(
            TRAFFIC_ITEM_MODEL_NAME,
            responseData[TRAFFIC_ITEM_MODEL_ID],
            { ...data, ...responseData }
          )
        );
        dispatch(stopEditingTrafficItem());
        dispatch(selectTrafficItem(responseData[TRAFFIC_ITEM_MODEL_ID]));
        dispatch(enqueueNotificationMessage(TRAFFIC_ITEM_SUCCESS_MESSAGE));

        return Promise.resolve();
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function saveTrafficItem() {
  return dispatch => {
    return dispatch(updateTrafficItem());
  };
}

export const createTrafficItem = orderId => (dispatch, getState) => {
  const state = getState();

  const agencyID = selectAgency(state);
  const url = generateUrl(CREATE_TRAFFIC_ITEM, {
    agency: agencyID,
    [ORDER_MODEL_ID]: orderId
  });

  return dispatch(apiCall(POST, url)).then(
    response => {
      if (response.isSuccessful) {
        const { trafficItemId: id } = response;
        const data = omit(response, "isSuccessful");

        dispatch(commitData(TRAFFIC_ITEM_MODEL_NAME, id, data));
        dispatch(enqueueNotificationMessage(TRAFFIC_ITEM_SUCCESS_MESSAGE));
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const addTrafficItem = () => (dispatch, getState) => {
  const state = getState();
  const orderId = selectOrderForTraffic(state);

  dispatch(createTrafficItem(orderId));

  return Promise.resolve();
};

export const createAllTrafficItems = trafficPlanId => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);

  const url = generateUrl(CREATE_ALL_TRAFFIC_ITEMS, {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: trafficPlanId
  });

  return dispatch(apiCall(POST, url)).then(
    response => {
      const { isSuccessful, notProcessedOrders, items } = response;

      if (isSuccessful && items.length === 0) {
        dispatch(
          enqueueNotificationMessage(TRAFFIC_ITEMS_ALREADY_CREATED_MESSAGE)
        );
        return isSuccessful;
      }

      if (isSuccessful) {
        items.forEach(item => {
          const itemData = set(item, "id", item[TRAFFIC_ITEM_MODEL_ID]);

          dispatch(
            commitData(
              TRAFFIC_ITEM_MODEL_NAME,
              itemData[TRAFFIC_ITEM_MODEL_ID],
              itemData
            )
          );
        });
        dispatch(enqueueNotificationMessage(TRAFFIC_ITEMS_ALL_SUCCESS_MESSAGE));
        return isSuccessful;
      }

      dispatch(
        enqueueApiErrorMessage(
          `For next orders traffic items are not created: ${notProcessedOrders.join(
            ", "
          )}`
        )
      );
      return Promise.reject();
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
      return true;
    }
  );
};

export const addAllTrafficItems = () => (dispatch, getState) => {
  const state = getState();
  const trafficPlanId = selectCurrentTrafficPlanId(state);

  return dispatch(createAllTrafficItems(trafficPlanId));
};

export const copyTrafficItem = (trafficItemId, navigate) => (
  dispatch,
  getState
) => {
  const state = getState();
  const trafficItem = getTrafficItemById(state, trafficItemId);
  const orderId = get(trafficItem, ORDER_MODEL_ID);

  const agencyID = selectAgency(state);
  const url = generateUrl(COPY_TRAFFIC_ITEM, {
    agency: agencyID,
    [ORDER_MODEL_ID]: orderId,
    [TRAFFIC_ITEM_MODEL_ID]: trafficItemId
  });

  return dispatch(apiCall(POST, url)).then(
    response => {
      if (response.isSuccessful) {
        const { trafficItemId: id } = response;
        const data = omit(response, "isSuccessful");
        dispatch(commitData(TRAFFIC_ITEM_MODEL_NAME, id, data));
        dispatch(editNewItem(TRAFFIC_ITEM_MODEL_NAME, id, data));
        dispatch(changeUrl(id, navigate));
        dispatch({ type: TRAFFIC_ITEM_SET_IS_NEW });
        dispatch({ type: TRAFFIC_ITEM_EDIT_START });
        dispatch({ type: TRAFFIC_ITEM_COPY_START });
        dispatch(enqueueNotificationMessage(TRAFFIC_ITEM_SUCCESS_MESSAGE));
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const changeTrafficItemStatus = (id, statusId) => (
  dispatch,
  getState
) => {
  const state = getState();

  const agencyID = selectAgency(state);

  const url = generateUrl(EDIT_TRAFFIC_ITEM_STATUS, {
    agency: agencyID
  });

  const body = {
    trafficItemsStatus: [
      {
        [TRAFFIC_ITEM_MODEL_ID]: id,
        [TRAFFIC_ITEM_STATUS_ID]: statusId
      }
    ]
  };

  return dispatch(apiCall(PUT, url, body)).then(
    response => {
      if (
        response.isSuccessful &&
        !isNullOrUndefined(response.sentTrafficItemStatuses)
      ) {
        const data = response.sentTrafficItemStatuses[0];
        dispatch(
          commitData(TRAFFIC_ITEM_MODEL_NAME, id, {
            trafficItemId: data.trafficItemId,
            trafficItemStatusId: data.trafficItemStatusId,
            trafficItemStatusText: data.trafficItemStatusText
          })
        );
        dispatch(
          enqueueNotificationMessage(TRAFFIC_ITEM_STATUS_SUCCESS_MESSAGE)
        );
      } else {
        const invalidTransition = response.invalidStatusTransitions[0];
        dispatch(
          enqueueApplicationErrorMessage(
            `${TRAFFIC_ITEMS_INVALID_TRANSITIONS}: ${invalidTransition}`
          )
        );
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const deleteTrafficItem = () => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const data = getEditingTrafficItem(state);

  const url = generateUrl(DELETE_TRAFFIC_ITEM, {
    agency: agencyID,
    [ORDER_MODEL_ID]: data.orderId,
    [TRAFFIC_ITEM_MODEL_ID]: data[TRAFFIC_ITEM_MODEL_ID]
  });

  return dispatch(apiCall(DELETE, url)).then(
    response => {
      if (response.isSuccessful) {
        dispatch(stopEditingTrafficItem());
        dispatch(
          deleteEntity(TRAFFIC_ITEM_MODEL_NAME, data[TRAFFIC_ITEM_MODEL_ID])
        );
        dispatch(clearCurrentTrafficItem());
        dispatch(
          enqueueNotificationMessage("Traffic Item deleted successfully")
        );
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const setTrafficItemDigitalTemplate = template => ({
  type: TRAFFIC_ITEM_SET_DIGITAL_TEMPLATE,
  payload: { template }
});

export const resetTrafficItemDigitalTemplate = () => ({
  type: TRAFFIC_ITEM_RESET_DIGITAL_TEMPLATE
});
