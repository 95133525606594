import React, { memo } from "react";

import { IconButton, Tooltip } from "@mui/material";

import { CancelledIcon } from "../../../../assets/styles/icons";

import { classes, Div } from "../styles/filters";

const ClearAll = ({ onClear, disabled }) => {
  return (
    <Div className={classes.clearAll}>
      <Tooltip title="Clear" aria-label="clear">
        <span>
          <IconButton
            name="clear_filters"
            id="clear_filters"
            className={classes.button}
            onClick={onClear}
            disabled={disabled}
          >
            <CancelledIcon size={32} disabled={disabled} />
          </IconButton>
        </span>
      </Tooltip>
    </Div>
  );
};

export default memo(ClearAll);
