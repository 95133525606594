import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@mui/material";

import { CancelledIcon } from "../../../assets/styles/icons";

const ErrorNotifierAction = ({ key, closeSnackbar }) => {
  const handleClick = () => closeSnackbar(key);
  return (
    <IconButton onClick={handleClick}>
      <CancelledIcon />
    </IconButton>
  );
};

ErrorNotifierAction.propTypes = {
  key: PropTypes.number,
  closeSnackbar: PropTypes.func
};

export default ErrorNotifierAction;
