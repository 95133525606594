import React from "react";
import { Grid } from "@mui/material";
import CampaignField from "../../../form/CampaignField";
import CampaignCustomField from "../../../form/CampaignCustomField";
import { classes, Div } from "../styles/campaignForm";

const CampaignForm = () => {
  return (
    <Div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CampaignField
            component="text"
            id="campaignName"
            name="campaignName"
            label="Campaign name *"
            maxLength={100}
          />
        </Grid>
        <Grid item xs={10}>
          <CampaignField
            component="autocomplete"
            id="debtorId"
            name="debtorId"
            label="Debtor name *"
          />
        </Grid>
        <Grid item xs={2}>
          <CampaignCustomField
            component="text"
            id="debtorIDview"
            name="debtorId"
            label="Debtor ID"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <CampaignField
            component="autocomplete"
            id="agreementId"
            name="agreementId"
            label="Agreement *"
          />
        </Grid>
        <Grid item xs={10}>
          <CampaignField
            component="autocomplete"
            id="productId"
            name="productId"
            label="Product *"
          />
        </Grid>
        <Grid item xs={2}>
          <CampaignField
            component="text"
            id="productIDview"
            name="productId"
            label="Product ID"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <CampaignField
            component="date"
            id="startDate"
            name="startDate"
            label="Start Date *"
          />
        </Grid>
        <Grid item xs={6}>
          <CampaignField
            component="date"
            id="endDate"
            name="endDate"
            label="End Date *"
          />
        </Grid>
        <Grid item xs={6}>
          <CampaignField
            component="decimal"
            id="poBudget"
            name="poBudget"
            label="PO budget"
          />
        </Grid>
        <Grid item xs={6}>
          <CampaignField
            component="text"
            id="poNumber"
            name="poNumber"
            label="PO number"
            maxLength={50}
          />
        </Grid>
        <Grid item xs={12}>
          <CampaignField
            component="text"
            id="debtorContactPersonEmail"
            name="debtorContactPersonEmail"
            label="E-mail debtor contact person"
            maxLength={50}
          />
        </Grid>
        <Grid item xs={12}>
          <CampaignField
            component="autocomplete"
            id="processManagerId"
            name="processManagerId"
            label="Process manager *"
          />
        </Grid>
      </Grid>
    </Div>
  );
};

export default CampaignForm;
