import { styled, Table as MuiTable } from "@mui/material";

const PREFIX = "OrderBuyingStatusesTableStyles";
export const classes = {
  tableCell: `${PREFIX}-tableCell`
};

export const Table = styled(MuiTable)(() => ({
  [`& .${classes.tableCell}`]: {
    border: "none"
  }
}));
