import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import some from "lodash/some";
import size from "lodash/size";
import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";
import { selectCurrentOrder } from "../Order/orderSelectors";
import { getCampaignData } from "../Campaign/campaignSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";
import { getText } from "../common/utils/modelUtils";
import calculate, { calculateProperty } from "../common/utils/calculationUtils";
import OrderModel from "../Order/Order";
import InsertionModel from "./Insertion";
import { selectAgency } from "../../selectors/applicationSelectors";
import { currencySymbols } from "../../constants/businessConstants";

export const selectInsertion = state => state.insertion;
export const selectPropertyName = (_, props) => props.name;
export const selectInsertionId = (_, props) => props.entityId;
export const selectOrderId = (_, orderId) => orderId;

export const selectCurrentInsertions = createSelector(
  selectInsertion,
  insertion => insertion.currentInsertions
);

export const selectInsertionIsEditing = createSelector(
  selectInsertion,
  insertion => insertion.isEditing
);

export const selectInsertionDisplayErrors = createSelector(
  selectInsertion,
  insertion => insertion.displayErrors
);

export const hasCurrentInsertions = createSelector(
  selectCurrentInsertions,
  insertions => size(insertions) > 0
);

export const getInsertions = createSelector(
  [getEntitiesSession, selectCurrentOrder, getApiData, selectAgency],
  (entitiesSession, orderId, apiData, agencyID) => {
    const { Insertion } = entitiesSession;
    const configuration = OrderModel.apiConfiguration;
    const calculationConfig = InsertionModel.calculations;

    return Insertion.filter(insertion => insertion.order === orderId)
      .toModelArray()
      .map(insertion => {
        const fullEntity = {
          ...insertion.order.plan.campaign.ref,
          ...insertion.order.plan.ref,
          ...insertion.order.ref,
          ...insertion.ref
        };
        const calculationObject = calculate(calculationConfig, fullEntity);

        return {
          ...insertion.ref,
          ...calculationObject,
          title: getText(
            insertion.order.ref.titleId,
            configuration.titleId,
            fullEntity,
            apiData,
            agencyID
          ),
          currencySupplierSymbol:
            currencySymbols[insertion.order.plan.ref.currencySupplierId]
        };
      });
  }
);

export const getInsertion = createSelector(
  [getEntitiesSession, getApiData, selectAgency, selectInsertionId],
  (entitiesSession, apiData, agencyID, insertionId) => {
    const { Insertion } = entitiesSession;
    const calculationConfig = InsertionModel.calculations;

    const insertionModel = Insertion.get(
      insertion => insertion.id === insertionId
    );
    const fullEntity = {
      ...insertionModel.order.plan.campaign.ref,
      ...insertionModel.order.plan.ref,
      ...insertionModel.order.ref,
      ...insertionModel.ref
    };
    const calculationObject = calculate(calculationConfig, fullEntity);

    return {
      ...insertionModel.ref,
      ...calculationObject,
      currencySupplierSymbol:
        currencySymbols[insertionModel.order.plan.ref.currencySupplierId]
    };
  }
);

export const getInsertionsByOrderId = createSelector(
  [getEntitiesSession, selectOrderId],
  (entitiesSession, orderId) => {
    const { Insertion } = entitiesSession;
    return Insertion.filter(
      insertion => insertion.order === orderId
    ).toRefArray();
  }
);

export const getInsertionIDsByOrderId = createSelector(
  [getEntitiesSession, selectOrderId],
  (entitiesSession, orderId) => {
    const { Insertion } = entitiesSession;
    return Insertion.filter(insertion => insertion.order === orderId)
      .toRefArray()
      .map(i => i.id);
  }
);

export const getInsertionsIDs = createSelector(
  [getEntitiesSession, selectCurrentOrder],
  (entitiesSession, orderId) => {
    const { Insertion } = entitiesSession;
    return Insertion.filter(insertion => insertion.order === orderId)
      .toRefArray()
      .map(i => i.id);
  }
);

export const getEditingInsertionsIDs = createSelector(
  [getEditingEntitiesSession, selectInsertion],
  (editingSession, insertion) => {
    const { Insertion } = editingSession;
    return Insertion.filter(i => insertion.currentInsertions.includes(i.id))
      .toRefArray()
      .map(i => i.id);
  }
);

export const getInsertionsForSave = createSelector(
  [getEditingEntitiesSession, selectInsertion],
  (editingSession, insertion) => {
    const { Insertion } = editingSession;
    return Insertion.filter(i =>
      insertion.currentInsertions.includes(i.id)
    ).toRefArray();
  }
);

export const getInsertionPropertyValue = createCachedSelector(
  [getEditingEntitiesSession, selectInsertionId, selectPropertyName],
  (editingSession, entityId, name) => {
    const { Insertion } = editingSession;
    const insertionModel = Insertion.get(i => i.id === entityId);
    return insertionModel ? insertionModel.ref[name] : undefined;
  }
)((state, props) => `${props.entityId}:${props.name}`);

export const getInsertionPropertyCalculationValue = createCachedSelector(
  [getEditingEntitiesSession, selectInsertionId, selectPropertyName],
  (editingSession, entityId, name) => {
    const { Insertion } = editingSession;
    const insertionModel = Insertion.get(i => i.id === entityId);
    const calcPropertyConfig = Insertion.calculations[name];
    return insertionModel
      ? calculateProperty(calcPropertyConfig, insertionModel.ref)
      : undefined;
  }
)((state, props) => `${props.entityId}:${props.name}`);

export const getInsertionPropertyCalculationSumValue = createCachedSelector(
  [getEditingEntitiesSession, selectCurrentInsertions, selectPropertyName],
  (editingSession, currentInsertions, name) => {
    const { Insertion } = editingSession;
    const insertions = Insertion.filter(i => currentInsertions.includes(i.id))
      .toModelArray()
      .map(insertion => ({
        ...insertion.order.ref,
        ...insertion.ref
      }));
    const calcPropertyConfig = Insertion.calculations[name];
    return insertions
      ? calculateProperty(calcPropertyConfig, insertions)
      : undefined;
  }
)((state, props) => props.name);

export const getInsertionPropertyCalculationSumErrors = createCachedSelector(
  [getEditingEntitiesSession, selectCurrentInsertions, selectPropertyName],
  (editingSession, currentInsertions, name) => {
    const { Insertion } = editingSession;
    const insertions = Insertion.filter(i => currentInsertions.includes(i.id))
      .toModelArray()
      .map(insertion => ({
        ...insertion.order.ref,
        ...insertion.ref
      }));
    const calcPropertyConfig = Insertion.calculations[name];
    return insertions
      ? Insertion.validateCalculationByProperty(
          name,
          calculateProperty(calcPropertyConfig, insertions)
        )
      : undefined;
  }
)((state, props) => props.name);

export const getInsertionPropertyErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectInsertionId, selectPropertyName],
  (editingSession, entityId, name) => {
    const { Insertion } = editingSession;
    const insertionModel = Insertion.get(c => c.id === entityId);
    return insertionModel ? insertionModel.validateByProperty(name) : undefined;
  }
)((state, props) => `${props.entityId}:${props.name}`);

export const doInsertionsHaveErrors = createSelector(
  [getEditingEntitiesSession, getEditingInsertionsIDs],
  (editingSession, currentInsertions) => {
    const { Insertion } = editingSession;
    const insertions = Insertion.filter(insertion =>
      currentInsertions.includes(insertion.id)
    ).toModelArray();
    const insertionsData = insertions.map(insertion => ({
      ...insertion.order.ref,
      ...insertion.ref
    }));
    return size(insertions) > 0
      ? some(insertions, insertion => insertion.hasErrors()) ||
          Insertion.hasCalculationErrors(insertionsData)
      : false;
  }
);

export const isInsertionInvoiced = createCachedSelector(
  [getEditingEntitiesSession, selectInsertionId],
  (editingSession, entityId) => {
    const { Insertion } = editingSession;
    const insertionModel = Insertion.get(c => c.id === entityId);
    return insertionModel ? insertionModel.ref.insertionIsInvoiced : false;
  }
)((state, props) => props.entityId);

export const isOrderCancelledOrToBeCancelled = createCachedSelector(
  [getEntitiesSession, selectInsertionId],
  (entitiesSession, entityId) => {
    const { Insertion } = entitiesSession;
    const insertionModel = Insertion.get(c => c.id === entityId);
    return insertionModel
      ? insertionModel.order.ref.statusId === 4 ||
          insertionModel.order.ref.statusId === 32
      : false;
  }
)((state, props) => props.entityId);

export const isOrderPendingForApproval = createCachedSelector(
  [getEntitiesSession, selectInsertionId],
  (entitiesSession, entityId) => {
    const { Insertion } = entitiesSession;
    const insertionModel = Insertion.get(c => c.id === entityId);
    return insertionModel
      ? insertionModel.order.ref.statusId === 35 ||
          insertionModel.order.ref.statusId === 36
      : false;
  }
)((state, props) => props.entityId);

export const disabledByInactiveDebtor = createSelector(
  [getCampaignData],
  campaign => {
    return !campaign?.isDebtorActive;
  }
);

export const selectRules = createSelector(
  [
    isInsertionInvoiced,
    isOrderCancelledOrToBeCancelled,
    isOrderPendingForApproval,
    disabledByInactiveDebtor
  ],
  (isInvoiced, isCancelledOrToBeCancelled, isPendingForApproval, disabled) => {
    return {
      isInsertionInvoiced: isInvoiced,
      isOrderCancelledOrToBeCancelled: isCancelledOrToBeCancelled,
      isOrderPendingForApproval: isPendingForApproval,
      disabledByInactiveDebtor: disabled
    };
  }
);

export const isDisabled = createCachedSelector(
  [selectRules, selectPropertyName],
  (rules, name) => {
    const configuration = InsertionModel.disabledFields;
    return some(configuration[name], c => rules[c]);
  }
)((state, props) => props.name);
