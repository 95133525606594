import React from "react";
import { useSelector } from "react-redux";

import Scrollbar from "../../../../shared/Scrollbar";
import ActionPlanItem from "./extensions/ActionPlanItem";

import { getPlans } from "../../../../../business/models/Plan/planSelectors";

const ActionPlanPanel = () => {
  const plans = useSelector(getPlans);

  return (
    <Scrollbar>
      {plans.map(plan => (
        <ActionPlanItem key={plan.id} plan={plan} />
      ))}
    </Scrollbar>
  );
};

export default ActionPlanPanel;
