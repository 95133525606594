import { styled, Grid as MuiGrid } from "@mui/material";

const PREFIX = "ActionOrderElementActionsStyle";
export const classes = {
  checkbox: `${PREFIX}-checkbox`,
  iconButton: `${PREFIX}-iconButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.checkbox}`]: {
    height: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(24)
  },
  [`& .${classes.iconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(theme.spacing(1))
  }
}));
