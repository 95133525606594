import { styled, Grid as MuiGrid } from "@mui/material";
import { baliHai, solitude } from "../../../assets/styles/colors";

const PREFIX = "CompactStyles";
export const classes = {
  chipHeaderLarge: `${PREFIX}-chipHeaderLarge`,
  typography: `${PREFIX}-typography`,
  inheritWidth: `${PREFIX}-inheritWidth`,
  chipTraffic: `${PREFIX}-chipTraffic`,
  chipHeader: `${PREFIX}-chipHeader`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.chipHeaderLarge}`]: {
    width: theme.typography.pxToRem(190)
  },
  [`& .${classes.typography}`]: {
    color: baliHai
  },
  [`& .${classes.inheritWidth}`]: {
    width: "inherit"
  },
  [`& .${classes.chipTraffic}`]: {
    color: baliHai,
    minWidth: theme.typography.pxToRem(180),
    backgroundColor: solitude
  },
  [`& .${classes.chipHeader}`]: {
    width: theme.typography.pxToRem(100)
  }
}));
