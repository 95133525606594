import { createReducer } from "../utils/reducerUtils";

import {
  LOAD_ENTITY_DATA_SUCCESS,
  APPEND_ENTITY_DATA_SUCCESS,
  CLEAR_ENTITY_DATA,
  LOAD_USERS_SUCCESS,
  LOAD_TRAFFIC_PLAN_DATA_SUCCESS,
  LOAD_SYSTEM_MESSAGES_SUCCESS,
  LOAD_TRAFFIC_TEMPLATES_SUCCESS
} from "../data/dataConstants";

import orm from "../../schema";

const initialState = orm.getEmptyState();

export function loadEntityData(state, payload) {
  // Create a Redux-ORM session from our entities "tables"
  const session = orm.session(state);

  // Get a reference to the correct version of model classes for this Session
  const {
    Campaign,
    Plan,
    PlanExtension,
    PlanTargetMetricsKpi,
    PlanChangeOverview,
    Project,
    Order,
    Insertion
  } = session;

  // Clear out any existing models from state so that we can avoid
  // conflicts from the new data coming in if data is reloaded
  [
    Campaign,
    Plan,
    PlanExtension,
    PlanTargetMetricsKpi,
    PlanChangeOverview,
    Project,
    Order,
    Insertion
  ].forEach(modelType => {
    modelType
      .all()
      .toModelArray()
      .forEach(model => model.delete());
  });

  // Immutably update the session state as we insert items
  const { data } = payload;
  const campaign = data;

  Campaign.parse(campaign);

  // Return the new "tables" object containing the updates
  return session.state;
}

export function loadUsers(state, payload) {
  const session = orm.session(state);
  const { User } = session;
  payload.forEach(user => {
    User.parse(user);
  });

  return session.state;
}

export function loadTrafficTemplates(state, payload) {
  const session = orm.session(state);
  const { TrafficTemplate } = session;
  const { data } = payload;
  data.forEach(template => {
    TrafficTemplate.parse(template);
  });

  return session.state;
}

export const loadSystemMessages = (state, payload) => {
  const session = orm.session(state);
  const { SystemMessage } = session;
  const { items } = payload;
  items.forEach(entry => SystemMessage.parse(entry));

  return session.state;
};

export function clearEntityData() {
  return initialState;
}

export function loadTrafficPlans(state, payload) {
  const session = orm.session(state);
  const { TrafficPlan } = session;
  const { items } = payload;
  const { isDebtorActive } = payload;
  items.forEach(entry => TrafficPlan.parse(entry, isDebtorActive));
  return session.state;
}

export function appendEntityData(state, payload) {
  // Create a Redux-ORM session from our entities "tables"
  const session = orm.session(state);

  // Get a reference to the correct version of model classes for this Session
  const { Campaign } = session;

  // Immutably update the session state as we insert items
  const { data } = payload;
  const campaign = data;

  Campaign.parse(campaign);

  // Return the new "tables" object containing the updates
  return session.state;
}

export default createReducer(initialState, {
  [LOAD_ENTITY_DATA_SUCCESS]: loadEntityData,
  [APPEND_ENTITY_DATA_SUCCESS]: appendEntityData,
  [LOAD_USERS_SUCCESS]: loadUsers,
  [LOAD_TRAFFIC_TEMPLATES_SUCCESS]: loadTrafficTemplates,
  [CLEAR_ENTITY_DATA]: clearEntityData,
  [LOAD_TRAFFIC_PLAN_DATA_SUCCESS]: loadTrafficPlans,
  [LOAD_SYSTEM_MESSAGES_SUCCESS]: loadSystemMessages
});
