import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { Button } from "@mui/material";

import { selectIsLoading } from "../../../../../business/selectors/applicationSelectors";
import { openDialog } from "../../../../../business/actions/userProfileActions";

import { classes, Grid } from "../../styles/userProfileActions";

const UserProfileActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(selectIsLoading);

  const handleCancel = () => {
    navigate("/");
  };
  const handleSave = () => {
    dispatch(openDialog());
  };

  return (
    <>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            name="up_cancel"
            id="up_cancel"
            variant="contained"
            className={classes.cancelButton}
            disabled={isLoading}
            onClick={handleCancel}
          >
            CANCEL
          </Button>
        </Grid>
        <Grid item>
          <Button
            name="up_save"
            id="up_save"
            variant="contained"
            className={clsx(classes.saveButton, classes.markedButton)}
            disabled={isLoading}
            onClick={handleSave}
          >
            SAVE CHANGES
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UserProfileActions;
