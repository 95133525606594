import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import size from "lodash/size";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import { selectAgency } from "../../selectors/applicationSelectors";
import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";
import { getText, getOptions, getPathValue } from "../common/utils/modelUtils";
import calculate, { sum } from "../common/utils/calculationUtils";
import { selectCurrentPlan, selectedOrders } from "../Plan/planSelectors";
import { getCampaignData } from "../Campaign/campaignSelectors";
import OrderModel from "./Order";
import { currencySymbols } from "../../constants/businessConstants";
import { isNullOrUndefined } from "../../../functions/util";
import { getSelected } from "../../selectors/actionPageSelectors";
import {
  ORDER_STATUS_TO_BE_CANCELLED,
  ORDER_STATUS_CANCELLED
} from "../../../configurations/appConstants";

export const selectOrder = state => state.order;
export const selectOrderIdFromData = (_, data) => data.orderId;
export const selectPlanIdFromData = (_, data) => data.planId;
export const selectPropertyName = (_, props) => props.name;
export const selectOrderId = (_, orderId) => orderId;

export const selectCurrentOrder = createSelector(
  selectOrder,
  order => order.currentOrder
);

export const selectIsEditingOrder = createSelector(
  selectOrder,
  order => order.isEditing
);

export const selectOrderDisplayErrors = createSelector(
  selectOrder,
  order => order.displayErrors
);

export const selectOrderIsNew = createSelector(
  selectOrder,
  order => order.isNew
);

export const selectOrderToDelete = createSelector(
  selectOrder,
  order => order.orderToDelete
);

export const getOrder = createSelector(
  [getEntitiesSession, selectOrder],
  (entitiesSession, order) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(c => c.id === order.currentOrder);

    return orderModel ? orderModel.ref : undefined;
  }
);

export const getOrderById = createSelector(
  [getEntitiesSession, selectOrderId],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(c => c.id === orderId);

    return orderModel ? orderModel.ref : undefined;
  }
);

export const getOrders = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  (entitiesSession, planId) => {
    const { Order, Insertion } = entitiesSession;

    return Order.filter(order => order.plan === planId)
      .toModelArray()
      .map(order => {
        const numberOfInsertions = Insertion.filter(
          i => i.order === order.ref.orderId
        ).count();

        const haveInvoicedInsertions =
          Insertion.filter(
            i => i.order === order.ref.orderId && i.insertionIsInvoiced === true
          ).count() > 0;

        return {
          ...order.plan.campaign.ref,
          ...order.plan.ref,
          ...order.ref,
          numberOfInsertions,
          haveInvoicedInsertions,
          currencySupplierSymbol:
            currencySymbols[order.plan.ref.currencySupplierId],
          currencySupplierId: order.plan.ref.currencySupplierId
        };
      });
  }
);

export const getFullEntityOrder = createSelector(
  [getEntitiesSession, getApiData, selectAgency, selectOrderIdFromData],
  (entitiesSession, apiData, agencyId, orderId) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(c => c.id === orderId);

    const configuration = Order.apiConfiguration;

    const entity = {
      ...orderModel.plan.campaign.ref,
      ...orderModel.plan.ref,
      ...orderModel.ref
    };

    const fullEntity = {
      ...entity,
      surcharge1Commission: getPathValue(
        configuration.surcharge1Commission,
        entity,
        apiData,
        agencyId
      ),
      surcharge1Fee: getPathValue(
        configuration.surcharge1Fee,
        entity,
        apiData,
        agencyId
      ),
      surcharge2Commission: getPathValue(
        configuration.surcharge2Commission,
        entity,
        apiData,
        agencyId
      ),
      surcharge2Fee: getPathValue(
        configuration.surcharge2Fee,
        entity,
        apiData,
        agencyId
      ),
      costTypeMultiplier: getPathValue(
        configuration.costTypeMultiplier,
        entity,
        apiData,
        agencyId
      ),
      currencySupplierSymbol:
        currencySymbols[orderModel.plan.ref.currencySupplierId],
      currencySupplierId: orderModel.plan.ref.currencySupplierId
    };

    return fullEntity;
  }
);

export const getPropertyTextFromApiData = createSelector(
  [getFullEntityOrder, getApiData, selectAgency, selectPropertyName],
  (order, apiData, agency, name) => {
    const configuration = OrderModel.apiConfiguration;
    return order
      ? getText(order[name], configuration[name], order, apiData, agency)
      : null;
  }
);

export const getOrderIds = createSelector([getOrders], orders => {
  return orders.map(order => order.id);
});

export const getOrderCalculationData = createSelector(
  [getFullEntityOrder],
  order => {
    const calculationConfig = OrderModel.calculations;
    return calculate(calculationConfig, order);
  }
);

export const getOrdersForChangeStatus = createSelector(
  [selectedOrders, getOrders],
  (selectedOrderList, orders) => {
    return orders.filter(e => selectedOrderList.includes(e.orderId));
  }
);

export const getOrdersForActionPage = createSelector(
  [getOrders, getSelected],
  (orders, selectedActionOrders) => {
    return orders.filter(e => selectedActionOrders.includes(e.orderId));
  }
);

// Helper method
function isNoneApprovedStatus(order) {
  return !(order.statusId === 3 || order.statusId === 33);
}

function isToBeCancelledOrCancelledStatus(order) {
  return order.statusId === 4 || order.statusId === 32;
}

export const getOrdersCount = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  (entitiesSession, planId) => {
    const { Order } = entitiesSession;
    return Order.filter(
      order => order.plan === planId && isNoneApprovedStatus(order)
    ).count();
  }
);

export const getEditingOrder = createSelector(
  [getEditingEntitiesSession, selectOrder],
  (editingSession, order) => {
    const { Order } = editingSession;
    const orderModel = Order.get(c => c.id === order.currentOrder);
    return orderModel
      ? {
          ...orderModel.plan.campaign.ref,
          ...orderModel.plan.ref,
          ...orderModel.ref
        }
      : undefined;
  }
);

export const getEditingOrderFull = createSelector(
  [getEditingEntitiesSession, getApiData, selectCurrentOrder, selectAgency],
  (editingSession, apiData, orderId, agencyID) => {
    const { Order } = editingSession;
    const orderModel = Order.get(c => c.id === orderId);
    const configuration = Order.apiConfiguration;
    const calculationConfig = OrderModel.calculations;
    if (!orderModel) {
      return undefined;
    }
    const fullEntity = {
      ...orderModel.plan.campaign.ref,
      ...orderModel.plan.ref,
      ...orderModel.ref
    };
    const calculationObject = calculate(calculationConfig, fullEntity);
    return {
      ...orderModel.ref,
      ...calculationObject,
      channel: getText(
        orderModel.ref.channelId,
        configuration.channelId,
        fullEntity,
        apiData,
        agencyID
      ),
      title: getText(
        orderModel.ref.titleId,
        configuration.titleId,
        fullEntity,
        apiData,
        agencyID
      ),
      agreement: getText(
        orderModel.ref.orderAgreementId,
        configuration.orderAgreementId,
        fullEntity,
        apiData,
        agencyID
      ),
      digitalChannel: getText(
        orderModel.ref.digitalChannelId,
        configuration.digitalChannelId,
        fullEntity,
        apiData,
        agencyID
      ),
      format: getText(
        orderModel.ref.formatId,
        configuration.formatId,
        fullEntity,
        apiData,
        agencyID
      ),
      costType: getText(
        orderModel.ref.costTypeId,
        configuration.costTypeId,
        fullEntity,
        apiData,
        agencyID
      ),
      discountCode: getText(
        orderModel.ref.discountCodeId,
        configuration.discountCodeId,
        fullEntity,
        apiData,
        agencyID
      ),
      surcharge1Type: getText(
        orderModel.ref.surcharge1TypeId,
        configuration.surcharge1TypeId,
        fullEntity,
        apiData,
        agencyID
      ),
      surcharge2Type: getText(
        orderModel.ref.surcharge2TypeId,
        configuration.surcharge2TypeId,
        fullEntity,
        apiData,
        agencyID
      ),
      statusText: getText(
        orderModel.ref.statusId,
        configuration.statusId,
        fullEntity,
        apiData,
        agencyID
      ),
      currencySupplierSymbol:
        currencySymbols[orderModel.plan.ref.currencySupplierId],
      currencySupplierId: orderModel.plan.ref.currencySupplierId
    };
  }
);

export const getOrderForSave = createSelector(
  [getEditingEntitiesSession, selectCurrentOrder],
  (editingSession, orderId) => {
    const { Order } = editingSession;
    const orderModel = Order.get(c => c.id === orderId);
    return orderModel ? orderModel.ref : undefined;
  }
);

export const doesOrderHaveErrors = createSelector(
  [getEditingEntitiesSession, selectOrder],
  (editingSession, order) => {
    const { Order } = editingSession;
    const orderModel = Order.get(c => c.id === order.currentOrder);
    return orderModel ? orderModel.hasErrors() : false;
  }
);

export const getOrderPropertyErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectOrder, selectPropertyName],
  (editingSession, order, name) => {
    const { Order } = editingSession;
    const orderModel = Order.get(c => c.id === order.currentOrder);
    return orderModel ? orderModel.validateByProperty(name) : undefined;
  }
)((state, props) => props.name);

export const doesOrderHaveDescriptionError = createSelector(
  [getEntitiesSession, selectOrderId],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(c => c.id === orderId);
    return orderModel ? size(orderModel.ref.description) > 50 : false;
  }
);

export const getOrderPropertyValue = createCachedSelector(
  [getEditingOrder, selectPropertyName],
  (order, name) => (order ? order[name] : undefined)
)((state, props) => props.name);

export const getOrderPropertyPreviousValue = createCachedSelector(
  [getOrder, selectPropertyName],
  (order, name) => (order ? order[name] : undefined)
)((state, props) => props.name);

export const getOrderPropertyOptions = createCachedSelector(
  [getApiData, getEditingOrder, selectPropertyName, selectAgency],
  (apiData, order, name, agencyID) => {
    const configuration = OrderModel.apiConfiguration[name];
    return configuration
      ? getOptions(configuration, apiData, order, agencyID)
      : undefined;
  }
)((state, props) => props.name);

export const getEditingOrderCalculationData = createSelector(
  [getApiData, getEditingOrder, selectAgency],
  (apiData, order, agencyID) => {
    const apiConfig = OrderModel.apiConfiguration;
    const calculationConfig = OrderModel.calculations;
    const calculationObject = calculate(calculationConfig, order);
    return order
      ? {
          title: getText(
            order.titleId,
            apiConfig.titleId,
            order,
            apiData,
            agencyID
          ),
          flightId: order.flightId,
          currencySupplierSymbol: currencySymbols[order.currencySupplierId],
          agencyDiscountAmount: order.agencyDiscountAmount,
          feePercentage: order.feePercentage,
          ...calculationObject
        }
      : undefined;
  }
);

export const getOrderCustomCalculationData = createSelector(
  [getEntitiesSession, getEditingOrder],
  (entitiesSession, editingOrder) => {
    if (!editingOrder) {
      return undefined;
    }
    const calculationConfig = OrderModel.calculations;
    const { totalBudget } = editingOrder;
    const { totalCostClient } = calculate(calculationConfig, editingOrder);

    const { Order } = entitiesSession;
    const orderModel = Order.filter(
      order =>
        order.id !== editingOrder.orderId &&
        order.plan === editingOrder.planId &&
        !order.isCopyTemp &&
        order.statusId !== ORDER_STATUS_CANCELLED &&
        order.statusId !== ORDER_STATUS_TO_BE_CANCELLED
    )
      .toModelArray()
      .map(order => {
        const calculationObject = calculate(calculationConfig, order);
        return calculationObject.totalCostClient;
      });

    let remainingBudget;
    if (!isNullOrUndefined(totalBudget) && totalBudget !== 0) {
      remainingBudget = totalBudget - sum([totalCostClient, ...orderModel]);
    }
    return {
      totalBudget: totalBudget === 0 ? undefined : totalBudget,
      remainingBudget
    };
  }
);

export const doesOrderHaveInvoicedInsertions = createSelector(
  [getEntitiesSession, selectCurrentOrder],
  (entitiesSession, orderId) => {
    const { Insertion } = entitiesSession;
    const insertions = Insertion.filter(i => i.order === orderId)
      .toModelArray()
      .map(i => i.insertionIsInvoiced);
    return !isEmpty(insertions) ? some(insertions) : false;
  }
);

export const doesOrderHaveInvoicedInsertionsById = createSelector(
  [getEntitiesSession, selectOrderId],
  (entitiesSession, orderId) => {
    const { Insertion } = entitiesSession;
    const insertions = Insertion.filter(i => i.order === orderId)
      .toModelArray()
      .map(i => i.insertionIsInvoiced);
    return !isEmpty(insertions) ? some(insertions) : false;
  }
);

export const doesOrderHaveStatusToBeCancelledOrCancelledById = createSelector(
  [getEntitiesSession, selectOrderId],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    const order = Order.get(o => o.orderId === orderId);
    return isToBeCancelledOrCancelledStatus(order);
  }
);

export const isOrderApproved = createSelector(
  [getEntitiesSession, selectCurrentOrder],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(o => o.id === orderId);

    return orderModel ? [3, 34].includes(orderModel.ref.statusId) : false;
  }
);

export const isOrderOrderChange = createSelector(
  [getEntitiesSession, selectCurrentOrder],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(o => o.id === orderId);

    return orderModel ? [6].includes(orderModel.ref.statusId) : false;
  }
);

export const isOrderChangeNotApproved = createSelector(
  [getEntitiesSession, selectCurrentOrder],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(o => o.id === orderId);

    return orderModel ? [33].includes(orderModel.ref.statusId) : false;
  }
);

export const isOrderToBeCancelled = createSelector(
  [getEntitiesSession, selectCurrentOrder],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(o => o.id === orderId);

    return orderModel ? [32].includes(orderModel.ref.statusId) : false;
  }
);

export const isOrderCancelled = createSelector(
  [getEntitiesSession, selectCurrentOrder],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(o => o.id === orderId);

    return orderModel ? [4].includes(orderModel.ref.statusId) : false;
  }
);

export const isOrderPendingForApproval = createSelector(
  [getEntitiesSession, selectCurrentOrder],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(o => o.id === orderId);

    return orderModel ? [35, 36].includes(orderModel.ref.statusId) : false;
  }
);

export const hasTrafficItems = createSelector(
  [getEntitiesSession, selectCurrentOrder],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(o => o.id === orderId);

    return orderModel ? orderModel.ref.hasTrafficItems : false;
  }
);

export const disabledByInactiveDebtor = createSelector(
  [getCampaignData],
  campaign => {
    return campaign?.isDebtorActive === false;
  }
);

export const selectRules = createSelector(
  [
    isOrderApproved,
    isOrderOrderChange,
    isOrderChangeNotApproved,
    isOrderToBeCancelled,
    isOrderCancelled,
    isOrderPendingForApproval,
    hasTrafficItems,
    disabledByInactiveDebtor
  ],
  (
    isApproved,
    isOrderChange,
    isChangeNotApproved,
    isToBeCancelled,
    isCancelled,
    isPendingForApproval,
    hasTraffic,
    disabled
  ) => {
    return {
      isOrderApproved: isApproved,
      isOrderOrderChange: isOrderChange,
      isOrderChangeNotApproved: isChangeNotApproved,
      isOrderToBeCancelled: isToBeCancelled,
      isOrderCancelled: isCancelled,
      isOrderPendingForApproval: isPendingForApproval,
      hasTrafficItems: hasTraffic,
      disabledByInactiveDebtor: disabled
    };
  }
);

export const isDisabled = createCachedSelector(
  [selectRules, selectPropertyName],
  (rules, name) => {
    const configuration = OrderModel.disabledFields;
    return some(configuration[name], c => rules[c]);
  }
)((state, props) => props.name);

export const doesOrderExist = createSelector(
  [getEntitiesSession, selectPlanIdFromData, selectOrderIdFromData],
  (entitiesSession, planId, orderId) => {
    const { Order } = entitiesSession;
    return Order.exists(o => o.id === orderId && o.plan === planId);
  }
);

export const selectInvoicedOrderAgreementChangeFlag = createSelector(
  [getOrder, getEditingOrder, doesOrderHaveInvoicedInsertions],
  (savedOrder, editingOrder, hasInvoiced) => {
    const { orderAgreementId: savedAgreement } = savedOrder || {};
    const { orderAgreementId: editedAgreemnt } = editingOrder || {};
    if (savedAgreement !== editedAgreemnt && hasInvoiced) {
      return true;
    }
    return false;
  }
);

export const isOrderCancelledOrToBeCancelled = createSelector(
  [getEntitiesSession, selectCurrentOrder],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    return Order.exists(
      o => o.id === orderId && (o.statusId === 4 || o.statusId === 32)
    );
  }
);

export const isOrderCancelledOrToBeCancelledByID = createCachedSelector(
  [getEntitiesSession, selectOrderId],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    return Order.exists(
      o => o.id === orderId && (o.statusId === 4 || o.statusId === 32)
    );
  }
)((state, orderId) => orderId);

export const isOrderPendingForApprovalByID = createCachedSelector(
  [getEntitiesSession, selectOrderId],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;

    return Order.exists(
      o => o.id === orderId && (o.statusId === 35 || o.statusId === 36)
    );
  }
)((state, orderId) => orderId);

export const hasOrdersWithStatus2or6or32 = createSelector(
  [getEntitiesSession],
  entitiesSession => {
    const { Order } = entitiesSession;
    return Order.exists(
      o => o.statusId === 2 || o.statusId === 6 || o.statusId === 32
    );
  }
);

export const selectCanUserDoActions = createSelector(
  [getEntitiesSession, selectOrderId],
  (entitiesSession, orderId) => {
    const { Order } = entitiesSession;
    const orderModel = Order.get(c => c.id === orderId);

    return orderModel ? orderModel.ref.canUserDoActions : false;
  }
);
