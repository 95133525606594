import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, DialogContent, DialogActions } from "@mui/material";

import DialogTitle from "../../shared/dialog/DialogTitle";
import { updateSystemNotificationsStatuses } from "../../../business/models/SystemMessage/systemMessageActions";
import { selectSysNotifications } from "../../../business/selectors/notificationsSelectors";
import NotificationCard from "./extensions/NotificationCard";
import { NOTIFICATION_TYPE_SYSTEM } from "../../../configurations/appConstants";
import { selectOpenSysNotificationDialog } from "../../../business/selectors/applicationSelectors";
import { downloadSysMsgAttachment } from "../../../business/actions/notificationsActions";
import { classes, Dialog } from "./styles/systemNotificationDialog";

const SystemNotificationDialog = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectSysNotifications);
  const open = useSelector(selectOpenSysNotificationDialog);

  const handleClick = () => {
    const ids = notifications.map(n => n.id);
    dispatch(updateSystemNotificationsStatuses(ids));
  };
  const handleDownloadClick = (id, fileName) => {
    dispatch(downloadSysMsgAttachment(id, fileName));
  };

  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle id="system-notification-dialog-title">
        System Notification(s):
      </DialogTitle>
      <DialogContent id="system-notification-dialog-content">
        {notifications.map(notification => {
          const {
            title,
            message,
            modified,
            id,
            hasAttachment,
            systemMessageId,
            fileName
          } = notification;

          return (
            <NotificationCard
              key={id + modified}
              title={title}
              message={message}
              timestamp={modified}
              type={NOTIFICATION_TYPE_SYSTEM}
              id={id}
              hasAttachment={hasAttachment}
              systemMessageId={systemMessageId}
              onDownloadClick={handleDownloadClick}
              fileName={fileName}
            />
          );
        })}
      </DialogContent>
      <DialogActions id="system-notification-dialog-action">
        <div />
        <Button className={classes.buttonColor} onClick={handleClick}>
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SystemNotificationDialog;
