import { styled, Accordion as MuiAccordion } from "@mui/material";
import {
  baliHai,
  matisse,
  crusta,
  linkWater,
  lilac,
  deYork
} from "../../../../../assets/styles/colors";

const PREFIX = "ActionCampaignItemStyle";
export const classes = {
  typographyHeaderCaption: `${PREFIX}-typographyHeaderCaption`,
  typographyHeaderValue: `${PREFIX}-typographyHeaderValue`,
  typographyLink: `${PREFIX}-typographyLink`,
  cssToBeCancelled: `${PREFIX}-cssToBeCancelled`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  cssOrderChange: `${PREFIX}-cssOrderChange`,
  cssReadyForApproval: `${PREFIX}-cssReadyForApproval`,
  cssApproved: `${PREFIX}-cssApproved`,
  link: `${PREFIX}-link`
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`& .${classes.typographyHeaderCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyHeaderValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyLink}`]: {
    "&:hover": {
      color: theme.palette.primary.light
    }
  },
  [`& .${classes.link}`]: {
    display: "block"
  },
  [`& .${classes.cssToBeCancelled}`]: {
    color: crusta,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.cssOrderChange}`]: {
    color: lilac,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.cssReadyForApproval}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.cssApproved}`]: {
    color: deYork,
    fontSize: theme.typography.pxToRem(24)
  }
}));
