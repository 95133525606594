import { styled, Dialog as MuiDialog } from "@mui/material";
import { crusta } from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "ActionInsertionItemStyles";
export const classes = {
  dialog: `${PREFIX}-dialog`,
  root: `${PREFIX}-root`,
  closeButton: `${PREFIX}-closeButton`,
  titleButton: `${PREFIX}-titleButton`,
  redLabelChip: `${PREFIX}-redLabelChip`,
  action: `${PREFIX}-action`,
  title: `${PREFIX}-title`
};

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${classes.dialog}`]: {
    width: theme.typography.pxToRem(400),
    overflowY: "visible" // Autocomplete issue
  },
  [`& .${classes.root}`]: {
    margin: 0,
    padding: theme.typography.pxToRem(getThemeSpacing(theme, 2)),
    overflowY: "visible !important"
  },
  [`& .${classes.closeButton}`]: {
    color: crusta
  },
  [`& .${classes.titleButton}`]: {
    float: "right"
  },
  [`& .${classes.action}`]: {
    borderTop: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.typography.pxToRem(getThemeSpacing(theme, 2))
  },
  [`& .${classes.title}`]: {
    marginBottom: theme.typography.pxToRem(12),
    marginTop: theme.typography.pxToRem(2)
  }
}));
