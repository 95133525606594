import React from "react";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import { fieldToTextField } from "formik-mui";
import {
  EMPTY_STRING,
  MAX_NUMBER_FOR_INPUTS,
  MIN_NUMBER_FOR_INPUTS
} from "../../../configurations/appConstants";

const DecimalFormik = ({ allowNegative = true, ...props }) => {
  const {
    form: { setTouched, setFieldValue, touched }
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  const handleBlure = () => setTouched({ ...touched, [name]: true });
  const handleChange = val => setFieldValue(name, val.floatValue);

  return (
    <NumberFormat
      {...props}
      {...field}
      onValueChange={handleChange}
      onBlur={handleBlure}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      helperText={helperText}
      fixedDecimalScale
      allowNegative={allowNegative}
      error={error}
      customInput={TextField}
      isAllowed={values => {
        const { formattedValue, floatValue: floatVal } = values;
        return (
          formattedValue === EMPTY_STRING ||
          (floatVal > MIN_NUMBER_FOR_INPUTS && floatVal < MAX_NUMBER_FOR_INPUTS)
        );
      }}
    />
  );
};

export default DecimalFormik;
