import callApi from "../../functions/api";
import {
  GET_USER_PROFILE,
  GET_USER_SETTINGS,
  SAVE_USER_SETTINGS
} from "../../configurations/apiUrls";
import {
  LOAD_USER_PROFILE_SUCCESS,
  OPEN_USER_PROFILE_CONFIRMATION_DIALOG,
  CLOSE_USER_PROFILE_CONFIRMATION_DIALOG
} from "../constants/userProfileConstants";
import { apiCall, enqueueApiErrorMessage } from "./applicationActions";
import { selectAgency } from "../selectors/applicationSelectors";
import generateUrl from "../models/common/utils/urlUtils";
import { GET, PUT } from "../constants/applicationConstants";

export function loadUserProfileData() {
  return (dispatch, getState) => {
    const agencyID = selectAgency(getState());
    const url = generateUrl(GET_USER_PROFILE, {
      agency: agencyID || 0
    });
    callApi.get(url).then(
      response => {
        dispatch({ type: LOAD_USER_PROFILE_SUCCESS, userProfile: response });
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export const loadUserSettings = userId => {
  return (dispatch, getState) => {
    const state = getState();
    const agencyID = selectAgency(state);

    const url = generateUrl(GET_USER_SETTINGS, {
      agency: agencyID || 0,
      userId
    });

    return dispatch(apiCall(GET, url)).then(
      response => response,
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
};

export const openDialog = () => ({
  type: OPEN_USER_PROFILE_CONFIRMATION_DIALOG
});

export const closeDialog = () => ({
  type: CLOSE_USER_PROFILE_CONFIRMATION_DIALOG
});

export const saveUserSettings = ({ id, settings }) => {
  return (dispatch, getState) => {
    const state = getState();
    const agencyID = selectAgency(state);

    const url = generateUrl(SAVE_USER_SETTINGS, {
      agency: agencyID || 0,
      userId: id
    });

    return dispatch(apiCall(PUT, url, { settings })).then(
      response => response,
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
};
