import { styled, Accordion as MuiAccordion } from "@mui/material";
import { dodgerBlue } from "../../../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../../../functions/util";

const PREFIX = "ActionOrderItemStyle";
export const classes = {
  expansionPanel: `${PREFIX}-expansionPanel`,
  selected: `${PREFIX}-selected`,
  summaryOrderPanel: `${PREFIX}-summaryOrderPanel`,
  expensionDetailsTablePanel: `${PREFIX}-expensionDetailsTablePanel`
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`&.${classes.expansionPanel}`]: {
    marginBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`&.${classes.selected}`]: {
    borderColor: dodgerBlue
  },
  [`& .${classes.summaryOrderPanel}`]: {
    height: theme.typography.pxToRem(48)
  },
  [`& .${classes.expensionDetailsTablePanel}`]: {
    paddingTop: theme.typography.pxToRem(0),
    paddingBottom: theme.typography.pxToRem(0)
  }
}));
