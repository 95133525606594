import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, DialogContent, DialogActions } from "@mui/material";

import DialogTitle from "../../../../shared/dialog/DialogTitle";
import Scrollbar from "../../../../shared/Scrollbar";
import OrderBuyingStatusesTable from "../../table/OrderBuyingStatusesTable";
import { selectOrderBuyingStatusesItems } from "../../../../../business/selectors/campaignDashboardSelector";
import {
  loadOrderBuyingStatuses,
  closeOrderBuyingStatusesDialog
} from "../../../../../business/actions/campaignDashboardActions";
import { classes, Dialog } from "../../styles/orderBuyingStatusesDialogStyle";

const OrderBuyingStatusesDialog = () => {
  const dispatch = useDispatch();
  const items = useSelector(selectOrderBuyingStatusesItems);

  useEffect(() => {
    dispatch(loadOrderBuyingStatuses());
  }, [dispatch]);

  const handleClose = () => dispatch(closeOrderBuyingStatusesDialog());

  return (
    <Dialog aria-labelledby="remark-dialog-title" open maxWidth="md" fullWidth>
      <DialogTitle id="remark-dialog-title">Order Buying Statuses</DialogTitle>
      <DialogContent>
        <Scrollbar>
          <OrderBuyingStatusesTable rows={items} />
        </Scrollbar>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button onClick={handleClose} className={classes.closeButton}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default OrderBuyingStatusesDialog;
