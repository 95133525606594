import React, { Component } from "react";
import PropTypes from "prop-types";
import { MenuItem, OutlinedInput } from "@mui/material";

import { connect } from "react-redux";
import { classes, Select } from "./styles/TrafficStatusDialogSelect";

import {
  getTrafficItemPropertyOptions,
  trafficItemsHasNonApprovedOrders
} from "../../business/models/TrafficItem/trafficItemSelectors";
import { selectUserIsAdmin } from "../../business/selectors/userProfileSelectors";

class TrafficStatusDialogSelect extends Component {
  handleChange = event => {
    event.stopPropagation();
    const { onChange } = this.props;
    onChange(event.target.value);
  };

  handleClick = event => {
    event.stopPropagation();
  };

  render() {
    const { options, value, hasNonApprovedOrders, isAdmin } = this.props;

    const displayOptions = [];

    options.forEach(option => {
      const disabled =
        !isAdmin && hasNonApprovedOrders && option.text === "Ready for Traffic";

      displayOptions.push({ key: option.key, text: option.text, disabled });
    });

    return (
      <Select
        id="select_traffic_status_dialog"
        name="select_traffic_status_dialog"
        value={value}
        classes={{
          icon: classes.arrowDropDownIcon,
          select: classes.outlinedInput
        }}
        className={classes.dialogSelect}
        onChange={this.handleChange}
        onClick={this.handleClick}
        input={
          <OutlinedInput
            name="traffic-outlined-input"
            id="outlined-age-simple"
          />
        }
      >
        <MenuItem value={0} disabled>
          Choose new status
        </MenuItem>
        {displayOptions.map(({ key, text, disabled }) => (
          <MenuItem key={key} value={key} disabled={disabled}>
            {text}
          </MenuItem>
        ))}
      </Select>
    );
  }
}

TrafficStatusDialogSelect.propTypes = {
  name: PropTypes.string.isRequired
};

TrafficStatusDialogSelect.defaultProps = {
  options: [],
  value: 0
};

const mapStateToProps = (state, ownProps) => ({
  options: getTrafficItemPropertyOptions(state, ownProps),
  hasNonApprovedOrders: trafficItemsHasNonApprovedOrders(state),
  isAdmin: selectUserIsAdmin(state)
});

export default connect(mapStateToProps, null)(TrafficStatusDialogSelect);
