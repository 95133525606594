export const defaultOptions = Object.freeze([
  { id: 1, property: "campaignid", text: "By Campaign ID" },
  { id: 2, property: "campaignname", text: "By Campaign Name" },
  { id: 3, property: "planid", text: "By Plan ID" },
  { id: 4, property: "planname", text: "By Plan Name" },
  { id: 5, property: "orderid", text: "By Order ID" },
  { id: 6, property: "debtorname", text: "By Debtor" },
  { id: 7, property: "processmanager", text: "By Process Manager" }
]);

export const defaultOptionsForOverview = Object.freeze([
  { id: 1, property: "campaignid", text: "By Campaign ID" },
  { id: 2, property: "campaignname", text: "By Campaign Name" },
  { id: 3, property: "planid", text: "By Plan ID" },
  { id: 4, property: "planname", text: "By Plan Name" },
  { id: 5, property: "orderid", text: "By Order ID" },
  { id: 6, property: "debtorname", text: "By Debtor Name" },
  { id: 7, property: "debtorid", text: "By Debtor ID" },
  { id: 8, property: "processmanager", text: "By Process Manager" }
]);

export const defaultOptionsForTrafficOverview = Object.freeze([
  { id: 1, property: "campaignid", text: "By Campaign ID" },
  { id: 2, property: "campaignname", text: "By Campaign Name" },
  { id: 3, property: "planid", text: "By Plan ID" },
  { id: 4, property: "planname", text: "By Plan Name" },
  { id: 5, property: "orderid", text: "By Order ID" },
  { id: 6, property: "debtorname", text: "By Debtor" },
  { id: 7, property: "processmanager", text: "By Process Manager" },
  { id: 8, property: "trafficker", text: "By Trafficker" }
]);

export const defaultOptionsForCoppyPlan = Object.freeze([
  { id: 1, property: "campaignid", text: "By Campaign ID" },
  { id: 2, property: "campaignname", text: "By Campaign Name" }
]);

export const defaultOptionsForAdditionalApproval = Object.freeze([
  { id: 1, property: "planid", text: "By Plan ID" },
  { id: 2, property: "orderid", text: "By Order ID" },
  { id: 3, property: "debtorname", text: "By Debtor Name" }
]);

export const defaultOptionsForTrafficTemplate = Object.freeze([
  { id: 1, property: "templateName", text: "By Template Name" },
  { id: 2, property: "remarks", text: "By Remarks" }
]);

export const defaultOptionsForApiManagement = Object.freeze([
  { id: 1, property: "applicationId", text: "By Application ID" },
  { id: 2, property: "resource", text: "By Resource" }
]);
