import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import clsx from "clsx";
import NumberFormat from "react-number-format";

import { Typography } from "@mui/material";

import CtcButtonPlan from "../../shared/ctc/CtcButtonPlan";
import { isNullOrUndefined } from "../../../functions/util";
import { PLACEHOLDER, TYPE_ICON } from "../../../configurations/appConstants";
import { getPlanCalculationData } from "../../../business/models/Plan/planSelectors";
import { classes, Grid } from "../styles/planPanelCalculations";

const PlanPanelCalculations = ({
  id,
  totalBudget,
  currencySupplierSymbol,
  currencySupplier
}) => {
  const calculations = useSelector(state => getPlanCalculationData(state, id));

  const {
    clientTotalCost,
    clientTotalCostEur,
    orderAmountPercentage,
    orderTotalAmount
  } = calculations;

  const { totalCostClient } = clientTotalCost || {};
  const { totalCostClient: totalCostClientEur } = clientTotalCostEur || {};
  return (
    <Grid container>
      <Grid item xs={11}>
        <Typography
          align="right"
          className={clsx(classes.typographyValue, classes.typographyMarked)}
        >
          <NumberFormat
            id="totalBudget"
            name="totalBudget"
            value={totalBudget}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix={`${currencySupplierSymbol} `}
            fixedDecimalScale
            decimalScale={2}
          />
        </Typography>
        <Typography align="right" className={classes.typographyCaptionBlue}>
          {!isNullOrUndefined(totalCostClient) ||
          !isNullOrUndefined(totalCostClientEur) ? (
            <NumberFormat
              id="totalCostClient"
              name="totalCostClient"
              value={totalCostClient || totalCostClientEur}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              prefix={`${currencySupplierSymbol} `}
              fixedDecimalScale
              decimalScale={2}
            />
          ) : (
            PLACEHOLDER
          )}{" "}
          {!isNullOrUndefined(orderAmountPercentage) &&
          orderTotalAmount >= 0 ? (
            <NumberFormat
              id="orderAmountPercentage"
              name="orderAmountPercentage"
              value={orderAmountPercentage}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              prefix="("
              suffix="%)"
              decimalScale={2}
              fixedDecimalScale
            />
          ) : (
            PLACEHOLDER
          )}
        </Typography>
      </Grid>
      <Grid item xs={1} className={classes.ctcInfoGridItemAlign}>
        <CtcButtonPlan
          id={`p_view_ctc_${id}`}
          name={`p_view_ctc_${id}`}
          clientTotalCostEur={clientTotalCostEur}
          clientTotalCost={clientTotalCost}
          currencySupplier={currencySupplier}
          type={TYPE_ICON}
          showCtc={false}
        />
      </Grid>
    </Grid>
  );
};

PlanPanelCalculations.propTypes = {
  id: PropTypes.number.isRequired,
  totalBudget: PropTypes.number,
  currencySupplierSymbol: PropTypes.string,
  currencySupplier: PropTypes.string
};

export default PlanPanelCalculations;
