import { styled, Box as MuiBox } from "@mui/material";
import { solitude, lightBlue } from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "OrderStatusEditActionsStyles";
export const classes = {
  headerStyle: `${PREFIX}-headerStyle`,
  paddingBottom: `${PREFIX}-paddingBottom`
};

export const Box = styled(MuiBox)(({ theme }) => ({
  [`& .${classes.paddingBottom}`]: {
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.headerStyle}`]: {
    backgroundColor: lightBlue,
    paddingLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1.3)),
    border: `${theme.typography.pxToRem(
      getThemeSpacing(theme, 0.2)
    )} solid ${solitude}`
  }
}));
