import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@mui/material";

import { toggleOrderConfirmPopup } from "../../../../business/actions/applicationActions";
import {
  clearOrderToDelete,
  deleteOrder
} from "../../../../business/models/Order/orderActions";

const paperProps = { square: true };

const ConfirmOrderDeleteDialog = () => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteOrder());
    dispatch(toggleOrderConfirmPopup());
  };

  const handleCancel = () => {
    dispatch(clearOrderToDelete());
    dispatch(toggleOrderConfirmPopup());
  };

  return (
    <Dialog open PaperProps={paperProps} maxWidth="md">
      <DialogContent>
        <DialogContentText noWrap={false}>
          Are you sure you want to delete order?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary">
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmOrderDeleteDialog;
