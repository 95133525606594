import React from "react";
import Field from "../../../../../form/TrafficItemField";
import { classes, Grid } from "../../../../styles/multipleEdit";

const FoldRadio = ({
  editMode,
  areTrafficAgentFieldsDisabled,
  isDebtorActive
}) => {
  return (
    <Grid container justifyContent="flex-start">
      <Grid item className={classes.gridItem}>
        <Field
          component="date"
          id="trafficItemStartDate"
          name="trafficItemStartDate"
          helperText="Start Date"
          label="Start Date"
          variant="standard"
          hideIcon={!editMode}
          disabled={!editMode || !isDebtorActive}
          isLabelHighlighted
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="date"
          id="trafficItemEndDate"
          name="trafficItemEndDate"
          helperText="End Date"
          label="End Date"
          hideIcon={!editMode}
          variant="standard"
          disabled={!editMode || !isDebtorActive}
          isLabelHighlighted
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="spotLength"
          name="spotLength"
          helperText="Spotlength"
          label="Spotlength *"
          variant="standard"
          disabled={!editMode || !isDebtorActive}
          isLabelHighlighted
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="spotName"
          name="spotName"
          helperText="SpotName"
          label="SpotName"
          disabled={!editMode || !isDebtorActive}
          variant="standard"
          isLabelHighlighted
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="material"
          name="material"
          helperText="Material"
          label="Material *"
          disabled={!editMode || !isDebtorActive}
          variant="standard"
          isLabelHighlighted
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="date"
          id="deliveryDate"
          name="deliveryDate"
          helperText="Delivery Date"
          label="Delivery Date"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          hideIcon={!editMode}
          variant="standard"
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="phoneNumber"
          name="phoneNumber"
          helperText="Phone Number"
          label="Phone Number"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid item className={classes.gridItem} xs={12}>
        <Field
          component="text"
          id="trafficItemRemarks"
          name="trafficItemRemarks"
          helperText="Remarks"
          label="Remarks"
          variant="standard"
          rowsMax="6"
          multiline
          isLabelHighlighted
          disabled={!isDebtorActive}
        />
      </Grid>
    </Grid>
  );
};

export default FoldRadio;
