import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@mui/material";

import {
  selectOpenAddAllTrafficItemsDialog,
  selectIsLoading
} from "../../../../business/selectors/applicationSelectors";
import { addAllTrafficItems } from "../../../../business/models/TrafficItem/trafficItemActions";
import { closeAddAllTrafficItemsDialog } from "../../../../business/actions/applicationActions";

const paperProps = { square: true };

const AddAllTrafficItemsDialog = ({
  addItems,
  closeDialog,
  isLoading,
  isOpen
}) => {
  const handleAddAll = () => {
    addItems().then(isSuccessful => {
      if (isSuccessful) {
        closeDialog();
      }
    });
  };

  return (
    <Dialog open={isOpen} PaperProps={paperProps} maxWidth="sm" fullWidth>
      <DialogContent>
        <DialogContentText noWrap={false}>
          {isLoading
            ? "Please wait..."
            : "Are you sure you want to add all missing Traffic Items for selected Traffic Plan?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel_all-traffic-items"
          name="cancel_all-traffic-items"
          onClick={closeDialog}
          disabled={isLoading}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          id="add_all_traffic_items"
          name="add_all_traffic_items"
          onClick={handleAddAll}
          disabled={isLoading}
          color="secondary"
        >
          ADD ALL
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  isLoading: selectIsLoading(state),
  isOpen: selectOpenAddAllTrafficItemsDialog(state)
});

const mapDispatchToProps = dispatch => ({
  addItems: () => dispatch(addAllTrafficItems()),
  closeDialog: () => dispatch(closeAddAllTrafficItemsDialog())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAllTrafficItemsDialog);
