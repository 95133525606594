import React, { useEffect, useCallback, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { Grid } from "@mui/material";
import CampaignPanel from "../../panels/CampaignPanel";
import PlanPanel from "../../panels/PlanPanel";
import OrderPanel from "../../panels/OrderPanel";
import ProjectDialog from "./project/ProjectDialog";
import DivideOrdersDialog from "./popup/DivideOrdersDialog";
import CampaignForm from "./forms/CampaignForm";
import PlanForm from "./forms/PlanForm";
import PlanExtensionForm from "./forms/PlanExtensionForm";
import OrderForm from "./forms/OrderForm";

import {
  parseIntOrDefault,
  parseIntOrString
} from "../../../business/models/common/utils/clientUtils";

import {
  clearCreatePage,
  openCampaignFormPage,
  closePlanFormPage,
  closeOrderFormPage,
  closeCampaignFormPage,
  closePlanExtensionFormPage,
  closePlanExportDialog
} from "../../../business/actions/campaignDashboardActions";
import {
  loadEntityData,
  clearEntityData,
  clearEditingEntityData
} from "../../../business/models/common/data/dataActions";
import {
  addNewCampaign,
  clearCurrentCampaign,
  selectCampaign
} from "../../../business/models/Campaign/campaignActions";
import { clearCurrentPlanExtension } from "../../../business/models/PlanExtension/planExtensionActions";
import {
  clearCurrentPlan,
  selectPlan,
  addNewPlan,
  editPlan
} from "../../../business/models/Plan/planActions";
import {
  clearCurrentOrder,
  selectOrder,
  addNewOrder
} from "../../../business/models/Order/orderActions";
import {
  clearCurrentInsertions,
  stopEditingInsertions
} from "../../../business/models/Insertion/insertionActions";
import { clearCurrentProject } from "../../../business/models/Project/projectActions";

import {
  selectIsCampaignFormOpen,
  selectIsPlanFormOpen,
  selectIsPlanExtensionFormOpen,
  selectIsOrderFormOpen,
  selectOpenClientStatusLogDialog,
  selectIsProjectDialogOpen,
  selectIsOrderBuyingStatusesDialogOpen,
  selectIsSetActualDialogOpen,
  selectIsSetCampaignActualDialogOpen,
  selectIsPlanExportDialogOpen,
  selectPlansWithInsertionChange
} from "../../../business/selectors/campaignDashboardSelector";
import {
  selectOpenMbsConfirmPopup,
  selectOpenOrderStatusChangeDialog,
  selectOpenDivideOrdersDialog,
  selectOpenOrderConfirmPopup
} from "../../../business/selectors/applicationSelectors";
import {
  selectCurrentCampaign,
  doesCampaignExist
} from "../../../business/models/Campaign/campaignSelectors";
import {
  selectCurrentPlan,
  doesPlanExist
} from "../../../business/models/Plan/planSelectors";
import {
  doesOrderExist,
  selectOrderIsNew
} from "../../../business/models/Order/orderSelectors";

import ClientStatusLogDialog from "./popup/ClientStatusLogDialog";
import ConfirmSendToMbsDialog from "./popup/ConfirmSendToMbsDialog";
import ConfirmSetActualDialog from "./popup/ConfirmSetActualDialog";
import ConfirmCampaignSetActualDialog from "./popup/ConfirmCampaignSetActualDialog";
import ChangeOrderStatusDialog from "./popup/order-status/ChangeOrderStatusDialog";
import OrderBuyingStatusesDialog from "./popup/order-status/OrderBuyingStatusesDialog";
import ConfirmOrderDeleteDialog from "./popup/ConfirmOrderDeleteDialog";
import PlanExportDialog from "./popup/PlanExportDialog";
import ConfirmInsertionChangeDialog from "./popup/ConfirmInsertionChangeDialog";

const CampaignPage = () => {
  const dispatch = useDispatch();
  const { campaignId, planId, orderId } = useParams();

  const urlCampaignId = parseIntOrDefault(campaignId);
  const urlPlanId = parseIntOrString(planId);
  const urlOrderId = parseIntOrString(orderId);

  const orderIsNew = useSelector(selectOrderIsNew);
  const isCampaignFormOpen = useSelector(selectIsCampaignFormOpen);
  const isPlanFormOpen = useSelector(selectIsPlanFormOpen);
  const isPlanExtensionFormOpen = useSelector(selectIsPlanExtensionFormOpen);
  const isOrderFormOpen = useSelector(selectIsOrderFormOpen);
  const isProjectDialogOpen = useSelector(selectIsProjectDialogOpen);
  const isPlanExportDialogOpen = useSelector(selectIsPlanExportDialogOpen);
  const currentCampaign = useSelector(selectCurrentCampaign);
  const currentPlan = useSelector(selectCurrentPlan);
  const campaignExist = useSelector(state =>
    doesCampaignExist(state, { id: urlCampaignId })
  );
  const planExists = useSelector(state => doesPlanExist(state, urlPlanId));
  const orderExists = useSelector(state =>
    doesOrderExist(state, {
      planId: urlPlanId,
      orderId: urlOrderId
    })
  );
  const isClientStatusLogDialogOpen = useSelector(
    selectOpenClientStatusLogDialog
  );
  const isMbsConfirmDialogOpen = useSelector(selectOpenMbsConfirmPopup);
  const isOrderStatusChangeDialogOpen = useSelector(
    selectOpenOrderStatusChangeDialog
  );
  const isOrderBuyingStatusesDialogOpen = useSelector(
    selectIsOrderBuyingStatusesDialogOpen
  );
  const isConfirmActualDialogOpen = useSelector(selectIsSetActualDialogOpen);
  const isConfirmCampaignActualDialogOpen = useSelector(
    selectIsSetCampaignActualDialogOpen
  );
  const isDivideOrdersDialogOpen = useSelector(selectOpenDivideOrdersDialog);
  const isOrderDeleteConfirmDialogOpen = useSelector(
    selectOpenOrderConfirmPopup
  );
  const plansWithInsertionChanges = useSelector(selectPlansWithInsertionChange);

  const clear = useCallback(() => {
    // CLEAR CREATE PAGE REDUX STATE
    dispatch(clearCreatePage());
    dispatch(clearEditingEntityData());
    // CLEAR CURRENT
    dispatch(clearCurrentCampaign());
    dispatch(clearCurrentPlan());
    dispatch(clearCurrentPlanExtension());
    dispatch(clearCurrentOrder());
    dispatch(clearCurrentInsertions());
    dispatch(clearCurrentProject());
  }, [dispatch]);

  useEffect(() => {
    const onBackButtonEvent = e => {
      e.preventDefault();

      dispatch(closePlanFormPage());
      dispatch(closePlanExtensionFormPage());
      dispatch(closeOrderFormPage());
      dispatch(closeCampaignFormPage());
      dispatch(stopEditingInsertions());
      dispatch(closePlanExportDialog());
    };

    window.onpopstate = onBackButtonEvent;

    dispatch(clearEntityData());
    return () => {
      clear();
    };
  }, [clear, dispatch]);

  useEffect(() => {
    if (!urlCampaignId) {
      dispatch(addNewCampaign());
      dispatch(openCampaignFormPage());
    }
    if (urlCampaignId) {
      dispatch(loadEntityData(urlCampaignId));
    }
  }, [dispatch, urlCampaignId]);

  useEffect(() => {
    if (!isPlanFormOpen) {
      return;
    }
    if (planExists) {
      dispatch(editPlan());
    } else {
      dispatch(addNewPlan());
    }
  }, [dispatch, isPlanFormOpen, planExists]);

  useEffect(() => {
    if (!isOrderFormOpen) {
      return;
    }
    if (!urlOrderId && !orderIsNew) {
      dispatch(addNewOrder());
    }
  }, [dispatch, isOrderFormOpen, urlOrderId, orderIsNew]);

  useEffect(() => {
    if (
      isCampaignFormOpen ||
      isPlanFormOpen ||
      isPlanExtensionFormOpen ||
      isOrderFormOpen
    ) {
      return;
    }

    if (campaignExist && urlCampaignId !== currentCampaign) {
      dispatch(clearCurrentOrder());
      dispatch(clearCurrentPlanExtension());
      dispatch(clearCurrentPlan());
      dispatch(selectCampaign(urlCampaignId));
    }

    if (planExists && urlPlanId) {
      dispatch(clearCurrentOrder());
      dispatch(clearCurrentPlanExtension());
      dispatch(selectPlan(urlPlanId));
    }
    if (orderExists && urlOrderId) {
      dispatch(selectOrder(urlOrderId));
    }

    if (!urlPlanId) {
      dispatch(clearCurrentPlanExtension());
      dispatch(clearCurrentPlan());
    }
    if (!urlOrderId) dispatch(clearCurrentOrder());
  }, [
    currentCampaign,
    dispatch,
    campaignExist,
    planExists,
    orderExists,
    urlCampaignId,
    urlOrderId,
    urlPlanId,
    isCampaignFormOpen,
    isPlanFormOpen,
    isPlanExtensionFormOpen,
    isOrderFormOpen
  ]);

  return (
    <>
      {!isCampaignFormOpen &&
        !isPlanFormOpen &&
        !isPlanExtensionFormOpen &&
        !isOrderFormOpen && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CampaignPanel />
            </Grid>
            <Grid item xs={4}>
              {currentCampaign && <PlanPanel />}
            </Grid>
            <Grid item xs={8}>
              {currentPlan && <OrderPanel />}
            </Grid>
          </Grid>
        )}
      {isCampaignFormOpen && <CampaignForm />}
      {isPlanFormOpen && <PlanForm />}
      {isPlanExtensionFormOpen && <PlanExtensionForm />}
      {isOrderFormOpen && <OrderForm />}
      {isProjectDialogOpen && <ProjectDialog />}
      {isDivideOrdersDialogOpen && <DivideOrdersDialog />}
      {isClientStatusLogDialogOpen && <ClientStatusLogDialog />}
      {isMbsConfirmDialogOpen && <ConfirmSendToMbsDialog />}
      {isConfirmActualDialogOpen && <ConfirmSetActualDialog />}
      {isConfirmCampaignActualDialogOpen && <ConfirmCampaignSetActualDialog />}
      {isOrderStatusChangeDialogOpen && <ChangeOrderStatusDialog />}
      {isOrderBuyingStatusesDialogOpen && <OrderBuyingStatusesDialog />}
      {isOrderDeleteConfirmDialogOpen && <ConfirmOrderDeleteDialog />}
      {isPlanExportDialogOpen && <PlanExportDialog />}
      {plansWithInsertionChanges.indexOf(urlPlanId) >= 0 && (
        <ConfirmInsertionChangeDialog />
      )}
    </>
  );
};

export default CampaignPage;
