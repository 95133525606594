import React from "react";
import { useSelector } from "react-redux";

import Scrollbar from "../shared/Scrollbar";
import OrderPanelItem from "./extensions/OrderPanelItem";

import { getOrders } from "../../business/models/Order/orderSelectors";
import OrderStatusEditActions from "../pages/create/actions/OrderStatusEditActions";

const OrderPanel = () => {
  const orders = useSelector(getOrders);
  return (
    <Scrollbar>
      {orders.length > 0 && <OrderStatusEditActions></OrderStatusEditActions>}
      {orders.map((order, key) => (
        <OrderPanelItem key={key} order={order} allowAddInsertion />
      ))}
    </Scrollbar>
  );
};

export default OrderPanel;
