import React from "react";

import NumberFormat from "react-number-format";

import { Typography } from "@mui/material";

import {
  PLACEHOLDER,
  EMPTY_STRING
} from "../../../configurations/appConstants";

import { classes, Grid } from "../styles/trafficLine";

const TrafficLineTooltipText = ({
  marathonOrderId = 0,
  costType = EMPTY_STRING,
  orderNumOfUnits = 0,
  netTotal = 0.0,
  netPerUnit = 0.0
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item>
        <Grid>
          <Typography className={classes.ctcProperty} variant="body1">
            Marathon Order Id :&nbsp;&nbsp;
          </Typography>
          {(
            <NumberFormat
              value={marathonOrderId}
              displayType="text"
              decimalSeparator=","
              fixedDecimalScale
            />
          ) || PLACEHOLDER}
        </Grid>
        <Grid>
          <Typography className={classes.ctcProperty} variant="body1">
            Cost type:&nbsp;&nbsp; {costType}
          </Typography>
        </Grid>
        <Grid>
          <Typography className={classes.ctcProperty} variant="body1">
            Number of Units:&nbsp;&nbsp;
          </Typography>
          {(
            <NumberFormat
              value={orderNumOfUnits}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              fixedDecimalScale
              decimalScale={2}
            />
          ) || PLACEHOLDER}
        </Grid>
        <Grid>
          <Typography className={classes.ctcProperty} variant="body1">
            Net Total:&nbsp;&nbsp;
          </Typography>
          {(
            <NumberFormat
              value={netTotal}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
            />
          ) || PLACEHOLDER}
        </Grid>
        <Grid>
          <Typography className={classes.ctcProperty} variant="body1">
            Net per Unit:&nbsp;&nbsp;
          </Typography>
          {(
            <NumberFormat
              value={netPerUnit}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
            />
          ) || PLACEHOLDER}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrafficLineTooltipText;
