export const TRAFFIC_ITEM_MODEL_NAME = "TrafficItem";
export const TRAFFIC_ITEM_MODEL_ID = "trafficItemId";
export const TRAFFIC_ITEM_MEDIA_TYPE_ID = "mediaTypeId";
export const TRAFFIC_ITEM_STATUS_ID = "trafficItemStatusId";

export const TRAFFIC_ITEMS_PROPERTY = "trafficItems";

export const TRAFFIC_ITEM_EDIT_START = "TRAFFIC_ITEM_EDIT_START";
export const TRAFFIC_ITEM_COPY_START = "TRAFFIC_ITEM_COPY_START";
export const TRAFFIC_ITEM_SELECT = "TRAFFIC_ITEM_SELECT";
export const TRAFFIC_ITEM_EDIT_STOP = "TRAFFIC_ITEM_EDIT_STOP";
export const TRAFFIC_ITEM_COPY_STOP = "TRAFFIC_ITEM_COPY_STOP";
export const TRAFFIC_ITEM_DISPLAY_ERRORS = "TRAFFIC_ITEM_DISPLAY_ERRORS";
export const TRAFFIC_ITEM_HIDE_ERRORS = "TRAFFIC_ITEM_HIDE_ERRORS";
export const TRAFFIC_ITEM_CLEAR_CURRENT = "TRAFFIC_ITEM_CLEAR_CURRENT";
export const TRAFFIC_ITEM_SET_IS_NEW = "TRAFFIC_ITEM_SET_IS_NEW";
export const TRAFFIC_ITEM_SET_DIGITAL_TEMPLATE =
  "TRAFFIC_ITEM_SET_DIGITAL_TEMPLATE";
export const TRAFFIC_ITEM_RESET_DIGITAL_TEMPLATE =
  "TRAFFIC_ITEM_RESET_DIGITAL_TEMPLATE";

export const TRAFFIC_ITEM_SUCCESS_MESSAGE =
  "Traffic Item is saved successfully";
export const TRAFFIC_ITEM_STATUS_SUCCESS_MESSAGE =
  "Status is changed successfully";

export const TRAFFIC_ITEMS_SUCCESS_MESSAGE =
  "Traffic Item is saved successfully";
export const TRAFFIC_ITEMS_ALL_SUCCESS_MESSAGE =
  "Traffic Items are saved successfully";
export const TRAFFIC_ITEMS_ALREADY_CREATED_MESSAGE =
  "Traffic plan is already up to date";

export const TRAFFIC_ITEMS_FAIL_MESSAGE =
  "something went wrong, could not update all traffic items";

export const TRAFFIC_ITEMS_INVALID_TRANSITIONS =
  "Please note that Cancelled status can only change to Finalized and Finalized status with cancelled order can not be changed. Status change failed for traffic item(s)";
