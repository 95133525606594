import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton
} from "@mui/material";

import { EMPTY_TABLE } from "../../../../../configurations/appConstants";
import { EditIcon } from "../../../../../assets/styles/icons";
import { classes, Table } from "../../styles/projectTable";
import { trimString } from "../../../../../business/models/common/utils/clientUtils";

const ProjectTableBody = ({
  onEdit,
  projects,
  onClickItem,
  currentProject
}) => {
  return (
    <Table className={classes.tableBody}>
      <TableBody>
        {!isEmpty(projects) ? (
          projects.map(project => (
            <TableRow
              key={project.id}
              onClick={() => onClickItem(project.id)}
              hover
              selected={currentProject === project.id}
            >
              <TableCell className={classes.cellBody}>
                <Typography noWrap>
                  {trimString(project.projectName, 58)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cellBody}>
                <Typography noWrap>
                  {trimString(project.projectLeaderId, 58)}
                </Typography>
              </TableCell>
              <TableCell className={clsx(classes.cellBody, classes.cellCustom)}>
                <Typography noWrap>{project.marathonProjectId}</Typography>
              </TableCell>
              <TableCell align="right" className={classes.cellAction}>
                <IconButton
                  onClick={event => onEdit(event, project.id)}
                  className={classes.buttonIcon}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell align="center">
              <Typography className={classes.colorLinkWater}>
                {EMPTY_TABLE}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

ProjectTableBody.propTypes = {
  projects: PropTypes.array,
  onEdit: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  currentProject: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

ProjectTableBody.defaultProps = {
  projects: []
};

export default ProjectTableBody;
