import { styled, Card as MuiCard } from "@mui/material";
import { darkIndigo, azureRadiance } from "../../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../../functions/util";

const PREFIX = "RulePanelStyles";
export const classes = {
  typography: `${PREFIX}-typography`,
  autoComplete: `${PREFIX}-autoComplete`,
  divider: `${PREFIX}-divider`,
  activeTypography: `${PREFIX}-activeTypography`
};

export const Card = styled(MuiCard)(({ theme }) => ({
  [`& .${classes.typography}`]: {
    textAlign: "flex-start",
    color: darkIndigo
  },
  [`& .${classes.activeTypography}`]: {
    textAlign: "flex-start",
    color: azureRadiance
  },
  [`& .${classes.autoComplete}`]: {
    width: theme.typography.pxToRem(400)
  },
  [`& .${classes.divider}`]: {
    height: getThemeSpacing(theme, 0.1),
    width: getThemeSpacing(theme, 30)
  }
}));
