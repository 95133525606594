import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";

import { Button } from "@mui/material";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import {
  stopEditingCampaign,
  createCampaign
} from "../../../../business/models/Campaign/campaignActions";
import {
  closeCampaignFormPage,
  openPlanFormPage
} from "../../../../business/actions/campaignDashboardActions";
import { canEditCampaign } from "../../../../business/selectors/userProfileSelectors";

import { CREATE_PAGE, CAMPAIGN } from "../../../../configurations/appConstants";

import { classes, Grid } from "../styles/campaignNewActions";

const CampaignNewActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agency } = useParams();
  const isLoading = useSelector(selectIsLoading);
  const disableAction = !useSelector(canEditCampaign);

  const handleCancel = () => {
    dispatch(stopEditingCampaign());
    navigate(-1);
  };

  const handleSave = () => {
    dispatch(createCampaign()).then(id => {
      if (id) {
        navigate(`/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${id}`);
        dispatch(closeCampaignFormPage());
      }
    });
  };

  const handleSaveAndAddPlan = () => {
    dispatch(createCampaign()).then(id => {
      if (id) {
        dispatch(closeCampaignFormPage());
        dispatch(openPlanFormPage());
      }
    });
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Button
          id="c_cancel"
          name="c_cancel"
          className={classes.cancelButton}
          onClick={handleCancel}
          disabled={isLoading || disableAction}
        >
          CANCEL
        </Button>
      </Grid>
      <Grid item>
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              id="c_create"
              name="c_create"
              variant="contained"
              className={clsx(classes.saveButton, classes.markedButton)}
              disabled={isLoading || disableAction}
              onClick={handleSave}
            >
              SAVE
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="c_addPlan"
              name="c_addPlan"
              variant="contained"
              className={classes.saveButton}
              onClick={handleSaveAndAddPlan}
              disabled={disableAction}
            >
              SAVE AND ADD PLAN
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CampaignNewActions;
