import { createReducer } from "../models/common/utils/reducerUtils";
import {
  OPEN_ADMANAGEMENT_DIALOG,
  CLOSE_ADMANAGEMENT_DIALOG
} from "../constants/trafficDashboardPageConstants";

const initialState = {
  openAdmanagementDialog: false
};

export const openAdmanagement = state => ({
  ...state,
  openAdmanagementDialog: true
});

export const closeAdmanagement = state => ({
  ...state,
  openAdmanagementDialog: false
});

export default createReducer(initialState, {
  [OPEN_ADMANAGEMENT_DIALOG]: openAdmanagement,
  [CLOSE_ADMANAGEMENT_DIALOG]: closeAdmanagement
});
