import React from "react";
import PropTypes from "prop-types";

import { TableFooter, TableRow, TablePagination } from "@mui/material";

const ActionTableFooter = ({
  count,
  pageSize,
  currentPage,
  rowsPerPageOptions,
  onChangePage,
  onChangeRowsPerPage,
  PaginationActions,
  selectProps
}) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          colSpan={3}
          count={count}
          rowsPerPage={pageSize}
          page={count ? currentPage : 0}
          rowsPerPageOptions={rowsPerPageOptions}
          SelectProps={selectProps}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          ActionsComponent={PaginationActions}
        />
      </TableRow>
    </TableFooter>
  );
};

ActionTableFooter.propTypes = {
  count: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  PaginationActions: PropTypes.func.isRequired,
  selectProps: PropTypes.object
};

ActionTableFooter.defaultProps = {
  count: 0,
  pageSize: 10,
  currentPage: 0,
  rowsPerPageOptions: [5, 10, 25],
  selectProps: { native: true }
};

export default ActionTableFooter;
