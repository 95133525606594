import {
  LOAD_USER_AGENCIES_SUCCESS,
  LOAD_USER_PROFILE_SUCCESS,
  CLEAR_USER_PROFILE,
  OPEN_USER_PROFILE_CONFIRMATION_DIALOG,
  CLOSE_USER_PROFILE_CONFIRMATION_DIALOG
} from "../constants/userProfileConstants";

const initialState = {
  name: "",
  isAdmin: false,
  agencies: [],
  actions: [],
  openDialog: false
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.userProfile
      };
    case LOAD_USER_AGENCIES_SUCCESS:
      return {
        ...state,
        isAdmin: action.payload.isAdmin,
        agencies: action.payload.agencies
      };
    case OPEN_USER_PROFILE_CONFIRMATION_DIALOG:
      return {
        ...state,
        openDialog: true
      };
    case CLOSE_USER_PROFILE_CONFIRMATION_DIALOG:
      return {
        ...state,
        openDialog: false
      };
    case CLEAR_USER_PROFILE:
      return initialState;
    default:
      return state;
  }
};

export default userProfileReducer;
