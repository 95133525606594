import React, { Component } from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { selectErrors } from "../../business/selectors/applicationSelectors";
import { removeErrorMessage } from "../../business/actions/applicationActions";
import ErrorNotifierAction from "./snackbarActions/ErrorNotifierAction";

class ErrorNotifier extends Component {
  displayed = [];

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id];
  };

  shouldComponentUpdate({ errors: newSnacks = [] }) {
    const { errors: currentSnacks } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      // eslint-disable-next-line no-continue
      if (notExists) continue;
      notExists =
        notExists ||
        !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  }

  formatNotificationMessage = (msg = []) =>
    msg.constructor === Array
      ? msg
      : msg.split("\n").filter(entry => /\S/.test(entry));

  displaySnackbars = (msgs = []) =>
    msgs.forEach(msg =>
      this.props.enqueueSnackbar(msg, {
        variant: "error",
        action: <ErrorNotifierAction closeSnackbar={this.props.closeSnackbar} />
      })
    );

  componentDidUpdate() {
    const { errors = [] } = this.props;
    errors.forEach(error => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(error.key)) return;

      const errorMsgs = this.formatNotificationMessage(error.message);

      // Display snackbar using notistack
      this.displaySnackbars(errorMsgs);

      // Keep track of snackbars that we've displayed
      this.storeDisplayed(error.key);
      // Dispatch action to remove snackbar from redux store
      this.props.removeErrorMessage(error.key);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  errors: selectErrors(state)
});

const mapDispatchToProps = dispatch => ({
  removeErrorMessage: key => dispatch(removeErrorMessage(key))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ErrorNotifier));
