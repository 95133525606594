import React from "react";

import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

import {
  CardContent,
  Typography,
  IconButton,
  CardActionArea,
  CardHeader,
  Avatar,
  CardActions
} from "@mui/material";

import {
  DownloadIcon,
  DeleteSingleNotificationIcon
} from "../../../../assets/styles/icons";

import {
  NOTIFICATION_TYPE_REMARKS,
  NOTIFICATION_TYPE_SYSTEM,
  NOTIFICATION_TYPE_ORDERS_APPROVED,
  NOTIFICATION_TYPE_ORDERS_CHANGED,
  NOTIFICATION_TYPE_ORDERS_TO_BE_CANCELLED,
  NOTIFICATION_TYPE_ORDERS_PENDING_FOR_APPROVAL,
  EMPTY_STRING
} from "../../../../configurations/appConstants";
import { classes, Card } from "../styles/notificationCard";

const NotificationCard = ({
  title,
  message,
  timestamp,
  route,
  onCardClick,
  onDownloadClick,
  id,
  type,
  hasAttachment,
  systemMessageId,
  fileName,
  onDeleteNotification
}) => {
  const titleProps = { className: classes.typography };
  const subheaderProps = { className: classes.typography };
  return (
    <Card
      className={clsx(classes.card, {
        [classes.colorRemarks]:
          type === NOTIFICATION_TYPE_REMARKS ||
          type === NOTIFICATION_TYPE_ORDERS_APPROVED ||
          type === NOTIFICATION_TYPE_ORDERS_CHANGED ||
          type === NOTIFICATION_TYPE_ORDERS_TO_BE_CANCELLED ||
          type === NOTIFICATION_TYPE_ORDERS_PENDING_FOR_APPROVAL,
        [classes.colorSystemMessage]: type === NOTIFICATION_TYPE_SYSTEM
      })}
      elevation={5}
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label="Recipe"
            className={classes.colorSystemMessageAvatar}
          >
            {type === NOTIFICATION_TYPE_SYSTEM && "S"}
            {type === NOTIFICATION_TYPE_REMARKS && "R"}
            {type === NOTIFICATION_TYPE_ORDERS_APPROVED && "OA"}
            {type === NOTIFICATION_TYPE_ORDERS_CHANGED && "OC"}
            {type === NOTIFICATION_TYPE_ORDERS_TO_BE_CANCELLED && "OTBC"}
            {type === NOTIFICATION_TYPE_ORDERS_PENDING_FOR_APPROVAL && "PA"}
          </Avatar>
        }
        action={
          <IconButton
            onClick={() => onDeleteNotification(id)}
            disabled={type === NOTIFICATION_TYPE_SYSTEM}
          >
            <DeleteSingleNotificationIcon />
          </IconButton>
        }
        title={
          <Typography
            className={classes.typographyText}
            id={`titleMsg_${id}`}
            name={`titleMsg_${id}`}
          >
            {title}
          </Typography>
        }
        titleTypographyProps={
          <Typography
            className={classes.typography}
            id={`titlePropsMsg_${id}`}
            name={`titlePropsMsg_${id}`}
          >
            {titleProps}
          </Typography>
        }
        subheader={
          <Typography
            className={classes.typographyText}
            id={`timestampMsg_${id}`}
            name={`timestampMsg_${id}`}
          >
            {timestamp}
          </Typography>
        }
        subheaderTypographyProps={
          <Typography
            className={classes.typograph}
            id={`subheaderPropsMsg_${id}`}
            name={`subheaderPropsMsg_${id}`}
          >
            {subheaderProps}
          </Typography>
        }
      />
      <CardActionArea
        component={NavLink}
        to={location => {
          return type === NOTIFICATION_TYPE_REMARKS ||
            NOTIFICATION_TYPE_ORDERS_APPROVED ||
            NOTIFICATION_TYPE_ORDERS_CHANGED ||
            NOTIFICATION_TYPE_ORDERS_TO_BE_CANCELLED ||
            NOTIFICATION_TYPE_ORDERS_PENDING_FOR_APPROVAL
            ? route
            : location.pathname;
        }}
        onClick={() => onCardClick(id)}
        disabled={type === NOTIFICATION_TYPE_SYSTEM}
      >
        <CardContent>
          <Typography
            id={`message_${id}`}
            name={`message_${id}`}
            component="p"
            gutterBottom
            className={classes.typographyText}
          >
            {message}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {type === NOTIFICATION_TYPE_SYSTEM && hasAttachment && (
          <IconButton
            onClick={() => onDownloadClick(systemMessageId, fileName)}
          >
            <DownloadIcon />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

NotificationCard.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  timestamp: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  onCardClick: PropTypes.func,
  onDeleteNotification: PropTypes.func,
  onDownloadClick: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.oneOf([
    NOTIFICATION_TYPE_REMARKS,
    NOTIFICATION_TYPE_SYSTEM,
    NOTIFICATION_TYPE_ORDERS_APPROVED,
    NOTIFICATION_TYPE_ORDERS_CHANGED,
    NOTIFICATION_TYPE_ORDERS_TO_BE_CANCELLED,
    NOTIFICATION_TYPE_ORDERS_PENDING_FOR_APPROVAL
  ]).isRequired,
  hasAttachment: PropTypes.bool,
  fileName: PropTypes.string
};

NotificationCard.defaultProps = {
  title: EMPTY_STRING,
  message: EMPTY_STRING,
  timestamp: EMPTY_STRING,
  route: EMPTY_STRING,
  id: null,
  type: NOTIFICATION_TYPE_REMARKS,
  hasAttachment: false,
  fileName: EMPTY_STRING
};

export default NotificationCard;
