import { styled, Dialog as MuiDialog } from "@mui/material";
import {
  crusta,
  deYork,
  white,
  dodgerBlue
} from "../../../../../assets/styles/colors";

const PREFIX = "ActionDialogStyle";
export const classes = {
  closeButton: `${PREFIX}-closeButton`,
  doneButton: `${PREFIX}-doneButton`,
  approveSelectionButton: `${PREFIX}-approveSelectionButton`,
  declineSelectionButton: `${PREFIX}-declineSelectionButton`
};

export const Dialog = styled(MuiDialog)(() => ({
  [`& .${classes.closeButton}`]: {
    color: crusta
  },
  [`& .${classes.doneButton}`]: {
    backgroundColor: deYork,
    color: white
  },
  [`& .${classes.approveSelectionButton}`]: {
    backgroundColor: dodgerBlue,
    color: white
  },
  [`& .${classes.declineSelectionButton}`]: {
    backgroundColor: crusta,
    color: white
  }
}));
