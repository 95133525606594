import { Model, attr, fk } from "redux-orm";
import set from "lodash/set";

import {
  TRAFFIC_PLAN_REMARK_MODEL_NAME,
  TRAFFIC_PLAN_REMARK_MODEL_ID
} from "./trafficPlanRemarkConstants";

import {
  TRAFFIC_PLAN_MODEL_NAME,
  TRAFFIC_PLAN_MODEL_ID
} from "../TrafficPlan/trafficPlanConstants";

class TrafficPlanRemark extends Model {
  static get fields() {
    return {
      id: attr(),

      trafficPlanRemarkId: attr(),
      trafficPlanId: attr(),
      username: attr(),
      remark: attr(),
      statusId: attr(),
      modified: attr(),

      trafficPlan: fk(TRAFFIC_PLAN_MODEL_NAME)
    };
  }

  static get defaultAttributes() {
    return {};
  }

  static parse(trafficPlanRemarkData) {
    if (trafficPlanRemarkData[TRAFFIC_PLAN_REMARK_MODEL_ID]) {
      set(
        trafficPlanRemarkData,
        "id",
        trafficPlanRemarkData[TRAFFIC_PLAN_REMARK_MODEL_ID]
      );
    }
    if (trafficPlanRemarkData[TRAFFIC_PLAN_MODEL_ID]) {
      set(
        trafficPlanRemarkData,
        "trafficPlan",
        trafficPlanRemarkData[TRAFFIC_PLAN_MODEL_ID]
      );
    }

    return this.upsert(trafficPlanRemarkData);
  }

  static updateModel(trafficPlanRemarkData) {
    if (trafficPlanRemarkData[TRAFFIC_PLAN_REMARK_MODEL_ID]) {
      set(
        trafficPlanRemarkData,
        "id",
        trafficPlanRemarkData[TRAFFIC_PLAN_REMARK_MODEL_ID]
      );
    }
    if (trafficPlanRemarkData[TRAFFIC_PLAN_MODEL_ID]) {
      set(
        trafficPlanRemarkData,
        "trafficPlan",
        trafficPlanRemarkData[TRAFFIC_PLAN_MODEL_ID]
      );
    }

    return this.upsert(trafficPlanRemarkData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...TrafficPlanRemark.defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherTrafficPlanRemark) {
    this.update(otherTrafficPlanRemark.ref);
  }
}

TrafficPlanRemark.modelName = TRAFFIC_PLAN_REMARK_MODEL_NAME;

export default TrafficPlanRemark;
