import { combineReducers } from "redux";
import { reduceReducers } from "../models/common/utils/reducerUtils";

import userProfileReducer from "./userProfileReducer";
import applicationReducer from "./applicationReducer";
import { apiDataReducer, apiDataFlagReducer } from "./apiDataReducer";

import entitiesReducer from "../models/common/reducers/entitiesReducer";
import editingEntitiesReducer from "../models/common/reducers/editingEntitiesReducer";
import campaignReducer from "../models/Campaign/campaignReducer";
import planReducer from "../models/Plan/planReducer";
import planExtensionReducer from "../models/PlanExtension/planExtensionReducers";
import planTargetsMetricsKpiReducer from "../models/PlanTargetMetricsKpi/planTargetMetricsKpiReducers";
import planChangeOverviewReducer from "../models/PlanChangeOverview/planChangeOverviewReducers";
import projectReducer from "../models/Project/projectReducer";
import orderReducer from "../models/Order/orderReducer";
import insertionReducer from "../models/Insertion/insertionReducer";
import userReducer from "../models/User/userReducer";
import systemMessageReducer from "../models/SystemMessage/systemMessageReducer";
import campaignDashboardReducer from "./campaignDashboardReducer";
import actionPageReducer from "./actionPageReducer";
import overviewPageReducer from "./overviewPageReducer";
import trafficPageReducer from "./trafficPageReducer";
import trafficPlanReducer from "../models/TrafficPlan/trafficPlanReducer";
import trafficItemReducer from "../models/TrafficItem/trafficItemReducer";
import trafficPlanRemarkReducer from "../models/TrafficPlanRemark/trafficPlanRemarkReducer";
import trafficDashboardReducer from "./trafficDashboardPageReducer";
import notificationsReducer from "./notificationsReducer";
import approvalPageReducer from "./approvalPageReducer";
import copyPlanReducer from "./copyPlanReducer";
import apiManagementReducer from "./apiManagementReducer";

import trafficTemplateManagerReducer from "./trafficTemplateManagerReducer";
import trafficTemplateReducer from "../models/TrafficTemplate/trafficTemplateReducer";

import entityCrudReducer from "../models/common/entities/entityReducer";
import editingFeatureReducer from "../models/common/editing/editingReducer";

const combinedReducer = combineReducers({
  application: applicationReducer,
  userProfile: userProfileReducer,
  apiData: apiDataReducer,
  pendingData: apiDataFlagReducer,
  entities: entitiesReducer,
  editingEntities: editingEntitiesReducer,
  campaign: campaignReducer,
  plan: planReducer,
  planExtension: planExtensionReducer,
  planTargetsMetricsKpi: planTargetsMetricsKpiReducer,
  planChangeOverview: planChangeOverviewReducer,
  order: orderReducer,
  insertion: insertionReducer,
  project: projectReducer,
  user: userReducer,
  systemMessage: systemMessageReducer,
  campaignDashboard: campaignDashboardReducer,
  actionPage: actionPageReducer,
  overviewPage: overviewPageReducer,
  trafficPage: trafficPageReducer,
  trafficDashboard: trafficDashboardReducer,
  trafficPlan: trafficPlanReducer,
  trafficPlanRemark: trafficPlanRemarkReducer,
  trafficItem: trafficItemReducer,
  notifications: notificationsReducer,
  approvalPage: approvalPageReducer,
  copyPlanProcess: copyPlanReducer,
  trafficTemplateManager: trafficTemplateManagerReducer,
  trafficTemplate: trafficTemplateReducer,
  apiManagement: apiManagementReducer
});

const rootReducer = reduceReducers(
  combinedReducer,
  entityCrudReducer,
  editingFeatureReducer
);

export default rootReducer;
