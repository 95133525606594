import React, { memo } from "react";

import clsx from "clsx";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import {
  Grid,
  Button,
  Typography,
  DialogContent,
  DialogActions
} from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import Scrollbar from "../../../shared/Scrollbar";
import ChatItem from "./extensions/ChatItem";
import TrafficPlanRemarkField from "../../../form/TrafficPlanRemarkField";
import { classes, Dialog } from "../styles/dialog";

import { EMPTY_TABLE } from "../../../../configurations/appConstants";
import {
  closeTrafficPlanRemarkDialog,
  stopEditingTrafficPlanRemark
} from "../../../../business/models/TrafficPlan/trafficPlanActions";
import { saveTrafficPlanRemark } from "../../../../business/models/TrafficPlanRemark/trafficPlanRemarkActions";
import {
  selectIsRemarkEmpty,
  getTrafficPlanRemarks
} from "../../../../business/models/TrafficPlanRemark/trafficPlanRemarkSelectors";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import { selectUserName } from "../../../../business/selectors/userProfileSelectors";
import {
  selectOpenTrafficPlanRemarkDialog,
  getSelectedTraffic
} from "../../../../business/models/TrafficPlan/trafficPlanSelectors";

const TrafficPlanRemarkDialog = ({
  isLoading,
  isOpen,
  items,
  currentUser,
  isRemarkEmpty,
  closeDialog,
  stopEditing,
  handleSave,
  selectedTraffic
}) => {
  const handleClose = () => {
    stopEditing();
    closeDialog();
  };

  const { isDebtorActive } = selectedTraffic || false;

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogTitle>
        <Button
          id="tp_close_internal_remark"
          name="tp_close_internal_remark"
          onClick={handleClose}
          className={clsx(classes.closeButton, classes.titleButton)}
          disabled={isLoading}
        >
          CLOSE
        </Button>
      </DialogTitle>
      <DialogContent>
        {!isEmpty(items) ? (
          <Scrollbar autoHeightMax={400}>
            <Grid container>
              {items.map((item, key) => (
                <Grid item xs={12} key={key}>
                  <ChatItem item={item} currentUser={currentUser} />
                </Grid>
              ))}
            </Grid>
          </Scrollbar>
        ) : (
          <Typography align="center">{EMPTY_TABLE}</Typography>
        )}
      </DialogContent>
      {isDebtorActive && (
        <DialogActions className={classes.action}>
          <TrafficPlanRemarkField
            multiline
            rowsMax={10}
            maxLength={1000}
            margin="normal"
            id="remark"
            name="remark"
            component="text"
            disabled={isLoading}
          />
          <Button
            id="tp_save_internal_remark"
            name="tp_save_internal_remark"
            onClick={handleSave}
            color="primary"
            disabled={isLoading || isRemarkEmpty}
          >
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: selectUserName(state),
    items: getTrafficPlanRemarks(state, ownProps),
    isOpen: selectOpenTrafficPlanRemarkDialog(state),
    isLoading: selectIsLoading(state),
    isRemarkEmpty: selectIsRemarkEmpty(state),
    selectedTraffic: getSelectedTraffic(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSave: () => dispatch(saveTrafficPlanRemark()),
    stopEditing: () => dispatch(stopEditingTrafficPlanRemark()),
    closeDialog: () => dispatch(closeTrafficPlanRemarkDialog())
  };
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(TrafficPlanRemarkDialog)
);
