import React, { memo } from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import { IconButton, Typography, Checkbox, Hidden } from "@mui/material";

import ProjectButton from "../../pages/create/project/ProjectButton";
import { EditIcon, DownloadIcon } from "../../../assets/styles/icons";
import { classes, Grid } from "../styles/campaign";

import {
  COLOR_LINKWATER,
  COLOR_BLACK
} from "../../../configurations/appConstants";
import {
  selectProject,
  addNewProject
} from "../../../business/models/Project/projectActions";
import {
  openProjectDialog,
  openCampaignFormPage,
  toggleSetCampaignActualDialog
} from "../../../business/actions/campaignDashboardActions";
import {
  downloadExcel,
  startEditingCampaign
} from "../../../business/models/Campaign/campaignActions";
import {
  doesCampaignHaveAnyProject,
  getCampaignData
} from "../../../business/models/Campaign/campaignSelectors";
import {
  canEditCampaign,
  canViewCampaignArchiveCheckbox
} from "../../../business/selectors/userProfileSelectors";

const CampaignFoldActions = ({
  id,
  allowDownload = false,
  isInCopyProcess = false,
  isActual = false
}) => {
  const dispatch = useDispatch();
  const projectExists = useSelector(doesCampaignHaveAnyProject);
  const disableAction = !useSelector(canEditCampaign);
  const canViewArchive = useSelector(canViewCampaignArchiveCheckbox);
  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const handleProjectClick = () => {
    if (projectExists) {
      dispatch(selectProject());
    } else {
      dispatch(addNewProject());
    }
    dispatch(openProjectDialog());
  };

  const handleDownload = () => dispatch(downloadExcel());

  const handleEdit = () => {
    dispatch(startEditingCampaign());
    dispatch(openCampaignFormPage());
  };

  const handleSelectActual = e => {
    e.stopPropagation();
    dispatch(toggleSetCampaignActualDialog());
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      flex-direction="row"
      display="flex"
    >
      <Grid item>
        <Hidden xsUp={isInCopyProcess}>
          <IconButton
            name={`c_edit_${id}`}
            id={`c_edit_${id}`}
            onClick={handleEdit}
            className={classes.iconButton}
            disabled={disableAction}
          >
            <EditIcon disabled={disableAction} />
          </IconButton>
        </Hidden>
      </Grid>
      <Grid item>
        <ProjectButton
          id={id}
          onClick={handleProjectClick}
          projectExists={projectExists}
          disabled={disableAction}
        />
      </Grid>
      <Grid item>
        {allowDownload && (
          <IconButton
            name={`c_download_${id}`}
            id={`c_download_${id}`}
            onClick={handleDownload}
            className={classes.iconButton}
            disabled={disableAction}
          >
            <DownloadIcon
              color={disableAction ? COLOR_LINKWATER : COLOR_BLACK}
            />
          </IconButton>
        )}
      </Grid>
      {isDebtorActive && (
        <Grid item>
          <Hidden xsUp={isInCopyProcess || !canViewArchive}>
            <Checkbox
              onClick={handleSelectActual}
              checked={isActual}
              color="primary"
              className={classes.iconButton}
              id={`check_Actual_${id}`}
              name={`check_Actual_${id}`}
            />
            <Typography align="left" className={classes.typographyCaption}>
              Archive
            </Typography>
          </Hidden>
        </Grid>
      )}
    </Grid>
  );
};

CampaignFoldActions.propTypes = {
  allowDownload: PropTypes.bool,
  isActual: PropTypes.bool
};

export default memo(CampaignFoldActions);
