import React from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import { Button, DialogContent, DialogActions } from "@mui/material";
import { isEmpty } from "lodash";

import ProjectTable from "./ProjectTable";
import ProjectForm from "./ProjectForm";
import { classes, Dialog } from "../styles/dialog";
import {
  getProjects,
  selectIsEditingProject,
  selectCurrentProject,
  selectProjectIsNew
} from "../../../../business/models/Project/projectSelectors";
import {
  editProject,
  addNewProject,
  selectProject,
  updateProject,
  createProject,
  cancelProject
} from "../../../../business/models/Project/projectActions";
import { getCampaignData } from "../../../../business/models/Campaign/campaignSelectors";
import { closeProjectDialog } from "../../../../business/actions/campaignDashboardActions";

const ProjectDialog = () => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);
  const editMode = useSelector(selectIsEditingProject);
  const currentProject = useSelector(selectCurrentProject);
  const projectIsNew = useSelector(selectProjectIsNew);
  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const handleSave = () => {
    const result = projectIsNew
      ? dispatch(createProject())
      : dispatch(updateProject());

    result.then(isSuccessful => isSuccessful && dispatch(closeProjectDialog()));
  };

  const handleCancel = () => {
    dispatch(cancelProject());
    dispatch(closeProjectDialog());
  };

  const handleEditProject = id => {
    dispatch(editProject(id));
  };

  const handleOnAddProject = () => {
    dispatch(addNewProject());
  };

  const handleClickProjectItem = id => {
    dispatch(selectProject(id));
  };

  return (
    <Dialog open maxWidth="md" fullWidth>
      <ProjectTable
        projects={projects}
        onEditProject={handleEditProject}
        onAddProject={handleOnAddProject}
        onClickProjectItem={handleClickProjectItem}
        currentProject={currentProject}
        isDebtorActive={isDebtorActive}
      />
      {(isDebtorActive || !isEmpty(projects)) && (
        <DialogContent
          id="project-dialog-content"
          name="project-dialog-content"
        >
          <ProjectForm />
        </DialogContent>
      )}
      <DialogActions id="project-dialog-action">
        <Button
          id="project_cancel"
          name="project_cancel"
          onClick={handleCancel}
          className={classes.closeButton}
        >
          Cancel
        </Button>
        {isDebtorActive && (
          <Button
            id="project_save"
            name="project_save"
            onClick={handleSave}
            className={classes.saveButton}
            disabled={!editMode}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ProjectDialog.propTypes = {
  editMode: PropTypes.bool,
  projects: PropTypes.array
};

ProjectDialog.defaultProps = {
  projects: [],
  editMode: false
};

export default ProjectDialog;
