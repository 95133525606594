import { styled, Dialog as MuiDialog } from "@mui/material";
import { pxToRem } from "../../../../assets/styles/theme";

const PREFIX = "ActionInsertionItemStyles";
export const classes = {
  dialog: `${PREFIX}-dialog`,
  content: `${PREFIX}-content`,
  chip: `${PREFIX}-chip`,
  whiteChip: `${PREFIX}-whiteChip`,
  redLabelChip: `${PREFIX}-redLabelChip`,
  insertionIconButton: `${PREFIX}-insertionIconButton`
};

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${classes.dialog}`]: {
    width: theme.typography.pxToRem(400),
    overflowY: "visible" // Autocomplete issue
  },
  [`& .${classes.content}`]: {
    marginTop: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(150),
    overflowY: "visible !important"
  }
}));

export const selectCustomStyles = {
  menuList: styles => ({
    ...styles,
    maxHeight: pxToRem(110)
  })
};
