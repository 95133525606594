import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box
} from "@mui/material";

import DialogTitle from "../../shared/dialog/DialogTitle";
import UserField from "../../form/UserField";
import { getAgentForIdsOptions } from "../../../business/models/User/userSelectors";
import { UserFieldCheckbox } from "./styles/userManagementDialogStyle";

const companyOptions = [
  { key: 1, text: "GroupM" },
  { key: 5, text: "Kinetic" },
  { key: 2, text: "Mindshare" },
  { key: 6, text: "EssenceMediacom" },
  { key: 7, text: "Wavemaker" },
  { key: 8, text: "Blossom" },
  { key: 9, text: "Greenhouse One" },
  { key: 10, text: "Greenhouse Tech" },
  { key: 11, text: "Choreograph Create" }
];

const roleOptions = [
  { key: 1, text: "Viewer" },
  { key: 2, text: "Planner" },
  { key: 3, text: "Process Manager" },
  { key: 4, text: "Traffic Planner" },
  { key: 5, text: "Traffic Process Manager" },
  { key: 7, text: "Approver" },
  { key: 8, text: "Evouching" },
  { key: 9, text: "Traffic Manager" },
  { key: 10, text: "Inventory" }
];

const UserManagementDialog = props => {
  const agentForIdsOptions = useSelector(state => getAgentForIdsOptions(state));
  const { open, onCancel, onSave, action } = props;

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle id="project-dialog-title">{`${action} User`}</DialogTitle>
      <DialogContent id="project-dialog-content">
        <UserField
          component="text"
          name="lastName"
          id="lastName"
          label="Last Name *"
        />
        <UserField
          component="text"
          name="firstName"
          id="firstName"
          label="First Name *"
        />
        <UserField
          component="text"
          name="userName"
          id="userName"
          label="Username *"
        />
        <UserField
          component="text"
          name="marathonUser"
          id="marathonUser"
          label="Marathon User *"
        />
        <UserField
          component="autocomplete"
          name="agencyId"
          id="agencyId"
          label="Company *"
          options={companyOptions}
        />
        <UserFieldCheckbox
          component="checkbox"
          label="Active"
          name="isActive"
          id="isActive"
        />
        <UserFieldCheckbox label="Admin" name="isAdmin" id="isAdmin" />
        <UserField
          component="autocomplete"
          name="roleIds"
          id="roleIds"
          label="Roles *"
          options={roleOptions}
          isMulti
        />
        <UserFieldCheckbox label="Agent" name="isAgent" id="isAgent" />
        <UserField
          component="autocomplete"
          label="For Agencies"
          name="agentForIds"
          id="agentForIds"
          options={agentForIdsOptions}
          isMulti
        />
        <UserField
          component="autocomplete"
          name="groupmRoles"
          id="groupmRoles"
          label="GroupM Roles"
          options={roleOptions}
          isMulti
        />
        <UserField
          component="autocomplete"
          name="kineticRoles"
          id="kineticRoles"
          label="Kinetic Roles"
          options={roleOptions}
          isMulti
        />
        <UserField
          component="autocomplete"
          name="mindshareRoles"
          id="midnshareRoles"
          label="Mindshare Roles"
          options={roleOptions}
          isMulti
        />
        <UserField
          component="autocomplete"
          name="mediacomRoles"
          id="mediacomRoles"
          label="EssenceMediacom Roles"
          options={roleOptions}
          isMulti
        />
        <UserField
          component="autocomplete"
          name="wavemakerRoles"
          id="wavemakerRoles"
          label="Wavemaker Roles"
          options={roleOptions}
          isMulti
        />
        <UserField
          component="autocomplete"
          name="blossomRoles"
          id="blossomRoles"
          label="Blossom Roles"
          options={roleOptions}
          isMulti
        />
        <UserField
          component="autocomplete"
          name="greenhouseoneRoles"
          id="greenhouseoneRoles"
          label="Greenhouse One Roles"
          options={roleOptions}
          isMulti
        />
        <UserField
          component="autocomplete"
          name="greenhousetechRoles"
          id="greenhousetechRoles"
          label="Greenhouse Tech Roles"
          options={roleOptions}
          isMulti
        />
        <UserField
          component="autocomplete"
          name="choreographcreateRoles"
          id="choreographcreateRoles"
          label="Choreograph Create Roles"
          options={roleOptions}
          isMulti
        />
      </DialogContent>
      <DialogActions id="project-dialog-action">
        <Button style={{ color: "black" }} onClick={onCancel}>
          Cancel
        </Button>
        <Button style={{ color: "black" }} onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserManagementDialog.propTypes = {
  open: PropTypes.bool,
  action: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

UserManagementDialog.defaultProps = {
  action: "Create"
};

export default UserManagementDialog;
