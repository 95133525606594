import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { Button } from "@mui/material";

import { openManageTrafficTemplateDialog } from "../../../../business/actions/trafficTemplateManagerActions";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";

import { classes, Grid } from "../styles/trafficTemplateManagerActions";
import { addNewTrafficTemplate } from "../../../../business/models/TrafficTemplate/trafficTemplateActions";

const TrafficTemplateManagerActions = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);

  const handleAddTrafficTemplate = () => {
    dispatch(openManageTrafficTemplateDialog());
    dispatch(addNewTrafficTemplate());
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      // className={classes.root}//TODO(AM)
    >
      <Grid item />
      <Grid item>
        <Button
          variant="contained"
          id="add_item"
          name="add_item"
          className={clsx(classes.saveButton, classes.markedButton)}
          disabled={isLoading}
          onClick={handleAddTrafficTemplate}
        >
          ADD TEMPLATE
        </Button>
      </Grid>
    </Grid>
  );
};

export default TrafficTemplateManagerActions;
