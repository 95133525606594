import React from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";

import { IconButton, Checkbox } from "@mui/material";

import {
  CompareIcon,
  ViewIcon,
  ApprovedSelectionIcon,
  DeclineSelectionIcon
} from "../../../../../../assets/styles/icons";
import {
  COLOR_JORDYBLUE,
  ORDER_STATUS_ORDER_CHANGE,
  ORDER_STATUS_APPROVED,
  ORDER_STATUS_CANCELLED
} from "../../../../../../configurations/appConstants";
import {
  toggleSelected,
  setOrderChangeCompareOrderId,
  openOrderChangeCompareDialog
} from "../../../../../../business/actions/actionPageActions";
import { selectIsSelected } from "../../../../../../business/selectors/actionPageSelectors";

import { classes, Grid } from "../styles/actionOrderElementActions";

const ActionOrderElementActions = ({
  orderId,
  onToggle,
  buttonRef,
  canUserDoActions,
  statusId
}) => {
  const dispatch = useDispatch();

  const isSelected = useSelector(state =>
    selectIsSelected(state, { id: orderId })
  );

  const showDifference = statusId === ORDER_STATUS_ORDER_CHANGE;

  const handleCheckBoxClick = e => {
    e.stopPropagation();
    dispatch(toggleSelected(orderId));
  };

  const handleCompareClick = e => {
    e.stopPropagation();
    dispatch(setOrderChangeCompareOrderId(orderId));
    dispatch(openOrderChangeCompareDialog());
  };

  const actionIcon = type => {
    switch (type) {
      case ORDER_STATUS_APPROVED:
        return <ApprovedSelectionIcon />;
      case ORDER_STATUS_CANCELLED:
        return <DeclineSelectionIcon />;
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      {showDifference && (
        <Grid item>
          <IconButton
            id={`ao_compare_${orderId}`}
            name={`ao_compare_${orderId}`}
            onClick={handleCompareClick}
            className={classes.iconButton}
          >
            <CompareIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item>
        <IconButton
          id={`ao_view_${orderId}`}
          name={`ao_view_${orderId}`}
          onClick={onToggle}
          ref={buttonRef}
          className={classes.iconButton}
        >
          <ViewIcon color={COLOR_JORDYBLUE} />
        </IconButton>
      </Grid>
      <Grid item>
        {actionIcon(statusId) || (
          <Checkbox
            onClick={handleCheckBoxClick}
            checked={isSelected}
            color="primary"
            className={classes.checkbox}
            disabled={!canUserDoActions}
          />
        )}
      </Grid>
    </Grid>
  );
};

ActionOrderElementActions.propTypes = {
  orderId: PropTypes.number,
  onToggle: PropTypes.func.isRequired,
  buttonRef: PropTypes.object,
  canUserDoActions: PropTypes.bool,
  statusId: PropTypes.number
};

ActionOrderElementActions.defaultProps = {
  canUserDoActions: false
};

export default ActionOrderElementActions;
