import React, { useState, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

import {
  Grow,
  Paper,
  Typography,
  Grid,
  ClickAwayListener,
  Badge
} from "@mui/material";

import { NotificationImportant } from "@mui/icons-material";

import Scrollbar from "../../../shared/Scrollbar";

import {
  selectNotifications,
  selectNotificationCount
} from "../../../../business/selectors/notificationsSelectors";

import {
  updateNotificationStatus,
  downloadSysMsgAttachment,
  clearNoSystemNotification
} from "../../../../business/actions/notificationsActions";
import NotificationCard from "./NotificationCard";
import { NOTIFICATION_WINDOW_HEGIHT } from "../../../../configurations/appConstants";
import { setRefetch } from "../../../../business/actions/applicationActions";
import ClearNotificationButtonCard from "./ClearNotificationButtonCard";
import { classes, IconButton, Popper } from "../styles/notificationListMenu";

const NotificationListMenu = () => {
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const trafficNotifications = useSelector(selectNotifications);
  const trafficNotificationCount = useSelector(selectNotificationCount);

  const handleToggle = () => {
    buttonRef.current.focus();
    setOpen(!open);
  };

  const handleClose = e => {
    if (buttonRef.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCardClick = id => {
    dispatch(updateNotificationStatus(id));
    dispatch(setRefetch());
    setOpen(false);
  };

  const handleClearSingleNotification = id => {
    dispatch(updateNotificationStatus(id));
    dispatch(setRefetch());
    setOpen(false);
  };

  const handleClearAllNoSystemNotification = () => {
    dispatch(clearNoSystemNotification());
  };

  const handleDownloadClick = (id, fileName) => {
    dispatch(downloadSysMsgAttachment(id, fileName));
  };

  return (
    <>
      <IconButton
        id="notificationList"
        name="notificationList"
        alt="NotificationListButton"
        onClick={handleToggle}
        className={classes.button}
        ref={buttonRef}
      >
        <Badge badgeContent={trafficNotificationCount} color="secondary">
          <NotificationImportant />
        </Badge>
      </IconButton>
      <Popper
        open={open}
        anchorEl={buttonRef.current}
        transition
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} id="notification-list-grow">
            <Paper
              elevation={!isEmpty(trafficNotifications) ? 0 : 5}
              className={classes.paper}
            >
              <ClickAwayListener onClickAway={handleClose}>
                {!isEmpty(trafficNotifications) ? (
                  <Scrollbar autoHeightMax={NOTIFICATION_WINDOW_HEGIHT}>
                    <Grid item xs={12}>
                      <ClearNotificationButtonCard
                        title="Clear all notifications"
                        onCardClick={handleClearAllNoSystemNotification}
                      />
                    </Grid>
                    {trafficNotifications.map(notification => (
                      <NotificationCard
                        key={notification.id}
                        title={notification.title}
                        message={notification.message}
                        timestamp={notification.modified}
                        route={notification.urlPath}
                        onCardClick={handleCardClick}
                        onDeleteNotification={handleClearSingleNotification}
                        id={notification.id}
                        type={notification.typeId}
                        hasAttachment={notification.hasAttachment}
                        systemMessageId={notification.systemMessageId}
                        fileName={notification.fileName}
                        onDownloadClick={handleDownloadClick}
                      />
                    ))}
                  </Scrollbar>
                ) : (
                  <Grid
                    container
                    className={classes.paperEmptyNotification}
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item>
                      <Typography align="center" color="textSecondary">
                        Currently you have no notifications.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default memo(NotificationListMenu);
