import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import Controls from "./controls/index";

const CustomFootnoteField = props => {
  const { component, id, onChange, ...componentProps } = props;
  const [value, setValue] = useState("");

  const handleChange = val => {
    setValue(val);
  };

  useEffect(() => {
    props.val(value, id);
  });

  const Control = Controls[component];
  return (
    <Control
      value={value}
      component={component}
      id={`${id}`}
      {...componentProps}
      onChange={handleChange}
    />
  );
};

CustomFootnoteField.props = {
  component: PropTypes.oneOf(["text"])
};

export default CustomFootnoteField;
