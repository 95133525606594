import { styled, Grid as MuiGrid } from "@mui/material";
import { crusta, white, dodgerBlue } from "../../../../../assets/styles/colors";

const PREFIX = "ConfigurationActionsStyles";
export const classes = {
  cancelButton: `${PREFIX}-cancelButton`,
  saveButton: `${PREFIX}-saveButton`,
  approveButton: `${PREFIX}-approveButton`
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.cancelButton}`]: {
    color: crusta
  },
  [`& .${classes.saveButton}`]: {
    color: white
  },
  [`& .${classes.approveButton}`]: {
    backgroundColor: dodgerBlue
  }
}));
