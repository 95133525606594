import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

import InputComponent from "./InputComponent";

class Control extends React.Component {
  render() {
    const {
      selectProps,
      innerProps,
      innerRef,
      children,
      hasValue,
      isFocused
    } = this.props;
    const {
      id,
      variant,
      label,
      isDisabled,
      displayErrors,
      isValid,
      errorText,
      textFieldClassName,
      InputProps,
      formHelperTextProps,
      inputLabelProps,
      helperText
    } = selectProps;

    const InputPropsTextField = {
      inputComponent: InputComponent,
      inputProps: {
        className: selectProps.classes.input,
        autoComplete: "off",
        ref: innerRef,
        children,
        ...innerProps
      },
      ...InputProps
    };

    const InputLabelProps = {
      shrink: hasValue || isFocused,
      ...inputLabelProps
    };
    return (
      <TextField
        id={id}
        variant={variant}
        label={label}
        disabled={isDisabled}
        error={displayErrors && !isValid}
        helperText={(displayErrors && errorText) || helperText}
        margin="normal"
        fullWidth
        InputProps={InputPropsTextField}
        FormHelperTextProps={formHelperTextProps}
        InputLabelProps={InputLabelProps}
        className={textFieldClassName}
      />
    );
  }
}

Control.propTypes = {
  selectProps: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
  children: PropTypes.array,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  hasValue: PropTypes.bool,
  isFocused: PropTypes.bool
};

Control.defaultProps = {
  children: []
};

export default Control;
