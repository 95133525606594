export const DO_NOT_LOGIN = false;

export const CREATE_PAGE = "create";
export const CAMPAIGN = "campaign";
export const PLAN = "plan";
export const ORDER = "order";
export const OVERVIEW_PAGE = "overview";
export const ACTION_PAGE = "action";
export const TRAFFIC_PAGE = "traffic";
export const TRAFFIC_OVERVEIW_PAGE = "traffic-overview";
export const ACTION_OVERVIEW_PAGE = "action-overview";
export const API_MANAGEMENT_PAGE = "api-management";
export const USER_MANAGEMENT_PAGE = "user-management";
export const SYSTEM_MESSAGES_PAGE = "system-messages";
export const AACONFIGURATION_PAGE = "configuration";
export const HOME_PAGE = "home";
export const HOME_PAGE_PATH = "/";
export const ERROR_PAGE = "error";
export const USER_PROFILE_PAGE = "user-profile";
export const TRAFFIC_TEMPLATE_MANAGER = "traffic-template";

export const ERROR_MSG_NOT_AUTHORIZED =
  "You are not authorized to see this content";
export const ERROR_MSG_INVALID_DATA =
  "Data was not sent properly, please check input data and try again";
export const ERROR_MSG_500 =
  "Ooops! Something went wrong, please contact support.amsterdam@groupm.com";

export const ERROR_MSG_SOMETHING_WHENT_WRONG =
  "Something went wrong, please refresh browser window";

export const ERROR_MSG_NOT_FOUND = "resource not found";

export const SUCCESS_MSG = "success";

export const ERROR_MSG_SERVICE = "Service not available";

// order client status; TO DO: make it obvious that is client status
export const ORDER_STATUS_WORK_IN_PROGRESS = 1;
export const ORDER_STATUS_READY_FOR_APPROVED = 2;
export const ORDER_STATUS_APPROVED = 3;
export const ORDER_STATUS_CANCELLED = 4;
export const ORDER_STATUS_NOT_APPROVED = 5;
export const ORDER_STATUS_ORDER_CHANGE = 6;
export const ORDER_STATUS_TO_BE_CANCELLED = 32;

export const CAMPAIGN_SCHEDULED_STATUS = "Scheduled";
export const CAMPAIGN_TAKE_OFF_STATUS = "TakeOff";
export const CAMPAIGN_IN_AIR_STATUS = "InAir";
export const CAMPAIGN_LANDING_STATUS = "Landing";
export const CAMPAIGN_ARRIVED_STATUS = "Arrived";
export const CAMPAIGN_ARCHIVED_STATUS = "Archived";
export const CAMPAIGN_ALL_STATUS = "All";
export const CAMPAIGN_FAVOURITES = "Favourites";

export const AGENCY_GROUPM = "GroupM";
export const AGENCY_MINDSHARE = "Mindshare";
export const AGENCY_KINETIC = "Kinetic";
export const AGENCY_ESSENCEMEDIACOM = "EssenceMediacom";
export const AGENCY_WAVEMAKER = "Wavemaker";
export const AGENCY_BLOSSOM = "Blossom";
export const AGENCY_GREENHOUSEONE = "Greenhouse One";
export const AGENCY_GREENHOUSETECH = "Greenhouse Tech";
export const AGENCY_CHOREOGRAPHCREATE = "Choreograph Create";

export const TRAFFIC_CAMPAIGN_NEW_STATUS = "New";
export const TRAFFIC_CAMPAIGN_ASSIGNED_STATUS = "Assigned";
export const TRAFFIC_CAMPAIGN_ARCHIVED_STATUS = "Archived";

export const ASC = "_asc";
export const PLACEHOLDER = "_";
export const DASH_PLACEHOLDER = "-";
export const WHITESPACE = " ";
export const EMPTY_STRING = "";
export const EMPTY_TABLE = "no items to display";

export const MINDSHARE_AGENCY = "mindshare";
export const KINETIC_AGENCY = "kinetic";
export const GROUPM_AGENCY = "groupm";
export const WAVEMAKER_AGENCY = "wavemaker";
export const ESSENCEMEDIACOM_AGENCY = "essencemediacom";
export const BLOSSOM_AGENCY = "blossom";
export const GREENHOUSEONE_AGENCY = "greenhouseone";
export const GREENHOUSETECH_AGENCY = "greenhousetech";
export const CHOREOGRAPHCREATE_AGENCY = "choreographcreate";

export const GROUPM_AGENCY_ID = 1;
export const MINDSHARE_AGENCY_ID = 2;
export const KINETIC_AGENCY_ID = 5;
export const ESSENCEMEDIACOM_AGENCY_ID = 6;
export const WAVEMAKER_AGENCY_ID = 7;
export const BLOSSOM_AGENCY_ID = 8;
export const GREENHOUSEONE_AGENCY_ID = 9;
export const GREENHOUSETECH_AGENCY_ID = 10;
export const CHOREOGRAPHCREATE_AGENCY_ID = 11;

export const MEDIATYPE_DIGITAL = 1;
export const MEDIATYPE_PRINT = 2;
export const MEDIATYPE_OOH = 3;
export const MEDIATYPE_TV = 4;
export const MEDIATYPE_RADIO = 5;
export const MEDIATYPE_CINEMA = 6;
export const MEDIATYPE_OTHER = 7;
export const MEDIATYPE_INVENTORY = 8;

export const MEDIATYPE_NAME_DIGITAL = "Digital";
export const MEDIATYPE_NAME_PRINT = "Print";
export const MEDIATYPE_NAME_OOH = "Ooh";
export const MEDIATYPE_NAME_TV = "Tv";
export const MEDIATYPE_NAME_RADIO = "Radio";
export const MEDIATYPE_NAME_CINEMA = "Cinema";
export const MEDIATYPE_NAME_OTHER = "Other";
export const MEDIATYPE_NAME_INVENTORY = "Inventory";

export const FOREIGN_CURRENCY = 1;
export const NEGATIVE_AMOUNT = 2;
export const SPECIFIC_TITLE = 3;
export const SPECIFIC_SURCHARGE = 4;
export const EXCEED_THRESHOLD = 5;

export const FOREIGN_CURRENCY_NAME = "foreignCurrency";
export const NEGATIVE_AMOUNT_NAME = "negativeAmount";
export const SPECIFIC_TITLE_NAME = "highRiskTitle";
export const SPECIFIC_SURCHARGE_NAME = "highRiskSurcharge";
export const EXCEED_THRESHOLD_NAME = "exceedsThreshold";

export const COLOR_LILAC = "lilac";
export const COLOR_DEYORK = "deYork";
export const COLOR_JORDYBLUE = "jordyBlue";
export const COLOR_CRUSTA = "crusta";
export const COLOR_SUPERNOVA = "supernova";
export const COLOR_DODGERBLUE = "dodgerBlue";
export const COLOR_DODGERBLUE20 = "dodgerBlue20";
export const COLOR_SAPPHIRE = "sapphire";
export const COLOR_MATISSE = "matisse";
export const COLOR_BALIHALI = "baliHai";
export const COLOR_LINKWATER = "linkWater";
export const COLOR_SOLITUDE = "solitude";
export const COLOR_AZURERADINACE = "azureRadiance";
export const COLOR_SOFTRED = "softRed";
export const COLOR_BITTERSWEET = "bittersweet";
export const COLOR_WHITE = "white";
export const COLOR_BLACK = "black";

export const TYPE_BUTTON = "button";
export const TYPE_ICON = "icon";

export const REMARK_STATUS_READ = 2;
export const REMARK_STATUS_UNREAD = 1;

export const NOTIFICATION_STATUS_UNREAD = 1;
export const NOTIFICATION_STATUS_READ = 2;

export const NOTIFICATION_TYPE_SYSTEM = 1;
export const NOTIFICATION_TYPE_REMARKS = 20;
export const NOTIFICATION_TYPE_ORDERS_APPROVED = 10;
export const NOTIFICATION_TYPE_ORDERS_CHANGED = 11;
export const NOTIFICATION_TYPE_ORDERS_TO_BE_CANCELLED = 12;
export const NOTIFICATION_TYPE_ORDERS_PENDING_FOR_APPROVAL = 13;
export const NOTIFICATION_TYPE_REMARKS_URL = "traffic";
export const NOTIFICATION_TYPE_ORDERS_APPROVED_URL = "action";
export const NOTIFICATION_TYPE_ORDERS_ADDITIONAL_APPROVED_URL =
  "approval-overview";

export const MAX_NUMBER_FOR_INPUTS = 1000000000;
export const MIN_NUMBER_FOR_INPUTS = -1000000000;

export const APP_BAR_HEIGHT = 84;
export const SIDE_BAR_OPEN_WIDTH = 316;
export const SIDE_BAR_CLOSE_WIDTH = 104;

export const NOTIFICATION_WINDOW_HEGIHT =
  window.innerHeight * window.devicePixelRatio - APP_BAR_HEIGHT * 2;

export const getPanelHeight = () => {
  const defaultScreen = 1080;
  const defaultScroll = 700;
  const result = defaultScroll - (defaultScreen - window.screen.height);
  return result;
};

const ruleTypesEnum = {
  FOREIGN_CURRENCY_NAME,
  NEGATIVE_AMOUNT_NAME,
  SPECIFIC_TITLE_NAME,
  SPECIFIC_SURCHARGE_NAME,
  EXCEED_THRESHOLD_NAME
};
Object.freeze(ruleTypesEnum);

const ruleTypeDict = {
  [FOREIGN_CURRENCY_NAME]: "Foreign Currency",
  [NEGATIVE_AMOUNT_NAME]: "Negative Amount",
  [SPECIFIC_TITLE_NAME]: "High Risk Title",
  [SPECIFIC_SURCHARGE_NAME]: "High Risk Surcharge",
  [EXCEED_THRESHOLD_NAME]: "Exceeds Threshold"
};

Object.freeze(ruleTypeDict);

const mediaTypesEnum = [
  MEDIATYPE_NAME_DIGITAL,
  MEDIATYPE_NAME_PRINT,
  MEDIATYPE_NAME_OOH,
  MEDIATYPE_NAME_TV,
  MEDIATYPE_NAME_RADIO,
  MEDIATYPE_NAME_CINEMA,
  MEDIATYPE_NAME_OTHER,
  MEDIATYPE_NAME_INVENTORY
];

Object.freeze(mediaTypesEnum);

const mediaTypesEnumTraffic = [
  MEDIATYPE_NAME_DIGITAL,
  MEDIATYPE_NAME_PRINT,
  MEDIATYPE_NAME_OOH,
  MEDIATYPE_NAME_TV,
  MEDIATYPE_NAME_RADIO,
  MEDIATYPE_NAME_CINEMA,
  MEDIATYPE_NAME_INVENTORY
];

Object.freeze(mediaTypesEnumTraffic);

export { mediaTypesEnum, mediaTypesEnumTraffic, ruleTypesEnum, ruleTypeDict };
