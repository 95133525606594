import { Model, attr, fk } from "redux-orm";
import set from "lodash/set";

import {
  PLAN_TARGET_METRICS_KPI_MODEL_NAME,
  PLAN_TARGET_METRICS_KPI_MODEL_ID
} from "./planTargetMetricsKpiConstants";
import {
  PLAN_EXTENSION_MODEL_NAME,
  PLAN_EXTENSION_MODEL_ID
} from "../PlanExtension/planExtensionConstants";

import {
  NUMBER_OF_CHARACTERS_RULE,
  NUMBER_OF_CHARACTERS_MESSAGE_100,
  NUMBER_OF_CHARACTERS_MESSAGE_50
} from "../../constants/validationConstants";

import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";

class PlanTargetMetricsKpi extends Model {
  static get fields() {
    return {
      id: attr(),
      planTargetMetricsKpiId: attr(),
      planExtensionId: attr(),
      channel: attr(),
      spotLength: attr(),
      primaryTargetAudience: attr(),
      netReach: attr(),
      ots: attr(),
      effectiveReachContactRange: attr(),
      grps: attr(),
      location: attr(),
      visitors: attr(),
      weeklyDistribution: attr(),
      impressions: attr(),
      buyingTargetAudience: attr(),
      positionInBreak: attr(),
      views: attr(),
      preferredPosition: attr(),
      primetimeShare: attr(),
      remarksRestrictions: attr(),
      planExtension: fk(PLAN_EXTENSION_MODEL_NAME)
    };
  }

  static get defaultAttributes() {
    return {};
  }

  static get inheritConfiguration() {
    return {};
  }

  static parse(planTargetMetricsKpiData) {
    if (planTargetMetricsKpiData[PLAN_TARGET_METRICS_KPI_MODEL_ID]) {
      set(
        planTargetMetricsKpiData,
        "id",
        planTargetMetricsKpiData[PLAN_TARGET_METRICS_KPI_MODEL_ID]
      );
    }
    if (planTargetMetricsKpiData[PLAN_EXTENSION_MODEL_ID]) {
      set(
        planTargetMetricsKpiData,
        "planExtension",
        planTargetMetricsKpiData[PLAN_EXTENSION_MODEL_ID]
      );
    }

    return this.upsert(planTargetMetricsKpiData);
  }

  static updateModel(planTargetMetricsKpiData) {
    if (planTargetMetricsKpiData[PLAN_TARGET_METRICS_KPI_MODEL_ID]) {
      set(
        planTargetMetricsKpiData,
        "id",
        planTargetMetricsKpiData[PLAN_TARGET_METRICS_KPI_MODEL_ID]
      );
    }
    if (planTargetMetricsKpiData[PLAN_EXTENSION_MODEL_ID]) {
      set(
        planTargetMetricsKpiData,
        "planExtension",
        planTargetMetricsKpiData[PLAN_EXTENSION_MODEL_ID]
      );
    }

    return this.upsert(planTargetMetricsKpiData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...PlanTargetMetricsKpi.defaultAttributes,
      ...newAttributes
    };
    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherPlanTargetMetricsKpi) {
    this.update(otherPlanTargetMetricsKpi.ref);
  }

  static get validation() {
    return {
      channel: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      spotLength: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      primaryTargetAudience: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      netReach: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      ots: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      effectiveReachContactRange: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      grps: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      impressions: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      weeklyDistribution: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      remarksRestrictions: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      location: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      visitors: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      buyingTargetAudience: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      positionInBreak: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      views: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      preferredPosition: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      primetimeShare: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ]
    };
  }

  static get calculations() {
    return {};
  }

  validate() {
    return validateModel(PlanTargetMetricsKpi.validation, this.toJSON());
  }

  validateByProperty(name) {
    return validateProperty(
      PlanTargetMetricsKpi.validation[name],
      this.toJSON()[name]
    );
  }

  hasErrors() {
    return doesModelHaveErrors(PlanTargetMetricsKpi.validation, this.toJSON());
  }

  static get apiConfiguration() {
    return {};
  }

  static get disabledFields() {
    return {};
  }
}

PlanTargetMetricsKpi.modelName = PLAN_TARGET_METRICS_KPI_MODEL_NAME;

export default PlanTargetMetricsKpi;
