import cuid from "cuid";
import get from "lodash/get";
import set from "lodash/set";
import {
  SYSTEM_MESSAGE_SELECT,
  SYSTEM_MESSAGE_EDIT_START,
  SYSTEM_MESSAGE_CLEAR_CURRENT,
  SYSTEM_MESSAGE_DISPLAY_ERRORS,
  SYSTEM_MESSAGE_HIDE_ERRORS,
  SYSTEM_MESSAGE_MODEL_NAME,
  SYSTEM_MESSAGE_EDIT_STOP,
  SYSTEM_MESSAGE_SUCCESS_MESSAGE,
  SYSTEM_MESSAGE_MODEL_ID,
  SYSTEM_MESSAGE_SET_IS_NEW,
  SYSTEM_MESSAGE_RESET_IS_NEW
} from "./systemMessageConstants";
import {
  selectCurrentSystemMessage,
  selectIsEditingSystemMessage,
  getEditingSystemMessage,
  doesSystemMessageHaveErrors
} from "./systemMessageSelectors";

import {
  enqueueApiErrorMessage,
  enqueueNotificationMessage,
  apiCall,
  openSystemMessageDialog,
  enqueueApplicationErrorMessage,
  closeSystemNotificationDialog
} from "../../actions/applicationActions";

import {
  editExistingItem,
  editItemAttributes,
  editNewItem,
  stopEditingItem,
  commitData
} from "../common/editing/editingActions";
import { LOAD_SYSTEM_MESSAGES_SUCCESS } from "../common/data/dataConstants";
import { clearEntityData } from "../common/data/dataActions";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import generateUrl from "../common/utils/urlUtils";
import {
  GET_SYSTEM_MESSAGES_DATA,
  SAVE_SYSTEM_MESSAGE,
  UPDATE_SYSTEM_MESSAGE,
  NOTIFICATIONS_URL
} from "../../../configurations/apiUrls";
import { GET, POST, PUT } from "../../constants/applicationConstants";
import { loadNotifications } from "../../actions/notificationsActions";
import { selectAgency } from "../../selectors/applicationSelectors";

export function loadSystemMessages() {
  return dispatch => {
    const url = generateUrl(GET_SYSTEM_MESSAGES_DATA, {
      agency: 0
    });

    return dispatch(apiCall(GET, url)).then(
      response => {
        dispatch(clearEntityData());
        dispatch({
          type: LOAD_SYSTEM_MESSAGES_SUCCESS,
          payload: response
        });
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function startEditingSystemMessage() {
  return (dispatch, getState) => {
    const currentSystemMessage = selectCurrentSystemMessage(getState());

    dispatch(editExistingItem(SYSTEM_MESSAGE_MODEL_NAME, currentSystemMessage));

    dispatch({ type: SYSTEM_MESSAGE_EDIT_START });
  };
}

export function stopEditingSystemMessage() {
  return (dispatch, getState) => {
    const currentSystemMessage = selectCurrentSystemMessage(getState());

    dispatch(stopEditingItem(SYSTEM_MESSAGE_MODEL_NAME, currentSystemMessage));
    dispatch({ type: SYSTEM_MESSAGE_EDIT_STOP });
    dispatch({ type: SYSTEM_MESSAGE_HIDE_ERRORS });
    dispatch({ type: SYSTEM_MESSAGE_RESET_IS_NEW });
  };
}

export function selectSystemMessage(systemMessageId) {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectIsEditingSystemMessage(state);

    if (isEditing) {
      dispatch(stopEditingSystemMessage());
    }

    dispatch({ type: SYSTEM_MESSAGE_CLEAR_CURRENT });

    dispatch({
      type: SYSTEM_MESSAGE_SELECT,
      payload: systemMessageId
    });
  };
}

export function clearCurrentSystemMessage() {
  return dispatch => {
    dispatch({ type: SYSTEM_MESSAGE_CLEAR_CURRENT });
  };
}

export function addNewSystemMessage() {
  return (dispatch, getState) => {
    const session = getUnsharedEntitiesSession(getState());
    const { SystemMessage } = session;

    const id = cuid();
    const newSystemMessage = SystemMessage.generate({ id });
    const SystemMessageContents = newSystemMessage.toJSON();

    dispatch(editNewItem(SYSTEM_MESSAGE_MODEL_NAME, id, SystemMessageContents));
    dispatch(selectSystemMessage(id));
    dispatch({ type: SYSTEM_MESSAGE_EDIT_START });
    dispatch({ type: SYSTEM_MESSAGE_SET_IS_NEW });
    dispatch(openSystemMessageDialog());
  };
}

export function displaySystemMessageErrors() {
  return { type: SYSTEM_MESSAGE_DISPLAY_ERRORS };
}

export function saveSystemMessage() {
  return (dispatch, getState) => {
    const state = getState();

    const hasErrors = doesSystemMessageHaveErrors(state);
    if (hasErrors) {
      dispatch(displaySystemMessageErrors());
      return Promise.reject();
    }

    const body = getEditingSystemMessage(state);

    const saveUrl = generateUrl(SAVE_SYSTEM_MESSAGE, {
      agency: 0
    });

    return dispatch(apiCall(POST, saveUrl, body)).then(
      response => {
        const id = get(response, SYSTEM_MESSAGE_MODEL_ID);
        const responseData = set(response, "id", id);
        const hasAttachment = response.file
          ? response.file.isSuccessful
          : false;
        set(responseData, "hasAttachment", hasAttachment);
        dispatch(commitData(SYSTEM_MESSAGE_MODEL_NAME, id, responseData));
        dispatch(stopEditingSystemMessage());
        dispatch(clearCurrentSystemMessage());
        dispatch(enqueueNotificationMessage(SYSTEM_MESSAGE_SUCCESS_MESSAGE));

        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function updateSystemMessage(id) {
  return (dispatch, getState) => {
    const state = getState();

    const hasErrors = doesSystemMessageHaveErrors(state);

    if (hasErrors) {
      dispatch(displaySystemMessageErrors());
      return Promise.reject();
    }

    const body = getEditingSystemMessage(state);

    const updateUrl = generateUrl(UPDATE_SYSTEM_MESSAGE, {
      agency: 0,
      [SYSTEM_MESSAGE_MODEL_ID]: id
    });

    return dispatch(apiCall(PUT, updateUrl, body)).then(
      response => {
        const responseData = set(response, "id", id);
        const hasAttachment = response.file
          ? response.file.isSuccessful
          : false;
        set(responseData, "hasAttachment", hasAttachment);
        dispatch(commitData(SYSTEM_MESSAGE_MODEL_NAME, id, responseData));
        dispatch(stopEditingSystemMessage());
        dispatch(clearCurrentSystemMessage());
        dispatch(stopEditingSystemMessage());
        dispatch(enqueueNotificationMessage(SYSTEM_MESSAGE_SUCCESS_MESSAGE));
        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function setSystemMessageValue(data) {
  return (dispatch, getState) => {
    const state = getState();
    const currentSystemMessage = selectCurrentSystemMessage(state);
    dispatch(
      editItemAttributes(SYSTEM_MESSAGE_MODEL_NAME, currentSystemMessage, data)
    );
  };
}

export const updateSystemNotificationsStatuses = keys => (
  dispatch,
  getState
) => {
  const data = { Notifications: keys };
  const state = getState();
  const agencyID = selectAgency(state);

  const url = generateUrl(NOTIFICATIONS_URL, {
    agency: agencyID
  });

  return dispatch(apiCall(PUT, url, data)).then(
    response => {
      if (response && response.isSuccessful) {
        dispatch(loadNotifications());
      }
      dispatch(closeSystemNotificationDialog());
    },
    error => {
      dispatch(enqueueApplicationErrorMessage(error));
    }
  );
};
