import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import clsx from "clsx";

import { Button } from "@mui/material";

import { CAMPAIGN, CREATE_PAGE } from "../../../../configurations/appConstants";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import { selectCurrentCampaign } from "../../../../business/models/Campaign/campaignSelectors";
import {
  closePlanExtensionFormPage,
  openOrderFormPage
} from "../../../../business/actions/campaignDashboardActions";

import {
  cancelPlanExtension,
  createPlanExtension
} from "../../../../business/models/PlanExtension/planExtensionActions";

import { generateUrlPath } from "../../../../business/models/common/utils/clientUtils";
import { classes, Grid } from "../styles/planExtensionNewActions";

const PlanExtensionNewActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agency } = useParams();

  const isLoading = useSelector(selectIsLoading);
  const campaignId = useSelector(selectCurrentCampaign);

  const handleCancel = () => {
    dispatch(cancelPlanExtension());
    dispatch(closePlanExtensionFormPage()).then(() =>
      navigate(`/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${campaignId}`)
    );
  };

  const handleSave = () => {
    dispatch(createPlanExtension()).then(planId => {
      if (planId) {
        dispatch(closePlanExtensionFormPage()).then(() =>
          navigate(
            `/${agency}/${CREATE_PAGE}${generateUrlPath(campaignId, planId)}`
          )
        );
      }
    });
  };

  const handleSaveAndAddOrder = () => {
    dispatch(createPlanExtension()).then(planId => {
      if (planId) {
        dispatch(openOrderFormPage());
        dispatch(closePlanExtensionFormPage());
      }
    });
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Button
          id="cancel_plan_extension"
          name="cancel_plan_extension"
          className={classes.cancelButton}
          onClick={handleCancel}
          disabled={isLoading}
        >
          CANCEL
        </Button>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-around" spacing={2}>
          <Grid item>
            <Button
              id="save_plan_extension"
              name="save_plan_extension"
              variant="contained"
              className={clsx(classes.saveButton)}
              disabled={isLoading}
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="add_plan_extension_and_order"
              name="add_plan_extension_and_order"
              variant="contained"
              className={clsx(classes.saveButton, classes.markedButton)}
              disabled={isLoading}
              onClick={handleSaveAndAddOrder}
            >
              SAVE AND ADD ORDER
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlanExtensionNewActions;
