import React from "react";
import { Typography } from "@mui/material";
import cuid from "cuid";

import ActionCompareItem from "./ActionCompareItem";
import Scrollbar from "../../../../../shared/Scrollbar";

const ActionComparePanel = ({ properties }) => {
  const arePropertiesExists = properties.length !== 0;

  return arePropertiesExists ? (
    <Scrollbar>
      {properties.map(property => (
        <ActionCompareItem key={cuid()} property={property} />
      ))}
    </Scrollbar>
  ) : (
    <Typography align="center" noWrap>
      no data...
    </Typography>
  );
};

export default ActionComparePanel;
