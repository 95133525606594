import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";

import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";

import { selectCurrentPlanExtension } from "../PlanExtension/planExtensionSelectors";

export const selectPlanChangeOverview = state => state.planChangeOverview;
export const selectPlanChangeOverviewId = (_, props) => props.entityId;
export const selectPropertyName = (_, props) => props.name;
export const selectPlanExtensionId = (_, props) => props.planExtensionId;

export const selectCurrentPlanChangeOverviews = createSelector(
  selectPlanChangeOverview,
  planCo => planCo.currentPlanChangeOverviews
);

export const selectPlanChangeOverviewsIsEditing = createSelector(
  selectPlanChangeOverview,
  planCo => planCo.isEditing
);

export const selectEditingOriginalItem = createSelector(
  selectPlanChangeOverview,
  planCo => planCo.editingOriginalItem
);

export const getPlanChangeOverviewIDs = createSelector(
  [getEntitiesSession, selectCurrentPlanExtension],
  (entitiesSession, planExtensionId) => {
    const { PlanChangeOverview } = entitiesSession;
    return PlanChangeOverview.filter(
      planCo => planCo.planExtension === planExtensionId
    )
      .toRefArray()
      .map(pco => pco.id);
  }
);

export const getPlanChangeOverviewIDsForCopyPlanProcess = createSelector(
  [getEntitiesSession, selectPlanExtensionId],
  (entitiesSession, planExtensionId) => {
    const { PlanChangeOverview } = entitiesSession;
    return PlanChangeOverview.filter(
      planCo => planCo.planExtension === planExtensionId
    )
      .toRefArray()
      .map(pco => pco.id);
  }
);

export const selectEditingPlanChangeOverviewData = createSelector(
  [getEditingEntitiesSession, selectPlanChangeOverview],
  (editingSession, planCo) => {
    const { PlanChangeOverview } = editingSession;
    return PlanChangeOverview.filter(p =>
      planCo.currentPlanChangeOverviews.includes(p.id)
    ).toRefArray();
  }
);

export const selectEditingPlanChangeOverviewDataById = createSelector(
  [selectEditingPlanChangeOverviewData, selectPlanChangeOverviewId],
  (planChangeOverviewData, planCoId) =>
    planChangeOverviewData.find(p => p.id === planCoId)
);

export const getPlanChangeOverviewPropertyValue = createCachedSelector(
  [getEditingEntitiesSession, selectPlanChangeOverviewId, selectPropertyName],
  (editingSession, entityId, name) => {
    const { PlanChangeOverview } = editingSession;
    const planChangeOverviewModel = PlanChangeOverview.get(
      p => p.id === entityId
    );
    return planChangeOverviewModel
      ? planChangeOverviewModel.ref[name]
      : undefined;
  }
)((state, props) => `${props.entityId}:${props.name}`);

export const getPlanChangeOverviewPropertyErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectPlanChangeOverviewId, selectPropertyName],
  (editingSession, entityId, name) => {
    const { PlanChangeOverview } = editingSession;
    const planChangeOverviewModel = PlanChangeOverview.get(
      p => p.id === entityId
    );
    return planChangeOverviewModel
      ? planChangeOverviewModel.validateByProperty(name)
      : undefined;
  }
)((state, props) => `${props.entityId}:${props.name}`);

export const selectPlanChangeOverviewDisplayErrors = createSelector(
  selectPlanChangeOverview,
  planCo => planCo.displayErrors
);

export const doesPlanChangeOverviewHasErrors = createSelector(
  [getEditingEntitiesSession, selectPlanChangeOverviewId],
  (editingSession, planCo) => {
    const { PlanChangeOverview } = editingSession;
    const planChangeOverviewModel = PlanChangeOverview.get(
      p => p.id === planCo
    );

    return planChangeOverviewModel
      ? planChangeOverviewModel.hasErrors()
      : false;
  }
);

export const selectPlanChangeOverviewsByPlanExtensionId = createSelector(
  [getEntitiesSession, selectPlanExtensionId],
  (entitiesSession, planExtensionId) => {
    const { PlanChangeOverview } = entitiesSession;
    return PlanChangeOverview.filter(
      planCo => planCo.planExtension === planExtensionId
    )
      .toRefArray()
      .map(pco => pco.id);
  }
);
