import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@mui/material";

import { toggleMbsConfirmPopup } from "../../../../business/actions/applicationActions";
import { isNullOrUndefined } from "../../../../functions/util";
import {
  updateFlight,
  createFlight
} from "../../../../business/models/Plan/planActions";
import {
  selectFlightForMbs,
  selectPlanForMbs
} from "../../../../business/models/Plan/planSelectors";

const paperProps = { square: true };

const ConfirmSendToMbsDialog = () => {
  const dispatch = useDispatch();
  const flightId = useSelector(selectFlightForMbs);
  const planToSendToMbs = useSelector(selectPlanForMbs);

  const handleMbs = () => {
    const isSent = !isNullOrUndefined(flightId);

    // eslint-disable-next-line no-unused-expressions
    isSent
      ? dispatch(updateFlight(planToSendToMbs, flightId))
      : dispatch(createFlight(planToSendToMbs));

    dispatch(toggleMbsConfirmPopup());
  };

  const handleNo = () => {
    dispatch(toggleMbsConfirmPopup());
  };

  const message = isNullOrUndefined(flightId)
    ? "Are you sure that you want to create a flight in MediaView for this plan?"
    : "Are you sure that you want to update the flight in MediaView for this plan?";
  return (
    <Dialog open PaperProps={paperProps} maxWidth="sm">
      <DialogContent>
        <DialogContentText noWrap={false}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo} color="primary">
          No
        </Button>
        <Button onClick={handleMbs} color="secondary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSendToMbsDialog;
