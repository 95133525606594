import React from "react";
import PropTypes from "prop-types";

import isEmpty from "lodash/isEmpty";
import {
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  TableHead,
  IconButton,
  Grid
} from "@mui/material";

import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

import Typography from "@mui/material/Typography";
import {
  EMPTY_TABLE,
  PLACEHOLDER
} from "../../../../configurations/appConstants";
import PaginationActions from "./ApiManagementTablePaginationActions";

import { classes, Table } from "../styles/apiManagementTableStyle";
import { DeleteItemIcon } from "../../../../assets/styles/icons";
import DynamicFilter from "../../../shared/dynamicFilter/DynamicFilter";
import { defaultOptionsForApiManagement } from "../../../../business/constants/filterOptions";
import {
  rowsPerPageOptions,
  resourceOptions
} from "../../../../business/constants/apiManagementConstants";

const ApiManagementTable = ({
  rows,
  count,
  contentLoading,
  pageSize,
  page,
  onChangePage,
  onChangeRowsPerPage,
  onDelete,
  searchProperty,
  searchText,
  onInputSearch,
  onSearchBy,
  onDynamicFilter,
  queryFilters
}) => {
  return (
    <>
      <Toolbar>
        <Grid
          className={classes.filters}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <DynamicFilter
                searchProperty={searchProperty}
                searchText={searchText}
                onInputSearch={onInputSearch}
                onSearchBy={onSearchBy}
                updateUrl={onDynamicFilter}
                queryFilters={queryFilters}
                filterOptions={defaultOptionsForApiManagement}
                limit={2}
              />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableHeader} align="left">
              Application ID
            </TableCell>
            <TableCell className={classes.tableHeader} align="left">
              Resource
            </TableCell>
            <TableCell
              className={classes.tableHeader}
              align="right"
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(rows)
            ? rows.map(row => {
                const resourceText = resourceOptions.find(
                  r => r.key === row.resource
                );
                return (
                  <TableRow
                    className={classes.tableRow}
                    key={`${row.applicationId}_${row.resource}`}
                  >
                    <TableCell className={classes.tableCell} align="left">
                      <Typography noWrap>
                        {PLACEHOLDER && row.applicationId}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      <Typography noWrap>
                        {PLACEHOLDER && resourceText.text}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      <Grid item>
                        <IconButton
                          name={`am_delete_${row.applicationId}_${row.resource}`}
                          id={`am_delete_${row.applicationId}_${row.resource}`}
                          size="small"
                          onClick={e =>
                            onDelete(row.applicationId, row.resource, e)
                          }
                        >
                          <DeleteItemIcon />
                        </IconButton>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })
            : !contentLoading && (
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell} align="center">
                    {EMPTY_TABLE}
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
        <TableFooter>
          <TableRow className={classes.tableRow}>
            <TablePagination
              colSpan={3}
              count={count}
              rowsPerPage={pageSize}
              page={count ? page : 0}
              rowsPerPageOptions={rowsPerPageOptions}
              SelectProps={{
                native: true
              }}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              ActionsComponent={PaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

ApiManagementTable.propTypes = {
  rows: PropTypes.array,
  count: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  page: PropTypes.number,
  contentLoading: PropTypes.bool
};

ApiManagementTable.defaultProps = {
  rows: [],
  count: 0,
  pageSize: 10,
  page: 0
};

export default ApiManagementTable;
