import React from "react";
import PropTypes from "prop-types";
import { FormControl, FormControlLabel } from "@mui/material";
import { Switch } from "../../../assets/styles/switchToggle";

const SwitchToggle = ({ onChange, label, disabled, value, id, name }) => {
  const handleChange = (...args) => {
    onChange(args[0]);
  };

  return (
    <FormControl fullWidth={false} disabled={disabled}>
      <FormControlLabel
        control={
          <Switch
            id={id}
            checked={value}
            onChange={handleChange}
            disabled={disabled}
            color="primary"
            name={name}
          />
        }
        label={label}
      />
    </FormControl>
  );
};

SwitchToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string
};

SwitchToggle.defaultProps = {
  value: false,
  label: "",
  disabled: false,
  id: "id",
  name: "name"
};

export default SwitchToggle;
