import { styled, Grid as MuiGrid } from "@mui/material";
import { matisse, linkWater } from "../../../assets/styles/colors";

const PREFIX = "TrafficPlanViewStyles";
export const classes = {
  digitalTrafficDetails: `${PREFIX}-digitalTrafficDetails`,
  trafficViewItem: `${PREFIX}-trafficViewItem`,
  typographyDetailsValue: `${PREFIX}-typographyDetailsValue`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  typographyCaptionBold: `${PREFIX}-typographyCaptionBold`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.digitalTrafficDetails}`]: {
    paddingTop: theme.typography.pxToRem(16)
  },
  [`& .${classes.trafficViewItem}`]: {
    width: theme.typography.pxToRem(200),
    height: theme.typography.pxToRem(75)
  },
  [`&.${classes.typographyInsertionHeader}`]: {
    width: theme.typography.pxToRem(200),
    height: theme.typography.pxToRem(75)
  },
  [`& .${classes.typographyDetailsValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyCaptionBold}`]: {
    fontWeight: "bold"
  }
}));
