import React from "react";
import PropTypes from "prop-types";

import cuid from "cuid";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid
} from "@mui/material";

import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import Scrollbar from "../../../../../../shared/Scrollbar";

import {
  classes,
  DialogContent
} from "../../../../styles/trafficExportPreviewTable";
import {
  PLACEHOLDER,
  EMPTY_TABLE
} from "../../../../../../../configurations/appConstants";

const PreviewOoh = ({ data }) => {
  const {
    paperRoot,
    tableCellUnderline,
    scrollTableCell,
    tableCellHeader,
    tableCellHeaderInner
  } = classes;
  const {
    debtorName,
    plannerAgency,
    productName,
    creationDate,
    remarks,
    creativeAgency,
    contact,
    email,
    cc,
    contactGroupm,
    phoneNumberGroupm,
    version,
    imageBase64Contents,
    ordersOoh,
    planName
  } = data;
  return (
    <>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className={paperRoot} elevation={0}>
              <Table>
                <TableBody key={"campaignId"}>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CLIENT NAME
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{debtorName}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        VERSION
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{version}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        PRODUCT NAME
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{productName}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        DATE
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{creationDate}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        PLAN NAME
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{planName}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        REPLACES DATE
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{PLACEHOLDER}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={2} className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        REMARKS
                      </Typography>
                    </TableCell>
                    <TableCell rowSpan={2} colSpan={4}>
                      <Typography align="left">{remarks}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={paperRoot} elevation={0}>
              <Table>
                <TableBody key={"campaignId"}>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CREATIVE AGENCY
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{creativeAgency}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        CONTACT GROUPM
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{contactGroupm}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CONTACT
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{contact}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        EMAIL ADDRESS
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">
                        Ams.GRM-Nexus-team-ooh@groupm.com
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        E-MAIL
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{email}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        PHONE NUMBER
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{phoneNumberGroupm}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CC
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{cc}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        PLANNER AGENCY
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="left">{plannerAgency}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={paperRoot} elevation={0}>
              <Scrollbar
                autoHeightMax={600}
                autoHeightMin={95}
                autoHide={false}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          ORDER NUMBER
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          PLAN NUMBER
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          VENDOR
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          FORMAT
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          NEWTORK / LOCATIONS
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          DESCRIPTION
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          DIGITAL FILE (YES / NO)
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          SPOTLENGTH
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          # OF UNITS
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          # OF UNITS TO DELIVER
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          START DATE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          END DATE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          DELIVERY DATE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          TECHNICAL SPECIFICATIONS
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          DELIVERY ADDRESS
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          VENDOR REFERENCE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(scrollTableCell, tableCellUnderline)}
                      >
                        <Typography variant="subtitle2" align="left" noWrap>
                          REMARKS
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {!isEmpty(ordersOoh) ? (
                      ordersOoh.map((order, index) => {
                        const {
                          deliveryAddress,
                          deliveryDate,
                          description,
                          digitalFile,
                          format,
                          marathonOrderId,
                          marathonPlanId,
                          networkLocations,
                          numOfUnits,
                          numberOfUnitsToDeliver,
                          orderEndDate,
                          orderStartDate,
                          remarks: orderRemakrs,
                          spotLength,
                          technicalSpecifications,
                          title,
                          vendorReference
                        } = order;
                        return (
                          <TableRow
                            key={`${marathonOrderId +
                              marathonPlanId} - ${index}`}
                          >
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {marathonOrderId}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {marathonPlanId}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {title}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {format}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {networkLocations}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {description}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {digitalFile}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {spotLength}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {numOfUnits}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {numberOfUnitsToDeliver}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {orderStartDate}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {orderEndDate}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {deliveryDate}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {technicalSpecifications}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {deliveryAddress}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {vendorReference}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {orderRemakrs}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={17} align="center">
                          <Typography align="center" noWrap>
                            {EMPTY_TABLE}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </Paper>
          </Grid>
          {imageBase64Contents != null &&
            imageBase64Contents.map(image => (
              <Grid item xs={12} key={cuid()}>
                <Paper className={paperRoot} elevation={0}>
                  <Typography align="center" noWrap>
                    <img src={`data:image/jpeg;base64,${image}`} alt="" />
                  </Typography>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
    </>
  );
};

PreviewOoh.propTypes = {
  data: PropTypes.object
};

export default PreviewOoh;
