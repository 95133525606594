import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  Paper,
  Grid
} from "@mui/material";
import PropTypes from "prop-types";

import { isEmpty } from "lodash";
import { EMPTY_TABLE } from "../../../../configurations/appConstants";
import Row from "./TrafficTemplateRow";

import { classes, Table } from "../styles/trafficTemplateManagerPageStyle";
import DynamicFilter from "../../../shared/dynamicFilter/DynamicFilter";
import { defaultOptionsForTrafficTemplate } from "../../../../business/constants/filterOptions";

const TrafficTemplateTable = ({
  data,
  onEdit,
  onDelete,
  searchProperty,
  searchText,
  onInputSearch,
  onSearchBy,
  onDynamicFilter,
  queryFilters
}) => {
  return (
    <Paper elevation={0}>
      <Toolbar>
        <Grid container alignItems="center" spacing={1}>
          <DynamicFilter
            searchProperty={searchProperty}
            searchText={searchText}
            onInputSearch={onInputSearch}
            onSearchBy={onSearchBy}
            updateUrl={onDynamicFilter}
            queryFilters={queryFilters}
            filterOptions={defaultOptionsForTrafficTemplate}
          />
        </Grid>
      </Toolbar>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} align="left">
              Template Name
            </TableCell>
            <TableCell className={classes.header} align="left">
              Description
            </TableCell>
            <TableCell className={classes.header} align="left">
              Dimensions
            </TableCell>
            <TableCell className={classes.header} align="left">
              Weight
            </TableCell>
            <TableCell className={classes.header} align="left">
              Creative Type
            </TableCell>
            <TableCell className={classes.header} align="left">
              Animation/Looping
            </TableCell>
            <TableCell className={classes.header} align="left">
              Framerate
            </TableCell>
            <TableCell className={classes.header} align="left">
              AdServer
            </TableCell>
            <TableCell className={classes.header} align="left">
              Contact
            </TableCell>
            <TableCell className={classes.header} align="left">
              Remarks
            </TableCell>
            <TableCell className={classes.header} align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(data) ? (
            data.map(row => (
              <Row
                key={row.id}
                data={row}
                onHandleEdit={onEdit}
                onHandleDelete={onDelete}
              />
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={12}>
                {EMPTY_TABLE}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

TrafficTemplateTable.propTypes = {
  data: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default TrafficTemplateTable;
