import { styled, Grid as MuiGrid } from "@mui/material";
import { supernova, white, softRed } from "../../../../assets/styles/colors";

const PREFIX = "CalculationFormStyles";
export const classes = {
  saveButton: `${PREFIX}-saveButton`,
  cancelButton: `${PREFIX}-cancelButton`
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.saveButton}`]: {
    float: "right",
    color: white,
    backgroundColor: supernova
  },
  [`& .${classes.cancelButton}`]: {
    float: "right",
    color: white,
    backgroundColor: softRed
  }
}));
