import { object, number, boolean, array } from "yup";
import isString from "lodash/isString";

const INVALID_NUMBER = "Invalid number";
const REQUIRED_APPROVER = "Must have approver for active rule";
const INVALID_NEGATIVE_NUMBER = "Must be a negative number";
const INVALID_POSITIVE_NUMBER = " Must be greater than or equal to 0";

// #region Helpers
const formatNumber = () =>
  number().transform((o, v) => {
    let val = v;
    if (isString(v)) {
      val = v.replace(/,/g, "");
    }
    return parseFloat(val);
  });
// #endregion

const validationSchema = object().shape({
  negativeAmountNumOfUnits: formatNumber()
    .typeError(INVALID_NUMBER)
    .negative(INVALID_NEGATIVE_NUMBER),
  negativeAmountDiscountAmount: formatNumber()
    .typeError(INVALID_NUMBER)
    .negative(INVALID_NEGATIVE_NUMBER),
  negativeAmountNetTotal: formatNumber()
    .typeError(INVALID_NUMBER)
    .negative(INVALID_NEGATIVE_NUMBER),
  negativeAmountSurcharge1Amount: formatNumber()
    .typeError(INVALID_NUMBER)
    .negative(INVALID_NEGATIVE_NUMBER),
  negativeAmountSurcharge2Amount: formatNumber()
    .typeError(INVALID_NUMBER)
    .negative(INVALID_NEGATIVE_NUMBER),
  negativeAmountCtc: formatNumber()
    .typeError(INVALID_NUMBER)
    .negative(INVALID_NEGATIVE_NUMBER),
  negativeAmountRule: boolean(),
  negativeAmountApprovers: array().when("negativeAmountRule", {
    is: true,
    then: array().required(REQUIRED_APPROVER)
  }),
  exceedsThresholdNumOfUnits: formatNumber()
    .typeError(INVALID_NUMBER)
    .min(0, INVALID_POSITIVE_NUMBER),
  exceedsThresholdDiscountAmount: formatNumber()
    .typeError(INVALID_NUMBER)
    .min(0, INVALID_POSITIVE_NUMBER),
  exceedsThresholdNetTotal: formatNumber()
    .typeError(INVALID_NUMBER)
    .min(0, INVALID_POSITIVE_NUMBER),
  exceedsThresholdSurcharge1Amount: formatNumber()
    .typeError(INVALID_NUMBER)
    .min(0, INVALID_POSITIVE_NUMBER),
  exceedsThresholdSurcharge2Amount: formatNumber()
    .typeError(INVALID_NUMBER)
    .min(0, INVALID_POSITIVE_NUMBER),
  exceedsThresholdCtc: formatNumber()
    .typeError(INVALID_NUMBER)
    .min(0, INVALID_POSITIVE_NUMBER),
  exceedsThresholdRule: boolean(),
  exceedsThresholdApprovers: array().when("exceedsThresholdRule", {
    is: true,
    then: array().required(REQUIRED_APPROVER)
  }),
  highRiskSurchargeSurcharge1TypeId: array(),
  highRiskSurchargeSurcharge2TypeId: array(),
  highRiskSurchargeRule: boolean(),
  highRiskSurchargeApprovers: array().when("highRiskSurchargeRule", {
    is: true,
    then: array().required(REQUIRED_APPROVER)
  }),
  highRiskTitleTitleId: array(),
  highRiskTitleRule: boolean(),
  highRiskTitleApprovers: array().when("highRiskTitleRule", {
    is: true,
    then: array().required(REQUIRED_APPROVER)
  }),
  foreignCurrencyCurrencyClient: boolean(),
  foreignCurrencyCurrencySupplierId: boolean(),
  foreignCurrencyRule: boolean(),
  foreignCurrencyApprovers: array().when("foreignCurrencyRule", {
    is: true,
    then: array().required(REQUIRED_APPROVER)
  })
});

export default validationSchema;
