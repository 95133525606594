import { Box } from "@mui/material";
import SystemMessageField from "../../../form/SystemMessageField";

const PREFIX = "SystemMessagesDialogStyle";

// eslint-disable-next-line import/prefer-default-export
export const SystemMessageFieldCheckbox = props => {
  const { id, name, label } = props;

  return (
    <Box component="grid" sx={{ pb: 2, pl: 2, pt: 3 }}>
      <SystemMessageField
        component="checkbox"
        id={id}
        name={name}
        label={label}
      >
        Save
      </SystemMessageField>
    </Box>
  );
};
