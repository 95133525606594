import { styled } from "@mui/material";
import { getThemeSpacing } from "../../../functions/util";

const PREFIX = "PrivacyStatementStyles";
export const classes = {
  container: `${PREFIX}-container`,
  margin: `${PREFIX}-margin`,
  ol: `${PREFIX}-ol`,
  ul: `${PREFIX}-ul`
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.container}`]: {
    margin: getThemeSpacing(theme, 2)
  },
  [`& .${classes.margin}`]: {
    marginTop: getThemeSpacing(theme, 8),
    marginLeft: getThemeSpacing(theme, 4)
  },
  [`& .${classes.ol}`]: {
    "& li": {
      marginTop: getThemeSpacing(theme, 2)
    }
  },
  [`& .${classes.ul}`]: {
    "& li": {
      marginTop: getThemeSpacing(theme, 2)
    }
  }
}));
