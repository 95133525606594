import { Model, attr } from "redux-orm";
import set from "lodash/set";
import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";

import { USER_MODEL_NAME, USER_MODEL_ID } from "./userConstants";
import {
  MANDATORY_RULE,
  EMAIL_RULE,
  MANDATORY_MESSAGE,
  EMAIL_MESSAGE,
  NUMBER_OF_CHARACTERS_RULE,
  NUMBER_OF_CHARACTERS_MESSAGE_4,
  NUMBER_OF_CHARACTERS_MESSAGE_150
} from "../../constants/validationConstants";

export const defaultAttributes = {
  isActive: false,
  isAdmin: false,
  isAgent: false,
  roleIds: [],
  agentForIds: []
};

class User extends Model {
  static get fields() {
    return {
      id: attr(),
      firstName: attr(),
      lastName: attr(),
      userName: attr(),
      marathonUser: attr(),
      agencyId: attr(),
      isActive: attr(),
      isAdmin: attr(),
      isAgent: attr(),
      roleIds: attr(),
      agentForIds: attr(),
      groupmRoles: attr(),
      kineticRoles: attr(),
      mindshareRoles: attr(),
      mediacomRoles: attr(),
      wavemakerRoles: attr(),
      blossomRoles: attr(),
      greenhouseoneRoles: attr(),
      greenhousetechRoles: attr(),
      choreographcreateRoles: attr()
    };
  }

  static parse(userData) {
    if (userData[USER_MODEL_ID]) {
      set(userData, "id", userData[USER_MODEL_ID]);
    }

    return this.upsert(userData);
  }

  static updateModel(userData) {
    return this.update(userData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherUser) {
    this.update(otherUser.ref);
  }

  static get validation() {
    return {
      lastName: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_150,
          options: {
            min: 1,
            max: 150
          }
        }
      ],
      firstName: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_150,
          options: {
            min: 1,
            max: 150
          }
        }
      ],
      userName: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: EMAIL_RULE,
          message: EMAIL_MESSAGE
        }
      ],
      marathonUser: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_4, // TODO: check if it's exact 4 insted of max 4.
          options: {
            min: 1,
            max: 4
          }
        }
      ],
      agencyId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      roleIds: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      isActive: []
    };
  }

  validate(excludedFields) {
    return validateModel(this.validation, this.toJSON(), excludedFields);
  }

  validateByProperty(name, isExcluded) {
    return validateProperty(User.validation[name], this.ref[name], isExcluded);
  }

  hasErrors(excludedFields) {
    return doesModelHaveErrors(User.validation, this.toJSON(), excludedFields);
  }

  static get validationRestrictions() {
    return {
      roleIds: ["isUserNotActive", "isUserAdmin"]
    };
  }

  static get disabledFields() {
    return {
      isAdmin: ["isUserNotActive"],
      roleIds: ["isUserNotActive", "isUserAdmin"],
      isAgent: ["isUserNotActive", "isUserAdmin"],
      agentForIds: ["isUserNotActive", "isUserNotAgent", "isUserAdmin"],
      groupmRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isGroupmNotSelected"
      ],
      kineticRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isKineticNotSelected"
      ],
      mindshareRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isMindshareNotSelected"
      ],
      mediacomRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isMediacomNotSelected"
      ],
      wavemakerRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isWavemakerNotSelected"
      ],
      blossomRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isBlossomNotSelected"
      ],
      greenhouseoneRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isGreenHouseOneNotSelected"
      ],
      greenhousetechRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isGreenHouseTechNotSelected"
      ],
      choreographcreateRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isChoreographCreateNotSelected"
      ]
    };
  }

  static get resetFields() {
    return {
      isAdmin: ["isUserNotActive"],
      roleIds: ["isUserNotActive", "isUserAdmin"],
      isAgent: ["isUserNotActive", "isUserAdmin"],
      agentForIds: ["isUserNotActive", "isUserNotAgent", "isUserAdmin"],
      groupmRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isGroupmNotSelected"
      ],
      kineticRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isKineticNotSelected"
      ],
      mindshareRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isMindshareNotSelected"
      ],
      mediacomRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isMediacomNotSelected"
      ],
      wavemakerRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isWavemakerNotSelected"
      ],
      blossomRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isBlossomNotSelected"
      ],
      greenhouseoneRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isGreenHouseOneNotSelected"
      ],
      greenhousetechRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isGreenHouseTechNotSelected"
      ],
      choreographcreateRoles: [
        "isUserNotActive",
        "isUserNotAgent",
        "isUserAdmin",
        "isChoreographCreateNotSelected"
      ]
    };
  }
}

User.modelName = USER_MODEL_NAME;

export default User;
