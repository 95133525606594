import React, { memo } from "react";
import { useSelector } from "react-redux";

import Cinema from "./byMediaType/Traffic/Item/FoldCinema";
import Digital from "./byMediaType/Traffic/Item/FoldDigital";
import Ooh from "./byMediaType/Traffic/Item/FoldOoh";
import Print from "./byMediaType/Traffic/Item/FoldPrint";
import Radio from "./byMediaType/Traffic/Item/FoldRadio";
import Tv from "./byMediaType/Traffic/Item/FoldTv";
import {
  MEDIATYPE_DIGITAL,
  MEDIATYPE_PRINT,
  MEDIATYPE_OOH,
  MEDIATYPE_TV,
  MEDIATYPE_RADIO,
  MEDIATYPE_CINEMA,
  MEDIATYPE_INVENTORY
} from "../../../configurations/appConstants";
import { selectTrafficItemDisplayErrors } from "../../../business/models/TrafficItem/trafficItemSelectors";

const TrafficLineFold = ({
  mediaTypeId,
  editMode,
  areTrafficAgentFieldsDisabled,
  isDebtorActive
}) => {
  const displayErrors = useSelector(state =>
    selectTrafficItemDisplayErrors(state)
  );

  switch (mediaTypeId) {
    case MEDIATYPE_PRINT:
      return (
        <Print
          displayErrors={displayErrors}
          editMode={editMode}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    case MEDIATYPE_DIGITAL:
    case MEDIATYPE_INVENTORY:
      return (
        <Digital
          displayErrors={displayErrors}
          editMode={editMode}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    case MEDIATYPE_OOH:
      return (
        <Ooh
          displayErrors={displayErrors}
          editMode={editMode}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    case MEDIATYPE_RADIO:
      return (
        <Radio
          displayErrors={displayErrors}
          editMode={editMode}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    case MEDIATYPE_TV:
      return (
        <Tv
          displayErrors={displayErrors}
          editMode={editMode}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    case MEDIATYPE_CINEMA:
      return (
        <Cinema
          displayErrors={displayErrors}
          editMode={editMode}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    default:
      return null;
  }
};

export default memo(TrafficLineFold);
