import { styled, Grid as MuiGrid } from "@mui/material";
import { solitude } from "../../../assets/styles/colors";

const PREFIX = "EmptyTrafficItemsLabelStyles";
export const classes = {
  fullHeight: `${PREFIX}-fullHeight`,
  color: `${PREFIX}-color`
};

export const Grid = styled(MuiGrid)(() => ({
  [`&.${classes.fullHeight}`]: {
    height: "100%"
  },
  [`& .${classes.color}`]: {
    color: solitude
  }
}));
