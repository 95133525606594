import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

import { Button } from "@mui/material";

import ConfirmPlanSaveDialog from "../popup/ConfirmPlanSaveDialog";
import ConfirmAgreementDialog from "../popup/ConfirmAgreementDialog";
import ConfirmMediaTypeDialog from "../popup/ConfirmMediaTypeDialog";

import {
  togglePlanConfirmPopup,
  toggleAgreementConfirmPopup,
  toggleMediaTypeConfirmPopup
} from "../../../../business/actions/applicationActions";
import {
  cancelPlan,
  updatePlan
} from "../../../../business/models/Plan/planActions";
import {
  closePlanFormPage,
  openPlanExtensionFormPage
} from "../../../../business/actions/campaignDashboardActions";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import {
  selectCascadingSaveFlag,
  selectInvoicedOrderAgreementChangeFlag,
  selectDeletePlansTargetMetricsKpiData
} from "../../../../business/models/Plan/planSelectors";
import { getCampaignData } from "../../../../business/models/Campaign/campaignSelectors";
import { editPlanExtension } from "../../../../business/models/PlanExtension/planExtensionActions";

import { classes, Grid } from "../styles/planEditActions";

const PlanEditActions = () => {
  const dispatch = useDispatch();

  const [redirect, setRedirect] = useState(false);

  const isLoading = useSelector(selectIsLoading);
  const willTriggerCascadingSave = useSelector(selectCascadingSaveFlag);
  const agreementChangeForInvoicedOrder = useSelector(
    selectInvoicedOrderAgreementChangeFlag
  );
  const deletePlansTargetMetricsKpis = useSelector(
    selectDeletePlansTargetMetricsKpiData
  );
  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const handleCancel = () => {
    dispatch(cancelPlan());
    dispatch(closePlanFormPage());
  };

  const executeSave = () => {
    dispatch(updatePlan()).then(id => {
      if (id) {
        if (redirect) {
          dispatch(editPlanExtension());
          dispatch(openPlanExtensionFormPage());
          setRedirect(false);
        }
        dispatch(closePlanFormPage());
      } else {
        setRedirect(false);
      }
    });
  };

  const commitSave = () => {
    if (deletePlansTargetMetricsKpis) {
      dispatch(toggleMediaTypeConfirmPopup());
    } else {
      executeSave();
    }
  };

  const handleSave = () => {
    if (agreementChangeForInvoicedOrder) {
      dispatch(toggleAgreementConfirmPopup());
    } else {
      commitSave();
    }
  };

  const handleSavePlan = () => {
    if (willTriggerCascadingSave) {
      dispatch(togglePlanConfirmPopup());
    } else {
      handleSave();
    }
  };

  useEffect(() => {
    if (redirect) {
      handleSavePlan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  const handleSavePlanAndEditAdditionalData = useCallback(() => {
    setRedirect(true);
  }, []);

  const resetRedirect = () => {
    setRedirect(false);
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button
            className={classes.cancelButton}
            disabled={isLoading}
            onClick={handleCancel}
          >
            CANCEL
          </Button>
        </Grid>
        {isDebtorActive && (
          <Grid item>
            <Grid container justifyContent="space-around" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  className={clsx(classes.saveButton, classes.addPlanButton)}
                  disabled={isLoading}
                  onClick={handleSavePlanAndEditAdditionalData}
                >
                  SAVE CHANGES AND EDIT ADDITIONAL PLAN DATA
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={clsx(classes.saveButton, classes.markedButton)}
                  disabled={isLoading}
                  onClick={handleSavePlan}
                >
                  SAVE CHANGES
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ConfirmPlanSaveDialog onOk={handleSave} />
      <ConfirmAgreementDialog onOk={commitSave} />
      <ConfirmMediaTypeDialog onOk={executeSave} onCancel={resetRedirect} />
    </>
  );
};

export default PlanEditActions;
