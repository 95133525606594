import React, { memo, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  Typography,
  MenuItem,
  IconButton,
  Menu,
  ListItemText
} from "@mui/material";
import Select from "react-select";
import { SaveIcon, DeleteItemIcon } from "../../../../assets/styles/icons";
import { classes, FormControl } from "../styles/saveSearch";
import {
  OVERVIEW_PAGE,
  EMPTY_STRING
} from "../../../../configurations/appConstants";
import {
  loadSaveSearches,
  deleteCampaignSaveSearch,
  setSelectedSaveSearch
} from "../../../../business/actions/overviewPageActions";
import SaveSearchDialog from "../../traffic-dashboard/dialog/SaveSearchDialog";
import { selectUserId } from "../../../../business/selectors/userProfileSelectors";
import { defaultOptionForSaveSearch } from "../../../../business/constants/overviewPageConstants";
import { selectCurrentlySaveSearch } from "../../../../business/selectors/overviewPageSelectors";

const arrayDefaultOption = new Array(defaultOptionForSaveSearch);

const SaveSearch = onChangePage => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { campaignStatus, agency } = useParams();

  const selectedSearch = useSelector(selectCurrentlySaveSearch);
  const [list, setList] = useState([]);
  const [refetch, setRefetch] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSaveAs, setSaveAs] = useState(false);

  const userId = useSelector(selectUserId);
  const isExistQueryString = location.search !== EMPTY_STRING;

  const setQueryParams = useCallback(
    params => {
      navigate(
        `/${agency}/${OVERVIEW_PAGE}/${campaignStatus}${params || EMPTY_STRING}`
      );
    },
    [navigate, agency, campaignStatus]
  );

  // context menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = event => {
    event.stopPropagation();
    handleClose();
  };

  // select
  const handleChange = savedSearch => {
    dispatch(setSelectedSaveSearch(savedSearch));
    setQueryParams(savedSearch.path);
    onChangePage(undefined, 0);
  };

  // save
  const handleSave = () => {
    setOpenDialog(true);
  };

  // saveAs
  const handleSaveAs = () => {
    setOpenDialog(true);
    setSaveAs(true);
  };

  // delete
  const handleDelete = () => {
    dispatch(deleteCampaignSaveSearch(selectedSearch.value)).then(
      () => setRefetch(true),
      setQueryParams(EMPTY_STRING)
    );
  };

  useEffect(() => {
    if (userId && refetch) {
      dispatch(loadSaveSearches()).then(response => {
        setList(
          arrayDefaultOption.concat(
            response.campaignSearches.map(s => {
              return {
                label: s.searchName,
                value: s.campaignSearchId,
                path: s.value
              };
            })
          )
        );
        setRefetch(false);
        dispatch(setSelectedSaveSearch(defaultOptionForSaveSearch));
      });
    }
  }, [dispatch, userId, refetch, setQueryParams, setList, selectedSearch]);

  const isDisabled = selectedSearch?.value === defaultOptionForSaveSearch.value;

  return (
    <>
      <Typography display="inline">Saved Searches: </Typography>
      <FormControl className={classes.content}>
        <Select
          id="select_saved_search"
          name="select_saved_search"
          options={list}
          onChange={handleChange}
          value={selectedSearch}
        ></Select>
      </FormControl>
      <IconButton
        name={`save_search`}
        id={`save_search`}
        aria-controls="saveSearch-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <SaveIcon />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleMenuClick}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <MenuItem
          onClick={handleSave}
          selected={false}
          disabled={!isExistQueryString}
        >
          <ListItemText primary="Save" />
        </MenuItem>
        <MenuItem
          onClick={handleSaveAs}
          selected={false}
          disabled={!isExistQueryString}
        >
          <ListItemText primary="Save as" />
        </MenuItem>
      </Menu>
      <IconButton
        name={`delete_save_search`}
        id={`delete_save_search`}
        onClick={handleDelete}
        disabled={isDisabled}
      >
        <DeleteItemIcon disabled={isDisabled} />
      </IconButton>
      {openDialog && (
        <SaveSearchDialog
          searchN={selectedSearch?.label || EMPTY_STRING}
          searchId={selectedSearch?.value || defaultOptionForSaveSearch.value}
          onRefetch={setRefetch}
          onOpenDialog={setOpenDialog}
          open={true}
          isSaveAs={isSaveAs}
          setSaveAs={setSaveAs}
          setQueryParams={setQueryParams}
        />
      )}
    </>
  );
};

export default memo(SaveSearch);
