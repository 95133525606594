import React from "react";

import clsx from "clsx";
import Field from "../../../../../form/TrafficItemField";
import { classes, Grid } from "../../../../styles/fold";

const FoldOoh = ({
  editMode,
  areTrafficAgentFieldsDisabled,
  displayErrors,
  isDebtorActive
}) => {
  return (
    <Grid container justifyContent="flex-start">
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="trafficItemId"
          name="trafficItemId"
          helperText="Traffic Item ID"
          label="Traffic Item ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="marathonOrderId"
          name="marathonOrderId"
          helperText="Order ID"
          label="Order ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="orderStartDate"
          name="orderStartDate"
          helperText="Start Date"
          label="Start Date"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="orderEndDate"
          name="orderEndDate"
          helperText="End Date"
          label="End Date"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="orderNumOfUnits"
          name="orderNumOfUnits"
          helperText="# of Units"
          label="# of Units"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="decimal"
          id="numberOfUnitsToDeliver"
          name="numberOfUnitsToDeliver"
          helperText="# of Units to Deliver"
          label="# of Units to Deliver"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="marathonPlanId"
          name="marathonPlanId"
          helperText="Plan ID"
          label="Plan ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="orderDescription"
          name="orderDescription"
          helperText="Description"
          label="Description"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="orderFormat"
          name="orderFormat"
          helperText="Format"
          label="Format"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="networkLocations"
          name="networkLocations"
          helperText="Network/Locations"
          label="Network/Locations"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="autocomplete"
          id="digitalFileId"
          name="digitalFileId"
          helperText="Digital File"
          label="Digital File"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
          textFieldClassName={classes.field}
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="spotLength"
          name="spotLength"
          helperText="Spotlength"
          label="Spotlength"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="deliveryDate"
          name="deliveryDate"
          helperText="Delivery Date"
          label="Delivery Date"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          hideIcon={!editMode}
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="technicalSpecifications"
          name="technicalSpecifications"
          helperText="Technical Specifications"
          label="Technical Specifications"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="deliveryAddress"
          name="deliveryAddress"
          helperText="Delivery Address"
          label="Delivery Address"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="vendorReference"
          name="vendorReference"
          helperText="Vendor Reference"
          label="Vendor Reference"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
        xs={12}
      >
        <Field
          component="text"
          id="trafficItemRemarks"
          name="trafficItemRemarks"
          helperText="Remarks"
          label="Remarks"
          variant="standard"
          disabled={!editMode || !isDebtorActive}
          rowsMax="6"
          multiline
          isLabelHighlighted
        />
      </Grid>
    </Grid>
  );
};

export default FoldOoh;
