import { styled, Dialog as MuiDialog } from "@mui/material";

const PREFIX = "insertionPopupStyles";
export const classes = {
  insertionPopup: `${PREFIX}-insertionPopup`
};

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${classes.insertionPopup}`]: {
    width: theme.typography.pxToRem(1500),
    minHeight: theme.typography.pxToRem(500),
    overflow: "visible"
  }
}));
