import {
  CAMPAIGN_MODEL_ID,
  CAMPAIGN_PROCESS_MANAGER_ROLE_ID,
  CAMPAIGN_SEARCH_ID
} from "../business/models/Campaign/campaignConstants";
import { PROJECT_MODEL_ID } from "../business/models/Project/projectConstants";
import {
  PLAN_MODEL_ID,
  PLAN_MODEL_FLIGHT_ID,
  PLAN_PLANNER_ROLE_ID
} from "../business/models/Plan/planConstants";
import { PROCESS_MODEL_ID } from "../business/constants/copyPlanConstants";
import { PLAN_EXTENSION_MODEL_ID } from "../business/models/PlanExtension/planExtensionConstants";
import { USER_MODEL_ID } from "../business/constants/userProfileConstants";
import { ORDER_MODEL_ID } from "../business/models/Order/orderConstants";
import { TRAFFIC_PLAN_MODEL_ID } from "../business/models/TrafficPlan/trafficPlanConstants";
import { TRAFFIC_ITEM_MODEL_ID } from "../business/models/TrafficItem/trafficItemConstants";
import { SYSTEM_MESSAGE_MODEL_ID } from "../business/models/SystemMessage/systemMessageConstants";
import { APPROVER_ROLE_ID } from "../business/constants/configurationPageConstants";
import { TRAFFIC_TEMPLATE_MODEL_ID } from "../business/models/TrafficTemplate/trafficTemplateConstants";

const BASE = "agency/:agency";

// Media
export const GET_DEBTORS = `${BASE}/media/debtors`;
export const GET_PRODUCTS = `${BASE}/media/products`;
export const GET_TRAFFIC_REQUESTS = `${BASE}/traffic/media/trafficrequest`;
export const GET_THIRD_PARTY_MEASUREMENTS = `${BASE}/traffic/media/thirdpartymeasurement`;
export const GET_AGREEMENTS = `${BASE}/media/agreements`;
export const GET_MEDIA_TYPES = `${BASE}/media/media-types`;
export const GET_AGREEMENTDETAILS = `${BASE}/media/agreement-details`;
export const GET_CURRENCYSUPPLIERS = `${BASE}/media/currency-suppliers`;
export const GET_CHANNELS = `${BASE}/media/channels`;
export const GET_MEDIAS = `${BASE}/media/medias`;
export const GET_DIGITAL_CHANNELS = `${BASE}/media/digital-channels`;
export const GET_FORMATS = `${BASE}/media/formats`;
export const GET_UNITS = `${BASE}/media/units`;
export const GET_DISCOUNT_CODES = `${BASE}/media/discount-codes`;
export const GET_SURCHARGE_CODES = `${BASE}/media/surcharge-codes`;
export const GET_CLIENT_STATUS_FOR_CAMPAIGN_PAGE = `${BASE}/media/client-status-for-campaign-page`;
export const GET_JOB_TYPES = `${BASE}/media/job-types`;

// System Message
export const GET_SYSTEM_MESSAGES_DATA = `${BASE}/message/system-messages`;
export const SAVE_SYSTEM_MESSAGE = `${BASE}/message/system-message`;
export const UPDATE_SYSTEM_MESSAGE = `${BASE}/message/system-message/${SYSTEM_MESSAGE_MODEL_ID}`;
export const DOWNLOAD_SYSTEM_MESSAGE_ATTACHMENT = `${BASE}/notifications/system-message/:${SYSTEM_MESSAGE_MODEL_ID}/attachment`;

// Traffic Template
export const SAVE_TRAFFIC_TEMPLATE = `${BASE}/traffic-template`;
export const DELETE_TRAFFIC_TEMPLATE = `${BASE}/traffic-template/:${TRAFFIC_TEMPLATE_MODEL_ID}`;
export const UPDATE_TRAFFIC_TEMPLATE = `${BASE}/traffic-template/:${TRAFFIC_TEMPLATE_MODEL_ID}`;
export const GET_TRAFFIC_DIGITAL_TEMPLATES = `${BASE}/traffic/media/traffic-template`;
export const GET_TRAFFIC_DIGITAL_TEMPLATE = `${BASE}/traffic-template/:${TRAFFIC_TEMPLATE_MODEL_ID}`;

// User
export const GET_USERS = `${BASE}/user-management/user`;
export const GET_PROCESS_MANAGER_USERS = `${BASE}/user-management/role/${CAMPAIGN_PROCESS_MANAGER_ROLE_ID}/user`;
export const GET_PLANNER_USERS = `${BASE}/user-management/role/${PLAN_PLANNER_ROLE_ID}/user`;
export const GET_APPROVERS = `${BASE}/user-management/role/${APPROVER_ROLE_ID}/user`;
export const GET_USERS_DATA = `${BASE}/user-management/user-data`;
export const GET_USER_PROFILE = `${BASE}/user-management/user-profile`;
export const GET_USER_SETTINGS = `${BASE}/user-management/user/:${USER_MODEL_ID}/settings`;
export const GET_USER_AGENCIES = `${BASE}/user-management/user-agency`;
export const SAVE_USER = `${BASE}/user-management/user`;
export const SAVE_USER_SETTINGS = `${BASE}/user-management/user/:${USER_MODEL_ID}/settings`;
export const CLEAR_SECURITY_CACHE = `${BASE}/user-management/security`;
export const CLEAR_MEDIA_CACHE = `${BASE}/user-management/media`;

// Campaign
export const CREATE_CAMPAIGN = `${BASE}/campaign`;
export const UPDATE_CAMPAIGN = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}`;
export const CLIENT_CREDIT = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/client-creadit`;

export const LOAD_ENTITY_DATA = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}`;

export const GET_PROJECTS = `${BASE}/campaign/projects`;
export const CREATE_PROJECT = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/project`;
export const UPDATE_PROJECT = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/project/:${PROJECT_MODEL_ID}`;

export const CREATE_PLAN = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/plan`;
export const UPDATE_PLAN = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/plan/:${PLAN_MODEL_ID}`;
export const LOAD_ORDER_BUYING_STATUSES = `${BASE}/plan/:${PLAN_MODEL_ID}/orderbuyingstatuses`;
export const GET_CTC_INFO_CAMPAIGN = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/ctcinfo`;
export const GET_CTC_INFO_PLAN = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/plan/:${PLAN_MODEL_ID}/ctcinfo`;

export const CREATE_PLAN_EXTENSION = `${BASE}/plan/:${PLAN_MODEL_ID}/plan-extension`;
export const UPDATE_PLAN_EXTENSION = `${BASE}/plan/:${PLAN_MODEL_ID}/plan-extension/:${PLAN_EXTENSION_MODEL_ID}`;

export const SET_CAMPAIGN_ACTUAL = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/actual`;
export const SET_ACTUAL = `${BASE}/plan/:${PLAN_MODEL_ID}/actual`;

export const CREATE_FLIGHT = `${BASE}/plan/:${PLAN_MODEL_ID}/flight`;
export const UPDATE_FLIGHT = `${BASE}/plan/:${PLAN_MODEL_ID}/flight/:${PLAN_MODEL_FLIGHT_ID}`;

export const CREATE_ORDER = `${BASE}/plan/:${PLAN_MODEL_ID}/order`;
export const UPDATE_ORDER = `${BASE}/plan/:${PLAN_MODEL_ID}/order/:${ORDER_MODEL_ID}`;
export const DELETE_ORDER = `${BASE}/plan/:${PLAN_MODEL_ID}/order/:${ORDER_MODEL_ID}`;

export const ORDER_STATUS_CHANGE = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/order/next-status`;

export const PLAN_ORDERS = `${BASE}/plan/:${PLAN_MODEL_ID}/order`;

export const ORDERS_DIVIDE = `${BASE}/order/divide`;

export const GET_ORDER_CLIENT_STATUS_LOG = `${BASE}/order/:${ORDER_MODEL_ID}/status-logs`;

export const DO_NOT_NOTIFY_INSERTION_CHANGE = `${BASE}/plan/:${PLAN_MODEL_ID}/do-not-notify-insertion-change`;

// Copy Plan Process
export const INIT_COPY_PLAN = `${BASE}/copy`;
export const RESUME_COPY_PLAN = `${BASE}/copy/:${PROCESS_MODEL_ID}/resume`;
export const DELETE_COPY_PLAN = `${BASE}/copy/:${PROCESS_MODEL_ID}`;
export const CREATE_CAMPAIGN_COPY_PLAN = `${BASE}/copy/:${PROCESS_MODEL_ID}/campaign`;
export const CREATE_PLAN_COPY_PLAN = `${BASE}/copy/:${PROCESS_MODEL_ID}/campaign/:${CAMPAIGN_MODEL_ID}/plan`;
export const CREATE_PLAN_EXTENSION_COPY_PLAN = `${BASE}/copy/:${PROCESS_MODEL_ID}/plan/:${PLAN_MODEL_ID}/plan-extension`;
export const CREATE_ORDER_COPY_PLAN = `${BASE}/copy/:${PROCESS_MODEL_ID}/campaign/:${CAMPAIGN_MODEL_ID}/plan/:${PLAN_MODEL_ID}/order`;
export const SKIP_ORDER_COPY_PLAN = `${BASE}/copy/:${PROCESS_MODEL_ID}/order`;

// Action Page
export const APPROVE_ORDERS = `${BASE}/order/approve`;
export const DECLINE_ORDERS = `${BASE}/order/decline`;

export const LOAD_ACTION_ENTITY_DATA = `${BASE}/action/:${CAMPAIGN_MODEL_ID}`;

export const GET_ACTION_CAMPAIGNS = `${BASE}/action-overview/campaigns`;
export const GET_ORDER_CHANGES = `${BASE}/action/orders/:${ORDER_MODEL_ID}`;

// Configuration Page
export const ADDITIONAL_APPROVAL_SETTINGS = `${BASE}/additional-approval/settings`;
export const GET_RULE_PROPERTIES = `${BASE}/additional-approval/ruleproperties`;

// Overview Page
export const GET_CAMPAIGN_EXPORT = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/excel`;
export const GET_PLAN_EXPORT = `${BASE}/plan/:${PLAN_MODEL_ID}/excel`;
export const GET_PLAN_EXPORT_COMPONENTS = `${BASE}/plan/excel-components`;
export const GET_CAMPAIGN_TRAFFIC = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/plan/:${PLAN_MODEL_ID}/traffic`;
export const EMAIL_CAMPAIGN_TRAFFIC = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/plan/:${PLAN_MODEL_ID}/traffic`;
export const GET_CAMPAIGN_TRAFFIC_OPTIONS = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/traffic`;
export const GET_CAMPAIGNS = `${BASE}/campaign`;
export const GET_CAMPAIGNS_COUNT_BY_STATUS = `${BASE}/campaign/bystatus`;
export const CAMPAIGN_FAVOURITE = `${BASE}/campaign/:${CAMPAIGN_MODEL_ID}/favourite`;
export const GET_SAVED_SEARCHES = `${BASE}/user-management/user/:${USER_MODEL_ID}/campaign-search`;
export const CREATE_SAVE_SEARCH = `${BASE}/user-management/user/:${USER_MODEL_ID}/campaign-search`;
export const DELETE_SAVE_SEARCH = `${BASE}/user-management/user/:${USER_MODEL_ID}/campaign-search/:${CAMPAIGN_SEARCH_ID}`;
export const EDIT_SAVE_SEARCH = `${BASE}/user-management/user/:${USER_MODEL_ID}/campaign-search/:${CAMPAIGN_SEARCH_ID}`;

// Traffic Overview Page
export const GET_TRAFFIC_PLANS = `${BASE}/traffic/traffic-overview`;
export const GET_TRAFFIC_PLANS_COUNT_BY_STATUS = `${BASE}/traffic/statuscount`;

// Traffic Dachboard Page
export const GET_TRAFFIC_DASHBOARD = `${BASE}/plan/:${PLAN_MODEL_ID}/traffic-dashboard`;
export const CREATE_TRAFFIC_PLAN = `${BASE}/plan/:${PLAN_MODEL_ID}/traffic`;
export const CREATE_TRAFFIC_ITEM = `${BASE}/order/:${ORDER_MODEL_ID}/traffic`;
export const CREATE_ALL_TRAFFIC_ITEMS = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/trafficitems`;
export const COPY_TRAFFIC_ITEM = `${BASE}/order/:${ORDER_MODEL_ID}/traffic/:${TRAFFIC_ITEM_MODEL_ID}`;
export const EDIT_TRAFFIC_PLAN = `${BASE}/plan/:${PLAN_MODEL_ID}/traffic/:${TRAFFIC_PLAN_MODEL_ID}`;
export const EDIT_TRAFFIC_ITEM = `${BASE}/order/:${ORDER_MODEL_ID}/traffic/:${TRAFFIC_ITEM_MODEL_ID}`;
export const EDIT_TRAFFIC_ITEM_MULTIPLE = `${BASE}/order/traffic/multiple`;
export const EDIT_TRAFFIC_PLAN_REMARKS = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/remarks`;
export const EDIT_TRAFFICKER = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/trafficker`;
export const UPLOAD_TRAFFIC_PLAN_ATTACHMENT = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/attachment`;
export const EDIT_TRAFFIC_ITEM_STATUS = `${BASE}/traffic/status`;
export const GET_TRAFFIC_STATUS = `${BASE}/traffic/item-status`;
export const GET_TRAFFIC_AD_SERVER_LIST = `${BASE}/traffic/media/ad-server`;
export const GET_TRAFFIC_DIGITAL_FILES = `${BASE}/traffic/media/digital-file`;
export const GET_TRAFFIC_CREATIVE_TYPE = `${BASE}/traffic/media/creative-type`;
export const DELETE_TRAFFIC_ITEM = `${BASE}/order/:${ORDER_MODEL_ID}/traffic/:${TRAFFIC_ITEM_MODEL_ID}`;
export const INCREMENT_TRAFFIC_VERSION = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/version`;
export const DECREMENT_TRAFFIC_VERSION = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/version-revert`;
export const ASSIGN_TRAFFIC_PLAN_TO_ME = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/assignee`;
export const UNASSIGN_TRAFFIC_PLAN_TO_ME = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/assignee`;
export const GET_TRAFFIC_DIMENSIONS = `${BASE}/traffic/media/dimensions`;
export const SAVE_TRAFFIC_PLAN_REMARK = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/remark`;
export const GET_TRAFFIC_PLAN_EXPORT_PREVIEW = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/traffic-preview`;
export const GET_TRAFFIC_MATERIAL_HOSTED_BY = `${BASE}/traffic/media/material`;
export const GET_TRAFFIC_EXPORT_VERSIONS = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/export-versions`;
export const DOWNLOAD_TRAFFIC_EXCEL = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/download`;
export const GET_TRAFFIC_PLAN_ATTACHMENTS = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/attachment`;
export const DELETE_TRAFFIC_PLAN_ATTACHMENT = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/attachment`;
export const EMAIL_TRAFFIC_PLAN = `${BASE}/traffic/:${TRAFFIC_PLAN_MODEL_ID}/email`;

// SignalR
export const NOTIFICATION_SERVICE = `${BASE}/notification`;
export const NOTIFICATIONS_URL = `${BASE}/notifications`;

// Additional Approval Page
export const GET_ORDER_CONDITIONS = `${BASE}/additional-approval/orders`;
export const GET_ORDER_FOR_APPROVAL = `${BASE}/additional-approval/orders/:${ORDER_MODEL_ID}`;
export const GET_APPROVAL_MATRIX_DATA = `${BASE}/additional-approval/orders/:${ORDER_MODEL_ID}/rules`;
export const APPROVE_CONDITION = `${BASE}/additional-approval/orders/:${ORDER_MODEL_ID}/approve`;
export const DECLINE_CONDITION = `${BASE}/additional-approval/orders/:${ORDER_MODEL_ID}/decline`;

// HDT Images
export const GET_HDT_IMAGE_FOR_ORDER = `${BASE}/order/evouching`;

// Public Permission
export const MANAGE_PUBLIC_PERMISSIONS = `back-office/api-management/permission`;
