import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Button } from "@mui/material";
import { enqueueApiErrorMessage } from "../../../business/actions/applicationActions";
import {
  SYSTEM_MESSAGE_ATTACHMENT_FILE_SIZE_VALIDATION_ERROR_MESSAGE,
  SYSTEM_MESSAGE_ATTACHMENT_FILE_TYPE_VALIDATION_ERROR_MESSAGE
} from "../../../business/models/SystemMessage/systemMessageConstants";

const inputStyle = { display: "none" };

const UploadButton = ({ isLoading, onChange, label, mime }) => {
  const dispatch = useDispatch();

  const fileSizeErrorMessage = () =>
    dispatch(
      enqueueApiErrorMessage(
        SYSTEM_MESSAGE_ATTACHMENT_FILE_SIZE_VALIDATION_ERROR_MESSAGE
      )
    );
  const fileTypeErrorMessage = () =>
    dispatch(
      enqueueApiErrorMessage(
        SYSTEM_MESSAGE_ATTACHMENT_FILE_TYPE_VALIDATION_ERROR_MESSAGE
      )
    );

  const validateFileIs10MbSize = file => {
    if (!file) return false;
    const fileSizeMb = file.size / 1024 / 1024;
    return fileSizeMb <= 10;
  };

  const validateFileType = fileForUpload =>
    fileForUpload ? mime.includes(fileForUpload.type) : false;

  const handleUpload = event => {
    if (event.target.files.length === 0) return;

    const fileForUpload = event.target.files[0];
    const isValidSize = validateFileIs10MbSize(fileForUpload);
    const isValidType = validateFileType(fileForUpload);

    if (!isValidSize) fileSizeErrorMessage();

    if (!isValidType) fileTypeErrorMessage();

    if (!isValidSize || !isValidType) return;

    const reader = new FileReader();
    reader.readAsDataURL(fileForUpload);
    reader.onload = () => {
      const fileInfo = {
        fileName: fileForUpload.name,
        fileType: fileForUpload.type,
        fileSize: fileForUpload.size / 1024 / 1024,
        fileBase64Content: reader.result.split(",")[1]
      };

      onChange(fileInfo);
    };
  };
  return (
    <Button
      color="primary"
      component="label"
      disabled={isLoading}
      id="upload-file-button"
      name="upload-file-button"
    >
      <input
        accept={mime}
        type="file"
        style={inputStyle}
        onChange={handleUpload}
        disabled={isLoading}
        id="upload-file-input"
        name="upload-file-input"
      />
      {label}
    </Button>
  );
};

UploadButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  mime: PropTypes.string.isRequired
};

export default UploadButton;
