import React from "react";
import PropTypes from "prop-types";

import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Divider
} from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import Field from "../../../form/PlanChangeOverviewField";
import PlanChangeOverviewDialogActions from "./PlanChangeOverviewDialogActions";

import {
  ADD_TEXT,
  EDIT_TEXT
} from "../../../../business/models/PlanTargetMetricsKpi/planTargetMetricsKpiConstants";

const PlanChangeOverviewDialog = ({
  open,
  onAddClick,
  onCancelClick,
  currentItemId,
  isNew
}) => {
  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle id="plan-target-dialog-title">
          {isNew ? ADD_TEXT : EDIT_TEXT} Change
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Field
                component="text"
                id="version"
                name="version"
                label="Version"
                entityId={currentItemId}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component="text"
                id="change"
                name="change"
                label="Change"
                entityId={currentItemId}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component="text"
                id="consequence"
                name="consequence"
                label="Consequence"
                entityId={currentItemId}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component="text"
                id="changedBy"
                name="changedBy"
                label="Changed by"
                entityId={currentItemId}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component="date"
                id="date"
                name="date"
                label="Date"
                entityId={currentItemId}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <PlanChangeOverviewDialogActions
            isNew={isNew}
            onAddClick={onAddClick}
            onCancelClick={onCancelClick}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

PlanChangeOverviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  currentItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isNew: PropTypes.bool.isRequired
};

export default PlanChangeOverviewDialog;
