import React, { memo } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { PLACEHOLDER } from "../../../configurations/appConstants";
import { classes, Grid } from "../styles/campaign";

const CampaignFold = ({
  product,
  productId,
  processManager,
  poNumber,
  marathonDebtorId,
  debtorContactPersonEmail,
  agreement
}) => {
  return (
    <Grid container spacing={3} justifyContent="flex-end">
      <Grid item>
        <Typography
          id="c_productName"
          name="c_productName"
          align="right"
          className={classes.typographyDetailsValue}
        >
          {product}
        </Typography>
        <Typography align="right" className={classes.typographyCaption}>
          Product name
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          id="c_productId"
          name="c_productId"
          align="right"
          className={classes.typographyDetailsValue}
        >
          {productId}
        </Typography>
        <Typography align="right" className={classes.typographyCaption}>
          Product ID
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          id="c_processManager"
          name="c_processManager"
          align="right"
          className={classes.typographyDetailsValue}
        >
          {processManager}
        </Typography>
        <Typography align="right" className={classes.typographyCaption}>
          Process Manager
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          id="c_poNumber"
          name="c_poNumber"
          align="right"
          className={classes.typographyDetailsValue}
        >
          {poNumber || PLACEHOLDER}
        </Typography>
        <Typography align="right" className={classes.typographyCaption}>
          PO/ATB Number
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          id="c_marathonDebtorId"
          name="c_marathonDebtorId"
          align="right"
          className={classes.typographyDetailsValue}
        >
          {marathonDebtorId}
        </Typography>
        <Typography align="right" className={classes.typographyCaption}>
          Debtor ID
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          id="c_debtorEmail"
          name="c_debtorEmail"
          align="right"
          className={classes.typographyDetailsValue}
        >
          {debtorContactPersonEmail || PLACEHOLDER}
        </Typography>
        <Typography align="right" className={classes.typographyCaption}>
          Debtor Contact Person
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          id="c_agreement"
          name="c_agreement"
          align="right"
          className={classes.typographyDetailsValue}
        >
          {agreement}
        </Typography>
        <Typography align="right" className={classes.typographyCaption}>
          Agreement
        </Typography>
      </Grid>
    </Grid>
  );
};

CampaignFold.propTypes = {
  product: PropTypes.string,
  productId: PropTypes.string,
  processManager: PropTypes.string,
  poNumber: PropTypes.string,
  marathonDebtorId: PropTypes.string,
  debtorContactPersonEmail: PropTypes.string,
  agreement: PropTypes.string
};

export default memo(CampaignFold);
