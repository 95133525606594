import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Search as SearchIcon } from "@mui/icons-material";
import { MenuItem, OutlinedInput, IconButton } from "@mui/material";

import { CancelledIcon } from "../../../../assets/styles/icons";
import { EMPTY_STRING } from "../../../../configurations/appConstants";
import { classes, Div, Select } from "../../styles/chipEdit";

const INPUT_ID = "input-search";
const SELECT_ID = "select-search";
const BUTTON_ID = "chip-cancel";
const KEY_ENTER = 13;

const ChipEdit = ({
  filterProperty,
  filterValue,
  options,
  cancelEdit,
  commit,
  id
}) => {
  const [value, setValue] = useState(filterValue);
  const [property, setProperty] = useState(filterProperty);

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  useEffect(() => {
    setProperty(filterProperty);
  }, [filterProperty]);

  const handleInputSearch = e => {
    setValue(e.target.value);
  };

  const handleSearchBy = e => {
    setProperty(e.target.value);
  };

  const areFilterValuesSet = () =>
    value !== null &&
    value !== undefined &&
    value.trim().length !== 0 &&
    options.some(({ property: prop }) => prop === property);

  const doesChipHaveFocusedElement = elementId =>
    elementId !== null &&
    elementId !== undefined &&
    (elementId === SELECT_ID ||
      elementId === INPUT_ID ||
      elementId === BUTTON_ID);

  const handleBlur = e => {
    const isFilterSet = areFilterValuesSet();
    const isFocused = doesChipHaveFocusedElement(e.relatedTarget?.id);

    if (isFocused) return;

    if (isFilterSet) commit({ property, value, id });

    cancelEdit();
  };

  const handleKeyPress = e => {
    const isFilterSet = areFilterValuesSet();
    if (e.keyCode === KEY_ENTER && isFilterSet) {
      commit({ property, value, id });
      cancelEdit();
    }
  };
  return (
    <Div className={classes.outlined} id={`chip-search_${id}`}>
      <Select
        name={SELECT_ID}
        id={SELECT_ID}
        autoFocus
        margin="dense"
        onBlur={handleBlur}
        className={classes.dropDown}
        value={property}
        onChange={handleSearchBy}
        input={<OutlinedInput name="search" />}
      >
        {options.map(({ property: prop, text }) => (
          <MenuItem key={prop} value={prop}>
            {text}
          </MenuItem>
        ))}
      </Select>
      <OutlinedInput
        startAdornment={<SearchIcon color="primary" />}
        id={INPUT_ID}
        name={INPUT_ID}
        onBlur={handleBlur}
        onKeyDown={handleKeyPress}
        placeholder="Search"
        className={classes.search}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        value={value}
        onChange={handleInputSearch}
      />
      <IconButton
        className={classes.cancelButton}
        onClick={cancelEdit}
        id={BUTTON_ID}
        name={BUTTON_ID}
      >
        <CancelledIcon />
      </IconButton>
    </Div>
  );
};

ChipEdit.propTypes = {
  filterValue: PropTypes.string.isRequired,
  filterProperty: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  commit: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired
};

ChipEdit.defaultProps = {
  filterValue: EMPTY_STRING,
  filterProperty: EMPTY_STRING,
  options: []
};

export default ChipEdit;
