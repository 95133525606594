import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import legend from "../../../../assets/img/new_legend.png";

const TITLE = "Legend";

class InfoDialog extends Component {
  render() {
    const { open, onBackdropClick } = this.props;
    return (
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onBackdropClick={onBackdropClick}
      >
        <DialogTitle id="info-dialog-title">{TITLE}</DialogTitle>
        <DialogContent id="info-dialog-content">
          <img src={legend} alt="legend-img" />
        </DialogContent>
      </Dialog>
    );
  }
}

InfoDialog.propTypes = {
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

InfoDialog.defaultProps = {
  orders: []
};

export default InfoDialog;
