export const TRAFFIC_TEMPLATE_MODEL_NAME = "TrafficTemplate";
export const TRAFFIC_TEMPLATE_MODEL_ID = "templateId";
export const TRAFFIC__TEMPLATE_SUCCESS_MESSAGE =
  "Traffic Template saved successfully";
export const TRAFFIC_TEMPLATE_DELETE_SUCCESS_MESSAGE =
  "Traffic Template deleted successfully";
export const LOAD_TRAFFIC_TEMPLATES = "LOAD_TRAFFIC_TEMPLATES";

export const TRAFFIC_TEMPLATE_SET_IS_NEW = "TRAFFIC_TEMPLATE_SET_IS_NEW";
export const TRAFFIC_TEMPLATE_RESET_IS_NEW = "TRAFFIC_TEMPLATE_RESET_IS_NEW";
export const TRAFFIC_TEMPLATE_HIDE_ERRORS = "TRAFFIC_TEMPLATE_HIDE_ERRORS";

export const TRAFFIC_TEMPLATE_SELECT = "TRAFFIC_TEMPLATE_SELECT";
export const TRAFFIC_TEMPLATE_CLEAR_CURRENT = "TRAFFIC_TEMPLATE_CLEAR_CURRENT";
export const TRAFFIC_TEMPLATE_EDIT_START = "TRAFFIC_TEMPLATE_EDIT_START";
export const TRAFFIC_TEMPLATE_EDIT_STOP = "TRAFFIC_TEMPLATE_EDIT_STOP";

export const TRAFFIC_TEMPLATE_DISPLAY_ERRORS = "PROJECT_DISPLAY_ERRORS";
