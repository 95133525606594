import { styled, Stepper as MuiStepper } from "@mui/material";

const PREFIX = "CopyPlanStepperStyles";
export const classes = {
  stepper: `${PREFIX}-stepper`
};

export const Stepper = styled(MuiStepper)(() => ({
  [`&.${classes.stepper}`]: {
    padding: 0
  }
}));
