import { styled } from "@mui/material";
import { SnackbarProvider as MuiSnackbarProvider } from "notistack";

const PREFIX = "IndexComponentStyles";
export const classes = {
  root: `${PREFIX}-root`
};

export const SnackbarProvider = styled(MuiSnackbarProvider)(({ theme }) => ({
  [`&.${classes.root}`]: {
    maxWidth: theme.typography.pxToRem(window.innerWidth)
  }
}));
