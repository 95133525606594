import { styled, Grid as MuiGrid } from "@mui/material";
import { white, crusta } from "../../../../assets/styles/colors";

const PREFIX = "StepperActionsStyles";
export const classes = {
  button: `${PREFIX}-button`,
  buttonCancel: `${PREFIX}-buttonCancel`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.button}`]: {
    marginRight: theme.spacing(1)
  },
  [`& .${classes.buttonCancel}`]: {
    backgroundColor: crusta,
    color: white
  }
}));
