import React from "react";

import { NavLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { sapphire } from "../../../../../assets/styles/colors";
import { getCurrentCampaignName } from "../../../../../business/models/Campaign/campaignSelectors";
import { ACTION_OVERVIEW_PAGE } from "../../../../../configurations/appConstants";
import { resetPage } from "../../../../../business/actions/actionPageActions";

import { classes, Breadcrumbs } from "./styles";

const activeStyle = { color: sapphire };

const BreadcrumbsAction = () => {
  const dispatch = useDispatch();
  const { agency, campaignId } = useParams();

  const campaignName = useSelector(getCurrentCampaignName);

  const handleClick = () => dispatch(resetPage());

  const isActive = match => !!match;

  const getUrl = () => {
    const baseUrl = `/${agency}/${ACTION_OVERVIEW_PAGE}`;
    return `${baseUrl}/${campaignId}`;
  };

  const to = getUrl();
  return (
    <Breadcrumbs arial-label="BreadcrumbsAction">
      <NavLink
        color="inherit"
        to={to}
        exact
        key={to}
        isActive={isActive}
        onClick={handleClick}
        className={classes.navlink}
        activeStyle={activeStyle}
      >
        {campaignName}
      </NavLink>
    </Breadcrumbs>
  );
};

export default BreadcrumbsAction;
