import React from "react";
import clsx from "clsx";

import { CardContent, Typography } from "@mui/material";

import { parseUtcDate } from "../../../../../business/models/common/utils/clientUtils";
// import { CHAT_STATUS_READ } from "../../../../configurations/appConstants";

import { classes, Card } from "../../styles/chat";

function ChatItem(props) {
  const { item, currentUser } = props;
  const { username, remark, modified } = item; // statusId
  const isCurrent = username === currentUser;
  // const isRead = statusId === CHAT_STATUS_READ;

  const cardClass = clsx(classes.card, {
    [classes.cardCurrent]: isCurrent
  });

  const cardDateClass = clsx(classes.cardDate, {
    [classes.cardDateCurrent]: isCurrent
  });

  return (
    <>
      <Card className={cardClass}>
        <CardContent className={classes.cardContent}>
          {!isCurrent && (
            <Typography
              id="username"
              name="username"
              className={classes.cardTitle}
              gutterBottom
            >
              {username}
            </Typography>
          )}
          <Typography
            className={classes.cardBody}
            variant="body2"
            component="p"
            id="tp_internal_remark_item"
            name="tp_internal_remark_item"
          >
            {remark}
          </Typography>
          <Typography
            id="modified"
            name="modified"
            className={cardDateClass}
            align="right"
          >
            {parseUtcDate(modified)}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default ChatItem;
