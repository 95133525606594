import { styled, Grid as MuiGrid } from "@mui/material";
import { matisse } from "../../../../assets/styles/colors";

const PREFIX = "ChangeTrafficItemStatusDialogElementStyles";
export const classes = {
  widthTrafficItemStatusText: `${PREFIX}-widthTrafficItemStatusText`,
  widthTrafficItemId: `${PREFIX}-widthTrafficItemId`,
  widthMarathonOrderId: `${PREFIX}-widthMarathonOrderId`,
  widthMarathonPlanId: `${PREFIX}-widthMarathonPlanId`,
  widthTitle: `${PREFIX}-widthTitle`,
  cssTypography: `${PREFIX}-cssTypography`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.widthTrafficItemStatusText}`]: {
    maxWidth: theme.typography.pxToRem(200)
  },
  [`&.${classes.widthTrafficItemId}`]: {
    maxWidth: theme.typography.pxToRem(100)
  },
  [`&.${classes.widthMarathonOrderId}`]: {
    maxWidth: theme.typography.pxToRem(100)
  },
  [`&.${classes.widthMarathonPlanId}`]: {
    maxWidth: theme.typography.pxToRem(100)
  },
  [`&.${classes.widthTitle}`]: {
    maxWidth: theme.typography.pxToRem(320)
  },
  [`& .${classes.cssTypography}`]: {
    color: matisse
  }
}));
