import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { AccordionSummary } from "@mui/material";

import ChangeTrafficItemStatusDialogElement from "./ChangeTrafficItemStatusDialogElement";
import { selectSelectedTrafficItems } from "../../../../../business/models/TrafficPlan/trafficPlanSelectors";

import {
  closeDialog,
  trafficItemStatusChangeToggleSelected
} from "../../../../../business/models/TrafficPlan/trafficPlanActions";
import { selectIsTrafficItemSelected } from "../../../../../business/models/TrafficItem/trafficItemSelectors";
import { classes, Accordion } from "../../styles/changeTrafficItemDialogItem";
import withRouter from "../../../configuration/form/withRouter";

class ChangeTrafficItemStatusDialogItem extends PureComponent {
  handleCheckboxClick = selected => {
    const { id, anySelected } = this.props;

    this.props.handleCheckboxClick([id], selected);

    if (!anySelected) {
      this.props.closeDialog();
    }
  };

  render() {
    const { id, trafficItem, isSelected, statusSuccess } = this.props;

    return (
      <Accordion
        key={id}
        square
        elevation={0}
        classes={{ root: classes.expansionPanel, expanded: classes.selected }}
        expanded={false}
      >
        <AccordionSummary key={id} className={classes.contentWrapper}>
          <ChangeTrafficItemStatusDialogElement
            id={id}
            key={id}
            trafficItem={trafficItem}
            onCheckboxClick={this.handleCheckboxClick}
            isSelected={isSelected}
            allowOpen={false}
            statusSuccess={statusSuccess}
          />
        </AccordionSummary>
      </Accordion>
    );
  }
}

ChangeTrafficItemStatusDialogItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  trafficItem: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    isSelected: selectIsTrafficItemSelected(state, {
      trafficItemId: ownProps.id
    }),
    anySelected: selectSelectedTrafficItems(state).length > 1
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleCheckboxClick: (trafficItemIds, selected) =>
      dispatch(trafficItemStatusChangeToggleSelected(trafficItemIds, selected)),
    closeDialog: () => dispatch(closeDialog())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangeTrafficItemStatusDialogItem)
);
