import React from "react";
import Field from "../../../../../form/TrafficItemField";
import { classes, Grid } from "../../../../styles/multipleEdit";

const FoldPrint = ({
  editMode,
  areTrafficAgentFieldsDisabled,
  isDebtorActive
}) => {
  return (
    <Grid container justifyContent="flex-start">
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="trimSize"
          name="trimSize"
          helperText="Trim Size"
          label="Trim Size"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="typeSize"
          name="typeSize"
          helperText="Type Size"
          label="Type Size"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="colour"
          name="colour"
          helperText="Colour"
          label="Colour"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="technicalSpecifications"
          name="technicalSpecifications"
          helperText="Technical Specifications"
          label="Technical Specifications"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="editionNumber"
          name="editionNumber"
          helperText="Edition Number"
          label="Edition Number"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="date"
          id="materialDeadline"
          name="materialDeadline"
          helperText="Material Deadline"
          label="Material Deadline"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          hideIcon={!editMode}
          variant="standard"
        />
      </Grid>
      <Grid item className={classes.gridItem} xs={12}>
        <Field
          component="text"
          id="trafficItemRemarks"
          name="trafficItemRemarks"
          helperText="Remarks"
          label="Remarks"
          variant="standard"
          rowsMax="6"
          multiline
          isLabelHighlighted
          disabled={!isDebtorActive}
        />
      </Grid>
    </Grid>
  );
};

export default FoldPrint;
