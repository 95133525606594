import { schema } from "normalizr";

const condition = new schema.Entity(
  "conditions",
  {},
  { idAttribute: "conditionId" }
);
const user = new schema.Entity("users", {}, { idAttribute: "userId" });

const rule = new schema.Entity(
  "rules",
  {
    conditions: [condition],
    users: [user]
  },
  { idAttribute: "ruleId" }
);

const entitiesSchema = new schema.Entity(
  "agencies",
  {
    rules: [rule]
  },
  { idAttribute: "agencyId" }
);

export default entitiesSchema;
