import { styled, Dialog as MuiDialog } from "@mui/material";
import { dodgerBlue, white } from "../../../../assets/styles/colors";

const PREFIX = "SystemNotificationDialogStyles";
export const classes = {
  buttonColor: `${PREFIX}-buttonColor`
};

export const Dialog = styled(MuiDialog)(() => ({
  [`& .${classes.buttonColor}`]: {
    backgroundColor: dodgerBlue,
    color: white
  }
}));
