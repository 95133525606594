import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { styled } from "@mui/material";

const PREFIX = "dateFieldStyles";
export const classes = {
  inputRoot: `${PREFIX}-inputRoot`
};

export const DatePicker = styled(MuiDatePicker)(() => ({
  [`&.${classes.inputRoot}`]: {
    paddingRight: 0
  }
}));
