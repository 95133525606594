import { styled, IconButton as MuiIconButton } from "@mui/material";
import { jordyBlue } from "../../../../assets/styles/colors";

const PREFIX = "SupportButtonStyles";
export const classes = {
  button: `${PREFIX}-button`
};

export const IconButton = styled(MuiIconButton)(() => ({
  [`&.${classes.button}`]: {
    color: jordyBlue
  }
}));
