import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { Button } from "@mui/material";

import { setIsOpenAddPublicPermission } from "../../../../business/actions/apiManagementActions";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";

import { classes, Grid } from "../styles/ApiManagementActions";

const ApiManagementActions = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);

  const handleAddPublicPermission = () => {
    dispatch(setIsOpenAddPublicPermission());
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      // className={classes.root} //TODO(AM)
    >
      <Grid item />
      <Grid item>
        <Button
          variant="contained"
          id="add_resource"
          name="add_resource"
          className={clsx(classes.saveButton, classes.markedButton)}
          disabled={isLoading}
          onClick={handleAddPublicPermission}
        >
          ADD RESOURCE
        </Button>
      </Grid>
    </Grid>
  );
};

export default ApiManagementActions;
