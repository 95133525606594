import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import upperFirst from "lodash/upperFirst";

import {
  classes,
  Tooltip,
  IconButton
} from "../styles/ActionTableMediaTypeSearch";

import { MediaTypeSearchIcon } from "../../../../../assets/styles/icons";
import { mediaTypesEnum } from "../../../../../configurations/appConstants";

const ActionTableMediaTypeSearch = ({
  searchMediaTypes,
  onSearchMediaTypes
}) => {
  return (
    <>
      {mediaTypesEnum.map((mediaType, key) => (
        <Tooltip key={key} title={upperFirst(mediaType)}>
          <IconButton
            name={mediaType}
            id={mediaType}
            className={clsx({
              [classes.active]: searchMediaTypes.includes(mediaType)
            })}
            onClick={() => onSearchMediaTypes(mediaType)}
          >
            <MediaTypeSearchIcon type={mediaType} />
          </IconButton>
        </Tooltip>
      ))}
    </>
  );
};

ActionTableMediaTypeSearch.propTypes = {
  searchMediaTypes: PropTypes.array,
  onSearchMediaTypes: PropTypes.func.isRequired
};

ActionTableMediaTypeSearch.defaultProps = {
  searchMediaTypes: []
};

export default ActionTableMediaTypeSearch;
