import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { setOrderValue } from "../../business/models/Order/orderActions";
import {
  inheritInsertionPoNumberFromOrder,
  resetInsertions
} from "../../business/models/Insertion/insertionActions";

import {
  getOrderPropertyValue,
  getOrderPropertyPreviousValue,
  getOrderPropertyErrorsByProperty,
  selectOrderDisplayErrors,
  getOrderPropertyOptions,
  isDisabled
} from "../../business/models/Order/orderSelectors";

import Controls from "./controls/index";

const OrderField = props => {
  const { name, component, ...componentProps } = props;

  const dispatch = useDispatch();

  const value = useSelector(state => getOrderPropertyValue(state, props));
  const prevValue = useSelector(state =>
    getOrderPropertyPreviousValue(state, props)
  );
  const errors = useSelector(state =>
    getOrderPropertyErrorsByProperty(state, props)
  );
  const displayErrors = useSelector(selectOrderDisplayErrors);
  const options = useSelector(state => getOrderPropertyOptions(state, props));
  const disabled = useSelector(state => isDisabled(state, props));

  const handleChange = val => {
    dispatch(setOrderValue({ [name]: val }));

    if (name === "orderPoNumber" && prevValue !== val) {
      dispatch(inheritInsertionPoNumberFromOrder());
    } else {
      dispatch(resetInsertions());
    }
  };

  const Control = Controls[component];
  return (
    <Control
      component={component}
      {...componentProps}
      onChange={handleChange}
      value={value}
      prevValue={prevValue}
      errors={errors}
      displayErrors={displayErrors}
      options={options}
      disabled={disabled}
    />
  );
};

OrderField.propTypes = {
  component: PropTypes.oneOf(["text", "date", "decimal", "autocomplete"]),
  name: PropTypes.string.isRequired
};

export default OrderField;
