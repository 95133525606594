import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { setPlanTargetMetricsKpiValue } from "../../business/models/PlanTargetMetricsKpi/planTargetMetricsKpiActions";

import {
  getPlanTargetMetricsKpiPropertyValue,
  getPlanTargetMetricsKpiPropertyErrorsByProperty,
  selectPlanTargetMetricsKpiDisplayErrors
} from "../../business/models/PlanTargetMetricsKpi/planTargetMetricsKpiSelectors";

import Controls from "./controls/index";

const PlanTargetMetricsKpiField = props => {
  const { component, entityId, name, id, onChange, ...componentProps } = props;

  const dispatch = useDispatch();
  const value = useSelector(state =>
    getPlanTargetMetricsKpiPropertyValue(state, props)
  );
  const errors = useSelector(state =>
    getPlanTargetMetricsKpiPropertyErrorsByProperty(state, props)
  );
  const displayErrors = useSelector(selectPlanTargetMetricsKpiDisplayErrors);

  const handleChange = val =>
    dispatch(setPlanTargetMetricsKpiValue(entityId, { [name]: val }));

  const Control = Controls[component];

  return (
    <Control
      value={value}
      errors={errors}
      displayErrors={displayErrors}
      component={component}
      id={`${entityId}-${id}`}
      name={name}
      {...componentProps}
      onChange={handleChange}
    />
  );
};

PlanTargetMetricsKpiField.propTypes = {
  component: PropTypes.oneOf(["text", "date", "decimal"]),
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default PlanTargetMetricsKpiField;
