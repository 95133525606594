import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import cuid from "cuid";
import isEmpty from "lodash/isEmpty";

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip
} from "@mui/material";

import {
  EMPTY_TABLE,
  PLACEHOLDER,
  EMPTY_STRING
} from "../../../../../configurations/appConstants";
import Scrollbar from "../../../../shared/Scrollbar";
import {
  parseDate,
  trimString
} from "../../../../../business/models/common/utils/clientUtils";
import { StatusIcon } from "../../../../../assets/styles/icons";
import { isNullOrUndefined } from "../../../../../functions/util";
import { classes, Paper } from "../../styles/approvalMatrixStyle";

const StatusLogTable = ({ logs }) => {
  const { paperRoot, tableCellUnderline, scrollTableCell } = classes;
  return (
    <Paper className={paperRoot} elevation={0}>
      <Scrollbar autoHeightMax={600} autoHeightMin={95} autoHide={false}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={clsx(scrollTableCell, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left" noWrap>
                  Username
                </Typography>
              </TableCell>
              <TableCell className={clsx(scrollTableCell, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left" noWrap>
                  Old Status
                </Typography>
              </TableCell>
              <TableCell className={clsx(scrollTableCell, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left" noWrap>
                  New Status
                </Typography>
              </TableCell>
              <TableCell className={clsx(scrollTableCell, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left" noWrap>
                  Comment
                </Typography>
              </TableCell>
              <TableCell className={tableCellUnderline}>
                <Typography variant="subtitle2" align="left" noWrap>
                  Modified
                </Typography>
              </TableCell>
            </TableRow>
            {!isEmpty(logs) ? (
              logs.map(log => {
                const {
                  username,
                  modified,
                  previousClientStatusId,
                  currentClientStatusId,
                  previousClientStatus,
                  currentClientStatus,
                  comment
                } = log;
                return (
                  <TableRow key={cuid()}>
                    <TableCell className={scrollTableCell}>
                      <Typography align="left" noWrap>
                        {username}
                      </Typography>
                    </TableCell>
                    <TableCell className={scrollTableCell}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          {StatusIcon({ status: previousClientStatusId })}
                        </Grid>
                        <Grid item>
                          <Typography align="left" noWrap>
                            {previousClientStatus || PLACEHOLDER}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell className={scrollTableCell}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          {StatusIcon({ status: currentClientStatusId })}
                        </Grid>
                        <Grid item>
                          <Typography align="left" noWrap>
                            {currentClientStatus}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell className={scrollTableCell}>
                      <Tooltip
                        title={comment || PLACEHOLDER}
                        placement="bottom-start"
                      >
                        <Typography align="left" noWrap>
                          {!isNullOrUndefined(comment)
                            ? trimString(comment, 50)
                            : EMPTY_STRING}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Typography align="left" noWrap>
                        {parseDate(modified)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={17} align="center">
                  <Typography align="center" noWrap>
                    {EMPTY_TABLE}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Scrollbar>
    </Paper>
  );
};

StatusLogTable.propTypes = {
  logs: PropTypes.array.isRequired
};

StatusLogTable.defaultProps = {
  logs: []
};

export default StatusLogTable;
