import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";

import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";

import { selectCurrentPlanExtension } from "../PlanExtension/planExtensionSelectors";

export const selectPlanTargetMetricsKpi = state => state.planTargetsMetricsKpi;
export const selectPlanTargetMetricsKpiId = (_, props) => props.entityId;
export const selectPropertyName = (_, props) => props.name;
export const selectPlanExtensionId = (_, props) => props.planExtensionId;

export const selectCurrentPlanTargetMetricsKpis = createSelector(
  selectPlanTargetMetricsKpi,
  planTmkpi => planTmkpi.currentPlanTargetMetricsKpis
);

export const selectPlanTargetMetricsKpisIsEditing = createSelector(
  selectPlanTargetMetricsKpi,
  planTmkpi => planTmkpi.isEditing
);

export const selectEditingOriginalItem = createSelector(
  selectPlanTargetMetricsKpi,
  planTmkpi => planTmkpi.editingOriginalItem
);

export const getPlanTargetMetricKpiIDs = createSelector(
  [getEntitiesSession, selectCurrentPlanExtension],
  (entitiesSession, planExtensionId) => {
    const { PlanTargetMetricsKpi } = entitiesSession;
    return PlanTargetMetricsKpi.filter(
      planTmkpi => planTmkpi.planExtension === planExtensionId
    )
      .toRefArray()
      .map(ptmkpi => ptmkpi.id);
  }
);

export const getPlanTargetMetricKpiIDsForCopyPlanProcess = createSelector(
  [getEntitiesSession, selectPlanExtensionId],
  (entitiesSession, planExtensionId) => {
    const { PlanTargetMetricsKpi } = entitiesSession;
    return PlanTargetMetricsKpi.filter(
      planTmkpi => planTmkpi.planExtension === planExtensionId
    )
      .toRefArray()
      .map(ptmkpi => ptmkpi.id);
  }
);

export const selectEditingPlanTargetMetricsKpiData = createSelector(
  [getEditingEntitiesSession, selectPlanTargetMetricsKpi],
  (editingSession, planTmkpi) => {
    const { PlanTargetMetricsKpi } = editingSession;
    return PlanTargetMetricsKpi.filter(p =>
      planTmkpi.currentPlanTargetMetricsKpis.includes(p.id)
    ).toRefArray();
  }
);

export const selectEditingPlanTargetMetricsKpiDataById = createSelector(
  [selectEditingPlanTargetMetricsKpiData, selectPlanTargetMetricsKpiId],
  (planTargetMetricsKpiData, planTmkpiId) =>
    planTargetMetricsKpiData.find(p => p.id === planTmkpiId)
);

export const getPlanTargetMetricsKpiPropertyValue = createCachedSelector(
  [getEditingEntitiesSession, selectPlanTargetMetricsKpiId, selectPropertyName],
  (editingSession, entityId, name) => {
    const { PlanTargetMetricsKpi } = editingSession;
    const planTargetMetricsKpiModel = PlanTargetMetricsKpi.get(
      p => p.id === entityId
    );
    return planTargetMetricsKpiModel
      ? planTargetMetricsKpiModel.ref[name]
      : undefined;
  }
)((state, props) => `${props.entityId}:${props.name}`);

export const getPlanTargetMetricsKpiPropertyErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectPlanTargetMetricsKpiId, selectPropertyName],
  (editingSession, entityId, name) => {
    const { PlanTargetMetricsKpi } = editingSession;
    const planTargetMetricsKpiModel = PlanTargetMetricsKpi.get(
      p => p.id === entityId
    );
    return planTargetMetricsKpiModel
      ? planTargetMetricsKpiModel.validateByProperty(name)
      : undefined;
  }
)((state, props) => `${props.entityId}:${props.name}`);

export const selectPlanTargetMetricsKpiDisplayErrors = createSelector(
  selectPlanTargetMetricsKpi,
  planTmkpi => planTmkpi.displayErrors
);

export const doesPlanTargetMetricsKpiHasErrors = createSelector(
  [getEditingEntitiesSession, selectPlanTargetMetricsKpiId],
  (editingSession, planTmkpi) => {
    const { PlanTargetMetricsKpi } = editingSession;
    const planTargetMetricsKpiModel = PlanTargetMetricsKpi.get(
      p => p.id === planTmkpi
    );

    return planTargetMetricsKpiModel
      ? planTargetMetricsKpiModel.hasErrors()
      : false;
  }
);

export const selectPlanTargetMetricsKpisByPlanExtensionId = createSelector(
  [getEntitiesSession, selectPlanExtensionId],
  (entitiesSession, planExtensionId) => {
    const { PlanTargetMetricsKpi } = entitiesSession;
    return PlanTargetMetricsKpi.filter(
      planTmkpi => planTmkpi.planExtension === planExtensionId
    )
      .toRefArray()
      .map(ptmkpi => ptmkpi.id);
  }
);
