import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  DialogContent,
  DialogActions,
  Typography
} from "@mui/material";

import DialogTitle from "../../../../shared/dialog/DialogTitle";
import ActionComparePanel from "./extensions/ActionComparePanel";
import {
  closeActionCompareDialog,
  closeAndSelectActionCompareDialog,
  getChangesForOrder
} from "../../../../../business/actions/actionPageActions";

import { selectCanUserDoActions } from "../../../../../business/models/Order/orderSelectors";
import { selectIsCompareOrderSelected } from "../../../../../business/selectors/actionPageSelectors";
import { selectIsLoading } from "../../../../../business/selectors/applicationSelectors";

import { EMPTY_STRING } from "../../../../../configurations/appConstants";

import { classes, Dialog } from "../styles/actionCompareDialog";

const ActionCompareDialog = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const { marathonOrderId = EMPTY_STRING, orderId, items = [] } = data || {};

  const canUserDoAction = useSelector(state =>
    selectCanUserDoActions(state, orderId)
  );
  const isLoading = useSelector(selectIsLoading);
  const isSelectionDisabled = useSelector(selectIsCompareOrderSelected);

  const onCloseClick = () => dispatch(closeActionCompareDialog());
  const onClick = () => dispatch(closeAndSelectActionCompareDialog());

  useEffect(() => {
    dispatch(getChangesForOrder()).then(response => setData(response));
  }, [dispatch]);

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle>Changes for order: {marathonOrderId}</DialogTitle>
      <DialogContent className={classes.content}>
        {isLoading ? (
          <Typography align="center" noWrap>
            loading...
          </Typography>
        ) : (
          <ActionComparePanel properties={items} />
        )}
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button onClick={onCloseClick} className={classes.closeButton}>
          Close
        </Button>
        <Button
          onClick={onClick}
          className={classes.doneButton}
          disabled={isLoading || isSelectionDisabled || !canUserDoAction}
        >
          Close and Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionCompareDialog;
