import { createReducer } from "../common/utils/reducerUtils";

import {
  INSERTION_SELECT,
  INSERTION_EDIT_START,
  INSERTION_EDIT_STOP,
  INSERTION_CLEAR_CURRENT,
  INSERTION_DISPLAY_ERRORS,
  INSERTION_HIDE_ERRORS
} from "./insertionConstants";

const initialState = {
  currentInsertions: [],
  isEditing: false,
  displayErrors: false
};

export function selectInsertions(state, payload) {
  return {
    ...state,
    currentInsertions: payload.currentInsertions
  };
}

export function startEditingInsertions(state) {
  return {
    ...state,
    isEditing: true
  };
}

export function stopEditingInsertions(state) {
  return {
    ...state,
    isEditing: false
  };
}

export function displayErrors(state) {
  return {
    ...state,
    displayErrors: true
  };
}

export function hideErrors(state) {
  return {
    ...state,
    displayErrors: false
  };
}

export function clearCurrent() {
  return initialState;
}

export default createReducer(initialState, {
  [INSERTION_SELECT]: selectInsertions,
  [INSERTION_EDIT_START]: startEditingInsertions,
  [INSERTION_EDIT_STOP]: stopEditingInsertions,
  [INSERTION_CLEAR_CURRENT]: clearCurrent,
  [INSERTION_DISPLAY_ERRORS]: displayErrors,
  [INSERTION_HIDE_ERRORS]: hideErrors
});
