import join from "lodash/join";
import generateUrl from "../models/common/utils/urlUtils";

import {
  GET_ORDER_CONDITIONS,
  GET_ORDER_FOR_APPROVAL,
  GET_APPROVAL_MATRIX_DATA,
  APPROVE_CONDITION,
  DECLINE_CONDITION
} from "../../configurations/apiUrls";

import { selectAgency } from "../selectors/applicationSelectors";
import {
  apiCall,
  enqueueApiErrorMessage,
  enqueueNotificationMessage
} from "./applicationActions";
import { GET, PUT } from "../constants/applicationConstants";

import {
  SET_APPROVAL_OVERVIEW_COUNT,
  SET_APPROVAL_OVERVIEW_ITEMS,
  SET_CURRENT_CONDITION,
  RESET_APPROVAL_OVERVIEW_PAGE,
  RESET_APPROVAL_DETAIL_PAGE,
  OPEN_APPROVAL_DIALOG,
  CLOSE_APPROVAL_DIALOG,
  SET_APPROVAL_ACTION,
  RESET_APPROVAL_ACTION,
  ADDITIONAL_ACTION_SUCCESS
} from "../constants/approvalPageConstants";

import { selectCurrentOrder } from "../models/Order/orderSelectors";
import { ORDER_MODEL_ID } from "../models/Order/orderConstants";

export const openDialog = () => ({
  type: OPEN_APPROVAL_DIALOG
});

export const closeDialog = () => dispatch => {
  dispatch({ type: RESET_APPROVAL_ACTION });
  dispatch({ type: CLOSE_APPROVAL_DIALOG });
};

export const setAction = payload => ({
  type: SET_APPROVAL_ACTION,
  payload
});

export const resetApprovalDetailsPage = () => ({
  type: RESET_APPROVAL_DETAIL_PAGE
});

const getRequestBody = query => {
  const {
    currentPage = 0,
    pageSize,
    searchRuleTypes,
    searchProperty,
    searchText,
    ...queryFilters
  } = query;

  return {
    page: currentPage + 1,
    pagesize: pageSize,
    searchruletypes: join(searchRuleTypes, ","),
    searchproperty: searchProperty,
    searchtext: searchText,
    ...queryFilters
  };
};

const getUrl = (agency, query) => {
  const paramObj = { agency };
  const queryObj = getRequestBody(query);
  return generateUrl(GET_ORDER_CONDITIONS, paramObj, queryObj);
};

export const setCount = payload => ({
  type: SET_APPROVAL_OVERVIEW_COUNT,
  payload
});

export const setItems = payload => ({
  type: SET_APPROVAL_OVERVIEW_ITEMS,
  payload
});

export const loadConditions = (orderid, searchRuleTypes) => (
  dispatch,
  getState
) => {
  const state = getState();

  const agency = selectAgency(state);
  const url = getUrl(agency, orderid, searchRuleTypes);

  return dispatch(apiCall(GET, url)).then(
    response => {
      if (response) {
        const { totalItems, items } = response;

        dispatch(setCount(totalItems));
        dispatch(setItems(items));
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const approveCondition = ({
  orderId,
  conditionId,
  includeAllFromPlan
}) => (dispatch, getState) => {
  const state = getState();

  const requestBody = { conditionId, includeAllFromPlan };
  const agency = selectAgency(state);
  const url = generateUrl(APPROVE_CONDITION, {
    agency,
    [ORDER_MODEL_ID]: orderId
  });
  return dispatch(apiCall(PUT, url, requestBody)).then(
    response => {
      const { isSuccessful } = response || {};

      if (isSuccessful) {
        dispatch(enqueueNotificationMessage(ADDITIONAL_ACTION_SUCCESS));
      }

      return isSuccessful || false;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const declineCondition = ({
  orderId,
  conditionId,
  comment,
  includeAllFromPlan
}) => (dispatch, getState) => {
  const state = getState();

  const requestBody = { conditionId, comment, includeAllFromPlan };
  const agency = selectAgency(state);
  const url = generateUrl(DECLINE_CONDITION, {
    agency,
    [ORDER_MODEL_ID]: orderId
  });

  return dispatch(apiCall(PUT, url, requestBody)).then(
    response => {
      const { isSuccessful } = response || {};

      if (isSuccessful) {
        dispatch(enqueueNotificationMessage(ADDITIONAL_ACTION_SUCCESS));
      }

      return isSuccessful || false;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const resetApprovalOverviewPage = () => ({
  type: RESET_APPROVAL_OVERVIEW_PAGE
});

export const loadOrder = (id, conditionId) => (dispatch, getState) => {
  const state = getState();

  const agency = selectAgency(state);
  const paramObj = { agency, [ORDER_MODEL_ID]: id };
  const queryObj = { conditionId };

  const url = generateUrl(GET_ORDER_FOR_APPROVAL, paramObj, queryObj);

  return dispatch(apiCall(GET, url)).then(
    response => response,
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const getMatrixData = () => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const id = selectCurrentOrder(state);
  const url = generateUrl(GET_APPROVAL_MATRIX_DATA, {
    agency: agencyID,
    [ORDER_MODEL_ID]: id
  });

  return dispatch(apiCall(GET, url)).then(
    response => {
      return response;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const setCurrentCondition = condition => ({
  type: SET_CURRENT_CONDITION,
  payload: condition
});
