export const PLAN_EXTENSION_MODEL_ID = "planExtensionId";
export const PLAN_EXTENSION_MODEL_NAME = "PlanExtension";
export const PLAN_EXTENSION_PROPERTY = "planExtension";

export const PLAN_EXTENSION_SELECT = "PLAN_EXTENSION_SELECT";
export const PLAN_EXTENSION_EDIT_START = "PLAN_EXTENSION_EDIT_START";
export const PLAN_EXTENSION_EDIT_STOP = "PLAN_EXTENSION_EDIT_STOP";
export const PLAN_EXTENSION_HIDE_ERRORS = "PLAN_EXTENSION_HIDE_ERRORS";
export const PLAN_EXTENSION_CLEAR_CURRENT = "PLAN_EXTENSION_CLEAR_CURRENT";
export const PLAN_EXTENSION_SET_IS_NEW = "PLAN_EXTENSION_SET_IS_NEW";
export const PLAN_EXTENSION_RESET_CURRENT = "PLAN_EXTENSION_RESET_CURRENT";
export const PLAN_EXTENSION_DISPLAY_ERRORS = "PLAN_EXTENSION_DISPLAY_ERRORS";
export const PLAN_EXTENSION_SET_IS_NEW_FROM_UPDATE =
  "PLAN_EXTENSION_SET_IS_NEW_FROM_UPDATE";

export const PLAN_EXTENSION_SUCCESS_MESSAGE =
  "Additional plan data saved successfully";
