import React from "react";
import Field from "../../../../../form/TrafficItemField";
import { classes, Grid } from "../../../../styles/multipleEdit";

const FoldCinema = ({
  editMode,
  areTrafficAgentFieldsDisabled,
  isDebtorActive
}) => {
  return (
    <Grid container justifyContent="flex-start">
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="networkLocations"
          name="networkLocations"
          label="Network Locations"
          helperText="Network Locations"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="autocomplete"
          id="digitalFileId"
          name="digitalFileId"
          label="Digital File"
          helperText="Digital File"
          variant="standard"
          textFieldClassName={classes.field}
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="spotLength"
          name="spotLength"
          label="Spotlength"
          helperText="Spotlength"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="date"
          id="deliveryDate"
          name="deliveryDate"
          label="Delivery Date"
          helperText="Delivery Date"
          variant="standard"
          hideIcon={!editMode}
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="technicalSpecifications"
          name="technicalSpecifications"
          label="Technical Specifications"
          helperText="Technical Specifications"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="deliveryAddress"
          name="deliveryAddress"
          label="Delivery Address"
          helperText="Delivery Address"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Field
          component="text"
          id="vendorReference"
          name="vendorReference"
          label="Vendor Reference"
          helperText="Vendor Reference"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid item className={classes.gridItem} xs={12}>
        <Field
          component="text"
          id="trafficItemRemarks"
          name="trafficItemRemarks"
          helperText="Remarks"
          label="Remarks"
          variant="standard"
          rowsMax="6"
          multiline
          isLabelHighlighted
          disabled={!isDebtorActive}
        />
      </Grid>
    </Grid>
  );
};

export default FoldCinema;
