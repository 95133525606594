import React, { memo } from "react";
import PropTypes from "prop-types";

import isEmpty from "lodash/isEmpty";

import { TableBody, TableRow, Grid } from "@mui/material";

import CampaignOverviewPanel from "../panel/CampaignOverviewPanel";
import { EMPTY_TABLE } from "../../../../configurations/appConstants";
import { classes, TableCell } from "../styles/table";

const TableOverviewBody = ({
  rows = [],
  disableAction = false,
  canViewTraffic = false,
  contentLoading = false,
  isCopyPlan = false,
  onEditClick,
  handleDownloadClick,
  handleTrafficClick,
  handleFavouriteClick
}) => {
  return (
    <TableBody>
      {!isEmpty(rows)
        ? rows.map(row => (
            <TableRow key={row.campaignId}>
              <TableCell className={classes.tableCell}>
                <Grid container>
                  <Grid item xs={12}>
                    <CampaignOverviewPanel
                      isCopyPlan={isCopyPlan}
                      id={row.campaignId}
                      disableDownload={disableAction}
                      disableTraffic={
                        !canViewTraffic ||
                        !row.isReadyForTraffic ||
                        disableAction
                      }
                      onEditClick={onEditClick}
                      onDownloadClick={handleDownloadClick}
                      onTrafficClick={handleTrafficClick}
                      onFavouriteClick={handleFavouriteClick}
                      data={row}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))
        : !contentLoading && (
            <TableRow>
              <TableCell align="center">{EMPTY_TABLE}</TableCell>
            </TableRow>
          )}
    </TableBody>
  );
};

TableOverviewBody.propTypes = {
  rows: PropTypes.array,

  disableAction: PropTypes.bool,
  canViewTraffic: PropTypes.bool,
  onEditClick: PropTypes.func,
  handleDownloadClick: PropTypes.func,
  handleTrafficClick: PropTypes.func,
  handleFavouriteClick: PropTypes.func,
  contentLoading: PropTypes.bool
};

export default memo(TableOverviewBody);
