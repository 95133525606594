import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import clsx from "clsx";
import NumberFormat from "react-number-format";

import { IconButton, Grid } from "@mui/material";

import {
  VisibilityIcon,
  InsertionRowIcon
} from "../../../../../../assets/styles/icons";

import { classes, Typography, Chip } from "../styles/ActionInsertionItem";

import { parseDate } from "../../../../../../business/models/common/utils/clientUtils";
import ActionInsertionItemPopper from "./ActionInsertionItemPopper";
import { getInsertion } from "../../../../../../business/models/Insertion/insertionSelectors";
import { getPropertyTextFromApiData } from "../../../../../../business/models/Order/orderSelectors";

const ActionInsertionItem = ({ id }) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const insertion = useSelector(state => getInsertion(state, { entityId: id }));

  const {
    insertionInvoiceDate,
    insertionGrossTotal,
    insertionDiscountAmount,
    insertionNetPerUnit,
    insertionAgencyDiscountAmount,
    insertionNetNetTotal,
    insertionSurcharge1Amount,
    insertionSurcharge2Amount,
    insertionPoNumber,
    insertionNetAmount,
    insertionNumOfUnits,
    currencySupplierSymbol,
    insertionId,
    orderId
  } = insertion;

  const title = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "titleId", orderId })
  );

  const handleToggle = e => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleClose = event => {
    if (buttonRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const typographyClass = insertion.insertionIsInvoiced
    ? clsx(
        classes.typographyInsertionHeader,
        classes.typographyInvoicedInsertionHeader
      )
    : clsx(classes.typographyInsertionHeader);
  const chipClass = insertion.insertionIsInvoiced
    ? clsx(classes.chip, classes.whiteChip, classes.redLabelChip)
    : clsx(classes.chip, classes.whiteChip);
  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <InsertionRowIcon />
            </Grid>
            <Grid item>
              <Typography className={typographyClass}>{title}</Typography>
            </Grid>
            <Grid item>
              <Chip
                id={`insertionInvoiceDate_${insertionId}`}
                name={`insertionInvoiceDate_${insertionId}`}
                label={parseDate(insertionInvoiceDate)}
                className={chipClass}
                variant="outlined"
                component={Typography}
              />
            </Grid>
            <Grid item>
              <Chip
                id={`insertionNetAmount_${insertionId}`}
                name={`insertionNetAmount_${insertionId}`}
                component={NumberFormat}
                value={insertionNetAmount}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySupplierSymbol} `}
                className={chipClass}
                variant="outlined"
                fixedDecimalScale
                decimalScale={2}
              />
            </Grid>
            <Grid item>
              <Chip
                id={`insertionNumOfUnits_${insertionId}`}
                name={`insertionNumOfUnits_${insertionId}`}
                component={NumberFormat}
                value={insertionNumOfUnits}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                className={chipClass}
                variant="outlined"
                fixedDecimalScale
                decimalScale={2}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton
                id={`ai_view_${insertionId}`}
                name={`ai_view_${insertionId}`}
                onClick={handleToggle}
                ref={buttonRef}
                className={classes.insertionIconButton}
              >
                <VisibilityIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ActionInsertionItemPopper
        open={open}
        insertionInvoiceDate={insertionInvoiceDate}
        insertionGrossTotal={insertionGrossTotal}
        insertionNetAmount={insertionNetAmount}
        insertionAgencyDiscountAmount={insertionAgencyDiscountAmount}
        insertionNetNetTotal={insertionNetNetTotal}
        insertionSurcharge1Amount={insertionSurcharge1Amount}
        insertionSurcharge2Amount={insertionSurcharge2Amount}
        insertionPoNumber={insertionPoNumber}
        insertionDiscountAmount={insertionDiscountAmount}
        insertionNetPerUnit={insertionNetPerUnit}
        onClose={handleClose}
        buttonRef={buttonRef}
      />
    </>
  );
};

ActionInsertionItem.defaultProps = {
  id: PropTypes.number.isRequired
};

export default ActionInsertionItem;
