import React, { memo } from "react";

import { useSelector } from "react-redux";
import { Route, Routes as ReactRouterRoutes } from "react-router-dom";
import cuid from "cuid";

import { Typography } from "@mui/material";
import AppBarWrapper from "./extensions/AppBarWrapper";
import AvatarMenu from "./extensions/AvatarMenu";
import { headerRoutes } from "../../../configurations/routes";
import InfoButton from "./extensions/InfoButton";
import SupportButton from "./extensions/SupportButton";
import NotificationListMenu from "./extensions/NotificationListMenu";
import { selectUserFullName } from "../../../business/selectors/userProfileSelectors";

const Routes = memo(() => {
  return (
    <ReactRouterRoutes>
      {headerRoutes.map(prop => (
        <Route key={cuid()} path={prop.path} element={prop.element} />
      ))}
    </ReactRouterRoutes>
  );
});

const Content = memo(() => {
  const name = useSelector(selectUserFullName);
  return (
    <div>
      <NotificationListMenu />
      <InfoButton />
      <SupportButton />
      <Typography color="primary" display="inline">
        {name}
      </Typography>
      <AvatarMenu />
    </div>
  );
});

const HeaderBar = () => {
  return (
    <AppBarWrapper>
      <Routes />
      <div />
      <Content />
    </AppBarWrapper>
  );
};

export default memo(HeaderBar);
