import { styled, alpha, Select as MuiSelect } from "@mui/material";
import { dodgerBlue, baliHai, crusta } from "../../../assets/styles/colors";
import { getThemeSpacing } from "../../../functions/util";

const heightChildren = 22;
const paddingTop = 5;
const paddingBottom = 5;
const backgroundColor = theme =>
  theme.palette.type === "light"
    ? theme.palette.grey[300]
    : theme.palette.grey[700];

const PREFIX = "ChipEditStyles";
export const classes = {
  outlined: `${PREFIX}-outlined`,
  grow: `${PREFIX}-grow`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  dropDown: `${PREFIX}-dropDown`,
  cancelButton: `${PREFIX}-cancelButton`
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.outlined}`]: {
    backgroundColor: "transparent",
    border: "1px solid ".concat(
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.23)"
        : "rgba(255, 255, 255, 0.23)"
    ),
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.getContrastText(backgroundColor(theme)),
    fontSize: theme.typography.pxToRem(3),
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.typography.pxToRem(
      (heightChildren + paddingTop + paddingBottom) / 2
    ),
    height: theme.typography.pxToRem(
      heightChildren + paddingTop + paddingBottom
    ),
    whiteSpace: "nowrap",
    transition: theme.transitions.create(["background-color", "box-shadow"]),
    cursor: "default",
    outline: "none",
    textDecoration: "none",
    paddingTop,
    paddingBottom,
    verticalAlign: "middle",
    boxSizing: "border-box"
  },
  [`&.${classes.grow}`]: {
    flexGrow: 1,
    display: "inline-block"
  },
  [`&.${classes.search}`]: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 0.5)),
      width: "auto"
    }
  },
  [`&.${classes.searchIcon}`]: {
    width: theme.typography.pxToRem(getThemeSpacing(theme, 5)),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: dodgerBlue
  },
  [`& .${classes.inputRoot}`]: {
    color: "inherit",
    width: "100%",
    height: theme.typography.pxToRem(heightChildren),
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${classes.inputInput}`]: {
    paddingTop: theme.typography.pxToRem(getThemeSpacing(theme, 5)),
    paddingRight: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 5)),
    paddingLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    transition: theme.transitions.create("width"),
    color: baliHai,
    [theme.breakpoints.up("sm")]: {
      width: theme.typography.pxToRem(50),
      "&:focus": {
        width: theme.typography.pxToRem(100)
      }
    }
  },
  [`& .${classes.dropDown}`]: {
    minWidth: theme.typography.pxToRem(160),
    marginLeft: theme.typography.pxToRem(15),
    height: theme.typography.pxToRem(heightChildren),
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${classes.cancelButton}`]: {
    color: crusta
  }
}));

export const Select = styled(MuiSelect)(({ theme }) => ({
  marginRight: theme.typography.pxToRem(8)
}));
