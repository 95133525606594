import React from "react";
import startCase from "lodash/startCase";

import { Grid, Typography, Divider } from "@mui/material";

import SettingField from "../../../../form/SettingField";
import { EmailIcon } from "../../../../../assets/styles/icons";

import { classes, Paper } from "../../styles/userProfilePageStyle";
import { COLOR_AZURERADINACE } from "../../../../../configurations/appConstants";

const EmailSettingsPanel = ({
  data,
  handleChange,
  handleAllChange,
  allSwitched
}) => {
  return (
    <>
      <Paper className={classes.paper}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <EmailIcon color={COLOR_AZURERADINACE} />
              </Grid>
              <Grid item>
                <Typography
                  id="name"
                  name="name"
                  className={classes.typographySettingsHeaderCaption}
                  noWrap
                >
                  Email Settings
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <SettingField
              component="switch"
              id="switchall"
              name="switchall"
              value={allSwitched}
              onChange={handleAllChange}
              label="Check/Uncheck all"
            />
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={1}>
          {data &&
            data.map(d => (
              <Grid item xs={12} key={d.id}>
                <SettingField
                  component="switch"
                  id={d.name}
                  name={d.name}
                  value={d.value}
                  onChange={handleChange}
                  label={startCase(d.name)}
                />
              </Grid>
            ))}
        </Grid>
      </Paper>
    </>
  );
};

export default EmailSettingsPanel;
