import { styled, Grid as MuiGrid } from "@mui/material";
import { matisse, linkWater } from "../../../../../../assets/styles/colors";

const PREFIX = "ActionPlanItemFoldStyle";
export const classes = {
  actionPlanCheckbox: `${PREFIX}-actionPlanCheckbox`,
  typographyDetailsValue: `${PREFIX}-typographyDetailsValue`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  planAgreementGridItem: `${PREFIX}-planAgreementGridItem`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`&.${classes.planAgreementGridItem}`]: {
    [theme.breakpoints.down("lg")]: {
      maxWidth: theme.typography.pxToRem(145)
    }
  },
  [`& .${classes.actionPlanCheckbox}`]: {
    padding: 0,
    marginBottom: 2
  },
  [`& .${classes.typographyDetailsValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(16)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  }
}));
