import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import { EMPTY_STRING } from "../../../../configurations/appConstants";

const ChipView = ({
  onDelete,
  filterPropertyText,
  filterProperty,
  filterValue,
  onClick
}) => {
  const handleDelete = () => onDelete(filterProperty);
  return (
    <Chip
      id={`chip-view-${filterProperty}`}
      label={`${filterPropertyText} = ${filterValue}`}
      onDelete={handleDelete}
      color="primary"
      variant="outlined"
      onClick={onClick}
    />
  );
};

ChipView.propTypes = {
  onDelete: PropTypes.func,
  filterPropertyText: PropTypes.string.isRequired,
  filterProperty: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired
};

ChipView.defaultProps = {
  filterPropertyText: EMPTY_STRING,
  filterProperty: EMPTY_STRING,
  filterValue: EMPTY_STRING
};

export default ChipView;
