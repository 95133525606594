import { Model, attr, fk } from "redux-orm";
import set from "lodash/set";

import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";

import {
  ISO8859_RULE,
  ISO8859_MESSAGE,
  NUMBER_OF_CHARACTERS_RULE,
  DATE_RULE,
  DATE_MESSAGE,
  NUMBER_OF_CHARACTERS_MESSAGE_100,
  NUMBER_OF_CHARACTERS_MESSAGE_50,
  NUMBER_OF_CHARACTERS_MESSAGE_1000,
  MANDATORY_RULE,
  MANDATORY_MESSAGE,
  DECIMAL_RULE,
  DECIMAL_MESSAGE
} from "../../constants/validationConstants";

import {
  TRAFFIC_PLAN_MODEL_NAME,
  TRAFFIC_PLAN_MODEL_ID
} from "../TrafficPlan/trafficPlanConstants";
import {
  TRAFFIC_ITEM_MODEL_NAME,
  TRAFFIC_ITEM_MODEL_ID,
  TRAFFIC_ITEM_MEDIA_TYPE_ID
} from "./trafficItemConstants";
import { PLAN_MODEL_ID } from "../Plan/planConstants";

import {
  GET_TRAFFIC_STATUS,
  GET_TRAFFIC_AD_SERVER_LIST,
  GET_TRAFFIC_DIGITAL_FILES,
  GET_TRAFFIC_DIMENSIONS,
  GET_TRAFFIC_MATERIAL_HOSTED_BY
} from "../../../configurations/apiUrls";

import {
  MEDIATYPE_DIGITAL,
  MEDIATYPE_PRINT,
  MEDIATYPE_OOH,
  MEDIATYPE_TV,
  MEDIATYPE_RADIO,
  MEDIATYPE_CINEMA,
  MEDIATYPE_INVENTORY
} from "../../../configurations/appConstants";

class TrafficItem extends Model {
  static get fields() {
    return {
      id: attr(),

      trafficItemId: attr(),
      trafficPlanId: attr(),
      campaignId: attr(),
      planId: attr(),
      orderId: attr(),
      marathonPlanId: attr(),
      marathonOrderId: attr(),
      title: attr(),
      mediaTypeId: attr(),
      orderStartDate: attr(),
      orderEndDate: attr(),
      orderDescription: attr(),
      orderFormat: attr(),
      orderNumOfUnits: attr(),
      trafficItemStartDate: attr(),
      trafficItemEndDate: attr(),
      trafficItemDescription: attr(),
      numberOfUnitsToDeliver: attr(),

      trafficItemStatusId: attr(),
      traficItemStatusText: attr(),
      trafficItemRemarks: attr(),
      deliveryDate: attr(),
      maximumSeconds: attr(),
      dimensionsId: attr(),
      weight: attr(),
      creativeType: attr(),
      animationLooping: attr(),
      framerate: attr(),
      adServerId: attr(),
      materialHostedById: attr(),
      trafficItemContact: attr(),
      networkLocations: attr(),
      digitalFileId: attr(),
      spotLength: attr(),
      technicalSpecifications: attr(),
      deliveryAddress: attr(),
      vendorReference: attr(),
      trimSize: attr(),
      typeSize: attr(),
      colour: attr(),
      editionNumber: attr(),
      materialDeadline: attr(),
      spotName: attr(),
      material: attr(),
      phoneNumber: attr(),
      orderClientStatusId: attr(),
      isPreliminary: attr(),
      trafficPlan: fk(TRAFFIC_PLAN_MODEL_NAME)
    };
  }

  static get defaultAttributes() {
    return {
      digitalFileId: false
    };
  }

  static parse(trafficItemData) {
    if (trafficItemData[TRAFFIC_ITEM_MODEL_ID]) {
      set(trafficItemData, "id", trafficItemData[TRAFFIC_ITEM_MODEL_ID]);
    }
    if (trafficItemData[TRAFFIC_PLAN_MODEL_ID]) {
      set(
        trafficItemData,
        "trafficPlan",
        trafficItemData[TRAFFIC_PLAN_MODEL_ID]
      );
    } else if (
      trafficItemData[PLAN_MODEL_ID] &&
      trafficItemData[TRAFFIC_ITEM_MEDIA_TYPE_ID]
    ) {
      set(
        trafficItemData,
        "trafficPlan",
        `${trafficItemData[PLAN_MODEL_ID]}_${trafficItemData[TRAFFIC_ITEM_MEDIA_TYPE_ID]}`
      );
    }

    return this.upsert(trafficItemData);
  }

  static updateModel(trafficItemData) {
    if (trafficItemData[TRAFFIC_ITEM_MODEL_ID]) {
      set(trafficItemData, "id", trafficItemData[TRAFFIC_ITEM_MODEL_ID]);
    }
    if (trafficItemData[TRAFFIC_PLAN_MODEL_ID]) {
      set(
        trafficItemData,
        "trafficPlan",
        trafficItemData[TRAFFIC_PLAN_MODEL_ID]
      );
    } else if (
      trafficItemData[PLAN_MODEL_ID] &&
      trafficItemData[TRAFFIC_ITEM_MEDIA_TYPE_ID]
    ) {
      set(
        trafficItemData,
        "trafficPlan",
        `${trafficItemData[PLAN_MODEL_ID]}_${trafficItemData[TRAFFIC_ITEM_MEDIA_TYPE_ID]}`
      );
    }
    return this.upsert(trafficItemData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...TrafficItem.defaultAttributes,
      ...newAttributes
    };
    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherTrafficItem) {
    this.update(otherTrafficItem.ref);
  }

  static get validation() {
    return {
      [MEDIATYPE_DIGITAL]: {
        trafficItemStartDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        trafficItemEndDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        trafficItemRemarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ],
        trafficItemDescription: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        deliveryDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        maximumSeconds: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        weight: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        creativeType: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ],
        animationLooping: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        framerate: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        adServerId: [
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        materialHostedById: [
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        trafficItemContact: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ]
      },
      [MEDIATYPE_INVENTORY]: {
        trafficItemStartDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        trafficItemEndDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        trafficItemRemarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ],
        trafficItemDescription: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        deliveryDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        maximumSeconds: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        weight: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        creativeType: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ],
        animationLooping: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        framerate: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        adServerId: [
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        materialHostedById: [
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        trafficItemContact: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ]
      },
      [MEDIATYPE_PRINT]: {
        trafficItemRemarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ],
        technicalSpecifications: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ],
        trimSize: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        typeSize: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        colour: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        editionNumber: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        materialDeadline: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ]
      },
      [MEDIATYPE_OOH]: {
        trafficItemRemarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ],
        trafficItemUnitsToDeliver: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: DECIMAL_RULE,
            message: DECIMAL_MESSAGE
          }
        ],
        deliveryDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        networkLocations: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ],
        spotLength: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        technicalSpecifications: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ],
        deliveryAddress: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ],
        vendorReference: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ]
      },
      [MEDIATYPE_TV]: {
        trafficItemStartDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        trafficItemEndDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        trafficItemRemarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ],
        deliveryDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        spotLength: [
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        spotName: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ],
        material: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        phoneNumber: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ]
      },
      [MEDIATYPE_RADIO]: {
        trafficItemStartDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        trafficItemEndDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        trafficItemRemarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ],
        deliveryDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        spotLength: [
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        spotName: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ],
        material: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        phoneNumber: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ]
      },
      [MEDIATYPE_CINEMA]: {
        trafficItemRemarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ],
        deliveryDate: [
          {
            rule: DATE_RULE,
            message: DATE_MESSAGE
          }
        ],
        networkLocations: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ],
        spotLength: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          }
        ],
        technicalSpecifications: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ],
        deliveryAddress: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ],
        vendorReference: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          }
        ]
      }
    };
  }

  validate(type) {
    return validateModel(TrafficItem.validation[type], this.toJSON());
  }

  validateByProperty(type, name) {
    return validateProperty(TrafficItem.validation[type][name], this.ref[name]);
  }

  hasErrors(type) {
    return doesModelHaveErrors(TrafficItem.validation[type], this.toJSON());
  }

  hasErrorsForAgentMultipleEdit(type) {
    const excludedProperties = Object.keys(TrafficItem.validation[type]).filter(
      property => {
        return TrafficItem.validation[type][property].some(
          validationObject => validationObject.rule === MANDATORY_RULE
        );
      }
    );

    return doesModelHaveErrors(
      TrafficItem.validation[type],
      this.toJSON(),
      excludedProperties
    );
  }

  static get apiConfiguration() {
    return {
      trafficItemStatusId: {
        url: GET_TRAFFIC_STATUS
      },
      adServerId: {
        url: GET_TRAFFIC_AD_SERVER_LIST
      },
      digitalFileId: {
        url: GET_TRAFFIC_DIGITAL_FILES
      },
      dimensionsId: {
        url: GET_TRAFFIC_DIMENSIONS
      },
      materialHostedById: {
        url: GET_TRAFFIC_MATERIAL_HOSTED_BY
      }
    };
  }
}

TrafficItem.modelName = TRAFFIC_ITEM_MODEL_NAME;

export default TrafficItem;
