import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Tooltip,
  IconButton,
  Box
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { classes, Div } from "../../../shared/styles/ctcStyle";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";

import { doNotNotifyInsertionChange } from "../../../../business/models/Plan/planActions";
import { parseIntOrDefault } from "../../../../business/models/common/utils/clientUtils";

import {
  UPDATE_INSERTIONS_WITH_CHANGE,
  UPDATE_ORDERS_WITH_INSERTION_CHANGE,
  UPDATE_PLANS_WITH_INSERTION_CHANGE
} from "../../../../business/constants/campaignDashboardConstants";

import { isNullOrUndefined } from "../../../../functions/util";

const paperProps = { square: true };

const ConfirmInsertionChangeDialog = () => {
  const dispatch = useDispatch();
  const { planId: urlPlanId } = useParams();

  const isLoading = useSelector(selectIsLoading);

  const handleOk = () => {
    dispatch(doNotNotifyInsertionChange(parseIntOrDefault(urlPlanId))).then(
      response => {
        const { isSuccessful, metadata } = response;

        if (isSuccessful && !isNullOrUndefined(metadata)) {
          const {
            plansWithInsertionChange,
            ordersWithInsertionChange,
            insertionsWithChange
          } = metadata;

          if (isNullOrUndefined(plansWithInsertionChange)) {
            dispatch({
              type: UPDATE_PLANS_WITH_INSERTION_CHANGE,
              payload: { planId: urlPlanId }
            });
          } else {
            dispatch({
              type: UPDATE_PLANS_WITH_INSERTION_CHANGE,
              payload: { plansWithInsertionChange }
            });
          }

          dispatch({
            type: UPDATE_ORDERS_WITH_INSERTION_CHANGE,
            payload: { ordersWithInsertionChange }
          });

          dispatch({
            type: UPDATE_INSERTIONS_WITH_CHANGE,
            payload: { insertionsWithChange }
          });
        }
      }
    );
  };

  const handleCancel = () => {
    dispatch({
      type: UPDATE_PLANS_WITH_INSERTION_CHANGE,
      payload: { planId: parseIntOrDefault(urlPlanId) }
    });
  };

  return (
    <Dialog open PaperProps={paperProps} maxWidth={"sm"}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            An insertion in the selected plan has been modified.
          </Grid>
          <Grid item sm={12}>
            Please review the order(s) and insertion amounts before approving.
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <Div className={classes.wrapper}>
              <Button
                id="cancel"
                name="cancel"
                onClick={handleCancel}
                color="primary"
              >
                Keep notifying
              </Button>
              <Tooltip title="Clicking this button will hide notification until page is reloaded">
                <IconButton
                  className={classes.infoPlanWithInsertionChange}
                  id="plan_notif_hide_"
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Div>
          </Grid>
          <Grid item sm={6}>
            <Div className={classes.wrapper}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  id="create"
                  name="create"
                  onClick={handleOk}
                  color="secondary"
                >
                  Do not show this again
                </Button>
                <Tooltip title="Clicking this button will permanently hide notification for all users">
                  <IconButton
                    className={classes.ctcIconButton}
                    id="plan_notif_hide_permanently_"
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Div>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmInsertionChangeDialog;
