import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid, Typography } from "@mui/material";

import PlanExtensionField from "../../../form/PlanExtensionField";
import PlanTargetMetricsKpiTable from "../table/PlanTargetMetricsKpiTable";
import PlanTragetMetricsKpiDialog from "../popup/PlanTragetMetricsKpiDialog";
import PlanChangeOverviewTable from "../table/PlanChangeOverviewTable";
import PlanChangeOverviewDialog from "../popup/PlanChangeOverviewDialog";

import { selectCurrentPlanMediaType } from "../../../../business/models/Plan/planSelectors";
import {
  addNewPlanTargetMetricsKpi,
  deletePlanTargetMetricsKpi,
  planTargetMetricsKpiHasErrors,
  setEditingOriginalPlanTargetMetrcsKpiData,
  clearEditingOriginalPlanTargetMetrcsKpiData,
  resetCurrentPlanTargetMetricsKpi
} from "../../../../business/models/PlanTargetMetricsKpi/planTargetMetricsKpiActions";
import { selectEditingPlanTargetMetricsKpiData } from "../../../../business/models/PlanTargetMetricsKpi/planTargetMetricsKpiSelectors";

import {
  addNewPlanChangeOverview,
  deletePlanChangeOverview,
  planChangeOverviewHasErrors,
  setEditingOriginalPlanChangeOverviewData,
  clearEditingOriginalPlanChangeOverviewData,
  resetCurrentPlanChangeOverview
} from "../../../../business/models/PlanChangeOverview/planChangeOverviewActions";
import { selectEditingPlanChangeOverviewData } from "../../../../business/models/PlanChangeOverview/planChangeOverviewSelectors";

import { classes, Div } from "../styles/planExtensionForm";

const PlanExtensionForm = () => {
  const dispatch = useDispatch();

  const tableGridStyle = { paddingBottom: "8px" };

  const [currentTargetMetricsKpiId, setCurrentTargetMetricsKpiId] = useState(
    null
  );
  const [openTargetMetricsKpiDialog, setOpenTargetMetricsKpiDialog] = useState(
    false
  );
  const [isPlanTargetMetricsKpiNew, setPlanTargetMetricsKpiNew] = useState(
    false
  );
  const [currentChangeOverviewId, setCurrentChangeOverviewId] = useState(null);
  const [openChangeOverviewDialog, setOpenChangeOverviewDialog] = useState(
    false
  );
  const [isPlanChangeOverviewNew, setPlanChangeOverviewNew] = useState(false);

  const mediaTypeId = useSelector(state => selectCurrentPlanMediaType(state));

  const planTargetMetricsKpiData = useSelector(state =>
    selectEditingPlanTargetMetricsKpiData(state)
  );
  const planChangeOverviewData = useSelector(state =>
    selectEditingPlanChangeOverviewData(state)
  );

  const resetState = () => {
    dispatch(clearEditingOriginalPlanTargetMetrcsKpiData());
    setOpenTargetMetricsKpiDialog(false);
    setPlanTargetMetricsKpiNew(false);
    setCurrentTargetMetricsKpiId(null);

    dispatch(clearEditingOriginalPlanChangeOverviewData());
    setOpenChangeOverviewDialog(false);
    setPlanChangeOverviewNew(false);
    setCurrentChangeOverviewId(null);
  };

  const handleOpenTargetMetricKpiDialog = () => {
    const id = dispatch(addNewPlanTargetMetricsKpi());
    if (id) {
      setCurrentTargetMetricsKpiId(id);
      setPlanTargetMetricsKpiNew(true);
      setOpenTargetMetricsKpiDialog(true);
    }
  };

  const handleAddTargetMetricsKpiDialog = () => {
    const hasErrors = dispatch(
      planTargetMetricsKpiHasErrors(currentTargetMetricsKpiId)
    );
    if (!hasErrors) {
      resetState();
    }
  };

  const handleEditPlanTargetMetrcsKpi = id => {
    dispatch(setEditingOriginalPlanTargetMetrcsKpiData(id));
    setCurrentTargetMetricsKpiId(id);
    setPlanTargetMetricsKpiNew(false);
    setOpenTargetMetricsKpiDialog(true);
  };

  const handleDeletePlanTargetMetricKpi = id => {
    dispatch(deletePlanTargetMetricsKpi(id));
  };

  const handleCancelTargetMetricsKpiDialog = () => {
    if (isPlanTargetMetricsKpiNew) {
      dispatch(deletePlanTargetMetricsKpi(currentTargetMetricsKpiId));
    } else {
      dispatch(resetCurrentPlanTargetMetricsKpi(currentTargetMetricsKpiId));
    }
    resetState();
  };

  const handleOpenChangeOverviewDialog = () => {
    const id = dispatch(addNewPlanChangeOverview());
    if (id) {
      setCurrentChangeOverviewId(id);
      setPlanChangeOverviewNew(true);
      setOpenChangeOverviewDialog(true);
    }
  };

  const handleAddChangeOverviewDialog = () => {
    const hasErrors = dispatch(
      planChangeOverviewHasErrors(currentChangeOverviewId)
    );
    if (!hasErrors) {
      resetState();
    }
  };

  const handleEditPlanChangeOverview = id => {
    dispatch(setEditingOriginalPlanChangeOverviewData(id));
    setCurrentChangeOverviewId(id);
    setPlanChangeOverviewNew(false);
    setOpenChangeOverviewDialog(true);
  };

  const handleDeletePlanChangeOverview = id => {
    dispatch(deletePlanChangeOverview(id));
  };

  const handleCancelChangeOverviewDialog = () => {
    if (isPlanChangeOverviewNew) {
      dispatch(deletePlanChangeOverview(currentChangeOverviewId));
    } else {
      dispatch(resetCurrentPlanChangeOverview(currentChangeOverviewId));
    }
    resetState();
  };

  return (
    // <Div className={classes.root}>//TODO(AM)
    <Div className={classes.root}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={10}>
          <PlanExtensionField
            component="text"
            id="campaignObjectives"
            name="campaignObjectives"
            label="Campaign Objectives"
            maxLength={500}
            multiline
            rowsMax="20"
          />
        </Grid>
        <Grid item xs={10}>
          <PlanExtensionField
            component="text"
            id="communicationObjectives"
            name="communicationObjectives"
            label="Communication Objectives & Targets"
            maxLength={1000}
            multiline
            rowsMax="20"
          />
        </Grid>
        <Grid item xs={10}>
          <PlanExtensionField
            component="text"
            id="mediaObjective"
            name="mediaObjective"
            label="Media Objective"
            maxLength={100}
            multiline
            rowsMax="20"
          />
        </Grid>
        <Grid item xs={10}>
          <PlanExtensionField
            component="text"
            id="kpi"
            name="kpi"
            label="KPI"
            maxLength={100}
            multiline
            rowsMax="20"
          />
        </Grid>
        <Grid item xs={12} style={tableGridStyle}>
          <Typography>Targets - Metrics - KPI's</Typography>
          <PlanTargetMetricsKpiTable
            mediaType={mediaTypeId}
            data={planTargetMetricsKpiData}
            onAdd={handleOpenTargetMetricKpiDialog}
            onEdit={handleEditPlanTargetMetrcsKpi}
            onDelete={handleDeletePlanTargetMetricKpi}
          />
        </Grid>
        <Grid item xs={12} style={tableGridStyle}>
          <Typography>Overview of Changes</Typography>
          <PlanChangeOverviewTable
            data={planChangeOverviewData}
            onAdd={handleOpenChangeOverviewDialog}
            onEdit={handleEditPlanChangeOverview}
            onDelete={handleDeletePlanChangeOverview}
          />
        </Grid>
      </Grid>
      {currentTargetMetricsKpiId && (
        <PlanTragetMetricsKpiDialog
          mediaType={mediaTypeId}
          open={openTargetMetricsKpiDialog}
          currentItemId={currentTargetMetricsKpiId}
          isNew={isPlanTargetMetricsKpiNew}
          onAddClick={handleAddTargetMetricsKpiDialog}
          onCancelClick={handleCancelTargetMetricsKpiDialog}
        />
      )}
      {currentChangeOverviewId && (
        <PlanChangeOverviewDialog
          open={openChangeOverviewDialog}
          currentItemId={currentChangeOverviewId}
          isNew={isPlanChangeOverviewNew}
          onAddClick={handleAddChangeOverviewDialog}
          onCancelClick={handleCancelChangeOverviewDialog}
        />
      )}
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={10}>
          <PlanExtensionField
            component="text"
            id="planExtensionRemarks"
            name="planExtensionRemarks"
            label="Remarks"
            maxLength={1000}
            multiline
            rowsMax="20"
          />
        </Grid>
      </Grid>
    </Div>
  );
};

export default PlanExtensionForm;
