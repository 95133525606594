import {
  API_CALL_START,
  API_CALL_SUCCESS,
  API_CALL_SUCCESS_NOTIFY,
  API_CALL_ERROR,
  API_CALL_ERROR_MSG,
  APPLICATION_ERROR,
  REMOVE_ERROR,
  REMOVE_NOTIFICATION,
  TOGGLE_INSERTION_POPUP,
  TOGGLE_CAMPAIGN_CONFIRM_POPUP,
  TOGGLE_PLAN_CONFIRM_POPUP,
  TOGGLE_ORDER_CONFIRM_POPUP,
  TOGGLE_MBS_CONFIRM_POPUP,
  TOGGLE_AGREEMENT_CONFIRM_POPUP,
  SET_ACTION,
  CLEAR_ACTION,
  SET_AGENCY,
  CLEAR_AGENCY,
  OPEN_ORDER_STATUS_CHANGE_DIALOG,
  CLOSE_ORDER_STATUS_CHANGE_DIALOG,
  SET_ORDER_STATUS_CHANGE_DIALOG_TITLE,
  OPEN_TRAFFIC_ITEM_DELETE_DIALOG,
  OPEN_ADD_ALL_TRAFFIC_ITEMS_DIALOG,
  CLOSE_TRAFFIC_ITEM_DELETE_DIALOG,
  OPEN_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG,
  CLOSE_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG,
  OPEN_ADD_TRAFFIC_DIALOG,
  CLOSE_ADD_TRAFFIC_DIALOG,
  CLOSE_ADD_ALL_TRAFFIC_ITEMS_DIALOG,
  TOGGLE_ORDERS_ACTION,
  OPEN_DIVIDE_ORDERS_DIALOG,
  CLOSE_DIVIDE_ORDERS_DIALOG,
  OPEN_SYSTEM_MESSAGE_DIALOG,
  CLOSE_SYSTEM_MESSAGE_DIALOG,
  GET,
  TOGGLE_TRAFFIC_ITEM_ACTION,
  OPEN_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG,
  CLOSE_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG,
  AUTHENTICATION_SUCCESS,
  OPEN_SYSTEM_NOTIFICATION_DIALOG,
  CLOSE_SYSTEM_NOTIFICATION_DIALOG,
  TOGGLE_DRAWER,
  CLOSE_EDIT_TRAFFICKER_DIALOG,
  OPEN_EDIT_TRAFFICKER_DIALOG,
  OPEN_TRAFFIC_EXPORT_VERSION_DIALOG,
  CLOSE_TRAFFIC_EXPORT_VERSION_DIALOG,
  OPEN_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG,
  CLOSE_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG,
  RESET_TRAFFIC_ITEM_ACTION,
  SET_REFETCH,
  RESET_REFETCH,
  TOGGLE_MEDIA_TYPE_CONFIRM_POPUP,
  EDIT_AGENCY_RULE,
  SET_CHECK_ALL_ORDERS
} from "../constants/applicationConstants";

import api from "../../functions/api";
import { EMPTY_STRING } from "../../configurations/appConstants";

export function authenticated() {
  return {
    type: AUTHENTICATION_SUCCESS
  };
}

export function startLoading() {
  return {
    type: API_CALL_START
  };
}

export function stopLoading() {
  return {
    type: API_CALL_SUCCESS
  };
}

export function enqueueApiErrorMessage(message) {
  return {
    type: API_CALL_ERROR_MSG,
    error: {
      key: new Date().getTime() + Math.random(),
      message
    }
  };
}

export function enqueueApplicationErrorMessage(message) {
  return {
    type: APPLICATION_ERROR,
    error: {
      key: new Date().getTime() + Math.random(),
      message
    }
  };
}

export function enqueueNotificationMessage(message) {
  return {
    type: API_CALL_SUCCESS_NOTIFY,
    notification: {
      key: new Date().getTime() + Math.random(),
      message
    }
  };
}

export function removeErrorMessage(key) {
  return {
    type: REMOVE_ERROR,
    key
  };
}

export function removeNotificationMessage(key) {
  return {
    type: REMOVE_NOTIFICATION,
    key
  };
}

export function toggleInsertionPopup() {
  return {
    type: TOGGLE_INSERTION_POPUP
  };
}

export function toggleCampaignConfirmPopup() {
  return {
    type: TOGGLE_CAMPAIGN_CONFIRM_POPUP
  };
}

export function togglePlanConfirmPopup() {
  return {
    type: TOGGLE_PLAN_CONFIRM_POPUP
  };
}

export function toggleOrderConfirmPopup() {
  return {
    type: TOGGLE_ORDER_CONFIRM_POPUP
  };
}

export function toggleMbsConfirmPopup() {
  return {
    type: TOGGLE_MBS_CONFIRM_POPUP
  };
}

export function toggleAgreementConfirmPopup() {
  return {
    type: TOGGLE_AGREEMENT_CONFIRM_POPUP
  };
}

export function setAction(payload) {
  return {
    type: SET_ACTION,
    payload
  };
}

export function clearAction() {
  return {
    type: CLEAR_ACTION
  };
}

export function setAgency(payload) {
  return {
    type: SET_AGENCY,
    payload
  };
}

export function clearAgency() {
  return {
    type: CLEAR_AGENCY
  };
}

export function openOrderStatusChangeDialog() {
  return {
    type: OPEN_ORDER_STATUS_CHANGE_DIALOG
  };
}

export function closeOrderStatusChangeDialog() {
  return {
    type: CLOSE_ORDER_STATUS_CHANGE_DIALOG
  };
}

export function setOrderStatusChangeDialogTitle(payload) {
  return {
    type: SET_ORDER_STATUS_CHANGE_DIALOG_TITLE,
    payload
  };
}

export function openConfirmTrafficItemDeleteDialog() {
  return {
    type: OPEN_TRAFFIC_ITEM_DELETE_DIALOG
  };
}

export function closeConfirmTrafficItemDeleteDialog() {
  return {
    type: CLOSE_TRAFFIC_ITEM_DELETE_DIALOG
  };
}

export function openTrafficItemStatusChangeDialog() {
  return {
    type: OPEN_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG
  };
}

export function closeTrafficItemStatusChangeDialog() {
  return {
    type: CLOSE_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG
  };
}

export function openAddTrafficDialog() {
  return {
    type: OPEN_ADD_TRAFFIC_DIALOG
  };
}

export function openAddAllTrafficItemsDialog() {
  return {
    type: OPEN_ADD_ALL_TRAFFIC_ITEMS_DIALOG
  };
}

export function closeAddTrafficDialog() {
  return {
    type: CLOSE_ADD_TRAFFIC_DIALOG
  };
}

export function openEditTraffickerDialog() {
  return {
    type: OPEN_EDIT_TRAFFICKER_DIALOG
  };
}

export function closeEditTraffickerDialog() {
  return {
    type: CLOSE_EDIT_TRAFFICKER_DIALOG
  };
}

export function closeAddAllTrafficItemsDialog() {
  return {
    type: CLOSE_ADD_ALL_TRAFFIC_ITEMS_DIALOG
  };
}

export function toggleOrdersAction() {
  return {
    type: TOGGLE_ORDERS_ACTION
  };
}

export function setCheckAllOrders(payload) {
  return {
    type: SET_CHECK_ALL_ORDERS,
    payload
  };
}

export function openDivideOrdersDialog() {
  return {
    type: OPEN_DIVIDE_ORDERS_DIALOG
  };
}

export function closeDivideOrdersDialog() {
  return {
    type: CLOSE_DIVIDE_ORDERS_DIALOG
  };
}

export function toggleTrafficItemAction() {
  return {
    type: TOGGLE_TRAFFIC_ITEM_ACTION
  };
}

export const resetTrafficItemAction = () => ({
  type: RESET_TRAFFIC_ITEM_ACTION
});

export function openTrafficItemMultipleEditDialog() {
  return {
    type: OPEN_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG
  };
}

export function closeTrafficItemMultipleEditDialog() {
  return {
    type: CLOSE_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG
  };
}

export function openTrafficExportVersionDialog() {
  return {
    type: OPEN_TRAFFIC_EXPORT_VERSION_DIALOG
  };
}

export function closeTrafficExportVersionDialog() {
  return {
    type: CLOSE_TRAFFIC_EXPORT_VERSION_DIALOG
  };
}

export function openTrafficExportDigitalVersionDialog() {
  return {
    type: OPEN_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG
  };
}

export function closeTrafficExportDigitalVersionDialog() {
  return {
    type: CLOSE_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG
  };
}

export function openSystemMessageDialog() {
  return {
    type: OPEN_SYSTEM_MESSAGE_DIALOG
  };
}

export function closeSystemMessageDialog() {
  return {
    type: CLOSE_SYSTEM_MESSAGE_DIALOG
  };
}

export const openSystemNotificationDialog = () => ({
  type: OPEN_SYSTEM_NOTIFICATION_DIALOG
});

export const closeSystemNotificationDialog = () => ({
  type: CLOSE_SYSTEM_NOTIFICATION_DIALOG
});

export const apiCallStart = () => ({ type: API_CALL_START });

export const apiCallSuccess = () => ({ type: API_CALL_SUCCESS });

export const apiCallError = () => ({ type: API_CALL_ERROR });

export const apiCall = (
  method = GET,
  endpoint = EMPTY_STRING,
  body = {}
) => dispatch => {
  dispatch(apiCallStart());

  return api[method](endpoint, body)
    .then(response => {
      dispatch(apiCallSuccess());
      return Promise.resolve(response);
    })
    .catch(error => {
      dispatch(apiCallError());
      return Promise.reject(error);
    });
};

export const apiCallDirect = (
  method = GET,
  endpoint = EMPTY_STRING,
  body = {}
) => () => {
  return api[method](endpoint, body);
};

export const apiCallAll = promises => dispatch => {
  dispatch(apiCallStart());

  return Promise.all(promises)
    .then(response => {
      dispatch(apiCallSuccess());
      return Promise.resolve(response);
    })
    .catch(error => {
      dispatch(apiCallError());
      return Promise.reject(error);
    });
};

export const toggleDrawer = () => ({
  type: TOGGLE_DRAWER
});

export const setRefetch = () => ({
  type: SET_REFETCH
});

export const resetRefetch = () => ({
  type: RESET_REFETCH
});

export function toggleMediaTypeConfirmPopup() {
  return {
    type: TOGGLE_MEDIA_TYPE_CONFIRM_POPUP
  };
}

export function setEditAgencyRule(payload) {
  return {
    type: EDIT_AGENCY_RULE,
    payload
  };
}
