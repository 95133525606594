import React, { useState, useRef } from "react";

import { Grid } from "@mui/material";

import { currencies } from "../../../../../../business/constants/businessConstants";
import ActionOrderElementPopper from "./ActionOrderElementPopper";

import ActionOrderElementCompact from "./ActionOrderElementCompact";
import ActionOrderElementActions from "./ActionOrderElementActions";

const ActionOrderElement = ({ order }) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleToggle = e => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleClose = event => {
    if (buttonRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const {
    statusId,
    marathonOrderId,
    orderStartDate,
    orderEndDate,
    netTotal,
    description,
    orderPoNumber,
    targetAudience,
    position,
    frequencyCap,
    accountId,
    numOfUnits,
    unit,
    discountAmount,
    agencyDiscountAmount,
    surcharge1Amount,
    surcharge2Amount,
    remarks,
    currencySupplierSymbol,
    currencyClient,
    currencySupplierId,
    id,
    feePercentage,
    flightId,
    canUserDoActions,
    exchangeRateClient,
    exchangeRate
  } = order;

  const showEurCurrency = currencySupplierId === currencies.EUR;

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <ActionOrderElementCompact
            canUserDoActions={canUserDoActions}
            statusId={statusId}
            marathonOrderId={marathonOrderId}
            description={description}
            orderStartDate={orderStartDate}
            orderEndDate={orderEndDate}
            netTotal={netTotal}
            currencySupplierSymbol={currencySupplierSymbol}
            surcharge1Amount={surcharge1Amount}
            surcharge2Amount={surcharge2Amount}
            showEurCurrency={showEurCurrency}
            currencyClient={currencyClient}
            currencySupplierId={currencySupplierId}
            orderId={id}
            feePercentage={feePercentage}
            flightId={flightId}
            exchangeRateClient={exchangeRateClient}
            exchangeRateSupplier={exchangeRate}
          />
        </Grid>
        <Grid item>
          <ActionOrderElementActions
            buttonRef={buttonRef}
            canUserDoActions={canUserDoActions}
            statusId={statusId}
            onToggle={handleToggle}
            orderId={id}
          />
        </Grid>
      </Grid>
      <ActionOrderElementPopper
        orderId={id}
        open={open}
        onClose={handleClose}
        buttonRef={buttonRef}
        description={description}
        orderPoNumber={orderPoNumber}
        targetAudience={targetAudience}
        position={position}
        frequencyCap={frequencyCap}
        accountId={accountId}
        numOfUnits={numOfUnits}
        unit={unit}
        agencyDiscountAmount={agencyDiscountAmount}
        netTotal={netTotal}
        discountAmount={discountAmount}
        remarks={remarks}
        surcharge1Amount={surcharge1Amount}
        surcharge2Amount={surcharge2Amount}
      />
    </>
  );
};
export default ActionOrderElement;
