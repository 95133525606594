import React from "react";
import ReactDOM from "react-dom/client";
import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material";
import * as ReactRedux from "react-redux";
import { authProvider } from "./configurations/authProvider.ts";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./configurations/reduxSettings";
import "./assets/css/index.css";
import "./web.config";
import { HOME_PAGE_PATH } from "./configurations/appConstants";
import { loadUserProfileData } from "./business/actions/userProfileActions";
import { authenticated } from "./business/actions/applicationActions";
import IndexComponent from "./components/IndexComponent";
import theme from "./assets/styles/theme";

// if (process.env.NODE_ENV !== "production") {
//   // eslint-disable-next-line global-require
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//     trackExtraHooks: [[ReactRedux, "useSelectors"]]
//   });
// }

const store = configureStore();

if (window.location.pathname === HOME_PAGE_PATH) {
  store.dispatch(loadUserProfileData());
}

store.dispatch(authenticated());

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <MsalProvider instance={authProvider}>
    <ThemeProvider theme={theme}>
      <ReactRedux.Provider store={store}>
        <IndexComponent />
      </ReactRedux.Provider>
    </ThemeProvider>
  </MsalProvider>
);

serviceWorker.unregister();
