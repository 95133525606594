import React from "react";
import { Alert, Grid } from "@mui/material";
import { Field } from "formik";
import { ConfigOptionsContextConsumer } from "../../configurationContext";
import AutoCompleteFormik from "../../../../form/controls/AutoCompleteFormik";

const SpecificTitleFields = ({
  type,
  isHighRiskTitleRuleActive,
  canEditAgencyRules
}) => {
  return (
    <ConfigOptionsContextConsumer>
      {context => (
        <>
          <Grid item xs={6}>
            <Grid container alignItems="center" spacing={2}>
              {!canEditAgencyRules && (
                <Grid item justifyContent="center" xs={6}>
                  <Alert elevation={0} variant="outlined" severity="info">
                    You can only add values
                  </Alert>
                </Grid>
              )}
              <Grid item xs={7}>
                <Field
                  component={AutoCompleteFormik}
                  disabled={!isHighRiskTitleRuleActive}
                  id={`${type}TitleId`}
                  name={`${type}TitleId`}
                  textFieldProps={{
                    label: "Title ID"
                  }}
                  multiple
                  canEditAgencyRules={canEditAgencyRules}
                  options={context.titleOptions}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </ConfigOptionsContextConsumer>
  );
};

export default SpecificTitleFields;
