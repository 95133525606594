import {
  cloneDeep,
  findIndex,
  isEmpty,
  isString,
  isNaN,
  isObject,
  isNumber,
  isBoolean
} from "lodash";
import { denormalize } from "normalizr";
import { ruleTypesEnum, EMPTY_STRING } from "../../configurations/appConstants";
import entitiesSchema from "../schemas/configurationPage/entitiesSchema";
import { isNullOrUndefined } from "../../functions/util";

const {
  FOREIGN_CURRENCY_NAME,
  NEGATIVE_AMOUNT_NAME,
  SPECIFIC_TITLE_NAME,
  SPECIFIC_SURCHARGE_NAME,
  EXCEED_THRESHOLD_NAME
} = ruleTypesEnum;

const propertyIdMapping = {
  NumOfUnits: 1,
  DiscountAmount: 2,
  NetTotal: 3,
  Surcharge1TypeId: 4,
  Surcharge2TypeId: 5,
  Surcharge1Amount: 6,
  Surcharge2Amount: 7,
  TitleId: 8,
  CurrencyClient: 9,
  CurrencySupplierId: 10,
  Ctc: 11
};

const ruleIdMapping = {
  [FOREIGN_CURRENCY_NAME]: 1,
  [NEGATIVE_AMOUNT_NAME]: 2,
  [SPECIFIC_TITLE_NAME]: 3,
  [SPECIFIC_SURCHARGE_NAME]: 4,
  [EXCEED_THRESHOLD_NAME]: 5
};

const getBooleanValue = condition =>
  Boolean(
    condition?.conditionValue?.value?.toString().replace(/\./, ",") ||
      EMPTY_STRING
  );

const getArrayValue = (condition, options) =>
  condition?.conditionValue?.value.map(val => {
    const s = options.find(to => to.key === val);
    return { value: s.key, label: s.text };
  }) || [];

const getNumberValue = condition =>
  condition?.conditionValue?.value?.toString().replace(/\./, ",") ||
  EMPTY_STRING;

const getApproverValue = (ruleUsers, users) =>
  ruleUsers.map(user => ({
    value: parseInt(users[user].userId, 10),
    label: users[user].username
  }));

const mapValues = (source, options) => {
  const { rules, conditions, users } = source;
  const { titleOptions, surchargeOptions } = options;

  const {
    foreignCurrency,
    negativeAmount,
    highRiskTitle,
    highRiskSurcharge,
    exceedsThreshold
  } = ruleIdMapping;

  const fcConditions = rules[foreignCurrency].conditions.map(
    co => conditions[co]
  );
  const naConditions = rules[negativeAmount].conditions.map(
    co => conditions[co]
  );
  const stConditions = rules[highRiskTitle].conditions.map(
    co => conditions[co]
  );
  const ssConditions = rules[highRiskSurcharge].conditions.map(
    co => conditions[co]
  );
  const etConditions = rules[exceedsThreshold].conditions.map(
    co => conditions[co]
  );

  return {
    [`${NEGATIVE_AMOUNT_NAME}NumOfUnits`]: getNumberValue(
      naConditions.find(na => na.propertyId === propertyIdMapping.NumOfUnits)
    ),
    [`${NEGATIVE_AMOUNT_NAME}DiscountAmount`]: getNumberValue(
      naConditions.find(
        na => na.propertyId === propertyIdMapping.DiscountAmount
      )
    ),
    [`${NEGATIVE_AMOUNT_NAME}NetTotal`]: getNumberValue(
      naConditions.find(na => na.propertyId === propertyIdMapping.NetTotal)
    ),
    [`${NEGATIVE_AMOUNT_NAME}Surcharge1Amount`]: getNumberValue(
      naConditions.find(
        na => na.propertyId === propertyIdMapping.Surcharge1Amount
      )
    ),
    [`${NEGATIVE_AMOUNT_NAME}Surcharge2Amount`]: getNumberValue(
      naConditions.find(
        na => na.propertyId === propertyIdMapping.Surcharge2Amount
      )
    ),
    [`${NEGATIVE_AMOUNT_NAME}Ctc`]: getNumberValue(
      naConditions.find(na => na.propertyId === propertyIdMapping.Ctc)
    ),
    [`${NEGATIVE_AMOUNT_NAME}Rule`]: rules[2].isActive || false,
    [`${NEGATIVE_AMOUNT_NAME}Approvers`]: getApproverValue(
      rules[2].users,
      users
    ),

    [`${EXCEED_THRESHOLD_NAME}NumOfUnits`]: getNumberValue(
      etConditions.find(et => et.propertyId === propertyIdMapping.NumOfUnits)
    ),
    [`${EXCEED_THRESHOLD_NAME}DiscountAmount`]: getNumberValue(
      etConditions.find(
        et => et.propertyId === propertyIdMapping.DiscountAmount
      )
    ),
    [`${EXCEED_THRESHOLD_NAME}NetTotal`]: getNumberValue(
      etConditions.find(et => et.propertyId === propertyIdMapping.NetTotal)
    ),
    [`${EXCEED_THRESHOLD_NAME}Surcharge1Amount`]: getNumberValue(
      etConditions.find(
        et => et.propertyId === propertyIdMapping.Surcharge1Amount
      )
    ),
    [`${EXCEED_THRESHOLD_NAME}Surcharge2Amount`]: getNumberValue(
      etConditions.find(
        et => et.propertyId === propertyIdMapping.Surcharge2Amount
      )
    ),
    [`${EXCEED_THRESHOLD_NAME}Ctc`]: getNumberValue(
      etConditions.find(et => et.propertyId === propertyIdMapping.Ctc)
    ),
    [`${EXCEED_THRESHOLD_NAME}Rule`]: rules[5].isActive || false,
    [`${EXCEED_THRESHOLD_NAME}Approvers`]: getApproverValue(
      rules[5].users,
      users
    ),

    [`${SPECIFIC_SURCHARGE_NAME}Surcharge1TypeId`]: getArrayValue(
      ssConditions.find(
        ss => ss.propertyId === propertyIdMapping.Surcharge1TypeId
      ),
      surchargeOptions
    ),
    [`${SPECIFIC_SURCHARGE_NAME}Surcharge2TypeId`]: getArrayValue(
      ssConditions.find(
        ss => ss.propertyId === propertyIdMapping.Surcharge2TypeId
      ),
      surchargeOptions
    ),
    [`${SPECIFIC_SURCHARGE_NAME}Rule`]: rules[4].isActive || false,
    [`${SPECIFIC_SURCHARGE_NAME}Approvers`]: getApproverValue(
      rules[4].users,
      users
    ),

    [`${SPECIFIC_TITLE_NAME}TitleId`]: getArrayValue(
      stConditions.find(st => st.propertyId === propertyIdMapping.TitleId),
      titleOptions
    ),
    [`${SPECIFIC_TITLE_NAME}Rule`]: rules[3].isActive || false,
    [`${SPECIFIC_TITLE_NAME}Approvers`]: getApproverValue(
      rules[3].users,
      users
    ),

    [`${FOREIGN_CURRENCY_NAME}CurrencyClient`]: getBooleanValue(
      fcConditions.find(
        fcc => fcc.propertyId === propertyIdMapping.CurrencyClient
      )
    ),

    [`${FOREIGN_CURRENCY_NAME}CurrencySupplierId`]: getBooleanValue(
      fcConditions.find(
        fcc => fcc.propertyId === propertyIdMapping.CurrencySupplierId
      )
    ),
    [`${FOREIGN_CURRENCY_NAME}Rule`]: rules[1].isActive || false,
    [`${FOREIGN_CURRENCY_NAME}Approvers`]: getApproverValue(
      rules[1].users,
      users
    )
  };
};

const isValidPayload = payload => {
  let retVal = false;
  if (isObject(payload) && !isEmpty(payload)) {
    retVal = true;
  }
  if (isBoolean(payload) && payload === true) {
    retVal = true;
  }
  if (isNumber(payload)) {
    retVal = true;
  }
  return retVal;
};

const setValue = model => {
  // eslint-disable-next-line no-param-reassign
  model.value = [
    propertyIdMapping.TitleId,
    propertyIdMapping.Surcharge1TypeId,
    propertyIdMapping.Surcharge2TypeId
  ].includes(model.id)
    ? model.payload.map(p => p.value)
    : model.payload;
};
const generateNewCondition = model => ({
  propertyId: model.id,
  conditionValue: {
    value: model.value
  }
});

const isExistingCondition = (conditions, propertyId) =>
  conditions.some(c => c.propertyId === propertyId);

const updateOrRemoveCondition = (source, model) => {
  let conditions = cloneDeep(source);
  const propIdx = findIndex(conditions, {
    propertyId: model.id
  });

  if (model.isValid) {
    // eslint-disable-next-line no-param-reassign
    conditions[propIdx].conditionValue.value = model.value;
  } else {
    // eslint-disable-next-line no-param-reassign
    conditions = conditions.filter(c => c.propertyId !== model.id);
  }
  return conditions;
};

const addCondition = (conditions, model) => {
  const newCondition = generateNewCondition(model);
  conditions.push(newCondition);
};

const setRuleConditions = (source, properties) => {
  let conditions = cloneDeep(source);
  Object.keys(properties).forEach(propId => {
    const propertyId = parseInt(propId, 10);
    const payload = properties[propertyId];
    const hasPayload = isValidPayload(payload);
    const isExisting = isExistingCondition(conditions, propertyId);

    const propertyModel = {
      isValid: hasPayload,
      id: propertyId,
      value: null,
      payload: properties[propertyId]
    };

    if (propertyModel.isValid) {
      setValue(propertyModel);
    }

    if (!isExisting && propertyModel.isValid) {
      addCondition(conditions, propertyModel);
    } else if (isExisting) {
      conditions = updateOrRemoveCondition(conditions, propertyModel);
    }
  });
  return conditions;
};

const getUsers = approvers => {
  const retVal = {};
  approvers.forEach(approver => {
    retVal[approver.key] = {
      userId: approver.key,
      username: approver.text
    };
  });
  return retVal;
};

const formatValues = values => {
  const result = {};
  Object.entries(values).forEach(([key, value]) => {
    if (isObject(value) || isNumber(value) || isBoolean(value)) {
      result[key] = value;
    }
    if (!isNullOrUndefined(value) && isString(value)) {
      let tempVal = value.includes(".") ? value.replace(/\./g, "") : value;
      tempVal = tempVal.includes(",") ? tempVal.replace(/,/, ".") : tempVal;
      tempVal = parseFloat(tempVal);
      const retVal = isNaN(tempVal) ? value : tempVal;
      result[key] = retVal;
    }
  });
  return result;
};

const formatData = (values, entities, options) => {
  const { rules } = entities;
  const formatedValues = formatValues(values);
  const {
    foreignCurrencyRule,
    negativeAmountRule,
    highRiskTitleRule,
    highRiskSurchargeRule,
    exceedsThresholdRule,

    foreignCurrencyApprovers,
    negativeAmountApprovers,
    highRiskTitleApprovers,
    highRiskSurchargeApprovers,
    exceedsThresholdApprovers,

    negativeAmountNumOfUnits,
    negativeAmountDiscountAmount,
    negativeAmountNetTotal,
    negativeAmountSurcharge1Amount,
    negativeAmountSurcharge2Amount,
    negativeAmountCtc,
    exceedsThresholdNumOfUnits,
    exceedsThresholdDiscountAmount,
    exceedsThresholdNetTotal,
    exceedsThresholdSurcharge1Amount,
    exceedsThresholdSurcharge2Amount,
    exceedsThresholdCtc,
    highRiskSurchargeSurcharge1TypeId,
    highRiskSurchargeSurcharge2TypeId,
    highRiskTitleTitleId,
    foreignCurrencyCurrencyClient,
    foreignCurrencyCurrencySupplierId
  } = formatedValues;

  const normalizedRules = {};

  // update isActive
  normalizedRules[ruleIdMapping[FOREIGN_CURRENCY_NAME]] = {
    ...rules[ruleIdMapping[FOREIGN_CURRENCY_NAME]],
    isActive: foreignCurrencyRule
  };

  normalizedRules[ruleIdMapping[NEGATIVE_AMOUNT_NAME]] = {
    ...rules[ruleIdMapping[NEGATIVE_AMOUNT_NAME]],
    isActive: negativeAmountRule
  };

  normalizedRules[ruleIdMapping[SPECIFIC_TITLE_NAME]] = {
    ...rules[ruleIdMapping[SPECIFIC_TITLE_NAME]],
    isActive: highRiskTitleRule
  };

  normalizedRules[ruleIdMapping[SPECIFIC_SURCHARGE_NAME]] = {
    ...rules[ruleIdMapping[SPECIFIC_SURCHARGE_NAME]],
    isActive: highRiskSurchargeRule
  };

  normalizedRules[ruleIdMapping[EXCEED_THRESHOLD_NAME]] = {
    ...rules[ruleIdMapping[EXCEED_THRESHOLD_NAME]],
    isActive: exceedsThresholdRule
  };

  // update users
  normalizedRules[ruleIdMapping[FOREIGN_CURRENCY_NAME]] = {
    ...normalizedRules[ruleIdMapping[FOREIGN_CURRENCY_NAME]],
    users: foreignCurrencyApprovers.map(user => user.value)
  };

  normalizedRules[ruleIdMapping[NEGATIVE_AMOUNT_NAME]] = {
    ...normalizedRules[ruleIdMapping[NEGATIVE_AMOUNT_NAME]],
    users: negativeAmountApprovers.map(user => user.value)
  };

  normalizedRules[ruleIdMapping[SPECIFIC_TITLE_NAME]] = {
    ...normalizedRules[ruleIdMapping[SPECIFIC_TITLE_NAME]],
    users: highRiskTitleApprovers.map(user => user.value)
  };

  normalizedRules[ruleIdMapping[SPECIFIC_SURCHARGE_NAME]] = {
    ...normalizedRules[ruleIdMapping[SPECIFIC_SURCHARGE_NAME]],
    users: highRiskSurchargeApprovers.map(user => user.value)
  };

  normalizedRules[ruleIdMapping[EXCEED_THRESHOLD_NAME]] = {
    ...normalizedRules[ruleIdMapping[EXCEED_THRESHOLD_NAME]],
    users: exceedsThresholdApprovers.map(user => user.value)
  };

  const { approverOptions } = options;
  const input = Object.keys(entities.agencies)[0];
  const settingsResult = denormalize(input, entitiesSchema, {
    ...entities,
    users: getUsers(approverOptions),
    rules: normalizedRules
  });

  // update conditions
  const fcConditions = settingsResult.rules[0].conditions;
  const naConditions = settingsResult.rules[1].conditions;
  const stConditions = settingsResult.rules[2].conditions;
  const ssConditions = settingsResult.rules[3].conditions;
  const etConditions = settingsResult.rules[4].conditions;

  const {
    Ctc,
    CurrencyClient,
    CurrencySupplierId,
    DiscountAmount,
    NetTotal,
    NumOfUnits,
    Surcharge1Amount,
    Surcharge1TypeId,
    Surcharge2Amount,
    Surcharge2TypeId,
    TitleId
  } = propertyIdMapping;

  let props = {
    [CurrencyClient]: foreignCurrencyCurrencyClient,
    [CurrencySupplierId]: foreignCurrencyCurrencySupplierId
  };

  settingsResult.rules[0].conditions = setRuleConditions(fcConditions, props);

  props = {
    [NumOfUnits]: negativeAmountNumOfUnits,
    [DiscountAmount]: negativeAmountDiscountAmount,
    [NetTotal]: negativeAmountNetTotal,
    [Surcharge1Amount]: negativeAmountSurcharge1Amount,
    [Surcharge2Amount]: negativeAmountSurcharge2Amount,
    [Ctc]: negativeAmountCtc
  };

  settingsResult.rules[1].conditions = setRuleConditions(naConditions, props);

  props = {
    [TitleId]: highRiskTitleTitleId
  };

  settingsResult.rules[2].conditions = setRuleConditions(stConditions, props);

  props = {
    [Surcharge1TypeId]: highRiskSurchargeSurcharge1TypeId,
    [Surcharge2TypeId]: highRiskSurchargeSurcharge2TypeId
  };

  settingsResult.rules[3].conditions = setRuleConditions(ssConditions, props);

  props = {
    [NumOfUnits]: exceedsThresholdNumOfUnits,
    [DiscountAmount]: exceedsThresholdDiscountAmount,
    [NetTotal]: exceedsThresholdNetTotal,
    [Surcharge1Amount]: exceedsThresholdSurcharge1Amount,
    [Surcharge2Amount]: exceedsThresholdSurcharge2Amount,
    [Ctc]: exceedsThresholdCtc
  };

  settingsResult.rules[4].conditions = setRuleConditions(etConditions, props);
  return settingsResult;
};

export { mapValues, formatData };
