import React from "react";
import PropTypes from "prop-types";
import {
  classes,
  MuiSelect as Select,
  autocompleteCustomStyles
} from "../styles/AutoComplete";
import Components from "./extensions/autocomplete/index";
import { isNullOrUndefined } from "../../../functions/util";
import { EMPTY_STRING } from "../../../configurations/appConstants";
import { solitude } from "../../../assets/styles/colors";

const AutoComplete = ({
  id,
  variant,
  label,
  name,
  displayErrors,
  errors,
  disabled,
  prevValue,
  value,
  options,
  textFieldClassName,
  isMulti,
  InputProps,
  formHelperTextProps,
  inputLabelProps,
  helperText,
  placeholder,
  onChange
}) => {
  const handleChange = val => {
    if (typeof onChange === "function") {
      if (val && val.constructor === Array) {
        const newVal = val ? val.map(x => x.value) : null;
        onChange(newVal);
      } else {
        onChange(val ? val.value : null);
      }
    }
  };

  const filteredOptions = options.filter(item => {
    if (isNullOrUndefined(item.enabled)) {
      return true;
    }
    if (!isNullOrUndefined(prevValue) && item.key === prevValue) {
      return true;
    }
    return item.enabled;
  });

  const suggestions = filteredOptions.map(suggestion => ({
    value: suggestion.key,
    label: suggestion.text
  }));

  const errorText = errors[0];
  const isValid = errors.length === 0;

  let valueObject = null;
  if (value && value.constructor === Array) {
    valueObject = [];
    value.forEach(x => {
      valueObject = valueObject.concat(suggestions.filter(s => s.value === x));
    });
  } else {
    valueObject = suggestions.filter(s => s.value === value)[0] || null;
  }

  return (
    <Select
      id={id}
      variant={variant}
      label={label}
      name={name}
      options={suggestions}
      components={Components}
      value={valueObject}
      onChange={handleChange}
      errorText={errorText}
      isValid={isValid}
      displayErrors={displayErrors}
      isDisabled={disabled}
      placeholder={placeholder}
      styles={autocompleteCustomStyles}
      classes={classes}
      menuPortalTarget={document.body}
      textFieldClassName={textFieldClassName}
      isMulti={isMulti}
      formHelperTextProps={formHelperTextProps}
      inputLabelProps={inputLabelProps}
      helperText={helperText}
      isSearchable
      isClearable
      InputProps={{
        ...InputProps,
        sx: { background: disabled && solitude }
      }}
    />
  );
};

AutoComplete.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["standard", "outlined"]),
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  prevValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool
  ]),
  displayErrors: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]),
      text: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

AutoComplete.defaultProps = {
  variant: "outlined",
  options: [],
  errors: [],
  displayErrors: false,
  disabled: false,
  label: EMPTY_STRING,
  placeholder: EMPTY_STRING
};

export default AutoComplete;
