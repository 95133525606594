import { createReducer } from "../common/utils/reducerUtils";

import {
  PROJECT_SELECT,
  PROJECT_EDIT_START,
  PROJECT_EDIT_STOP,
  PROJECT_CLEAR_CURRENT,
  PROJECT_DISPLAY_ERRORS,
  PROJECT_HIDE_ERRORS,
  PROJECT_SET_IS_NEW,
  PROJECT_RESET_IS_NEW,
  PROJECT_RESET_CURRENT
} from "./projectConstants";

const initialState = {
  currentProject: null,
  isNew: false,
  isEditing: false,
  displayErrors: false
};

export function selectProject(state, payload) {
  return {
    ...state,
    currentProject: payload.currentProject
  };
}

export function startEditingProject(state) {
  return {
    ...state,
    isEditing: true
  };
}

export function stopEditingProject(state) {
  return {
    ...state,
    isEditing: false
  };
}

export function displayErrors(state) {
  return {
    ...state,
    displayErrors: true
  };
}

export function hideErrors(state) {
  return {
    ...state,
    displayErrors: false
  };
}

export function setIsNew(state) {
  return {
    ...state,
    isNew: true
  };
}

export function resetIsNew(state) {
  return {
    ...state,
    isNew: false
  };
}

export function clearCurrent() {
  return initialState;
}

export function resetCurrent(state) {
  return {
    ...state,
    isNew: false,
    isEditing: false,
    displayErrors: false
  };
}

export default createReducer(initialState, {
  [PROJECT_SELECT]: selectProject,
  [PROJECT_EDIT_START]: startEditingProject,
  [PROJECT_EDIT_STOP]: stopEditingProject,
  [PROJECT_CLEAR_CURRENT]: clearCurrent,
  [PROJECT_DISPLAY_ERRORS]: displayErrors,
  [PROJECT_HIDE_ERRORS]: hideErrors,
  [PROJECT_SET_IS_NEW]: setIsNew,
  [PROJECT_RESET_IS_NEW]: resetIsNew,
  [PROJECT_RESET_CURRENT]: resetCurrent
});
