import React, { memo } from "react";

import { List } from "@mui/material";

import SideBarItem from "./SideBarItem";

const SideBarList = ({ drawerItems, userActions }) => {
  return (
    <List>
      {drawerItems.map(
        ({ path, url, title, icon, key }) =>
          userActions.includes(key) && (
            <SideBarItem
              key={key}
              path={path}
              url={url}
              title={title}
              icon={icon}
            />
          )
      )}
    </List>
  );
};

export default memo(SideBarList);
