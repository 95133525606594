import React from "react";
import { useSelector } from "react-redux";
import toUpper from "lodash/toUpper";
import toLower from "lodash/toLower";
import { Grid, Typography, Tooltip, Divider } from "@mui/material";
import { MediaTypeIcon } from "../../../../../assets/styles/icons"; // TODO Dynamic
import { parseDate } from "../../../../../business/models/common/utils/clientUtils";
import { getPlanData } from "../../../../../business/models/Plan/planSelectors";
import {
  PLACEHOLDER,
  EMPTY_STRING
} from "../../../../../configurations/appConstants";
import { classes, Paper } from "../../styles/sidePanel";

const OrderLeftSidePanel = () => {
  const {
    mediaType,
    planName,
    marathonPlanId: planId,
    campaignName,
    marathonCampaignId: campaignId,
    debtor: debtorName,
    marathonDebtorId: debtorId,
    planAgreement,
    planPoNumber,
    startDate,
    endDate,
    currencySupplier,
    exchangeRate,
    planner
  } = useSelector(getPlanData) || {};
  return (
    <Paper square elevation={0} className={classes.sidePanel}>
      <div className={classes.sidePanelWrapper}>
        <div className={classes.sideBox}>
          <Grid container alignItems="center">
            <Grid item zeroMinWidth>
              <MediaTypeIcon type={toLower(mediaType)} />
            </Grid>
            <Grid item>
              <Tooltip title={toUpper(mediaType) || EMPTY_STRING}>
                <Typography
                  align="left"
                  noWrap
                  className={classes.typographyHeaderCaption}
                >
                  &nbsp;{toUpper(mediaType)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} zeroMinWidth>
              <Tooltip title={planName || EMPTY_STRING}>
                <Typography
                  align="left"
                  noWrap
                  className={classes.typographyHeaderValue}
                >
                  {planName}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.sideBox}>
          <Tooltip title={campaignName || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {campaignName}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Campaign Name
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={campaignId || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {campaignId}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Campaign ID
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={planId || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {planId}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Plan ID
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={debtorName || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {debtorName}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Debtor Name
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={debtorId || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {debtorId}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Debtor ID
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={planAgreement || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {planAgreement}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Plan Agreement
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={planPoNumber || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {planPoNumber || PLACEHOLDER}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Plan PO number
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={parseDate(startDate) || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {parseDate(startDate)}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Campaign Start date
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={parseDate(endDate) || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {parseDate(endDate)}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Campaign End date
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={currencySupplier || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {currencySupplier}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Currency Supplier
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={exchangeRate || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {exchangeRate}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Exchange Rate Supplier
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={planner || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {planner}
            </Typography>
          </Tooltip>

          <Typography align="left" noWrap className={classes.typographyCaption}>
            Planner
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

export default OrderLeftSidePanel;
