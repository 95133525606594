import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { setPlanValue } from "../../business/models/Plan/planActions";

import {
  getPlanRatePropertyValue,
  getPlanPropertyPreviousValue,
  getPlanPropertyErrorsByProperty,
  selectPlanDisplayErrors,
  getPlanPropertyOptions,
  isDisabled
} from "../../business/models/Plan/planSelectors";

import Controls from "./controls/index";

const PlanField = props => {
  const { name, component, ...componentProps } = props;
  const dispatch = useDispatch();

  const value = useSelector(state => getPlanRatePropertyValue(state, props));
  const prevValue = useSelector(state =>
    getPlanPropertyPreviousValue(state, props)
  );
  const errors = useSelector(state =>
    getPlanPropertyErrorsByProperty(state, props)
  );
  const displayErrors = useSelector(selectPlanDisplayErrors);
  const options = useSelector(state => getPlanPropertyOptions(state, props));
  const disabled = useSelector(state => isDisabled(state, props));

  const handleChange = val => {
    dispatch(setPlanValue({ [name]: val }));
  };

  const Control = Controls[component];
  return (
    <Control
      component={component}
      {...componentProps}
      onChange={handleChange}
      value={value}
      prevValue={prevValue}
      errors={errors}
      displayErrors={displayErrors}
      options={options}
      disabled={disabled}
    />
  );
};

PlanField.propTypes = {
  component: PropTypes.oneOf(["decimal"]),
  name: PropTypes.string.isRequired
};

export default PlanField;
