import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setSystemMessageValue } from "../../business/models/SystemMessage/systemMessageActions";

import {
  getSystemMessagePropertyValue,
  getSystemMessagePropertyPreviousValue,
  getSystemMessagePropertyErrorsByProperty,
  selectSystemMessageDisplayErrors
} from "../../business/models/SystemMessage/systemMessageSelectors";

import Controls from "./controls/index";

const SystemMessageField = ({
  component,
  name,
  onChange,
  ...componentProps
}) => {
  const handleChange = value => {
    onChange({ [name]: value });
  };

  const Control = Controls[component];

  return (
    <Control
      component={component}
      {...componentProps}
      onChange={handleChange}
    />
  );
};

SystemMessageField.propTypes = {
  component: PropTypes.oneOf(["text", "date", "checkbox", "uploadButton"]),
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

SystemMessageField.defaultProps = {
  errors: []
};

const mapStateToProps = (state, ownProps) => {
  const { disabled = false } = ownProps;
  return {
    value: getSystemMessagePropertyValue(state, ownProps),
    prevValue: getSystemMessagePropertyPreviousValue(state, ownProps),
    errors: getSystemMessagePropertyErrorsByProperty(state, ownProps),
    displayErrors: selectSystemMessageDisplayErrors(state),
    disabled
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: (id, data) => dispatch(setSystemMessageValue(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemMessageField);
