import React from "react";
import clsx from "clsx";
import { CardActionArea, Avatar, Typography, CardHeader } from "@mui/material";
import { DeleteNotificationIcon } from "../../../../assets/styles/icons";
import { classes, Card } from "../styles/clearNotificationButtonCard";

const ClearNotificationButtonCard = ({ title, onCardClick, id }) => {
  return (
    <Card className={clsx(classes.card)} elevation={5}>
      <CardActionArea onClick={() => onCardClick(id)}>
        <CardHeader
          avatar={
            <Avatar className={classes.colorSystemMessageAvatar}>
              <DeleteNotificationIcon />
            </Avatar>
          }
          title={
            <Typography id="clearNotifications" name="clearNotifications">
              {title}
            </Typography>
          }
        />
      </CardActionArea>
    </Card>
  );
};

export default ClearNotificationButtonCard;
