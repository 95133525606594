import { styled, Card } from "@mui/material";

const PREFIX = "CardLinkStyles";
export const classes = {
  actionArea: `${PREFIX}-actionArea`,
  media: `${PREFIX}-media`,
  content: `${PREFIX}-content`
};

export const MuiCard = styled(Card)(({ theme }) => ({
  [`& .${classes.actionArea}`]: {
    minWidth: theme.typography.pxToRem(240),
    display: "inline-block"
  },
  [`& .${classes.media}`]: {
    height: theme.typography.pxToRem(80),
    backgroundSize: theme.typography.pxToRem(80)
  },
  [`& .${classes.content}`]: {
    paddingTop: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
    maxHeight: theme.typography.pxToRem(80)
  }
}));
