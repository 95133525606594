import React from "react";
import PropTypes from "prop-types";

import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid
} from "@mui/material";

import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import Scrollbar from "../../../../../../shared/Scrollbar";

import {
  classes,
  DialogContent
} from "../../../../styles/trafficExportPreviewTable";
import {
  PLACEHOLDER,
  EMPTY_TABLE
} from "../../../../../../../configurations/appConstants";

const PreviewPrint = ({ data }) => {
  const {
    paperRoot,
    tableCellUnderline,
    scrollTableCell,
    scrollTableCellHeaderColord,
    tableCellHeader,
    tableCellHeaderInner,
    tableCell,
    scrollTableTypography
  } = classes;

  const {
    debtorName,
    plannerAgency,
    productName,
    creationDate,
    remarks,
    creativeAgency,
    contact,
    email,
    cc,
    contactGroupm,
    phoneNumberGroupm,
    version,
    ordersPrint,
    planName
  } = data;
  return (
    <>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className={paperRoot} elevation={0}>
              <Table>
                <TableBody key={"campaignId"}>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CLIENT NAME
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{debtorName}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        VERSION
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{version}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        PRODUCT NAME
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{productName}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        DATE
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{creationDate}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        PLAN NAME
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{planName}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        REPLACES DATE
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{PLACEHOLDER}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={2} className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        REMARKS
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell} rowSpan={2} colSpan={4}>
                      <Typography align="left">{remarks}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={paperRoot} elevation={0}>
              <Table>
                <TableBody key={"campaignId"}>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CREATIVE AGENCY
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{creativeAgency}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        CONTACT GROUPM
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{contactGroupm}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CONTACT
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{contact}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        EMAIL ADDRESS
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">print@groupm.com</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        E-MAIL
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography variant="subtitle2" align="left">
                        {email || "print@groupm.com"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        PHONE NUMBER
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{phoneNumberGroupm}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={tableCellHeader}>
                      <Typography variant="subtitle2" align="left">
                        CC
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{cc}</Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(tableCellHeader, tableCellHeaderInner)}
                    >
                      <Typography variant="subtitle2" align="left">
                        PLANNER AGENCY
                      </Typography>
                    </TableCell>
                    <TableCell className={tableCell}>
                      <Typography align="left">{plannerAgency}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={paperRoot} elevation={0}>
              <Scrollbar
                autoHeightMax={600}
                autoHeightMin={95}
                autoHide={false}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          GROUPM ORDER NUMBER
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          PLAN NUMBER
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          TITLE+SALESHOUSE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          FORMAT
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          TRIM SIZE IN MM (WxH)
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          TYPE SIZE IN MM (WxH)
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          COLOUR
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          TECHNICAL SPECIFICATIONS
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          HEADER / VISUAL
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          EDITION NUMBER
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          ON SALE DATE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          scrollTableCell,
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          MATERIAL DEADLINE
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          tableCellUnderline,
                          scrollTableCellHeaderColord
                        )}
                      >
                        <Typography
                          variant="subtitle2"
                          className={scrollTableTypography}
                          align="left"
                          noWrap
                        >
                          ORDER REMARKS
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {!isEmpty(ordersPrint) ? (
                      ordersPrint.map((order, index) => {
                        const {
                          marathonOrderId,
                          marathonPlanId,
                          title,
                          format,
                          trimSize,
                          typeSize,
                          colour,
                          technicalSpecifications,
                          description,
                          editionNumber,
                          orderStartDate,
                          materialDeadline,
                          remarks: orderRemarks
                        } = order;
                        return (
                          <TableRow
                            key={`${marathonOrderId +
                              marathonPlanId} - ${index}`}
                          >
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {marathonOrderId}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {marathonPlanId}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {title}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {format}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {trimSize}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {typeSize}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {colour}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {technicalSpecifications}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {description}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {editionNumber}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {orderStartDate}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {materialDeadline}
                              </Typography>
                            </TableCell>
                            <TableCell className={scrollTableCell}>
                              <Typography align="left" noWrap>
                                {orderRemarks}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={17} align="center">
                          <Typography align="center" noWrap>
                            {EMPTY_TABLE}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

PreviewPrint.propTypes = {
  data: PropTypes.object
};

export default PreviewPrint;
