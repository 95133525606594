import React from "react";
import toUpper from "lodash/toUpper";
import isEmpty from "lodash/isEmpty";

import { Grid, Typography, Divider } from "@mui/material";

import { UserAccountIcon } from "../../../../../assets/styles/icons";
import { classes, Paper } from "../../styles/userProfilePageStyle";

const UserOverviewPanel = ({ data }) => {
  const {
    name,
    email,
    marathonUser,
    agency,
    admin,
    agent,
    agentAgencies,
    roles
  } = data;

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <UserAccountIcon />
              </Grid>
              <Grid item>
                <Typography
                  id="name"
                  name="name"
                  className={classes.typographyHeaderCaption}
                  noWrap
                >
                  {toUpper(name)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Email:
            </Typography>
          </Grid>
          <Grid item>{email}</Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Marathon user name:
            </Typography>
          </Grid>
          <Grid item>{marathonUser}</Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Agency:
            </Typography>
          </Grid>
          <Grid item>{agency}</Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Roles:
            </Typography>
          </Grid>
          <Grid item>{roles}</Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Admin:
            </Typography>
          </Grid>
          <Grid item>{admin}</Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Agent:
            </Typography>
          </Grid>
          <Grid item>{agent}</Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Agent agencies:
            </Typography>
          </Grid>
          <Grid item>{isEmpty(agentAgencies) ? "-" : agentAgencies}</Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default UserOverviewPanel;
