import React, { useState } from "react";
import PropTypes from "prop-types";

import isFunction from "lodash/isFunction";

import CtcButtonBase from "./CtcButtonBase";
import MultipleCurrencySupplierDialog from "../../pages/create/popup/MultipleCurrencySupplierDialog";
import { TYPE_BUTTON, TYPE_ICON } from "../../../configurations/appConstants";

const CtcButtonCampaign = ({
  hasMultipleCurrencies,
  type,
  fetchData,
  plans,
  campaign,
  loading,
  showCtc,
  id,
  name
}) => {
  const [openDialog, setDialog] = useState(false);
  const [openTooltip, setTooltip] = useState(false);

  const handleDialogOpenAndFetchData = e => {
    e.stopPropagation();
    if (isFunction(fetchData)) {
      fetchData().then(() => setDialog(true));
    } else {
      setDialog(true);
    }
  };

  const handleDialogClose = e => {
    e.stopPropagation();
    setDialog(false);
  };

  const handleTooltipClose = e => {
    e.stopPropagation();
    setTooltip(false);
  };

  const handleTooltipOpenAndFetchData = e => {
    e.stopPropagation();
    if (isFunction(fetchData)) {
      fetchData().then(() => setTooltip(true));
    } else {
      setTooltip(true);
    }
  };

  const handleOpen = e =>
    hasMultipleCurrencies
      ? handleDialogOpenAndFetchData(e)
      : handleTooltipOpenAndFetchData(e);

  const handleClose = e =>
    hasMultipleCurrencies ? handleDialogClose(e) : handleTooltipClose(e);

  const { clientTotalCostEur } = campaign || {};

  return (
    <>
      <CtcButtonBase
        id={id}
        name={name}
        open={openTooltip}
        onOpen={handleOpen}
        onClose={handleClose}
        clientTotalCostEur={clientTotalCostEur}
        hasMultipleCurrencies={hasMultipleCurrencies}
        type={type}
        showCtc={showCtc}
        loading={loading}
      />
      <MultipleCurrencySupplierDialog
        open={openDialog}
        onOk={handleDialogClose}
        planListData={plans}
        campaignData={campaign}
        loading={loading}
      />
    </>
  );
};

CtcButtonCampaign.propTypes = {
  fetchData: PropTypes.func,
  campaign: PropTypes.object,
  plans: PropTypes.array,
  hasMultipleCurrencies: PropTypes.bool,
  showCtc: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf([TYPE_BUTTON, TYPE_ICON]),
  id: PropTypes.string,
  name: PropTypes.string
};

CtcButtonCampaign.defaultProps = {
  type: TYPE_BUTTON,
  hasMultipleCurrencies: false,
  loading: false,
  showCtc: false
};

export default CtcButtonCampaign;
