import React, { memo } from "react";
import PropTypes from "prop-types";

import { NavLink, useLocation } from "react-router-dom";
import { ListItemIcon, ListItemText } from "@mui/material";

import { useSelector } from "react-redux";
import { selectIsFormOpen } from "../../../../business/selectors/campaignDashboardSelector";
import { classes, ListItemButton } from "../styles/sideBarItem";

const SideBarItem = ({ path, url, title, icon }) => {
  const location = useLocation();
  const isFormOpen = useSelector(selectIsFormOpen);
  const isInCopyProcess = location.pathname.includes("copy-plan-wizard"); // TODO: Temporary solution, copy plan functionality needs refactoring
  const isMenuDisabled =
    isInCopyProcess || (title === "Create" ? isFormOpen : false);

  const fullPath = location.pathname + location.search;
  const selected = fullPath.includes(path) && path !== "/";
  return (
    <ListItemButton
      button="true"
      selected={selected}
      component={NavLink}
      to={url}
      className={classes.drawerItem}
      disabled={isMenuDisabled}
    >
      <ListItemIcon
        className={
          selected ? classes.drawerSelectedItemIcon : classes.drawerItemIcon
        }
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={title}
        className={
          selected ? classes.drawerSelectedItemText : classes.drawerItemText
        }
        disableTypography
      />
    </ListItemButton>
  );
};

SideBarItem.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  selected: PropTypes.bool
};

SideBarItem.defaultProps = {
  selected: false
};

export default memo(SideBarItem);
