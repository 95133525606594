import React from "react";
import { Info } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";

const CurrencyAdornment = ({ onClick }) => {
  return (
    <InputAdornment position="end">
      <IconButton aria-label="open Currency dialog" onClick={onClick}>
        <Info />
      </IconButton>
    </InputAdornment>
  );
};

export default CurrencyAdornment;
