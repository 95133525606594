import get from "lodash/get";
import has from "lodash/has";

import { getApiData, getPendingData } from "../selectors/apiDataSelectors";
import callApi from "../../functions/api";
import {
  LOAD_API_DATA_SUCCESS,
  LOAD_API_DATA_ERROR,
  BEGIN_API_DATA_CALL
} from "../constants/apiDataConstants";
import { enqueueApiErrorMessage } from "./applicationActions";
import { getHash } from "../models/common/utils/modelUtils";

const beginApiDataApiCall = hash => {
  return { type: BEGIN_API_DATA_CALL, id: hash };
};

const loadApiDataError = hash => {
  return { type: LOAD_API_DATA_ERROR, id: hash };
};

const fetchApiData = (url, callback) => dispatch => {
  const hash = getHash(url);
  dispatch(beginApiDataApiCall(hash));
  return callApi.get(url).then(
    response => {
      const apiData = has(response, "items")
        ? get(response, "items")
        : response;
      dispatch({
        type: LOAD_API_DATA_SUCCESS,
        id: hash,
        response: apiData
      });
      if (typeof callback === "function") {
        dispatch(callback());
      }
    },
    error => {
      dispatch(loadApiDataError(hash));
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

const shouldFetchApiData = (state, hash) => {
  const pendingData = getPendingData(state).indexOf(hash);
  if (pendingData === -1) {
    const apiData = getApiData(state)[hash];
    if (!apiData) {
      return true;
    }
  }
  return false;
};

const fetchApiDataIfNeeded = (url, callback) => (dispatch, getState) => {
  const hash = getHash(url);
  if (shouldFetchApiData(getState(), hash)) {
    dispatch(fetchApiData(url, callback));
  } else if (typeof callback === "function") {
    dispatch(callback());
  }
};

export default fetchApiDataIfNeeded;
