import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import upperFirst from "lodash/upperFirst";

import { MediaTypeSearchIcon } from "../../../../assets/styles/icons";
import { mediaTypesEnumTraffic } from "../../../../configurations/appConstants";
import { classes, Tooltip, IconButton } from "../styles/trafficMediaTypeSearch";

const TrafficMediaTypeSearch = ({ searchMediaTypes, onSearchMediaTypes }) => {
  return (
    <>
      {mediaTypesEnumTraffic.map((mediaType, key) => (
        <Tooltip key={key} title={upperFirst(mediaType)}>
          <IconButton
            name={mediaType}
            id={mediaType}
            className={clsx({
              [classes.active]: searchMediaTypes.includes(mediaType)
            })}
            onClick={() => onSearchMediaTypes(mediaType)}
          >
            <MediaTypeSearchIcon type={mediaType} />
          </IconButton>
        </Tooltip>
      ))}
    </>
  );
};

TrafficMediaTypeSearch.propTypes = {
  searchMediaTypes: PropTypes.array
};

TrafficMediaTypeSearch.defaultProps = {
  searchMediaTypes: []
};

export default TrafficMediaTypeSearch;
