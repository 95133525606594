import { styled, Table as MuiTable } from "@mui/material";
import { APP_BAR_HEIGHT } from "../../../../configurations/appConstants";
import { matisse, solitude } from "../../../../assets/styles/colors";

const PREFIX = "ActionInsertionItemStyles";
export const classes = {
  table: `${PREFIX}-table`,
  header: `${PREFIX}-header`
};

export const Table = styled(MuiTable)(({ theme }) => ({
  [`&.${classes.table}`]: {
    overflowX: "auto",
    marginBottom: theme.typography.pxToRem(APP_BAR_HEIGHT)
  },
  [`& .${classes.header}`]: {
    backgroundColor: solitude,
    color: matisse
  }
}));
