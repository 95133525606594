import React from "react";
import PropTypes from "prop-types";

import FirstTable from "./PlanTargetMetricsKpiTables/FirstTargetMetricsKpiTable";
import SecondTable from "./PlanTargetMetricsKpiTables/SecondTargetMetricsKpiTable";

const PlanTargetMetricsKpiTable = ({ data, onAdd, onEdit, onDelete }) => {
  return (
    <>
      <FirstTable data={data} onHandleEdit={onEdit} onHandleDelete={onDelete} />
      <br />
      <SecondTable
        data={data}
        onHandleAdd={onAdd}
        onHandleEdit={onEdit}
        onHandleDelete={onDelete}
      />
    </>
  );
};

PlanTargetMetricsKpiTable.propTypes = {
  mediaType: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default PlanTargetMetricsKpiTable;
