import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";

import { Typography, IconButton, Checkbox, Chip } from "@mui/material";

import { classes, Grid } from "../styles/actionDialogElement";

import {
  ViewIcon,
  StatusIcon,
  ApprovedSelectionIcon,
  DeclineSelectionIcon
} from "../../../../../assets/styles/icons";
import { ACTION_DONE } from "../../../../../business/constants/actionPageConstants";
import { COLOR_JORDYBLUE } from "../../../../../configurations/appConstants";

import { parseDate } from "../../../../../business/models/common/utils/clientUtils";
import ActionDialogElementPopper from "./extensions/ActionDialogElementPopper";
import { getPropertyTextFromApiData } from "../../../../../business/models/Order/orderSelectors";

const ActionOrderElement = ({
  id,
  order,
  statusSuccess,
  isSelected,
  onCheckboxClick,
  action
}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleToggle = () => {
    buttonRef.current.focus();
    setOpen(!open);
  };

  const handleClose = event => {
    if (buttonRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const actionIcon = () => {
    switch (action) {
      case ACTION_DONE:
        return statusSuccess ? (
          <ApprovedSelectionIcon />
        ) : (
          <DeclineSelectionIcon />
        );
      default:
        return null;
    }
  };

  const {
    statusId,
    marathonOrderId,
    orderStartDate,
    orderEndDate,
    netTotal,
    description,
    orderPoNumber,
    targetAudience,
    position,
    frequencyCap,
    accountId,
    numOfUnits,
    unit,
    discountAmount,
    agencyDiscountAmount,
    surcharge1Amount,
    surcharge2Amount,
    remarks,
    currencySupplierSymbol,
    feePercentage
  } = order;

  const title = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "titleId", orderId: id })
  );
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        key={id}
        alignItems="center"
      >
        <Grid item xs={9}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <StatusIcon id="statusId" name="statusId" status={statusId} />
            </Grid>
            <Grid item xs={3}>
              <Typography
                id="title"
                name="title"
                className={classes.cssTypography}
                noWrap
              >
                {title}
              </Typography>
            </Grid>
            <Grid item key={marathonOrderId} zeroMinWidth>
              <Chip
                id="marathonOrderId"
                name="marathonOrderId"
                label={marathonOrderId}
                className={classes.chip}
                component={Typography}
              />
            </Grid>
            <Grid item zeroMinWidth>
              <Chip
                id="orderDate"
                name="orderDate"
                label={`${parseDate(orderStartDate)} - ${parseDate(
                  orderEndDate
                )}`}
                className={classes.chip}
                component={Typography}
              />
            </Grid>
            <Grid item key={netTotal} zeroMinWidth>
              <Chip
                label={
                  <NumberFormat
                    id="netTotal"
                    name="netTotal"
                    decimalScale={2}
                    value={netTotal}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={currencySupplierSymbol}
                    fixedDecimalScale
                  />
                }
                className={classes.chip}
                component={Typography}
              />
            </Grid>
            <Grid item key={feePercentage} zeroMinWidth>
              <Chip
                label={
                  <NumberFormat
                    id="feePercentage"
                    name="feePercentage"
                    decimalScale={2}
                    value={feePercentage}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="%"
                    fixedDecimalScale
                  />
                }
                className={classes.chip}
                component={Typography}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={0}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <IconButton
                id={`view_selection_${id}`}
                name={`view_selection_${id}`}
                onClick={handleToggle}
                ref={buttonRef}
                className={classes.iconButton}
              >
                <ViewIcon color={COLOR_JORDYBLUE} />
              </IconButton>
            </Grid>
            <Grid item>
              {actionIcon() || (
                <Checkbox
                  onClick={() => onCheckboxClick(id)}
                  checked={isSelected}
                  color="primary"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ActionDialogElementPopper
        open={open}
        onClose={handleClose}
        buttonRef={buttonRef}
        orderId={id}
        description={description}
        orderPoNumber={orderPoNumber}
        targetAudience={targetAudience}
        position={position}
        frequencyCap={frequencyCap}
        accountId={accountId}
        numOfUnits={numOfUnits}
        unit={unit}
        discountAmount={discountAmount}
        netTotal={netTotal}
        agencyDiscountAmount={agencyDiscountAmount}
        remarks={remarks}
        surcharge1Amount={surcharge1Amount}
        surcharge2Amount={surcharge2Amount}
      />
    </>
  );
};

ActionOrderElement.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  order: PropTypes.object.isRequired,
  statusSuccess: PropTypes.bool,
  isSelected: PropTypes.bool,
  onCheckboxClick: PropTypes.func.isRequired,
  action: PropTypes.string
};

ActionOrderElement.defaultProps = {
  isSelected: false,
  statusSuccess: false
};

export default ActionOrderElement;
