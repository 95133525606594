import { EMPTY_STRING } from "../../../configurations/appConstants";
import { createReducer } from "../common/utils/reducerUtils";

import {
  CAMPAIGN_SELECT,
  CAMPAIGN_EDIT_START,
  CAMPAIGN_EDIT_STOP,
  CAMPAIGN_CLEAR_CURRENT,
  CAMPAIGN_DISPLAY_ERRORS,
  CAMPAIGN_HIDE_ERRORS,
  CAMPAIGN_SET_IS_NEW,
  CAMPAIGN_RESET_IS_NEW,
  RESET_CLIENT_CREDIT_MESSAGE,
  SET_CLIENT_CREDIT_MESSAGE
} from "./campaignConstants";

const initialState = {
  currentCampaign: null,
  isNew: false,
  isEditing: false,
  displayErrors: false,
  clientCreditMessage: EMPTY_STRING
};

export function selectCampaign(state, payload) {
  return {
    ...state,
    currentCampaign: payload.currentCampaign
  };
}

export function startEditingCampaign(state) {
  return {
    ...state,
    isEditing: true
  };
}

export function stopEditingCampaign(state) {
  return {
    ...state,
    isEditing: false
  };
}

export function displayErrors(state) {
  return {
    ...state,
    displayErrors: true
  };
}

export function hideErrors(state) {
  return {
    ...state,
    displayErrors: false
  };
}

export function setIsNew(state) {
  return {
    ...state,
    isNew: true
  };
}

export function resetIsNew(state) {
  return {
    ...state,
    isNew: false
  };
}

export function clearCurrent() {
  return initialState;
}

export function setClientCreditMessage(state, payload) {
  return {
    ...state,
    clientCreditMessage: payload.clientCreditMessage
  };
}

export function resetClientCreditMessage(state) {
  return {
    ...state,
    clientCreditMessage: EMPTY_STRING
  };
}

export default createReducer(initialState, {
  [CAMPAIGN_SELECT]: selectCampaign,
  [CAMPAIGN_EDIT_START]: startEditingCampaign,
  [CAMPAIGN_EDIT_STOP]: stopEditingCampaign,
  [CAMPAIGN_CLEAR_CURRENT]: clearCurrent,
  [CAMPAIGN_DISPLAY_ERRORS]: displayErrors,
  [CAMPAIGN_HIDE_ERRORS]: hideErrors,
  [CAMPAIGN_SET_IS_NEW]: setIsNew,
  [CAMPAIGN_RESET_IS_NEW]: resetIsNew,
  [SET_CLIENT_CREDIT_MESSAGE]: setClientCreditMessage,
  [RESET_CLIENT_CREDIT_MESSAGE]: resetClientCreditMessage
});
