import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import cuid from "cuid";
import isEmpty from "lodash/isEmpty";

import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";

import Scrollbar from "../../../../shared/Scrollbar";

import { EMPTY_TABLE } from "../../../../../configurations/appConstants";
import { ApprovalStatusIcon } from "../../../../../assets/styles/icons";

import { classes, Paper } from "../../styles/approvalMatrixStyle";

const StatusMatrixTable = ({ columns, rows }) => {
  return (
    <>
      <Paper className={classes.paperRoot} elevation={0}>
        <Scrollbar autoHeightMax={600} autoHeightMin={95} autoHide={false}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  className={clsx(
                    classes.scrollTableCell,
                    classes.tableCellUnderline
                  )}
                >
                  <Typography variant="subtitle2" align="left" noWrap>
                    Rule
                  </Typography>
                </TableCell>
                {!isEmpty(columns) &&
                  columns.map(column => (
                    <TableCell
                      key={cuid()}
                      className={clsx(
                        classes.scrollTableCell,
                        classes.tableCellUnderline
                      )}
                    >
                      <Typography variant="subtitle2" align="left" noWrap>
                        {column}
                      </Typography>
                    </TableCell>
                  ))}
              </TableRow>
              {!isEmpty(rows) ? (
                rows.map(row => {
                  const { header, isApproved } = row;
                  return (
                    <TableRow key={cuid()}>
                      <TableCell className={classes.scrollTableCell}>
                        <Typography align="left" noWrap>
                          {header}
                        </Typography>
                      </TableCell>
                      {isApproved &&
                        isApproved.map(approveRow => (
                          <TableCell
                            key={cuid()}
                            align="center"
                            className={classes.scrollTableCell}
                          >
                            {ApprovalStatusIcon({ status: approveRow })}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={17} align="center">
                    <Typography align="center" noWrap>
                      {EMPTY_TABLE}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </Paper>
    </>
  );
};

StatusMatrixTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired
};

export default StatusMatrixTable;
