import React, { Component } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Button } from "@mui/material";

import { canViewCampaignOverview } from "../../../../../business/selectors/userProfileSelectors";
import { selectCurrentCampaign } from "../../../../../business/models/Campaign/campaignSelectors";
import { selectCurrentPlan } from "../../../../../business/models/Plan/planSelectors";
import { selectCurrentOrder } from "../../../../../business/models/Order/orderSelectors";
import { selectIsLoading } from "../../../../../business/selectors/applicationSelectors";
import { selectSelectedNumber } from "../../../../../business/selectors/actionPageSelectors";
import {
  openDialog,
  setAction
} from "../../../../../business/actions/actionPageActions";

import {
  ACTION_APPROVE,
  ACTION_DECLINE
} from "../../../../../business/constants/actionPageConstants";

import {
  CAMPAIGN,
  PLAN,
  ORDER,
  CREATE_PAGE,
  EMPTY_STRING
} from "../../../../../configurations/appConstants";
import { isNullOrUndefinedOrEmpty } from "../../../../../functions/util";
import { classes, Grid } from "../styles/ActionActions";
import withRouter from "../../../configuration/form/withRouter";

class ActionActions extends Component {
  handleApproveSelection = () => {
    this.props.openDialog();
    this.props.setAction(ACTION_APPROVE);
  };

  handleDeclineSelection = () => {
    this.props.openDialog();
    this.props.setAction(ACTION_DECLINE);
  };

  goToOverviewDetailsPage = () => {
    const {
      campaignID,
      planID,
      orderID,
      canViewCampaignOverviewDetails,
      navigate,
      params
    } = this.props;

    const agencyValue = params.agency;

    if (canViewCampaignOverviewDetails) {
      const plan = isNullOrUndefinedOrEmpty(planID)
        ? EMPTY_STRING
        : `/${PLAN}/${planID}`;
      const order = isNullOrUndefinedOrEmpty(orderID)
        ? EMPTY_STRING
        : `/${ORDER}/${orderID}`;
      const urlParams = `${CAMPAIGN}/${campaignID}${plan}${order}`;
      navigate(`/${agencyValue}/${CREATE_PAGE}/${urlParams}`);
    }
  };

  render() {
    const {
      selectedNumber,
      disabled,
      isLoading,
      canViewCampaignOverviewDetails
    } = this.props;

    return (
      <Grid container justifyContent="space-between">
        <Grid item>
          {canViewCampaignOverviewDetails && (
            <Button
              id="redirect_campaign-dashboard"
              name="redirect_campaign-dashboard"
              className={classes.redirectButton}
              disabled={isLoading}
              onClick={this.goToOverviewDetailsPage}
            >
              GO TO DASHBOARD PAGE
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button
            id="o_decline"
            name="o_decline"
            variant="contained"
            color="secondary"
            className={clsx(classes.saveButton, classes.declineButton)}
            disabled={disabled}
            onClick={this.handleDeclineSelection}
          >
            {`DECLINE SELECTION (${selectedNumber})`}
          </Button>
          <Button
            id="o_approve"
            name="o_approve"
            variant="contained"
            color="primary"
            className={clsx(classes.saveButton, classes.approveButton)}
            disabled={disabled}
            onClick={this.handleApproveSelection}
          >
            {`APPROVE SELECTION (${selectedNumber})`}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  const selectedNumber = selectSelectedNumber(state);
  const disabled = selectedNumber === 0;

  return {
    campaignID: selectCurrentCampaign(state),
    planID: selectCurrentPlan(state),
    orderID: selectCurrentOrder(state),
    canViewCampaignOverviewDetails: canViewCampaignOverview(state),
    isLoading: selectIsLoading(state),
    disabled,
    selectedNumber
  };
};

const mapDispatchToProps = dispatch => ({
  openDialog: () => dispatch(openDialog()),
  setAction: action => dispatch(setAction(action))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionActions)
);
