import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { classes, Grid } from "../styles/emptyTrafficItemsLabel";

const EmptyTrafficItemsLabel = () => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    className={classes.fullHeight}
  >
    <Typography className={classes.color} variant="h5">
      NO TRAFFIC PLAN SELECTED
    </Typography>
  </Grid>
);

EmptyTrafficItemsLabel.propTypes = {};

export default EmptyTrafficItemsLabel;
