import { styled, Grid as MuiGrid } from "@mui/material";

const PREFIX = "MultipleEditStyles";
export const classes = {
  gridItem: `${PREFIX}-gridItem`,
  field: `${PREFIX}-field`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`&.${classes.gridItem}`]: {
    width: theme.typography.pxToRem(190),
    height: theme.typography.pxToRem(75),
    padding: theme.typography.pxToRem(12),
    [`& .MuiInputLabel-root`]: {
      zIndex: 1500
    }
  },
  [`& .${classes.field}`]: {
    marginTop: theme.typography.pxToRem(0)
  }
}));
