export const SYSTEM_MESSAGE_SELECT = "SYSTEM_MESSAGE_SELECT";
export const SYSTEM_MESSAGE_EDIT_START = "SYSTEM_MESSAGE_EDIT_START";
export const SYSTEM_MESSAGE_EDIT_STOP = "SYSTEM_MESSAGE_EDIT_STOP";
export const SYSTEM_MESSAGE_CLEAR_CURRENT = "SYSTEM_MESSAGE_CLEAR_CURRENT";
export const SYSTEM_MESSAGE_DISPLAY_ERRORS = "SYSTEM_MESSAGE_DISPLAY_ERRORS";
export const SYSTEM_MESSAGE_HIDE_ERRORS = "SYSTEM_MESSAGE_HIDE_ERRORS";
export const SYSTEM_MESSAGE_SET_IS_NEW = "SYSTEM_MESSAGE_SET_IS_NEW";
export const SYSTEM_MESSAGE_RESET_IS_NEW = "SYSTEM_MESSAGE_RESET_IS_NEW";

export const SYSTEM_MESSAGE_SUCCESS_MESSAGE = "Message saved successfully";

export const SYSTEM_MESSAGE_MODEL_NAME = "SystemMessage";
export const SYSTEM_MESSAGE_MODEL_ID = "systemMessageId";

export const SYSTEM_MESSAGE_ATTACHMENT_FILE_SIZE_VALIDATION_ERROR_MESSAGE =
  "Attachment should be less than or equal to 10 megabytes, please try again.";
export const SYSTEM_MESSAGE_ATTACHMENT_FILE_TYPE_VALIDATION_ERROR_MESSAGE =
  "Selected file type is not supported.";
