import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Divider,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

import InsertionActions from "../actions/InsertionActions";
import OrderPanel from "./extensions/OrderPanel";

import { addNewInsertion } from "../../../../business/models/Insertion/insertionActions";
import {
  getEditingOrderFull,
  isOrderCancelledOrToBeCancelled,
  isOrderPendingForApproval
} from "../../../../business/models/Order/orderSelectors";
import { selectOpenInsertionPopup } from "../../../../business/selectors/applicationSelectors";

import { classes, Dialog } from "../styles/popup";

const InsertionPopup = () => {
  const dispatch = useDispatch();
  const isCancelledOrToBeCancelled = useSelector(
    isOrderCancelledOrToBeCancelled
  );
  const isPendingForApproval = useSelector(isOrderPendingForApproval);
  const open = useSelector(selectOpenInsertionPopup);
  const order = useSelector(getEditingOrderFull) || {};
  const disableAddInsertions =
    isCancelledOrToBeCancelled || isPendingForApproval;

  const handleAddInsertion = () => {
    dispatch(addNewInsertion());
  };

  const paperProps = { className: classes.insertionPopup, square: true };
  return (
    <Dialog open={open} PaperProps={paperProps} maxWidth={false}>
      <DialogTitle>Edit insertions</DialogTitle>
      <Divider />
      <DialogContent>
        <OrderPanel
          order={order}
          onAddInsertion={handleAddInsertion}
          allowAddInsertion
          disableAddInsertions={disableAddInsertions}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <InsertionActions />
      </DialogActions>
    </Dialog>
  );
};

export default InsertionPopup;
