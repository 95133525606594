import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogContentText
} from "@mui/material";

const paperProps = { square: true };

const CurrencyClientDialog = props => {
  const { open, onOk } = props;

  return (
    <Dialog open={open} PaperProps={paperProps} maxWidth="md">
      <DialogContent>
        <DialogContentText noWrap={false}>
          When currency client is other than EUR the exchange rate client should
          contain the rate to convert that foreign currency to EUR, issued by
          GroupM Finance. When currency client is EUR the exchange rate client
          is always 1.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Button onClick={onOk} color="secondary">
            Ok
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CurrencyClientDialog;
