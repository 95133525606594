import { ORM } from "redux-orm";
import User from "./User/User";
import Campaign from "./Campaign/Campaign";
import Plan from "./Plan/Plan";
import PlanExtension from "./PlanExtension/PlanExtension";
import PlanTargetMetricsKpi from "./PlanTargetMetricsKpi/PlanTargetMetricsKpi";
import PlanChangeOverview from "./PlanChangeOverview/PlanChangeOverview";
import Project from "./Project/Project";
import Order from "./Order/Order";
import Insertion from "./Insertion/Insertion";
import TrafficPlan from "./TrafficPlan/TrafficPlan";
import TrafficPlanRemark from "./TrafficPlanRemark/TrafficPlanRemark";
import TrafficItem from "./TrafficItem/TrafficItem";
import SystemMessage from "./SystemMessage/SystemMessage";
import TrafficTemplate from "./TrafficTemplate/TrafficTemplate";

const orm = new ORM();
orm.register(
  User,
  Campaign,
  Plan,
  PlanExtension,
  PlanTargetMetricsKpi,
  PlanChangeOverview,
  Order,
  Insertion,
  Project,
  TrafficPlan,
  TrafficPlanRemark,
  TrafficItem,
  SystemMessage,
  TrafficTemplate
);

export default orm;
