import { Model, attr, fk } from "redux-orm";
import set from "lodash/set";

import {
  PLAN_CHANGE_OVERVIEW_MODEL_NAME,
  PLAN_CHANGE_OVERVIEW_MODEL_ID
} from "./planChangeOverviewConstants";
import {
  PLAN_EXTENSION_MODEL_NAME,
  PLAN_EXTENSION_MODEL_ID
} from "../PlanExtension/planExtensionConstants";

import {
  DATE_RULE,
  DATE_MESSAGE,
  NUMBER_OF_CHARACTERS_RULE,
  NUMBER_OF_CHARACTERS_MESSAGE_100
} from "../../constants/validationConstants";

import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";

class PlanChangeOverview extends Model {
  static get fields() {
    return {
      id: attr(),
      planChangeOverviewId: attr(),
      planExtensionId: attr(),
      version: attr(),
      change: attr(),
      consequence: attr(),
      changedBy: attr(),
      date: attr(),
      planExtension: fk(PLAN_EXTENSION_MODEL_NAME)
    };
  }

  static get defaultAttributes() {
    return {};
  }

  static get inheritConfiguration() {
    return {};
  }

  static parse(planChangeOverviewData) {
    if (planChangeOverviewData[PLAN_CHANGE_OVERVIEW_MODEL_ID]) {
      set(
        planChangeOverviewData,
        "id",
        planChangeOverviewData[PLAN_CHANGE_OVERVIEW_MODEL_ID]
      );
    }
    if (planChangeOverviewData[PLAN_EXTENSION_MODEL_ID]) {
      set(
        planChangeOverviewData,
        "planExtension",
        planChangeOverviewData[PLAN_EXTENSION_MODEL_ID]
      );
    }

    return this.upsert(planChangeOverviewData);
  }

  static updateModel(planChangeOverviewData) {
    if (planChangeOverviewData[PLAN_CHANGE_OVERVIEW_MODEL_ID]) {
      set(
        planChangeOverviewData,
        "id",
        planChangeOverviewData[PLAN_CHANGE_OVERVIEW_MODEL_ID]
      );
    }
    if (planChangeOverviewData[PLAN_EXTENSION_MODEL_ID]) {
      set(
        planChangeOverviewData,
        "planExtension",
        planChangeOverviewData[PLAN_EXTENSION_MODEL_ID]
      );
    }

    return this.upsert(planChangeOverviewData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...PlanChangeOverview.defaultAttributes,
      ...newAttributes
    };
    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherPlanChangeOverview) {
    this.update(otherPlanChangeOverview.ref);
  }

  static get validation() {
    return {
      version: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      change: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      consequence: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      changedBy: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      date: [
        {
          rule: DATE_RULE,
          message: DATE_MESSAGE
        }
      ]
    };
  }

  static get calculations() {
    return {};
  }

  validate() {
    return validateModel(PlanChangeOverview.validation, this.toJSON());
  }

  validateByProperty(name) {
    return validateProperty(
      PlanChangeOverview.validation[name],
      this.toJSON()[name]
    );
  }

  hasErrors() {
    return doesModelHaveErrors(PlanChangeOverview.validation, this.toJSON());
  }

  static get apiConfiguration() {
    return {};
  }

  static get disabledFields() {
    return {};
  }
}

PlanChangeOverview.modelName = PLAN_CHANGE_OVERVIEW_MODEL_NAME;

export default PlanChangeOverview;
