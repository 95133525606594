import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  DialogActions,
  DialogContent,
  Button,
  Table,
  TableRow,
  TableCell,
  Dialog,
  TableBody
} from "@mui/material";
import TextField from "../../../form/controls/TextField";
import DateField from "../../../form/controls/DateField";
import Checkbox from "../../../form/controls/Checkbox";
import {
  closeDivideOrdersDialog,
  setCheckAllOrders
} from "../../../../business/actions/applicationActions";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import {
  selectIsDivideInsertionEvenly,
  selectSelectedInsertionDates
} from "../../../../business/models/Plan/planSelectors";
import {
  initializeInsertionDatesArray,
  updateInsertionDatesArray,
  clearInsertionDatesArray,
  clearOrdersForDivide,
  divideOrders,
  updateIsDivideEvenly
} from "../../../../business/models/Plan/planActions";
import { MAX_NUMBER_FOR_INPUTS } from "../../../../configurations/appConstants";
import { Grid, classes } from "../styles/divideOrdersDialog";
import DecimalField from "../../../form/controls/DecimalField";
import {
  INVOICE_DATE_PROPERTY,
  PO_NUMBER_PROPERTY,
  SPLIT_PERCENTAGE_PROPERTY
} from "../../../../business/models/Plan/planConstants";

const paperProps = { square: true };

const DivideOrdersDialog = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [created, setCreated] = useState(false);

  const isLoading = useSelector(selectIsLoading);
  const insertionDates = useSelector(selectSelectedInsertionDates);
  const isDivideInsertionsEvenly = useSelector(selectIsDivideInsertionEvenly);

  const clearData = () => {
    setCount(0);
    setCreated(false);
    dispatch(clearInsertionDatesArray());
  };

  const handleCountChange = value => {
    const val = Math.floor(Number(value));
    const min = 0;
    if (val >= min && val < MAX_NUMBER_FOR_INPUTS) {
      setCount(val);
    }
  };

  const handleOk = () => {
    dispatch(divideOrders()).then(isSuccessful => {
      if (isSuccessful) {
        clearData();

        dispatch(clearOrdersForDivide());
        dispatch(closeDivideOrdersDialog());
        dispatch(setCheckAllOrders(false));
      }
    });
  };

  const handleCancel = () => {
    clearData();
    dispatch(closeDivideOrdersDialog());
  };

  const handleCreate = () => {
    setCreated(true);
    dispatch(initializeInsertionDatesArray(count));
  };

  const isDisabled = created || count === 0;

  const handleDivideEvenlyChange = event => {
    dispatch(updateIsDivideEvenly(event));
    if (event) {
      insertionDates.map((obj, index) => {
        return dispatch(
          updateInsertionDatesArray(index, null, SPLIT_PERCENTAGE_PROPERTY)
        );
      });
    }
  };

  const handleValueChange = (index, value, property) => {
    dispatch(updateInsertionDatesArray(index, value, property));
  };

  return (
    <Dialog fullWidth open PaperProps={paperProps} maxWidth={"lg"}>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <TextField
              id="divideCount"
              name="divideCount"
              label="Number of Insertions"
              type="text"
              value={count}
              onChange={handleCountChange}
              disabled={created}
            />
            <Button
              id="createInsertion"
              name="createInsertion"
              onClick={handleCreate}
              disabled={isDisabled}
              color="primary"
            >
              Create
            </Button>
            <br />
            {count > 1 && created && (
              <Checkbox
                id={`divide-evenly-checkbox`}
                label="Divide Insertions Evenly"
                color="default"
                value={isDivideInsertionsEvenly}
                onChange={handleDivideEvenlyChange}
              ></Checkbox>
            )}
          </Grid>
          <Grid item xs={7}>
            <Table>
              <TableBody>
                {insertionDates.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell className={classes.tableCell}>
                      <DateField
                        component="date"
                        id={`invoiceDate${key}`}
                        label="Invoice Date"
                        value={item.invoiceDate}
                        onChange={value =>
                          handleValueChange(key, value, INVOICE_DATE_PROPERTY)
                        }
                      ></DateField>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <TextField
                        id={`poNumber${key}`}
                        label="PO Number"
                        value={item.poNumber}
                        onChange={value =>
                          handleValueChange(key, value, PO_NUMBER_PROPERTY)
                        }
                      ></TextField>
                    </TableCell>
                    {count > 1 && (
                      <TableCell className={classes.tableCell}>
                        <DecimalField
                          id={`splitPercentage${key}`}
                          label="Split Percentage"
                          decimalScale={2}
                          disabled={isDivideInsertionsEvenly}
                          value={
                            isDivideInsertionsEvenly ? 0 : item.splitPercentage
                          }
                          onChange={value =>
                            handleValueChange(
                              key,
                              value,
                              SPLIT_PERCENTAGE_PROPERTY
                            )
                          }
                          minValue={0}
                          maxValue={100}
                        ></DecimalField>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel"
          name="cancel"
          onClick={handleCancel}
          disabled={isLoading}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          id="create"
          name="create"
          onClick={handleOk}
          disabled={isLoading || !created}
          color="secondary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DivideOrdersDialog;
