import React from "react";
import PropTypes from "prop-types";
import { AccessTime } from "@mui/icons-material";
import { classes, IconButton } from "../styles/projectButton";

const ProjectButton = ({ onClick, title, id, projectExists, disabled }) => {
  return (
    <IconButton
      name={`open_project_${id}`}
      id={`open_project_${id}`}
      disabled={disabled}
      onClick={onClick}
      title={title}
      className={projectExists ? classes.blueIconButton : classes.iconButton}
    >
      <AccessTime />
    </IconButton>
  );
};

ProjectButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  projectExists: PropTypes.bool,
  disabled: PropTypes.bool
};

ProjectButton.defaultProps = {
  projectExists: false,
  title: "",
  disabled: false
};

export default ProjectButton;
