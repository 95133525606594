import { styled, Grid as MuiGrid } from "@mui/material";
import {
  baliHai,
  matisse,
  linkWater,
  azureRadiance,
  dodgerBlue,
  darkIndigo
} from "../../../assets/styles/colors";
import { getThemeSpacing } from "../../../functions/util";

const PREFIX = "CampaignStyles";
export const classes = {
  selected: `${PREFIX}-selected`,
  typographyHeaderCaption: `${PREFIX}-typographyHeaderCaption`,
  typographyHeaderValue: `${PREFIX}-typographyHeaderValue`,
  typographyValue: `${PREFIX}-typographyValue`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  dateWidth: `${PREFIX}-dateWidth`,
  budgetWidth: `${PREFIX}-budgetWidth`,
  ctcInfoGridItemAlign: `${PREFIX}-ctcInfoGridItemAlign`,
  typographyMarked: `${PREFIX}-typographyMarked`,
  ordersWidth: `${PREFIX}-ordersWidth`,
  typographyCaptionBlue: `${PREFIX}-typographyCaptionBlue`,
  typographyDetailsValue: `${PREFIX}-typographyDetailsValue`,
  iconButton: `${PREFIX}-iconButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`&.${classes.selected}`]: {
    borderColor: dodgerBlue
  },
  [`& .${classes.typographyHeaderCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyHeaderValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${classes.dateWidth}`]: {
    width: theme.typography.pxToRem(200)
  },
  [`& .${classes.budgetWidth}`]: {
    width: theme.typography.pxToRem(200)
  },
  [`& .${classes.ctcInfoGridItemAlign}`]: {
    textAlign: "end"
  },
  [`& .${classes.typographyMarked}`]: {
    color: azureRadiance
  },
  [`& .${classes.ordersWidth}`]: {
    width: theme.typography.pxToRem(78)
  },
  [`& .${classes.typographyCaptionBlue}`]: {
    color: darkIndigo,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold"
  },
  [`& .${classes.typographyDetailsValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`& .${classes.iconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  }
}));
