import { Box, Grid, styled } from "@mui/material";
import { getThemeSpacing } from "../../../../functions/util";
import OrderField from "../../../form/OrderField";

const PREFIX = "OrderFormStyles";
export const classes = {
  root: `${PREFIX}-root`,
  detailsLayout: `${PREFIX}-detailsLayout`,
  deatilsLeft: `${PREFIX}-deatilsLeft`,
  detailsRight: `${PREFIX}-detailsRight`,
  surchargeType: `${PREFIX}-surchargeType`
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: "0 auto",
    width: theme.typography.pxToRem(1386),
    marginBottom: "3rem"
  },
  [`& .${classes.detailsLayout}`]: {
    paddingTop: theme.typography.pxToRem(getThemeSpacing(theme, 2)),
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.deatilsLeft}`]: {
    paddingRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.detailsRight}`]: {
    paddingLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.surchargeType}`]: {
    [`& .MuiOutlinedInput-input`]: {
      paddingLeft: theme.typography.pxToRem(12),
      paddingRight: theme.typography.pxToRem(12)
    }
  }
}));

export const OrderFieldDate = props => {
  const { id, name, label } = props;

  return (
    <Box sx={{ pt: 2 }}>
      <OrderField component="date" id={id} name={name} label={label}>
        Save
      </OrderField>
    </Box>
  );
};
