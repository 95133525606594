import React, { memo } from "react";
import PropTypes from "prop-types";

import isEmpty from "lodash/isEmpty";

import { TableCell, TableRow } from "@mui/material";

import { EMPTY_TABLE } from "../../../../../configurations/appConstants";
import ActionCampaignItem from "../item/ActionCampaignItem";
import { classes, TableBody } from "../styles/ActionTableBody";

const ActionTableBody = ({ rows, contentLoading, onOpenClick }) => {
  return (
    <TableBody>
      {!isEmpty(rows)
        ? rows.map(row => (
            <TableRow key={row.campaignId}>
              <TableCell className={classes.tableCell}>
                {row.campaignId && (
                  <ActionCampaignItem
                    id={row.campaignId}
                    onOpenClick={onOpenClick}
                    campaign={row}
                  />
                )}
              </TableCell>
            </TableRow>
          ))
        : !contentLoading && (
            <TableRow>
              <TableCell align="center">{EMPTY_TABLE}</TableCell>
            </TableRow>
          )}
    </TableBody>
  );
};

ActionTableBody.propTypes = {
  rows: PropTypes.array.isRequired,

  onOpenClick: PropTypes.func.isRequired,
  contentLoading: PropTypes.bool
};

export default memo(ActionTableBody);
