import { serviceConfig } from "../configurations/appSettings";
import {
  ERROR_MSG_NOT_AUTHORIZED,
  ERROR_MSG_INVALID_DATA,
  ERROR_MSG_500,
  ERROR_MSG_NOT_FOUND,
  SUCCESS_MSG,
  ERROR_MSG_SERVICE
} from "../configurations/appConstants";
import { fetchWithAuth } from "./authUtils.ts";

const f = (endpoint, options) => {
  return new Promise((resolve, reject) => {
    const address = `${serviceConfig.resourceBaseAddress}/${endpoint}`;

    fetchWithAuth(fetch, address, options)
      .then(response => {
        switch (response.status) {
          case 200:
          case 201: {
            const contentType = response.headers.get("content-type");
            // eslint-disable-next-line no-unused-expressions
            contentType && contentType.indexOf("application/json") !== -1
              ? resolve(response.json())
              : resolve(response);
            break;
          }
          case 204:
            resolve(SUCCESS_MSG);
            break;
          case 400:
            reject(ERROR_MSG_INVALID_DATA);
            break;
          case 401:
          case 403:
            reject(ERROR_MSG_NOT_AUTHORIZED);
            break;
          case 404:
            reject(ERROR_MSG_NOT_FOUND);
            break;
          case 422:
            response.json().then(err => reject(err.validation_messages));
            break;
          case 500:
            reject(ERROR_MSG_500);
            break;
          default:
            reject(response.statusText);
            break;
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => reject(ERROR_MSG_SERVICE));
  });
};

export default f;
