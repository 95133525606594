import React from "react";

import { Button } from "@mui/material";

import { classes, Div } from "../styles/trafficClearAll";

const ClearAll = ({ onClear }) => {
  return (
    <Div className={classes.clearAll}>
      <Button
        name="clear_filters"
        id="clear_filters"
        className={classes.button}
        onClick={onClear}
      >
        Clear All
      </Button>
    </Div>
  );
};

export default ClearAll;
