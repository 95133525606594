import React from "react";
import { connect } from "react-redux";

import { Button } from "@mui/material";

import {
  saveTrafficItem,
  cancelTrafficItem
} from "../../../business/models/TrafficItem/trafficItemActions";
import { selectIsLoading } from "../../../business/selectors/applicationSelectors";
import { classes, Grid } from "../styles/trafficLine";

const TrafficLineActions = props => {
  const { isLoading, isDebtorActive } = props;

  const handleSave = () => {
    props.save();
  };

  const handleCancel = () => {
    props.cancel();
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
      alignItems="center"
    >
      <Grid item>
        {false && (
          <Button variant="text" className={classes.btn} disableFocusRipple>
            VERWIJERDEN
          </Button>
        )}
      </Grid>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <Button
              variant="text"
              disableFocusRipple
              disabled={isLoading}
              onClick={handleCancel}
              id="cancel_traffic"
              name="cancel_traffic"
            >
              CANCEL
            </Button>
          </Grid>
          {isDebtorActive && (
            <Grid item>
              <Button
                id="save-traffic"
                name="save-traffic"
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={isLoading}
              >
                SAVE
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  isLoading: selectIsLoading(state)
});

const mapDispatchToProps = dispatch => ({
  save: () => dispatch(saveTrafficItem()),
  cancel: () => dispatch(cancelTrafficItem())
});

export default connect(mapStateToProps, mapDispatchToProps)(TrafficLineActions);
