import React from "react";
import PropTypes from "prop-types";

import {
  Tooltip,
  IconButton,
  CircularProgress,
  ClickAwayListener
} from "@mui/material";
import { Info } from "@mui/icons-material";

import ButtonBase from "@mui/material/ButtonBase";

import CtcTooltipText from "./CtcTooltipText";
import { TYPE_BUTTON, TYPE_ICON } from "../../../configurations/appConstants";
import { currencies } from "../../../business/constants/businessConstants";
import { isNullOrUndefined } from "../../../functions/util";

import { classes, Div } from "../styles/ctcStyle";

const CtcButtonBase = ({
  type,
  hasMultipleCurrencies,
  clientTotalCost,
  clientTotalCostEur,
  currencyClient,
  currencySupplier,
  open,
  loading,
  onClose,
  showCtc,
  onOpen,
  id,
  name,
  exchangeRateClient,
  exchangeRateSupplier
}) => {
  const {
    fee = 0.0,
    netNetTotal = 0.0,
    surcharge1Amount = 0.0,
    surcharge2Amount = 0.0,
    totalCostClient = 0.0,
    totalCostClientPercentage = 0
  } = clientTotalCost || {};

  const {
    fee: feeEur = 0.0,
    netNetTotal: netNetTotalEur = 0.0,
    surcharge1Amount: surcharge1AmountEur = 0.0,
    surcharge2Amount: surcharge2AmountEur = 0.0,
    totalCostClient: totalCostClientEur = 0.0,
    totalCostClientPercentage: totalCostClientPercentageEur = 0
  } = clientTotalCostEur || {};

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Tooltip
        onClose={onClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <CtcTooltipText
            feeEur={feeEur}
            netEur={netNetTotalEur}
            surcharge1Eur={surcharge1AmountEur}
            surcharge2Eur={surcharge2AmountEur}
            ctcEur={totalCostClientEur}
            ctcPercentageEur={totalCostClientPercentageEur}
            fee={fee}
            net={netNetTotal}
            surcharge1={surcharge1Amount}
            surcharge2={surcharge2Amount}
            ctc={totalCostClient}
            ctcPercentage={totalCostClientPercentage}
            currencyClient={currencyClient}
            currencySupplier={currencySupplier}
            showEurCurrency={!isNullOrUndefined(clientTotalCostEur) && !loading}
            showCurrency={
              (!loading &&
                isNullOrUndefined(clientTotalCostEur) &&
                isNullOrUndefined(clientTotalCost)) ||
              !isNullOrUndefined(clientTotalCost)
            }
            showCtc={showCtc}
            exchangeRateClient={exchangeRateClient}
            exchangeRateSupplier={exchangeRateSupplier}
          />
        }
      >
        <Div>
          {(type === TYPE_BUTTON || hasMultipleCurrencies) && (
            <Div className={classes.wrapper}>
              <ButtonBase
                id={id}
                name={id}
                disableRipple
                onClick={onOpen}
                className={classes.ctcButton}
                disabled={loading}
              >
                {hasMultipleCurrencies ? "Multiple currencies" : "Show CTC"}
              </ButtonBase>
              {loading && (
                <CircularProgress
                  size={14}
                  className={classes.buttonProgress}
                />
              )}
            </Div>
          )}

          {type === TYPE_ICON && !hasMultipleCurrencies && (
            <IconButton
              className={classes.ctcIconButton}
              onClick={onOpen}
              id={id}
              name={name}
            >
              <Info />
            </IconButton>
          )}
        </Div>
      </Tooltip>
    </ClickAwayListener>
  );
};

CtcButtonBase.propTypes = {
  type: PropTypes.oneOf([TYPE_BUTTON, TYPE_ICON]),
  currencySupplier: PropTypes.oneOf(...[Object.keys(currencies)]),
  clientTotalCost: PropTypes.object,
  clientTotalCostEur: PropTypes.object,
  hasMultipleCurrencies: PropTypes.bool,
  open: PropTypes.bool,
  showCtc: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string
};

CtcButtonBase.defaultProps = {
  type: TYPE_BUTTON,
  hasMultipleCurrencies: false,
  showCtc: true,
  currencySupplier: currencies.EUR
};

export default CtcButtonBase;
