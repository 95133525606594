import React from "react";

import { Button, DialogContent, DialogActions } from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import TrafficPlanField from "../../../form/TrafficPlanField";
import { classes, Dialog } from "../styles/dialog";

const RemarkDialog = ({ handleClose, open, handleSave, disableActions }) => {
  return (
    <Dialog
      aria-labelledby="remark-dialog-title"
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="remark-dialog-title">Remark</DialogTitle>
      <DialogContent>
        <TrafficPlanField
          multiline
          rowsMax="10"
          margin="normal"
          name="tl_remarks"
          id="tl_remarks"
          component="text"
          disabled={disableActions}
        />
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          onClick={handleClose}
          className={classes.closeButton}
          name="tl_cancel_remarks"
          id="tl_cancel_remarks"
        >
          Cancel
        </Button>
        {!disableActions && (
          <Button
            onClick={handleSave}
            color="primary"
            name="tl_save_remarks"
            id="tl_save_remarks"
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RemarkDialog;
