import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import Controls from "./controls/index";
import { setTrafficTemplateValue } from "../../business/models/TrafficTemplate/trafficTemplateActions";
import {
  getTrafficTemplatePropertyValue,
  getTrafficTemplatePreviousValue,
  getTrafficTemplateErrorsByProperty,
  selectTrafficTemplateErrors,
  getTrafficTemplatePropertyOptions
} from "../../business/models/TrafficTemplate/trafficTemplateSelectors";

const TrafficTemplateField = props => {
  const { name, onChange, component, ...componentProps } = props;
  const dispatch = useDispatch();
  const value = useSelector(state =>
    getTrafficTemplatePropertyValue(state, props)
  );
  const prevValue = useSelector(state =>
    getTrafficTemplatePreviousValue(state, props)
  );
  const displayErrors = useSelector(selectTrafficTemplateErrors);
  const errors = useSelector(state =>
    getTrafficTemplateErrorsByProperty(state, props)
  );
  const options = useSelector(state =>
    getTrafficTemplatePropertyOptions(state, props)
  );

  const handleChange = val =>
    dispatch(setTrafficTemplateValue({ [name]: val }));
  const Control = Controls[component];

  return (
    <Control
      component={component}
      {...componentProps}
      onChange={handleChange}
      value={value}
      prevValue={prevValue}
      options={options}
      errors={errors}
      displayErrors={displayErrors}
    />
  );
};

TrafficTemplateField.propTypes = {
  component: PropTypes.oneOf(["text", "autocomplete", "decimal"]),
  onChange: PropTypes.func
};

export default TrafficTemplateField;
