import React from "react";
import PropTypes from "prop-types";

import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Divider
} from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import Field from "../../../form/PlanTargetMetricsKpiField";
import PlanTragetMetricsKpiDialogActions from "./PlanTragetMetricsKpiDialogActions";

import {
  ADD_TEXT,
  EDIT_TEXT
} from "../../../../business/models/PlanTargetMetricsKpi/planTargetMetricsKpiConstants";

const PlanTragetMetricsKpiDialog = ({
  open,
  onAddClick,
  onCancelClick,
  currentItemId,
  isNew
}) => {
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle id="plan-target-dialog-title">
        {isNew ? ADD_TEXT : EDIT_TEXT} Targets - Metrics - KPI's
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Field
              component="text"
              id="channel"
              name="channel"
              label="Channel"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="spotLength"
              name="spotLength"
              label="Spot Length"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="primaryTargetAudience"
              name="primaryTargetAudience"
              label="Primary Target Audience"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="buyingTargetAudience"
              name="buyingTargetAudience"
              label="Buying Target Audience"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="netReach"
              name="netReach"
              label="Net Reach"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="ots"
              name="ots"
              label="OTS"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="effectiveReachContactRange"
              name="effectiveReachContactRange"
              label="Effective Reach / Contact Range"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="grps"
              name="grps"
              label="GRP's / #units"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="weeklyDistribution"
              name="weeklyDistribution"
              label="Weekly Distribution"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="impressions"
              name="impressions"
              label="Impressions"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="positionInBreak"
              name="positionInBreak"
              label="Position In Break"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="views"
              name="views"
              label="Views (Viewable + Indemo)"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="preferredPosition"
              name="preferredPosition"
              label="Preferred Position"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="primetimeShare"
              name="primetimeShare"
              label="Primetime Share"
              entityId={currentItemId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component="text"
              id="remarksRestrictions"
              name="remarksRestrictions"
              label="Remarks / Restrictions"
              entityId={currentItemId}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <PlanTragetMetricsKpiDialogActions
          isNew={isNew}
          onAddClick={onAddClick}
          onCancelClick={onCancelClick}
        />
      </DialogActions>
    </Dialog>
  );
};

PlanTragetMetricsKpiDialog.propTypes = {
  mediaType: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  currentItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isNew: PropTypes.bool.isRequired
};

export default PlanTragetMetricsKpiDialog;
