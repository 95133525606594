import { styled, Grid as MuiGrid } from "@mui/material";
import { matisse } from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "TrafficPlanUploadStyles";
export const classes = {
  typographyFileName: `${PREFIX}-typographyFileName`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.typographyFileName}`]: {
    color: matisse,
    width: theme.typography.pxToRem(195),
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));
