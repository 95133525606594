import React, { Component } from "react";
import PropTypes from "prop-types";
import { MenuItem, OutlinedInput } from "@mui/material";

import { connect } from "react-redux";
import { classes, Select } from "./styles/TrafficStatusSelect";

import {
  getTrafficItemPropertyOptions,
  getTrafficItemPropertySelectValue,
  getTrafficItemStatusText,
  isTrafficItemOrderApproved
} from "../../business/models/TrafficItem/trafficItemSelectors";

import { selectUserIsAdmin } from "../../business/selectors/userProfileSelectors";
import { isNullOrUndefined } from "../../functions/util";

class TrafficStatusSelect extends Component {
  handleChange = event => {
    event.stopPropagation();
    const { onChange } = this.props;
    onChange(event.target.value);
  };

  handleClick = event => {
    event.stopPropagation();
  };

  render() {
    const {
      options,
      value,
      id,
      statusText,
      isOrderApproved,
      isAdmin,
      selectStatusDisabled,
      isDebtorActive
    } = this.props;
    const valueOption = options.find(option => option.key === value);
    const isValueInOption = !isNullOrUndefined(valueOption);

    const disaplayOptions = [];
    options.forEach(option => {
      const disabled =
        !isAdmin && !isOrderApproved && option.text === "Ready for Traffic";

      disaplayOptions.push({ key: option.key, text: option.text, disabled });
    });

    return (
      <Select
        value={value}
        classes={{
          icon: classes.arrowDropDownIcon,
          select: classes.outlinedInput
        }}
        className={classes.select}
        disabled={selectStatusDisabled || !isDebtorActive}
        onChange={this.handleChange}
        onClick={this.handleClick}
        input={
          <OutlinedInput
            name={`select_traffic_status_${id}`}
            id={`select_traffic_status_${id}`}
          />
        }
      >
        {!isValueInOption && (
          <MenuItem
            value={value}
            key={`ti-status-${value}`}
            name={`ti-status-${value}`}
            disabled
          >
            {statusText}
          </MenuItem>
        )}
        {disaplayOptions.map(({ key, text, disabled }) => (
          <MenuItem
            key={key}
            id={`ti-status-${key}`}
            name={`ti-status-${key}`}
            value={key}
            disabled={disabled}
          >
            {text}
          </MenuItem>
        ))}
      </Select>
    );
  }
}

TrafficStatusSelect.propTypes = {
  id: PropTypes.number.isRequired,
  dropDownDisabled: PropTypes.bool.isRequired
};

TrafficStatusSelect.defaultProps = {
  options: [],
  dropDownDisabled: false
};

const mapStateToProps = (state, ownProps) => ({
  value: getTrafficItemPropertySelectValue(state, ownProps),
  statusText: getTrafficItemStatusText(state, ownProps),
  isOrderApproved: isTrafficItemOrderApproved(state, ownProps),
  isAdmin: selectUserIsAdmin(state),
  options: getTrafficItemPropertyOptions(state, ownProps)
});

export default connect(mapStateToProps, null)(TrafficStatusSelect);
