import React, { memo } from "react";
import clsx from "clsx";
import Field from "../../../../../form/TrafficItemField";
import { classes, Grid } from "../../../../styles/fold";

const FoldDigital = ({
  editMode,
  areTrafficAgentFieldsDisabled,
  displayErrors,
  isDebtorActive
}) => {
  return (
    <Grid container justifyContent="flex-start">
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="trafficItemId"
          name="trafficItemId"
          helperText="Traffic Item ID"
          label="Traffic Item ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="marathonOrderId"
          name="marathonOrderId"
          helperText="Order ID"
          label="Order ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="marathonPlanId"
          name="marathonPlanId"
          helperText="Plan ID"
          label="Plan ID"
          variant="standard"
          disabled
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="trafficItemStartDate"
          name="trafficItemStartDate"
          helperText="Start Date"
          label="Start Date"
          hideIcon={!editMode}
          variant="standard"
          disabled={!editMode || !isDebtorActive}
          isLabelHighlighted
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="trafficItemEndDate"
          name="trafficItemEndDate"
          helperText="End Date"
          label="End Date"
          hideIcon={!editMode}
          variant="standard"
          disabled={!editMode || !isDebtorActive}
          isLabelHighlighted
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="trafficItemDescription"
          name="trafficItemDescription"
          helperText="Description"
          label="Description"
          variant="standard"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="date"
          id="deliveryDate"
          name="deliveryDate"
          helperText="Delivery Date"
          label="Delivery Date"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          hideIcon={!editMode}
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="maximumSeconds"
          name="maximumSeconds"
          helperText="Maximum Seconds"
          label="Maximum Seconds"
          disabled={!editMode || !isDebtorActive}
          variant="standard"
          isLabelHighlighted
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="autocomplete"
          id="dimensionsId"
          name="dimensionsId"
          helperText="Dimensions"
          label="Dimensions"
          disabled={!editMode || !isDebtorActive}
          variant="standard"
          textFieldClassName={classes.field}
          isLabelHighlighted
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="weight"
          name="weight"
          helperText="Weight"
          label="Weight"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="creativeType"
          name="creativeType"
          helperText="Creative Type"
          label="Creative Type"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="animationLooping"
          name="animationLooping"
          helperText="Animation/Looping"
          label="Animation/Looping"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="framerate"
          name="framerate"
          helperText="Framerate"
          label="Framerate"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="autocomplete"
          id="adServerId"
          name="adServerId"
          helperText="AdServer"
          label="AdServer *"
          disabled={!editMode || !isDebtorActive}
          variant="standard"
          textFieldClassName={classes.field}
          isLabelHighlighted
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="autocomplete"
          id="materialHostedById"
          name="materialHostedById"
          helperText="Material Hosted By"
          label="Material Hosted By *"
          disabled={!editMode || !isDebtorActive}
          variant="standard"
          textFieldClassName={classes.field}
          isLabelHighlighted
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
      >
        <Field
          component="text"
          id="trafficItemContact"
          name="trafficItemContact"
          helperText="Contact"
          label="Contact"
          disabled={
            !editMode || areTrafficAgentFieldsDisabled || !isDebtorActive
          }
          variant="standard"
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, {
          [classes.gridItemDisplayErrors]: displayErrors
        })}
        xs={12}
      >
        <Field
          component="text"
          id="trafficItemRemarks"
          name="trafficItemRemarks"
          helperText="Remarks"
          label="Remarks"
          variant="standard"
          disabled={!editMode || !isDebtorActive}
          rowsMax="6"
          multiline
          isLabelHighlighted
        />
      </Grid>
    </Grid>
  );
};

export default memo(FoldDigital);
