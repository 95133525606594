import isEqual from "lodash/isEqual";
import transform from "lodash/transform";
import isObject from "lodash/isObject";
import { EMPTY_STRING } from "../configurations/appConstants";

// eslint-disable-next-line import/prefer-default-export
export const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const hasValue = s => s && !!/\S/.test(s);

export const formatValue = data => {
  const retVal =
    typeof data === "string" || data instanceof String
      ? data.trimStart()
      : data;
  return retVal === EMPTY_STRING ? null : retVal;
};

export function difference(object, base) {
  function changes(obj, thisBase) {
    // eslint-disable-next-line func-names
    return transform(obj, function(result, value, key) {
      if (!isEqual(value, thisBase[key])) {
        result[key] =
          isObject(value) && isObject(thisBase[key])
            ? changes(value, thisBase[key])
            : value;
      }
    });
  }

  return changes(object, base);
}

export const isNullOrUndefined = value => value === null || value === undefined;
export const isNullOrUndefinedOrEmpty = value =>
  value === null || value === undefined || value === "";
export const isNull = value => value === null;
export const isNumber = value => typeof value === "number";
export const coalesce = value => (isNullOrUndefined(value) ? "" : value);

export const getThemeSpacing = (theme, value) =>
  Number(theme.spacing(value).replace("px", EMPTY_STRING));

export const formatCurrency = number => {
  if (isNullOrUndefinedOrEmpty(number)) {
    return "";
  }

  const formated = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2
  }).format(number);

  // Remove € sign from the string
  return formated.substring(0, formated.length - 1);
};
