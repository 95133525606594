/* eslint-disable import/no-cycle */
import cuid from "cuid";
import get from "lodash/get";
import set from "lodash/set";
import omit from "lodash/omit";
import forEach from "lodash/forEach";
import assign from "lodash/assign";
import keys from "lodash/keys";
import size from "lodash/size";

import OrderModel from "./Order";
import {
  ORDER_SELECT,
  ORDER_EDIT_START,
  ORDER_EDIT_STOP,
  ORDER_CLEAR_CURRENT,
  ORDER_RESET_CURRENT,
  ORDER_DISPLAY_ERRORS,
  ORDER_HIDE_ERRORS,
  ORDER_SUCCESS_MESSAGE,
  ORDER_SKIP_SUCCESS_MESSAGE,
  ORDER_MODEL_ID,
  ORDER_MODEL_NAME,
  ORDER_SET_IS_NEW,
  ORDER_DELETE_SUCCESS_MESSAGE,
  ORDER_DESCRIPTION_ERROR_MESSAGE,
  ORDER_DIGITAL_CHANNEL_ERROR_MESSAGE,
  ORDER_SET_ORDER_TO_DELETE,
  ORDER_CLEAR_OREDER_TO_DELETE
} from "./orderConstants";
import {
  selectOrderIsNew,
  selectCurrentOrder,
  selectIsEditingOrder,
  getEditingOrder,
  getOrderForSave,
  doesOrderHaveErrors,
  getOrderById,
  doesOrderHaveDescriptionError,
  selectOrderToDelete
} from "./orderSelectors";

import {
  selectInsertions,
  stopEditingInsertions,
  startEditingInsertions,
  displayInsertionErrors,
  copyInsertions,
  deleteInsertions,
  resetInsertions,
  clearCurrentInsertions
} from "../Insertion/insertionActions";
import {
  getInsertionIDsByOrderId,
  getInsertionsForSave,
  doInsertionsHaveErrors
} from "../Insertion/insertionSelectors";
import { INSERTION_CLEAR_CURRENT } from "../Insertion/insertionConstants";

import { PLAN_MODEL_ID } from "../Plan/planConstants";
import {
  startEditingPlan,
  stopEditingPlan,
  resetCurrentPlan,
  diselectOrderIfApproved
} from "../Plan/planActions";
import { getPlan, selectCurrentPlan } from "../Plan/planSelectors";

import {
  startEditingCampaign,
  stopEditingCampaign
} from "../Campaign/campaignActions";

import {
  getQueryObj,
  generateParamObj,
  getMappedObject,
  getMatchingProperties,
  getPathValue
} from "../common/utils/modelUtils";
import generateUrl from "../common/utils/urlUtils";
import {
  editItemAttributes,
  editExistingItem,
  editNewItem,
  stopEditingItem,
  commitData
} from "../common/editing/editingActions";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import { deleteEntity } from "../common/entities/entityActions";

import {
  enqueueApiErrorMessage,
  enqueueNotificationMessage,
  enqueueApplicationErrorMessage,
  clearAction,
  apiCall
} from "../../actions/applicationActions";
import fetchApiDataIfNeeded from "../../actions/apiDataActions";

import {
  selectAction,
  selectAgency
} from "../../selectors/applicationSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";

import {
  ORDER_FORM_UPDATE,
  POST,
  PUT,
  DELETE,
  GET
} from "../../constants/applicationConstants";

import {
  CREATE_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
  GET_ORDER_CLIENT_STATUS_LOG,
  CREATE_ORDER_COPY_PLAN,
  SKIP_ORDER_COPY_PLAN
} from "../../../configurations/apiUrls";

import { isNullOrUndefined } from "../../../functions/util";
import { selectProcess } from "../../selectors/copyPlanSelectors";
import { CAMPAIGN_MODEL_ID } from "../Campaign/campaignConstants";
import { selectCurrentCampaign } from "../Campaign/campaignSelectors";
import {
  setActiveStep,
  setOrderSteps,
  setCurrentOrderStep
} from "../../actions/copyPlanActions";
import { SKIP_ORDER_STEP_FAILED } from "../../constants/copyPlanConstants";
import {
  UPDATE_INSERTIONS_WITH_CHANGE,
  UPDATE_ORDERS_WITH_INSERTION_CHANGE
} from "../../constants/campaignDashboardConstants";

function setOrderMediaData() {
  return (dispatch, getState) => {
    const state = getState();
    const configuration = OrderModel.apiConfiguration;
    const apiData = getApiData(state);
    const editingData = getEditingOrder(state);
    const currentOrder = selectCurrentOrder(state);
    const agencyID = selectAgency(state);
    forEach(configuration, (prop, key) => {
      if (prop.path) {
        const prevValue = get(editingData, key);
        const value = getPathValue(prop, editingData, apiData, agencyID);
        if (!isNullOrUndefined(value) && value !== "" && prevValue !== value) {
          dispatch(
            editItemAttributes(ORDER_MODEL_NAME, currentOrder, { [key]: value })
          );
        }
      }
    });
  };
}

export function loadOrderMediaData(data) {
  return (dispatch, getState) => {
    const configuration = OrderModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url));
      }
    });
  };
}

export function loadEditingOrderMediaData(data) {
  return (dispatch, getState) => {
    const configuration = OrderModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url, setOrderMediaData));
      }
    });
  };
}

export const clearCurrentOrder = () => dispatch =>
  dispatch({ type: ORDER_CLEAR_CURRENT });

export function clearOrder() {
  return dispatch => {
    dispatch(clearCurrentOrder());
    dispatch(clearCurrentInsertions());
  };
}

export function startEditingOrder() {
  return (dispatch, getState) => {
    const currentOrder = selectCurrentOrder(getState());

    dispatch(editExistingItem(ORDER_MODEL_NAME, currentOrder));
    dispatch({ type: ORDER_EDIT_START });

    // Load media - improve
    const editingData = getEditingOrder(getState());
    dispatch(loadEditingOrderMediaData(editingData));
  };
}

export function stopEditingOrder() {
  return (dispatch, getState) => {
    const currentOrder = selectCurrentOrder(getState());

    dispatch(stopEditingItem(ORDER_MODEL_NAME, currentOrder));
    dispatch({ type: ORDER_EDIT_STOP });
    dispatch({ type: ORDER_HIDE_ERRORS });
  };
}

export function cancelOrder() {
  return (dispatch, getState) => {
    dispatch(stopEditingInsertions());
    dispatch(stopEditingOrder());
    dispatch(stopEditingPlan());
    dispatch(stopEditingCampaign());
    const isNew = selectOrderIsNew(getState());
    if (isNew) {
      dispatch({ type: INSERTION_CLEAR_CURRENT });
      dispatch({ type: ORDER_CLEAR_CURRENT });
    } else {
      dispatch(resetInsertions(false));
      dispatch({ type: ORDER_RESET_CURRENT });
    }
  };
}

export function editOrder() {
  return dispatch => {
    dispatch(startEditingCampaign());
    dispatch(startEditingPlan());
    dispatch(startEditingOrder());
    dispatch(startEditingInsertions());
  };
}

export function selectOrder(orderID) {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectIsEditingOrder(state);

    if (isEditing) {
      dispatch(stopEditingInsertions());
      dispatch(stopEditingOrder());
      dispatch(stopEditingPlan());
      dispatch(stopEditingCampaign());
    }

    dispatch(clearOrder());
    dispatch({
      type: ORDER_SELECT,
      payload: {
        currentOrder: orderID
      }
    });

    dispatch(selectInsertions());
  };
}

export function addNewOrder() {
  return (dispatch, getState) => {
    const state = getState();
    const session = getUnsharedEntitiesSession(state);
    const planId = selectCurrentPlan(state);
    const plan = getPlan(state);
    const inheritObject = getMappedObject(
      OrderModel.inheritConfiguration,
      plan
    );

    const { Order } = session;

    const id = cuid();

    const newOrder = Order.generate({
      id,
      ...inheritObject,
      plan: planId
    });
    const orderContents = newOrder.toJSON();
    // TODO: Current workaround for not reseting fields wjen save and add order is clicked
    orderContents.discountAmount = "";
    orderContents.netTotal = "";
    orderContents.numOfUnits = "";

    dispatch(startEditingCampaign());
    dispatch(startEditingPlan());
    dispatch(editNewItem(ORDER_MODEL_NAME, id, orderContents));
    dispatch({ type: ORDER_SET_IS_NEW });
    dispatch({ type: INSERTION_CLEAR_CURRENT });
    dispatch({
      type: ORDER_SELECT,
      payload: {
        currentOrder: id
      }
    });
    dispatch({ type: ORDER_EDIT_START });

    dispatch(loadEditingOrderMediaData({ ...plan, ...orderContents }));

    return id;
  };
}

export function displayOrderErrors() {
  return { type: ORDER_DISPLAY_ERRORS };
}

export function createOrder() {
  return (dispatch, getState) => {
    const state = getState();

    const hasErrors = doesOrderHaveErrors(state);
    if (hasErrors) {
      dispatch(displayOrderErrors());
      dispatch(enqueueApplicationErrorMessage("Order data is not valid"));
      return Promise.reject();
    }

    const hasErrorsInsertions = doInsertionsHaveErrors(state);
    const insertions = getInsertionsForSave(state);

    const insertionsData =
      (size(insertions) === 1 && !hasErrorsInsertions) || size(insertions) > 1
        ? { insertions }
        : {};

    const action = selectAction(state);

    if (hasErrorsInsertions) {
      if (action === ORDER_FORM_UPDATE) {
        if (size(insertions) > 1) {
          dispatch(displayInsertionErrors());

          dispatch(
            enqueueNotificationMessage(
              "You need to edit Insertions before saving Order"
            )
          );

          return Promise.reject();
        }
      } else {
        dispatch(displayInsertionErrors());

        dispatch(
          enqueueNotificationMessage(
            "You need to edit Insertions before saving Order"
          )
        );

        return Promise.reject();
      }
    }

    const agencyID = selectAgency(state);
    const currentPlan = selectCurrentPlan(state);
    const orderData = getOrderForSave(state);
    const url = generateUrl(CREATE_ORDER, {
      agency: agencyID,
      [PLAN_MODEL_ID]: currentPlan
    });
    const body = { ...orderData, ...insertionsData };

    return dispatch(apiCall(POST, url, body)).then(
      response => {
        const id = get(response, ORDER_MODEL_ID);
        const responseData = set(response, "id", id);
        dispatch(deleteInsertions());
        dispatch(commitData(ORDER_MODEL_NAME, id, responseData));
        dispatch(stopEditingInsertions());
        dispatch(stopEditingOrder());
        dispatch(stopEditingPlan());
        dispatch(stopEditingCampaign());
        dispatch({ type: INSERTION_CLEAR_CURRENT });
        dispatch(selectOrder(id));
        dispatch(enqueueNotificationMessage(ORDER_SUCCESS_MESSAGE));
        dispatch(clearAction());
        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function updateOrder() {
  return (dispatch, getState) => {
    const state = getState();

    const hasErrors = doesOrderHaveErrors(state);
    if (hasErrors) {
      dispatch(displayOrderErrors());
      dispatch(enqueueApplicationErrorMessage("Order data is not valid"));
      return Promise.reject();
    }

    const hasErrorsInsertions = doInsertionsHaveErrors(state);
    const insertions = getInsertionsForSave(state);

    const insertionsData = { insertions };

    const action = selectAction(state);

    if (hasErrorsInsertions && size(insertions) > 1) {
      if (action === ORDER_FORM_UPDATE) {
        dispatch(
          enqueueApplicationErrorMessage(
            "You need to edit Insertions before saving Order"
          )
        );
      }
      dispatch(displayInsertionErrors());
      return Promise.reject();
    }

    const agencyID = selectAgency(state);
    const currentPlan = selectCurrentPlan(state);
    const currentOrder = selectCurrentOrder(state);
    const orderData = getOrderForSave(state);
    const url = generateUrl(UPDATE_ORDER, {
      agency: agencyID,
      [PLAN_MODEL_ID]: currentPlan,
      [ORDER_MODEL_ID]: currentOrder
    });

    const body = { ...orderData, ...insertionsData };

    return dispatch(apiCall(PUT, url, body)).then(
      response => {
        const id = get(response, ORDER_MODEL_ID);
        const responseData = set(response, "id", id);

        dispatch(deleteInsertions());
        dispatch(commitData(ORDER_MODEL_NAME, id, responseData));
        dispatch(stopEditingInsertions());
        dispatch(stopEditingOrder());
        dispatch(stopEditingPlan());
        dispatch(stopEditingCampaign());
        dispatch({ type: INSERTION_CLEAR_CURRENT });
        dispatch(selectOrder(id));
        dispatch(enqueueNotificationMessage(ORDER_SUCCESS_MESSAGE));
        dispatch(clearAction());
        dispatch(diselectOrderIfApproved(orderData));

        dispatch({
          type: UPDATE_ORDERS_WITH_INSERTION_CHANGE,
          payload: { orderId: id }
        });
        dispatch({
          type: UPDATE_INSERTIONS_WITH_CHANGE,
          payload: { insertionId: response.insertions.map(i => i.insertionId) }
        });

        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function checkIsOrderValidAndShowError(id) {
  return (dispatch, getState) => {
    const state = getState();
    const hasDescriptionError = doesOrderHaveDescriptionError(state, id);
    if (hasDescriptionError) {
      dispatch(enqueueApplicationErrorMessage(ORDER_DESCRIPTION_ERROR_MESSAGE));
    }
    return !hasDescriptionError;
  };
}

export function checkIsOrderValidAndShowErrors() {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesOrderHaveErrors(state);
    if (hasErrors) {
      dispatch(displayOrderErrors());
      dispatch(enqueueApplicationErrorMessage("Order data is not valid"));
    }
    return !hasErrors;
  };
}

export function resetOrderData(currentOrder, data) {
  return dispatch => {
    const configuration = OrderModel.apiConfiguration;
    const defaults = OrderModel.defaultAttributes;
    const name = keys(data)[0];
    const properties = getMatchingProperties(configuration, name);
    forEach(properties, propertyName => {
      dispatch(
        editItemAttributes(ORDER_MODEL_NAME, currentOrder, {
          [propertyName]: defaults[propertyName]
        })
      );
    });
  };
}

export function setOrderValue(data) {
  return (dispatch, getState) => {
    const currentOrder = selectCurrentOrder(getState());
    const editingData = getEditingOrder(getState());
    const orderData = assign({}, editingData, data);

    dispatch(editItemAttributes(ORDER_MODEL_NAME, currentOrder, data));
    dispatch(resetOrderData(currentOrder, data));
    dispatch(loadEditingOrderMediaData(orderData));
    dispatch(setOrderMediaData());
  };
}

export function copyOrder(orderId) {
  return (dispatch, getState) => {
    const state = getState();
    const session = getUnsharedEntitiesSession(state);
    const orderData = getOrderById(state, orderId);
    const plan = getPlan(state);

    const order = omit(orderData, ["id", ORDER_MODEL_ID, "flightId"]);
    set(order, "statusId", 1);
    const { Order } = session;

    const id = cuid();

    const newOrder = Order.generate({ id, ...order });
    const orderContents = newOrder.toJSON();
    dispatch(startEditingCampaign());
    dispatch(startEditingPlan());
    dispatch(editNewItem(ORDER_MODEL_NAME, id, orderContents));
    dispatch({
      type: ORDER_SELECT,
      payload: {
        currentOrder: id
      }
    });
    dispatch(selectInsertions());
    dispatch({ type: ORDER_SET_IS_NEW });
    dispatch({ type: ORDER_EDIT_START });
    dispatch(loadEditingOrderMediaData({ ...plan, ...orderContents }));

    dispatch(copyInsertions(orderId));

    return Promise.resolve(id);
  };
}

export const deleteOrder = () => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const planID = selectCurrentPlan(state);
  const id = selectOrderToDelete(state);
  const selectedOrderId = selectCurrentOrder(state);
  const url = generateUrl(DELETE_ORDER, {
    agency: agencyID,
    [PLAN_MODEL_ID]: planID,
    [ORDER_MODEL_ID]: id
  });

  return dispatch(apiCall(DELETE, url)).then(
    () => {
      const insertionsIdsToDelete = getInsertionIDsByOrderId(state, id);

      dispatch(enqueueNotificationMessage(ORDER_DELETE_SUCCESS_MESSAGE));

      dispatch(deleteInsertions(insertionsIdsToDelete));
      dispatch(deleteEntity(ORDER_MODEL_NAME, id));
      if (id === selectedOrderId) {
        dispatch({ type: INSERTION_CLEAR_CURRENT });
      }
      dispatch(resetCurrentPlan());
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const getClientStatusLogs = () => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const id = selectCurrentOrder(state);
  const url = generateUrl(GET_ORDER_CLIENT_STATUS_LOG, {
    agency: agencyID,
    [ORDER_MODEL_ID]: id
  });

  return dispatch(apiCall(GET, url)).then(
    response => {
      return response;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const setOrderToDelete = payload => ({
  type: ORDER_SET_ORDER_TO_DELETE,
  payload
});

export const clearOrderToDelete = () => ({
  type: ORDER_CLEAR_OREDER_TO_DELETE
});

/* eslint-disable no-extra-boolean-cast */
export const createOrderCopyPlan = () => (dispatch, getState) => {
  const state = getState();
  const hasErrors = doesOrderHaveErrors(state);
  if (hasErrors) {
    dispatch(displayOrderErrors());
    return Promise.reject();
  }

  const agency = selectAgency(state);
  const processId = selectProcess(state);
  const currentCampaign = selectCurrentCampaign(state);
  const currentPlan = selectCurrentPlan(state);

  const data = getOrderForSave(state);
  const url = generateUrl(CREATE_ORDER_COPY_PLAN, {
    agency,
    processId,
    [CAMPAIGN_MODEL_ID]: currentCampaign,
    [PLAN_MODEL_ID]: currentPlan
  });

  const body = { ...data };
  return dispatch(apiCall(POST, url, body)).then(
    response => {
      const {
        isSuccessful,
        newOrder,
        order,
        step,
        orderStepsCount,
        currentOrderStep
      } = response || {};
      if (isSuccessful) {
        if (!!newOrder) {
          const newOrderId = get(newOrder, ORDER_MODEL_ID);
          const newOrderData = set(newOrder, "id", newOrderId);
          dispatch(commitData(ORDER_MODEL_NAME, newOrderId, newOrderData));
        }

        if (!!order) {
          const orderId = get(order, ORDER_MODEL_ID);
          const orderData = set(order, "id", orderId);
          set(orderData, "isCopyTemp", true);
          dispatch(commitData(ORDER_MODEL_NAME, orderId, orderData));
          dispatch(selectOrder(orderId));
        }

        dispatch(stopEditingOrder());
        dispatch(stopEditingPlan());
        dispatch(stopEditingCampaign());
        dispatch(setOrderSteps(orderStepsCount));
        dispatch(setCurrentOrderStep(currentOrderStep));
        dispatch(setActiveStep(step));

        dispatch(enqueueNotificationMessage(ORDER_SUCCESS_MESSAGE));
      } else {
        dispatch(enqueueApiErrorMessage(`TODO: NEW MESSAGE`));
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const skipOrderCopyPlan = () => (dispatch, getState) => {
  const state = getState();
  const agency = selectAgency(state);
  const processId = selectProcess(state);

  const url = generateUrl(SKIP_ORDER_COPY_PLAN, {
    agency,
    processId
  });

  const body = {};

  return dispatch(apiCall(DELETE, url, body)).then(
    response => {
      const { isSuccessful, order, step, orderStepsCount, currentOrderStep } =
        response || {};
      if (isSuccessful) {
        if (!!order) {
          const orderId = get(order, ORDER_MODEL_ID);
          const orderData = set(order, "id", orderId);
          set(orderData, "isCopyTemp", true);
          dispatch(commitData(ORDER_MODEL_NAME, orderId, orderData));
          dispatch(selectOrder(orderId));
        }

        dispatch(stopEditingOrder());
        dispatch(stopEditingPlan());
        dispatch(stopEditingCampaign());
        dispatch(setOrderSteps(orderStepsCount));
        dispatch(setCurrentOrderStep(currentOrderStep));
        dispatch(setActiveStep(step));

        dispatch(enqueueNotificationMessage(ORDER_SKIP_SUCCESS_MESSAGE));
      } else {
        dispatch(enqueueApiErrorMessage(SKIP_ORDER_STEP_FAILED));
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export function copyOrderPlanCopy() {
  return (dispatch, getState) => {
    const state = getState();
    const session = getUnsharedEntitiesSession(state);
    const orderId = selectCurrentOrder(state);
    const orderData = getOrderById(state, orderId);
    const planId = selectCurrentPlan(state);
    const plan = getPlan(state);

    const inheritObject = getMappedObject(
      OrderModel.inheritConfiguration,
      plan
    );

    const order = omit(orderData, ["id", ORDER_MODEL_ID, "flightId"]);
    set(order, "statusId", 1);
    set(order, "orderStartDate", null);
    set(order, "orderEndDate", null);
    set(order, "orderAgreementId", inheritObject.orderAgreementId);
    set(order, "orderPoNumber", inheritObject.orderPoNumber);

    const { Order } = session;
    const id = cuid();
    const newOrder = Order.generate({ id, ...order, planId, plan: planId });
    const orderContents = newOrder.toJSON();

    dispatch(startEditingCampaign());
    dispatch(startEditingPlan());
    dispatch(editNewItem(ORDER_MODEL_NAME, id, orderContents));
    dispatch({
      type: ORDER_SELECT,
      payload: {
        currentOrder: id
      }
    });
    dispatch(selectInsertions());
    dispatch({ type: ORDER_SET_IS_NEW });
    dispatch({ type: ORDER_EDIT_START });
    dispatch(loadEditingOrderMediaData({ ...plan, ...orderContents }));

    return Promise.resolve();
  };
}
