export const SET_CURRENT_TRAFFIC_PLAN_ID = "SET_CURRENT_TRAFFIC_PLAN_ID";
export const START_EDITING_TRAFFIC_PLAN = "START_EDITING_TRAFFIC_PLAN";
export const STOP_EDITING_TRAFFIC_PLAN = "STOP_EDITING_TRAFFIC_PLAN";
export const DISPLAY_TRAFFIC_PLAN_ERRORS = "DISPLAY_TRAFFIC_PLAN_ERRORS";
export const HIDE_TRAFFIC_PLAN_ERRORS = "HIDE_TRAFFIC_PLAN_ERRORS";
export const CLEAR_CURRENT_TRAFFIC_PLAN = "CLEAR_CURRENT_TRAFFIC_PLAN";

export const TRAFFIC_PLAN_SUCCESS_MESSAGE =
  "Traffic Plan is saved successfully";
export const TRAFFIC_PLAN_REMARKS_SUCCESS_MESSAGE =
  "Remarks is saved successfully";
export const TRAFFIC_PLAN_TRAFFICKER_SUCCESS_MESSAGE =
  "Trafficker is saved successfully";
export const TRAFFIC_PLAN_DOWNLOAD_SUCCESS_MESSAGE =
  "Traffic file has been downloaded";
export const TRAFFIC_PLAN_DOWNLOAD_ERROR_MESSAGE =
  "Traffic file could not be downloaded, please try again!";
export const TRAFFIC_PLAN_EMAIL_SUCCESS_MESSAGE =
  "Traffic file has been successfully sent by email";
export const TRAFFIC_PLAN_EMAIL_ERROR_MESSAGE =
  "Traffic file could not be sent by email, please try again!";
export const TRAFFIC_PLAN_ATTACHMENT_UPLOAD_SUCCESS_MESSAGE =
  "Attachment for selected Traffic Plan has been successfully uploaded";
export const TRAFFIC_PLAN_ATTACHMENT_UPLOAD_ERROR_MESSAGE =
  "Attachment for selected Traffic Plan could not be uploaded, please try again!";
export const TRAFFIC_PLAN_ATTACHMENT_FILE_SIZE_VALIDATION_ERROR_MESSAGE =
  "Uploaded file should be less than or equal to 10 megabytes, please try again!";
export const TRAFFIC_PLAN_ATTACHMENT_FILE_TYPE_VALIDATION_ERROR_MESSAGE =
  "Uploaded file should be an image, please try again!";
export const TRAFFIC_PLAN_ASSIGN_ERROR_MESSAGE =
  "Traffic plan is not saved and can not be assign.";
export const TRAFFIC_PLAN_ASSIGN_SUCCESS_MESSAGE =
  "Traffic plan is successfully assigned";
export const TRAFFIC_PLAN_UNASSIGN_SUCCESS_MESSAGE =
  "Traffic plan is successfully unassigned";
export const START_EDITING_TRAFFIC_PLAN_REMARK =
  "START_EDITING_TRAFFIC_PLAN_REMARK";
export const STOP_EDITING_TRAFFIC_PLAN_REMARK =
  "STOP_EDITING_TRAFFIC_PLAN_REMARK";

export const TRAFFIC_PLAN_MODEL_NAME = "TrafficPlan";
export const TRAFFIC_PLAN_MODEL_ID = "trafficPlanId";
export const TRAFFIC_PLAN_MEDIA_TYPE_ID = "mediaTypeId";
export const TRAFFIC_PLAN_MODEL_CONTACT_GROUPM = "contactGroupM";
export const SET_ORDER_LIST = "SET_ORDER_LIST";
export const RESET_ORDER_LIST = "RESET_ORDER_LIST";
export const SET_SELECTED_ORDER_TRAFFIC = "SET_SELECTED_ORDER_TRAFFIC";
export const RESET_SELECTED_ORDER_TRAFFIC = "RESET_SELECTED_ORDER_TRAFFIC";
export const APPROVED_ORDERS_LIST_EMPTY_ERROR =
  "Traffic lines can not be added because there are no orders in required statuses";
export const TRAFFIC_PLAN_INSERT_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT =
  "TRAFFIC_PLAN_INSERT_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT";
export const TRAFFIC_PLAN_REMOVE_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT =
  "TRAFFIC_PLAN_REMOVE_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT";
export const TRAFFIC_PLAN_CLEAR_TRAFFIC_ITEMS_FOR_MULTIPLE_EDIT =
  "TRAFFIC_PLAN_CLEAR_TRAFFIC_ITEMS_FOR_MULTIPLE_EDIT";

export const TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_INSERT_SELECTED =
  "TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_INSERT_SELECTED";
export const TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_REMOVE_SELECTED =
  "TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_REMOVE_SELECTED";
export const TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_CLEAR_SELECTED =
  "TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_CLEAR_SELECTED";

export const TRAFFIC_PLAN_REMARK_DIALOG_OPEN =
  "TRAFFIC_PLAN_REMARK_DIALOG_OPEN";
export const TRAFFIC_PLAN_REMARK_DIALOG_CLOSE =
  "TRAFFIC_PLAN_REMARK_DIALOG_CLOSE";
export const TRAFFIC_PLAN_PREVIEW_OPEN = "TRAFFIC_PLAN_PREVIEW_OPEN";
export const TRAFFIC_PLAN_PREVIEW_CLOSE = "TRAFFIC_PLAN_PREVIEW_CLOSE";
export const TRAFFIC_PLAN_EMAIL_OPEN = "TRAFFIC_PLAN_EMAIL_OPEN";
export const TRAFFIC_PLAN_EMAIL_CLOSE = "TRAFFIC_PLAN_EMAIL_CLOSE";
export const TRAFFIC_PLAN_SELECTED_USERS_SET =
  "TRAFFIC_PLAN_SELECTED_USERS_SET";
export const TRAFFIC_PLAN_SELECTED_USERS_CLEAR =
  "TRAFFIC_PLAN_SELECTED_USERS_CLEAR";
export const TRAFFIC_PLAN_MEDIA_TYPE_ID_SET = "TRAFFIC_PLAN_MEDIA_TYPE_ID_SET";
export const TRAFFIC_PLAN_MEDIA_TYPE_ID_CLEAR =
  "TRAFFIC_PLAN_MEDIA_TYPE_ID_CLEAR";
export const TRAFFIC_PLAN_PREVIEW_DATA_SET = "TRAFFIC_PLAN_PREVIEW_SET";
export const TRAFFIC_PLAN_PREVIEW_DATA_CLEAR = "TRAFFIC_PLAN_PREVIEW_CLEAR";
export const SET_TRAFFIC_EXPORT_VERSIONS = "SET_TRAFFIC_EXPORT_VERSIONS";
export const RESET_TRAFFIC_EXPORT_VERSIONS = "RESET_TRAFFIC_EXPORT_VERSIONS";

export const SET_TRAFFIC_EXPORT_SELECTED_VERSION =
  "SET_TRAFFIC_EXPORT_SELECTED_VERSION";
export const RESET_TRAFFIC_EXPORT_SELECTED_VERSION =
  "RESET_TRAFFIC_EXPORT_SELECTED_VERSION";
export const SET_TRAFFIC_PLAN_ATTACHMENTS = "SET_TRAFFIC_PLAN_ATTACHMENTS";
export const RESET_TRAFFIC_PLAN_ATTACHMENTS = "RESET_TRAFFIC_PLAN_ATTACHMENTS";
export const TRAFFIC_PLAN_ADD_ATTACHMENT_SUCCESS =
  "TRAFFIC_PLAN_ADD_ATTACHMENT_SUCCESS";
export const TRAFFIC_PLAN_ADD_SELECTED_ATTACHMENT =
  "TRAFFIC_PLAN_ADD_SELECTED_ATTACHMENT";
export const TRAFFIC_PLAN_REMOVE_SELECTED_ATTACHMENT =
  "TRAFFIC_PLAN_REMOVE_SELECTED_ATTACHMENT";
export const TRAFFIC_PLAN_CLEAR_SELECTED_ATTACHMENTS =
  "TRAFFIC_PLAN_CLEAR_SELECTED_ATTACHMENTS";
export const TRAFFIC_PLAN_DELETE_ATTACHMENT_SUCCESS =
  "TRAFFIC_PLAN_DELETE_ATTACHMENT_SUCCESS";
export const TRAFFIC_PLAN_ATTACHMENT_DELETE_SUCCESS_MESSAGE =
  "Attachments are successfully deleted";
export const TRAFFIC_PLAN_ATTACHMENT_DELETE_ERROR_MESSAGE =
  "Something went wrong during attachments deletion";
export const TRAFFIC_PLAN_ATTACHMENT_INVALID_DELETE_ERROR_MESSAGE =
  "Next attachments are not deleted: ";
