import React, { memo } from "react";
import PropTypes from "prop-types";

import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActionsWrapped from "./TablePaginationActions";
import { classes, TableRow } from "../styles/table";

const TableOverviewFooter = ({
  count,
  pageSize,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPageOptions,
  contentLoading,
  selectProps,
  backIconButtonProps,
  nextIconButtonProps,
  showTableControls
}) => {
  const disableAction = { disabled: contentLoading };

  return (
    <TableFooter>
      <TableRow className={classes.tableRow}>
        {showTableControls && (
          <TablePagination
            colSpan={3}
            count={count}
            rowsPerPage={pageSize}
            page={count ? currentPage : 0}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            ActionsComponent={TablePaginationActionsWrapped}
            SelectProps={{ ...selectProps, ...disableAction }}
            backIconButtonProps={{
              ...backIconButtonProps,
              ...disableAction
            }}
            nextIconButtonProps={{
              ...nextIconButtonProps,
              ...disableAction
            }}
          />
        )}
      </TableRow>
    </TableFooter>
  );
};

TableOverviewFooter.propTypes = {
  count: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.array,
  selectProps: PropTypes.object,
  backIconButtonProps: PropTypes.object,
  nextIconButtonProps: PropTypes.object,
  contentLoading: PropTypes.bool,
  showTableControls: PropTypes.bool
};

TableOverviewFooter.defaultProps = {
  count: 0,
  pageSize: 10,
  currentPage: 0,
  rowsPerPageOptions: [5, 10, 25],
  selectProps: {
    native: true
  },
  contentLoading: false,
  showTableControls: true
};

export default memo(TableOverviewFooter);
