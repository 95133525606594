import React from "react";
import PropTypes from "prop-types";

class ValueContainer extends React.Component {
  render() {
    const { selectProps, children } = this.props;
    const { classes } = selectProps;
    return <div className={classes.valueContainer}>{children}</div>;
  }
}

ValueContainer.propTypes = {
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired
};

export default ValueContainer;
