import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useIdleTimer } from "react-idle-timer";
import App from "../App";
import { logOutAndClearStorage } from "../functions/authUtils.ts";
import { classes, SnackbarProvider } from "./styles";

const fourHours = 4 * 60 * 60 * 1000;

const IndexComponent = () => {
  const onIdle = () => {
    logOutAndClearStorage();
  };

  useIdleTimer({
    onIdle,
    timeout: fourHours,
    debounce: 250
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SnackbarProvider
        maxSnack={10}
        preventDuplicate
        hideIconVariant
        classes={{
          variantError: classes.root,
          variantSuccess: classes.root,
          variantInfo: classes.root,
          variantWarning: classes.root
        }}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </LocalizationProvider>
  );
};

export default IndexComponent;
