import { styled, Table as MuiTable, Grid as MuiGrid } from "@mui/material";
import { solitude, matisse } from "../../../../assets/styles/colors";

const PREFIX = "ApiManagementTableStyle";
export const classes = {
  tableRow: `${PREFIX}-tableRow`,
  tableCell: `${PREFIX}-tableCell`,
  tableHeader: `${PREFIX}-tableHeader`,
  filters: `${PREFIX}-filters`
};

export const Table = styled(MuiTable)(({ theme }) => ({
  [`& .${classes.tableRow}`]: {
    height: theme.typography.pxToRem(10)
  },
  [`& .${classes.tableCell}`]: {
    padding: theme.typography.pxToRem(5)
  },
  [`& .${classes.tableHeader}`]: {
    padding: theme.typography.pxToRem(5),
    backgroundColor: solitude,
    color: matisse
  }
}));
