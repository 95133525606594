import React from "react";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import { IconButton, ListItemIcon, ListItemText } from "@mui/material";

import { Person as PersonIcon } from "@mui/icons-material";

import { classes, MenuItem } from "../styles/avatarMenu";
import { logOutAndClearStorage } from "../../../../functions/authUtils.ts";
import { PermIdentityIcon, LockIcon } from "../../../../assets/styles/icons";

export default function AvatarMenu() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserProfile = () => {
    navigate("/back-office/user-profile");
    handleClose();
  };

  const handleLogout = () => logOutAndClearStorage();

  return (
    <>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <PersonIcon color="primary" />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <MenuItem onClick={handleUserProfile} selected={false}>
          <ListItemIcon>
            <PermIdentityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary="View profile"
            className={classes.textMenuItem}
          />
        </MenuItem>
        <MenuItem onClick={handleLogout} selected={false}>
          <ListItemIcon>
            <LockIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" className={classes.textMenuItem} />
        </MenuItem>
      </Menu>
    </>
  );
}
