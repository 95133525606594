import { Box, styled } from "@mui/material";
import { getThemeSpacing } from "../../../../functions/util";
import PlanField from "../../../form/PlanField";

const PREFIX = "PlanFormStyles";
export const classes = {
  root: `${PREFIX}-root`,
  detailsLayout: `${PREFIX}-detailsLayout`,
  deatilsLeft: `${PREFIX}-deatilsLeft`,
  detailsRight: `${PREFIX}-detailsRight`
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: "0 auto",
    width: theme.typography.pxToRem(800)
  },
  [`& .${classes.detailsLayout}`]: {
    paddingTop: theme.typography.pxToRem(getThemeSpacing(theme, 2)),
    paddingRight: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  }
}));

export const PlanFieldDate = props => {
  const { id, name, label } = props;

  return (
    <Box sx={{ pt: 2 }}>
      <PlanField component="date" id={id} name={name} label={label}>
        Save
      </PlanField>
    </Box>
  );
};
