import { styled, Select as MuiSelect } from "@mui/material";
import { dodgerBlue, sapphire, solitude } from "../../../assets/styles/colors";

const PREFIX = "trafficDigitalTemplateDropdownStyles";
export const classes = {
  arrowDropDownIcon: `${PREFIX}-arrowDropDownIcon`,
  outlinedInput: `${PREFIX}-outlinedInput`,
  select: `${PREFIX}-select`
};

export const Select = styled(MuiSelect)(({ theme }) => ({
  [`&.${classes.arrowDropDownIcon}`]: {
    color: sapphire,
    right: theme.typography.pxToRem(5)
  },
  [`&.${classes.outlinedInput}`]: {
    "&:focus": { backgroundColor: "transparent" }
  },
  [`&.${classes.select}`]: {
    borderRadius: theme.typography.pxToRem(4),
    height: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(230),
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid black"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `2px solid ${dodgerBlue}`
    },
    "&.Mui-disabled": {
      backgroundColor: solitude
    }
  }
}));
