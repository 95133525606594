import { styled, Grid as MuiGrid } from "@mui/material";
import { matisse, baliHai, solitude } from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "ChangeOrderStatusDialogElementStyle";
export const classes = {
  cssTypography: `${PREFIX}-cssTypography`,
  chip: `${PREFIX}-chip`,
  iconButton: `${PREFIX}-iconButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.cssTypography}`]: {
    color: matisse
  },
  [`& .${classes.chip}`]: {
    color: baliHai,
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    minWidth: theme.typography.pxToRem(80),
    backgroundColor: solitude
  },
  [`& .${classes.iconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  }
}));
