import { styled } from "@mui/material";

const PREFIX = "ActionInsertionItemStyles";
export const classes = {
  root: `${PREFIX}-root`
};

export const Hr = styled("hr")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: theme.typography.pxToRem(1),
    height: theme.typography.pxToRem(24),
    backgroundColor: theme.palette.divider,
    margin: 0,
    border: "none",
    flexShrink: 0
  }
}));
