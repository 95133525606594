import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";

import upperFirst from "lodash/upperFirst";
import clsx from "clsx";
import { RuleTypeSearchIcon } from "../../../../../assets/styles/icons";

import {
  ruleTypesEnum,
  ruleTypeDict
} from "../../../../../configurations/appConstants";
import { classes, IconButton } from "../../styles/approvalRuleTypeSearch";

function AdditionalApprovalRuleTypeSearch(props) {
  const { searchRuleTypes, onSearchRuleTypes } = props;

  return (
    <>
      {Object.values(ruleTypesEnum).map(ruleType => {
        const upperRuleType = upperFirst(ruleType);
        return (
          <Tooltip key={ruleType} title={ruleTypeDict[ruleType]}>
            <IconButton
              name={ruleType}
              id={ruleType}
              className={clsx({
                [classes.active]: searchRuleTypes.includes(upperRuleType)
              })}
              onClick={() => onSearchRuleTypes(upperRuleType)}
            >
              <RuleTypeSearchIcon type={ruleType} />
            </IconButton>
          </Tooltip>
        );
      })}
    </>
  );
}

AdditionalApprovalRuleTypeSearch.propTypes = {
  searchRuleTypes: PropTypes.array
};

AdditionalApprovalRuleTypeSearch.defaultProps = {
  searchRuleTypes: []
};

export default AdditionalApprovalRuleTypeSearch;
