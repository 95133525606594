import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";

import some from "lodash/some";
import lowerCase from "lodash/lowerCase";
import upperFirst from "lodash/upperFirst";
import first from "lodash/first";

import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";
import {
  MEDIATYPE_DIGITAL,
  MEDIATYPE_PRINT,
  MEDIATYPE_OOH,
  MEDIATYPE_TV,
  MEDIATYPE_RADIO,
  MEDIATYPE_CINEMA,
  REMARK_STATUS_UNREAD,
  EMPTY_STRING,
  MEDIATYPE_INVENTORY
} from "../../../configurations/appConstants";
import TrafficPlanModel from "./TrafficPlan";
import {
  selectCanUploadAttachment,
  selectUserIsAdmin,
  canEditTrafficAgentFields
} from "../../selectors/userProfileSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";
import { selectAgency } from "../../selectors/applicationSelectors";
import { getOptions, getText } from "../common/utils/modelUtils";
import { isNullOrUndefined } from "../../../functions/util";

export const selectCurrentTrafficPlan = state => state.trafficPlan;
export const isUserAgent = state => state.userProfile.isAgent;
export const selectPropertyName = (_, props) => props.name;
export const selectTrafficPlanId = (_, { trafficPlanId }) => trafficPlanId;

export const selectCurrentTrafficPlanId = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.currentTrafficPlanId
);

export const isLoadedOrdersListEmpty = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.loadedOrders.length === 0
);

export const selectSelectedCampaignId = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.selectedCampaignId
);

export const selectIsEditingPlan = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.isEditing
);

export const selectDisplayErrors = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.displayErrors
);

export const getTraffics = createSelector(
  [getEntitiesSession],
  entitiesSession => {
    const { TrafficPlan, TrafficPlanRemark } = entitiesSession;

    return TrafficPlan.all()
      .orderBy("mediaTypeId")
      .toModelArray()
      .map(trafficPlan => {
        const hasUnreadTrafficPlanRemarks = TrafficPlanRemark.exists(
          r =>
            r.trafficPlan === trafficPlan.trafficPlanId &&
            r.statusId === REMARK_STATUS_UNREAD
        );
        return { ...trafficPlan.ref, hasUnreadTrafficPlanRemarks };
      });
  }
);

export const isTrafficInEditMode = createSelector(
  [selectCurrentTrafficPlan],
  trafficPlan => {
    return trafficPlan.isNew || trafficPlan.isEditing;
  }
);

export const doesModelHaveErrors = createSelector(
  [getEditingEntitiesSession, selectCurrentTrafficPlanId],
  (editingSession, trafficId) => {
    const { TrafficPlan } = editingSession;
    const model = TrafficPlan.get(c => c.id === trafficId);
    const { mediaTypeId } = model || EMPTY_STRING;

    return model ? model.hasErrors(mediaTypeId) : false;
  }
);

export const getEditingTrafficPlan = createSelector(
  [getEditingEntitiesSession, selectCurrentTrafficPlanId],
  (editingSession, trafficId) => {
    const { TrafficPlan } = editingSession;
    const model = TrafficPlan.get(c => c.id === trafficId);

    return model ? { ...model.ref } : undefined;
  }
);

export const getPropertyValue = createCachedSelector(
  [getEditingTrafficPlan, selectPropertyName],
  (trafficPlan, name) => (trafficPlan ? trafficPlan[name] : undefined)
)((_, props) => props.name);

export const getPropertyPreviousValue = createCachedSelector(
  [selectCurrentTrafficPlanId, selectPropertyName],
  (trafficPlan, name) => (trafficPlan ? trafficPlan[name] : undefined)
)((_, props) => props.name);

export const getPropertyErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectCurrentTrafficPlanId, selectPropertyName],
  (editingSession, trafficId, name) => {
    const { TrafficPlan } = editingSession;
    const model = TrafficPlan.get(c => c.id === trafficId);
    const { mediaTypeId } = model || EMPTY_STRING;

    return model ? model.validateByProperty(mediaTypeId, name) : undefined;
  }
)((_, props) => props.name);

export const isCreativeAgencyFieldVisibleForMediatype = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficId) => {
    const { TrafficPlan } = entitiesSession;
    const traffic = TrafficPlan.get(c => c.id === trafficId);
    const { mediaTypeId } = traffic || {};
    switch (mediaTypeId) {
      case MEDIATYPE_DIGITAL:
        return true;
      case MEDIATYPE_PRINT:
        return true;
      case MEDIATYPE_OOH:
        return true;
      case MEDIATYPE_TV:
        return true;
      case MEDIATYPE_RADIO:
        return true;
      case MEDIATYPE_CINEMA:
        return true;
      case MEDIATYPE_INVENTORY:
        return true;
      default:
        return false;
    }
  }
);

export const isContactFieldVisibleForMediatype = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficId) => {
    const { TrafficPlan } = entitiesSession;
    const traffic = TrafficPlan.get(c => c.id === trafficId);
    const { mediaTypeId } = traffic || {};

    switch (mediaTypeId) {
      case MEDIATYPE_DIGITAL:
        return true;
      case MEDIATYPE_PRINT:
        return true;
      case MEDIATYPE_OOH:
        return true;
      case MEDIATYPE_TV:
        return true;
      case MEDIATYPE_RADIO:
        return true;
      case MEDIATYPE_CINEMA:
        return true;
      case MEDIATYPE_INVENTORY:
        return true;
      default:
        return false;
    }
  }
);

export const isUploadButtonVisible = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId, selectCanUploadAttachment],
  (entitiesSession, trafficId, canUpload) => {
    const { TrafficPlan } = entitiesSession;
    const traffic = TrafficPlan.get(c => c.id === trafficId);
    const { mediaTypeId } = traffic || {};

    return mediaTypeId === MEDIATYPE_OOH && canUpload;
  }
);

export const isEmailFieldVisibleForMediatype = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficId) => {
    const { TrafficPlan } = entitiesSession;
    const traffic = TrafficPlan.get(c => c.id === trafficId);
    const { mediaTypeId } = traffic || {};

    switch (mediaTypeId) {
      case MEDIATYPE_DIGITAL:
        return true;
      case MEDIATYPE_PRINT:
        return true;
      case MEDIATYPE_OOH:
        return true;
      case MEDIATYPE_TV:
        return false;
      case MEDIATYPE_RADIO:
        return false;
      case MEDIATYPE_CINEMA:
        return true;
      case MEDIATYPE_INVENTORY:
        return true;
      default:
        return false;
    }
  }
);

export const isContactGroupMFieldVisibleForMediatype = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficId) => {
    const { TrafficPlan } = entitiesSession;
    const traffic = TrafficPlan.get(c => c.id === trafficId);
    const { mediaTypeId } = traffic || {};

    switch (mediaTypeId) {
      case MEDIATYPE_DIGITAL:
        return true;
      case MEDIATYPE_PRINT:
        return true;
      case MEDIATYPE_OOH:
        return true;
      case MEDIATYPE_TV:
        return true;
      case MEDIATYPE_RADIO:
        return true;
      case MEDIATYPE_CINEMA:
        return true;
      case MEDIATYPE_INVENTORY:
        return true;
      default:
        return false;
    }
  }
);

export const isCCFieldVisibleForMediatype = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficId) => {
    const { TrafficPlan } = entitiesSession;
    const traffic = TrafficPlan.get(c => c.id === trafficId);
    const { mediaTypeId } = traffic || {};

    switch (mediaTypeId) {
      case MEDIATYPE_DIGITAL:
      case MEDIATYPE_PRINT:
      case MEDIATYPE_OOH:
      case MEDIATYPE_TV:
      case MEDIATYPE_RADIO:
      case MEDIATYPE_CINEMA:
      case MEDIATYPE_INVENTORY:
        return true;
      default:
        return false;
    }
  }
);

export const isPhoneNumberGroupMFieldVisibleForMediatype = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficId) => {
    const { TrafficPlan } = entitiesSession;
    const traffic = TrafficPlan.get(c => c.id === trafficId);
    const { mediaTypeId } = traffic || {};

    switch (mediaTypeId) {
      case MEDIATYPE_DIGITAL:
        return true;
      case MEDIATYPE_PRINT:
        return true;
      case MEDIATYPE_OOH:
        return true;
      case MEDIATYPE_TV:
        return true;
      case MEDIATYPE_RADIO:
        return true;
      case MEDIATYPE_CINEMA:
        return true;
      case MEDIATYPE_INVENTORY:
        return true;
      default:
        return false;
    }
  }
);

export const doesTrafficPlanExist = createSelector(
  [selectCurrentTrafficPlanId, getEntitiesSession],
  (trafficId, entitiesSession) => {
    const trafficPlanModel = entitiesSession.TrafficPlan.withId(trafficId);
    const { trafficPlanId } = trafficPlanModel || {};
    return !!trafficPlanId;
  }
);

export const selectRules = createSelector(
  [
    isCreativeAgencyFieldVisibleForMediatype,
    isContactFieldVisibleForMediatype,
    isEmailFieldVisibleForMediatype,
    isCCFieldVisibleForMediatype,
    isContactGroupMFieldVisibleForMediatype,
    isPhoneNumberGroupMFieldVisibleForMediatype,
    doesTrafficPlanExist
  ],
  (
    isCreativeAgencyFieldVisible,
    isContactFieldVisible,
    isEmailFieldVisible,
    isCCFieldVisable,
    isContactGroupMFieldVisible,
    isPhoneNumberGroupMField,
    doesTrafficExist
  ) => {
    return {
      isCreativeAgencyFieldVisibleForMediatype: isCreativeAgencyFieldVisible,
      isContactFieldVisibleForMediatype: isContactFieldVisible,
      isEmailFieldVisibleForMediatype: isEmailFieldVisible,
      isCCFieldVisibleForMediatype: isCCFieldVisable,
      isContactGroupMFieldVisibleForMediatype: isContactGroupMFieldVisible,
      isPhoneNumberGroupMFieldVisibleForMediatype: isPhoneNumberGroupMField,
      doesTrafficPlanExist: doesTrafficExist
    };
  }
);

export const isHidden = createCachedSelector(
  [selectRules, selectPropertyName],
  (rules, name) => {
    const configuration = TrafficPlanModel.hideFields;
    return some(configuration[name], c => rules[c]);
  }
)((_, props) => props.name);

export const selectSelectedTrafficItems = createSelector(
  selectCurrentTrafficPlan,
  trafficPlan => trafficPlan.selectedTrafficItems
);

export const selectSelectedMultipleEditTrafficItems = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.selectedMultipleEditTrafficItems
);

export const selectSelectedTrafficPlanMediaType = createSelector(
  [selectSelectedMultipleEditTrafficItems, getEntitiesSession],
  (selectedMultipleEditTrafficItems, entitiesSession) => {
    const { TrafficItem } = entitiesSession;
    const fistSelectedTrafficItem = first(selectedMultipleEditTrafficItems);
    const { mediaTypeId } =
      TrafficItem.get(ti => ti.trafficItemId === fistSelectedTrafficItem) || {};

    return mediaTypeId;
  }
);

export const selectTrafficPlanIsSelectable = createSelector(
  [getEntitiesSession, selectSelectedTrafficPlanMediaType, selectTrafficPlanId],
  (entitiesSession, selectedMediaTypeId, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    const trafficPlan = TrafficPlan.get(tp => tp.id === trafficPlanId);

    return (
      !selectedMediaTypeId || selectedMediaTypeId === trafficPlan.mediaTypeId
    );
  }
);

export const selectIsTrafficPlanSelectableForStatusChange = createSelector(
  [getEntitiesSession, selectTrafficPlanIsSelectable, selectTrafficPlanId],
  (entitiesSession, isSelectable, trafficPlanId) => {
    const { TrafficItem } = entitiesSession;
    const hasNonePreliminary = TrafficItem.exists(
      ti => ti.trafficPlanId === trafficPlanId && !ti.isPreliminary
    );

    return hasNonePreliminary && isSelectable;
  }
);

export const selectedTrafficItemStatus = createSelector(
  selectCurrentTrafficPlan,
  trafficPlan => trafficPlan.selectedTrafficItemStatus
);

export const isSelected = createSelector(
  selectCurrentTrafficPlan,
  traffingPlan => !isNullOrUndefined(traffingPlan.currentTrafficPlanId)
);

export const selectAnyTrafficItemSelected = createSelector(
  [selectSelectedTrafficItems],
  selectedTrafficItemList => {
    return selectedTrafficItemList.length > 0;
  }
);

export const getTrafficTemplateName = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    const trafficPlan = TrafficPlan.get(ti => ti.id === trafficPlanId);
    return trafficPlan && trafficPlan.mediaTypeName
      ? `Traffic${upperFirst(lowerCase(trafficPlan.mediaTypeName))}`
      : "NoTraffic";
  }
);

export const getTrafficMediaTypeId = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    const trafficPlan = TrafficPlan.get(ti => ti.id === trafficPlanId);
    return trafficPlan && trafficPlan.mediaTypeId
      ? trafficPlan.mediaTypeId
      : null;
  }
);

export const getTrafficPlan = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    const trafficPlanModel = TrafficPlan.get(ti => ti.id === trafficPlanId);
    return trafficPlanModel ? trafficPlanModel.ref : undefined;
  }
);

export const getTrafficPlanName = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    const trafficPlan = TrafficPlan.get(ti => ti.id === trafficPlanId);
    return trafficPlan && trafficPlan.planName ? trafficPlan.planName : null;
  }
);

export const getTrafficMediaTypeName = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    const trafficPlan = TrafficPlan.get(ti => ti.id === trafficPlanId);
    return trafficPlan && trafficPlan.mediaTypeName
      ? trafficPlan.mediaTypeName
      : null;
  }
);

export const getPlanIdByTrafficPlanId = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    const trafficPlan = TrafficPlan.get(ti => ti.id === trafficPlanId);
    return trafficPlan ? trafficPlan.planId : undefined;
  }
);

export const getCampaignIdByTrafficPlanId = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    const trafficPlan = TrafficPlan.get(ti => ti.id === trafficPlanId);
    return trafficPlan ? trafficPlan.campaignId : undefined;
  }
);

export const isTrafficPlanVirtual = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    const trafficPlan = TrafficPlan.get(ti => ti.id === trafficPlanId);
    return trafficPlan ? isNullOrUndefined(trafficPlan.trafficPlanId) : false;
  }
);

export const selectOrderList = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.loadedOrders
);

export const selectOrderForTraffic = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.selectedOrderForTraffic
);

export const doesTrafficPlanExistById = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    return TrafficPlan.idExists(trafficPlanId);
  }
);

export const getTrafficItemIdsByCheckedTraficPlan = createSelector(
  [
    getEntitiesSession,
    selectTrafficPlanId,
    canEditTrafficAgentFields,
    selectUserIsAdmin
  ],
  (entitiesSession, checkedTrafficPlan, isAgent, isAdmin) => {
    const { TrafficItem } = entitiesSession;

    if (isAgent && !isAdmin) {
      return TrafficItem.filter(
        trafficItem =>
          trafficItem.trafficPlan === checkedTrafficPlan &&
          (!trafficItem.isPreliminary || trafficItem.trafficItemStatusId !== 1)
      )
        .toRefArray()
        .map(({ trafficItemId }) => trafficItemId);
    }

    return TrafficItem.filter(
      trafficItem => trafficItem.trafficPlan === checkedTrafficPlan
    )
      .toRefArray()
      .map(({ trafficItemId }) => trafficItemId);
  }
);

export const getTrafficItemIdsByCheckedTraficPlanForMultipleEdit = createSelector(
  [
    getEntitiesSession,
    selectTrafficPlanId,
    canEditTrafficAgentFields,
    selectUserIsAdmin
  ],
  (entitiesSession, checkedTrafficPlan, isAgent, isAdmin) => {
    const { TrafficItem } = entitiesSession;

    if (isAgent && !isAdmin) {
      return TrafficItem.filter(
        trafficItem => trafficItem.trafficPlan === checkedTrafficPlan
      )
        .toRefArray()
        .map(({ trafficItemId }) => trafficItemId);
    }

    return TrafficItem.filter(
      trafficItem => trafficItem.trafficPlan === checkedTrafficPlan
    )
      .toRefArray()
      .map(({ trafficItemId }) => trafficItemId);
  }
);

export const getNonePreliminaryTrafficItemIdsByCheckedTraficPlan = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, checkedTrafficPlan) => {
    const { TrafficItem } = entitiesSession;
    return TrafficItem.filter(
      trafficItem =>
        trafficItem.trafficPlan === checkedTrafficPlan &&
        trafficItem.isPreliminary === false
    )
      .toRefArray()
      .map(({ trafficItemId }) => trafficItemId);
  }
);

export const selectSelectedMultipleEditTrafficItemsByTrafficPlan = createSelector(
  [
    selectSelectedMultipleEditTrafficItems,
    getTrafficItemIdsByCheckedTraficPlanForMultipleEdit
  ],
  (multipleTrafficItems, multipleTrafficItemsByTrafficPlan) => {
    return multipleTrafficItems.filter(selectedEl =>
      multipleTrafficItemsByTrafficPlan.includes(selectedEl)
    ).length;
  }
);

export const selectTrafficItemStatusChangeSelectedByTrafficPlan = createSelector(
  [selectSelectedTrafficItems, getTrafficItemIdsByCheckedTraficPlan],
  (multipleTrafficItems, multipleTrafficItemsByTrafficPlan) => {
    return multipleTrafficItems.filter(selectedEl =>
      multipleTrafficItemsByTrafficPlan.includes(selectedEl)
    ).length;
  }
);

export const selectAreAnyTrafficItemsForMultipleEditSelected = createSelector(
  [selectSelectedMultipleEditTrafficItems],
  selectedMultipleEditTrafficItems => {
    return selectedMultipleEditTrafficItems.length > 0;
  }
);

export const getTrafficItemsSelectableForStatusChangeCount = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficItem } = entitiesSession;

    return TrafficItem.filter(
      trafficItem =>
        (trafficItem.trafficPlanId === trafficPlanId ||
          trafficItem.trafficPlan === trafficPlanId) &&
        !trafficItem.isPreliminary
    ).count();
  }
);

export const getTrafficItemsCount = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficItem } = entitiesSession;

    return TrafficItem.filter(
      trafficItem =>
        trafficItem.trafficPlanId === trafficPlanId ||
        trafficItem.trafficPlan === trafficPlanId
    ).count();
  }
);

export const selectIsFinalized = createSelector(
  [getEntitiesSession, selectTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan, TrafficItem } = entitiesSession;
    const finalizedStatuses = [20, 500, 600];
    const finalizedPlanIds = [];

    TrafficPlan.all()
      .toModelArray()
      .forEach(trafficPlan => {
        const trafficPlansItems = TrafficItem.filter(
          ti => ti.trafficPlan === trafficPlan.id
        ).toModelArray();

        const isFinalized = trafficPlansItems.every(trafficItem =>
          finalizedStatuses.includes(trafficItem.trafficItemStatusId)
        );

        if (isFinalized) {
          finalizedPlanIds.push(trafficPlan.id);
        }
      });

    return finalizedPlanIds.includes(trafficPlanId);
  }
);

export const selectOpenTrafficPlanRemarkDialog = createSelector(
  [selectCurrentTrafficPlan],
  trafficPlan => {
    return trafficPlan.openTrafficPlanRemarkDialog;
  }
);

export const selectOpenTrafficPlanEmailDialog = createSelector(
  [selectCurrentTrafficPlan],
  trafficPlan => {
    return trafficPlan.openTrafficPlanEmailDialog;
  }
);

export const selectPreviewData = createSelector(
  [selectCurrentTrafficPlan],
  trafficPlan => {
    return trafficPlan.previewData;
  }
);
export const selectOpenTrafficPlanPreviewDialog = createSelector(
  [selectCurrentTrafficPlan],
  trafficPlan => {
    return trafficPlan.openPreviewDialog;
  }
);

export const selectMediaTypeId = createSelector(
  [selectCurrentTrafficPlan],
  trafficPlan => {
    return trafficPlan.mediaTypeId;
  }
);

export const selectSelectedUsers = createSelector(
  [selectCurrentTrafficPlan],
  trafficPlan => {
    return trafficPlan.selectedUsers;
  }
);

export const getSelectedTraffic = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficId) => {
    const { TrafficPlan } = entitiesSession;

    return TrafficPlan.withId(trafficId);
  }
);

export const isSelectedTrafficDigital = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficId) => {
    const { TrafficPlan } = entitiesSession;

    const trafficPlan = TrafficPlan.withId(trafficId);
    return trafficPlan
      ? trafficPlan.mediaTypeId === MEDIATYPE_DIGITAL ||
          trafficPlan.mediaTypeId === MEDIATYPE_INVENTORY
      : false;
  }
);

export const selectExportVersions = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.loadedVersions
);

export const selectTrafficExportSelectedVersion = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.selectedVersion
);

export const getTrafficPlanPropertyOptions = createCachedSelector(
  [getApiData, getEditingTrafficPlan, selectPropertyName, selectAgency],
  (apiData, trafficItem, name, agencyID) => {
    const configuration = TrafficPlanModel.apiConfiguration[name];

    return configuration
      ? getOptions(configuration, apiData, trafficItem, agencyID)
      : undefined;
  }
)((_, props) => props.name);

export const getTrafficRequestText = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId, getApiData, selectAgency],
  (entities, trafficPlanId, apiData, agencyID) => {
    const { TrafficPlan } = entities;
    const { apiConfiguration: configuration } = TrafficPlanModel;
    const trafficPlanModelInstance = TrafficPlan.withId(trafficPlanId);
    const trafficPlan = trafficPlanModelInstance
      ? trafficPlanModelInstance.ref
      : {};
    return getText(
      trafficPlan.trafficRequest,
      configuration.trafficRequest,
      trafficPlan,
      apiData,
      agencyID
    );
  }
);

export const getThirdPartyMeasurementText = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId, getApiData, selectAgency],
  (entities, trafficPlanId, apiData, agencyID) => {
    const { TrafficPlan } = entities;
    const { apiConfiguration: configuration } = TrafficPlanModel;
    const trafficPlanModelInstance = TrafficPlan.withId(trafficPlanId);
    const trafficPlan = trafficPlanModelInstance
      ? trafficPlanModelInstance.ref
      : {};
    return getText(
      trafficPlan.thirdPartyMeasurement,
      configuration.thirdPartyMeasurement,
      trafficPlan,
      apiData,
      agencyID
    );
  }
);

export const selectTrafficPlanAttachments = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.loadedAttachments
);

export const selectSelectedTrafficPlanAttachments = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.selectedAttachments
);

export const selectSelectedAttachmentCount = createSelector(
  selectCurrentTrafficPlan,
  traffic => traffic.selectedAttachments.length
);

export const selectAdmanagementIsSet = createSelector(
  [getEditingEntitiesSession, selectCurrentTrafficPlanId],
  (editingSession, trafficPlanId) => {
    const { TrafficPlan } = editingSession;
    const trafficPlanModel = TrafficPlan.get(ti => ti.id === trafficPlanId);
    const trafficPlan = trafficPlanModel?.ref;
    return Boolean(trafficPlan?.adManagementOrderID);
  }
);

export const selectPlanIdByTrafficPlanId = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    const trafficPlan = TrafficPlan.get(ti => ti.id === trafficPlanId);
    return trafficPlan ? trafficPlan.planId : undefined;
  }
);

export const selectCanGoToDashboardPage = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlan } = entitiesSession;
    const trafficPlanModelInstance = TrafficPlan.withId(trafficPlanId);
    const trafficPlan = trafficPlanModelInstance
      ? trafficPlanModelInstance.ref
      : {};
    return trafficPlan?.canGoToDashboardPage;
  }
);
