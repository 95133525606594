import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setProjectValue } from "../../business/models/Project/projectActions";

import {
  getProjectPropertyValue,
  getProjectPropertyPreviousValue,
  getProjectPropertyErrorsByProperty,
  selectProjectDisplayErrors,
  getProjectPropertyOptions,
  selectIsEditingProject,
  getProjectViewPropertyValue
} from "../../business/models/Project/projectSelectors";

import Controls from "./controls/index";

class ProjectField extends PureComponent {
  componentDidMount() {
    const { defaultValue, name, onChange } = this.props;

    if (defaultValue) {
      onChange({ [name]: defaultValue });
    }
  }

  handleChange = value => {
    const { name, onChange } = this.props;
    onChange({ [name]: value });
  };

  render() {
    const { component, ...componentProps } = this.props;
    const Control = Controls[component];
    return (
      <Control
        component={component}
        {...componentProps}
        onChange={this.handleChange}
      />
    );
  }
}

ProjectField.propTypes = {
  component: PropTypes.oneOf(["text", "autocomplete", "checkbox"]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

ProjectField.defaultProps = {
  errors: []
};

const mapStateToProps = (state, ownProps) => {
  const editMode = selectIsEditingProject(state);

  return {
    value: editMode
      ? getProjectPropertyValue(state, ownProps)
      : getProjectViewPropertyValue(state, ownProps),
    prevValue: getProjectPropertyPreviousValue(state, ownProps),
    errors: getProjectPropertyErrorsByProperty(state, ownProps),
    displayErrors: selectProjectDisplayErrors(state),
    options: getProjectPropertyOptions(state, ownProps),
    disabled: !editMode || ownProps.disabled
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: (id, data) => dispatch(setProjectValue(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectField);
