import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import { Button, Grid, DialogActions, DialogContent } from "@mui/material";

import DialogTitle from "../../../../shared/dialog/DialogTitle";
import ActionDialogItem from "./ActionDialogItem";
import Scrollbar from "../../../../shared/Scrollbar";

import { classes, Dialog } from "../styles/actionDialog";

import {
  selectOpenDialog,
  getSelected,
  selectSelectedNumber,
  selectAction,
  selectUnsendStatuses
} from "../../../../../business/selectors/actionPageSelectors";
import {
  closeDialog,
  clearAction,
  clearSendStatuses,
  clearUnsendStatuses,
  statusSave,
  clearSelected
} from "../../../../../business/actions/actionPageActions";
import {
  ACTION_DECLINE,
  ACTION_APPROVE,
  ACTION_DONE
} from "../../../../../business/constants/actionPageConstants";
import {
  selectCurrentCampaign,
  getCurrentCampaignName
} from "../../../../../business/models/Campaign/campaignSelectors";
import { loadActionEntityData } from "../../../../../business/models/common/data/dataActions";
import { selectIsLoading } from "../../../../../business/selectors/applicationSelectors";
import { getOrders } from "../../../../../business/models/Order/orderSelectors";

const TITLE = "Order list:";
const ActionDialog = () => {
  const dispatch = useDispatch();

  const orders = useSelector(getOrders);
  const selectedOrders = useSelector(getSelected);
  const open = useSelector(selectOpenDialog);
  const selectedNumber = useSelector(selectSelectedNumber);
  const action = useSelector(selectAction);
  const unsendStatuses = useSelector(selectUnsendStatuses);
  const currentCampaign = useSelector(selectCurrentCampaign);
  const campaignName = useSelector(getCurrentCampaignName);
  const isLoading = useSelector(selectIsLoading);

  const disabled = selectedNumber === 0 && action !== ACTION_DONE;
  const filterdOrders = orders.filter(o => selectedOrders.includes(o.orderId));

  const handleClose = () => dispatch(closeDialog());

  const handleSave = () => {
    if (action === ACTION_DONE) {
      handleClose();
    } else {
      dispatch(statusSave());
    }
  };

  const handleOnExited = () => {
    if (action === ACTION_DONE) {
      dispatch(clearAction());
      dispatch(clearSendStatuses());
      dispatch(clearUnsendStatuses());
    }
  };

  const handleOnExit = () => {
    if (isEmpty(unsendStatuses) && action === ACTION_DONE) {
      dispatch(clearSelected());
    }
  };

  const handleOnExiting = () => {
    if (action === ACTION_DONE) {
      dispatch(loadActionEntityData(currentCampaign));
    }
  };

  const titleText = campaignName || TITLE;

  useEffect(() => {
    if (selectedNumber === 0) {
      dispatch(closeDialog());
    }
  }, [dispatch, selectedNumber]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      TransitionProps={{
        onExited: () => handleOnExited(),
        onExit: () => handleOnExit(),
        onExiting: () => handleOnExiting()
      }}
    >
      <DialogTitle id="action-dialog-title">{titleText}</DialogTitle>
      <DialogContent id="action-dialog-content">
        <Scrollbar>
          {filterdOrders.map(order => (
            <ActionDialogItem
              key={order.orderId}
              id={order.orderId}
              order={order}
            />
          ))}
        </Scrollbar>
      </DialogContent>
      <DialogActions id="action-dialog-actions">
        <Grid container justifyContent="space-between">
          {action === ACTION_DONE ? (
            <div />
          ) : (
            <Button
              id="cancel_selection"
              name="cancel_selection"
              onClick={handleClose}
              className={classes.closeButton}
            >
              Cancel
            </Button>
          )}
          <Button
            id="approve_selection"
            name="approve_selection"
            onClick={handleSave}
            className={clsx({
              [classes.approveSelectionButton]: action === ACTION_APPROVE,
              [classes.declineSelectionButton]: action === ACTION_DECLINE,
              [classes.doneButton]: action === ACTION_DONE
            })}
            variant="contained"
            disabled={disabled || isLoading}
          >
            {action === ACTION_DONE
              ? ACTION_DONE
              : `${action} SELECTION (${selectedNumber})`}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
