import {
  styled,
  Dialog as MuiDialog,
  Button as MuiButton
} from "@mui/material";
import { crusta } from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "UploadAttachmentOoh";
export const classes = {
  action: `${PREFIX}-action`,
  content: `${PREFIX}-content`
};

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${classes.content}`]: {
    marginTop: theme.typography.pxToRem(16)
  },
  [`& .${classes.action}`]: {
    borderTop: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.typography.pxToRem(getThemeSpacing(theme, 2))
  }
}));

export const Button = styled(MuiButton)(() => ({
  "&.MuiButton-textSecondary": {
    color: crusta
  },
  "&.Mui-disabled": {
    color: "#000000",
    opacity: 0.26,
    boxShadow: "none"
  }
}));
