import { styled, Popper as MuiPopper } from "@mui/material";
import { matisse, linkWater } from "../../../assets/styles/colors";

const PREFIX = "OrderDetailsPopperStyles";
export const classes = {
  orderPopper: `${PREFIX}-orderPopper`,
  planPopper: `${PREFIX}-planPopper`,
  trafficItemPopper: `${PREFIX}-trafficItemPopper`,
  detailsPanel: `${PREFIX}-detailsPanel`,
  typographyDetailsValue: `${PREFIX}-typographyDetailsValue`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  typographyTrafficItemRemarksValue: `${PREFIX}-typographyTrafficItemRemarksValue`
};

export const Popper = styled(MuiPopper)(({ theme }) => ({
  [`&.${classes.orderPopper}`]: {
    width: theme.typography.pxToRem(1200),
    marginRight: theme.typography.pxToRem(90),
    zIndex: 1500
  },
  [`&.${classes.planPopper}`]: {
    width: theme.typography.pxToRem(600)
  },
  [`&.${classes.trafficItemPopper}`]: {
    maxWidth: theme.typography.pxToRem(420)
  },
  [`& .${classes.detailsPanel}`]: {
    padding: theme.typography.pxToRem(10)
  },
  [`& .${classes.typographyDetailsValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyTrafficItemRemarksValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(16),
    wordBreak: "break-all"
  }
}));
