import { MANAGE_PUBLIC_PERMISSIONS } from "../../configurations/apiUrls";
import {
  SET_API_MANAGEMENT_ITEMS,
  SET_API_MANAGEMENT_COUNT,
  SET_IS_OPEN_DELETE_PUBLIC_PERMISSION_DIALOG,
  SET_SELECTED_PUBLIC_PERMISSION,
  SET_IS_OPEN_ADD_PUBLIC_PERMISSION_DIALOG
} from "../constants/apiManagementConstants";
import { GET, DELETE, POST } from "../constants/applicationConstants";
import generateUrl from "../models/common/utils/urlUtils";
import { apiCall, enqueueApiErrorMessage } from "./applicationActions";

export const setCount = payload => ({
  type: SET_API_MANAGEMENT_COUNT,
  payload
});

export const setItems = payload => ({
  type: SET_API_MANAGEMENT_ITEMS,
  payload
});

export const setIsOpenDeletePublicPermission = () => ({
  type: SET_IS_OPEN_DELETE_PUBLIC_PERMISSION_DIALOG
});

export const setIsOpenAddPublicPermission = () => ({
  type: SET_IS_OPEN_ADD_PUBLIC_PERMISSION_DIALOG
});

export const setSelectedPublicPermission = query => ({
  type: SET_SELECTED_PUBLIC_PERMISSION,
  payload: query
});

export const loadPublicPermissions = query => dispatch => {
  const { applicationId, resource, ...queryFilters } = query;
  const queryObj = { applicationId, resource, ...queryFilters };
  const url = generateUrl(MANAGE_PUBLIC_PERMISSIONS, queryObj);

  return dispatch(apiCall(GET, url)).then(
    response => {
      if (response) {
        const { totalItems, items } = response;

        dispatch(setCount(totalItems));
        dispatch(setItems(items));
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const deletePublicPermission = publicPermission => dispatch => {
  const url = generateUrl(MANAGE_PUBLIC_PERMISSIONS);

  return dispatch(apiCall(DELETE, url, publicPermission)).then(
    response => {
      if (response) {
        dispatch(setIsOpenDeletePublicPermission());
        dispatch(
          setSelectedPublicPermission({ applicationId: null, resource: null })
        );
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const addPublicPermission = (applicationId, resource) => dispatch => {
  const url = generateUrl(MANAGE_PUBLIC_PERMISSIONS);
  const body = { applicationId, resource };

  return dispatch(apiCall(POST, url, body)).then(
    response => {
      if (response) {
        dispatch(setIsOpenAddPublicPermission());
        dispatch(
          setSelectedPublicPermission({ applicationId: null, resource: null })
        );
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};
