import { styled, Grid as MuiGrid } from "@mui/material";
import {
  crusta,
  white,
  dodgerBlue,
  baliHai,
  linkWater,
  solitude,
  black
} from "../../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../../functions/util";

const PREFIX = "ActionActionsStyle";
export const classes = {
  saveButton: `${PREFIX}-saveButton`,
  approveButton: `${PREFIX}-approveButton`,
  declineButton: `${PREFIX}-declineButton`,
  redirectButton: `${PREFIX}-redirectButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.saveButton}`]: {
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 3)),
    color: white,
    "&:disabled": {
      color: linkWater,
      backgroundColor: solitude
    }
  },
  [`& .${classes.approveButton}`]: {
    backgroundColor: dodgerBlue
  },
  [`& .${classes.declineButton}`]: {
    backgroundColor: crusta
  },
  [`& .${classes.typographyInvoicedInsertionHeader}`]: {
    color: baliHai
  },
  [`& .${classes.redirectButton}`]: {
    color: black,
    "&:disabled": {
      color: linkWater
    }
  }
}));
