import without from "lodash/without";

import { createReducer } from "../common/utils/reducerUtils";

import {
  SET_CURRENT_TRAFFIC_PLAN_ID,
  START_EDITING_TRAFFIC_PLAN,
  STOP_EDITING_TRAFFIC_PLAN,
  DISPLAY_TRAFFIC_PLAN_ERRORS,
  HIDE_TRAFFIC_PLAN_ERRORS,
  START_EDITING_TRAFFIC_PLAN_REMARK,
  STOP_EDITING_TRAFFIC_PLAN_REMARK,
  CLEAR_CURRENT_TRAFFIC_PLAN,
  SET_ORDER_LIST,
  RESET_ORDER_LIST,
  SET_SELECTED_ORDER_TRAFFIC,
  RESET_SELECTED_ORDER_TRAFFIC,
  TRAFFIC_PLAN_INSERT_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT,
  TRAFFIC_PLAN_REMOVE_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT,
  TRAFFIC_PLAN_CLEAR_TRAFFIC_ITEMS_FOR_MULTIPLE_EDIT,
  TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_INSERT_SELECTED,
  TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_REMOVE_SELECTED,
  TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_CLEAR_SELECTED,
  TRAFFIC_PLAN_REMARK_DIALOG_OPEN,
  TRAFFIC_PLAN_REMARK_DIALOG_CLOSE,
  TRAFFIC_PLAN_PREVIEW_OPEN,
  TRAFFIC_PLAN_PREVIEW_CLOSE,
  TRAFFIC_PLAN_EMAIL_OPEN,
  TRAFFIC_PLAN_EMAIL_CLOSE,
  TRAFFIC_PLAN_SELECTED_USERS_SET,
  TRAFFIC_PLAN_SELECTED_USERS_CLEAR,
  TRAFFIC_PLAN_MEDIA_TYPE_ID_SET,
  TRAFFIC_PLAN_MEDIA_TYPE_ID_CLEAR,
  TRAFFIC_PLAN_PREVIEW_DATA_SET,
  TRAFFIC_PLAN_PREVIEW_DATA_CLEAR,
  SET_TRAFFIC_EXPORT_VERSIONS,
  RESET_TRAFFIC_EXPORT_VERSIONS,
  SET_TRAFFIC_EXPORT_SELECTED_VERSION,
  RESET_TRAFFIC_EXPORT_SELECTED_VERSION,
  SET_TRAFFIC_PLAN_ATTACHMENTS,
  RESET_TRAFFIC_PLAN_ATTACHMENTS,
  TRAFFIC_PLAN_ADD_ATTACHMENT_SUCCESS,
  TRAFFIC_PLAN_ADD_SELECTED_ATTACHMENT,
  TRAFFIC_PLAN_REMOVE_SELECTED_ATTACHMENT,
  TRAFFIC_PLAN_CLEAR_SELECTED_ATTACHMENTS,
  TRAFFIC_PLAN_DELETE_ATTACHMENT_SUCCESS
} from "./trafficPlanConstants";

const initialState = {
  currentTrafficPlanId: null,
  isEditing: false,
  isEditingRemark: false,
  displayErrors: false,
  selectedTrafficItemStatus: 0,
  selectedTrafficItems: [],
  selectedMultipleEditTrafficItems: [],
  loadedOrders: [],
  selectedOrderForTraffic: null,
  openTrafficPlanRemarkDialog: false,
  openTrafficPlanEmailDialog: false,
  openPreviewDialog: false,
  previewData: {},
  selectedUsers: [],
  mediaTypeId: null,
  loadedVersions: [],
  selectedVersion: {},
  loadedAttachments: [],
  selectedAttachments: []
};

const openTrafficPlanRemarkDialog = state => ({
  ...state,
  openTrafficPlanRemarkDialog: true
});

const closeTrafficPlanRemarkDialog = state => ({
  ...state,
  openTrafficPlanRemarkDialog: false
});

const openTrafficPlanEmailDialog = state => ({
  ...state,
  openTrafficPlanEmailDialog: true
});

const closeTrafficPlanEmailDialog = state => ({
  ...state,
  openTrafficPlanEmailDialog: false
});

const openPreviewDialog = state => ({
  ...state,
  openPreviewDialog: true
});

const closePreviewDialog = state => ({
  ...state,
  openPreviewDialog: false
});

const setPreviewData = (state, payload) => ({
  ...state,
  previewData: payload
});

const clearPreviewData = state => ({
  ...state,
  previewData: {}
});

const setMediaTypeId = (state, payload) => ({
  ...state,
  mediaTypeId: payload
});

const clearMediaTypeId = state => ({
  ...state,
  mediaTypeId: null
});

const setSelectedUsers = (state, payload) => ({
  ...state,
  selectedUsers: payload
});

const clearSelectedUsers = state => ({
  ...state,
  selectedUsers: []
});

function setCurrentTrafficPlanId(state, trafficPlanId) {
  return {
    ...state,
    isEditing: false,
    isEditingRemark: false,
    displayErrors: false,
    currentTrafficPlanId: trafficPlanId
  };
}

function startEditing(state) {
  return {
    ...state,
    isEditing: true
  };
}

function stopEditing(state) {
  return {
    ...state,
    isEditing: false
  };
}

function displayErrors(state) {
  return {
    ...state,
    displayErrors: true
  };
}

function hideErrors(state) {
  return {
    ...state,
    displayErrors: false
  };
}

function startEditingRemark(state) {
  return {
    ...state,
    isEditingRemark: true
  };
}

function stopEditingRemark(state) {
  return {
    ...state,
    isEditingRemark: false
  };
}

function resetCurrent(state) {
  return {
    ...state,
    currentTrafficPlanId: null,
    isEditing: false,
    isEditingRemark: false,
    displayErrors: false
  };
}

const insertMultipleEditTrafficItems = (state, { trafficItemIds = [] }) => ({
  ...state,
  selectedMultipleEditTrafficItems: [
    ...new Set([...state.selectedMultipleEditTrafficItems, ...trafficItemIds])
  ]
});

const removeMultipleEditTrafficItems = (state, { trafficItemIds = [] }) => ({
  ...state,
  selectedMultipleEditTrafficItems: without(
    state.selectedMultipleEditTrafficItems,
    ...trafficItemIds
  )
});

const clearMultipleEditTrafficItems = state => ({
  ...state,
  selectedMultipleEditTrafficItems: []
});

const insertSelectedTrafficItemsStatusChange = (
  state,
  { trafficItemIds = [] }
) => ({
  ...state,
  selectedTrafficItems: [
    ...new Set([...state.selectedTrafficItems, ...trafficItemIds])
  ]
});

const removeSelecteddTrafficItemsStatusChange = (
  state,
  { trafficItemIds = [] }
) => ({
  ...state,
  selectedTrafficItems: without(state.selectedTrafficItems, ...trafficItemIds)
});

const clearSelectedTrafficItemsStatusChange = state => ({
  ...state,
  selectedTrafficItems: []
});

function setLoadedOrders(state, payload) {
  return {
    ...state,
    loadedOrders: payload.loadedOrders
  };
}

function clearLoadedOrders(state) {
  return {
    ...state,
    loadedOrders: []
  };
}

function setSelectedOrder(state, payload) {
  return {
    ...state,
    selectedOrderForTraffic: payload
  };
}

function clearSelectedOrder(state) {
  return {
    ...state,
    selectedOrderForTraffic: null
  };
}

function setTrafficExportVersions(state, payload) {
  return {
    ...state,
    loadedVersions: payload.loadedVersions
  };
}

function resetTrafficExportVersions(state) {
  return {
    ...state,
    loadedVersions: []
  };
}

const setTrafficExportSelectedVersion = (state, payload) => ({
  ...state,
  selectedVersion: payload
});

const clearTrafficExportSelectedVersion = state => ({
  ...state,
  selectedVersion: {}
});

function setTrafficPlanAttachments(state, payload) {
  return {
    ...state,
    loadedAttachments: payload.loadAttachments
  };
}

function clearTrafficPlanAttachments(state) {
  return {
    ...state,
    loadedAttachments: []
  };
}

function addTrafficPlanAttachment(state, payload) {
  return {
    ...state,
    loadedAttachments: [...state.loadedAttachments, payload]
  };
}

function addTrafficPlanSelectedAttachment(state, payload) {
  return {
    ...state,
    selectedAttachments: [...state.selectedAttachments, payload]
  };
}

function removeTrafficPlanSelectedAttachment(state, payload) {
  return {
    ...state,
    selectedAttachments: state.selectedAttachments.filter(id => id !== payload)
  };
}

function deleteTrafficPlanAttachments(state, payload) {
  return {
    ...state,
    loadedAttachments: state.loadedAttachments.filter(
      attachment => !payload.includes(attachment.fileInfoId)
    )
  };
}

function clearTrafficPlanSelectedAttachments(state) {
  return {
    ...state,
    selectedAttachments: []
  };
}

export default createReducer(initialState, {
  [SET_CURRENT_TRAFFIC_PLAN_ID]: setCurrentTrafficPlanId,
  [START_EDITING_TRAFFIC_PLAN]: startEditing,
  [STOP_EDITING_TRAFFIC_PLAN]: stopEditing,
  [DISPLAY_TRAFFIC_PLAN_ERRORS]: displayErrors,
  [HIDE_TRAFFIC_PLAN_ERRORS]: hideErrors,
  [START_EDITING_TRAFFIC_PLAN_REMARK]: startEditingRemark,
  [STOP_EDITING_TRAFFIC_PLAN_REMARK]: stopEditingRemark,
  [CLEAR_CURRENT_TRAFFIC_PLAN]: resetCurrent,
  [SET_ORDER_LIST]: setLoadedOrders,
  [RESET_ORDER_LIST]: clearLoadedOrders,
  [SET_SELECTED_ORDER_TRAFFIC]: setSelectedOrder,
  [RESET_SELECTED_ORDER_TRAFFIC]: clearSelectedOrder,
  [TRAFFIC_PLAN_INSERT_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT]: insertMultipleEditTrafficItems,
  [TRAFFIC_PLAN_REMOVE_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT]: removeMultipleEditTrafficItems,
  [TRAFFIC_PLAN_CLEAR_TRAFFIC_ITEMS_FOR_MULTIPLE_EDIT]: clearMultipleEditTrafficItems,
  [TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_INSERT_SELECTED]: insertSelectedTrafficItemsStatusChange,
  [TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_REMOVE_SELECTED]: removeSelecteddTrafficItemsStatusChange,
  [TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_CLEAR_SELECTED]: clearSelectedTrafficItemsStatusChange,
  [TRAFFIC_PLAN_REMARK_DIALOG_OPEN]: openTrafficPlanRemarkDialog,
  [TRAFFIC_PLAN_REMARK_DIALOG_CLOSE]: closeTrafficPlanRemarkDialog,
  [TRAFFIC_PLAN_PREVIEW_OPEN]: openPreviewDialog,
  [TRAFFIC_PLAN_PREVIEW_CLOSE]: closePreviewDialog,
  [TRAFFIC_PLAN_EMAIL_OPEN]: openTrafficPlanEmailDialog,
  [TRAFFIC_PLAN_EMAIL_CLOSE]: closeTrafficPlanEmailDialog,
  [TRAFFIC_PLAN_SELECTED_USERS_SET]: setSelectedUsers,
  [TRAFFIC_PLAN_SELECTED_USERS_CLEAR]: clearSelectedUsers,
  [TRAFFIC_PLAN_MEDIA_TYPE_ID_SET]: setMediaTypeId,
  [TRAFFIC_PLAN_MEDIA_TYPE_ID_CLEAR]: clearMediaTypeId,
  [TRAFFIC_PLAN_PREVIEW_DATA_SET]: setPreviewData,
  [TRAFFIC_PLAN_PREVIEW_DATA_CLEAR]: clearPreviewData,
  [SET_TRAFFIC_EXPORT_VERSIONS]: setTrafficExportVersions,
  [RESET_TRAFFIC_EXPORT_VERSIONS]: resetTrafficExportVersions,
  [SET_TRAFFIC_EXPORT_SELECTED_VERSION]: setTrafficExportSelectedVersion,
  [RESET_TRAFFIC_EXPORT_SELECTED_VERSION]: clearTrafficExportSelectedVersion,
  [SET_TRAFFIC_PLAN_ATTACHMENTS]: setTrafficPlanAttachments,
  [RESET_TRAFFIC_PLAN_ATTACHMENTS]: clearTrafficPlanAttachments,
  [TRAFFIC_PLAN_ADD_ATTACHMENT_SUCCESS]: addTrafficPlanAttachment,
  [TRAFFIC_PLAN_ADD_SELECTED_ATTACHMENT]: addTrafficPlanSelectedAttachment,
  [TRAFFIC_PLAN_REMOVE_SELECTED_ATTACHMENT]: removeTrafficPlanSelectedAttachment,
  [TRAFFIC_PLAN_CLEAR_SELECTED_ATTACHMENTS]: clearTrafficPlanSelectedAttachments,
  [TRAFFIC_PLAN_DELETE_ATTACHMENT_SUCCESS]: deleteTrafficPlanAttachments
});
