import {
  styled,
  IconButton as MuiIconButton,
  Button as MuiButton
} from "@mui/material";

import { dodgerBlue20 } from "../../../../assets/styles/colors";

const PREFIX = "FilterStyles";
export const classes = {
  clearAll: `${PREFIX}-clearAll`,
  button: `${PREFIX}-button`,
  chip: `${PREFIX}-chip`,
  active: `${PREFIX}-active`
};

export const Div = styled("div")(() => ({
  [`&.${classes.clearAll}`]: {
    borderLeft: "0.1em solid #ebeef2"
  },
  [`& .${classes.button}`]: {
    textTransform: "none",
    fontWeight: 100
  },
  [`& .${classes.active}`]: {
    backgroundColor: dodgerBlue20
  }
}));

export const IconButton = styled(MuiIconButton)(() => ({
  [`&.${classes.active}`]: {
    backgroundColor: dodgerBlue20
  }
}));

export const Button = styled(MuiButton)(() => ({
  [`&.${classes.active}`]: {
    backgroundColor: dodgerBlue20
  },
  [`&.${classes.button}`]: {
    color: "#000000de",
    textTransform: "none",
    fontWeight: 100
  }
}));
