import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { AccordionSummary } from "@mui/material";

import ActionDialogElement from "./ActionDialogElement";
import { classes, Accordion } from "../styles/actionDialogItem";

import { toggleSelected } from "../../../../../business/actions/actionPageActions";
import {
  selectIsSelected,
  selectAction,
  SelectIsStatusSuccessful
} from "../../../../../business/selectors/actionPageSelectors";

const ActionDialogItem = ({ id, isExpanded, order, allowOpen }) => {
  const dispatch = useDispatch();
  const { marathonOrderId } = order;
  const isSelected = useSelector(state => selectIsSelected(state, { id }));
  const action = useSelector(selectAction);
  const statusSuccess = useSelector(state =>
    SelectIsStatusSuccessful(state, {
      marathonOrderId
    })
  );

  const handleCheckboxClick = oid => {
    dispatch(toggleSelected(oid));
  };

  return (
    <Accordion
      key={id}
      square
      elevation={0}
      classes={{ root: classes.expansionPanel, expanded: classes.selected }}
      expanded={isExpanded}
    >
      <AccordionSummary key={id} className={classes.contentWrapper}>
        <ActionDialogElement
          order={order}
          onCheckboxClick={handleCheckboxClick}
          isSelected={isSelected}
          allowOpen={allowOpen}
          id={id}
          key={id}
          statusSuccess={statusSuccess}
          action={action}
        />
      </AccordionSummary>
    </Accordion>
  );
};

ActionDialogItem.propTypes = {
  isExpanded: PropTypes.bool,
  allowOpen: PropTypes.bool,
  order: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ActionDialogItem.defaultProps = {
  isExpanded: false,
  allowOpen: false
};

export default ActionDialogItem;
