import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import some from "lodash/some";
import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";
import {
  GROUPM_AGENCY_ID,
  MINDSHARE_AGENCY_ID,
  KINETIC_AGENCY_ID,
  ESSENCEMEDIACOM_AGENCY_ID,
  WAVEMAKER_AGENCY_ID,
  GREENHOUSEONE_AGENCY_ID,
  BLOSSOM_AGENCY_ID,
  GREENHOUSETECH_AGENCY_ID,
  CHOREOGRAPHCREATE_AGENCY_ID
} from "../../../configurations/appConstants";
import UserModel from "./User";

export const selectUser = state => state.user;
export const selectPropertyName = (_, props) => props.name;
export const selectDefaultDisable = (_, props) => props.disabled;

export const selectCurrentUser = createSelector(
  selectUser,
  users => users.currentUser
);

export const getUsers = createSelector(selectUser, user => user.users);

export const selectIsEditingUser = createSelector(
  selectUser,
  users => users.isEditing
);

export const selectUserDisplayErrors = createSelector(
  selectUser,
  users => users.displayErrors
);

export const selectSearchTerm = createSelector(
  selectUser,
  users => users.searchTerm
);

export const selectSearchProperty = createSelector(
  selectUser,
  users => users.searchProperty
);

export const selectUserIsNew = createSelector(selectUser, user => user.isNew);

export const selectIsActiveUserSwitch = createSelector(
  selectUser,
  user => user.isActiveUserSwitch
);

export const getUsersData = createSelector(
  getEntitiesSession,
  selectSearchProperty,
  selectSearchTerm,
  selectIsActiveUserSwitch,
  (entitiesSession, searchProperty, searchTerm, isActiveUserSwitch) => {
    const { User } = entitiesSession;
    const users = User.all()
      .toRefArray()
      .filter(user => {
        if (searchProperty === "") {
          return true;
        }
        const filterdUser = user[searchProperty];
        if (typeof filterdUser === "boolean") {
          if (filterdUser) {
            return true;
          }
        } else if (typeof filterdUser === "string") {
          return user[searchProperty]
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        }
        return false;
      });
    return isActiveUserSwitch ? users.filter(user => user.isActive) : users;
  }
);

export const hasLoadedUsers = createSelector(
  getEntitiesSession,
  entitiesSession => {
    const { User } = entitiesSession;

    return User.all().count() > 0;
  }
);

export const getEditingUser = createSelector(
  [getEditingEntitiesSession, selectUser],
  (editingSession, user) => {
    const { User } = editingSession;
    const editingUserModel = User.filter(
      editingUser => editingUser.id === user.currentUser
    ).first();

    return editingUserModel ? editingUserModel.ref : undefined;
  }
);

export const getCurrentUser = createSelector(
  [getEditingEntitiesSession, selectCurrentUser],
  (editingSession, userId) => {
    const { User } = editingSession;
    return User.idExists(userId) ? User.withId(userId).ref : undefined;
  }
);

export const getUser = createSelector(
  [getEntitiesSession, selectUser],
  (entitiesSession, user) => {
    const { User } = entitiesSession;
    const userModel = User.get(c => c.id === user.currentUser);

    return userModel ? userModel.ref : undefined;
  }
);

export const getUserPropertyValue = createCachedSelector(
  [getEditingUser, selectPropertyName],
  (user, name) => (user ? user[name] : undefined)
)((_, props) => props.name);

export const getUserPropertyPreviousValue = createCachedSelector(
  [getUser, selectPropertyName],
  (user, name) => (user ? user[name] : undefined)
)((_, props) => props.name);

export const isUserActive = createSelector(
  [getEditingEntitiesSession, selectCurrentUser],
  (editingSession, userId) => {
    const { User } = editingSession;
    return User.idExists(userId) ? User.withId(userId).ref.isActive : false;
  }
);

export const isUserAgent = createSelector(
  [getEditingEntitiesSession, selectCurrentUser],
  (editingSession, userId) => {
    const { User } = editingSession;
    return User.idExists(userId) ? User.withId(userId).ref.isAgent : false;
  }
);

export const isUserAdmin = createSelector(
  [getEditingEntitiesSession, selectCurrentUser],
  (editingSession, userId) => {
    const { User } = editingSession;
    return User.idExists(userId) ? User.withId(userId).ref.isAdmin : false;
  }
);

export const isGroupmAgencySelected = createSelector(
  [getCurrentUser],
  currentUser => {
    return currentUser && currentUser.agentForIds
      ? currentUser.agentForIds.indexOf(GROUPM_AGENCY_ID) >= 0
      : null;
  }
);

export const isKineticAgencySelected = createSelector(
  [getCurrentUser],
  currentUser => {
    return currentUser && currentUser.agentForIds
      ? currentUser.agentForIds.indexOf(KINETIC_AGENCY_ID) >= 0
      : null;
  }
);

export const isMindshareAgencySelected = createSelector(
  [getCurrentUser],
  currentUser => {
    return currentUser && currentUser.agentForIds
      ? currentUser.agentForIds.indexOf(MINDSHARE_AGENCY_ID) >= 0
      : null;
  }
);

export const isMediacomAgencySelected = createSelector(
  [getCurrentUser],
  currentUser => {
    return currentUser && currentUser.agentForIds
      ? currentUser.agentForIds.indexOf(ESSENCEMEDIACOM_AGENCY_ID) >= 0
      : null;
  }
);

export const isWavemakerAgencySelected = createSelector(
  [getCurrentUser],
  currentUser => {
    return currentUser && currentUser.agentForIds
      ? currentUser.agentForIds.indexOf(WAVEMAKER_AGENCY_ID) >= 0
      : null;
  }
);

export const isGreenHouseOneAgencySelected = createSelector(
  [getCurrentUser],
  currentUser => {
    return currentUser && currentUser.agentForIds
      ? currentUser.agentForIds.indexOf(GREENHOUSEONE_AGENCY_ID) >= 0
      : null;
  }
);

export const isGreenHouseTechAgencySelected = createSelector(
  [getCurrentUser],
  currentUser => {
    return currentUser && currentUser.agentForIds
      ? currentUser.agentForIds.indexOf(GREENHOUSETECH_AGENCY_ID) >= 0
      : null;
  }
);

export const isChoreographCreateAgencySelected = createSelector(
  [getCurrentUser],
  currentUser => {
    return currentUser && currentUser.agentForIds
      ? currentUser.agentForIds.indexOf(CHOREOGRAPHCREATE_AGENCY_ID) >= 0
      : null;
  }
);

export const isBlossomAgencySelected = createSelector(
  [getCurrentUser],
  currentUser => {
    return currentUser && currentUser.agentForIds
      ? currentUser.agentForIds.indexOf(BLOSSOM_AGENCY_ID) >= 0
      : null;
  }
);

export const selectRules = createSelector(
  [
    isUserActive,
    isUserAdmin,
    isUserAgent,
    isGroupmAgencySelected,
    isKineticAgencySelected,
    isMindshareAgencySelected,
    isMediacomAgencySelected,
    isWavemakerAgencySelected,
    isBlossomAgencySelected,
    isGreenHouseOneAgencySelected,
    isGreenHouseTechAgencySelected,
    isChoreographCreateAgencySelected
  ],
  (
    isActive,
    isAdmin,
    isAgent,
    isGroupmSelected,
    isKineticSelected,
    isMindshareSelected,
    isMediacomSelected,
    isWavemakerSelected,
    isBlossomSelected,
    isGreenHouseOneSelected,
    isGreenHouseTechSelected,
    isChoreographCreateSelected
  ) => {
    return {
      isUserNotActive: !isActive,
      isUserAdmin: isAdmin,
      isUserNotAgent: !isAgent,
      isGroupmNotSelected: !isGroupmSelected,
      isKineticNotSelected: !isKineticSelected,
      isMindshareNotSelected: !isMindshareSelected,
      isMediacomNotSelected: !isMediacomSelected,
      isWavemakerNotSelected: !isWavemakerSelected,
      isBlossomNotSelected: !isBlossomSelected,
      isGreenHouseOneNotSelected: !isGreenHouseOneSelected,
      isGreenHouseTechNotSelected: !isGreenHouseTechSelected,
      isChoreographCreateNotSelected: !isChoreographCreateSelected
    };
  }
);

export const isDisabled = createCachedSelector(
  [selectRules, selectPropertyName],
  (rules, name) => {
    const configuration = UserModel.disabledFields;
    const isDisabledProperty = some(configuration[name], c => rules[c]);
    return isDisabledProperty;
  }
)((_, props) => props.name);

export const getExcludedFieldsFromValidation = createSelector(
  [selectRules],
  rules => {
    const restrictions = UserModel.validationRestrictions;

    const keys = Object.keys(restrictions);
    const exclude = [];

    keys.forEach(key => {
      if (some(restrictions[key], r => rules[r])) {
        exclude.push(key);
      }
    });

    return exclude;
  }
);

export const getEditingUserErrors = createSelector(
  [getEditingEntitiesSession, selectUser, getExcludedFieldsFromValidation],
  (editingSession, user, excludedFields) => {
    const { User } = editingSession;
    const userModel = User.filter(c => c.id === user.currentUser).first();
    return userModel ? userModel.validate(excludedFields) : undefined;
  }
);

export const doesUserHaveErrors = createSelector(
  [getEditingEntitiesSession, selectUser, getExcludedFieldsFromValidation],
  (editingSession, user, excludedFields) => {
    const { User } = editingSession;
    const userModel = User.get(c => c.id === user.currentUser);
    return userModel ? userModel.hasErrors(excludedFields) : false;
  }
);

export const getUserPropertyErrorsByProperty = createCachedSelector(
  [
    getEditingEntitiesSession,
    selectUser,
    selectPropertyName,
    getExcludedFieldsFromValidation
  ],
  (editingSession, user, name, excludedFields) => {
    const { User } = editingSession;
    const userModel = User.get(c => c.id === user.currentUser);
    const isExcluded = excludedFields.includes(name);
    return userModel
      ? userModel.validateByProperty(name, isExcluded)
      : undefined;
  }
)((_, props) => props.name);

export const getAgentForIdsOptions = createSelector(
  [selectUser, getCurrentUser],
  (reducer, currentUser) => {
    if (!currentUser) {
      return reducer.agentForIdsOptions;
    }

    const newAgentForIdsOptions = reducer.agentForIdsOptions.filter(
      f => f.key !== currentUser.agencyId
    );

    return newAgentForIdsOptions;
  }
);
