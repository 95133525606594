import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";

import PlanExtensionModel from "./PlanExtension";

import { selectAgency } from "../../selectors/applicationSelectors";

import { getEditingEntitiesSession } from "../common/editing/editingSelectors";
import { getEntitiesSession } from "../common/entities/entitySelectors";

import { getApiData } from "../../selectors/apiDataSelectors";

import {
  getQueryObj,
  generateParamObj,
  getHash
} from "../common/utils/modelUtils";
import generateUrl from "../common/utils/urlUtils";

import { selectCurrentPlan } from "../Plan/planSelectors";
import { isNullOrUndefinedOrEmpty } from "../../../functions/util";

export const selectPlanExtension = state => state.planExtension;
export const selectPropertyName = (_, props) => props.name;
export const selectPlanId = (_, props) => props.planId;

export const selectCurrentPlanExtension = createSelector(
  selectPlanExtension,
  planExtension => planExtension.currentPlanExtension
);

export const selectIsEditingPlanExtension = createSelector(
  selectPlanExtension,
  planExtension => planExtension.isEditing
);

export const selectPlanExtensionIsNew = createSelector(
  selectPlanExtension,
  planExtension => planExtension.isNew
);

export const selectIsPlanExtensionNewFromUpdate = createSelector(
  selectPlanExtension,
  planExtension => planExtension.isNewFromUpdate
);

export const getEditingPlanExtension = createSelector(
  [getEditingEntitiesSession, selectPlanExtension],
  (editingSession, planExtension) => {
    const { PlanExtension } = editingSession;
    const planExtensionModel = PlanExtension.get(
      c => c.id === planExtension.currentPlanExtension
    );

    return planExtensionModel
      ? {
          ...planExtensionModel.plan.campaign.ref,
          ...planExtensionModel.plan.ref,
          ...planExtensionModel.ref
        }
      : undefined;
  }
);

export const getPlanExtension = createSelector(
  [getEntitiesSession, selectPlanExtension],
  (entitiesSession, planExtension) => {
    const { PlanExtension } = entitiesSession;
    const planExtensionModel = PlanExtension.get(
      c => c.id === planExtension.currentPlanExtension
    );

    return planExtensionModel ? planExtensionModel.ref : undefined;
  }
);

export const getPlanExtensionPropertyValue = createCachedSelector(
  [getEditingPlanExtension, selectPropertyName],
  (planExtension, name) => (planExtension ? planExtension[name] : undefined)
)((state, props) => props.name);

export const getPlanExtensionPropertyPreviousValue = createCachedSelector(
  [getPlanExtension, selectPropertyName],
  (planExtension, name) => (planExtension ? planExtension[name] : undefined)
)((state, props) => props.name);

export const getPlanExtensionPropertyErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectPlanExtension, selectPropertyName],
  (editingSession, planExtension, name) => {
    const { PlanExtension } = editingSession;
    const planExtensionModel = PlanExtension.get(
      c => c.id === planExtension.currentPlanExtension
    );
    return planExtensionModel
      ? planExtensionModel.validateByProperty(name)
      : undefined;
  }
)((state, props) => props.name);

export const selectPlanExtensionDisplayErrors = createSelector(
  selectPlanExtension,
  planExtension => planExtension.displayErrors
);

export const getPlanExtensionPropertyOptions = createCachedSelector(
  [getApiData, getEditingPlanExtension, selectPropertyName, selectAgency],
  (apiData, planExtension, name, agencyID) => {
    const configuration = PlanExtensionModel.apiConfiguration[name];
    if (configuration) {
      const queryObj = getQueryObj(
        configuration.urlParams,
        configuration.urlRequiredParams,
        planExtension
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(configuration.url, paramObj, queryObj);
        return apiData[getHash(url)];
      }
    }
    return undefined;
  }
)((state, props) => props.name);

export const selectCurrentPlanExtensionByPlanId = createSelector(
  [getEntitiesSession, selectCurrentPlan],
  (entitiesSession, planId) => {
    const { PlanExtension } = entitiesSession;
    const planExtensionModel = PlanExtension.get(c => c.plan === planId);

    return planExtensionModel ? planExtensionModel.ref : undefined;
  }
);

export const doesPlanExtensionHaveErrors = createSelector(
  [getEditingEntitiesSession, selectPlanExtension],
  (editingSession, planExtension) => {
    const { PlanExtension } = editingSession;
    const planExtensionModel = PlanExtension.get(
      c => c.id === planExtension.currentPlanExtension
    );
    return planExtensionModel ? planExtensionModel.hasErrors() : false;
  }
);

export const getPlanExtensionForSave = createSelector(
  [getEditingEntitiesSession, selectPlanExtension],
  (editingSession, planExtension) => {
    const { PlanExtension } = editingSession;
    const planExtensioModel = PlanExtension.get(
      c => c.id === planExtension.currentPlanExtension
    );
    return planExtensioModel ? planExtensioModel.ref : undefined;
  }
);

export const selectIsFieldsEmpty = createSelector(
  [getEditingEntitiesSession, selectPlanExtension],
  (editingSession, planExtension) => {
    const { PlanExtension } = editingSession;
    const planExtensioModel = PlanExtension.get(
      c => c.id === planExtension.currentPlanExtension
    );

    return planExtensioModel
      ? isNullOrUndefinedOrEmpty(planExtensioModel.planExtensionRemarks) &&
          isNullOrUndefinedOrEmpty(planExtensioModel.communicationObjectives) &&
          isNullOrUndefinedOrEmpty(planExtensioModel.campaignObjectives)
      : true;
  }
);

export const getPlanExtensionByPlanId = createSelector(
  [getEntitiesSession, selectPlanId],
  (entitiesSession, planId) => {
    const { PlanExtension } = entitiesSession;
    const planExtensionModel = PlanExtension.get(pe => pe.planId === planId);

    return planExtensionModel ? planExtensionModel.ref : undefined;
  }
);
