import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "@mui/material";

import { loadTabcounters } from "../../../../business/actions/trafficPageActions";
import { selectTabCounters } from "../../../../business/selectors/trafficPageSelectors";

import {
  TRAFFIC_OVERVEIW_PAGE,
  TRAFFIC_CAMPAIGN_NEW_STATUS,
  TRAFFIC_CAMPAIGN_ASSIGNED_STATUS,
  TRAFFIC_CAMPAIGN_ARCHIVED_STATUS
} from "../../../../configurations/appConstants";
import withRouter from "../../configuration/form/withRouter";

// import { selectAgency } from "../../../../business/selectors/applicationSelectors";

class TrafficCampaignStatusActions extends Component {
  componentDidMount() {
    this.props.loadTabcounters();
  }

  handleChange = (event, value) => {
    const { params, navigate } = this.props;
    const { trafficCampaignStatus, agency } = params;

    if (value !== trafficCampaignStatus) {
      navigate(
        `/${agency}/${TRAFFIC_OVERVEIW_PAGE}/${value}?searchProperty=PlanId`
      );
    }
  };

  render() {
    const { params, tabCounters } = this.props;
    const { trafficCampaignStatus } = params;
    const { newCount, assignedCount, archivedCount } = tabCounters;

    return (
      <Tabs
        value={trafficCampaignStatus}
        onChange={this.handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          label={`New (${newCount})`}
          value={TRAFFIC_CAMPAIGN_NEW_STATUS.toLowerCase()}
          id="new"
          name="new"
        />
        <Tab
          label={`Assigned (${assignedCount})`}
          value={TRAFFIC_CAMPAIGN_ASSIGNED_STATUS.toLowerCase()}
          id="assigned"
          name="assigned"
        />
        <Tab
          label={`Archived (${archivedCount})`}
          value={TRAFFIC_CAMPAIGN_ARCHIVED_STATUS.toLowerCase()}
          id="archived"
          name="archived"
        />
      </Tabs>
    );
  }
}

const mapStateToProps = state => {
  return {
    // agencyID: selectAgency(state),
    tabCounters: selectTabCounters(state)
  };
};

const mapDispatchToProps = dispatch => ({
  loadTabcounters: () => dispatch(loadTabcounters())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrafficCampaignStatusActions)
);
