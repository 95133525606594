import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";

import { Button } from "@mui/material";

import { CREATE_PAGE } from "../../../../configurations/appConstants";

import { generateUrlPath } from "../../../../business/models/common/utils/clientUtils";

import { toggleInsertionPopup } from "../../../../business/actions/applicationActions";
import {
  createOrder,
  updateOrder
} from "../../../../business/models/Order/orderActions";
import {
  inheritInsertionPoNumberFromOrder,
  resetInsertions
} from "../../../../business/models/Insertion/insertionActions";
import { closeOrderFormPage } from "../../../../business/actions/campaignDashboardActions";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import { selectCurrentCampaign } from "../../../../business/models/Campaign/campaignSelectors";
import { selectCurrentPlan } from "../../../../business/models/Plan/planSelectors";
import {
  isOrderCancelledOrToBeCancelled,
  isOrderPendingForApproval,
  selectOrderIsNew
} from "../../../../business/models/Order/orderSelectors";
import { classes, Grid } from "../styles/insertionActions";

const InsertionActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agency } = useParams();

  const isCancelledOrToBeCancelled = useSelector(
    isOrderCancelledOrToBeCancelled
  );
  const isPendingForApproval = useSelector(isOrderPendingForApproval);

  const isLoading = useSelector(selectIsLoading);
  const isOrderNew = useSelector(selectOrderIsNew);
  const campaignId = useSelector(selectCurrentCampaign);
  const planId = useSelector(selectCurrentPlan);
  const disableSave = isCancelledOrToBeCancelled || isPendingForApproval;

  const handleCancel = () => {
    dispatch(resetInsertions());
    dispatch(inheritInsertionPoNumberFromOrder());
    dispatch(toggleInsertionPopup());
  };

  const handleSave = () => {
    // TODO: refactor this
    if (isOrderNew) {
      dispatch(createOrder()).then(orderId => {
        if (orderId) {
          dispatch(toggleInsertionPopup());
          navigate(
            `/${agency}/${CREATE_PAGE}${generateUrlPath(
              campaignId,
              planId,
              orderId
            )}`
          );
          dispatch(closeOrderFormPage());
        }
      });
    } else {
      dispatch(updateOrder()).then(orderId => {
        if (orderId) {
          dispatch(toggleInsertionPopup());
          dispatch(closeOrderFormPage());
        }
      });
    }
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Button
          className={classes.cancelButton}
          onClick={handleCancel}
          disabled={isLoading}
        >
          CANCEL
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          className={clsx(classes.saveButton, classes.markedButton)}
          disabled={isLoading || disableSave}
          onClick={handleSave}
        >
          SAVE ORDER AND INSERTIONS
        </Button>
      </Grid>
    </Grid>
  );
};

export default InsertionActions;
