import { styled, FormControl as MuiFormControl } from "@mui/material";

const PREFIX = "SaveSearchStyles";
export const classes = {
  content: `${PREFIX}-content`
};

export const FormControl = styled(MuiFormControl)(({ theme }) => ({
  [`&.${classes.content}`]: {
    height: theme.typography.pxToRem(10),
    minWidth: theme.typography.pxToRem(200),
    zIndex: 1
  }
}));
