import {
  styled,
  IconButton as MuiIconButton,
  Popper as MuiPopper
} from "@mui/material";

import { jordyBlue, lavender } from "../../../../assets/styles/colors";

const PREFIX = "NotificationListMenuStyles";
export const classes = {
  button: `${PREFIX}-button`,
  popper: `${PREFIX}-popper`,
  paper: `${PREFIX}-paper`,
  paperEmptyNotification: `${PREFIX}-paperEmptyNotification`
};

export const Popper = styled(MuiPopper)(({ theme }) => ({
  [`&.${classes.popper}`]: {
    position: "relative",
    zIndex: 2
  },
  [`& .${classes.paper}`]: {
    background: "transparent"
  },
  [`& .${classes.paperEmptyNotification}`]: {
    width: theme.typography.pxToRem(210),
    height: theme.typography.pxToRem(120),
    background: lavender
  }
}));

export const IconButton = styled(MuiIconButton)(() => ({
  [`&.${classes.button}`]: {
    color: jordyBlue
  }
}));
