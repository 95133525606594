import { createReducer } from "../models/common/utils/reducerUtils";
import {
  SET_API_MANAGEMENT_ITEMS,
  SET_API_MANAGEMENT_COUNT,
  SET_IS_OPEN_DELETE_PUBLIC_PERMISSION_DIALOG,
  SET_SELECTED_PUBLIC_PERMISSION,
  SET_IS_OPEN_ADD_PUBLIC_PERMISSION_DIALOG
} from "../constants/apiManagementConstants";

const initialState = {
  isOpenAddPublicPermissionDialog: false,
  isOpenDeletePublicPermissionDialog: false,
  selectedPublicPermission: {
    applicationId: null,
    resource: null
  },
  table: {
    count: 0,
    items: []
  }
};

export const setCount = (state, payload) => ({
  ...state,
  table: { ...state.table, count: payload }
});

export const setItems = (state, payload) => ({
  ...state,
  table: { ...state.table, items: payload }
});

export const setOpenDeletePublicPermissionDialog = state => ({
  ...state,
  isOpenDeletePublicPermissionDialog: !state.isOpenDeletePublicPermissionDialog
});

export const setPublicPermission = (state, payload) => ({
  ...state,
  selectedPublicPermission: {
    applicationId: payload.appId,
    resource: payload.res
  }
});

export const setOpenAddPublicPermissionDialog = state => ({
  ...state,
  isOpenAddPublicPermissionDialog: !state.isOpenAddPublicPermissionDialog
});

export default createReducer(initialState, {
  [SET_API_MANAGEMENT_ITEMS]: setItems,
  [SET_API_MANAGEMENT_COUNT]: setCount,
  [SET_IS_OPEN_ADD_PUBLIC_PERMISSION_DIALOG]: setOpenAddPublicPermissionDialog,
  [SET_IS_OPEN_DELETE_PUBLIC_PERMISSION_DIALOG]: setOpenDeletePublicPermissionDialog,
  [SET_SELECTED_PUBLIC_PERMISSION]: setPublicPermission
});
