import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  DialogContent,
  Typography,
  DialogActions
} from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import { closeAdmanagementDialog } from "../../../../business/actions/trafficDashboardPageActions";
import { selectOpenAdmanagementDialog } from "../../../../business/selectors/trafficDashboardPageSelectors";

import { classes, Dialog } from "../styles/admanagementDialog";
import { saveTrafficPlan } from "../../../../business/models/TrafficPlan/trafficPlanActions";

const AdmanagementDialog = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectOpenAdmanagementDialog);

  const save = () => dispatch(saveTrafficPlan());
  const closeDialog = () => dispatch(closeAdmanagementDialog());

  const handleContinue = () => {
    save().then(closeDialog());
  };

  return (
    <Dialog
      aria-labelledby="admanagement-dialog-dialog"
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="admanagement-dialog-dialog-title">Warning</DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          The admanagement orderID is not filled. Please make sure that no
          admanagement costs are involved for this plan. If there are
          admanagement costs involved, please create an order to hold those
          costs and make sure that the OrderID is filled on the Traffic page.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          id="close_admanagment-save"
          name="close_admanagment-save"
          onClick={closeDialog}
          className={classes.buttonClose}
        >
          Close
        </Button>
        <Button
          id="continue_admanagment-save"
          name="continue_admanagment-save"
          onClick={handleContinue}
          className={classes.buttonContinue}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdmanagementDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object
};

export default AdmanagementDialog;
