import { styled, ListItemButton as MuiListItemButton } from "@mui/material";
import { dodgerBlue, baliHai, white } from "../../../../assets/styles/colors";

const PREFIX = "SideBarItemStyles";
export const classes = {
  drawerItem: `${PREFIX}-drawerItem`,
  drawerSelectedItemIcon: `${PREFIX}-drawerSelectedItemIcon`,
  drawerItemIcon: `${PREFIX}-drawerItemIcon`,
  drawerSelectedItemText: `${PREFIX}-drawerSelectedItemText`,
  drawerItemText: `${PREFIX}-drawerItemText`
};

export const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  [`&.${classes.drawerItem}`]: {
    paddingLeft: theme.typography.pxToRem(40),
    color: baliHai
  },
  [`& .${classes.drawerSelectedItemIcon}`]: {
    color: dodgerBlue
  },
  [`& .${classes.drawerItemIcon}`]: {
    color: baliHai
  },
  [`& .${classes.drawerSelectedItemText}`]: {
    color: white,
    paddingLeft: theme.typography.pxToRem(30)
  },
  [`& .${classes.drawerItemText}`]: {
    color: baliHai,
    paddingLeft: theme.typography.pxToRem(30)
  }
}));
