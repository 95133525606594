import { styled, AppBar as MuiAppBar } from "@mui/material";
import { getThemeSpacing } from "../../../../functions/util";

import {
  SIDE_BAR_OPEN_WIDTH,
  SIDE_BAR_CLOSE_WIDTH,
  APP_BAR_HEIGHT
} from "../../../../configurations/appConstants";
import { solitude } from "../../../../assets/styles/colors";

const PREFIX = "AppBarWrapperStyles";
export const classes = {
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  appBarBottom: `${PREFIX}-appBarBottom`,
  appToolbar: `${PREFIX}-appToolbar`
};

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  [`&.${classes.appBar}`]: {
    background: theme.palette.common.white,
    borderStyle: "solid",
    borderColor: solitude,
    borderWidth: "0.5px",
    maxHeight: theme.typography.pxToRem(APP_BAR_HEIGHT),
    width: `calc(100% - ${theme.typography.pxToRem(SIDE_BAR_CLOSE_WIDTH)})`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  [`&.${classes.appBarShift}`]: {
    width: `calc(100% - ${theme.typography.pxToRem(SIDE_BAR_OPEN_WIDTH)})`
  },
  [`&.${classes.appBarBottom}`]: {
    top: "auto",
    bottom: 0
  },
  [`& .${classes.appToolbar}`]: {
    justifyContent: "space-between",
    paddingLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    paddingRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  }
}));

export const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);
