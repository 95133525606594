import React, { memo, useState } from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import { EMPTY_STRING } from "../../../configurations/appConstants";
import ChipEdit from "./chips/ChipEdit";
import ChipAdd from "./chips/ChipAdd";
import Filter from "./Filter";

const DynamicFilter = ({ updateUrl, queryFilters, filterOptions, limit }) => {
  let filters = [];
  if (Array.isArray(filterOptions) && filterOptions.length) {
    filters = filterOptions;
  }
  const cleanQuery = Object.keys(queryFilters).filter(
    key => queryFilters[key] !== undefined
  );

  const formatFiltersFromQuery = () => {
    return cleanQuery.map(key => ({
      ...filters.find(o => o.property === key),
      value: queryFilters[key]
    }));
  };

  const queryIds = filters
    .filter(o => cleanQuery.some(q => q === o.property))
    .map(i => i.id);

  const getOptionTextByProperty = prop =>
    filters.find(option => option.property === prop)?.text;

  const getOptionPropertyById = id =>
    filters.find(option => option.id === id)?.property;

  const limited = limit
    ? Object.keys(cleanQuery).length >= limit
    : Object.keys(cleanQuery).length >= filterOptions.length;

  const [newFilter, setNewFilter] = useState(!limited);

  const showNewFilter = () => {
    if (!limited) setNewFilter(true);
  };
  const hideNewFilter = () => {
    setNewFilter(false);
  };

  const addFilter = filter => {
    // This is needed when updating existing filter property, remove old property from url and add new one
    let oldProperty = null;
    if (queryIds.includes(filter.id)) {
      oldProperty = getOptionPropertyById(filter.id);
    }
    updateUrl({
      ...(oldProperty && { [oldProperty]: undefined }),
      [filter.property]: filter.value
    });
  };

  const removeFilter = property => {
    updateUrl({ [property]: undefined });
  };

  const getOptions = () =>
    filters.filter(
      option => !cleanQuery.some(property => option.property === property)
    );

  const currentOptions = getOptions();

  const appendSelectedOption = selectedOption =>
    currentOptions.concat(selectedOption);

  return (
    <Grid container alignItems="center" spacing={1}>
      {formatFiltersFromQuery().map(({ property, value, id }) => {
        const text = getOptionTextByProperty(property);
        const options = appendSelectedOption({ property, text, id });

        return (
          <Filter
            name={`dinamycFilter_${id}`}
            id={`dinamycFilter_${id}`}
            key={property}
            value={value}
            property={property}
            addFilter={addFilter}
            removeFilter={removeFilter}
            options={options}
            optionText={text}
          />
        );
      })}
      {newFilter && (
        <Grid item>
          <ChipEdit
            name="edit_Filter"
            id="edit_Filter"
            commit={addFilter}
            options={currentOptions}
            cancelEdit={hideNewFilter}
          />
        </Grid>
      )}
      {!limited && (
        <Grid item>
          <ChipAdd name="add_filter" id="add_filter" add={showNewFilter} />
        </Grid>
      )}
    </Grid>
  );
};

DynamicFilter.propTypes = {
  updateUrl: PropTypes.func.isRequired,
  queryFilters: PropTypes.object.isRequired,
  limit: PropTypes.number
};

DynamicFilter.defaultProps = {
  updateUrl: EMPTY_STRING,
  queryFilters: {}
};

export default memo(DynamicFilter);
