import { styled, List as MuiList } from "@mui/material";

const PREFIX = "InsertionStyles";
export const classes = {
  table: `${PREFIX}-table`,
  tableRow: `${PREFIX}-tableRow`
};

export const List = styled(MuiList)(() => ({
  [`&.${classes.table}`]: {
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0
  },
  [`& .${classes.tableRow}`]: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));
