import React from "react";

import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";

import { classes, Grid } from "../styles/trafficTemplateManagerActions";
import TrafficTemplateField from "../../../form/TrafficTemplateField";
import {
  ADD_TEXT,
  EDIT_TEXT
} from "../../../../business/models/PlanTargetMetricsKpi/planTargetMetricsKpiConstants";

const ManageTrafficTemplateDialog = ({
  open,
  onApply,
  onCancel,
  isNewTemplate
}) => {
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle id="template-dialog-title">
        {isNewTemplate ? ADD_TEXT : EDIT_TEXT} Digital Traffic Template
      </DialogTitle>
      <DialogContent id="template-dialog-content">
        <TrafficTemplateField
          component="text"
          name="templateName"
          id="templateName"
          label="Template Name *"
        />
        <TrafficTemplateField
          component="text"
          name="description"
          id="description"
          label="Description"
        />
        <TrafficTemplateField
          component="autocomplete"
          name="dimensions"
          id="dimensions"
          label="Dimensions"
        />
        <TrafficTemplateField
          component="text"
          name="weight"
          id="weight"
          label="Weight"
        />
        <TrafficTemplateField
          component="text"
          name="creativeType"
          id="creativeType"
          label="Creative Type"
        />
        <TrafficTemplateField
          component="text"
          name="animationLooping"
          id="animationLooping"
          label="Animation/Looping"
        />
        <TrafficTemplateField
          component="text"
          name="framerate"
          id="framerate"
          label="Framerate"
        />
        <TrafficTemplateField
          component="autocomplete"
          name="adServer"
          id="adServer"
          label="AdServer *"
        />
        <TrafficTemplateField
          component="text"
          name="contact"
          id="contact"
          label="Contact"
        />
        <TrafficTemplateField
          component="text"
          name="remarks"
          id="remarks"
          label="Remarks"
        />
      </DialogContent>
      <DialogActions id="action-dialog-actions">
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              onClick={onCancel}
              className={classes.cancelButton}
              id="tt_cancel_dialog"
              name="tt_cancel_dialog"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onApply}
              variant="contained"
              id="tt_apply_dialog"
              name="tt_apply_dialog"
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ManageTrafficTemplateDialog;
