import { styled, Grid as MuiGrid } from "@mui/material";

import {
  jordyBlue,
  dodgerBlue,
  linkWater
} from "../../../assets/styles/colors";

const PREFIX = "CtcStyles";
export const classes = {
  wrapper: `${PREFIX}-wrapper`,
  ctcButton: `${PREFIX}-ctcButton`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  ctcIconButton: `${PREFIX}-ctcIconButton`,
  ctcProperty: `${PREFIX}-ctcProperty`,
  insertionIconButton: `${PREFIX}-insertionIconButton`,
  infoPlanWithInsertionChange: `${PREFIX}-infoPlanWithInsertionChange`
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.wrapper}`]: {
    position: "relative"
  },
  [`& .${classes.ctcButton}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14),
    "&:hover": {
      color: dodgerBlue
    }
  },
  [`& .${classes.buttonProgress}`]: {
    color: dodgerBlue,
    position: "absolute",
    top: "50%",
    marginTop: theme.typography.pxToRem(-5),
    marginLeft: theme.typography.pxToRem(5)
  },
  [`& .${classes.ctcIconButton}`]: {
    width: "1.5rem",
    height: "1.5rem",
    color: jordyBlue
  },
  [`& .${classes.infoPlanWithInsertionChange}`]: {
    width: "1.5rem",
    height: "1.5rem",
    color: jordyBlue,
    top: "-2px"
  }
}));

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.ctcProperty}`]: {
    color: theme.palette.common.white,
    display: "inline-block"
  }
}));
