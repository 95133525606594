/* eslint-disable import/no-cycle */
import cuid from "cuid";
import get from "lodash/get";
import set from "lodash/set";

import generateUrl from "../common/utils/urlUtils";
import { selectAgency } from "../../selectors/applicationSelectors";

import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import {
  editNewItem,
  editExistingItem,
  stopEditingItem,
  editItemAttributes,
  commitData
} from "../common/editing/editingActions";

import {
  PLAN_EXTENSION_MODEL_NAME,
  PLAN_EXTENSION_SELECT,
  PLAN_EXTENSION_CLEAR_CURRENT,
  PLAN_EXTENSION_EDIT_START,
  PLAN_EXTENSION_EDIT_STOP,
  PLAN_EXTENSION_HIDE_ERRORS,
  PLAN_EXTENSION_SET_IS_NEW,
  PLAN_EXTENSION_RESET_CURRENT,
  PLAN_EXTENSION_DISPLAY_ERRORS,
  PLAN_EXTENSION_MODEL_ID,
  PLAN_EXTENSION_SUCCESS_MESSAGE,
  PLAN_EXTENSION_SET_IS_NEW_FROM_UPDATE
} from "./planExtensionConstants";
import {
  selectIsEditingPlanExtension,
  selectCurrentPlanExtension,
  selectPlanExtensionIsNew,
  selectCurrentPlanExtensionByPlanId,
  doesPlanExtensionHaveErrors,
  getPlanExtensionForSave,
  getPlanExtensionByPlanId
} from "./planExtensionSelectors";

import {
  clearCurrentPlanTargetMetricKpis,
  selectPlanTargetMetricsKpis,
  startEditingPlanTargetMetricsKpis,
  stopEditingPlanTargetMetricsKpis,
  deletePlanTargetMetricsKpis
} from "../PlanTargetMetricsKpi/planTargetMetricsKpiActions";

import { selectEditingPlanTargetMetricsKpiData } from "../PlanTargetMetricsKpi/planTargetMetricsKpiSelectors";

import {
  clearCurrentPlanChangeOverviews,
  selectPlanChangeOverviews,
  startEditingPlanChangeOverviews,
  stopEditingPlanChangeOverviews,
  deletePlanChangeOverviews
} from "../PlanChangeOverview/planChangeOverviewActions";

import { selectEditingPlanChangeOverviewData } from "../PlanChangeOverview/planChangeOverviewSelectors";

import {
  startEditingCampaign,
  stopEditingCampaign
} from "../Campaign/campaignActions";
import { startEditingPlan, stopEditingPlan } from "../Plan/planActions";
import { selectCurrentPlan } from "../Plan/planSelectors";
import { PLAN_MODEL_ID } from "../Plan/planConstants";

import {
  CREATE_PLAN_EXTENSION,
  UPDATE_PLAN_EXTENSION,
  CREATE_PLAN_EXTENSION_COPY_PLAN
} from "../../../configurations/apiUrls";
import { POST, PUT } from "../../constants/applicationConstants";
import {
  enqueueApiErrorMessage,
  enqueueNotificationMessage,
  apiCall
} from "../../actions/applicationActions";
import {
  setOrderSteps,
  setActiveStep,
  setCurrentOrderStep
} from "../../actions/copyPlanActions";
import { selectProcess } from "../../selectors/copyPlanSelectors";
import { PROCESS_MODEL_ID } from "../../constants/copyPlanConstants";
import { isNullOrUndefinedOrEmpty } from "../../../functions/util";
import { ORDER_MODEL_ID, ORDER_MODEL_NAME } from "../Order/orderConstants";
import { selectOrder } from "../Order/orderActions";

export const stopEditingPlanExtension = () => {
  return (dispatch, getState) => {
    const state = getState();
    const currentPlanExtension = selectCurrentPlanExtension(state);
    dispatch(stopEditingItem(PLAN_EXTENSION_MODEL_NAME, currentPlanExtension));
    dispatch({ type: PLAN_EXTENSION_EDIT_STOP });
    dispatch({ type: PLAN_EXTENSION_HIDE_ERRORS });
  };
};

export const clearCurrentPlanExtension = () => dispatch =>
  dispatch({ type: PLAN_EXTENSION_CLEAR_CURRENT });

export function clearPlanExtension() {
  return dispatch => {
    dispatch(clearCurrentPlanExtension());
    dispatch(clearCurrentPlanTargetMetricKpis());
    dispatch(clearCurrentPlanChangeOverviews());
  };
}

export const selectPlanExtension = (
  planExtensionId,
  copyProcessProps = { isInCopyProcess: false, sourcePlanExtensionId: null }
) => {
  return (dispatch, getState) => {
    const state = getState();
    const id = planExtensionId;
    const isEditing = selectIsEditingPlanExtension(state);

    if (isEditing) {
      dispatch(stopEditingPlanChangeOverviews());
      dispatch(stopEditingPlanTargetMetricsKpis());
      dispatch(stopEditingPlanExtension());
      dispatch(stopEditingPlan());
      dispatch(stopEditingCampaign());
    }

    dispatch(clearPlanExtension());
    dispatch({
      type: PLAN_EXTENSION_SELECT,
      payload: {
        currentPlanExtension: id
      }
    });

    dispatch(selectPlanTargetMetricsKpis(copyProcessProps));
    dispatch(selectPlanChangeOverviews(copyProcessProps));
  };
};

export const addNewPlanExtension = () => {
  return (dispatch, getState) => {
    const state = getState();
    const session = getUnsharedEntitiesSession(state);
    const planId = selectCurrentPlan(state);

    const { PlanExtension } = session;

    const id = cuid();
    const newPlanExtension = PlanExtension.generate({
      id,
      plan: planId
    });

    const planExtensionsContents = newPlanExtension.toJSON();

    dispatch(startEditingCampaign());
    dispatch(startEditingPlan());
    dispatch(
      editNewItem(PLAN_EXTENSION_MODEL_NAME, id, planExtensionsContents)
    );
    dispatch(selectPlanExtension(id));
    dispatch({ type: PLAN_EXTENSION_SET_IS_NEW });
    dispatch({ type: PLAN_EXTENSION_EDIT_START });
  };
};

export const copyPlanExtension = () => {
  return (dispatch, getState) => {
    const state = getState();
    const planId = selectCurrentPlan(state);
    const session = getUnsharedEntitiesSession(state);

    const { PlanExtension } = session;
    const planExtensionData = getPlanExtensionByPlanId(state, { planId });

    const id = cuid();
    const newPlanExtension = PlanExtension.generate({
      ...planExtensionData,
      id,
      [PLAN_EXTENSION_MODEL_ID]: id,
      plan: planId,
      [PLAN_MODEL_ID]: planId
    });

    const planExtensionsContents = newPlanExtension.toJSON();

    dispatch(startEditingCampaign());
    dispatch(startEditingPlan());
    dispatch(
      editNewItem(PLAN_EXTENSION_MODEL_NAME, id, planExtensionsContents)
    );
    dispatch(
      selectPlanExtension(id, {
        isInCopyProcess: true,
        sourcePlanExtensionId: planExtensionData?.id
      })
    );
    dispatch({ type: PLAN_EXTENSION_SET_IS_NEW });
    dispatch({ type: PLAN_EXTENSION_EDIT_START });

    if (!isNullOrUndefinedOrEmpty(planExtensionData)) {
      dispatch(startEditingPlanTargetMetricsKpis());
      dispatch(startEditingPlanChangeOverviews());
    }
  };
};

export const startEditingPlanExtension = () => {
  return (dispatch, getState) => {
    const state = getState();
    const currentPlanExtension = selectCurrentPlanExtension(state);
    dispatch(editExistingItem(PLAN_EXTENSION_MODEL_NAME, currentPlanExtension));
    dispatch({ type: PLAN_EXTENSION_EDIT_START });
  };
};

export const editPlanExtension = () => (dispatch, getState) => {
  const state = getState();
  const planExtension = selectCurrentPlanExtensionByPlanId(state);

  if (planExtension) {
    dispatch(selectPlanExtension(planExtension.planExtensionId));
    dispatch(startEditingCampaign());
    dispatch(startEditingPlan());
    dispatch(startEditingPlanExtension());
    dispatch(startEditingPlanTargetMetricsKpis());
    dispatch(startEditingPlanChangeOverviews());
  } else {
    dispatch(addNewPlanExtension());
    dispatch({ type: PLAN_EXTENSION_SET_IS_NEW_FROM_UPDATE });
  }
};

export const setPlanExtensionValue = data => {
  return (dispatch, getState) => {
    const currentPlanExtension = selectCurrentPlanExtension(getState());

    dispatch(
      editItemAttributes(PLAN_EXTENSION_MODEL_NAME, currentPlanExtension, data)
    );
  };
};

export const cancelPlanExtension = () => {
  return (dispatch, getState) => {
    dispatch(stopEditingPlanChangeOverviews());
    dispatch(stopEditingPlanTargetMetricsKpis());
    dispatch(stopEditingPlanExtension());
    dispatch(stopEditingPlan());
    dispatch(stopEditingCampaign());
    const isNew = selectPlanExtensionIsNew(getState());
    if (isNew) {
      dispatch(clearPlanExtension());
    } else {
      dispatch(clearCurrentPlanTargetMetricKpis());
      dispatch(clearCurrentPlanChangeOverviews());
      dispatch({ type: PLAN_EXTENSION_RESET_CURRENT });
    }
  };
};

export const displayPlanExtensionErrors = () => {
  return { type: PLAN_EXTENSION_DISPLAY_ERRORS };
};

export const createPlanExtension = () => {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesPlanExtensionHaveErrors(state);
    if (hasErrors) {
      dispatch(displayPlanExtensionErrors());
      return Promise.reject();
    }

    const agencyID = selectAgency(state);
    const currentPlan = selectCurrentPlan(state);
    const planExtensionData = getPlanExtensionForSave(state);
    const planTargetMetricsKpis = selectEditingPlanTargetMetricsKpiData(state);
    const planTargetMetricsKpisData = { planTargetMetricsKpis };
    const planChangeOverviews = selectEditingPlanChangeOverviewData(state);
    const planChangeOverviewsData = { planChangeOverviews };
    const url = generateUrl(CREATE_PLAN_EXTENSION, {
      agency: agencyID,
      [PLAN_MODEL_ID]: currentPlan
    });

    const body = {
      ...planExtensionData,
      ...planTargetMetricsKpisData,
      ...planChangeOverviewsData
    };

    return dispatch(apiCall(POST, url, body)).then(
      response => {
        const planid = get(response, PLAN_MODEL_ID);
        const planExtensionid = get(response, PLAN_EXTENSION_MODEL_ID);
        const responseData = set(response, "id", planExtensionid);
        dispatch(deletePlanTargetMetricsKpis());
        dispatch(deletePlanChangeOverviews());
        dispatch(
          commitData(PLAN_EXTENSION_MODEL_NAME, planExtensionid, responseData)
        );
        dispatch(stopEditingPlanTargetMetricsKpis());
        dispatch(stopEditingPlanChangeOverviews());
        dispatch(stopEditingPlanExtension());
        dispatch(stopEditingPlan());
        dispatch(stopEditingCampaign());
        dispatch(clearCurrentPlanTargetMetricKpis());
        dispatch(clearCurrentPlanChangeOverviews());
        dispatch(selectPlanExtension(planExtensionid));
        dispatch(enqueueNotificationMessage(PLAN_EXTENSION_SUCCESS_MESSAGE));
        return planid;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
};

export const createPlanExtensionCopyPlan = () => {
  return (dispatch, getState) => {
    const state = getState();

    const hasErrors = doesPlanExtensionHaveErrors(state);
    if (hasErrors) {
      dispatch(displayPlanExtensionErrors());
      return Promise.reject();
    }

    const agencyID = selectAgency(state);
    const processId = selectProcess(state);
    const currentPlan = selectCurrentPlan(state);
    const planExtensionData = getPlanExtensionForSave(state);
    const planTargetMetricsKpis = selectEditingPlanTargetMetricsKpiData(state);
    const planTargetMetricsKpisData = { planTargetMetricsKpis };
    const planChangeOverviews = selectEditingPlanChangeOverviewData(state);
    const planChangeOverviewsData = { planChangeOverviews };

    const url = generateUrl(CREATE_PLAN_EXTENSION_COPY_PLAN, {
      agency: agencyID,
      [PROCESS_MODEL_ID]: processId,
      [PLAN_MODEL_ID]: currentPlan
    });

    const body = {
      ...planExtensionData,
      ...planTargetMetricsKpisData,
      ...planChangeOverviewsData
    };

    return dispatch(apiCall(POST, url, body)).then(
      response => {
        const { step, currentOrderStep, orderStepsCount, order } =
          response || {};

        if (order) {
          const orderId = get(order, ORDER_MODEL_ID);
          const orderData = set(order, "id", orderId);
          set(orderData, "isCopyTemp", true);
          dispatch(commitData(ORDER_MODEL_NAME, orderId, orderData));
          dispatch(selectOrder(orderId));
        }

        dispatch(deletePlanTargetMetricsKpis());
        dispatch(deletePlanChangeOverviews());
        dispatch(stopEditingPlanTargetMetricsKpis());
        dispatch(stopEditingPlanChangeOverviews());
        dispatch(stopEditingPlanExtension());
        dispatch(stopEditingPlan());
        dispatch(stopEditingCampaign());
        dispatch(clearCurrentPlanTargetMetricKpis());
        dispatch(clearCurrentPlanChangeOverviews());
        dispatch(setOrderSteps(orderStepsCount));
        dispatch(setCurrentOrderStep(currentOrderStep));
        dispatch(setActiveStep(step));

        dispatch(enqueueNotificationMessage(PLAN_EXTENSION_SUCCESS_MESSAGE));
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
};

export const updatePlanExtension = () => {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesPlanExtensionHaveErrors(state);
    if (hasErrors) {
      dispatch(displayPlanExtensionErrors());
      return Promise.reject();
    }

    const agencyID = selectAgency(state);
    const currentPlan = selectCurrentPlan(state);
    const currentPlanExtension = selectCurrentPlanExtension(state);
    const planExtensionData = getPlanExtensionForSave(state);
    const planTargetMetricsKpis = selectEditingPlanTargetMetricsKpiData(state);
    const planTargetMetricsKpisData = { planTargetMetricsKpis };
    const planChangeOverviews = selectEditingPlanChangeOverviewData(state);
    const planChangeOverviewsData = { planChangeOverviews };
    const url = generateUrl(UPDATE_PLAN_EXTENSION, {
      agency: agencyID,
      [PLAN_MODEL_ID]: currentPlan,
      [PLAN_EXTENSION_MODEL_ID]: currentPlanExtension
    });
    const body = {
      ...planExtensionData,
      ...planTargetMetricsKpisData,
      ...planChangeOverviewsData
    };

    return dispatch(apiCall(PUT, url, body)).then(
      response => {
        const planId = get(response, PLAN_MODEL_ID);
        const planExtesionId = get(response, PLAN_EXTENSION_MODEL_ID);
        const responseData = set(response, "id", planExtesionId);
        dispatch(deletePlanTargetMetricsKpis());
        dispatch(deletePlanChangeOverviews());
        dispatch(
          commitData(PLAN_EXTENSION_MODEL_NAME, planExtesionId, responseData)
        );
        dispatch(stopEditingPlanTargetMetricsKpis());
        dispatch(stopEditingPlanChangeOverviews());
        dispatch(stopEditingPlanExtension());
        dispatch(stopEditingPlan());
        dispatch(stopEditingCampaign());
        dispatch(clearCurrentPlanTargetMetricKpis());
        dispatch(clearCurrentPlanChangeOverviews());
        dispatch(selectPlanExtension(planExtesionId));
        dispatch(enqueueNotificationMessage(PLAN_EXTENSION_SUCCESS_MESSAGE));
        return planId;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
};
