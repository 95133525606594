import { createReducer } from "../common/utils/reducerUtils";

import {
  USER_SELECT,
  USER_EDIT_START,
  USER_EDIT_STOP,
  USER_CLEAR_CURRENT,
  USER_DISPLAY_ERRORS,
  USER_HIDE_ERRORS,
  USER_SET_SEARCH_TERM,
  USER_SET_SEARCH_PROPERTY,
  USER_SET_IS_NEW,
  USER_RESET_IS_NEW,
  GET_USERS_SUCCESS,
  USER_ACTIVE_TOGGLE,
  DEFAULT_AGENT_FOR_IDS_OPTIONS
} from "./userConstants";

const initialState = {
  currentUser: null,
  isNew: false,
  isEditing: false,
  displayErrors: false,
  searchTerm: "",
  searchProperty: "userName",
  users: [],
  isActiveUserSwitch: false,
  agentForIdsOptions: DEFAULT_AGENT_FOR_IDS_OPTIONS
};

export function selectUser(state, payload) {
  return {
    ...state,
    currentUser: payload
  };
}

export function toggleIsActiveUserSwitch(state) {
  return {
    ...state,
    isActiveUserSwitch: !state.isActiveUserSwitch
  };
}

export function startEditingUser(state) {
  return {
    ...state,
    isEditing: true
  };
}

export function stopEditingUser(state) {
  return {
    ...state,
    isEditing: false
  };
}

export function displayErrors(state) {
  return {
    ...state,
    displayErrors: true
  };
}

export function hideErrors(state) {
  return {
    ...state,
    displayErrors: false
  };
}

export function clearCurrent(state) {
  return {
    ...state,
    currentUser: null
  };
}

export function setSearchTerm(state, payload) {
  return {
    ...state,
    searchTerm: payload.searchTerm
  };
}

export function setSearchProperty(state, payload) {
  return {
    ...state,
    searchProperty: payload.searchProperty
  };
}

export function setIsNew(state) {
  return {
    ...state,
    isNew: true
  };
}

export function resetIsNew(state) {
  return {
    ...state,
    isNew: false
  };
}

export function setUsers(state, payload) {
  return {
    ...state,
    users: payload.items
  };
}

export default createReducer(initialState, {
  [USER_SELECT]: selectUser,
  [USER_EDIT_START]: startEditingUser,
  [USER_EDIT_STOP]: stopEditingUser,
  [USER_CLEAR_CURRENT]: clearCurrent,
  [USER_DISPLAY_ERRORS]: displayErrors,
  [USER_HIDE_ERRORS]: hideErrors,
  [USER_SET_SEARCH_TERM]: setSearchTerm,
  [USER_SET_SEARCH_PROPERTY]: setSearchProperty,
  [USER_SET_IS_NEW]: setIsNew,
  [USER_RESET_IS_NEW]: resetIsNew,
  [GET_USERS_SUCCESS]: setUsers,
  [USER_ACTIVE_TOGGLE]: toggleIsActiveUserSwitch
});
