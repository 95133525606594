import { createSelector } from "reselect";
import {
  selectCurrentTrafficPlanId,
  selectTrafficPlanId
} from "../models/TrafficPlan/trafficPlanSelectors";

const trafficPage = state => state.trafficPage;
const selectTable = state => state.trafficPage.table;

export const selectCount = createSelector(selectTable, table => table.count);

export const selectItems = createSelector(selectTable, table => table.items);

export const selectCurrentTrafficker = createSelector(
  [selectCurrentTrafficPlanId, selectItems],
  (trafficPlanId, items) => {
    return items.find(item => item.trafficPlanId === trafficPlanId).trafficker;
  }
);

export const selectCurrentTraffickerOverview = createSelector(
  [selectTrafficPlanId, selectItems],
  (trafficPlanId, items) => {
    return items.find(item => item.trafficPlanId === trafficPlanId)?.trafficker;
  }
);
export const selectTabCounters = createSelector(
  trafficPage,
  trafficOverview => trafficOverview.tabCounters
);

export const selectCurrentPage = createSelector(
  trafficPage,
  trafficOverview => trafficOverview.currentPage
);

export const selectCurrentPageSize = createSelector(
  trafficPage,
  trafficOverview => trafficOverview.currentPageSize
);
