import { styled, Card as MuiCard } from "@mui/material";
import { blue } from "@mui/material/colors";
import {
  white,
  sapphire,
  supernova,
  dodgerBlue20
} from "../../../../assets/styles/colors";

const PREFIX = "NotificationCardStyles";
export const classes = {
  card: `${PREFIX}-card`,
  colorRemarks: `${PREFIX}-colorRemarks`,
  colorSystemMessage: `${PREFIX}-colorSystemMessage`,
  title: `${PREFIX}-title`,
  pos: `${PREFIX}-pos`,
  colorSystemMessageAvatar: `${PREFIX}-colorSystemMessageAvatar`,
  colorDelete: `${PREFIX}-colorDelete`,
  typographyText: `${PREFIX}-typographyText`
};

export const Card = styled(MuiCard)(({ theme }) => ({
  [`&.${classes.card}`]: {
    minWidth: theme.typography.pxToRem(275),
    maxWidth: theme.typography.pxToRem(700),
    margin: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(10)}`,
    zIndex: 1
  },
  [`&.${classes.colorRemarks}`]: {
    backgroundColor: sapphire
  },
  [`&.${classes.colorSystemMessage}`]: {
    backgroundColor: supernova
  },
  [`& .${classes.title}`]: {
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.pos}`]: {
    marginBottom: theme.typography.pxToRem(12)
  },
  [`& .${classes.typography}`]: {
    color: white
  },
  [`& .${classes.colorSystemMessageAvatar}`]: {
    backgroundColor: dodgerBlue20
  },
  [`& .${classes.colorDelete}`]: {
    color: white,
    backgroundColor: blue
  },
  [`& .${classes.typographyText}`]: {
    color: white,
    wordBreak: "break-all"
  }
}));
