import { Model, attr, fk } from "redux-orm";
import set from "lodash/set";
import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";

import {
  MANDATORY_RULE,
  MANDATORY_MESSAGE,
  NUMBER_OF_CHARACTERS_RULE,
  ISO8859_RULE,
  ISO8859_MESSAGE,
  NUMBER_OF_CHARACTERS_MESSAGE_100
} from "../../constants/validationConstants";

import { GET_JOB_TYPES } from "../../../configurations/apiUrls";
import {
  CAMPAIGN_MODEL_NAME,
  CAMPAIGN_MODEL_ID
} from "../Campaign/campaignConstants";
import { PROJECT_MODEL_NAME, PROJECT_MODEL_ID } from "./projectConstants";

class Project extends Model {
  static get fields() {
    return {
      id: attr(),
      projectId: attr(),
      projectName: attr(),
      projectLeaderId: attr(),
      jobTypeId: attr(),
      projectGroup: attr(),
      projectNumber: attr(),
      campaign: fk(CAMPAIGN_MODEL_NAME)
    };
  }

  static parse(projectData) {
    if (projectData[PROJECT_MODEL_ID]) {
      set(projectData, "id", projectData[PROJECT_MODEL_ID]);
    }
    if (projectData[CAMPAIGN_MODEL_ID]) {
      set(projectData, "campaign", projectData[CAMPAIGN_MODEL_ID]);
    }
    return this.upsert(projectData);
  }

  static updateModel(projectData) {
    return this.update(projectData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...Project.defaultAttributes,
      ...newAttributes
    };
    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherProject) {
    this.update(otherProject.ref);
  }

  static get validation() {
    return {
      projectName: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      projectLeaderId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      jobTypeId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      projectGroup: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ]
    };
  }

  static get apiConfiguration() {
    return {
      jobTypeId: {
        url: GET_JOB_TYPES
      }
    };
  }

  static get inheritConfiguration() {
    return {
      projectName: "campaignName",
      projectGroup: "agency"
    };
  }

  validate() {
    return validateModel(Project.validation, this.toJSON());
  }

  validateByProperty(name) {
    return validateProperty(Project.validation[name], this.toJSON()[name]);
  }

  hasErrors() {
    return doesModelHaveErrors(Project.validation, this.toJSON());
  }
}

Project.modelName = PROJECT_MODEL_NAME;

export default Project;
