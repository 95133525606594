export const INSERTION_SELECT = "INSERTION_SELECT";
export const INSERTION_EDIT_START = "INSERTION_EDIT_START";
export const INSERTION_EDIT_STOP = "INSERTION_EDIT_STOP";
export const INSERTION_CLEAR_CURRENT = "INSERTION_CLEAR_CURRENT";
export const INSERTION_DISPLAY_ERRORS = "INSERTION_DISPLAY_ERRORS";
export const INSERTION_HIDE_ERRORS = "INSERTION_HIDE_ERRORS";

export const INSERTION_MODEL_NAME = "Insertion";
export const INSERTION_MODEL_ID = "insertionId";
export const INSERTIONS_PROPERTY = "insertions";
