import React from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";

import { selectIsLoading } from "../../../../../business/selectors/applicationSelectors";
import {
  resetApprovalDetailsPage,
  openDialog,
  setAction
} from "../../../../../business/actions/approvalPageActions";

import {
  APPROVE,
  DECLINE
} from "../../../../../business/constants/approvalPageConstants";

import { classes, Grid } from "../../styles/approvalActions";

const ApprovalActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agency } = useParams();

  const isLoading = useSelector(state => selectIsLoading(state));

  const handleApproveSelection = () => {
    dispatch(openDialog());
    dispatch(setAction(APPROVE));
  };

  const handleDeclineSelection = () => {
    dispatch(openDialog());
    dispatch(setAction(DECLINE));
  };

  const redirectToApprovalOverviewPage = () => {
    navigate(`/${agency}/approval-overview`);
    dispatch(resetApprovalDetailsPage());
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Button
          id="aa_cancel_order"
          name="aa_cancel_order"
          className={classes.redirectButton}
          disabled={isLoading}
          onClick={redirectToApprovalOverviewPage}
        >
          CANCEL
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="aa_decline_order"
          name="aa_decline_order"
          variant="contained"
          color="secondary"
          className={clsx(classes.saveButton, classes.declineButton)}
          disabled={isLoading}
          onClick={handleDeclineSelection}
        >
          DECLINE
        </Button>
        <Button
          id="aa_approve_order"
          name="aa_approve_order"
          variant="contained"
          color="primary"
          className={clsx(classes.saveButton, classes.approveButton)}
          disabled={isLoading}
          onClick={handleApproveSelection}
        >
          APPROVE
        </Button>
      </Grid>
    </Grid>
  );
};

export default ApprovalActions;
