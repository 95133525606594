import { styled, Grid as MuiGrid, Badge as MuiBadge } from "@mui/material";
import { getThemeSpacing } from "../../../functions/util";

const PREFIX = "TraficItemItemStyles";
export const classes = {
  iconButton: `${PREFIX}-iconButton`,
  checkbox: `${PREFIX}-checkbox`,
  chip: `${PREFIX}-chip`,
  whiteChip: `${PREFIX}-whiteChip`,
  redLabelChip: `${PREFIX}-redLabelChip`,
  insertionIconButton: `${PREFIX}-insertionIconButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.iconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.checkbox}`]: {
    height: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(24)
  }
}));

export const Badge = styled(MuiBadge)(({ theme }) => ({
  "& .MuiBadge-dot": {
    height: 9,
    minWidth: 9,
    borderRadius: 5
  }
}));
