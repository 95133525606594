import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { Button } from "@mui/material";

import { classes, Grid } from "../styles/planChangeOverviewDialogActions";

const PlanExportDialogActions = ({
  isDownloadDisabled,
  onCancelClick,
  onDownloadClick,
  buttonText
}) => {
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Button
            onClick={() => onCancelClick(false)}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={isDownloadDisabled}
            onClick={onDownloadClick}
            className={clsx(classes.saveButton, classes.markedButton)}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

PlanExportDialogActions.propTypes = {
  onCancelClick: PropTypes.func.isRequired
};

export default PlanExportDialogActions;
