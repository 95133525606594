import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { Dialog, Grid, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "../../../../shared/dialog/DialogTitle";
import Scrollbar from "../../../../shared/Scrollbar";
import { selectOpenOrderStatusChangeDialogTitle } from "../../../../../business/selectors/applicationSelectors";
import { getOrdersForChangeStatus } from "../../../../../business/models/Order/orderSelectors";
import {
  classes,
  Button,
  ChangeOrderStatusDialogItemStyled
} from "../../styles/changeOrderStatusDialogStyle";

import {
  closeDialog,
  changeStatusForSelectedOrders
} from "../../../../../business/models/Plan/planActions";

const ChangeOrderStatusDialog = () => {
  const dispatch = useDispatch();

  const title = useSelector(selectOpenOrderStatusChangeDialogTitle);
  const orders = useSelector(getOrdersForChangeStatus);

  const selectedNumber = orders.length;

  const disabled = selectedNumber === 0;

  const handleSave = () => dispatch(changeStatusForSelectedOrders());

  const onCancel = () => dispatch(closeDialog());

  const titleText = `Change status to: ${title}`;

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle id="action-dialog-title">{titleText}</DialogTitle>
      <DialogContent id="action-dialog-content">
        <Scrollbar>
          {orders.map(order => (
            <ChangeOrderStatusDialogItemStyled
              key={order.orderId}
              id={order.orderId}
              order={order}
            />
          ))}
        </Scrollbar>
      </DialogContent>
      <DialogActions id="action-dialog-actions">
        <Grid container justifyContent="space-between">
          <Button onClick={onCancel} className={classes.closeButton}>
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" disabled={disabled}>
            Confirm
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ChangeOrderStatusDialog.propTypes = {
  open: PropTypes.bool
};

export default ChangeOrderStatusDialog;
