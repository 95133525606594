import React from "react";
import PropTypes from "prop-types";
import toUpper from "lodash/toUpper";
import toLower from "lodash/toLower";
import { Grid, Typography, Tooltip, Divider } from "@mui/material";

import { MediaTypeIcon } from "../../../../../../assets/styles/icons";
import { classes, Paper } from "../../../styles/sidePanel";
import { parseDate } from "../../../../../../business/models/common/utils/clientUtils";
import {
  PLACEHOLDER,
  EMPTY_STRING
} from "../../../../../../configurations/appConstants";

const ApprovalLeftSidePanel = ({ plan }) => {
  const {
    media,
    planName,
    marathonPlanId: planId,
    planAgreement,
    planPoNumber,
    currencySupplierId: currencySupplier,
    exchangeRate,
    currencyClient,
    exchangeRateClient,
    planner,
    campaign,
    planRemarks
  } = plan;

  const {
    campaignName,
    marathonCampaignId: campaignId,
    debtorInternalName: debtor,
    marathonDebtorId: debtorId,
    startDate,
    endDate
  } = campaign;

  return (
    <Paper square elevation={0} className={classes.sidePanel}>
      <div className={classes.sidePanelWrapper}>
        <div className={classes.sideBox}>
          <Grid container alignItems="center">
            <Grid item zeroMinWidth>
              <MediaTypeIcon type={toLower(media)} />
            </Grid>
            <Grid item zeroMinWidth>
              <Typography
                id="media"
                name="media"
                align="left"
                noWrap
                className={classes.typographyHeaderCaption}
              >
                &nbsp;{toUpper(media)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              zeroMinWidth
              className={classes.leftSidePanelItem}
            >
              <Tooltip title={planName || EMPTY_STRING}>
                <Typography
                  id="planName"
                  name="planName"
                  align="left"
                  noWrap
                  className={classes.typographyHeaderValue}
                >
                  {planName}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.startSection}>
          <Tooltip title={campaignName || EMPTY_STRING}>
            <Typography
              id="campaignName"
              name="campaignName"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {campaignName}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Campaign Name
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={campaignId || EMPTY_STRING}>
            <Typography
              id="campaignId"
              name="campaignId"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {campaignId}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Campaign ID
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={planId || EMPTY_STRING}>
            <Typography
              id="planId"
              name="planId"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {planId}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Plan ID
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={debtor || EMPTY_STRING}>
            <Typography
              id="debtorName"
              name="debtorName"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {debtor}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Debtor Name
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={debtorId || EMPTY_STRING}>
            <Typography
              id="debtorId"
              name="debtorId"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {debtorId}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Debtor ID
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={planAgreement || EMPTY_STRING}>
            <Typography
              id="planAgreement"
              name="planAgreement"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {planAgreement}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Plan Agreement
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={planPoNumber || EMPTY_STRING}>
            <Typography
              id="planPoNumber"
              name="planPoNumber"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {planPoNumber || PLACEHOLDER}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Plan PO number
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={parseDate(startDate) || EMPTY_STRING}>
            <Typography
              id="campaignStartDate"
              name="campaignStartDate"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {parseDate(startDate)}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Campaign Start date
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={parseDate(endDate) || EMPTY_STRING}>
            <Typography
              id="campaignEndDate"
              name="campaignEndDate"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {parseDate(endDate)}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Campaign End date
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={currencySupplier || EMPTY_STRING}>
            <Typography
              id="currencySupplier"
              name="currencySupplier"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {currencySupplier}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Currency Supplier
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={exchangeRate || EMPTY_STRING}>
            <Typography
              id="exchangeRateSupplier"
              name="exchangeRateSupplier"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {exchangeRate}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Exchange Rate Supplier
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={currencySupplier || EMPTY_STRING}>
            <Typography
              id="currencyClient"
              name="currencyClient"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {currencyClient}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Currency Client
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={exchangeRate || EMPTY_STRING}>
            <Typography
              id="exchangeRateClient"
              name="exchangeRateClient"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {exchangeRateClient}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Exchange Rate Client
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={planner || EMPTY_STRING}>
            <Typography
              id="planner"
              name="planner"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {planner}
            </Typography>
          </Tooltip>

          <Typography align="left" noWrap className={classes.typographyCaption}>
            Planner
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={planRemarks || EMPTY_STRING}>
            <Typography
              id="planRemarks"
              name="planRemarks"
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {planRemarks || PLACEHOLDER}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Plan Remarks
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

ApprovalLeftSidePanel.propTypes = {
  plan: PropTypes.object
};

export default ApprovalLeftSidePanel;
