import cuid from "cuid";
import forEach from "lodash/forEach";

import {
  SELECT_TRAFFIC_PLAN_REMARK,
  START_EDITING_TRAFFIC_PLAN_REMARK,
  STOP_EDITING_TRAFFIC_PLAN_REMARK,
  CLEAR_TRAFFIC_PLAN_REMARK,
  TRAFFIC_PLAN_REMARK_MODEL_NAME,
  TRAFFIC_PLAN_REMARK_MODEL_ID,
  TRAFFIC_PLAN_REMARK_SUCCESS_MESSAGE,
  TRAFFIC_PLAN_REMARK_ERROR_MESSAGE
} from "./trafficPlanRemarkConstants";
import {
  selectCurrentTrafficPlanRemarkId,
  selectIsEditingTrafficPlanRemark,
  getEditingTrafficPlanRemark,
  getUnreadTrafficPlanRemarks,
  getUnreadTrafficPlanRemarksIDs
} from "./trafficPlanRemarkSelectors";
import {
  selectCurrentTrafficPlanId,
  isTrafficPlanVirtual
} from "../TrafficPlan/trafficPlanSelectors";
import {
  editItemAttributes,
  editNewItem,
  stopEditingItem,
  commitData
} from "../common/editing/editingActions";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import { selectAgency } from "../../selectors/applicationSelectors";
import generateUrl from "../common/utils/urlUtils";
import {
  apiCall,
  enqueueApiErrorMessage,
  enqueueNotificationMessage,
  stopLoading
} from "../../actions/applicationActions";
import { POST, PUT } from "../../constants/applicationConstants";
import { REMARK_STATUS_READ } from "../../../configurations/appConstants";
import { SAVE_TRAFFIC_PLAN_REMARK } from "../../../configurations/apiUrls";
import { TRAFFIC_PLAN_MODEL_ID } from "../TrafficPlan/trafficPlanConstants";

export const startEditingTrafficPlanRemark = () => dispatch =>
  dispatch({ type: START_EDITING_TRAFFIC_PLAN_REMARK });

export const stopEditingTrafficPlanRemark = () => (dispatch, getState) => {
  const state = getState();
  const currentTrafficPlanRemarkId = selectCurrentTrafficPlanRemarkId(state);

  dispatch(
    stopEditingItem(TRAFFIC_PLAN_REMARK_MODEL_NAME, currentTrafficPlanRemarkId)
  );
  dispatch({ type: STOP_EDITING_TRAFFIC_PLAN_REMARK });
};

export const clearCurrentTrafficPlanRemark = () => dispatch =>
  dispatch({ type: CLEAR_TRAFFIC_PLAN_REMARK });

export function selectTrafficPlanRemark(trafficPlanRemarkId) {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectIsEditingTrafficPlanRemark(state);

    if (isEditing) {
      dispatch(stopEditingTrafficPlanRemark());
    }

    dispatch({
      type: SELECT_TRAFFIC_PLAN_REMARK,
      payload: trafficPlanRemarkId
    });
  };
}

export function addNewTrafficPlanRemark(trafficPlanId) {
  return (dispatch, getState) => {
    const state = getState();
    const session = getUnsharedEntitiesSession(state);
    const { TrafficPlanRemark } = session;

    const id = cuid();

    const newTrafficPlanRemark = TrafficPlanRemark.generate({
      id,
      trafficPlan: trafficPlanId
    });

    const trafficPlanRemarkContent = newTrafficPlanRemark.toJSON();

    dispatch(
      editNewItem(TRAFFIC_PLAN_REMARK_MODEL_NAME, id, trafficPlanRemarkContent)
    );
    dispatch(selectTrafficPlanRemark(id));
    dispatch(startEditingTrafficPlanRemark());

    return id;
  };
}

export function setPropertyValue(data) {
  return (dispatch, getState) => {
    const id = selectCurrentTrafficPlanRemarkId(getState());
    dispatch(editItemAttributes(TRAFFIC_PLAN_REMARK_MODEL_NAME, id, data));
  };
}

export const saveTrafficPlanRemark = () => (dispatch, getState) => {
  const state = getState();

  const agencyID = selectAgency(state);
  const trafficPlanId = selectCurrentTrafficPlanId(state);

  const isVirtual = isTrafficPlanVirtual(state, trafficPlanId);
  if (isVirtual) {
    dispatch(enqueueApiErrorMessage(TRAFFIC_PLAN_REMARK_ERROR_MESSAGE));
    return Promise.reject();
  }

  const data = getEditingTrafficPlanRemark(state);

  const url = generateUrl(SAVE_TRAFFIC_PLAN_REMARK, {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: trafficPlanId
  });
  const body = { remark: data.remark };

  return dispatch(apiCall(POST, url, body)).then(
    response => {
      if (response.isSuccessful) {
        dispatch(
          commitData(
            TRAFFIC_PLAN_REMARK_MODEL_NAME,
            response.trafficPlanRemarkId,
            {
              ...response.remark,
              trafficPlan: trafficPlanId
            }
          )
        );
        dispatch(stopEditingTrafficPlanRemark());
        dispatch(addNewTrafficPlanRemark(trafficPlanId));
        dispatch(
          enqueueNotificationMessage(TRAFFIC_PLAN_REMARK_SUCCESS_MESSAGE)
        );
        return Promise.resolve();
      }
      return Promise.reject();
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const updateTrafficPlanRemarksStatus = () => (dispatch, getState) => {
  const state = getState();

  const agencyID = selectAgency(state);
  const trafficPlanId = selectCurrentTrafficPlanId(state);

  const ids = getUnreadTrafficPlanRemarksIDs(state);

  const url = generateUrl(SAVE_TRAFFIC_PLAN_REMARK, {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: trafficPlanId
  });
  const body = { trafficPlanRemarks: ids };

  return dispatch(apiCall(PUT, url, body)).then(
    response => {
      if (response.isSuccessful) {
        // No need for Remarks status update
        dispatch(stopLoading());
        return Promise.resolve();
      }
      return Promise.reject();
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const updateRemarksStatusToRead = () => (dispatch, getState) => {
  const remarks = getUnreadTrafficPlanRemarks(getState());
  forEach(remarks, remark => {
    dispatch(
      commitData(
        TRAFFIC_PLAN_REMARK_MODEL_NAME,
        remark[TRAFFIC_PLAN_REMARK_MODEL_ID],
        {
          ...remark,
          statusId: REMARK_STATUS_READ
        }
      )
    );
  });
};
