import React from "react";
import PropTypes from "prop-types";

import { Grow, ClickAwayListener, Grid, Typography } from "@mui/material";

import { parseDate } from "../../../../../../business/models/common/utils/clientUtils";
import { PLACEHOLDER } from "../../../../../../configurations/appConstants";

import { classes, Paper, Popper } from "../styles/ActionPlanItemPopper";

const ActionPlanItemPopper = ({
  open,
  buttonRef,
  onClose,
  marathonPlanId,
  invoiceDate,
  currencyClient,
  exchangeRateClient,
  currencySupplier,
  exchangeRate,
  planPoNumber,
  planner,
  planRemarks
}) => {
  return (
    <Popper
      open={open}
      anchorEl={buttonRef.current}
      placement="bottom-start"
      transition
      className={classes.popper}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          id="ap-view-grow"
          name="ap-view-grow"
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.detailsPanel}>
            <ClickAwayListener onClickAway={onClose}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    id="marathonPlanId"
                    name="marathonPlanId"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {marathonPlanId || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Plan ID
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id="invoiceDate"
                    name="invoiceDate"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {parseDate(invoiceDate)}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Invoice date
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id="currencyClient"
                    name="currencyClient"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {currencyClient || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Currency Client
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id="exchangeRateClient"
                    name="exchangeRateClient"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {exchangeRateClient || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Exchange rate client
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id="currencySupplier"
                    name="currencySupplier"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {currencySupplier || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Currency Supplier
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id="exchangeRate"
                    name="exchangeRate"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {exchangeRate || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Exchange rate supplier
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id="planPoNumber"
                    name="planPoNumber"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {planPoNumber || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    PO number
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id="planner"
                    name="planner"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {planner || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Planner
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    id="planRemarks"
                    name="planRemarks"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {planRemarks || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Remarks
                  </Typography>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

ActionPlanItemPopper.propTypes = {
  open: PropTypes.bool,
  buttonRef: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  marathonPlanId: PropTypes.number,
  invoiceDate: PropTypes.string,
  currencyClient: PropTypes.string,
  exchangeRateClient: PropTypes.number,
  currencySupplier: PropTypes.string,
  exchangeRate: PropTypes.number,
  planPoNumber: PropTypes.string,
  planner: PropTypes.string
};

ActionPlanItemPopper.defaultProps = {
  open: false
};

export default ActionPlanItemPopper;
