import { Button, Grid, IconButton, ListItem } from "@mui/material";
import { useEffect, useState } from "react";
import { DeleteItemIcon } from "../../../../../assets/styles/icons";
import CustomFootnoteField from "../../../../form/CustomFootnoteField";

import { classes, List } from "../../styles/tablePanel";

const FootnoteTable = props => {
  const [footnotes, setFootnotes] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const handleAddFootnote = () => {
    if (footnotes.length === 0) {
      setFootnotes([{ id: 1, value: "" }]);
      return;
    }
    const newFootnotes = footnotes.concat({
      id: footnotes.at(-1).id + 1,
      value: ""
    });
    setFootnotes(newFootnotes);
  };

  const updateDisableButton = updatedFootnotes => {
    const isDisabled =
      updatedFootnotes.length !== 0 &&
      updatedFootnotes.some(footnote => {
        return footnote.value.trim() === "";
      });
    setDisableButton(isDisabled);
  };

  const handleDelete = (_event, id) => {
    const newFootnotes = footnotes.filter(f => f.id !== id);
    setFootnotes(newFootnotes);
    updateDisableButton(newFootnotes);
  };

  const valueChanged = (data, id) => {
    footnotes.map(footnote => {
      if (footnote.id === id) {
        footnote.value = data;
      }
      return footnote;
    });
    setFootnotes(footnotes);
    updateDisableButton(footnotes);
  };

  useEffect(() => {
    props.footnotes(footnotes);
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <List className={classes.table}>
          {footnotes.map(footnote => (
            <ListItem key={footnote.id} className={classes.tableRow}>
              <CustomFootnoteField
                component="text"
                id={footnote.id}
                label={`Custom footnote`}
                val={valueChanged}
              />
              <IconButton
                name={`o_delete_${footnote.id}`}
                id={`o_delete_${footnote.id}`}
                onClick={event => handleDelete(event, footnote.id)}
                className={classes.iconButton}
              >
                <DeleteItemIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Button onClick={handleAddFootnote} disabled={disableButton}>
          Add New Footnote
        </Button>
      </Grid>
    </Grid>
  );
};

export default FootnoteTable;
