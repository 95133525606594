import { styled, Grid as MuiGrid } from "@mui/material";

const PREFIX = "agencyListStyles";
export const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  adminCard: `${PREFIX}-adminCard`,
  typography: `${PREFIX}-typography`,
  media: `${PREFIX}-media`,
  cardActionArea: `${PREFIX}-cardActionArea`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    // margin: theme.typography.pxToRem(-11.5),
    margin: "-8px",
    padding: theme.typography.pxToRem(80)
  },
  [`& .${classes.card}`]: {
    width: theme.typography.pxToRem(240)
  },
  [`& .${classes.adminCard}`]: {
    width: theme.typography.pxToRem(240),
    height: theme.typography.pxToRem(80)
  },
  [`& .${classes.typography}`]: {
    textAlign: "center",
    fontWeight: 500
  },
  [`& .${classes.media}`]: {
    height: theme.typography.pxToRem(80),
    backgroundSize: theme.typography.pxToRem(80)
  },
  [`& .${classes.cardActionArea}`]: {
    paddingTop: theme.typography.pxToRem(8),
    height: "inherit"
  }
}));
