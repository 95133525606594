import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { Button } from "@mui/material";

import ConfirmCampaignSaveDialog from "../popup/ConfirmCampaignSaveDialog";
import ConfirmAgreementDialog from "../popup/ConfirmAgreementDialog";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import {
  toggleCampaignConfirmPopup,
  toggleAgreementConfirmPopup
} from "../../../../business/actions/applicationActions";
import {
  stopEditingCampaign,
  updateCampaign
} from "../../../../business/models/Campaign/campaignActions";
import {
  selectCascadingSaveFlag,
  selectInvoicedOrderAgreementChangeFlag,
  getCampaignData
} from "../../../../business/models/Campaign/campaignSelectors";
import { closeCampaignFormPage } from "../../../../business/actions/campaignDashboardActions";

import { classes, Grid } from "../styles/campaignEditActions";

const CampaignEditActions = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const willTriggerCascadingSave = useSelector(selectCascadingSaveFlag);
  const agreementChangeForInvoicedOrder = useSelector(
    selectInvoicedOrderAgreementChangeFlag
  );
  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const handleCancel = () => {
    dispatch(stopEditingCampaign());
    dispatch(closeCampaignFormPage());
  };

  const commitSave = () => {
    dispatch(updateCampaign()).then(id => {
      if (id) {
        dispatch(closeCampaignFormPage());
      }
    });
  };

  const handleSave = () => {
    if (agreementChangeForInvoicedOrder) {
      dispatch(toggleAgreementConfirmPopup());
    } else {
      commitSave();
    }
  };

  const handleSaveCampaign = () => {
    if (willTriggerCascadingSave) {
      dispatch(toggleCampaignConfirmPopup());
    } else {
      handleSave();
    }
  };

  return (
    <>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            name="c_discard"
            id="c_discard"
            variant="contained"
            className={classes.cancelButton}
            disabled={isLoading}
            onClick={handleCancel}
          >
            CANCEL
          </Button>
        </Grid>
        {isDebtorActive && (
          <Grid item>
            <Button
              name="c_update"
              id="c_update"
              variant="contained"
              className={clsx(classes.saveButton, classes.markedButton)}
              disabled={isLoading}
              onClick={handleSaveCampaign}
            >
              SAVE CHANGES
            </Button>
          </Grid>
        )}
      </Grid>
      <ConfirmCampaignSaveDialog onOk={handleSave} />
      <ConfirmAgreementDialog onOk={commitSave} />
    </>
  );
};

export default CampaignEditActions;
