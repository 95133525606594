import React from "react";
import PropTypes from "prop-types";

import NumberFormat from "react-number-format";

import {
  Grow,
  ClickAwayListener,
  Grid,
  Paper,
  Typography
} from "@mui/material";

import { parseDate } from "../../../../../../business/models/common/utils/clientUtils";
import { isNullOrUndefined } from "../../../../../../functions/util";
import { PLACEHOLDER } from "../../../../../../configurations/appConstants";

import { classes, Popper } from "../styles/actionInsertionItemPopper";

const ActionInsertionItemPopper = ({
  open,
  insertionInvoiceDate,
  insertionGrossTotal,
  insertionNetAmount,
  insertionAgencyDiscountAmount,
  insertionNetNetTotal,
  insertionSurcharge1Amount,
  insertionSurcharge2Amount,
  insertionPoNumber,
  insertionDiscountAmount,
  insertionNetPerUnit,
  onClose,
  buttonRef
}) => {
  return (
    <Popper
      open={open}
      anchorEl={buttonRef.current}
      placement="bottom-start"
      transition
      className={classes.orderPopper}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          id="ai_view_grow"
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.detailsPanel}>
            <ClickAwayListener onClickAway={onClose}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Typography
                    id="insertionInvoiceDate"
                    name="insertionInvoiceDate"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {parseDate(insertionInvoiceDate) || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Invoice Date
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(insertionGrossTotal) ? (
                      <NumberFormat
                        id="insertionGrossTotal"
                        name="insertionGrossTotal"
                        decimalScale={2}
                        value={insertionGrossTotal}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Gross Total
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(insertionNetAmount) ? (
                      <NumberFormat
                        id="insertionNetAmount"
                        name="insertionNetAmount"
                        decimalScale={2}
                        value={insertionNetAmount}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Net Total
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(insertionAgencyDiscountAmount) ? (
                      <NumberFormat
                        id="insertionAgencyDiscountAmount"
                        name="insertionAgencyDiscountAmount"
                        decimalScale={2}
                        value={insertionAgencyDiscountAmount}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Agency Discount
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(insertionNetNetTotal) ? (
                      <NumberFormat
                        id="insertionNetNetTotal"
                        name="insertionNetNetTotal"
                        decimalScale={2}
                        value={insertionNetNetTotal}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Net Net Total
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(insertionSurcharge1Amount) ? (
                      <NumberFormat
                        id="insertionSurcharge1Amount"
                        name="insertionSurcharge1Amount"
                        decimalScale={2}
                        value={insertionSurcharge1Amount}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Surcharge Amount 1
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(insertionSurcharge2Amount) ? (
                      <NumberFormat
                        id="insertionSurcharge2Amount"
                        name="insertionSurcharge2Amount"
                        decimalScale={2}
                        value={insertionSurcharge2Amount}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Surcharge Amount 2
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    id="insertionPoNumber"
                    name="insertionPoNumber"
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {insertionPoNumber || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    PO Number
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(insertionDiscountAmount) ? (
                      <NumberFormat
                        id="insertionDiscountAmount"
                        name="insertionDiscountAmount"
                        decimalScale={2}
                        value={insertionDiscountAmount}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Discount Amount
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="left"
                    className={classes.typographyDetailsValue}
                  >
                    {!isNullOrUndefined(insertionNetPerUnit) ? (
                      <NumberFormat
                        id="insertionNetPerUnit"
                        name="insertionNetPerUnit"
                        decimalScale={2}
                        value={insertionNetPerUnit}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                      />
                    ) : (
                      PLACEHOLDER
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Net Per Unit
                  </Typography>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

ActionInsertionItemPopper.propTypes = {
  open: PropTypes.bool,
  insertionInvoiceDate: PropTypes.string,
  insertionGrossTotal: PropTypes.number,
  insertionNetAmount: PropTypes.number,
  insertionAgencyDiscountAmount: PropTypes.number,
  insertionNetNetTotal: PropTypes.number,
  insertionSurcharge1Amount: PropTypes.number,
  insertionSurcharge2Amount: PropTypes.number,
  insertionPoNumber: PropTypes.string,
  insertionDiscountAmount: PropTypes.number,
  insertionNetPerUnit: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  buttonRef: PropTypes.object
};

export default ActionInsertionItemPopper;
