import {
  styled,
  Chip as MuiChip,
  Typography as MuiTypography
} from "@mui/material";
import {
  matisse,
  baliHai,
  solitude,
  white
} from "../../../../../../assets/styles/colors";

const PREFIX = "ActionOrderElementCompactStyle";
export const classes = {
  typographyOrderHeader: `${PREFIX}-typographyOrderHeader`,
  chip: `${PREFIX}-chip`,
  chipDark: `${PREFIX}-chip-dark`,
  widerChip: `${PREFIX}-widerChip`
};

export const Typography = styled(MuiTypography)(({ theme }) => ({
  [`&.${classes.typographyOrderHeader}`]: {
    color: matisse,
    width: theme.typography.pxToRem(195),
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(8),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

export const Chip = styled(MuiChip)(({ theme }) => ({
  [`&.${classes.chip}`]: {
    color: baliHai,
    marginLeft: theme.typography.pxToRem(8),
    minWidth: theme.typography.pxToRem(80),
    backgroundColor: solitude,
    padding: theme.typography.pxToRem(10)
  },
  [`&.${classes.chipDark}`]: {
    color: white,
    marginLeft: theme.typography.pxToRem(8),
    minWidth: theme.typography.pxToRem(80),
    backgroundColor: baliHai,
    padding: theme.typography.pxToRem(10)
  },
  [`&.${classes.widerChip}`]: {
    minWidth: theme.typography.pxToRem(120)
  }
}));
