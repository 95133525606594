import { styled, Card as MuiCard } from "@mui/material";
import {
  solitude,
  greyChateau,
  baliHai,
  jordyBlue,
  lochmara,
  black
} from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "ChatStyles";
export const classes = {
  card: `${PREFIX}-card`,
  cardCurrent: `${PREFIX}-cardCurrent`,
  iconButton: `${PREFIX}-iconButton`,
  cardContent: `${PREFIX}-cardContent`,
  cardTitle: `${PREFIX}-cardTitle`,
  cardDate: `${PREFIX}-cardDate`,
  cardDateCurrent: `${PREFIX}-cardDateCurrent`,
  cardBody: `${PREFIX}-cardBody`
};

export const Card = styled(MuiCard)(({ theme }) => ({
  [`&.${classes.card}`]: {
    width: theme.typography.pxToRem(600),
    backgroundColor: solitude,
    marginTop: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    marginBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`&.${classes.cardCurrent}`]: {
    backgroundColor: jordyBlue,
    float: "right"
  },
  [`& .${classes.iconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32)
  },
  [`& .${classes.cardContent}`]: {
    color: baliHai,
    padding: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    "&:last-child": {
      paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
    }
  },
  [`& .${classes.cardTitle}`]: {
    color: greyChateau
  },
  [`& .${classes.cardDate}`]: {
    color: greyChateau,
    marginTop: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.cardDateCurrent}`]: {
    color: lochmara
  },
  [`& .${classes.cardBody}`]: {
    color: black,
    fontSize: 10,
    wordBreak: "break-all"
  }
}));
