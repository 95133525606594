import { styled, Grid as MuiGrid } from "@mui/material";
import {
  supernova,
  white,
  crusta,
  dodgerBlue
} from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "UserManagementActionsStyles";
export const classes = {
  securityCacheButton: `${PREFIX}-securityCacheButton`,
  saveButton: `${PREFIX}-saveButton`,
  markedButton: `${PREFIX}-markedButton`,
  mediaCacheButton: `${PREFIX}-mediaCacheButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.securityCacheButton}`]: {
    backgroundColor: crusta
  },
  [`& .${classes.saveButton}`]: {
    color: white
  },
  [`& .${classes.markedButton}`]: {
    backgroundColor: supernova
  },
  [`& .${classes.mediaCacheButton}`]: {
    backgroundColor: dodgerBlue,
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  }
}));
