import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import { selectAgency } from "../../selectors/applicationSelectors";
import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";
import {
  getQueryObj,
  generateParamObj,
  getHash,
  getID,
  getPathValue
} from "../common/utils/modelUtils";
import generateUrl from "../common/utils/urlUtils";
import CampaignModel from "./Campaign";
import OrderModel from "../Order/Order";
import calculate from "../common/utils/calculationUtils";
import { currencies } from "../../constants/businessConstants";
import { selectIsInCopyPlanProcess } from "../../selectors/copyPlanSelectors";

export const selectCampaign = state => state.campaign;
export const selectPropertyName = (_, props) => props.name;
export const selectDefaultDisable = (_, props) => props.disabled;
export const selectCampaignId = (_, props) => props.id;

export const selectCurrentCampaign = createSelector(
  selectCampaign,
  campaign => campaign.currentCampaign
);

export const selectIsEditingCampaign = createSelector(
  selectCampaign,
  campaign => campaign.isEditing
);

export const selectCampaignDisplayErrors = createSelector(
  selectCampaign,
  campaign => campaign.displayErrors
);

export const selectCampaignIsNew = createSelector(
  selectCampaign,
  campaign => campaign.isNew
);

export const showConfirmSave = createSelector(
  [getEntitiesSession, getEditingEntitiesSession, selectCurrentCampaign],
  (entitiesSession, editingSession, campaignId) => {
    const savedCampaign = entitiesSession.Campaign.get(
      c => c.id === campaignId
    );
    const editedCampaign = editingSession.Campaign.get(
      c => c.id === campaignId
    );

    if (savedCampaign || editedCampaign) {
      return false;
    }

    return true;
  }
);

export const getCampaignActual = createSelector(
  [getEntitiesSession, selectCampaign],
  (entitiesSession, campaign) => {
    const { Campaign } = entitiesSession;
    const campaignModel = Campaign.get(c => c.id === campaign.currentCampaign);

    return campaignModel ? campaignModel.ref.isActual : undefined;
  }
);

export const getCampaign = createSelector(
  [getEntitiesSession, selectCampaign],
  (entitiesSession, campaign) => {
    const { Campaign } = entitiesSession;
    const campaignModel = Campaign.get(c => c.id === campaign.currentCampaign);

    return campaignModel ? campaignModel.ref : undefined;
  }
);

export const getNumberOfPlans = createSelector(
  [getEntitiesSession, selectCurrentCampaign],
  (entitiesSession, campaignId) => {
    const { Plan } = entitiesSession;
    return Plan.filter(p => p.campaign === campaignId).count();
  }
);

export const getNumberOfOrders = createSelector(
  [getEntitiesSession, selectCurrentCampaign],
  (entitiesSession, campaignId) => {
    const { Plan, Order } = entitiesSession;
    const plansIDs = Plan.filter(p => p.campaign === campaignId)
      .toModelArray()
      .map(p => p.id);
    return Order.filter(
      o => plansIDs.includes(o.plan) && o.statusId !== 4 && o.statusId !== 32
    ).count();
  }
);

export const getCurrentCampaignName = createSelector(
  [getEntitiesSession, selectCampaign],
  (entitiesSession, campaign) => {
    const { Campaign } = entitiesSession;
    const campaignModel = Campaign.get(c => c.id === campaign.currentCampaign);
    return campaignModel ? campaignModel.ref.campaignName : undefined;
  }
);

export const getEditingCampaign = createSelector(
  [getEditingEntitiesSession, selectCampaign],
  (editingSession, campaign) => {
    const { Campaign } = editingSession;
    const campaignModel = Campaign.get(c => c.id === campaign.currentCampaign);
    return campaignModel ? campaignModel.ref : undefined;
  }
);

export const getEditingCampaignErrors = createSelector(
  [getEditingEntitiesSession, selectCampaign],
  (editingSession, campaign) => {
    const { Campaign } = editingSession;
    const campaignModel = Campaign.get(c => c.id === campaign.currentCampaign);
    return campaignModel ? campaignModel.validate() : undefined;
  }
);

export const doesCampaignHaveErrors = createSelector(
  [getEditingEntitiesSession, selectCampaign],
  (editingSession, campaign) => {
    const { Campaign } = editingSession;
    const campaignModel = Campaign.get(c => c.id === campaign.currentCampaign);
    return campaignModel ? campaignModel.hasErrors() : false;
  }
);

export const getCampaignPropertyValue = createCachedSelector(
  [getEditingCampaign, selectPropertyName],
  (campaign, name) => (campaign ? campaign[name] : undefined)
)((_, props) => props.name);

export const getCampaignCustomPropertyValue = createCachedSelector(
  [getApiData, getEditingCampaign, selectPropertyName, selectAgency],
  (apiData, campaign, name, agencyID) =>
    campaign
      ? getID(
          campaign[name],
          CampaignModel.apiConfiguration[name],
          campaign,
          apiData,
          agencyID
        )
      : undefined
)((_, props) => props.name);

export const getCampaignPropertyPreviousValue = createCachedSelector(
  [getCampaign, selectPropertyName],
  (campaign, name) => (campaign ? campaign[name] : undefined)
)((_, props) => props.name);

export const getCampaignPropertyErrors = createCachedSelector(
  [getEditingCampaignErrors, selectPropertyName],
  (campaignErrors, name) => (campaignErrors ? campaignErrors[name] : undefined)
)((_, props) => props.name);

export const getCampaignPropertyErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectCampaign, selectPropertyName],
  (editingSession, campaign, name) => {
    const { Campaign } = editingSession;
    const campaignModel = Campaign.get(c => c.id === campaign.currentCampaign);
    return campaignModel ? campaignModel.validateByProperty(name) : undefined;
  }
)((_, props) => props.name);

export const getCampaignPropertyOptions = createCachedSelector(
  [getApiData, getEditingCampaign, selectPropertyName, selectAgency],
  (apiData, campaign, name, agencyID) => {
    const configuration = CampaignModel.apiConfiguration[name];
    if (configuration) {
      const queryObj = getQueryObj(
        configuration.urlParams,
        configuration.urlRequiredParams,
        campaign
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(configuration.url, paramObj, queryObj);
        return apiData[getHash(url)];
      }
    }
    return undefined;
  }
)((_, props) => props.name);

export const getCampaignData = createSelector(
  [
    getEntitiesSession,
    selectCampaign,
    getNumberOfPlans,
    getNumberOfOrders,
    selectAgency
  ],
  (entitiesSession, campaign, numberOfPlans, numberOfOrders) => {
    const { Campaign } = entitiesSession;
    const campaignModel = Campaign.get(c => c.id === campaign.currentCampaign);

    if (campaignModel) {
      return {
        ...campaignModel.ref,
        numberOfPlans,
        numberOfOrders,
        debtor: campaignModel.ref.debtor?.internalName,
        marathonDebtorId: campaignModel.ref.debtor?.maratonDebtorId, // TODO: API returns maraton instead of marathon
        processManager: campaignModel.ref.processManager?.username
      };
    }
    return undefined;
  }
);

export const getCalculationData = createSelector(
  [getEntitiesSession, selectCampaign, getApiData, selectAgency],
  (entitiesSession, campaign, apiData, agencyID) => {
    const { Campaign, Plan, Order } = entitiesSession;
    const campaignModel = Campaign.get(c => c.id === campaign.currentCampaign);
    const calculationConfig = CampaignModel.calculations;
    const orderConfiguration = OrderModel.apiConfiguration;

    if (campaignModel) {
      const plansIDs = Plan.filter(p => p.campaign === campaignModel.ref.id)
        .toRefArray()
        .map(p => p.id);
      const orders = Order.filter(
        o => plansIDs.includes(o.plan) && o.statusId !== 4 && o.statusId !== 32
      )
        .toModelArray()
        .map(order => {
          const entity = {
            ...order.plan.campaign.ref,
            ...order.plan.ref,
            ...order.ref
          };
          return {
            ...entity,
            surcharge1Commission: getPathValue(
              orderConfiguration.surcharge1Commission,
              entity,
              apiData,
              agencyID
            ),
            surcharge1Fee: getPathValue(
              orderConfiguration.surcharge1Fee,
              entity,
              apiData,
              agencyID
            ),
            surcharge2Commission: getPathValue(
              orderConfiguration.surcharge2Commission,
              entity,
              apiData,
              agencyID
            ),
            surcharge2Fee: getPathValue(
              orderConfiguration.surcharge2Fee,
              entity,
              apiData,
              agencyID
            )
          };
        });
      return calculate(calculationConfig, orders);
    }
    return null;
  }
);

export const getCtcData = createSelector([getCalculationData], calculations => {
  if (!calculations) {
    return {};
  }
  return { clientTotalCostEur: calculations.clientTotalCostEur };
});

export const doesCampaignHaveInvoicedInsertions = createSelector(
  [getEntitiesSession, selectCurrentCampaign],
  (entitiesSession, campaignId) => {
    const { Plan, Order, Insertion } = entitiesSession;
    const plansIDs = Plan.filter(p => p.campaign === campaignId)
      .toModelArray()
      .map(p => p.id);
    const ordersIDs = Order.filter(o => plansIDs.includes(o.plan))
      .toModelArray()
      .map(o => o.id);
    const insertions = Insertion.filter(i => ordersIDs.includes(i.order))
      .toModelArray()
      .map(i => i.insertionIsInvoiced);
    return !isEmpty(insertions) ? some(insertions) : false;
  }
);

export const doesCampaignHaveCancelledOrToBeCancelledOrders = createSelector(
  [getEntitiesSession, selectCurrentCampaign],
  (entitiesSession, campaignId) => {
    const { Plan, Order } = entitiesSession;
    const plansIDs = Plan.filter(p => p.campaign === campaignId)
      .toModelArray()
      .map(p => p.id);
    return (
      Order.filter(
        o =>
          plansIDs.includes(o.plan) && (o.statusId === 4 || o.statusId === 32)
      ).count() > 0
    );
  }
);

export const isCampaignInCopyPlanProcess = createSelector(
  [selectIsInCopyPlanProcess, selectCampaignIsNew],
  (isCopyProcess, isNew) => {
    return isCopyProcess && isNew === false;
  }
);

export const isCampaignInExistingCampaignCopyPlanProcess = createSelector(
  [isCampaignInCopyPlanProcess, selectIsEditingCampaign],
  (isCopyPlanProcess, isEditing) => {
    return isCopyPlanProcess && isEditing === false;
  }
);

export const disabledByInactiveDebtor = createSelector(
  [getCampaignData, selectCampaignIsNew],
  (campaign, campaignIsNew) => {
    return !(campaignIsNew || campaign?.isDebtorActive);
  }
);

export const selectRules = createSelector(
  [
    doesCampaignHaveInvoicedInsertions,
    doesCampaignHaveCancelledOrToBeCancelledOrders,
    isCampaignInCopyPlanProcess,
    disabledByInactiveDebtor
  ],
  (hasInvoiced, hasCancelledOrToBeCancelledOrders, isCopyPlan, disabled) => {
    return {
      doesCampaignHaveInvoicedInsertions: hasInvoiced,
      doesCampaignHaveCancelledOrToBeCancelledOrders: hasCancelledOrToBeCancelledOrders,
      isCampaignInCopyPlanProcess: isCopyPlan,
      disabledByInactiveDebtor: disabled
    };
  }
);

export const isDisabled = createCachedSelector(
  [selectRules, selectPropertyName, selectDefaultDisable],
  (rules, name, disabled) => {
    const configuration = CampaignModel.disabledFields;
    const isDisabledProperty =
      some(configuration[name], c => rules[c]) || disabled;
    return isDisabledProperty;
  }
)((_, props) => props.name);

export const selectCascadingSaveFlag = createSelector(
  [getCampaign, getEditingCampaign, selectCampaignIsNew, getNumberOfPlans],
  (savedCampaign, editingCampaign, isNew, numberOfPlans) => {
    const {
      debtorId: savedDebtor,
      productId: savedProduct,
      agreementId: savedAgreement,
      poNumber: savedPoNumber
    } = savedCampaign || {};
    const {
      debtorId: editedDebtor,
      productId: editedProduct,
      agreementId: editedAgreemnt,
      poNumber: editedPoNumber
    } = editingCampaign || {};

    if (
      (savedDebtor !== editedDebtor ||
        savedProduct !== editedProduct ||
        savedAgreement !== editedAgreemnt ||
        savedPoNumber !== editedPoNumber) &&
      !isNew &&
      numberOfPlans > 0
    ) {
      return true;
    }
    return false;
  }
);

export const selectInvoicedOrderAgreementChangeFlag = createSelector(
  [getCampaign, getEditingCampaign, doesCampaignHaveInvoicedInsertions],
  (savedCampaign, editingCampaign, hasInvoiced) => {
    const { agreementId: savedAgreement } = savedCampaign || {};
    const { agreementId: editedAgreemnt } = editingCampaign || {};
    if (savedAgreement !== editedAgreemnt && hasInvoiced) {
      return true;
    }
    return false;
  }
);

export const CampaignHasMutipleCurrencies = createSelector(
  [selectCurrentCampaign, getEntitiesSession],
  (campaignId, entitiesSession) => {
    const { Plan } = entitiesSession;
    const plans = Plan.filter(p => p.campaign === campaignId).toRefArray();
    const currencySet = new Set(plans.map(p => p.currencySupplierId));
    return [...currencySet].filter(c => c !== currencies.EUR).length > 0;
  }
);

export const doesCampaignHaveAnyProject = createSelector(
  [getEntitiesSession, selectCurrentCampaign],
  (entitiesSession, campaignId) => {
    const { Project } = entitiesSession;
    return Project.exists(p => p.campaign === campaignId);
  }
);

export const doesCampaignExist = createSelector(
  [getEntitiesSession, selectCampaignId],
  (entitiesSession, campaignId) => {
    const { Campaign } = entitiesSession;
    return Campaign.idExists(campaignId);
  }
);

export const selectCopyProcessFromData = createSelector(
  [getCampaignData],
  campaign => {
    return campaign?.isInCopyProcess;
  }
);

export const getClientCreditMessage = createSelector(
  selectCampaign,
  campaign => campaign.clientCreditMessage
);
