import React, { memo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import clsx from "clsx";

import {
  getTrafficItemPropertyOptions,
  getTrafficItemPropertyValue,
  getTrafficItemViewPropertyValue,
  getTrafficItemPropertyPreviousValue,
  getTrafficItemPropertyErrorsByProperty,
  selectTrafficItemDisplayErrors,
  isTrafficItemInEditMode
} from "../../business/models/TrafficItem/trafficItemSelectors";

import { setTrafficItemValue } from "../../business/models/TrafficItem/trafficItemActions";

import Controls from "./controls/index";
import { PLACEHOLDER } from "../../configurations/appConstants";
import { formatValue } from "../../functions/util";
import { classes, Box } from "./styles/TrafficItemField";

const TrafficItemField = ({
  name,
  onChange,
  component,
  editMode,
  helperText,
  label,
  variant,
  isLabelHighlighted,
  ...componentProps
}) => {
  const handleChange = value => {
    onChange({ [name]: formatValue(value) });
  };

  const Control = Controls[component];

  const helperTextProps = {
    className: clsx(classes.typographyCaption, {
      [classes.typographyCaptionBold]: isLabelHighlighted
    })
  };

  let InputProps = {
    className: clsx({
      [classes.typographyDetailsValue]: !editMode,
      [classes.decimalField]: component === "decimal"
    }),
    classes: { disabled: classes.disabled }
  };

  const inputLabelProps = {
    className: clsx({
      [classes.typographyCaptionBold]: isLabelHighlighted
    })
  };

  const inputProps = {
    className: clsx({
      [classes.ellipsisText]: !editMode && label !== "Remarks"
    })
  };

  if (variant === "standard") {
    InputProps = { ...InputProps };
  }
  return (
    <Box>
      <Control
        component={component}
        placeholder={!editMode ? PLACEHOLDER : undefined}
        onChange={handleChange}
        className={classes.field}
        helperText={!editMode ? helperText : undefined}
        label={editMode ? label : undefined}
        InputProps={InputProps}
        inputProps={inputProps}
        InputLabelProps={inputLabelProps}
        formHelperTextProps={helperTextProps}
        variant={variant}
        {...componentProps}
      />
    </Box>
  );
};

TrafficItemField.propTypes = {
  component: PropTypes.oneOf(["text", "date", "autocomplete", "decimal"]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,

  isLabelHighlighted: PropTypes.bool
};

TrafficItemField.defaultProps = {
  isLabelHighlighted: false,
  errors: []
};

const mapStateToProps = (state, ownProps) => {
  const editMode = isTrafficItemInEditMode(state);
  return {
    value: editMode
      ? getTrafficItemPropertyValue(state, ownProps)
      : getTrafficItemViewPropertyValue(state, ownProps),
    prevValue: getTrafficItemPropertyPreviousValue(state, ownProps),
    errors: getTrafficItemPropertyErrorsByProperty(state, ownProps),
    displayErrors: selectTrafficItemDisplayErrors(state),
    options: getTrafficItemPropertyOptions(state, ownProps),
    editMode
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: data => dispatch(setTrafficItemValue(data))
});

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(TrafficItemField)
);
