import { createReducer } from "../common/utils/reducerUtils";

import {
  ORDER_SELECT,
  ORDER_EDIT_START,
  ORDER_EDIT_STOP,
  ORDER_CLEAR_CURRENT,
  ORDER_RESET_CURRENT,
  ORDER_DISPLAY_ERRORS,
  ORDER_HIDE_ERRORS,
  ORDER_SET_IS_NEW,
  ORDER_RESET_IS_NEW,
  ORDER_SET_ORDER_TO_DELETE,
  ORDER_CLEAR_OREDER_TO_DELETE
} from "./orderConstants";

const initialState = {
  currentOrder: null,
  isNew: false,
  isEditing: false,
  displayErrors: false,
  orderToDelete: null
};

export function selectOrder(state, payload) {
  return {
    ...state,
    currentOrder: payload.currentOrder
  };
}

export function startEditingOrder(state) {
  return {
    ...state,
    isEditing: true
  };
}

export function stopEditingOrder(state) {
  return {
    ...state,
    isEditing: false
  };
}

export function displayErrors(state) {
  return {
    ...state,
    displayErrors: true
  };
}

export function hideErrors(state) {
  return {
    ...state,
    displayErrors: false
  };
}

export function setIsNew(state) {
  return {
    ...state,
    isNew: true
  };
}

export function resetIsNew(state) {
  return {
    ...state,
    isNew: false
  };
}

export const setOrderToDelte = (state, payload) => ({
  ...state,
  orderToDelete: payload
});

export const clearOrderToDelte = state => ({
  ...state,
  orderToDelete: null
});

export function clearCurrent() {
  return initialState;
}

export function resetCurrent(state) {
  return {
    ...state,
    isNew: false,
    isEditing: false,
    displayErrors: false,
    orderToDelete: null
  };
}

export default createReducer(initialState, {
  [ORDER_SELECT]: selectOrder,
  [ORDER_EDIT_START]: startEditingOrder,
  [ORDER_EDIT_STOP]: stopEditingOrder,
  [ORDER_CLEAR_CURRENT]: clearCurrent,
  [ORDER_RESET_CURRENT]: resetCurrent,
  [ORDER_DISPLAY_ERRORS]: displayErrors,
  [ORDER_HIDE_ERRORS]: hideErrors,
  [ORDER_SET_IS_NEW]: setIsNew,
  [ORDER_RESET_IS_NEW]: resetIsNew,
  [ORDER_SET_ORDER_TO_DELETE]: setOrderToDelte,
  [ORDER_CLEAR_OREDER_TO_DELETE]: clearOrderToDelte
});
