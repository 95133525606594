import { useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Checkbox, Tooltip, IconButton } from "@mui/material";
import {
  selectCheckAllOrders,
  selectIsLoading,
  selectOrdersActionSwitch
} from "../../../../business/selectors/applicationSelectors";
import { selectInsertionIsEditing } from "../../../../business/models/Insertion/insertionSelectors";
import {
  clearMultipleSelections,
  openDialog
} from "../../../../business/models/Plan/planActions";
import {
  openDivideOrdersDialog,
  setCheckAllOrders,
  toggleOrdersAction
} from "../../../../business/actions/applicationActions";
import {
  InsertionIcon,
  StatusChangeIcon
} from "../../../../assets/styles/icons";
import {
  isSelected,
  selectAnyOrderSelected,
  selectAreAnyOrdersForDivideChecked
} from "../../../../business/models/Plan/planSelectors";
import { canEditCampaign } from "../../../../business/selectors/userProfileSelectors";
import { Switch } from "../../../../assets/styles/switchToggle";
import { classes, Box } from "../styles/orderStatusEditActions";

const OrderStatusEditActions = () => {
  const dispatch = useDispatch();
  const checkAllOrders = useSelector(selectCheckAllOrders);
  const isLoading = useSelector(selectIsLoading);
  const isPlanSelected = useSelector(isSelected);
  const isEditing = useSelector(selectInsertionIsEditing);
  const isOrderSelected = useSelector(selectAnyOrderSelected);
  const disableAction = !useSelector(canEditCampaign);
  const areAnyOrdersForDivideChecked = useSelector(
    selectAreAnyOrdersForDivideChecked
  );
  const switchChecked = useSelector(selectOrdersActionSwitch);

  const disableStatusChange =
    isLoading ||
    !isPlanSelected ||
    isEditing ||
    !isOrderSelected ||
    disableAction;

  const disableInsertionSplit =
    isLoading ||
    !isPlanSelected ||
    isEditing ||
    !areAnyOrdersForDivideChecked ||
    disableAction;

  const handleDivideOrders = () => {
    dispatch(openDivideOrdersDialog());
  };

  const handleChangeOrderStatus = () => {
    dispatch(openDialog());
  };

  const handleSwitchChange = () => {
    dispatch(clearMultipleSelections());
    dispatch(toggleOrdersAction());
    dispatch(setCheckAllOrders(false));
  };

  const handleSelectAllCheckboxChange = e => {
    e.stopPropagation();
    dispatch(setCheckAllOrders(e.target.checked));
    if (!e.target.checked) {
      dispatch(clearMultipleSelections());
    }
  };

  return (
    <Box>
      <Grid className={clsx(classes.paddingBottom)}>
        <Grid className={clsx(classes.headerStyle)} container>
          <Grid item>
            <Checkbox
              id="checkbox_AllOrders"
              name="checkbox_AllOrders"
              checked={checkAllOrders}
              onChange={handleSelectAllCheckboxChange}
              disabled={disableAction}
            ></Checkbox>
          </Grid>
          <Grid item>
            <Switch
              id="switch_orderstatus"
              name="switch_orderstatus"
              disableRipple
              disabled={isLoading || isEditing}
              color="default"
              checked={switchChecked}
              onChange={handleSwitchChange}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Click to Change Client Status">
              <span>
                <IconButton
                  id="change_clientStatus"
                  name="change_clientStatus"
                  disabled={disableStatusChange || switchChecked}
                  onClick={handleChangeOrderStatus}
                >
                  <StatusChangeIcon
                    id="icon_clientStatus"
                    name="icon_clientStatus"
                    disabled={disableStatusChange || switchChecked}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Click to Add Insertions">
              <span>
                <IconButton
                  id="add_insertions"
                  name="add_insertions"
                  disabled={disableInsertionSplit}
                  onClick={handleDivideOrders}
                >
                  <InsertionIcon
                    id="icon_insertions"
                    name="icon_insertions"
                    disabled={disableInsertionSplit}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderStatusEditActions;
