import React from "react";
import PropTypes from "prop-types";

import Controls from "./controls/index";

const SettingField = ({ component, onChange, ...componentProps }) => {
  const handleChange = value => {
    onChange(value);
  };

  const Control = Controls[component];
  return (
    <Control
      component={component}
      onChange={handleChange}
      {...componentProps}
    />
  );
};

SettingField.propTypes = {
  component: PropTypes.oneOf(["switch"])
};

export default SettingField;
