import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import {
  loadUserSettings,
  closeDialog,
  saveUserSettings
} from "../../../../business/actions/userProfileActions";
import {
  selectIsOpenDialog,
  selectUserId
} from "../../../../business/selectors/userProfileSelectors";

import UserProfileForm from "./form/UserProfileForm";
import UserProfileConfirmationDialog from "./dialog/UserProfileConfirmationDialog";
import { isNullOrUndefined } from "../../../../functions/util";

const UserProfilePage = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();

  const [userProfile, setUserProfile] = useState({});
  const [notificationSettings, setNotificationSettings] = useState([]);
  const [emailSettings, setEmailSettings] = useState([]);
  const [allNotificationSwitched, setAllNotificationSwitched] = useState(false);
  const [allEmailSwitched, setAllEmailSwitched] = useState(false);

  const openDialog = useSelector(state => selectIsOpenDialog(state));
  const loading = useSelector(state => selectIsLoading(state));
  const currentUserId = useSelector(state => selectUserId(state));

  useEffect(() => {
    const id = isNullOrUndefined(userId) ? currentUserId : userId;

    if (id) {
      dispatch(loadUserSettings(id)).then(response => {
        if (response) {
          const {
            overview,
            notificationSettings: notifications,
            emailSettings: emails
          } = response;
          setUserProfile(overview);
          setNotificationSettings(notifications);
          setEmailSettings(emails);
        }
      });
    }
  }, [dispatch, userId, currentUserId]);

  useEffect(() => {
    setAllNotificationSwitched(
      notificationSettings.every(setting => setting.value)
    );
  }, [notificationSettings]);

  useEffect(() => {
    setAllEmailSwitched(emailSettings.every(setting => setting.value));
  }, [emailSettings]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSave = useCallback(() => {
    const { id } = userProfile;
    const settings = [...notificationSettings, ...emailSettings];

    dispatch(saveUserSettings({ id, settings })).then(response => {
      if (response) {
        const { isSuccessful } = response;
        if (isSuccessful) {
          dispatch(closeDialog());
        }
      }
    });
  });

  const handleCancel = useCallback(() => {
    dispatch(closeDialog());
  }, [dispatch]);

  const handleNotificationSwitch = useCallback(
    event => {
      setNotificationSettings(
        [...notificationSettings].map(setting => {
          if (setting.name === event.target.name) {
            return {
              ...setting,
              value: event.target.checked
            };
          }
          return setting;
        })
      );
    },
    [notificationSettings]
  );

  const handleEmailSwitch = useCallback(
    event => {
      setEmailSettings(
        [...emailSettings].map(setting => {
          if (setting.name === event.target.name) {
            return {
              ...setting,
              value: event.target.checked
            };
          }
          return setting;
        })
      );
    },
    [emailSettings]
  );

  const handleAllNotificationSwitch = useCallback(
    event => {
      setAllNotificationSwitched(event.target.checked);

      setNotificationSettings(
        [...notificationSettings].map(setting => ({
          ...setting,
          value: event.target.checked
        }))
      );
    },
    [notificationSettings]
  );

  const handleAllEmailSwitch = useCallback(
    event => {
      setAllEmailSwitched(event.target.checked);

      setEmailSettings(
        [...emailSettings].map(setting => ({
          ...setting,
          value: event.target.checked
        }))
      );
    },
    [emailSettings]
  );

  return (
    <>
      {userProfile && (
        <UserProfileForm
          profile={userProfile}
          notificationSettings={notificationSettings}
          onNotificationSwitch={handleNotificationSwitch}
          onAllNotificationSwitch={handleAllNotificationSwitch}
          allNotificationSwitched={allNotificationSwitched}
          emailSettings={emailSettings}
          onEmailSwitch={handleEmailSwitch}
          onAllEmailSwitch={handleAllEmailSwitch}
          allEmailSwitched={allEmailSwitched}
        />
      )}
      {openDialog && (
        <UserProfileConfirmationDialog
          isLoading={loading}
          open={openDialog}
          onCancel={handleCancel}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default UserProfilePage;
