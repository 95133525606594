import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import { selectAgency } from "../../selectors/applicationSelectors";
import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";
import {
  getQueryObj,
  generateParamObj,
  getHash
} from "../common/utils/modelUtils";
import generateUrl from "../common/utils/urlUtils";
import ProjectModel from "./Project";
import { selectCurrentCampaign } from "../Campaign/campaignSelectors";

export const selectProject = state => state.project;
export const selectPropertyName = (_, props) => props.name;

export const selectCurrentProject = createSelector(
  selectProject,
  project => project.currentProject
);

export const selectIsEditingProject = createSelector(
  selectProject,
  project => project.isEditing
);

export const selectProjectDisplayErrors = createSelector(
  selectProject,
  project => project.displayErrors
);

export const selectProjectIsNew = createSelector(
  selectProject,
  project => project.isNew
);

export const getProject = createSelector(
  [getEntitiesSession, selectProject],
  (entitiesSession, project) => {
    const { Project } = entitiesSession;
    const projectModel = Project.get(c => c.id === project.currentProject);

    return projectModel ? projectModel.ref : undefined;
  }
);

export const selectFirstIdFromProjectList = createSelector(
  [getEntitiesSession, selectCurrentCampaign],
  (entitiesSession, campaignId) => {
    const { Project } = entitiesSession;
    const project = Project.first(p => p.campaign === campaignId);
    return project ? project.id : undefined;
  }
);

export const getProjects = createSelector(
  [getEntitiesSession, selectCurrentCampaign],
  (entitiesSession, campaignId) => {
    const { Project } = entitiesSession;
    return Project.filter(p => p.campaign === campaignId).toRefArray();
  }
);

export const getEditingProject = createSelector(
  [getEditingEntitiesSession, selectProject],
  (editingSession, project) => {
    const { Project } = editingSession;
    const projectModel = Project.get(c => c.id === project.currentProject);
    return projectModel
      ? { ...projectModel.campaign.ref, ...projectModel.ref }
      : undefined;
  }
);

export const doesProjectHaveErrors = createSelector(
  [getEditingEntitiesSession, selectProject],
  (editingSession, project) => {
    const { Project } = editingSession;
    const projectModel = Project.get(c => c.id === project.currentProject);
    return projectModel ? projectModel.hasErrors() : false;
  }
);

export const getProjectPropertyErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectProject, selectPropertyName],
  (editingSession, project, name) => {
    const { Project } = editingSession;
    const projectModel = Project.get(c => c.id === project.currentProject);
    return projectModel ? projectModel.validateByProperty(name) : undefined;
  }
)((state, props) => props.name);

export const getProjectPropertyValue = createCachedSelector(
  [getEditingProject, selectPropertyName],
  (project, name) => (project ? project[name] : undefined)
)((state, props) => props.name);

export const getProjectViewPropertyValue = createCachedSelector(
  [getEntitiesSession, selectPropertyName, selectCurrentProject],
  (entitiesSession, name, projectId) => {
    const { Project } = entitiesSession;
    const project = Project.get(p => p.id === projectId);
    return project ? project.ref[name] : undefined;
  }
)((state, props) => props.name);

export const getProjectPropertyPreviousValue = createCachedSelector(
  [getProject, selectPropertyName],
  (project, name) => (project ? project[name] : undefined)
)((state, props) => props.name);

export const getProjectPropertyOptions = createCachedSelector(
  [getApiData, getEditingProject, selectPropertyName, selectAgency],
  (apiData, project, name, agencyID) => {
    const configuration = ProjectModel.apiConfiguration[name];
    if (configuration) {
      const queryObj = getQueryObj(
        configuration.urlParams,
        configuration.urlRequiredParams,
        project
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(configuration.url, paramObj, queryObj);
        return apiData[getHash(url)];
      }
    }
    return undefined;
  }
)((state, props) => props.name);
