import { styled, Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { baliHai } from "../../../../../assets/styles/colors";

const PREFIX = "BreadcrumbsActionStyle";
export const classes = {
  navlink: `${PREFIX}-navlink`
};

export const Breadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  [`& .${classes.navlink}`]: {
    textDecoration: "none",
    fontSize: theme.typography.pxToRem(16),
    color: baliHai
  }
}));
