import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getInsertionPropertyCalculationValue } from "../../business/models/Insertion/insertionSelectors";

import Controls from "./controls/index";

class InsertionCalculationField extends PureComponent {
  render() {
    const { entityId, id, ...componentProps } = this.props;
    const Control = Controls.decimal;
    return (
      <Control
        disabled
        component={Control}
        variant="standard"
        id={`${entityId}-${id}`}
        {...componentProps}
        onChange={this.handleChange}
      />
    );
  }
}

InsertionCalculationField.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getInsertionPropertyCalculationValue(state, ownProps)
  };
};

export default connect(mapStateToProps, null)(InsertionCalculationField);
