import { styled, Button as MuiButton } from "@mui/material";
import { dodgerBlue20 } from "../../../../assets/styles/colors";

const PREFIX = "TrafficOnlyMyTrafficPlansSearchStyles";
export const classes = {
  active: `${PREFIX}-active`,
  button: `${PREFIX}-button`
};

export const Button = styled(MuiButton)(() => ({
  [`&.${classes.active}`]: {
    backgroundColor: dodgerBlue20
  },
  [`&.${classes.button}`]: {
    textTransform: "none",
    fontWeight: 100,
    color: "#000000de"
  }
}));
