import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setUserValue } from "../../business/models/User/userActions";

import {
  getUserPropertyValue,
  getUserPropertyPreviousValue,
  getUserPropertyErrorsByProperty,
  selectUserDisplayErrors,
  isDisabled
} from "../../business/models/User/userSelectors";

import Controls from "./controls/index";

class UserField extends Component {
  handleChange = value => {
    const { name, onChange } = this.props;
    onChange({ [name]: value });
  };

  render() {
    const { component, ...componentProps } = this.props;
    const Control = Controls[component];

    return (
      <Control
        component={component}
        {...componentProps}
        onChange={this.handleChange}
      />
    );
  }
}

UserField.propTypes = {
  component: PropTypes.oneOf(["text", "autocomplete", "checkbox"]),
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

UserField.defaultProps = {
  errors: []
};

const mapStateToProps = (state, ownProps) => {
  const { disabled = false } = ownProps;
  return {
    value: getUserPropertyValue(state, ownProps),
    prevValue: getUserPropertyPreviousValue(state, ownProps),
    errors: getUserPropertyErrorsByProperty(state, ownProps),
    displayErrors: selectUserDisplayErrors(state),
    disabled: isDisabled(state, ownProps) || disabled
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: (id, data) => dispatch(setUserValue(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserField);
