import React from "react";
import PropTypes from "prop-types";

import { Scrollbars } from "react-custom-scrollbars-2";
import { getPanelHeight } from "../../configurations/appConstants";

const Scrollbar = ({ children, autoHeightMax, autoHeightMin, ...other }) => {
  return (
    <Scrollbars
      autoHide
      autoHeight
      autoHeightMin={autoHeightMin}
      autoHeightMax={autoHeightMax}
      {...other}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  autoHeightMax: PropTypes.number,
  autoHide: PropTypes.bool
};

Scrollbar.defaultProps = {
  autoHeightMax: getPanelHeight(),
  autoHeightMin: 0
};

export default Scrollbar;
