import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import clsx from "clsx";

import { Button } from "@mui/material";

import { CAMPAIGN, CREATE_PAGE } from "../../../../configurations/appConstants";

import { generateUrlPath } from "../../../../business/models/common/utils/clientUtils";

import {
  cancelPlan,
  createPlan
} from "../../../../business/models/Plan/planActions";
import {
  closePlanFormPage,
  openOrderFormPage,
  openPlanExtensionFormPage
} from "../../../../business/actions/campaignDashboardActions";

import { addNewPlanExtension } from "../../../../business/models/PlanExtension/planExtensionActions";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import { selectCurrentCampaign } from "../../../../business/models/Campaign/campaignSelectors";
import { classes, Grid } from "../styles/planNewActions";

const PlanNewActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agency } = useParams();

  const isLoading = useSelector(selectIsLoading);
  const campaignId = useSelector(selectCurrentCampaign);

  const handleCancel = () => {
    dispatch(cancelPlan());
    dispatch(closePlanFormPage()).then(() =>
      navigate(`/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${campaignId}`)
    );
  };

  const handleSave = () => {
    dispatch(createPlan()).then(planId => {
      if (planId) {
        dispatch(closePlanFormPage()).then(() =>
          navigate(
            `/${agency}/${CREATE_PAGE}${generateUrlPath(campaignId, planId)}`
          )
        );
      }
    });
  };

  const handleSaveAndAddAdditionalData = () => {
    dispatch(createPlan()).then(planId => {
      if (planId) {
        dispatch(addNewPlanExtension());
        dispatch(openPlanExtensionFormPage());
        dispatch(closePlanFormPage());
      }
    });
  };

  const handleSaveAndAddOrder = () => {
    dispatch(createPlan()).then(planId => {
      if (planId) {
        // Order of form opening is required do to small bug regarding continual create flow
        dispatch(openOrderFormPage());
        dispatch(closePlanFormPage());
      }
    });
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Button
          id="cancel_plan"
          name="cancel_plan"
          className={classes.cancelButton}
          onClick={handleCancel}
          disabled={isLoading}
        >
          CANCEL
        </Button>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-around" spacing={2}>
          <Grid item>
            <Button
              id="save_plan"
              name="save_plan"
              variant="contained"
              className={clsx(classes.saveButton)}
              disabled={isLoading}
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="add_additional"
              name="add_additional"
              variant="contained"
              className={clsx(classes.saveButton, classes.addPlanButton)}
              disabled={isLoading}
              onClick={handleSaveAndAddAdditionalData}
            >
              SAVE AND ADD ADDITIONAL PLAN DATA
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="add_order"
              name="add_order"
              variant="contained"
              className={clsx(classes.saveButton, classes.markedButton)}
              disabled={isLoading}
              onClick={handleSaveAndAddOrder}
            >
              SAVE AND ADD ORDER
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlanNewActions;
