import { styled, Table as MuiTable } from "@mui/material";
import { SIDE_BAR_OPEN_WIDTH } from "../../../../configurations/appConstants";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "CopyPlanTableStyles";
export const classes = {
  table: `${PREFIX}-table`
};

export const Table = styled(MuiTable)(({ theme }) => ({
  [`&.${classes.table}`]: {
    minWidth: theme.typography.pxToRem(window.innerWidth - SIDE_BAR_OPEN_WIDTH),
    marginTop: theme.typography.pxToRem(getThemeSpacing(theme, 3)),
    overflowX: "auto"
  }
}));
