import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogContentText
} from "@mui/material";

import { toggleMediaTypeConfirmPopup } from "../../../../business/actions/applicationActions";
import { selectOpenMediaTypeConfirmPopup } from "../../../../business/selectors/applicationSelectors";

const ConfirmMediaTypeDialog = ({ onOk, onCancel }) => {
  const dispatch = useDispatch();
  const open = useSelector(state => selectOpenMediaTypeConfirmPopup(state));

  const handleOk = () => {
    onOk();
    dispatch(toggleMediaTypeConfirmPopup());
  };

  const handleCancel = () => {
    onCancel();
    dispatch(toggleMediaTypeConfirmPopup());
  };

  const paperProps = { square: true };
  return (
    <Dialog open={open} PaperProps={paperProps} maxWidth="md">
      <DialogContent>
        <DialogContentText noWrap={false}>
          You are going to change plan media type. This will delete additional
          plan data related to Target - Metrics - KPIs. Do you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="secondary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmMediaTypeDialog;
