/* eslint-disable no-console */
import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder
  // LogLevel
} from "@microsoft/signalr";
import { serviceConfig } from "./appSettings";
import { NOTIFICATION_SERVICE } from "./apiUrls";

import { getToken } from "../functions/authUtils.ts";

import { AUTHENTICATION_SUCCESS } from "../business/constants/applicationConstants";
import { loadNotifications } from "../business/actions/notificationsActions";
import generateUrl from "../business/models/common/utils/urlUtils";

const startSignalRConnection = connection =>
  connection
    .start()
    .then(() => console.info("SignalR Connected"))
    .catch(err => console.error("SignalR Connection Error: ", err));

const signalRMiddleware = ({ dispatch }) => next => async action => {
  if (action.type === AUTHENTICATION_SUCCESS) {
    const url = `${serviceConfig.resourceBaseAddress}/${NOTIFICATION_SERVICE}`;
    const connectionHub = generateUrl(url, {
      agency: 0
    });

    const protocol = new JsonHubProtocol();

    const transport = HttpTransportType.WebSockets;

    const options = {
      transport,
      // logMessageContent: true,
      // logger: LogLevel.Trace,
      accessTokenFactory: () => getToken()
    };

    const connection = new HubConnectionBuilder()
      .withUrl(connectionHub, options)
      .withHubProtocol(protocol)
      .build();

    connection.on("NewTrafficRemark", () => dispatch(loadNotifications()));

    connection.on("OrderReadyForApproval", () => dispatch(loadNotifications()));
    connection.on("OrderToBeCancelled", () => dispatch(loadNotifications()));
    connection.on("OrderChanged", () => dispatch(loadNotifications()));
    connection.on("OrderPendingForApproval", () =>
      dispatch(loadNotifications())
    );

    connection.on("NewSystemNotification", () =>
      console.info("DEBUG: NewSystemNotification")
    );

    connection.onclose(() =>
      setTimeout(startSignalRConnection(connection), 5000)
    );

    startSignalRConnection(connection);
  }

  return next(action);
};

export default signalRMiddleware;
