import React from "react";
import { Tabs, Tab } from "@mui/material";

import { useParams, useNavigate } from "react-router-dom";
import {
  WAVEMAKER_AGENCY,
  MINDSHARE_AGENCY,
  ESSENCEMEDIACOM_AGENCY,
  KINETIC_AGENCY,
  GROUPM_AGENCY,
  GREENHOUSEONE_AGENCY,
  BLOSSOM_AGENCY,
  GREENHOUSETECH_AGENCY,
  CHOREOGRAPHCREATE_AGENCY
} from "../../../../configurations/appConstants";

const ConfigurationStatusActions = () => {
  const { status } = useParams();
  const navigate = useNavigate();

  const handleChange = (event, value) => {
    if (value !== status) {
      navigate(`/back-office/configuration/${value}`);
    }
  };

  return (
    <Tabs
      value={status}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant="standard"
    >
      <Tab
        id="tab_blossomStatus"
        name="tab_blossomStatus"
        label="BLOSSOM"
        value={BLOSSOM_AGENCY}
        index={0}
      />
      <Tab
        id="tab_greenHouseOneStatus"
        name="tab_greenHouseOneStatus"
        label="GREENHOUSE ONE"
        value={GREENHOUSEONE_AGENCY}
        index={1}
      />
      <Tab
        id="tab_greenHouseTechStatus"
        name="tab_greenHouseTechStatus"
        label="GREENHOUSE TECH"
        value={GREENHOUSETECH_AGENCY}
        index={2}
      />
      <Tab
        id="tab_choreographCreateStatus"
        name="tab_choreographCreateStatus"
        label="CHOREOGRAPH CREATE"
        value={CHOREOGRAPHCREATE_AGENCY}
        index={3}
      />
      <Tab
        id="tab_groupmStatus"
        name="tab_groupmStatus"
        label="GROUPM"
        value={GROUPM_AGENCY}
        index={4}
      />
      <Tab
        id="tab_kineticStatus"
        name="tab_kineticStatus"
        label="KINETIC"
        value={KINETIC_AGENCY}
        index={5}
      />
      <Tab
        id="tab_essenceMediacomStatus"
        name="tab_essenceMediacomStatus"
        label="ESSENCEMEDIACOM"
        value={ESSENCEMEDIACOM_AGENCY}
        index={6}
      />
      <Tab
        id="tab_mindshareStatus"
        name="tab_mindshareStatus"
        label="MINDSHARE"
        value={MINDSHARE_AGENCY}
        index={7}
      />
      <Tab
        id="tab_wavemakerStatus"
        name="tab_wavemakerStatus"
        label="WAVEMAKER"
        value={WAVEMAKER_AGENCY}
        index={8}
      />
    </Tabs>
  );
};

export default ConfigurationStatusActions;
