import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import isEmpty from "lodash/isEmpty";

import { Grid, TableBody, TableCell, TableRow, Toolbar } from "@mui/material";

import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

import Sort from "./TrafficTableSort";
import TrafficOverviewTrafficPlanPanel from "../panel/TrafficOverviewTrafficPlanPanel";
import { classes, Table } from "../styles/trafficTable";
import PaginationActions from "./TrafficTablePaginationActions";
import ClearAll from "./TrafficClearAll";
import TrafficOnlyMyTrafficPlansSearch from "./TrafficOnlyMyTrafficPlansSearch";
import MediaTypeSearch from "./TrafficMediaTypeSearch";
import { EMPTY_TABLE } from "../../../../configurations/appConstants";
import DynamicFilter from "../../../shared/dynamicFilter/DynamicFilter";
import {
  defaultOptionsForTrafficOverview,
  defaultOptions
} from "../../../../business/constants/filterOptions";
import { MAX_FILTER_NUMBER } from "../../../../business/constants/applicationConstants";

const rowsPerPageOptions = [5, 10, 25];

class TrafficTable extends PureComponent {
  render() {
    const {
      rows,
      pageSize,
      currentPage,
      searchMyTrafficPlans,
      searchMediaTypes,
      areActionsDisabled,
      count,
      sort,
      onSortChange,
      onSearchBy,
      searchProperty,
      searchText,
      onInputSearch,
      contentLoading,
      onClear,
      displaySearchMyTrafficPlans,
      onSearchMyTrafficPlans,
      onSearchMediaTypes,
      onChangePage,
      username,
      onChangeRowsPerPage,
      handleEditTraffickerDialogOpen,
      onOpenClick,
      handleAssignToMe,
      onDynamicFilter,
      queryFilters,
      status
    } = this.props;

    return (
      <>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                {displaySearchMyTrafficPlans && (
                  <Grid item>
                    <TrafficOnlyMyTrafficPlansSearch
                      searchMyTrafficPlans={searchMyTrafficPlans}
                      onSearchMyTrafficPlans={onSearchMyTrafficPlans}
                    />
                  </Grid>
                )}
                <Grid item>
                  <MediaTypeSearch
                    searchMediaTypes={searchMediaTypes}
                    onSearchMediaTypes={onSearchMediaTypes}
                  />
                </Grid>
                <Grid item>
                  <DynamicFilter
                    searchProperty={searchProperty}
                    onInputSearch={onInputSearch}
                    onSearchBy={onSearchBy}
                    searchText={searchText}
                    updateUrl={onDynamicFilter}
                    queryFilters={queryFilters}
                    filterOptions={
                      status ? defaultOptionsForTrafficOverview : defaultOptions
                    }
                    limit={MAX_FILTER_NUMBER}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Sort sortValue={sort} onSortChange={onSortChange} />
                </Grid>
                <Grid item>
                  <ClearAll onClear={onClear} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>

        <Table className={classes.table}>
          <TableBody>
            {!isEmpty(rows)
              ? rows.map(row => (
                  <TableRow key={row.trafficPlanId}>
                    <TableCell className={classes.tableCell}>
                      <TrafficOverviewTrafficPlanPanel
                        id={row.trafficPlanId}
                        key={row.trafficPlanId}
                        areActionsDisabled={areActionsDisabled}
                        onOpenClick={onOpenClick}
                        trafficPlan={row}
                        username={username}
                        handleAssignToMe={handleAssignToMe}
                        handleEditTraffickerDialogOpen={
                          handleEditTraffickerDialogOpen
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))
              : !contentLoading && (
                  <TableRow>
                    <TableCell align="center">{EMPTY_TABLE}</TableCell>
                  </TableRow>
                )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={3}
                count={count}
                rowsPerPage={pageSize}
                page={count ? currentPage : 0}
                rowsPerPageOptions={rowsPerPageOptions}
                SelectProps={{
                  native: true
                }}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={PaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </>
    );
  }
}

TrafficTable.propTypes = {
  rows: PropTypes.array,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  contentLoading: PropTypes.bool
};

TrafficTable.defaultProps = {
  rows: [],
  count: 0,
  pageSize: 10,
  currentPage: 0
};

export default TrafficTable;
