import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import { isNullOrUndefined } from "../../functions/util";

const selectUserProfile = state => state.userProfile;
const selectClaim = (_, claim) => claim;

export const selectUserId = createSelector(
  selectUserProfile,
  userProfile => userProfile.userId
);

export const selectUserName = createSelector(
  selectUserProfile,
  userProfile => userProfile.name
);

export const selectUserIsAdmin = createSelector(
  selectUserProfile,
  userProfile => userProfile.isAdmin
);
export const selectUserAgencies = createSelector(
  selectUserProfile,
  userProfile => userProfile.agencies
);

export const selectUserActions = createSelector(
  selectUserProfile,
  userProfile => userProfile.actions
);

export const selectIsOpenDialog = createSelector(
  selectUserProfile,
  userProfile => userProfile.openDialog
);

export const isAuthorized = createCachedSelector(
  [selectUserActions, selectClaim],
  (userActions, claim) => {
    return claim && userActions ? userActions.includes(claim) : false;
  }
)((state, claim) => claim);

export const canViewCampaignAction = createSelector(selectUserActions, claims =>
  claims ? claims.includes("CanViewCampaignAction") : false
);

export const canViewCampaignOverview = createSelector(
  selectUserActions,
  claims => (claims ? claims.includes("CanViewCampaignOverview") : false)
);

export const canViewCampaignArchiveCheckbox = createSelector(
  selectUserActions,
  claims => (claims ? claims.includes("CanViewCampaignArchiveCheckbox") : false)
);

export const canViewTrafficDashboardPage = createSelector(
  selectUserActions,
  claims => (claims ? claims.includes("CanViewTrafficDashboardPage") : false)
);

export const canEditCampaign = createSelector(selectUserActions, claims =>
  claims ? claims.includes("CanEditCampaign") : false
);

export const canEditTrafficAgencyFields = createSelector(
  selectUserActions,
  claims => (claims ? claims.includes("CanEditTrafficAgencyFields") : false)
);

export const canEditTrafficAgentFields = createSelector(
  selectUserActions,
  claims => (claims ? claims.includes("CanEditTrafficAgentFields") : false)
);

export const canViewTrafficPlanAssignToMeButton = createSelector(
  selectUserActions,
  claims =>
    claims ? claims.includes("CanViewTrafficPlanAssignToMeButton") : false
);

export const selectCanDownloadTrafficExcel = createSelector(
  selectUserActions,
  claims => (claims ? claims.includes("CanDownloadTrafficExcel") : false)
);

export const selectCanSendEmail = createSelector(selectUserActions, claims =>
  claims ? claims.includes("CanSendEmail") : false
);

export const selectCreateTrafficItemsForTrafficPlan = createSelector(
  selectUserActions,
  claims =>
    claims ? claims.includes("CreateTrafficItemsForTrafficPlan") : false
);

export const selectCanDownloadVersion = createSelector(
  selectUserActions,
  claims => (claims ? claims.includes("GetTrafficExportVersion") : false)
);

export const selectCanUploadAttachment = createSelector(
  selectUserActions,
  claims => (claims ? claims.includes("GetTrafficPlanAttachments") : false)
);

export const selectCanManageApiManagementPage = createSelector(
  selectUserActions,
  claims => (claims ? claims.includes("CanManagePublicApi") : false)
);

export const selectUserFullName = createSelector(
  selectUserProfile,
  userProfile => {
    const { firstName, lastName } = userProfile;
    return isNullOrUndefined(firstName) || isNullOrUndefined(lastName)
      ? ""
      : `${firstName} ${lastName}`;
  }
);

export const selectCanViewCopyPlan = createSelector(selectUserActions, claims =>
  claims ? claims.includes("InitializeCopyPlanProcess") : false
);

export const canViewTrafficManagementPage = createSelector(
  selectUserActions,
  claims => (claims ? claims.includes("CanViewTrafficManagementPage") : false)
);

export const isInventoryMediaTypeUser = createSelector(
  selectUserActions,
  claims => (claims ? claims.includes("Inventory") : false)
);
