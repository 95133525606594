import React from "react";
import PropTypes from "prop-types";
import startCase from "lodash/startCase";
import clsx from "clsx";
import { useParams } from "react-router-dom";

import {
  AccordionSummary,
  Typography,
  Grid,
  Link,
  Tooltip
} from "@mui/material";

import ClipboardTooltip from "../../../../shared/ClipboardTooltip";

import {
  parseDate,
  trimString
} from "../../../../../business/models/common/utils/clientUtils";
import { AgencyLogoIcon } from "../../../../../assets/styles/icons";
import { selectCapitalizeAgencyName } from "../../../../../business/models/common/utils/appUtils";

import { classes, Accordion } from "../../styles/AdditionalApprovalPanel";

const AdditionalApprovalPanel = ({ condition, onOrderClick }) => {
  const {
    marathonPlanId,
    debtorInternalName: debtorName,
    marathonId,
    orderStartDate,
    agencyId,
    ruleName,
    propertyName,
    message
  } = condition;
  const { conditionId, orderId } = condition;
  const { agency } = useParams();

  const preventDefault = event => {
    event.preventDefault();
  };

  const handleOrderClick = event => {
    event.stopPropagation();
    onOrderClick(condition);
  };

  const handleCopy = event => {
    event.stopPropagation();
  };

  return (
    <>
      <Accordion square elevation={0} expanded={false}>
        <AccordionSummary>
          <Grid container>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={3}>
              <Grid container spacing={2} alignItems="center">
                <Grid item className={classes.approvalOverviewAgencyIconField}>
                  <AgencyLogoIcon
                    agency={selectCapitalizeAgencyName(agencyId)}
                  />
                </Grid>
                <Grid
                  item
                  zeroMinWidth
                  className={classes.approvalOverviewMarathonIdField}
                >
                  <Typography
                    noWrap
                    className={classes.typographyHeaderCaption}
                  >
                    Order ID
                  </Typography>
                  <Tooltip
                    title={
                      <ClipboardTooltip
                        onButtonClick={handleCopy}
                        text={marathonId}
                      />
                    }
                    placement="bottom-start"
                  >
                    <Typography
                      id="aa_orderId"
                      name="aa_orderId"
                      className={clsx(
                        classes.typographyHeaderValue,
                        classes.typographyLink
                      )}
                      onClick={handleOrderClick}
                      noWrap
                    >
                      <Link
                        onClick={preventDefault}
                        href={`/${agency}/approval/order/${orderId}/${conditionId}`}
                        underline="none"
                        color="inherit"
                        className={classes.link}
                      >
                        {marathonId}
                      </Link>
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={9}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item className={classes.approvalOverviewField}>
                  <Typography
                    id="aa_planId"
                    name="aa_planId"
                    align="left"
                    className={classes.cssApproval}
                    noWrap
                  >
                    {startCase(marathonPlanId)}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Plan ID
                  </Typography>
                </Grid>
                <Grid item className={classes.approvalOverviewField}>
                  <Tooltip title={debtorName}>
                    <Typography
                      id="aa_debtorName"
                      name="aa_debtorName"
                      align="left"
                      className={classes.cssApproval}
                      noWrap
                    >
                      {trimString(debtorName, 25)}
                    </Typography>
                  </Tooltip>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Debtor Name
                  </Typography>
                </Grid>
                <Grid item className={classes.approvalOverviewField}>
                  <Typography
                    id="aa_ruleName"
                    name="aa_ruleName"
                    align="left"
                    className={classes.cssApproval}
                    noWrap
                  >
                    {startCase(ruleName)}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Rule Name
                  </Typography>
                </Grid>
                <Grid item className={classes.approvalOverviewField}>
                  <Typography
                    id="aa_propertyName"
                    name="aa_propertyName"
                    align="left"
                    className={classes.cssApproval}
                    noWrap
                  >
                    {startCase(propertyName)}
                  </Typography>

                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Property Name
                  </Typography>
                </Grid>
                <Grid item className={classes.approvalOverviewField}>
                  <Typography
                    id="aa_orderStartDate"
                    name="aa_orderStartDate"
                    align="left"
                    className={classes.cssApproval}
                    noWrap
                  >
                    {parseDate(orderStartDate)}
                  </Typography>

                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Order Start Date
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip title={message}>
                    <Typography
                      id="aa_message"
                      name="aa_message"
                      align="left"
                      className={classes.cssApproval}
                      noWrap
                    >
                      {trimString(message, 35)}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
    </>
  );
};

AdditionalApprovalPanel.propTypes = {
  condition: PropTypes.object
};

AdditionalApprovalPanel.defaultProps = {
  row: {}
};

export default AdditionalApprovalPanel;
