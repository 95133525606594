import { styled, Accordion as MuiAccordion } from "@mui/material";
import { dodgerBlue } from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "ChangeTrafficItemDialogItemStyles";
export const classes = {
  expansionPanel: `${PREFIX}-expansionPanel`,
  selected: `${PREFIX}-selected`,
  contentWrapper: `${PREFIX}-contentWrapper`
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`&.${classes.expansionPanel}`]: {
    marginBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`&.${classes.selected}`]: {
    borderColor: dodgerBlue
  },
  [`& .${classes.contentWrapper}`]: {
    height: theme.typography.pxToRem(24)
  }
}));
