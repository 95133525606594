import React from "react";
import { Grid } from "@mui/material";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-mui";

const ForeignCurrencyFields = ({ type, isForeignCurrencyRuleActive }) => (
  <>
    <Grid item xs={2}>
      <Field
        component={CheckboxWithLabel}
        name={`${type}CurrencyClient`}
        id={`${type}CurrencyClient`}
        color="primary"
        disabled={!isForeignCurrencyRuleActive}
        type="checkbox"
        Label={{ label: "Currency Client" }}
      />
    </Grid>
    <Grid item xs={2}>
      <Field
        component={CheckboxWithLabel}
        name={`${type}CurrencySupplierId`}
        id={`${type}CurrencySupplierId`}
        color="primary"
        disabled={!isForeignCurrencyRuleActive}
        type="checkbox"
        Label={{ label: "Currency Supplier ID" }}
      />
    </Grid>
  </>
);

export default ForeignCurrencyFields;
