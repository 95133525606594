export const ORDER_SELECT = "ORDER_SELECT";
export const ORDER_EDIT_START = "ORDER_EDIT_START";
export const ORDER_EDIT_STOP = "ORDER_EDIT_STOP";
export const ORDER_CLEAR_CURRENT = "ORDER_CLEAR_CURRENT";
export const ORDER_RESET_CURRENT = "ORDER_RESET_CURRENT";
export const ORDER_DISPLAY_ERRORS = "ORDER_DISPLAY_ERRORS";
export const ORDER_HIDE_ERRORS = "ORDER_HIDE_ERRORS";
export const ORDER_SET_IS_NEW = "ORDER_SET_IS_NEW";
export const ORDER_RESET_IS_NEW = "ORDER_RESET_IS_NEW";
export const ORDER_SET_ORDER_TO_DELETE = "ORDER_SET_ORDER_TO_DELETE,";
export const ORDER_CLEAR_OREDER_TO_DELETE = "  ORDER_CLEAR_OREDER_TO_DELETE";

export const ORDER_SUCCESS_MESSAGE = "Order saved successfully";
export const ORDER_SKIP_SUCCESS_MESSAGE = "Order skipped successfully";
export const ORDER_DELETE_SUCCESS_MESSAGE = "Order deleted successfully";
export const ORDER_DESCRIPTION_ERROR_MESSAGE =
  "Order description for this order is too long. Please adjust the order description to a maximum of 50 characters before continuing";
export const ORDER_DIGITAL_CHANNEL_ERROR_MESSAGE =
  "Digital Channel field for this order is not filled. Please add a Digital Channel before continuing";

export const ORDER_MODEL_NAME = "Order";
export const ORDER_MODEL_ID = "orderId";
export const ORDERS_PROPERTY = "orders";
