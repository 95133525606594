import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import {
  getSystemMessages,
  selectSystemMessageIsNew,
  selectCurrentSystemMessage
} from "../../../business/models/SystemMessage/systemMessageSelectors";

import {
  loadSystemMessages,
  selectSystemMessage,
  startEditingSystemMessage,
  stopEditingSystemMessage,
  saveSystemMessage,
  clearCurrentSystemMessage,
  updateSystemMessage
} from "../../../business/models/SystemMessage/systemMessageActions";

import SystemMessagesList from "./SystemMessagesList";
import SystemMessagesDialog from "./SystemMessagesDialog";
import { classes, Div } from "./styles/userManagmentPageStyle";
import { selectOpenSystemMessageDialog } from "../../../business/selectors/applicationSelectors";
import {
  closeSystemMessageDialog,
  openSystemMessageDialog
} from "../../../business/actions/applicationActions";
import {
  ACTION_CREATE,
  ACTION_EDIT
} from "../../../business/constants/applicationConstants";

const SystemMessagesPage = ({
  messages,
  action,
  loadMessages,
  setMessage,
  editMessage,
  stopMessageEdit,
  saveMessage,
  updateMessage,
  clearMessage,
  closeSystemMsgDialog,
  openSystemMsgDialog,
  currentSystemMessage,
  open
}) => {
  useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  const handleEdit = useCallback(
    id => {
      setMessage(id);
      editMessage();
      openSystemMsgDialog();
    },
    [editMessage, openSystemMsgDialog, setMessage]
  );

  const handleDialogClose = useCallback(() => {
    closeSystemMsgDialog();
    clearMessage();
    stopMessageEdit();
  }, [clearMessage, closeSystemMsgDialog, stopMessageEdit]);

  const handleDialogSave = useCallback(() => {
    saveMessage().then(() => closeSystemMsgDialog());
  }, [closeSystemMsgDialog, saveMessage]);

  const handleUpdateMessage = useCallback(() => {
    updateMessage(currentSystemMessage).then(() => closeSystemMsgDialog());
  }, [closeSystemMsgDialog, currentSystemMessage, updateMessage]);

  return (
    <Div className={classes.root}>
      <SystemMessagesList messages={messages} onEdit={handleEdit} />
      <SystemMessagesDialog
        open={open}
        action={action}
        onCancel={handleDialogClose}
        onSave={handleDialogSave}
        onUpdate={handleUpdateMessage}
      />
    </Div>
  );
};

SystemMessagesPage.propTypes = {
  messages: PropTypes.array,

  action: PropTypes.oneOf([ACTION_CREATE, ACTION_EDIT]),
  open: PropTypes.bool,
  loadMessages: PropTypes.func,
  setMessage: PropTypes.func,
  editMessage: PropTypes.func,
  stopMessageEdit: PropTypes.func,
  saveMessage: PropTypes.func,
  clearMessage: PropTypes.func,
  closeSystemMsgDialog: PropTypes.func
};

SystemMessagesPage.defaultMessages = {
  messages: [],
  action: ACTION_CREATE,
  open: false
};

const mapStateToProps = state => {
  const isNew = selectSystemMessageIsNew(state);
  const action = isNew ? ACTION_CREATE : ACTION_EDIT;

  return {
    messages: getSystemMessages(state),
    open: selectOpenSystemMessageDialog(state),
    currentSystemMessage: selectCurrentSystemMessage(state),
    action
  };
};

const mapDispatchToProps = dispatch => ({
  loadMessages: () => dispatch(loadSystemMessages()),
  setMessage: userId => dispatch(selectSystemMessage(userId)),
  editMessage: () => dispatch(startEditingSystemMessage()),
  stopMessageEdit: () => dispatch(stopEditingSystemMessage()),
  saveMessage: () => dispatch(saveSystemMessage()),
  updateMessage: id => dispatch(updateSystemMessage(id)),
  clearMessage: () => dispatch(clearCurrentSystemMessage()),
  closeSystemMsgDialog: () => dispatch(closeSystemMessageDialog()),
  openSystemMsgDialog: () => dispatch(openSystemMessageDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemMessagesPage);
