import { styled, Accordion as MuiAccordion } from "@mui/material";
import {
  softRed,
  crusta,
  baliHai,
  matisse,
  linkWater
} from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "TrafficOverviewTrafficPlanPanelStyles";
export const classes = {
  fontSize18: `${PREFIX}-fontSize18`,
  redColor: `${PREFIX}-redColor`,
  orangeColor: `${PREFIX}-orangeColor`,
  trafficOverviewAgencyIconField: `${PREFIX}-trafficOverviewAgencyIconField`,
  trafficOverviewPlanNameField: `${PREFIX}-trafficOverviewPlanNameField`,
  typographyHeaderCaption: `${PREFIX}-typographyHeaderCaption`,
  typographyHeaderValue: `${PREFIX}-typographyHeaderValue`,
  typographyLink: `${PREFIX}-typographyLink`,
  link: `${PREFIX}-link`,
  trafficOverviewMediaTypeField: `${PREFIX}-trafficOverviewMediaTypeField`,
  trafficOverviewAssignedIconField: `${PREFIX}-trafficOverviewAssignedIconField`,
  iconButton: `${PREFIX}-iconButton`,
  trafficOverviewEmailField: `${PREFIX}-trafficOverviewEmailField`,
  cssTraffic: `${PREFIX}-cssTraffic`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  trafficOverviewStatusField: `${PREFIX}-trafficOverviewStatusField`,
  trafficOverviewDateField: `${PREFIX}-trafficOverviewDateField`,
  trafficOverviewCounterField: `${PREFIX}-trafficOverviewCounterField`
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`& .${classes.fontSize18}`]: {
    fontSize: theme.typography.pxToRem(18)
  },
  [`& .${classes.redColor}`]: {
    color: softRed
  },
  [`& .${classes.orangeColor}`]: {
    color: crusta
  },
  [`& .${classes.trafficOverviewAgencyIconField}`]: {
    width: "30%"
  },
  [`& .${classes.trafficOverviewPlanNameField}`]: {
    width: "70%"
  },
  [`& .${classes.typographyHeaderCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyHeaderValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyLink}`]: {
    "&:hover": {
      color: theme.palette.primary.light
    }
  },
  [`& .${classes.link}`]: {
    display: "block"
  },
  [`& .${classes.trafficOverviewMediaTypeField}`]: {
    width: "8%"
  },
  [`& .${classes.trafficOverviewAssignedIconField}`]: {
    width: "4%"
  },
  [`& .${classes.iconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.trafficOverviewEmailField}`]: {
    width: "16%"
  },
  [`& .${classes.cssTraffic}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(18)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.trafficOverviewStatusField}`]: {
    width: "15%"
  },
  [`& .${classes.trafficOverviewDateField}`]: {
    width: "13%"
  },
  [`& .${classes.trafficOverviewCounterField}`]: {
    width: "5%"
  }
}));
