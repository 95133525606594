import React from "react";
import PropTypes from "prop-types";

import Scrollbar from "../../../shared/Scrollbar";

import ProjectTableHeader from "./table/ProjectTableHeader";
import ProjectTableBody from "./table/ProjectTableBody";

const ProjectTable = ({
  projects,
  onEditProject,
  onAddProject,
  onClickProjectItem,
  currentProject,
  isDebtorActive
}) => {
  const onEdit = (e, id) => {
    e.stopPropagation();
    onEditProject(id);
  };

  return (
    <>
      <ProjectTableHeader
        onAdd={onAddProject}
        projects={projects}
        isDebtorActive={isDebtorActive}
      />
      <Scrollbar autoHeightMax={150}>
        <ProjectTableBody
          onEdit={onEdit}
          projects={projects}
          onClickItem={onClickProjectItem}
          currentProject={currentProject}
        />
      </Scrollbar>
    </>
  );
};

ProjectTable.propTypes = {
  projects: PropTypes.array,
  onEditProject: PropTypes.func
};

ProjectTable.defaultProps = {
  projects: []
};

export default ProjectTable;
