import { createReducer } from "../common/utils/reducerUtils";
import {
  SELECT_TRAFFIC_PLAN_REMARK,
  START_EDITING_TRAFFIC_PLAN_REMARK,
  STOP_EDITING_TRAFFIC_PLAN_REMARK,
  CLEAR_TRAFFIC_PLAN_REMARK
} from "./trafficPlanRemarkConstants";

const initialState = {
  currentTrafficPlanRemarkId: null,
  isEditing: false
};

export function selectTrafficPlanRemark(state, trafficPlanRemarkId) {
  return {
    ...state,
    currentTrafficPlanRemarkId: trafficPlanRemarkId
  };
}

export function startEditing(state) {
  return {
    ...state,
    isEditing: true
  };
}

export function stopEditing(state) {
  return {
    ...state,
    isEditing: false
  };
}

export function clearCurrent() {
  return initialState;
}

export default createReducer(initialState, {
  [SELECT_TRAFFIC_PLAN_REMARK]: selectTrafficPlanRemark,
  [START_EDITING_TRAFFIC_PLAN_REMARK]: startEditing,
  [STOP_EDITING_TRAFFIC_PLAN_REMARK]: stopEditing,
  [CLEAR_TRAFFIC_PLAN_REMARK]: clearCurrent
});
