import React, { memo } from "react";
import PropTypes from "prop-types";

import { FormControl, MenuItem } from "@mui/material";

import { EMPTY_STRING } from "../../../../configurations/appConstants";

import { classes, Typography, Select } from "../styles/sort";

const options = [
  { key: EMPTY_STRING, text: "None" },
  { key: "campaignId_asc", text: "By campaign id asc" },
  { key: "campaignId", text: "By campaign id dsc" },
  { key: "campaignName_asc", text: "By campaign name asc" },
  { key: "campaignName", text: "By campaign name dsc" },
  { key: "debtorName_asc", text: "By debtor name asc" },
  { key: "debtorName", text: "By debtor name dsc" },
  { key: "processManager_asc", text: "By process manager asc" },
  { key: "processManager", text: "By process manager dsc" }
];

const Sort = ({ sortValue, onSortChange }) => {
  return (
    <>
      <Typography display="inline" className={classes.cssTypography}>
        Sort:
      </Typography>
      <FormControl variant="standard">
        <Select
          id="sort"
          name="sort"
          className={classes.dropDown}
          value={sortValue}
          onChange={onSortChange}
          varaint="standard"
        >
          {options.map(({ key, text, disabled }) => (
            <MenuItem
              key={key}
              id={`sort-${key}`}
              name={`sort-${key}`}
              value={key}
              disabled={disabled}
            >
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
Sort.propTypes = {
  sortValue: PropTypes.string,
  onSortChange: PropTypes.func.isRequired
};

Sort.defaultProps = {
  sortValue: EMPTY_STRING
};

export default memo(Sort);
