import { styled, Grid as MuiGrid, Badge as MuiBadge } from "@mui/material";
import { matisse, linkWater } from "../../../assets/styles/colors";
import { getThemeSpacing } from "../../../functions/util";

const PREFIX = "ActionInsertionItemStyles";
export const classes = {
  typographyDetailsValue: `${PREFIX}-typographyDetailsValue`,
  actualIconButton: `${PREFIX}-actualIconButton`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  planPanelItem: `${PREFIX}-planPanelItem`,
  iconButton: `${PREFIX}-iconButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.typographyDetailsValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`& .${classes.actualIconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${classes.planPanelItem}`]: {
    paddingTop: theme.typography.pxToRem(4),
    paddingBottom: theme.typography.pxToRem(4)
  },
  [`& .${classes.iconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  }
}));

export const Badge = styled(MuiBadge)(({ theme }) => ({
  "& .MuiBadge-dot": {
    height: 8,
    minWidth: 8,
    borderRadius: 5
  }
}));
