import { Box, styled } from "@mui/material";
import { getThemeSpacing } from "../../../../functions/util";
import ApprovalField from "../../../form/ApprovalField";

const PREFIX = "ApprovalFormStyles";
export const classes = {
  root: `${PREFIX}-root`,
  detailsLayout: `${PREFIX}-detailsLayout`,
  deatilsLeft: `${PREFIX}-deatilsLeft`,
  detailsRight: `${PREFIX}-detailsRight`
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: "0 auto",
    width: theme.typography.pxToRem(1386),
    marginBottom: "3rem"
  },
  [`& .${classes.detailsLayout}`]: {
    paddingTop: theme.typography.pxToRem(getThemeSpacing(theme, 2)),
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.deatilsLeft}`]: {
    paddingRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.detailsRight}`]: {
    paddingLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  }
}));

export const ApprovalFieldDate = props => {
  const { id, name, label, value, disabled } = props;

  return (
    <Box sx={{ pt: 2 }}>
      <ApprovalField
        component="date"
        id={id}
        name={name}
        label={label}
        value={value}
        disabled={disabled}
      >
        Save
      </ApprovalField>
    </Box>
  );
};
