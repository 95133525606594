import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Grid } from "@mui/material";

import { isNullOrUndefined } from "../../../../../functions/util";
import ApprovalField from "../../../../form/ApprovalField";
import { DASH_PLACEHOLDER } from "../../../../../configurations/appConstants";

import ApprovalLeftSidePanel from "./extensions/ApprovalLeftSidePanel";
import ApprovalRightSidePanel from "./extensions/ApprovalRightSidePanel";
import ConditionInfo from "./extensions/ConditionInfo";

import { classes, Div, ApprovalFieldDate } from "../../styles/approvalForm";

const ApprovalForm = ({ order, isLoading }) => {
  const {
    channel,
    title,
    orderAgreement,
    description,
    targetAudience,
    orderPoNumber,
    digitalChannel,
    orderStartDate,
    orderEndDate,
    format,
    position,
    frequencyCap,
    accountId,
    costType,
    numOfUnits,
    unit,
    discountType,
    discountAmount,
    netTotal,
    surcharge1Type,
    surcharge2Type,
    surcharge1Amount,
    surcharge2Amount,
    remarks,
    status,
    plan,
    message
  } = order;

  const isEnabled = !isLoading && order && plan;
  const conditionMessage = `${message}`;

  return isEnabled ? (
    <Div className={classes.root}>
      {!isNullOrUndefined(message) && (
        <ConditionInfo message={conditionMessage} />
      )}
      <Grid container>
        <Grid
          item
          xs={3}
          className={clsx(classes.detailsLayout, classes.deatilsLeft)}
        >
          <ApprovalLeftSidePanel plan={plan} />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <ApprovalField
                component="text"
                id="channelId"
                name="channelId"
                label="Channel *"
                value={channel || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <ApprovalField
                component="text"
                id="titleId"
                name="titleId"
                label="Title - Saleshouse *"
                value={title || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <ApprovalField
                component="text"
                id="orderAgreementId"
                name="orderAgreementId"
                label="Agreement *"
                value={orderAgreement || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <ApprovalField
                component="text"
                id="description"
                name="description"
                label="Description *"
                maxLength={550}
                value={description || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="text"
                id="targetAudience"
                name="targetAudience"
                label="Target Audience"
                maxLength={200}
                value={targetAudience || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="text"
                id="orderPoNumber"
                name="orderPoNumber"
                label="PO number"
                maxLength={50}
                value={orderPoNumber || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <ApprovalField
                component="text"
                id="digitalChannelId"
                name="digitalChannelId"
                label="Digital Channel"
                value={digitalChannel || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalFieldDate
                id="orderStartDate"
                name="orderStartDate"
                label="Start date *"
                value={orderStartDate || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalFieldDate
                id="orderEndDate"
                name="orderEndDate"
                label="End date *"
                value={orderEndDate || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="text"
                id="formatId"
                name="formatId"
                label="Format"
                value={format || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="text"
                id="position"
                name="position"
                label="Position"
                maxLength={50}
                value={position || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="text"
                id="frequencyCap"
                name="frequencyCap"
                label="Freq. cap"
                maxLength={50}
                value={frequencyCap || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="text"
                id="accountId"
                name="accountId"
                label="Account ID"
                maxLength={50}
                value={accountId || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="text"
                id="costTypeId"
                name="costTypeId"
                label="Cost type *"
                value={costType || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="decimal"
                id="numOfUnits"
                name="numOfUnits"
                label="# of Units *"
                value={numOfUnits || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="text"
                id="unit"
                name="unit"
                label="Unit"
                maxLength={50}
                value={unit || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="text"
                id="discountCodeId"
                name="discountCodeId"
                label="Disc. type *"
                value={discountType || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="decimal"
                id="discountAmount"
                name="discountAmount"
                label="Disc. amount *"
                value={discountAmount || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="decimal"
                id="netTotal"
                name="netTotal"
                label="Net total *"
                value={netTotal || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="text"
                id="surcharge1TypeId"
                name="surcharge1TypeId"
                label="Sur. type 1"
                value={surcharge1Type || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="decimal"
                id="surcharge1Amount"
                name="surcharge1Amount"
                label="Sur. amount 1"
                value={surcharge1Amount || 0}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="text"
                id="surcharge2TypeId"
                name="surcharge2TypeId"
                label="Sur. type 2"
                value={surcharge2Type || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <ApprovalField
                component="decimal"
                id="surcharge2Amount"
                name="surcharge2Amount"
                label="Sur. amount 2"
                value={surcharge2Amount || 0}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <ApprovalField
                component="text"
                id="remarks"
                name="remarks"
                label="Order remarks"
                maxLength={365}
                value={remarks || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <ApprovalField
                component="text"
                id="statusId"
                name="statusId"
                label="Client status *"
                value={status || DASH_PLACEHOLDER}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          className={clsx(classes.detailsLayout, classes.detailsRight)}
        >
          <ApprovalRightSidePanel order={order} />
        </Grid>
      </Grid>
    </Div>
  ) : (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item>Loading, please wait...</Grid>
    </Grid>
  );
};

ApprovalForm.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool
};

export default ApprovalForm;
