import React from "react";
import PropTypes from "prop-types";

import Controls from "./controls/index";

const ApprovalField = ({ component, onChange, ...componentProps }) => {
  const handleChange = value => {
    onChange(value);
  };

  const Control = Controls[component];
  return (
    <Control
      component={component}
      onChange={handleChange}
      {...componentProps}
    />
  );
};

ApprovalField.propTypes = {
  component: PropTypes.oneOf(["text", "date", "decimal", "autocomplete"])
};

export default ApprovalField;
