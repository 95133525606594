import { styled } from "@mui/material";
import { softRed } from "../../../assets/styles/colors";

const PREFIX = "ErrorMessagesStyles";
export const classes = {
  root: `${PREFIX}-root`
};

export const Div = styled("div")(() => ({
  [`&.${classes.root}`]: {
    color: softRed
  }
}));
