import { styled, Grid as MuiGrid } from "@mui/material";
import { supernova, white, crusta } from "../../../../assets/styles/colors";

const PREFIX = "PlanExtensionEditActionsStyles";
export const classes = {
  cancelButton: `${PREFIX}-cancelButton`,
  saveButton: `${PREFIX}-saveButton`,
  markedButton: `${PREFIX}-markedButton`
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.cancelButton}`]: {
    color: crusta
  },
  [`& .${classes.saveButton}`]: {
    color: white
  },
  [`& .${classes.markedButton}`]: {
    backgroundColor: supernova
  }
}));
