import { createSelector } from "reselect";

import orm from "../../schema";

export const selectEntities = state => state.entities;
export const selectTrafficItemEntity = state => state.entities.TrafficItem;

export const getEntitiesSession = createSelector(selectEntities, entities =>
  orm.session(entities)
);

export const getUnsharedEntitiesSession = state => {
  const entities = selectEntities(state);
  return orm.session(entities);
};
