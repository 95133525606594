import React, { Component } from "react";
import validator from "validator";

import { Button, DialogContent, Grid, DialogActions } from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import { classes, Dialog } from "../styles/addTrafficItemDialog";
import TextFieldBase from "../../../form/controls/TextField";
import {
  NUMBER_OF_CHARACTERS_MESSAGE_255,
  EMAIL_MESSAGE
} from "../../../../business/constants/validationConstants";

const paperProps = { square: true };

class EditTraffickerDialog extends Component {
  state = {
    trafficker: this.props.trafficker || "",
    errors: []
  };

  handleChange = value => {
    this.setState({ trafficker: value, errors: [] });
  };

  cancel = () => {
    this.setState({ trafficker: this.props.trafficker, errors: [] });
    this.props.handleCancel();
  };

  add = () => {
    if (this.state.trafficker.length > 255) {
      this.setState({
        errors: [NUMBER_OF_CHARACTERS_MESSAGE_255]
      });
      return;
    }
    if (this.state.trafficker && !validator.isEmail(this.state.trafficker)) {
      this.setState({
        errors: [EMAIL_MESSAGE]
      });
      return;
    }
    this.props.handleAdd(this.state.trafficker);
  };

  render() {
    return (
      <Dialog
        open
        PaperProps={paperProps}
        maxWidth="sm"
        fullWidth
        className={classes.dialog}
      >
        <DialogTitle id="edit-trafficker-dialog-title">
          Edit Trafficker
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container>
            <Grid item xs={12}>
              <TextFieldBase
                id="edit_trafficker"
                name="edit_trafficker"
                placeholder="Trafficker"
                errors={this.state.errors}
                displayErrors={this.state.errors.length > 0}
                value={this.state.trafficker}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.cancel}
            id="cancel_trafficker"
            name="cancel_trafficker"
            color="primary"
          >
            CANCEL
          </Button>
          <Button
            onClick={this.add}
            color="secondary"
            id="save_trafficker"
            name="save_trafficker"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditTraffickerDialog;
