/* eslint-disable no-console */
import { createReducer } from "../common/utils/reducerUtils";

import {
  PLAN_SELECT,
  PLAN_EDIT_START,
  PLAN_EDIT_STOP,
  PLAN_CLEAR_CURRENT,
  PLAN_RESET_CURRENT,
  PLAN_DISPLAY_ERRORS,
  PLAN_HIDE_ERRORS,
  PLAN_SET_IS_NEW,
  PLAN_RESET_IS_NEW,
  PLAN_SELECT_ORDER_STATUS,
  PLAN_SELECT_ORDER_NEXT_STATUS,
  PLAN_SELECTED_ORDERS,
  PLAN_CLEAR_ORDER_STATUS,
  PLAN_CLEAR_ORDERS,
  PLAN_INSERT_ORDER_FOR_DIVIDE,
  PLAN_REMOVE_ORDER_FOR_DIVIDE,
  PLAN_CLEAR_ORDERS_FOR_DIVIDE,
  PLAN_INITIALIZE_INSERTION_DATES,
  PLAN_UPDATE_INSERTION_DATES,
  PLAN_CLEAR_INSERTION_DATES,
  PLAN_SET_FLIGHT_MBS,
  PLAN_RESET_FLIGHT_MBS,
  PLAN_SET_PLAN_MBS,
  PLAN_RESET_PLAN_MBS,
  INVOICE_DATE_PROPERTY,
  PO_NUMBER_PROPERTY,
  SPLIT_PERCENTAGE_PROPERTY,
  PLAN_UPDATE_IS_DIVIDE_INSERTION_EVENLY
} from "./planConstants";

const initialState = {
  currentPlan: null,
  isNew: false,
  isEditing: false,
  displayErrors: false,
  selectedOrderStatus: 0,
  selectedOrderNextStatus: 0,
  selectedOrders: [],
  selectedOrdersForDivide: [],
  selectedInsertionDates: [],
  planToSendToMbs: null,
  flightToSendToMbs: null,
  isDivideInsertionsEvenly: true
};

export function selectPlan(state, payload) {
  return {
    ...state,
    currentPlan: payload.currentPlan
  };
}

export function startEditingPlan(state) {
  return {
    ...state,
    isEditing: true
  };
}

export function stopEditingPlan(state) {
  return {
    ...state,
    isEditing: false
  };
}

export function displayErrors(state) {
  return {
    ...state,
    displayErrors: true
  };
}

export function hideErrors(state) {
  return {
    ...state,
    displayErrors: false
  };
}

export function setIsNew(state) {
  return {
    ...state,
    isNew: true
  };
}

export function resetIsNew(state) {
  return {
    ...state,
    isNew: false
  };
}
export function setFlightForMbs(state, payload) {
  return {
    ...state,
    flightToSendToMbs: payload
  };
}
export function resetFlightForMbs(state) {
  return {
    ...state,
    flightToSendToMbs: null
  };
}
export function setPlanForMbs(state, payload) {
  return {
    ...state,
    planToSendToMbs: payload
  };
}
export function resetPlanForMbs(state) {
  return {
    ...state,
    planToSendToMbs: null
  };
}

export function clearCurrent() {
  return initialState;
}

export function resetCurrent(state) {
  return {
    ...state,
    isNew: false,
    isEditing: false,
    displayErrors: false,
    selectedOrderStatus: 0,
    selectedOrderNextStatus: 0,
    selectedOrders: [],
    selectedOrdersForDivide: [],
    selectedInsertionDates: [],
    planToSendToMbs: null,
    flightToSendToMbs: null,
    isDivideInsertionsEvenly: true
  };
}

export function selectedOrderStatus(state, payload) {
  return {
    ...state,
    selectedOrderStatus: payload.selectedOrderStatusInPlan
  };
}

export function selectedOrdersInPlan(state, payload) {
  return {
    ...state,
    selectedOrders: payload.selectedOrdersInPlan
  };
}

export function selectedOrderNextStatus(state, payload) {
  return {
    ...state,
    selectedOrderNextStatus: payload.selectedOrderNextStatusInPlan
  };
}

export function clearOrderStatus(state) {
  return {
    ...state,
    selectedOrderStatus: 0
  };
}

export function clearOrdersInPlan(state) {
  return {
    ...state,
    selectedOrders: []
  };
}

export function insertOrderForDivide(state, payload) {
  return {
    ...state,
    selectedOrdersForDivide: [...state.selectedOrdersForDivide, payload.orderId]
  };
}

export function removeOrderForDivide(state, payload) {
  return {
    ...state,
    selectedOrdersForDivide: state.selectedOrdersForDivide.filter(
      id => id !== payload.orderId
    )
  };
}

export function clearOrdersForDivide(state) {
  return {
    ...state,
    selectedOrdersForDivide: []
  };
}

export function initializeSelectedInsertionDates(state, payload) {
  const emptyObjectArray = [];
  Array(payload.divideCount)
    .fill({
      invoiceDate: null,
      poNumber: null,
      splitPercentage: null
    })
    .forEach(item => emptyObjectArray.push(item));
  return {
    ...state,
    selectedInsertionDates: emptyObjectArray
  };
}

export function updateSelectedInsertionDates(state, payload) {
  return {
    ...state,
    selectedInsertionDates: state.selectedInsertionDates.map((obj, index) => {
      if (index === payload.index) {
        switch (payload.property) {
          case INVOICE_DATE_PROPERTY:
            return { ...obj, invoiceDate: payload.value };
          case PO_NUMBER_PROPERTY:
            return { ...obj, poNumber: payload.value };
          case SPLIT_PERCENTAGE_PROPERTY:
            return { ...obj, splitPercentage: payload.value };
          default:
            return obj;
        }
      }
      return obj;
    })
  };
}

export function clearSelectedInsertionDates(state) {
  return {
    ...state,
    selectedInsertionDates: []
  };
}

export function updateIsDivideInsertionEvenly(state, payload) {
  return {
    ...state,
    isDivideInsertionsEvenly: payload
  };
}

export default createReducer(initialState, {
  [PLAN_SELECT]: selectPlan,
  [PLAN_EDIT_START]: startEditingPlan,
  [PLAN_EDIT_STOP]: stopEditingPlan,
  [PLAN_CLEAR_CURRENT]: clearCurrent,
  [PLAN_RESET_CURRENT]: resetCurrent,
  [PLAN_DISPLAY_ERRORS]: displayErrors,
  [PLAN_HIDE_ERRORS]: hideErrors,
  [PLAN_SET_IS_NEW]: setIsNew,
  [PLAN_RESET_IS_NEW]: resetIsNew,
  [PLAN_SELECT_ORDER_STATUS]: selectedOrderStatus,
  [PLAN_SELECT_ORDER_NEXT_STATUS]: selectedOrderNextStatus,
  [PLAN_SELECTED_ORDERS]: selectedOrdersInPlan,
  [PLAN_CLEAR_ORDER_STATUS]: clearOrderStatus,
  [PLAN_CLEAR_ORDERS]: clearOrdersInPlan,
  [PLAN_INSERT_ORDER_FOR_DIVIDE]: insertOrderForDivide,
  [PLAN_REMOVE_ORDER_FOR_DIVIDE]: removeOrderForDivide,
  [PLAN_CLEAR_ORDERS_FOR_DIVIDE]: clearOrdersForDivide,
  [PLAN_INITIALIZE_INSERTION_DATES]: initializeSelectedInsertionDates,
  [PLAN_UPDATE_INSERTION_DATES]: updateSelectedInsertionDates,
  [PLAN_CLEAR_INSERTION_DATES]: clearSelectedInsertionDates,
  [PLAN_SET_FLIGHT_MBS]: setFlightForMbs,
  [PLAN_RESET_FLIGHT_MBS]: resetFlightForMbs,
  [PLAN_SET_PLAN_MBS]: setPlanForMbs,
  [PLAN_RESET_PLAN_MBS]: resetPlanForMbs,
  [PLAN_UPDATE_IS_DIVIDE_INSERTION_EVENLY]: updateIsDivideInsertionEvenly
});
