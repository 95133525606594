/* eslint-disable import/no-cycle */
import cuid from "cuid";
import forEach from "lodash/forEach";
import omit from "lodash/omit";

import {
  editItemAttributes,
  editExistingItem,
  editNewItem,
  stopEditingItem
} from "../common/editing/editingActions";

import {
  INSERTION_SELECT,
  INSERTION_EDIT_START,
  INSERTION_EDIT_STOP,
  INSERTION_CLEAR_CURRENT,
  INSERTION_DISPLAY_ERRORS,
  INSERTION_HIDE_ERRORS,
  INSERTION_MODEL_NAME,
  INSERTION_MODEL_ID
} from "./insertionConstants";

import {
  selectCurrentInsertions,
  selectInsertionIsEditing,
  getInsertionsIDs,
  getInsertionsByOrderId
} from "./insertionSelectors";

import {
  startEditingCampaign,
  stopEditingCampaign
} from "../Campaign/campaignActions";
import { startEditingPlan, stopEditingPlan } from "../Plan/planActions";
import { startEditingOrder, stopEditingOrder } from "../Order/orderActions";
import {
  selectCurrentOrder,
  getOrder,
  selectOrderIsNew,
  getEditingOrder
} from "../Order/orderSelectors";
import { deleteEntity } from "../common/entities/entityActions";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import { getMappedObject, getArraySize } from "../common/utils/modelUtils";

import InsertionModel from "./Insertion";
import { ORDER_MODEL_ID } from "../Order/orderConstants";

export const clearCurrentInsertions = () => dispatch =>
  dispatch({ type: INSERTION_CLEAR_CURRENT });

export function startEditingInsertions() {
  return (dispatch, getState) => {
    const currentInsertions = selectCurrentInsertions(getState());

    forEach(currentInsertions, insertionId => {
      dispatch(editExistingItem(INSERTION_MODEL_NAME, insertionId));
    });

    dispatch({ type: INSERTION_EDIT_START });
  };
}

export function stopEditingInsertions() {
  return (dispatch, getState) => {
    const currentInsertions = selectCurrentInsertions(getState());

    forEach(currentInsertions, insertionId =>
      dispatch(stopEditingItem(INSERTION_MODEL_NAME, insertionId))
    );

    dispatch({ type: INSERTION_EDIT_STOP });
    dispatch({ type: INSERTION_HIDE_ERRORS });
  };
}

export function selectInsertions() {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectInsertionIsEditing(state);

    if (isEditing) {
      dispatch(stopEditingInsertions());
      dispatch(stopEditingOrder());
      dispatch(stopEditingPlan());
      dispatch(stopEditingCampaign());
    }

    const insertionsIDs = getInsertionsIDs(state);

    dispatch({ type: INSERTION_CLEAR_CURRENT });
    dispatch({
      type: INSERTION_SELECT,
      payload: {
        currentInsertions: insertionsIDs
      }
    });
  };
}

export function editInsertions() {
  return dispatch => {
    dispatch(startEditingCampaign());
    dispatch(startEditingPlan());
    dispatch(startEditingOrder());
    dispatch(startEditingInsertions());
  };
}

export function addInsertion(insertionId) {
  return (dispatch, getState) => {
    const currentInsertions = selectCurrentInsertions(getState());

    dispatch({
      type: INSERTION_SELECT,
      payload: {
        currentInsertions: [...currentInsertions, insertionId]
      }
    });
  };
}

export function addNewInsertion() {
  return (dispatch, getState) => {
    const state = getState();
    const session = getUnsharedEntitiesSession(state);
    const orderId = selectCurrentOrder(state);
    const order = getOrder(state);

    const inheritObject = getMappedObject(
      InsertionModel.inheritConfiguration,
      order
    );

    const { Insertion } = session;
    const id = cuid();

    const newInsertion = Insertion.generate({
      id,
      ...inheritObject,
      order: orderId
    });

    const insertionContents = newInsertion.toJSON();

    dispatch(editNewItem(INSERTION_MODEL_NAME, id, insertionContents));
    dispatch(addInsertion(id));
  };
}

export function displayInsertionErrors() {
  return { type: INSERTION_DISPLAY_ERRORS };
}

export function setInsertionValue(id, data) {
  return dispatch => {
    dispatch(editItemAttributes(INSERTION_MODEL_NAME, id, data));
  };
}

export function inheritInsertionPoNumberFromOrder() {
  return (dispatch, getState) => {
    const state = getState();

    const currentOrder = getOrder(state);
    if (!currentOrder) return;
    const editingOrder = getEditingOrder(state);
    const insertionsIDs = getInsertionsIDs(state);

    if (currentOrder.orderPoNumber !== editingOrder.orderPoNumber) {
      insertionsIDs.forEach(insetrionId => {
        dispatch(
          editItemAttributes(INSERTION_MODEL_NAME, insetrionId, {
            [`insertionPoNumber`]: editingOrder.orderPoNumber
          })
        );
      });
    }
  };
}

export function deleteInsertion(insertionId) {
  return (dispatch, getState) => {
    const currentInsertions = selectCurrentInsertions(getState());
    const newCurrentInsertions = currentInsertions.filter(
      id => id !== insertionId
    );

    dispatch({
      type: INSERTION_SELECT,
      payload: {
        currentInsertions: newCurrentInsertions
      }
    });
    dispatch(stopEditingItem(INSERTION_MODEL_NAME, insertionId));
    if (newCurrentInsertions.length < 2) {
      dispatch({ type: INSERTION_HIDE_ERRORS });
    }
  };
}

export function resetInsertions(doEdit = true) {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectInsertionIsEditing(state);

    if (isEditing) {
      dispatch(stopEditingInsertions());
    }

    const insertionsIDs = getInsertionsIDs(state);

    dispatch({ type: INSERTION_CLEAR_CURRENT });
    dispatch({
      type: INSERTION_SELECT,
      payload: {
        currentInsertions: insertionsIDs
      }
    });
    if (doEdit) {
      dispatch(startEditingInsertions());
    }
  };
}

export function copyInsertions(orderId) {
  return (dispatch, getState) => {
    const state = getState();
    const session = getUnsharedEntitiesSession(state);
    const { Insertion } = session;
    const insertions = getInsertionsByOrderId(state, orderId);
    const isOrderNew = selectOrderIsNew(state);
    const currentOrder = selectCurrentOrder(state);

    if (getArraySize(insertions) > 1) {
      forEach(insertions, insertionData => {
        let insertion = omit(insertionData, ["id", INSERTION_MODEL_ID]);
        if (isOrderNew) {
          insertion = { ...insertion, [ORDER_MODEL_ID]: currentOrder };
        }
        const id = cuid();

        const newInsertion = Insertion.generate({ id, ...insertion });

        const insertionContents = newInsertion.toJSON();
        dispatch(editNewItem(INSERTION_MODEL_NAME, id, insertionContents));
        dispatch(addInsertion(id));
      });

      dispatch({ type: INSERTION_EDIT_START });
    }
  };
}

export function deleteInsertions(insertions) {
  return (dispatch, getState) => {
    const insertionsIDs = insertions || getInsertionsIDs(getState());

    forEach(insertionsIDs, insertionId =>
      dispatch(deleteEntity(INSERTION_MODEL_NAME, insertionId))
    );
  };
}

export function cancelInsertions() {
  return dispatch => {
    dispatch(resetInsertions(false));
    dispatch(stopEditingOrder());
    dispatch(stopEditingPlan());
    dispatch(stopEditingCampaign());
  };
}
