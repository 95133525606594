import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Button, DialogContent, DialogActions } from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import { closeClientStatusLogDialog } from "../../../../business/actions/campaignDashboardActions";

import { getClientStatusLogs } from "../../../../business/models/Order/orderActions";
import { isOrderPendingForApproval } from "../../../../business/models/Order/orderSelectors";
import { getMatrixData } from "../../../../business/actions/approvalPageActions";

import StatusLogTable from "./extensions/StatusLogTable";
import { classes, Dialog } from "../styles/clientStatusLogDialogStyle";
import StatusMatrixTable from "./extensions/StatusMatrixTable";

const ClientStatusLogDialog = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [matrixData, setMatrixData] = useState({});
  const { marathonOrderId, logs = [] } = data;
  const {
    marathonOrderId: marathonMatrixOrderId,
    columns = [],
    rows = []
  } = matrixData;

  const isPendingStatus = useSelector(state =>
    isOrderPendingForApproval(state)
  );

  useEffect(() => {
    if (isPendingStatus) {
      dispatch(getMatrixData()).then(response => setMatrixData(response));
      dispatch(getClientStatusLogs()).then(response => setData(response));
    } else {
      dispatch(getClientStatusLogs()).then(response => setData(response));
    }
  }, [dispatch, isPendingStatus]);

  const onClick = () => dispatch(closeClientStatusLogDialog());

  return (
    <Dialog
      aria-labelledby="client-status-log-dialog"
      open
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="client-status-log-dialog-title">
        Order: {isPendingStatus ? marathonMatrixOrderId : marathonOrderId}
      </DialogTitle>
      <DialogContent>
        {isPendingStatus ? (
          <>
            <StatusLogTable logs={logs} />
            <br />
            <StatusMatrixTable columns={columns} rows={rows} />
          </>
        ) : (
          <StatusLogTable logs={logs} />
        )}
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button onClick={onClick} className={classes.closeButton}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ClientStatusLogDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object
};

export default ClientStatusLogDialog;
