import React from "react";
import { Field } from "formik";
import { Grid } from "@mui/material";
import Decimal from "../../../../form/controls/DecimalFormik";

const NegativeAmountFields = ({ type, isNegativeAmountRuleActive }) => (
  <>
    <Grid item xs={2}>
      <Field
        component={Decimal}
        id={`${type}NumOfUnits`}
        name={`${type}NumOfUnits`}
        label="Number Of Units"
        type="text"
        disabled={!isNegativeAmountRuleActive}
      />
    </Grid>
    <Grid item xs={2}>
      <Field
        component={Decimal}
        id={`${type}DiscountAmount`}
        name={`${type}DiscountAmount`}
        label="Discount Amount"
        type="text"
        disabled={!isNegativeAmountRuleActive}
      />
    </Grid>
    <Grid item xs={2}>
      <Field
        component={Decimal}
        id={`${type}NetTotal`}
        name={`${type}NetTotal`}
        label="Net Total"
        type="text"
        disabled={!isNegativeAmountRuleActive}
      />
    </Grid>
    <Grid item xs={2}>
      <Field
        component={Decimal}
        id={`${type}Surcharge1Amount`}
        name={`${type}Surcharge1Amount`}
        label="Surcharge 1 Amount"
        type="text"
        disabled={!isNegativeAmountRuleActive}
      />
    </Grid>
    <Grid item xs={2}>
      <Field
        component={Decimal}
        name={`${type}Surcharge2Amount`}
        id={`${type}Surcharge2Amount`}
        label="Surcharge 2 Amount"
        type="text"
        disabled={!isNegativeAmountRuleActive}
      />
    </Grid>
    <Grid item xs={2}>
      <Field
        component={Decimal}
        id={`${type}Ctc`}
        name={`${type}Ctc`}
        label="Total Cost Client"
        type="text"
        disabled={!isNegativeAmountRuleActive}
      />
    </Grid>
  </>
);

export default NegativeAmountFields;
