import { styled, Table as MuiTable } from "@mui/material";
import {
  SIDE_BAR_OPEN_WIDTH,
  APP_BAR_HEIGHT
} from "../../../../configurations/appConstants";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "AdditionalApprovalTableStyle";
export const classes = {
  table: `${PREFIX}-table`,
  tableCell: `${PREFIX}-tableCell`
};

export const Table = styled(MuiTable)(({ theme }) => ({
  [`&.${classes.table}`]: {
    minWidth: theme.typography.pxToRem(window.innerWidth - SIDE_BAR_OPEN_WIDTH),
    marginTop: theme.typography.pxToRem(getThemeSpacing(theme, 3)),
    overflowX: "auto"
  },
  [`& .${classes.tableCell}`]: {
    border: "none"
  }
}));
