import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

import { IconButton, Typography, Tooltip, Hidden } from "@mui/material";

import { CheckBoxIcon, UncheckBoxIcon } from "../../../assets/styles/icons";
import { canEditCampaign } from "../../../business/selectors/userProfileSelectors";
import { toggleSetActualDialog } from "../../../business/actions/campaignDashboardActions";
import { getPropertyTextFromApiData } from "../../../business/models/Plan/planSelectors";
import { getCampaignData } from "../../../business/models/Campaign/campaignSelectors";
import { PLACEHOLDER } from "../../../configurations/appConstants";
import { classes, Grid } from "../styles/planPanelFold";

const PlanPanelFold = ({ id, isActual, numberOfOrders, isInCopyProcess }) => {
  const dispatch = useDispatch();
  const disableAction = !useSelector(canEditCampaign);
  const planAgreement =
    useSelector(state =>
      getPropertyTextFromApiData(state, { name: "planAgreementId", planId: id })
    ) || PLACEHOLDER;
  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const handleSelectActual = e => {
    e.stopPropagation();
    dispatch(toggleSetActualDialog());
  };
  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end">
      {isDebtorActive && (
        <Grid item>
          <Hidden xsUp={isInCopyProcess}>
            <Typography
              align="right"
              className={classes.typographyDetailsValue}
            >
              <IconButton
                onClick={handleSelectActual}
                className={classes.actualIconButton}
                disabled={disableAction}
              >
                {isActual ? <CheckBoxIcon /> : <UncheckBoxIcon />}
              </IconButton>
            </Typography>
            <Typography
              name={`p_checkActual_${id}`}
              id={`p_checkActual_${id}`}
              align="right"
              className={classes.typographyCaption}
            >
              Actual
            </Typography>
          </Hidden>
        </Grid>
      )}
      <Grid item>
        <Typography
          id="numberOfOrders"
          name="numberOfOrders"
          align="right"
          className={clsx(
            classes.planPanelItem,
            classes.typographyDetailsValue
          )}
        >
          {numberOfOrders}
        </Typography>
        <Typography align="right" className={classes.typographyCaption}>
          Orders
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title={planAgreement} placement="bottom-start">
          <Typography
            id="planAgreement"
            name="planAgreement"
            noWrap
            align="right"
            className={clsx(
              classes.planPanelItem,
              classes.typographyDetailsValue
            )}
          >
            {planAgreement}
          </Typography>
        </Tooltip>
        <Typography align="right" className={classes.typographyCaption}>
          Agreement
        </Typography>
      </Grid>
    </Grid>
  );
};

PlanPanelFold.propTypes = {
  isActual: PropTypes.bool,
  numberOfOrders: PropTypes.number,
  planAgreement: PropTypes.string
};

export default PlanPanelFold;
