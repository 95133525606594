export const PLAN_SELECT = "PLAN_SELECT";
export const PLAN_EDIT_START = "PLAN_EDIT_START";
export const PLAN_EDIT_STOP = "PLAN_EDIT_STOP";
export const PLAN_CLEAR_CURRENT = "PLAN_CLEAR_CURRENT";
export const PLAN_RESET_CURRENT = "PLAN_RESET_CURRENT";
export const PLAN_DISPLAY_ERRORS = "PLAN_DISPLAY_ERRORS";
export const PLAN_HIDE_ERRORS = "PLAN_HIDE_ERRORS";
export const PLAN_SET_IS_NEW = "PLAN_SET_IS_NEW";
export const PLAN_RESET_IS_NEW = "PLAN_RESET_IS_NEW";
export const PLAN_SELECT_ORDER_STATUS = "PLAN_SELECT_ORDER_STATUS";
export const PLAN_SELECT_ORDER_NEXT_STATUS = "PLAN_SELECT_ORDER_NEXT_STATUS";
export const PLAN_SELECTED_ORDERS = "PLAN_SELECTED_ORDERS";
export const PLAN_CLEAR_ORDER_STATUS = "PLAN_CLEAR_ORDER_STATUS";
export const PLAN_CLEAR_ORDERS = "PLAN_CLEAR_ORDERS";
export const PLAN_INSERT_ORDER_FOR_DIVIDE = "PLAN_INSERT_ORDER_FOR_DIVIDE";
export const PLAN_REMOVE_ORDER_FOR_DIVIDE = "PLAN_REMOVE_ORDER_FOR_DIVIDE";
export const PLAN_CLEAR_ORDERS_FOR_DIVIDE = "PLAN_CLEAR_ORDERS_FOR_DIVIDE";
export const PLAN_INITIALIZE_INSERTION_DATES =
  "PLAN_INITIALIZE_INSERTION_DATES";
export const PLAN_UPDATE_INSERTION_DATES = "PLAN_UPDATE_INSERTION_DATES";
export const PLAN_CLEAR_INSERTION_DATES = "PLAN_CLEAR_INSERTION_DATES";

export const PLAN_SUCCESS_MESSAGE = "Plan saved successfully";
export const PLAN_CREATE_FLIGHT_SUCCESS_MESSAGE =
  "Flight is created in MediaView with the following ordernumbers: ";
export const PLAN_UPDATE_FLIGHT_SUCCESS_MESSAGE =
  "Flight is updated in MediaView with the following ordernumbers: ";
export const PLAN_SET_ACTUAL_MESSAGE = "Actual was saved successfully";
export const PLAN_DIVIDE_ORDERS_INVOICE_DATE_NULL_ERROR_MESSAGE =
  "Invoice date is a mandatory field";
export const PLAN_DIVIDE_ORDERS_INVOICE_DATE_UNIQUE_ERROR_MESSAGE =
  "Invoice dates must be unique";
export const PLAN_DIVIDE_ORDERS_SPLIT_PERCENTAGE_NULL_ERROR_MESSAGE =
  "Split Percentage is a mandatory field";
export const PLAN_DIVIDE_ORDERS_SPLIT_PERCENTAGE_INCORRECT_ERROR_MESSAGE =
  "Sum of Split Percentages should be 100";
export const PLAN_DIVIDE_ORDERS_ERROR_MESSAGE =
  "An error occured. Order(s) {0} were not splitted in insertions.";
export const PLAN_DIVIDE_ORDERS_SUCCESS_MESSAGE =
  "Order(s) {0} successfully splitted in insertions";

export const PLAN_MODEL_NAME = "Plan";
export const PLAN_MODEL_ID = "planId";
export const PLAN_MODEL_FLIGHT_ID = "flightId";
export const PLAN_RESPONSE_ORDER_NUMBERS = "orderNumbers";
export const PLAN_MODEL_SENT_TO_MBS = "sendToMbs";
export const PLANS_PROPERTY = "plans";
export const INVOICE_DATE_PROPERTY = "invoiceDate";
export const PO_NUMBER_PROPERTY = "poNumber";
export const SPLIT_PERCENTAGE_PROPERTY = "splitPercentage";

export const PLAN_PLANNER_ROLE_ID = 2;

export const PLAN_SET_FLIGHT_MBS = "PLAN_SET_FLIGHT_MBS";
export const PLAN_RESET_FLIGHT_MBS = "PLAN_RESET_FLIGHT_MBS";
export const PLAN_SET_PLAN_MBS = "PLAN_SET_PLAN_MBS";
export const PLAN_RESET_PLAN_MBS = "PLAN_RESET_PLAN_MBS";
export const PLAN_UPDATE_IS_DIVIDE_INSERTION_EVENLY =
  "PLAN_UPDATE_IS_DIVIDE_INSERTION_EVENLY";

export const DELETE_TARGET_METRICS_KPI_PROPERTY = "isTargetMetricsKpisDeleted";
