export const PLAN_TARGET_METRICS_KPI_MODEL_ID = "planTargetMetricsKpiId";
export const PLAN_TARGET_METRICS_KPI_MODEL_NAME = "PlanTargetMetricsKpi";
export const PLAN_TARGET_METRICS_KPI_PROPERTY_NAME = "planTargetMetricsKpis";

export const PLAN_TARGET_METRICS_KPI_CLEAR_CURRENT =
  "PLAN_TARGET_METRIC_KPI_CLEAR_CURRENT";
export const PLAN_TARGET_METRICS_KPI_SELECT = "PLAN_TARGET_METRIC_KPI_SELECT";
export const PLAN_TARGET_METRICS_KPI_EDIT_START =
  "PLAN_TARGET_METRICS_KPI_EDIT_START";
export const PLAN_TARGET_METRICS_KPI_EDIT_STOP =
  "PLAN_TARGET_METRICS_KPI_EDIT_STOP";
export const PLAN_TARGET_METRICS_KPI_HIDE_ERRORS =
  "PLAN_TARGET_METRICS_KPI_HIDE_ERRORS";
export const PLAN_TARGET_METRICS_KPI_DISPLAY_ERRORS =
  "PLAN_TARGET_METRICS_KPI_DISPLAY_ERRORS";
export const PLAN_TARGET_METRICS_KPI_SET_EDITING_ORIGINAL_ITEM =
  "PLAN_TARGET_METRICS_KPI_SET_EDITING_ORIGINAL_ITEM";
export const PLAN_TARGET_METRICS_KPI_CLEAR_EDITING_ORIGINAL_ITEM =
  "PLAN_TARGET_METRICS_KPI_CLEAR_EDITING_ORIGINAL_ITEM";

export const ADD_TEXT = "Add";
export const EDIT_TEXT = "Edit";
