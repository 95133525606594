import React from "react";
import { useSelector } from "react-redux";
import { ListItem } from "@mui/material";
import ActionInsertionItem from "./extensions/ActionInsertionItem";
import { getInsertionsIDs } from "../../../../../business/models/Insertion/insertionSelectors";
import { classes, List } from "./styles/tablePanel";

const InsertionsView = () => {
  const insertions = useSelector(getInsertionsIDs);

  return (
    <List className={classes.table}>
      {insertions.map((id, index, { length }) => (
        <ListItem
          key={id}
          divider={length !== index + 1}
          className={classes.tableRow}
        >
          <ActionInsertionItem id={id} />
        </ListItem>
      ))}
    </List>
  );
};

export default InsertionsView;
