import { styled, Grid as MuiGrid, Switch as MuiSwitch } from "@mui/material";
import {
  baliHai,
  white,
  supernova,
  dodgerBlue,
  solitude,
  cancelGray,
  linkWater,
  black
} from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "ActionInsertionItemStyles";
export const classes = {
  root: `${PREFIX}-root`,
  redirectButton: `${PREFIX}-redirectButton`,
  changeSection: `${PREFIX}-changeSection`,
  buttonSave: `${PREFIX}-buttonSave`,
  colorSuperNova: `${PREFIX}-colorSuperNova`,
  fixedSize: `${PREFIX}-fixedSize`,
  buttonSpace: `${PREFIX}-buttonSpace`,
  buttonApply: `${PREFIX}-buttonApply`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.typography.pxToRem(getThemeSpacing(theme, 2))
  },
  [`& .${classes.redirectButton}`]: {
    color: black,
    "&:disabled": {
      color: linkWater
    }
  },
  [`& .${classes.changeSection}`]: {
    display: "inline-block",
    padding: `0 ${theme.typography.pxToRem(getThemeSpacing(theme, 2))}`,
    margin: `0 ${theme.typography.pxToRem(getThemeSpacing(theme, 2))}`,
    borderRight: `${theme.typography.pxToRem(1)} solid ${solitude}`
  },
  [`& .${classes.buttonSave}`]: {
    color: white,
    "&:hover": {
      backgroundColor: cancelGray
    },
    "&:disabled": {
      backgroundColor: solitude,
      color: linkWater
    }
  },
  [`& .${classes.colorSuperNova}`]: {
    backgroundColor: supernova
  },
  [`& .${classes.fixedSize}`]: {
    width: theme.typography.pxToRem(200)
  },
  [`& .${classes.buttonSpace}`]: {
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 2))
  },
  [`& .${classes.buttonApply}`]: {
    backgroundColor: dodgerBlue
  }
}));

export const Switch = styled(MuiSwitch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    marginTop: theme.typography.pxToRem(8),
    marginLeft: theme.typography.pxToRem(8)
  }
}));
