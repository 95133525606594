const lilac = "#c590c0";
const deYork = "#87cb9c";
const jordyBlue = "#77b0dd";
const lochmara = "#3376aa";
const crusta = "#f47f64";
const supernova = "#ffb32c";
const dodgerBlue = "#0080ff";
const dodgerBlue20 = "rgba(0, 128, 255, 0.2)";
const sapphire = "#0a2756";
const matisse = "#3b5277";
const baliHai = "#8997ae";
const greyChateau = "#9DA2AB";
const linkWater = "#c4cbd6";
const solitude = "#ebeef2";
const azureRadiance = "#0080FF";
const softRed = "#FC5959";
const bittersweet = "#FF6161";
const white = "#ffffff";
const black = "#000000";
const lavender = "#DDEBF7";
const shipCove = "#7B83A3";
const darkIndigo = "#1a237e";
const lightGrey = "#EBECEE";
const cancelRed = "#f50057";
const cancelGray = "#e0e0e0";
const lightBlue = "#E5F6FD";

const colorsEnum = {
  lilac,
  deYork,
  jordyBlue,
  lochmara,
  crusta,
  supernova,
  dodgerBlue,
  dodgerBlue20,
  sapphire,
  matisse,
  baliHai,
  greyChateau,
  linkWater,
  solitude,
  azureRadiance,
  softRed,
  bittersweet,
  white,
  black,
  lavender,
  shipCove,
  lightGrey,
  cancelRed,
  cancelGray,
  lightBlue
};

Object.freeze(colorsEnum);

export {
  lilac,
  deYork,
  jordyBlue,
  lochmara,
  crusta,
  supernova,
  dodgerBlue,
  dodgerBlue20,
  sapphire,
  matisse,
  baliHai,
  greyChateau,
  linkWater,
  solitude,
  white,
  azureRadiance,
  softRed,
  bittersweet,
  black,
  lavender,
  shipCove,
  darkIndigo,
  lightGrey,
  cancelRed,
  cancelGray,
  lightBlue
};

export default colorsEnum;
