import React, { useState } from "react";
import PropTypes from "prop-types";

import { IconButton, Tooltip, ClickAwayListener } from "@mui/material";

import { OrderFinancialsDigitalIcon } from "../../../assets/styles/icons";

import TrafficLineTooltipText from "./TrafficLineTooltipText";
import { classes, Grid } from "../styles/trafficLine";

const TrafficLineTooltip = ({
  marathonOrderId,
  orderNumOfUnits,
  costType,
  netTotal,
  netPerUnit
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = e => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleTooltipOpen = e => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <Grid>
      <ClickAwayListener
        onClickAway={handleTooltipClose}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <div>
          <Tooltip
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <TrafficLineTooltipText
                marathonOrderId={marathonOrderId}
                costType={costType}
                orderNumOfUnits={orderNumOfUnits}
                netTotal={netTotal}
                netPerUnit={netPerUnit}
              />
            }
          >
            <IconButton
              onClick={handleTooltipOpen}
              className={classes.iconButton}
              id="ti_financial"
              name="ti_financial"
            >
              <OrderFinancialsDigitalIcon />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </Grid>
  );
};

TrafficLineTooltip.propTypes = {
  marathonOrderId: PropTypes.number,
  orderNumOfUnits: PropTypes.number,
  costType: PropTypes.string,
  netTotal: PropTypes.number,
  netPerUnit: PropTypes.number
};

export default TrafficLineTooltip;
