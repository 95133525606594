import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField
} from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import {
  addPublicPermission,
  setIsOpenAddPublicPermission
} from "../../../../business/actions/apiManagementActions";
import AutoComplete from "../../../form/controls/AutoComplete";
import { EMPTY_STRING } from "../../../../configurations/appConstants";
import {
  CREATE_PUBLIC_PERMISSION,
  resourceOptions
} from "../../../../business/constants/apiManagementConstants";
import { MANDATORY_MESSAGE } from "../../../../business/constants/validationConstants";
import { classes, Grid } from "../styles/addPublicPermissionDialogStyle";

const paperProps = { square: true };

const AddPublicPermissionDialog = () => {
  const dispatch = useDispatch();
  const [resource, setResource] = useState(EMPTY_STRING);
  const [applicationId, setApplicationId] = useState(EMPTY_STRING);
  const [applicationError, setApplicationError] = useState(false);
  const [resourceError, setResourceError] = useState([]);

  const handleChange = selectedResource => {
    setResourceError([]);
    setResource(selectedResource);
  };

  const handleApplicationChange = e => {
    setApplicationError(false);
    setApplicationId(e.target.value);
  };

  const handleCancel = () => {
    dispatch(setIsOpenAddPublicPermission());
  };

  const handleSave = () => {
    if (applicationId === EMPTY_STRING) {
      setApplicationError(true);
    }

    if (resource === EMPTY_STRING) {
      setResourceError(resourceErr => [...resourceErr, MANDATORY_MESSAGE]);
    }

    if (resource === EMPTY_STRING || applicationId === EMPTY_STRING) {
      return;
    }

    dispatch(addPublicPermission(applicationId, resource));
  };

  return (
    <Dialog open PaperProps={paperProps} maxWidth="md">
      <DialogTitle id="add-public-permission-dialog-title">
        {CREATE_PUBLIC_PERMISSION}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.root}>
            <TextField
              id="add_public_permission"
              name="add_public_permission"
              label="Application ID *"
              type="text"
              value={applicationId}
              onChange={handleApplicationChange}
              fullWidth
              size="medium"
              variant="outlined"
              error={applicationError}
              helperText={applicationError && MANDATORY_MESSAGE}
            />
          </Grid>
          <Grid item xs={12}>
            <AutoComplete
              id="select_digital-export-version"
              name="select_digital-export-version"
              options={resourceOptions}
              value={resource}
              onChange={handleChange}
              label="Select resource *"
              errors={resourceError}
              displayErrors={resourceError.length !== 0}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPublicPermissionDialog;
