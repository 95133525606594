import React from "react";
import PropTypes from "prop-types";

import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import ChipAddWrapper from "../../styles/chipAdd";

const ChipAdd = ({ add }) => {
  return (
    <ChipAddWrapper
      name="add_newFilter"
      id="add_newFilter"
      color="primary"
      icon={<AddCircleIcon color="primary" />}
      onClick={add}
      label="Add filter"
      variant="outlined"
    />
  );
};

ChipAdd.propTypes = {
  add: PropTypes.func
};

export default ChipAdd;
