import React, { useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import includes from "lodash/includes";
import filter from "lodash/filter";
import concat from "lodash/concat";

import {
  useQueryParams,
  StringParam,
  BooleanParam,
  DelimitedArrayParam
} from "use-query-params";

import TrafficTable from "./table/TrafficTable";

import {
  loadItems,
  resetPage
} from "../../../business/actions/trafficPageActions";

import {
  selectCount,
  selectItems
} from "../../../business/selectors/trafficPageSelectors";

import {
  selectIsLoading,
  hasEditTraffickerDialogOpened
} from "../../../business/selectors/applicationSelectors";

import {
  assignTrafficPlan,
  unassignTrafficPlan,
  selectTrafficPlan,
  editTrafficker
} from "../../../business/models/TrafficPlan/trafficPlanActions";
import {
  closeEditTraffickerDialog,
  openEditTraffickerDialog
} from "../../../business/actions/applicationActions";
import EditTraffickerDialog from "../traffic-dashboard/dialog/EditTraffickerDialog";
import { selectUserName } from "../../../business/selectors/userProfileSelectors";
import {
  CAMPAIGN_ARCHIVED_STATUS,
  TRAFFIC_CAMPAIGN_NEW_STATUS,
  EMPTY_STRING
} from "../../../configurations/appConstants";

const filterQuerys = {
  campaignid: StringParam,
  campaignname: StringParam,
  planid: StringParam,
  planname: StringParam,
  orderid: StringParam,
  debtorname: StringParam,
  processmanager: StringParam,
  trafficker: StringParam
};

const TrafficOverviewPage = () => {
  const dispatch = useDispatch();
  const { trafficCampaignStatus: status, agency } = useParams();
  const navigate = useNavigate();

  const rows = useSelector(selectItems);
  const count = useSelector(selectCount);
  const isOpenEditTraffickerDialogOpen = useSelector(
    hasEditTraffickerDialogOpened
  );
  const isLoading = useSelector(selectIsLoading);
  const username = useSelector(selectUserName);

  const [
    currentTraffickerForEditing,
    setCurrentTraffickerForEditing
  ] = useState(EMPTY_STRING);

  const [query, setQuery] = useQueryParams({
    ...filterQuerys,
    searchMyTrafficPlans: BooleanParam,
    searchMediaTypes: DelimitedArrayParam,
    sort: StringParam,
    currentPage: StringParam,
    pageSize: StringParam
  });

  const {
    searchMyTrafficPlans,
    searchMediaTypes,
    sort,
    campaignid,
    campaignname,
    planid,
    planname,
    orderid,
    debtorname,
    processmanager,
    trafficker,
    pageSize = 10,
    currentPage = 0
  } = query;

  const handleDynamicFilter = useCallback(
    filterQuery => {
      setQuery({ ...filterQuery, currentPage: 0 }, "pushIn");
    },
    [setQuery]
  );

  const areActionsDisabled = status === CAMPAIGN_ARCHIVED_STATUS.toLowerCase();

  const displaySearchMyTrafficPlans =
    status !== TRAFFIC_CAMPAIGN_NEW_STATUS.toLowerCase();

  const statusForFilterOptionsTrafficker =
    status !== TRAFFIC_CAMPAIGN_NEW_STATUS.toLowerCase();
  const handleSearchMediaTypes = mediaType => {
    setQuery(
      {
        searchMediaTypes: includes(searchMediaTypes, mediaType)
          ? filter(searchMediaTypes, val => val !== mediaType)
          : concat(searchMediaTypes, mediaType),
        currentPage: 0
      },
      "pushIn"
    );
  };
  const handleSortChange = event => {
    setQuery({ sort: event.target.value, currentPage: 0 }, "pushIn");
  };

  const handleSearchMyTrafficPlans = useCallback(() => {
    setQuery(
      { searchMyTrafficPlans: !searchMyTrafficPlans, currentPage: 0 },
      "pushIn"
    );
  }, [searchMyTrafficPlans, setQuery]);

  const handleChangePage = (_, page) => {
    setQuery({ currentPage: page }, "pushIn");
  };

  const handleChangeRowsPerPage = event => {
    setQuery({ pageSize: event.target.value, currentPage: 0 }, "pushIn");
  };

  const handleInputSearch = event => {
    setQuery({ searchText: event.target.value, currentPage: 0 }, "pushIn");
  };

  const handleClear = () => {
    setQuery({}, "push");
  };

  const handleAssignToMe = (id, isAssigned) => {
    if (isAssigned) {
      dispatch(
        unassignTrafficPlan(id, { ...query, status, currentPage, pageSize })
      );
    } else {
      dispatch(
        assignTrafficPlan(id, { ...query, status, currentPage, pageSize })
      );
    }
  };

  const handleEditTraffickerOpen = (trafficPlanId, selectedTrafficker) => {
    if (areActionsDisabled) return;
    setCurrentTraffickerForEditing(selectedTrafficker);
    dispatch(selectTrafficPlan(trafficPlanId));
    dispatch(openEditTraffickerDialog());
  };

  const handleEditTrafficker = traffickerr => {
    dispatch(
      editTrafficker(traffickerr, {
        ...query,
        status,
        currentPage,
        pageSize
      })
    );
    dispatch(closeEditTraffickerDialog());
  };

  // Actions
  const handleOpenClick = id => {
    navigate(`/${agency}/traffic/${id}`);
  };

  const handleEditTraffickerClose = () => dispatch(closeEditTraffickerDialog());

  useEffect(() => {
    dispatch(
      loadItems({
        currentPage,
        pageSize,
        status,
        searchMyTrafficPlans,
        searchMediaTypes,
        sort,
        campaignid,
        campaignname,
        planid,
        planname,
        orderid,
        debtorname,
        processmanager,
        trafficker
      })
    );
  }, [
    currentPage,
    pageSize,
    searchMediaTypes,
    searchMyTrafficPlans,
    sort,
    status,
    campaignid,
    campaignname,
    planid,
    planname,
    orderid,
    debtorname,
    processmanager,
    trafficker,
    dispatch
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetPage());
    };
  }, [dispatch]);

  return (
    <>
      <TrafficTable
        rows={rows}
        count={count}
        pageSize={Number(pageSize)}
        currentPage={Number(currentPage)}
        searchMyTrafficPlans={searchMyTrafficPlans}
        searchMediaTypes={searchMediaTypes}
        sort={sort}
        displaySearchMyTrafficPlans={displaySearchMyTrafficPlans}
        contentLoading={isLoading}
        onClear={handleClear}
        onSortChange={handleSortChange}
        onInputSearch={handleInputSearch}
        onSearchMyTrafficPlans={handleSearchMyTrafficPlans}
        onSearchMediaTypes={handleSearchMediaTypes}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onOpenClick={handleOpenClick}
        handleAssignToMe={handleAssignToMe}
        handleEditTraffickerDialogOpen={handleEditTraffickerOpen}
        onDynamicFilter={handleDynamicFilter}
        username={username}
        areActionsDisabled={areActionsDisabled}
        status={statusForFilterOptionsTrafficker}
        queryFilters={{
          campaignid,
          campaignname,
          planid,
          planname,
          orderid,
          debtorname,
          processmanager,
          trafficker
        }}
      />
      {isOpenEditTraffickerDialogOpen && (
        <EditTraffickerDialog
          handleCancel={handleEditTraffickerClose}
          handleAdd={handleEditTrafficker}
          trafficker={currentTraffickerForEditing}
        />
      )}
    </>
  );
};

export default TrafficOverviewPage;
