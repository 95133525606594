import React from "react";
import { useSelector } from "react-redux";
import PlanPanelItem from "./extensions/PlanPanelItem";
import Scrollbar from "../shared/Scrollbar";

import { getPlans } from "../../business/models/Plan/planSelectors";

const PlanPanel = () => {
  const plans = useSelector(getPlans);

  return (
    <Scrollbar>
      {plans.map(plan => (
        <PlanPanelItem key={plan.id} plan={plan} />
      ))}
    </Scrollbar>
  );
};

export default PlanPanel;
