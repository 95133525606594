import React from "react";
import PropTypes from "prop-types";

import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";

import { Tooltip } from "@mui/material";

import CtcTooltipText from "../../shared/ctc/CtcTooltipText";
import { getOrderCalculationData } from "../../../business/models/Order/orderSelectors";
import { Chip } from "../styles/ctcChip";

const CtcChip = ({
  orderId,
  surcharge1Amount,
  surcharge2Amount,
  showEurCurrency,
  currencyClient,
  currencySupplierId,
  currencySupplierSymbol,
  className,
  exchangeRateClient,
  exchangeRateSupplier
}) => {
  const calculation = useSelector(state =>
    getOrderCalculationData(state, { orderId })
  );

  const { fee, netNetTotal, totalCostClient } = calculation;

  return (
    <Tooltip
      title={
        <CtcTooltipText
          showCtc={false}
          net={netNetTotal}
          fee={fee}
          surcharge1={surcharge1Amount}
          surcharge2={surcharge2Amount}
          ctc={totalCostClient}
          netEur={netNetTotal}
          feeEur={fee}
          surcharge1Eur={surcharge1Amount}
          surcharge2Eur={surcharge2Amount}
          ctcEur={totalCostClient}
          showEurCurrency={showEurCurrency}
          showCurrency={!showEurCurrency}
          currencyClient={currencyClient}
          currencySupplier={currencySupplierId}
          exchangeRateClient={exchangeRateClient}
          exchangeRateSupplier={exchangeRateSupplier}
        />
      }
      placement="bottom-start"
    >
      <div>
        <Chip
          id={`totalCostClient_${orderId}`}
          name={`totalCostClient_${orderId}`}
          component={NumberFormat}
          decimalScale={2}
          value={totalCostClient}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix={`${currencySupplierSymbol} `}
          className={className}
          fixedDecimalScale
        />
      </div>
    </Tooltip>
  );
};

CtcChip.propTypes = {
  orderId: PropTypes.number,
  surcharge1Amount: PropTypes.number,
  surcharge2Amount: PropTypes.number,
  showEurCurrency: PropTypes.bool,
  currencySupplierId: PropTypes.string,
  currencySupplierSymbol: PropTypes.string
};

export default CtcChip;
