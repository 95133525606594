export const SET_COUNT = "SET_COUNT";
export const SET_TRAFFIC_PAGE_ITEMS = "SET_TRAFFIC_PAGE_ITEMS";
export const UPDATE_TRAFFICKER = "UPDATE_TRAFFICKER";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const RESET_TRAFFIC_OVERVIEW_PAGE = "RESET_TRAFFIC_OVERVIEW_PAGE";
export const SET_TRAFFIC_OVERVIEW_TAB_COUNTERS =
  "SET_TRAFFIC_OVERVIEW_TAB_COUNTERS";
// ***STATUS*** //
export const NOT_REQUESTED = "notRequested";
export const REQUESTED = "requested";
export const READY_FOR_EVALUATION = "readyForEvaluation";
export const CANCELLED = "cancelled";
