import React from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import clsx from "clsx";
import toUpper from "lodash/toUpper";
import toLower from "lodash/toLower";

import {
  Checkbox,
  AccordionSummary,
  Typography,
  Grid,
  IconButton,
  Tooltip
} from "@mui/material";

import ClipboardTooltip from "../../shared/ClipboardTooltip";

import {
  MediaTypeIcon,
  FingerprintIcon,
  AssignIcon,
  UnassignIcon,
  ExportVersionIcon,
  InternalRemarkIcon,
  EmailIcon,
  PreviewIcon,
  DownloadIcon
} from "../../../assets/styles/icons";
import {
  PLACEHOLDER,
  TRAFFIC_PAGE
} from "../../../configurations/appConstants";
import {
  selectTrafficPlanIsSelectable,
  selectSelectedMultipleEditTrafficItemsByTrafficPlan,
  isTrafficPlanVirtual,
  selectTrafficItemStatusChangeSelectedByTrafficPlan,
  getTrafficItemsSelectableForStatusChangeCount,
  getTrafficItemsCount,
  selectCurrentTrafficPlanId,
  selectIsFinalized,
  isSelectedTrafficDigital,
  isTrafficInEditMode,
  selectIsTrafficPlanSelectableForStatusChange
} from "../../../business/models/TrafficPlan/trafficPlanSelectors";
import {
  selectTrafficItemActionSwitch,
  selectAgency,
  selectIsLoading
} from "../../../business/selectors/applicationSelectors";
import {
  selectCanDownloadTrafficExcel,
  selectCanSendEmail,
  canViewTrafficPlanAssignToMeButton,
  selectUserName,
  selectCanDownloadVersion
} from "../../../business/selectors/userProfileSelectors";
import {
  toggleMultipleEditTrafficPlanTrafficItems,
  trafficItemStatusChangeToggleSelectedMultiple,
  emailTraffic,
  assignTrafficPlanDashboard,
  unassignTrafficPlanDashboard,
  stopEditingTrafficPlanRemark,
  openTrafficPlanEmailDialog,
  openTrafficPlanRemarkDialog,
  getPreviewData,
  openPreviewDialog,
  setMediaTypeId,
  loadExportVersions,
  downloadTraffic
} from "../../../business/models/TrafficPlan/trafficPlanActions";
import {
  addNewTrafficPlanRemark,
  saveTrafficPlanRemark
} from "../../../business/models/TrafficPlanRemark/trafficPlanRemarkActions";
import { isTrafficItemInEditMode } from "../../../business/models/TrafficItem/trafficItemSelectors";
import {
  openTrafficExportVersionDialog,
  openTrafficExportDigitalVersionDialog
} from "../../../business/actions/applicationActions";
import { selectCurrentPlan } from "../../../business/models/Plan/planSelectors";
import { classes, Accordion, Badge } from "../styles/item";
import withRouter from "../../pages/configuration/form/withRouter";

const TrafficPlanItemElement = ({
  openExportVersionDialog,
  openExportDigitalVersionDialog,
  id,
  assignToMe,
  unassignFromMe,
  traffic,
  isActive,
  isTrafficItemEdit,
  isTrafficPlanEdit,
  showAssignToMeButton,
  currentUser,
  disabledExport,
  multipleEditSelectedNumber,
  statusChangeSelectedNumber,
  trafficItemsCount,
  switchChecked,
  handleSelectAllForMultipleEdit,
  handleSelectAllForStatusChange,
  isTrafficPlanSelectable,
  isTrafficPlanSelectableForStatusChange,
  isFinalized,
  disabledPreview,
  disabledEmail,
  isVirtual,
  trafficItemsSelectableForStatusChangeCount,
  canDownloadTrafficExcel,
  canSendEmail,
  currentPlan,
  navigate,
  getPreviewData: getPreview,
  openPreviewDialog: openPreview,
  openTrafficPlanEmailDialog: openEmailDialog,
  openTrafficPlanRemarkDialog: openRemarkDialog,
  setMediaTypeId: setMediaType,
  canDownloadVersion,
  loadVersions,
  currentTrafficPlan,
  addNewRemark,
  isSelectedTrafficDigitalValue,
  downloadTrafficExcel
}) => {
  const {
    mediaTypeName,
    planName,
    planMarathonId,
    contactGroupM,
    hasUnreadTrafficPlanRemarks,
    mediaTypeId,
    isDebtorActive
  } = traffic || {};

  const { agency } = useParams();

  const changeUrl = () =>
    navigate(`/${agency}/${TRAFFIC_PAGE}/${currentPlan}/${id}`);

  const setCurrent = () => {
    if (
      !isTrafficPlanEdit &&
      !isTrafficItemEdit &&
      id &&
      id !== currentTrafficPlan
    ) {
      changeUrl(id);
    }
  };

  const handleOnChange = e => {
    e.stopPropagation();
    setCurrent();
  };

  const handleOnCopy = e => {
    e.stopPropagation();
  };

  const onExportClick = e => {
    e.stopPropagation();
    if (isSelectedTrafficDigitalValue) {
      openExportDigitalVersionDialog();
    } else {
      downloadTrafficExcel(id, 0);
    }
  };

  const onExportVersionClick = e => {
    e.stopPropagation();
    loadVersions(id);
    setCurrent(id);
    openExportVersionDialog();
  };
  const onEmailClick = e => {
    e.stopPropagation();
    setCurrent(id);
    openEmailDialog();
  };

  const handleAssignToMe = e => {
    e.stopPropagation();
    assignToMe(id);
  };

  const handleUnassignFromMe = e => {
    e.stopPropagation();
    unassignFromMe(id);
  };

  const handleSelectAllForMultipleEditClick = e => {
    e.stopPropagation();
    handleSelectAllForMultipleEdit(id, e.target.checked);
  };
  const handleRemarksClick = event => {
    event.stopPropagation();
    setCurrent(id);
    addNewRemark(id);
    openRemarkDialog();
  };
  const onPreviewClick = e => {
    e.stopPropagation();
    getPreview(id, mediaTypeName).then(() => {
      setMediaType(mediaTypeId);
      openPreview();
    });
  };

  const handleSelectAllForStatusChangeClick = e => {
    e.stopPropagation();
    handleSelectAllForStatusChange(id, e.target.checked);
  };

  const disableDefaultTraffic =
    !isActive || isVirtual || isTrafficPlanEdit || isTrafficItemEdit;
  const disableExport =
    disabledExport || !canDownloadTrafficExcel || disableDefaultTraffic;
  const disableEmail = disabledEmail || !canSendEmail || disableDefaultTraffic;
  const disablePreview = disabledPreview || disableDefaultTraffic;
  const toggleAssignToMe = currentUser !== contactGroupM;

  return (
    <Accordion
      square
      elevation={0}
      onChange={handleOnChange}
      className={clsx(classes.expensionPanel, {
        [classes.selected]: isActive
      })}
      expanded={false}
    >
      <AccordionSummary
        classes={{ content: classes.expansionPanelSummeryContent }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              spacing={1}
              alignItems="baseline"
              justifyContent="space-between"
            >
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item>
                    <MediaTypeIcon
                      type={toLower(mediaTypeName)}
                      isActive={isActive}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.gridItemMediaTypeName}>
                    <Typography
                      id="mediaTypeName"
                      name="mediaTypeName"
                      className={clsx(classes.typographyPlanHeaderCaption, {
                        [classes.activeTypographyPlanHeaderCaption]: isActive
                      })}
                    >
                      {toUpper(mediaTypeName) || PLACEHOLDER}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FingerprintIcon isActive={isActive} />
                  </Grid>
                  <Grid item>
                    <Typography
                      id="planMarathonId"
                      name="planMarathonId"
                      className={clsx(classes.typographyPlanHeaderCaption, {
                        [classes.activeTypographyPlanHeaderCaption]: isActive
                      })}
                    >
                      {planMarathonId || PLACEHOLDER}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Internal remark">
                      <span>
                        <IconButton
                          id="internal_remark"
                          name="internal_remark"
                          className={clsx(
                            classes.iconButton,
                            classes.iconButtonRemark
                          )}
                          onClick={handleRemarksClick}
                          disabled={disableDefaultTraffic}
                        >
                          <Badge
                            id="indicator_internal_remark"
                            name="indicator_internal_remark"
                            variant="dot"
                            invisible={!hasUnreadTrafficPlanRemarks}
                            color="secondary"
                          >
                            <InternalRemarkIcon
                              isActive={isActive}
                              disabled={disableDefaultTraffic}
                            />
                          </Badge>
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  {showAssignToMeButton && isDebtorActive && (
                    <Grid item>
                      {toggleAssignToMe ? (
                        <Tooltip title="Assign To Me">
                          <span>
                            <IconButton
                              disabled={disableDefaultTraffic}
                              onClick={handleAssignToMe}
                              id="assignToMe"
                              name="assignToMe"
                              className={classes.iconButton}
                            >
                              <AssignIcon
                                isActive={isActive}
                                disabled={disableDefaultTraffic}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Unassign To Me">
                          <span>
                            <IconButton
                              disabled={disableDefaultTraffic}
                              id="unassignToMe"
                              name="unassignToMe"
                              onClick={handleUnassignFromMe}
                              className={classes.iconButton}
                            >
                              <UnassignIcon
                                isActive={isActive}
                                disabled={disableDefaultTraffic}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <Tooltip title="Export">
                      <span>
                        <IconButton
                          className={classes.iconButton}
                          onClick={onExportClick}
                          id="tp_export"
                          name="tp_export"
                          disabled={disableExport}
                        >
                          <DownloadIcon
                            isActive={isActive}
                            disabled={disableExport}
                            fontSize={14}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    {canDownloadVersion && (
                      <Tooltip title="Export version">
                        <span>
                          <IconButton
                            className={classes.iconButton}
                            id="export_version"
                            name="export_version"
                            onClick={onExportVersionClick}
                            disabled={disableDefaultTraffic}
                          >
                            <ExportVersionIcon
                              isActive={isActive}
                              disabled={disableDefaultTraffic}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item>
                    <Tooltip title="Email">
                      <span>
                        <IconButton
                          className={classes.iconButton}
                          onClick={onEmailClick}
                          id="export_email"
                          name="export_email"
                          disabled={disableEmail}
                        >
                          <EmailIcon fontSize={14} disabled={disableEmail} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Export preview">
                      <span>
                        <IconButton
                          className={classes.iconButton}
                          onClick={onPreviewClick}
                          id="export_preview"
                          name="export_preview"
                          disabled={disablePreview}
                        >
                          <PreviewIcon
                            isActive={isActive}
                            disabled={disablePreview}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} zeroMinWidth>
                <Grid container alignItems="center">
                  {switchChecked && isDebtorActive && (
                    <Grid item>
                      <Checkbox
                        id="tp_check_edit_multiple"
                        name="tp_check_edit_multiple"
                        color="primary"
                        onClick={handleSelectAllForMultipleEditClick}
                        checked={
                          multipleEditSelectedNumber === trafficItemsCount &&
                          multipleEditSelectedNumber > 0
                        }
                        indeterminate={
                          multipleEditSelectedNumber > 0 &&
                          multipleEditSelectedNumber < trafficItemsCount
                        }
                        disabled={
                          !isTrafficPlanSelectable ||
                          trafficItemsCount === 0 ||
                          isTrafficPlanEdit ||
                          isTrafficItemEdit
                        }
                      />
                    </Grid>
                  )}
                  {!switchChecked && isDebtorActive && (
                    <Grid item>
                      <Checkbox
                        id="tp_check_change_status"
                        name="tp_check_change_status"
                        color="primary"
                        onClick={handleSelectAllForStatusChangeClick}
                        checked={
                          statusChangeSelectedNumber ===
                            trafficItemsSelectableForStatusChangeCount &&
                          statusChangeSelectedNumber > 0
                        }
                        indeterminate={
                          statusChangeSelectedNumber > 0 &&
                          statusChangeSelectedNumber <
                            trafficItemsSelectableForStatusChangeCount
                        }
                        disabled={
                          !isTrafficPlanSelectableForStatusChange ||
                          trafficItemsCount === 0 ||
                          isTrafficPlanEdit ||
                          isTrafficItemEdit
                        }
                      />
                    </Grid>
                  )}
                  <Tooltip
                    title={
                      <ClipboardTooltip
                        onButtonClick={handleOnCopy}
                        text={planName}
                        propertyId="planName"
                        entityId={id}
                      />
                    }
                    placement="bottom-start"
                  >
                    <Typography
                      noWrap
                      id="planName"
                      name="planName"
                      className={clsx(
                        classes.typographyTrafficPlanHeaderValue,
                        {
                          [classes.activeTypographyTrafficPlanHeaderValue]: isActive,
                          [classes.finalizeTypographyTrafficPlanHeaderValue]: isFinalized
                        }
                      )}
                    >
                      {planName || PLACEHOLDER}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
    </Accordion>
  );
};

const mapStateToProps = (state, params) => {
  const selectedTrafficPlan = selectCurrentTrafficPlanId(state);
  const isLoading = selectIsLoading(state);

  return {
    multipleEditSelectedNumber: selectSelectedMultipleEditTrafficItemsByTrafficPlan(
      state,
      { trafficPlanId: params.id }
    ),
    statusChangeSelectedNumber: selectTrafficItemStatusChangeSelectedByTrafficPlan(
      state,
      { trafficPlanId: params.id }
    ),
    trafficItemsSelectableForStatusChangeCount: getTrafficItemsSelectableForStatusChangeCount(
      state,
      { trafficPlanId: params.id }
    ),
    trafficItemsCount: getTrafficItemsCount(state, {
      trafficPlanId: params.id
    }),
    switchChecked: selectTrafficItemActionSwitch(state),
    isTrafficPlanSelectable: selectTrafficPlanIsSelectable(state, {
      trafficPlanId: params.id
    }),
    isTrafficPlanSelectableForStatusChange: selectIsTrafficPlanSelectableForStatusChange(
      state,
      { trafficPlanId: params.id }
    ),
    isVirtual: isTrafficPlanVirtual(state, { trafficPlanId: params.id }),
    canDownloadTrafficExcel: selectCanDownloadTrafficExcel(state),
    canSendEmail: selectCanSendEmail(state),
    selectedTrafficPlan: selectCurrentTrafficPlanId(state),
    isTrafficItemEdit: isTrafficItemInEditMode(state),
    isTrafficPlanEdit: isTrafficInEditMode(state),
    currentPlan: selectCurrentPlan(state),
    showAssignToMeButton: canViewTrafficPlanAssignToMeButton(state),
    currentUser: selectUserName(state),
    isFinalized: selectIsFinalized(state, { trafficPlanId: params.id }),
    agency: params.agency,
    agencyId: selectAgency(state),
    isActive: selectedTrafficPlan === params.id,
    disabledExport: isLoading,
    disabledPreview: isLoading,
    disabledEmail: isLoading,
    canDownloadVersion: selectCanDownloadVersion(state),
    currentTrafficPlan: selectCurrentTrafficPlanId(state),
    isSelectedTrafficDigitalValue: isSelectedTrafficDigital(state)
  };
};

const mapDispatchToProps = dispatch => ({
  handleSelectAllForMultipleEdit: (id, checked) =>
    dispatch(toggleMultipleEditTrafficPlanTrafficItems(id, checked)),
  handleSelectAllForStatusChange: (id, checked) =>
    dispatch(trafficItemStatusChangeToggleSelectedMultiple(id, checked)),
  downloadTrafficExcel: trafficPlanId =>
    dispatch(downloadTraffic(trafficPlanId, 0)),
  emailTrafficPlan: (trafficPlanId, emailAddresses, attachment) =>
    dispatch(emailTraffic(trafficPlanId, emailAddresses, attachment)),
  assignToMe: trafficItemId =>
    dispatch(assignTrafficPlanDashboard(trafficItemId)),
  unassignFromMe: trafficItemId =>
    dispatch(unassignTrafficPlanDashboard(trafficItemId)),
  addNewRemark: id => dispatch(addNewTrafficPlanRemark(id)),
  saveTrafficPlanRemark: () => dispatch(saveTrafficPlanRemark()),
  stopEditingTrafficPlanRemark: () => dispatch(stopEditingTrafficPlanRemark()),
  openTrafficPlanEmailDialog: () => dispatch(openTrafficPlanEmailDialog()),
  openTrafficPlanRemarkDialog: () => dispatch(openTrafficPlanRemarkDialog()),
  openPreviewDialog: () => dispatch(openPreviewDialog()),
  getPreviewData: (id, mediaTypeName) =>
    dispatch(getPreviewData(id, mediaTypeName)),
  setMediaTypeId: mediaTypeId => dispatch(setMediaTypeId(mediaTypeId)),
  openExportVersionDialog: () => dispatch(openTrafficExportVersionDialog()),
  openExportDigitalVersionDialog: () =>
    dispatch(openTrafficExportDigitalVersionDialog()),
  loadVersions: id => dispatch(loadExportVersions(id))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrafficPlanItemElement)
);
