import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@mui/material";

import { closeSetCampaignActualDialog } from "../../../../business/actions/campaignDashboardActions";
import { getCampaignActual } from "../../../../business/models/Campaign/campaignSelectors";
import { setCampaignActual } from "../../../../business/models/Campaign/campaignActions";

const paperProps = { square: true };
const SET_ACTUAL_MSG = "Are you sure that you want to archive this campaign?";
const UNSET_ACTUAL_MSG =
  "Are you sure that you want to remove the archived status for this campaign?";

const ConfirmCampaignSetActualDialog = () => {
  const dispatch = useDispatch();
  const { agency } = useParams();
  const isActual = useSelector(getCampaignActual);

  const handleSetActual = () => {
    dispatch(setCampaignActual(agency));
    dispatch(closeSetCampaignActualDialog());
  };

  const handleNo = () => {
    dispatch(closeSetCampaignActualDialog());
  };

  return (
    <Dialog open PaperProps={paperProps} maxWidth="sm">
      <DialogContent>
        <DialogContentText noWrap={false}>
          {isActual ? UNSET_ACTUAL_MSG : SET_ACTUAL_MSG}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo} color="primary">
          No
        </Button>
        <Button onClick={handleSetActual} color="secondary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCampaignSetActualDialog;
