import { styled, IconButton as MuiIconButton } from "@mui/material";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "ProjectButtonStyles";
export const classes = {
  iconButton: `${PREFIX}-iconButton`,
  blueIconButton: `${PREFIX}-blueIconButton`
};

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  [`&.${classes.iconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`&.${classes.blueIconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    color: theme.palette.primary.main,
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  }
}));
