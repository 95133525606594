import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import clsx from "clsx";

import { Grid, Tooltip } from "@mui/material";

import ClipboardTooltip from "../../../../../shared/ClipboardTooltip";
import CtcChip from "../../../../../panels/extensions/CtcChip";
import {
  StatusIcon,
  StatusCannotBeChangedIcon
} from "../../../../../../assets/styles/icons";
import {
  parseDate,
  trimString
} from "../../../../../../business/models/common/utils/clientUtils";

import { classes, Chip, Typography } from "../styles/actionOrderElementCompact";
import { getPropertyTextFromApiData } from "../../../../../../business/models/Order/orderSelectors";
import { PLACEHOLDER } from "../../../../../../configurations/appConstants";

const ActionOrderElementCompact = ({
  canUserDoActions,
  statusId,
  marathonOrderId,
  description,
  orderStartDate,
  orderEndDate,
  netTotal,
  currencySupplierSymbol,
  surcharge1Amount,
  surcharge2Amount,
  showEurCurrency,
  currencyClient,
  currencySupplierId,
  orderId,
  feePercentage,
  flightId,
  exchangeRateClient,
  exchangeRateSupplier
}) => {
  const title = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "titleId", orderId })
  );

  const handleClipboardCopy = e => {
    e.stopPropagation();
  };

  return (
    <Grid container alignItems="center">
      <Grid item>
        <Grid item>
          {canUserDoActions ? (
            <StatusIcon status={statusId} />
          ) : (
            <Tooltip
              placement="bottom-start"
              title="You cannot approve this order because you made the latest change for this order."
            >
              <div>
                <StatusCannotBeChangedIcon />
              </div>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <ClipboardTooltip
              onButtonClick={handleClipboardCopy}
              text={title}
            />
          }
          placement="bottom-start"
        >
          <Typography
            id="title"
            name="title"
            noWrap
            className={classes.typographyOrderHeader}
          >
            {title}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <ClipboardTooltip
              onButtonClick={handleClipboardCopy}
              text={marathonOrderId}
            />
          }
          placement="bottom-start"
        >
          <Chip
            id="marathonOrderId"
            name="marathonOrderId"
            label={marathonOrderId}
            className={classes.chip}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <ClipboardTooltip
              onButtonClick={handleClipboardCopy}
              text={description}
            />
          }
          placement="bottom-start"
        >
          <Chip
            id="description"
            name="description"
            label={trimString(description, 15)}
            className={clsx(classes.chip, classes.widerChip)}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Chip
          id="orderDate"
          name="orderDate"
          label={`${parseDate(orderStartDate)} - 
            ${parseDate(orderEndDate)}`}
          className={classes.chip}
        />
      </Grid>
      <Grid item>
        <Chip
          id="netTotal"
          name="netTotal"
          component={NumberFormat}
          decimalScale={2}
          value={netTotal}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix={`${currencySupplierSymbol} `}
          className={classes.chip}
          fixedDecimalScale
        />
      </Grid>
      <Grid item>
        <CtcChip
          orderId={orderId}
          surcharge1Amount={surcharge1Amount}
          surcharge2Amount={surcharge2Amount}
          showEurCurrency={showEurCurrency}
          currencyClient={currencyClient}
          currencySupplierId={currencySupplierId}
          currencySupplierSymbol={currencySupplierSymbol}
          exchangeRateClient={exchangeRateClient}
          exchangeRateSupplier={exchangeRateSupplier}
        />
      </Grid>
      <Grid item>
        <Chip
          id="feePercentage"
          name="feePercentage"
          component={NumberFormat}
          decimalScale={2}
          value={feePercentage}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          suffix="%"
          className={classes.chip}
          fixedDecimalScale
        />
      </Grid>
      <Grid item>
        {flightId ? (
          <Tooltip
            title={
              <ClipboardTooltip
                onButtonClick={handleClipboardCopy}
                text={flightId}
              />
            }
            placement="bottom-start"
          >
            <Chip
              id="flightId"
              name="flightId"
              label={trimString(flightId, 15)}
              className={classes.chipDark}
            />
          </Tooltip>
        ) : (
          <Chip
            id="flightId"
            name="flightId"
            label={PLACEHOLDER}
            className={classes.chipDark}
          />
        )}
      </Grid>
    </Grid>
  );
};

ActionOrderElementCompact.propTypes = {
  canUserDoActions: PropTypes.bool,
  statusId: PropTypes.number,
  title: PropTypes.string,
  marathonOrderId: PropTypes.number,
  description: PropTypes.string,
  orderStartDate: PropTypes.string,
  orderEndDate: PropTypes.string,
  netTotal: PropTypes.number,
  currencySupplierSymbol: PropTypes.string,
  surcharge1Amount: PropTypes.number,
  surcharge2Amount: PropTypes.number,
  showEurCurrency: PropTypes.bool,
  currencySupplierId: PropTypes.string
};

export default ActionOrderElementCompact;
