import { styled, Grid as MuiGrid } from "@mui/material";
import {
  matisse,
  linkWater,
  azureRadiance,
  baliHai,
  darkIndigo,
  crusta
} from "../../../assets/styles/colors";

const PREFIX = "PlanPanelCalculationsStyles";
export const classes = {
  infoGridItem: `${PREFIX}-infoGridItem`,
  typographyPlanHeaderCaption: `${PREFIX}-typographyPlanHeaderCaption`,
  typographyPlanHeaderValue: `${PREFIX}-typographyPlanHeaderValue`,
  typographyValue: `${PREFIX}-typographyValue`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  calcInfoGridItem: `${PREFIX}-calcInfoGridItem`,
  ctcInfoGridItemAlign: `${PREFIX}-ctcInfoGridItemAlign`,
  typographyMarked: `${PREFIX}-typographyMarked`,
  typographyCaptionBlue: `${PREFIX}-typographyCaptionBlue`,
  insertionIconButton: `${PREFIX}-insertionIconButton`,
  typographyPlanHeaderActualValue: `${PREFIX}-typographyPlanHeaderActualValue`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`&.${classes.infoGridItem}`]: {
    maxWidth: "40%",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "25%"
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "20%"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "20%"
    }
  },
  [`& .${classes.typographyPlanHeaderCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(12),
    paddingLeft: theme.typography.pxToRem(4)
  },
  [`& .${classes.typographyPlanHeaderValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(20)
  },
  [`& .${classes.typographyValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(20)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${classes.calcInfoGridItem}`]: {
    width: theme.typography.pxToRem(200)
  },
  [`& .${classes.ctcInfoGridItemAlign}`]: {
    paddingTop: theme.typography.pxToRem(22)
  },
  [`& .${classes.typographyMarked}`]: {
    color: azureRadiance
  },
  [`& .${classes.typographyCaptionBlue}`]: {
    color: darkIndigo,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold"
  },
  [`& .${classes.typographyPlanHeaderActualValue}`]: {
    color: crusta,
    fontSize: theme.typography.pxToRem(20)
  }
}));
