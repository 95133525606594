import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

import NumberFormat from "react-number-format";
import { Typography, IconButton, Tooltip } from "@mui/material";

import ClipboardTooltip from "../../shared/ClipboardTooltip";

import {
  CampaignStatusIcon,
  FavouriteIcon,
  FavouriteEmptyIcon
} from "../../../assets/styles/icons";

import {
  PLACEHOLDER,
  TYPE_ICON,
  COLOR_AZURERADINACE
} from "../../../configurations/appConstants";

import { parseDate } from "../../../business/models/common/utils/clientUtils";

import CtcButtonCampaign from "../../shared/ctc/CtcButtonCampaign";
import { isNullOrUndefined } from "../../../functions/util";
import {
  CampaignHasMutipleCurrencies,
  getCtcData,
  getCalculationData
} from "../../../business/models/Campaign/campaignSelectors";
import { getCurrencyDataForEveryPlan } from "../../../business/models/Plan/planSelectors";
import { favouriteCampaign } from "../../../business/models/Campaign/campaignActions";
import { classes, Grid } from "../styles/campaign";

const CampaignCompact = ({
  id,
  campaignStatus,
  debtor,
  campaignName,
  isFavourite,
  marathonCampaignId,
  endDate,
  startDate,
  numberOfOrders,
  numberOfPlans,
  poBudget
}) => {
  const dispatch = useDispatch();
  const hasMultipleCurrencies = useSelector(CampaignHasMutipleCurrencies);
  const planListCurrencyData = useSelector(getCurrencyDataForEveryPlan);
  const campaignCurrencyData = useSelector(getCtcData);
  const calculations = useSelector(getCalculationData);

  const { totalCostClient } = campaignCurrencyData?.clientTotalCostEur || {};
  const { orderTotalAmount, orderAmountPercentage } = calculations || {};
  const campaignData = { ...campaignCurrencyData, id, marathonCampaignId };

  const handleCopy = e => {
    e.stopPropagation();
  };

  const handleFavouriteClick = e => {
    e.stopPropagation();
    dispatch(favouriteCampaign());
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={3} sm={3} md={3} lg={4} xl={5}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <CampaignStatusIcon status={campaignStatus} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography className={classes.typographyHeaderCaption}>
              {debtor}
            </Typography>
            <Tooltip
              title={
                <ClipboardTooltip
                  onButtonClick={handleCopy}
                  text={campaignName}
                  id={id}
                />
              }
              placement="bottom-start"
            >
              <Typography className={classes.typographyHeaderValue} noWrap>
                {campaignName}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={3}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <IconButton
              name={`c_favorite_${id}`}
              id={`c_favorite_${id}`}
              onClick={handleFavouriteClick}
            >
              {isFavourite ? (
                <FavouriteIcon color={COLOR_AZURERADINACE} />
              ) : (
                <FavouriteEmptyIcon color={COLOR_AZURERADINACE} />
              )}
            </IconButton>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <ClipboardTooltip
                  onButtonClick={handleCopy}
                  text={marathonCampaignId}
                />
              }
              placement="bottom-start"
            >
              <Typography
                id="marathonCampaignId"
                name="marathonCampaignId"
                align="right"
                className={classes.typographyValue}
              >
                {marathonCampaignId || PLACEHOLDER}
              </Typography>
            </Tooltip>
            <Typography align="right" className={classes.typographyCaption}>
              Campaign ID
            </Typography>
          </Grid>
          <Grid item className={classes.dateWidth}>
            <Typography
              id="c_startDate"
              name="c_startDate"
              align="right"
              className={classes.typographyValue}
            >
              {parseDate(startDate)}
            </Typography>
            <Typography align="right" className={classes.typographyCaption}>
              Start date
            </Typography>
          </Grid>
          <Grid item className={classes.dateWidth}>
            <Typography
              id="c_endDate"
              name="c_endDate"
              align="right"
              className={classes.typographyValue}
            >
              {parseDate(endDate)}
            </Typography>
            <Typography align="right" className={classes.typographyCaption}>
              End date
            </Typography>
          </Grid>
          <Grid item className={classes.plansWidth}>
            <Typography
              id="numberOfPlans"
              name="numberOfPlans"
              align="right"
              className={classes.typographyValue}
            >
              {numberOfPlans || PLACEHOLDER}
            </Typography>
            <Typography align="right" className={classes.typographyCaption}>
              Plans
            </Typography>
          </Grid>
          <Grid item className={classes.ordersWidth}>
            <Typography
              id="numberOfOrders"
              name="numberOfOrders"
              align="right"
              className={classes.typographyValue}
            >
              {numberOfOrders || PLACEHOLDER}
            </Typography>
            <Typography align="right" className={classes.typographyCaption}>
              Orders
            </Typography>
          </Grid>
          <Grid item className={classes.budgetWidth}>
            <Grid
              container
              justifyContent="center"
              alignItems="flex-end"
              spacing={1}
              // className={classes.ctcGridPadding} TODO(AM)
            >
              <Grid item className={classes.ctcInfoGridItemAlign}>
                <Typography
                  align="right"
                  className={clsx(
                    classes.typographyValue,
                    classes.typographyMarked
                  )}
                >
                  {!isNullOrUndefined(poBudget) ? (
                    <NumberFormat
                      id="poBudget"
                      name="poBudget"
                      value={poBudget}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix="€"
                      fixedDecimalScale
                    />
                  ) : (
                    PLACEHOLDER
                  )}
                </Typography>
                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    {!hasMultipleCurrencies && (
                      <Typography
                        align="right"
                        className={classes.typographyCaptionBlue}
                      >
                        {!isNullOrUndefined(totalCostClient) ? (
                          <NumberFormat
                            id="totalCostClientEur"
                            name="totalCostClientEur"
                            value={totalCostClient}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="€"
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}{" "}
                        {!isNullOrUndefined(orderAmountPercentage) &&
                        orderTotalAmount >= 0 ? (
                          <NumberFormat
                            id="totalCostClientEur"
                            name="totalCostClientEur"
                            value={orderAmountPercentage}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix={"("}
                            suffix={"%)"}
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <CtcButtonCampaign
                      id={`c_view_ctc_${id}`}
                      name={`c_view_ctc_${id}`}
                      plans={planListCurrencyData}
                      campaign={campaignData}
                      hasMultipleCurrencies={hasMultipleCurrencies}
                      type={TYPE_ICON}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CampaignCompact.propTypes = {};

export default memo(CampaignCompact);
