import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../components/shared/PrivateRoute";

import CreateCampaignPage from "../components/pages/create/CampaignPage";
import OverviewPage from "../components/pages/overview/OverviewPage";
import ActionOverviewPage from "../components/pages/action/overview/ActionOverviewPage";
import ActionCampaignPage from "../components/pages/action/campaign/ActionCampaignPage";
import UserManagementPage from "../components/pages/user-management/UserManagementPage";
import ApiManagementPage from "../components/pages/api-management/ApiManagementPage";
import SystemMessagesPage from "../components/pages/system-messages/SystemMessagesPage";
import CopyPlanWizardPage from "../components/pages/create/copyPlan/CopyPlanWizardPage";
import UserProfilePage from "../components/pages/user-management/user-profile/UserProfilePage";
import AditionalApprovalConfigurationPage from "../components/pages/configuration/ConfigurationPage";
import HdtImagePage from "../components/pages/hdt/HdtImagePage";

import CreatePageActions from "../components/pages/create/actions/CreatePageActions";
import StepperActions from "../components/pages/create/actions/StepperActions";
import CampaignStatusActions from "../components/pages/overview/actions/CampaignStatusActions";
import UserManagementActions from "../components/pages/create/actions/UserManagementActions";
import SystemMessagesActions from "../components/pages/system-messages/actions/SystemMessagesActions";
import BreadcrumbActionActions from "../components/pages/action/campaign/actions/BreadcrumbActionActions";
import ActionActions from "../components/pages/action/shared/actions/ActionActions";
import TrafficCampaignStatusActions from "../components/pages/traffic-overview/actions/TrafficCampaignStatusActions";
import ApprovalActions from "../components/pages/approval/details/actions/ApprovalActions";
import UserProfileActions from "../components/pages/user-management/user-profile/actions/UserProfileActions";
import ConfigurationActions from "../components/pages/configuration/actions/ConfigurationActions";
import TrafficTemplateManagerActions from "../components/pages/traffic-template-manager/actions/TrafficTemplateManagerActions";

import TrafficDashboardPage from "../components/pages/traffic-dashboard/TrafficDashboardPage";
import TrafficOverviewPage from "../components/pages/traffic-overview/TrafficOverviewPage";
import TrafficPageActions from "../components/pages/traffic-dashboard/actions/TrafficDashboardActions";
import TrafficTemplateManagerPage from "../components/pages/traffic-template-manager/TrafficTemplateManagerPage";

import AdditionalApprovalOverviewPage from "../components/pages/approval/overview/AdditionalApprovalOverviewPage";
import AdditionalApprovalDetailPage from "../components/pages/approval/details/AdditionalApprovalDetailPage";

import {
  CreateIcon,
  ActionIcon,
  AdditionalApprovalIcon,
  OverviewIcon,
  HomeIcon,
  TrafficOverviewIcon,
  HtdImageIcon,
  TemplateIcon
} from "../assets/styles/icons";
import {
  SYSTEM_MESSAGES_PAGE,
  TRAFFIC_PAGE,
  API_MANAGEMENT_PAGE,
  USER_MANAGEMENT_PAGE,
  HOME_PAGE,
  USER_PROFILE_PAGE,
  AACONFIGURATION_PAGE
} from "./appConstants";
import ConfigurationStatusActions from "../components/pages/configuration/actions/ConfigurationStatusActions";
import ApiManagementActions from "../components/pages/api-management/actions/ApiManagementActions";

export const agencyRoutes = [
  {
    path: "create/:campaign?/:campaignId?/:plan?/:planId?/:order?/:orderId?",
    element: <CreateCampaignPage />,
    route: Route
  },
  {
    path: "copy-plan-wizard/:processId?",
    element: <CopyPlanWizardPage />,
    route: Route
  },
  {
    path: "traffic/:planId/:trafficPlanId?/:trafficItemId?",
    element: <TrafficDashboardPage />,
    route: PrivateRoute,
    claim: "CanViewTrafficDashboardPage"
  },
  {
    path: "traffic-overview/:trafficCampaignStatus",
    element: <TrafficOverviewPage />,
    route: PrivateRoute,
    claim: "CanViewTrafficOverviewPage"
  },
  {
    path: "traffic-template",
    element: <TrafficTemplateManagerPage />,
    route: Route,
    claim: "CanViewTrafficManagementPage"
  },
  {
    path: "overview/:campaignStatus",
    element: <OverviewPage />,
    route: Route
  },
  {
    path: "action/:campaign?/:campaignId?/:plan?/:planId?/:order?/:orderId?",
    element: <ActionCampaignPage />,
    route: Route
  },
  {
    path: "action-overview/:orderStatus",
    element: <ActionOverviewPage />,
    route: Route
  },
  {
    path: "approval-overview",
    element: <AdditionalApprovalOverviewPage />,
    route: Route
  },
  {
    path: "approval/order/:orderId/:conditionId",
    element: <AdditionalApprovalDetailPage />,
    route: Route
  },
  {
    path: "hdt-image",
    element: <HdtImagePage />,
    route: PrivateRoute,
    claim: "CanViewEvouchingPage"
  }
];

export const routes = [
  {
    path: `${AACONFIGURATION_PAGE}/:agency`,
    element: <AditionalApprovalConfigurationPage />,
    route: Route
  },
  {
    path: `${API_MANAGEMENT_PAGE}`,
    element: <ApiManagementPage />,
    route: Route
  },
  {
    path: `${USER_MANAGEMENT_PAGE}`,
    element: <UserManagementPage />,
    route: Route
  },
  {
    path: "user-profile/:userId?",
    element: <UserProfilePage />,
    route: Route
  },
  {
    path: `${SYSTEM_MESSAGES_PAGE}`,
    element: <SystemMessagesPage />,
    route: Route
  }
];

const sideBarItems = agency => [
  {
    path: "/",
    url: "/",
    title: "Home",
    icon: <HomeIcon />,
    key: "CanViewDashboard"
  },
  {
    path: "create",
    url: "create",
    title: "Create",
    icon: <CreateIcon />,
    key: "CanViewCreateCampaign"
  },
  {
    path: "/overview",
    url: "overview/all",
    title: "Overview",
    icon: <OverviewIcon />,
    key: "CanViewCampaignOverview"
  },
  {
    path: "action-overview/campaigns",
    url: "action-overview/campaigns",
    title: "Action",
    icon: <ActionIcon />,
    key: "CanViewCampaignAction"
  },
  {
    path: "approval-overview",
    url: "approval-overview",
    title: "Additional Approval",
    icon: <AdditionalApprovalIcon />,
    key: "CanViewAdditionalApprovalPage"
  },
  {
    path: "traffic-overview",
    url: "traffic-overview/new?searchProperty=PlanId",
    title: "Traffic Overview",
    icon: <TrafficOverviewIcon />,
    key: "CanViewTrafficOverviewPage"
  },
  {
    path: "traffic-template",
    url: "traffic-template",
    title: "Traffic Digital",
    icon: <TemplateIcon />,
    key: "CanViewTrafficManagementPage"
  },
  {
    path: "hdt-image",
    url: "hdt-image",
    title: "HDT Image Retrieval",
    icon: <HtdImageIcon />,
    key: "CanViewEvouchingPage"
  }
];

export const getSidebarItems = agency => {
  let drawerItems = sideBarItems(agency);

  if (!agency) {
    drawerItems = drawerItems.filter(
      item => item.title.toLowerCase() === HOME_PAGE
    );
  }

  return drawerItems;
};

export const footerRoutes = [
  {
    path: "create/*",
    element: <CreatePageActions />
  },
  {
    path: "action/campaign/:campaignId/*",
    element: <ActionActions />
  },
  {
    path: `${TRAFFIC_PAGE}/:planId/*`,
    element: <TrafficPageActions />
  },
  {
    path: `traffic-template`,
    element: <TrafficTemplateManagerActions />
  },
  {
    path: `copy-plan-wizard/*`,
    element: <StepperActions />
  },
  {
    path: `approval/order/:orderId/:conditionId`,
    element: <ApprovalActions />
  },
  {
    path: "configuration/:agency",
    element: <ConfigurationActions />
  },
  {
    path: "user-management",
    element: <UserManagementActions />
  },
  {
    path: "user-profile/:userId?",
    element: <UserProfileActions />
  },
  {
    path: `${SYSTEM_MESSAGES_PAGE}/*`,
    element: <SystemMessagesActions />
  },
  {
    path: `${API_MANAGEMENT_PAGE}/*`,
    element: <ApiManagementActions />
  }
];

export const headerRoutes = [
  {
    path: "configuration/:status",
    element: <ConfigurationStatusActions />,
    isConfiguration: true
  },
  {
    path: "overview/:campaignStatus",
    element: <CampaignStatusActions />
  },
  {
    path: "action/campaign/:campaignId/*",
    element: <BreadcrumbActionActions />
  },
  {
    path: "traffic-overview/:trafficCampaignStatus",
    element: <TrafficCampaignStatusActions />
  }
];
