import { styled } from "@mui/material";

const PREFIX = "TrafficClearAllStyles";
export const classes = {
  clearAll: `${PREFIX}-clearAll`,
  button: `${PREFIX}-button`
};

export const Div = styled("div")(() => ({
  [`&.${classes.clearAll}`]: {
    borderLeft: "0.1em solid #ebeef2"
  },
  [`& .${classes.button}`]: {
    textTransform: "none",
    fontWeight: 100,
    color: "#000000de"
  }
}));
