import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import NumberFormat from "react-number-format";

import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  IconButton,
  Divider,
  Link,
  Tooltip
} from "@mui/material";

import {
  startEditingCampaign,
  selectCampaign
} from "../../../../business/models/Campaign/campaignActions";
import ClipboardTooltip from "../../../shared/ClipboardTooltip";
import {
  CampaignStatusIcon,
  DownloadIcon,
  FavouriteEmptyIcon,
  FavouriteIcon
} from "../../../../assets/styles/icons";
import { parseDate } from "../../../../business/models/common/utils/clientUtils";
import { classes, Accordion } from "../styles/campaignOverviewPanelStyles";
import {
  PLACEHOLDER,
  COLOR_AZURERADINACE,
  COLOR_LINKWATER,
  CREATE_PAGE,
  CAMPAIGN
} from "../../../../configurations/appConstants";
import CtcButtonWrapperCampaign from "../../../shared/ctc/CtcButtonWrapperCampaign";
import { isNullOrUndefined } from "../../../../functions/util";
import { openCampaignFormPage } from "../../../../business/actions/campaignDashboardActions";
import { loadCopyPlanEntityData } from "../../../../business/models/common/data/dataActions";

const CampaignOverviewPanel = ({
  data,
  disableDownload,
  disableOpen,
  id,
  isCopyPlan,
  onDownloadClick,
  onEditClick,
  onFavouriteClick
}) => {
  const dispatch = useDispatch();
  const { agency } = useParams();

  const preventDefault = event => {
    event.preventDefault();
  };

  const handleCopy = event => {
    event.stopPropagation();
  };

  const handleCopyPlanEdit = e => {
    e.stopPropagation();

    dispatch(loadCopyPlanEntityData(id)).then(cId => {
      dispatch(selectCampaign(cId));
      dispatch(startEditingCampaign());
      dispatch(openCampaignFormPage());
    });
  };

  const { searchParam } = data;

  const handleEditClick = event => {
    event.stopPropagation();
    onEditClick(id, searchParam);
  };

  const handleDownloadClick = event => {
    event.stopPropagation();
    onDownloadClick(id);
  };

  const handleFavouriteClick = event => {
    event.stopPropagation();
    onFavouriteClick(id);
  };

  const {
    debtor,
    campaignName,
    startDate,
    endDate,
    poBudget,
    productName,
    productId,
    processManager,
    poNumber,
    debtorContactPersonEmail,
    marathonCampaignId,
    agreementName,
    campaignStatus,
    numberOfPlans,
    numberOfOrders,
    hasMultipleCurrencies,
    isFavourite,
    campaignId,
    isInCopyProcess,
    canOpenCampaignFromSelectedAgency
  } = data;

  const { maratonDebtorId, internalName: name } = debtor || {};
  const { username } = processManager || {};
  return (
    <>
      {!isEmpty(data) && (
        <Accordion
          square
          elevation={0}
          className={clsx({
            [classes.copyProcess]: isInCopyProcess,
            [classes.invalidAgency]: !canOpenCampaignFromSelectedAgency
          })}
          disabled={!canOpenCampaignFromSelectedAgency}
        >
          <AccordionSummary>
            <Grid container justifyContent="space-between">
              <Grid item xs={3} sm={3} md={3} lg={3} xl={5}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <CampaignStatusIcon status={campaignStatus} />
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <Typography
                      id={`debtorName_${id}`}
                      name={`debtorName_${id}`}
                      className={classes.typographyHeaderCaption}
                    >
                      {name}
                    </Typography>
                    <Tooltip
                      title={
                        <ClipboardTooltip
                          propertyId="campaignName"
                          entityId={campaignId}
                          onButtonClick={handleCopy}
                          text={campaignName}
                        />
                      }
                      placement="bottom-start"
                    >
                      {isCopyPlan ? (
                        <Typography
                          id={`campaignName_${id}`}
                          name={`campaignName_${id}`}
                          disabled={disableOpen}
                          onClick={handleCopyPlanEdit}
                          className={clsx(
                            classes.typographyHeaderValue,
                            classes.typographyLink
                          )}
                          noWrap
                        >
                          {campaignName}
                        </Typography>
                      ) : (
                        <Typography
                          id={`campaignName_${id}`}
                          name={`campaignName_${id}`}
                          disabled={disableOpen}
                          onClick={handleEditClick}
                          className={clsx(
                            classes.typographyHeaderValue,
                            classes.typographyLink
                          )}
                          noWrap
                        >
                          <Link
                            name={`c_redirect_${campaignId}`}
                            id={`c_redirect_${campaignId}`}
                            onClick={preventDefault}
                            className={classes.link}
                            href={
                              !isNullOrUndefined(searchParam)
                                ? `/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${searchParam}`
                                : `/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${campaignId}`
                            }
                            underline="none"
                            color="inherit"
                          >
                            {campaignName}
                          </Link>
                        </Typography>
                      )}
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={3}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <IconButton
                      name={`c_favorite_${id}`}
                      id={`c_favorite_${id}`}
                      onClick={handleFavouriteClick}
                      disabled={isCopyPlan}
                    >
                      {isFavourite ? (
                        <FavouriteIcon
                          color={
                            isCopyPlan ? COLOR_LINKWATER : COLOR_AZURERADINACE
                          }
                        />
                      ) : (
                        <FavouriteEmptyIcon
                          color={
                            isCopyPlan ? COLOR_LINKWATER : COLOR_AZURERADINACE
                          }
                        />
                      )}
                    </IconButton>
                  </Grid>
                  <Grid item className={classes.dateWidth}>
                    <Typography
                      id={`startDate_${id}`}
                      name={`startDate_${id}`}
                      align="right"
                      className={classes.typographyValue}
                    >
                      {parseDate(startDate)}
                    </Typography>
                    <Typography
                      align="right"
                      className={classes.typographyCaption}
                    >
                      Start date
                    </Typography>
                  </Grid>
                  <Grid item className={classes.dateWidth}>
                    <Typography
                      id={`endDate_${id}`}
                      name={`endDate_${id}`}
                      align="right"
                      className={classes.typographyValue}
                    >
                      {parseDate(endDate)}
                    </Typography>
                    <Typography
                      align="right"
                      className={classes.typographyCaption}
                    >
                      End date
                    </Typography>
                  </Grid>
                  <Grid item className={classes.plansWidth}>
                    <Typography
                      id={`numberOfPlans_${id}`}
                      name={`numberOfPlans_${id}`}
                      align="right"
                      className={classes.typographyValue}
                    >
                      {numberOfPlans}
                    </Typography>
                    <Typography
                      align="right"
                      className={classes.typographyCaption}
                    >
                      Plans
                    </Typography>
                  </Grid>
                  <Grid item className={classes.ordersWidth}>
                    <Typography
                      id={`numberOfOrders_${id}`}
                      name={`numberOfOrders_${id}`}
                      align="right"
                      className={classes.typographyValue}
                    >
                      {numberOfOrders}
                    </Typography>
                    <Typography
                      align="right"
                      className={classes.typographyCaption}
                    >
                      Orders
                    </Typography>
                  </Grid>
                  <Grid item className={classes.budgetWidth}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      spacing={4}
                    >
                      <Grid
                        item
                        className={clsx(
                          classes.ctcInfoGridItemAlign,
                          classes.calcInfoGridItem
                        )}
                      >
                        <Typography
                          align="right"
                          className={clsx(
                            classes.typographyValue,
                            classes.typographyMarked
                          )}
                        >
                          {!isNullOrUndefined(poBudget) ? (
                            <NumberFormat
                              id={`poBudget_${id}`}
                              name={`poBudget_${id}`}
                              value={poBudget}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix={"€"}
                              fixedDecimalScale
                            />
                          ) : (
                            PLACEHOLDER
                          )}
                        </Typography>
                        <CtcButtonWrapperCampaign
                          campaignId={id}
                          hasMultipleCurrencies={hasMultipleCurrencies}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* {!isCopyPlan && (
                    <Grid item>
                      <Grid
                        container
                        alignItems="center"
                        spacing={0}
                        justifyContent="flex-end"
                        className={classes.verticalDivider}
                      >
                        <IconButton
                          name={`c_download_${campaignId}`}
                          id={`c_download_${campaignId}`}
                          onClick={handleDownloadClick}
                          disabled={disableDownload}
                        >
                          <DownloadIcon
                            color={
                              disableDownload || isCopyPlan
                                ? COLOR_LINKWATER
                                : COLOR_AZURERADINACE
                            }
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )} */}
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <Divider variant="middle" />
          <AccordionDetails>
            <Grid container spacing={3} justifyContent="flex-end">
              <Grid item>
                <Typography
                  align="right"
                  className={classes.typographyDetailsValue}
                >
                  {productName}
                </Typography>
                <Typography align="right" className={classes.typographyCaption}>
                  Product name
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  id={`productId_${id}`}
                  name={`productId_${id}`}
                  align="right"
                  className={classes.typographyDetailsValue}
                >
                  {productId}
                </Typography>
                <Typography align="right" className={classes.typographyCaption}>
                  Product ID
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  id={`username_${id}`}
                  name={`username_${id}`}
                  align="right"
                  className={classes.typographyDetailsValue}
                >
                  {username}
                </Typography>
                <Typography align="right" className={classes.typographyCaption}>
                  Process Manager
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  id={`poNumber_${id}`}
                  name={`poNumber_${id}`}
                  align="right"
                  className={classes.typographyDetailsValue}
                >
                  {poNumber || PLACEHOLDER}
                </Typography>
                <Typography align="right" className={classes.typographyCaption}>
                  PO/ATB Number
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  id={`marathonDebtorId_${id}`}
                  name={`marathonDebtorId_${id}`}
                  align="right"
                  className={classes.typographyDetailsValue}
                >
                  {maratonDebtorId}
                </Typography>
                <Typography align="right" className={classes.typographyCaption}>
                  Debtor ID
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  id={`debtorContactPersonEmail_${id}`}
                  name={`debtorContactPersonEmail_${id}`}
                  align="right"
                  className={classes.typographyDetailsValue}
                >
                  {debtorContactPersonEmail || PLACEHOLDER}
                </Typography>
                <Typography align="right" className={classes.typographyCaption}>
                  Debtor Contact Person
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  id={`marathonCampaignId_${id}`}
                  name={`marathonCampaignId_${id}`}
                  align="right"
                  className={classes.typographyDetailsValue}
                >
                  {marathonCampaignId}
                </Typography>
                <Typography align="right" className={classes.typographyCaption}>
                  Campaign ID
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  id={`agreementName_${id}`}
                  name={`agreementName_${id}`}
                  align="right"
                  className={classes.typographyDetailsValue}
                >
                  {agreementName}
                </Typography>
                <Typography align="right" className={classes.typographyCaption}>
                  Agreement
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

CampaignOverviewPanel.propTypes = {
  disableDownload: PropTypes.bool,
  disableOpen: PropTypes.bool,
  isCopyPlan: PropTypes.bool
};

CampaignOverviewPanel.defaultProps = {
  disableDownload: false,
  disableOpen: false
};

export default CampaignOverviewPanel;
