import { styled, Dialog as MuiDialog } from "@mui/material";
import {
  crusta,
  deYork,
  linkWater,
  solitude,
  white
} from "../../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../../functions/util";

const PREFIX = "ActionCompareDialogStyle";
export const classes = {
  content: `${PREFIX}-content`,
  action: `${PREFIX}-action`,
  closeButton: `${PREFIX}-closeButton`,
  doneButton: `${PREFIX}-doneButton`
};

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${classes.action}`]: {
    borderTop: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.typography.pxToRem(getThemeSpacing(theme, 2))
  },
  [`& .${classes.closeButton}`]: {
    color: crusta
  },
  [`& .${classes.doneButton}`]: {
    backgroundColor: deYork,
    color: white,
    "&:disabled": {
      color: linkWater,
      backgroundColor: solitude
    }
  },
  [`& .${classes.content}`]: {
    marginTop: theme.typography.pxToRem(4)
  }
}));
