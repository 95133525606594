import React, { memo } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { Card, CardActionArea, CardMedia } from "@mui/material";

import { classes, Grid } from "./styles/agencyList";
import agencyLogo from "../../../assets/styles/agencyLogo";

const AgencyList = ({ agencies, isAdmin }) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      className={classes.root}
    >
      {agencies.map(value => (
        <Grid key={value} item>
          <Card className={classes.card}>
            <CardActionArea component={NavLink} to={`${value}/overview/all`}>
              <CardMedia
                className={classes.media}
                image={agencyLogo[value]}
                title={value}
              />
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

AgencyList.propTypes = {
  agencies: PropTypes.arrayOf(PropTypes.string),
  isAdmin: PropTypes.bool.isRequired
};

AgencyList.defaultProps = {
  agencies: []
};

export default memo(AgencyList);
