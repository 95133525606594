/* eslint-disable no-unused-vars */
import React from "react";

import { useSelector } from "react-redux";
import clsx from "clsx";
import NumberFormat from "react-number-format";

import { AccordionSummary, Typography, Grid, Tooltip } from "@mui/material";

import CtcButtonWrapperCampaign from "../../../../shared/ctc/CtcButtonWrapperCampaign";
import ClipboardTooltip from "../../../../shared/ClipboardTooltip";
import { CampaignStatusIcon } from "../../../../../assets/styles/icons";
import { parseDate } from "../../../../../business/models/common/utils/clientUtils";
import { isNullOrUndefined } from "../../../../../functions/util";
import { PLACEHOLDER } from "../../../../../configurations/appConstants";

import {
  getCampaignData,
  getCalculationData,
  selectCurrentCampaign
} from "../../../../../business/models/Campaign/campaignSelectors";

import { classes, Accordion } from "./styles/actionCampaignPanel";

const ActionCampaignPanel = () => {
  const campaign = useSelector(getCampaignData);
  const calculations = useSelector(getCalculationData);
  const currentCampaign = useSelector(selectCurrentCampaign);

  const {
    id,
    debtor,
    campaignName,
    startDate,
    endDate,
    poBudget,
    campaignStatus,
    marathonCampaignId,
    hasMultipleCurrencies
  } = campaign || {};

  const { orderTotalAmount, orderAmountPercentage, clientTotalCostEur } =
    calculations || {};

  const { totalCostClient: totalCostClientEur } = clientTotalCostEur || {};

  const isActive = currentCampaign === id;

  const handleCopy = event => {
    event.stopPropagation();
  };

  return (
    <>
      <Accordion
        square
        elevation={0}
        expanded={false}
        className={clsx({
          [classes.selected]: isActive
        })}
      >
        <AccordionSummary>
          <Grid container justifyContent="space-between">
            <Grid item xs={3} sm={3} md={3} lg={3} xl={5}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <CampaignStatusIcon status={campaignStatus} />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography
                    id="debtor"
                    name="debtor"
                    className={classes.typographyHeaderCaption}
                    noWrap
                  >
                    {debtor}
                  </Typography>
                  <Tooltip
                    title={
                      <ClipboardTooltip
                        onButtonClick={handleCopy}
                        text={campaignName}
                      />
                    }
                    placement="bottom-start"
                  >
                    <Typography
                      id="campaignName"
                      name="campaignName"
                      className={classes.typographyHeaderValue}
                      noWrap
                    >
                      {campaignName}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={3} justifyContent="flex-end">
                <Grid item>
                  <Tooltip
                    disableFocusListener={isNullOrUndefined(marathonCampaignId)}
                    disableHoverListener={isNullOrUndefined(marathonCampaignId)}
                    disableTouchListener={isNullOrUndefined(marathonCampaignId)}
                    title={
                      <ClipboardTooltip
                        onButtonClick={handleCopy}
                        text={marathonCampaignId}
                      />
                    }
                    placement="bottom-start"
                  >
                    <Typography
                      id="marathonCampaignId"
                      name="marathonCampaignId"
                      align="right"
                      className={classes.typographyValue}
                    >
                      {marathonCampaignId || PLACEHOLDER}
                    </Typography>
                  </Tooltip>
                  <Typography
                    align="right"
                    className={classes.typographyCaption}
                  >
                    Campaign ID
                  </Typography>
                </Grid>
                <Grid item className={classes.dateWidth}>
                  <Typography
                    id="startDate"
                    name="startDate"
                    align="right"
                    className={classes.typographyValue}
                  >
                    {parseDate(startDate)}
                  </Typography>
                  <Typography
                    align="right"
                    className={classes.typographyCaption}
                  >
                    Start date
                  </Typography>
                </Grid>
                <Grid item className={classes.dateWidth}>
                  <Typography
                    id="endDate"
                    name="endDate"
                    align="right"
                    className={classes.typographyValue}
                  >
                    {parseDate(endDate)}
                  </Typography>
                  <Typography
                    align="right"
                    className={classes.typographyCaption}
                  >
                    End date
                  </Typography>
                </Grid>
                <Grid item className={classes.budgetWidth}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="flex-end"
                    spacing={1}
                  >
                    <Grid item className={classes.ctcInfoGridItemAlign}>
                      <Typography
                        align="right"
                        className={clsx(
                          classes.typographyValue,
                          classes.typographyMarked
                        )}
                      >
                        {!isNullOrUndefined(poBudget) ? (
                          <NumberFormat
                            id="poBudget"
                            name="poBudget"
                            value={poBudget}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix={"€"}
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <CtcButtonWrapperCampaign
                        campaignId={id}
                        hasMultipleCurrencies={hasMultipleCurrencies}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
    </>
  );
};

ActionCampaignPanel.propTypes = {};

ActionCampaignPanel.defaultProps = {};

export default ActionCampaignPanel;
