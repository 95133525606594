import React from "react";

import Cinema from "./byMediaType/PreviewCinema";
import Digital from "./byMediaType/PreviewDigital";
import Ooh from "./byMediaType/PreviewOoh";
import Print from "./byMediaType/PreviewPrint";
import Radio from "./byMediaType/PreviewRadio";
import Tv from "./byMediaType/PreviewTv";
import {
  MEDIATYPE_DIGITAL,
  MEDIATYPE_PRINT,
  MEDIATYPE_OOH,
  MEDIATYPE_TV,
  MEDIATYPE_RADIO,
  MEDIATYPE_CINEMA,
  MEDIATYPE_INVENTORY
} from "../../../../../../configurations/appConstants";

const TableExportPreview = ({ mediaTypeId, data }) => {
  switch (mediaTypeId) {
    case MEDIATYPE_PRINT:
      return <Print data={data} />;
    case MEDIATYPE_DIGITAL:
    case MEDIATYPE_INVENTORY:
      return <Digital data={data} />;
    case MEDIATYPE_OOH:
      return <Ooh data={data} />;
    case MEDIATYPE_RADIO:
      return <Radio data={data} />;
    case MEDIATYPE_TV:
      return <Tv data={data} />;
    case MEDIATYPE_CINEMA:
      return <Cinema data={data} />;
    default:
      return <div>NONE EXISTING MEDIA TYPE</div>;
  }
};

export default TableExportPreview;
