import { createReducer } from "../common/utils/reducerUtils";

import {
  SYSTEM_MESSAGE_SELECT,
  SYSTEM_MESSAGE_EDIT_START,
  SYSTEM_MESSAGE_EDIT_STOP,
  SYSTEM_MESSAGE_CLEAR_CURRENT,
  SYSTEM_MESSAGE_DISPLAY_ERRORS,
  SYSTEM_MESSAGE_HIDE_ERRORS,
  SYSTEM_MESSAGE_SET_IS_NEW,
  SYSTEM_MESSAGE_RESET_IS_NEW
} from "./systemMessageConstants";

const initialState = {
  currentSystemMessage: null,
  isEditing: false,
  isNew: false,
  displayErrors: false
};

export function selectSystemMessage(state, payload) {
  return {
    ...state,
    currentSystemMessage: payload
  };
}

export function startEditingSystemMessage(state) {
  return {
    ...state,
    isEditing: true
  };
}

export function stopEditingSystemMessage(state) {
  return {
    ...state,
    isEditing: false
  };
}

export function displayErrors(state) {
  return {
    ...state,
    displayErrors: true
  };
}

export function hideErrors(state) {
  return {
    ...state,
    displayErrors: false
  };
}

export function clearCurrent(state) {
  return {
    ...state,
    currentSystemMessage: null
  };
}

export function setIsNew(state) {
  return {
    ...state,
    isNew: true
  };
}

export function resetIsNew(state) {
  return {
    ...state,
    isNew: false
  };
}

export default createReducer(initialState, {
  [SYSTEM_MESSAGE_SELECT]: selectSystemMessage,
  [SYSTEM_MESSAGE_EDIT_START]: startEditingSystemMessage,
  [SYSTEM_MESSAGE_EDIT_STOP]: stopEditingSystemMessage,
  [SYSTEM_MESSAGE_CLEAR_CURRENT]: clearCurrent,
  [SYSTEM_MESSAGE_DISPLAY_ERRORS]: displayErrors,
  [SYSTEM_MESSAGE_HIDE_ERRORS]: hideErrors,
  [SYSTEM_MESSAGE_SET_IS_NEW]: setIsNew,
  [SYSTEM_MESSAGE_RESET_IS_NEW]: resetIsNew
});
