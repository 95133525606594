import React from "react";
import PropTypes from "prop-types";

import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from "@mui/material";

import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import Scrollbar from "../../../../../../shared/Scrollbar";

import {
  classes,
  DialogContent
} from "../../../../styles/trafficExportPreviewTable";
import {
  PLACEHOLDER,
  EMPTY_TABLE
} from "../../../../../../../configurations/appConstants";

const PreviewTV = ({ data }) => {
  const {
    paperRoot,
    tableCellUnderline,
    scrollTableCell,
    scrollTableCellHeaderColord,
    tableCellWidth,
    tableCellHeaderInner,
    tableCell,
    scrollTableTypography
  } = classes;

  const {
    debtorName,
    plannerAgency,
    productName,
    creationDate,
    broadcastPeriodTv,
    remarks,
    creativeAgency,
    contact,
    email,
    cc,
    contactGroupm,
    phoneNumberGroupm,
    version,
    ordersTv,
    planName
  } = data;
  return (
    <DialogContent>
      <Paper className={paperRoot} elevation={0}>
        <Table>
          <TableBody key={"campaignId"}>
            <TableRow>
              <TableCell className={clsx(tableCellWidth, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left">
                  CLIENT NAME:
                </Typography>
              </TableCell>
              <TableCell className={clsx(tableCell, tableCellUnderline)}>
                <Typography align="left">{debtorName}</Typography>
              </TableCell>
              <TableCell
                className={clsx(
                  tableCellWidth,
                  tableCellHeaderInner,
                  tableCellUnderline
                )}
              >
                <Typography variant="subtitle2" align="left">
                  CONTACT GROUPM:
                </Typography>
              </TableCell>
              <TableCell className={clsx(tableCell, tableCellUnderline)}>
                <Typography align="left">{contactGroupm}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(tableCellWidth, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left">
                  PRODUCT:
                </Typography>
              </TableCell>
              <TableCell className={clsx(tableCell, tableCellUnderline)}>
                <Typography align="left">{productName}</Typography>
              </TableCell>
              <TableCell
                className={clsx(
                  tableCellWidth,
                  tableCellHeaderInner,
                  tableCellUnderline
                )}
              >
                <Typography variant="subtitle2" align="left">
                  PHONE NUMBER:
                </Typography>
              </TableCell>
              <TableCell className={clsx(tableCell, tableCellUnderline)}>
                <Typography align="left">{phoneNumberGroupm}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(tableCellWidth, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left">
                  PLAN NAME:
                </Typography>
              </TableCell>
              <TableCell className={clsx(tableCell, tableCellUnderline)}>
                <Typography align="left">{planName}</Typography>
              </TableCell>
              <TableCell
                className={clsx(
                  tableCellWidth,
                  tableCellHeaderInner,
                  tableCellUnderline
                )}
              >
                <Typography variant="subtitle2" align="left">
                  E-MAIL:
                </Typography>
              </TableCell>
              <TableCell className={clsx(tableCell, tableCellUnderline)}>
                <Typography align="left">
                  {email || "rtvtraffic@groupm.com"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(tableCellWidth, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left">
                  1ST BROADCAST PERIOD:
                </Typography>
              </TableCell>
              <TableCell className={clsx(tableCell, tableCellUnderline)}>
                <Typography align="left">{broadcastPeriodTv}</Typography>
              </TableCell>
              <TableCell
                className={clsx(
                  tableCellWidth,
                  tableCellHeaderInner,
                  tableCellUnderline
                )}
              >
                <Typography variant="subtitle2" align="left">
                  PLANNER AGENCY:
                </Typography>
              </TableCell>
              <TableCell className={clsx(tableCell, tableCellUnderline)}>
                <Typography align="left">{plannerAgency}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(tableCellWidth, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left">
                  VERSION
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(tableCell, tableCellUnderline)}
                colSpan={3}
              >
                <Typography align="left">{version}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(tableCellWidth, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left">
                  DATE:
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(tableCell, tableCellUnderline)}
                colSpan={3}
              >
                <Typography align="left">{creationDate}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(tableCellWidth, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left">
                  REPLACES DATE:
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(tableCell, tableCellUnderline)}
                colSpan={3}
              >
                <Typography align="left">{PLACEHOLDER}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(tableCellWidth, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left">
                  CHANGES:
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(tableCell, tableCellUnderline)}
                colSpan={3}
              >
                <Typography align="left">{PLACEHOLDER}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(tableCellWidth, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left">
                  CREATIVE AGENCY:
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(tableCell, tableCellUnderline)}
                colSpan={3}
              >
                <Typography align="left">{creativeAgency}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(tableCellWidth, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left">
                  CREATIVE AGENCY CONTACT:
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(tableCell, tableCellUnderline)}
                colSpan={3}
              >
                <Typography align="left">{contact}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(tableCellWidth, tableCellUnderline)}>
                <Typography variant="subtitle2" align="left">
                  CC:
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(tableCell, tableCellUnderline)}
                colSpan={3}
              >
                <Typography align="left">{cc}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCellUnderline} rowSpan={2}>
                <Typography variant="subtitle2" align="left">
                  REMARKS:
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(tableCell, tableCellUnderline)}
                rowSpan={2}
                colSpan={4}
              >
                <Typography align="left">{remarks}</Typography>
              </TableCell>
            </TableRow>
            <TableRow />
          </TableBody>
        </Table>
        <Scrollbar autoHeightMax={600} autoHeightMin={95} autoHide={false}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  className={clsx(
                    scrollTableCell,
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    ORDER NUMBER
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(
                    scrollTableCell,
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    PLAN NUMBER
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(
                    scrollTableCell,
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    TITLE+SALESHOUSE
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(
                    scrollTableCell,
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    SPOTNAME
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(
                    scrollTableCell,
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    SPOTLENGTH
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(
                    scrollTableCell,
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    MATERIAL
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(
                    scrollTableCell,
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    MATERIAL (NEW/EXISTING)
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(
                    scrollTableCell,
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    DELIVERY
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(
                    scrollTableCell,
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    DELIVERY DATE
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(
                    scrollTableCell,
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    CONTACT
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(
                    scrollTableCell,
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    PHONE NUMBER
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(
                    tableCellUnderline,
                    scrollTableCellHeaderColord
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    className={scrollTableTypography}
                    align="left"
                    noWrap
                  >
                    REMARKS
                  </Typography>
                </TableCell>
              </TableRow>
              {!isEmpty(data.ordersTv) ? (
                ordersTv.map((order, index) => {
                  const {
                    marathonOrderId,
                    marathonPlanId,
                    title,
                    spotName,
                    spotLength,
                    material,
                    deliveryDate,
                    materialNewExisting,
                    delivery,
                    phoneNumber,
                    remarks: orderRemarks,
                    contact: orderContact
                  } = order;
                  return (
                    <TableRow
                      key={`${marathonOrderId + marathonPlanId} - ${index}`}
                    >
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {marathonOrderId}
                        </Typography>
                      </TableCell>
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {marathonPlanId}
                        </Typography>
                      </TableCell>
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {title}
                        </Typography>
                      </TableCell>
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {spotName}
                        </Typography>
                      </TableCell>
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {spotLength}
                        </Typography>
                      </TableCell>
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {material}
                        </Typography>
                      </TableCell>
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {materialNewExisting}
                        </Typography>
                      </TableCell>
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {delivery}
                        </Typography>
                      </TableCell>
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {deliveryDate}
                        </Typography>
                      </TableCell>
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {orderContact}
                        </Typography>
                      </TableCell>
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {phoneNumber}
                        </Typography>
                      </TableCell>
                      <TableCell className={scrollTableCell}>
                        <Typography align="left" noWrap>
                          {orderRemarks}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={17} align="center">
                    <Typography align="center" noWrap>
                      {EMPTY_TABLE}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </Paper>
    </DialogContent>
  );
};

PreviewTV.propTypes = {
  data: PropTypes.object
};

export default PreviewTV;
