import React from "react";
import { Typography } from "@mui/material";
import { classes, MuiDialogTitle } from "../styles/dialogTitle";

const DialogTitle = ({ children }) => {
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography className={classes.titleTypography}>{children}</Typography>
    </MuiDialogTitle>
  );
};

export default DialogTitle;
