import { createReducer } from "../models/common/utils/reducerUtils";

import {
  CLEAR_TRAFFIC_TEMPLATE_MANAGER_PAGE,
  OPEN_MANAGE_TRAFFIC_TEMPLATE_DIALOG,
  CLOSE_MANAGE_TRAFFIC_TEMPLATE_DIALOG
} from "../constants/trafficTemplateManagerConstants";

const initialState = {
  openTemplateDialog: false
};

export const openTemplateDialog = state => ({
  ...state,
  openTemplateDialog: true
});

export const closeTemplateDialog = state => ({
  ...state,
  openTemplateDialog: false
});

export const clearPage = () => initialState;

export default createReducer(initialState, {
  [CLEAR_TRAFFIC_TEMPLATE_MANAGER_PAGE]: clearPage,
  [OPEN_MANAGE_TRAFFIC_TEMPLATE_DIALOG]: openTemplateDialog,
  [CLOSE_MANAGE_TRAFFIC_TEMPLATE_DIALOG]: closeTemplateDialog
});
