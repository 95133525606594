import { createReducer } from "../utils/reducerUtils";

import orm from "../../schema";

import {
  createEntity,
  updateEntity,
  deleteEntity
} from "../entities/entityReducer";

import {
  EDIT_ITEM_EXISTING,
  EDIT_ITEM_NEW,
  EDIT_ITEM_UPDATE,
  EDIT_ITEM_APPLY,
  EDIT_ITEM_STOP,
  EDIT_ITEM_RESET,
  COMMIT_DATA
} from "./editingConstants";

import { getModelByType } from "../utils/modelUtils";

import { selectEntities } from "../entities/entitySelectors";
import { selectEditingEntities } from "./editingSelectors";
import {
  readEntityData,
  updateEntitiesState,
  updateEditingEntitiesState
} from "./editingUtils";
import { TRAFFIC_PLAN_MODEL_NAME } from "../../TrafficPlan/trafficPlanConstants";

export function copyEntity(sourceEntities, destinationEntities, payload) {
  const { itemID, itemType } = payload;

  const newItemAttributes = readEntityData(sourceEntities, itemType, itemID);
  const creationPayload = { itemType, itemID, newItemAttributes };

  const updatedEntities = createEntity(destinationEntities, creationPayload);
  return updatedEntities;
}

export function updateEditedEntity(
  sourceEntities,
  destinationEntities,
  payload
) {
  // Start by reading our "work-in-progress" data
  const readSession = orm.session(sourceEntities);
  const { itemType, itemID } = payload;
  // Look up the model instance for the requested item
  const model = getModelByType(readSession, itemType, itemID);

  // We of course will be updating our "current" relational data
  const writeSession = orm.session(destinationEntities);

  const ModelClass = writeSession[itemType];

  if (ModelClass.idExists(itemID)) {
    // Look up the original Model instance for the top item
    const existingItem = ModelClass.withId(itemID);

    if (existingItem.updateFrom) {
      // Each model class should know how to properly update itself and its
      // relations from another model of the same type.  Ask the original model to
      // update itself based on the "work-in-progress" model. Redux-ORM will apply
      // those changes as we go, and update `session.state` immutably.
      existingItem.updateFrom(model);
    }
  } else {
    const itemContents = model.toJSON();
    ModelClass.parse(itemContents);
  }

  // Return the updated "current" relational data.
  return writeSession.state;
}

export function editItemExisting(state, payload) {
  const entities = selectEntities(state);
  const editingEntities = selectEditingEntities(state);
  const updatedEditingEntities = copyEntity(entities, editingEntities, payload);

  return updateEditingEntitiesState(state, updatedEditingEntities);
}

export function editItemNew(state, payload) {
  const editingEntities = selectEditingEntities(state);

  const updatedEditingEntities = createEntity(editingEntities, payload);
  return updateEditingEntitiesState(state, updatedEditingEntities);
}

export function editItemUpdate(state, payload) {
  const editingEntities = selectEditingEntities(state);

  const updatedEditingEntities = updateEntity(editingEntities, payload);
  return updateEditingEntitiesState(state, updatedEditingEntities);
}

export function editItemStop(state, payload) {
  const editingEntities = selectEditingEntities(state);

  const updatedEditingEntities = deleteEntity(editingEntities, payload);
  return updateEditingEntitiesState(state, updatedEditingEntities);
}

export function editItemApply(state, payload) {
  const entities = selectEntities(state);
  const editingEntities = selectEditingEntities(state);

  const updatedEntities = updateEditedEntity(
    editingEntities,
    entities,
    payload
  );
  return updateEntitiesState(state, updatedEntities);
}

export function editItemReset(state, payload) {
  const stateWithoutItem = editItemStop(state, payload);
  const stateWithCurrentItem = editItemExisting(stateWithoutItem, payload);

  return stateWithCurrentItem;
}

// Update Entity with data from server
export function commitData(state, payload) {
  const entities = selectEntities(state);

  const { itemType, itemID, data } = payload;

  const session = orm.session(entities);
  const ModelClass = session[itemType];
  if (ModelClass.idExists(itemID)) {
    ModelClass.updateModel(data);
  } else if (itemType === TRAFFIC_PLAN_MODEL_NAME) {
    ModelClass.parse(data, data.isDebtorActive);
  } else {
    ModelClass.parse(data);
  }

  const updatedEntities = session.state;

  return updateEntitiesState(state, updatedEntities);
}

const editingFeatureReducer = createReducer(
  {},
  {
    [EDIT_ITEM_EXISTING]: editItemExisting,
    [EDIT_ITEM_NEW]: editItemNew,
    [EDIT_ITEM_UPDATE]: editItemUpdate,
    [EDIT_ITEM_APPLY]: editItemApply,
    [EDIT_ITEM_STOP]: editItemStop,
    [EDIT_ITEM_RESET]: editItemReset,
    [COMMIT_DATA]: commitData
  }
);

export default editingFeatureReducer;
