import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import toUpper from "lodash/toUpper";
import NumberFormat from "react-number-format";
import { Grid, Typography, Tooltip, Divider } from "@mui/material";

import { MoneyIcon } from "../../../../../../assets/styles/icons";
import { classes, Paper } from "../../../styles/sidePanel";
import {
  EMPTY_STRING,
  PLACEHOLDER
} from "../../../../../../configurations/appConstants";

import { currencySymbols } from "../../../../../../business/constants/businessConstants";
import { isNumber } from "../../../../../../functions/util";

const ApprovalRightSidePanel = ({ order }) => {
  const {
    title,
    agencyDiscountAmount,
    fee,
    feePercentage,
    flightId,
    gross,
    grossPerUnit,
    netPerUnit,
    netNetTotal,
    netNetPerUnit,
    totalCostClient,
    remainingBudget,
    plan
  } = order;

  const { totalBudget, currencySupplierId } = plan;
  const currencySymbol = currencySymbols[currencySupplierId] || EMPTY_STRING;

  return (
    <Paper square elevation={0} className={classes.sidePanel}>
      <div className={classes.sidePanelWrapper}>
        <div className={classes.sideBox}>
          <Grid container alignItems="center">
            <Grid item zeroMinWidth>
              <MoneyIcon />
            </Grid>
            <Grid item zeroMinWidth>
              <Typography
                noWrap
                align="left"
                className={classes.typographyHeaderCaption}
              >
                &nbsp;{toUpper("Calculated costs")}
              </Typography>
            </Grid>
            <Grid item zeroMinWidth xs={12}>
              <Tooltip title={title || EMPTY_STRING}>
                <Typography
                  id="title_text"
                  name="title_text"
                  noWrap
                  align="left"
                  className={classes.typographyHeaderValue}
                >
                  {title}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item zeroMinWidth xs={12} className={classes.startSection}>
              <Tooltip
                title={
                  isNumber(totalBudget) ? (
                    <NumberFormat
                      value={totalBudget}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={`${currencySymbol} `}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  ) : (
                    ""
                  )
                }
              >
                <Typography
                  noWrap
                  align="left"
                  className={classes.typographyDetailsValue}
                >
                  {isNumber(totalBudget) ? (
                    <NumberFormat
                      id="totalBudget"
                      name="totalBudget"
                      value={totalBudget}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={`${currencySymbol} `}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  ) : (
                    totalBudget
                  )}
                </Typography>
              </Tooltip>
              <Typography
                noWrap
                align="left"
                className={classes.typographyCaption}
              >
                Total Budget
              </Typography>
            </Grid>
            <Grid item zeroMinWidth xs={12} className={classes.startSection}>
              <Tooltip
                title={
                  isNumber(remainingBudget) ? (
                    <NumberFormat
                      value={remainingBudget}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={`${currencySymbol} `}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  ) : (
                    ""
                  )
                }
              >
                <Typography
                  noWrap
                  align="left"
                  className={clsx(classes.typographyDetailsValue, {
                    [classes.mark]: remainingBudget < 0
                  })}
                >
                  {isNumber(remainingBudget) ? (
                    <NumberFormat
                      id="remainingBudget"
                      name="remainingBudget"
                      value={remainingBudget}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={`${currencySymbol} `}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  ) : (
                    remainingBudget
                  )}
                </Typography>
              </Tooltip>
              <Typography
                noWrap
                align="left"
                className={classes.typographyCaption}
              >
                Remaining Budget
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.sideBox}>
          <Tooltip
            title={
              <NumberFormat
                value={gross}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            }
          >
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              <NumberFormat
                id="grossTotal"
                name="grossTotal"
                value={gross}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Gross Total
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip
            title={
              <NumberFormat
                value={grossPerUnit}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            }
          >
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              <NumberFormat
                id="grossPerUnit"
                name="grossPerUnit"
                value={grossPerUnit}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Gross per Unit
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip
            title={
              <NumberFormat
                value={netPerUnit}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            }
          >
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              <NumberFormat
                id="netPerUnit"
                name="netPerUnit"
                value={netPerUnit}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Net per Unit
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={agencyDiscountAmount || EMPTY_STRING}>
            <Typography
              id="agencyDiscountAmount"
              name="agencyDiscountAmount"
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              {agencyDiscountAmount}
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Agency Discount
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip
            title={
              <NumberFormat
                value={netNetTotal}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            }
          >
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              <NumberFormat
                id="netNetTotal"
                name="netNetTotal"
                value={netNetTotal}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Net Net Total
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip
            title={
              <NumberFormat
                value={netNetPerUnit}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            }
          >
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              <NumberFormat
                id="netNetPerUnit"
                name="netNetPerUnit"
                value={netNetPerUnit}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Net Net per Unit
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={feePercentage || EMPTY_STRING}>
            <Typography
              id="feePercentage"
              name="feePercentage"
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              {feePercentage}
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Fee Percentage
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip
            title={
              <NumberFormat
                value={fee}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            }
          >
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              <NumberFormat
                id="feeAmount"
                name="feeAmount"
                value={fee}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Fee Amount
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip
            title={
              <NumberFormat
                value={totalCostClient}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            }
          >
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              <NumberFormat
                id="totalCostClient"
                name="totalCostClient"
                value={totalCostClient}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySymbol} `}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Total Cost Client
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.sideBox}>
          <Tooltip title={flightId || EMPTY_STRING}>
            <Typography
              align="left"
              noWrap
              className={classes.typographyDetailsValue}
            >
              {flightId || PLACEHOLDER}
            </Typography>
          </Tooltip>
          <Typography align="left" noWrap className={classes.typographyCaption}>
            Flight Id
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

ApprovalRightSidePanel.propTypes = {
  order: PropTypes.object
};

export default ApprovalRightSidePanel;
