import React from "react";
import { Alert } from "@mui/material";

const ConditionInfo = ({ message }) => {
  return (
    <Alert id="conditionMessage" name="conditionMessage" severity="info">
      {message}
    </Alert>
  );
};

export default ConditionInfo;
