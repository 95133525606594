import { createSelector } from "reselect";

const apiManagementPage = state => state.apiManagement;
const selectTable = state => state.apiManagement.table;

export const selectItems = createSelector(selectTable, table => table.items);

export const selectCount = createSelector(selectTable, table => table.count);

export const selectIsOpenDeletePublicPermissionDialog = createSelector(
  apiManagementPage,
  apiManagement => apiManagement.isOpenDeletePublicPermissionDialog
);

export const selectIsOpenAddPublicPermissionDialog = createSelector(
  apiManagementPage,
  apiManagement => apiManagement.isOpenAddPublicPermissionDialog
);

export const selectPublicPermission = createSelector(
  apiManagementPage,
  apiManagement => apiManagement.selectedPublicPermission
);
