import Menu from "./Menu";
import Option from "./Option";
import Control from "./Control";
import NoOptionsMessage from "./NoOptionsMessage";
import Placeholder from "./Placeholder";
import SingleValue from "./SingleValue";
import ValueContainer from "./ValueContainer";

const Components = {
  Menu,
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer
};

export default Components;
