import React, { Fragment } from "react";
import PropTypes from "prop-types";

import NumberFormat from "react-number-format";

import {
  Button,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Grid,
  Paper
} from "@mui/material";

import Scrollbar from "../../../shared/Scrollbar";
import { EMPTY_STRING } from "../../../../configurations/appConstants";
import { currencySymbols } from "../../../../business/constants/businessConstants";

import { classes, Dialog } from "../styles/multipleCurrencySupplierDialog";

const paperProps = { square: true };

const MultipleCurrencySupplierDialog = ({
  open,
  onOk,
  planListData,
  campaignData,
  onEntering
}) => {
  const { tableCell, dialogContent } = classes;
  const {
    campaignId,
    marathonCampaignId,
    clientTotalCostEur: campaignclientTotalCostEur
  } = campaignData;

  const {
    fee: campaignFeeEur = null,
    netNetTotal: campaignNetNetTotalEur = null,
    surcharge1Amount: campaignSurcharge1AmountEur = null,
    surcharge2Amount: campaignSurcharge2AmountEur = null,
    totalCostClient: campaignTotalCostClientEur = null
  } = campaignclientTotalCostEur || {};

  return (
    <Dialog
      open={open}
      PaperProps={paperProps}
      maxWidth="md"
      onEntering={onEntering}
      disableBackdropClick
    >
      <DialogContent className={dialogContent}>
        <Paper>
          <Scrollbar autoHeightMax={500} autoHide={false}>
            <Table>
              <TableBody>
                {planListData.map(x => {
                  const {
                    clientTotalCost,
                    clientTotalCostEur,
                    marathonPlanId,
                    planId,
                    currencySupplierId
                  } = x;

                  const {
                    fee = null,
                    netNetTotal = null,
                    surcharge1Amount = null,
                    surcharge2Amount = null,
                    totalCostClient = null
                  } = clientTotalCost || {};

                  const {
                    fee: feeEur = null,
                    netNetTotal: netNetTotalEur = null,
                    surcharge1Amount: surcharge1AmountEur = null,
                    surcharge2Amount: surcharge2AmountEur = null,
                    totalCostClient: totalCostClientEur = null
                  } = clientTotalCostEur || {};
                  return (
                    (clientTotalCost || clientTotalCostEur) && (
                      <Fragment key={planId}>
                        <TableRow key={marathonPlanId}>
                          <TableCell className={tableCell}>
                            <Typography align="left" noWrap>
                              {EMPTY_STRING}
                            </Typography>
                          </TableCell>
                          {clientTotalCost ? (
                            <TableCell className={tableCell}>
                              <Typography
                                noWrap
                              >{`Plan ID: ${marathonPlanId} (${currencySymbols[currencySupplierId]})`}</Typography>
                            </TableCell>
                          ) : (
                            <TableCell className={tableCell}>
                              <Typography align="left" noWrap>
                                {EMPTY_STRING}
                              </Typography>
                            </TableCell>
                          )}
                          {clientTotalCostEur ? (
                            <TableCell className={tableCell}>
                              <Typography
                                align="left"
                                noWrap
                              >{`Plan ID: ${marathonPlanId} (€)`}</Typography>
                            </TableCell>
                          ) : (
                            <TableCell className={tableCell}>
                              <Typography align="left" noWrap>
                                {EMPTY_STRING}
                              </Typography>
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell className={tableCell}>
                            <Typography align="left">
                              Total Cost Client:
                            </Typography>
                          </TableCell>
                          <TableCell className={tableCell}>
                            <NumberFormat
                              value={totalCostClient}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                          <TableCell className={tableCell}>
                            <NumberFormat
                              value={totalCostClientEur}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={tableCell}>
                            <Typography align="left">Net:</Typography>
                          </TableCell>
                          <TableCell className={tableCell}>
                            <NumberFormat
                              value={netNetTotal}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                          <TableCell className={tableCell}>
                            <NumberFormat
                              value={netNetTotalEur}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={tableCell}>
                            <Typography align="left">Fee:</Typography>
                          </TableCell>
                          <TableCell className={tableCell}>
                            <NumberFormat
                              value={fee}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                          <TableCell className={tableCell}>
                            <NumberFormat
                              value={feeEur}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={tableCell}>
                            <Typography align="left">Surcharge:</Typography>
                          </TableCell>
                          <TableCell className={tableCell}>
                            <NumberFormat
                              value={surcharge1Amount}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                          <TableCell className={tableCell}>
                            <NumberFormat
                              value={surcharge1AmountEur}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={tableCell}>
                            <Typography align="left">Surcharge 2:</Typography>
                          </TableCell>
                          <TableCell className={tableCell}>
                            <NumberFormat
                              value={surcharge2Amount}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                          <TableCell className={tableCell}>
                            <NumberFormat
                              value={surcharge2AmountEur}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </Scrollbar>
        </Paper>
      </DialogContent>
      <Paper>
        <Table>
          <TableBody key={campaignId}>
            <TableRow>
              <TableCell className={tableCell}>
                <Typography align="left">{EMPTY_STRING}</Typography>
              </TableCell>
              <TableCell className={tableCell}>
                <Typography align="right">{`Campaign ID: ${marathonCampaignId} (€)`}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Typography align="left">Total Cost Client:</Typography>
              </TableCell>
              <TableCell className={tableCell}>
                <NumberFormat
                  value={campaignTotalCostClientEur}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Typography align="left">Net:</Typography>
              </TableCell>
              <TableCell className={tableCell}>
                <NumberFormat
                  value={campaignNetNetTotalEur}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Typography align="left">Fee:</Typography>
              </TableCell>
              <TableCell className={tableCell}>
                <NumberFormat
                  value={campaignFeeEur}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Typography align="left">Surcharge:</Typography>
              </TableCell>
              <TableCell className={tableCell}>
                <NumberFormat
                  value={campaignSurcharge1AmountEur}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Typography align="left">Surcharge 2:</Typography>
              </TableCell>
              <TableCell className={tableCell}>
                <NumberFormat
                  value={campaignSurcharge2AmountEur}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Button onClick={onOk} color="primary">
            Ok
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

MultipleCurrencySupplierDialog.propTypes = {
  planListData: PropTypes.array,
  campaignData: PropTypes.object,
  open: PropTypes.bool,
  onOk: PropTypes.func,
  onEntering: PropTypes.func
};

MultipleCurrencySupplierDialog.defaultProps = {
  planListData: [],
  campaignData: {}
};

export default MultipleCurrencySupplierDialog;
