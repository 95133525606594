import React from "react";
import { classes, Div } from "./styles";

const PrivacyStatement = () => {
  return (
    <Div className={classes.container}>
      <h1>Privacy Statement</h1>
      <p>
        GroupM B.V. respects the privacy of it’s virtual assistant users, in
        particular their rights regarding the automatic processing of personal
        data. We have therefore formulated and implemented a policy on complete
        transparency with our users regarding the processing of personal data,
        it’s purpose(s) and the possibilities to exercise your legal rights in
        the best possible way.
      </p>
      <p>
        If you require any additional information about the protection of
        personal data, please visit the website of the Dutch Data Protection
        Authority (Autoriteit Persoonsgegevens):{" "}
        <a href="https://autoriteitpersoonsgegevens.nl/nl">
          https://autoriteitpersoonsgegevens.nl/nl
        </a>
      </p>
      <p>
        Until you accept the use of cookies and other tracking devices, we will
        not place any non-anonymised analytical cookies and/or tracking cookies
        on your computer, mobile phone or tablet. With the continued use of this
        Virtual Assistant you accept these terms of use and you accept the use
        of cookies and other tracking systems, unless we have provided for
        another method of accepting cookies.
      </p>
      <p>
        The current available version of this privacy policy is the only version
        that applies while using or Virtual Assistant until a new version
        replace the current version.
      </p>
      <h2>Article 1 – Definitions</h2>
      <ol className={classes.ol}>
        <li>
          Application (hereinafter “Application”) Spot – Virtual Assistant
        </li>
        <li>
          Party responsible for processing personal data (hereinafter: “the
          controller”): GroupM B.V., established at Amsteldijk 166, 1079LH
          Amsterdam, The Netherlands, Chamber of Commerce number: 34108169
        </li>
      </ol>
      <h2>Article 2 – Access to the application</h2>
      <p>
        Access to, and use of, the application is strictly personal. You will
        refrain from using the data and information of this application for your
        own commercial, political, or advertising purposes, as well as for any
        commercial offers, in particular unsolicited electronic offers.
      </p>
      <h2>Article 3 – Application content</h2>
      <p>
        All brands, images, texts, comments, illustrations, (animated) images,
        and sounds that can be used to operate this application, and in more
        general, all the components used in this application, are protected by
        the laws on intellectual property. Any reproduction, repetition, use or
        modification, by any means whatsoever, of all or just part of it,
        including technical components, without the prior written permission of
        the controller, is strictly prohibited. The fact that the controller may
        not take immediate action against any infringement, cannot be considered
        as a tacit consent, nor of a waiver of any right to prosecute the
        infringing party.
      </p>
      <h2>Article 4 – Management of the Application</h2>
      <p>
        For the purpose of proper management of this application, the controller
        may at any time:
      </p>
      <ul className={classes.ul}>
        <li>
          Suspend, interrupt, reduce or decline the access to the application
          for a particular category of visitors.
        </li>
        <li>
          Delete all information that may disrupt the functioning of the
          application or conflicts with national or international laws or is
          contrary to internet etiquette.
        </li>
        <li>
          Make the application temporary unavailable in order to perform updates
        </li>
      </ul>
      <h2>Article 5 – Responsibility</h2>
      <ol className={classes.ol}>
        <li>
          The controller is not liable for any failure, disturbance,
          difficulties or interruptions in the functioning of the application,
          causing the (temporary) inaccessibility of the application or any of
          it’s functionalities. You, yourself, are responsible for the way you
          seek connection to the application. You need to take all appropriate
          steps to protect your equipment and data against hazards such as virus
          attacks on the internet. Furthermore, you are responsible for which
          websites you visit and what information you seek.
        </li>
        <li>
          <p>
            The controller is not liable for any legal proceedings taken against
            you:
          </p>
          <ol type="a">
            <li>
              Because of use of the application or services accessible via the
              internet
            </li>
            <li>For violating the terms of this privacy policy</li>
          </ol>
        </li>
        <li>
          The controller is not liable for any damages that incur to you or
          third parties or your equipment, as a result of your connection to, or
          use of the application and you will refrain fromany subsequent (legal)
          action against the controller.
        </li>
        <li>
          If the controller is involved in a dispute because of your (ab)use of
          this application, he is entitled to (re)claim all subsequent damages
          from you.
        </li>
      </ol>
      <h2>Article 6 – Collection of data</h2>
      <ol className={classes.ol}>
        <li>Your personal data will be collected by GroupM B.V.</li>
        <li>
          Personal data means any information relating to an identifiable
          natural person (‘data subject’)
        </li>
        <li>
          An identifiable natural person is one who can be identified, directly
          of indirectly, in particular by reference to an identifier such as a
          name, an identification number, location data, an online identifier or
          to one or more factors specific to the physical, physiological,
          genetic, mental, economic, cultural or social identity of that natural
          person.
        </li>
        <li>
          The personal data that are collected in the application are used
          mainly by the collector in order to maintain a (commercial)
          relationship with you and if applicable in order to process your
          orders. They are recorded in an (electronical) register.
        </li>
      </ol>
      <h2>Article 7 – Your rights regarding information</h2>
      <ol className={classes.ol}>
        <li>
          Pursuant to Article 13 paragraph 2 sub b GDPR each data subject has
          the right to information on and access to, and rectification, erasure
          and restriction of processing of his personal data, as well as the
          right to object to the processing and the right to data portability.
        </li>
        <li>
          You can exercise these rights by contacting us at{" "}
          <a href="mailto:support.amsterdam@groupm.com">
            support.amsterdam@groupm.com
          </a>
        </li>
        <li>
          Each request must be accompanied by a copy of a valid ID, on which you
          put your signature and state the address where we can contact you.
        </li>
        <li>
          Within one month of the submitted request, you will receive an answer
          from us.
        </li>
        <li>
          Depending on the complexity and the number of the requests this period
          may be extended to two months
        </li>
      </ol>
      <h2>Article 8 – Legal obligations</h2>
      <ol className={classes.ol}>
        <li>
          In case of an infringement of any law or regulation, of which a user
          is suspected and for which the authorities require the personal data
          collected by the collector, they will be provided to them after an
          explicit and reasoned request of those authorities, after which these
          personal data do not fall anymore under the protection of the
          provisions of this Privacy policy
        </li>
        <li>
          If any information is necessary in order to obtain access to certain
          features of the website, the controller will indicate the mandatory
          nature of this information when requesting these data.
        </li>
      </ol>
      <h2>Article 9 – Collected data and commercial offers</h2>
      <ol className={classes.ol}>
        <li>
          You may receive commercial offers from the collector. If you do not
          wish to receive them (anymore), please send us an email to the
          following address:{" "}
          <a href="mailto: support.amsterdam@groupm.com">
            support.amsterdam@groupm.com
          </a>
        </li>
        <li>
          Your personal data will not be used by our partners for commercial
          purposes.
        </li>
        <li>
          If you encounter any personal data from other data subjects while
          using the application, you are to refrain from collection, any
          unauthorized use or any other act that constitutes an infringement of
          the privacy of the data subject(s) in question. The collector is not
          responsible in these circumstances
        </li>
      </ol>
      <h2>Article 10 – Data retention</h2>
      <p>
        The collected data are used and retained for the duration determined by
        law
      </p>
      <h2>Article 11 – Cookies</h2>
      <ol className={classes.ol}>
        <li>
          A cookie is a small text file placed on the hard drive of your
          electronic device when using the application. A cookie contains data
          so you can be recognized as a user when you are using the application.
          It enables us to adjust to your needs and it facilitates you to log in
          to the application.
        </li>
        <li>
          When you use our application, cookies from the controller and/or third
          parties may be installed on your equipment.
        </li>
        <li>
          For more information about using, managing, and deleting cookies for
          each electronic device, we invite you to consult the following link:{" "}
          <a href="https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/internet-telefoon-tv-en-post/cookies#faq">
            https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/internet-telefoon-tv-en-post/cookies#faq
          </a>
        </li>
      </ol>
      <h2>Article 12 – Imagery and products offered</h2>
      <p>
        You cannot derive any rights from the imagery that accompanies any
        offered product in our application.
      </p>
      <h2>Article 13 – Applicable Law</h2>
      <p>
        These conditions are governed by Dutch law. The court in the district
        where the collector has its place of business has the sole jurisdiction
        if any dispute regarding these conditions may arise, save when a legal
        exception applies
      </p>
      <h2>Article 14 – Contact</h2>
      <p>
        For questions, product information or information about the application
        itself, please contact GroupM IT & Change,{" "}
        <a href="mailto:support.amsterdam@groupm.com">
          support.amsterdam@groupm.com
        </a>
      </p>
      <p className={classes.margin}>
        This privacy statement applies since 1st of January, 2021 until further
        notice.
      </p>
    </Div>
  );
};

export default PrivacyStatement;
