import { createTheme } from "@mui/material";
import { blue } from "@mui/material/colors";
import {
  solitude,
  baliHai,
  sapphire,
  linkWater,
  dodgerBlue,
  cancelRed
} from "./colors";
import { APP_BAR_HEIGHT } from "../../configurations/appConstants";

const unit = 8;
const fontSize = 10.5;
const htmlFontSize = 16;
const coef = fontSize / 14;
export const pxToRem = size => `${(size / htmlFontSize) * coef}rem`;

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      light: blue[500],
      main: blue[500],
      dark: blue[500],
      contrastText: "#fff"
    },
    secondary: {
      main: cancelRed
    },
    action: {
      selected: "rgba(0, 0, 0, 0.1)",
      hover: "rgba(0, 0, 0, 0.1)"
    }
  },
  typography: {
    fontSize
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& $notchedOutline": {
            borderColor: baliHai
          },
          "&$focused $notchedOutline": {
            borderColor: blue[500]
          },
          "&$disabled $notchedOutline": {
            border: 0
          },
          color: sapphire
        },
        adornedEnd: {
          paddingRight: pxToRem(0)
        },
        disabled: {},
        notchedOutline: {}
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(14),
          "&$disabled": {
            borderRadius: 4,
            backgroundColor: solitude
          }
        },
        disabled: {}
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(12)
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(12)
        },
        input: {
          color: sapphire
        },
        underline: {
          "&:before": {
            borderBottom: `1px solid ${solitude}`
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: dodgerBlue,
          top: `calc(50% - ${pxToRem(12)})`
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(12),
          height: pxToRem(18),
          paddingTop: pxToRem(16),
          paddingBottom: pxToRem(16)
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        body2: {
          fontSize: pxToRem(12)
        },
        subtitle1: {
          fontSize: pxToRem(16)
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: `${pxToRem(4)} ${pxToRem(56)} ${pxToRem(4)} ${pxToRem(24)}`,
          "&:last-child": {
            paddingRight: pxToRem(24)
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: pxToRem(40),
          height: pxToRem(40)
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(14)
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          width: pxToRem(56),
          height: pxToRem(56)
        },
        extended: {
          borderRadius: pxToRem(48 / 2),
          padding: `0 ${pxToRem(16)}`,
          minWidth: pxToRem(48),
          height: pxToRem(48),
          "&$sizeSmall": {
            width: "auto",
            padding: `0 ${pxToRem(8)}`,
            borderRadius: pxToRem(34 / 2),
            minWidth: pxToRem(34),
            height: pxToRem(34)
          },
          "&$sizeMedium": {
            width: "auto",
            padding: `0 ${pxToRem(16)}`,
            borderRadius: pxToRem(40 / 2),
            minWidth: pxToRem(40),
            height: pxToRem(40)
          }
        },
        sizeSmall: {
          width: pxToRem(40),
          height: pxToRem(40)
        },
        sizeMedium: {
          width: pxToRem(48),
          height: pxToRem(48)
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 0,
          minWidth: pxToRem(64),
          padding: `${pxToRem(6)} ${pxToRem(16)}`
        },
        text: {
          padding: `${pxToRem(6)} ${pxToRem(8)}`
        },
        outlined: {
          padding: `${pxToRem(5)} ${pxToRem(15)}`
        },
        sizeSmall: {
          padding: `${pxToRem(4)} ${pxToRem(8)}`,
          minWidth: pxToRem(64)
        },
        sizeLarge: {
          padding: `${pxToRem(8)} ${pxToRem(24)}`
        },
        contained: {
          "&$disabled": {
            backgroundColor: solitude,
            color: linkWater
          },
          boxShadow: 0
        },
        disabled: {}
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(12)
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: pxToRem(11),
          paddingBottom: pxToRem(11)
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&$disabled": {
            zIndex: 1
          }
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          height: pxToRem(20),
          minWidth: pxToRem(20),
          borderRadius: pxToRem(10),
          padding: `0 ${pxToRem(4)}`,
          zIndex: 0
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(24)
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: pxToRem(0),
          width: pxToRem(48),
          height: pxToRem(48),
          color: "inherit"
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderStyle: "solid",
          borderColor: solitude,
          borderWidth: pxToRem(1),
          "&:before": {
            height: 0
          },
          "&$expanded": {
            margin: `${pxToRem(16)} 0`
          }
        }
      }
    },
    MuiAccordionActions: {
      styleOverrides: {
        root: {
          padding: pxToRem(8)
        },
        spacing: {
          "& > :not(:first-child)": {
            marginLeft: pxToRem(8)
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)}`
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: pxToRem(unit * 6),
          padding: `0 ${pxToRem(24)} 0 ${pxToRem(24)}`,
          "&$expanded": {
            minHeight: pxToRem(64)
          }
        },
        content: {
          "& > :last-child": {
            paddingRight: pxToRem(0)
          },
          margin: `${pxToRem(12)} 0`,
          "&$expanded": {
            margin: `${pxToRem(20)} 0`
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          padding: pxToRem(2)
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderBottom: "none"
        },
        caption: {
          color: baliHai
        },
        toolbar: {
          height: pxToRem(56),
          minHeight: pxToRem(56),
          paddingRight: pxToRem(2)
        },

        selectRoot: {
          marginRight: pxToRem(32),
          marginLeft: pxToRem(8)
        },

        select: {
          paddingLeft: pxToRem(8),
          paddingRight: pxToRem(16)
        },

        actions: {
          marginLeft: pxToRem(20)
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: pxToRem(48)
        },
        indicator: {
          height: pxToRem(2)
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(14),
          color: baliHai,
          minHeight: pxToRem(APP_BAR_HEIGHT),
          maxWidth: pxToRem(400),
          minWidth: pxToRem(72),
          padding: `${pxToRem(6)} ${pxToRem(12)}`,
          [defaultTheme.breakpoints.up("sm")]: {
            padding: `${pxToRem(6)} ${pxToRem(24)}`
          },
          [defaultTheme.breakpoints.up("sm")]: {
            minWidth: pxToRem(160)
          }
        },
        labelIcon: {
          minHeight: pxToRem(72),
          paddingTop: pxToRem(9)
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: pxToRem(unit * 2)
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: pxToRem(unit * 2),
          justifyContent: "space-between"
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: pxToRem(unit * 2)
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: baliHai
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: pxToRem(14),
          maxWidth: pxToRem(1000)
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          [defaultTheme.breakpoints.up("sm")]: {
            minHeight: pxToRem(64)
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: pxToRem(32),
          borderRadius: pxToRem(32 / 2),
          "& $avatar": {
            marginLeft: pxToRem(5),
            marginRight: pxToRem(-6),
            width: pxToRem(24),
            height: pxToRem(24)
          }
        },

        outlined: {
          backgroundColor: "transparent",

          "& $avatar": {
            marginLeft: pxToRem(4)
          },

          "& $icon": {
            marginLeft: pxToRem(4)
          },

          "& $deleteIcon": {
            marginRight: pxToRem(5)
          }
        },

        icon: {
          marginLeft: pxToRem(5),
          marginRight: pxToRem(-6)
        },

        label: {
          paddingLeft: pxToRem(12),
          paddingRight: pxToRem(12)
        },

        deleteIcon: {
          height: pxToRem(22),
          width: pxToRem(22),
          margin: `0 ${pxToRem(5)} 0 ${pxToRem(-6)}`
        },

        avatar: {}
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: `${pxToRem(8)} ${pxToRem(4)}`
        },
        spacing: {
          margin: `0 ${pxToRem(4)}`
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: pxToRem(16)
        },

        avatar: {
          marginRight: pxToRem(16)
        },

        action: {
          marginTop: pxToRem(-8),
          marginRight: pxToRem(-8)
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: pxToRem(16),
          "&:last-child": {
            paddingBottom: pxToRem(24)
          }
        }
      }
    }
  }
});

export default theme;
