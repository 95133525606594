import { styled, Dialog as MuiDialog } from "@mui/material";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "TrafficDialogStyleStyles";
export const classes = {
  dialog: `${PREFIX}-dialog`,
  root: `${PREFIX}-root`,
  cell: `${PREFIX}-cell`
};

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${classes.dialog}`]: {
    width: theme.typography.pxToRem(400),
    overflowY: "visible" // Autocomplete issue
  },
  [`& .${classes.root}`]: {
    overflowY: "visible !important"
  },
  [`& .${classes.cell}`]: {
    paddingRight: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    paddingTop: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    "& ~ $cell": {
      paddingLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1))
    }
  }
}));
