export const SELECT_TRAFFIC_PLAN_REMARK = "SELECT_TRAFFIC_PLAN_REMARK";
export const START_EDITING_TRAFFIC_PLAN_REMARK =
  "START_EDITING_TRAFFIC_PLAN_REMARK";
export const STOP_EDITING_TRAFFIC_PLAN_REMARK =
  "STOP_EDITING_TRAFFIC_PLAN_REMARK";
export const CLEAR_TRAFFIC_PLAN_REMARK = "CLEAR_TRAFFIC_PLAN_REMARK";

export const TRAFFIC_PLAN_REMARK_MODEL_NAME = "TrafficPlanRemark";
export const TRAFFIC_PLAN_REMARK_MODEL_ID = "trafficPlanRemarkId";
export const TRAFFIC_PLAN_REMARK_PROPERTY = "trafficPlanRemarks";

export const TRAFFIC_PLAN_REMARK_SUCCESS_MESSAGE =
  "Traffic Plan Remark saved successfully";
export const TRAFFIC_PLAN_REMARK_ERROR_MESSAGE =
  "Traffic plan is not saved and remark can not be added";
