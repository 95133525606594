import { styled } from "@mui/material";
import Creatable from "react-select/creatable";
import { getThemeSpacing } from "../../../functions/util";

const PREFIX = "autocompleteStyles";
export const classes = {
  input: `${PREFIX}-input`,
  valueContainer: `${PREFIX}-valueContainer`,
  paper: `${PREFIX}-paper`
};

export const MuiCreatable = styled(Creatable)(({ theme }) => ({
  [`& .${classes.input}`]: {
    display: "flex",
    height: "max-content"
  },
  [`& .${classes.valueContainer}`]: {
    display: "inline-block",
    flex: 1,
    flexWrap: "wrap",
    alignItems: "center"
  },
  [`&.${classes.paper}`]: {
    marginTop: getThemeSpacing(theme, 1)
  }
}));

export const autocompleteCustomStyles = {
  input: styles => {
    return {
      ...styles,
      margin: 0,
      padding: 0
    };
  },
  dropdownIndicator: styles => {
    return {
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 2
    };
  },
  clearIndicator: styles => {
    return {
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 2,
      paddingLeft: 0
    };
  },
  indicatorsContainer: styles => {
    return {
      ...styles,
      " svg": {
        width: 13,
        height: 13
      }
    };
  },
  indicatorSeparator: styles => {
    return {
      ...styles,
      margin: 0
    };
  },
  menuPortal: base => ({ ...base, zIndex: 9999 })
};
