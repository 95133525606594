import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AccordionSummary } from "@mui/material";

import ChangeOrderStatusDialogElement from "./ChangeOrderStatusDialogElement";
import { classes, Accordion } from "../../styles/changeOrderStatusDialogItem";
import {
  selectIsOrderSelected,
  selectedOrders,
  selectedOrderStatus
} from "../../../../../business/models/Plan/planSelectors";
import {
  setSelectedOrderStatusInPlan,
  closeDialog
} from "../../../../../business/models/Plan/planActions";
import withRouter from "../../../configuration/form/withRouter";

class ChangeOrderStatusDialogItem extends PureComponent {
  handleCheckboxClick = selected => {
    const { id, selectedStatus, anySelected } = this.props;

    this.props.handleCheckboxClick(selectedStatus, id, selected);

    if (!anySelected) {
      this.props.closeDialog();
    }
  };

  render() {
    const { id, order, isSelected, statusSuccess } = this.props;

    return (
      <Accordion
        key={id}
        square
        elevation={0}
        classes={{ root: classes.expansionPanel, expanded: classes.selected }}
        expanded={false}
      >
        <AccordionSummary key={id} className={classes.contentWrapper}>
          <ChangeOrderStatusDialogElement
            id={id}
            key={id}
            order={order}
            onCheckboxClick={this.handleCheckboxClick}
            isSelected={isSelected}
            allowOpen={false}
            statusSuccess={statusSuccess}
          />
        </AccordionSummary>
      </Accordion>
    );
  }
}

ChangeOrderStatusDialogItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  order: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    isSelected: selectIsOrderSelected(state, ownProps.id),
    anySelected: selectedOrders(state).length > 1,
    selectedStatus: selectedOrderStatus(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleCheckboxClick: (statusId, orderId, selected) =>
      dispatch(setSelectedOrderStatusInPlan(statusId, orderId, selected)),
    closeDialog: () => dispatch(closeDialog())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangeOrderStatusDialogItem)
);
