import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import clsx from "clsx";

import { AccordionSummary, AccordionDetails, Divider } from "@mui/material";

import InsertionPanel from "../InsertionPanel";

import {
  CAMPAIGN,
  PLAN,
  ORDER,
  CREATE_PAGE,
  EMPTY_STRING
} from "../../../configurations/appConstants";

import { parseIntOrDefault } from "../../../business/models/common/utils/clientUtils";
import { currencies } from "../../../business/constants/businessConstants";

import { selectCurrentOrder } from "../../../business/models/Order/orderSelectors";
import OrderDetailsPopper from "./OrderDetailsPopper";
import OrderCompact from "./OrderCompact";
import { classes, Accordion } from "../styles/item";

const OrderPanelItem = ({ order, allowAddInsertion }) => {
  const navigate = useNavigate();
  const {
    agency,
    campaignId: urlCampaignId,
    planId: urlPlanId,
    orderId: urlOrderId
  } = useParams();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const {
    id,
    statusId,
    marathonOrderId,
    orderStartDate,
    orderEndDate,
    netTotal,
    description,
    orderPoNumber,
    targetAudience,
    position,
    frequencyCap,
    accountId,
    numOfUnits,
    discountAmount,
    unit,
    agencyDiscountAmount,
    remarks,
    numberOfInsertions,
    haveInvoicedInsertions,
    netNetTotal,
    surcharge1Amount,
    surcharge2Amount,
    currencyClient,
    currencySupplierSymbol,
    currencySupplierId,
    feePercentage,
    isInCopyProcess,
    flightId,
    exchangeRateClient,
    exchangeRate
  } = order;

  const currentOrder = useSelector(selectCurrentOrder);

  const showEurCurrency = currencySupplierId === currencies.EUR;

  const isActive = currentOrder === id;

  const changeUrl = () =>
    navigate(
      `/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${urlCampaignId}/${PLAN}/${urlPlanId}${
        parseIntOrDefault(urlOrderId) === id ? EMPTY_STRING : `/${ORDER}/${id}`
      }`
    );

  const handleChange = e => {
    e.stopPropagation();
    changeUrl();
  };

  return (
    <>
      <Accordion
        square
        elevation={0}
        className={clsx(classes.expensionPanel, {
          [classes.selected]: isActive,
          [classes.copyProcess]: isInCopyProcess
        })}
        expanded={isActive}
        onChange={handleChange}
      >
        <AccordionSummary>
          <OrderCompact
            id={id}
            buttonRef={buttonRef}
            setOpen={setOpen}
            open={open}
            allowAddInsertion={allowAddInsertion}
            statusId={statusId}
            marathonOrderId={marathonOrderId}
            description={description}
            orderStartDate={orderStartDate}
            orderEndDate={orderEndDate}
            netTotal={netTotal}
            currencySupplierSymbol={currencySupplierSymbol}
            netNetTotal={netNetTotal}
            surcharge1Amount={surcharge1Amount}
            surcharge2Amount={surcharge2Amount}
            showEurCurrency={showEurCurrency}
            currencyClient={currencyClient}
            currencySupplierId={currencySupplierId}
            haveInvoicedInsertions={haveInvoicedInsertions}
            numberOfInsertions={numberOfInsertions}
            feePercentage={feePercentage}
            isInCopyProcess={isInCopyProcess}
            flightId={flightId}
            exchangeRateClient={exchangeRateClient}
            exchangeRateSupplier={exchangeRate}
          />
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails className={classes.expensionDetailsTablePanel}>
          {isActive && <InsertionPanel isInCopyProcess={isInCopyProcess} />}
        </AccordionDetails>
      </Accordion>
      <OrderDetailsPopper
        id={id}
        buttonRef={buttonRef}
        open={open}
        setOpen={setOpen}
        description={description}
        orderPoNumber={orderPoNumber}
        targetAudience={targetAudience}
        position={position}
        frequencyCap={frequencyCap}
        accountId={accountId}
        numOfUnits={numOfUnits}
        unit={unit}
        discountAmount={discountAmount}
        netTotal={netTotal}
        agencyDiscountAmount={agencyDiscountAmount}
        surcharge1Amount={surcharge1Amount}
        surcharge2Amount={surcharge2Amount}
        remarks={remarks}
        orderId={id}
      />
    </>
  );
};

export default OrderPanelItem;
