import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import CtcButtonCampaign from "./CtcButtonCampaign";
import { CAMPAIGN_MODEL_ID } from "../../../business/models/Campaign/campaignConstants";
import { GET_CTC_INFO_CAMPAIGN } from "../../../configurations/apiUrls";
import { TYPE_ICON, TYPE_BUTTON } from "../../../configurations/appConstants";
import generateUrl from "../../../business/models/common/utils/urlUtils";
import { selectAgency } from "../../../business/selectors/applicationSelectors";
import api from "../../../functions/api";

const CtcButtonWrapperCampaign = ({
  campaignId,
  type,
  hasMultipleCurrencies
}) => {
  const [data, setData] = useState({
    plans: [],
    campaign: {}
  });
  const [loading, setLoading] = useState(false);
  const agencyId = useSelector(selectAgency);

  const handleFetchData = async () => {
    setLoading(true);
    const url = generateUrl(GET_CTC_INFO_CAMPAIGN, {
      agency: agencyId,
      [CAMPAIGN_MODEL_ID]: campaignId
    });
    const response = await api.get(url);
    if (response) {
      const { campaign, plans } = response;
      setData({
        campaign,
        plans
      });
      setLoading(false);
    }
  };

  const { plans, campaign } = data;

  return (
    <CtcButtonCampaign
      name={`c_view_ctc_multiple_${campaignId}`}
      id={`c_view_ctc_multiple_${campaignId}`}
      campaign={campaign}
      plans={plans}
      fetchData={handleFetchData}
      type={type}
      hasMultipleCurrencies={hasMultipleCurrencies}
      showCtc
      loading={loading}
    />
  );
};

CtcButtonWrapperCampaign.propTypes = {
  campaignId: PropTypes.number,
  hasMultipleCurrencies: PropTypes.bool,
  type: PropTypes.oneOf([TYPE_BUTTON, TYPE_ICON])
};

CtcButtonWrapperCampaign.defaultProps = {
  hasMultipleCurrencies: false
};

export default CtcButtonWrapperCampaign;
