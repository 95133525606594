import React from "react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import { TableHead, TableRow, TableCell, TableBody } from "@mui/material";

import { EMPTY_TABLE } from "../../../../../configurations/appConstants";

import FirstTableRow from "./extensions/TargetMetricsKpiFirstTableRow";

import { classes, Table } from "../../styles/targetMetricsKpiTable";

const FirstPlanTargetMetricsKpiTable = ({
  data,
  onHandleEdit,
  onHandleDelete
}) => {
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.header} align="left">
            Channel
          </TableCell>
          <TableCell className={classes.header} align="left">
            Primary Target Audience
          </TableCell>
          <TableCell className={classes.header} align="left">
            Buying Target Audience
          </TableCell>
          <TableCell className={classes.header} align="left">
            Spot Length
          </TableCell>
          <TableCell className={classes.header} align="left">
            Net Reach
          </TableCell>
          <TableCell className={classes.header} align="left">
            OTS
          </TableCell>
          <TableCell className={classes.header} align="left">
            Effective Reach / Contact Range
          </TableCell>
          <TableCell className={classes.header} align="left">
            GRP's / #units
          </TableCell>
          <TableCell className={classes.header} align="center" />
          <TableCell className={classes.header} align="center" />
        </TableRow>
      </TableHead>
      <TableBody>
        {!isEmpty(data) ? (
          data.map(row => (
            <FirstTableRow
              key={row.id}
              data={row}
              onHandleEdit={onHandleEdit}
              onHandleDelete={onHandleDelete}
            />
          ))
        ) : (
          <TableRow>
            <TableCell align="center" colSpan={12}>
              {EMPTY_TABLE}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

FirstPlanTargetMetricsKpiTable.propTypes = {
  onHandleEdit: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired
};

export default FirstPlanTargetMetricsKpiTable;
