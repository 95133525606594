import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import {
  MAX_NUMBER_FOR_INPUTS,
  EMPTY_STRING,
  MIN_NUMBER_FOR_INPUTS
} from "../../../configurations/appConstants";
import { solitude } from "../../../assets/styles/colors";
import { isNullOrUndefinedOrEmpty } from "../../../functions/util";

class DecimalField extends React.Component {
  handleValueChange = ({ floatValue }) => {
    const { onChange } = this.props;
    if (typeof onChange === "function") onChange(floatValue);
  };

  render() {
    const {
      id,
      variant,
      label,
      disabled,
      value,
      prefix,
      displayErrors,
      errors,
      decimalScale,
      inputProps,
      InputProps,
      className,
      InputLabelProps,
      helperText,
      formHelperTextProps,
      maxValue,
      minValue
    } = this.props;

    const inputProperties = {
      ...inputProps,
      autoComplete: "off"
    };
    const isValid = errors.length === 0;
    const errorText = errors[0];
    const minAllowedValue = isNullOrUndefinedOrEmpty(minValue)
      ? MIN_NUMBER_FOR_INPUTS
      : minValue;
    const maxAllowedValue = isNullOrUndefinedOrEmpty(maxValue)
      ? MAX_NUMBER_FOR_INPUTS
      : maxValue;

    return (
      <NumberFormat
        id={id}
        variant={variant}
        label={label}
        FormHelperTextProps={formHelperTextProps}
        InputLabelProps={InputLabelProps}
        disabled={disabled}
        value={value}
        inputProps={inputProperties}
        InputProps={{
          ...InputProps,
          sx: { background: disabled && solitude }
        }}
        error={displayErrors && !isValid}
        helperText={(displayErrors && errorText) || helperText}
        prefix={prefix}
        allowNegative
        thousandSeparator="."
        decimalSeparator=","
        margin="normal"
        fullWidth
        decimalScale={decimalScale}
        customInput={TextField}
        onValueChange={this.handleValueChange}
        fixedDecimalScale
        className={className}
        isAllowed={values => {
          const { formattedValue, floatValue: floatVal } = values;
          return (
            formattedValue === EMPTY_STRING ||
            (floatVal > minAllowedValue && floatVal < maxAllowedValue)
          );
        }}
      />
    );
  }
}

DecimalField.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["standard", "outlined"]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.number || EMPTY_STRING,
  prefix: PropTypes.string,
  displayErrors: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

DecimalField.defaultProps = {
  variant: "outlined",
  errors: [],
  disabled: false,
  displayErrors: false,
  decimalScale: 2
};

export default DecimalField;
