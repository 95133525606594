import React from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import { Checkbox, IconButton, Typography, Tooltip } from "@mui/material";

import { VisibilityIcon } from "../../../../../../assets/styles/icons";
import { PLACEHOLDER } from "../../../../../../configurations/appConstants";
import {
  getPropertyTextFromApiData,
  selectCanUserDoActions
} from "../../../../../../business/models/Plan/planSelectors";

import { classes, Grid } from "../styles/actionPlanItemFold";
import { selectSelectedNumber } from "../../../../../../business/selectors/actionPageSelectors";
import { getOrdersCount } from "../../../../../../business/models/Order/orderSelectors";
import { selectAll } from "../../../../../../business/actions/actionPageActions";

const ActionPlanItemFold = ({ buttonRef, planId, onToggle }) => {
  const dispatch = useDispatch();

  const selectedNumber = useSelector(selectSelectedNumber);
  const ordersCount = useSelector(getOrdersCount);
  const canUserDoActions = useSelector(state =>
    selectCanUserDoActions(state, { planId })
  );

  const planAgreement =
    useSelector(state =>
      getPropertyTextFromApiData(state, { name: "planAgreementId", planId })
    ) || PLACEHOLDER;

  const handleSelectAll = e => {
    e.stopPropagation();
    dispatch(selectAll(planId));
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Checkbox
              id={`ap_check_${planId}`}
              name={`ap_check_${planId}`}
              className={classes.actionPlanCheckbox}
              color="primary"
              onClick={handleSelectAll}
              checked={selectedNumber === ordersCount && selectedNumber > 0}
              indeterminate={selectedNumber > 0 && selectedNumber < ordersCount}
              disabled={!canUserDoActions}
            />
          </Grid>
          <Grid item>
            <IconButton
              name={`ap_view_${planId}`}
              id={`ap_view_${planId}`}
              size="small"
              onClick={onToggle}
              ref={buttonRef}
            >
              <VisibilityIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item>
            <Typography
              id="numOrders"
              name="numOrders"
              align="right"
              className={classes.typographyDetailsValue}
            >
              _
            </Typography>
            <Typography align="right" className={classes.typographyCaption}>
              Orders
            </Typography>
          </Grid>
          <Grid item className={classes.planAgreementGridItem}>
            <Tooltip title={planAgreement} placement="bottom-start">
              <Typography
                id="planAgreement"
                name="planAgreement"
                align="right"
                className={classes.typographyDetailsValue}
                noWrap
              >
                {planAgreement}
              </Typography>
            </Tooltip>
            <Typography align="right" className={classes.typographyCaption}>
              Agreement
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ActionPlanItemFold.propTypes = {
  buttonRef: PropTypes.object,
  planId: PropTypes.number,
  onToggle: PropTypes.func.isRequired
};

export default ActionPlanItemFold;
