import cuid from "cuid";
import get from "lodash/get";
import set from "lodash/set";
import some from "lodash/some";
import {
  USER_SELECT,
  USER_EDIT_START,
  USER_CLEAR_CURRENT,
  USER_DISPLAY_ERRORS,
  USER_HIDE_ERRORS,
  USER_MODEL_NAME,
  USER_SET_SEARCH_TERM,
  USER_SET_SEARCH_PROPERTY,
  USER_EDIT_STOP,
  USER_SUCCESS_MESSAGE,
  USER_MODEL_ID,
  USER_SET_IS_NEW,
  USER_RESET_IS_NEW,
  GET_USERS_SUCCESS,
  USER_ACTIVE_TOGGLE
} from "./userConstants";
import {
  selectCurrentUser,
  selectIsEditingUser,
  getEditingUser,
  doesUserHaveErrors,
  selectRules
} from "./userSelectors";
import {
  enqueueApiErrorMessage,
  enqueueNotificationMessage,
  apiCall
} from "../../actions/applicationActions";
import {
  editExistingItem,
  editItemAttributes,
  editNewItem,
  stopEditingItem,
  commitData
} from "../common/editing/editingActions";
import { LOAD_USERS_SUCCESS } from "../common/data/dataConstants";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import generateUrl from "../common/utils/urlUtils";
import UserModel, { defaultAttributes } from "./User";
import {
  GET_USERS_DATA,
  SAVE_USER,
  GET_USERS,
  CLEAR_SECURITY_CACHE,
  CLEAR_MEDIA_CACHE
} from "../../../configurations/apiUrls";
import { GET, POST, DELETE } from "../../constants/applicationConstants";
import {
  GROUPM_AGENCY_ID,
  MINDSHARE_AGENCY_ID,
  KINETIC_AGENCY_ID,
  ESSENCEMEDIACOM_AGENCY_ID,
  WAVEMAKER_AGENCY_ID,
  BLOSSOM_AGENCY_ID,
  GREENHOUSEONE_AGENCY_ID,
  CHOREOGRAPHCREATE_AGENCY_ID,
  GREENHOUSETECH_AGENCY_ID
} from "../../../configurations/appConstants";
import { selectAgency } from "../../selectors/applicationSelectors";

function mapQueryToReduxUserModel(queryModel) {
  const groupm = queryModel.userAgencyRoleList.filter(
    e => e.agencyId === GROUPM_AGENCY_ID
  )[0];
  let groupmRoleIds = [];
  if (groupm) {
    groupmRoleIds = groupm.roleIds;
  }

  const kinetic = queryModel.userAgencyRoleList.filter(
    e => e.agencyId === KINETIC_AGENCY_ID
  )[0];
  let kineticRoleIds = [];
  if (kinetic) {
    kineticRoleIds = kinetic.roleIds;
  }

  const mindshare = queryModel.userAgencyRoleList.filter(
    e => e.agencyId === MINDSHARE_AGENCY_ID
  )[0];
  let mindshareRoleIds = [];
  if (mindshare) {
    mindshareRoleIds = mindshare.roleIds;
  }

  const mediacom = queryModel.userAgencyRoleList.filter(
    e => e.agencyId === ESSENCEMEDIACOM_AGENCY_ID
  )[0];
  let mediacomRoleIds = [];
  if (mediacom) {
    mediacomRoleIds = mediacom.roleIds;
  }

  const wavemaker = queryModel.userAgencyRoleList.filter(
    e => e.agencyId === WAVEMAKER_AGENCY_ID
  )[0];
  let wavemakerRoleIds = [];
  if (wavemaker) {
    wavemakerRoleIds = wavemaker.roleIds;
  }

  const blossom = queryModel.userAgencyRoleList.filter(
    e => e.agencyId === BLOSSOM_AGENCY_ID
  )[0];
  let blossomRoleIds = [];
  if (blossom) {
    blossomRoleIds = blossom.roleIds;
  }

  const greenhouseone = queryModel.userAgencyRoleList.filter(
    e => e.agencyId === GREENHOUSEONE_AGENCY_ID
  )[0];
  let greenhouseoneRoleIds = [];
  if (greenhouseone) {
    greenhouseoneRoleIds = greenhouseone.roleIds;
  }

  const greenhousetech = queryModel.userAgencyRoleList.filter(
    e => e.agencyId === GREENHOUSETECH_AGENCY_ID
  )[0];
  let greenhousetechRoleIds = [];
  if (greenhousetech) {
    greenhousetechRoleIds = greenhousetech.roleIds;
  }

  const choreographcreate = queryModel.userAgencyRoleList.filter(
    e => e.agencyId === CHOREOGRAPHCREATE_AGENCY_ID
  )[0];
  let choreographcreateRoleIds = [];
  if (choreographcreate) {
    choreographcreateRoleIds = choreographcreate.roleIds;
  }

  const reduxModel = {
    groupmRoles: groupmRoleIds,
    kineticRoles: kineticRoleIds,
    mindshareRoles: mindshareRoleIds,
    mediacomRoles: mediacomRoleIds,
    wavemakerRoles: wavemakerRoleIds,
    blossomRoles: blossomRoleIds,
    greenhouseoneRoles: greenhouseoneRoleIds,
    greenhousetechRoles: greenhousetechRoleIds,
    choreographcreateRoles: choreographcreateRoleIds
  };

  return reduxModel;
}

export function loadUsersData() {
  return dispatch => {
    const url = generateUrl(GET_USERS_DATA, {
      agency: 0
    });

    return dispatch(apiCall(GET, url)).then(
      response => {
        const users = [];
        response.items.forEach(user => {
          const mapper = mapQueryToReduxUserModel(user);
          users.push({ ...user, ...mapper });
        });

        dispatch({
          type: LOAD_USERS_SUCCESS,
          payload: users
        });
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function loadUsers() {
  return (dispatch, getState) => {
    const agencyID = selectAgency(getState());

    const url = generateUrl(GET_USERS, {
      agency: agencyID
    });

    return dispatch(apiCall(GET, url)).then(
      response => {
        dispatch({
          type: GET_USERS_SUCCESS,
          payload: response
        });
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export const activeUsers = () => ({
  type: USER_ACTIVE_TOGGLE
});

export function startEditingUser() {
  return (dispatch, getState) => {
    const currentUser = selectCurrentUser(getState());

    dispatch(editExistingItem(USER_MODEL_NAME, currentUser));

    dispatch({ type: USER_EDIT_START });
  };
}

export function stopEditingUser() {
  return (dispatch, getState) => {
    const currentUser = selectCurrentUser(getState());

    dispatch(stopEditingItem(USER_MODEL_NAME, currentUser));
    dispatch({ type: USER_EDIT_STOP });
    dispatch({ type: USER_HIDE_ERRORS });
    dispatch({ type: USER_RESET_IS_NEW });
  };
}

export function selectUser(userID) {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectIsEditingUser(state);

    if (isEditing) {
      dispatch(stopEditingUser());
    }

    dispatch({ type: USER_CLEAR_CURRENT });

    dispatch({
      type: USER_SELECT,
      payload: userID
    });
  };
}

export function clearCurrentUser() {
  return dispatch => {
    dispatch({ type: USER_CLEAR_CURRENT });
  };
}

export function addNewUser() {
  return (dispatch, getState) => {
    const session = getUnsharedEntitiesSession(getState());
    const { User } = session;

    const id = cuid();
    const newUser = User.generate({ id });
    const UserContents = newUser.toJSON();

    dispatch(editNewItem(USER_MODEL_NAME, id, UserContents));
    dispatch(selectUser(id));
    dispatch({ type: USER_EDIT_START });
    dispatch({ type: USER_SET_IS_NEW });
  };
}

export function setSearchTerm(searchTerm) {
  return {
    type: USER_SET_SEARCH_TERM,
    payload: { searchTerm }
  };
}

export function setSearchProperty(searchProperty) {
  return {
    type: USER_SET_SEARCH_PROPERTY,
    payload: { searchProperty }
  };
}

export function displayUserErrors() {
  return { type: USER_DISPLAY_ERRORS };
}

export function saveUser() {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesUserHaveErrors(state);
    if (hasErrors) {
      dispatch(displayUserErrors());
      return Promise.reject();
    }

    // Transform redux model to command model
    let body = getEditingUser(state);
    body = {
      ...body,
      agentForIds: body.agentForIds || [],
      userAgencyRoleList: [
        { agencyId: GROUPM_AGENCY_ID, roleIds: body.groupmRoles },
        { agencyId: MINDSHARE_AGENCY_ID, roleIds: body.mindshareRoles },
        { agencyId: KINETIC_AGENCY_ID, roleIds: body.kineticRoles },
        { agencyId: ESSENCEMEDIACOM_AGENCY_ID, roleIds: body.mediacomRoles },
        { agencyId: WAVEMAKER_AGENCY_ID, roleIds: body.wavemakerRoles },
        { agencyId: BLOSSOM_AGENCY_ID, roleIds: body.blossomRoles },
        { agencyId: GREENHOUSEONE_AGENCY_ID, roleIds: body.greenhouseoneRoles },
        {
          agencyId: GREENHOUSETECH_AGENCY_ID,
          roleIds: body.greenhousetechRoles
        },
        {
          agencyId: CHOREOGRAPHCREATE_AGENCY_ID,
          roleIds: body.choreographcreateRoles
        }
      ]
    };

    const saveUrl = generateUrl(SAVE_USER, {
      agency: 0
    });

    return dispatch(apiCall(POST, saveUrl, body)).then(
      response => {
        const id = get(response, USER_MODEL_ID);

        let responseData = set(response, "id", id);
        const mapper = mapQueryToReduxUserModel(responseData);
        responseData = { ...responseData, ...mapper };

        dispatch(commitData(USER_MODEL_NAME, id, responseData));
        dispatch(stopEditingUser());
        dispatch(clearCurrentUser());
        dispatch(stopEditingUser());
        dispatch(enqueueNotificationMessage(USER_SUCCESS_MESSAGE));
        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export const resetUserData = () => (dispatch, getState) => {
  const state = getState();
  const currentUser = selectCurrentUser(state);

  const configuration = UserModel.resetFields;
  const rules = selectRules(state);
  const defaults = defaultAttributes;

  const keys = Object.keys(configuration);

  keys.forEach(key => {
    if (some(configuration[key], c => rules[c])) {
      dispatch(
        editItemAttributes(USER_MODEL_NAME, currentUser, {
          [key]: defaults[key]
        })
      );
    }
  });
};

export const dropdownAdjustments = data => (dispatch, getState) => {
  const state = getState();
  const currentUser = selectCurrentUser(state);

  if (data.roleIds) {
    dispatch(
      editItemAttributes(USER_MODEL_NAME, currentUser, {
        groupmRoles: data.roleIds,
        kineticRoles: data.roleIds,
        mindshareRoles: data.roleIds,
        mediacomRoles: data.roleIds,
        wavemakerRoles: data.roleIds,
        blossomRoles: data.roleIds,
        greenhouseoneRoles: data.roleIds,
        greenhousetechRoles: data.roleIds,
        choreographcreateRoles: data.roleIds
      })
    );
  }

  if (data.agentForIds) {
    const currentUserData = getEditingUser(state);
    const roles = currentUserData.roleIds;
    dispatch(
      editItemAttributes(USER_MODEL_NAME, currentUser, {
        groupmRoles: roles,
        kineticRoles: roles,
        mindshareRoles: roles,
        mediacomRoles: roles,
        wavemakerRoles: roles,
        blossomRoles: roles,
        greenhouseoneRoles: roles,
        greenhousetechRoles: roles,
        choreographcreateRoles: roles
      })
    );
  }

  if (data.agencyId) {
    dispatch(
      editItemAttributes(USER_MODEL_NAME, currentUser, {
        agentForIds: [],
        groupmRoles: [],
        kineticRoles: [],
        mindshareRoles: [],
        mediacomRoles: [],
        wavemakerRoles: [],
        blossomRoles: [],
        greenhouseoneRoles: [],
        greenhousetechRoles: [],
        choreographcreateRoles: []
      })
    );
  }
};

export function setUserValue(data) {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = selectCurrentUser(state);

    dispatch(editItemAttributes(USER_MODEL_NAME, currentUser, data));
    dispatch(dropdownAdjustments(data));
    dispatch(resetUserData());
  };
}

export function clearSecurityCache() {
  return dispatch => {
    const clearUrl = generateUrl(CLEAR_SECURITY_CACHE, {
      agency: 0
    });

    return dispatch(apiCall(DELETE, clearUrl)).then(
      response => response,
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function clearMediaCache() {
  return dispatch => {
    const clearUrl = generateUrl(CLEAR_MEDIA_CACHE, {
      agency: 0
    });

    return dispatch(apiCall(DELETE, clearUrl)).then(
      response => response,
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}
