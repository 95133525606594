import download from "../../functions/download";
import {
  SET_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  CLEAR_NOSYSTEM_NOTIFICATIONS
} from "../constants/trafficNotificationsConstants";

import {
  apiCall,
  enqueueApplicationErrorMessage,
  openSystemNotificationDialog,
  enqueueNotificationMessage,
  enqueueApiErrorMessage
} from "./applicationActions";

import {
  NOTIFICATIONS_URL,
  DOWNLOAD_SYSTEM_MESSAGE_ATTACHMENT
} from "../../configurations/apiUrls";

import { GET, PUT } from "../constants/applicationConstants";

import {
  NOTIFICATION_TYPE_SYSTEM,
  NOTIFICATION_STATUS_UNREAD
} from "../../configurations/appConstants";

import generateUrl from "../models/common/utils/urlUtils";

import { selectAgency } from "../selectors/applicationSelectors";
import { SYSTEM_MESSAGE_MODEL_ID } from "../models/SystemMessage/systemMessageConstants";

// Helper method
const hasUnreadSystemNotifications = notifications =>
  notifications.some(
    n =>
      n.typeId === NOTIFICATION_TYPE_SYSTEM &&
      n.statusId === NOTIFICATION_STATUS_UNREAD
  );

const getUrl = state => {
  const agencyID = selectAgency(state);
  const paramObj = { agency: agencyID };

  return generateUrl(NOTIFICATIONS_URL, paramObj);
};

const setNotifications = notifications => dispatch => {
  dispatch({
    type: CLEAR_NOTIFICATIONS
  });
  dispatch({
    type: SET_NOTIFICATIONS,
    payload: notifications
  });
};
// Helper END

export const clearNoSystemNotification = () => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const paramObj = { agency: agencyID };
  const data = { ClearAll: true };
  const url = generateUrl(NOTIFICATIONS_URL, paramObj);

  return dispatch(apiCall(PUT, url, data)).then(
    response => {
      if (response && response.isSuccessful) {
        dispatch({
          type: CLEAR_NOSYSTEM_NOTIFICATIONS
        });
      }
    },
    error => {
      dispatch(enqueueApplicationErrorMessage(error));
    }
  );
};

export const loadNotifications = () => (dispatch, getState) => {
  const state = getState();
  const url = getUrl(state);

  return dispatch(apiCall(GET, url)).then(
    response => {
      if (response) {
        const { notifications } = response;

        dispatch(setNotifications(notifications));

        if (hasUnreadSystemNotifications(notifications)) {
          dispatch(openSystemNotificationDialog());
        }
      }
    },
    error => {
      dispatch(enqueueApplicationErrorMessage(error));
    }
  );
};

export const updateNotificationStatus = key => (dispatch, getState) => {
  const state = getState();
  const data = { Notifications: [key] };
  const url = generateUrl(NOTIFICATIONS_URL, {
    agency: selectAgency(state)
  });

  return dispatch(apiCall(PUT, url, data)).then(
    response => {
      if (response && response.isSuccessful) {
        dispatch(loadNotifications());
      }
    },
    error => {
      dispatch(enqueueApplicationErrorMessage(error));
    }
  );
};

export const downloadSysMsgAttachment = (systemMessageId, fileName) => (
  dispatch,
  getState
) => {
  const state = getState();

  const agencyID = selectAgency(state);
  const paramObj = {
    agency: agencyID,
    [SYSTEM_MESSAGE_MODEL_ID]: systemMessageId
  };
  const url = generateUrl(DOWNLOAD_SYSTEM_MESSAGE_ATTACHMENT, paramObj);

  return dispatch(apiCall(GET, url))
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      download(blob, fileName);
    })
    .then(() =>
      dispatch(enqueueNotificationMessage("File is successfully downloaded!"))
    )
    .catch(() =>
      dispatch(
        enqueueApiErrorMessage("There was problem while downloading file.")
      )
    );
};
