import { styled, Grid as MuiGrid } from "@mui/material";

const PREFIX = "SaveSearchDialogStyles";
export const classes = {
  root: `${PREFIX}-root`
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.root}`]: {
    paddingTop: "15px"
  }
}));
