import { styled, Accordion as MuiAccordion } from "@mui/material";
import { getThemeSpacing } from "../../../../../functions/util";
import {
  baliHai,
  solitude,
  supernova,
  matisse,
  black
} from "../../../../../assets/styles/colors";

const PREFIX = "ActionCompareItemStyle";
export const classes = {
  item: `${PREFIX}-item`,
  typographyOrderHeader: `${PREFIX}-typographyOrderHeader`,
  typographyCompareHeader: `${PREFIX}-typographyCompareHeader`,
  chip: `${PREFIX}-chip`,
  compareChip: `${PREFIX}-compareChip`,
  differenceCompareChip: `${PREFIX}-differenceCompareChip`
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`&.${classes.item}`]: {
    marginTop: theme.typography.pxToRem(8)
  },
  [`& .${classes.typographyOrderHeader}`]: {
    color: matisse,
    width: theme.typography.pxToRem(195),
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`& .${classes.typographyCompareHeader}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`& .${classes.chip}`]: {
    color: baliHai,
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    minWidth: theme.typography.pxToRem(80),
    backgroundColor: solitude
  },
  [`& .${classes.compareChip}`]: {
    width: theme.typography.pxToRem(150)
  },
  [`& .${classes.differenceCompareChip}`]: {
    color: black,
    backgroundColor: supernova
  }
}));
