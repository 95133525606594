import React, { memo } from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { useParams } from "react-router-dom";

import agencyLogo from "../../../assets/styles/agencyLogo";
import { getSidebarItems } from "../../../configurations/routes";

import { selectUserActions } from "../../../business/selectors/userProfileSelectors";
import { selectOpenDrawer } from "../../../business/selectors/applicationSelectors";
import { toggleDrawer } from "../../../business/actions/applicationActions";
import SideBarList from "./extensions/SideBarList";

import { classes, Drawer } from "./styles/sideBar";
import { EMPTY_STRING } from "../../../configurations/appConstants";

const SideBar = () => {
  const dispatch = useDispatch();
  const userActions = useSelector(selectUserActions);
  const open = useSelector(selectOpenDrawer);
  const drawerClasses = {
    paper: clsx(classes.drawer, {
      [classes.drawerShift]: open
    })
  };

  const handleToggleDrawer = () => dispatch(toggleDrawer());

  const { agency } = useParams();
  const drawerItems = getSidebarItems(agency);

  return (
    <Drawer variant="permanent" classes={drawerClasses} open={open}>
      <div className={classes.drawerHeader}>
        <img
          alt="GroupM"
          src={agencyLogo.groupmLogo}
          className={classes.drawerIcon}
          onClick={handleToggleDrawer}
        />
      </div>
      <SideBarList drawerItems={drawerItems} userActions={userActions} />
    </Drawer>
  );
};

SideBar.propTypes = {
  agency: PropTypes.string,
  open: PropTypes.bool,
  handleToggleDrawer: PropTypes.func,
  userActions: PropTypes.array
};

SideBar.defaultProps = {
  userActions: [],
  agency: EMPTY_STRING,
  open: false
};

export default memo(SideBar);
