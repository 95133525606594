import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Grid } from "@mui/material";
import ActionCampaignPanel from "./panel/ActionCampaignPanel";
import ActionPlanPanel from "./panel/ActionPlanPanel";
import ActionOrderPanel from "./panel/ActionOrderPanel";
import ActionDialog from "../shared/dialog/ActionDialog";
import ActionCompareDialog from "../shared/dialog/ActionCompareDialog";

import { parseIntOrDefault } from "../../../../business/models/common/utils/clientUtils";

import {
  loadActionEntityData,
  clearEntityData
} from "../../../../business/models/common/data/dataActions";
import {
  resetPage,
  clearSelected
} from "../../../../business/actions/actionPageActions";
import { selectOpenCompareDialog } from "../../../../business/selectors/actionPageSelectors";
import { clearCurrentCampaign } from "../../../../business/models/Campaign/campaignActions";
import {
  selectPlan,
  clearCurrentPlan
} from "../../../../business/models/Plan/planActions";
import {
  selectOrder,
  clearCurrentOrder
} from "../../../../business/models/Order/orderActions";
import { clearCurrentInsertions } from "../../../../business/models/Insertion/insertionActions";
import { clearCurrentProject } from "../../../../business/models/Project/projectActions";

import { doesPlanExist } from "../../../../business/models/Plan/planSelectors";
import { doesOrderExist } from "../../../../business/models/Order/orderSelectors";

const ActionCampaignPage = () => {
  const dispatch = useDispatch();
  const { planId, orderId, campaignId } = useParams();

  const urlCampaignId = parseIntOrDefault(campaignId);
  const urlPlanId = parseIntOrDefault(planId);
  const urlOrderId = parseIntOrDefault(orderId);

  const isCompareActionDialogOpen = useSelector(selectOpenCompareDialog);

  const planExists = useSelector(state => doesPlanExist(state, urlPlanId));
  const orderExists = useSelector(state =>
    doesOrderExist(state, {
      planId: urlPlanId,
      orderId: urlOrderId
    })
  );

  useEffect(() => {
    if (urlCampaignId) {
      dispatch(loadActionEntityData(urlCampaignId));
    }
    return () => {
      dispatch(resetPage());
      dispatch(clearEntityData());
      dispatch(clearCurrentCampaign());
      dispatch(clearCurrentPlan());
      dispatch(clearCurrentOrder());
      dispatch(clearCurrentInsertions());
      dispatch(clearCurrentProject());
    };
  }, [dispatch, urlCampaignId]);

  useEffect(() => {
    if (planExists && urlPlanId) {
      dispatch(clearCurrentOrder());
      dispatch(clearSelected());
      dispatch(selectPlan(urlPlanId));
    }
    if (orderExists && urlOrderId) {
      dispatch(selectOrder(urlOrderId));
    }

    if (!urlPlanId) {
      dispatch(clearCurrentPlan());
      dispatch(clearSelected());
    }
    if (!urlOrderId) dispatch(clearCurrentOrder());
  }, [dispatch, orderExists, planExists, urlOrderId, urlPlanId]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ActionCampaignPanel />
        </Grid>
        <Grid item xs={4}>
          {urlCampaignId && <ActionPlanPanel />}
        </Grid>
        <Grid item xs={8}>
          {urlPlanId && <ActionOrderPanel />}
        </Grid>
      </Grid>
      {urlPlanId && <ActionDialog />}
      {isCompareActionDialogOpen && <ActionCompareDialog />}
    </>
  );
};

export default ActionCampaignPage;
