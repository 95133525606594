import { styled, Switch as MuiSwitch } from "@mui/material";
import { Switch as FormikMuiSwitch } from "formik-mui";

const PREFIX = "SwitchToggleStyles";
export const classes = {
  root: `${PREFIX}-root`
};

export const Switch = styled(MuiSwitch)(() => ({
  [`& .MuiSwitch-switchBase`]: {
    padding: "9px"
  }
}));

export const FormikSwitch = styled(FormikMuiSwitch)(() => ({
  [`& .MuiSwitch-switchBase`]: {
    padding: "9px"
  }
}));
