import { styled, Grid as MuiGrid } from "@mui/material";
import { crusta, white, dodgerBlue } from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "approvalActionsStyles";
export const classes = {
  saveButton: `${PREFIX}-saveButton`,
  approveButton: `${PREFIX}-approveButton`,
  declineButton: `${PREFIX}-declineButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.saveButton}`]: {
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 3)),
    color: white
  },
  [`& .${classes.approveButton}`]: {
    backgroundColor: dodgerBlue
  },
  [`& .${classes.typographyInvoicedInsertionHeader}`]: {
    backgroundColor: crusta
  }
}));
