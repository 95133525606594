import React from "react";
import PropTypes from "prop-types";

import isEmpty from "lodash/isEmpty";

import { Grid, Toolbar } from "@mui/material";

import Sort from "./ActionTableSort";
import PaginationActions from "./ActionTablePaginationActions";
import OnlyMyCampaignsSearch from "./ActionTableOnlyMyCampaignsSearch";
import MediaTypeSearch from "./ActionTableMediaTypeSearch";
import ClearAll from "./ActionTableClearAll";
import DynamicFilter from "../../../../shared/dynamicFilter/DynamicFilter";
import { MAX_FILTER_NUMBER } from "../../../../../business/constants/applicationConstants";
import { classes, Table } from "../styles/ActionTable";

import { defaultOptions } from "../../../../../business/constants/filterOptions";
import ActionTableBody from "./ActionTableBody";
import ActionTableFooter from "./ActionTableFooter";

const ActionTable = ({
  rows,
  pageSize,
  currentPage,
  searchProperty,
  searchText,
  searchMyCampaigns,
  searchMediaTypes,
  sort,
  count,
  contentLoading,
  onClear,
  onInputSearch,
  onSearchBy,
  onSortChange,
  onSearchMyCampaigns,
  onSearchMediaTypes,
  onChangePage,
  onChangeRowsPerPage,
  onOpenClick,
  allowAction,
  onDynamicFilter,
  queryFilters
}) => {
  return (
    <>
      {(allowAction || contentLoading || isEmpty(rows)) && (
        <>
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <OnlyMyCampaignsSearch
                      searchMyCampaigns={searchMyCampaigns}
                      onSearchMyCampaigns={onSearchMyCampaigns}
                    />
                  </Grid>
                  <Grid item>
                    <MediaTypeSearch
                      searchMediaTypes={searchMediaTypes}
                      onSearchMediaTypes={onSearchMediaTypes}
                    />
                  </Grid>
                  <Grid item>
                    <DynamicFilter
                      searchProperty={searchProperty}
                      searchText={searchText}
                      onInputSearch={onInputSearch}
                      onSearchBy={onSearchBy}
                      updateUrl={onDynamicFilter}
                      queryFilters={queryFilters}
                      filterOptions={defaultOptions}
                      limit={MAX_FILTER_NUMBER}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Sort sortValue={sort} onSortChange={onSortChange} />
                  </Grid>
                  <Grid item>
                    <ClearAll
                      id="action_clearAll"
                      name="action_clearAll"
                      onClear={onClear}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </>
      )}
      <Table className={classes.table}>
        <ActionTableBody
          rows={rows}
          contentLoading={contentLoading}
          onOpenClick={onOpenClick}
        />
        <ActionTableFooter
          count={count}
          pageSize={pageSize}
          currentPage={currentPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          PaginationActions={PaginationActions}
        />
      </Table>
    </>
  );
};

ActionTable.propTypes = {
  rows: PropTypes.array,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  contentLoading: PropTypes.bool,
  allowAction: PropTypes.bool
};

ActionTable.defaultProps = {
  rows: [],
  count: 0,
  pageSize: 10,
  currentPage: 0,
  allowAction: true
};

export default ActionTable;
