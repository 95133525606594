import React from "react";

import ForeignCurrencyFields from "./fields/ForeignCurrencyFields";
import NegativeAmountFields from "./fields/NegativeAmountFields";
import SpecificTitleFields from "./fields/SpecificTitleFields";
import SpecificSurchargeFields from "./fields/SpecificSurchargeFields";
import ExceedThresholdFields from "./fields/ExceedThresholdFields";
import { ruleTypesEnum } from "../../../../configurations/appConstants";

const {
  EXCEED_THRESHOLD_NAME,
  FOREIGN_CURRENCY_NAME,
  SPECIFIC_TITLE_NAME,
  SPECIFIC_SURCHARGE_NAME,
  NEGATIVE_AMOUNT_NAME
} = ruleTypesEnum;

const RuleTypeFields = ({ type, ...otherProps }) => {
  switch (type) {
    case FOREIGN_CURRENCY_NAME:
      return <ForeignCurrencyFields type={type} {...otherProps} />;
    case NEGATIVE_AMOUNT_NAME:
      return <NegativeAmountFields type={type} {...otherProps} />;
    case SPECIFIC_TITLE_NAME:
      return <SpecificTitleFields type={type} {...otherProps} />;
    case SPECIFIC_SURCHARGE_NAME:
      return <SpecificSurchargeFields type={type} {...otherProps} />;
    case EXCEED_THRESHOLD_NAME:
      return <ExceedThresholdFields type={type} {...otherProps} />;
    default:
      return <div />;
  }
};

export default RuleTypeFields;
