import { styled, Grid as MuiGrid } from "@mui/material";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "InsertionFormStyles";
export const classes = {
  deleteButton: `${PREFIX}-deleteButton`,
  insertionDate: `${PREFIX}-insertionDate`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.deleteButton}`]: {
    float: "right",
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.insertionDate}`]: {
    [`& .MuiButtonBase-root`]: {
      width: 18,
      height: 18
    }
  }
}));
