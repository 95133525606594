import {
  styled,
  Typography as MuiTypography,
  Select as MuiSelect
} from "@mui/material";
import { Sort } from "@mui/icons-material";
import { shipCove } from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "SortStyles";
export const classes = {
  cssTypography: `${PREFIX}-cssTypography`,
  cssSortIcon: `${PREFIX}-cssSortIcon`,
  dropDown: `${PREFIX}-dropDown`
};

export const Typography = styled(MuiTypography)(({ theme }) => ({
  [`&.${classes.cssTypography}`]: {
    color: shipCove,
    marginRight: theme.typography.pxToRem(15)
  }
}));

export const SortIcon = styled(Sort)(({ theme }) => ({
  [`&.${classes.cssSortIcon}`]: {
    width: theme.typography.pxToRem(getThemeSpacing(theme, 4)),
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export const Select = styled(MuiSelect)(({ theme }) => ({
  [`&.${classes.dropDown}`]: {
    minWidth: theme.typography.pxToRem(125),
    height: theme.typography.pxToRem(22)
  }
}));
