import { styled, Select as MuiSelect } from "@mui/material";
import { sapphire } from "../../../assets/styles/colors";

const PREFIX = "TrafficStatusDialogSelectStyles";
export const classes = {
  arrowDropDownIcon: `${PREFIX}-arrowDropDownIcon`,
  outlinedInput: `${PREFIX}-outlinedInput`,
  dialogSelect: `${PREFIX}-dialogSelect`
};

export const Select = styled(MuiSelect)(({ theme }) => ({
  [`&.${classes.arrowDropDownIcon}`]: {
    color: sapphire,
    right: theme.typography.pxToRem(5)
  },
  [`&.${classes.outlinedInput}`]: {
    "&:focus": { backgroundColor: "transparent" }
  },
  [`&.${classes.dialogSelect}`]: {
    width: theme.typography.pxToRem(300),
    height: theme.typography.pxToRem(38),
    marginRight: theme.typography.pxToRem(theme.spacing(2))
  }
}));
