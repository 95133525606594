import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import clsx from "clsx";

import NumberFormat from "react-number-format";

import { Typography, IconButton, Chip, Checkbox, Tooltip } from "@mui/material";

import OrderCompactActions from "./OrderCompactActions";
import {
  selectIsOrderSelected,
  selectOrderIsSelectable,
  selectIsOrderForDivideChecked
} from "../../../business/models/Plan/planSelectors";
import {
  doesOrderHaveInvoicedInsertionsById,
  doesOrderHaveStatusToBeCancelledOrCancelledById,
  isOrderPendingForApprovalByID,
  getPropertyTextFromApiData
} from "../../../business/models/Order/orderSelectors";
import { getCampaignData } from "../../../business/models/Campaign/campaignSelectors";
import {
  setSelectedOrderStatusInPlan,
  setOrderForDivide
} from "../../../business/models/Plan/planActions";
import { selectOrder } from "../../../business/models/Order/orderActions";
import { openClientStatusLogDialog } from "../../../business/actions/campaignDashboardActions";
import {
  selectCheckAllOrders,
  selectOrdersActionSwitch
} from "../../../business/selectors/applicationSelectors";
import { canEditCampaign } from "../../../business/selectors/userProfileSelectors";
import ClipboardTooltip from "../../shared/ClipboardTooltip";
import { StatusIcon } from "../../../assets/styles/icons";
import {
  parseDate,
  trimString
} from "../../../business/models/common/utils/clientUtils";
import {
  CREATE_PAGE,
  CAMPAIGN,
  PLAN,
  ORDER,
  PLACEHOLDER
} from "../../../configurations/appConstants";
import CtcChip from "./CtcChip";
import { classes, Grid } from "../styles/orderCompact";
import { selectOrdersWithInsertionChange } from "../../../business/selectors/campaignDashboardSelector";
import { setCheckAllOrders } from "../../../business/actions/applicationActions";

const OrderCompact = ({
  buttonRef,
  setOpen,
  open,
  id,
  statusId,
  allowAddInsertion,
  marathonOrderId,
  flightId,
  description,
  orderStartDate,
  orderEndDate,
  netTotal,
  currencySupplierSymbol,
  surcharge1Amount,
  surcharge2Amount,
  showEurCurrency,
  currencyClient,
  currencySupplierId,
  haveInvoicedInsertions,
  numberOfInsertions,
  feePercentage,
  isInCopyProcess,
  exchangeRateClient,
  exchangeRateSupplier
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    agency,
    campaignId: urlCampaignId,
    planId: urlPlanId,
    orderId: urlOrderId
  } = useParams();

  const title = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "titleId", orderId: id })
  );
  const disableAction = !useSelector(canEditCampaign);
  const switchChecked = useSelector(selectOrdersActionSwitch);
  const checkAllOrdersChecked = useSelector(selectCheckAllOrders);
  const isOrderSelectable = useSelector(state =>
    selectOrderIsSelectable(state, id)
  );
  const hasInvoicedInsertions = useSelector(state =>
    doesOrderHaveInvoicedInsertionsById(state, id)
  );
  const hasStatusToBeCancelledOrCancelled = useSelector(state =>
    doesOrderHaveStatusToBeCancelledOrCancelledById(state, id)
  );
  const hasStatusPendingForApproval = useSelector(state =>
    isOrderPendingForApprovalByID(state, id)
  );

  const disableStatusChangeCheckbox =
    !isOrderSelectable || disableAction || isInCopyProcess;

  const disableInsertionSplitCheckbox =
    hasInvoicedInsertions ||
    disableAction ||
    hasStatusToBeCancelledOrCancelled ||
    isInCopyProcess ||
    hasStatusPendingForApproval;

  useEffect(() => {
    if (checkAllOrdersChecked) {
      if (!disableStatusChangeCheckbox && !switchChecked) {
        dispatch(
          setSelectedOrderStatusInPlan(statusId, id, checkAllOrdersChecked)
        );
      }

      if (!disableInsertionSplitCheckbox && switchChecked) {
        dispatch(setOrderForDivide(id, checkAllOrdersChecked));
      }
    }
  }, [
    checkAllOrdersChecked,
    switchChecked,
    dispatch,
    id,
    statusId,
    disableStatusChangeCheckbox,
    disableInsertionSplitCheckbox
  ]);

  const isOrderSelected = useSelector(state =>
    selectIsOrderSelected(state, id)
  );
  const isOrderForDivideChecked = useSelector(state =>
    selectIsOrderForDivideChecked(state, id)
  );

  const orderInsertionData = useSelector(
    selectOrdersWithInsertionChange
  ).filter(o => o.orderId === id);
  const isNetTotalChanged =
    orderInsertionData.length > 0
      ? orderInsertionData[0].isNetTotalChanged
      : false;
  const isCTCChanged =
    orderInsertionData.length > 0 ? orderInsertionData[0].isCTCChanged : false;

  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const deselectCheckAllOrders = state => {
    if (!state) {
      dispatch(setCheckAllOrders(state));
    }
  };

  const changeUrl = () => {
    const url = `/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${urlCampaignId}/${PLAN}/${urlPlanId}/${ORDER}/${id}`;
    if (urlOrderId !== id) navigate(url);
  };

  const handleClipboardCopy = e => {
    e.stopPropagation();
  };

  const handleCheckBoxClick = e => {
    e.stopPropagation();
    dispatch(setSelectedOrderStatusInPlan(statusId, id, e.target.checked));
    deselectCheckAllOrders(e.target.checked);
  };

  const handleSelectOrderForDivide = e => {
    e.stopPropagation();
    dispatch(setOrderForDivide(id, e.target.checked));
    deselectCheckAllOrders(e.target.checked);
  };

  const handleClientStatusLogDialogOpen = e => {
    e.stopPropagation();
    changeUrl();
    dispatch(selectOrder(id));
    dispatch(openClientStatusLogDialog());
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              {isDebtorActive && (
                <Grid item>
                  {!switchChecked && (
                    <Checkbox
                      name={`o_check_${id}`}
                      id={`o_check_${id}`}
                      onClick={handleCheckBoxClick}
                      checked={isOrderSelected}
                      color="primary"
                      className={classes.checkbox}
                      disabled={disableStatusChangeCheckbox}
                    />
                  )}
                  {switchChecked && (
                    <Checkbox
                      name={`o_check_${id}`}
                      id={`o_check_${id}`}
                      onClick={handleSelectOrderForDivide}
                      checked={isOrderForDivideChecked}
                      color="primary"
                      className={classes.checkbox}
                      disabled={disableInsertionSplitCheckbox}
                    />
                  )}
                </Grid>
              )}

              <Grid item>
                <IconButton
                  name={`o_status_${id}`}
                  id={`o_status_${id}`}
                  className={classes.statusButton}
                  onClick={handleClientStatusLogDialogOpen}
                  disabled={isInCopyProcess}
                >
                  <StatusIcon status={statusId} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <ClipboardTooltip
                  propertyId="orderName-title"
                  entityId={id}
                  onButtonClick={handleClipboardCopy}
                  text={title}
                />
              }
              placement="bottom-start"
            >
              <Typography
                id={`o_title_${id}`}
                name={`o_title_${id}`}
                noWrap
                className={classes.typographyOrderHeader}
              >
                {title}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <ClipboardTooltip
                  propertyId="marathonOrderId"
                  entityId={id}
                  onButtonClick={handleClipboardCopy}
                  text={marathonOrderId}
                />
              }
              placement="bottom-start"
            >
              <Chip
                id={`o_marathonOrderId_${id}`}
                name={`o_marathonOrderId_${id}`}
                label={marathonOrderId}
                className={classes.chip}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <ClipboardTooltip
                  propertyId="description"
                  entityId={description}
                  onButtonClick={handleClipboardCopy}
                  text={description}
                />
              }
              placement="bottom-start"
            >
              <Chip
                id={`o_description_${id}`}
                name={`o_description_${id}`}
                label={trimString(description, 15)}
                className={clsx(classes.chip, classes.widerChip)}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Chip
              id={`o_orderStartDate_${id}`}
              name={`o_orderStartDate_${id}`}
              label={`${parseDate(orderStartDate)} - 
            ${parseDate(orderEndDate)}`}
              className={classes.chip}
            />
          </Grid>
          <Grid item>
            <Chip
              id={`o_netTotal_${id}`}
              name={`o_netTotal_${id}`}
              component={NumberFormat}
              decimalScale={2}
              value={netTotal}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              prefix={`${currencySupplierSymbol} `}
              className={clsx(classes.chip, {
                [classes.chipNotify]: isNetTotalChanged
              })}
              fixedDecimalScale
            />
          </Grid>
          <Grid item>
            <CtcChip
              orderId={id}
              surcharge1Amount={surcharge1Amount}
              surcharge2Amount={surcharge2Amount}
              showEurCurrency={showEurCurrency}
              currencyClient={currencyClient}
              currencySupplierId={currencySupplierId}
              currencySupplierSymbol={currencySupplierSymbol}
              className={clsx(classes.chip, {
                [classes.chipNotify]: isCTCChanged
              })}
              exchangeRateClient={exchangeRateClient}
              exchangeRateSupplier={exchangeRateSupplier}
            />
          </Grid>
          <Grid item>
            <Chip
              id={`o_feePercentage_${id}`}
              name={`o_feePercentage_${id}`}
              component={NumberFormat}
              decimalScale={2}
              value={feePercentage}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              suffix={"%"}
              className={classes.chip}
              fixedDecimalScale
            />
          </Grid>
          <Grid item>
            {flightId ? (
              <Tooltip
                title={
                  <ClipboardTooltip
                    propertyId="flightId"
                    entityId={id}
                    onButtonClick={handleClipboardCopy}
                    text={flightId}
                  />
                }
                placement="bottom-start"
              >
                <Chip
                  id={`o_flightId_${id}`}
                  name={`o_flightId_${id}`}
                  label={trimString(flightId, 15)}
                  className={classes.chipDark}
                />
              </Tooltip>
            ) : (
              <Chip
                id={`o_flightId_${id}`}
                name={`o_flightId_${id}`}
                label={PLACEHOLDER}
                className={classes.chipDark}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <OrderCompactActions
          allowAddInsertion={allowAddInsertion}
          haveInvoicedInsertions={haveInvoicedInsertions}
          numberOfInsertions={numberOfInsertions}
          buttonRef={buttonRef}
          setOpen={setOpen}
          open={open}
          id={id}
          isInCopyProcess={isInCopyProcess}
        />
      </Grid>
    </Grid>
  );
};

OrderCompact.propTypes = {
  buttonRef: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  id: PropTypes.number,
  statusId: PropTypes.number,
  allowAddInsertion: PropTypes.bool,
  marathonOrderId: PropTypes.number,
  description: PropTypes.string,
  orderStartDate: PropTypes.string,
  orderEndDate: PropTypes.string,
  netTotal: PropTypes.number,
  currencySupplierSymbol: PropTypes.string,
  surcharge1Amount: PropTypes.number,
  surcharge2Amount: PropTypes.number,
  showEurCurrency: PropTypes.bool,
  currencySupplierId: PropTypes.string,
  haveInvoicedInsertions: PropTypes.bool,
  numberOfInsertions: PropTypes.number
};

export default OrderCompact;
