import React, { useState, useRef } from "react";

import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";

import { Typography, IconButton, Chip, Checkbox } from "@mui/material";

import { parseDate } from "../../../../../business/models/common/utils/clientUtils";

import { classes, Grid } from "../../styles/changeOrderStatusDialogElement";

import { ViewIcon, StatusIcon } from "../../../../../assets/styles/icons";
import { COLOR_JORDYBLUE } from "../../../../../configurations/appConstants";
import { getPropertyTextFromApiData } from "../../../../../business/models/Order/orderSelectors";
import ChangeOrderStatusDialogElementPopper from "./ChangeOrderStatusDialogElementPopper";

const ChangeOrderStatusDialogElement = ({
  id,
  order,
  isSelected,
  onCheckboxClick
}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleToggle = e => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleClose = event => {
    if (buttonRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const {
    statusId,
    marathonOrderId,
    orderStartDate,
    orderEndDate,
    netTotal,
    description,
    orderPoNumber,
    targetAudience,
    position,
    frequencyCap,
    accountId,
    numOfUnits,
    unit,
    discountAmount,
    agencyDiscountAmount,
    surcharge1Amount,
    surcharge2Amount,
    remarks,
    currencySupplierSymbol,
    feePercentage
  } = order;

  const title = useSelector(state =>
    getPropertyTextFromApiData(state, { name: "titleId", orderId: id })
  );

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        key={id}
        alignItems="center"
      >
        <Grid item xs={9}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <StatusIcon status={statusId} />
            </Grid>
            <Grid item xs={3}>
              <Typography
                id="title"
                name="title"
                className={classes.cssTypography}
                noWrap
              >
                {title}
              </Typography>
            </Grid>
            <Grid item key={marathonOrderId} zeroMinWidth>
              <Chip
                id="orderDate"
                name="orderDate"
                label={marathonOrderId}
                className={classes.chip}
                component={Typography}
              />
            </Grid>
            <Grid item zeroMinWidth key="date">
              <Chip
                id="orderDate"
                name="orderDate"
                label={`${parseDate(orderStartDate)} - ${parseDate(
                  orderEndDate
                )}`}
                className={classes.chip}
                component={Typography}
              />
            </Grid>
            <Grid item key={netTotal} zeroMinWidth>
              <Chip
                label={
                  <NumberFormat
                    id="netTotal"
                    name="netTotal"
                    decimalScale={2}
                    value={netTotal}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={currencySupplierSymbol}
                    fixedDecimalScale
                  />
                }
                className={classes.chip}
                component={Typography}
              />
            </Grid>
            <Grid item key={feePercentage} zeroMinWidth>
              <Chip
                label={
                  <NumberFormat
                    id="feePercentage"
                    name="feePercentage"
                    decimalScale={2}
                    value={feePercentage}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="%"
                    fixedDecimalScale
                  />
                }
                className={classes.chip}
                component={Typography}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={0}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <IconButton
                id="o_view"
                name="o_view"
                onClick={handleToggle}
                ref={buttonRef}
                className={classes.iconButton}
              >
                <ViewIcon color={COLOR_JORDYBLUE} />
              </IconButton>
            </Grid>
            <Grid item>
              <Checkbox
                id="o_check"
                name="o_check"
                onClick={e => onCheckboxClick(e.target.checked)}
                checked={isSelected}
                color="primary"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ChangeOrderStatusDialogElementPopper
        onClose={handleClose}
        open={open}
        ref={buttonRef}
        bRef={buttonRef}
        orderId={id}
        description={description}
        orderPoNumber={orderPoNumber}
        targetAudience={targetAudience}
        position={position}
        frequencyCap={frequencyCap}
        accountId={accountId}
        numOfUnits={numOfUnits}
        unit={unit}
        discountAmount={discountAmount}
        netTotal={netTotal}
        agencyDiscountAmount={agencyDiscountAmount}
        remarks={remarks}
        surcharge1Amount={surcharge1Amount}
        surcharge2Amount={surcharge2Amount}
      />
    </>
  );
};

export default ChangeOrderStatusDialogElement;
