/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import clsx from "clsx";

import {
  AccordionSummary,
  Typography,
  Grid,
  Link,
  Tooltip
} from "@mui/material";

import { CampaignStatusIcon } from "../../../../../assets/styles/icons";
import ClipboardTooltip from "../../../../shared/ClipboardTooltip";

import { classes, Accordion } from "../styles/actionCampaignItem";
import {
  ACTION_PAGE,
  CAMPAIGN
} from "../../../../../configurations/appConstants";
import { isNullOrUndefined } from "../../../../../functions/util";

const ActionCampaignItem = ({ id, campaign, onOpenClick }) => {
  const { agency } = useParams();
  const {
    toBeCanceledOrders,
    orderChangeOrders,
    readyForApprovalOrders,
    approvedOrders,
    debtor,
    searchParam,
    campaignName,
    campaignStatus,
    campaignId
  } = campaign;
  const { name } = debtor || {};

  const handleOpen = event => {
    event.stopPropagation();
    onOpenClick(id, searchParam);
  };

  const handleCopy = event => {
    event.stopPropagation();
  };

  const preventDefault = event => {
    event.preventDefault();
  };

  return (
    <>
      <Accordion square elevation={0} expanded={false}>
        <AccordionSummary>
          <Grid container justifyContent="space-between">
            <Grid item xs={3} sm={3} md={3} lg={4} xl={5}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <CampaignStatusIcon status={campaignStatus} />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography
                    id={`debtorName_${id}`}
                    name={`debtorName_${id}`}
                    className={classes.typographyHeaderCaption}
                  >
                    {name}
                  </Typography>
                  <Tooltip
                    title={
                      <ClipboardTooltip
                        onButtonClick={handleCopy}
                        text={campaignName}
                      />
                    }
                    placement="bottom-start"
                  >
                    <Typography
                      onClick={handleOpen}
                      className={clsx(
                        classes.typographyHeaderValue,
                        classes.typographyLink
                      )}
                      noWrap
                    >
                      <Link
                        name={`ac_redirect_${campaignId}`}
                        id={`ac_redirect_${campaignId}`}
                        className={classes.link}
                        href={
                          isNullOrUndefined(searchParam)
                            ? `/${agency}/${ACTION_PAGE}/${CAMPAIGN}/${campaignId}`
                            : `/${agency}/${ACTION_PAGE}/${CAMPAIGN}/${searchParam}`
                        }
                        onClick={preventDefault}
                        underline="none"
                        color="inherit"
                      >
                        {campaignName}
                      </Link>
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={3} justifyContent="flex-end">
                <Grid item>
                  <Typography
                    id={`numToBeCanceledOrders_${id}`}
                    name={`numToBeCanceledOrders_${id}`}
                    align="right"
                    className={classes.cssToBeCancelled}
                  >
                    {toBeCanceledOrders}
                  </Typography>
                  <Typography
                    align="right"
                    className={classes.typographyCaption}
                  >
                    To be cancelled
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    id={`numOrderChangeOrders_${id}`}
                    name={`numOrderChangeOrders_${id}`}
                    align="right"
                    className={classes.cssOrderChange}
                  >
                    {orderChangeOrders}
                  </Typography>
                  <Typography
                    align="right"
                    className={classes.typographyCaption}
                  >
                    Order change
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    id={`numReadyForApprovalOrders_${id}`}
                    name={`numReadyForApprovalOrders_${id}`}
                    align="right"
                    className={classes.cssReadyForApproval}
                  >
                    {readyForApprovalOrders}
                  </Typography>
                  <Typography
                    align="right"
                    className={classes.typographyCaption}
                  >
                    Ready for approval
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    id={`numApprovedOrders_${id}`}
                    name={`numApprovedOrders_${id}`}
                    align="right"
                    className={classes.cssApproved}
                  >
                    {approvedOrders}
                  </Typography>
                  <Typography
                    align="right"
                    className={classes.typographyCaption}
                  >
                    Approved
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
    </>
  );
};

ActionCampaignItem.propTypes = {
  id: PropTypes.number,
  campaign: PropTypes.object,
  onOpenClick: PropTypes.func.isRequired
};

export default ActionCampaignItem;
