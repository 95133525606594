import React from "react";
import PropTypes from "prop-types";

import isEmpty from "lodash/isEmpty";
import cuid from "cuid";

import { Grid, TableBody, TableCell, TableRow, Toolbar } from "@mui/material";

import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

import { EMPTY_TABLE } from "../../../../../configurations/appConstants";

import RuleTypeSearch from "./AdditionalApprovalRuleTypeSearch";
import AdditionalApprovalPanel from "../panel/AdditionalApprovalPanel";
import PaginationActions from "./AdditionalApprovalTablePaginationActions";
import DynamicFilter from "../../../../shared/dynamicFilter/DynamicFilter";
import { defaultOptionsForAdditionalApproval } from "../../../../../business/constants/filterOptions";
import { MAX_FILTER_NUMBER } from "../../../../../business/constants/applicationConstants";
import { classes, Table } from "../../styles/AdditionalApprovalTable";

const rowsPerPageOptions = [5, 10, 25];
const AdditionalApprovalTable = ({
  rows,
  count,
  contentLoading,
  pageSize,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  onOrderClick,
  searchProperty,
  searchText,
  onInputSearch,
  onSearchBy,
  onDynamicFilter,
  queryFilters,
  searchRuleTypes,
  onSearchRuleTypes
}) => {
  return (
    <>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <RuleTypeSearch
                  searchRuleTypes={searchRuleTypes}
                  onSearchRuleTypes={onSearchRuleTypes}
                />
              </Grid>
              <Grid item>
                <DynamicFilter
                  isAdditionalApproval
                  searchProperty={searchProperty}
                  searchText={searchText}
                  onInputSearch={onInputSearch}
                  onSearchBy={onSearchBy}
                  updateUrl={onDynamicFilter}
                  queryFilters={queryFilters}
                  filterOptions={defaultOptionsForAdditionalApproval}
                  limit={MAX_FILTER_NUMBER}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Table className={classes.table}>
        <TableBody>
          {!isEmpty(rows)
            ? rows.map(row => (
                <TableRow key={cuid()}>
                  <TableCell className={classes.tableCell}>
                    <AdditionalApprovalPanel
                      condition={row}
                      onOrderClick={onOrderClick}
                    />
                  </TableCell>
                </TableRow>
              ))
            : !contentLoading && (
                <TableRow>
                  <TableCell align="center">{EMPTY_TABLE}</TableCell>
                </TableRow>
              )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={3}
              count={count}
              rowsPerPage={pageSize}
              page={currentPage}
              rowsPerPageOptions={rowsPerPageOptions}
              SelectProps={{
                native: true
              }}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              ActionsComponent={PaginationActions}
              loading={contentLoading.toString()}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

AdditionalApprovalTable.propTypes = {
  rows: PropTypes.array,
  count: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  contentLoading: PropTypes.bool
};

AdditionalApprovalTable.defaultProps = {
  rows: [],
  count: 0,
  pageSize: 10,
  currentPage: 0
};

export default AdditionalApprovalTable;
