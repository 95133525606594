import React from "react";
import PropTypes from "prop-types";

import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";

import { DASH_PLACEHOLDER } from "../../../../../../configurations/appConstants";
import { EditIcon, DeleteIcon } from "../../../../../../assets/styles/icons";

import { trimString } from "../../../../../../business/models/common/utils/clientUtils";
import { isNullOrUndefinedOrEmpty } from "../../../../../../functions/util";

const FirstRow = ({ data, onHandleEdit, onHandleDelete }) => {
  const {
    id,
    channel,
    primaryTargetAudience,
    buyingTargetAudience,
    spotLength,
    netReach,
    ots,
    effectiveReachContactRange,
    grps
  } = data;
  const stringLength = 12;
  return (
    <TableRow>
      <TableCell align="left">
        <Tooltip title={channel || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(channel)
              ? trimString(channel, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={primaryTargetAudience || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(primaryTargetAudience)
              ? trimString(primaryTargetAudience, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={buyingTargetAudience || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(buyingTargetAudience)
              ? trimString(buyingTargetAudience, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={spotLength || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(spotLength)
              ? trimString(spotLength, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={netReach || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(netReach)
              ? trimString(netReach, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={ots || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(ots)
              ? trimString(ots, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title={effectiveReachContactRange || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(effectiveReachContactRange)
              ? trimString(effectiveReachContactRange, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={grps || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(grps)
              ? trimString(grps, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <IconButton
          name={`tmkpi_edit_${id}`}
          id={`tmkpi_edit_${id}`}
          onClick={() => onHandleEdit(id)}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell align="left">
        <IconButton
          name={`tmkpi_delete_${id}`}
          id={`tmkpi_delete_${id}`}
          onClick={() => onHandleDelete(id)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

FirstRow.propTypes = {
  onHandleEdit: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired
};

export default FirstRow;
