import {
  styled,
  Snackbar as MuiSnackbar,
  Grid as MuiGrid
} from "@mui/material";
import { getThemeSpacing } from "../../../../../functions/util";

const PREFIX = "aaConfigFormStyles";
export const classes = {
  alert: `${PREFIX}-alert`,
  grid: `${PREFIX}-grid`
};

export const Snackbar = styled(MuiSnackbar)(({ theme }) => ({
  [`&.${classes.alert}`]: {
    marginTop: getThemeSpacing(theme, 5)
  }
}));

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`&.${classes.grid}`]: {
    paddingTop: "11px"
  }
}));
