import { styled, Grid as MuiGrid } from "@mui/material";
import { supernova, white, linkWater } from "../../../../assets/styles/colors";

const PREFIX = "CampaignEditActionsStyles";
export const classes = {
  cancelButton: `${PREFIX}-cancelButton`,
  saveButton: `${PREFIX}-saveButton`,
  markedButton: `${PREFIX}-markedButton`
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.cancelButton}`]: {
    color: white,
    backgroundColor: linkWater
  },
  [`& .${classes.saveButton}`]: {
    color: white
  },
  [`& .${classes.markedButton}`]: {
    backgroundColor: supernova
  }
}));
