import React, { Component } from "react";
import NumberFormat from "react-number-format";

import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  IconButton,
  Paper,
  Chip,
  Divider,
  Grow,
  ClickAwayListener
} from "@mui/material";

import InsertionTable from "./InsertionTable";

import {
  ViewIcon,
  InsertionIcon,
  StatusIcon
} from "../../../../../assets/styles/icons";

import { parseDate } from "../../../../../business/models/common/utils/clientUtils";
import { ORDER_MODEL_ID } from "../../../../../business/models/Order/orderConstants";
import {
  PLACEHOLDER,
  COLOR_JORDYBLUE
} from "../../../../../configurations/appConstants";
import { isNullOrUndefined } from "../../../../../functions/util";
import { classes, Accordion } from "../../styles/orderPanel";
import {
  classes as cls,
  Popper
} from "../../../../panels/styles/orderDetailsPopper";

class OrderPanelItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  handleToggle = e => {
    e.stopPropagation();
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleAddInsertion = (e, id) => {
    e.stopPropagation();
    this.props.onAddInsertion(id);
  };

  render() {
    const { order, allowAddInsertion, disableAddInsertions } = this.props;
    const {
      statusId,
      title,
      marathonOrderId,
      orderStartDate,
      orderEndDate,
      netTotal,
      channel,
      description,
      agreement,
      orderPoNumber,
      targetAudience,
      digitalChannel,
      format,
      position,
      frequencyCap,
      accountId,
      costType,
      numOfUnits,
      unit,
      grossTotal,
      grossPerUnit,
      discountCode,
      discountAmount,
      netPerUnit,
      agencyDiscountAmount,
      netNetTotal,
      netNetPerUnit,
      fee,
      surcharge1Type,
      surcharge1Amount,
      surcharge2Type,
      surcharge2Amount,
      totalCostClient,
      remarks,
      statusText,
      currencySupplierSymbol,
      feePercentage
    } = order;
    const { open } = this.state;
    return (
      <>
        <Accordion
          square
          elevation={0}
          className={classes.expensionPanel}
          expanded
        >
          <AccordionSummary className={classes.accordionSummary}>
            <Grid container justifyContent="space-between" direction="column">
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid item>
                        <StatusIcon status={statusId} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.typographyOrderHeader}>
                          {title}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={marathonOrderId}
                          className={classes.chip}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          label={`${parseDate(orderStartDate)} - 
                      ${parseDate(orderEndDate)}`}
                          className={classes.chip}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          component={NumberFormat}
                          value={netTotal}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix={`${currencySupplierSymbol} `}
                          className={classes.chip}
                          fixedDecimalScale
                          decimalScale={2}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          component={NumberFormat}
                          value={feePercentage}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          suffix="%"
                          className={classes.chip}
                          fixedDecimalScale
                          decimalScale={2}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid item>
                        <IconButton
                          onClick={this.handleToggle}
                          ref={node => {
                            this.anchorEl = node;
                          }}
                          className={classes.iconButton}
                        >
                          <ViewIcon color={COLOR_JORDYBLUE} />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          disabled={!allowAddInsertion || disableAddInsertions}
                          onClick={e =>
                            this.handleAddInsertion(e, order[ORDER_MODEL_ID])
                          }
                          className={classes.iconButton}
                        >
                          <InsertionIcon
                            disabled={
                              !allowAddInsertion || disableAddInsertions
                            }
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Divider variant="middle" />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.expensionDetailsTablePanel}>
            <InsertionTable />
          </AccordionDetails>
        </Accordion>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          placement="bottom-end"
          transition
          className={cls.orderPopper}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="o_view_grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper className={cls.detailsPanel}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {channel || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Channel
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {description || PLACEHOLDER}
                      </Typography>
                      <Typography
                        noWrap
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Description
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        noWrap
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {agreement || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Agreement
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {orderPoNumber || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        PO Number
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {targetAudience || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Target Audience
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {digitalChannel || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Digital Channel
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {format || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Format
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {position || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Position
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {frequencyCap || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Frequency Cap
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {accountId || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        AccountID
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {costType || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Cost Type
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(numOfUnits) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={numOfUnits}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        # of Units
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {unit || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Unit
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(grossTotal) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={grossTotal}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Gross Total
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(grossPerUnit) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={grossPerUnit}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Gross per Unit
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {discountCode || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Discount Type
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(discountAmount) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={discountAmount}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Discount Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(netTotal) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={netTotal}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Net Total
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(netPerUnit) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={netPerUnit}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Net per Unit
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(agencyDiscountAmount) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={agencyDiscountAmount}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Agency Discount
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(netNetTotal) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={netNetTotal}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Net Net Total
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(netNetPerUnit) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={netNetPerUnit}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Net Net per Unit
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(fee) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={fee}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Fee Percentage
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {surcharge1Type || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Surcharge Type 1
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(surcharge1Amount) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={surcharge1Amount}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Surcharge Amount 1
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {surcharge2Type || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Surcharge Type 2
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(surcharge2Amount) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={surcharge2Amount}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Surcharge Amount 2
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {!isNullOrUndefined(totalCostClient) ? (
                          <NumberFormat
                            decimalScale={2}
                            value={totalCostClient}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                          />
                        ) : (
                          PLACEHOLDER
                        )}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Total Cost Client
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {remarks || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Order Remarks
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        align="left"
                        className={cls.typographyDetailsValue}
                      >
                        {statusText || PLACEHOLDER}
                      </Typography>
                      <Typography
                        align="left"
                        className={cls.typographyCaption}
                      >
                        Client Status
                      </Typography>
                    </Grid>
                  </Grid>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
}

export default OrderPanelItem;
