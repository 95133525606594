import React, { Component } from "react";
import { connect } from "react-redux";

import { withSnackbar } from "notistack";

import { selectNotifications } from "../../business/selectors/applicationSelectors";
import { removeNotificationMessage } from "../../business/actions/applicationActions";
import SuccessNotifierAction from "./snackbarActions/SuccessNotifierAction";

class SuccessNotifier extends Component {
  displayed = [];

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id];
  };

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      // eslint-disable-next-line no-continue
      if (notExists) continue;
      notExists =
        notExists ||
        !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach(notification => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) return;
      // Display snackbar using notistack
      this.props.enqueueSnackbar(notification.message, {
        variant: "success",
        action: (
          <SuccessNotifierAction closeSnackbar={this.props.closeSnackbar} />
        )
      });
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key);
      // Dispatch action to remove snackbar from redux store
      this.props.removeNotificationMessage(notification.key);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  notifications: selectNotifications(state)
});

const mapDispatchToProps = dispatch => ({
  removeNotificationMessage: key => dispatch(removeNotificationMessage(key))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SuccessNotifier));
