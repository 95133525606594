import { styled } from "@mui/material";
import { dodgerBlue } from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "TrafficTablePaginationActionsStyles";
export const classes = {
  root: `${PREFIX}-root`
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexShrink: 0,
    color: dodgerBlue,
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 2.5))
  }
}));
