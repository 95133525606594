// ACTION PAGE CONSTANTS
export const TOGGLE_SELECTED = "TOGGLE_SELECTED";
export const CLEAR_SELECTED = "CLEAR_SELECTED";
export const OPEN_DIALOG = "OPEN_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const SET_ACTION = "SET_ACTION";
export const CLEAR_ACTION = "CLEAR_ACTION";
export const SET_SEND_STATUSES = "SET_SEND_STATUSES";
export const SET_UNSEND_STATUSES = "SET_UNSEND_STATUSES";
export const CLEAR_SEND_STATUSES = "CLEAR_SEND_STATUSES";
export const CLEAR_UNSEND_STATUSES = "CLEAR_UNSEND_STATUSES";
export const RESET_ACTION_PAGE = "RESET_ACTION_PAGE";
export const SET_COMPARE_ORDER_ID = "SET_COMPARE_ORDER_ID";
export const OPEN_COMPARE_DIALOG = "OPEN_COMPARE_DIALOG";
export const CLOSE_COMPARE_DIALOG = "CLOSE_COMPARE_DIALOG";
// TABLE CONSTANTS
export const SET_ITEMS = "SET_ITEMS";
export const SET_COUNT = "SET_COUNT";
export const CLEAR_ITEMS = "CLEAR_ITEMS";

// ACTION CONSTANTS
export const ACTION_DECLINE = "decline";
export const ACTION_APPROVE = "approve";
export const ACTION_DONE = "done";
