import React from "react";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";

class Menu extends React.Component {
  render() {
    const { selectProps, innerProps, children } = this.props;
    const { classes } = selectProps;
    return (
      <Paper square className={classes.paper} {...innerProps}>
        {children}
      </Paper>
    );
  }
}

Menu.propTypes = {
  selectProps: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
  children: PropTypes.object
};

export default Menu;
