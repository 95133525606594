import React, { memo } from "react";

import { connect } from "react-redux";
import Select from "react-select";

import { Button, DialogContent, Grid, DialogActions } from "@mui/material";

import { selectOpenAddTrafficDialog } from "../../../../business/selectors/applicationSelectors";
import {
  selectOrderList,
  selectOrderForTraffic,
  getSelectedTraffic
} from "../../../../business/models/TrafficPlan/trafficPlanSelectors";
import {
  clearOrderList,
  setSelectedOrderForTraffic,
  clearSelectedOrderForTraffic
} from "../../../../business/models/TrafficPlan/trafficPlanActions";

import { addTrafficItem } from "../../../../business/models/TrafficItem/trafficItemActions";
import DialogTitle from "../../../shared/dialog/DialogTitle";
import {
  classes,
  Dialog,
  selectCustomStyles
} from "../styles/addTrafficItemDialog";
import { closeAddTrafficDialog } from "../../../../business/actions/applicationActions";

const paperProps = { square: true };

const AddTrafficItemDialog = ({
  addTraffic,
  clearSelectedOrderc,
  onClose,
  setSelectedOrder,
  isOpen,
  orders,
  isDiabled,
  selectedTraffic
}) => {
  const { isDebtorActive } = selectedTraffic || false;

  const handleAdd = () => {
    addTraffic();
    clearSelectedOrderc();
    onClose();
  };

  const handleCancel = () => {
    clearSelectedOrderc();
    onClose();
  };

  const handleChange = selected => {
    setSelectedOrder(selected.value);
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={paperProps}
      className={classes.dialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="add-traffic-item-dialog-title">
        Add new Traffic Item
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container>
          <Grid item xs={12}>
            <Select
              id="select_traffic-item"
              name="select_traffic-item"
              options={orders}
              onChange={handleChange}
              // className={classes.option} TODO(AM)
              styles={selectCustomStyles}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel_traffic-item"
          name="cancel_traffic-item"
          onClick={handleCancel}
          color="primary"
        >
          CANCEL
        </Button>
        {isDebtorActive && (
          <Button
            id="add_traffic-item"
            name="add_traffic-item"
            onClick={handleAdd}
            color="secondary"
            disabled={isDiabled}
          >
            ADD TO PLAN
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    isOpen: selectOpenAddTrafficDialog(state),
    orders: selectOrderList(state),
    isDiabled: !selectOrderForTraffic(state),
    selectedTraffic: getSelectedTraffic(state)
  };
};

const mapDispatchToProps = dispatch => ({
  clearOrders: () => dispatch(clearOrderList()),
  setSelectedOrder: orderId => dispatch(setSelectedOrderForTraffic(orderId)),
  clearSelectedOrderc: () => dispatch(clearSelectedOrderForTraffic()),
  addTraffic: () => dispatch(addTrafficItem()),
  onClose: () => dispatch(closeAddTrafficDialog())
});

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(AddTrafficItemDialog)
);
