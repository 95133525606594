import { Model, attr } from "redux-orm";
import { set } from "lodash";
import {
  TRAFFIC_TEMPLATE_MODEL_NAME,
  TRAFFIC_TEMPLATE_MODEL_ID
} from "./trafficTemplateConstants";
import {
  MANDATORY_RULE,
  MANDATORY_MESSAGE,
  NUMBER_OF_CHARACTERS_MESSAGE_100,
  NUMBER_OF_CHARACTERS_RULE,
  NUMBER_OF_CHARACTERS_MESSAGE_1000,
  NUMBER_OF_CHARACTERS_MESSAGE_50
} from "../../constants/validationConstants";
import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";
import {
  GET_TRAFFIC_AD_SERVER_LIST,
  GET_TRAFFIC_DIMENSIONS
} from "../../../configurations/apiUrls";

export const defaultAttributes = {};

class TrafficTemplate extends Model {
  static get fields() {
    return {
      templateId: attr(),
      templateName: attr(),
      description: attr(),
      dimensions: attr(),
      weight: attr(),
      creativeType: attr(),
      animationLooping: attr(),
      framerate: attr(),
      adServer: attr(),
      contact: attr(),
      remarks: attr()
    };
  }

  static parse(trafficTemplateData) {
    if (trafficTemplateData[TRAFFIC_TEMPLATE_MODEL_ID]) {
      set(
        trafficTemplateData,
        "id",
        trafficTemplateData[TRAFFIC_TEMPLATE_MODEL_ID]
      );
    }

    return this.upsert(trafficTemplateData);
  }

  static updateModel(trafficTemplateData) {
    return this.update(trafficTemplateData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherTrafficTemplates) {
    this.update(otherTrafficTemplates.ref);
  }

  static get validation() {
    return {
      templateName: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      description: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      weight: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      creativeType: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      animationLooping: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      framerate: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      adServer: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      contact: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      remarks: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
          options: {
            min: 0,
            max: 1000
          }
        }
      ]
    };
  }

  validate(excludedFields) {
    return validateModel(this.validation, this.toJSON(), excludedFields);
  }

  validateByProperty(name, isExcluded) {
    return validateProperty(
      TrafficTemplate.validation[name],
      this.ref[name],
      isExcluded
    );
  }

  hasErrors(excludedFields) {
    return doesModelHaveErrors(
      TrafficTemplate.validation,
      this.toJSON(),
      excludedFields
    );
  }

  static get apiConfiguration() {
    return {
      adServer: {
        url: GET_TRAFFIC_AD_SERVER_LIST
      },
      dimensions: {
        url: GET_TRAFFIC_DIMENSIONS
      }
    };
  }
}

TrafficTemplate.modelName = TRAFFIC_TEMPLATE_MODEL_NAME;

export default TrafficTemplate;
