import React, { memo } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "@mui/material";

import TrafficStatusSelect from "../../form/TrafficStatusSelect";

import {
  selectIsTrafficItemSelected,
  selectIsMultipleEditTrafficItemSelected,
  selectIsEditingTrafficItem,
  selectIsTrafficItemSelectable,
  selectIsTrafficItemSelectableForStatusChange
} from "../../../business/models/TrafficItem/trafficItemSelectors";
import { selectTrafficItemActionSwitch } from "../../../business/selectors/applicationSelectors";
import {
  doesTrafficPlanExist,
  isTrafficInEditMode
} from "../../../business/models/TrafficPlan/trafficPlanSelectors";

import {
  trafficItemStatusChangeToggleSelected,
  toggleMultipleEditTrafficItems
} from "../../../business/models/TrafficPlan/trafficPlanActions";
import { changeTrafficItemStatus } from "../../../business/models/TrafficItem/trafficItemActions";
import { classes, Grid } from "../styles/traficItemItem";

const TrafficItemItemSelection = ({
  id,
  isPreliminary,
  isDisabledByStatus,
  isDebtorActive
}) => {
  const dispatch = useDispatch();

  const isEditing = useSelector(selectIsEditingTrafficItem);
  const isTrafficPlanEdit = useSelector(isTrafficInEditMode);
  const isTrafficItemSelectable = useSelector(state =>
    selectIsTrafficItemSelectable(state, {
      trafficItemId: id
    })
  );
  const trafficPlanCreated = useSelector(doesTrafficPlanExist);
  const isStatusCheckboxSeletable = useSelector(state =>
    selectIsTrafficItemSelectableForStatusChange(state, {
      trafficItemId: id
    })
  );

  const disableStatusCheckbox =
    isTrafficPlanEdit ||
    isEditing ||
    isPreliminary ||
    !isStatusCheckboxSeletable ||
    !trafficPlanCreated;

  const disableStatus =
    isTrafficPlanEdit || isEditing || isPreliminary || !trafficPlanCreated;

  const disableMultipleEdit =
    isTrafficPlanEdit ||
    isEditing ||
    isDisabledByStatus ||
    !isTrafficItemSelectable;

  const isTrafficItemSelected = useSelector(state =>
    selectIsTrafficItemSelected(state, {
      trafficItemId: id
    })
  );
  const isTrafficItemMultipleEditSelected = useSelector(state =>
    selectIsMultipleEditTrafficItemSelected(state, { trafficItemId: id })
  );
  const switchChecked = useSelector(selectTrafficItemActionSwitch);

  const handleCheckBoxClick = e => {
    e.stopPropagation();
    dispatch(trafficItemStatusChangeToggleSelected([id], e.target.checked));
  };

  const handleSelectChange = statusId => {
    dispatch(changeTrafficItemStatus(id, statusId));
  };

  const handleSelectTrafficItemForMultipleEdit = e => {
    e.stopPropagation();
    dispatch(toggleMultipleEditTrafficItems([id], e.target.checked));
  };
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        {!switchChecked && isDebtorActive && (
          <Checkbox
            onClick={handleCheckBoxClick}
            checked={isTrafficItemSelected}
            color="primary"
            className={classes.checkbox}
            disabled={disableStatusCheckbox}
            id={`check_changeStatus_${id}`}
            name={`check_changeStatus_${id}`}
          />
        )}
        {switchChecked && isDebtorActive && (
          <Checkbox
            onClick={handleSelectTrafficItemForMultipleEdit}
            checked={isTrafficItemMultipleEditSelected}
            color="primary"
            className={classes.checkbox}
            disabled={disableMultipleEdit}
            id={`check_edit_multiple_${id}`}
            name={`check_edit_multiple_${id}`}
          />
        )}
      </Grid>
      <Grid item>
        <TrafficStatusSelect
          id={id}
          selectStatusDisabled={disableStatus}
          onChange={handleSelectChange}
          name="trafficItemStatusId"
          isDebtorActive={isDebtorActive}
        />
      </Grid>
    </Grid>
  );
};

TrafficItemItemSelection.propTypes = {
  isTrafficItemSelected: PropTypes.bool,
  disableMultipleEdit: PropTypes.bool,
  disableStatusCheckbox: PropTypes.bool,
  switchChecked: PropTypes.bool,
  disableStatus: PropTypes.bool,
  isTrafficItemMultipleEditSelected: PropTypes.bool,
  id: PropTypes.number,
  setSelectedForStatusChange: PropTypes.func,
  setSelectedForMultipleEdit: PropTypes.func,
  changeStatus: PropTypes.func
};

export default memo(TrafficItemItemSelection);
