import React, { useState, memo } from "react";
import { connect } from "react-redux";

import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  IconButton,
  Divider,
  Tooltip,
  Badge,
  Alert
} from "@mui/material";

import clsx from "clsx";
import ClipboardTooltip from "../shared/ClipboardTooltip";
import {
  EditIcon,
  RemarkIcon,
  PublishIcon,
  AgencyLogoIcon
} from "../../assets/styles/icons";
import { parseDate2 } from "../../business/models/common/utils/clientUtils";
import {
  startEditingTrafficPlan,
  stopEditingTrafficPlan,
  saveTrafficPlan,
  saveRemarks,
  startEditingTrafficPlanRemark,
  stopEditingTrafficPlanRemark,
  uploadTrafficPlanAttachment,
  trafficPlanAttachmentSizeValidationFailed,
  trafficPlanAttachmentTypeValidationFailed,
  loadTrafficPlanAttachments,
  clearTrafficPlanAttachments,
  clearTrafficPlanSelectedAttachments,
  deleteTrafficPlanAttachments
} from "../../business/models/TrafficPlan/trafficPlanActions";
import { openAdmanagementDialog } from "../../business/actions/trafficDashboardPageActions";
import { PLACEHOLDER } from "../../configurations/appConstants";
import TrafficEdit from "./extensions/TrafficPlanEdit";
import TrafficView from "./extensions/TrafficPlanView";
import {
  isCreativeAgencyFieldVisibleForMediatype,
  isContactFieldVisibleForMediatype,
  isEmailFieldVisibleForMediatype,
  isContactGroupMFieldVisibleForMediatype,
  isPhoneNumberGroupMFieldVisibleForMediatype,
  isTrafficInEditMode,
  isUploadButtonVisible,
  doesTrafficPlanExist,
  isCCFieldVisibleForMediatype,
  getSelectedTraffic,
  isSelectedTrafficDigital,
  getTrafficRequestText,
  getThirdPartyMeasurementText,
  selectSelectedAttachmentCount,
  selectAdmanagementIsSet,
  isUserAgent
} from "../../business/models/TrafficPlan/trafficPlanSelectors";
import RemarkDialog from "../pages/traffic-dashboard/dialog/RemarkDialog";
import UploadDialog from "../pages/traffic-dashboard/dialog/UploadDialog";
import ChangeTrafficStatusDialog from "../pages/traffic-dashboard/dialog/ChangeTrafficStatusDialog";
import { selectIsLoading } from "../../business/selectors/applicationSelectors";
import {
  canEditTrafficAgencyFields,
  canEditTrafficAgentFields
} from "../../business/selectors/userProfileSelectors";
import { isTrafficItemInEditMode } from "../../business/models/TrafficItem/trafficItemSelectors";
import { classes, Accordion } from "./styles/item";

const TrafficPlanPanel = ({
  cancelEditing,
  startEditing,
  editMode,
  startEditingRemark,
  cancelEditingRemark,
  save,
  isDigital,
  trafficRequestText,
  thirdPartyMeasurementText,
  uploadButtonVisible,
  areTrafficAgencyFieldsDisabled,
  areTrafficAgentFieldsDisabled,
  uploadTrafficPlanFile,
  fileSizeValidationFailed,
  fileTypeValidationFailed,
  saveRemark,
  selectedTraffic,
  isCreativeAgencyFieldVisible,
  isContactFieldVisible,
  isEmailFieldVisible,
  isCCFieldVisible,
  isContactGroupMFieldVisible,
  isPhoneNumberGroupMFieldVisible,
  loadAttachments,
  clearAttachments,
  clearSelectedAttachments,
  deleteAttachments,
  isDeleteAttachmentDisabled,
  isLoading,
  disabled,
  admanagementIsSet,
  openAdmanagmenetWarningDialog,
  isAgent,
  isTrafficItemInEdit
}) => {
  const [isExpanded, setExpended] = useState(true);
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const handleOnCopy = e => {
    e.stopPropagation();
  };

  const handleOnExpand = () => {
    if (!editMode) {
      setExpended(!isExpanded);
    }
  };

  const handleOnEdit = () => {
    if (!editMode) {
      startEditing();
    } else {
      cancelEditing();
    }
  };

  const handleOnSave = e => {
    e.stopPropagation();
    if (!admanagementIsSet && !isAgent && isDigital) {
      openAdmanagmenetWarningDialog();
      return;
    }

    save();
  };

  const handleOnCancel = () => {
    cancelEditing();
  };

  const handleSaveRemark = () => {
    saveRemark()
      .then(() => {
        setOpen(false);
      })
      .catch(() => {
        setOpen(true);
      });
  };

  const handleOpenRemark = () => {
    startEditingRemark();
    setOpen(true);
  };

  const handleOpenUploadDialog = e => {
    e.stopPropagation();
    loadAttachments();
    setOpenUpload(true);
  };

  const handleCloseUploadDialog = e => {
    e.stopPropagation();
    setOpenUpload(false);
    clearAttachments();
    clearSelectedAttachments();
  };

  const handleDeleteUploadDialog = e => {
    e.stopPropagation();
    deleteAttachments();
  };

  const handleUpload = event => {
    if (event.target.files.length === 0) return;

    const fileForUpload = event.target.files[0];
    const fileSizeMb = fileForUpload.size / 1024 / 1024;

    if (fileSizeMb > 10) {
      fileSizeValidationFailed();
      return;
    }

    if (!fileForUpload.type.startsWith("image/")) {
      fileTypeValidationFailed();
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(fileForUpload);
    reader.onload = () => {
      const fileInfo = {
        fileName: fileForUpload.name,
        fileType: fileForUpload.type,
        fileSize: fileSizeMb,
        fileBase64Content: reader.result.split(",")[1]
      };

      uploadTrafficPlanFile(fileInfo);
    };

    event.target.value = null;
  };

  const handleCloseRemark = () => {
    cancelEditingRemark();
    setOpen(false);
  };

  const {
    agency,
    campaignName,
    campaignDebtorInternalName: campaignDebtor,
    version,
    exportDate,
    creativeAgency,
    thirdPartyMeasurement,
    adServerSeat,
    adManagementOrderID,
    contact,
    email,
    planner,
    cc,
    contactGroupM,
    phoneNumberGroupM,
    remarks,
    id,
    isDebtorActive
  } = selectedTraffic || {};

  return (
    <>
      {isDebtorActive === false && (
        <Alert severity="warning">
          {`READ ONLY - Debtor "${campaignDebtor}" is inactive!`}
        </Alert>
      )}
      <Accordion
        square
        elevation={0}
        expanded={isExpanded}
        className={clsx(classes.expensionPanel, {
          [classes.selected]: true
        })}
        onChange={() => handleOnExpand()}
      >
        <AccordionSummary>
          <Grid container justifyContent="space-between">
            <Grid item xs={3} sm={3} md={3} lg={4} xl={5}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <AgencyLogoIcon agency={agency} />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography
                    className={classes.typographyHeaderCaption}
                    id="campaignDebtor"
                    name="campaignDebtor"
                  >
                    {campaignDebtor || PLACEHOLDER}
                  </Typography>
                  <Tooltip
                    title={
                      <ClipboardTooltip
                        onButtonClick={handleOnCopy}
                        text={campaignName || PLACEHOLDER}
                        propertyId="campaignName"
                        entityId={id}
                      />
                    }
                    placement="bottom-start"
                  >
                    <Typography
                      className={classes.typographyHeaderValue}
                      id="campaignName"
                      name="campaignName"
                      noWrap
                    >
                      {campaignName || PLACEHOLDER}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={3} justifyContent="flex-end">
                <Grid item className={classes.dateWidth}>
                  <Typography
                    align="right"
                    className={classes.typographyValue}
                    id="export_version_text"
                    name="export_version_text"
                  >
                    {version || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="right"
                    className={classes.typographyCaption}
                  >
                    Version
                  </Typography>
                </Grid>
                <Grid item className={classes.dateWidth}>
                  <Typography
                    align="right"
                    className={classes.typographyValue}
                    id="export_date"
                    name="export_date"
                  >
                    {parseDate2(exportDate)}
                  </Typography>
                  <Typography
                    align="right"
                    className={classes.typographyCaption}
                  >
                    Date
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <Grid container justifyContent="space-between">
            <Grid item xs={1}>
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="baseline"
                className={classes.actionContainer}
              >
                {isDebtorActive && (
                  <Grid item>
                    <IconButton
                      onClick={handleOnEdit}
                      className={classes.iconButton}
                      id="tp_edit"
                      name="tp_edit"
                      disabled={isTrafficItemInEdit || editMode}
                    >
                      <EditIcon disabled={isTrafficItemInEdit || editMode} />
                    </IconButton>
                  </Grid>
                )}
                <Grid item>
                  <IconButton
                    className={classes.iconButton}
                    onClick={handleOpenRemark}
                    id="tp_open_remark"
                    name="tp_open_remark"
                    disabled={disabled || editMode || isTrafficItemInEdit}
                  >
                    <Badge
                      id="tp_indicator_remark"
                      name="tp_indicator_remark"
                      variant="dot"
                      invisible={!remarks}
                      color="secondary"
                    >
                      <RemarkIcon
                        disabled={disabled || editMode || isTrafficItemInEdit}
                      />
                    </Badge>
                  </IconButton>
                </Grid>
                {uploadButtonVisible && isDebtorActive && (
                  <Grid item>
                    <IconButton
                      className={classes.iconButton}
                      disabled={disabled || editMode || isTrafficItemInEdit}
                      id="tp_upload"
                      name="tp_upload"
                      onClick={handleOpenUploadDialog}
                    >
                      <PublishIcon
                        disabled={disabled || editMode || isTrafficItemInEdit}
                      />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={11}>
              {editMode && isDebtorActive ? (
                <TrafficEdit
                  isDigital={isDigital}
                  handleOnSave={handleOnSave}
                  handleOnCancel={handleOnCancel}
                  isCreativeAgencyVisible={isCreativeAgencyFieldVisible}
                  isContactVisible={isContactFieldVisible}
                  isEmailVisible={isEmailFieldVisible}
                  isCCVisible={isCCFieldVisible}
                  isContactGroupMVisible={isContactGroupMFieldVisible}
                  isPhoneNumberGroupMVisible={isPhoneNumberGroupMFieldVisible}
                  isLoading={isLoading}
                  areTrafficAgencyFieldsDisabled={
                    areTrafficAgencyFieldsDisabled
                  }
                  areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
                />
              ) : (
                <TrafficView
                  creativeAgency={creativeAgency}
                  contact={contact}
                  email={email}
                  planner={planner}
                  cc={cc}
                  isDigital={isDigital}
                  trafficRequestText={trafficRequestText}
                  thirdPartyMeasurementText={thirdPartyMeasurementText}
                  adManagementOrderID={adManagementOrderID}
                  adServerSeat={adServerSeat}
                  thirdPartyMeasurement={thirdPartyMeasurement}
                  contactGroupM={contactGroupM}
                  phoneNumberGroupM={phoneNumberGroupM}
                  isCreativeAgencyVisible={isCreativeAgencyFieldVisible}
                  isContactVisible={isContactFieldVisible}
                  isEmailVisible={isEmailFieldVisible}
                  isCCVisible={isCCFieldVisible}
                  isContactGroupMVisible={isContactGroupMFieldVisible}
                  isPhoneNumberGroupMVisible={isPhoneNumberGroupMFieldVisible}
                />
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <RemarkDialog
        open={open}
        handleClose={handleCloseRemark}
        handleSave={handleSaveRemark}
        disableActions={!isDebtorActive}
      />
      <UploadDialog
        open={openUpload}
        handleClose={handleCloseUploadDialog}
        handleUpload={handleUpload}
        handleDelete={handleDeleteUploadDialog}
        isDeleteDisabled={isDeleteAttachmentDisabled}
        isLoading={isLoading}
        disableActions={!isDebtorActive}
      />
      <ChangeTrafficStatusDialog />
    </>
  );
};

const mapStateToProps = state => {
  return {
    selectedTraffic: getSelectedTraffic(state),
    isCreativeAgencyFieldVisible: isCreativeAgencyFieldVisibleForMediatype(
      state
    ),
    isContactFieldVisible: isContactFieldVisibleForMediatype(state),
    isEmailFieldVisible: isEmailFieldVisibleForMediatype(state),
    isCCFieldVisible: isCCFieldVisibleForMediatype(state),
    isContactGroupMFieldVisible: isContactGroupMFieldVisibleForMediatype(state),
    isPhoneNumberGroupMFieldVisible: isPhoneNumberGroupMFieldVisibleForMediatype(
      state
    ),
    uploadButtonVisible: isUploadButtonVisible(state),
    areTrafficAgencyFieldsDisabled: !canEditTrafficAgencyFields(state),
    areTrafficAgentFieldsDisabled: !canEditTrafficAgentFields(state),
    editMode: isTrafficInEditMode(state),
    trafficRequestText: getTrafficRequestText(state),
    thirdPartyMeasurementText: getThirdPartyMeasurementText(state),
    isLoading: selectIsLoading(state),
    disabled: !doesTrafficPlanExist(state),
    isDigital: isSelectedTrafficDigital(state),
    isDeleteAttachmentDisabled: selectSelectedAttachmentCount(state) === 0,
    admanagementIsSet: selectAdmanagementIsSet(state),
    isAgent: isUserAgent(state),
    isTrafficItemInEdit: isTrafficItemInEditMode(state)
  };
};

const mapDispatchToProps = dispatch => ({
  startEditing: () => dispatch(startEditingTrafficPlan()),
  fileSizeValidationFailed: () =>
    dispatch(trafficPlanAttachmentSizeValidationFailed()),
  fileTypeValidationFailed: () =>
    dispatch(trafficPlanAttachmentTypeValidationFailed()),
  cancelEditing: () => dispatch(stopEditingTrafficPlan()),
  startEditingRemark: () => dispatch(startEditingTrafficPlanRemark()),
  cancelEditingRemark: () => dispatch(stopEditingTrafficPlanRemark()),
  save: () => dispatch(saveTrafficPlan()),
  saveRemark: () => dispatch(saveRemarks()),
  uploadTrafficPlanFile: attachment =>
    dispatch(uploadTrafficPlanAttachment(attachment)),
  loadAttachments: () => dispatch(loadTrafficPlanAttachments()),
  clearAttachments: () => dispatch(clearTrafficPlanAttachments()),
  clearSelectedAttachments: () =>
    dispatch(clearTrafficPlanSelectedAttachments()),
  deleteAttachments: () => dispatch(deleteTrafficPlanAttachments()),
  openAdmanagmenetWarningDialog: () => dispatch(openAdmanagementDialog())
});

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(TrafficPlanPanel)
);
