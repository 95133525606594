import { createSelector } from "reselect";

const selectApiData = state => state.apiData;
const selectPendingData = state => state.pendingData;

export const getApiData = createSelector(selectApiData, apiData => apiData);

export const getPendingData = createSelector(
  selectPendingData,
  pendingData => pendingData
);
