import React, { useContext, useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { normalize } from "normalizr";
import { Formik, Form } from "formik";
import { Alert } from "@mui/material";

import { useParams } from "react-router-dom";

import { ConfigOptionsContext } from "../configurationContext";
import entitiesSchema from "../../../../business/schemas/configurationPage/entitiesSchema";
import validationSchema from "../../../../business/schemas/configurationPage/validationSchema";
import {
  loadAditionalApprovalSettings,
  saveAditionalApprovalSettings
} from "../../../../business/actions/configurationPageActions";
import {
  formatData,
  mapValues
} from "../../../../business/mappings/configurationMappings";
import { PENDING_RULE_MESSAGE } from "../../../../business/constants/configurationPageConstants";
import NavigationPrompt from "./NavigationPrompt";
import RuleList from "./RuleList";
import { setEditAgencyRule } from "../../../../business/actions/applicationActions";
import { classes, Snackbar, Grid } from "./styles/aaConfigForm";

const AAConfigForm = () => {
  const dispatch = useDispatch();
  const contextValue = useContext(ConfigOptionsContext);
  const { agency } = useParams();
  const [entities, setEntities] = useState(null);
  const canFetchEntities = !isEmpty(contextValue);
  const { canEditAgencyRules } = entities
    ? Object.values(entities.agencies)[0]
    : false;

  useEffect(() => {
    if (canFetchEntities) {
      dispatch(loadAditionalApprovalSettings(agency)).then(response => {
        setEntities(normalize(response, entitiesSchema).entities);
      });
    }
    return () => {
      setEntities(null);
    };
  }, [agency, canFetchEntities, dispatch]);

  useEffect(() => {
    dispatch(setEditAgencyRule(canEditAgencyRules));
  }, [dispatch, canEditAgencyRules]);

  return (
    <>
      {!isEmpty(entities?.rules) && (
        <>
          <Formik
            initialValues={mapValues(entities, contextValue)}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              const result = formatData(values, entities, contextValue);
              dispatch(saveAditionalApprovalSettings(agency, result)).then(
                isSuccessful => {
                  if (isSuccessful) {
                    resetForm({ values });
                  }
                }
              );
            }}
          >
            {() => (
              <Form id="AAConfigForm">
                {/* <NavigationPrompt /> */}
                <Grid
                  container
                  spacing={4}
                  direction="column"
                  className={classes.grid}
                >
                  <RuleList />
                </Grid>
              </Form>
            )}
          </Formik>
          <Snackbar
            id="notistack-snackbar"
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={!canEditAgencyRules}
            key="top-center"
            className={classes.alert}
          >
            <Alert elevation={6} variant="filled" severity="warning">
              {PENDING_RULE_MESSAGE}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
};

export default AAConfigForm;
