import { Box } from "@mui/material";
import UserField from "../../../form/UserField";

const PREFIX = "UserManagementDialogStyles";

// eslint-disable-next-line import/prefer-default-export
export const UserFieldCheckbox = props => {
  const { id, name, label } = props;

  return (
    <Box component="grid" sx={{ pb: 2, pl: 1, pt: 3 }}>
      <UserField component="checkbox" id={id} name={name} label={label}>
        Save
      </UserField>
    </Box>
  );
};
