import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import { TableHead, TableRow, TableCell, TableBody, Grid } from "@mui/material";

import { EMPTY_TABLE } from "../../../../configurations/appConstants";

import Row from "./byMediaType/extensions/PlanChangeOverviewRow";

import { classes, Table, Button } from "../styles/targetMetricsKpiTable";

const PlanChangeOverviewTable = ({ data, onAdd, onEdit, onDelete }) => {
  const isDisabled = data.length === 5;
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} align="left">
              Version
            </TableCell>
            <TableCell className={classes.header} align="left">
              Change
            </TableCell>
            <TableCell className={classes.header} align="left">
              Consequence
            </TableCell>
            <TableCell className={classes.header} align="left">
              Changed by
            </TableCell>
            <TableCell className={classes.header} align="left">
              Date
            </TableCell>
            <TableCell className={classes.header} align="center" />
            <TableCell className={classes.header} align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(data) ? (
            data.map(row => (
              <Row
                key={row.id}
                data={row}
                onHandleEdit={onEdit}
                onHandleDelete={onDelete}
              />
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={12}>
                {EMPTY_TABLE}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            name="t_update"
            id="t_update"
            variant="contained"
            className={classes.saveButton}
            onClick={onAdd}
            disabled={isDisabled}
          >
            ADD
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

PlanChangeOverviewTable.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default PlanChangeOverviewTable;
