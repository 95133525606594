import React, { Component } from "react";
import { connect } from "react-redux";
import ConfirmSaveDialog from "../../../shared/ConfirmSaveDialog";

import { toggleCampaignConfirmPopup } from "../../../../business/actions/applicationActions";
import { selectOpenCampaignConfirmPopup } from "../../../../business/selectors/applicationSelectors";

class ConfirmCampaignSaveDialog extends Component {
  handleOk = () => {
    if (typeof this.props.onOk === "function") {
      this.props.onOk();
    }
    this.props.toggle();
  };

  handleCancel = () => {
    this.props.toggle();
  };

  render() {
    const { open } = this.props;
    return (
      <ConfirmSaveDialog
        open={open}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      />
    );
  }
}

const mapStateToProps = state => ({
  open: selectOpenCampaignConfirmPopup(state)
});

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(toggleCampaignConfirmPopup())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmCampaignSaveDialog);
