import { styled, Typography as MuiTypography } from "@mui/material";

const PREFIX = "PlanExportDialogStyles";
export const classes = {
  typographyCaptionBold: `${PREFIX}-typographyCaptionBold`
};

export const Typography = styled(MuiTypography)(() => ({
  [`&.${classes.typographyCaptionBold}`]: {
    fontWeight: "bold",
    alignSelf: "center"
  }
}));
