import React from "react";

import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Typography, Tooltip } from "@mui/material";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import { EMPTY_STRING } from "../../../../configurations/appConstants";
import UploadButton from "../../../form/controls/UploadButton";
import { classes, Grid } from "../styles/trafficPlanUpload";

const mimeType = [
  "application/pdf",
  "application/excel",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/zip",
  "application/x-7z-compressed",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/ico",
  "image/wbmp"
];
Object.freeze(mimeType);
const mimes = mimeType.toString();

const TrafficPlanUpload = ({ onChange, fileName }) => {
  const isLoading = useSelector(selectIsLoading);

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={0}
      alignItems="center"
    >
      <Grid item>
        <Tooltip title={fileName}>
          <Typography className={classes.typographyFileName}>
            Attachment: {fileName}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item>
        <UploadButton
          component="uploadButton"
          name="file"
          id="file"
          label="upload"
          isLoading={isLoading}
          mime={mimes}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

TrafficPlanUpload.propTypes = {
  isLoading: PropTypes.bool,
  fileName: PropTypes.string
};

TrafficPlanUpload.defaultProps = {
  fileName: EMPTY_STRING,
  isLoading: false
};

export default TrafficPlanUpload;
