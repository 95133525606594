/* eslint-disable no-lonely-if */
import forEach from "lodash/forEach";
import set from "lodash/set";
import get from "lodash/get";
import lowerCase from "lodash/lowerCase";
import upperFirst from "lodash/upperFirst";

import {
  GET_TRAFFIC_DASHBOARD,
  CREATE_TRAFFIC_PLAN,
  EDIT_TRAFFIC_PLAN,
  EDIT_TRAFFIC_PLAN_REMARKS,
  EDIT_TRAFFIC_ITEM_STATUS,
  PLAN_ORDERS,
  ASSIGN_TRAFFIC_PLAN_TO_ME,
  UNASSIGN_TRAFFIC_PLAN_TO_ME,
  UPLOAD_TRAFFIC_PLAN_ATTACHMENT,
  GET_TRAFFIC_PLAN_EXPORT_PREVIEW,
  EDIT_TRAFFICKER,
  GET_TRAFFIC_EXPORT_VERSIONS,
  DOWNLOAD_TRAFFIC_EXCEL,
  GET_TRAFFIC_PLAN_ATTACHMENTS,
  DELETE_TRAFFIC_PLAN_ATTACHMENT,
  EDIT_TRAFFIC_ITEM_MULTIPLE,
  EMAIL_TRAFFIC_PLAN
} from "../../../configurations/apiUrls";
import TrafficPlanModel from "./TrafficPlan";
import { TRAFFIC_PAGE } from "../../../configurations/appConstants";
import { LOAD_TRAFFIC_PLAN_DATA_SUCCESS } from "../common/data/dataConstants";
import { PLAN_MODEL_ID } from "../Plan/planConstants";
import {
  SET_CURRENT_TRAFFIC_PLAN_ID,
  START_EDITING_TRAFFIC_PLAN,
  STOP_EDITING_TRAFFIC_PLAN,
  TRAFFIC_PLAN_MODEL_NAME,
  DISPLAY_TRAFFIC_PLAN_ERRORS,
  HIDE_TRAFFIC_PLAN_ERRORS,
  TRAFFIC_PLAN_MODEL_ID,
  TRAFFIC_PLAN_MODEL_CONTACT_GROUPM,
  TRAFFIC_PLAN_SUCCESS_MESSAGE,
  START_EDITING_TRAFFIC_PLAN_REMARK,
  STOP_EDITING_TRAFFIC_PLAN_REMARK,
  CLEAR_CURRENT_TRAFFIC_PLAN,
  TRAFFIC_PLAN_REMARKS_SUCCESS_MESSAGE,
  TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_INSERT_SELECTED,
  TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_REMOVE_SELECTED,
  TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_CLEAR_SELECTED,
  TRAFFIC_PLAN_DOWNLOAD_ERROR_MESSAGE,
  TRAFFIC_PLAN_DOWNLOAD_SUCCESS_MESSAGE,
  TRAFFIC_PLAN_EMAIL_ERROR_MESSAGE,
  TRAFFIC_PLAN_EMAIL_SUCCESS_MESSAGE,
  SET_ORDER_LIST,
  RESET_ORDER_LIST,
  SET_SELECTED_ORDER_TRAFFIC,
  RESET_SELECTED_ORDER_TRAFFIC,
  TRAFFIC_PLAN_ASSIGN_SUCCESS_MESSAGE,
  TRAFFIC_PLAN_UNASSIGN_SUCCESS_MESSAGE,
  TRAFFIC_PLAN_ASSIGN_ERROR_MESSAGE,
  TRAFFIC_PLAN_INSERT_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT,
  TRAFFIC_PLAN_REMOVE_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT,
  TRAFFIC_PLAN_CLEAR_TRAFFIC_ITEMS_FOR_MULTIPLE_EDIT,
  APPROVED_ORDERS_LIST_EMPTY_ERROR,
  TRAFFIC_PLAN_ATTACHMENT_UPLOAD_SUCCESS_MESSAGE,
  TRAFFIC_PLAN_ATTACHMENT_UPLOAD_ERROR_MESSAGE,
  TRAFFIC_PLAN_ATTACHMENT_FILE_SIZE_VALIDATION_ERROR_MESSAGE,
  TRAFFIC_PLAN_ATTACHMENT_FILE_TYPE_VALIDATION_ERROR_MESSAGE,
  TRAFFIC_PLAN_REMARK_DIALOG_OPEN,
  TRAFFIC_PLAN_REMARK_DIALOG_CLOSE,
  TRAFFIC_PLAN_PREVIEW_OPEN,
  TRAFFIC_PLAN_PREVIEW_CLOSE,
  TRAFFIC_PLAN_EMAIL_OPEN,
  TRAFFIC_PLAN_EMAIL_CLOSE,
  TRAFFIC_PLAN_SELECTED_USERS_SET,
  TRAFFIC_PLAN_SELECTED_USERS_CLEAR,
  TRAFFIC_PLAN_MEDIA_TYPE_ID_SET,
  TRAFFIC_PLAN_MEDIA_TYPE_ID_CLEAR,
  TRAFFIC_PLAN_PREVIEW_DATA_SET,
  TRAFFIC_PLAN_PREVIEW_DATA_CLEAR,
  TRAFFIC_PLAN_TRAFFICKER_SUCCESS_MESSAGE,
  TRAFFIC_PLAN_ADD_ATTACHMENT_SUCCESS,
  TRAFFIC_PLAN_ADD_SELECTED_ATTACHMENT,
  TRAFFIC_PLAN_REMOVE_SELECTED_ATTACHMENT,
  SET_TRAFFIC_EXPORT_VERSIONS,
  RESET_TRAFFIC_EXPORT_VERSIONS,
  SET_TRAFFIC_EXPORT_SELECTED_VERSION,
  TRAFFIC_PLAN_MEDIA_TYPE_ID,
  RESET_TRAFFIC_EXPORT_SELECTED_VERSION,
  SET_TRAFFIC_PLAN_ATTACHMENTS,
  RESET_TRAFFIC_PLAN_ATTACHMENTS,
  TRAFFIC_PLAN_CLEAR_SELECTED_ATTACHMENTS,
  TRAFFIC_PLAN_DELETE_ATTACHMENT_SUCCESS,
  TRAFFIC_PLAN_ATTACHMENT_DELETE_SUCCESS_MESSAGE,
  TRAFFIC_PLAN_ATTACHMENT_DELETE_ERROR_MESSAGE,
  TRAFFIC_PLAN_ATTACHMENT_INVALID_DELETE_ERROR_MESSAGE
} from "./trafficPlanConstants";
import {
  TRAFFIC_ITEMS_PROPERTY,
  TRAFFIC_ITEM_MODEL_NAME,
  TRAFFIC_ITEMS_INVALID_TRANSITIONS,
  TRAFFIC_ITEM_MODEL_ID,
  TRAFFIC_ITEM_DISPLAY_ERRORS,
  TRAFFIC_ITEMS_SUCCESS_MESSAGE,
  TRAFFIC_ITEMS_FAIL_MESSAGE
} from "../TrafficItem/trafficItemConstants";

import {
  enqueueApiErrorMessage,
  enqueueNotificationMessage,
  enqueueApplicationErrorMessage,
  openTrafficItemStatusChangeDialog,
  closeTrafficItemStatusChangeDialog,
  apiCall,
  startLoading,
  stopLoading
} from "../../actions/applicationActions";
import {
  editExistingItem,
  stopEditingItem,
  commitData,
  editItemAttributes
} from "../common/editing/editingActions";
import { deleteEntity } from "../common/entities/entityActions";
import { clearEntityData } from "../common/data/dataActions";
import {
  loadTrafficItemMediaData,
  stopEditingTrafficItem,
  clearCurrentTrafficItem
} from "../TrafficItem/trafficItemActions";

import { selectAgency } from "../../selectors/applicationSelectors";
import {
  selectCurrentTrafficPlanId,
  selectIsEditingPlan,
  doesModelHaveErrors,
  getEditingTrafficPlan,
  isTrafficPlanVirtual,
  selectSelectedTrafficItems,
  getTrafficItemIdsByCheckedTraficPlanForMultipleEdit,
  getNonePreliminaryTrafficItemIdsByCheckedTraficPlan,
  selectTrafficExportSelectedVersion,
  selectSelectedTrafficPlanAttachments,
  selectCurrentTrafficPlan
} from "./trafficPlanSelectors";

import generateUrl from "../common/utils/urlUtils";
import api from "../../../functions/api";
import download from "../../../functions/download";
import {
  PUT,
  GET,
  POST,
  DELETE,
  API_CALL_TRAFFIC_DASHBOARD_START,
  API_CALL_TRAFFIC_DASHBOARD_SUCCESS,
  API_CALL_TRAFFIC_DASHBOARD_ERROR
} from "../../constants/applicationConstants";
import { selectCurrentPlan } from "../Plan/planSelectors";
import { selectCampaign } from "../Campaign/campaignActions";
import {
  updateTrafficker,
  removeItem,
  setTabCounters,
  loadItems,
  loadTabcounters
} from "../../actions/trafficPageActions";
import { selectTabCounters } from "../../selectors/trafficPageSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";
import {
  getPathValue,
  getQueryObj,
  generateParamObj
} from "../common/utils/modelUtils";
import fetchApiDataIfNeeded from "../../actions/apiDataActions";
import { selectAgencyName } from "../common/utils/appUtils";
import {
  selectUserIsAdmin,
  canEditTrafficAgentFields
} from "../../selectors/userProfileSelectors";
import {
  doesModelForAgentMultipleEditHaveErrors,
  getMultipleEditData
} from "../TrafficItem/trafficItemSelectors";
import { isNullOrUndefined } from "../../../functions/util";
import { selectPlan } from "../Plan/planActions";

function setTrafficPlanMediaData() {
  return (dispatch, getState) => {
    const state = getState();
    const configuration = TrafficPlanModel.apiConfiguration;
    const apiData = getApiData(state);
    const editingData = getEditingTrafficPlan(state);
    const currentTrafficPlan = selectCurrentTrafficPlan(state);
    const agencyID = selectAgency(state);
    forEach(configuration, (prop, key) => {
      if (prop.path) {
        const prevValue = get(editingData, key);
        const value = getPathValue(prop, editingData, apiData, agencyID);
        if (!isNullOrUndefined(value) && value !== "" && prevValue !== value) {
          dispatch(
            editItemAttributes(TRAFFIC_PLAN_MODEL_NAME, currentTrafficPlan, {
              [key]: value
            })
          );
        }
      }
    });
  };
}

export function loadTrafficPlanMediaData(data) {
  return (dispatch, getState) => {
    const configuration = TrafficPlanModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url));
      }
    });
  };
}

export function loadEditingTrafficPlanMediaData(data) {
  return (dispatch, getState) => {
    const configuration = TrafficPlanModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url, setTrafficPlanMediaData));
      }
    });
  };
}

// #region Helpers
const changeUrl = (navigate, trafficPlanId, planId) => (_, getState) => {
  const state = getState();
  const agencyId = selectAgency(state);
  const agency = selectAgencyName(agencyId);
  const currentPlan = selectCurrentPlan(state);
  let currentPlanId = currentPlan;
  if (!currentPlanId) {
    currentPlanId = planId;
  }

  navigate(`/${agency}/${TRAFFIC_PAGE}/${currentPlanId}/${trafficPlanId}`);
};
// #endregion Helpers

export function startEditingTrafficPlan() {
  return (dispatch, getState) => {
    const editingItem = selectCurrentTrafficPlanId(getState());

    dispatch(editExistingItem(TRAFFIC_PLAN_MODEL_NAME, editingItem));
    dispatch({
      type: START_EDITING_TRAFFIC_PLAN
    });
    dispatch(loadEditingTrafficPlanMediaData(editingItem));
  };
}

export function stopEditingTrafficPlan() {
  return (dispatch, getState) => {
    const editingItem = selectCurrentTrafficPlanId(getState());

    dispatch(stopEditingItem(TRAFFIC_PLAN_MODEL_NAME, editingItem));
    dispatch({ type: STOP_EDITING_TRAFFIC_PLAN });
    dispatch({ type: HIDE_TRAFFIC_PLAN_ERRORS });
  };
}

export const clearCurrentTrafficPlan = () => dispatch =>
  dispatch({ type: CLEAR_CURRENT_TRAFFIC_PLAN });

export function selectTrafficPlan(trafficPlanId) {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectIsEditingPlan(state);
    if (isEditing) {
      dispatch(stopEditingTrafficPlan());
    }

    // dispatch({
    //   type: CLEAR_CURRENT_TRAFFIC_PLAN
    // });
    dispatch({
      type: SET_CURRENT_TRAFFIC_PLAN_ID,
      payload: trafficPlanId
    });
  };
}

const loadMediaData = data => dispatch => {
  dispatch(loadTrafficPlanMediaData(data));
  forEach(data, trafficPlanData => {
    forEach(trafficPlanData[TRAFFIC_ITEMS_PROPERTY], trafficItemData => {
      dispatch(
        loadTrafficItemMediaData({ ...trafficPlanData, ...trafficItemData })
      );
    });
  });
};

export const loadTrafficData = (navigate, params) => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const planId = selectCurrentPlan(state);
  const url = generateUrl(GET_TRAFFIC_DASHBOARD, {
    agency: agencyID,
    [PLAN_MODEL_ID]: planId
  });

  // TODO: this is workaround need refactoring to separate api call loaders
  dispatch({ type: API_CALL_TRAFFIC_DASHBOARD_START });
  return dispatch(apiCall(GET, url)).then(
    response => {
      if (!isNullOrUndefined(response) && !isNullOrUndefined(response.items)) {
        dispatch(clearEntityData());
        dispatch(selectCampaign(response.campaignId)); // used for navigation
        dispatch({
          type: LOAD_TRAFFIC_PLAN_DATA_SUCCESS,
          payload: {
            items: response.items,
            isDebtorActive: response.isDebtorActive
          }
        });

        if (response.items.length > 0) {
          if (!params.trafficPlanId) {
            const firstPlan = response.items[0];
            const id =
              firstPlan[TRAFFIC_PLAN_MODEL_ID] ||
              `${firstPlan[PLAN_MODEL_ID]}_${firstPlan[TRAFFIC_PLAN_MEDIA_TYPE_ID]}`;
            dispatch(changeUrl(navigate, id, firstPlan[PLAN_MODEL_ID]));
            dispatch(selectPlan(firstPlan[PLAN_MODEL_ID]));
          }
          dispatch(loadMediaData(response.items));
        }
      }
      dispatch({ type: API_CALL_TRAFFIC_DASHBOARD_SUCCESS });
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
      dispatch({ type: API_CALL_TRAFFIC_DASHBOARD_ERROR });
    }
  );
};

export function setPropertyValue(data) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedTraffic = selectCurrentTrafficPlanId(state);

    dispatch(
      editItemAttributes(TRAFFIC_PLAN_MODEL_NAME, selectedTraffic, data)
    );
  };
}

export function createTrafficPlan() {
  return (dispatch, getState) => {
    const state = getState();

    const hasErrors = doesModelHaveErrors(state);
    if (hasErrors) {
      dispatch({ type: DISPLAY_TRAFFIC_PLAN_ERRORS });
      return Promise.reject();
    }

    const agencyID = selectAgency(state);
    const data = getEditingTrafficPlan(state);

    const url = generateUrl(CREATE_TRAFFIC_PLAN, {
      agency: agencyID,
      [PLAN_MODEL_ID]: data.planId
    });

    return dispatch(apiCall(POST, url, data)).then(
      response => {
        const responseData = set(
          response,
          "id",
          response[TRAFFIC_PLAN_MODEL_ID]
        );

        // TODO: UPDATE TRAFFIC ITEMS WITH NEW ID
        dispatch(deleteEntity(TRAFFIC_PLAN_MODEL_NAME, data.id));
        dispatch(
          commitData(TRAFFIC_PLAN_MODEL_NAME, data[TRAFFIC_PLAN_MODEL_ID], {
            ...data,
            ...responseData
          })
        );
        dispatch(stopEditingTrafficPlan());
        dispatch(selectTrafficPlan(responseData[TRAFFIC_PLAN_MODEL_ID]));
        dispatch(enqueueNotificationMessage(TRAFFIC_PLAN_SUCCESS_MESSAGE));

        return Promise.resolve();
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function updateTrafficPlan() {
  return (dispatch, getState) => {
    const state = getState();

    const hasErrors = doesModelHaveErrors(state);
    if (hasErrors) {
      dispatch({ type: DISPLAY_TRAFFIC_PLAN_ERRORS });
      return Promise.reject();
    }

    const agencyID = selectAgency(state);
    const data = getEditingTrafficPlan(state);

    const url = generateUrl(EDIT_TRAFFIC_PLAN, {
      agency: agencyID,
      [PLAN_MODEL_ID]: data.planId,
      [TRAFFIC_PLAN_MODEL_ID]: data[TRAFFIC_PLAN_MODEL_ID]
    });

    return dispatch(apiCall(PUT, url, data)).then(
      response => {
        const responseData = set(
          response,
          "id",
          response[TRAFFIC_PLAN_MODEL_ID]
        );

        dispatch(
          commitData(
            TRAFFIC_PLAN_MODEL_NAME,
            responseData[TRAFFIC_PLAN_MODEL_ID],
            responseData
          )
        );
        dispatch(stopEditingTrafficPlan());
        dispatch(selectTrafficPlan(responseData[TRAFFIC_PLAN_MODEL_ID]));
        dispatch(enqueueNotificationMessage(TRAFFIC_PLAN_SUCCESS_MESSAGE));

        return Promise.resolve();
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function saveTrafficPlan() {
  return (dispatch, getState) => {
    const state = getState();
    const data = getEditingTrafficPlan(state);
    if (!data) {
      return Promise.reject();
    }
    if (data[TRAFFIC_PLAN_MODEL_ID]) {
      return dispatch(updateTrafficPlan());
    }
    return dispatch(createTrafficPlan());
  };
}

export const saveRemarks = () => (dispatch, getState) => {
  const state = getState();

  const hasErrors = doesModelHaveErrors(state);
  if (hasErrors) {
    dispatch({ type: DISPLAY_TRAFFIC_PLAN_ERRORS });
    return Promise.reject();
  }

  const agencyID = selectAgency(state);
  const trafficPlanId = selectCurrentTrafficPlanId(state);
  const data = getEditingTrafficPlan(state);

  const url = generateUrl(EDIT_TRAFFIC_PLAN_REMARKS, {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: trafficPlanId
  });

  const body = { remarks: data.remarks };
  return dispatch(apiCall(PUT, url, body)).then(
    response => {
      if (response.isSuccessful) {
        data.isDebtorActive = response.isDebtorActive;
        dispatch(
          commitData(TRAFFIC_PLAN_MODEL_NAME, trafficPlanId, { ...data })
        );
        dispatch(stopEditingTrafficPlan());
        dispatch(
          enqueueNotificationMessage(TRAFFIC_PLAN_REMARKS_SUCCESS_MESSAGE)
        );

        return Promise.resolve();
      }

      return Promise.reject();
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const editTrafficker = (trafficker, query) => (dispatch, getState) => {
  const state = getState();

  const agencyID = selectAgency(state);
  const trafficPlanId = selectCurrentTrafficPlanId(state);

  const url = generateUrl(EDIT_TRAFFICKER, {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: trafficPlanId
  });

  const body = { trafficker };
  return dispatch(apiCall(PUT, url, body)).then(
    response => {
      if (response.isSuccessful) {
        dispatch(
          enqueueNotificationMessage(TRAFFIC_PLAN_TRAFFICKER_SUCCESS_MESSAGE)
        );
        dispatch(updateTrafficker(trafficPlanId, response.trafficker));
        dispatch(loadTabcounters());
        dispatch(loadItems(query));
        return Promise.resolve();
      }

      return Promise.reject();
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const uploadTrafficPlanAttachment = attachment => (
  dispatch,
  getState
) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const trafficPlanId = selectCurrentTrafficPlanId(state);

  const url = generateUrl(UPLOAD_TRAFFIC_PLAN_ATTACHMENT, {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: trafficPlanId
  });

  return dispatch(apiCall(POST, url, attachment))
    .then(response => {
      if (response.isSuccessful) {
        const { fileInfoId, fileName } = response;

        dispatch({
          type: TRAFFIC_PLAN_ADD_ATTACHMENT_SUCCESS,
          payload: { fileInfoId, fileName }
        });

        dispatch(
          enqueueNotificationMessage(
            TRAFFIC_PLAN_ATTACHMENT_UPLOAD_SUCCESS_MESSAGE
          )
        );
      }
    })
    .catch(() => {
      dispatch(
        enqueueApiErrorMessage(TRAFFIC_PLAN_ATTACHMENT_UPLOAD_ERROR_MESSAGE)
      );
    });
};

export const trafficPlanAttachmentSizeValidationFailed = () => dispatch => {
  dispatch(
    enqueueApiErrorMessage(
      TRAFFIC_PLAN_ATTACHMENT_FILE_SIZE_VALIDATION_ERROR_MESSAGE
    )
  );
};

export const trafficPlanAttachmentTypeValidationFailed = () => dispatch => {
  dispatch(
    enqueueApiErrorMessage(
      TRAFFIC_PLAN_ATTACHMENT_FILE_TYPE_VALIDATION_ERROR_MESSAGE
    )
  );
};

export function startEditingTrafficPlanRemark() {
  return (dispatch, getState) => {
    const editingItem = selectCurrentTrafficPlanId(getState());

    dispatch(editExistingItem(TRAFFIC_PLAN_MODEL_NAME, editingItem));
    dispatch({
      type: START_EDITING_TRAFFIC_PLAN_REMARK
    });
  };
}

export function stopEditingTrafficPlanRemark() {
  return (dispatch, getState) => {
    const editingItem = selectCurrentTrafficPlanId(getState());

    dispatch(stopEditingItem(TRAFFIC_PLAN_MODEL_NAME, editingItem));
    dispatch({ type: HIDE_TRAFFIC_PLAN_ERRORS });
    dispatch({ type: STOP_EDITING_TRAFFIC_PLAN_REMARK });
  };
}

export function openDialog() {
  return dispatch => {
    dispatch(openTrafficItemStatusChangeDialog());
  };
}

export function closeDialog() {
  return dispatch => {
    dispatch(closeTrafficItemStatusChangeDialog());
  };
}

export const approvedOrdersListEmptyError = () => dispatch => {
  dispatch(enqueueApiErrorMessage(APPROVED_ORDERS_LIST_EMPTY_ERROR));
};

export const downloadTraffic = (id, trafficDigitalType) => (
  dispatch,
  getState
) => {
  const state = getState();

  const agencyID = selectAgency(state);
  const paramObj = {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: id
  };
  const url = generateUrl(DOWNLOAD_TRAFFIC_EXCEL, paramObj);
  const body = { trafficDigitalType };
  return dispatch(apiCall(PUT, url, body))
    .then(response => {
      const byteCharacters = atob(response.file);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const responseData = set(
          response,
          "id",
          response[TRAFFIC_PLAN_MODEL_ID]
        );
        dispatch(
          commitData(
            TRAFFIC_PLAN_MODEL_NAME,
            responseData[TRAFFIC_PLAN_MODEL_ID],
            responseData
          )
        );

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays);
      return {
        blob,
        fileName: response.fileName
      };
    })
    .then(obj => {
      download(obj.blob, obj.fileName);
    })
    .then(() => {
      dispatch(
        enqueueNotificationMessage(TRAFFIC_PLAN_DOWNLOAD_SUCCESS_MESSAGE)
      );
    })
    .catch(() => {
      dispatch(enqueueApiErrorMessage(TRAFFIC_PLAN_DOWNLOAD_ERROR_MESSAGE));
    });
};

export const emailTraffic = (
  id,
  toAddresses,
  trafficDigitalType,
  attachment
) => (dispatch, getState) => {
  const state = getState();
  const body = { trafficDigitalType, toAddresses, attachment };
  const agencyID = selectAgency(state);
  const paramObj = {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: id
  };

  const url = generateUrl(EMAIL_TRAFFIC_PLAN, paramObj);
  return api
    .post(url, body)
    .then(response => {
      const responseData = set(response, "id", response[TRAFFIC_PLAN_MODEL_ID]);
      dispatch(
        commitData(
          TRAFFIC_PLAN_MODEL_NAME,
          responseData[TRAFFIC_PLAN_MODEL_ID],
          responseData
        )
      );

      dispatch(enqueueNotificationMessage(TRAFFIC_PLAN_EMAIL_SUCCESS_MESSAGE));
    })
    .catch(() => {
      dispatch(enqueueApiErrorMessage(TRAFFIC_PLAN_EMAIL_ERROR_MESSAGE));
    });
};

export const loadOrderList = () => (dispatch, getState) => {
  const state = getState();
  const planId = selectCurrentPlan(state);
  dispatch({
    type: RESET_ORDER_LIST
  });

  const agencyID = selectAgency(state);
  const paramObj = {
    agency: agencyID,
    [PLAN_MODEL_ID]: planId
  };
  const queryObj = {
    forTraffic: true
  };
  const url = generateUrl(PLAN_ORDERS, paramObj, queryObj);

  return dispatch(apiCall(GET, url))
    .then(response => {
      if (response.items.length > 0) {
        const dropdownItems = response.items.map(item => {
          return {
            label: `${item.marathonOrderId} ${item.saleshouseId}`,
            value: item.orderId
          };
        });

        dispatch({
          type: SET_ORDER_LIST,
          payload: {
            loadedOrders: dropdownItems
          }
        });
      }
    })
    .catch(() => {
      dispatch(enqueueApiErrorMessage("Could not get Order list."));
    });
};

export const clearOrderList = () => dispatch => {
  dispatch({
    type: RESET_ORDER_LIST
  });
};

export const setSelectedOrderForTraffic = orderId => dispatch => {
  dispatch({
    type: SET_SELECTED_ORDER_TRAFFIC,
    payload: orderId
  });
};

export const clearSelectedOrderForTraffic = () => dispatch => {
  dispatch({
    type: RESET_SELECTED_ORDER_TRAFFIC
  });
};

export const assignTrafficPlan = (id, query) => (dispatch, getState) => {
  const state = getState();
  const isVirtual = isTrafficPlanVirtual(state, id);

  if (isVirtual) {
    dispatch(enqueueApiErrorMessage(TRAFFIC_PLAN_ASSIGN_ERROR_MESSAGE));
    return Promise.reject();
  }

  const agencyID = selectAgency(state);

  const paramObj = {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: id
  };
  const url = generateUrl(ASSIGN_TRAFFIC_PLAN_TO_ME, paramObj);
  dispatch(startLoading());
  return api.put(url).then(
    response => {
      if (response && response.isSuccessful) {
        const trafficPlanIdResult = get(response, TRAFFIC_PLAN_MODEL_ID);
        const contactGroupMResult = get(
          response,
          TRAFFIC_PLAN_MODEL_CONTACT_GROUPM
        );

        dispatch(
          commitData(TRAFFIC_PLAN_MODEL_NAME, trafficPlanIdResult, {
            trafficPlanId: trafficPlanIdResult,
            contactGroupM: contactGroupMResult
          })
        );
        dispatch(
          enqueueNotificationMessage(TRAFFIC_PLAN_ASSIGN_SUCCESS_MESSAGE)
        );
        dispatch(loadTabcounters());
        dispatch(loadItems(query));
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
      dispatch(stopLoading());
    }
  );
};

export const unassignTrafficPlan = (id, query) => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);

  const paramObj = {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: id
  };

  const queryObj = {
    unassign: true
  };

  const url = generateUrl(UNASSIGN_TRAFFIC_PLAN_TO_ME, paramObj, queryObj);
  dispatch(startLoading());
  return api.put(url).then(
    response => {
      if (response && response.isSuccessful) {
        const trafficPlanIdResult = get(response, TRAFFIC_PLAN_MODEL_ID);
        const contactGroupMResult = get(
          response,
          TRAFFIC_PLAN_MODEL_CONTACT_GROUPM
        );

        dispatch(
          commitData(TRAFFIC_PLAN_MODEL_NAME, trafficPlanIdResult, {
            trafficPlanId: trafficPlanIdResult,
            contactGroupM: contactGroupMResult
          })
        );
        dispatch(loadItems(query));
        dispatch(loadTabcounters());
        dispatch(
          enqueueNotificationMessage(TRAFFIC_PLAN_UNASSIGN_SUCCESS_MESSAGE)
        );
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
      dispatch(stopLoading());
    }
  );
};

export const assignTrafficPlanDashboard = id => (dispatch, getState) => {
  const state = getState();
  const counters = selectTabCounters(state);
  const isVirtual = isTrafficPlanVirtual(state, id);
  if (isVirtual) {
    dispatch(enqueueApiErrorMessage(TRAFFIC_PLAN_ASSIGN_ERROR_MESSAGE));
    return Promise.reject();
  }

  const agencyID = selectAgency(state);

  const paramObj = {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: id
  };
  const url = generateUrl(ASSIGN_TRAFFIC_PLAN_TO_ME, paramObj);
  dispatch(startLoading());
  return api.put(url).then(
    response => {
      if (response && response.isSuccessful) {
        const trafficPlanIdResult = get(response, TRAFFIC_PLAN_MODEL_ID);
        const contactGroupMResult = get(
          response,
          TRAFFIC_PLAN_MODEL_CONTACT_GROUPM
        );

        dispatch(
          commitData(TRAFFIC_PLAN_MODEL_NAME, trafficPlanIdResult, {
            trafficPlanId: trafficPlanIdResult,
            contactGroupM: contactGroupMResult
          })
        );

        dispatch(
          enqueueNotificationMessage(TRAFFIC_PLAN_ASSIGN_SUCCESS_MESSAGE)
        );
        dispatch(removeItem(trafficPlanIdResult));
        dispatch(
          setTabCounters({
            ...counters,
            assignedCount: counters.assignedCount + 1,
            newCount: counters.newCount - 1
          })
        );
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
      dispatch(stopLoading());
    }
  );
};

export const unassignTrafficPlanDashboard = id => (dispatch, getState) => {
  const state = getState();
  const counters = selectTabCounters(state);
  const agencyID = selectAgency(state);

  const paramObj = {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: id
  };

  const queryObj = {
    unassign: true
  };

  const url = generateUrl(UNASSIGN_TRAFFIC_PLAN_TO_ME, paramObj, queryObj);
  dispatch(startLoading());
  return api.put(url).then(
    response => {
      if (response && response.isSuccessful) {
        const trafficPlanIdResult = get(response, TRAFFIC_PLAN_MODEL_ID);
        const contactGroupMResult = get(
          response,
          TRAFFIC_PLAN_MODEL_CONTACT_GROUPM
        );

        dispatch(
          commitData(TRAFFIC_PLAN_MODEL_NAME, trafficPlanIdResult, {
            trafficPlanId: trafficPlanIdResult,
            contactGroupM: contactGroupMResult
          })
        );

        dispatch(
          enqueueNotificationMessage(TRAFFIC_PLAN_UNASSIGN_SUCCESS_MESSAGE)
        );
        dispatch(removeItem(trafficPlanIdResult));
        dispatch(
          setTabCounters({
            ...counters,
            assignedCount: counters.assignedCount - 1,
            newCount: counters.newCount + 1
          })
        );
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
      dispatch(startLoading());
    }
  );
};
export const setMultipleEditTrafficItems = trafficItemIds => ({
  type: TRAFFIC_PLAN_INSERT_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT,
  payload: {
    trafficItemIds
  }
});

export const removeMultipleEditTrafficItems = trafficItemIds => ({
  type: TRAFFIC_PLAN_REMOVE_TRAFFIC_ITEM_FOR_MULTIPLE_EDIT,
  payload: {
    trafficItemIds
  }
});

export function clearMultipleEditTrafficItems() {
  return dispatch => {
    dispatch({
      type: TRAFFIC_PLAN_CLEAR_TRAFFIC_ITEMS_FOR_MULTIPLE_EDIT
    });
  };
}

export const toggleMultipleEditTrafficItems = (
  trafficItemIds,
  checked
) => dispatch => {
  if (checked) {
    dispatch(setMultipleEditTrafficItems(trafficItemIds));
  } else {
    dispatch(removeMultipleEditTrafficItems(trafficItemIds));
  }
};

export const toggleMultipleEditTrafficPlanTrafficItems = (
  trafficPlanId,
  checked
) => (dispatch, getState) => {
  const state = getState();
  const trafficItemIds = getTrafficItemIdsByCheckedTraficPlanForMultipleEdit(
    state,
    {
      trafficPlanId
    }
  );

  if (checked) {
    dispatch(setMultipleEditTrafficItems(trafficItemIds));
  } else {
    dispatch(removeMultipleEditTrafficItems(trafficItemIds));
  }
};

export const trafficItemStatusChangeSetSelected = trafficItemIds => ({
  type: TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_INSERT_SELECTED,
  payload: {
    trafficItemIds
  }
});

export const trafficItemStatusChangeRemoveSelected = trafficItemIds => ({
  type: TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_REMOVE_SELECTED,
  payload: {
    trafficItemIds
  }
});

export function trafficItemStatusChangeClearSelected() {
  return dispatch => {
    dispatch({
      type: TRAFFIC_PLAN_TRAFFIC_ITEM_STATUS_CHANGE_CLEAR_SELECTED
    });
  };
}

export const trafficItemStatusChangeToggleSelected = (
  trafficItemIds,
  checked
) => dispatch => {
  if (checked) {
    dispatch(trafficItemStatusChangeSetSelected(trafficItemIds));
  } else {
    dispatch(trafficItemStatusChangeRemoveSelected(trafficItemIds));
  }
};

export const trafficItemStatusChangeToggleSelectedMultiple = (
  trafficPlanId,
  checked
) => (dispatch, getState) => {
  const state = getState();

  const trafficItemIds = getNonePreliminaryTrafficItemIdsByCheckedTraficPlan(
    state,
    { trafficPlanId }
  );

  if (checked) {
    dispatch(trafficItemStatusChangeSetSelected(trafficItemIds));
  } else {
    dispatch(trafficItemStatusChangeRemoveSelected(trafficItemIds));
  }
};

export function changeStatusForTrafficItems(statusId) {
  return (dispatch, getState) => {
    const state = getState();
    const agencyID = selectAgency(state);
    const selectedTrafficItemIds = selectSelectedTrafficItems(state);

    const url = generateUrl(EDIT_TRAFFIC_ITEM_STATUS, {
      agency: agencyID
    });

    const trafficItemsList = [];
    selectedTrafficItemIds.forEach(id =>
      trafficItemsList.push({
        trafficItemId: id,
        trafficItemStatusId: statusId
      })
    );

    const body = {
      trafficItemsStatus: trafficItemsList
    };
    dispatch(apiCall(PUT, url, body)).then(
      response => {
        const { sentTrafficItemStatuses, invalidStatusTransitions } = response;
        if (response && !isNullOrUndefined(sentTrafficItemStatuses)) {
          sentTrafficItemStatuses.forEach(trafficItem => {
            const responseData = set(
              {
                trafficItemStatusId: trafficItem.trafficItemStatusId,
                trafficItemStatusText: trafficItem.trafficItemStatusText
              },
              "id",
              trafficItem.trafficItemId
            );
            dispatch(
              commitData(
                TRAFFIC_ITEM_MODEL_NAME,
                trafficItem.trafficItemId,
                responseData
              )
            );
          });
        }
        dispatch(closeTrafficItemStatusChangeDialog());

        if (!isNullOrUndefined(invalidStatusTransitions)) {
          dispatch(
            enqueueApplicationErrorMessage(
              `${TRAFFIC_ITEMS_INVALID_TRANSITIONS}:${invalidStatusTransitions.join(
                ", "
              )}`
            )
          );
        }

        dispatch(trafficItemStatusChangeClearSelected());
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export const openTrafficPlanRemarkDialog = () => ({
  type: TRAFFIC_PLAN_REMARK_DIALOG_OPEN
});
export const closeTrafficPlanRemarkDialog = () => ({
  type: TRAFFIC_PLAN_REMARK_DIALOG_CLOSE
});
export const openPreviewDialog = () => ({ type: TRAFFIC_PLAN_PREVIEW_OPEN });
export const closePreviewDialog = () => ({ type: TRAFFIC_PLAN_PREVIEW_CLOSE });
export const openTrafficPlanEmailDialog = () => ({
  type: TRAFFIC_PLAN_EMAIL_OPEN
});
export const closeTrafficPlanEmailDialog = () => ({
  type: TRAFFIC_PLAN_EMAIL_CLOSE
});
export const setSelectedUsers = payload => ({
  type: TRAFFIC_PLAN_SELECTED_USERS_SET,
  payload
});
export const clearSelectedUsers = () => ({
  type: TRAFFIC_PLAN_SELECTED_USERS_CLEAR
});
export const setMediaTypeId = payload => ({
  type: TRAFFIC_PLAN_MEDIA_TYPE_ID_SET,
  payload
});
export const clearMediaTypeId = () => ({
  type: TRAFFIC_PLAN_MEDIA_TYPE_ID_CLEAR
});
export const setPreviewData = payload => ({
  type: TRAFFIC_PLAN_PREVIEW_DATA_SET,
  payload
});
export const clearPreviewData = () => ({
  type: TRAFFIC_PLAN_PREVIEW_DATA_CLEAR
});

export const getPreviewData = (id, mediaTypeName) => (dispatch, getState) => {
  const state = getState();
  const agencyId = selectAgency(state);

  const trafficName =
    id && mediaTypeName
      ? `Traffic${upperFirst(lowerCase(mediaTypeName))}`
      : "NoTraffic";

  const paramObj = {
    agency: agencyId,
    [TRAFFIC_PLAN_MODEL_ID]: id
  };
  const queryObj = {
    trafficName
  };
  const url = generateUrl(GET_TRAFFIC_PLAN_EXPORT_PREVIEW, paramObj, queryObj);
  return dispatch(apiCall(GET, url)).then(
    response => {
      const responseData = response.trafficPreview.material_Specs[0];
      dispatch(setPreviewData(responseData));
      // dispatch(enqueueNotificationMessage(TRAFFIC_PLAN_SUCCESS_MESSAGE)); // TODO: DO WE NEED MSG?
      return Promise.resolve();
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const loadExportVersions = id => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: RESET_TRAFFIC_EXPORT_VERSIONS
  });

  const agencyID = selectAgency(state);
  const paramObj = {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: id
  };

  const url = generateUrl(GET_TRAFFIC_EXPORT_VERSIONS, paramObj);

  return dispatch(apiCall(GET, url))
    .then(response => {
      if (response.items.length > 0) {
        const dropdownItems = response.items.map(item => {
          return {
            label: item.text,
            value: item.key
          };
        });

        dispatch({
          type: SET_TRAFFIC_EXPORT_VERSIONS,
          payload: {
            loadedVersions: dropdownItems
          }
        });
      }
    })
    .catch(() => {
      dispatch(enqueueApiErrorMessage("Could not get export versions list."));
    });
};

export const resetExportVersions = () => dispatch => {
  dispatch({
    type: RESET_TRAFFIC_EXPORT_VERSIONS
  });
};

export const downloadExportVersion = () => (dispatch, getState) => {
  const state = getState();
  const trafficPlanId = selectCurrentTrafficPlanId(state);
  const version = selectTrafficExportSelectedVersion(state);
  const agencyID = selectAgency(state);
  const paramObj = {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: trafficPlanId
  };

  const body = { version: version.value };
  const url = generateUrl(DOWNLOAD_TRAFFIC_EXCEL, paramObj);

  return dispatch(apiCall(PUT, url, body))
    .then(response => {
      const byteCharacters = atob(response.file);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays);
      return {
        blob,
        fileName: response.fileName
      };
    })
    .then(obj => {
      download(obj.blob, obj.fileName);
    })
    .then(() => {
      dispatch(
        enqueueNotificationMessage(TRAFFIC_PLAN_DOWNLOAD_SUCCESS_MESSAGE)
      );
    })
    .catch(() => {
      dispatch(enqueueApiErrorMessage(TRAFFIC_PLAN_DOWNLOAD_ERROR_MESSAGE));
    });
};

export const setTrafficExportVersion = version => ({
  type: SET_TRAFFIC_EXPORT_SELECTED_VERSION,
  payload: version
});

export const clearTrafficExportVersion = () => ({
  type: RESET_TRAFFIC_EXPORT_SELECTED_VERSION
});

export const clearTrafficPlanAttachments = () => ({
  type: RESET_TRAFFIC_PLAN_ATTACHMENTS
});

export const clearTrafficPlanSelectedAttachments = () => ({
  type: TRAFFIC_PLAN_CLEAR_SELECTED_ATTACHMENTS
});

export const loadTrafficPlanAttachments = () => (dispatch, getState) => {
  const state = getState();

  dispatch(clearTrafficPlanAttachments());
  dispatch(clearTrafficPlanSelectedAttachments());

  const trafficPlanId = selectCurrentTrafficPlanId(state);
  const agencyID = selectAgency(state);

  const paramObj = {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: trafficPlanId
  };

  const url = generateUrl(GET_TRAFFIC_PLAN_ATTACHMENTS, paramObj);

  return dispatch(apiCall(GET, url))
    .then(response => {
      if (response.items.length > 0) {
        dispatch({
          type: SET_TRAFFIC_PLAN_ATTACHMENTS,
          payload: {
            loadAttachments: response.items
          }
        });
      }
    })
    .catch(() => {
      dispatch(enqueueApiErrorMessage("Could not get export versions list."));
    });
};

export const deleteTrafficPlanAttachments = () => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const trafficPlanId = selectCurrentTrafficPlanId(state);
  const selectedAttachments = selectSelectedTrafficPlanAttachments(state);
  const selectedAttachmentsConcat = selectedAttachments.join(",");

  const queryObj = {
    attachmentIds: selectedAttachmentsConcat
  };

  const paramObj = {
    agency: agencyID,
    [TRAFFIC_PLAN_MODEL_ID]: trafficPlanId
  };

  const url = generateUrl(DELETE_TRAFFIC_PLAN_ATTACHMENT, paramObj, queryObj);

  return dispatch(apiCall(DELETE, url))
    .then(response => {
      if (response) {
        const { attachmentIds, failAttachmentIds } = response;

        dispatch({
          type: TRAFFIC_PLAN_DELETE_ATTACHMENT_SUCCESS,
          payload: attachmentIds
        });

        dispatch(clearTrafficPlanSelectedAttachments());

        dispatch(
          enqueueNotificationMessage(
            TRAFFIC_PLAN_ATTACHMENT_DELETE_SUCCESS_MESSAGE
          )
        );

        if (!response.isSuccessful) {
          const ids = failAttachmentIds.join(",");
          const message = `${TRAFFIC_PLAN_ATTACHMENT_INVALID_DELETE_ERROR_MESSAGE}${ids}`;
          dispatch(enqueueApiErrorMessage(message));
        }
      }
    })
    .catch(() => {
      dispatch(clearTrafficPlanSelectedAttachments());
      dispatch(
        enqueueApiErrorMessage(TRAFFIC_PLAN_ATTACHMENT_DELETE_ERROR_MESSAGE)
      );
    });
};

export function setSelectedAttachment(id, selected) {
  return dispatch => {
    if (selected) {
      dispatch({
        type: TRAFFIC_PLAN_ADD_SELECTED_ATTACHMENT,
        payload: id
      });
    } else {
      dispatch({
        type: TRAFFIC_PLAN_REMOVE_SELECTED_ATTACHMENT,
        payload: id
      });
    }
  };
}

export function updateTrafficItemMultiple() {
  return (dispatch, getState) => {
    const state = getState();
    const isUserAdmin = selectUserIsAdmin(state);
    const isUserAgent = canEditTrafficAgentFields(state);

    const hasErrors =
      isUserAgent && !isUserAdmin
        ? doesModelForAgentMultipleEditHaveErrors(state)
        : doesModelHaveErrors(state);

    if (hasErrors) {
      dispatch({ type: TRAFFIC_ITEM_DISPLAY_ERRORS });
      return Promise.reject();
    }

    const agencyID = selectAgency(state);

    const data = getMultipleEditData(state);

    const url = generateUrl(EDIT_TRAFFIC_ITEM_MULTIPLE, {
      agency: agencyID
    });

    return dispatch(apiCall(PUT, url, data)).then(
      response => {
        const { isSuccessful, items } = response;

        if (isSuccessful) {
          // TO DO: improvement for handling error message when one of the items fail to update
          items.forEach(item => {
            const itemData = set(item, "id", item[TRAFFIC_ITEM_MODEL_ID]);

            dispatch(
              commitData(
                TRAFFIC_ITEM_MODEL_NAME,
                itemData[TRAFFIC_ITEM_MODEL_ID],
                itemData
              )
            );
          });
          dispatch(stopEditingTrafficItem());
          dispatch(clearCurrentTrafficItem());
          dispatch(clearMultipleEditTrafficItems());
          dispatch(enqueueNotificationMessage(TRAFFIC_ITEMS_SUCCESS_MESSAGE));
          return Promise.resolve(isSuccessful);
        }
        dispatch(enqueueNotificationMessage(TRAFFIC_ITEMS_FAIL_MESSAGE));
        return Promise.reject();
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export const clearMultipleEditSelections = () => dispatch => {
  dispatch(trafficItemStatusChangeClearSelected());
  dispatch(clearMultipleEditTrafficItems());
};
