import React from "react";
import { Route, Routes } from "react-router-dom";
import AppBarWrapper from "./extensions/AppBarWrapper";

import { footerRoutes } from "../../../configurations/routes";

const FooterBar = () => {
  return (
    <AppBarWrapper bottom>
      <Routes>
        {footerRoutes.map((prop, key) => (
          <Route key={key} path={prop.path} element={prop.element} />
        ))}
      </Routes>
    </AppBarWrapper>
  );
};

export default FooterBar;
