import React from "react";
import { useSelector } from "react-redux";
import { Typography, Step, StepLabel } from "@mui/material";

import {
  selectSteps,
  selectActiveStep,
  selectOrderSteps,
  selectCurrentOrderStep
} from "../../../../business/selectors/copyPlanSelectors";
import { isStepOptional } from "../../../../business/helpers/copyPlanHelpers";
import { stepsEnum } from "../../../../business/constants/copyPlanConstants";
import { classes, Stepper } from "../styles/copyPlanStepper";

const CopyPlanStepper = () => {
  const steps = useSelector(selectSteps);
  const activeStep = useSelector(selectActiveStep);
  const orderSteps = useSelector(selectOrderSteps);
  const currentOrderStep = useSelector(selectCurrentOrderStep);

  return (
    <Stepper className={classes.stepper} activeStep={activeStep - 1}>
      {steps.map((label, step) => {
        const stepProps = {};
        const labelProps = {};
        if (isStepOptional(step)) {
          labelProps.optional = (
            <Typography variant="caption">Optional</Typography>
          );
        }
        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>
              {label}
              {activeStep === stepsEnum.ORDER &&
                step + 1 === stepsEnum.ORDER &&
                `(${currentOrderStep}/${orderSteps})`}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

CopyPlanStepper.propTypes = {};

export default CopyPlanStepper;
