import React from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";

import InsertionPopup from "../popup/InsertionPopup";
import OrderField from "../../../form/OrderField";

import OrderLeftSidePanel from "./extensions/OrderLeftSidePanel";
import OrderRightSidePanel from "./extensions/OrderRightSidePanel";

import { classes, Div, OrderFieldDate } from "../styles/orderForm";

const OrderForm = () => {
  return (
    <Div className={classes.root}>
      <Grid container>
        <Grid
          item
          xs={3}
          className={clsx(classes.detailsLayout, classes.deatilsLeft)}
        >
          <OrderLeftSidePanel />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <OrderField
                component="autocomplete"
                id="channelId"
                name="channelId"
                label="Channel *"
              />
            </Grid>
            <Grid item xs={6}>
              <OrderField
                component="autocomplete"
                id="titleId"
                name="titleId"
                label="Title - Saleshouse *"
              />
            </Grid>
            <Grid item xs={6}>
              <OrderField
                component="autocomplete"
                id="orderAgreementId"
                name="orderAgreementId"
                label="Agreement *"
              />
            </Grid>
            <Grid item xs={6}>
              <OrderField
                component="text"
                id="description"
                name="description"
                label="Description *"
                maxLength={550}
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="text"
                id="targetAudience"
                name="targetAudience"
                label="Target Audience"
                maxLength={200}
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="text"
                id="orderPoNumber"
                name="orderPoNumber"
                label="PO number"
                maxLength={50}
              />
            </Grid>
            <Grid item xs={6}>
              <OrderField
                component="autocomplete"
                id="digitalChannelId"
                name="digitalChannelId"
                label="Digital Channel"
              />
            </Grid>
            <Grid item xs={3}>
              <OrderFieldDate
                id="orderStartDate"
                name="orderStartDate"
                label="Start Date *"
              />
            </Grid>
            <Grid item xs={3}>
              <OrderFieldDate
                id="orderEndDate"
                name="orderEndDate"
                label="End date *"
              />
            </Grid>
            <Grid item xs={3} className={classes.surchargeType}>
              <OrderField
                component="autocomplete"
                id="formatId"
                name="formatId"
                label="Format"
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="text"
                id="position"
                name="position"
                label="Position"
                maxLength={50}
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="text"
                id="frequencyCap"
                name="frequencyCap"
                label="Freq. cap"
                maxLength={50}
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="text"
                id="accountId"
                name="accountId"
                label="Account ID"
                maxLength={50}
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="autocomplete"
                id="costTypeId"
                name="costTypeId"
                label="Cost type *"
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="decimal"
                id="numOfUnits"
                name="numOfUnits"
                label="# of Units *"
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="text"
                id="unit"
                name="unit"
                label="Unit"
                maxLength={50}
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="autocomplete"
                id="discountCodeId"
                name="discountCodeId"
                label="Disc. type *"
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="decimal"
                id="discountAmount"
                name="discountAmount"
                label="Disc. amount *"
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="decimal"
                id="netTotal"
                name="netTotal"
                label="Net total *"
              />
            </Grid>
            <Grid item xs={3} className={classes.surchargeType}>
              <OrderField
                component="autocomplete"
                id="surcharge1TypeId"
                name="surcharge1TypeId"
                label="Sur. type 1"
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="decimal"
                id="surcharge1Amount"
                name="surcharge1Amount"
                label="Sur. amount 1"
              />
            </Grid>
            <Grid item xs={3} className={classes.surchargeType}>
              <OrderField
                component="autocomplete"
                id="surcharge2TypeId"
                name="surcharge2TypeId"
                label="Sur. type 2"
              />
            </Grid>
            <Grid item xs={3}>
              <OrderField
                component="decimal"
                id="surcharge2Amount"
                name="surcharge2Amount"
                label="Sur. amount 2"
              />
            </Grid>
            <Grid item xs={6}>
              <OrderField
                component="text"
                id="remarks"
                name="remarks"
                label="Order remarks"
                maxLength={365}
              />
            </Grid>
            <Grid item xs={6}>
              <OrderField
                component="autocomplete"
                id="statusId"
                name="statusId"
                label="Client status *"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          className={clsx(classes.detailsLayout, classes.detailsRight)}
        >
          <OrderRightSidePanel />
        </Grid>
      </Grid>
      <InsertionPopup />
    </Div>
  );
};

export default OrderForm;
