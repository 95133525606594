import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";
import Controls from "./controls/index";
import { setPropertyValue } from "../../business/models/TrafficPlan/trafficPlanActions";
import {
  getPropertyValue,
  getPropertyPreviousValue,
  getPropertyErrorsByProperty,
  selectDisplayErrors,
  getTrafficPlanPropertyOptions
} from "../../business/models/TrafficPlan/trafficPlanSelectors";
import { formatValue } from "../../functions/util";
import { classes, Box } from "./styles/TrafficItemField";

class TrafficPlanField extends Component {
  handleChange = value => {
    const { name, onChange } = this.props;
    onChange({ [name]: formatValue(value) });
  };

  render() {
    const {
      component,
      isLabelHighlighted,
      disabled,
      ...componentProps
    } = this.props;
    const Control = Controls[component];

    const InputProps = {
      className: clsx({
        [classes.typographyDetailsValue]: false
      }),
      classes: { disabled: classes.disabled }
      // disableUnderline: false
    };

    const inputLabelProps = {
      className: clsx({
        [classes.typographyCaptionBold]: isLabelHighlighted
      })
    };

    return (
      <Box>
        <Control
          component={component}
          {...componentProps}
          InputProps={InputProps}
          inputLabelProps={inputLabelProps}
          onChange={this.handleChange}
          disabled={disabled}
        />
      </Box>
    );
  }
}

TrafficPlanField.propTypes = {
  component: PropTypes.oneOf(["text", "autocomplete"]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isLabelHighlighted: PropTypes.bool
};

TrafficPlanField.defaultProps = {
  isLabelHighlighted: false,
  errors: []
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getPropertyValue(state, ownProps),
    prevValue: getPropertyPreviousValue(state, ownProps),
    errors: getPropertyErrorsByProperty(state, ownProps),
    displayErrors: selectDisplayErrors(state),
    options: getTrafficPlanPropertyOptions(state, ownProps),
    disabled: ownProps.disabled
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: data => dispatch(setPropertyValue(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrafficPlanField);
