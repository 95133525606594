import { styled } from "@mui/material";
import {
  SIDE_BAR_CLOSE_WIDTH,
  SIDE_BAR_OPEN_WIDTH
} from "../../../../configurations/appConstants";

const PREFIX = "LayoutStyles";
export const classes = {
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  offset: `${PREFIX}-offset`
};

export const MuiMain = styled("main")(({ theme }) => ({
  [`&.${classes.content}`]: {
    marginLeft: theme.typography.pxToRem(SIDE_BAR_CLOSE_WIDTH),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  [`&.${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: theme.typography.pxToRem(SIDE_BAR_OPEN_WIDTH)
  },
  [`& .${classes.offset}`]: {
    offset: theme.mixins.toolbar
  }
}));
