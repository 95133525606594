import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@mui/material";

class ConfirmSaveDialog extends Component {
  handleOk = () => {
    if (typeof this.props.onOk === "function") {
      this.props.onOk();
    }
  };

  handleCancel = () => {
    if (typeof this.props.onCancel === "function") {
      this.props.onCancel();
    }
  };

  render() {
    const { open } = this.props;
    const paperProps = { square: true };
    return (
      <Dialog open={open} PaperProps={paperProps} maxWidth="md">
        <DialogContent>
          <DialogContentText noWrap={false}>
            The change(s) that you have made will affect multiple levels
            (campaign/plans/orders). Depending on the size of this campaign the
            time needed to save all items can be substantial. Do you want to
            proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleOk} color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmSaveDialog;
