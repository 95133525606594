import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { Grid, Slide, Typography } from "@mui/material";

import PlanForm from "../forms/PlanForm";
import OrderForm from "../forms/OrderForm";
import PlanExtensionForm from "../forms/PlanExtensionForm";
import SelectCampaign from "./SelectCampaign";

import {
  selectActiveStep,
  selectCurrentOrderStep
} from "../../../../business/selectors/copyPlanSelectors";

import {
  copyPlan,
  clearCurrentPlan
} from "../../../../business/models/Plan/planActions";

import {
  stepsEnum,
  WIZARD_COMPLETED_MSG
} from "../../../../business/constants/copyPlanConstants";

import {
  CREATE_PAGE,
  CAMPAIGN,
  PLAN
} from "../../../../configurations/appConstants";

import {
  copyOrderPlanCopy,
  clearCurrentOrder
} from "../../../../business/models/Order/orderActions";

import {
  clearEntityData,
  clearEditingEntityData
} from "../../../../business/models/common/data/dataActions";

import { clearCurrentCampaign } from "../../../../business/models/Campaign/campaignActions";

import {
  setProcess,
  resumeCopyPlan,
  clearStepper
} from "../../../../business/actions/copyPlanActions";

import { copyPlanExtension } from "../../../../business/models/PlanExtension/planExtensionActions";
import { selectCurrentPlan } from "../../../../business/models/Plan/planSelectors";
import {
  selectCurrentCampaign,
  getCampaignData
} from "../../../../business/models/Campaign/campaignSelectors";

// #region Slider options
const initStepTimeout = { appear: 0, enter: 300, exit: 0 };
const nextStepTimeout = { appear: 1000, enter: 300, exit: 0 };
// #endregion Slider options

const CopyPlanWizardPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { agency, processId } = useParams();

  const activeStep = useSelector(selectActiveStep);
  const currentOrderStep = useSelector(selectCurrentOrderStep);

  const currentCampaign = useSelector(selectCurrentCampaign);
  const currentPlan = useSelector(selectCurrentPlan);

  const { isDebtorActive } = useSelector(getCampaignData) || false;

  // TODO: Move this outside of CopyPlanWizardPage for reusability
  const isCampaignStep = activeStep === stepsEnum.CAMPAIGN;
  const isPlanStep = activeStep === stepsEnum.PLAN;
  const isPlanExtensionStep = activeStep === stepsEnum.PLAN_EXTENSION;
  const isOrderStep = activeStep === stepsEnum.ORDER;
  const isFinalStep = activeStep === stepsEnum.FINISH;

  useEffect(() => {
    dispatch(setProcess(processId));
    if (!processId || !isDebtorActive) {
      dispatch(clearCurrentCampaign());
      dispatch(clearCurrentPlan());
      dispatch(clearCurrentOrder());
      dispatch(clearEntityData());
      dispatch(clearEditingEntityData());
      dispatch(clearStepper());

      if (Number.isInteger(currentCampaign) && Number.isInteger(currentPlan)) {
        navigate(
          `/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${currentCampaign}/${PLAN}/${currentPlan}`
        );
        return;
      }

      if (Number.isInteger(currentCampaign) && !Number.isInteger(currentPlan)) {
        navigate(`/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${currentCampaign}`);
        return;
      }

      navigate(`/${agency}/overview/all`);
    } else {
      dispatch(resumeCopyPlan(processId)).then(result => {
        if (result === "return_to_overview") {
          navigate(`/${agency}/overview/all`);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, processId, agency]); // DO NOT ADD isUninterruptibleProcess as dependency! Flow will break!

  useEffect(() => {
    if (isPlanStep) {
      dispatch(copyPlan());
    }

    if (isPlanExtensionStep) {
      dispatch(copyPlanExtension());
    }

    if (isOrderStep && currentOrderStep) {
      dispatch(copyOrderPlanCopy());
    }
  }, [
    dispatch,
    isPlanStep,
    isPlanExtensionStep,
    isOrderStep,
    currentOrderStep
  ]);

  return (
    <>
      {/* <Prompt when={preventNavigation} message={NAVIGATION_MSG} /> */}

      <Slide
        timeout={initStepTimeout}
        direction="right"
        in={isCampaignStep}
        mountOnEnter
        unmountOnExit
      >
        <Grid item>
          <SelectCampaign />
        </Grid>
      </Slide>
      <Slide
        timeout={nextStepTimeout}
        direction="right"
        in={isPlanStep}
        mountOnEnter
        unmountOnExit
      >
        <Grid item>
          <PlanForm />
        </Grid>
      </Slide>
      <Slide
        timeout={nextStepTimeout}
        direction="right"
        in={isPlanExtensionStep}
        mountOnEnter
        unmountOnExit
      >
        <Grid item>
          <PlanExtensionForm />
        </Grid>
      </Slide>
      <Slide
        timeout={nextStepTimeout}
        direction="right"
        in={isOrderStep}
        mountOnEnter
        unmountOnExit
      >
        <Grid item>
          <OrderForm />
        </Grid>
      </Slide>
      {isFinalStep && (
        <Typography variant="h4" align="center" color="secondary">
          {WIZARD_COMPLETED_MSG}
        </Typography>
      )}
    </>
  );
};

CopyPlanWizardPage.propTypes = {};

export default CopyPlanWizardPage;
