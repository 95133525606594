import { styled } from "@mui/material";
import { getThemeSpacing } from "../../../../functions/util";
import {
  white,
  matisse,
  greyChateau,
  solitude
} from "../../../../assets/styles/colors";

const PREFIX = "PlanExtensionFormStyles";
export const classes = {
  header: `${PREFIX}-header`,
  saveButton: `${PREFIX}-saveButton`,
  table: `${PREFIX}-table`
};

export const Div = styled("div")(({ theme }) => ({
  [`& .${classes.header}`]: {
    backgroundColor: solitude,
    color: matisse
  },
  [`& .${classes.saveButton}`]: {
    backgroundColor: greyChateau,
    color: white,
    marginTop: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.table}`]: {
    overflowX: "auto"
  }
}));
