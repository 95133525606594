import { Model, attr } from "redux-orm";
import set from "lodash/set";
import omit from "lodash/omit";
import forEach from "lodash/forEach";
import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";
import {
  ISO8859_RULE,
  NUMBER_OF_CHARACTERS_RULE,
  ISO8859_MESSAGE,
  EMAIL_RULE,
  EMAIL_MESSAGE,
  NUMBER_OF_CHARACTERS_MESSAGE_1000,
  NUMBER_OF_CHARACTERS_MESSAGE_50,
  NUMBER_OF_CHARACTERS_MESSAGE_100,
  MANDATORY_RULE,
  MANDATORY_MESSAGE
} from "../../constants/validationConstants";

import {
  TRAFFIC_PLAN_MODEL_NAME,
  TRAFFIC_PLAN_MODEL_ID,
  TRAFFIC_PLAN_MEDIA_TYPE_ID
} from "./trafficPlanConstants";
import {
  TRAFFIC_ITEMS_PROPERTY,
  TRAFFIC_ITEM_MODEL_NAME
} from "../TrafficItem/trafficItemConstants";
import {
  TRAFFIC_PLAN_REMARK_PROPERTY,
  TRAFFIC_PLAN_REMARK_MODEL_NAME
} from "../TrafficPlanRemark/trafficPlanRemarkConstants";
import { PLAN_MODEL_ID } from "../Plan/planConstants";
import { getModelClassByType } from "../common/utils/modelUtils";
import {
  MEDIATYPE_DIGITAL,
  MEDIATYPE_PRINT,
  MEDIATYPE_OOH,
  MEDIATYPE_TV,
  MEDIATYPE_RADIO,
  MEDIATYPE_CINEMA,
  MEDIATYPE_INVENTORY
} from "../../../configurations/appConstants";
import {
  GET_TRAFFIC_REQUESTS,
  GET_THIRD_PARTY_MEASUREMENTS
} from "../../../configurations/apiUrls";

class TrafficPlan extends Model {
  static get fields() {
    return {
      id: attr(),

      trafficPlanId: attr(),
      campaignStatus: attr(),
      campaignName: attr(),
      campaignDebtor: attr(),
      campaignDebtorInternalName: attr(),
      mediaTypeId: attr(),
      mediaTypeName: attr(),
      campaignId: attr(),
      planId: attr(),
      planName: attr(),
      planMarathonId: attr(),
      version: attr(),
      exportDate: attr(),
      trafficRequest: attr(),
      adManagementOrderID: attr(),
      adServerSeat: attr(),
      thirdPartyMeasurement: attr(),
      remarks: attr(),
      creativeAgency: attr(),
      planner: attr(),
      contact: attr(),
      email: attr(),
      cc: attr(),
      contactGroupM: attr(),
      // emailGroupM: attr(),
      phoneNumberGroupM: attr(),
      isDebtorActive: attr()
    };
  }

  static get defaultAttributes() {
    return {};
  }

  static parse(trafficPlanData, isDebtorActive) {
    set(trafficPlanData, "isDebtorActive", isDebtorActive);

    if (trafficPlanData[TRAFFIC_PLAN_MODEL_ID]) {
      set(trafficPlanData, "id", trafficPlanData[TRAFFIC_PLAN_MODEL_ID]);
    } else if (
      trafficPlanData[PLAN_MODEL_ID] &&
      trafficPlanData[TRAFFIC_PLAN_MEDIA_TYPE_ID]
    ) {
      set(
        trafficPlanData,
        "id",
        `${trafficPlanData[PLAN_MODEL_ID]}_${trafficPlanData[TRAFFIC_PLAN_MEDIA_TYPE_ID]}`
      );
    }

    // Traffic Item
    const trafficItemProperty = TRAFFIC_ITEMS_PROPERTY;
    const TrafficItemModelClass = getModelClassByType(
      this.session,
      TRAFFIC_ITEM_MODEL_NAME
    );
    forEach(trafficPlanData[trafficItemProperty], entry => {
      TrafficItemModelClass.parse(entry);
    });
    const parsedTrafficItemData = omit(trafficPlanData, trafficItemProperty);

    // Traffic Plan Remark
    const trafficPlanRemarkProperty = TRAFFIC_PLAN_REMARK_PROPERTY;
    const TrafficPlanRemarkModelClass = getModelClassByType(
      this.session,
      TRAFFIC_PLAN_REMARK_MODEL_NAME
    );
    forEach(trafficPlanData[trafficPlanRemarkProperty], entry => {
      TrafficPlanRemarkModelClass.parse(entry);
    });
    const parsedTrafficPlanRemarkData = omit(
      parsedTrafficItemData,
      trafficPlanRemarkProperty
    );

    return this.upsert(parsedTrafficPlanRemarkData);
  }

  static updateModel(trafficPlanData) {
    if (trafficPlanData[TRAFFIC_PLAN_MODEL_ID]) {
      set(trafficPlanData, "id", trafficPlanData[TRAFFIC_PLAN_MODEL_ID]);
    } else if (
      trafficPlanData[PLAN_MODEL_ID] &&
      trafficPlanData[TRAFFIC_PLAN_MEDIA_TYPE_ID]
    ) {
      set(
        trafficPlanData,
        "id",
        `${trafficPlanData[PLAN_MODEL_ID]}_${trafficPlanData[TRAFFIC_PLAN_MEDIA_TYPE_ID]}`
      );
    }

    // Traffic Item
    const trafficItemProperty = TRAFFIC_ITEMS_PROPERTY;
    const TrafficItemModelClass = getModelClassByType(
      this.session,
      TRAFFIC_ITEM_MODEL_NAME
    );
    forEach(trafficPlanData[trafficItemProperty], entry => {
      TrafficItemModelClass.parse(entry);
    });
    const parsedTrafficItemData = omit(trafficPlanData, trafficItemProperty);

    // Traffic Plan Remark
    const trafficPlanRemarkProperty = TRAFFIC_PLAN_REMARK_PROPERTY;
    const TrafficPlanRemarkModelClass = getModelClassByType(
      this.session,
      TRAFFIC_PLAN_REMARK_MODEL_NAME
    );
    forEach(trafficPlanData[trafficPlanRemarkProperty], entry => {
      TrafficPlanRemarkModelClass.parse(entry);
    });
    const parsedTrafficPlanRemarkData = omit(
      parsedTrafficItemData,
      trafficPlanRemarkProperty
    );

    return this.upsert(parsedTrafficPlanRemarkData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...TrafficPlan.defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherTrafficPlan) {
    this.update(otherTrafficPlan.ref);
  }

  validate(type) {
    return validateModel(TrafficPlan.validation[type], this.toJSON());
  }

  validateByProperty(type, name) {
    return validateProperty(TrafficPlan.validation[type][name], this.ref[name]);
  }

  hasErrors(type) {
    return doesModelHaveErrors(TrafficPlan.validation[type], this.toJSON());
  }

  static get validation() {
    return {
      [MEDIATYPE_DIGITAL]: {
        creativeAgency: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        trafficRequest: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        adManagementOrderID: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        adServerSeat: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        thirdPartyMeasurement: [
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        contact: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        email: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        cc: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        contactGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        // emailGroupM: [
        //   {
        //     rule: NUMBER_OF_CHARACTERS_RULE,
        //     message: NUMBER_OF_CHARACTERS_MESSAGE_50,
        //     options: {
        //       min: 0,
        //       max: 50
        //     }
        //   },
        //   {
        //     rule: ISO8859_RULE,
        //     message: ISO8859_MESSAGE
        //   },
        //   {
        //     rule: EMAIL_RULE,
        //     message: EMAIL_MESSAGE
        //   }
        // ],
        phoneNumberGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        remarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ]
      },
      [MEDIATYPE_INVENTORY]: {
        creativeAgency: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        trafficRequest: [
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        adManagementOrderID: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        adServerSeat: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        thirdPartyMeasurement: [
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        contact: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        email: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        cc: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        contactGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        phoneNumberGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        remarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ]
      },
      [MEDIATYPE_PRINT]: {
        creativeAgency: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        contact: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        email: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        cc: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        contactGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        // emailGroupM: [
        //   {
        //     rule: NUMBER_OF_CHARACTERS_RULE,
        //     message: NUMBER_OF_CHARACTERS_MESSAGE_50,
        //     options: {
        //       min: 0,
        //       max: 50
        //     }
        //   },
        //   {
        //     rule: ISO8859_RULE,
        //     message: ISO8859_MESSAGE
        //   },
        //   {
        //     rule: EMAIL_RULE,
        //     message: EMAIL_MESSAGE
        //   }
        // ],
        phoneNumberGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        remarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ]
      },
      [MEDIATYPE_OOH]: {
        creativeAgency: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        contact: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        email: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        cc: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        contactGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        // emailGroupM: [
        //   {
        //     rule: NUMBER_OF_CHARACTERS_RULE,
        //     message: NUMBER_OF_CHARACTERS_MESSAGE_50,
        //     options: {
        //       min: 0,
        //       max: 50
        //     }
        //   },
        //   {
        //     rule: ISO8859_RULE,
        //     message: ISO8859_MESSAGE
        //   },
        //   {
        //     rule: EMAIL_RULE,
        //     message: EMAIL_MESSAGE
        //   }
        // ],
        phoneNumberGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        remarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ]
      },
      [MEDIATYPE_TV]: {
        creativeAgency: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        contact: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        email: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        cc: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        contactGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        // emailGroupM: [
        //   {
        //     rule: NUMBER_OF_CHARACTERS_RULE,
        //     message: NUMBER_OF_CHARACTERS_MESSAGE_50,
        //     options: {
        //       min: 0,
        //       max: 50
        //     }
        //   },
        //   {
        //     rule: ISO8859_RULE,
        //     message: ISO8859_MESSAGE
        //   },
        //   {
        //     rule: EMAIL_RULE,
        //     message: EMAIL_MESSAGE
        //   }
        // ],
        phoneNumberGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        remarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ]
      },
      [MEDIATYPE_RADIO]: {
        creativeAgency: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        contact: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        email: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        cc: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        contactGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        // emailGroupM: [
        //   {
        //     rule: NUMBER_OF_CHARACTERS_RULE,
        //     message: NUMBER_OF_CHARACTERS_MESSAGE_50,
        //     options: {
        //       min: 0,
        //       max: 50
        //     }
        //   },
        //   {
        //     rule: ISO8859_RULE,
        //     message: ISO8859_MESSAGE
        //   },
        //   {
        //     rule: EMAIL_RULE,
        //     message: EMAIL_MESSAGE
        //   }
        // ],
        phoneNumberGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        remarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ]
      },
      [MEDIATYPE_CINEMA]: {
        creativeAgency: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_100,
            options: {
              min: 0,
              max: 100
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        contact: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        email: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          },
          {
            rule: MANDATORY_RULE,
            message: MANDATORY_MESSAGE
          }
        ],
        cc: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        contactGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          },
          {
            rule: EMAIL_RULE,
            message: EMAIL_MESSAGE
          }
        ],
        // emailGroupM: [
        //   {
        //     rule: NUMBER_OF_CHARACTERS_RULE,
        //     message: NUMBER_OF_CHARACTERS_MESSAGE_50,
        //     options: {
        //       min: 0,
        //       max: 50
        //     }
        //   },
        //   {
        //     rule: ISO8859_RULE,
        //     message: ISO8859_MESSAGE
        //   },
        //   {
        //     rule: EMAIL_RULE,
        //     message: EMAIL_MESSAGE
        //   }
        // ],
        phoneNumberGroupM: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_50,
            options: {
              min: 0,
              max: 50
            }
          },
          {
            rule: ISO8859_RULE,
            message: ISO8859_MESSAGE
          }
        ],
        remarks: [
          {
            rule: NUMBER_OF_CHARACTERS_RULE,
            message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
            options: {
              min: 0,
              max: 1000
            }
          }
        ]
      }
    };
  }

  static get hideFields() {
    return {
      creativeAgency: ["isCreativeAgencyFieldVisibleForMediatype"],
      contact: ["isContactFieldVisibleForMediatype"],
      email: ["isEmailFieldVisibleForMediatype"],
      cc: ["isCCFieldVisibleForMediatype"],
      contactGroupM: ["isContactGroupMFieldVisibleForMediatype"],
      // emailGroupM: ["isEmailGroupMFieldVisibleForMediatype"],
      phoneNumberGroupM: ["isPhoneNumberGroupMFieldVisibleForMediatype"]
    };
  }

  static get apiConfiguration() {
    return {
      trafficRequest: {
        url: GET_TRAFFIC_REQUESTS
      },
      thirdPartyMeasurement: {
        url: GET_THIRD_PARTY_MEASUREMENTS
      }
    };
  }
}

TrafficPlan.modelName = TRAFFIC_PLAN_MODEL_NAME;

export default TrafficPlan;
