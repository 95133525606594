import { Model, attr } from "redux-orm";
import set from "lodash/set";
import omit from "lodash/omit";
import forEach from "lodash/forEach";

import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";

import {
  MANDATORY_RULE,
  EMAIL_RULE,
  DATE_RULE,
  ISO8859_RULE,
  NUMBER_OF_CHARACTERS_RULE,
  MANDATORY_MESSAGE,
  EMAIL_MESSAGE,
  DATE_MESSAGE,
  ISO8859_MESSAGE,
  DECIMAL_RULE,
  DECIMAL_MESSAGE,
  NUMBER_OF_CHARACTERS_MESSAGE_100,
  NUMBER_OF_CHARACTERS_MESSAGE_50
} from "../../constants/validationConstants";

import { CAMPAIGN_MODEL_NAME, CAMPAIGN_MODEL_ID } from "./campaignConstants";
import {
  PROJECT_MODEL_NAME,
  PROJECT_PROPERTY
} from "../Project/projectConstants";
import { PLAN_MODEL_NAME, PLANS_PROPERTY } from "../Plan/planConstants";

import {
  GET_DEBTORS,
  GET_PRODUCTS,
  GET_AGREEMENTS,
  GET_PROCESS_MANAGER_USERS
} from "../../../configurations/apiUrls";

import { getModelClassByType } from "../common/utils/modelUtils";

class Campaign extends Model {
  static get fields() {
    return {
      id: attr(),
      campaignId: attr(),
      campaignName: attr(),
      debtorId: attr(),
      debtorName: attr(),
      productId: attr(),
      productName: attr(),
      agreementId: attr(),
      agreementName: attr(),
      startDate: attr(),
      endDate: attr(),
      poNumber: attr(),
      poBudget: attr(),
      processManagerId: attr(),
      debtorContactPersonEmail: attr(),
      marathonCampaignId: attr(),
      isActual: attr(),
      isFavourite: attr(),
      isInCopyProcess: attr(),

      // calculations
      orderAmountPercentage: attr(),
      orderTotalAmount: attr(),
      clientTotalCostEur: attr(),
      netNet: attr(),
      fee: attr(),
      surcharge: attr(),
      surcharge2: attr()
    };
  }

  static get defaultAttributes() {
    return {
      isActual: false
    };
  }

  static parse(campaignData) {
    if (campaignData[CAMPAIGN_MODEL_ID]) {
      set(campaignData, "id", campaignData[CAMPAIGN_MODEL_ID]);
    }

    // Plan
    const planProperty = PLANS_PROPERTY;
    const PlanModelClass = getModelClassByType(this.session, PLAN_MODEL_NAME);
    forEach(campaignData[planProperty], entry => {
      PlanModelClass.parse(entry);
    });
    let parsedData = omit(campaignData, planProperty);

    // Project
    const projectProperty = PROJECT_PROPERTY;
    const ProjectModelClass = getModelClassByType(
      this.session,
      PROJECT_MODEL_NAME
    );
    forEach(campaignData[projectProperty], entry => {
      ProjectModelClass.parse(entry);
    });
    parsedData = omit(parsedData, projectProperty);

    return this.upsert(parsedData);
  }

  static updateModel(campaignData) {
    if (campaignData[CAMPAIGN_MODEL_ID]) {
      set(campaignData, "id", campaignData[CAMPAIGN_MODEL_ID]);
    }

    // Plan
    const planProperty = PLANS_PROPERTY;
    const PlanModelClass = getModelClassByType(this.session, PLAN_MODEL_NAME);
    forEach(campaignData[planProperty], entry => {
      PlanModelClass.updateModel(entry);
    });
    let parsedData = omit(campaignData, planProperty);

    // Project
    const projectProperty = PROJECT_PROPERTY;
    const ProjectModelClass = getModelClassByType(
      this.session,
      PROJECT_MODEL_NAME
    );
    forEach(campaignData[projectProperty], entry => {
      ProjectModelClass.updateModel(entry);
    });
    parsedData = omit(parsedData, projectProperty);

    return this.withId(campaignData.id).update(parsedData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...Campaign.defaultAttributes,
      ...newAttributes
    };
    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherCampaign) {
    this.update(otherCampaign.ref);
  }

  static get validation() {
    return {
      campaignName: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      debtorId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      productId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      agreementId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      startDate: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DATE_RULE,
          message: DATE_MESSAGE
        }
      ],
      endDate: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DATE_RULE,
          message: DATE_MESSAGE
        }
      ],
      poBudget: [
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      poNumber: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      debtorContactPersonEmail: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: EMAIL_RULE,
          message: EMAIL_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      processManagerId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ]
    };
  }

  static get calculations() {
    return {
      orderTotalAmount: {
        calcMethod: "sumOrderNetTotal",
        calcParams: ["netTotal"]
      },
      orderAmountPercentage: {
        calcMethod: "orderAmountPercentageOnCampaign",
        calcParams: [
          "feePercentage",
          "agencyDiscountAmount",
          "circulationBase",
          "netTotal",
          "surcharge1Amount",
          "surcharge1Commission",
          "surcharge1Fee",
          "surcharge2Amount",
          "surcharge2Commission",
          "surcharge2Fee",
          "poBudget",
          "currencySupplierId",
          "exchangeRate"
        ]
      },
      clientTotalCostEur: {
        calcMethod: "sumtotalcostclienteur",
        calcParams: [
          "feePercentage",
          "agencyDiscountAmount",
          "circulationBase",
          "netTotal",
          "surcharge1Amount",
          "surcharge1Commission",
          "surcharge1Fee",
          "surcharge2Amount",
          "surcharge2Commission",
          "surcharge2Fee",
          "currencySupplierId",
          "exchangeRate"
        ]
      }
    };
  }

  validate() {
    return validateModel(Campaign.validation, this.toJSON());
  }

  validateByProperty(name) {
    return validateProperty(Campaign.validation[name], this.ref[name]);
  }

  hasErrors() {
    return doesModelHaveErrors(Campaign.validation, this.toJSON());
  }

  static get apiConfiguration() {
    return {
      debtorId: {
        url: GET_DEBTORS
      },
      productId: {
        url: GET_PRODUCTS,
        urlParams: ["debtorId"],
        urlRequiredParams: ["debtorId"]
      },
      agreementId: {
        url: GET_AGREEMENTS,
        urlParams: ["debtorId"],
        urlRequiredParams: ["debtorId"]
      },
      processManagerId: {
        url: GET_PROCESS_MANAGER_USERS
      }
    };
  }

  static get disabledFields() {
    return {
      debtorId: [
        "doesCampaignHaveInvoicedInsertions",
        ["isCampaignInCopyPlanProcess"],
        "disabledByInactiveDebtor"
      ],
      productId: [
        "doesCampaignHaveInvoicedInsertions",
        ["isCampaignInCopyPlanProcess"],
        "disabledByInactiveDebtor"
      ],
      campaignName: ["isCampaignInCopyPlanProcess", "disabledByInactiveDebtor"],
      agreementId: ["isCampaignInCopyPlanProcess", "disabledByInactiveDebtor"],
      debtorIDview: ["isCampaignInCopyPlanProcess", "disabledByInactiveDebtor"],
      productIDview: [
        "isCampaignInCopyPlanProcess",
        "disabledByInactiveDebtor"
      ],
      startDate: ["isCampaignInCopyPlanProcess", "disabledByInactiveDebtor"],
      endDate: ["isCampaignInCopyPlanProcess", "disabledByInactiveDebtor"],
      poBudget: ["isCampaignInCopyPlanProcess", "disabledByInactiveDebtor"],
      poNumber: ["isCampaignInCopyPlanProcess", "disabledByInactiveDebtor"],
      debtorContactPersonEmail: [
        "isCampaignInCopyPlanProcess",
        "disabledByInactiveDebtor"
      ],
      processManagerId: [
        "isCampaignInCopyPlanProcess",
        "disabledByInactiveDebtor"
      ]
    };
  }
}

Campaign.modelName = CAMPAIGN_MODEL_NAME;

export default Campaign;
