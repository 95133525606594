import { styled, Dialog as MuiDialog } from "@mui/material";
import {
  white,
  dodgerBlue,
  crusta,
  linkWater,
  solitude
} from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "ChangeTrafficStatusDialogStyles";
export const classes = {
  buttonCancel: `${PREFIX}-buttonCancel`,
  buttonSave: `${PREFIX}-buttonSave`,
  buttonSpace: `${PREFIX}-buttonSpace`,
  buttonApply: `${PREFIX}-buttonApply`
};

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${classes.buttonCancel}`]: {
    color: crusta
  },
  [`& .${classes.buttonSave}`]: {
    color: white,
    "&:disabled": {
      color: linkWater,
      backgroundColor: solitude
    }
  },
  [`& .${classes.buttonSpace}`]: {
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 2))
  },
  [`& .${classes.buttonApply}`]: {
    backgroundColor: dodgerBlue
  }
}));
