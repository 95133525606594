import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import {
  Grid,
  Typography,
  AccordionSummary,
  Checkbox,
  Tooltip
} from "@mui/material";

import { classes, Accordion } from "../../styles/uploadDialogItem";

class UploadDialogItem extends PureComponent {
  onCheckboxClick = e => {
    const { id, onCheckBoxClick } = this.props;
    onCheckBoxClick(id, e.target.checked);
  };

  render() {
    const { id, attachment, hideCheckbox } = this.props;
    const { fileName } = attachment || {};

    return (
      <Accordion
        key={id}
        square
        elevation={0}
        classes={{ root: classes.expansionPanel, expanded: classes.selected }}
        expanded={false}
      >
        <AccordionSummary key={id} className={classes.contentWrapper}>
          <Grid
            container
            justifyContent="flex-start"
            key={id}
            alignItems="center"
          >
            {!hideCheckbox && (
              <Grid item>
                <Checkbox
                  id={`upload_dialog_item_${id}`}
                  onClick={this.onCheckboxClick}
                  color="primary"
                />
              </Grid>
            )}
            <Grid item>
              <Tooltip title={fileName}>
                <Typography className={classes.cssTypography} noWrap>
                  File name: {fileName}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
    );
  }
}

UploadDialogItem.propTypes = {
  id: PropTypes.number,
  attachment: PropTypes.object,
  onCheckBoxClick: PropTypes.func.isRequired
};

export default UploadDialogItem;
