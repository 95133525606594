import { styled, Chip as MuiChip } from "@mui/material";
import { baliHai, solitude } from "../../../assets/styles/colors";
import { getThemeSpacing } from "../../../functions/util";

const PREFIX = "CtcChipStyles";
export const classes = {
  chip: `${PREFIX}-chip`
};

export const Chip = styled(MuiChip)(({ theme }) => ({
  [`&.${classes.chip}`]: {
    color: baliHai,
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    minWidth: theme.typography.pxToRem(80),
    backgroundColor: solitude
  }
}));
