import React from "react";
import PropTypes from "prop-types";

import {
  Typography,
  Paper,
  Grid,
  ClickAwayListener,
  Grow
} from "@mui/material";

import { PLACEHOLDER } from "../../../configurations/appConstants";
import { classes, Popper } from "../styles/orderDetailsPopper";

const TrafficItemRemarkPopper = ({ open, buttonRef, setOpen, remarks }) => {
  const handleClose = e => {
    if (buttonRef.current.contains(e.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <Popper
      open={open}
      anchorEl={buttonRef.current}
      placement="bottom-start"
      transition
      className={classes.trafficItemPopper}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          id="ti-remarks-popper"
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.detailsPanel}>
            <ClickAwayListener onClickAway={handleClose}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    id="trafficItemRemarks"
                    name="trafficItemRemarks"
                    align="left"
                    className={classes.typographyTrafficItemRemarksValue}
                  >
                    {remarks || PLACEHOLDER}
                  </Typography>
                  <Typography
                    align="left"
                    className={classes.typographyCaption}
                  >
                    Remarks
                  </Typography>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

TrafficItemRemarkPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  buttonRef: PropTypes.object,

  setOpen: PropTypes.func,
  remarks: PropTypes.string
};

export default TrafficItemRemarkPopper;
