import { styled, MenuItem as MuiMenuItem } from "@mui/material";
import { azureRadiance } from "../../../../assets/styles/colors";

const PREFIX = "AvatarMenuStyles";
export const classes = {
  textMenuItem: `${PREFIX}-textMenuItem`
};

export const MenuItem = styled(MuiMenuItem)(() => ({
  [`& .${classes.textMenuItem}`]: {
    color: azureRadiance
  }
}));
