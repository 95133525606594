import React from "react";
import PropTypes from "prop-types";

import {
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Typography,
  Tooltip
} from "@mui/material";

import { DASH_PLACEHOLDER } from "../../../../configurations/appConstants";
import { EditIcon, DeleteItemIcon } from "../../../../assets/styles/icons";

import { trimString } from "../../../../business/models/common/utils/clientUtils";
import { isNullOrUndefinedOrEmpty } from "../../../../functions/util";

const TrafficTemplateRow = ({ data, onHandleEdit, onHandleDelete }) => {
  const { id } = data;
  const stringLength = 15;

  return (
    <TableRow>
      <TableCell align="left">
        <Tooltip title={data.templateName || DASH_PLACEHOLDER}>
          <Typography noWrap id="tt_templateName" name="tt_templateName">
            {!isNullOrUndefinedOrEmpty(data.templateName)
              ? trimString(data.templateName, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={data.description || DASH_PLACEHOLDER}>
          <Typography noWrap id="tt_description" name="tt_description">
            {!isNullOrUndefinedOrEmpty(data.description)
              ? trimString(data.description, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={data.dimensions || DASH_PLACEHOLDER}>
          <Typography noWrap id="tt_dimensions" name="tt_dimensions">
            {!isNullOrUndefinedOrEmpty(data.dimensions)
              ? data.dimensions
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={data.weight || DASH_PLACEHOLDER}>
          <Typography noWrap id="tt_weight" name="tt_weight">
            {!isNullOrUndefinedOrEmpty(data.weight)
              ? trimString(data.weight, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={data.creativeType || DASH_PLACEHOLDER}>
          <Typography noWrap id="tt_creativeType" name="tt_creativeType">
            {!isNullOrUndefinedOrEmpty(data.creativeType)
              ? trimString(data.creativeType, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={data.animationLooping || DASH_PLACEHOLDER}>
          <Typography
            noWrap
            id="tt_animationLooping"
            name="tt_animationLooping"
          >
            {" "}
            {!isNullOrUndefinedOrEmpty(data.animationLooping)
              ? trimString(data.animationLooping, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={data.framerate || DASH_PLACEHOLDER}>
          <Typography noWrap>
            {!isNullOrUndefinedOrEmpty(data.framerate)
              ? trimString(data.framerate, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={data.adServer || DASH_PLACEHOLDER}>
          <Typography noWrap id="adServer" name="adServer">
            {!isNullOrUndefinedOrEmpty(data.adServer)
              ? data.adServer
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={data.contact || DASH_PLACEHOLDER}>
          <Typography noWrap id="contact" name="contact">
            {!isNullOrUndefinedOrEmpty(data.contact)
              ? trimString(data.contact, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={data.remarks || DASH_PLACEHOLDER}>
          <Typography noWrap id="remarks" name="remarks">
            {!isNullOrUndefinedOrEmpty(data.remarks)
              ? trimString(data.remarks, stringLength)
              : DASH_PLACEHOLDER}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <IconButton
              name={`trtem_edit_${id}`}
              id={`trtem_edit_${id}`}
              onClick={() => onHandleEdit(id)}
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item>
            {" "}
            <IconButton
              name={`trtem_delete_${id}`}
              id={`trtem_delete_${id}`}
              onClick={() => onHandleDelete(id)}
            >
              <DeleteItemIcon />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

TrafficTemplateRow.propTypes = {
  data: PropTypes.object.isRequired,
  onHandleEdit: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired
};

export default TrafficTemplateRow;
