import { createSelector } from "reselect";

const selectCampaignDashboard = state => state.campaignDashboard;
const selectOrderBuyingStatuses = state =>
  state.campaignDashboard.orderBuyingStatuses;

export const selectIsCampaignFormOpen = createSelector(
  selectCampaignDashboard,
  page => page.isCampaignFormOpen
);

export const selectIsPlanFormOpen = createSelector(
  selectCampaignDashboard,
  page => page.isPlanFormOpen
);

export const selectIsPlanExtensionFormOpen = createSelector(
  selectCampaignDashboard,
  page => page.isPlanExtensionFormOpen
);

// TODO: DO we need this?
export const isInCopyPlanProcess = createSelector(
  [selectCampaignDashboard],
  page => {
    return page.stepper.processId != null;
  }
);

export const selectOrderBuyingStatusesItems = createSelector(
  selectOrderBuyingStatuses,
  orderBuyingStatuses => orderBuyingStatuses.items
);

export const selectOrderBuyingStatusesPlanId = createSelector(
  selectOrderBuyingStatuses,
  orderBuyingStatuses => orderBuyingStatuses.planId
);

export const selectIsOrderFormOpen = createSelector(
  selectCampaignDashboard,
  page => page.isOrderFormOpen
);

export const selectOpenClientStatusLogDialog = createSelector(
  selectCampaignDashboard,
  page => page.isClientStatusLogDialogOpen
);

export const selectIsProjectDialogOpen = createSelector(
  selectCampaignDashboard,
  page => page.isProjectDialogOpen
);
export const selectIsCopyPlanWizardOpen = createSelector(
  selectCampaignDashboard,
  page => page.isCopyPlanWizardOpen
);

export const selectIsOrderBuyingStatusesDialogOpen = createSelector(
  selectCampaignDashboard,
  page => page.isOrderBuyingStatusesDialogOpen
);

export const selectIsSetCampaignActualDialogOpen = createSelector(
  selectCampaignDashboard,
  page => page.isSetCampaignActualDialogOpen
);

export const selectIsSetActualDialogOpen = createSelector(
  selectCampaignDashboard,
  page => page.isSetActualDialogOpen
);

export const selectIsPlanExportDialogOpen = createSelector(
  selectCampaignDashboard,
  page => page.isPlanExportDialogOpen
);

export const selectPlansWithInsertionChange = createSelector(
  selectCampaignDashboard,
  page => page.plansWithInsertionChange
);

export const selectOrdersWithInsertionChange = createSelector(
  selectCampaignDashboard,
  page => page.ordersWithInsertionChange
);

export const selectInsertionsWithChange = createSelector(
  selectCampaignDashboard,
  page => page.insertionsWithChange
);

export const selectIsFormOpen = createSelector(
  [
    selectIsCampaignFormOpen,
    selectIsPlanFormOpen,
    selectIsOrderFormOpen,
    selectIsPlanExtensionFormOpen
  ],
  (
    isCampaignFormOpen,
    isPlanFormOpen,
    isOrderFormOpen,
    isPlanExtensionFormOpen
  ) => {
    return (
      isCampaignFormOpen ||
      isPlanFormOpen ||
      isOrderFormOpen ||
      isPlanExtensionFormOpen
    );
  }
);
