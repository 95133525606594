import { styled, Popper as MuiPopper } from "@mui/material";
import { matisse, linkWater } from "../../../../assets/styles/colors";

const PREFIX = "ChangeOrderStatusDialogElementPopperStyle";
export const classes = {
  orderPopper: `${PREFIX}-orderPopper`,
  detailsPanel: `${PREFIX}-detailsPanel`,
  typographyDetailsValue: `${PREFIX}-typographyDetailsValue`,
  typographyCaption: `${PREFIX}-typographyCaption`
};

export const Popper = styled(MuiPopper)(({ theme }) => ({
  [`&.${classes.orderPopper}`]: {
    width: theme.typography.pxToRem(1200),
    marginRight: theme.typography.pxToRem(90),
    zIndex: 1500
  },
  [`& .${classes.detailsPanel}`]: {
    padding: theme.typography.pxToRem(10)
  },
  [`& .${classes.typographyDetailsValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  }
}));
