import React, { memo } from "react";
import PropTypes from "prop-types";

import isEmpty from "lodash/isEmpty";

import {
  Typography,
  Grid,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";

import { parseDate } from "../../../business/models/common/utils/clientUtils";

import { EditIcon } from "../../../assets/styles/icons";
import { classes, Table } from "./styles/systemMessageList";
import { EMPTY_TABLE, PLACEHOLDER } from "../../../configurations/appConstants";

const SystemMessagesList = ({ messages, onEdit }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">ID</TableCell>
          <TableCell align="left">User</TableCell>
          <TableCell align="left">Title</TableCell>
          <TableCell align="left">Message</TableCell>
          <TableCell align="left">Start Date</TableCell>
          <TableCell align="left">End Date</TableCell>
          <TableCell align="left">Is Active</TableCell>
          <TableCell align="left">Attachment</TableCell>
          <TableCell align="left">Modified</TableCell>
          <TableCell align="center" />
        </TableRow>
      </TableHead>
      <TableBody>
        {!isEmpty(messages) ? (
          messages.map(row => (
            <TableRow key={row.systemMessageId}>
              <TableCell className={classes.tableCell} align="left">
                <Typography noWrap>
                  {PLACEHOLDER && row.systemMessageId}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                <Typography noWrap>{PLACEHOLDER && row.user}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                <Typography noWrap>{PLACEHOLDER && row.title}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                <Typography noWrap>{PLACEHOLDER && row.message}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                <Typography noWrap>{parseDate(row.startDate)}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                <Typography noWrap>{parseDate(row.endDate)}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                <Typography noWrap>{row.isActive ? "YES" : "NO"}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                <Typography noWrap>
                  {row.hasAttachment ? "YES" : "NO"}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                <Typography noWrap>{parseDate(row.modified)}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <Grid item>
                  <IconButton
                    name={`sm_edit_${row.id}`}
                    id={`sm_edit_${row.id}`}
                    size="small"
                    onClick={e => onEdit(row.id, e)}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={9}
              className={classes.tableCellEmpty}
              align="center"
            >
              {EMPTY_TABLE}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

SystemMessagesList.propTypes = {
  messages: PropTypes.array
};

SystemMessagesList.defaultProps = {
  messages: []
};

export default memo(SystemMessagesList);
