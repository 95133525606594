import { createReducer } from "../common/utils/reducerUtils";

import {
  PLAN_EXTENSION_SELECT,
  PLAN_EXTENSION_CLEAR_CURRENT,
  PLAN_EXTENSION_RESET_CURRENT,
  PLAN_EXTENSION_EDIT_START,
  PLAN_EXTENSION_EDIT_STOP,
  PLAN_EXTENSION_HIDE_ERRORS,
  PLAN_EXTENSION_SET_IS_NEW,
  PLAN_EXTENSION_DISPLAY_ERRORS,
  PLAN_EXTENSION_SET_IS_NEW_FROM_UPDATE
} from "./planExtensionConstants";

const initialState = {
  currentPlanExtension: null,
  isEditing: false,
  displayErrors: false,
  isNew: false,
  isNewFromUpdate: false
};

export const startEditingPlanExtension = state => {
  return {
    ...state,
    isEditing: true
  };
};

export const stopEditingPlanExtension = state => {
  return {
    ...state,
    isEditing: false
  };
};

export const selectPlanExtension = (state, payload) => {
  return {
    ...state,
    currentPlanExtension: payload.currentPlanExtension
  };
};

export const hideErrors = state => {
  return {
    ...state,
    displayErrors: false
  };
};

export const clearCurrent = () => {
  return initialState;
};

export const resetCurrent = state => {
  return {
    ...state,
    isNew: false,
    isNewFromUpdate: false,
    isEditing: false,
    displayErrors: false
  };
};

export const setIsNew = state => {
  return {
    ...state,
    isNew: true
  };
};

export const displayErrors = state => {
  return {
    ...state,
    displayErrors: true
  };
};

export const setNewFromUpdate = state => {
  return {
    ...state,
    isNewFromUpdate: true
  };
};

export default createReducer(initialState, {
  [PLAN_EXTENSION_EDIT_START]: startEditingPlanExtension,
  [PLAN_EXTENSION_EDIT_STOP]: stopEditingPlanExtension,
  [PLAN_EXTENSION_SELECT]: selectPlanExtension,
  [PLAN_EXTENSION_CLEAR_CURRENT]: clearCurrent,
  [PLAN_EXTENSION_RESET_CURRENT]: resetCurrent,
  [PLAN_EXTENSION_HIDE_ERRORS]: hideErrors,
  [PLAN_EXTENSION_SET_IS_NEW]: setIsNew,
  [PLAN_EXTENSION_DISPLAY_ERRORS]: displayErrors,
  [PLAN_EXTENSION_SET_IS_NEW_FROM_UPDATE]: setNewFromUpdate
});
