import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTemplateDataIfNeeded,
  MapTemplateToTrafficItem
} from "../../../business/models/TrafficTemplate/trafficTemplateActions";
import {
  selectIsEditingTrafficItem,
  selectTrafficItemDigitalTemplateId
} from "../../../business/models/TrafficItem/trafficItemSelectors";

const useTemplateData = (includeActive = false, isActive = false) => {
  const dispatch = useDispatch();
  const editMode = useSelector(selectIsEditingTrafficItem);
  const templateId = useSelector(selectTrafficItemDigitalTemplateId);
  const enabled = includeActive
    ? editMode && isActive && templateId
    : editMode && templateId;

  useEffect(() => {
    if (enabled) {
      dispatch(fetchTemplateDataIfNeeded()).then(() =>
        dispatch(MapTemplateToTrafficItem())
      );
    }
  }, [dispatch, enabled]);
};

export default useTemplateData;
