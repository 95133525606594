import { styled, Box as MuiBox } from "@mui/material";
import { white, linkWater, matisse } from "../../../assets/styles/colors";

const PREFIX = "TrafficItemFieldStyles";
export const classes = {
  field: `${PREFIX}-field`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  typographyCaptionBold: `${PREFIX}-typographyCaptionBold`,
  typographyDetailsValue: `${PREFIX}-typographyDetailsValue`,
  decimalField: `${PREFIX}-decimalField`,
  disabled: `${PREFIX}-disabled`,
  ellipsisText: `${PREFIX}-ellipsisText`
};

export const Box = styled(MuiBox)(({ theme }) => ({
  [`& .${classes.field}`]: {
    marginTop: theme.typography.pxToRem(0)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyCaptionBold}`]: {
    fontWeight: "bold"
  },
  [`& .${classes.typographyDetailsValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    background: white
  },
  [`& .${classes.decimalField}`]: {
    backgroundColor: "none",
    background: white
  },
  [`& .${classes.disabled}`]: {
    "&$disabled": {
      backgroundColor: "transparent"
    },
    disabled: {}
  },
  [`& .${classes.ellipsisText}`]: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));
