import React, { useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import includes from "lodash/includes";
import filter from "lodash/filter";
import concat from "lodash/concat";

import {
  useQueryParams,
  StringParam,
  BooleanParam,
  DelimitedArrayParam
} from "use-query-params";

import OverviewTable from "./form/Table";

import {
  loadItems,
  downloadExcel,
  resetPage,
  campaignFavourite,
  setSelectedSaveSearch
} from "../../../business/actions/overviewPageActions";

import {
  selectItems,
  selectCount
} from "../../../business/selectors/overviewPageSelectors";

import { selectIsLoading } from "../../../business/selectors/applicationSelectors";

import {
  CAMPAIGN,
  CREATE_PAGE,
  TRAFFIC_PAGE,
  CAMPAIGN_FAVOURITES
} from "../../../configurations/appConstants";
import {
  canViewTrafficDashboardPage,
  canEditCampaign
} from "../../../business/selectors/userProfileSelectors";
import { isNullOrUndefined } from "../../../functions/util";
import { defaultOptionForSaveSearch } from "../../../business/constants/overviewPageConstants";

const filterQuerys = {
  campaignid: StringParam,
  campaignname: StringParam,
  planid: StringParam,
  planname: StringParam,
  orderid: StringParam,
  debtorname: StringParam,
  processmanager: StringParam,
  debtorid: StringParam
};

const OverviewPage = () => {
  const dispatch = useDispatch();
  const { campaignStatus: status, agency } = useParams();
  const navigate = useNavigate();

  const isLoading = useSelector(selectIsLoading);
  const rows = useSelector(selectItems);
  const count = useSelector(selectCount);
  const canViewTraffic = useSelector(canViewTrafficDashboardPage);
  const disableAction = !useSelector(canEditCampaign);

  const [query, setQuery] = useQueryParams({
    ...filterQuerys,
    searchMyCampaigns: BooleanParam,
    searchMediaTypes: DelimitedArrayParam,
    sort: StringParam,
    currentPage: StringParam,
    pageSize: StringParam
  });

  const {
    searchMyCampaigns,
    searchMediaTypes,
    sort,
    campaignid,
    campaignname,
    planid,
    planname,
    orderid,
    debtorname,
    debtorid,
    processmanager,
    currentPage = 0,
    pageSize = 10
  } = query;

  const handleDynamicFilter = useCallback(
    filterQuery => {
      setQuery({ ...filterQuery, currentPage: 0 }, "pushIn");
    },
    [setQuery]
  );

  const handleSortChange = useCallback(
    event => {
      setQuery({ sort: event.target.value, currentPage: 0 }, "pushIn");
    },
    [setQuery]
  );

  const handleSearchMediaTypes = useCallback(
    e => {
      const mediaType = e.currentTarget.id;
      setQuery(
        {
          searchMediaTypes: includes(searchMediaTypes, mediaType)
            ? filter(searchMediaTypes, val => val !== mediaType)
            : concat(searchMediaTypes, mediaType),
          currentPage: 0
        },
        "pushIn"
      );
    },
    [searchMediaTypes, setQuery]
  );

  const handleSearchMyCampaigns = useCallback(() => {
    setQuery(
      { searchMyCampaigns: !searchMyCampaigns, currentPage: 0 },
      "pushIn"
    );
  }, [searchMyCampaigns, setQuery]);

  const handleChangePage = useCallback(
    (_, page) => {
      setQuery({ currentPage: page }, "pushIn");
    },
    [setQuery]
  );

  const handleChangeRowsPerPage = useCallback(
    event => {
      setQuery({ pageSize: event.target.value, currentPage: 0 }, "pushIn");
    },
    [setQuery]
  );

  const handleClear = useCallback(() => {
    setQuery({ sort, currentPage: 0 }, "push");
    dispatch(setSelectedSaveSearch(defaultOptionForSaveSearch));
  }, [setQuery, sort, dispatch]);

  // Actions
  const handleEditClick = useCallback(
    (id, searchParam) => {
      if (!isNullOrUndefined(searchParam)) {
        navigate(`/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${searchParam}`);
      } else {
        navigate(`/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${id}`);
      }
    },
    [agency, navigate]
  );

  const handleDownloadClick = useCallback(
    id => {
      dispatch(downloadExcel(id));
    },
    [dispatch]
  );

  const handleTrafficClick = useCallback(
    id => {
      navigate(`/${agency}/${TRAFFIC_PAGE}/${id}`);
    },
    [agency, navigate]
  );

  const handleFavouriteClick = useCallback(
    id => {
      dispatch(
        campaignFavourite(id, { ...query, status, currentPage, pageSize })
      );
    },
    [dispatch, query, status, currentPage, pageSize]
  );

  useEffect(() => {
    dispatch(
      loadItems({
        searchMyCampaigns,
        searchMediaTypes,
        sort,
        currentPage,
        pageSize,
        status,
        campaignid,
        campaignname,
        planid,
        planname,
        orderid,
        debtorname,
        debtorid,
        processmanager
      })
    );
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchMediaTypes,
    searchMyCampaigns,
    sort,
    status,
    campaignid,
    campaignname,
    planid,
    planname,
    orderid,
    debtorname,
    debtorid,
    processmanager
  ]);

  useEffect(() => {
    dispatch(resetPage());
  }, [dispatch]);

  return (
    <OverviewTable
      rows={rows}
      count={count}
      pageSize={Number(pageSize)}
      currentPage={Number(currentPage)}
      searchMyCampaigns={searchMyCampaigns}
      searchMediaTypes={searchMediaTypes}
      sort={sort}
      contentLoading={isLoading}
      onClear={handleClear}
      onSortChange={handleSortChange}
      onSearchMyCampaigns={handleSearchMyCampaigns}
      onSearchMediaTypes={handleSearchMediaTypes}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onEditClick={handleEditClick}
      handleDownloadClick={handleDownloadClick}
      handleTrafficClick={handleTrafficClick}
      handleFavouriteClick={handleFavouriteClick}
      canViewTraffic={canViewTraffic}
      disableAction={disableAction}
      onDynamicFilter={handleDynamicFilter}
      showTableControls={status !== CAMPAIGN_FAVOURITES.toLocaleLowerCase()}
      queryFilters={{
        campaignid,
        campaignname,
        planid,
        planname,
        orderid,
        debtorname,
        debtorid,
        processmanager
      }}
    />
  );
};

export default OverviewPage;
