import { styled, DialogTitle } from "@mui/material";
import { getThemeSpacing } from "../../../functions/util";
import { sapphire } from "../../../assets/styles/colors";

const PREFIX = "DialogTitleStyles";
export const classes = {
  root: `${PREFIX}-root`,
  titleTypography: `${PREFIX}-titleTypography`
};

export const MuiDialogTitle = styled(DialogTitle)(({ theme }) => ({
  [`&.${classes.root}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: getThemeSpacing(theme, 2)
  },
  [`& .${classes.titleTypography}`]: {
    fontSize: theme.typography.pxToRem(16),
    color: sapphire
  }
}));
