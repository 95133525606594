import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { setInsertionValue } from "../../business/models/Insertion/insertionActions";

import {
  getInsertionPropertyValue,
  getInsertionPropertyErrorsByProperty,
  selectInsertionDisplayErrors,
  isDisabled
} from "../../business/models/Insertion/insertionSelectors";

// import { classes } from "../../assets/styles/components/field/insertionField";TODO(AM)

import Controls from "./controls/index";

const InsertionField = props => {
  const { component, entityId, name, id, onChange, ...componentProps } = props;
  const dispatch = useDispatch();
  const value = useSelector(state => getInsertionPropertyValue(state, props));
  const errors = useSelector(state =>
    getInsertionPropertyErrorsByProperty(state, props)
  );
  const displayErrors = useSelector(selectInsertionDisplayErrors);
  const disabled = useSelector(state => isDisabled(state, props));

  const handleChange = val =>
    dispatch(setInsertionValue(entityId, { [name]: val }));

  // const KeyboardButtonProps = { classes: { root: classes.root } }; TODO(AM)
  const Control = Controls[component];
  return (
    <Control
      value={value}
      errors={errors}
      displayErrors={displayErrors}
      disabled={disabled}
      component={component}
      variant="standard"
      id={`${entityId}-${id}`}
      name={name}
      {...componentProps}
      onChange={handleChange}
      // KeyboardButtonProps={KeyboardButtonProps}TODO(AM)
    />
  );
};

InsertionField.propTypes = {
  component: PropTypes.oneOf(["text", "date", "decimal"]),
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default InsertionField;
