import { styled, Drawer as MuiDrawer } from "@mui/material";
import { sapphire } from "../../../../assets/styles/colors";
import {
  SIDE_BAR_OPEN_WIDTH,
  SIDE_BAR_CLOSE_WIDTH
} from "../../../../configurations/appConstants";

const PREFIX = "ActionInsertionItemStyles";
export const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerShift: `${PREFIX}-drawerShift`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  drawerIcon: `${PREFIX}-drawerIcon`
};

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    width: theme.typography.pxToRem(SIDE_BAR_CLOSE_WIDTH),
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: sapphire,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden"
  },
  [`& .${classes.drawerShift}`]: {
    width: theme.typography.pxToRem(SIDE_BAR_OPEN_WIDTH)
  },
  [`& .${classes.drawerHeader}`]: {
    paddingLeft: theme.typography.pxToRem(24)
  },
  [`& .${classes.drawerIcon}`]: {
    width: theme.typography.pxToRem(56)
  }
}));
