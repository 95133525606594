import { styled, Button as MuiButton } from "@mui/material";
import { crusta } from "../../../../assets/styles/colors";

const PREFIX = "UserProfileConfirmationDialogStyles";
export const classes = {
  closeButton: `${PREFIX}-closeButton`
};

export const Button = styled(MuiButton)(() => ({
  [`&.${classes.closeButton}`]: {
    color: crusta
  }
}));
