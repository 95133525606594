import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  loadOrder,
  closeDialog,
  approveCondition,
  declineCondition
} from "../../../../business/actions/approvalPageActions";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import {
  selectDialogOpen,
  selectAction,
  selectCurrentCondition
} from "../../../../business/selectors/approvalPageSelectors";
import {
  APPROVE,
  DECLINE
} from "../../../../business/constants/approvalPageConstants";

import ApprovalForm from "./form/ApprovalForm";
import ApproveDialog from "./dialog/ApprovalDialog";

const AdditionalApprovalDetailPage = () => {
  const { orderId, conditionId, agency } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [order, setOrder] = useState({});
  const [comment, setComment] = useState("");

  const loading = useSelector(state => selectIsLoading(state));
  const isDialogOpen = useSelector(state => selectDialogOpen(state));
  const actionName = useSelector(state => selectAction(state));
  const action = useSelector(state => selectAction(state));
  const currentCondition = useSelector(state => selectCurrentCondition(state));

  useEffect(() => {
    dispatch(loadOrder(orderId, conditionId)).then(response => {
      if (response) {
        setOrder(response);
      } else {
        navigate(-1);
      }
    });
  }, [dispatch, orderId, conditionId, navigate]);

  const handleOnAction = useCallback(
    includeAllFromPlan => {
      if (action === APPROVE) {
        dispatch(
          approveCondition({ orderId, conditionId, includeAllFromPlan })
        ).then(() => {
          dispatch(closeDialog());
          navigate(`/${agency}/approval-overview`);
        });
      }

      if (action === DECLINE) {
        dispatch(
          declineCondition({
            orderId,
            conditionId,
            comment,
            includeAllFromPlan
          })
        ).then(() => {
          dispatch(closeDialog());
          navigate(`/${agency}/approval-overview`);
        });
      }
    },
    [dispatch, action, agency, orderId, conditionId, comment, navigate]
  );

  const handleOnCancel = useCallback(() => {
    dispatch(closeDialog());
  }, [dispatch]);

  const handleOnChange = value => {
    setComment(value);
  };

  return (
    order && (
      <>
        <ApprovalForm order={order} isLoading={loading} />
        <ApproveDialog
          condition={currentCondition}
          open={isDialogOpen}
          action={actionName}
          comment={comment}
          isLoading={loading}
          onAction={handleOnAction}
          onCancel={handleOnCancel}
          onChange={handleOnChange}
        />
      </>
    )
  );
};

export default AdditionalApprovalDetailPage;
