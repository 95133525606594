import React from "react";
import clsx from "clsx";

import { classes, Button } from "../styles/ActionTableOnlyMyCampaignsSearch";

const ActionTableOnlyMyCampaignsSearch = ({
  searchMyCampaigns,
  onSearchMyCampaigns
}) => {
  const btnClass = clsx(classes.button, {
    [classes.active]: searchMyCampaigns
  });
  return (
    <Button
      name="search_myCampaigns"
      id="search_myCampaigns"
      className={btnClass}
      onClick={onSearchMyCampaigns}
    >
      My Campaigns
    </Button>
  );
};

export default ActionTableOnlyMyCampaignsSearch;
