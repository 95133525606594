import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { IconButton } from "@mui/material";

import { CopyTextIcon } from "../../assets/styles/icons";
import { PLACEHOLDER } from "../../configurations/appConstants";
import { classes, Grid } from "./styles/clipboardTooltip";

const ClipboardTooltip = ({
  text = PLACEHOLDER,
  propertyId,
  entityId,
  onButtonClick
}) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      wrap="nowrap"
    >
      <Grid item>
        <CopyToClipboard text={text}>
          <IconButton
            name={`clip_${entityId}_${propertyId}`}
            id={`clip_${entityId}_${propertyId}`}
            size="small"
            disableRipple
            className={classes.tooltipCopyButton}
            onClick={onButtonClick}
          >
            <CopyTextIcon />
          </IconButton>
        </CopyToClipboard>
      </Grid>
      <Grid item zeroMinWidth>
        {text}
      </Grid>
    </Grid>
  );
};

ClipboardTooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onButtonClick: PropTypes.func
};

export default ClipboardTooltip;
