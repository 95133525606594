export const SET_API_MANAGEMENT_ITEMS = "SET_API_MANAGEMENT_ITEMS";
export const SET_API_MANAGEMENT_COUNT = "SET_API_MANAGEMENT_COUNT";
export const SET_IS_OPEN_DELETE_PUBLIC_PERMISSION_DIALOG =
  "SET_IS_OPEN_DELETE_PUBLIC_PERMISSION_DIALOG";
export const SET_IS_OPEN_ADD_PUBLIC_PERMISSION_DIALOG =
  "SET_IS_OPEN_ADD_PUBLIC_PERMISSION_DIALOG";
export const SET_SELECTED_PUBLIC_PERMISSION = "SET_SELECTED_PUBLIC_PERMISSION";

export const CREATE_PUBLIC_PERMISSION = "Create resource";
export const DELETE_PUBLIC_PERMISSION_MESSAGE =
  "Are you sure you want to remove permission to resource from application?";

export const rowsPerPageOptions = [5, 10, 25];

export const resourceOptions = [
  { key: "cinema", text: "Cinema" },
  { key: "digital", text: "Digital" },
  { key: "ooh", text: "OOH" },
  { key: "other", text: "Other" },
  { key: "print", text: "Print" },
  { key: "radio", text: "Radio" },
  { key: "tv", text: "TV" },
  { key: "blossom", text: "Blossom" },
  { key: "essencemediacom", text: "EssenceMediacom" },
  { key: "greenhouseone", text: "Greenhouse One" },
  { key: "greenhousetech", text: "Greenhouse Tech" },
  { key: "choreographcreate", text: "Choreograph Create" },
  { key: "groupm", text: "GroupM" },
  { key: "kinetic", text: "Kinetic" },
  { key: "mindshare", text: "Mindshare" },
  { key: "wavemaker", text: "Wavemaker" }
];
