import { Model, attr, fk } from "redux-orm";
import set from "lodash/set";
import omit from "lodash/omit";
import forEach from "lodash/forEach";

import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";

import {
  MANDATORY_RULE,
  DATE_RULE,
  ISO8859_RULE,
  NUMBER_OF_CHARACTERS_RULE,
  MANDATORY_MESSAGE,
  DATE_MESSAGE,
  ISO8859_MESSAGE,
  DECIMAL_RULE,
  DECIMAL_MESSAGE,
  NUMBER_OF_CHARACTERS_MESSAGE_365,
  NUMBER_OF_CHARACTERS_MESSAGE_50,
  NUMBER_OF_CHARACTERS_MESSAGE_200
} from "../../constants/validationConstants";

import { PLAN_MODEL_NAME, PLAN_MODEL_ID } from "../Plan/planConstants";
import { ORDER_MODEL_NAME, ORDER_MODEL_ID } from "./orderConstants";
import {
  INSERTION_MODEL_NAME,
  INSERTIONS_PROPERTY
} from "../Insertion/insertionConstants";

import {
  GET_AGREEMENTS,
  GET_AGREEMENTDETAILS,
  GET_CHANNELS,
  GET_MEDIAS,
  GET_DIGITAL_CHANNELS,
  GET_FORMATS,
  GET_UNITS,
  GET_DISCOUNT_CODES,
  GET_SURCHARGE_CODES,
  GET_CLIENT_STATUS_FOR_CAMPAIGN_PAGE
} from "../../../configurations/apiUrls";

import { getModelClassByType } from "../common/utils/modelUtils";

class Order extends Model {
  static get fields() {
    return {
      id: attr(),
      orderId: attr(),
      marathonOrderId: attr(),
      channelId: attr(),
      titleId: attr(),
      saleshouseId: attr(),
      targetAudience: attr(),
      digitalChannelId: attr(),
      description: attr(),
      formatId: attr(),
      position: attr(),
      frequencyCap: attr(),
      accountId: attr(),
      orderStartDate: attr(),
      orderEndDate: attr(),
      costTypeId: attr(),
      numOfUnits: attr(),
      unit: attr(),
      discountCodeId: attr(),
      discountAmount: attr(),
      netTotal: attr(),
      agencyDiscountAmount: attr(),
      feePercentage: attr(),
      circulationBase: attr(),
      surcharge1TypeId: attr(),
      surcharge1Amount: attr(),
      surcharge2TypeId: attr(),
      surcharge2Amount: attr(),
      orderPoNumber: attr(),
      orderAgreementId: attr(),
      remarks: attr(),
      status: attr(),
      statusId: attr(),
      hasTrafficItems: attr(),
      isReadyForTraffic: attr(),
      modified: attr(),
      canUserDoActions: attr(),
      isInCopyProcess: attr(),
      isCopyTemp: attr(),

      plan: fk(PLAN_MODEL_NAME),

      // Calculation params
      surcharge1Commission: attr(),
      surcharge1Fee: attr(),
      surcharge2Commission: attr(),
      surcharge2Fee: attr(),
      costTypeMultiplier: attr(),

      // Calculations
      grossTotal: attr(),
      grossPerUnit: attr(),
      netPerUnit: attr(),
      netNetTotal: attr(),
      netNetPerUnit: attr(),
      fee: attr(),
      totalCostClient: attr()
    };
  }

  static get defaultAttributes() {
    return {
      numOfUnits: null,
      discountAmount: null,
      netTotal: null,
      surcharge1Amount: 0,
      surcharge2Amount: 0,
      status: 1,
      statusId: 1,
      isCopyTemp: false
    };
  }

  static get inheritConfiguration() {
    return {
      orderPoNumber: "planPoNumber",
      orderAgreementId: "planAgreementId"
    };
  }

  static parse(orderData) {
    if (orderData[ORDER_MODEL_ID]) {
      set(orderData, "id", orderData[ORDER_MODEL_ID]);
    }
    if (orderData[ORDER_MODEL_ID]) {
      set(orderData, "plan", orderData[PLAN_MODEL_ID]);
    }
    if (orderData.statusId) {
      set(orderData, "status", orderData.statusId);
    }

    // Parse Insertion data
    const childProperty = INSERTIONS_PROPERTY;
    const ChildModelClass = getModelClassByType(
      this.session,
      INSERTION_MODEL_NAME
    );
    forEach(orderData[childProperty], entry => {
      ChildModelClass.parse(entry);
    });
    const parsedData = omit(orderData, childProperty);

    return this.upsert(parsedData);
  }

  static updateModel(orderData) {
    if (orderData[ORDER_MODEL_ID]) {
      set(orderData, "id", orderData[ORDER_MODEL_ID]);
    }
    if (orderData[ORDER_MODEL_ID]) {
      set(orderData, "plan", orderData[PLAN_MODEL_ID]);
    }
    if (orderData.statusId) {
      set(orderData, "status", orderData.statusId);
    }

    // Parse Insertion data
    const childProperty = INSERTIONS_PROPERTY;
    const ChildModelClass = getModelClassByType(
      this.session,
      INSERTION_MODEL_NAME
    );
    forEach(orderData[childProperty], entry => {
      ChildModelClass.updateModel(entry);
    });
    const parsedData = omit(orderData, childProperty);

    return this.withId(orderData.id).update(parsedData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...Order.defaultAttributes,
      ...newAttributes
    };
    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherOrder) {
    this.update(otherOrder.ref);
  }

  static get validation() {
    return {
      channelId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      titleId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      orderAgreementId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      description: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      targetAudience: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_200,
          options: {
            min: 0,
            max: 200
          }
        }
      ],
      orderPoNumber: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      orderStartDate: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DATE_RULE,
          message: DATE_MESSAGE
        }
      ],
      orderEndDate: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DATE_RULE,
          message: DATE_MESSAGE
        }
      ],
      position: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      frequencyCap: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      accountId: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      costTypeId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        }
      ],
      numOfUnits: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        }
      ],
      unit: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      discountCodeId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      discountAmount: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      netTotal: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      surcharge1Amount: [
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      surcharge2Amount: [
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      remarks: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_365,
          options: {
            min: 0,
            max: 365
          }
        }
      ],
      statusId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ]
    };
  }

  validate() {
    return validateModel(Order.validation, this.toJSON());
  }

  validateByProperty(name) {
    return validateProperty(Order.validation[name], this.ref[name]);
  }

  hasErrors() {
    return doesModelHaveErrors(Order.validation, this.toJSON());
  }

  static get apiConfiguration() {
    return {
      channelId: {
        url: GET_CHANNELS,
        urlParams: ["mediaTypeId"],
        urlRequiredParams: ["mediaTypeId"]
      },
      titleId: {
        url: GET_MEDIAS,
        urlParams: ["channelId"],
        urlRequiredParams: ["channelId"],
        filter: "[*currency={{currencySupplierId}}]"
      },
      saleshouseId: {
        url: GET_MEDIAS,
        urlParams: ["channelId"],
        urlRequiredParams: ["channelId"],
        path: "[id={{titleId}}].publisherName"
      },
      digitalChannelId: {
        url: GET_DIGITAL_CHANNELS
      },
      formatId: {
        url: GET_FORMATS
      },
      costTypeId: {
        url: GET_UNITS
      },
      discountCodeId: {
        url: GET_DISCOUNT_CODES
      },
      agencyDiscountAmount: {
        url: GET_MEDIAS,
        urlParams: ["channelId"],
        urlRequiredParams: ["channelId"],
        path: "[id={{titleId}}].commissions[type=FORM].value"
      },
      feePercentage: {
        url: GET_AGREEMENTDETAILS,
        urlParams: ["orderAgreementId"],
        urlRequiredParams: ["orderAgreementId"],
        path: "capitalCostFromClient"
      },
      circulationBase: {
        url: GET_AGREEMENTDETAILS,
        urlParams: ["orderAgreementId"],
        urlRequiredParams: ["orderAgreementId"],
        path: "circulationBasis"
      },
      surcharge1TypeId: {
        url: GET_SURCHARGE_CODES
      },
      surcharge2TypeId: {
        url: GET_SURCHARGE_CODES
      },
      orderAgreementId: {
        url: GET_AGREEMENTS,
        urlParams: ["debtorId"],
        urlRequiredParams: ["debtorId"]
      },
      statusId: {
        url: GET_CLIENT_STATUS_FOR_CAMPAIGN_PAGE,
        urlParams: ["status"],
        urlRequiredParams: ["status"]
      },
      surcharge1Commission: {
        url: GET_SURCHARGE_CODES,
        path: "[key={{surcharge1TypeId}}].commission"
      },
      surcharge1Fee: {
        url: GET_SURCHARGE_CODES,
        path: "[key={{surcharge1TypeId}}].fee"
      },
      surcharge2Commission: {
        url: GET_SURCHARGE_CODES,
        path: "[key={{surcharge2TypeId}}].commission"
      },
      surcharge2Fee: {
        url: GET_SURCHARGE_CODES,
        path: "[key={{surcharge2TypeId}}].fee"
      },
      costTypeMultiplier: {
        url: GET_UNITS,
        path: "[key={{costTypeId}}].multiplier"
      }
    };
  }

  static get calculations() {
    return {
      grossTotal: {
        calcMethod: "grosstotal",
        calcParams: ["netTotal", "discountAmount"]
      },
      grossPerUnit: {
        calcMethod: "grossperunit",
        calcParams: [
          "netTotal",
          "discountAmount",
          "numOfUnits",
          "costTypeMultiplier"
        ]
      },
      netPerUnit: {
        calcMethod: "netperunit",
        calcParams: ["netTotal", "numOfUnits", "costTypeMultiplier"]
      },
      netNetTotal: {
        calcMethod: "netnettotal",
        calcParams: ["netTotal", "agencyDiscountAmount"]
      },
      netNetPerUnit: {
        calcMethod: "netnetperunit",
        calcParams: [
          "netTotal",
          "agencyDiscountAmount",
          "numOfUnits",
          "costTypeMultiplier"
        ]
      },
      fee: {
        calcMethod: "fee",
        calcParams: [
          "feePercentage",
          "agencyDiscountAmount",
          "circulationBase",
          "netTotal",
          "surcharge1Amount",
          "surcharge1Commission",
          "surcharge1Fee",
          "surcharge2Amount",
          "surcharge2Commission",
          "surcharge2Fee"
        ]
      },
      totalCostClient: {
        calcMethod: "totalcostclient",
        calcParams: [
          "feePercentage",
          "agencyDiscountAmount",
          "circulationBase",
          "netTotal",
          "surcharge1Amount",
          "surcharge1Commission",
          "surcharge1Fee",
          "surcharge2Amount",
          "surcharge2Commission",
          "surcharge2Fee"
        ]
      }
    };
  }

  static get disabledFields() {
    return {
      channelId: [
        "isOrderApproved",
        "isOrderOrderChange",
        "isOrderChangeNotApproved",
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "hasTrafficItems",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      titleId: [
        "isOrderApproved",
        "isOrderOrderChange",
        "isOrderChangeNotApproved",
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "hasTrafficItems",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      orderAgreementId: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      description: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      targetAudience: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      orderPoNumber: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      digitalChannelId: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      orderStartDate: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      orderEndDate: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      formatId: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      position: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      frequencyCap: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      accountId: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      costTypeId: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      numOfUnits: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      unit: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      discountCodeId: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      discountAmount: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      netTotal: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      surcharge1TypeId: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      surcharge1Amount: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      surcharge2TypeId: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      surcharge2Amount: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      remarks: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      statusId: [
        "isOrderToBeCancelled",
        "isOrderCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ]
    };
  }
}

Order.modelName = ORDER_MODEL_NAME;

export default Order;
