import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { setCampaignValue } from "../../business/models/Campaign/campaignActions";

import {
  getCampaignPropertyValue,
  getCampaignPropertyPreviousValue,
  getCampaignPropertyErrors,
  selectCampaignDisplayErrors,
  getCampaignPropertyOptions,
  isDisabled
} from "../../business/models/Campaign/campaignSelectors";

import Controls from "./controls/index";

const CampaignField = props => {
  const { name, component, ...componentProps } = props;
  const dispatch = useDispatch();

  const value = useSelector(state => getCampaignPropertyValue(state, props));
  const prevValue = useSelector(state =>
    getCampaignPropertyPreviousValue(state, props)
  );
  const errors = useSelector(state => getCampaignPropertyErrors(state, props));
  const displayErrors = useSelector(selectCampaignDisplayErrors);
  const options = useSelector(state =>
    getCampaignPropertyOptions(state, props)
  );
  const disabled = useSelector(state => isDisabled(state, props));

  const handleChange = val => {
    dispatch(setCampaignValue({ [name]: val }));
  };

  const Control = Controls[component];
  return (
    <Control
      component={component}
      {...componentProps}
      value={value}
      prevValue={prevValue}
      errors={errors}
      displayErrors={displayErrors}
      options={options}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

CampaignField.propTypes = {
  component: PropTypes.oneOf(["text", "date", "decimal", "autocomplete"])
    .isRequired,
  name: PropTypes.string.isRequired
};

export default CampaignField;
