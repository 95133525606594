import { createReducer } from "../common/utils/reducerUtils";

import {
  PLAN_CHANGE_OVERVIEW_CLEAR_CURRENT,
  PLAN_CHANGE_OVERVIEW_SELECT,
  PLAN_CHANGE_OVERVIEW_EDIT_START,
  PLAN_CHANGE_OVERVIEW_EDIT_STOP,
  PLAN_CHANGE_OVERVIEW_HIDE_ERRORS,
  PLAN_CHANGE_OVERVIEW_DISPLAY_ERRORS,
  PLAN_CHANGE_OVERVIEW_SET_EDITING_ORIGINAL_ITEM,
  PLAN_CHANGE_OVERVIEW_CLEAR_EDITING_ORIGINAL_ITEM
} from "./planChangeOverviewConstants";

const initialState = {
  currentPlanChangeOverviews: [],
  isEditing: false,
  displayErrors: false,
  editingOriginalItem: null
};

export const selectCurrent = (state, payload) => {
  return {
    ...state,
    currentPlanChangeOverviews: payload.currentPlanChangeOverviews
  };
};

export const startEditing = state => {
  return {
    ...state,
    isEditing: true
  };
};

export const stopEditing = state => {
  return {
    ...state,
    isEditing: false
  };
};

export const displayErrors = state => {
  return {
    ...state,
    displayErrors: true
  };
};

export const hideErrors = state => {
  return {
    ...state,
    displayErrors: false
  };
};

export const setEditingOriginalItem = (state, payload) => {
  return {
    ...state,
    editingOriginalItem: payload
  };
};

export const clearEditingOriginalItem = state => {
  return {
    ...state,
    editingOriginalItem: null
  };
};

export const clearCurrent = () => initialState;

export default createReducer(initialState, {
  [PLAN_CHANGE_OVERVIEW_SELECT]: selectCurrent,
  [PLAN_CHANGE_OVERVIEW_CLEAR_CURRENT]: clearCurrent,
  [PLAN_CHANGE_OVERVIEW_EDIT_START]: startEditing,
  [PLAN_CHANGE_OVERVIEW_EDIT_STOP]: stopEditing,
  [PLAN_CHANGE_OVERVIEW_HIDE_ERRORS]: hideErrors,
  [PLAN_CHANGE_OVERVIEW_DISPLAY_ERRORS]: displayErrors,
  [PLAN_CHANGE_OVERVIEW_SET_EDITING_ORIGINAL_ITEM]: setEditingOriginalItem,
  [PLAN_CHANGE_OVERVIEW_CLEAR_EDITING_ORIGINAL_ITEM]: clearEditingOriginalItem
});
