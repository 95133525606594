import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StringParam, useQueryParams } from "use-query-params";
import {
  loadPublicPermissions,
  setIsOpenDeletePublicPermission,
  setSelectedPublicPermission
} from "../../../business/actions/apiManagementActions";
import {
  selectItems,
  selectCount,
  selectIsOpenDeletePublicPermissionDialog,
  selectPublicPermission,
  selectIsOpenAddPublicPermissionDialog
} from "../../../business/selectors/apiManagementSelectors";
import ApiManagementTable from "./table/ApiManagementTable";
import { selectIsLoading } from "../../../business/selectors/applicationSelectors";
import DeletePublicPermissionDialog from "./dialog/DeletePublicPermissionDialog";
import AddPublicPermissionDialog from "./dialog/AddPublicPermissionDialog";

const filterQuerys = {
  applicationId: StringParam,
  resource: StringParam
};

const ApiManagementPage = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useQueryParams({
    ...filterQuerys,
    page: StringParam,
    pageSize: StringParam
  });

  const { applicationId, resource, page = 0, pageSize = 10 } = query;

  const rows = useSelector(selectItems);
  const count = useSelector(selectCount);
  const isLoading = useSelector(state => selectIsLoading(state));
  const isOpenDeletePublicPermissionDialog = useSelector(
    selectIsOpenDeletePublicPermissionDialog
  );

  const isOpenAddPublicPermissionDialog = useSelector(
    selectIsOpenAddPublicPermissionDialog
  );
  const publicPermission = useSelector(selectPublicPermission);

  const handleChangePage = (_, p) => {
    setQuery({ page: p }, "pushIn");
  };

  const handleChangeRowsPerPage = event => {
    setQuery({ pageSize: event.target.value, page: 0 }, "pushIn");
  };

  const handleDynamicFilter = useCallback(
    filterQuery => {
      setQuery({ ...filterQuery, page: 0 }, "pushIn");
    },
    [setQuery]
  );

  const handleDeletePublicPermission = (appId, res) => {
    dispatch(setSelectedPublicPermission({ appId, res }));
    dispatch(setIsOpenDeletePublicPermission());
  };

  useEffect(() => {
    dispatch(
      loadPublicPermissions({
        applicationId,
        resource,
        page,
        pageSize
      })
    );
  }, [applicationId, resource, page, pageSize, publicPermission, dispatch]);

  return (
    <>
      <ApiManagementTable
        rows={rows}
        count={count}
        pageSize={Number(pageSize)}
        page={Number(page)}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onDynamicFilter={handleDynamicFilter}
        contentLoading={isLoading}
        onDelete={handleDeletePublicPermission}
        queryFilters={{
          applicationId,
          resource
        }}
      />
      {isOpenDeletePublicPermissionDialog && <DeletePublicPermissionDialog />}
      {isOpenAddPublicPermissionDialog && <AddPublicPermissionDialog />}
    </>
  );
};

export default ApiManagementPage;
