import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { Button } from "@mui/material";

import {
  ADD_TEXT,
  EDIT_TEXT
} from "../../../../business/models/PlanChangeOverview/planChangeOverviewConstants";

import { classes, Grid } from "../styles/planChangeOverviewDialogActions";

const PlanChangeOverviewDialogActions = ({
  isNew,
  onAddClick,
  onCancelClick
}) => {
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Button onClick={onCancelClick} className={classes.cancelButton}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={onAddClick}
            className={clsx(classes.saveButton, classes.markedButton)}
          >
            {isNew ? ADD_TEXT : EDIT_TEXT}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

PlanChangeOverviewDialogActions.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired
};

export default PlanChangeOverviewDialogActions;
