import React from "react";
import { useSelector } from "react-redux";
import CreateActions from "./CreateActions";
import CampaignNewActions from "./CampaignNewActions";
import CampaignEditActions from "./CampaignEditActions";
import PlanNewActions from "./PlanNewActions";
import PlanEditActions from "./PlanEditActions";
import PlanExtensionNewActions from "./PlanExtensionNewActions";
import PlanExtensionEditActions from "./PlanExtensionEditActions";
import OrderNewActions from "./OrderNewActions";
import OrderEditActions from "./OrderEditActions";

import { selectCampaignIsNew } from "../../../../business/models/Campaign/campaignSelectors";
import { selectPlanIsNew } from "../../../../business/models/Plan/planSelectors";
import {
  selectPlanExtensionIsNew,
  selectIsPlanExtensionNewFromUpdate
} from "../../../../business/models/PlanExtension/planExtensionSelectors";
import { selectOrderIsNew } from "../../../../business/models/Order/orderSelectors";

import {
  selectIsCampaignFormOpen,
  selectIsPlanFormOpen,
  selectIsPlanExtensionFormOpen,
  selectIsOrderFormOpen
} from "../../../../business/selectors/campaignDashboardSelector";

const CreatePageActions = () => {
  const isCampaignNew = useSelector(selectCampaignIsNew);
  const isPlanNew = useSelector(selectPlanIsNew);
  const isPlanExtensionNew = useSelector(selectPlanExtensionIsNew);
  const isPlanExtensionNewFromUpdate = useSelector(
    selectIsPlanExtensionNewFromUpdate
  );
  const isOrderNew = useSelector(selectOrderIsNew);
  const isCampaignFormOpen = useSelector(selectIsCampaignFormOpen);
  const isPlanFormOpen = useSelector(selectIsPlanFormOpen);
  const isPlanExtensionFormOpen = useSelector(selectIsPlanExtensionFormOpen);
  const isOrderFormOpen = useSelector(selectIsOrderFormOpen);

  return (
    <>
      {!isCampaignFormOpen &&
        !isPlanFormOpen &&
        !isPlanExtensionFormOpen &&
        !isOrderFormOpen && <CreateActions />}
      {isCampaignFormOpen && isCampaignNew && <CampaignNewActions />}
      {isCampaignFormOpen && !isCampaignNew && <CampaignEditActions />}
      {isPlanFormOpen && isPlanNew && <PlanNewActions />}
      {isPlanFormOpen && !isPlanNew && <PlanEditActions />}
      {isPlanExtensionFormOpen &&
        isPlanExtensionNew &&
        !isPlanExtensionNewFromUpdate && <PlanExtensionNewActions />}
      {isPlanExtensionFormOpen &&
        (!isPlanExtensionNew || isPlanExtensionNewFromUpdate) && (
          <PlanExtensionEditActions />
        )}
      {isOrderFormOpen && isOrderNew && <OrderNewActions />}
      {isOrderFormOpen && !isOrderNew && <OrderEditActions />}
    </>
  );
};

export default CreatePageActions;
