import React from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { TextField } from "@mui/material";
import { classes, DatePicker } from "../styles/dateField";
import { EMPTY_STRING } from "../../../configurations/appConstants";
import { solitude } from "../../../assets/styles/colors";

const hiddenIconProps = { disabled: true, style: { display: "none" } };

const DateField = ({
  onChange,
  id,
  variant,
  label,
  disabled,
  value,
  displayErrors,
  errors,
  className,
  helperText,
  formHelperTextProps,
  InputProps,
  inputLabelProps,
  placeholder,
  KeyboardButtonProps,
  hideIcon
}) => {
  const handleChange = date => {
    if (typeof onChange === "function") {
      const val = date ? moment(date).format("YYYY-MM-DD") : null;
      onChange(val);
    }
  };

  const dateValue = value ? moment(value, "YYYY-MM-DD") : null;
  const isValid = errors.length === 0;
  const errorText = errors[0];
  const keyboardButtonProps = hideIcon
    ? { ...KeyboardButtonProps, ...hiddenIconProps }
    : KeyboardButtonProps;
  return (
    <DatePicker
      id={id}
      inputFormat="dd-MM-yyyy"
      mask="__-__-____"
      label={label}
      disabled={disabled}
      value={dateValue}
      clearable
      InputAdornmentProps={{
        position: "end",
        sx: { paddingRight: "11px" },
        ...keyboardButtonProps
      }}
      InputProps={{
        ...InputProps,
        sx: { background: disabled && solitude }
      }}
      onChange={handleChange}
      className={className}
      renderInput={({ inputProps, ...props }) => (
        <TextField
          {...props}
          id={id}
          required
          helperText={
            (displayErrors && !isValid ? errorText : "") || helperText
          }
          error={displayErrors && !isValid}
          fullWidth
          variant={variant}
          inputProps={{
            ...inputProps,
            placeholder: EMPTY_STRING
          }}
          InputLabelProps={{ required: false }}
        />
      )}
    />
  );
};

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["standard", "outlined"]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  displayErrors: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  keyboard: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  KeyboardButtonProps: PropTypes.object,
  hideIcon: PropTypes.bool
};

DateField.defaultProps = {
  variant: "outlined",
  errors: [],
  disabled: false,
  displayErrors: false,
  keyboard: true,
  label: null,
  placeholder: "DD-MM-YYYY",
  hideIcon: false,
  KeyboardButtonProps: {}
};

export default DateField;
