import { createReducer } from "../models/common/utils/reducerUtils";

import {
  SET_COUNT,
  SET_TRAFFIC_PAGE_ITEMS,
  RESET_TRAFFIC_OVERVIEW_PAGE,
  SET_TRAFFIC_OVERVIEW_TAB_COUNTERS,
  UPDATE_TRAFFICKER,
  REMOVE_ITEM
} from "../constants/trafficPageConstants";

const initialState = {
  tabCounters: {
    newCount: 0,
    archivedCount: 0,
    assignedCount: 0
  },

  table: {
    count: 0,
    items: []
  }
};

export const setCount = (state, payload) => ({
  ...state,
  table: { ...state.table, count: payload }
});

export const setItems = (state, payload) => ({
  ...state,
  table: { ...state.table, items: payload }
});

export const resetPage = () => initialState;

export const setTabCounters = (state, payload) => ({
  ...state,
  tabCounters: { ...payload }
});

export const removeItem = (state, payload) => ({
  ...state,
  table: {
    ...state.table,
    items: state.table.items.filter(
      item => item.trafficPlanId !== payload.trafficPlanId
    )
  }
});

export const updateTrafficker = (state, payload) => ({
  ...state,
  table: {
    ...state.table,
    items: state.table.items.map(item => {
      if (item.trafficPlanId === payload.trafficPlanId) {
        return {
          ...item,
          trafficker: payload.trafficker
        };
      }
      return item;
    })
  }
});

export default createReducer(initialState, {
  [SET_COUNT]: setCount,
  [SET_TRAFFIC_PAGE_ITEMS]: setItems,
  [REMOVE_ITEM]: removeItem,

  [UPDATE_TRAFFICKER]: updateTrafficker,
  [RESET_TRAFFIC_OVERVIEW_PAGE]: resetPage,
  [SET_TRAFFIC_OVERVIEW_TAB_COUNTERS]: setTabCounters
});
