import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogContentText
} from "@mui/material";

import { toggleAgreementConfirmPopup } from "../../../../business/actions/applicationActions";
import { selectOpenAgreementConfirmPopup } from "../../../../business/selectors/applicationSelectors";

class ConfirmAgreementDialog extends Component {
  handleOk = () => {
    if (typeof this.props.onOk === "function") {
      this.props.onOk();
    }
    this.props.toggle();
  };

  handleCancel = () => {
    this.props.toggle();
  };

  render() {
    const { open } = this.props;
    const paperProps = { square: true };
    return (
      <Dialog open={open} PaperProps={paperProps} maxWidth="md">
        <DialogContent>
          <DialogContentText noWrap={false}>
            You are going to change an agreement for an invoiced order. This
            might have financial impact. Please follow the agreed instructions.
            Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleOk} color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  open: selectOpenAgreementConfirmPopup(state)
});

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(toggleAgreementConfirmPopup())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmAgreementDialog);
