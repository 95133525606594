/* eslint-disable no-alert, no-console */
import f from "./fetch";
import { version } from "../configurations/version";
import { logOutAndClearStorage } from "./authUtils.ts";

const serverVersionGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class api {
  static checkVersion() {
    fetch("/meta.json", { cache: "no-store" })
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = version;

        const shouldForceRefresh = serverVersionGreaterThan(
          latestVersion,
          currentVersion
        );

        if (shouldForceRefresh) {
          alert(`We have a new version - ${latestVersion}. Please login again`);

          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches
              .keys()
              .then(names =>
                Promise.all(names.forEach(name => caches.delete(name)))
              );
          }

          // Delete browser cache and hard reload
          window.location.reload(true);

          console.log("Logout user...");

          logOutAndClearStorage();
        }
      });
  }

  static get(endpoint) {
    this.checkVersion();

    return new Promise((resolve, reject) => {
      f(endpoint, null)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  static post(endpoint, body = {}) {
    return new Promise((resolve, reject) => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(body)
      };
      f(endpoint, options)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  static put(endpoint, body = {}) {
    return new Promise((resolve, reject) => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify(body)
      };
      f(endpoint, options)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  static delete(endpoint, body = {}) {
    return new Promise((resolve, reject) => {
      const options = {
        method: "DELETE",
        body: JSON.stringify(body)
      };
      f(endpoint, options)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
}

export default api;
