import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import clsx from "clsx";
import NumberFormat from "react-number-format";
import toUpper from "lodash/toUpper";
import toLower from "lodash/toLower";

import { Typography, Tooltip } from "@mui/material";

import { MediaTypeIcon } from "../../../../../../assets/styles/icons";
import ClipboardTooltip from "../../../../../shared/ClipboardTooltip";
import CtcButtonWrapperPlan from "../../../../../shared/ctc/CtcButtonWrapperPlan";
import { isNullOrUndefined } from "../../../../../../functions/util";

import { classes, Grid } from "../styles/actionPlanItemCompact";
import { getPropertyTextFromApiData } from "../../../../../../business/models/Plan/planSelectors";
import { PLACEHOLDER } from "../../../../../../configurations/appConstants";

const ActionPlanItemCompact = ({
  currencySupplierSymbol,
  marathonPlanId,
  totalBudget,
  campaignId,
  planName,
  planId
}) => {
  const mediaType =
    useSelector(state =>
      getPropertyTextFromApiData(state, { name: "mediaTypeId", planId })
    ) || PLACEHOLDER;

  const handleCopy = event => {
    event.stopPropagation();
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={7}
        className={classes.infoGridItem}
      >
        <Grid container alignItems="center">
          <Grid item>
            <MediaTypeIcon type={toLower(mediaType)} />
          </Grid>
          <Grid item>
            <Typography className={classes.typographyPlanHeaderCaption}>
              {toUpper(mediaType)}
            </Typography>
          </Grid>
          <Grid item xs={12} zeroMinWidth>
            <Tooltip
              title={
                <ClipboardTooltip onButtonClick={handleCopy} text={planName} />
              }
              placement="bottom-start"
            >
              <Typography
                id="planName"
                name="planName"
                className={classes.typographyPlanHeaderValue}
                noWrap
              >
                {planName}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item>
            <Tooltip
              disableFocusListener={isNullOrUndefined(marathonPlanId)}
              disableHoverListener={isNullOrUndefined(marathonPlanId)}
              disableTouchListener={isNullOrUndefined(marathonPlanId)}
              title={
                <ClipboardTooltip
                  onButtonClick={handleCopy}
                  text={marathonPlanId}
                />
              }
              placement="bottom-start"
            >
              <Typography
                id="marathonPlanId"
                name="marathonPlanId"
                align="right"
                className={classes.typographyValue}
              >
                {marathonPlanId}
              </Typography>
            </Tooltip>
            <Typography
              align="right"
              className={clsx(
                classes.typographyCaption,
                classes.planIdGridItem
              )}
            >
              Plan ID
            </Typography>
          </Grid>
          <Grid
            item
            className={clsx(
              classes.calcInfoGridItem,
              classes.ctcInfoGridItemAlign
            )}
          >
            <Typography
              align="right"
              className={clsx(
                classes.typographyValue,
                classes.typographyMarked
              )}
            >
              <NumberFormat
                id="totalBudget"
                name="totalBudget"
                value={totalBudget}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={`${currencySupplierSymbol} `}
                fixedDecimalScale
              />
            </Typography>
            <CtcButtonWrapperPlan campaignId={campaignId} planId={planId} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ActionPlanItemCompact.propTypes = {
  currencySupplierSymbol: PropTypes.string,
  marathonPlanId: PropTypes.number,
  totalBudget: PropTypes.number,
  campaignId: PropTypes.number,
  planName: PropTypes.string,
  planId: PropTypes.number
};

export default ActionPlanItemCompact;
