import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid, Alert } from "@mui/material";

import PlanField from "../../../form/PlanField";
import PlanRateField from "../../../form/PlanRateField";
import PlanSidePanel from "./extensions/PlanSidePanel";
import CurrencyClientDialog from "../popup/CurrencyClientDialog";
import CurrencySupplierDialog from "../popup/CurrencySupplierDialog";
import CurrencyAdornment from "./extensions/CurrencyAdornment";

import { classes, Div, PlanFieldDate } from "../styles/planForm";
import { getClientCreditResult } from "../../../../business/models/Campaign/campaignActions";
import { getClientCreditMessage } from "../../../../business/models/Campaign/campaignSelectors";
import { isInventoryMediaTypeUser } from "../../../../business/selectors/userProfileSelectors";

const PlanForm = () => {
  const dispatch = useDispatch();

  const [openClient, setClientDialog] = useState(false);
  const [openSupplier, setSupplierDialog] = useState(false);
  const clientCreditMessage = useSelector(state =>
    getClientCreditMessage(state)
  );
  const isInventoryUser = useSelector(isInventoryMediaTypeUser);

  const handleClientOk = () => setClientDialog(false);
  const handleSupplierOk = () => setSupplierDialog(false);
  const handleOpenClient = () => setClientDialog(true);
  const handleOpenSupplier = () => setSupplierDialog(true);

  useEffect(() => {
    dispatch(getClientCreditResult());
  }, [dispatch]);

  return (
    <Div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.detailsLayout}>
          <Alert severity="info">{clientCreditMessage}</Alert>
        </Grid>
        <Grid item xs={4} className={classes.detailsLayout}>
          <PlanSidePanel />
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <PlanField
                component="text"
                id="planName"
                name="planName"
                label="Plan name *"
                maxLength={50}
              />
            </Grid>
            <Grid item xs={2}>
              <PlanField
                component="text"
                id="marathonPlanId"
                name="marathonPlanId"
                label="Plan ID"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <PlanField
                component="autocomplete"
                id="mediaTypeId"
                name="mediaTypeId"
                label="Media *"
                isInventoryUser={isInventoryUser}
              />
            </Grid>
            <Grid item xs={6}>
              <PlanField
                component="autocomplete"
                id="planAgreementId"
                name="planAgreementId"
                label="Agreement *"
              />
            </Grid>
            <Grid item xs={6}>
              <PlanField
                component="decimal"
                id="totalBudget"
                name="totalBudget"
                label="Total budget"
              />
            </Grid>
            <Grid item xs={6}>
              <PlanFieldDate
                id="invoiceDate"
                name="invoiceDate"
                label="Invoice date"
              />
            </Grid>
            <Grid item xs={6}>
              <PlanField
                component="text"
                id="currencyClient"
                name="currencyClient"
                label="Currency Client"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <PlanRateField
                component="decimal"
                id="exchangeRateClient"
                name="exchangeRateClient"
                label="Exchange rate client *"
                InputProps={{
                  endAdornment: <CurrencyAdornment onClick={handleOpenClient} />
                }}
                decimalScale={4}
              />
            </Grid>
            <Grid item xs={6}>
              <PlanField
                component="autocomplete"
                id="currencySupplierId"
                name="currencySupplierId"
                label="Currency supplier *"
              />
            </Grid>
            <Grid item xs={6}>
              <PlanRateField
                component="decimal"
                id="exchangeRate"
                name="exchangeRate"
                label="Exchange rate supplier *"
                InputProps={{
                  endAdornment: (
                    <CurrencyAdornment onClick={handleOpenSupplier} />
                  )
                }}
                decimalScale={4}
              />
            </Grid>
            <Grid item xs={6}>
              <PlanField
                component="text"
                id="planPoNumber"
                name="planPoNumber"
                label="PO number"
                maxLength={50}
              />
            </Grid>
            <Grid item xs={6}>
              <PlanField
                component="autocomplete"
                id="plannerId"
                name="plannerId"
                label="Planner *"
              />
            </Grid>
            <Grid item xs={12}>
              <PlanField
                component="autocomplete"
                id="marathonProjectId"
                name="marathonProjectId"
                label="Project"
              />
            </Grid>
            <Grid item xs={12}>
              <PlanField
                component="text"
                id="planRemarks"
                name="planRemarks"
                label="Remarks"
                maxLength={500}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CurrencyClientDialog open={openClient} onOk={handleClientOk} />
      <CurrencySupplierDialog open={openSupplier} onOk={handleSupplierOk} />
    </Div>
  );
};

export default PlanForm;
