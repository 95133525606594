import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import {
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid,
  Alert
} from "@mui/material";

import {
  getCampaignData,
  getClientCreditMessage,
  selectCurrentCampaign
} from "../../business/models/Campaign/campaignSelectors";

import CampaignCompact from "./extensions/CampaignCompact";
import CampaignFoldActions from "./extensions/CampaignFoldActions";
import CampaignFold from "./extensions/CampaignFold";
import { classes, Accordion } from "./styles/item";
import { getClientCreditResult } from "../../business/models/Campaign/campaignActions";

const CampaignPanel = () => {
  const currentCampaign = useSelector(selectCurrentCampaign);
  const dispatch = useDispatch();
  const clientCreditMessage = useSelector(state =>
    getClientCreditMessage(state)
  );

  const {
    id,
    debtor,
    campaignName,
    startDate,
    endDate,
    poBudget,
    productName,
    productId,
    processManager,
    poNumber,
    marathonDebtorId,
    debtorContactPersonEmail,
    marathonCampaignId,
    agreementName,
    campaignStatus,
    numberOfPlans,
    numberOfOrders,
    isActual,
    isFavourite,
    isInCopyProcess,
    isDebtorActive
  } = useSelector(getCampaignData) || {};

  const isActive = currentCampaign === id;

  useEffect(() => {
    dispatch(getClientCreditResult());
  }, [dispatch, id]);

  return (
    <>
      {isDebtorActive === false && (
        <Alert severity="warning">
          {`READ ONLY - Debtor "${debtor}" is inactive!`}
        </Alert>
      )}
      <Accordion
        square
        elevation={0}
        className={clsx(classes.expensionPanel, {
          [classes.selected]: isActive,
          [classes.copyProcess]: isInCopyProcess
        })}
        expanded={isActive}
      >
        <Grid item className={classes.detailsLayout}>
          <Alert severity="info">{clientCreditMessage}</Alert>
        </Grid>
        <AccordionSummary>
          <CampaignCompact
            id={id}
            campaignStatus={campaignStatus}
            debtor={debtor}
            campaignName={campaignName}
            isFavourite={isFavourite}
            marathonCampaignId={marathonCampaignId}
            endDate={endDate}
            startDate={startDate}
            numberOfOrders={numberOfOrders}
            numberOfPlans={numberOfPlans}
            poBudget={poBudget}
          />
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <Grid container justifyContent="space-between">
            <Grid item>
              <CampaignFoldActions
                id={id}
                isActual={isActual}
                isInCopyProcess={isInCopyProcess}
              />
            </Grid>
            <Grid item>
              <CampaignFold
                id={id}
                product={productName}
                productId={productId}
                processManager={processManager}
                poNumber={poNumber}
                marathonDebtorId={marathonDebtorId}
                debtorContactPersonEmail={debtorContactPersonEmail}
                agreement={agreementName}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CampaignPanel;
