import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, ListItem } from "@mui/material";

import InsertionForm from "../../forms/InsertionForm";
import CalculationForm from "../../forms/CalculationForm";

import { getEditingInsertionsIDs } from "../../../../../business/models/Insertion/insertionSelectors";
import { deleteInsertion } from "../../../../../business/models/Insertion/insertionActions";
import { selectIsLoading } from "../../../../../business/selectors/applicationSelectors";
import {
  isOrderCancelledOrToBeCancelled,
  isOrderPendingForApprovalByID
} from "../../../../../business/models/Order/orderSelectors";

import { classes, List } from "../../styles/tablePanel";

const InsertionTable = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const urlOrderId = parseInt(orderId, 10);

  const editingInsertionsIDs = useSelector(getEditingInsertionsIDs);
  const isCancellerOrToBeCancelled = useSelector(
    isOrderCancelledOrToBeCancelled
  );
  const isPendingForApproval = useSelector(state =>
    isOrderPendingForApprovalByID(state, urlOrderId)
  );
  const disableButton = isCancellerOrToBeCancelled || isPendingForApproval;
  const isLoading = useSelector(selectIsLoading);

  const handleDelete = id => {
    dispatch(deleteInsertion(id));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <List className={classes.table}>
          {editingInsertionsIDs.map((insertionId, index) => (
            <ListItem key={insertionId} className={classes.tableRow}>
              <InsertionForm
                entityId={insertionId}
                onDelete={handleDelete}
                disableDelete={disableButton || index === 0}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <CalculationForm isLoading={isLoading} />
      </Grid>
    </Grid>
  );
};

export default InsertionTable;
