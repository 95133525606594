import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { Tabs, Tab } from "@mui/material";
import queryString from "query-string";

import { loadTabcounters } from "../../../../business/actions/overviewPageActions";
import { selectTabCounters } from "../../../../business/selectors/overviewPageSelectors";

import {
  OVERVIEW_PAGE,
  CAMPAIGN_ALL_STATUS,
  CAMPAIGN_ARCHIVED_STATUS,
  CAMPAIGN_FAVOURITES
} from "../../../../configurations/appConstants";
import { selectAgencyId } from "../../../../business/models/common/utils/appUtils";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";

const CampaignStatusActions = () => {
  const tabCounters = useSelector(selectTabCounters);

  const dispatch = useDispatch();

  const { campaignStatus: status, agency } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const agencyId = selectAgencyId(agency);
  const isLoading = useSelector(selectIsLoading);

  const redirectToNewTab = value => {
    const query = queryString.parse(location.search);
    query.currentPage = 0;

    navigate({
      pathname: `/${agency}/${OVERVIEW_PAGE}/${value}`,
      search: queryString.stringify(query)
    });
  };

  useEffect(() => {
    dispatch(loadTabcounters(agencyId));
  }, [agencyId, dispatch]);

  const handleChange = (e, value) => {
    e.stopPropagation();

    if (value !== status) redirectToNewTab(value);
  };

  const { archivedCount, allCount, favouriteCount } = tabCounters;

  return (
    <Tabs
      value={status}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab
        disabled={isLoading}
        label={`Favourites (${favouriteCount})`}
        value={CAMPAIGN_FAVOURITES.toLowerCase()}
      />
      <Tab
        disabled={isLoading}
        label={`All (${allCount})`}
        value={CAMPAIGN_ALL_STATUS.toLowerCase()}
      />
      <Tab
        disabled={isLoading}
        label={`Archived (${archivedCount})`}
        value={CAMPAIGN_ARCHIVED_STATUS.toLowerCase()}
      />
    </Tabs>
  );
};

export default CampaignStatusActions;
