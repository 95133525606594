import React, { memo } from "react";
import { Typography, Tooltip } from "@mui/material";

import clsx from "clsx";
import {
  PLACEHOLDER,
  EMPTY_STRING
} from "../../../configurations/appConstants";
import { classes, Grid } from "../styles/trafficPlanView";

const TrafficPlanView = ({
  creativeAgency,
  contact,
  email,
  cc,
  contactGroupM,
  phoneNumberGroupM,
  isDigital,
  trafficRequestText,
  adManagementOrderID,
  adServerSeat,
  thirdPartyMeasurementText,
  planner,
  isCreativeAgencyVisible,
  isContactVisible,
  isEmailVisible,
  isContactGroupMVisible,
  isCCVisible,
  isPhoneNumberGroupMVisible
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item className={classes.trafficViewItem} zeroMinWidth>
            <Tooltip
              title={planner || EMPTY_STRING}
              id="show_planner"
              name="show_planner"
            >
              <Typography
                align="left"
                className={classes.typographyDetailsValue}
                id="planner"
                name="planner"
                noWrap
              >
                {planner || PLACEHOLDER}
              </Typography>
            </Tooltip>
            <Typography
              align="left"
              className={clsx(classes.typographyCaption)}
            >
              Planner
            </Typography>
          </Grid>
          {isCreativeAgencyVisible && (
            <Grid item className={classes.trafficViewItem} zeroMinWidth>
              <Tooltip
                title={creativeAgency || EMPTY_STRING}
                id="show_creativeAgency"
                name="show_creativeAgency"
              >
                <Typography
                  align="left"
                  className={classes.typographyDetailsValue}
                  noWrap
                  id="creativeAgency"
                  name="creativeAgency"
                >
                  {creativeAgency || PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Typography
                align="left"
                className={clsx(
                  classes.typographyCaption,
                  classes.typographyCaptionBold
                )}
              >
                Creative Agency
              </Typography>
            </Grid>
          )}
          {isContactVisible && (
            <Grid item className={classes.trafficViewItem} zeroMinWidth>
              <Tooltip
                title={contact || EMPTY_STRING}
                id="show_contact"
                name="show_contact"
              >
                <Typography
                  align="left"
                  className={classes.typographyDetailsValue}
                  noWrap
                  id="contact"
                  name="contact"
                >
                  {contact || PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Typography
                align="left"
                className={clsx(
                  classes.typographyCaption,
                  classes.typographyCaptionBold
                )}
              >
                Contact
              </Typography>
            </Grid>
          )}
          {isEmailVisible && (
            <Grid item className={classes.trafficViewItem} zeroMinWidth>
              <Tooltip
                title={email || EMPTY_STRING}
                id="show_email"
                name="show_email"
              >
                <Typography
                  align="left"
                  className={classes.typographyDetailsValue}
                  noWrap
                  id="email"
                  name="email"
                >
                  {email || PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Typography
                align="left"
                className={clsx(
                  classes.typographyCaption,
                  classes.typographyCaptionBold
                )}
              >
                Email
              </Typography>
            </Grid>
          )}
          {isCCVisible && (
            <Grid item className={classes.trafficViewItem} zeroMinWidth>
              <Tooltip title={cc || EMPTY_STRING} id="show_cc" name="show_cc">
                <Typography
                  align="left"
                  className={classes.typographyDetailsValue}
                  id="cc"
                  name="cc"
                  noWrap
                >
                  {cc || PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Typography
                align="left"
                className={clsx(
                  classes.typographyCaption,
                  classes.typographyCaptionBold
                )}
              >
                CC
              </Typography>
            </Grid>
          )}
          {isContactGroupMVisible && (
            <Grid item className={classes.trafficViewItem} zeroMinWidth>
              <Tooltip
                title={contactGroupM || EMPTY_STRING}
                id="show_contactGroupM"
                name="show_contactGroupM"
              >
                <Typography
                  align="left"
                  className={classes.typographyDetailsValue}
                  id="contactGroupM"
                  name="contactGroupM"
                  noWrap
                >
                  {contactGroupM || PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Typography align="left" className={classes.typographyCaption}>
                Contact GroupM
              </Typography>
            </Grid>
          )}
          {isPhoneNumberGroupMVisible && (
            <Grid item className={classes.trafficViewItem} zeroMinWidth>
              <Tooltip
                title={phoneNumberGroupM || EMPTY_STRING}
                id="show_phoneNumberGroupM"
                name="show_phoneNumberGroupM"
              >
                <Typography
                  align="left"
                  className={classes.typographyDetailsValue}
                  id="phoneNumberGroupM"
                  name="phoneNumberGroupM"
                  noWrap
                >
                  {phoneNumberGroupM || PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Typography align="left" className={classes.typographyCaption}>
                Phone GroupM
              </Typography>
            </Grid>
          )}
        </Grid>
        {isDigital && (
          <Grid
            container
            spacing={3}
            justifyContent="flex-end"
            className={classes.digitalTrafficDetails}
          >
            <Grid item className={classes.trafficViewItem} zeroMinWidth>
              <Tooltip
                title={trafficRequestText || EMPTY_STRING}
                id="show_trafficRequestText"
                name="show_trafficRequestText"
              >
                <Typography
                  align="left"
                  className={classes.typographyDetailsValue}
                  id="trafficRequestText"
                  name="trafficRequestText"
                  noWrap
                >
                  {trafficRequestText || PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Typography
                align="left"
                className={clsx(
                  classes.typographyCaption,
                  classes.typographyCaptionBold
                )}
              >
                Traffic Request
              </Typography>
            </Grid>
            <Grid item className={classes.trafficViewItem} zeroMinWidth>
              <Tooltip
                title={thirdPartyMeasurementText || EMPTY_STRING}
                id="show_thirdPartyMeasurementText"
                name="show_thirdPartyMeasurementText"
              >
                <Typography
                  align="left"
                  className={classes.typographyDetailsValue}
                  noWrap
                  id="thirdPartyMeasurementText"
                  name="thirdPartyMeasurementText"
                >
                  {thirdPartyMeasurementText || PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Typography
                align="left"
                className={clsx(
                  classes.typographyCaption,
                  classes.typographyCaptionBold
                )}
              >
                Third Party Measurement
              </Typography>
            </Grid>
            <Grid item className={classes.trafficViewItem} zeroMinWidth>
              <Tooltip
                title={adManagementOrderID || EMPTY_STRING}
                id="show_admanagementOrderId"
                name="show_admanagementOrderId"
              >
                <Typography
                  align="left"
                  className={classes.typographyDetailsValue}
                  id="admanagementOrderId"
                  name="admanagementOrderId"
                  noWrap
                >
                  {adManagementOrderID || PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Typography
                align="left"
                className={clsx(
                  classes.typographyCaption,
                  classes.typographyCaptionBold
                )}
              >
                Admanagement OrderID
              </Typography>
            </Grid>
            <Grid item className={classes.trafficViewItem} zeroMinWidth>
              <Tooltip
                title={adServerSeat || EMPTY_STRING}
                id="show_adServerSeat"
                name="show_adServerSeat"
              >
                <Typography
                  align="left"
                  className={classes.typographyDetailsValue}
                  id="adServerSeat"
                  name="adServerSeat"
                  noWrap
                >
                  {adServerSeat || PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Typography align="left" className={classes.typographyCaption}>
                Adserver seat
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(TrafficPlanView);
