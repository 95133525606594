import {
  START_UNINTERRUPTIBLE_PROCESS,
  API_CALL_START,
  API_CALL_SUCCESS,
  API_CALL_SUCCESS_NOTIFY,
  API_CALL_ERROR,
  API_CALL_ERROR_MSG,
  APPLICATION_ERROR,
  REMOVE_ERROR,
  REMOVE_NOTIFICATION,
  TOGGLE_INSERTION_POPUP,
  TOGGLE_CAMPAIGN_CONFIRM_POPUP,
  TOGGLE_PLAN_CONFIRM_POPUP,
  TOGGLE_ORDER_CONFIRM_POPUP,
  TOGGLE_MBS_CONFIRM_POPUP,
  TOGGLE_AGREEMENT_CONFIRM_POPUP,
  CLEAR_ACTION,
  SET_ACTION,
  CLEAR_AGENCY,
  SET_AGENCY,
  OPEN_ORDER_STATUS_CHANGE_DIALOG,
  CLOSE_ORDER_STATUS_CHANGE_DIALOG,
  SET_ORDER_STATUS_CHANGE_DIALOG_TITLE,
  OPEN_TRAFFIC_ITEM_DELETE_DIALOG,
  CLOSE_TRAFFIC_ITEM_DELETE_DIALOG,
  OPEN_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG,
  CLOSE_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG,
  OPEN_ADD_TRAFFIC_DIALOG,
  OPEN_ADD_ALL_TRAFFIC_ITEMS_DIALOG,
  CLOSE_ADD_TRAFFIC_DIALOG,
  CLOSE_ADD_ALL_TRAFFIC_ITEMS_DIALOG,
  TOGGLE_ORDERS_ACTION,
  OPEN_DIVIDE_ORDERS_DIALOG,
  CLOSE_DIVIDE_ORDERS_DIALOG,
  OPEN_SYSTEM_MESSAGE_DIALOG,
  CLOSE_SYSTEM_MESSAGE_DIALOG,
  TOGGLE_TRAFFIC_ITEM_ACTION,
  OPEN_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG,
  CLOSE_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG,
  OPEN_SYSTEM_NOTIFICATION_DIALOG,
  CLOSE_SYSTEM_NOTIFICATION_DIALOG,
  TOGGLE_DRAWER,
  OPEN_EDIT_TRAFFICKER_DIALOG,
  CLOSE_EDIT_TRAFFICKER_DIALOG,
  OPEN_TRAFFIC_EXPORT_VERSION_DIALOG,
  CLOSE_TRAFFIC_EXPORT_VERSION_DIALOG,
  OPEN_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG,
  CLOSE_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG,
  API_CALL_TRAFFIC_DASHBOARD_START,
  API_CALL_TRAFFIC_DASHBOARD_SUCCESS,
  API_CALL_TRAFFIC_DASHBOARD_ERROR,
  RESET_TRAFFIC_ITEM_ACTION,
  SET_REFETCH,
  RESET_REFETCH,
  TOGGLE_MEDIA_TYPE_CONFIRM_POPUP,
  RESET_UNINTERRUPTIBLE_PROCESS,
  EDIT_AGENCY_RULE,
  SET_CHECK_ALL_ORDERS
} from "../constants/applicationConstants";
import { EMPTY_STRING } from "../../configurations/appConstants";

const initialState = {
  uninterruptibleProcess: false,
  errors: [],
  notifications: [],
  isLoading: false,
  orderStatusChangeDialogTitle: EMPTY_STRING,
  action: EMPTY_STRING,
  agency: null,
  ordersActionSwitch: false,
  hasEditTraffickerDialogOpened: false,
  trafficItemActionSwitch: false,
  openInsertionPopup: false,
  openCampaignConfirmPopup: false,
  openPlanConfirmPopup: false,
  openOrderConfirmPopup: false,
  openMbsConfirmPopup: false,
  openAgreementConfirmPopup: false,
  openOrderStatusChangeDialog: false,
  openTrafficItemDeleteDialog: false,
  openTrafficItemStatusChangeDialog: false,
  openAddTrafficDialog: false,
  openAddAllTrafficItemsDialog: false,
  openDivideOrdersDialog: false,
  openSystemMessageDialog: false,
  openTrafficItemMultipleEditDialog: false,
  openSysNotificationDialog: false,
  openDrawer: false,
  openTrafficExportVersionDialog: false,
  openTrafficExportDigitalVersionDialog: false,
  isTrafficDashboardLoading: false,
  refetch: false,
  openMediaTypeConfirmPopup: false,
  openSaveSearchDialog: false,
  canEditAgencyRules: false,
  checkAllOrders: false
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    // TODO: Related to copy plan => Need to move this to copy plan actions
    case START_UNINTERRUPTIBLE_PROCESS:
      return {
        ...state,
        uninterruptibleProcess: true
      };
    // TODO: Related to copy plan => Need to move this to copy plan actions
    case RESET_UNINTERRUPTIBLE_PROCESS:
      return {
        ...state,
        uninterruptibleProcess: false
      };
    case API_CALL_START:
      return {
        ...state,
        isLoading: true
      };
    case API_CALL_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case API_CALL_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case API_CALL_TRAFFIC_DASHBOARD_START:
      return {
        ...state,
        isTrafficDashboardLoading: true
      };
    case API_CALL_TRAFFIC_DASHBOARD_SUCCESS:
      return {
        ...state,
        isTrafficDashboardLoading: false
      };
    case API_CALL_TRAFFIC_DASHBOARD_ERROR:
      return {
        ...state,
        isTrafficDashboardLoading: false
      };
    case API_CALL_SUCCESS_NOTIFY:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification
          }
        ],
        isLoading: false
      };
    case API_CALL_ERROR_MSG:
    case APPLICATION_ERROR:
      return {
        ...state,
        errors: [
          ...state.errors,
          {
            ...action.error
          }
        ]
      };
    case REMOVE_ERROR:
      return {
        ...state,
        errors: state.errors.filter(err => err.key !== action.key)
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(n => n.key !== action.key)
      };
    case TOGGLE_INSERTION_POPUP:
      return {
        ...state,
        openInsertionPopup: !state.openInsertionPopup
      };
    case TOGGLE_CAMPAIGN_CONFIRM_POPUP:
      return {
        ...state,
        openCampaignConfirmPopup: !state.openCampaignConfirmPopup
      };
    case TOGGLE_PLAN_CONFIRM_POPUP:
      return {
        ...state,
        openPlanConfirmPopup: !state.openPlanConfirmPopup
      };
    case TOGGLE_ORDER_CONFIRM_POPUP:
      return {
        ...state,
        openOrderConfirmPopup: !state.openOrderConfirmPopup
      };
    case TOGGLE_MBS_CONFIRM_POPUP:
      return {
        ...state,
        openMbsConfirmPopup: !state.openMbsConfirmPopup
      };
    case TOGGLE_AGREEMENT_CONFIRM_POPUP:
      return {
        ...state,
        openAgreementConfirmPopup: !state.openAgreementConfirmPopup
      };
    case SET_ACTION:
      return {
        ...state,
        action: action.payload
      };
    case CLEAR_ACTION:
      return {
        ...state,
        action: ""
      };
    case SET_AGENCY:
      return {
        ...state,
        agency: action.payload
      };
    case CLEAR_AGENCY:
      return {
        ...state,
        agency: null
      };
    case OPEN_ORDER_STATUS_CHANGE_DIALOG:
      return {
        ...state,
        openOrderStatusChangeDialog: true
      };
    case CLOSE_ORDER_STATUS_CHANGE_DIALOG:
      return {
        ...state,
        openOrderStatusChangeDialog: false
      };
    case SET_ORDER_STATUS_CHANGE_DIALOG_TITLE:
      return {
        ...state,
        orderStatusChangeDialogTitle: action.payload
      };
    case OPEN_TRAFFIC_ITEM_DELETE_DIALOG:
      return {
        ...state,
        openTrafficItemDeleteDialog: true
      };
    case OPEN_EDIT_TRAFFICKER_DIALOG:
      return {
        ...state,
        hasEditTraffickerDialogOpened: true
      };
    case CLOSE_EDIT_TRAFFICKER_DIALOG:
      return {
        ...state,
        hasEditTraffickerDialogOpened: false
      };
    case CLOSE_TRAFFIC_ITEM_DELETE_DIALOG:
      return {
        ...state,
        openTrafficItemDeleteDialog: false
      };
    case OPEN_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG:
      return {
        ...state,
        openTrafficItemStatusChangeDialog: true
      };
    case CLOSE_TRAFFIC_ITEM_STATUS_CHANGE_DIALOG:
      return {
        ...state,
        openTrafficItemStatusChangeDialog: false
      };
    case OPEN_ADD_TRAFFIC_DIALOG:
      return {
        ...state,
        openAddTrafficDialog: true
      };
    case OPEN_ADD_ALL_TRAFFIC_ITEMS_DIALOG:
      return {
        ...state,
        openAddAllTrafficItemsDialog: true
      };
    case CLOSE_ADD_TRAFFIC_DIALOG:
      return {
        ...state,
        openAddTrafficDialog: false
      };
    case CLOSE_ADD_ALL_TRAFFIC_ITEMS_DIALOG:
      return {
        ...state,
        openAddAllTrafficItemsDialog: false
      };
    case TOGGLE_ORDERS_ACTION:
      return {
        ...state,
        ordersActionSwitch: !state.ordersActionSwitch
      };
    case SET_CHECK_ALL_ORDERS:
      return {
        ...state,
        checkAllOrders: action.payload
      };
    case OPEN_DIVIDE_ORDERS_DIALOG:
      return {
        ...state,
        openDivideOrdersDialog: true
      };
    case CLOSE_DIVIDE_ORDERS_DIALOG:
      return {
        ...state,
        openDivideOrdersDialog: false
      };
    case OPEN_SYSTEM_MESSAGE_DIALOG:
      return {
        ...state,
        openSystemMessageDialog: true
      };
    case CLOSE_SYSTEM_MESSAGE_DIALOG:
      return {
        ...state,
        openSystemMessageDialog: false
      };
    case TOGGLE_TRAFFIC_ITEM_ACTION:
      return {
        ...state,
        trafficItemActionSwitch: !state.trafficItemActionSwitch
      };
    case RESET_TRAFFIC_ITEM_ACTION:
      return {
        ...state,
        trafficItemActionSwitch: false
      };
    case OPEN_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG:
      return {
        ...state,
        openTrafficItemMultipleEditDialog: true
      };
    case CLOSE_TRAFFIC_ITEM_MULTIPLE_EDIT_DIALOG:
      return {
        ...state,
        openTrafficItemMultipleEditDialog: false
      };
    case OPEN_SYSTEM_NOTIFICATION_DIALOG:
      return {
        ...state,
        openSysNotificationDialog: true
      };
    case CLOSE_SYSTEM_NOTIFICATION_DIALOG:
      return {
        ...state,
        openSysNotificationDialog: false
      };
    case TOGGLE_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer
      };
    case OPEN_TRAFFIC_EXPORT_VERSION_DIALOG:
      return {
        ...state,
        openTrafficExportVersionDialog: true
      };
    case CLOSE_TRAFFIC_EXPORT_VERSION_DIALOG:
      return {
        ...state,
        openTrafficExportVersionDialog: false
      };
    case OPEN_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG:
      return {
        ...state,
        openTrafficExportDigitalVersionDialog: true
      };
    case CLOSE_TRAFFIC_EXPORT_DIGITAL_VERSION_DIALOG:
      return {
        ...state,
        openTrafficExportDigitalVersionDialog: false
      };
    case SET_REFETCH:
      return {
        ...state,
        refetch: true
      };
    case RESET_REFETCH:
      return {
        ...state,
        refetch: false
      };
    case TOGGLE_MEDIA_TYPE_CONFIRM_POPUP:
      return {
        ...state,
        openMediaTypeConfirmPopup: !state.openMediaTypeConfirmPopup
      };
    case EDIT_AGENCY_RULE:
      return {
        ...state,
        canEditAgencyRules: action.payload
      };
    default:
      return state;
  }
};

export default applicationReducer;
