import React, { useState } from "react";

import { Link } from "react-router-dom";
import {
  CardMedia,
  Typography,
  CardContent,
  CardActionArea,
  Grid
} from "@mui/material";

import { classes, MuiCard } from "../styles/cardLink";

export const CardLink = ({ to, id, image, title, disabled, subtitle }) => {
  const [elevation, setElevation] = useState(disabled ? 0 : 1);

  const handleMouseEnter = () => {
    if (!disabled) {
      setElevation(8);
    }
  };
  const handleMouseLeave = () => {
    if (!disabled) {
      setElevation(1);
    }
  };

  return (
    <MuiCard
      id={id}
      elevation={elevation}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardActionArea
        to={to}
        component={Link}
        disabled={disabled}
        className={classes.actionArea}
      >
        {image ? (
          <CardMedia
            title={title}
            image={image.default}
            className={classes.media}
          />
        ) : (
          <CardContent
            container
            component={Grid}
            direction="column"
            className={classes.content}
          >
            <Grid item>
              <Typography noWrap align="center" variant="h6" component="h2">
                {title}
              </Typography>
              {subtitle && (
                <Typography noWrap variant="caption" color="textSecondary">
                  {subtitle}
                </Typography>
              )}
            </Grid>
          </CardContent>
        )}
      </CardActionArea>
    </MuiCard>
  );
};

CardLink.defaultProps = {
  subtitle: null,
  disabled: false,
  image: undefined
};

export default CardLink;
