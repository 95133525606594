import { createReducer } from "../common/utils/reducerUtils";

import {
  PLAN_TARGET_METRICS_KPI_CLEAR_CURRENT,
  PLAN_TARGET_METRICS_KPI_SELECT,
  PLAN_TARGET_METRICS_KPI_EDIT_START,
  PLAN_TARGET_METRICS_KPI_EDIT_STOP,
  PLAN_TARGET_METRICS_KPI_HIDE_ERRORS,
  PLAN_TARGET_METRICS_KPI_DISPLAY_ERRORS,
  PLAN_TARGET_METRICS_KPI_SET_EDITING_ORIGINAL_ITEM,
  PLAN_TARGET_METRICS_KPI_CLEAR_EDITING_ORIGINAL_ITEM
} from "./planTargetMetricsKpiConstants";

const initialState = {
  currentPlanTargetMetricsKpis: [],
  isEditing: false,
  displayErrors: false,
  editingOriginalItem: null
};

export const selectCurrent = (state, payload) => {
  return {
    ...state,
    currentPlanTargetMetricsKpis: payload.currentPlanTargetMetricsKpis
  };
};

export const startEditing = state => {
  return {
    ...state,
    isEditing: true
  };
};

export const stopEditing = state => {
  return {
    ...state,
    isEditing: false
  };
};

export const displayErrors = state => {
  return {
    ...state,
    displayErrors: true
  };
};

export const hideErrors = state => {
  return {
    ...state,
    displayErrors: false
  };
};

export const setEditingOriginalItem = (state, payload) => {
  return {
    ...state,
    editingOriginalItem: payload
  };
};

export const clearEditingOriginalItem = state => {
  return {
    ...state,
    editingOriginalItem: null
  };
};

export const clearCurrent = () => initialState;

export default createReducer(initialState, {
  [PLAN_TARGET_METRICS_KPI_SELECT]: selectCurrent,
  [PLAN_TARGET_METRICS_KPI_CLEAR_CURRENT]: clearCurrent,
  [PLAN_TARGET_METRICS_KPI_EDIT_START]: startEditing,
  [PLAN_TARGET_METRICS_KPI_EDIT_STOP]: stopEditing,
  [PLAN_TARGET_METRICS_KPI_HIDE_ERRORS]: hideErrors,
  [PLAN_TARGET_METRICS_KPI_DISPLAY_ERRORS]: displayErrors,
  [PLAN_TARGET_METRICS_KPI_SET_EDITING_ORIGINAL_ITEM]: setEditingOriginalItem,
  [PLAN_TARGET_METRICS_KPI_CLEAR_EDITING_ORIGINAL_ITEM]: clearEditingOriginalItem
});
