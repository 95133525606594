import { styled, Grid as MUIGrid } from "@mui/material";

const PREFIX = "DivideOrdersDialogStyles";
export const classes = {
  tableCell: `${PREFIX}-tableCell`
};

export const Grid = styled(MUIGrid)(({ theme }) => ({
  [`& .${classes.tableCell}`]: {
    border: 0
  }
}));
