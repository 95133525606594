import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import clsx from "clsx";
import toLower from "lodash/toLower";

import {
  AccordionSummary,
  AccordionDetails,
  Divider,
  Tooltip,
  Typography
} from "@mui/material";

import { parseIntOrDefault } from "../../../business/models/common/utils/clientUtils";
import {
  PLACEHOLDER,
  CAMPAIGN,
  PLAN,
  CREATE_PAGE
} from "../../../configurations/appConstants";

import {
  selectCurrentPlan,
  getPropertyTextFromApiData
} from "../../../business/models/Plan/planSelectors";
import { getCampaignData } from "../../../business/models/Campaign/campaignSelectors";
import {
  selectUserActions,
  canEditCampaign
} from "../../../business/selectors/userProfileSelectors";
import PlanPanelCompact from "./PlanPanelCompact";
import PlanPanelFoldActions from "./PlanPanelFoldActions";
import PlanPanelFold from "./PlanPanelFold";
import PlanDetailsPopper from "./PlanDetailsPopper";
import { classes, Accordion } from "../styles/item";
import { classes as planPanelFoldClass, Grid } from "../styles/planPanelFold";

const PlanPanelItem = ({ plan }) => {
  const navigate = useNavigate();
  const { agency, campaignId: urlCampaignId, planId: urlPlanId } = useParams();
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);

  const currentPlan = useSelector(selectCurrentPlan);
  const userActions = useSelector(selectUserActions);
  const disableAction = !useSelector(canEditCampaign);

  const { isDebtorActive } = useSelector(getCampaignData) || false;

  const mediaType =
    useSelector(state =>
      getPropertyTextFromApiData(state, {
        name: "mediaTypeId",
        planId: plan.id
      })
    ) || PLACEHOLDER;

  const currencySupplier = useSelector(state =>
    getPropertyTextFromApiData(state, {
      name: "currencySupplierId",
      planId: plan.id
    })
  );

  const projectName =
    useSelector(state =>
      getPropertyTextFromApiData(state, {
        name: "marathonProjectId",
        planId: plan.id
      })
    ) || PLACEHOLDER;

  const {
    id,
    planName,
    totalBudget,
    marathonPlanId,
    invoiceDate,
    currencyClient,
    exchangeRateClient,
    exchangeRate,
    planPoNumber,
    planner,
    currencySupplierSymbol,
    numberOfOrders,
    flightId,
    isActual,
    hasUnreadTrafficPlanRemarks,
    isInCopyProcess,
    copyProcessId,
    planRemarks
  } = plan || {};

  const isActive = currentPlan === id;

  const changeUrl = () =>
    navigate(
      `/${agency}/${CREATE_PAGE}/${CAMPAIGN}/${urlCampaignId}${
        parseIntOrDefault(urlPlanId) === id ? "" : `/${PLAN}/${id}`
      }`
    );

  const handleChange = e => {
    e.stopPropagation();
    changeUrl();
  };

  const mbsPlans = ["radio", "tv"];
  let showMbsButton = false;
  const showMbs = userActions.includes("CreateFlight");

  if (mediaType && showMbs) {
    showMbsButton =
      mbsPlans.includes(toLower(mediaType)) && showMbs && isDebtorActive;
  }
  return (
    <>
      <Accordion
        square
        elevation={0}
        className={clsx(classes.expensionPanel, {
          [classes.selected]: isActive,
          [classes.copyProcess]: isInCopyProcess
        })}
        expanded={isActive}
        onChange={handleChange}
      >
        <AccordionSummary
          classes={{
            content: classes.expansionPanelSummeryContent,
            root: classes.expansionPanelSummeryRoot
          }}
        >
          <PlanPanelCompact
            id={id}
            mediaType={mediaType}
            planName={planName}
            disableAction={disableAction}
            isActual={isActual}
            marathonPlanId={marathonPlanId}
            totalBudget={totalBudget}
            currencySupplierSymbol={currencySupplierSymbol}
            currencySupplier={currencySupplier}
          />
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <Grid container justifyContent="space-between">
            <Grid item>
              <PlanPanelFoldActions
                allowDownload
                showMbsButton={showMbsButton}
                buttonRef={buttonRef}
                flightId={flightId}
                hasUnreadTrafficPlanRemarks={hasUnreadTrafficPlanRemarks}
                id={id}
                setOpen={setOpen}
                isInCopyProcess={isInCopyProcess}
                copyProcessId={copyProcessId}
              />
            </Grid>
            <Grid item>
              <PlanPanelFold
                id={id}
                isActual={isActual}
                numberOfOrders={numberOfOrders}
                isInCopyProcess={isInCopyProcess}
              />
            </Grid>
            <Grid xs={12} item>
              <Tooltip title={projectName} placement="bottom-start">
                <Typography
                  id="projectName"
                  name="projectName"
                  noWrap
                  align="left"
                  className={clsx(
                    planPanelFoldClass.planPanelItem,
                    planPanelFoldClass.typographyDetailsValue
                  )}
                >
                  {projectName}
                </Typography>
              </Tooltip>
              <Typography
                align="left"
                className={planPanelFoldClass.typographyCaption}
              >
                Project
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <PlanDetailsPopper
        open={open}
        setOpen={setOpen}
        buttonRef={buttonRef}
        marathonPlanId={marathonPlanId}
        invoiceDate={invoiceDate}
        currencyClient={currencyClient}
        exchangeRateClient={exchangeRateClient}
        currencySupplier={currencySupplier}
        exchangeRate={exchangeRate}
        planPoNumber={planPoNumber}
        planner={planner}
        planRemarks={planRemarks}
      />
    </>
  );
};

export default PlanPanelItem;
