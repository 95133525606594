import React from "react";
import PropTypes from "prop-types";

import { MenuItem, Grid, FormControl } from "@mui/material";

import { EMPTY_STRING } from "../../../../configurations/appConstants";

import {
  classes,
  Typography,
  SortIcon,
  Select
} from "../styles/trafficTableSort";

const Sort = ({ sortValue, onSortChange }) => {
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Typography className={classes.cssTypography}>SORT</Typography>
      </Grid>
      <Grid item>
        <SortIcon className={classes.cssSortIcon} color="primary" />
      </Grid>
      <Grid item>
        <FormControl variant="standard">
          <Select
            className={classes.dropDown}
            value={sortValue}
            onChange={onSortChange}
            inputProps={{
              name: "sort",
              id: "sort-list"
            }}
          >
            <MenuItem value={""}>None</MenuItem>
            <MenuItem value={"planId_asc"}>By plan ID asc</MenuItem>
            <MenuItem value={"planId"}>By plan ID dsc</MenuItem>
            <MenuItem value={"planName_asc"}>By plan name asc</MenuItem>
            <MenuItem value={"planName"}>By plan name dsc</MenuItem>
            <MenuItem value={"debtorName_asc"}>By debtor name asc</MenuItem>
            <MenuItem value={"debtorName"}>By debtor name dsc</MenuItem>
            <MenuItem value={"processManager_asc"}>
              By process manager asc
            </MenuItem>
            <MenuItem value={"processManager"}>By process manager dsc</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

Sort.propTypes = {
  sortValue: PropTypes.string
};

Sort.defaultProps = {
  sortValue: EMPTY_STRING
};

export default Sort;
