import groupmLogo from "../img/groupm.png";
import GroupM from "../img/groupmlogo.png";
import Kinetic from "../img/kineticlogo.png";
import EssenceMediacom from "../img/essencemediacomlogo.png";
import Mindshare from "../img/mindsharelogo.png";
import Wavemaker from "../img/wavemakerlogo.png";
import GreenhouseOne from "../img/greenhouseonelogo.png";
import GreenhouseTech from "../img/greenhousetechlogo.png";
import ChoreographCreate from "../img/choreographcreatelogo.png";
import Blossom from "../img/blossomlogo.png";

const agencyLogo = {
  groupmLogo,
  GroupM,
  Kinetic,
  EssenceMediacom,
  Mindshare,
  Wavemaker,
  GreenhouseOne,
  GreenhouseTech,
  ChoreographCreate,
  Blossom
};

export default agencyLogo;
