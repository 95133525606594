import { styled, Grid as MuiGrid } from "@mui/material";
import { baliHai, solitude } from "../../../assets/styles/colors";

const PREFIX = "FoldStyles";
export const classes = {
  gridItem: `${PREFIX}-gridItem`,
  gridItemDisplayErrors: `${PREFIX}-gridItemDisplayErrors`,
  inheritWidth: `${PREFIX}-inheritWidth`,
  chipTraffic: `${PREFIX}-chipTraffic`,
  chipHeader: `${PREFIX}-chipHeader`,
  field: `${PREFIX}-field`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`&.${classes.gridItem}`]: {
    width: theme.typography.pxToRem(190),
    height: theme.typography.pxToRem(75),
    padding: theme.typography.pxToRem(12),
    [`& .MuiInputLabel-root`]: {
      zIndex: 1100
    }
  },
  [`&.${classes.gridItemDisplayErrors}`]: {
    marginTop: theme.typography.pxToRem(40)
  },
  [`& .${classes.inheritWidth}`]: {
    width: "inherit"
  },
  [`& .${classes.chipTraffic}`]: {
    color: baliHai,
    minWidth: theme.typography.pxToRem(180),
    backgroundColor: solitude
  },
  [`& .${classes.chipHeader}`]: {
    width: theme.typography.pxToRem(100)
  },
  [`&.${classes.gridItemAutocomplete}`]: {
    width: theme.typography.pxToRem(190),
    height: theme.typography.pxToRem(75),
    padding: theme.typography.pxToRem(2)
  },
  [`& .${classes.field}`]: {
    marginTop: theme.typography.pxToRem(0)
  }
}));
