import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@mui/material";

import { setActual } from "../../../../business/models/Plan/planActions";
import { getPlanActual } from "../../../../business/models/Plan/planSelectors";
import { closeSetActualDialog } from "../../../../business/actions/campaignDashboardActions";

const paperProps = { square: true };
const SET_ACTUAL_MSG =
  "Are you sure that this plan can be set to status Actual?";
const UNSET_ACTUAL_MSG =
  "Are you sure that you want to remove the actual status for this plan?";

const ConfirmSetActualDialog = () => {
  const dispatch = useDispatch();
  const { agency } = useParams();
  const isActual = useSelector(getPlanActual);

  const handleSetActual = () => {
    dispatch(setActual(agency));
    dispatch(closeSetActualDialog());
  };

  const handleNo = () => {
    dispatch(closeSetActualDialog());
  };

  return (
    <Dialog open PaperProps={paperProps} maxWidth="sm">
      <DialogContent>
        <DialogContentText noWrap={false}>
          {isActual ? UNSET_ACTUAL_MSG : SET_ACTUAL_MSG}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo} color="primary">
          No
        </Button>
        <Button onClick={handleSetActual} color="secondary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSetActualDialog;
