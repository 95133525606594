import React from "react";
import PropTypes from "prop-types";

import { Dialog, DialogContent, Grid, DialogActions } from "@mui/material";

import DialogTitle from "../../../../shared/dialog/DialogTitle";

import { classes, Button } from "../../styles/userProfileConfirmationDialog";

const UserProfileConfirmationDialog = ({
  open,
  isLoading,
  onSave,
  onCancel
}) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle id="action-dialog-title">Save user settings</DialogTitle>
      <DialogContent id="action-dialog-content">
        Are you sure you want to change settings?
      </DialogContent>
      <DialogActions id="action-dialog-actions">
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              onClick={onCancel}
              className={classes.closeButton}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onSave} variant="contained" disabled={isLoading}>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

UserProfileConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool
};

UserProfileConfirmationDialog.defaultProps = {
  open: false
};

export default UserProfileConfirmationDialog;
