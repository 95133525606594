import React, { memo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Button, Dialog, DialogActions, Grid } from "@mui/material";

import TableExportPreview from "./extensions/previewDialog/TableExportPreview";
import {
  selectMediaTypeId,
  selectOpenTrafficPlanPreviewDialog,
  selectPreviewData
} from "../../../../business/models/TrafficPlan/trafficPlanSelectors";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import {
  closePreviewDialog,
  clearPreviewData
} from "../../../../business/models/TrafficPlan/trafficPlanActions";

const paperProps = { square: true };

const TrafficPlanExportPreviewDialog = ({
  isOpen,
  cancel,
  data,
  mediaTypeId,
  clearPreview
}) => {
  const handleCancel = () => {
    cancel();
    clearPreview();
  };
  return (
    <Dialog
      open={isOpen}
      PaperProps={paperProps}
      fullScreen
      disableBackdropClick
    >
      <TableExportPreview mediaTypeId={mediaTypeId} data={data} />
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Button
            id="cancel_preview"
            name="cancel_preview"
            onClick={handleCancel}
            color="primary"
          >
            Cancel
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

TrafficPlanExportPreviewDialog.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  mediaTypeId: PropTypes.number
};

TrafficPlanExportPreviewDialog.defaultProps = {
  data: {},
  isOpen: false,
  mediaTypeId: 0
};

const mapStateToProps = state => ({
  data: selectPreviewData(state),
  isOpen: selectOpenTrafficPlanPreviewDialog(state),
  isLoading: selectIsLoading(state),
  mediaTypeId: selectMediaTypeId(state)
});

const mapDispatchToProps = dispatch => {
  return {
    cancel: () => dispatch(closePreviewDialog()),
    clearPreview: () => dispatch(clearPreviewData())
  };
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(TrafficPlanExportPreviewDialog)
);
