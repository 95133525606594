import React, { memo } from "react";
import { connect } from "react-redux";

import Scrollbar from "../shared/Scrollbar";
import TrafficPlanItemElement from "./extensions/TrafficPlanlItemElement";

import { getTraffics } from "../../business/models/TrafficPlan/trafficPlanSelectors";

function TrafficPlanItem(props) {
  const { traffics } = props;

  return (
    <Scrollbar>
      {traffics.map(traffic => (
        <TrafficPlanItemElement
          key={traffic.id}
          id={traffic.id}
          traffic={traffic}
        />
      ))}
    </Scrollbar>
  );
}

const mapStateToProps = state => {
  return {
    traffics: getTraffics(state)
  };
};

export default memo(connect(mapStateToProps, null)(TrafficPlanItem));
