import React from "react";
import { Typography, Checkbox } from "@mui/material";
import VerticalDivider from "../../../../panels/extensions/VerticalDivider";
import {
  classes,
  Grid
} from "../../styles/changeTrafficItemStatusDialogElement";

const ChangeTrafficItemStatusDialogElement = ({
  id,
  trafficItem,
  isSelected,
  onCheckboxClick
}) => {
  const {
    trafficItemStatusText,
    trafficItemId,
    marathonOrderId,
    marathonPlanId,
    title
  } = trafficItem;

  return (
    <Grid container spacing={2} alignItems="center" key={id}>
      <Grid item>
        <Checkbox
          onClick={e => onCheckboxClick(e.target.checked)}
          checked={isSelected}
          color="primary"
        />
      </Grid>
      <Grid item className={classes.widthTrafficItemStatusText}>
        <Typography className={classes.cssTypography} noWrap>
          {trafficItemStatusText}
        </Typography>
      </Grid>
      <Grid item className={classes.widthTrafficItemStatusText}>
        <VerticalDivider />
      </Grid>
      <Grid item className={classes.widthTrafficItemId}>
        <Typography className={classes.cssTypography} noWrap>
          {trafficItemId}
        </Typography>
      </Grid>
      <Grid item className={classes.widthTrafficItemStatusText}>
        <VerticalDivider />
      </Grid>
      <Grid item className={classes.widthMarathonOrderId}>
        <Typography className={classes.cssTypography} noWrap>
          {marathonOrderId}
        </Typography>
      </Grid>
      <Grid item className={classes.widthTrafficItemStatusText}>
        <VerticalDivider />
      </Grid>
      <Grid item className={classes.widthMarathonPlanId}>
        <Typography className={classes.cssTypography} noWrap>
          {marathonPlanId}
        </Typography>
      </Grid>
      <Grid item className={classes.widthTrafficItemStatusText}>
        <VerticalDivider />
      </Grid>
      <Grid item className={classes.widthTitle}>
        <Typography className={classes.cssTypography} noWrap>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ChangeTrafficItemStatusDialogElement;
