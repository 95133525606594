import { styled, Grid as MuiGrid } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { softRed, crusta, baliHai } from "../../../assets/styles/colors";
import { getThemeSpacing } from "../../../functions/util";

const PREFIX = "TrafficLineStyles";
export const classes = {
  btn: `${PREFIX}-btn`,
  cancelButton: `${PREFIX}-cancelButton`,
  cancelSimpleButton: `${PREFIX}-cancelButton`,
  iconButton: `${PREFIX}-iconButton`,
  ctcProperty: `${PREFIX}-ctcProperty`,
  trafficField: `${PREFIX}-trafficField`,
  insertionIconButton: `${PREFIX}-insertionIconButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.btn}`]: {
    color: softRed,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  [`& .${classes.cancelButton}`]: {
    color: theme.palette.common.white,
    backgroundColor: crusta
  },
  [`& .${classes.iconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.ctcProperty}`]: {
    color: theme.palette.common.white,
    display: "inline-block"
  },
  [`& .${classes.trafficField}`]: {
    marginTop: theme.typography.pxToRem(0),
    [`& .MuiInputLabel-root.Mui-disabled`]: {
      zIndex: 1500
    }
  }
}));
