import React from "react";
import NumberFormat from "react-number-format";
import { Typography } from "@mui/material";
import { PLACEHOLDER } from "../../../configurations/appConstants";
import {
  currencies,
  currencySymbols
} from "../../../business/constants/businessConstants";
import { classes, Grid } from "../styles/ctcStyle";

const CtcTooltipText = ({
  showCtc = true,
  ctc = 0.0,
  ctcPercentage = 0.0,
  fee = 0.0,
  net = 0.0,
  surcharge1 = 0.0,
  surcharge2 = 0.0,
  currencyClient,
  currencySupplier,
  showEurCurrency,
  showCurrency,
  ctcPercentageEur = 0.0,
  ctcEur = 0.0,
  feeEur = 0.0,
  netEur = 0.0,
  surcharge1Eur = 0.0,
  surcharge2Eur = 0.0,
  exchangeRateClient = 1,
  exchangeRateSupplier = 1
}) => {
  const bothCurrenciesAreDifferent = currencyClient !== currencySupplier;
  const currencySymbolSupplier = currencySymbols[currencySupplier];
  const currencySymbolClient = currencySymbols[currencyClient];

  const calculateSupplierAmount = (amount, amountEur) => {
    let outputAmount = amountEur;
    if (currencySupplier !== currencies.EUR) {
      outputAmount = amount;
    }
    return outputAmount;
  };

  const calculateClientAmount = (amount, amountEur) => {
    let outputAmount = amount;
    if (currencyClient === currencies.EUR) {
      outputAmount = amount * exchangeRateSupplier;
    } else {
      outputAmount = amountEur / exchangeRateClient;
      if (
        currencySupplier !== currencies.EUR &&
        currencyClient !== currencies.EUR
      ) {
        outputAmount = (amount * exchangeRateSupplier) / exchangeRateClient;
      }
    }

    return outputAmount;
  };
  return (
    <Grid container spacing={3}>
      <Grid item>
        {showCtc &&
          (showEurCurrency || showCurrency)(
            <Grid>
              <Typography className={classes.ctcProperty} variant="body1">
                {`Total Cost Client (${currencySymbols[currencySupplier]}):`}
                &nbsp;&nbsp;
              </Typography>
              {(
                <NumberFormat
                  id="ctc"
                  name="ctc"
                  value={ctc || ctcEur}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              ) || PLACEHOLDER}
              {(
                <NumberFormat
                  id="ctcPercentage"
                  name="ctcPercentage"
                  value={ctcPercentage || ctcPercentageEur}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="("
                  suffix=")%"
                />
              ) || PLACEHOLDER}
            </Grid>
          )}
        <Grid>
          <Typography className={classes.ctcProperty} variant="body1">
            {`Net (${currencySymbolSupplier}):`}&nbsp;&nbsp;
          </Typography>
          {(
            <NumberFormat
              id="netSupplier"
              name="netSupplier"
              value={calculateSupplierAmount(net, netEur)}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
            />
          ) || PLACEHOLDER}
          {bothCurrenciesAreDifferent && (
            <>
              <Typography className={classes.ctcProperty} variant="body1">
                &nbsp;|&nbsp;({currencySymbolClient}):&nbsp;&nbsp;
              </Typography>
              {(
                <NumberFormat
                  id="netClient"
                  name="netClient"
                  value={calculateClientAmount(net, netEur)}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              ) || PLACEHOLDER}
            </>
          )}
        </Grid>
        <Grid>
          <Typography className={classes.ctcProperty} variant="body1">
            {`Fee (${currencySymbolSupplier}):`}&nbsp;&nbsp;
          </Typography>
          {(
            <NumberFormat
              id="feeSupplier"
              name="feeSupplier"
              value={calculateSupplierAmount(fee, feeEur)}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
            />
          ) || PLACEHOLDER}
          {bothCurrenciesAreDifferent && (
            <>
              <Typography className={classes.ctcProperty} variant="body1">
                &nbsp;|&nbsp;({currencySymbolClient}):&nbsp;&nbsp;
              </Typography>
              {(
                <NumberFormat
                  id="feeClient"
                  name="feeClient"
                  value={calculateClientAmount(fee, feeEur)}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              ) || PLACEHOLDER}
            </>
          )}
        </Grid>
        <Grid>
          <Typography className={classes.ctcProperty} variant="body1">
            {`Surcharge 1 (${currencySymbolSupplier}):`}&nbsp;&nbsp;
          </Typography>
          {(
            <NumberFormat
              id="surcharge1Supplier"
              name="surcharge1Supplier"
              value={calculateSupplierAmount(surcharge1, surcharge1Eur)}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
            />
          ) || PLACEHOLDER}
          {bothCurrenciesAreDifferent && (
            <>
              <Typography className={classes.ctcProperty} variant="body1">
                &nbsp;|&nbsp;({currencySymbolClient}):&nbsp;&nbsp;
              </Typography>
              {(
                <NumberFormat
                  id="surcharge1Client"
                  name="surcharge1Client"
                  value={calculateClientAmount(surcharge1, surcharge1Eur)}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              ) || PLACEHOLDER}
            </>
          )}
        </Grid>
        <Grid>
          <Typography className={classes.ctcProperty} variant="body1">
            {`Surcharge 2 (${currencySymbolSupplier}):`}&nbsp;&nbsp;
          </Typography>
          {(
            <NumberFormat
              id="surcharge2Supplier"
              name="surcharge2Supplier"
              value={calculateSupplierAmount(surcharge2, surcharge2Eur)}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
            />
          ) || PLACEHOLDER}
          {bothCurrenciesAreDifferent && (
            <>
              <Typography className={classes.ctcProperty} variant="body1">
                &nbsp;|&nbsp;({currencySymbolClient}):&nbsp;&nbsp;
              </Typography>
              {(
                <NumberFormat
                  id="surcharge2Client"
                  name="surcharge2Client"
                  value={calculateClientAmount(surcharge2, surcharge2Eur)}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              ) || PLACEHOLDER}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CtcTooltipText;
