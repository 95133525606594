import { createSelector } from "reselect";

const selectCopyPlanProcess = state => state.copyPlanProcess;

export const selectOrderSteps = createSelector(
  selectCopyPlanProcess,
  process => process.orderSteps
);

export const selectCurrentOrderStep = createSelector(
  selectCopyPlanProcess,
  process => process.currentOrderStep
);

export const selectSteps = createSelector(
  selectCopyPlanProcess,
  process => process.steps
);

export const selectActiveStep = createSelector(
  selectCopyPlanProcess,
  process => process.activeStep
);

export const selectCampaignStatus = createSelector(
  selectCopyPlanProcess,
  process => process.campaignStatus
);

export const selectIsExisting = createSelector(
  selectCampaignStatus,
  status => status === "existing"
);

export const selectProcess = createSelector(
  selectCopyPlanProcess,
  process => process.processId
);

export const selectIsInCopyPlanProcess = createSelector(
  selectProcess,
  processId => processId != null
);
