import { styled, Table as MuiTable } from "@mui/material";
import {
  linkWater,
  matisse,
  azureRadiance
} from "../../../../assets/styles/colors";

const PREFIX = "ProjectTableStyles";
export const classes = {
  tableHeader: `${PREFIX}-tableHeader`,
  tableHead: `${PREFIX}-tableHead`,
  tableBody: `${PREFIX}-tableBody`,
  cellHeader: `${PREFIX}-cellHeader`,
  cellBody: `${PREFIX}-cellBody`,
  cellAction: `${PREFIX}-cellAction`,
  cellCustom: `${PREFIX}-cellCustom`,
  buttonIcon: `${PREFIX}-buttonIcon`,
  buttonAdd: `${PREFIX}-cellAction`,
  typography: `${PREFIX}-typography`,
  colorLinkWater: `${PREFIX}-colorLinkWater`
};

export const Table = styled(MuiTable)(({ theme }) => ({
  [`&.${classes.tableHeader}`]: {
    tableLayout: "fixed"
  },
  [`& .${classes.tableBody}`]: {
    maxHeight: theme.typography.pxToRem(300),
    tableLayout: "fixed"
  },
  [`& .${classes.tableHead}`]: {
    height: theme.typography.pxToRem(80)
  },
  [`& .${classes.cellHeader}`]: {
    width: theme.typography.pxToRem(400),
    paddingRight: theme.typography.pxToRem(0)
  },
  [`& .${classes.cellBody}`]: {
    width: theme.typography.pxToRem(400),
    paddingRight: theme.typography.pxToRem(0)
  },
  [`& .${classes.cellAction}`]: {
    "&:last-child": {
      paddingLeft: theme.typography.pxToRem(0)
    }
  },
  [`& .${classes.cellCustom}`]: {
    paddingLeft: theme.typography.pxToRem(56),
    paddingRight: theme.typography.pxToRem(0),
    width: theme.typography.pxToRem(64)
  },
  [`& .${classes.buttonIcon}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32)
  },
  [`& .${classes.buttonAdd}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    color: matisse,
    "&:hover": {
      color: azureRadiance
    },
    "&:disabled": {
      color: linkWater
    }
  },
  [`& .${classes.typography}`]: {
    display: "inline-block"
  },
  [`& .${classes.colorLinkWater}`]: {
    color: linkWater
  }
}));
