import forEach from "lodash/forEach";
import get from "lodash/get";

import generateUrl from "../utils/urlUtils";

import {
  selectCampaign,
  loadCampaignMediaData
} from "../../Campaign/campaignActions";
import {
  CAMPAIGN_MODEL_NAME,
  CAMPAIGN_MODEL_ID
} from "../../Campaign/campaignConstants";

import {
  loadProjectMediaData,
  selectProject
} from "../../Project/projectActions";
import { PROJECT_MODEL_NAME } from "../../Project/projectConstants";

import { loadPlanMediaData } from "../../Plan/planActions";
import { PLANS_PROPERTY } from "../../Plan/planConstants";

import { loadOrderMediaData } from "../../Order/orderActions";
import { ORDERS_PROPERTY } from "../../Order/orderConstants";

import {
  enqueueApiErrorMessage,
  apiCall
} from "../../../actions/applicationActions";

import { selectAgency } from "../../../selectors/applicationSelectors";

import {
  LOAD_ENTITY_DATA_SUCCESS,
  CLEAR_ENTITY_DATA,
  CLEAR_EDITING_ENTITY_DATA,
  APPEND_ENTITY_DATA_SUCCESS,
  LOAD_TRAFFIC_TEMPLATES_SUCCESS
} from "./dataConstants";

import {
  LOAD_ENTITY_DATA,
  LOAD_ACTION_ENTITY_DATA,
  SAVE_TRAFFIC_TEMPLATE
} from "../../../../configurations/apiUrls";
import { GET } from "../../../constants/applicationConstants";
import { isNullOrUndefined } from "../../../../functions/util";
import { ERROR_MSG_SOMETHING_WHENT_WRONG } from "../../../../configurations/appConstants";
import { TRAFFIC_TEMPLATE_MODEL_ID } from "../../TrafficTemplate/trafficTemplateConstants";
import { loadTrafficTableMediaData } from "../../TrafficTemplate/trafficTemplateActions";
import {
  UPDATE_INSERTIONS_WITH_CHANGE,
  UPDATE_ORDERS_WITH_INSERTION_CHANGE,
  UPDATE_PLANS_WITH_INSERTION_CHANGE
} from "../../../constants/campaignDashboardConstants";

export const clearEntityData = () => dispatch => {
  dispatch({ type: CLEAR_ENTITY_DATA });
};

export const clearEditingEntityData = () => dispatch => {
  dispatch({ type: CLEAR_EDITING_ENTITY_DATA });
};

const loadMediaData = campaignData => dispatch => {
  dispatch(loadCampaignMediaData(campaignData));
  forEach(campaignData[PLANS_PROPERTY], planData => {
    dispatch(loadPlanMediaData({ ...campaignData, ...planData }));
    forEach(planData[ORDERS_PROPERTY], orderData => {
      dispatch(
        loadOrderMediaData({ ...campaignData, ...planData, ...orderData })
      );
    });
  });

  forEach(campaignData[PROJECT_MODEL_NAME.toLowerCase()], projectData => {
    dispatch(loadProjectMediaData({ ...campaignData, ...projectData }));
  });
};

export const loadEntityData = id => (dispatch, getState) => {
  const agencyID = selectAgency(getState());
  const url = generateUrl(
    LOAD_ENTITY_DATA,
    {
      agency: agencyID,
      [CAMPAIGN_MODEL_ID]: id
    },
    { IsFullEntity: true }
  );

  return dispatch(apiCall(GET, url)).then(
    response => {
      const data = response[CAMPAIGN_MODEL_NAME.toLowerCase()];
      const path = "projects[0].projectId";
      const projectId = get(data, path);

      if (!isNullOrUndefined(data)) {
        dispatch(clearEntityData());
        dispatch(clearEditingEntityData());

        dispatch({
          type: LOAD_ENTITY_DATA_SUCCESS,
          payload: { data }
        });

        dispatch(loadMediaData(data));

        dispatch(selectCampaign(Number(id)));

        if (projectId) {
          dispatch(selectProject(projectId));
        }
      }

      const { metadata } = response;

      if (!isNullOrUndefined(metadata)) {
        const {
          plansWithInsertionChange,
          ordersWithInsertionChange,
          insertionsWithChange
        } = metadata;

        dispatch({
          type: UPDATE_PLANS_WITH_INSERTION_CHANGE,
          payload: { plansWithInsertionChange }
        });

        dispatch({
          type: UPDATE_ORDERS_WITH_INSERTION_CHANGE,
          payload: { ordersWithInsertionChange }
        });

        dispatch({
          type: UPDATE_INSERTIONS_WITH_CHANGE,
          payload: { insertionsWithChange }
        });
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const loadCopyPlanEntityData = id => (dispatch, getState) => {
  const agencyID = selectAgency(getState());
  const url = generateUrl(
    LOAD_ENTITY_DATA,
    {
      agency: agencyID,
      [CAMPAIGN_MODEL_ID]: id
    },
    { IsFullEntity: true }
  );

  return dispatch(apiCall(GET, url)).then(
    response => {
      const data = response[CAMPAIGN_MODEL_NAME.toLowerCase()];

      if (isNullOrUndefined(data)) {
        dispatch(enqueueApiErrorMessage(ERROR_MSG_SOMETHING_WHENT_WRONG));
        return null;
      }
      const campaignId = get(data, CAMPAIGN_MODEL_ID);
      dispatch({
        type: APPEND_ENTITY_DATA_SUCCESS,
        payload: { data }
      });
      dispatch(loadMediaData(data));
      return campaignId;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const loadTrafficTemplates = query => (dispatch, getState) => {
  const agencyID = selectAgency(getState());
  const { searchProperty, searchText, ...queryFilters } = query;
  const paramObj = { agency: agencyID };
  const queryObj = {
    searchproperty: searchProperty,
    searchtext: searchText,
    ...queryFilters
  };
  const url = generateUrl(SAVE_TRAFFIC_TEMPLATE, paramObj, queryObj);

  return dispatch(apiCall(GET, url)).then(
    response => {
      const data = response.trafficTemplates;
      if (isNullOrUndefined(data)) {
        dispatch(enqueueApiErrorMessage(ERROR_MSG_SOMETHING_WHENT_WRONG));
        return null;
      }
      const trafficTemplateId = get(data, TRAFFIC_TEMPLATE_MODEL_ID);
      dispatch(clearEntityData());
      dispatch({
        type: LOAD_TRAFFIC_TEMPLATES_SUCCESS,
        payload: { data }
      });
      dispatch(loadTrafficTableMediaData(data));

      return trafficTemplateId;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const loadActionEntityData = id => (dispatch, getState) => {
  const agencyID = selectAgency(getState());
  const url = generateUrl(LOAD_ACTION_ENTITY_DATA, {
    agency: agencyID,
    [CAMPAIGN_MODEL_ID]: id
  });

  return dispatch(apiCall(GET, url)).then(
    response => {
      const data = response[CAMPAIGN_MODEL_NAME.toLowerCase()];
      const path = "project[0].projectId";
      const projectId = get(data, path);

      if (!isNullOrUndefined(data)) {
        dispatch(clearEntityData());
        dispatch(clearEditingEntityData());

        dispatch({
          type: LOAD_ENTITY_DATA_SUCCESS,
          payload: { data }
        });

        dispatch(loadMediaData(data));

        dispatch(selectCampaign(Number(id)));

        if (projectId) {
          dispatch(selectProject(projectId));
        }
      }
      return id;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};
