import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import clsx from "clsx";

import { Button } from "@mui/material";

import { CREATE_PAGE } from "../../../../configurations/appConstants";

import { generateUrlPath } from "../../../../business/models/common/utils/clientUtils";
import { toggleInsertionPopup } from "../../../../business/actions/applicationActions";
import {
  addNewOrder,
  cancelOrder,
  createOrder
} from "../../../../business/models/Order/orderActions";
import { closeOrderFormPage } from "../../../../business/actions/campaignDashboardActions";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import { selectCurrentCampaign } from "../../../../business/models/Campaign/campaignSelectors";
import { selectCurrentPlan } from "../../../../business/models/Plan/planSelectors";
import { disabledByInactiveDebtor } from "../../../../business/models/Order/orderSelectors";
import { hasCurrentInsertions } from "../../../../business/models/Insertion/insertionSelectors";

import { classes, Grid } from "../styles/orderNewActions";

const OrderNewActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agency } = useParams();

  const isLoading = useSelector(selectIsLoading);
  const campaignId = useSelector(selectCurrentCampaign);
  const planId = useSelector(selectCurrentPlan);
  const hasInsertions = useSelector(hasCurrentInsertions);
  const { disableActions } = useSelector(disabledByInactiveDebtor);

  const handleCancel = () => {
    dispatch(cancelOrder());
    dispatch(closeOrderFormPage());
    navigate(`/${agency}/${CREATE_PAGE}${generateUrlPath(campaignId, planId)}`);
  };

  const handleSave = () => {
    dispatch(createOrder()).then(orderId => {
      if (orderId) {
        dispatch(closeOrderFormPage()).then(() =>
          navigate(
            `/${agency}/${CREATE_PAGE}${generateUrlPath(
              campaignId,
              planId,
              orderId
            )}`
          )
        );
      }
    });
  };

  const handleSaveAndAddOrder = () => {
    dispatch(createOrder()).then(orderId => {
      if (orderId) {
        dispatch(addNewOrder());
      }
    });
  };

  const handleEditInsertions = () => {
    dispatch(toggleInsertionPopup());
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Button
          id="cancel_order"
          name="cancel_order"
          className={classes.cancelButton}
          onClick={handleCancel}
          disabled={isLoading}
        >
          CANCEL
        </Button>
      </Grid>
      {!disableActions && (
        <Grid item>
          <Grid container justifyContent="space-around" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                className={clsx(classes.saveButton, classes.markedButton)}
                disabled={isLoading}
                onClick={handleSave}
              >
                SAVE
              </Button>
            </Grid>
            {hasInsertions && (
              <Grid item>
                <Button
                  id="edit_insertions"
                  name="edit_insertions"
                  variant="contained"
                  className={classes.saveButton}
                  onClick={handleEditInsertions}
                >
                  EDIT INSERTIONS
                </Button>
              </Grid>
            )}
            {!hasInsertions && (
              <Grid item>
                <Button
                  id="save_order"
                  name="save_order"
                  variant="contained"
                  className={classes.saveButton}
                  disabled={isLoading}
                  onClick={handleSaveAndAddOrder}
                >
                  SAVE AND ADD ORDER
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OrderNewActions;
