import { createReducer } from "../models/common/utils/reducerUtils";

import {
  SET_APPROVAL_OVERVIEW_COUNT,
  SET_APPROVAL_OVERVIEW_ITEMS,
  SET_CURRENT_CONDITION,
  RESET_APPROVAL_OVERVIEW_PAGE,
  RESET_APPROVAL_DETAIL_PAGE,
  OPEN_APPROVAL_DIALOG,
  CLOSE_APPROVAL_DIALOG,
  SET_APPROVAL_ACTION,
  RESET_APPROVAL_ACTION
} from "../constants/approvalPageConstants";

const initialState = {
  openDialog: false,
  action: null,
  currentCondition: {},
  table: {
    count: 0,
    items: []
  }
};

export const setCount = (state, payload) => ({
  ...state,
  table: { ...state.table, count: payload }
});

export const setItems = (state, payload) => ({
  ...state,
  table: { ...state.table, items: payload }
});

export const setCondition = (state, payload) => ({
  ...state,
  currentCondition: payload
});

export const clearItems = state => ({
  ...state,
  table: { ...state.table, items: [] }
});

export const openDialog = state => ({ ...state, openDialog: true });

export const closeDialog = state => ({ ...state, openDialog: false });

export const setAction = (state, payload) => ({
  ...state,
  action: payload
});

export const resetAction = state => ({
  ...state,
  action: null
});

export const resetOverviewPage = state => ({
  ...state,
  table: { ...state.table, count: 0, items: [] }
});

export const resetDetailPage = state => ({
  ...state,
  action: null,
  openDialog: false,
  currentCondition: {}
});

export const resetPage = () => initialState;

export default createReducer(initialState, {
  [SET_APPROVAL_OVERVIEW_COUNT]: setCount,
  [SET_APPROVAL_OVERVIEW_ITEMS]: setItems,
  [SET_CURRENT_CONDITION]: setCondition,
  [RESET_APPROVAL_OVERVIEW_PAGE]: resetOverviewPage,
  [RESET_APPROVAL_DETAIL_PAGE]: resetDetailPage,
  [OPEN_APPROVAL_DIALOG]: openDialog,
  [CLOSE_APPROVAL_DIALOG]: closeDialog,
  [SET_APPROVAL_ACTION]: setAction,
  [RESET_APPROVAL_ACTION]: resetAction
});
