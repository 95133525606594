import React from "react";
import clsx from "clsx";

import {
  AccordionSummary,
  Typography,
  Grid,
  Chip,
  Tooltip
} from "@mui/material";

import {
  parseIntOrDefault,
  trimString
} from "../../../../../../business/models/common/utils/clientUtils";

import {
  isNullOrUndefined,
  formatCurrency
} from "../../../../../../functions/util";
import { PLACEHOLDER } from "../../../../../../configurations/appConstants";
import { classes, Accordion } from "../../styles/ActionCompareItem";

const TextFields = [
  "Description",
  "Target Audience",
  "Position",
  "Frequency Cap",
  "Account Id",
  "Remarks"
];

const ActionCompareItem = ({ property }) => {
  Object.entries(property).forEach(([key, value]) => {
    if (TextFields.includes(property.propertyName)) {
      return;
    }
    const parsed = parseIntOrDefault(value);

    if (parsed) {
      property[key] = formatCurrency(value);
    }
  });

  const { propertyName, currentValue, previousValue, difference } = property;
  const isDifferenceExists = isNullOrUndefined(difference);

  return (
    <>
      <Accordion square elevation={0} expanded={false} className={classes.item}>
        <AccordionSummary>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Tooltip title={propertyName} placement="bottom-start">
                    <Typography
                      noWrap
                      className={classes.typographyOrderHeader}
                    >
                      {propertyName}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography
                    noWrap
                    className={classes.typographyCompareHeader}
                  >
                    Current value:
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={currentValue || PLACEHOLDER}
                    placement="bottom-start"
                  >
                    <Chip
                      id="compareItemCurrentValue"
                      label={trimString(currentValue, 15) || PLACEHOLDER}
                      className={clsx(classes.chip, classes.compareChip)}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography
                    noWrap
                    className={classes.typographyCompareHeader}
                  >
                    Previous value:
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    id="compareItemPreviousValueTooltip"
                    title={previousValue || PLACEHOLDER}
                    placement="bottom-start"
                  >
                    <Chip
                      label={trimString(previousValue, 15) || PLACEHOLDER}
                      className={clsx(classes.chip, classes.compareChip)}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            {!isDifferenceExists && (
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography
                      noWrap
                      className={classes.typographyCompareHeader}
                    >
                      Difference:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title={difference} placement="bottom-start">
                      <Chip
                        label={trimString(difference, 15)}
                        className={clsx(
                          classes.chip,
                          classes.compareChip,
                          classes.differenceCompareChip
                        )}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
      </Accordion>
    </>
  );
};

export default ActionCompareItem;
