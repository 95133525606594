import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { IconButton } from "@mui/material";

import {
  EditIcon,
  CopyIcon,
  RemarkIcon,
  DeleteOrderIcon as DeleteIcon
} from "../../../assets/styles/icons";
import {
  copyTrafficItem,
  editTrafficItem,
  selectTrafficItem
} from "../../../business/models/TrafficItem/trafficItemActions";
import { selectIsEditingTrafficItem } from "../../../business/models/TrafficItem/trafficItemSelectors";
import { openConfirmTrafficItemDeleteDialog } from "../../../business/actions/applicationActions";
import { TRAFFIC_PAGE } from "../../../configurations/appConstants";
import {
  isSelectedTrafficDigital,
  isTrafficInEditMode
} from "../../../business/models/TrafficPlan/trafficPlanSelectors";
import TrafficLineTooltip from "./TrafficLineTooltip";
import { canEditTrafficAgentFields } from "../../../business/selectors/userProfileSelectors";
import { classes, Grid, Badge } from "../styles/traficItemItem";

const TrafficItemItemActions = ({
  trafficItemRemarks,
  disableCopy,
  disableEdit,
  disableDelete,
  disableRemark,
  edit,
  copy,
  openConfirmDialog,
  id,
  select,
  isSelectedTrafficDigitalValue,
  marathonOrderId,
  orderNumOfUnits,
  costType,
  netTotal,
  netPerUnit,
  isUserAgent,
  buttonRef,
  open,
  setOpen,
  isDebtorActive
}) => {
  const navigate = useNavigate();
  const {
    agency,
    trafficItemId: urlTrafficItemId,
    planId,
    trafficPlanId
  } = useParams();

  const changeUrl = () => {
    const url = `/${agency}/${TRAFFIC_PAGE}/${planId}/${trafficPlanId}/${id}`;
    if (urlTrafficItemId !== id) navigate(url);
  };

  const handleToggle = e => {
    e.stopPropagation();
    buttonRef.current.focus();
    setOpen(!open);
  };

  const handleEdit = e => {
    e.stopPropagation();
    select(id);
    changeUrl(id);
    edit();
  };

  const handleCopy = e => {
    e.stopPropagation();
    copy(id, navigate);
  };

  const handleDelete = e => {
    e.stopPropagation();
    select(id);
    changeUrl(id);
    openConfirmDialog();
  };

  return (
    <Grid container alignItems="center">
      {isUserAgent && isSelectedTrafficDigitalValue && (
        <Grid item>
          <TrafficLineTooltip
            marathonOrderId={marathonOrderId}
            costType={costType}
            orderNumOfUnits={orderNumOfUnits}
            netTotal={netTotal}
            netPerUnit={netPerUnit}
          ></TrafficLineTooltip>
        </Grid>
      )}
      <Grid item>
        <IconButton
          id="ti_remarks"
          name="ti_remarks"
          className={classes.iconButton}
          disabled={disableRemark}
          onClick={handleToggle}
          ref={buttonRef}
        >
          <Badge
            variant="dot"
            color="secondary"
            invisible={!trafficItemRemarks}
          >
            <RemarkIcon disabled={disableRemark} />
          </Badge>
        </IconButton>
      </Grid>
      {isDebtorActive && (
        <Grid item>
          <IconButton
            id="ti_copy"
            name="ti_copy"
            disabled={disableCopy}
            onClick={handleCopy}
            className={classes.iconButton}
          >
            <CopyIcon disabled={disableCopy} />
          </IconButton>
        </Grid>
      )}
      {isDebtorActive && (
        <Grid item>
          <IconButton
            id="ti_edit"
            name="ti_edit"
            disabled={disableEdit}
            onClick={handleEdit}
            className={classes.iconButton}
          >
            <EditIcon disabled={disableEdit} />
          </IconButton>
        </Grid>
      )}
      {isDebtorActive && (
        <Grid item>
          <IconButton
            id="ti_delete"
            name="ti_delete"
            disabled={disableDelete}
            onClick={handleDelete}
            className={classes.iconButton}
          >
            <DeleteIcon disabled={disableDelete} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

TrafficItemItemActions.propTypes = {
  trafficItemRemarks: PropTypes.string,
  disableCopy: PropTypes.bool,
  disableEdit: PropTypes.bool,
  disableDelete: PropTypes.bool,
  disableRemark: PropTypes.bool,
  edit: PropTypes.func,
  copy: PropTypes.func,
  openConfirmTrafficItemDelete: PropTypes.func,
  id: PropTypes.number,

  select: PropTypes.func,
  changeUrl: PropTypes.func,
  showFinancialOrder: PropTypes.bool
};

const mapStateToProps = (
  state,
  { isPreliminary, isDisabledByStatus, isDisabledByStatusCopy, isDebtorActive }
) => {
  const isEditing = selectIsEditingTrafficItem(state);
  const isTrafficPlanEdit = isTrafficInEditMode(state);

  return {
    disableCopy:
      isTrafficPlanEdit || isEditing || isPreliminary || isDisabledByStatusCopy,
    disableEdit: isTrafficPlanEdit || isEditing || isDisabledByStatus,
    disableDelete: isTrafficPlanEdit || isEditing,
    disableRemark: isTrafficPlanEdit || isEditing || isPreliminary,
    isSelectedTrafficDigitalValue: isSelectedTrafficDigital(state),
    isUserAgent: canEditTrafficAgentFields(state),
    isDebtorActive
  };
};

const mapDispatchToProps = dispatch => ({
  copy: (id, navigate) => dispatch(copyTrafficItem(id, navigate)),
  edit: () => dispatch(editTrafficItem()),
  openConfirmDialog: () => dispatch(openConfirmTrafficItemDeleteDialog()),
  select: id => dispatch(selectTrafficItem(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrafficItemItemActions);
