import { styled, Dialog as MuiDialog } from "@mui/material";
import {
  crusta,
  dodgerBlue,
  white,
  deYork,
  linkWater
} from "../../../../assets/styles/colors";

const PREFIX = "ProjectDialogStyle";
export const classes = {
  closeButton: `${PREFIX}-closeButton`,
  saveButton: `${PREFIX}-saveButton`,
  approveSelectionButton: `${PREFIX}-approveSelectionButton`,
  declineSelectionButton: `${PREFIX}-declineSelectionButton`,
  doneButton: `${PREFIX}-doneButton`
};

export const Dialog = styled(MuiDialog)(() => ({
  [`& .${classes.closeButton}`]: {
    color: crusta
  },
  [`& .${classes.saveButton}`]: {
    color: dodgerBlue,
    "&:disabled": {
      color: linkWater
    }
  },
  [`& .${classes.approveSelectionButton}`]: {
    backgroundColor: dodgerBlue,
    color: white
  },
  [`& .${classes.declineSelectionButton}`]: {
    backgroundColor: crusta,
    color: white
  },
  [`& .${classes.typographyInvoicedInsertionHeader}`]: {
    backgroundColor: deYork,
    color: white
  }
}));
