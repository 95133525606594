export const CLEAR_CREATE_PAGE = "CLEAR_CREATE_PAGE";
export const OPEN_CAMPAIGN_FORM_PAGE = "OPEN_CAMPAIGN_FORM_PAGE";
export const CLOSE_CAMPAIGN_FORM_PAGE = "CLOSE_CAMPAIGN_FORM_PAGE";
export const OPEN_PLAN_FORM_PAGE = "OPEN_PLAN_FORM_PAGE";
export const CLOSE_PLAN_FORM_PAGE = "CLOSE_PLAN_FORM_PAGE";
export const OPEN_ORDER_FORM_PAGE = "OPEN_ORDER_FORM_PAGE";
export const CLOSE_ORDER_FORM_PAGE = "CLOSE_ORDER_FORM_PAGE";
export const SET_ORDER_BUYING_STATUSES = "SET_ORDER_BUYING_STATUSES";
export const OPEN_CLIENT_STATUS_LOG_DIALOG = "OPEN_CLIENT_STATUS_LOG_DIALOG";
export const CLOSE_CLIENT_STATUS_LOG_DIALOG = "CLOSE_CLIENT_STATUS_LOG_DIALOG";
export const OPEN_PROJECT_DIALOG = "OPEN_PROJECT_DIALOG";
export const CLOSE_PROJECT_DIALOG = "CLOSE_PROJECT_DIALOG";
export const OPEN_ORDER_BUYING_STATUSES_DIALOG =
  "OPEN_ORDER_BUYING_STATUSES_DIALOG";
export const CLOSE_ORDER_BUYING_STATUSES_DIALOG =
  "  CLOSE_ORDER_BUYING_STATUSES_DIALOG";
export const OPEN_SET_CAMPAIGN_ACTUAL_DIALOG =
  "  OPEN_SET_CAMPAIGN_ACTUAL_DIALOG";
export const CLOSE_SET_CAMPAIGN_ACTUAL_DIALOG =
  "  CLOSE_SET_CAMPAIGN_ACTUAL_DIALOG";
export const OPEN_SET_ACTUAL_DIALOG = "  OPEN_SET_ACTUAL_DIALOG";
export const CLOSE_SET_ACTUAL_DIALOG = "  CLOSE_SET_ACTUAL_DIALOG";
export const OPEN_PLAN_EXTENSION_FORM_PAGE = "OPEN_PLAN_EXTENSION_FORM_PAGE";
export const CLOSE_PLAN_EXTENSION_FORM_PAGE = "CLOSE_PLAN_EXTENSION_FORM_PAGE";
export const OPEN_PLAN_EXPORT_DIALOG = "OPEN_PLAN_EXPORT_DIALOG";
export const CLOSE_PLAN_EXPORT_DIALOG = "CLOSE_PLAN_EXPORT_DIALOG";
export const UPDATE_PLANS_WITH_INSERTION_CHANGE =
  "UPDATE_PLANS_WITH_INSERTION_CHANGE";
export const UPDATE_ORDERS_WITH_INSERTION_CHANGE =
  "UPDATE_ORDERS_WITH_INSERTION_CHANGE";
export const UPDATE_INSERTIONS_WITH_CHANGE = "UPDATE_INSERTIONS_WITH_CHANGE";

export const SET_CAMPAIGN_STATUS = "SET_CAMPAIGN_STATUS";
export const RESET_CAMPAIGN_STATUS = "RESET_CAMPAIGN_STATUS";
export const CLEAR_STEPPER = "CLEAR_STEPPER";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const RESET_ACTIVE_STEP = "RESET_ACTIVE_STEP";
export const SET_SKIPPED = "SET_SKIPPED";
export const CLEAR_SKIPPED = "CLEAR_SKIPPED";
export const SET_STEPS = "SET_STEPS";
export const RESET_STEPS = "RESET_STEPS";
export const SET_PROCESS = "SET_PROCESS";
export const CLEAR_PROCESS = "CLEAR_PROCESS";
export function getSteps() {
  return ["Select Campaign", "Create Plan", "Create Order"];
}
