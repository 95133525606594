import {
  styled,
  Accordion as MuiAccordion,
  Popper as MuiPopper
} from "@mui/material";
import { getThemeSpacing } from "../../../../functions/util";
import {
  baliHai,
  solitude,
  supernova,
  matisse,
  black,
  linkWater
} from "../../../../assets/styles/colors";

const PREFIX = "OrderPanelStyles";
export const classes = {
  expensionPanel: `${PREFIX}-expensionPanel`,
  typographyOrderHeader: `${PREFIX}-typographyOrderHeader`,
  typographyCompareHeader: `${PREFIX}-typographyCompareHeader`,
  chip: `${PREFIX}-chip`,
  compareChip: `${PREFIX}-compareChip`,
  differenceCompareChip: `${PREFIX}-differenceCompareChip`,
  accordionSummary: `${PREFIX}-accordionSummary`,
  expensionDetailsTablePanel: `${PREFIX}-expensionDetailsTablePanel`
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`&.${classes.expensionPanel}`]: {
    marginBottom: theme.typography.pxToRem(getThemeSpacing(theme, 3))
  },
  [`& .${classes.typographyOrderHeader}`]: {
    color: matisse,
    width: theme.typography.pxToRem(195),
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`& .${classes.typographyCompareHeader}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`& .${classes.chip}`]: {
    color: baliHai,
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    minWidth: theme.typography.pxToRem(80),
    backgroundColor: solitude
  },
  [`& .${classes.compareChip}`]: {
    width: theme.typography.pxToRem(150)
  },
  [`& .${classes.differenceCompareChip}`]: {
    color: black,
    backgroundColor: supernova
  },
  [`& .${classes.expensionDetailsTablePanel}`]: {
    paddingTop: 0
  },
  [`& .${classes.accordionSummary}`]: {
    height: theme.typography.pxToRem(40),
    [`& .MuiAccordionSummary-content`]: {
      marginBottom: 0
    }
  }
}));
