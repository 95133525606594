import {
  LOAD_API_DATA_SUCCESS,
  LOAD_API_DATA_ERROR,
  BEGIN_API_DATA_CALL
} from "../constants/apiDataConstants";

export const apiDataReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_API_DATA_SUCCESS:
      return {
        ...state,
        [action.id]: action.response
      };
    default:
      return state;
  }
};

export const apiDataFlagReducer = (state = [], action) => {
  switch (action.type) {
    case BEGIN_API_DATA_CALL:
      return [...state, action.id];
    case LOAD_API_DATA_SUCCESS:
    case LOAD_API_DATA_ERROR:
      return state.filter(item => item !== action.id);
    default:
      return state;
  }
};
