import {
  OPEN_ADMANAGEMENT_DIALOG,
  CLOSE_ADMANAGEMENT_DIALOG
} from "../constants/trafficDashboardPageConstants";

export const openAdmanagementDialog = () => ({
  type: OPEN_ADMANAGEMENT_DIALOG
});

export const closeAdmanagementDialog = () => ({
  type: CLOSE_ADMANAGEMENT_DIALOG
});
