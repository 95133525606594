import React from "react";
import { useSelector, useDispatch } from "react-redux";

import clsx from "clsx";

import { Button } from "@mui/material";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import { addNewSystemMessage } from "../../../../business/models/SystemMessage/systemMessageActions";

import { classes, Grid } from "../styles/systemMessagesActions";

const SystemMessagesActions = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);

  const addNewSystemMsg = () => dispatch(addNewSystemMessage());

  return (
    // <Grid container justifyContent="space-between" className={classes.root}>TODO(AM)
    <Grid container justifyContent="space-between">
      <Grid item />
      <Grid item>
        <Button
          id="sm_add"
          name="sm_add"
          variant="contained"
          className={clsx(classes.saveButton, classes.markedButton)}
          disabled={isLoading}
          onClick={addNewSystemMsg}
        >
          ADD MESSAGE
        </Button>
      </Grid>
    </Grid>
  );
};

export default SystemMessagesActions;
