import { styled, Accordion as MuiAccordion } from "@mui/material";
import {
  baliHai,
  matisse,
  linkWater,
  azureRadiance,
  dodgerBlue
} from "../../../../../../assets/styles/colors";

const PREFIX = "ActionCampaignPanelStyle";
export const classes = {
  root: `${PREFIX}-root`,
  selected: `${PREFIX}-selected`,
  typographyHeaderCaption: `${PREFIX}-typographyHeaderCaption`,
  typographyHeaderValue: `${PREFIX}-typographyHeaderValue`,
  typographyValue: `${PREFIX}-typographyValue`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  dateWidth: `${PREFIX}-dateWidth`,
  budgetWidth: `${PREFIX}-budgetWidth`,
  ctcInfoGridItemAlign: `${PREFIX}-ctcInfoGridItemAlign`,
  typographyMarked: `${PREFIX}-typographyMarked`
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`&.${classes.selected}`]: {
    borderColor: dodgerBlue
  },
  [`& .${classes.typographyHeaderCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyHeaderValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.dateWidth}`]: {
    width: theme.typography.pxToRem(200)
  },
  [`& .${classes.budgetWidth}`]: {
    width: theme.typography.pxToRem(200)
  },
  [`& .${classes.ctcInfoGridItemAlign}`]: {
    textAlign: "end"
  },
  [`& .${classes.typographyMarked}`]: {
    color: azureRadiance
  }
}));
