import { styled, Dialog as MuiDialog } from "@mui/material";
import { crusta } from "../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../functions/util";

const PREFIX = "OrderBuyingStatusesDialogStyles";
export const classes = {
  closeButton: `${PREFIX}-closeButton`,
  action: `${PREFIX}-action`
};

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    color: crusta
  },
  [`& .${classes.action}`]: {
    borderTop: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.typography.pxToRem(getThemeSpacing(theme, 2))
  }
}));
