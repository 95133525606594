import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { setPlanChangeOverviewValue } from "../../business/models/PlanChangeOverview/planChangeOverviewActions";

import {
  getPlanChangeOverviewPropertyValue,
  getPlanChangeOverviewPropertyErrorsByProperty,
  selectPlanChangeOverviewDisplayErrors
} from "../../business/models/PlanChangeOverview/planChangeOverviewSelectors";

import Controls from "./controls/index";

const PlanChangeOverviewField = props => {
  const { component, entityId, name, id, onChange, ...componentProps } = props;

  const dispatch = useDispatch();
  const value = useSelector(state =>
    getPlanChangeOverviewPropertyValue(state, props)
  );
  const errors = useSelector(state =>
    getPlanChangeOverviewPropertyErrorsByProperty(state, props)
  );
  const displayErrors = useSelector(selectPlanChangeOverviewDisplayErrors);

  const handleChange = val =>
    dispatch(setPlanChangeOverviewValue(entityId, { [name]: val }));

  const Control = Controls[component];

  return (
    <Control
      value={value}
      errors={errors}
      displayErrors={displayErrors}
      component={component}
      id={`${entityId}-${id}`}
      name={name}
      {...componentProps}
      onChange={handleChange}
    />
  );
};

PlanChangeOverviewField.propTypes = {
  component: PropTypes.oneOf(["text", "date", "decimal"]),
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default PlanChangeOverviewField;
