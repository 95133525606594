import React from "react";

import clsx from "clsx";

import { Chip, Typography, Tooltip } from "@mui/material";
import ClipboardTooltip from "../../../../../shared/ClipboardTooltip";
import { parseDate } from "../../../../../../business/models/common/utils/clientUtils";
import { PLACEHOLDER } from "../../../../../../configurations/appConstants";
import { classes, Grid } from "../../../../styles/compact";

const CompactCinema = ({ item, onClipboardCopy }) => {
  const {
    orderDescription,
    spotLength,
    orderStartDate,
    orderEndDate,
    title,
    id
  } = item;
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Tooltip
          title={
            <ClipboardTooltip onButtonClick={onClipboardCopy} text={title} />
          }
          placement="bottom-start"
        >
          <Chip
            classes={{ label: classes.chipHeaderLarge }}
            label={
              <Typography
                id={`title_${id}`}
                name={`title_${id}`}
                className={clsx(classes.typography, classes.inheritWidth)}
                align="center"
                noWrap
              >
                {title || PLACEHOLDER}
              </Typography>
            }
            className={classes.chipTraffic}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <ClipboardTooltip
              onButtonClick={onClipboardCopy}
              text={orderDescription}
            />
          }
          placement="bottom-start"
        >
          <Chip
            classes={{ label: classes.chipHeader }}
            label={
              <Typography
                id={`orderDescription_${id}`}
                name={`orderDescription_${id}`}
                className={clsx(classes.typography, classes.inheritWidth)}
                align="center"
                noWrap
              >
                {orderDescription || PLACEHOLDER}
              </Typography>
            }
            className={classes.chipTraffic}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Chip
          classes={{ label: classes.chipHeader }}
          label={
            <Typography
              id={`orderStartDate_${id}`}
              name={`orderStartDate_${id}`}
              className={clsx(classes.typography, classes.inheritWidth)}
              align="center"
              noWrap
            >
              {parseDate(orderStartDate)}
            </Typography>
          }
          className={classes.chipTraffic}
        />
      </Grid>
      <Grid item>
        <Chip
          classes={{ label: classes.chipHeader }}
          label={
            <Typography
              id={`orderEndDate_${id}`}
              name={`orderEndDate_${id}`}
              className={clsx(classes.typography, classes.inheritWidth)}
              align="center"
              noWrap
            >
              {parseDate(orderEndDate)}
            </Typography>
          }
          className={classes.chipTraffic}
        />
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <ClipboardTooltip
              onButtonClick={onClipboardCopy}
              text={spotLength}
            />
          }
          placement="bottom-start"
        >
          <Chip
            classes={{ label: classes.chipHeaderLarge }}
            label={
              <Typography
                id={`spotLength_${id}`}
                name={`spotLength_${id}`}
                className={clsx(classes.typography, classes.inheritWidth)}
                align="center"
                noWrap
              >
                {spotLength || PLACEHOLDER}
              </Typography>
            }
            className={classes.chipTraffic}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default CompactCinema;
