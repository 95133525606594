export const USER_SELECT = "USER_SELECT";
export const USER_EDIT_START = "USER_EDIT_START";
export const USER_EDIT_STOP = "USER_EDIT_STOP";
export const USER_CLEAR_CURRENT = "USER_CLEAR_CURRENT";
export const USER_DISPLAY_ERRORS = "USER_DISPLAY_ERRORS";
export const USER_HIDE_ERRORS = "USER_HIDE_ERRORS";
export const USER_SET_SEARCH_TERM = "USER_SET_SEARCH_TERM";
export const USER_SET_SEARCH_PROPERTY = "USER_SET_SEARCH_PROPERTY";
export const USER_SET_IS_NEW = "USER_SET_IS_NEW";
export const USER_RESET_IS_NEW = "USER_RESET_IS_NEW";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const USER_SUCCESS_MESSAGE = "User saved successfully";
export const USER_MODEL_NAME = "User";
export const USER_MODEL_ID = "userId";
export const USER_ACTIVE_TOGGLE = "USER_ACTIVE_TOGGLE";
export const DEFAULT_AGENT_FOR_IDS_OPTIONS = [
  { key: 1, text: "GroupM" },
  { key: 5, text: "Kinetic" },
  { key: 2, text: "Mindshare" },
  { key: 6, text: "EssenceMediacom" },
  { key: 7, text: "Wavemaker" },
  { key: 8, text: "Blossom" },
  { key: 9, text: "Greenhouse One" },
  { key: 10, text: "Greenhouse Tech" },
  { key: 11, text: "Choreograph Create" }
];
