import React, { memo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Grid
} from "@mui/material";

import {
  selectIsLoading,
  selectOpenTrafficItemMultipleEditDialog
} from "../../../../business/selectors/applicationSelectors";
import { closeTrafficItemMultipleEditDialog } from "../../../../business/actions/applicationActions";
import {
  selectSelectedTrafficPlanMediaType,
  getSelectedTraffic
} from "../../../../business/models/TrafficPlan/trafficPlanSelectors";
import {
  MEDIATYPE_CINEMA,
  MEDIATYPE_DIGITAL,
  MEDIATYPE_INVENTORY,
  MEDIATYPE_OOH,
  MEDIATYPE_PRINT,
  MEDIATYPE_RADIO,
  MEDIATYPE_TV
} from "../../../../configurations/appConstants";
import Form from "../../../panels/extensions/TrafficItemMultipleEditForm";
import {
  isTrafficItemInEditMode,
  multipleSelectTrafficItemsHasPreliminaryItem
} from "../../../../business/models/TrafficItem/trafficItemSelectors";
import {
  canEditTrafficAgencyFields,
  canEditTrafficAgentFields,
  selectUserIsAdmin,
  canViewTrafficManagementPage
} from "../../../../business/selectors/userProfileSelectors";
import { cancelMultipleTrafficItems } from "../../../../business/models/TrafficItem/trafficItemActions";
import DialogTitle from "../../../shared/dialog/DialogTitle";
import { updateTrafficItemMultiple } from "../../../../business/models/TrafficPlan/trafficPlanActions";
import TrafficDigitalTemplateSelect from "../../../form/TrafficDigitalTemplateSelect";
import useTemplateData from "../../../form/hooks/useTemplateData";

const TrafficItemMultipleEditDialog = ({
  open,
  isLoading,
  closeDialog,
  mediaTypeId,
  editMode,
  updateMultiple,
  cancel,
  areTrafficAgencyFieldsDisabled,
  areTrafficAgentFieldsDisabled,
  canViewManageDigitalTrafficTemplate,
  isAdmin,
  selectedMultipleEditHasPreliminaryItem,
  selectedTraffic
}) => {
  useTemplateData();

  const { isDebtorActive } = selectedTraffic || false;

  const isTemplateSelectVisible =
    (mediaTypeId === MEDIATYPE_DIGITAL ||
      mediaTypeId === MEDIATYPE_INVENTORY) &&
    canViewManageDigitalTrafficTemplate;

  const isTemplateSelectDisabled =
    !isAdmin && selectedMultipleEditHasPreliminaryItem;

  const handleOk = () => {
    updateMultiple().then(isSuccessful => {
      if (isSuccessful) {
        closeDialog();
      }
    });
  };

  const handleCancel = () => {
    cancel();
    closeDialog();
  };

  const paperProps = { square: true };
  return (
    <Dialog fullWidth open={open} PaperProps={paperProps} maxWidth={"md"}>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            Edit Multiple Traffic Items
            <Typography variant="caption" display="block">
              * Leave properties empty if you want to keep existing values.
            </Typography>
          </Grid>
          <Grid item>
            {isTemplateSelectVisible && (
              <TrafficDigitalTemplateSelect
                disabled={isTemplateSelectDisabled || !isDebtorActive}
              />
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Form
          mediaTypeId={mediaTypeId}
          editMode={editMode}
          areTrafficAgencyFieldsDisabled={areTrafficAgencyFieldsDisabled}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel_multiple-edit"
          name="cancel_multiple-edit"
          onClick={handleCancel}
          disabled={isLoading}
          color="primary"
        >
          Cancel
        </Button>
        {isDebtorActive && (
          <Button
            id="save_multiple-edit"
            name="save_multiple-edit"
            onClick={handleOk}
            disabled={isLoading}
            color="secondary"
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

TrafficItemMultipleEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  mediaTypeId: PropTypes.oneOf([
    MEDIATYPE_CINEMA,
    MEDIATYPE_DIGITAL,
    MEDIATYPE_OOH,
    MEDIATYPE_PRINT,
    MEDIATYPE_RADIO,
    MEDIATYPE_TV,
    MEDIATYPE_INVENTORY
  ])
};

TrafficItemMultipleEditDialog.defaultProps = {};

const mapStateToProps = state => ({
  open: selectOpenTrafficItemMultipleEditDialog(state),
  isLoading: selectIsLoading(state),
  mediaTypeId: selectSelectedTrafficPlanMediaType(state),
  editMode: isTrafficItemInEditMode(state),
  areTrafficAgencyFieldsDisabled: !canEditTrafficAgencyFields(state),
  areTrafficAgentFieldsDisabled: !canEditTrafficAgentFields(state),
  canViewManageDigitalTrafficTemplate: canViewTrafficManagementPage(state),
  isAdmin: selectUserIsAdmin(state),
  selectedMultipleEditHasPreliminaryItem: multipleSelectTrafficItemsHasPreliminaryItem(
    state
  ),
  selectedTraffic: getSelectedTraffic(state)
});

const mapDispatchToProps = dispatch => ({
  closeDialog: () => dispatch(closeTrafficItemMultipleEditDialog()),
  updateMultiple: () => dispatch(updateTrafficItemMultiple()),
  cancel: () => dispatch(cancelMultipleTrafficItems())
});

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(TrafficItemMultipleEditDialog)
);
