import toLower from "lodash/toLower";

import {
  CAMPAIGN,
  PLAN,
  AGENCY_MINDSHARE,
  AGENCY_KINETIC,
  AGENCY_WAVEMAKER,
  AGENCY_GROUPM,
  AGENCY_ESSENCEMEDIACOM,
  AGENCY_BLOSSOM,
  AGENCY_GREENHOUSEONE,
  MINDSHARE_AGENCY,
  KINETIC_AGENCY,
  WAVEMAKER_AGENCY,
  GROUPM_AGENCY,
  ESSENCEMEDIACOM_AGENCY,
  BLOSSOM_AGENCY,
  GREENHOUSEONE_AGENCY,
  MINDSHARE_AGENCY_ID,
  KINETIC_AGENCY_ID,
  WAVEMAKER_AGENCY_ID,
  GROUPM_AGENCY_ID,
  ESSENCEMEDIACOM_AGENCY_ID,
  BLOSSOM_AGENCY_ID,
  GREENHOUSEONE_AGENCY_ID,
  NOTIFICATION_TYPE_REMARKS,
  NOTIFICATION_TYPE_REMARKS_URL,
  NOTIFICATION_TYPE_ORDERS_APPROVED,
  NOTIFICATION_TYPE_ORDERS_CHANGED,
  NOTIFICATION_TYPE_ORDERS_TO_BE_CANCELLED,
  NOTIFICATION_TYPE_ORDERS_APPROVED_URL,
  NOTIFICATION_TYPE_ORDERS_PENDING_FOR_APPROVAL,
  NOTIFICATION_TYPE_ORDERS_ADDITIONAL_APPROVED_URL,
  GREENHOUSETECH_AGENCY_ID,
  GREENHOUSETECH_AGENCY,
  CHOREOGRAPHCREATE_AGENCY,
  CHOREOGRAPHCREATE_AGENCY_ID,
  AGENCY_GREENHOUSETECH,
  AGENCY_CHOREOGRAPHCREATE
} from "../../../../configurations/appConstants";

export const selectAgencyId = type => {
  switch (toLower(type)) {
    case MINDSHARE_AGENCY:
      return MINDSHARE_AGENCY_ID;
    case KINETIC_AGENCY:
      return KINETIC_AGENCY_ID;
    case WAVEMAKER_AGENCY:
      return WAVEMAKER_AGENCY_ID;
    case GROUPM_AGENCY:
      return GROUPM_AGENCY_ID;
    case ESSENCEMEDIACOM_AGENCY:
      return ESSENCEMEDIACOM_AGENCY_ID;
    case BLOSSOM_AGENCY:
      return BLOSSOM_AGENCY_ID;
    case GREENHOUSEONE_AGENCY:
      return GREENHOUSEONE_AGENCY_ID;
    case GREENHOUSETECH_AGENCY:
      return GREENHOUSETECH_AGENCY_ID;
    case CHOREOGRAPHCREATE_AGENCY:
      return CHOREOGRAPHCREATE_AGENCY_ID;
    default:
      return null;
  }
};

export const selectAgencyName = type => {
  switch (type) {
    case MINDSHARE_AGENCY_ID:
      return MINDSHARE_AGENCY;
    case KINETIC_AGENCY_ID:
      return KINETIC_AGENCY;
    case WAVEMAKER_AGENCY_ID:
      return WAVEMAKER_AGENCY;
    case GROUPM_AGENCY_ID:
      return GROUPM_AGENCY;
    case ESSENCEMEDIACOM_AGENCY_ID:
      return ESSENCEMEDIACOM_AGENCY;
    case BLOSSOM_AGENCY_ID:
      return BLOSSOM_AGENCY;
    case GREENHOUSEONE_AGENCY_ID:
      return GREENHOUSEONE_AGENCY;
    case GREENHOUSETECH_AGENCY_ID:
      return GREENHOUSETECH_AGENCY;
    case CHOREOGRAPHCREATE_AGENCY_ID:
      return CHOREOGRAPHCREATE_AGENCY;
    default:
      return "";
  }
};

export const selectCapitalizeAgencyName = type => {
  switch (type) {
    case MINDSHARE_AGENCY_ID:
      return AGENCY_MINDSHARE;
    case KINETIC_AGENCY_ID:
      return AGENCY_KINETIC;
    case WAVEMAKER_AGENCY_ID:
      return AGENCY_WAVEMAKER;
    case GROUPM_AGENCY_ID:
      return AGENCY_GROUPM;
    case ESSENCEMEDIACOM_AGENCY_ID:
      return AGENCY_ESSENCEMEDIACOM;
    case BLOSSOM_AGENCY_ID:
      return AGENCY_BLOSSOM;
    case GREENHOUSEONE_AGENCY_ID:
      return AGENCY_GREENHOUSEONE;
    case GREENHOUSETECH_AGENCY_ID:
      return AGENCY_GREENHOUSETECH;
    case CHOREOGRAPHCREATE_AGENCY_ID:
      return AGENCY_CHOREOGRAPHCREATE;
    default:
      return "";
  }
};

export const getNotificationRedirectUrl = (
  typeId,
  campaignId,
  planId,
  trafficPlanId,
  marathonOrderIds,
  marathonPlanId
) => {
  switch (typeId) {
    case NOTIFICATION_TYPE_REMARKS:
      return `${NOTIFICATION_TYPE_REMARKS_URL}/${planId}/${trafficPlanId}`;
    case NOTIFICATION_TYPE_ORDERS_APPROVED:
    case NOTIFICATION_TYPE_ORDERS_CHANGED:
    case NOTIFICATION_TYPE_ORDERS_TO_BE_CANCELLED:
      return `${NOTIFICATION_TYPE_ORDERS_APPROVED_URL}/${CAMPAIGN}/${campaignId}/${PLAN}/${planId}`;
    case NOTIFICATION_TYPE_ORDERS_PENDING_FOR_APPROVAL:
      return marathonOrderIds.length > 1
        ? `${NOTIFICATION_TYPE_ORDERS_ADDITIONAL_APPROVED_URL}?planid=${marathonPlanId}`
        : `${NOTIFICATION_TYPE_ORDERS_ADDITIONAL_APPROVED_URL}?orderid=${marathonOrderIds}`;
    default:
      return "";
  }
};
