import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import clsx from "clsx";

import HeaderBar from "./HeaderBar";
import FooterBar from "./FooterBar";
import SideBar from "./SideBar";
import SystemNotificationDialog from "./SystemNotificationDialog";
import Main from "./Main";
import ErrorNotifier from "../../shared/ErrorNotifier";
import SuccessNotifier from "../../shared/SuccessNotifier";
import { EMPTY_STRING } from "../../../configurations/appConstants";
import { loadUserProfileData } from "../../../business/actions/userProfileActions";

import {
  setAgency,
  clearAgency
} from "../../../business/actions/applicationActions";
import { loadNotifications } from "../../../business/actions/notificationsActions";
import { selectOpenDrawer } from "../../../business/selectors/applicationSelectors";

import { classes, MuiMain } from "./styles/layoutStyles";
import { selectAgencyId } from "../../../business/models/common/utils/appUtils";

const Layout = ({ agencyID }) => {
  const dispatch = useDispatch();
  const { agency } = useParams();
  const open = useSelector(selectOpenDrawer);

  useEffect(() => {
    const agencyFromUrl = agency || EMPTY_STRING;
    const agencyIdFromUrl = selectAgencyId(agencyFromUrl);

    if (agencyID !== agencyIdFromUrl) {
      dispatch(setAgency(agencyIdFromUrl));
      dispatch(loadUserProfileData());
    }
    dispatch(loadNotifications());
    return () => {
      dispatch(clearAgency());
    };
  }, [agency, agencyID, dispatch]);

  return (
    <>
      <ErrorNotifier />
      <SuccessNotifier />
      <HeaderBar />
      <SideBar />
      <MuiMain
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.offset} />
        <Main />
        <div className={classes.offset} />
      </MuiMain>
      <FooterBar />
      <SystemNotificationDialog />
    </>
  );
};

export default Layout;
