export const SET_APPROVAL_OVERVIEW_COUNT = "SET_APPROVAL_OVERVIEW_COUNT";
export const SET_APPROVAL_OVERVIEW_ITEMS = "SET_APPROVAL_OVERVIEW_ITEMS";
export const SET_CURRENT_CONDITION = "SET_CURRENT_CONDITION";
export const RESET_APPROVAL_OVERVIEW_PAGE = "RESET_APPROVAL_OVERVIEW_PAGE";

export const RESET_APPROVAL_DETAIL_PAGE = "RESET_APPROVAL_DETAIL_PAGE";
export const APPROVE_ALL_ORDERS_MESSAGE =
  "Select this option if you want to approve all Orders in parent Plan that triggered this rule";

export const DECLINE_ALL_ORDERS_MESSAGE =
  "Select this option if you want to decline all Orders in parent Plan that triggered this rule";

export const ADDITIONAL_ACTION_SUCCESS = "Task completed successfully";

export const OPEN_APPROVAL_DIALOG = "OPEN_APPROVAL_DIALOG";
export const CLOSE_APPROVAL_DIALOG = "CLOSE_APPROVAL_DIALOG";
export const SET_APPROVAL_ACTION = "SET_APPROVAL_ACTION";
export const RESET_APPROVAL_ACTION = "RESET_APPROVAL_ACTION";
export const APPROVE = "approve";
export const DECLINE = "decline";
