import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

class Option extends React.Component {
  render() {
    const {
      innerRef,
      isFocused,
      isSelected,
      innerProps,
      children
    } = this.props;
    const style = {
      fontWeight: isSelected ? 500 : 400
    };
    return (
      <MenuItem
        ref={innerRef}
        selected={isFocused}
        component="div"
        style={style}
        {...innerProps}
      >
        {children}
      </MenuItem>
    );
  }
}

Option.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerProps: PropTypes.object,
  children: PropTypes.string
};

Option.defaultProps = {
  children: ""
};

export default Option;
