import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getCampaignCustomPropertyValue,
  getCampaignPropertyErrors,
  selectCampaignDisplayErrors
} from "../../business/models/Campaign/campaignSelectors";

import Controls from "./controls/index";

class CampaignCustomField extends Component {
  handleChange = value => {
    const { name, onChange } = this.props;
    onChange({ [name]: value });
  };

  render() {
    const { component, ...componentProps } = this.props;
    const Control = Controls[component];
    return (
      <Control
        component={component}
        {...componentProps}
        onChange={this.handleChange}
      />
    );
  }
}

CampaignCustomField.propTypes = {
  component: PropTypes.oneOf(["text"]),
  name: PropTypes.string.isRequired
};

CampaignCustomField.defaultProps = {
  errors: []
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getCampaignCustomPropertyValue(state, ownProps),
    errors: getCampaignPropertyErrors(state, ownProps),
    displayErrors: selectCampaignDisplayErrors(state)
  };
};

export default connect(mapStateToProps, null)(CampaignCustomField);
