import { createSelector } from "reselect";

const overviewPage = state => state.overviewPage;
const selectTable = state => state.overviewPage.table;

export const selectItems = createSelector(selectTable, table => table.items);

export const selectCount = createSelector(selectTable, table => table.count);

export const selectTabCounters = createSelector(
  overviewPage,
  overview => overview.tabCounters
);

export const selectCurrentlySaveSearch = createSelector(
  overviewPage,
  overview => overview.selectedSaveSearch
);
