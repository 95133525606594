import {
  styled,
  Accordion as MuiAccordion,
  Badge as MuiBadge
} from "@mui/material";
import {
  dodgerBlue,
  matisse,
  sapphire,
  crusta,
  baliHai,
  linkWater
} from "../../../assets/styles/colors";
import { getThemeSpacing } from "../../../functions/util";

const PREFIX = "ItemStyles";
export const classes = {
  expansionPanel: `${PREFIX}-expansionPanel`,
  expensionPanel: `${PREFIX}-expensionPanel`,
  selected: `${PREFIX}-selected`,
  summaryOrderPanel: `${PREFIX}-summaryOrderPanel`,
  expensionDetailsTablePanel: `${PREFIX}-expensionDetailsTablePanel`,
  copyProcess: `${PREFIX}-copyProcess`,
  expansionPanelSummeryContent: `${PREFIX}-expansionPanelSummeryContent`,
  expansionPanelSummeryRoot: `${PREFIX}-expansionPanelSummeryRoot`,
  gridItemMediaTypeName: `${PREFIX}-gridItemMediaTypeName`,
  typographyPlanHeaderCaption: `${PREFIX}-typographyPlanHeaderCaption`,
  activeTypographyPlanHeaderCaption: `${PREFIX}-activeTypographyPlanHeaderCaption`,
  iconButton: `${PREFIX}-iconButton`,
  iconButtonRemark: `${PREFIX}-iconButtonRemark`,
  typographyTrafficPlanHeaderValue: `${PREFIX}-typographyTrafficPlanHeaderValue`,
  activeTypographyTrafficPlanHeaderValue: `${PREFIX}-activeTypographyTrafficPlanHeaderValue`,
  finalizeTypographyTrafficPlanHeaderValue: `${PREFIX}-finalizeTypographyTrafficPlanHeaderValue`,
  expansionDetailsTablePanelTrafficItem: `${PREFIX}-expansionDetailsTablePanelTrafficItem`,
  ExpansionDetailsAction: `${PREFIX}-ExpansionDetailsAction`,
  typographyHeaderCaption: `${PREFIX}-typographyHeaderCaption`,
  typographyHeaderValue: `${PREFIX}-typographyHeaderValue`,
  dateWidth: `${PREFIX}-dateWidth`,
  typographyValue: `${PREFIX}-typographyValue`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  actionContainer: `${PREFIX}-actionContainer`
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`&.${classes.expansionPanel}`]: {
    marginBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`&.${classes.selected}`]: {
    borderColor: dodgerBlue
  },
  [`&.${classes.copyProcess}`]: {
    backgroundColor: "lavender"
  },
  [`&.${classes.expensionPanel}`]: {
    marginBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.summaryOrderPanel}`]: {
    height: theme.typography.pxToRem(48)
  },
  [`& .${classes.expensionDetailsTablePanel}`]: {
    paddingTop: theme.typography.pxToRem(0),
    paddingBottom: theme.typography.pxToRem(4)
  },
  [`& .${classes.expansionPanelSummeryContent}`]: {
    width: "-webkit-fill-available"
  },
  [`& .${classes.expansionPanelSummeryRoot}`]: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.typography.pxToRem(0),
      paddingRight: theme.typography.pxToRem(0)
    }
  },
  [`& .${classes.gridItemMediaTypeName}`]: {
    width: theme.typography.pxToRem(58)
  },
  [`& .${classes.typographyPlanHeaderCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(12),
    paddingLeft: theme.typography.pxToRem(getThemeSpacing(theme, 0.5))
  },
  [`& .${classes.activeTypographyPlanHeaderCaption}`]: {
    color: matisse
  },
  [`& .${classes.iconButton}`]: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 1))
  },
  [`& .${classes.iconButtonRemark}`]: {
    marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 2))
  },
  [`& .${classes.typographyTrafficPlanHeaderValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(18)
  },
  [`& .${classes.activeTypographyTrafficPlanHeaderValue}`]: {
    color: sapphire
  },
  [`& .${classes.finalizeTypographyTrafficPlanHeaderValue}`]: {
    color: crusta
  },
  [`& .${classes.expansionDetailsTablePanelTrafficItem}`]: {
    paddingTop: theme.typography.pxToRem(0),
    paddingBottom: theme.typography.pxToRem(100)
  },
  [`& .${classes.ExpansionDetailsAction}`]: {
    paddingTop: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyHeaderCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyHeaderValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.dateWidth}`]: {
    width: theme.typography.pxToRem(200)
  },
  [`& .${classes.typographyValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.actionContainer}`]: {
    height: "100%"
  }
}));

export const Badge = styled(MuiBadge)(({ theme }) => ({
  "& .MuiBadge-dot": {
    height: 8,
    minWidth: 8,
    borderRadius: 5
  }
}));
