import { styled, Grid as MuiGrid } from "@mui/material";

const PREFIX = "ClipboardTooltipStyles";
export const classes = {
  tooltipCopyButton: `${PREFIX}-tooltipCopyButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.tooltipCopyButton}`]: {
    height: theme.typography.pxToRem(22),
    width: theme.typography.pxToRem(22),
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
      opacity: 0.9
    }
  }
}));
