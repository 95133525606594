import objectHash from "object-hash";
import get from "lodash/get";
import set from "lodash/set";
import reduce from "lodash/reduce";
import every from "lodash/every";
import find from "lodash/find";
import indexOf from "lodash/indexOf";
import replace from "lodash/replace";
import size from "lodash/size";
import some from "lodash/some";
import jsonQuery from "json-query";
import generateUrl from "./urlUtils";
import {
  isNullOrUndefined,
  isNullOrUndefinedOrEmpty
} from "../../../../functions/util";

export function getModelByType(session, itemType, itemID) {
  const modelClass = session[itemType];
  const model = modelClass.withId(itemID);
  return model;
}

export function getModelClassByType(session, itemType) {
  return session[itemType];
}

export function getHash(url) {
  return objectHash.sha1(url);
}

function generateQueryObj(urlParams, data) {
  return reduce(
    urlParams,
    (obj, param) => set(obj, param, get(data, param)),
    {}
  );
}

export function generateParamObj(agencyID) {
  return { agency: agencyID };
}

function checkReqParams(urlRequiredParams, data) {
  return every(
    urlRequiredParams,
    param => !isNullOrUndefined(get(data, param))
  );
}

function queryReplacer(data) {
  return function replacer(match, param) {
    return data[param];
  };
}

function getQuery(query, data) {
  const regExp = /{{([\s\S]+?)}}/g; // mustache
  return replace(query, regExp, queryReplacer(data));
}

export function getQueryObj(urlParams, urlRequiredParams, data) {
  return checkReqParams(urlRequiredParams, data)
    ? generateQueryObj(urlParams, data)
    : undefined;
}

export function getOptions(config, apiData, data, agencyID) {
  const queryObj = getQueryObj(
    config.urlParams,
    config.urlRequiredParams,
    data
  );
  if (queryObj && agencyID) {
    const paramObj = generateParamObj(agencyID);
    const url = generateUrl(config.url, paramObj, queryObj);
    const options = apiData[getHash(url)];
    if (options && config.filter) {
      const query = getQuery(config.filter, data);
      return jsonQuery(query, { data: options }).value;
    }
    return options;
  }
  return undefined;
}

export function getText(id, config, data, apiData, agencyID) {
  const queryObj = getQueryObj(
    config.urlParams,
    config.urlRequiredParams,
    data
  );
  if (queryObj && agencyID) {
    const paramObj = generateParamObj(agencyID);
    const url = generateUrl(config.url, paramObj, queryObj);
    const hash = getHash(url);
    const item = find(apiData[hash], ({ key }) => key === id);
    return item ? item.text : undefined;
  }
  return undefined;
}

export function getID(id, config, data, apiData, agencyID) {
  const queryObj = getQueryObj(
    config.urlParams,
    config.urlRequiredParams,
    data
  );
  if (queryObj && agencyID) {
    const paramObj = generateParamObj(agencyID);
    const url = generateUrl(config.url, paramObj, queryObj);
    const hash = getHash(url);
    const item = find(apiData[hash], ({ key }) => key === id);
    return item ? item.id : undefined;
  }
  return undefined;
}

export function getPathValue(config, data, apiData, agencyID) {
  const queryObj = getQueryObj(
    config.urlParams,
    config.urlRequiredParams,
    data
  );
  if (queryObj && agencyID) {
    const paramObj = generateParamObj(agencyID);
    const url = generateUrl(config.url, paramObj, queryObj);
    const options = apiData[getHash(url)];
    if (options && config.path) {
      const query = getQuery(config.path, data);
      return jsonQuery(query, { data: options }).value;
    }
  }
  return undefined;
}

export function getMatchingProperties(configuration, name) {
  return reduce(
    configuration,
    (properties, propertyValidation, propertyName) => {
      if (indexOf(propertyValidation.urlParams, name) !== -1) {
        properties.push(propertyName);
      }
      return properties;
    },
    []
  );
}

export function getMappedObject(configuration, data) {
  return reduce(
    configuration,
    (obj, mapValue, mapName) => set(obj, mapName, get(data, mapValue)),
    {}
  );
}

export function getArraySize(array) {
  return size(array);
}

export const hasUniqueValues = array => new Set(array).size === array.length;

export const hasNullOrUndefinedOrEmptyValues = array =>
  some(array, value => isNullOrUndefinedOrEmpty(value));

export const sumArrayValues = array =>
  array.reduce((previousValue, currentValue) => {
    return previousValue + currentValue;
  }, 0);
