import React from "react";
import { useSelector } from "react-redux";

import Scrollbar from "../../../../shared/Scrollbar";
import ActionOrderItem from "./extensions/ActionOrderItem";

import { getOrders } from "../../../../../business/models/Order/orderSelectors";

const ActionOrderPanel = () => {
  const orders = useSelector(getOrders);

  return (
    <Scrollbar>
      {orders.map(order => (
        <ActionOrderItem key={order.id} order={order} />
      ))}
    </Scrollbar>
  );
};

export default ActionOrderPanel;
