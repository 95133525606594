import cuid from "cuid";
import get from "lodash/get";
import set from "lodash/set";
import forEach from "lodash/forEach";
import assign from "lodash/assign";
import keys from "lodash/keys";
import CampaignModel from "./Campaign";
import {
  CAMPAIGN_SELECT,
  CAMPAIGN_EDIT_START,
  CAMPAIGN_EDIT_STOP,
  CAMPAIGN_CLEAR_CURRENT,
  CAMPAIGN_DISPLAY_ERRORS,
  CAMPAIGN_HIDE_ERRORS,
  CAMPAIGN_SUCCESS_MESSAGE,
  CAMPAIGN_MODEL_ID,
  CAMPAIGN_MODEL_NAME,
  CAMPAIGN_SET_IS_NEW,
  CAMPAIGN_RESET_IS_NEW,
  CAMPAIGN_SET_ACTUAL_MESSAGE,
  SET_CLIENT_CREDIT_MESSAGE
} from "./campaignConstants";
import {
  selectCurrentCampaign,
  selectIsEditingCampaign,
  getEditingCampaign,
  doesCampaignHaveErrors,
  getCampaignActual
} from "./campaignSelectors";
import {
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  CAMPAIGN_FAVOURITE,
  CREATE_CAMPAIGN_COPY_PLAN,
  GET_CAMPAIGN_EXPORT,
  SET_CAMPAIGN_ACTUAL,
  CLIENT_CREDIT
} from "../../../configurations/apiUrls";
import generateUrl from "../common/utils/urlUtils";
import {
  getQueryObj,
  generateParamObj,
  getMatchingProperties
} from "../common/utils/modelUtils";
import {
  editItemAttributes,
  editExistingItem,
  editNewItem,
  stopEditingItem,
  commitData
} from "../common/editing/editingActions";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import fetchApiDataIfNeeded from "../../actions/apiDataActions";
import {
  enqueueApiErrorMessage,
  enqueueNotificationMessage,
  apiCall
} from "../../actions/applicationActions";
import { selectAgency } from "../../selectors/applicationSelectors";
import { PUT, POST, GET } from "../../constants/applicationConstants";
import {
  selectProcess,
  selectIsExisting
} from "../../selectors/copyPlanSelectors";
import download from "../../../functions/download";
import { PLAN_MODEL_ID, PLAN_MODEL_NAME } from "../Plan/planConstants";
import { CREATE_CAMPAIGN_STEP_FAILED } from "../../constants/copyPlanConstants";
/* eslint-disable import/no-cycle */
import { setActiveStep } from "../../actions/copyPlanActions";
import { selectPlan } from "../Plan/planActions";
import { EMPTY_STRING } from "../../../configurations/appConstants";
import { isNullOrUndefinedOrEmpty, isNumber } from "../../../functions/util";

export const clearCurrentCampaign = () => dispatch =>
  dispatch({ type: CAMPAIGN_CLEAR_CURRENT });

/* eslint-disable no-extra-boolean-cast */
export function startEditingCampaign() {
  return (dispatch, getState) => {
    const currentCampaign = selectCurrentCampaign(getState());

    if (!!currentCampaign) {
      dispatch(editExistingItem(CAMPAIGN_MODEL_NAME, currentCampaign));
      dispatch({ type: CAMPAIGN_EDIT_START });
    }
  };
}

export function stopEditingCampaign() {
  return (dispatch, getState) => {
    const currentCampaign = selectCurrentCampaign(getState());

    dispatch(stopEditingItem(CAMPAIGN_MODEL_NAME, currentCampaign));
    dispatch({ type: CAMPAIGN_EDIT_STOP });
    dispatch({ type: CAMPAIGN_HIDE_ERRORS });
    dispatch({ type: CAMPAIGN_RESET_IS_NEW });
  };
}

export function selectCampaign(campaignID) {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectIsEditingCampaign(state);

    if (isEditing) {
      dispatch(stopEditingCampaign());
    }

    dispatch({ type: CAMPAIGN_CLEAR_CURRENT });
    dispatch({
      type: CAMPAIGN_SELECT,
      payload: {
        currentCampaign: campaignID
      }
    });
  };
}

export function setCampaignActual() {
  return (dispatch, getState) => {
    const state = getState();
    const agencyID = selectAgency(state);
    const campaignId = selectCurrentCampaign(state);
    const isActual = getCampaignActual(state);
    const url = generateUrl(SET_CAMPAIGN_ACTUAL, {
      agency: agencyID,
      [CAMPAIGN_MODEL_ID]: campaignId
    });

    return dispatch(apiCall(PUT, url)).then(
      () => {
        dispatch(
          commitData(CAMPAIGN_MODEL_NAME, campaignId, {
            campaignId,
            isActual: !isActual
          })
        );
        dispatch(enqueueNotificationMessage(CAMPAIGN_SET_ACTUAL_MESSAGE));
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function loadCampaignMediaData(data) {
  return (dispatch, getState) => {
    const configuration = CampaignModel.apiConfiguration;
    const agencyID = selectAgency(getState());
    forEach(configuration, prop => {
      const queryObj = getQueryObj(
        prop.urlParams,
        prop.urlRequiredParams,
        data
      );
      if (queryObj && agencyID) {
        const paramObj = generateParamObj(agencyID);
        const url = generateUrl(prop.url, paramObj, queryObj);
        dispatch(fetchApiDataIfNeeded(url));
      }
    });
  };
}

export function addNewCampaign() {
  return (dispatch, getState) => {
    const session = getUnsharedEntitiesSession(getState());
    const { Campaign } = session;

    const id = cuid();
    const newCampaign = Campaign.generate({ id });

    const campaignContents = newCampaign.toJSON();

    dispatch(loadCampaignMediaData(campaignContents));
    dispatch(editNewItem(CAMPAIGN_MODEL_NAME, id, campaignContents));
    dispatch(selectCampaign(id));
    dispatch({ type: CAMPAIGN_SET_IS_NEW });
    dispatch({ type: CAMPAIGN_EDIT_START });
  };
}

export function displayCampaignErrors() {
  return { type: CAMPAIGN_DISPLAY_ERRORS };
}

export function createCampaignCopyPlan() {
  return (dispatch, getState) => {
    const state = getState();

    const hasErrors = doesCampaignHaveErrors(state);
    if (hasErrors) {
      dispatch(displayCampaignErrors());
      return Promise.reject();
    }

    const agencyID = selectAgency(state);
    const processId = selectProcess(state);
    const isExisting = selectIsExisting(state);
    const campaignId = selectCurrentCampaign(state);
    const data = getEditingCampaign(state);

    const url = generateUrl(CREATE_CAMPAIGN_COPY_PLAN, {
      agency: agencyID,
      processId
    });

    const body = {
      ...data,
      existingCampaignId: isExisting ? campaignId : null
    };

    return dispatch(apiCall(POST, url, body)).then(
      response => {
        const { isSuccessful, campaign, plan, step } = response || {};

        if (isSuccessful) {
          const campId = get(campaign, CAMPAIGN_MODEL_ID);
          const campaignData = set(campaign, "id", campId);
          dispatch(commitData(CAMPAIGN_MODEL_NAME, campId, campaignData));

          const planId = get(plan, PLAN_MODEL_ID);
          const planData = set(plan, "id", planId);
          dispatch(commitData(PLAN_MODEL_NAME, planId, planData));

          dispatch(stopEditingCampaign());
          dispatch(selectCampaign(campId));
          dispatch(selectPlan(planId));
          dispatch(setActiveStep(step));

          dispatch(enqueueNotificationMessage(CAMPAIGN_SUCCESS_MESSAGE));
        } else {
          dispatch(enqueueApiErrorMessage(CREATE_CAMPAIGN_STEP_FAILED));
        }
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function createCampaign() {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesCampaignHaveErrors(state);
    if (hasErrors) {
      dispatch(displayCampaignErrors());
      return Promise.reject();
    }

    const agencyID = selectAgency(state);
    const data = getEditingCampaign(state);
    const url = generateUrl(CREATE_CAMPAIGN, {
      agency: agencyID
    });

    return dispatch(apiCall(POST, url, data)).then(
      response => {
        const id = get(response, CAMPAIGN_MODEL_ID);
        const responseData = set(response, "id", id);
        dispatch(commitData(CAMPAIGN_MODEL_NAME, id, responseData));
        dispatch(stopEditingCampaign());
        dispatch({
          type: CAMPAIGN_SELECT,
          payload: {
            currentCampaign: id
          }
        });
        // dispatch(selectCampaign(id));
        dispatch(enqueueNotificationMessage(CAMPAIGN_SUCCESS_MESSAGE));
        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function updateCampaign() {
  return (dispatch, getState) => {
    const state = getState();
    const hasErrors = doesCampaignHaveErrors(state);
    if (hasErrors) {
      dispatch(displayCampaignErrors());
      return Promise.reject();
    }
    const agencyID = selectAgency(state);
    const currentCampaign = selectCurrentCampaign(state);
    const data = getEditingCampaign(state);
    const url = generateUrl(UPDATE_CAMPAIGN, {
      agency: agencyID,
      [CAMPAIGN_MODEL_ID]: currentCampaign
    });

    return dispatch(apiCall(PUT, url, data)).then(
      response => {
        const id = get(response, CAMPAIGN_MODEL_ID);
        const responseData = set(response, "id", id);
        dispatch(commitData(CAMPAIGN_MODEL_NAME, id, responseData));
        dispatch(stopEditingCampaign());
        dispatch(selectCampaign(id));
        dispatch(enqueueNotificationMessage(CAMPAIGN_SUCCESS_MESSAGE));
        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export function resetCampaignData(currentCampaign, data) {
  return dispatch => {
    const configuration = CampaignModel.apiConfiguration;
    const defaults = CampaignModel.defaultAttributes;
    const name = keys(data)[0];
    const properties = getMatchingProperties(configuration, name);
    forEach(properties, propertyName => {
      dispatch(
        editItemAttributes(CAMPAIGN_MODEL_NAME, currentCampaign, {
          [propertyName]: defaults[propertyName]
        })
      );
    });
  };
}

export function setCampaignValue(data) {
  return (dispatch, getState) => {
    const currentCampaign = selectCurrentCampaign(getState());
    const editingData = getEditingCampaign(getState());
    const campaignData = assign({}, editingData, data);

    dispatch(loadCampaignMediaData(campaignData));
    dispatch(editItemAttributes(CAMPAIGN_MODEL_NAME, currentCampaign, data));
    dispatch(resetCampaignData(currentCampaign, data));
  };
}

export function favouriteCampaign() {
  return (dispatch, getState) => {
    const currentCampaign = selectCurrentCampaign(getState());
    const agencyID = selectAgency(getState());

    const url = generateUrl(CAMPAIGN_FAVOURITE, {
      agency: agencyID,
      [CAMPAIGN_MODEL_ID]: currentCampaign
    });

    return dispatch(apiCall(POST, url, {})).then(
      response => {
        const id = get(response, CAMPAIGN_MODEL_ID);
        const responseData = set(response, "id", id);
        dispatch(commitData(CAMPAIGN_MODEL_NAME, id, responseData));

        return id;
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}

export const downloadExcel = () => (dispatch, getState) => {
  const state = getState();
  const currentCampaign = selectCurrentCampaign(state);

  const agencyId = selectAgency(state);

  const url = generateUrl(GET_CAMPAIGN_EXPORT, {
    agency: agencyId,
    [CAMPAIGN_MODEL_ID]: currentCampaign
  });

  return dispatch(apiCall(GET, url))
    .then(response => {
      return response.blob();
    })
    .then(blob => download(blob, `${currentCampaign}.xlsx`))
    .then(() =>
      dispatch(enqueueNotificationMessage("File is successfully downloaded"))
    )
    .catch(() =>
      dispatch(
        enqueueApiErrorMessage("There was problem while downloading file")
      )
    );
};

export function getClientCreditResult() {
  return (dispatch, getState) => {
    const state = getState();
    const agencyID = selectAgency(state);
    const currentCampaign = selectCurrentCampaign(state);
    const url = generateUrl(CLIENT_CREDIT, {
      agency: agencyID,
      [CAMPAIGN_MODEL_ID]: currentCampaign
    });
    if (
      isNullOrUndefinedOrEmpty(currentCampaign) ||
      !isNumber(currentCampaign)
    ) {
      return undefined;
    }
    return dispatch(apiCall(GET, url)).then(
      response => {
        dispatch({
          type: SET_CLIENT_CREDIT_MESSAGE,
          payload: {
            clientCreditMessage: response?.creditResultMessage ?? EMPTY_STRING
          }
        });
      },
      error => {
        dispatch(enqueueApiErrorMessage(error));
      }
    );
  };
}
