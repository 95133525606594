import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { setPlanExtensionValue } from "../../business/models/PlanExtension/planExtensionActions";

import {
  getPlanExtensionPropertyValue,
  getPlanExtensionPropertyPreviousValue,
  getPlanExtensionPropertyErrorsByProperty,
  selectPlanExtensionDisplayErrors,
  getPlanExtensionPropertyOptions
} from "../../business/models/PlanExtension/planExtensionSelectors";

import Controls from "./controls/index";

const PlanExtensionField = props => {
  const { name, component, ...componentProps } = props;
  const dispatch = useDispatch();

  const value = useSelector(state =>
    getPlanExtensionPropertyValue(state, props)
  );
  const prevValue = useSelector(state =>
    getPlanExtensionPropertyPreviousValue(state, props)
  );
  const errors = useSelector(state =>
    getPlanExtensionPropertyErrorsByProperty(state, props)
  );
  const displayErrors = useSelector(selectPlanExtensionDisplayErrors);
  const options = useSelector(state =>
    getPlanExtensionPropertyOptions(state, props)
  );

  const handleChange = val => {
    dispatch(setPlanExtensionValue({ [name]: val }));
  };

  const Control = Controls[component];
  return (
    <Control
      component={component}
      {...componentProps}
      onChange={handleChange}
      value={value}
      prevValue={prevValue}
      errors={errors}
      displayErrors={displayErrors}
      options={options}
    />
  );
};

PlanExtensionField.propTypes = {
  component: PropTypes.oneOf(["text", "date", "decimal", "autocomplete"]),
  name: PropTypes.string.isRequired
};

export default PlanExtensionField;
