import { createReducer } from "../utils/reducerUtils";
import { CLEAR_EDITING_ENTITY_DATA } from "../data/dataConstants";

import orm from "../../schema";

const defaultEditingEntities = orm.getEmptyState();

export function clearEditingEntityData() {
  return defaultEditingEntities;
}

export default createReducer(defaultEditingEntities, {
  [CLEAR_EDITING_ENTITY_DATA]: clearEditingEntityData
});
