import { styled, Grid as MuiGrid } from "@mui/material";
import {
  supernova,
  white,
  crusta,
  linkWater,
  solitude
} from "../../../../assets/styles/colors";

const PREFIX = "OrderEditActionsStyles";
export const classes = {
  cancelButton: `${PREFIX}-cancelButton`,
  saveButton: `${PREFIX}-saveButton`,
  markedButton: `${PREFIX}-markedButton`
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.cancelButton}`]: {
    color: crusta
  },
  [`& .${classes.saveButton}`]: {
    color: white,
    "&:disabled": {
      color: linkWater,
      backgroundColor: solitude
    }
  },
  [`& .${classes.markedButton}`]: {
    backgroundColor: supernova
  }
}));
