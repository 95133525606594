import {
  SET_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  CLEAR_NOSYSTEM_NOTIFICATIONS
} from "../constants/trafficNotificationsConstants";

const initialState = {
  notificationList: []
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notificationList: action.payload
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notificationList: []
      };
    case CLEAR_NOSYSTEM_NOTIFICATIONS:
      return {
        ...state,
        notificationList: state.notificationList.filter(n => n.typeId === 1)
      };
    default:
      return state;
  }
};

export default notificationsReducer;
