import React, { Component } from "react";
import { classes, Div } from "./styles/errorMessage";

class ErrorMessage extends Component {
  constructor() {
    super();
    this.state = { ready: true };
  }

  componentDidMount() {
    const delay = 1000;
    if (delay && delay > 0) {
      this.setState({ ready: false });
      this.timeout = setTimeout(() => {
        this.setState({ ready: true });
      }, delay);
    } else {
      this.setState({ ready: true });
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { message } = this.props;
    if (this.state.ready) {
      return <Div className={classes.root}>{message}</Div>;
    }
    return null;
  }
}

export default ErrorMessage;
