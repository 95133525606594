import { createReducer } from "../common/utils/reducerUtils";
import {
  TRAFFIC_TEMPLATE_SELECT,
  TRAFFIC_TEMPLATE_EDIT_START,
  TRAFFIC_TEMPLATE_EDIT_STOP,
  TRAFFIC_TEMPLATE_SET_IS_NEW,
  TRAFFIC_TEMPLATE_CLEAR_CURRENT,
  TRAFFIC_TEMPLATE_RESET_IS_NEW,
  TRAFFIC_TEMPLATE_HIDE_ERRORS,
  TRAFFIC_TEMPLATE_DISPLAY_ERRORS
} from "./trafficTemplateConstants";

const initialState = {
  currentTrafficTemplate: null,
  isNew: false,
  isEditing: false,
  displayErrors: false
};

export function setIsNew(state) {
  return {
    ...state,
    isNew: true
  };
}

export function resetIsNew(state) {
  return {
    ...state,
    isNew: false
  };
}
export function clearCurrent(state) {
  return {
    ...state,
    currentTrafficTemplate: null
  };
}

export function hideErrors(state) {
  return {
    ...state,
    displayErrors: false
  };
}
export function displayErrors(state) {
  return {
    ...state,
    displayErrors: true
  };
}

export function selectTrafficTemplate(state, payload) {
  return {
    ...state,
    currentTrafficTemplate: payload
  };
}

export function startEditingTrafficTemplate(state) {
  return {
    ...state,
    isEditing: true
  };
}

export function stopEditingTrafficTemplate(state) {
  return {
    ...state,
    isEditing: false
  };
}

export default createReducer(initialState, {
  [TRAFFIC_TEMPLATE_SET_IS_NEW]: setIsNew,
  [TRAFFIC_TEMPLATE_RESET_IS_NEW]: resetIsNew,
  [TRAFFIC_TEMPLATE_HIDE_ERRORS]: hideErrors,
  [TRAFFIC_TEMPLATE_DISPLAY_ERRORS]: displayErrors,
  [TRAFFIC_TEMPLATE_CLEAR_CURRENT]: clearCurrent,
  [TRAFFIC_TEMPLATE_SELECT]: selectTrafficTemplate,
  [TRAFFIC_TEMPLATE_EDIT_START]: startEditingTrafficTemplate,
  [TRAFFIC_TEMPLATE_EDIT_STOP]: stopEditingTrafficTemplate
});
