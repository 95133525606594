import React from "react";

import Cinema from "./byMediaType/Traffic/multipleEditForms/MultipleEditCinema";
import Digital from "./byMediaType/Traffic/multipleEditForms/MultipleEditDigital";
import Ooh from "./byMediaType/Traffic/multipleEditForms/MultipleEditOoh";
import Print from "./byMediaType/Traffic/multipleEditForms/MultipleEditPrint";
import Radio from "./byMediaType/Traffic/multipleEditForms/MultipleEditRadio";
import Tv from "./byMediaType/Traffic/multipleEditForms/MultipleEditTv";
import {
  MEDIATYPE_DIGITAL,
  MEDIATYPE_PRINT,
  MEDIATYPE_OOH,
  MEDIATYPE_TV,
  MEDIATYPE_RADIO,
  MEDIATYPE_CINEMA,
  MEDIATYPE_INVENTORY
} from "../../../configurations/appConstants";

const TrafficLineFold = ({
  mediaTypeId,
  editMode,
  areTrafficAgencyFieldsDisabled,
  areTrafficAgentFieldsDisabled,
  isDebtorActive
}) => {
  switch (mediaTypeId) {
    case MEDIATYPE_PRINT:
      return (
        <Print
          editMode={editMode}
          areTrafficAgencyFieldsDisabled={areTrafficAgencyFieldsDisabled}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    case MEDIATYPE_DIGITAL:
    case MEDIATYPE_INVENTORY:
      return (
        <Digital
          editMode={editMode}
          areTrafficAgencyFieldsDisabled={areTrafficAgencyFieldsDisabled}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    case MEDIATYPE_OOH:
      return (
        <Ooh
          editMode={editMode}
          areTrafficAgencyFieldsDisabled={areTrafficAgencyFieldsDisabled}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    case MEDIATYPE_RADIO:
      return (
        <Radio
          editMode={editMode}
          areTrafficAgencyFieldsDisabled={areTrafficAgencyFieldsDisabled}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    case MEDIATYPE_TV:
      return (
        <Tv
          editMode={editMode}
          areTrafficAgencyFieldsDisabled={areTrafficAgencyFieldsDisabled}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    case MEDIATYPE_CINEMA:
      return (
        <Cinema
          editMode={editMode}
          areTrafficAgencyFieldsDisabled={areTrafficAgencyFieldsDisabled}
          areTrafficAgentFieldsDisabled={areTrafficAgentFieldsDisabled}
          isDebtorActive={isDebtorActive}
        />
      );
    default:
      return null;
  }
};

export default TrafficLineFold;
