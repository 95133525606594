import { Model, attr, fk } from "redux-orm";
import set from "lodash/set";

import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";
import calculate from "../common/utils/calculationUtils";
import {
  MANDATORY_RULE,
  DATE_RULE,
  DECIMAL_RULE,
  ISO8859_RULE,
  NUMBER_OF_CHARACTERS_RULE,
  EQUALS_TO_ZERO_RULE,
  MANDATORY_MESSAGE,
  DATE_MESSAGE,
  DECIMAL_MESSAGE,
  ISO8859_MESSAGE,
  EQUALS_TO_ZERO_MESSAGE,
  NUMBER_OF_CHARACTERS_MESSAGE_50
} from "../../constants/validationConstants";

import { ORDER_MODEL_NAME, ORDER_MODEL_ID } from "../Order/orderConstants";
import { INSERTION_MODEL_NAME, INSERTION_MODEL_ID } from "./insertionConstants";

class Insertion extends Model {
  static get fields() {
    return {
      id: attr(),
      orderId: attr(),
      insertionId: attr(),
      insertionAgencyDiscountAmount: attr(),
      insertionDiscountAmount: attr(),
      insertionInvoiceDate: attr(),
      insertionIsInvoiced: attr(),
      insertionNetAmount: attr(),
      insertionNumOfUnits: attr(),
      insertionPoNumber: attr(),
      insertionSurcharge1Amount: attr(),
      insertionSurcharge2Amount: attr(),
      order: fk(ORDER_MODEL_NAME)
    };
  }

  static get defaultAttributes() {
    return {};
  }

  static get inheritConfiguration() {
    return {
      insertionPoNumber: "orderPoNumber",
      insertionAgencyDiscountAmount: "agencyDiscountAmount"
    };
  }

  static parse(insertionData) {
    if (insertionData[INSERTION_MODEL_ID]) {
      set(insertionData, "id", insertionData[INSERTION_MODEL_ID]);
    }
    if (insertionData[ORDER_MODEL_ID]) {
      set(insertionData, "order", insertionData[ORDER_MODEL_ID]);
    }

    return this.upsert(insertionData);
  }

  static updateModel(insertionData) {
    if (insertionData[INSERTION_MODEL_ID]) {
      set(insertionData, "id", insertionData[INSERTION_MODEL_ID]);
    }
    if (insertionData[ORDER_MODEL_ID]) {
      set(insertionData, "order", insertionData[ORDER_MODEL_ID]);
    }

    return this.upsert(insertionData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...Insertion.defaultAttributes,
      ...newAttributes
    };
    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherPlan) {
    this.update(otherPlan.ref);
  }

  static get validation() {
    return {
      insertionInvoiceDate: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DATE_RULE,
          message: DATE_MESSAGE
        }
      ],
      insertionAgencyDiscountAmount: [
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      insertionDiscountAmount: [
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      insertionNetAmount: [
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      insertionNumOfUnits: [
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      insertionSurcharge1Amount: [
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        }
      ],
      insertionSurcharge2Amount: [
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        }
      ],
      insertionPoNumber: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ]
    };
  }

  static get calcValidation() {
    return {
      sumInsertionNumOfUnits: [
        {
          rule: EQUALS_TO_ZERO_RULE,
          message: EQUALS_TO_ZERO_MESSAGE,
          options: 0
        }
      ],
      sumInsertionDiscountAmount: [
        {
          rule: EQUALS_TO_ZERO_RULE,
          message: EQUALS_TO_ZERO_MESSAGE,
          options: 0
        }
      ],
      sumInsertionNetAmount: [
        {
          rule: EQUALS_TO_ZERO_RULE,
          message: EQUALS_TO_ZERO_MESSAGE,
          options: 0
        }
      ],
      sumInsertionAgencyDiscountAmount: [
        {
          rule: EQUALS_TO_ZERO_RULE,
          message: EQUALS_TO_ZERO_MESSAGE,
          options: 0
        }
      ],
      sumInsertionSurcharge1Amount: [
        {
          rule: EQUALS_TO_ZERO_RULE,
          message: EQUALS_TO_ZERO_MESSAGE,
          options: 0
        }
      ],
      sumInsertionSurcharge2Amount: [
        {
          rule: EQUALS_TO_ZERO_RULE,
          message: EQUALS_TO_ZERO_MESSAGE,
          options: 0
        }
      ],
      sumInsertionNetPerUnit: [
        {
          rule: EQUALS_TO_ZERO_RULE,
          message: EQUALS_TO_ZERO_MESSAGE,
          options: 0
        }
      ],
      sumInsertionGrossTotal: [
        {
          rule: EQUALS_TO_ZERO_RULE,
          message: EQUALS_TO_ZERO_MESSAGE,
          options: 0
        }
      ],
      sumInsertionNetNetTotal: [
        {
          rule: EQUALS_TO_ZERO_RULE,
          message: EQUALS_TO_ZERO_MESSAGE,
          options: 0
        }
      ]
    };
  }

  validate() {
    return validateModel(Insertion.validation, this.toJSON());
  }

  validateByProperty(name) {
    return validateProperty(Insertion.validation[name], this.ref[name]);
  }

  hasErrors() {
    return doesModelHaveErrors(Insertion.validation, this.toJSON());
  }

  static validateCalculationByProperty(name, data) {
    return validateProperty(Insertion.calcValidation[name], data);
  }

  static hasCalculationErrors(data) {
    return doesModelHaveErrors(
      Insertion.calcValidation,
      calculate(Insertion.calculations, data)
    );
  }

  static get calculations() {
    return {
      insertionGrossTotal: {
        calcMethod: "insertiongrosstotal",
        calcParams: ["insertionNetAmount", "insertionDiscountAmount"]
      },
      insertionNetPerUnit: {
        calcMethod: "insertionnetperunit",
        calcParams: ["insertionNetAmount", "insertionNumOfUnits"]
      },
      insertionNetNetTotal: {
        calcMethod: "insertionnetnettotal",
        calcParams: ["insertionNetAmount", "insertionAgencyDiscountAmount"]
      },
      sumInsertionNumOfUnits: {
        calcMethod: "suminsertionnumofunits",
        calcParams: ["insertionNumOfUnits", "numOfUnits"]
      },
      sumInsertionDiscountAmount: {
        calcMethod: "suminsertiondiscount",
        calcParams: ["insertionDiscountAmount", "discountAmount"]
      },
      sumInsertionNetAmount: {
        calcMethod: "suminsertionnettotal",
        calcParams: ["insertionNetAmount", "netTotal"]
      },
      sumInsertionAgencyDiscountAmount: {
        calcMethod: "suminsertionagencydiscount",
        calcParams: ["insertionAgencyDiscountAmount", "agencyDiscountAmount"]
      },
      sumInsertionSurcharge1Amount: {
        calcMethod: "suminsertionsurcharge1",
        calcParams: ["insertionSurcharge1Amount", "surcharge1Amount"]
      },
      sumInsertionSurcharge2Amount: {
        calcMethod: "suminsertionsurcharge2",
        calcParams: ["insertionSurcharge2Amount", "surcharge2Amount"]
      },
      sumInsertionNetPerUnit: {
        calcMethod: "suminsertionnetperunit",
        calcParams: [
          "insertionNetAmount",
          "insertionNumOfUnits",
          "netTotal",
          "numOfUnits"
        ]
      },
      sumInsertionGrossTotal: {
        calcMethod: "suminsertiongrosstotal",
        calcParams: [
          "insertionNetAmount",
          "insertionDiscountAmount",
          "netTotal",
          "discountAmount"
        ]
      },
      sumInsertionNetNetTotal: {
        calcMethod: "suminsertionnetnettotal",
        calcParams: [
          "insertionNetAmount",
          "insertionAgencyDiscountAmount",
          "netTotal",
          "agencyDiscountAmount"
        ]
      }
    };
  }

  static get disabledFields() {
    return {
      insertionInvoiceDate: [
        "isOrderCancelledOrToBeCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      insertionNumOfUnits: [
        "isOrderCancelledOrToBeCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      insertionDiscountAmount: [
        "isOrderCancelledOrToBeCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      insertionNetPerUnit: [
        "isOrderCancelledOrToBeCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      insertionNetAmount: [
        "isOrderCancelledOrToBeCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      insertionGrossTotal: [
        "isOrderCancelledOrToBeCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      insertionAgencyDiscountAmount: [
        "isOrderCancelledOrToBeCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      insertionNetNetTotal: [
        "isOrderCancelledOrToBeCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      insertionSurcharge1Amount: [
        "isOrderCancelledOrToBeCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      insertionSurcharge2Amount: [
        "isOrderCancelledOrToBeCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ],
      insertionPoNumber: [
        "isOrderCancelledOrToBeCancelled",
        "isOrderPendingForApproval",
        "disabledByInactiveDebtor"
      ]
    };
  }
}

Insertion.modelName = INSERTION_MODEL_NAME;

export default Insertion;
