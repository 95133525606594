import React from "react";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { fieldToTextField } from "formik-mui";
import ClearIcon from "@mui/icons-material/Clear";
import {
  isNullOrUndefined,
  isNullOrUndefinedOrEmpty
} from "../../../functions/util";

const getFilterdOptions = (value, options = []) => {
  return options
    .filter(item => {
      const alreadySelected =
        !isNullOrUndefinedOrEmpty(value) &&
        value.some(v => v.value === item.key);

      if (isNullOrUndefined(item.enabled) && !alreadySelected) {
        return true;
      }
      if (alreadySelected) {
        return false;
      }

      return item.enabled;
    })
    .map(suggestion => ({
      value: suggestion.key,
      label: suggestion.text
    }));
};

const FormikAutocomplete = ({
  textFieldProps,
  options,
  canEditAgencyRules,
  ...props
}) => {
  const {
    form: { setTouched, setFieldValue, touched }
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name, value } = field;

  const handleBlure = () => setTouched({ ...touched, [name]: true });
  const handleChange = (_, retVal) => setFieldValue(name, retVal);

  const fieldProps = { variant: "outlined", ...textFieldProps };
  return (
    <Autocomplete
      {...props}
      {...field}
      options={getFilterdOptions(value, options)}
      onChange={handleChange}
      filterSelectedOptions
      onBlur={handleBlure}
      ChipProps={{
        deleteIcon: (
          <IconButton disabled={!canEditAgencyRules}>
            <ClearIcon />
          </IconButton>
        )
      }}
      disableClearable={!canEditAgencyRules}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField
          {...params}
          {...fieldProps}
          helperText={helperText}
          error={error}
        />
      )}
    />
  );
};

export default FormikAutocomplete;
