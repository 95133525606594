import React, { memo } from "react";
import PropTypes from "prop-types";

import { Grid, Toolbar } from "@mui/material";

import OnlyMyCampaignsSearch from "./OnlyMyCampaignsSearch";
import MediaTypeSearch from "./MediaTypeSearch";
import ClearAll from "./ClearAll";

import TableOverviewBody from "./TableOverviewBody";
import TableOverviewFooter from "./TableOverviewFooter";
import DynamicFilter from "../../../shared/dynamicFilter/DynamicFilter";
import { defaultOptionsForOverview } from "../../../../business/constants/filterOptions";
import { MAX_FILTER_NUMBER } from "../../../../business/constants/applicationConstants";
import TableOverviewHeader from "./TableOverviewHeader";
import SaveSearch from "./SaveSearch";
import { classes, Table } from "../styles/table";
import { EMPTY_STRING } from "../../../../configurations/appConstants";

const OverviewTable = ({
  rows,
  pageSize,
  currentPage,
  searchText,
  searchProperty,
  searchMyCampaigns,
  searchMediaTypes,
  sort,
  count,
  contentLoading,
  onClear,
  onInputSearch,
  onSearchBy,
  onSortChange,
  onSearchMyCampaigns,
  onSearchMediaTypes,
  onChangePage,
  onChangeRowsPerPage,
  onEditClick,
  handleDownloadClick,
  handleTrafficClick,
  handleFavouriteClick,
  canViewTraffic,
  disableAction,
  onDynamicFilter,
  queryFilters,
  showTableControls
}) => {
  const isClearAllDisabled =
    (searchMediaTypes === undefined || searchMediaTypes.length === 0) &&
    searchMyCampaigns === false &&
    Object.values(queryFilters).every(el => el === undefined);

  return (
    <>
      {showTableControls && (
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <OnlyMyCampaignsSearch
                    searchMyCampaigns={searchMyCampaigns}
                    onSearchMyCampaigns={onSearchMyCampaigns}
                  />
                </Grid>
                <Grid item>
                  <MediaTypeSearch
                    searchMediaTypes={searchMediaTypes}
                    onSearchMediaTypes={onSearchMediaTypes}
                  />
                </Grid>
                <Grid item>
                  <Grid container alignContent="flex-start" alignItems="center">
                    <Grid item>
                      <DynamicFilter
                        searchProperty={searchProperty}
                        onInputSearch={onInputSearch}
                        onSearchBy={onSearchBy}
                        searchText={searchText}
                        updateUrl={onDynamicFilter}
                        queryFilters={queryFilters}
                        filterOptions={defaultOptionsForOverview}
                        limit={MAX_FILTER_NUMBER}
                      />
                    </Grid>
                    <Grid item>
                      <ClearAll
                        onClear={onClear}
                        disabled={isClearAllDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <SaveSearch onChangePage={onChangePage}></SaveSearch>
            </Grid>
          </Grid>
        </Toolbar>
      )}
      <Grid item xs={12}>
        <Table className={classes.table}>
          {showTableControls && (
            <TableOverviewHeader
              count={count}
              onChangePage={onChangePage}
              pageSize={pageSize}
              currentPage={currentPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              sortValue={sort}
              onSortChange={onSortChange}
              showTableControls={showTableControls}
              contentLoading={contentLoading}
            />
          )}

          <TableOverviewBody
            isCopyPlan={false}
            rows={rows}
            onEditClick={onEditClick}
            handleDownloadClick={handleDownloadClick}
            handleTrafficClick={handleTrafficClick}
            canViewTraffic={canViewTraffic}
            disableAction={disableAction}
            contentLoading={contentLoading}
            handleFavouriteClick={handleFavouriteClick}
          />
          {showTableControls && (
            <TableOverviewFooter
              count={count}
              onChangePage={onChangePage}
              pageSize={pageSize}
              currentPage={currentPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              contentLoading={contentLoading}
              showTableControls={showTableControls}
            />
          )}
        </Table>
      </Grid>
    </>
  );
};

OverviewTable.propTypes = {
  rows: PropTypes.array,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  contentLoading: PropTypes.bool,
  showTableControls: PropTypes.bool,
  searchText: PropTypes.string,
  searchProperty: PropTypes.string,
  searchMyCampaigns: PropTypes.bool,
  searchMediaTypes: PropTypes.array,
  sort: PropTypes.string,
  count: PropTypes.number,
  canViewTraffic: PropTypes.bool,
  disableAction: PropTypes.bool,
  queryFilters: PropTypes.object,
  onClear: PropTypes.func.isRequired,
  onInputSearch: PropTypes.func,
  onSearchBy: PropTypes.func,
  onSortChange: PropTypes.func.isRequired,
  onSearchMyCampaigns: PropTypes.func.isRequired,
  onSearchMediaTypes: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDynamicFilter: PropTypes.func.isRequired,
  handleDownloadClick: PropTypes.func.isRequired,
  handleTrafficClick: PropTypes.func.isRequired,
  handleFavouriteClick: PropTypes.func.isRequired
};

OverviewTable.defaultProps = {
  rows: [],
  count: 0,
  pageSize: 10,
  currentPage: 0,
  contentLoading: false,
  canViewTraffic: false,
  disableAction: false,
  showTableControls: false,
  searchMyCampaigns: false,
  searchText: EMPTY_STRING,
  searchProperty: EMPTY_STRING,
  searchMediaTypes: [],
  sort: EMPTY_STRING,
  queryFilters: {}
};

export default memo(OverviewTable);
