/* eslint-disable import/no-cycle */
import cuid from "cuid";
import forEach from "lodash/forEach";

import {
  editExistingItem,
  stopEditingItem,
  editItemAttributes,
  editNewItem
} from "../common/editing/editingActions";

import { deleteEntity } from "../common/entities/entityActions";

import {
  PLAN_CHANGE_OVERVIEW_CLEAR_CURRENT,
  PLAN_CHANGE_OVERVIEW_SELECT,
  PLAN_CHANGE_OVERVIEW_MODEL_NAME,
  PLAN_CHANGE_OVERVIEW_EDIT_START,
  PLAN_CHANGE_OVERVIEW_EDIT_STOP,
  PLAN_CHANGE_OVERVIEW_HIDE_ERRORS,
  PLAN_CHANGE_OVERVIEW_DISPLAY_ERRORS,
  PLAN_CHANGE_OVERVIEW_SET_EDITING_ORIGINAL_ITEM,
  PLAN_CHANGE_OVERVIEW_CLEAR_EDITING_ORIGINAL_ITEM
} from "./planChangeOverviewConstants";

import {
  getPlanChangeOverviewIDs,
  selectCurrentPlanChangeOverviews,
  selectPlanChangeOverviewsIsEditing,
  doesPlanChangeOverviewHasErrors,
  selectEditingPlanChangeOverviewDataById,
  selectEditingOriginalItem,
  selectPlanChangeOverviewsByPlanExtensionId,
  getPlanChangeOverviewIDsForCopyPlanProcess
} from "./planChangeOverviewSelectors";

import {
  selectCurrentPlanExtension,
  selectCurrentPlanExtensionByPlanId
} from "../PlanExtension/planExtensionSelectors";

import { stopEditingPlanTargetMetricsKpis } from "../PlanTargetMetricsKpi/planTargetMetricsKpiActions";

import { stopEditingPlanExtension } from "../PlanExtension/planExtensionActions";
import { stopEditingPlan } from "../Plan/planActions";
import { stopEditingCampaign } from "../Campaign/campaignActions";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import { isNullOrUndefined } from "../../../functions/util";

export const clearCurrentPlanChangeOverviews = () => dispatch =>
  dispatch({ type: PLAN_CHANGE_OVERVIEW_CLEAR_CURRENT });

export const stopEditingPlanChangeOverviews = () => {
  return (dispatch, getState) => {
    const currentPlanChangeOverviews = selectCurrentPlanChangeOverviews(
      getState()
    );

    forEach(currentPlanChangeOverviews, planCoId =>
      dispatch(stopEditingItem(PLAN_CHANGE_OVERVIEW_MODEL_NAME, planCoId))
    );

    dispatch({ type: PLAN_CHANGE_OVERVIEW_EDIT_STOP });
    dispatch({ type: PLAN_CHANGE_OVERVIEW_HIDE_ERRORS });
  };
};

export const selectPlanChangeOverviews = (
  copyProcessProps = { isInCopyProcess: false, sourcePlanExtensionId: null }
) => {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectPlanChangeOverviewsIsEditing(state);

    if (isEditing) {
      dispatch(stopEditingPlanChangeOverviews());
      dispatch(stopEditingPlanTargetMetricsKpis());
      dispatch(stopEditingPlanExtension());
      dispatch(stopEditingPlan());
      dispatch(stopEditingCampaign());
    }

    const { isInCopyProcess, sourcePlanExtensionId } = copyProcessProps;

    const planChangeOverviewIDs = isInCopyProcess
      ? getPlanChangeOverviewIDsForCopyPlanProcess(state, {
          planExtensionId: sourcePlanExtensionId
        })
      : getPlanChangeOverviewIDs(state);

    dispatch(clearCurrentPlanChangeOverviews());
    dispatch({
      type: PLAN_CHANGE_OVERVIEW_SELECT,
      payload: {
        currentPlanChangeOverviews: planChangeOverviewIDs
      }
    });
  };
};

export const startEditingPlanChangeOverviews = () => {
  return (dispatch, getState) => {
    const currentPlanChangeOverviews = selectCurrentPlanChangeOverviews(
      getState()
    );

    forEach(currentPlanChangeOverviews, planCoId =>
      dispatch(editExistingItem(PLAN_CHANGE_OVERVIEW_MODEL_NAME, planCoId))
    );

    dispatch({ type: PLAN_CHANGE_OVERVIEW_EDIT_START });
  };
};

export const setPlanChangeOverviewValue = (id, data) => {
  return dispatch => {
    dispatch(editItemAttributes(PLAN_CHANGE_OVERVIEW_MODEL_NAME, id, data));
  };
};

export const addPlanChangeOverview = planChangeOverviewId => {
  return (dispatch, getState) => {
    const currentPlanChangeOverviews = selectCurrentPlanChangeOverviews(
      getState()
    );

    dispatch({
      type: PLAN_CHANGE_OVERVIEW_SELECT,
      payload: {
        currentPlanChangeOverviews: [
          ...currentPlanChangeOverviews,
          planChangeOverviewId
        ]
      }
    });
  };
};

export const addNewPlanChangeOverview = () => {
  return (dispatch, getState) => {
    const state = getState();
    const session = getUnsharedEntitiesSession(state);
    const planExtensionId = selectCurrentPlanExtension(state);

    const { PlanChangeOverview } = session;
    const id = cuid();

    const newPlanChangeOverview = PlanChangeOverview.generate({
      id,
      planExtension: planExtensionId
    });

    const planChangeOverviewContents = newPlanChangeOverview.toJSON();

    dispatch(
      editNewItem(
        PLAN_CHANGE_OVERVIEW_MODEL_NAME,
        id,
        planChangeOverviewContents
      )
    );
    dispatch(addPlanChangeOverview(id));

    return id;
  };
};

// in memory delete change overview from editing entities
export const deletePlanChangeOverview = planChangeOverviewId => {
  return (dispatch, getState) => {
    const currentPlanChangeOverviews = selectCurrentPlanChangeOverviews(
      getState()
    );

    dispatch({
      type: PLAN_CHANGE_OVERVIEW_SELECT,
      payload: {
        currentPlanChangeOverviews: currentPlanChangeOverviews.filter(
          id => id !== planChangeOverviewId
        )
      }
    });
    dispatch(
      stopEditingItem(PLAN_CHANGE_OVERVIEW_MODEL_NAME, planChangeOverviewId)
    );
  };
};

export const displayPlanChangeOverviewErrors = () => {
  return { type: PLAN_CHANGE_OVERVIEW_DISPLAY_ERRORS };
};

export const planChangeOverviewHasErrors = id => {
  return (dispatch, getState) => {
    const hasErrors = doesPlanChangeOverviewHasErrors(getState(), {
      entityId: id
    });
    if (hasErrors) {
      if (hasErrors) {
        dispatch(displayPlanChangeOverviewErrors());
      }
    }
    return hasErrors;
  };
};

export const setEditingOriginalPlanChangeOverviewData = id => {
  return (dispatch, getState) => {
    const itemData = selectEditingPlanChangeOverviewDataById(getState(), {
      entityId: id
    });

    dispatch({
      type: PLAN_CHANGE_OVERVIEW_SET_EDITING_ORIGINAL_ITEM,
      payload: {
        editingPlanChangeOverviews: itemData
      }
    });
  };
};

export const clearEditingOriginalPlanChangeOverviewData = () => {
  return dispatch => {
    dispatch({
      type: PLAN_CHANGE_OVERVIEW_CLEAR_EDITING_ORIGINAL_ITEM
    });
  };
};

export const resetCurrentPlanChangeOverview = id => {
  return (dispatch, getState) => {
    const state = getState();
    const itemData = selectEditingPlanChangeOverviewDataById(state, {
      entityId: id
    });
    const original = selectEditingOriginalItem(state);
    const originalData = original.editingPlanChangeOverviews;

    Object.keys(itemData).forEach(key => {
      if (isNullOrUndefined(originalData[key])) {
        dispatch(
          editItemAttributes(PLAN_CHANGE_OVERVIEW_MODEL_NAME, id, {
            [key]: null
          })
        );
      } else {
        dispatch(
          editItemAttributes(PLAN_CHANGE_OVERVIEW_MODEL_NAME, id, {
            [key]: originalData[key]
          })
        );
      }
    });
  };
};

// delete all plan target metrics kpi from entities for current plan extension (create, update)
export const deletePlanChangeOverviews = () => {
  return (dispatch, getState) => {
    const planChangeOverviewIDs = getPlanChangeOverviewIDs(getState());

    forEach(planChangeOverviewIDs, planCoId =>
      dispatch(deleteEntity(PLAN_CHANGE_OVERVIEW_MODEL_NAME, planCoId))
    );
  };
};

// delete all plan change overview from entities for given plan - update plan with deletions
export const deletePlansChangeOverviewsByPlanId = () => {
  return (dispatch, getState) => {
    const state = getState();

    const planExtension = selectCurrentPlanExtensionByPlanId(state);
    const { planExtensionId } = planExtension;

    const planChangeOverviewIDs = selectPlanChangeOverviewsByPlanExtensionId(
      state,
      { planExtensionId }
    );

    forEach(planChangeOverviewIDs, planCoId =>
      dispatch(deleteEntity(PLAN_CHANGE_OVERVIEW_MODEL_NAME, planCoId))
    );
  };
};
