import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { Button } from "@mui/material";

import {
  addNewUser,
  clearSecurityCache,
  clearMediaCache
} from "../../../../business/models/User/userActions";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";

import { classes, Grid } from "../styles/userManagementActions";

const UserManagementActions = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => selectIsLoading(state));

  const handleAddUser = () => {
    dispatch(addNewUser());
  };

  const handleClearSecurityCache = () => {
    dispatch(clearSecurityCache());
  };

  const handleClearMediaCache = () => {
    dispatch(clearMediaCache());
  };

  return (
    // <Grid container justifyContent="space-between" className={classes.root}> TODO(AM)
    <Grid container justifyContent="space-between">
      <Grid item>
        <Button
          id="clear_security_cache"
          name="clear_security_cache"
          variant="contained"
          className={clsx(classes.securityCacheButton, classes.saveButton)}
          onClick={handleClearSecurityCache}
          disabled={isLoading}
        >
          CLEAR SECURITY CACHE
        </Button>
        <Button
          id="clear_media_cache"
          name="clear_media_cache"
          variant="contained"
          className={clsx(classes.mediaCacheButton, classes.saveButton)}
          onClick={handleClearMediaCache}
          disabled={isLoading}
        >
          CLEAR MEDIA CACHE
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="add_user"
          name="add_user"
          variant="contained"
          className={clsx(classes.saveButton, classes.markedButton)}
          onClick={handleAddUser}
          disabled={isLoading}
        >
          ADD USER
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserManagementActions;
