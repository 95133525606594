const CAMPAIGN = 1;
const PLAN = 2;
const PLAN_EXTENSION = 3;
const ORDER = 4;
const FINISH = 10;

export const stepsEnum = Object.freeze({
  CAMPAIGN,
  PLAN,
  PLAN_EXTENSION,
  ORDER,
  FINISH
});
export const ERROR_MSG_OPTIONAL_STEP =
  "You can't skip the step that isn't optional";
export const WIZARD_COMPLETED_MSG =
  "All steps completed - please click finish to see Plan";
export const NAVIGATION_MSG = "Changes you made may not be saved";
export const CREATE_CAMPAIGN_STEP_FAILED = "Create Campaign step failed";
export const CREATE_PLAN_STEP_FAILED = "Create Plan step failed";
export const SKIP_ORDER_STEP_FAILED = "Skip Order step failed";

export const PROCESS_MODEL_ID = "processId";
export const SET_CAMPAIGN_STATUS = "SET_CAMPAIGN_STATUS";
export const RESET_CAMPAIGN_STATUS = "RESET_CAMPAIGN_STATUS";
export const CLEAR_STEPPER = "CLEAR_STEPPER";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const RESET_ACTIVE_STEP = "RESET_ACTIVE_STEP";
export const SET_CURRENT_ORDER_STEP = "SET_CURRENT_ORDER_STEP";
export const SET_ORDER_STEPS = "SET_ORDER_STEPS";
export const RESET_ORDER_STEPS = "RESET_ORDER_STEPS";
export const RESET_CURRENT_ORDER_STEP = "RESET_CURRENT_ORDER_STEP";
export const SET_STEPS = "SET_STEPS";
export const RESET_STEPS = "RESET_STEPS";
export const SET_PROCESS = "SET_PROCESS";
export function getSteps() {
  return [
    "Select Existing or Create Campaign",
    "Create Plan",
    "Create Additional Plan Data",
    "Create Order"
  ];
}
