import React from "react";
import { Routes, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import queryString from "query-string";

import AgencyPage from "./components/pages/agency/AgencyPage";
import Layout from "./components/pages/layout/Layout";
import TermsOfUse from "./components/pages/terms-of-use";
import PrivacyStatement from "./components/pages/privacy-statement";

const App = () => {
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      ReactRouterRoute={Route}
      options={{
        searchStringToObject: queryString.parse,
        objectToSearchString: queryString.stringify
      }}
    >
      <Routes>
        <Route path="/" element={<AgencyPage />} />
        <Route path="user" element={<AgencyPage />} />
        <Route path="termsOfUse" element={<TermsOfUse />} />
        <Route path="privacyStatement" element={<PrivacyStatement />} />
        <Route path="back-office/*" element={<Layout />} />
        <Route path=":agency/*" element={<Layout />} />
      </Routes>
    </QueryParamProvider>
  );
};

export default App;
