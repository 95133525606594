import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grow
} from "@mui/material";

import CampaignForm from "../forms/CampaignForm";

import CopyPlanTable from "./CopyPlanTable";
import {
  addNewCampaign,
  clearCurrentCampaign
} from "../../../../business/models/Campaign/campaignActions";

import {
  openCampaignFormPage,
  closeCampaignFormPage
} from "../../../../business/actions/campaignDashboardActions";
import { selectIsCampaignFormOpen } from "../../../../business/selectors/campaignDashboardSelector";

import { setCampaignStatus } from "../../../../business/actions/copyPlanActions";
import {
  selectIsInCopyPlanProcess,
  selectCampaignStatus
} from "../../../../business/selectors/copyPlanSelectors";

import {
  loadItems,
  resetPage
} from "../../../../business/actions/overviewPageActions";
import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";
import {
  selectItems,
  selectCount
} from "../../../../business/selectors/overviewPageSelectors";
import {
  canViewTrafficDashboardPage,
  canEditCampaign
} from "../../../../business/selectors/userProfileSelectors";

const SelectCampaign = () => {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({});
  const radioValue = useSelector(selectCampaignStatus);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const showCampaignForm = useSelector(selectIsCampaignFormOpen);
  const isLoading = useSelector(selectIsLoading);
  const rows = useSelector(selectItems);
  const count = useSelector(selectCount);
  const canViewTraffic = useSelector(canViewTrafficDashboardPage);
  const disableAction = !useSelector(canEditCampaign);
  const isInCopyProcess = useSelector(selectIsInCopyPlanProcess);

  const handleDynamicFilter = useCallback(
    filterQuery => {
      setFilters({ ...filterQuery });
      setCurrentPage(0);
    },
    [setFilters]
  );

  const handleChangePage = useCallback((_, page) => {
    setCurrentPage(page);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setPageSize(Number(event.target.value));
    setCurrentPage(0);
  }, []);

  useEffect(() => {
    const { campaignid, campaignname } = filters;
    if (radioValue === "existing") {
      dispatch(
        loadItems({
          currentPage,
          pageSize,
          campaignid,
          campaignname,
          processCopy: isInCopyProcess
        })
      );
    }
  }, [currentPage, pageSize, filters, dispatch, isInCopyProcess, radioValue]);

  useEffect(() => {
    dispatch(resetPage());
  }, [dispatch]);

  useEffect(() => {
    if (radioValue === "new") {
      dispatch(addNewCampaign());
      dispatch(openCampaignFormPage());
    }
    if (radioValue === "existing") {
      dispatch(clearCurrentCampaign());
      dispatch(closeCampaignFormPage());
    }
    return () => {
      dispatch(closeCampaignFormPage());
    };
  }, [dispatch, radioValue]);

  const handleChange = e => dispatch(setCampaignStatus(e.target.value));
  return (
    <>
      <FormControl>
        <FormLabel>Choose Campaign</FormLabel>
        <RadioGroup
          aria-label="Select campaign"
          name="select_campaign"
          id="select_campaign"
          value={radioValue}
          onChange={handleChange}
          row
        >
          <FormControlLabel value="new" control={<Radio />} label="New" />
          <FormControlLabel
            value="existing"
            control={<Radio />}
            label="Existing"
          />
        </RadioGroup>
      </FormControl>
      <Divider variant="middle" />

      {showCampaignForm ? (
        <Grow in={showCampaignForm}>
          <div>
            <CampaignForm />
          </div>
        </Grow>
      ) : (
        <Grow in={radioValue === "existing"}>
          <div>
            <CopyPlanTable
              rows={rows}
              count={count}
              pageSize={pageSize}
              currentPage={currentPage}
              contentLoading={isLoading}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              canViewTraffic={canViewTraffic}
              disableAction={disableAction}
              onDynamicFilter={handleDynamicFilter}
              queryFilters={filters}
            />
          </div>
        </Grow>
      )}
    </>
  );
};

export default SelectCampaign;
