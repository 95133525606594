import { styled, Table as MuiTable, Button as MuiButton } from "@mui/material";
import { getThemeSpacing } from "../../../../functions/util";
import {
  white,
  matisse,
  greyChateau,
  solitude,
  linkWater
} from "../../../../assets/styles/colors";

const PREFIX = "TargetMetricsKpiTableStyles";
export const classes = {
  header: `${PREFIX}-header`,
  saveButton: `${PREFIX}-saveButton`,
  table: `${PREFIX}-table`
};

export const Table = styled(MuiTable)(() => ({
  [`&.${classes.table}`]: {
    overflowX: "auto"
  },
  [`& .${classes.header}`]: {
    backgroundColor: solitude,
    color: matisse
  }
}));

export const Button = styled(MuiButton)(({ theme }) => ({
  [`&.${classes.saveButton}`]: {
    backgroundColor: greyChateau,
    color: white,
    marginTop: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    "&:disabled": {
      color: linkWater
    }
  }
}));
