import { createReducer } from "../models/common/utils/reducerUtils";
import {
  SET_OVERVIEW_ITEMS,
  SET_OVERVIEW_TAB_COUNTERS,
  SET_OVERVIEW_COUNT,
  RESET_OVERVIEW_PAGE,
  SET_SELECTED_SAVE_SEARCH
} from "../constants/overviewPageConstants";
import { EMPTY_STRING } from "../../configurations/appConstants";

const initialState = {
  tabCounters: {
    archivedCount: 0,
    allCount: 0,
    favouriteCount: 0
  },
  table: {
    count: 0,
    items: []
  },
  selectedSaveSearch: {
    label: "None",
    value: 0,
    path: EMPTY_STRING
  }
};

export const setItems = (state, payload) => ({
  ...state,
  table: { ...state.table, items: payload }
});

export const setCount = (state, payload) => ({
  ...state,
  table: { ...state.table, count: payload }
});

export const setTabCounters = (state, payload) => ({
  ...state,
  tabCounters: { ...payload }
});

export const setSelectedSearch = (state, payload) => ({
  ...state,
  selectedSaveSearch: {
    ...payload
  }
});

export const resetPage = () => initialState;

export default createReducer(initialState, {
  [SET_OVERVIEW_ITEMS]: setItems,
  [SET_OVERVIEW_COUNT]: setCount,
  [SET_OVERVIEW_TAB_COUNTERS]: setTabCounters,
  [RESET_OVERVIEW_PAGE]: resetPage,
  [SET_SELECTED_SAVE_SEARCH]: setSelectedSearch
});
