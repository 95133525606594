import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

class NoOptionsMessage extends React.Component {
  render() {
    const { selectProps, innerProps, children } = this.props;
    const { classes } = selectProps;
    return (
      <Typography
        align="center"
        color="textSecondary"
        className={classes.noOptionsMessage}
        {...innerProps}
      >
        {children}
      </Typography>
    );
  }
}

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
  children: PropTypes.string
};

NoOptionsMessage.defaultProps = {
  children: ""
};

export default NoOptionsMessage;
