import React from "react";
import { useSelector } from "react-redux";

import InsertionsView from "./extensions/InsertionsView";
import InsertionsEdit from "./extensions/InsertionsEdit";

import { selectInsertionIsEditing } from "../../business/models/Insertion/insertionSelectors";

const InsertionPanel = ({ isInCopyProcess }) => {
  const isEditing = useSelector(selectInsertionIsEditing);

  return isEditing ? (
    <InsertionsEdit />
  ) : (
    <InsertionsView isInCopyProcess={isInCopyProcess} />
  );
};

export default InsertionPanel;
