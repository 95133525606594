import join from "lodash/join";

import {
  SET_COUNT,
  SET_TRAFFIC_PAGE_ITEMS,
  RESET_TRAFFIC_OVERVIEW_PAGE,
  SET_TRAFFIC_OVERVIEW_TAB_COUNTERS,
  UPDATE_TRAFFICKER,
  REMOVE_ITEM
} from "../constants/trafficPageConstants";

import { selectAgency } from "../selectors/applicationSelectors";
import {
  GET_TRAFFIC_PLANS_COUNT_BY_STATUS,
  GET_TRAFFIC_PLANS
} from "../../configurations/apiUrls";
import generateUrl from "../models/common/utils/urlUtils";

import { enqueueApiErrorMessage, apiCall } from "./applicationActions";
import { GET } from "../constants/applicationConstants";
import { ASC, PLACEHOLDER } from "../../configurations/appConstants";

const getRequestBody = query => {
  const {
    currentPage = 0,
    pageSize,
    searchText,
    searchProperty,
    searchMyTrafficPlans,
    searchMediaTypes,
    sort,
    status,
    ...queryFilters
  } = query;
  const toSortBy = sort || "campaignId";
  const isascending = sort ? sort.indexOf(ASC) !== -1 : false;
  const sortbycolumn = isascending ? sort.split(PLACEHOLDER)[0] : toSortBy;
  const filterbystatus = status.toLowerCase();
  return {
    filterbystatus,
    searchproperty: searchProperty,
    searchtext: searchText,
    searchmytrafficplans: searchMyTrafficPlans,
    searchmediatypes: join(searchMediaTypes, ","),
    sortbycolumn,
    isascending,
    page: Number(currentPage) + 1,
    pagesize: Number(pageSize),
    ...queryFilters
  };
};

const getUrl = (agency, query) => {
  const paramObj = { agency };
  const queryObj = getRequestBody(query);
  return generateUrl(GET_TRAFFIC_PLANS, paramObj, queryObj);
};

export const setCount = payload => dispatch => {
  dispatch({ type: SET_COUNT, payload });
};

export const setItems = payload => ({ type: SET_TRAFFIC_PAGE_ITEMS, payload });

export const updateTrafficker = (trafficPlanId, trafficker) => ({
  type: UPDATE_TRAFFICKER,
  payload: { trafficPlanId, trafficker }
});

export const removeItem = trafficPlanId => ({
  type: REMOVE_ITEM,
  payload: { trafficPlanId }
});

export const resetPage = () => ({
  type: RESET_TRAFFIC_OVERVIEW_PAGE
});

export const loadItems = query => (dispatch, getState) => {
  const state = getState();
  const agency = selectAgency(state);
  const url = getUrl(agency, query);
  return dispatch(apiCall(GET, url)).then(
    response => {
      if (response) {
        const { totalItems, items } = response;
        dispatch(setCount(totalItems));
        dispatch(setItems(items));
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const setTabCounters = payload => ({
  type: SET_TRAFFIC_OVERVIEW_TAB_COUNTERS,
  payload
});

export const loadTabcounters = () => (dispatch, getState) => {
  const agencyID = selectAgency(getState());
  const url = generateUrl(GET_TRAFFIC_PLANS_COUNT_BY_STATUS, {
    agency: agencyID
  });
  dispatch(apiCall(GET, url)).then(response => {
    if (response) {
      dispatch(setTabCounters(response));
    }
  });
};
