/* eslint-disable import/no-cycle */
import cuid from "cuid";
import forEach from "lodash/forEach";

import {
  editExistingItem,
  stopEditingItem,
  editItemAttributes,
  editNewItem
} from "../common/editing/editingActions";

import { deleteEntity } from "../common/entities/entityActions";

import {
  PLAN_TARGET_METRICS_KPI_CLEAR_CURRENT,
  PLAN_TARGET_METRICS_KPI_SELECT,
  PLAN_TARGET_METRICS_KPI_MODEL_NAME,
  PLAN_TARGET_METRICS_KPI_EDIT_START,
  PLAN_TARGET_METRICS_KPI_EDIT_STOP,
  PLAN_TARGET_METRICS_KPI_HIDE_ERRORS,
  PLAN_TARGET_METRICS_KPI_DISPLAY_ERRORS,
  PLAN_TARGET_METRICS_KPI_SET_EDITING_ORIGINAL_ITEM,
  PLAN_TARGET_METRICS_KPI_CLEAR_EDITING_ORIGINAL_ITEM
} from "./planTargetMetricsKpiConstants";

import {
  getPlanTargetMetricKpiIDs,
  selectCurrentPlanTargetMetricsKpis,
  selectPlanTargetMetricsKpisIsEditing,
  doesPlanTargetMetricsKpiHasErrors,
  selectEditingPlanTargetMetricsKpiDataById,
  selectEditingOriginalItem,
  selectPlanTargetMetricsKpisByPlanExtensionId,
  getPlanTargetMetricKpiIDsForCopyPlanProcess
} from "./planTargetMetricsKpiSelectors";

import {
  selectCurrentPlanExtension,
  selectCurrentPlanExtensionByPlanId
} from "../PlanExtension/planExtensionSelectors";

import { stopEditingPlanChangeOverviews } from "../PlanChangeOverview/planChangeOverviewActions";

import { stopEditingPlanExtension } from "../PlanExtension/planExtensionActions";
import { stopEditingPlan } from "../Plan/planActions";
import { stopEditingCampaign } from "../Campaign/campaignActions";
import { getUnsharedEntitiesSession } from "../common/entities/entitySelectors";
import { isNullOrUndefined } from "../../../functions/util";

export const clearCurrentPlanTargetMetricKpis = () => dispatch =>
  dispatch({ type: PLAN_TARGET_METRICS_KPI_CLEAR_CURRENT });

export const stopEditingPlanTargetMetricsKpis = () => {
  return (dispatch, getState) => {
    const currentPlanTargetMetricsKpis = selectCurrentPlanTargetMetricsKpis(
      getState()
    );

    forEach(currentPlanTargetMetricsKpis, planTmkpiId =>
      dispatch(stopEditingItem(PLAN_TARGET_METRICS_KPI_MODEL_NAME, planTmkpiId))
    );

    dispatch({ type: PLAN_TARGET_METRICS_KPI_EDIT_STOP });
    dispatch({ type: PLAN_TARGET_METRICS_KPI_HIDE_ERRORS });
  };
};

export const selectPlanTargetMetricsKpis = (
  copyProcessProps = { isInCopyProcess: false, sourcePlanExtensionId: null }
) => {
  return (dispatch, getState) => {
    const state = getState();
    const isEditing = selectPlanTargetMetricsKpisIsEditing(state);

    if (isEditing) {
      dispatch(stopEditingPlanTargetMetricsKpis());
      dispatch(stopEditingPlanChangeOverviews());
      dispatch(stopEditingPlanExtension());
      dispatch(stopEditingPlan());
      dispatch(stopEditingCampaign());
    }

    const { isInCopyProcess, sourcePlanExtensionId } = copyProcessProps;
    const planTargetMetricKpiIDs = isInCopyProcess
      ? getPlanTargetMetricKpiIDsForCopyPlanProcess(state, {
          planExtensionId: sourcePlanExtensionId
        })
      : getPlanTargetMetricKpiIDs(state);

    dispatch(clearCurrentPlanTargetMetricKpis());
    dispatch({
      type: PLAN_TARGET_METRICS_KPI_SELECT,
      payload: {
        currentPlanTargetMetricsKpis: planTargetMetricKpiIDs
      }
    });
  };
};

export const startEditingPlanTargetMetricsKpis = () => {
  return (dispatch, getState) => {
    const currentPlanTargetMetricsKpis = selectCurrentPlanTargetMetricsKpis(
      getState()
    );

    forEach(currentPlanTargetMetricsKpis, planTmkpiId =>
      dispatch(
        editExistingItem(PLAN_TARGET_METRICS_KPI_MODEL_NAME, planTmkpiId)
      )
    );

    dispatch({ type: PLAN_TARGET_METRICS_KPI_EDIT_START });
  };
};

export const setPlanTargetMetricsKpiValue = (id, data) => {
  return dispatch => {
    dispatch(editItemAttributes(PLAN_TARGET_METRICS_KPI_MODEL_NAME, id, data));
  };
};

export const addPlanTargetMetricKpi = planTargetMetricKpiId => {
  return (dispatch, getState) => {
    const currentPlanTargetMetricKpis = selectCurrentPlanTargetMetricsKpis(
      getState()
    );

    dispatch({
      type: PLAN_TARGET_METRICS_KPI_SELECT,
      payload: {
        currentPlanTargetMetricsKpis: [
          ...currentPlanTargetMetricKpis,
          planTargetMetricKpiId
        ]
      }
    });
  };
};

export const addNewPlanTargetMetricsKpi = () => {
  return (dispatch, getState) => {
    const state = getState();
    const session = getUnsharedEntitiesSession(state);
    const planExtensionId = selectCurrentPlanExtension(state);

    const { PlanTargetMetricsKpi } = session;
    const id = cuid();

    const newPlanTargetMetricKpi = PlanTargetMetricsKpi.generate({
      id,
      planExtension: planExtensionId
    });

    const planTargetMetricKpiContents = newPlanTargetMetricKpi.toJSON();

    dispatch(
      editNewItem(
        PLAN_TARGET_METRICS_KPI_MODEL_NAME,
        id,
        planTargetMetricKpiContents
      )
    );
    dispatch(addPlanTargetMetricKpi(id));

    return id;
  };
};

// in memory delete target metrics kpi from editing entities
export const deletePlanTargetMetricsKpi = planTargetMetricsKpiId => {
  return (dispatch, getState) => {
    const currentPlanTargetMetricsKpis = selectCurrentPlanTargetMetricsKpis(
      getState()
    );

    dispatch({
      type: PLAN_TARGET_METRICS_KPI_SELECT,
      payload: {
        currentPlanTargetMetricsKpis: currentPlanTargetMetricsKpis.filter(
          id => id !== planTargetMetricsKpiId
        )
      }
    });
    dispatch(
      stopEditingItem(
        PLAN_TARGET_METRICS_KPI_MODEL_NAME,
        planTargetMetricsKpiId
      )
    );
  };
};

export const displayPlanTargetMetricsKpiErrors = () => {
  return { type: PLAN_TARGET_METRICS_KPI_DISPLAY_ERRORS };
};

export const planTargetMetricsKpiHasErrors = id => {
  return (dispatch, getState) => {
    const hasErrors = doesPlanTargetMetricsKpiHasErrors(getState(), {
      entityId: id
    });
    if (hasErrors) {
      if (hasErrors) {
        dispatch(displayPlanTargetMetricsKpiErrors());
      }
    }
    return hasErrors;
  };
};

export const setEditingOriginalPlanTargetMetrcsKpiData = id => {
  return (dispatch, getState) => {
    const itemData = selectEditingPlanTargetMetricsKpiDataById(getState(), {
      entityId: id
    });

    dispatch({
      type: PLAN_TARGET_METRICS_KPI_SET_EDITING_ORIGINAL_ITEM,
      payload: {
        editingPlanTargetMetricsKpis: itemData
      }
    });
  };
};

export const clearEditingOriginalPlanTargetMetrcsKpiData = () => {
  return dispatch => {
    dispatch({
      type: PLAN_TARGET_METRICS_KPI_CLEAR_EDITING_ORIGINAL_ITEM
    });
  };
};

export const resetCurrentPlanTargetMetricsKpi = id => {
  return (dispatch, getState) => {
    const state = getState();
    const itemData = selectEditingPlanTargetMetricsKpiDataById(state, {
      entityId: id
    });
    const original = selectEditingOriginalItem(state);
    const originalData = original.editingPlanTargetMetricsKpis;

    Object.keys(itemData).forEach(key => {
      if (isNullOrUndefined(originalData[key])) {
        dispatch(
          editItemAttributes(PLAN_TARGET_METRICS_KPI_MODEL_NAME, id, {
            [key]: null
          })
        );
      } else {
        dispatch(
          editItemAttributes(PLAN_TARGET_METRICS_KPI_MODEL_NAME, id, {
            [key]: originalData[key]
          })
        );
      }
    });
  };
};

// delete all plan target metrics kpi from entities for current plan extension (create, update)
export const deletePlanTargetMetricsKpis = () => {
  return (dispatch, getState) => {
    const planTargetMetricsKpiIDs = getPlanTargetMetricKpiIDs(getState());

    forEach(planTargetMetricsKpiIDs, planTmkpiId =>
      dispatch(deleteEntity(PLAN_TARGET_METRICS_KPI_MODEL_NAME, planTmkpiId))
    );
  };
};

// delete all plan target metrics kpi from entities for given plan - update plan with deletions
export const deletePlansTargetMetricsKpisByPlanId = () => {
  return (dispatch, getState) => {
    const state = getState();

    const planExtension = selectCurrentPlanExtensionByPlanId(state);
    const { planExtensionId } = planExtension;

    const planTargetMetricsKpiIDs = selectPlanTargetMetricsKpisByPlanExtensionId(
      state,
      { planExtensionId }
    );

    forEach(planTargetMetricsKpiIDs, planTmkpiId =>
      dispatch(deleteEntity(PLAN_TARGET_METRICS_KPI_MODEL_NAME, planTmkpiId))
    );
  };
};
