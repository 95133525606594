import React, { Component } from "react";
import { connect } from "react-redux";
import UserManagementList from "./UserManagementList";
import {
  loadUsersData,
  selectUser,
  startEditingUser,
  stopEditingUser,
  saveUser,
  clearCurrentUser,
  setSearchTerm,
  setSearchProperty,
  activeUsers
} from "../../../business/models/User/userActions";
import {
  getUsersData,
  selectCurrentUser,
  selectUserIsNew,
  selectSearchProperty,
  selectSearchTerm,
  selectIsActiveUserSwitch
} from "../../../business/models/User/userSelectors";
import UserManagementDialog from "./UserManagementDialog";
import { isNullOrUndefined } from "../../../functions/util";
import { classes, Div } from "../system-messages/styles/userManagmentPageStyle";
import withRouter from "../configuration/form/withRouter";

const CREATE_MESSAGE = "Create";
const EDIT_MESSAGE = "Edit";
class UserManagementPage extends Component {
  componentDidMount() {
    this.props.loadUsers();
  }

  handleEdit = userId => {
    const { setUser, editUser } = this.props;
    setUser(userId);
    editUser();
  };

  handleDialogClose = () => {
    const { stopUserEdit, clearUser } = this.props;
    clearUser();
    stopUserEdit();
  };

  handleDialogSave = () => {
    const { saveUser: handleSaveUser } = this.props;
    handleSaveUser();
  };

  handleSearchBy = event => {
    this.props.setSearchProperty(event.target.value);
  };

  handleInputSearch = event => {
    this.props.setSearchTerm(event.target.value);
  };

  handleViewProfile = userId => {
    if (!isNullOrUndefined(userId)) {
      this.props.navigate(`/back-office/user-profile/${userId}`);
    }
  };

  render() {
    const {
      users,
      openDialog,
      dialogMsg,
      searchProperty,
      searchText,
      isActiveUserSwitch,
      handleToggleSwitch
    } = this.props;
    return (
      <Div className={classes.root}>
        <UserManagementList
          users={users}
          onEditClick={this.handleEdit}
          onProfileClick={this.handleViewProfile}
          onChange={this.handleSearchBy}
          onInputChange={this.handleInputSearch}
          searchProperty={searchProperty}
          searchText={searchText}
          handleToggleSwitch={handleToggleSwitch}
          isActiveUserSwitch={isActiveUserSwitch}
        />
        <UserManagementDialog
          open={openDialog}
          action={dialogMsg}
          onCancel={this.handleDialogClose}
          onSave={this.handleDialogSave}
        />
      </Div>
    );
  }
}

const mapStateToProps = state => {
  const currentUserId = selectCurrentUser(state);
  const isNew = selectUserIsNew(state);
  const dialogMsg = isNew ? CREATE_MESSAGE : EDIT_MESSAGE;
  return {
    users: getUsersData(state),
    openDialog: Boolean(currentUserId),
    dialogMsg,
    searchProperty: selectSearchProperty(state),
    searchText: selectSearchTerm(state),
    isActiveUserSwitch: selectIsActiveUserSwitch(state)
  };
};

const mapDispatchToProps = dispatch => ({
  handleToggleSwitch: () => dispatch(activeUsers()),
  loadUsers: () => dispatch(loadUsersData()),
  setUser: userId => dispatch(selectUser(userId)),
  editUser: () => dispatch(startEditingUser()),
  stopUserEdit: () => dispatch(stopEditingUser()),
  saveUser: () => dispatch(saveUser()),
  clearUser: () => dispatch(clearCurrentUser()),
  setSearchTerm: searchTerm => dispatch(setSearchTerm(searchTerm)),
  setSearchProperty: searchProperty =>
    dispatch(setSearchProperty(searchProperty))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserManagementPage)
);
