import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  StringParam,
  useQueryParams,
  DelimitedArrayParam
} from "use-query-params";
import filter from "lodash/filter";
import includes from "lodash/includes";
import concat from "lodash/concat";
import AdditionalApprovalTable from "./table/AdditionalApprovalTable";

import {
  loadConditions,
  resetApprovalOverviewPage,
  setCurrentCondition
} from "../../../../business/actions/approvalPageActions";

import {
  selectItems,
  selectCount
} from "../../../../business/selectors/approvalPageSelectors";

import { selectIsLoading } from "../../../../business/selectors/applicationSelectors";

const filterQuerys = {
  planid: StringParam,
  orderid: StringParam,
  debtorname: StringParam
};

const AdditionalApprovalPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [query, setQuery] = useQueryParams({
    ...filterQuerys,
    searchRuleTypes: DelimitedArrayParam
  });

  const { agency } = useParams();

  const { planid, orderid, debtorname, searchRuleTypes } = query;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rows = useSelector(state => selectItems(state));
  const count = useSelector(state => selectCount(state));
  const isLoading = useSelector(state => selectIsLoading(state));

  useEffect(() => {
    dispatch(
      loadConditions({
        currentPage,
        pageSize,
        planid,
        orderid,
        debtorname,
        searchRuleTypes
      })
    );
  }, [
    dispatch,
    currentPage,
    pageSize,
    planid,
    orderid,
    debtorname,
    searchRuleTypes
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetApprovalOverviewPage());
    };
  }, [dispatch]);

  const handleChangePage = useCallback((_, page) => {
    setCurrentPage(page);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setPageSize(Number(event.target.value));
    setCurrentPage(0);
  }, []);

  const handleOrderClick = useCallback(
    condition => {
      dispatch(setCurrentCondition(condition));
      const { orderId, conditionId } = condition;
      navigate(`/${agency}/approval/order/${orderId}/${conditionId}`);
    },
    [dispatch, navigate, agency]
  );

  const handleSearchRuleTypes = ruleType => {
    setQuery(
      {
        searchRuleTypes: includes(searchRuleTypes, ruleType)
          ? filter(searchRuleTypes, val => val !== ruleType)
          : concat(searchRuleTypes, ruleType)
      },
      "pushIn"
    );
    setCurrentPage(0);
  };

  const handleDynamicFilter = useCallback(
    filterQuery => {
      setQuery({ ...filterQuery }, "pushIn");
      setCurrentPage(0);
    },
    [setQuery]
  );

  return (
    <>
      <AdditionalApprovalTable
        rows={rows}
        count={count}
        pageSize={pageSize}
        currentPage={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onOrderClick={handleOrderClick}
        contentLoading={isLoading}
        searchRuleTypes={searchRuleTypes}
        onSearchRuleTypes={handleSearchRuleTypes}
        onDynamicFilter={handleDynamicFilter}
        queryFilters={{
          planid,
          orderid,
          debtorname
        }}
      />
    </>
  );
};

export default AdditionalApprovalPage;
