import React, { memo } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@mui/material";

import { selectOpenTrafficItemDeleteDialogTitle } from "../../../../business/selectors/applicationSelectors";
import {
  deleteTrafficItem,
  stopEditingTrafficItem,
  editTrafficItem
} from "../../../../business/models/TrafficItem/trafficItemActions";
import { closeConfirmTrafficItemDeleteDialog } from "../../../../business/actions/applicationActions";

const paperProps = { square: true };

const ConfirmTrafficItemDeleteDialog = ({
  isOpen,
  onDelete,
  stopEdit,
  closeDialog,
  editTraffic
}) => {
  const handleDelete = () => {
    onDelete();
    closeDialog();
  };

  const handleCancel = () => {
    stopEdit();
    closeDialog();
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={paperProps}
      TransitionProps={{
        onEntering: () => {
          editTraffic();
        }
      }}
      maxWidth="md"
    >
      <DialogContent>
        <DialogContentText noWrap={false}>
          Are you sure you want to delete Traffic Item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel_delete"
          name="cancel_delete"
          onClick={handleCancel}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          id="delete_order"
          name="delete_order"
          onClick={handleDelete}
          color="secondary"
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  isOpen: selectOpenTrafficItemDeleteDialogTitle(state)
});

const mapDispatchToProps = dispatch => ({
  onDelete: () => dispatch(deleteTrafficItem()),
  closeDialog: () => dispatch(closeConfirmTrafficItemDeleteDialog()),
  stopEdit: () => dispatch(stopEditingTrafficItem()),
  editTraffic: () => {
    dispatch(editTrafficItem());
  }
});

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmTrafficItemDeleteDialog)
);
