import isEqual from "lodash/isEqual";
import { createReducer } from "../models/common/utils/reducerUtils";
import {
  TOGGLE_SELECTED,
  CLEAR_SELECTED,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_ACTION,
  CLEAR_ACTION,
  SET_ITEMS,
  CLEAR_ITEMS,
  SET_SEND_STATUSES,
  SET_UNSEND_STATUSES,
  CLEAR_SEND_STATUSES,
  CLEAR_UNSEND_STATUSES,
  SET_COUNT,
  RESET_ACTION_PAGE,
  SET_COMPARE_ORDER_ID,
  OPEN_COMPARE_DIALOG,
  CLOSE_COMPARE_DIALOG
} from "../constants/actionPageConstants";

const initialState = {
  action: null,
  openDialog: false,
  selected: [],
  selectedNumber: 0,
  sendStatuses: [],
  unsendStatuses: [],
  compareOrderId: null,
  openCompareDialog: false,
  table: {
    count: 0,
    items: []
  }
};

const removeElement = (arr, value) => arr.filter(element => element !== value);

export const toogleSelected = (state, payload) => {
  const { selected } = state;
  let newSelected = [];
  let isSelected = false;

  if (payload.constructor === Array) {
    if (isEqual(selected.sort(), payload.sort())) {
      newSelected = [];
    } else {
      newSelected = payload;
    }
  } else {
    isSelected = selected.includes(payload);

    if (isSelected) {
      newSelected = removeElement(selected, payload);
    } else {
      newSelected = [...selected, payload];
    }
  }

  const selectedNumber = newSelected.length;

  return {
    ...state,
    selected: newSelected,
    selectedNumber
  };
};

export const clearAction = state => ({ ...state, action: null });

export const setAction = (state, payload) => ({ ...state, action: payload });

export const clearSelected = state => ({
  ...state,
  selected: [],
  selectedNumber: 0
});

export const setItems = (state, payload) => ({
  ...state,
  table: { ...state.table, items: payload }
});

export const clearItems = state => ({
  ...state,
  table: { ...state.table, items: [] }
});

export const setCount = (state, payload) => ({
  ...state,
  table: { ...state.table, count: payload }
});

export const openDialog = state => ({ ...state, openDialog: true });

export const closeDialog = state => ({ ...state, openDialog: false });

export const setSendStatuses = (state, payload) => ({
  ...state,
  sendStatuses: payload
});

export const setUnsendStatuses = (state, payload) => ({
  ...state,
  unsendStatuses: payload
});

export const clearUnsendStatuses = state => ({ ...state, unsendStatuses: [] });

export const clearSendStatuses = state => ({ ...state, sendStatuses: [] });

export const setCompareOrderId = (state, payload) => ({
  ...state,
  compareOrderId: payload
});

export const openCompareDialog = state => ({
  ...state,
  openCompareDialog: true
});

export const closeCompareDialog = state => ({
  ...state,
  openCompareDialog: false,
  compareOrderId: null
});

export const resetPage = () => initialState;

export default createReducer(initialState, {
  [TOGGLE_SELECTED]: toogleSelected,
  [CLEAR_SELECTED]: clearSelected,
  [OPEN_DIALOG]: openDialog,
  [CLOSE_DIALOG]: closeDialog,
  [SET_ACTION]: setAction,
  [CLEAR_ACTION]: clearAction,
  [SET_ITEMS]: setItems,
  [CLEAR_ITEMS]: clearItems,
  [SET_SEND_STATUSES]: setSendStatuses,
  [SET_UNSEND_STATUSES]: setUnsendStatuses,
  [CLEAR_SEND_STATUSES]: clearSendStatuses,
  [CLEAR_UNSEND_STATUSES]: clearUnsendStatuses,
  [SET_COUNT]: setCount,
  [RESET_ACTION_PAGE]: resetPage,
  [SET_COMPARE_ORDER_ID]: setCompareOrderId,
  [OPEN_COMPARE_DIALOG]: openCompareDialog,
  [CLOSE_COMPARE_DIALOG]: closeCompareDialog
});
