import join from "lodash/join";
import download from "../../functions/download";

import {
  apiCall,
  enqueueApplicationErrorMessage,
  enqueueApiErrorMessage,
  enqueueNotificationMessage
} from "./applicationActions";

import {
  SET_OVERVIEW_ITEMS,
  SET_OVERVIEW_TAB_COUNTERS,
  SET_OVERVIEW_COUNT,
  RESET_OVERVIEW_PAGE,
  SET_SELECTED_SAVE_SEARCH
} from "../constants/overviewPageConstants";
import { CAMPAIGN_MODEL_ID } from "../models/Campaign/campaignConstants";
import {
  PLACEHOLDER,
  ASC,
  CAMPAIGN_FAVOURITES,
  EMPTY_STRING
} from "../../configurations/appConstants";
import { GET, POST, DELETE, PUT } from "../constants/applicationConstants";

import { selectAgency } from "../selectors/applicationSelectors";

import {
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_COUNT_BY_STATUS,
  GET_CAMPAIGN_EXPORT,
  CAMPAIGN_FAVOURITE,
  GET_SAVED_SEARCHES,
  CREATE_SAVE_SEARCH,
  DELETE_SAVE_SEARCH,
  EDIT_SAVE_SEARCH
} from "../../configurations/apiUrls";

import generateUrl from "../models/common/utils/urlUtils";
import { selectUserId } from "../selectors/userProfileSelectors";

const getRequestBody = query => {
  const {
    currentPage = 0,
    pageSize,
    searchProperty,
    searchText,
    searchMyCampaigns,
    searchMediaTypes,
    sort,
    status = EMPTY_STRING,
    processCopy,
    ...queryFilters
  } = query;

  const toSortBy = sort || "campaignId";
  const isascending = sort ? sort.indexOf(ASC) !== -1 : false;
  const sortbycolumn = isascending ? sort.split(PLACEHOLDER)[0] : toSortBy;
  const filterbystatus = status.toLowerCase();

  const showOnlyFavourites = status === CAMPAIGN_FAVOURITES.toLowerCase();

  return {
    filterbystatus,
    showOnlyFavourites,
    searchproperty: searchProperty,
    searchtext: searchText,
    searchmycampaigns: searchMyCampaigns,
    searchmediatypes: join(searchMediaTypes, ","),
    sortbycolumn,
    isascending,
    page: Number(currentPage) + 1,
    pagesize: Number(pageSize),
    processCopy,
    ...queryFilters
  };
};

const getUrl = (agency, query) => {
  const paramObj = { agency };
  const queryObj = getRequestBody(query);
  return generateUrl(GET_CAMPAIGNS, paramObj, queryObj);
};

export const setItems = payload => ({ type: SET_OVERVIEW_ITEMS, payload });

export const setCount = payload => dispatch => {
  dispatch({ type: SET_OVERVIEW_COUNT, payload });
};

export const resetPage = () => ({ type: RESET_OVERVIEW_PAGE });

export const setTabCounters = payload => ({
  type: SET_OVERVIEW_TAB_COUNTERS,
  payload
});

export const setSelectedSaveSearch = payload => ({
  type: SET_SELECTED_SAVE_SEARCH,
  payload
});

export const loadTabcounters = agency => dispatch => {
  const url = generateUrl(GET_CAMPAIGNS_COUNT_BY_STATUS, {
    agency
  });

  dispatch(apiCall(GET, url)).then(response => {
    if (response) {
      dispatch(setTabCounters(response));
    }
  });
};

export const loadSaveSearches = () => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const userId = selectUserId(state);

  const url = generateUrl(GET_SAVED_SEARCHES, {
    agency: agencyID || 0,
    userId
  });

  return dispatch(apiCall(GET, url)).then(
    response => response,
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const loadItems = query => (dispatch, getState) => {
  const state = getState();
  const agency = selectAgency(state);
  const url = getUrl(agency, query);

  return dispatch(apiCall(GET, url)).then(
    response => {
      if (response) {
        const { totalItems, items } = response;
        dispatch(setCount(totalItems));
        dispatch(setItems(items));
      }
    },
    error => {
      dispatch(enqueueApplicationErrorMessage(error));
    }
  );
};

export const downloadExcel = id => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);

  const url = generateUrl(GET_CAMPAIGN_EXPORT, {
    agency: agencyID,
    [CAMPAIGN_MODEL_ID]: id
  });

  return dispatch(apiCall(GET, url))
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      download(blob, `${id}.xlsx`);
    })
    .then(() => enqueueNotificationMessage("File is successfully downloaded!"))
    .catch(() =>
      enqueueApiErrorMessage("There was problem while downloading file.")
    );
};

export const campaignFavourite = (id, query) => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);

  const url = generateUrl(CAMPAIGN_FAVOURITE, {
    agency: agencyID,
    [CAMPAIGN_MODEL_ID]: id
  });

  return dispatch(apiCall(POST, url)).then(
    response => {
      if (response.isSuccessful) {
        dispatch(loadItems(query));
        dispatch(loadTabcounters(agencyID));
      }

      return id;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const campaignSaveSearch = (value, searchName) => (
  dispatch,
  getState
) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const userId = selectUserId(state);
  const url = generateUrl(CREATE_SAVE_SEARCH, {
    agency: agencyID || 0,
    userId
  });

  return dispatch(apiCall(POST, url, { searchName, value })).then(
    response => response,

    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const editCampaignSaveSearch = (value, searchName, campaignSearchId) => (
  dispatch,
  getState
) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const userId = selectUserId(state);
  const url = generateUrl(EDIT_SAVE_SEARCH, {
    agency: agencyID || 0,
    userId,
    campaignSearchId
  });

  return dispatch(apiCall(PUT, url, { searchName, value })).then(
    response => response,

    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const deleteCampaignSaveSearch = campaignSearchId => (
  dispatch,
  getState
) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const userId = selectUserId(state);
  const url = generateUrl(DELETE_SAVE_SEARCH, {
    agency: agencyID || 0,
    userId,
    campaignSearchId
  });

  return dispatch(apiCall(DELETE, url)).then(
    response => response,

    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};
