import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Alert
} from "@mui/material";

import Checkbox from "../../../form/controls/Checkbox";
import DialogTitle from "../../../shared/dialog/DialogTitle";
import PlanExportDialogActions from "./PlanExportDialogActions";
import { closePlanExportDialog } from "../../../../business/actions/campaignDashboardActions";
import {
  downloadExcel,
  getExcelComponents
} from "../../../../business/models/Plan/planActions";
import { getPlan } from "../../../../business/models/Plan/planSelectors";
import {
  DISABLED_CHECKBOXES,
  SEPARATED_COMPONENTS,
  CHILD_CHECKBOX_LEFT_MARGIN,
  CHECKBOXES_WITH_TOP_BORDER,
  THIRD_DIALOG_SECTION,
  AT_LEAST_ONE_MUST_BE_CHECKED,
  DIALOG_FAILED_TO_DISPLAY,
  HIDDEN_CHECKBOXES,
  ORDER_DETAILS_ID,
  STAY_CHECKED_WHEN_BUDGET_FLEX,
  ORDER_DETAILS,
  NEXT,
  DOWNLOAD,
  PIPE_SYMBOL,
  SELECT_ORDERS,
  GO_EXPORT_COMPONENTS
} from "../../../../business/constants/PlanExportDialogConstants";
import FootnoteTable from "./extensions/FootnoteTable";
import { classes, Typography } from "../styles/planExportDialog";
import { getOrders } from "../../../../business/models/Order/orderSelectors";
import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_TO_BE_CANCELLED
} from "../../../../configurations/appConstants";
import { parseDate } from "../../../../business/models/common/utils/clientUtils";

const PlanExportDialog = () => {
  const dispatch = useDispatch();

  const [planExport, setPlanExport] = useState([]);
  const [showRatecard, setShowRatecard] = useState(false);
  const [footnotes, setFootnotes] = useState([]);
  const [isRatecardChecked, setIsRatecardChecked] = useState(false);
  const [isOrderDialog, setIsOrderDialog] = useState(false);
  const [buttonText, setButtonText] = useState(NEXT);
  const [planOrder, setPlanOrder] = useState([]);
  const currentPlan = useSelector(getPlan);
  const currentOrders = useSelector(getOrders);
  const isForeignCurrency =
    currentPlan && currentPlan.currencySupplierId !== "EUR";

  const formatMonetaryString = (
    currency,
    number,
    thousandSeparator,
    decimalSeparator
  ) => {
    if (!number) {
      return `${currency} 0.00`;
    }
    const formattedString = number
      .toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      .replaceAll(",", PIPE_SYMBOL)
      .replaceAll(".", decimalSeparator)
      .replaceAll(PIPE_SYMBOL, thousandSeparator);

    return `${currency} ${formattedString}`;
  };

  useEffect(() => {
    const getPlanExport = async () => {
      const { data, showRatecardOption } = await dispatch(getExcelComponents());
      setPlanExport(data);
      setShowRatecard(showRatecardOption);
      const orderDetailsValue = data.filter(
        item => item.name === ORDER_DETAILS
      );
      setButtonText(orderDetailsValue[0].defaultValue ? NEXT : DOWNLOAD);
    };
    const getFilteredOrders = items => {
      const filteredOrders = items
        .filter(
          order =>
            order.statusId !== ORDER_STATUS_CANCELLED &&
            order.statusId !== ORDER_STATUS_TO_BE_CANCELLED
        )
        .map(item => {
          return {
            text: `${item.marathonOrderId}
            ${PIPE_SYMBOL}
            ${item.saleshouseId}
            ${PIPE_SYMBOL}
            ${item.description}
            ${PIPE_SYMBOL}
            ${parseDate(item.orderStartDate)} - 
            ${parseDate(item.orderEndDate)}
            ${PIPE_SYMBOL}
            ${formatMonetaryString(
              item.currencySupplierSymbol,
              item.netTotal,
              ".",
              ","
            )}`,
            key: item.marathonOrderId,
            id: item.orderId,
            checked: true
          };
        });
      setPlanOrder(filteredOrders);
    };
    getPlanExport();
    getFilteredOrders(currentOrders);
  }, [dispatch, currentOrders]);

  const isThirdSectionValid = () => {
    const areChecked = planExport
      .filter(p => THIRD_DIALOG_SECTION.includes(p.name))
      .map(p => p.defaultValue);

    return areChecked.includes(true);
  };

  const isDialogValid = () => {
    return isThirdSectionValid();
  };

  const handleDownload = () => {
    if (buttonText === NEXT) {
      setIsOrderDialog(true);
      setButtonText(DOWNLOAD);
      return;
    }
    if (isDialogValid()) {
      const clickedComponentIds = planExport
        .filter(p => p.defaultValue)
        .map(p => p.id);
      const footnotesCustom = footnotes
        .filter(f => f.value.trim() !== "")
        .map(f => f.value);
      const checkedOrderIds = planOrder.filter(o => o.checked).map(o => o.id);

      dispatch(
        downloadExcel(
          clickedComponentIds,
          footnotesCustom,
          isRatecardChecked,
          currentPlan.marathonPlanId,
          currentPlan.planName,
          checkedOrderIds
        )
      );
      dispatch(closePlanExportDialog());
    }
  };

  const handleCancelPlanExportDialog = () => dispatch(closePlanExportDialog());

  const shouldErrorBeDisplay = name => {
    return THIRD_DIALOG_SECTION[0] === name && !isThirdSectionValid();
  };

  const shouldChildCheckboxesReset = (
    checked,
    clickedCheckboxName,
    checkbox
  ) => {
    return (
      !checked &&
      SEPARATED_COMPONENTS[clickedCheckboxName]?.includes(checkbox.name)
    );
  };

  const handleCheckBoxClick = (checked, fieldName) => {
    if (fieldName === ORDER_DETAILS) {
      setButtonText(checked ? NEXT : DOWNLOAD);
      if (!checked) {
        setIsRatecardChecked(checked);
      }
    }

    setPlanExport(
      planExport.map(p => {
        if (shouldChildCheckboxesReset(checked, fieldName, p)) {
          return { ...p, defaultValue: false };
        }
        if (p.name === fieldName) {
          return { ...p, defaultValue: checked };
        }
        return p;
      })
    );
  };

  const isCheckboxDisabled = name => {
    return (
      DISABLED_CHECKBOXES.includes(name) ||
      !!Object.entries(SEPARATED_COMPONENTS).find(
        ([key, value]) =>
          !!planExport.find(plan => plan.name === key && !plan.defaultValue) &&
          value.includes(name)
      )
    );
  };

  const isCheckboxHidden = name => {
    return HIDDEN_CHECKBOXES.includes(name);
  };

  const checkboxStyle = name => {
    if (CHECKBOXES_WITH_TOP_BORDER.includes(name)) return { borderTop: 1 };

    if (Object.values(SEPARATED_COMPONENTS).find(sc => sc.includes(name)))
      return { ml: CHILD_CHECKBOX_LEFT_MARGIN };

    return {};
  };

  const updateFootnotes = data => {
    setFootnotes(data);
  };
  const handleOrderCheckBoxClick = (selected, id) => {
    setPlanOrder(
      planOrder.map(p => {
        if (p.key === id) {
          return { ...p, checked: selected };
        }
        return p;
      })
    );
  };
  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle id="excel-export-dialog-title">
        {isOrderDialog ? SELECT_ORDERS : GO_EXPORT_COMPONENTS}
      </DialogTitle>
      {isForeignCurrency && !isOrderDialog && (
        <Alert severity="warning">
          Plan is in foreign currency, please double-check amounts.
        </Alert>
      )}
      {isOrderDialog && planOrder.length < 1 && (
        <Alert severity="error">No Orders found.</Alert>
      )}
      <DialogContent>
        {isOrderDialog &&
          planOrder &&
          planOrder.map(item => {
            return (
              <Grid container key={item.key}>
                <Checkbox
                  onChange={e => handleOrderCheckBoxClick(e, item.key)}
                  id={item.key}
                  label={item.text}
                  value={item.checked}
                  color="success"
                />
              </Grid>
            );
          })}
        {!isOrderDialog && (
          <Grid container>
            {planExport ? (
              <>
                {planExport.map(p => {
                  const { id, name, defaultValue } = p;
                  return (
                    <Grid container key={id} xs={12} sx={checkboxStyle(name)}>
                      <Checkbox
                        onChange={e => handleCheckBoxClick(e, name)}
                        error={shouldErrorBeDisplay(name)}
                        errorMessage={AT_LEAST_ONE_MUST_BE_CHECKED}
                        label={name}
                        value={defaultValue}
                        color="success"
                        disabled={isCheckboxDisabled(name)}
                        hidden={isCheckboxHidden(name)}
                      />
                      {ORDER_DETAILS_ID === id && (
                        <>
                          <Typography className={classes.typographyCaptionBold}>
                            {STAY_CHECKED_WHEN_BUDGET_FLEX}
                          </Typography>
                          {showRatecard && (
                            <Grid
                              container
                              sx={{ ml: CHILD_CHECKBOX_LEFT_MARGIN }}
                            >
                              <Checkbox
                                onChange={e => setIsRatecardChecked(e)}
                                label="Export Ratecard"
                                value={isRatecardChecked}
                                color="info"
                                disabled={!defaultValue}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  );
                })}

                {planExport.length > 0 && (
                  <FootnoteTable footnotes={updateFootnotes} />
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <Alert severity="error">{DIALOG_FAILED_TO_DISPLAY}</Alert>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <PlanExportDialogActions
          isDownloadDisabled={!planExport}
          onCancelClick={handleCancelPlanExportDialog}
          onDownloadClick={handleDownload}
          buttonText={buttonText}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PlanExportDialog;
