import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getPageData } from "../../../business/actions/configurationPageActions";

const ConfigOptionsContext = React.createContext();
const { Consumer, Provider } = ConfigOptionsContext;

const ConfigOptionsContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(undefined);

  useEffect(() => {
    dispatch(getPageData()).then(response => {
      setOptions(response);
    });

    return () => {
      setOptions(undefined);
    };
  }, [dispatch]);

  return <Provider value={{ ...options }}>{children}</Provider>;
};

export {
  ConfigOptionsContext,
  ConfigOptionsContextProvider,
  Consumer as ConfigOptionsContextConsumer
};
