import validator from "validator";
import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import get from "lodash/get";
import set from "lodash/set";
import reduce from "lodash/reduce";
import { EMPTY_STRING } from "../../../../configurations/appConstants";
import { isNullOrUndefined } from "../../../../functions/util";
import { VALIDATE_EXCHANGE_RATE } from "../../../constants/validationConstants";

const iso8859 = /^[\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178]*$/;

export const validation = {
  mandatory(value) {
    return !validator.isEmpty(value);
  },
  email(value, options) {
    return !validator.isEmpty(value) ? validator.isEmail(value, options) : true;
  },
  equals(value, comparison) {
    return !validator.isEmpty(value)
      ? validator.equals(value, String(comparison))
      : true;
  },
  date(value) {
    return !validator.isEmpty(value)
      ? validator.isAfter(value, "1900-01-01") &&
          validator.isDate(validator.toDate(value))
      : true;
  },
  alphanumeric(value, locale) {
    return !validator.isEmpty(value)
      ? validator.isAlphanumeric(value, locale)
      : true;
  },
  numeric(value) {
    return !validator.isEmpty(value) ? validator.isNumeric(value) : true;
  },
  int(value, options) {
    return !validator.isEmpty(value) ? validator.isInt(value, options) : true;
  },
  decimal(value) {
    return !validator.isEmpty(value) ? validator.isDecimal(value) : true;
  },
  length(value, options) {
    return validator.isLength(value, options);
  },
  iso_8859_15(value) {
    return iso8859.test(value);
  },
  validate_exchange_rate(value, options) {
    if (value === "1" && options.currency && options.baselineCurrency) {
      return validator.equals(options.currency, options.baselineCurrency);
    }
    return true;
  }
};

const updateRuleWithCurrencyData = (data, propertyValidation) => {
  return propertyValidation?.map(obj =>
    obj.rule === VALIDATE_EXCHANGE_RATE
      ? {
          ...obj,
          options: {
            currency: get(data, obj.validateField),
            baselineCurrency: obj.options.baselineCurrency
          }
        }
      : obj
  );
};

const getValueOrDefault = value =>
  isNullOrUndefined(value) ? EMPTY_STRING : String(value);

export const validateProperty = (
  propertyValidation,
  propertyValue,
  isExluded
) => {
  return reduce(
    propertyValidation,
    (propertyErrors, propertyRule) => {
      const value = getValueOrDefault(propertyValue);

      const isValid = validation[propertyRule.rule](
        value,
        propertyRule.options
      );
      if (!isValid && !isExluded) {
        propertyErrors.push(propertyRule.message);
      }
      return propertyErrors;
    },
    []
  );
};

export const validateModel = (modelValidation, data, exludedFields = []) =>
  reduce(
    modelValidation,
    (modelErrors, propertyValidation, propertyName) =>
      set(
        modelErrors,
        propertyName,
        validateProperty(
          updateRuleWithCurrencyData(data, propertyValidation),
          get(data, propertyName),
          exludedFields.includes(propertyName)
        )
      ),
    {}
  );

export const doesModelHaveErrors = (
  modelValidation,
  data,
  exludedFields = []
) =>
  some(modelValidation, (propertyValidation, propertyName) => {
    return !isEmpty(
      validateProperty(
        updateRuleWithCurrencyData(data, propertyValidation),
        get(data, propertyName),
        exludedFields.includes(propertyName)
      )
    );
  });
