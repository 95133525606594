import { createSelector } from "reselect";

// eslint-disable-next-line no-unused-vars
const approvalPage = state => state.approvalPage;
const selectTable = state => state.approvalPage.table;

export const selectItems = createSelector(selectTable, table => table.items);

export const selectCount = createSelector(selectTable, table => table.count);

export const selectDialogOpen = createSelector(
  approvalPage,
  approval => approval.openDialog
);

export const selectAction = createSelector(
  approvalPage,
  approval => approval.action
);

export const selectCurrentCondition = createSelector(
  approvalPage,
  approval => approval.currentCondition
);
