import { createSelector } from "reselect";
import orderBy from "lodash/orderBy";
import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";
import { selectCurrentTrafficPlanId } from "../TrafficPlan/trafficPlanSelectors";
import {
  REMARK_STATUS_UNREAD,
  EMPTY_STRING
} from "../../../configurations/appConstants";
import { hasValue } from "../../../functions/util";

export const selectTrafficPlanRemark = state => state.trafficPlanRemark;
export const selectTrafficPlan = (_, props) => props.id;
export const selectPropertyName = (_, props) => props.name;

export const selectCurrentTrafficPlanRemarkId = createSelector(
  selectTrafficPlanRemark,
  trafficPlanRemark => trafficPlanRemark.currentTrafficPlanRemarkId
);

export const selectIsEditingTrafficPlanRemark = createSelector(
  selectTrafficPlanRemark,
  trafficPlanRemark => trafficPlanRemark.isEditing
);

export const getTrafficPlanRemarks = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlanRemark } = entitiesSession;
    return orderBy(
      TrafficPlanRemark.filter(
        r => r.trafficPlan === trafficPlanId
      ).toRefArray(),
      ["modified"],
      ["desc"]
    );
  }
);

export const getUnreadTrafficPlanRemarks = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlanRemark } = entitiesSession;
    return TrafficPlanRemark.filter(
      r =>
        r.trafficPlan === trafficPlanId && r.statusId === REMARK_STATUS_UNREAD
    ).toRefArray();
  }
);

export const getUnreadTrafficPlanRemarksIDs = createSelector(
  [getEntitiesSession, selectCurrentTrafficPlanId],
  (entitiesSession, trafficPlanId) => {
    const { TrafficPlanRemark } = entitiesSession;
    return TrafficPlanRemark.filter(
      r =>
        r.trafficPlan === trafficPlanId && r.statusId === REMARK_STATUS_UNREAD
    )
      .toModelArray()
      .map(r => r.id);
  }
);

export const getEditingTrafficPlanRemark = createSelector(
  [getEditingEntitiesSession, selectCurrentTrafficPlanRemarkId],
  (editingSession, trafficPlanRemarkId) => {
    const { TrafficPlanRemark } = editingSession;
    const model = TrafficPlanRemark.get(r => r.id === trafficPlanRemarkId);
    return model ? { ...model.ref } : undefined;
  }
);

export const selectIsRemarkEmpty = createSelector(
  [getEditingEntitiesSession, selectCurrentTrafficPlanRemarkId],
  (editingSession, trafficPlanRemarkId) => {
    const { TrafficPlanRemark } = editingSession;
    const model = TrafficPlanRemark.get(r => r.id === trafficPlanRemarkId);
    const { remark = EMPTY_STRING } = model ? model.ref : {};
    return !hasValue(remark);
  }
);

export const getPropertyValue = createSelector(
  [getEditingTrafficPlanRemark, selectPropertyName],
  (trafficPlanRemark, name) =>
    trafficPlanRemark ? trafficPlanRemark[name] : undefined
);
