import React from "react";
import { useSelector } from "react-redux";
import toUpper from "lodash/toUpper";

import { Typography, Divider, Tooltip } from "@mui/material";

import { getCampaignData } from "../../../../../business/models/Campaign/campaignSelectors";
import {
  PLACEHOLDER,
  EMPTY_STRING
} from "../../../../../configurations/appConstants";
import { classes, Paper } from "../../styles/sidePanel";

const PlanSidePanel = () => {
  const campaign = useSelector(getCampaignData) || {};

  const {
    debtor: debtorName,
    campaignName,
    marathonCampaignId: campaignId,
    marathonDebtorId,
    productName,
    productId,
    agreementName: agreement,
    poNumber,
    processManager
  } = campaign;

  return (
    <Paper square elevation={0} className={classes.sidePanel}>
      <div className={classes.sidePanelWrapper}>
        <div className={classes.sideBox}>
          <Tooltip title={toUpper(debtorName) || EMPTY_STRING}>
            <Typography
              noWrap
              align="left"
              className={classes.typographyHeaderCaption}
            >
              {toUpper(debtorName)}
            </Typography>
          </Tooltip>
          <Tooltip title={campaignName || EMPTY_STRING}>
            <Typography
              noWrap
              align="left"
              className={classes.typographyHeaderValue}
            >
              {campaignName}
            </Typography>
          </Tooltip>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.sideBox}>
          <Tooltip title={campaignId || EMPTY_STRING}>
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              {campaignId}
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Campaign ID
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={marathonDebtorId || EMPTY_STRING}>
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              {marathonDebtorId}
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Debtor ID
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={productName || EMPTY_STRING}>
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              {productName}
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Product name
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={productId || EMPTY_STRING}>
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              {productId}
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Product ID
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={agreement || EMPTY_STRING}>
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              {agreement}
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Agreement
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={poNumber || EMPTY_STRING}>
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              {poNumber || PLACEHOLDER}
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            PO Number
          </Typography>
        </div>
        <div className={classes.sideBox}>
          <Tooltip title={processManager || EMPTY_STRING}>
            <Typography
              noWrap
              align="left"
              className={classes.typographyDetailsValue}
            >
              {processManager}
            </Typography>
          </Tooltip>
          <Typography noWrap align="left" className={classes.typographyCaption}>
            Process Manager
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

export default PlanSidePanel;
