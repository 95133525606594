import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StringParam, useQueryParams } from "use-query-params";

import {
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TextField
} from "@mui/material";

import { classes, Grid } from "./styles";

import {
  selectIsLoading,
  selectAgency
} from "../../../business/selectors/applicationSelectors";
import generateUrl from "../../../business/models/common/utils/urlUtils";
import { GET_HDT_IMAGE_FOR_ORDER } from "../../../configurations/apiUrls";
import {
  apiCall,
  enqueueApiErrorMessage
} from "../../../business/actions/applicationActions";
import { GET } from "../../../business/constants/applicationConstants";
import {
  MAX_NUMBER_FOR_INPUTS,
  EMPTY_STRING
} from "../../../configurations/appConstants";

const HdtImagePage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const agency = useSelector(selectAgency);

  const [query, setQuery] = useQueryParams({
    orderId: StringParam
  });
  const [orderId, setOrderId] = useState(EMPTY_STRING);
  const [images, setImages] = useState({
    previousPage: null,
    adPage: null,
    nextPage: null
  });

  const loadHdtImages = useCallback(
    marathonOrderId => {
      if (!marathonOrderId) {
        return;
      }

      const url = generateUrl(
        GET_HDT_IMAGE_FOR_ORDER,
        { agency },
        { marathonOrderId }
      );

      dispatch(apiCall(GET, url)).then(
        response => {
          if (response) {
            setImages({
              previousPage: response.previousPageImage,
              adPage: response.currentPageImage,
              nextPage: response.nextPageImage
            });
          }
        },
        error => {
          dispatch(enqueueApiErrorMessage(error));
        }
      );
    },
    [agency, dispatch]
  );

  useEffect(() => {
    if (typeof query.orderId === "undefined") {
      setOrderId(EMPTY_STRING);
    } else {
      setOrderId(query.orderId);
    }
    loadHdtImages(query.orderId);
  }, [setOrderId, query, loadHdtImages]);

  const handleOrderNumChange = e => {
    const value = Math.floor(Number(e.target.value));
    const min = 0;
    if (value >= min && value < MAX_NUMBER_FOR_INPUTS) {
      setOrderId(value || EMPTY_STRING);
    }
  };

  const handleGetHdtImage = () => {
    setQuery({ orderId }, "pushIn");
  };

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid item>
            <TextField
              id="hdtImageOrderNumber"
              type="text"
              label="Order Number"
              value={orderId}
              variant="outlined"
              onChange={handleOrderNumChange}
            />
          </Grid>
          <Grid item>
            <Button
              id="get_hdt_image"
              name="get_hdt_image"
              variant="contained"
              className={classes.hdtButton}
              disabled={isLoading || !orderId}
              onClick={handleGetHdtImage}
            >
              GET HDT IMAGE
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ImageList cols={4} className={classes.gridList}>
          {images.previousPage && (
            <ImageListItem rows={6} cols={1.5} className={classes.gridTile}>
              <img src={images.previousPage} alt="Not found" />
              <ImageListItemBar subtitle={<span>Previous page</span>} />
            </ImageListItem>
          )}
          {images.adPage && (
            <ImageListItem rows={6} cols={1.5} className={classes.gridTile}>
              <img src={images.adPage} alt="Not found" />
              <ImageListItemBar subtitle={<span>Ad page</span>} />
            </ImageListItem>
          )}
          {images.nextPage && (
            <ImageListItem rows={6} cols={1.5} className={classes.gridTile}>
              <img src={images.nextPage} alt="Not found" />
              <ImageListItemBar subtitle={<span>Next page</span>} />
            </ImageListItem>
          )}
        </ImageList>
      </Grid>
    </Grid>
  );
};

export default HdtImagePage;
