import { styled, Accordion as MuiAccordion } from "@mui/material";
import { baliHai, matisse, linkWater } from "../../../../assets/styles/colors";

const PREFIX = "AdditionalApprovalPanelStyles";
export const classes = {
  approvalOverviewAgencyIconField: `${PREFIX}-approvalOverviewAgencyIconField`,
  approvalOverviewMarathonIdField: `${PREFIX}-approvalOverviewMarathonIdField`,
  typographyHeaderCaption: `${PREFIX}-typographyHeaderCaption`,
  typographyHeaderValue: `${PREFIX}-typographyHeaderValue`,
  typographyLink: `${PREFIX}-redLabelChip`,
  link: `${PREFIX}-link`,
  cssApproval: `${PREFIX}-cssApproval`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  approvalOverviewField: `${PREFIX}-approvalOverviewField`
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`& .${classes.approvalOverviewAgencyIconField}`]: {
    width: "30%"
  },
  [`& .${classes.approvalOverviewMarathonIdField}`]: {
    width: "70%"
  },
  [`& .${classes.typographyHeaderCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.typographyHeaderValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyLink}`]: {
    "&:hover": {
      color: theme.palette.primary.light
    }
  },
  [`& .${classes.link}`]: {
    display: "block"
  },
  [`& .${classes.approvalOverviewField}`]: {
    width: "16%"
  },
  [`& .${classes.cssApproval}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(18)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  }
}));
