import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Grid } from "@mui/material";
import TrafficPlanPanel from "../../panels/TrafficPlanPanel";
import TrafficPlanItem from "../../panels/TrafficPlanItem";
import TrafficItemPanel from "../../panels/TrafficItemPanel";
import EmptyTrafficItemsLabel from "../../panels/extensions/EmptyTrafficItemsLabel";

import {
  clearEntityData,
  clearEditingEntityData
} from "../../../business/models/common/data/dataActions";
import {
  loadTrafficData,
  selectTrafficPlan,
  clearCurrentTrafficPlan,
  loadOrderList,
  trafficItemStatusChangeClearSelected,
  clearMultipleEditTrafficItems
} from "../../../business/models/TrafficPlan/trafficPlanActions";
import {
  doesTrafficPlanExistById,
  selectCurrentTrafficPlanId
} from "../../../business/models/TrafficPlan/trafficPlanSelectors";
import {
  doesTrafficItemExistById,
  selectCurrentTrafficItem,
  selectIsCurrentTrafficItemVirtual
} from "../../../business/models/TrafficItem/trafficItemSelectors";
import {
  parseIntOrDefault,
  parseIntOrString
} from "../../../business/models/common/utils/clientUtils";

import { clearCurrentCampaign } from "../../../business/models/Campaign/campaignActions";
import {
  selectTrafficItem,
  clearCurrentTrafficItem
} from "../../../business/models/TrafficItem/trafficItemActions";
import { clearCurrentTrafficPlanRemark } from "../../../business/models/TrafficPlanRemark/trafficPlanRemarkActions";
import { loadUsers } from "../../../business/models/User/userActions";
import { selectPlan } from "../../../business/models/Plan/planActions";

import TrafficItemMultipleEditDialog from "../create/popup/TrafficItemMultipleEditDialog";
import ConfirmTrafficItemDeleteDialog from "./dialog/ConfirmTrafficItemDeleteDialog";
import AddTrafficItemDialog from "./dialog/AddTrafficItemDialog";
import TrafficPlanExportPreviewDialog from "./dialog/TrafficPlanExportPreviewDialog";
import TrafficEmailDialog from "../traffic-overview/form/TrafficEmailDialog";
import TrafficPlanRemarkDialog from "./dialog/TrafficPlanRemarkDialog";
import TrafficExportVersionDialog from "./dialog/TrafficExportVersionDialog";
import TrafficDigitalExportDialog from "./dialog/TrafficDigitalExportDialog";
import {
  selectIsTrafficDashboardLoading,
  selectRefetch
} from "../../../business/selectors/applicationSelectors";
import AddAllTrafficItemsDialog from "./dialog/AddAllTrafficItemsDialog";
import {
  resetTrafficItemAction,
  resetRefetch
} from "../../../business/actions/applicationActions";
import AdmanagementDialog from "./dialog/AdmanagementDialog";
import { APP_BAR_HEIGHT } from "../../../configurations/appConstants";
import withRouter from "../configuration/form/withRouter";

const trafficItemStyle = { paddingBottom: APP_BAR_HEIGHT }; // TODO: after hook refactor change this to use makeStyles

class TrafficDashboardPage extends PureComponent {
  componentDidMount() {
    const { loadOrders } = this.props;
    this.initPage();
    loadOrders();
  }

  componentDidUpdate(prevProps) {
    const { urlPlanId: prevUrlPlanId } = prevProps;
    const {
      urlPlanId,
      trafficPlanExists,
      trafficItemExists,
      urlTrafficPlanId,
      urlTrafficItemId,
      setTrafficPlan,
      setTrafficItem,
      currentTrafficPlan,
      currentTrafficItem,
      clearTrafficItem,
      clearTrafficPlan,
      isCurrentTrafficItemVirtual,
      refetch
    } = this.props;

    if ((!!urlPlanId && urlPlanId !== prevUrlPlanId) || refetch) {
      this.clear();
      this.initPage();
    }

    if (trafficPlanExists && urlTrafficPlanId !== currentTrafficPlan) {
      clearTrafficItem();
      setTrafficPlan(urlTrafficPlanId);
    }
    if (
      trafficItemExists &&
      !isCurrentTrafficItemVirtual &&
      urlTrafficItemId !== currentTrafficItem
    ) {
      setTrafficItem(urlTrafficItemId);
    }

    if (!urlTrafficPlanId) clearTrafficPlan();
    if (!urlTrafficItemId && !isCurrentTrafficItemVirtual) clearTrafficItem();
  }

  componentWillUnmount() {
    const { resetPlan } = this.props;
    this.clear();
    resetPlan();
  }

  clear = () => {
    const {
      clearCampaign,
      clearTrafficPlan,
      clearTrafficPlanRemark,
      clearTrafficItem,
      clearData,
      clearEditingData,
      clearSelectedTrafficItems,
      clearMultipleEdit,
      resetTrafficItemActionSwitch,
      resetFetchData
    } = this.props;
    clearData();
    clearEditingData();
    clearCampaign();
    clearTrafficPlan();
    clearTrafficItem();
    clearTrafficPlanRemark();
    clearMultipleEdit();
    clearSelectedTrafficItems();
    resetTrafficItemActionSwitch();
    resetFetchData();
  };

  initPage = () => {
    const {
      fetchUsers,
      urlPlanId,
      setPlan,
      loadTraffics,
      navigate,
      params
    } = this.props;
    fetchUsers();
    setPlan(parseIntOrDefault(urlPlanId, 10));
    loadTraffics(navigate, params);
  };

  render() {
    const { isLoading, currentTrafficPlan } = this.props;
    return (
      <>
        {!isLoading && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TrafficPlanPanel />
            </Grid>
            <Grid item xs={3}>
              <TrafficPlanItem />
            </Grid>
            <Grid item xs={9} style={trafficItemStyle}>
              {currentTrafficPlan ? (
                <TrafficItemPanel />
              ) : (
                <EmptyTrafficItemsLabel />
              )}
            </Grid>
          </Grid>
        )}
        <TrafficPlanRemarkDialog />
        <TrafficPlanExportPreviewDialog />
        <TrafficEmailDialog />
        <TrafficItemMultipleEditDialog />
        <ConfirmTrafficItemDeleteDialog />
        <AddTrafficItemDialog />
        <TrafficExportVersionDialog />
        <TrafficDigitalExportDialog />
        <AddAllTrafficItemsDialog />
        <AdmanagementDialog />
      </>
    );
  }
}

TrafficDashboardPage.propTypes = {
  agency: PropTypes.string.isRequired,
  urlPlanId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  trafficPlanId: PropTypes.number,
  trafficItemId: PropTypes.number,
  trafficPlanExists: PropTypes.bool,
  trafficItemExists: PropTypes.bool
};

TrafficDashboardPage.defaultProps = {
  trafficPlanExists: false,
  trafficItemExists: false
};

const mapStateToProps = (state, { params }) => {
  const {
    agency,
    trafficPlanId: urlTrafficPlan,
    trafficItemId: urlTrafficItem,
    planId: urlPlanId
  } = params;

  const urlTrafficPlanId = parseIntOrString(urlTrafficPlan);
  const urlTrafficItemId = parseIntOrDefault(urlTrafficItem);

  return {
    agency,
    urlPlanId,
    trafficPlanExists: doesTrafficPlanExistById(state, {
      trafficPlanId: urlTrafficPlanId
    }),
    trafficItemExists: doesTrafficItemExistById(state, {
      trafficPlanId: urlTrafficPlanId,
      trafficItemId: urlTrafficItemId
    }),
    urlTrafficPlanId,
    urlTrafficItemId,
    currentTrafficPlan: selectCurrentTrafficPlanId(state),
    currentTrafficItem: selectCurrentTrafficItem(state),
    isCurrentTrafficItemVirtual: selectIsCurrentTrafficItemVirtual(state),
    isLoading: selectIsTrafficDashboardLoading(state),
    refetch: selectRefetch(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchUsers: () => dispatch(loadUsers()), // TODO think about moving this to dialog
  setPlan: id => dispatch(selectPlan(id)),
  setTrafficPlan: planId => dispatch(selectTrafficPlan(planId)),
  setTrafficItem: trafficItemId => dispatch(selectTrafficItem(trafficItemId)),
  loadTraffics: (navigate, params) =>
    dispatch(loadTrafficData(navigate, params)),
  clearCampaign: () => dispatch(clearCurrentCampaign()),
  clearTrafficPlan: () => dispatch(clearCurrentTrafficPlan()),
  clearTrafficPlanRemark: () => dispatch(clearCurrentTrafficPlanRemark()),
  clearTrafficItem: () => dispatch(clearCurrentTrafficItem()),
  clearData: () => dispatch(clearEntityData()),
  loadOrders: () => dispatch(loadOrderList()),
  clearEditingData: () => dispatch(clearEditingEntityData()),
  clearSelectedTrafficItems: () =>
    dispatch(trafficItemStatusChangeClearSelected()),
  clearMultipleEdit: () => dispatch(clearMultipleEditTrafficItems()),
  resetTrafficItemActionSwitch: () => dispatch(resetTrafficItemAction()),
  resetFetchData: () => dispatch(resetRefetch()),
  resetPlan: () => dispatch(selectPlan(null))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrafficDashboardPage)
);
