import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@mui/material";
import { DoneAll } from "@mui/icons-material";

const SuccessNotifierAction = ({ key, closeSnackbar }) => {
  const handleClick = () => closeSnackbar(key);
  return (
    <IconButton onClick={handleClick}>
      <DoneAll />
    </IconButton>
  );
};

SuccessNotifierAction.propTypes = {
  key: PropTypes.number,
  closeSnackbar: PropTypes.func
};

export default SuccessNotifierAction;
