import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

import {
  deletePublicPermission,
  setIsOpenDeletePublicPermission,
  setSelectedPublicPermission
} from "../../../../business/actions/apiManagementActions";
import { selectPublicPermission } from "../../../../business/selectors/apiManagementSelectors";
import { DELETE_PUBLIC_PERMISSION_MESSAGE } from "../../../../business/constants/apiManagementConstants";
import {
  classes,
  DialogContentText
} from "../styles/deletePublicPermissionDialogStyle";

const paperProps = { square: true };

const DeletePublicPermissionDialog = () => {
  const dispatch = useDispatch();
  const publicPermission = useSelector(selectPublicPermission);

  const handleDelete = () => {
    dispatch(deletePublicPermission(publicPermission));
  };

  const handleCancel = () => {
    dispatch(
      setSelectedPublicPermission({ applicationId: null, resource: null })
    );
    dispatch(setIsOpenDeletePublicPermission());
  };

  return (
    <Dialog open PaperProps={paperProps} maxWidth="md">
      <DialogContent>
        <DialogContentText className={classes.root} noWrap={false}>
          {DELETE_PUBLIC_PERMISSION_MESSAGE}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary">
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePublicPermissionDialog;
