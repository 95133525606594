import { styled, Grid as MuiGrid } from "@mui/material";
import {
  matisse,
  linkWater,
  azureRadiance,
  baliHai
} from "../../../../../../assets/styles/colors";
import { getThemeSpacing } from "../../../../../../functions/util";

const PREFIX = "ActionPlanItemCompactStyle";
export const classes = {
  infoGridItem: `${PREFIX}-infoGridItem`,
  typographyPlanHeaderCaption: `${PREFIX}-typographyPlanHeaderCaption`,
  typographyPlanHeaderValue: `${PREFIX}-typographyPlanHeaderValue`,
  typographyValue: `${PREFIX}-typographyValue`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  planIdGridItem: `${PREFIX}-planIdGridItem`,
  calcInfoGridItem: `${PREFIX}-calcInfoGridItem`,
  ctcInfoGridItemAlign: `${PREFIX}-ctcInfoGridItemAlign`,
  typographyMarked: `${PREFIX}-typographyMarked`,
  insertionIconButton: `${PREFIX}-insertionIconButton`
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`&.${classes.infoGridItem}`]: {
    maxWidth: "40%",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "25%"
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "20%"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "20%"
    }
  },
  [`& .${classes.typographyPlanHeaderCaption}`]: {
    color: baliHai,
    fontSize: theme.typography.pxToRem(12),
    paddingLeft: theme.typography.pxToRem(getThemeSpacing(theme, 0.5))
  },
  [`& .${classes.typographyPlanHeaderValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(20)
  },
  [`& .${classes.typographyValue}`]: {
    color: matisse,
    fontSize: theme.typography.pxToRem(24)
  },
  [`& .${classes.typographyCaption}`]: {
    color: linkWater,
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${classes.planIdGridItem}`]: {
    width: theme.typography.pxToRem(96)
  },
  [`& .${classes.calcInfoGridItem}`]: {
    width: theme.typography.pxToRem(200)
  },
  [`& .${classes.ctcInfoGridItemAlign}`]: {
    textAlign: "end"
  },
  [`& .${classes.typographyMarked}`]: {
    color: azureRadiance
  }
}));
