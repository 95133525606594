import {
  CLEAR_TRAFFIC_TEMPLATE_MANAGER_PAGE,
  OPEN_MANAGE_TRAFFIC_TEMPLATE_DIALOG,
  CLOSE_MANAGE_TRAFFIC_TEMPLATE_DIALOG
} from "../constants/trafficTemplateManagerConstants";

export const clearTrafficTemplateManagerPage = () => ({
  type: CLEAR_TRAFFIC_TEMPLATE_MANAGER_PAGE
});

export const openManageTrafficTemplateDialog = () => ({
  type: OPEN_MANAGE_TRAFFIC_TEMPLATE_DIALOG
});

export const closeManageTrafficTemplateDialog = () => ({
  type: CLOSE_MANAGE_TRAFFIC_TEMPLATE_DIALOG
});
