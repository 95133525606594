import React from "react";
import PropTypes from "prop-types";
import InputBase from "@mui/material/InputBase";

import { Search as SearchIcon } from "@mui/icons-material";
import { MenuItem, FormControl } from "@mui/material";

import { classes, Div, Select } from "./styles/search";

const Search = props => {
  const { searchText, searchProperty, onChange, onInputChange } = props;
  return (
    <>
      <Div className={classes.grow} />
      <Div className={classes.search}>
        <Div className={classes.searchIcon}>
          <SearchIcon />
        </Div>
        <InputBase
          placeholder="Search"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          value={searchText}
          onChange={onInputChange}
        />
      </Div>
      <FormControl variant="standard" sx={{ minWidth: 60 }}>
        <Select
          id="um_search"
          name="um_search"
          className={classes.dropDown}
          value={searchProperty}
          onChange={onChange}
          inputProps={{
            name: "user-search",
            id: "user-general-search"
          }}
        >
          <MenuItem value={"firstName"}>By first name </MenuItem>
          <MenuItem value={"lastName"}>By last name </MenuItem>
          <MenuItem value={"userName"}>By user name </MenuItem>
          <MenuItem value={"marathonUser"}>By Marathon user</MenuItem>
          <MenuItem value={"agencyName"}>By company</MenuItem>
          <MenuItem value={"isAdmin"}>Is Admin</MenuItem>
          <MenuItem value={"isAgent"}>Is Agent</MenuItem>
          <MenuItem value={"roleNames"}>By role</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
Search.propTypes = {
  searchText: PropTypes.string,
  searchProperty: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func
};

Search.defaultProps = {
  searchText: "",
  searchProperty: "userName"
};

export default Search;
