import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox as MuiCheckbox
} from "@mui/material";

/** Checkbox Component */
class Checkbox extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(...args) {
    const { onChange } = this.props;
    onChange(args[1]);
  }

  render() {
    const {
      color,
      label,
      disabled,
      hidden,
      value,
      id,
      error,
      errorMessage
    } = this.props;
    return (
      <FormControl
        error={error}
        fullWidth={false}
        disabled={disabled}
        style={{ display: hidden ? "none" : "default" }}
      >
        {error && (
          <FormHelperText sx={{ fontSize: "small" }} variant="standard">
            {errorMessage}
          </FormHelperText>
        )}
        <FormControlLabel
          control={
            <MuiCheckbox
              id={id}
              color={color}
              disabled={disabled}
              checked={value}
              onChange={this.onChange}
            />
          }
          label={label}
        />
      </FormControl>
    );
  }
}

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string
};

Checkbox.defaultProps = {
  value: false,
  label: "",
  disabled: false,
  id: "id",
  color: "secondary",
  error: false,
  errorMessage: ""
};

export default Checkbox;
