import { styled, Table as MuiTable } from "@mui/material";

const PREFIX = "SystemMessagesListStyles";
export const classes = {
  tableCell: `${PREFIX}-tableCell`,
  tableCellEmpty: `${PREFIX}-tableCellEmpty`
};

export const Table = styled(MuiTable)(({ theme }) => ({
  [`& .${classes.tableCell}`]: {
    maxWidth: theme.typography.pxToRem(150)
  },
  [`& .${classes.tableCellEmpty}`]: {
    border: "none"
  }
}));
