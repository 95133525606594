import React, { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import {
  DialogContent,
  Button,
  DialogActions,
  DialogContentText
} from "@mui/material";

import { selectOpenTrafficExportDigitalVersionDialog } from "../../../../business/selectors/applicationSelectors";
import { selectCurrentTrafficPlanId } from "../../../../business/models/TrafficPlan/trafficPlanSelectors";
import { closeTrafficExportDigitalVersionDialog } from "../../../../business/actions/applicationActions";
import { downloadTraffic } from "../../../../business/models/TrafficPlan/trafficPlanActions";
import AutoComplete from "../../../form/controls/AutoComplete";
import { digitalTrafficTemplateOptions } from "../../traffic-overview/form/TrafficEmailDialog";

import { classes, Dialog } from "../styles/dialog";

const TrafficDigitalExportDialog = ({
  open,
  closeDialog,
  downloadTrafficExcel,
  trafficPlanId
}) => {
  const [templateId, setTemplateId] = useState(null);

  const handleChange = temp => {
    setTemplateId(temp);
  };

  const handleSubmit = () => {
    downloadTrafficExcel(parseInt(trafficPlanId, 10), templateId);
    closeDialog();
    setTemplateId(null);
  };

  const handleClose = () => {
    closeDialog();
    setTemplateId(null);
  };

  const isDisabled = templateId === null;
  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <DialogContent classes={{ root: classes.root }}>
        <DialogContentText>Select digital traffic template:</DialogContentText>
        <AutoComplete
          id="select_digital-export-version"
          name="select_digital-export-version"
          options={digitalTrafficTemplateOptions}
          value={templateId}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel_digital-export-version"
          name="cancel_digital-export-version"
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          id="download_digital-export-version"
          name="download_digital-export-version"
          onClick={handleSubmit}
          disabled={isDisabled}
          color="secondary"
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TrafficDigitalExportDialog.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  downloadTrafficExcel: PropTypes.func
};

const mapStateToProps = state => {
  return {
    open: selectOpenTrafficExportDigitalVersionDialog(state),
    trafficPlanId: selectCurrentTrafficPlanId(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeDialog: () => dispatch(closeTrafficExportDigitalVersionDialog()),
    downloadTrafficExcel: (trafficPlanId, templ) =>
      dispatch(downloadTraffic(trafficPlanId, templ))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrafficDigitalExportDialog);
