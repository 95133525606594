import React from "react";

import { connect } from "react-redux";

import { Grid, Typography, DialogContent, DialogActions } from "@mui/material";

import DialogTitle from "../../../shared/dialog/DialogTitle";
import { classes, Dialog, Button } from "../styles/uploadDialog";
import Scrollbar from "../../../shared/Scrollbar";
import UploadDialogItem from "./extensions/UploadDialogItem";

import { selectTrafficPlanAttachments } from "../../../../business/models/TrafficPlan/trafficPlanSelectors";
import { setSelectedAttachment } from "../../../../business/models/TrafficPlan/trafficPlanActions";

const UploadDialog = ({
  handleClose,
  open,
  handleUpload,
  handleDelete,
  attachments,
  handleCheckBox,
  isDeleteDisabled,
  isLoading,
  disableActions
}) => {
  const handleCheckBoxClick = (id, selected) => {
    handleCheckBox(id, selected);
  };

  const areAttachmentExists = attachments.length !== 0;

  return (
    <Dialog
      aria-labelledby="upload-ooh-attachment-dialog"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="upload-ooh-attachment-dialog-title">
        Attachment
      </DialogTitle>
      <DialogContent className={classes.content}>
        {!areAttachmentExists ? (
          <Typography align="center" noWrap>
            no attachments
          </Typography>
        ) : (
          <Scrollbar>
            {attachments.map(attachment => (
              <UploadDialogItem
                key={attachment.fileInfoId}
                id={attachment.fileInfoId}
                attachment={attachment}
                onCheckBoxClick={handleCheckBoxClick}
                hideCheckbox={disableActions}
              />
            ))}
          </Scrollbar>
        )}
      </DialogContent>
      <DialogActions className={classes.action}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button
              id="close_ooh_attachment_upload_dialog"
              onClick={handleClose}
              color="secondary"
              disabled={isLoading}
            >
              Close
            </Button>
          </Grid>
          <Grid item>
            <Grid container>
              {!disableActions && (
                <Grid item>
                  <Button
                    id="delete_ooh_attachment_dialog"
                    onClick={handleDelete}
                    color="secondary"
                    disabled={isDeleteDisabled || isLoading}
                  >
                    Delete
                  </Button>
                </Grid>
              )}
              {!disableActions && (
                <Grid item>
                  <Button
                    id="add_ooh_attchemnt_dialog"
                    color="primary"
                    component="label"
                    disabled={isLoading || !isDeleteDisabled}
                  >
                    <input
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleUpload}
                      disabled={isLoading}
                    />
                    Add
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => {
  return {
    attachments: selectTrafficPlanAttachments(state),
    disableActions: props.disableActions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleCheckBox: (id, selected) =>
      dispatch(setSelectedAttachment(id, selected))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDialog);
