import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import AgencyList from "./AgencyList";
import {
  selectUserAgencies,
  selectUserIsAdmin
} from "../../../business/selectors/userProfileSelectors";
import { CardLink } from "../../form/controls/CardLink";
import { classes, Div } from "./styles/agencyPage";
import {
  AACONFIGURATION_PAGE,
  API_MANAGEMENT_PAGE,
  BLOSSOM_AGENCY,
  SYSTEM_MESSAGES_PAGE,
  USER_MANAGEMENT_PAGE
} from "../../../configurations/appConstants";

const AgencyPage = () => {
  const navigate = useNavigate();
  const agencies = useSelector(selectUserAgencies);
  const isAdmin = useSelector(selectUserIsAdmin);

  useEffect(() => {
    const agenciesCount = agencies.length;
    if (agenciesCount > 0 && agenciesCount < 2) {
      navigate(`${agencies[0]}/overview/all`);
    }
  }, [agencies, navigate]);

  return (
    <Div className={classes.root}>
      <Grid
        container
        spacing={10}
        direction="column"
        className={classes.appContainer}
      >
        <Grid className={classes.item} item>
          <Card className={classes.appCard} elevation={0}>
            <CardHeader title="GO" />
            <CardContent>
              <Grid item container spacing={2} justifyContent="flex-start">
                <AgencyList agencies={agencies} isAdmin={isAdmin} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {isAdmin && (
          <Grid className={classes.item} key="apiManagement" item>
            <Card className={classes.appCard} elevation={0}>
              <CardHeader title="Back-office" />
              <CardContent>
                <Grid
                  item
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Grid key="apiManagement" item>
                    <CardLink
                      id="publicApiManagement"
                      title="Public API management"
                      to={`back-office/${API_MANAGEMENT_PAGE}`}
                    />
                  </Grid>
                  <Grid key="userManagement" item>
                    <CardLink
                      id="userManagement"
                      title="User Management"
                      to={`back-office/${USER_MANAGEMENT_PAGE}`}
                    />
                  </Grid>
                  <Grid key="systemMessages" item>
                    <CardLink
                      id="systemMessages"
                      title="System Messages"
                      to={`back-office/${SYSTEM_MESSAGES_PAGE}`}
                    />
                  </Grid>
                  <Grid key="configurationAA" item>
                    <CardLink
                      id="configurationAA"
                      title="Configuration"
                      to={`back-office/${AACONFIGURATION_PAGE}/${BLOSSOM_AGENCY}`}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Div>
  );
};

export default AgencyPage;
