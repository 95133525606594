import React from "react";
import { Grid } from "@mui/material";
import UserOverviewPanel from "./UserOverviewPanel";
import NotificationSettingsPanel from "./NotificationSettingsPanel";
import EmailSettingsPanel from "./EmailSettingsPanel";
import { classes } from "../../styles/userProfilePageStyle";

const UserProfileForm = ({
  profile,
  notificationSettings,
  onNotificationSwitch,
  onAllNotificationSwitch,
  allNotificationSwitched,
  emailSettings,
  onEmailSwitch,
  onAllEmailSwitch,
  allEmailSwitched
}) => {
  return (
    <Grid className={classes.overviewPanel} container spacing={3}>
      <Grid item xs={12}>
        {profile && <UserOverviewPanel data={profile} />}
      </Grid>
      <Grid item xs={6}>
        {notificationSettings && (
          <NotificationSettingsPanel
            data={notificationSettings}
            handleChange={onNotificationSwitch}
            handleAllChange={onAllNotificationSwitch}
            allSwitched={allNotificationSwitched}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {emailSettings && (
          <EmailSettingsPanel
            data={emailSettings}
            handleChange={onEmailSwitch}
            handleAllChange={onAllEmailSwitch}
            allSwitched={allEmailSwitched}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default UserProfileForm;
