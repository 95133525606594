import {
  styled,
  darken,
  alpha,
  DialogContent as MuiDialogContent
} from "@mui/material";

import { lavender, shipCove, white } from "../../../../assets/styles/colors";

const PREFIX = "ActionInsertionItemStyles";
export const classes = {
  paperRoot: `${PREFIX}-paperRoot`,
  tableCellUnderline: `${PREFIX}-tableCellUnderline`,
  scrollTableCell: `${PREFIX}-scrollTableCell`,
  tableCellHeader: `${PREFIX}-tableCellHeader`,
  tableCellWidth: `${PREFIX}-tableCellWidth`,
  tableCellHeaderInner: `${PREFIX}-tableCellHeaderInner`,
  tableCell: `${PREFIX}-tableCell`,
  scrollTableCellHeaderColord: `${PREFIX}-scrollTableCellHeaderColord`,
  scrollTableTypography: `${PREFIX}-scrollTableTypography`
};

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  [`& .${classes.paperRoot}`]: {
    border: `1px solid ${darken(alpha(theme.palette.divider, 1), 0.68)}`
  },
  [`& .${classes.tableCellUnderline}`]: {
    borderBottom: `1px solid ${darken(alpha(theme.palette.divider, 1), 0.68)}`
  },
  [`& .${classes.scrollTableCell}`]: {
    borderRight: `1px solid ${darken(alpha(theme.palette.divider, 1), 0.68)}`
  },
  [`& .${classes.tableCellHeader}`]: {
    width: theme.typography.pxToRem(200),
    borderRight: `1px solid ${darken(alpha(theme.palette.divider, 1), 0.68)}`
  },
  [`& .${classes.tableCellWidth}`]: {
    width: theme.typography.pxToRem(200)
  },
  [`& .${classes.tableCellHeaderInner}`]: {
    borderLeft: `1px solid ${darken(alpha(theme.palette.divider, 1), 0.68)}`
  },
  [`& .${classes.tableCell}`]: {
    backgroundColor: lavender
  },
  [`& .${classes.scrollTableCellHeaderColord}`]: {
    backgroundColor: shipCove
  },
  [`& .${classes.scrollTableTypography}`]: {
    color: white
  }
}));
