import join from "lodash/join";
import { enqueueApiErrorMessage, apiCall } from "./applicationActions";

import {
  TOGGLE_SELECTED,
  CLEAR_SELECTED,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_ACTION,
  CLEAR_ACTION,
  ACTION_APPROVE,
  ACTION_DECLINE,
  SET_SEND_STATUSES,
  SET_UNSEND_STATUSES,
  SET_ITEMS,
  CLEAR_UNSEND_STATUSES,
  CLEAR_SEND_STATUSES,
  SET_COUNT,
  RESET_ACTION_PAGE,
  ACTION_DONE,
  SET_COMPARE_ORDER_ID,
  OPEN_COMPARE_DIALOG,
  CLOSE_COMPARE_DIALOG
} from "../constants/actionPageConstants";

import {
  selectAction,
  selectCompareOrderId
} from "../selectors/actionPageSelectors";
import { selectAgency } from "../selectors/applicationSelectors";

import { PLACEHOLDER, ASC } from "../../configurations/appConstants";
import {
  APPROVE_ORDERS,
  DECLINE_ORDERS,
  GET_ACTION_CAMPAIGNS,
  GET_ORDER_CHANGES
} from "../../configurations/apiUrls";

import {
  getOrders,
  getOrdersForActionPage
} from "../models/Order/orderSelectors";
import { ORDER_MODEL_ID } from "../models/Order/orderConstants";

import generateUrl from "../models/common/utils/urlUtils";
import { GET, PUT } from "../constants/applicationConstants";

const getFullRequestBody = query => {
  const {
    currentPage = 0,
    pageSize,
    searchProperty,
    searchText,
    searchMyCampaigns,
    searchMediaTypes,
    sort,
    ...queryFilters
  } = query;
  const toSortBy = sort || "campaignId";
  const isascending = sort ? sort.indexOf(ASC) !== -1 : false;
  const sortbycolumn = isascending ? sort.split(PLACEHOLDER)[0] : toSortBy;
  return {
    searchproperty: searchProperty,
    searchtext: searchText,
    searchmycampaigns: searchMyCampaigns,
    searchmediatypes: join(searchMediaTypes, ","),
    sortbycolumn,
    isascending,
    page: Number(currentPage) + 1,
    pagesize: Number(pageSize),
    ...queryFilters
  };
};

const getUrl = (agency, query) => {
  const paramObj = { agency };

  const queryObj = getFullRequestBody(query);
  return generateUrl(GET_ACTION_CAMPAIGNS, paramObj, queryObj);
};

export const toggleSelected = payload => {
  return {
    type: TOGGLE_SELECTED,
    payload
  };
};

function isNoneApprovedStatus(order) {
  return !(order.statusId === 3 || order.statusId === 33);
}

export const selectAll = () => (dispatch, getState) => {
  const state = getState();
  const data = getOrders(state);
  const selected = data
    .filter(order => isNoneApprovedStatus(order) && order.canUserDoActions)
    .map(element => element.id);

  dispatch(toggleSelected(selected));
};

export const clearSelected = () => ({ type: CLEAR_SELECTED });

export const setSendStatuses = payload => ({
  type: SET_SEND_STATUSES,
  payload
});

export const setUnsendStatuses = payload => ({
  type: SET_UNSEND_STATUSES,
  payload
});

export const setAction = payload => ({ type: SET_ACTION, payload });

export const clearAction = () => ({ type: CLEAR_ACTION });

export const openDialog = () => ({ type: OPEN_DIALOG });

export const closeDialog = () => ({ type: CLOSE_DIALOG });

export const setItems = payload => ({ type: SET_ITEMS, payload });

export const setCount = payload => dispatch => {
  dispatch({ type: SET_COUNT, payload });
};

export const clearUnsendStatuses = () => ({ type: CLEAR_UNSEND_STATUSES });

export const clearSendStatuses = () => ({ type: CLEAR_SEND_STATUSES });

export const resetPage = () => ({ type: RESET_ACTION_PAGE });

export const loadItems = query => (dispatch, getState) => {
  const state = getState();
  const { status } = query;
  const agency = selectAgency(state);
  const url = getUrl(agency, query, status);

  return dispatch(apiCall(GET, url)).then(
    response => {
      if (response) {
        const { totalItems, items } = response;
        dispatch(setCount(totalItems));
        dispatch(setItems(items));
      }
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const approveOrders = () => (dispatch, getState) => {
  const state = getState();
  const selectedOrders = getOrdersForActionPage(state);

  const agencyID = selectAgency(state);
  const requestBody = {
    orders: selectedOrders.map(order => {
      return {
        OrderId: order.orderId,
        Modified: order.modified
      };
    })
  };
  const url = generateUrl(APPROVE_ORDERS, {
    agency: agencyID
  });

  return dispatch(apiCall(PUT, url, requestBody)).then(
    response => {
      const { isSuccessful } = response || {};

      if (response) {
        const { sendStatuses, unsendStatuses } = response;

        dispatch(setSendStatuses(sendStatuses));
        dispatch(setUnsendStatuses(unsendStatuses));
        dispatch(setAction(ACTION_DONE));
      }

      return isSuccessful || false;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const declineOrders = () => (dispatch, getState) => {
  const state = getState();
  const selectedOrders = getOrdersForActionPage(state);

  const agencyID = selectAgency(state);
  const requestBody = {
    orders: selectedOrders.map(order => {
      return {
        OrderId: order.orderId,
        Modified: order.modified
      };
    })
  };
  const url = generateUrl(DECLINE_ORDERS, {
    agency: agencyID
  });

  return dispatch(apiCall(PUT, url, requestBody)).then(
    response => {
      const { isSuccessful } = response || {};

      if (response) {
        const { sendStatuses, unsendStatuses } = response;

        dispatch(setSendStatuses(sendStatuses));
        dispatch(setUnsendStatuses(unsendStatuses));
        dispatch(setAction(ACTION_DONE));
      }

      return isSuccessful || false;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};

export const statusSave = () => (dispatch, getState) => {
  const action = selectAction(getState());

  // eslint-disable-next-line default-case
  switch (action) {
    case ACTION_APPROVE:
      return dispatch(approveOrders());
    case ACTION_DECLINE:
      return dispatch(declineOrders());
    default:
      return undefined;
  }
};

export const setOrderChangeCompareOrderId = payload => ({
  type: SET_COMPARE_ORDER_ID,
  payload
});

export const openOrderChangeCompareDialog = () => ({
  type: OPEN_COMPARE_DIALOG
});

export const closeActionCompareDialog = () => ({
  type: CLOSE_COMPARE_DIALOG
});

export const closeAndSelectActionCompareDialog = () => (dispatch, getState) => {
  const state = getState();
  const id = selectCompareOrderId(state);
  dispatch(toggleSelected(id));
  dispatch(closeActionCompareDialog());
};

export const getChangesForOrder = () => (dispatch, getState) => {
  const state = getState();
  const agencyID = selectAgency(state);
  const id = selectCompareOrderId(state);
  const url = generateUrl(GET_ORDER_CHANGES, {
    agency: agencyID,
    [ORDER_MODEL_ID]: id
  });

  return dispatch(apiCall(GET, url)).then(
    response => {
      return response;
    },
    error => {
      dispatch(enqueueApiErrorMessage(error));
    }
  );
};
