export const LOAD_ENTITY_DATA_SUCCESS = "LOAD_ENTITY_DATA_SUCCESS";
export const APPEND_ENTITY_DATA_SUCCESS = "APPEND_ENTITY_DATA_SUCCESS";

export const CLEAR_ENTITY_DATA = "CLEAR_ENTITY_DATA";
export const CLEAR_EDITING_ENTITY_DATA = "CLEAR_EDITING_ENTITY_DATA";

export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_TRAFFIC_TEMPLATES_SUCCESS = "LOAD_TRAFFIC_TEMPLATES_SUCCESS";

export const LOAD_SYSTEM_MESSAGES_SUCCESS = "LOAD_SYSTEM_MESSAGES_SUCCESS";

export const LOAD_TRAFFIC_PLAN_DATA_SUCCESS = "LOAD_TRAFFIC_PLAN_DATA";
