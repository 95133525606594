import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import RulePanel from "./RulePanel";
import { ruleTypesEnum } from "../../../../configurations/appConstants";

const RuleList = () => {
  return (
    <>
      {Object.values(ruleTypesEnum).map(rule => (
        <Grid item key={rule}>
          <RulePanel type={rule} />
        </Grid>
      ))}
    </>
  );
};

RuleList.propTypes = {
  canEditAgencyRules: PropTypes.bool
};

export default RuleList;
