import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import toUpper from "lodash/toUpper";
import toLower from "lodash/toLower";

import { Typography, Tooltip } from "@mui/material";

import { isNullOrUndefined } from "../../../functions/util";
import { MediaTypeIcon } from "../../../assets/styles/icons";
import ClipboardTooltip from "../../shared/ClipboardTooltip";
import PlanPanelCalculations from "./PlanPanelCalculations";
import { classes, Grid } from "../styles/planPanelCalculations";

const PlanPanelCompact = ({
  id,
  mediaType,
  planName,
  disableAction,
  isActual,
  marathonPlanId,
  totalBudget,
  currencySupplierSymbol,
  currencySupplier
}) => {
  const handleCopy = e => {
    e.stopPropagation();
  };
  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={7}
        className={classes.infoGridItem}
      >
        <Grid container alignItems="center">
          <Grid item>
            <MediaTypeIcon type={toLower(mediaType)} />
          </Grid>
          <Grid item>
            <Typography className={classes.typographyPlanHeaderCaption}>
              {toUpper(mediaType)}
            </Typography>
          </Grid>
          <Grid item xs={12} zeroMinWidth>
            <Tooltip
              title={
                <ClipboardTooltip
                  propertyId="planName"
                  entityId={id}
                  onButtonClick={handleCopy}
                  text={planName}
                  disabled={disableAction}
                />
              }
              placement="bottom-start"
            >
              <Typography
                noWrap
                id="planName"
                name="planName"
                className={
                  isActual
                    ? classes.typographyPlanHeaderActualValue
                    : classes.typographyPlanHeaderValue
                }
              >
                {planName}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ minWidth: "45%" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="space-between"
          spacing={1}
        >
          <Grid item xs={4}>
            <Tooltip
              disableFocusListener={isNullOrUndefined(marathonPlanId)}
              disableHoverListener={isNullOrUndefined(marathonPlanId)}
              disableTouchListener={isNullOrUndefined(marathonPlanId)}
              title={
                <ClipboardTooltip
                  onButtonClick={handleCopy}
                  text={marathonPlanId}
                  propertyId="marathonPlanId"
                  entityId={id}
                />
              }
              placement="bottom-start"
            >
              <Typography
                id="marathonPlanId"
                name="marathonPlanId"
                align="right"
                className={classes.typographyValue}
              >
                {marathonPlanId}
              </Typography>
            </Tooltip>
            <Typography
              align="right"
              className={clsx(
                classes.typographyCaption,
                classes.planIdGridItem
              )}
            >
              Plan ID
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <PlanPanelCalculations
              id={id}
              totalBudget={totalBudget}
              currencySupplierSymbol={currencySupplierSymbol}
              currencySupplier={currencySupplier}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PlanPanelCompact.propTypes = {
  mediaType: PropTypes.string,
  planName: PropTypes.string,
  disableAction: PropTypes.bool,
  isActual: PropTypes.bool,
  marathonPlanId: PropTypes.number,
  totalBudget: PropTypes.number,
  currencySupplierSymbol: PropTypes.string,
  totalCostClient: PropTypes.string,
  totalCostClientEur: PropTypes.number,
  orderAmountPercentage: PropTypes.number,
  orderTotalAmount: PropTypes.number,
  clientTotalCost: PropTypes.string,
  clientTotalCostEur: PropTypes.object,
  currencySupplier: PropTypes.string
};

export default PlanPanelCompact;
