import React, { useState } from "react";
import PropTypes from "prop-types";
import toUpper from "lodash/toUpper";

import {
  Dialog,
  DialogActions,
  Grid,
  Checkbox,
  DialogContentText,
  Typography
} from "@mui/material";

import DialogTitle from "../../../../shared/dialog/DialogTitle";
import Field from "../../../../form/ApprovalField";

import { classes, Button, DialogContent } from "../../styles/approvalDialog";
import {
  APPROVE,
  DECLINE,
  APPROVE_ALL_ORDERS_MESSAGE,
  DECLINE_ALL_ORDERS_MESSAGE
} from "../../../../../business/constants/approvalPageConstants";
import { isNullOrUndefinedOrEmpty } from "../../../../../functions/util";

const ApproveDialog = ({
  open,
  action,
  comment,
  onAction,
  onCancel,
  onChange,
  isLoading,
  condition
}) => {
  const [checked, setChecked] = useState(false);

  const isApproveAction = toUpper(action) === toUpper(APPROVE);
  const isDeclineAction = toUpper(action) === toUpper(DECLINE);
  const isForeignCurrency = condition.ruleId === 1;

  const isActionDisabled =
    (isDeclineAction && isNullOrUndefinedOrEmpty(comment)) || isLoading;

  const handleCancel = () => {
    onCancel();
  };

  const handleAction = () => {
    onAction(checked);
  };

  const handleChange = value => {
    onChange(value);
  };

  const handleCheck = event => {
    setChecked(event.target.checked);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle id="action-dialog-title">
        {action !== DECLINE ? "Confirm approval" : "Reason for declining"}
      </DialogTitle>

      <DialogContent className={classes.dialog} id="action-dialog-content">
        {isApproveAction && (
          <>
            <DialogContentText>
              Are you sure that you want to approve this order?
            </DialogContentText>
            {isForeignCurrency && (
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <Checkbox onChange={handleCheck} checked={checked} />
                </Grid>
                <Grid item>
                  <Typography>{APPROVE_ALL_ORDERS_MESSAGE}</Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {isDeclineAction && (
          <>
            <DialogContentText>
              Please specify the reason for declining this order:
            </DialogContentText>
            <Field
              rowsMax="10"
              margin="normal"
              onChange={handleChange}
              name="declineComment"
              id="declineComment"
              component="text"
              value={comment}
            />
            {isForeignCurrency && (
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <Checkbox onChange={handleCheck} checked={checked} />
                </Grid>
                <Grid item>
                  <Typography>{DECLINE_ALL_ORDERS_MESSAGE}</Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions id="action-dialog-actions">
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              id="aa_cancel_confirm_dialog"
              name="aa_cancel_confirm_dialog"
              onClick={handleCancel}
              className={classes.closeButton}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="aa_action_confirm_dialog"
              name="aa_action_confirm_dialog"
              onClick={handleAction}
              variant="contained"
              disabled={isActionDisabled}
            >
              {toUpper(action)}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ApproveDialog.propTypes = {
  open: PropTypes.bool,
  action: PropTypes.string,
  comment: PropTypes.string,
  disabled: PropTypes.bool,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool
};

ApproveDialog.defaultProps = {
  open: false,
  action: ""
};

export default ApproveDialog;
