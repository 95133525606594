import React, { memo } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  Grid,
  DialogContent,
  DialogActions
} from "@mui/material";

import DialogTitle from "../../shared/dialog/DialogTitle";

import SystemMessageField from "../../form/SystemMessageField";
import {
  ACTION_CREATE,
  ACTION_EDIT
} from "../../../business/constants/applicationConstants";
import { capitalize } from "../../../functions/util";
import SystemMessageUpload from "./actions/SystemMessageUpload";
import { SystemMessageFieldCheckbox } from "./styles/systemMessagesDialogStyle";

const SystemMessagesDialog = ({ action, open, onCancel, onSave, onUpdate }) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle id="system-message-dialog-title">
        {`${capitalize(action)} Message`}
      </DialogTitle>
      <DialogContent id="system-message-dialog-content">
        <Grid container justifyContent="space-between" spacing={1}>
          <SystemMessageFieldCheckbox
            name="isActive"
            id="isActive"
            label="Is Active"
          />
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item xs={4}>
                <SystemMessageField
                  component="date"
                  name="startDate"
                  id="startDate"
                  label="Start Date *"
                />
              </Grid>
              <Grid item xs={4}>
                <SystemMessageField
                  component="date"
                  name="endDate"
                  id="endDate"
                  label="End Date *"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SystemMessageField
              component="text"
              name="title"
              id="title"
              label="Title *"
            />
          </Grid>
          <Grid item xs={12}>
            <SystemMessageField
              component="text"
              name="message"
              id="message"
              label="Message"
              rowsMax="10"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <SystemMessageUpload />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions id="system-message-dialog-action">
        <Button style={{ color: "black" }} onClick={onCancel}>
          Cancel
        </Button>
        {action === ACTION_CREATE && (
          <Button style={{ color: "black" }} onClick={onSave}>
            Save
          </Button>
        )}
        {action === ACTION_EDIT && (
          <Button style={{ color: "black" }} onClick={onUpdate}>
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

SystemMessagesDialog.propTypes = {
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

SystemMessagesDialog.defaultProps = {
  action: ACTION_CREATE,
  open: false
};

export default memo(SystemMessagesDialog);
