import { Model, attr, fk } from "redux-orm";
import set from "lodash/set";
import omit from "lodash/omit";
import forEach from "lodash/forEach";

import { PLAN_MODEL_ID, PLAN_MODEL_NAME } from "../Plan/planConstants";
import {
  PLAN_EXTENSION_MODEL_ID,
  PLAN_EXTENSION_MODEL_NAME
} from "./planExtensionConstants";

import {
  NUMBER_OF_CHARACTERS_RULE,
  NUMBER_OF_CHARACTERS_MESSAGE_500,
  NUMBER_OF_CHARACTERS_MESSAGE_1000,
  NUMBER_OF_CHARACTERS_MESSAGE_100
} from "../../constants/validationConstants";

import {
  PLAN_TARGET_METRICS_KPI_PROPERTY_NAME,
  PLAN_TARGET_METRICS_KPI_MODEL_NAME
} from "../PlanTargetMetricsKpi/planTargetMetricsKpiConstants";

import {
  PLAN_CHANGE_OVERVIEW_PROPERTY_NAME,
  PLAN_CHANGE_OVERVIEW_MODEL_NAME
} from "../PlanChangeOverview/planChangeOverviewConstants";

import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";

import { getModelClassByType } from "../common/utils/modelUtils";

class PlanExtension extends Model {
  static get fields() {
    return {
      id: attr(),
      planExtensionId: attr(),
      planId: attr(),
      planExtensionRemarks: attr(),
      communicationObjectives: attr(),
      campaignObjectives: attr(),
      mediaObjective: attr(),
      kpi: attr(),
      plan: fk(PLAN_MODEL_NAME)
    };
  }

  static get defaultAttributes() {
    return {};
  }

  static get inheritConfiguration() {
    return {};
  }

  static parse(planExtensionData) {
    if (planExtensionData[PLAN_EXTENSION_MODEL_ID]) {
      set(planExtensionData, "id", planExtensionData[PLAN_EXTENSION_MODEL_ID]);
    }
    if (planExtensionData[PLAN_MODEL_ID]) {
      set(planExtensionData, "plan", planExtensionData[PLAN_MODEL_ID]);
    }

    // Parse Plan Target Metrics KPI data
    const planTargetsMetricsKpiProperty = PLAN_TARGET_METRICS_KPI_PROPERTY_NAME;
    const PlanTargetsMetricsKpiModelClass = getModelClassByType(
      this.session,
      PLAN_TARGET_METRICS_KPI_MODEL_NAME
    );
    forEach(planExtensionData[planTargetsMetricsKpiProperty], entry => {
      PlanTargetsMetricsKpiModelClass.updateModel(entry);
    });
    let parsedData = omit(planExtensionData, planTargetsMetricsKpiProperty);

    // Parse Plan Change Overview data
    const planChangeOverviewProperty = PLAN_CHANGE_OVERVIEW_PROPERTY_NAME;
    const PlanChangeOverviewModelClass = getModelClassByType(
      this.session,
      PLAN_CHANGE_OVERVIEW_MODEL_NAME
    );
    forEach(planExtensionData[planChangeOverviewProperty], entry => {
      PlanChangeOverviewModelClass.updateModel(entry);
    });
    parsedData = omit(parsedData, planChangeOverviewProperty);

    return this.upsert(parsedData);
  }

  static updateModel(planExtensionData) {
    if (planExtensionData[PLAN_EXTENSION_MODEL_ID]) {
      set(planExtensionData, "id", planExtensionData[PLAN_EXTENSION_MODEL_ID]);
    }
    if (planExtensionData[PLAN_MODEL_ID]) {
      set(planExtensionData, "plan", planExtensionData[PLAN_MODEL_ID]);
    }

    // Parse Plan Target Metrics KPI dat
    const planTargetsMetricsKpiProperty = PLAN_TARGET_METRICS_KPI_PROPERTY_NAME;
    const PlanTargetsMetricsKpiModelClass = getModelClassByType(
      this.session,
      PLAN_TARGET_METRICS_KPI_MODEL_NAME
    );
    forEach(planExtensionData[planTargetsMetricsKpiProperty], entry => {
      PlanTargetsMetricsKpiModelClass.updateModel(entry);
    });
    let parsedData = omit(planExtensionData, planTargetsMetricsKpiProperty);

    // Parse Plan Change Overview dat
    const planChangeOverviewProperty = PLAN_CHANGE_OVERVIEW_PROPERTY_NAME;
    const PlanChangeOverviewModelClass = getModelClassByType(
      this.session,
      PLAN_CHANGE_OVERVIEW_MODEL_NAME
    );
    forEach(planExtensionData[planChangeOverviewProperty], entry => {
      PlanChangeOverviewModelClass.updateModel(entry);
    });
    parsedData = omit(parsedData, planChangeOverviewProperty);

    return this.withId(planExtensionData.id).update(parsedData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...PlanExtension.defaultAttributes,
      ...newAttributes
    };
    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherPlanExtension) {
    this.update(otherPlanExtension.ref);
  }

  static get validation() {
    return {
      planExtensionRemarks: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
          options: {
            min: 0,
            max: 1000
          }
        }
      ],
      communicationObjectives: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_1000,
          options: {
            min: 0,
            max: 1000
          }
        }
      ],
      campaignObjectives: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_500,
          options: {
            min: 0,
            max: 500
          }
        }
      ],
      mediaObjectives: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ],
      kpi: [
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_100,
          options: {
            min: 0,
            max: 100
          }
        }
      ]
    };
  }

  static get calculations() {
    return {};
  }

  validate() {
    return validateModel(PlanExtension.validation, this.toJSON());
  }

  validateByProperty(name) {
    return validateProperty(
      PlanExtension.validation[name],
      this.toJSON()[name]
    );
  }

  hasErrors() {
    return doesModelHaveErrors(PlanExtension.validation, this.toJSON());
  }

  static get apiConfiguration() {
    return {};
  }

  static get disabledFields() {
    return {};
  }
}

PlanExtension.modelName = PLAN_EXTENSION_MODEL_NAME;

export default PlanExtension;
