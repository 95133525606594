import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

class Placeholder extends React.Component {
  render() {
    const { selectProps, innerProps, children } = this.props;
    const { classes } = selectProps;
    return (
      <Typography
        color="textSecondary"
        className={classes.placeholder}
        {...innerProps}
      >
        {children}
      </Typography>
    );
  }
}

Placeholder.propTypes = {
  selectProps: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
  children: PropTypes.string
};

Placeholder.defaultProps = {
  innerProps: {},
  children: ""
};

export default Placeholder;
