import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import { getEntitiesSession } from "../common/entities/entitySelectors";
import { getEditingEntitiesSession } from "../common/editing/editingSelectors";
import TrafficTemplateModel from "./TrafficTemplate";
import { selectAgency } from "../../selectors/applicationSelectors";
import { getApiData } from "../../selectors/apiDataSelectors";
import { getOptions, getText } from "../common/utils/modelUtils";

export const selectTrafficTemplate = state => state.trafficTemplate;
export const selectPropertyName = (_, props) => props.name;
export const selectTrafficTemplateId = (_, props) => props.id;

export const selectIsEditingTrafficTemplate = createSelector(
  selectTrafficTemplate,
  trafficTemplate => trafficTemplate.isEditing
);
export const selectIsNewTrafficTemplate = createSelector(
  selectTrafficTemplate,
  trafficTemplate => trafficTemplate.isNew
);
export const selectCurrentTrafficTemplate = createSelector(
  selectTrafficTemplate,
  trafficTemplate => trafficTemplate.currentTrafficTemplate
);
export const selectTrafficTemplateErrors = createSelector(
  selectTrafficTemplate,
  trafficTemplate => trafficTemplate.displayErrors
);

export const getTrafficTemplateErrorsByProperty = createCachedSelector(
  [getEditingEntitiesSession, selectTrafficTemplate, selectPropertyName],
  (editingSession, template, name) => {
    const { TrafficTemplate } = editingSession;
    const trafficTemplateModel = TrafficTemplate.get(
      c => c.id === template.currentTrafficTemplate
    );
    return trafficTemplateModel
      ? trafficTemplateModel.validateByProperty(name)
      : undefined;
  }
)((state, props) => props.name);

export const doesTrafficTemplateHaveErrors = createSelector(
  [getEditingEntitiesSession, selectTrafficTemplate],
  (editingSession, template) => {
    const { TrafficTemplate } = editingSession;
    const trafficTemplateModel = TrafficTemplate.get(
      c => c.id === template.currentTrafficTemplate
    );
    return trafficTemplateModel ? trafficTemplateModel.hasErrors() : false;
  }
);

export const getTrafficTemplateData = createSelector(
  [getEntitiesSession, selectCurrentTrafficTemplate, getApiData, selectAgency],
  (entitiesSession, planId, apiData, agencyID) => {
    const { TrafficTemplate } = entitiesSession;
    const templateModel = TrafficTemplate.all().toRefArray();
    if (!templateModel) {
      return undefined;
    }
    const trafficTemplateConfiguration = TrafficTemplateModel.apiConfiguration;

    return templateModel.map(template => {
      const fullEntity = {
        ...template
      };
      return {
        ...template,
        dimensions: getText(
          fullEntity.dimensions,
          trafficTemplateConfiguration.dimensions,
          fullEntity,
          apiData,
          agencyID
        ),
        adServer: getText(
          fullEntity.adServer,
          trafficTemplateConfiguration.adServer,
          fullEntity,
          apiData,
          agencyID
        )
      };
    });
  }
);

export const getEditingTrafficTemplate = createSelector(
  [getEditingEntitiesSession, selectTrafficTemplate],
  (editingSession, template) => {
    const { TrafficTemplate } = editingSession;
    const editingTrafficTemplateModel = TrafficTemplate.filter(
      editingTrafficTemplate =>
        editingTrafficTemplate.id === template.currentTrafficTemplate
    ).first();

    return editingTrafficTemplateModel
      ? editingTrafficTemplateModel.ref
      : undefined;
  }
);

export const getTrafficTemplate = createSelector(
  [getEntitiesSession, selectTrafficTemplate],
  (entitiesSession, template) => {
    const { TrafficTemplate } = entitiesSession;
    const trafficTemplateModel = TrafficTemplate.get(
      c => c.id === template.currentTrafficTemplate
    );

    return trafficTemplateModel ? trafficTemplateModel.ref : undefined;
  }
);

export const getTrafficTemplatePropertyValue = createCachedSelector(
  [getEditingTrafficTemplate, selectPropertyName],
  (trafficTemplate, name) =>
    trafficTemplate ? trafficTemplate[name] : undefined
)((_, props) => props.name);

export const getTrafficTemplatePreviousValue = createCachedSelector(
  [getTrafficTemplate, selectPropertyName],
  (template, name) => (template ? template[name] : undefined)
)((_, props) => props.name);

export const getTrafficTemplatePropertyOptions = createCachedSelector(
  [getApiData, getEditingTrafficTemplate, selectPropertyName, selectAgency],
  (apiData, template, name, agencyID) => {
    const configuration = TrafficTemplateModel.apiConfiguration[name];
    return configuration
      ? getOptions(configuration, apiData, template, agencyID)
      : undefined;
  }
)((state, props) => props.name);

export const doesTrafficTemplateExistsById = createSelector(
  [getEntitiesSession, selectTrafficTemplateId],
  (entitiesSession, templateId) => {
    const { TrafficTemplate } = entitiesSession;
    return TrafficTemplate.idExists(templateId);
  }
);
